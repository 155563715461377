<template>
  <div id="customer">
    <section>
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <h3>고객센터</h3>

            <!-- 빠른 메뉴 -->
            <div class="qu-menu">
              <div class="container justify-content-center">
                <div class="row col-12 ">
                  <div class="col-6 col-lg-3 my-4 text-center">
                    <div class="icon" style="cursor: pointer;" onclick="location.href='/customerAsk'">
                      <img class="img-fluid mb-3" src="../assets/img/qna.png" alt="">
                      <p class="name">1:1 문의</p>
                    </div>
                  </div>
                  <div class="col-6 col-lg-3 my-4 text-center">
                    <div class="icon" style="cursor: pointer;" onclick="location.href='/pointList_client'">
                      <img class="img-fluid mb-3" src="../assets/img/point.png" alt="">
                      <p class="name">포인트 안내</p>
                    </div>
                  </div>
                  <div class="col-6 col-lg-3 my-4 text-center">
                    <div style="cursor: pointer;" onclick="location.href='/customerNotice'">
                      <img class="img-fluid mb-3" src="../assets/img/notice.png" alt="">
                      <p class="name">공지사항</p>
                    </div>
                  </div>
                  <div class="col-6 col-lg-3 my-4 text-center">
                    <div style="cursor: pointer;" onclick="location.href='/customerNotice?param=E'">
                      <img class="img-fluid mb-3" src="../assets/img/new-test/event.png" alt="">
                      <p class="name">이벤트</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 자주묻는 질문 -->
            <div class="question">
              <div class="container">
                <div class="text-start mx-auto">
                  <h3>자주 묻는 질문</h3>
                </div>

                <hr style="margin:0; border-top:2px solid #000; opacity:1">
                
                <!-- 질문들 -->
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div class="accordion" id="accordionqua">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaOne">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <span class="seper">예약</span> 
                            <span>여권의 유효기간은 얼마나 남아있어야 하나요?</span>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="qnaOne" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            우선 소지하고 계신 여권이 단수, 복수인지 확인해 주시고 단수여권의 경우 1회 출입국 시 재사용이 불가능합니다.<br>
                            여권 유효기간 만료일은 출국일 기준 6개월 이상 여유를 권장해 드립니다. 
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaTwo">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <span class="seper">예약</span> 
                            <span>소아, 유아의 나이 기준이 어떻게 되나요?</span>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="qnaTwo" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            유아(INF) : 만 2세(24개월) 미만으로 좌석을 점유하지 않으며 통상 성인의 10%의 상품요금이 적용됩니다. <br>
                            소아(CHD) : 만 2세(24개월) 이상, 만 12세 미만으로 상품에 따라 75~90%의 상품요금이 적용됩니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaThree">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <span class="seper">예약</span> 
                            <span>여행상품 예약 후 예약 확인은 어떻게 하나요?</span>
                          </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="qnaThree" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            예약이 완료된 후 고객님의 카카오톡으로 예약 완료 메시지가 발송됩니다.<br> 
                            예약 확정에 대한 여부는 담당자를 통해 안내해 드리고 있으며, 예약 내용은 [마이페이지]에서 확인하실 수 있습니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaFour">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <span class="seper">예약</span> 
                            <span>대기예약이 무엇인가요?</span>
                          </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="qnaFour" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            패키지여행 등 모객성 여행상품의 경우 여행을 확정할 수 있는 최소 인원이 정해져 있습니다.<br>
                            해당 인원이 충족되지 않은 경우 충족될 시점까지 예약을 진행하는 것이 대기예약이며 대기예약 중 취소 시 결제하신 금액은 전액 환불됩니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item" >
                        <h2 class="accordion-header" id="qnaFive">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <span class="seper">결제</span> 
                            <span>여행상품 예약시 결제는 어떻게 하나요?</span>
                          </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="qnaFive" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            여행상품 예약 후 영업일 기준 72시간 이내 담당자 확인 후 확정여부에 대해 전화로 연락을 드리고 있습니다. <br>
                            예약확정 시 무통장 입금 / 카카오페이 / 토스페이 세가지 수단으로 결제가 진행됩니다.<br>
                            <br>
                            ※ 무통장 입금 시 주의사항<br>
                            - 입금자 명은 실제 입금 시 표기되는 성명과 반드시 일체해야 합니다.<br>
                            - 입금시 여행 예약자와 상이 할 경우 담당자에게 반드시 알려주시기 바랍니다.<br>
                            - 담당자에게 안내된 결제 시한이 지날 경우 예약이 취소될 수 있습니다.<br>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaSix">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <span class="seper">예약</span> 
                            <span>예약 변경(여행일자, 상품, 인원 등)은 어떻게 하나요?</span>
                          </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="qnaSix" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            담당자에게 전화 혹은 카카오톡 메시지를 통해 변경 요청을 해주시기 바랍니다.<br>
                            상품에 따라 변경 가능 유무를 확인하여 답변드리겠습니다. <br>
                            일부 상품 및 변경 시점에 따라 항공권, 호텔 등에 대해 수수료가 발생할 수 있습니다.<br>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaSeven">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <span class="seper">예약</span> 
                            <span>예약 후 취소는 어떻게 하나요?</span>
                          </button>
                        </h2>
                        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="qnaSeven" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            담당자에게 전화 혹은 카카오톡 메시지를 통해 취소 요청 혹은 마이페이지에서 취소를 요청하실 수 있습니다.<br>
                            취소 시점에 따라 사전에 안내드린 취소수수료 규정에 의해 취소수수료가 발생할 수 있습니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaEight">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            <span class="seper">예약</span> 
                            <span>예약 취소 수수료는 얼마인가요?</span>
                          </button>
                        </h2>
                        <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="qnaEight" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            국외여행 표준약관을 준수하고 있으나 항공사 및 현지 시설에 따라 환불규정이 상이할 수 있으므로 특별약관이 적용될 수 있습니다.<br>
                            여행상품의 상세페이지를 통해 정확한 환불 규정을 확인하실 수 있으며 예약 시 필히 참고 부탁드립니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaEight">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            <span class="seper">취소</span> 
                            <span>취소 후 환불은 어떻게 받을 수 있나요?</span>
                          </button>
                        </h2>
                        <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="qnaEight" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            취소 후 환불규정에 따라 환불금액이 발생할 경우 결제하신 수단(카카오페이, 토스페이)을 통해 환불됩니다. <br>
                            결제 대행사의 처리 시간에 따라 2~3일 정도 소요될 수 있습니다. 이 점 양해 부탁드리겠습니다.<br>
                            무통장 입금의 경우 결제하신 고객 명의의 은행 계좌를 담당자에게 알려주시면 영업일 기준 24시간 이내 환불이 완료됩니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaTen">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            <span class="seper">포인트</span> 
                            <span>포인트는 어떻게 적립이 되나요? </span>
                          </button>
                        </h2>
                        <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="qnaTen" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            엔타비글로벌 홈페이지에서 상품을 구매하시면 여행 종료 후 7일 후 상품가의 1%가 자동 적립됩니다.<br>
                            포인트는 회원에게만 적립되며 유류세 및 공과금 등에 해당하는 세금에 대해서는 적립되지 않습니다.<br>
                            *이벤트 및 특정 상품에 따라 포인트 적립 %가 달라지거나 적립이 되지 않을 수 있습니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaTen">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEle" aria-expanded="false" aria-controls="collapseEle">
                            <span class="seper">포인트</span> 
                            <span>포인트는 어떻게 사용하나요?</span>
                          </button>
                        </h2>
                        <div id="collapseEle" class="accordion-collapse collapse" aria-labelledby="qnaTen" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            여행상품 예약 확정 후 결제 시 현금처럼 사용이 가능합니다. (예약금 및 위약금에 해당하는 금액은 사용불가)
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaTen">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwl" aria-expanded="false" aria-controls="collapseTwl">
                            <span class="seper">포인트</span> 
                            <span> 포인트의 유효기간은 어떻게 되나요?</span>
                          </button>
                        </h2>
                        <div id="collapseTwl" class="accordion-collapse collapse" aria-labelledby="qnaTen" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            포인트 적립 시점으로부터 3년간 유효하며 휴면 회원 전환 및 탈퇴 시 포인트는 즉시 소멸됩니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaTen">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreet" aria-expanded="false" aria-controls="collapseThreet">
                            <span class="seper">포인트</span> 
                            <span> 결제시 사용한 포인트도 취소 시 환불되나요? </span>
                          </button>
                        </h2>
                        <div id="collapseThreet" class="accordion-collapse collapse" aria-labelledby="qnaTen" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            사용하신 포인트도 동일하게 환불됩니다. 환불된 포인트의 유효기간은 사용전과 동일하게 적용됩니다.<br>
                            예약금 및 위약금에 해당하는 금액은 포인트로 사용할 수 없습니다.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="qnaTen">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourt" aria-expanded="false" aria-controls="collapseFourt">
                            <span class="seper">포인트</span> 
                            <span> 포인트의 양도나 합산이 가능한가요?</span>
                          </button>
                        </h2>
                        <div id="collapseFourt" class="accordion-collapse collapse" aria-labelledby="qnaTen" data-bs-parent="#accordionqua">
                          <div class="accordion-body text-start">
                            포인트는 회원명의의 본인 여행만 사용이 가능하며 대리인 여행 시 사용이 불가능합니다. <br>
                            가족에게 사용을 양도하실 경우 필요한 서류(가족관계증명서 등)이 필요할 수 있습니다.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- 버튼 -->
                <div class="page text-center">
                  <ul>
                    <li>
                      <a href="#"><i class="bi bi-chevron-bar-left"></i></a>
                    </li>
                    <li class="now"><a href="#">1</a></li>
                    <li>
                      <a href="#"><i class="bi bi-chevron-bar-right"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created(){
    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },  
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //비회원 접근 시 대응처리
    // if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '',
    //     text: '로그인 후 이용해주세요.',
    //     icon: 'warning'
    //   });
    //   this.$router.push({path: '/'});
    // }
  },  
  methods: {
    // goToHome() {
    //   this.$router.push({path: '/'});
    // }
  }
}
</script>

<style>
#customer{
  margin:4rem 0 8rem;
}
/* @media (max-width:992px){
  #customer{
    margin:0rem 0 4rem;
  }
} */

#customer h3{
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1.5rem;
  text-transform: uppercase;
  text-align: center;
}



/*--------------------------------------------------------------
# 빠른 메뉴
--------------------------------------------------------------*/
#customer .qu-menu{
  margin:2.5rem 0;
}
#customer .qu-menu .name{
  color:#000;
  font-size:1.1rem;
  font-weight: 600;
  margin-bottom:0
}
#customer .qu-menu img{
  width:90px;
  height:auto
}
@media (max-width:767px){
  #customer .qu-menu{
    margin:1.5rem 0;
  }
  #customer .qu-menu .name{
    font-size:1.05rem;
    margin-top:.5rem
  }
}



/*--------------------------------------------------------------
# 자주묻는 질문
--------------------------------------------------------------*/
#customer .question{
  margin-top:5rem
}
#customer .question h3{
  font-size:1.6rem;
  font-weight:bold;
  text-align: left;
}

#customer .question span{
  font-size:1.1rem;
  font-weight:bold;
  color:#000;
}
#customer .question .seper{
  width:7rem;
  font-size:1rem;
  font-weight: 300;
  color:#999;
  text-align: center;
}

#customer .question .accordion-body{
  padding:1rem 0 2rem 8rem
}
#customer .question .accordion{
  --bs-accordion-border-color: none;
  --bs-accordion-btn-padding-y: 1.7rem;
  --bs-accordion-active-color: #000;
  --bs-accordion-active-bg: #F5F5F5
}
#customer .question .accordion-item{
  border-bottom:1px solid #F5F5F5
}
#customer .question .accordion-item .accordion-header{
  line-height:25px
}

@media (max-width:991px){
  #customer .question span{
    font-size:.85rem;
    padding-right:10px;
    font-weight: 500;
  }
  #customer .question .seper{
    width:3rem;
    font-size:.75rem;
    text-align: left;
  }
  #customer .question .accordion-button{
    padding:10px 5px;
    height:4rem
  }
  #customer .question .accordion-body{
    padding:1rem 0 2rem .5rem;
    font-size:.8rem
  }
}



/* ----------- 버튼 ----------- */
#customer .page{
  margin-top:3rem
}
#customer .page ul li{
  display: inline-block;
  list-style: none;
  padding:0 10px
}
#customer .page ul li:hover a{
  color:#777
}
#customer .page ul .now{
  background-color: #000;
  border-radius: 50%;
  padding:3px 11px 4px
}
#customer .page ul .now a{
  color:#fff;
}
#customer .page ul .now:hover a{
  color:#fff
}
</style>
