<template>
  <main class="mt-3">
  <div class="pl-1 text-center">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center">
          <u>&nbsp;예 약 관 리&nbsp;</u>
        </h2>
        <div v-if="this.reserveInfo.resMFlag === 'N'" class="text-success">
          (비회원)
        </div>
        <div v-else-if="this.reserveInfo.memLevel === 'P'" style="color:goldenrod;">
          (프리미엄멤버십)
        </div>
        <div v-else-if="this.reserveInfo.memLevel === 'S'" style="color:royalblue;">
          (스탠다드멤버십)
        </div>        
          <div class="text-success text-start fs-6 mb-2">
            <b>[요청정보&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('U','R');">&nbsp;예약저장</i>&nbsp; ]</b>&nbsp;&nbsp;
            <button type="button" class="btn btn-sm btn-out
            line-primary align-right" @click="callWinCalc();">
              <i class="bi bi-calculator-fill">계산기이용</i>
            </button>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr style="text-align: center">
                <th class="table-secondary col-2">예약코드</th>
                <td class="col-4 text-primary">{{this.reqRsvCode}}</td>
                <th class="table-secondary col-2">상태</th>
                <td class="col-4">
                  <div class="input-group">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="this.reserveInfo.reqStatus" @change="changeProcCode(this.reserveInfo.reqStatus)">
                      <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{ rProcCode.procName }} </option>
                    </select>
                    <!-- 취소사유 -->
                    <select class="form-select form-select-sm" style="background-color:lightyellow;" aria-label=".form-select-sm" ref="sel_cxlFlag" v-model="this.reserveInfo.cxlFlag" @change="changeCxlCode(this.reserveInfo.cxlFlag)" v-if="this.reserveInfo.reqStatus === 'CX'">
                      <option :value="cx.procCode" :key=i v-for="(cx,i) in cxlFlagList">{{cx.procName}}</option>
                    </select>

                    <!-- <button type="button" class="btn btn-warning btn-sm me-1" @click="callKakao(rProcCode);">
                      <font style="font-size:small" class="align-bottom">카톡발송</font>
                    </button> -->
                    <button type="button" class="btn btn-success btn-sm me-1" @click="pSetAside();" v-if="reserveInfo.resMFlag != 'N' && reserveInfo.reqStatus === 'CO'">
                      <span style="font-size:small" class="align-bottom">포인트적립</span>
                    </button>
                  </div>

                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary"><i class="bi bi-check text-danger"></i>예약자/연락처</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" class="form-control form-control-sm" style="font-size: small" ref="txt_reqName" v-model="reserveInfo.reqName">
                    <input type="text" class="form-control form-control-sm" style="font-size: small" ref="txt_reqTelNo" v-model="reserveInfo.reqTelNo">
                    <input type="text" class="form-control form-control-sm" style="font-size: small" ref="txt_conEmail" v-model="reserveInfo.conEmail">
                  </div>
                </td>
                <th class="table-secondary"><i class="bi bi-check text-danger"></i>신청일</th>
                <td class="col-md-4">
                  <input type="date" class="form-control" ref="txt_reqDate" v-model="reserveInfo.reqDate" disabled>
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary"><i class="bi bi-check text-danger"></i>출발/여행지역</th>
                <td class="col-4">
                  <div class="input-group">
                    <select class="form-select form-select-sm text-primary" aria-label=".form-select-sm" ref="sel_ctCd" v-model="this.productInfo.ctCd" disabled>
                      <option :value="cityList[i].baseCode" :key=i v-for="(baseCode,i) in cityList">{{cityList[i].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ntCd" disabled>
                      <option :value="this.productInfo.ntCd">{{this.productInfo.ntName}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_arCd" disabled>
                      <option :value="this.productInfo.arCd">{{this.productInfo.arName}}</option>
                    </select>
                  </div>
                </td>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>여정기간</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">출국</span>
                    <input type="date" class="form-control" style="font-size:small" ref="txt_dptDate" v-model="reserveInfo.reqDptDate" readonly>
                    <span class="input-group-text" id="basic-addon1">귀국</span>
                    <input type="date" class="form-control" style="font-size:small" ref="txt_rtnDate" v-model="reserveInfo.reqRtnDate" readonly>
                  </div>                  
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary col-md-2">출발불가일(공시)</th>
                <td class="col-md-4">
                  <textarea class="form-control text-danger" placeholder="(예시) 2022-12-25, 2022-12-26, 매주 화요일" ref="txt_unableDpDate" id="floatingTextarea" style="height:80px; font-size:smaller;" 
                    v-model="productInfo.unableDpDate" maxlength="200" disabled></textarea>
                </td>
                <th class="table-secondary col-md-2">상품문의</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px; font-size:smaller;" v-model="reserveInfo.reqRemarks" maxlength="200" readonly></textarea>
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary" rowspan="3"><i class="bi bi-check text-danger"></i>권종구성 ({{this.reserveInfo.reqTripProp}})
                  <p class="text-secondary text-sm-center">(금액X인원)</p>
                  <p style="font-size:small; color:red">※ 권종과 옵션의 인원수 개별변경불가</p>
                  <p :key="j" v-for="(pN,j) in this.pdtPriceNameInfo" style="font-size: smaller; color:darkgray">{{ pN.pdtPriceName }}</p>
                </th>
                <td class="col-md-4 text-start" rowspan="3" v-if="this.reserveInfo.reqTripProp === 'D'">
                  <div class="text-success">
                    <i class="bi bi-align-end text-primary"></i> {{this.reserveInfo.reqDptDate}} ~ {{this.reserveInfo.reqRtnDate}}
                  </div>
                  <div class="input-group" :key=i v-for="(ri, i) in this.reservePriceInfo">
                    <div class="input-group mb-1">
                      <span class="input-group-text">성인</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_aduPrice" v-model="ri.reqAdultPrice" disabled>
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" style="font-size: small" ref="txt_aduCnt" v-model="reserveInfo.reqAdultCnt"  disabled>
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totAduPrice" :value="getCurrencyFormat(ri.reqAdultPrice * reserveInfo.reqAdultCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totAduPrice" :value="ri.reqAdultPrice * reserveInfo.reqAdultCnt" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">소아</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_chiPrice" v-model="ri.reqChildPrice" disabled>
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_chiCnt" v-model="reserveInfo.reqChildCnt" disabled>
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totChiPrice" :value="this.getCurrencyFormat(ri.reqChildPrice * reserveInfo.reqChildCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totChiPrice" :value="ri.reqChildPrice * reserveInfo.reqChildCnt" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">유아</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_infPrice" v-model="ri.reqInfPrice" disabled>
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_infCnt" v-model="reserveInfo.reqInfCnt" disabled>
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totInfPrice" :value="getCurrencyFormat(ri.reqInfPrice * reserveInfo.reqInfCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totInfPrice" :value="ri.reqInfPrice * reserveInfo.reqInfCnt" disabled>
                    </div>
                  </div>
                </td>
                <td class="col-md-4" rowspan="3" v-else>
                  <div class="input-group" :key=i v-for=" (rPrInfo, i) in this.reservePriceInfo">
                    <div class="text-success">
                      <i class="bi bi-align-end text-primary"></i> {{this.getDateFormat(rPrInfo.reqDptDate)}} -------------
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">성인</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_aduPrice" v-model="rPrInfo.reqAdultPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_aduCnt" v-model="reserveInfo.reqAdultCnt">
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" :value="getCurrencyFormat(rPrInfo.reqAdultPrice * reserveInfo.reqAdultCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" :value="rPrInfo.reqAdultPrice * reserveInfo.reqAdultCnt" name="fAduPrice[]" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">소아</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_chiPrice" v-model="rPrInfo.reqChildPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_chiCnt" v-model="reserveInfo.reqChildCnt">
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totChiPrice" :value="getCurrencyFormat(rPrInfo.reqChildPrice * reserveInfo.reqChildCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totChiPrice" :value="rPrInfo.reqChildPrice * reserveInfo.reqChildCnt" name="fChiPrice[]" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">유아</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_infPrice" v-model="rPrInfo.reqInfPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_infCnt" v-model="reserveInfo.reqInfCnt">
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totInfPrice" :value="getCurrencyFormat(rPrInfo.reqInfPrice * reserveInfo.reqInfCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totInfPrice" :value="rPrInfo.reqInfPrice * reserveInfo.reqInfCnt" name="fInfPrice[]" disabled>
                    </div>
                  </div>
                </td>

                <th class="table-secondary col-md-2 align-middle">
                  <!-- 적용 프로모션
                  <br>
                  <small class="text-secondary">(출발일기준)</small> -->
                </th>
                <td class="col-md-4 text-start small">
                  <!-- - {{this.pdtPromoInfo_apply.promoName}}
                  <br>
                  - {{this.pdtPromoInfo_apply.promoStart}} ~ {{this.pdtPromoInfo_apply.promoEnd}}
                  <br>
                  - <span class="text-danger">{{getCurrencyFormat(this.pdtPromoInfo_apply.promoRate)}}</span><small>({{this.pdtPromoInfo_apply.pMethod}})</small> -->
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary col-mb-2">
                  <!-- 터미널이용료 (미사용)&nbsp;
                  <button type="button" class="btn btn-primary btn-sm me-1 align-text-middle" @click="getPdtTerminalFee_apply();" disabled>
                    <font style="font-size:small" class="align-bottom"><small>확인</small></font>
                  </button>
                  <br>
                  <small class="text-secondary">(여정기준)</small> -->
                </th>
                <td class="col-md-4 text-start small">
                  <!-- - &nbsp;출발&nbsp;<br>
                  &nbsp;&nbsp;{{this.dTerm}}&nbsp;(
                  <font class="text-secondary" :key=i v-for=" (agegroup,i) in this.pdtTermFeeInfo_dp_apply">
                    &nbsp;{{getCurrencyFormat(this.pdtTermFeeInfo_dp_apply[i].ageFee)}}/</font>){{this.dCur}}
                  <br>
                  - &nbsp;리턴&nbsp;<br>
                  &nbsp;&nbsp;{{this.rTerm}}&nbsp;(
                  <font class="text-secondary" :key=i v-for=" (agegroup,i) in this.pdtTermFeeInfo_rt_apply">
                    &nbsp;{{getCurrencyFormat(this.pdtTermFeeInfo_rt_apply[i].ageFee)}}/</font>){{this.rCur}} -->
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary col-md-2">
                  <!-- 유류할증료 (미사용)&nbsp;
                  <button type="button" class="btn btn-primary btn-sm me-1 align-text-middle" @click="getPdtOilSurcharge_apply();" disabled>
                    <font style="font-size:small" class="align-bottom"><small>확인</small></font>
                  </button>
                  <br>
                  <small class="text-secondary">(여정기준)</small> -->
                </th>
                <td class="col-md-4 text-start small">
                  <!-- - 출발편&nbsp;
                    ({{this.oilSCDp_apply.mbName}} / {{getCurrencyFormat(this.oilSCDp_apply.ageFee)}}{{this.oilSCDp_apply.curName}})
                  <br>
                  - 리턴편&nbsp;
                    ({{this.oilSCRt_apply.mbName}} / {{getCurrencyFormat(this.oilSCRt_apply.ageFee)}}{{this.oilSCRt_apply.curName}}) -->
                </td>
              </tr>


              <tr style="text-align: center">
                <th class="table-secondary col-md-2" rowspan="3">
                  선택옵션 & 추가상품
                </th>

                <td class="text-secondary text-start col-md-4" rowspan="3" style="vertical-align: top; padding-top: 10px;">
                  <!-- 선택된 옵션 -->
                  <div class="mb-3">
                    <div class="text-decoration-underline text-primary">
                      <b class="text-primary">선택된 옵션
                      ({{ this.getCurrencyFormat(selOptPriceTot) }}원)
                      </b>
                    </div>
                    <div>
                      {{ this.optName }}
                      <span class="text-primary"> : {{ getCurrencyFormat(selOptPriceTot) }} 원</span>
                    </div>
                    <!-- <div v-if="this.selOptions.length <=0"><i class="bi bi-cart text-primary"></i> 선택된 옵션이 없습니다.</div>
                    <div v-else :key=i v-for="(eo, i) in this.selOptions">
                      <i class="bi bi-cart-fill text-primary"></i> {{eo.optPdtName}}
                      <span class="text-primary"> : {{ getCurrencyFormat(selOptPriceTot[i]) }} 원</span>
                    </div> -->
                    <hr>
                  </div>

                  <div class="mt-4 text-decoration-underline text-info"><b class="text-info">선택된 추가상품  ({{ this.getCurrencyFormat(tot_selAddPdtPrice) }}원)</b></div>
                  <div v-if="this.selAddPdt.length <=0"><i class="bi bi-cart text-info"></i> 선택된 추가상품이 없습니다.</div>
                  <div v-else :key=i v-for="(ea, i) in this.selAddPdt">
                    <i class="bi bi-cart-fill text-info"></i> {{ea.addPdtName}} : <i></i>&nbsp;<span style="color:darkgray">
                      &nbsp;{{ ea.qty_addPdt }} 개 ( <b class="text-info">{{ this.getCurrencyFormat((ea.aduPdtPrice * ea.qty_addPdt)) }}</b> 원 )
                    </span>
                  </div>
                  <hr>
                  <div class="text-end text-danger" style="font-weight: 800; padding-right:8px;">{{ this.getCurrencyFormat(selOptPriceTot_tot + tot_selAddPdtPrice) }} 원</div>
                </td>

                <th class="table-secondary col-md-2">
                  <!-- 선택옵션 설정
                  <P>
                    <button type="button" class="btn btn-primary btn-sm me-1" @click="changeEtcOpt();">
                      <span style="font-size:small" class="align-bottom">설정변경</span>
                    </button>
                  </P> -->
                </th>
                <td class="col-md-4 text-start">
                  <!-- 예약시 선택된 옵션+필수옵션 & 선택가능한 옵션 -->
                  <!-- <div :key=i v-for="(eo, i) in this.etcOptionInfo">
                    <input class="form-check-input my-auto align-middle" type="checkbox" :id="i" v-model="eo.reqOptFlag" true-value="Y" false-value="N" name="checkedBox" v-if="eo.reqOptFlag_ex === 'Y'">
                    <input class="form-check-input my-auto align-middle" type="checkbox" :id="i" v-model="eo.reqOptFlag" true-value="Y" false-value="N" name="checkedBox" v-else>
                    &nbsp;
                    {{eo.etcOptName}} : &nbsp;<font style="color:darkgray">({{ this.getCurrencyFormat(eo.etcOptPrice) }} / {{ this.getCurrencyFormat(eo.etcChild) }} / {{ this.getCurrencyFormat(eo.etcInf) }} )원</font>
                  </div> -->
                </td>
              </tr>
              
              <tr style="text-align: center">
                <th class="table-secondary col-md-2">
                  <!-- 옵션적용일
                  <P>
                    <button type="button" class="btn btn-primary btn-sm me-1" @click="changeEtcOptApply();">
                      <span style="font-size:small" class="align-bottom">적용일설정</span>
                    </button>   
                  </P>
                  <p style="font-size: small; color: red">
                    (선택옵션 변경이 있는 경우, 설정변경을 먼저 한 후 진행하세요)
                  </p> -->
                </th>
                <td class="col-md-4 text-start">
                  <!-- <div v-if="this.selectedEtcOption.length > 0">
                    <div class="text-start text-secondary">----------------- 적용일 설정<i class="bi bi-caret-down-fill"></i></div>
                    <div class="text-start" :key=j v-for="(sApplyDate,j) in this.selectedEtcOptApplyDate">
                      <i class="bi bi-calendar-event"></i> {{this.getDateFormat(sApplyDate.applyDate)}}
                      <input class="form-check-input my-auto align-middle" type="checkbox" true-value="Y" false-value="N" name="checkedApply" :id="j" v-model="sApplyDate.applyFlag" v-if="sApplyDate.reqOptFlag == 'Y'"> 
                      <input class="form-check-input my-auto align-middle" type="checkbox" true-value="Y" false-value="N" name="checkedApply" :id="j" v-model="sApplyDate.applyFlag" v-else>
                      {{sApplyDate.etcOptName}}
                    </div>
                  </div> -->
                </td>
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary col-md-2">
                  할인정보
                </th>
                <td class="col-md-4 text-start">
                  <div v-if="reserveInfo.memLevel === 'S' || reserveInfo.memLevel === 'P'">
                    <div v-if="reserveInfo.dispFlag === 'c1' || reserveInfo.dispFlag === 'c3'">
                      <div class="text-start mt-1" v-if="reserveInfo.memLevel === 'P'" style="color:goldenrod">----------------- 프리미엄환급</div>
                      <div class="text-start mt-1" v-else-if="reserveInfo.memLevel === 'S'" style="color:royalblue;">----------------- 스탠다드환급</div>
                      &nbsp;&nbsp;{{ getCurrencyFormat(this.totRefundAmt + selOptPriceRef_tot) }}원                      
                    </div>
                    <div v-if="reserveInfo.dispFlag === 'c2' || reserveInfo.dispFlag === 'c4' || reserveInfo.dispFlag === 'c5'">
                      <div class="text-start mt-1" v-if="reserveInfo.memLevel === 'P'" style="color:goldenrod">----------------- 프리미엄할인</div>
                      <div class="text-start mt-1" v-else-if="reserveInfo.memLevel === 'S'" style="color:royalblue;">----------------- 스탠다드할인</div>
                      &nbsp;&nbsp;{{ getCurrencyFormat(this.totDiscountAmt + selOPtPriceDCTot_self) }}원
                    </div>
                  </div>

                  <div v-if="reserveInfo.tot_comDC > 0">
                    <div class="text-start mt-1" style="color:slategray">----------------- 멤버십 동반자할인</div>
                    &nbsp;&nbsp;{{ getCurrencyFormat(this.reserveInfo.tot_comDC + this.selOPtPriceDCTot_exSelf) }}원
                  </div>
                  
                  <div>
                    <div class="text-start text-success mt-3">----------------- 특 별 할 인</div>
                    <div class="input-group">
                      <input type="number" class="form-control text-end" ref="txt_optDcPrice" v-model="this.reserveInfo.optDcPrice">
                      <span class="input-group-text">원</span>
                    </div>
                  </div>
                </td>
                
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary col-md-2">
                  <!-- <i class="bi bi-check text-danger"></i>상품마진 (미사용)
                  <div><small class="text-danger">기본마진과 다를 수 있음</small></div> -->
                  <div>적립포인트 <span style="font-size:small; color:blue;">(비회원제외)</span></div>
                  <div><small class="text-danger">(멤버십은 추가적립포함)</small></div>
                </th>
                <td class="col-md-4">
                  <!-- <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_basicMargin" v-model="this.reqMarginRate" disabled>
                    <select class="form-select form-select-md" aria-label=".form-select-md" v-model="this.reqMarginMethod" disabled>
                      <option value="A" id="promoMethod" selected>원</option>
                      <option value="P" id="promoMethod">%</option>
                    </select>
                  </div> -->
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="rePoint" v-model="reserveInfo.rePoint">
                    <select class="form-select form-select-md" aria-label=".form-select-md" v-model="reserveInfo.rePointMethod">
                      <option value="A" id="rePointMethod" selected>원</option>
                      <option value="P" id="rePointMethod">%</option>
                    </select>
                  </div>                  
                </td>
                <th class="table-secondary col-md-2">
                  <button type="button" class="btn btn-primary btn-sm me-1" @click="calcCstPrice();">
                    <span style="font-size:small" class="align-bottom">상품가격계산</span>
                  </button>
                  <p>
                    <small><small class="text-secondary align-bottom">유류할증/터미널이용 불포함</small></small>
                    <br>
                    <small><small class="text-secondary">(소숫점아래 절사)</small></small>
                  </p>
                </th>
                <td class="col-md-4">
                  <div class="input-group">
                    <div class="text-secondary" v-if="this.reserveInfo.cstPrice_org == this.reserveInfo.cstPrice_new">
                      * 계산전: {{getCurrencyFormat(this.reserveInfo.cstPrice_org)}}
                    </div>
                    <div class="text-secondary" v-else>
                      * 계산전: {{getCurrencyFormat(this.reserveInfo.cstPrice_new)}}
                    </div>                    
                  </div>                  
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_cstPrice" v-model="this.cstPrice" readonly>
                    <span class="input-group-text">원</span>
                  </div>
                  <div class="text-start">
                    &nbsp;<small><small class="text-secondary">((권종가격*마진-멤버십할인)+옵션-(결제금액프로모션액))-포인트  + 추가상품가격</small></small>
                  </div>
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary col-md-2">
                  <!-- 부가세 <small>(0~10%)</small> -->
                </th>
                <td class="col-md-4">
                  <!-- <div class="input-group">
                    <input type="number" max="10" min="0" class="form-control text-end" ref="txt_vatPrice" v-model="this.reserveInfo.reqVatPrice">
                    <span class="input-group-text">%</span>
                  </div> -->
                </td>
                <!-- <th scope="row" class="table-secondary col-md-1">키워드</th>
                <td class="col-md-4"><input type="text" class="form-control" v-model="productInfo.pdtTopic"></td> -->
                <th class="table-secondary col-md-2">
                  가용포인트
                </th>
                <td class="col-md-4">
                  <div class="input-group" v-if="this.reserveInfo.payPoint > 0">
                    {{this.getCurrencyFormat(this.availPoint)}} P중
                    ({{this.getCurrencyFormat(this.reserveInfo.payPoint)}} P)사용
                  </div>
                  <div class="input-group" v-else>
                    <input type="number" class="form-control text-end" ref="txt_userPoint" v-model="this.availPoint" readonly>
                    <!-- <button type="button" class="btn btn-primary btn-sm me-1" @click="payByPoint();">
                      <font style="font-size:small" class="align-bottom">사용</font>
                    </button> -->
                  </div>
                  <div class="text-start">
                    <small class="text-danger">포인트 사용은 고객결제시 가능</small>
                  </div>
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary col-md-2">기존업무코멘트</th>
                <td class="col-md-4">
                  <textarea class="form-control" ref="txt_orgComment" id="floatingTextarea" style="height:110px; font-size:smaller;" 
                    v-model="this.reserveInfo.oldComment" maxlength="1000" disabled></textarea>
                </td>
                <th class="table-secondary col-md-2">업무코멘트</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="250자 이내" ref="txt_newComment" id="floatingTextarea" style="height:110px; font-size:smaller;" 
                    v-model="this.reserveInfo.newComment" maxlength="250"></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2" v-if="this.reserveInfo.reqStatus == 'PY' || (this.reserveInfo.reqStatus == 'RP' && this.userPayInfo.padiId != '') || this.reserveInfo.reqStatus == 'CO' || this.reserveInfo.reqStatus == 'CX' || this.reserveInfo.reqStatus == 'WP'">
            <b>[결제내역]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center"  v-if="this.reserveInfo.reqStatus == 'PY' || (this.reserveInfo.reqStatus == 'RP' && this.userPayInfo.padiId != '') || this.reserveInfo.reqStatus == 'CO' || this.reserveInfo.reqStatus == 'CX' || this.reserveInfo.reqStatus == 'WP'">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">
                  결제내역
                </th>
                <td class="col-md-3">
                  <div class="text-start">
                    - 대금총액: {{ getCurrencyFormat(reserveInfo.cstPrice_new) }}
                    <br>
                    - 청구액: {{ getCurrencyFormat(this.userPayInfo.reqPayAmount) }}
                    <span></span>
                    <hr>
                      <p style="padding-left: 2rem;">- 실결제액({{ this.userPayInfo.fName }}): {{ getCurrencyFormat(this.userPayInfo.actualPayAmount) }}</p>
                      <p style="padding-left: 2rem;">- 포인트결제: {{ getCurrencyFormat(this.payPointAmt) }}</p>
                  </div>
                </td>
                <td class="col-md-3" v-if="userPayInfo.reqTotPayAmt > userPayInfo.actualPayAmount">
                  <div class="text-start">
                    - 잔여대금: {{ getCurrencyFormat(balance) }}
                    <span class="text-danger" v-if="userPayInfo.reqPayBalance === 'Y' && userPayInfo.reqTotPayAmt  != userPayInfo.actualPayAmount">(잔여대금 청구 중)</span>
                    <hr>
                    <center><button class="btn btn-success mt-3" @click="payBalance()">잔여대금청구</button></center>
                  </div>
                </td>
                <th class="table-secondary col-md-2">
                  히스토리
                  <p v-if="totalPay <= 0" style="font-size:small; color:red;">2024-05-13 이전결제는 히스토리 제공X</p>
                </th>
                <td class="col-md-3">
                  <div class="text-start">
                    <p v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                      <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                      <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                      <span v-else></span>
                    </p>
                    <p v-else-if="fPayFlag === 'Y' && p1PayAmt > 0"> 
                      <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                      <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                      <span v-else></span>
                    </p>

                    <p  v-if="payPointAmt != '' && payPointAmt > 0"> 
                      <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                      <span v-if="payPointDate != ''" style="font-size:small;"> ( {{ payPointDate }} )</span>
                      <span v-else></span>
                    </p>

                    <p  v-if="p2_paidAmt != '' && p2PayAmt > 0"> 
                      <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                      <span v-if="p2PayDate != ''" style="font-size:small;"> ( {{ p2PayDate }} )</span>
                      <span v-else></span>
                    </p>
                    <hr>
                    <span style="font-weight:600; padding-left: 50%;" v-if="this.totalPay > 0">합계: {{ getCurrencyFormat(this.totalPay) }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>[인적사항&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('U','P');">&nbsp;저장</i> ]</b>&nbsp;
            <i class="bi bi-cone-striped text-danger">정확한 작업을 위해, 요청정보의 예약저장을 우선 진행하세요</i> 
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>
                  인원정보
                  <p class="mt-2">
                    <button type="button" class="btn btn-sm btn-outline-primary align-right">
                    <i class="bi bi-person-plus-fill" @click="addPniInfo()"> 인원추가</i>
                    </button>
                    <br>
                    <small class="text-danger">상담가격계산 수행 후 진행</small>
                  </p>
                </th>
                <td class="col-md-10" colspan="3">
                  <div class="mt-0 px-1 mb-2 text-start text-success">
                    ※ 여행자보험은 주민번호필요
                    <!-- <p><small class="text-secondary text-danger">(인적사항저장 후, 여권등록)</small></p> -->
                  </div>
                  <div class="input-group mb-1" :key=i v-for="(pList, i) in this.pniList">
                    <!-- <input type="text" class="form-control" ref="txt_pniAduPassNo" v-model="pList.pniUseFlag" placeholder="사용여부"> -->
                    <input class="form-check-input my-auto align-middle" type="checkbox" true-value="Y" false-value="N" v-model="pList.pniUseFlag">&nbsp;
                    <input type="hidden" class="form-control text-success" ref="txt_pniIndex" name="pniIndex" :value=i readonly>

                    <input type="text" class="form-control text-success" ref="txt_passNameKor" v-model="pList.passNameKor" placeholder="국문명">
                    
                    <input type="text" class="form-control" ref="txt_passNameEng" v-model="pList.passNameEng" placeholder="영문명">

                    <input type="text" class="form-control" maxlength="11" ref="txt_pniTel" v-model="pList.pniTel" placeholder="연락처">

                    <input type="date" class="form-control" ref="txt_passBirthDate" v-model="pList.pBirthDate" placeholder="생년월일">
 
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="pList.priceType">
                      <option :value=noVal disabled>{{pList.priceType}}</option>
                      <option value="A" id="ageGroupA">성인</option>
                      <option value="C" id="ageGroupC">소아</option>
                      <option value="I" id="ageGroupI">유아</option>
                      <option value="D" id="ageGroupD">할인</option>
                    </select>

                    <input type="text" class="form-control" ref="txt_passLicenseNo" maxlength="13" v-model="pList.passLicenseNo" placeholder="주민번호">

                    <input type="text" class="form-control" ref="txt_passNo" v-model="pList.passNo" placeholder="여권번호">

                    <input type="date" class="form-control" ref="txt_passExpDate" v-model="pList.pExpDate">&nbsp;

                    <div class="form-check form-switch align-bottom">
                      <input class="form-check-input" type="checkbox" role="switch" ref="txt_passFlag" v-model="pList.passFlag" true-value="D" false-value="S" checked>
                      <label class="form-check-label text-small" for="txt_pniAduPassFlag"><small>복수</small></label>
                    </div>&nbsp;
                    
                    <div>
                      <button type="button" class="btn btn-sm btn-success align-top" @click="passImgUp(pList.passNo)">여권</button>
                    </div>
                  
                  </div>
                  <div class="input-group mb-1" :key=pni-1 v-for="pni in this.vCnt">
                    <div class="input-group mb-1">
                      <div class="align-self-center col-md-1" v-if="this.vCnt === pni">  
                        <i class="bi bi-dash-circle-fill text-danger" @click="removePniInfo([this.pniLength*1+pni*1-1])"></i>
                      </div>
                      <input type="hidden" class="form-control text-success" ref="txt_pniIndex" name="pniIndex" size="1" :value=this.pniLength*1+pni*1-1 readonly>

                      <input type="text" class="form-control text-success" ref="txt_pniAduKor" v-model="this.passNameKor[this.pniLength*1+pni*1-1]" :id=[this.pniLength*1+pni*1-1] placeholder="국문명">

                      <input type="text" class="form-control" ref="txt_pniAduEng" v-model="this.passNameEng[this.pniLength*1+pni*1-1]" placeholder="영문명" :id=[this.pniLength*1+pni*1-1]>

                      <input type="text" class="form-control text-success" maxlength="11" ref="txt_pniAduKor" v-model="this.pniTel[this.pniLength*1+pni*1-1]" placeholder="연락처">

                      <input type="date" class="form-control" ref="txt_pniAduBirth" v-model="this.pBirthDate[this.pniLength*1+pni*1-1]" placeholder="생년월일">

                      <select class="form-select form-select-md" aria-label=".form-select-md" v-model="this.priceType[this.pniLength*1+pni*1-1]">
                        <option value="A" id="ageGroupA">성인</option>
                        <option value="C" id="ageGroupC">소아</option>
                        <option value="I" id="ageGroupI">유아</option>
                        <option value="D" id="ageGroupD">할인</option>
                      </select>

                      <input type="text" class="form-control" ref="txt_pniAduLcNo" maxlength="13" v-model="this.passLicenseNo[this.pniLength*1+pni*1-1]" placeholder="주민번호">

                      <input type="text" class="form-control" ref="txt_pniAduPassNo" v-model="this.passNo[this.pniLength*1+pni*1-1]" placeholder="여권번호">

                      <input type="date" class="form-control" ref="txt_pniAduPassExp" v-model="this.pExpDate[this.pniLength*1+pni*1-1]">&nbsp;
                      
                      <div class="form-check form-switch align-bottom">
                      <input class="form-check-input" type="checkbox" role="switch" ref="txt_pniAduPassFlag" v-model="this.passFlag[this.pniLength*1+pni*1-1]" true-value="D" false-value="S" checked>
                      <label class="form-check-label text-small" for="txt_pniAduPassFlag"><small>복수</small></label>
                      </div>&nbsp;

                      <div>
                        <button type="button" class="btn btn-sm btn-outline-success align-top" @click="passImgUp(this.passNo[this.pniLength*1+pni*1-1])">여권</button>
                      </div>
                    </div>
                  </div>                  
                </td>
              </tr>

              <tr>
                <th class="table-secondary col-md-2">여권사본</th>
                <td class="col-md-10" colspan="3">
                  <div v-if="this.passUpFlag === 'Y'">
                    <label class="col-md-3 col-form-label">[ {{this.enteredPassNo}} ] 여권사본</label>
                    <div class="col-md-9">
                      <div class="form-control" type="file" accept="image/jpg">
                        <div class="position-relative">
                          <img :src="`/download/passport/${this.reqRsvCode}/${this.enteredPassNo}.jpg`" class="img-fluid" />
                        </div>
                        <input type="file" class="form-control" accept="image/png,image/jpeg" @change="uploadFile($event.target.files, this.reqRsvCode, this.enteredPassNo)">
                      </div>
                      <div class="alert alert-secondary" role="alert" style="font-size:small;">
                        <span>File 확장자: jpg</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <th class="table-secondary col-md-2">기존내용</th>
                <td class="col-md-4">
                  <textarea class="form-control" ref="txt_pniRemarks" id="floatingTextarea" style="height:110px; font-size:smaller;" 
                    v-model="this.reserveInfo.pniRemarks" maxlength="1000" disabled></textarea>
                </td>
                <th class="table-secondary col-md-2">
                  신규특기사항
                  <p class="mt-2">
                    <button type="button" class="btn btn-sm btn-outline-primary align-right">
                      <i class="bi bi-save-fill" @click="spRemarkSave()"> 내용저장</i>
                    </button>
                  </p>
                </th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="250자 이내" ref="txt_nPniRemarks" id="floatingTextarea" style="height:110px; font-size:smaller;" 
                    v-model="this.reserveInfo.nPniRemarks" maxlength="250"></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
              <b>[상품정보]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">상품코드</th>
                <td class="col-md-4 text-primary">{{this.productCode}}</td>
                <th scope="row" class="table-secondary col-md-1">상품(속성)</th>
                <td class="col-md-4">
                  <div class="form-check-inline small" :key=i v-for="(baseCode,i) in this.sRoute">
                    <div v-if="this.sRoute[i].baseCode === this.salesRoute">
                      <label class="form-check-label text-danger" role="switch" for="flexRadioDefault1">
                        <b>{{ this.sRoute[i].codeNameKor }}</b>
                      </label>
                    </div>
                    <div v-else>
                      <label class="form-check-label text-secondary" for="flexRadioDefault1">
                        {{ this.sRoute[i].codeNameKor }}
                      </label>
                    </div>
                  </div>
                  &nbsp;&nbsp;(
                  <span v-if="productInfo.tripProp === 'F'" class="text-info"><b><i>{{productInfo.tripProp}}</i></b></span>
                  <span v-if="productInfo.tripProp === 'D'" class="text-warning"><b><i>{{productInfo.tripProp}}</i></b></span>
                  )
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>상품(국문)</th>
                <td class="col-md-4"><input type="text" class="form-control" ref="txt_pdtNameKor" style="font-size:smaller;"  v-model="productInfo.pdtNameKor" readonly></td>
                <th class="table-secondary col-md-2">상품(영문)</th>
                <td class="col-md-4"><input type="text" class="form-control" ref="txt_pdtNameKor" style="font-size:smaller;" v-model="productInfo.pdtNameEng" readonly></td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>출발/여행국</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" class="form-control text-success" ref="txt_ctCd" v-model="productInfo.ctName" readonly>
                    <input type="text" class="form-control" ref="txt_ntCd" v-model="productInfo.ntName" readonly>
                  </div>
                </td>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>지역/체류일</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" class="form-control" ref="txt_arCd" v-model="productInfo.arName" readonly>
                    <input type="number" min=0 class="form-control text-end text-small" ref="txt_stayCnt" v-model="productInfo.stayCnt" readonly>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>여행/유효기간</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">개시</span>
                    <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.tripStart" readonly>
                    <span class="input-group-text" id="basic-addon1">종료</span>
                    <input type="date" class="form-control" ref="txt_salesEnd" v-model="productInfo.tripEnd" readonly>
                  </div>
                </td>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>판매기간</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">개시</span>
                    <input type="date" class="form-control" ref="txt_salesEnd" v-model="productInfo.salesStart" readonly>
                    <span class="input-group-text" id="basic-addon1">종료</span>
                    <input type="date" class="form-control" ref="txt_salesEnd" v-model="productInfo.salesEnd" readonly>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">명목가격</th>
                <td class="col-md-4">

                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_unitPrice" v-model="productInfo.nominalAmt" readonly>
                    <span class="input-group-text">원</span>
                  </div>
                </td>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>기본마진</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_basicMargin" v-model="productInfo.basicMargin" readonly>
                    <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productInfo.marginMethod" readonly>
                      <option value="A" id="promoMethod" selected>원</option>
                      <option value="P" id="promoMethod">%</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">부가세</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" class="form-control text-end" ref="txt_vatPrice" v-model="productInfo.vatPrice" readonly>
                    <span class="input-group-text">%</span>
                  </div>
                </td>
                <!-- <th scope="row" class="table-secondary col-md-1">키워드</th>
                <td class="col-md-4"><input type="text" class="form-control" v-model="productInfo.pdtTopic"></td> -->
                <th class="table-secondary col-md-2">제공포인트</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_userPoint" v-model="productInfo.userPoint" readonly>
                    <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productInfo.userPointMethod" readonly>
                      <option value="A" id="userPointMethod" selected>점</option>
                      <option value="P" id="userPointMethod">%</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">출발불가일</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="(예시) 2022-12-25, 2022-12-26, 매주 화요일" ref="txt_unableDpDate" id="floatingTextarea" style="height:110px; font-size:smaller;" 
                    v-model="productInfo.unableDpDate" maxlength="200" readonly></textarea>
                </td>
                <th class="table-secondary col-md-2">
                  특기사항
                </th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:110px; font-size:smaller;" v-model="productInfo.pdtRemarks" maxlength="200" readonly></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
              <b>[교통정보]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">출발&nbsp;<i class="bi bi-arrow-right-circle"></i>&nbsp;도착</th>
                <td class="col-md-4">
                  한국({{this.pdtTransInfo1.ddCodeName}}) → {{this.productInfo.ntName}}({{this.pdtTransInfo1.daCodeName}})
                </td>
                <th class="table-secondary col-md-2">리턴&nbsp;<i class="bi bi-arrow-right-circle"></i>&nbsp;도착</th>
                <td class="col-md-4">
                  {{this.productInfo.ntName}}({{this.pdtTransInfo1.rdCodeName}}) → 한국({{this.pdtTransInfo1.raCodeName}})
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">출발편</th>
                <td class="col-md-4">
                  {{this.pdtTransInfo1.dmobileCodeName}}
                  <small>
                    ({{this.pdtTransInfo1.dmobileCode}}{{this.pdtTransInfo1.dpTpCode}},&nbsp;{{this.pdtTransInfo1.d_dpTime}} → {{this.pdtTransInfo1.d_arTime}})
                  </small>
                </td>
                <th class="table-secondary col-md-2">리턴편</th>
                <td class="col-md-4">
                  {{this.pdtTransInfo1.rmobileCodeName}}
                  <small>
                    ({{this.pdtTransInfo1.rmobileCode}}{{this.pdtTransInfo1.rtTpcode}},&nbsp;{{this.pdtTransInfo1.r_dpTime}} → {{this.pdtTransInfo1.r_arTime}})
                  </small>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">추가사항</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px; font-size:smaller;" v-model="this.pdtTransInfo1.transRemarks_dp" maxlength="300" readonly></textarea>
                </td>
                <th class="table-secondary col-md-2">추가사항</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px; font-size:smaller;" 
                    v-model="this.pdtTransInfo1.transRemarks_ar" maxlength="300" readonly></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
              <b>[수량정보]</b> <i class="bi bi-exclamation-triangle-fill"></i>자동판매時 주의              
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">수량현황</th>
                <td class="col-md-4 text-start" colspan="3">
                  <span class="text-primary"><b>수량관리({{this.salesVolumeInfo.autoSales}})</b>&nbsp;&nbsp;&nbsp;</span>
                  기초수량({{getCurrencyFormat(this.salesVolumeInfo.pdtQty)}})&nbsp;&nbsp;|&nbsp;&nbsp;
                  예약수량({{getCurrencyFormat(this.salesVolumeInfo.bookingFigure)}})&nbsp;&nbsp;|&nbsp;&nbsp;
                  확정수량({{getCurrencyFormat(this.salesVolumeInfo.soldFigure)}})&nbsp;&nbsp;|&nbsp;&nbsp;
                  취소수량({{getCurrencyFormat(this.salesVolumeInfo.cxlFigure)}})&nbsp;&nbsp;|&nbsp;&nbsp;
                  <span class="text-danger">안전재고({{getCurrencyFormat(this.salesVolumeInfo.safetyFigure)}})</span>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">재고수량</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_pdtQty" v-model="salesVolumeInfo.pdtQty" readonly>
                    <span class="input-group-text">건(개)</span>
                  </div>
                </td>
                <th class="table-secondary col-md-2">안전재고</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_safetyQty" v-model="salesVolumeInfo.safetyFigure" readonly>
                    <span class="input-group-text">건(개)</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>[옵션정보]</b>            
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">
                  추가옵션(원)
                </th>
                <td class="col-md-4">
                </td>
                <th class="table-secondary col-md-2">
                  기타옵션(원)
                </th>
                <td class="col-md-4">
                  <div class="text-start" :key=i v-for="(etcOptName,i) in this.etcOptionInfo">
                    -&nbsp;{{this.etcOptionInfo[i].etcOptName}}&nbsp;
                    (<span class="text-success text-small">{{getCurrencyFormat(this.etcOptionInfo[i].etcOptPrice)}}</span>)
                  </div>
                </td>
              </tr>
            </tbody> 
          </table>
      </div>
    </div>
    <div class="mb-3 row">
      <!-- <div class="col-auto d-grid p-1">
        
        <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
      </div> -->
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToList()">예약리스트</button>
      </div>
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
      </div> -->
    </div>
  </div>
  </main>
</template>

<script>
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      reserveInfo: [],              //예약정보
      reservePriceInfo: [],         //예약권종정보
      selectedEtcOption: [],        //선택된 기타옵션
      checkedOptions: [],           //변경선택된 옵션
      checkedOptionsApply: [],      //선택옵션 적용일

      cxlFlagList: [],              //취소사유리스트

      // 구매된 옵션 & 추가상품
      selOptFlag: "N",              //선택옵션유무
      selAddFlag: "N",              //선택추상유무
      pdtOptInfo: [],               //상품선택옵션정보
      pdtOptFlag: "N",              //선택옵션유무
      calOptFlag: "N",              //선택옵션유무(예약용)
      selOptions: [],               //선택된 옵션정보
      tot_selOptPrice:  0,          //선택된 옵션총액
      selOptNames: [],              //선택옵션명
     
      addPdtInfo: [],               //추가상품정보
      addPdtFlag: "N",              //추가상품유무
      calAddFlag: "N",              //추가상품유무(예약용)
      selAddPdt: [],                //선택된 추가상품정보
      tot_selAddPdtPrice:  0,       //선택된 추상총액
      selAddPdtNames: [],           //선택추상명


      etcOptionInfo: [],            //기타옵션
      pdtPromoInfo_apply: [],       //적용가능 프로모션(출발일기준)
      
      //selectedProcCode: "",       //예약처리상태      

      copyFlag1: "",                //정보복사여부

      productCode: "",              //상품코드
      pdtFlag: "",                  //상품종류
      availPoint: 0,                //가용포인트
      etcSelectedOption: [],        //선택된 기타옵션
      journeyDate: [],              //여정일자
      //selJourneyDate: [],         //옵션적용 여정일자(선택옵션용)
      sJDate: [],                   //옵션적용 여정일자(선택옵션용)

      //optDcPrice: 0,              //옵션특별할인액

      selectedEtcOpt: [],           //선택된 옵션의 확인선택
      selectedEtcOptApplyDate: [],  //선택된 옵션의 적용일자(기존)
      applyDateFlag: "",            //적용일자선택여부(기존)

      sAddOptNames: [],             //고객요청 특별추가옵션명

      sEtcOpt: [],                  //선택된 기타옵션 중 이용할 옵션

      cstPrice: 0,                  //상담가격

      productInfo: {},              //상품정보

      personCnt: 0,                 //총인원(화면상)

      reqMarginRate: 0,             //당해예약마진
      reqMarginMethod: "P",         //당해예약마진계산방법(P/A)

      insComment: "",               //예약상담내용

      paidPoint: 0,                 //사용포인트
      payPointFlag: "N",            //포인트사용여부
      userPayPoint: [],             //해당예약에서 유저지불 포인트

      //////// PNI정보
      pTotCnt : 0,                  //참가인원수
      pniList: [],                  //pni리스트
      pniLength: 0,                 //pni리스트길이

      passNameKor: [],              //pni성인
      passNameEng: [],
      //passBirthDate: [],
      pBirthDate: [],
      passLicenseNo: [],            //주민번호(여행자보험용)
      passNo: [],                   //여권번호
      //passExpDate: [],            //여권만료일
      pExpDate: [],                 //여권만료일
      passFlag: [],                 //단수복수구분(S단수, D복수)
      pniTel: [],
      imgFlag: [],                  //여권사본등록여부(Y/N)
      priceType: [],                //권종유형(A,C.I.D)

      pniRemarks: "",               //인적사항 특기사항(마스터 저장데이타)

      passUpFlag: "",               //여권 업로드영역 호출여부
      enteredPassNo: "",            //입력된 여권번호

      pdtTermFeeInfo_dp_apply: [],  //적용터미널료(출발)
      pdtTermFeeInfo_rt_apply: [],  //적용터미널료(리턴)
      oilSCDp_apply: [],            //적용유류할증료(출발)
      oilSCRt_apply: [],            //적용유류할증료(리턴)


      productTrans: [],
      pdtTransInfo1: [],            //기존교통정보
      pdtTransInfo: [],
      pdtPromoInfo: [],             //프로모션정보
      pdtOilSCInfo1: [],            //기존유류할증
      pdtTermFeeInfo_dp: [],        //기존터미널료
      pdtTermFeeInfo_rt: [],
      salesVolumeInfo: [],          //판매수량관리

      dTerm: "",                    //출발터미널
      rTerm: "",                    //리턴터미널
      dCur: "",                     //터미널이용료화폐
      rCur: "",

      pdtAddOptExisted: [],         //기존추가옵션
      addOptInfo: [],               //화면표시용 추가옵션

      areaFlag: "",

      cityList: [],
      nationList: [],
      areaList: [],
      areaList_tmp: [],
      transList: [],
      //tranList_via: {},
      mobilityList_tmp: [],
      mobilityList_dp: [],          //출발편
      mobilityList_rt: [],          //리턴편
      termList_dpd: [],             //출발시작터미널
      termList_dpa: [],             //출발도착터미널
      termList_rtd: [],             //리턴시작터미널
      termList_rta: [],             //리턴도착터미널

      sRoute: [],                   //상품구분

      termList_dp: {},              //출발터미널
      termList_rt: {},              //리턴국터미널
      oilSurcharge_dp: {},          //유류할증
      oilSurcharge_rt: {},
      termFee_dp: {},               //터미널이용료
      termFee_rt : {},
      termFeeNameList_dp: {},       //터미널이용료 명칭
      termFeeNameList_rt : {},

      promoWork: "",                //프로모션 적용방식(C/U)
      cPromoFlag: "",               //선택프로모션 진행?종료

      productPromotion: {},
      choicePromotion: {},          //선택된 프로모션정보
      promoList: [],                //해당상품의 프로모션목록
      optFlag: "",                  //옵션선택플래그
      pdtcode: "",

      etcOptName: [],
      etcOptPrice: [],
      vCnt: 0,
      
      reqOptFlag: [],               //기타옵션플래그

      productPrice: {},

      promoStart: "",
      promoEnd: "",

      rtnRPflag: "N",               //결제요청여부
      paymentInFull: "",            //완납여부
      userPayInfo: [],              //결제내역정보
      payPointAmt: 0,               //포인트결제액
      //actualPayAmt: 0,            //실결제액
      personCntK: 0,                //카카오알림톡용 인원수
      pdtPriceNameInfo: [],         //권종이명
      totRefundAmt: 0,              //환급액
      totDiscountAmt:0,             //할인액
      //tot_comDC: 0,                 //멤버십 동반자할인총액

      cxlName: "",                 //취소사유

      handTel: [],                //매니저연락처
      handTelCnt: 0,              //수신처갯수

      balance: 0,                 //부분결제 시 잔액

      /////결제히스토리용 정보
      p1PayAmt: 0, 
      p1PayDate: "",
      p2PayAmt: 0, 
      p2PayDate:"",
      //payPointAmt: 0,
      payPointDate: "",
      fPayFlag: "",                //예약금결제N, 전액결제Y
      totalPay: 0,                 //히스토리합산

      optName: "",                //선택된 옵션명
      selOptPriceTot: [],         //선택옵션별 총액
      selOPtPriceDCTot_self: 0,   //선택옵션 본인할인액
      selOPtPriceDCTot_exSelf: 0, //선택옵션 동반할인액
      selOptPriceRef_tot: 0,      //선택옵션 환급총
      selOptPriceTot_tot: 0,      //선택옵션총액(본인, 할인포함)

      reqStatus_old: "",          //변경전 상태   
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.reqRsvCode = this.$route.query.seqReq;
    this.productId = this.$route.query.pCode;
    this.copyFlag = this.$route.query.cCode;

    //this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;
    this.ntCd = this.$route.query.ntCd;
    //this.pdtFlag = 'MX';

    console.log("USER:",this.user);

    //this.getCopyReservation(); //예약처리용 데이타복사

    this.getReqProcLevel();
    this.getReserInfo();
    this.getProductInfo();
    this.getSalesRoute();
    //this.getSelectedEtcOption();
    
    this.getProductTransInfoExited(); //기존교통정보
    this.getProductTransInfo();
    this.getProductPrice();
    this.getPdtPromotion(); //상품프로모션
    
    if(this.productId.charAt(0) != "L")
    {
      this.getPdtOilSurchargeExited(); //기존상품 유류할증료
      this.getPdtTerminalFeeExited(); //기존상품 유류할증료
    }

    this.getSalesVolumeInfo(); //판매수량관리 정보

    this.getProductPromotion(); //상품프로모션정보
    this.getPromotionList();

    this.getCityList();
    this.getNationList();
    this.getAreaList();
    this.getTransList();
    this.getMobilityList();

    this.getHandlerInfo()           //알림톡 발송대상 체크
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: {
    /** 윈도우즈 계산기호출*/
    callWinCalc() 
    { 
      window.open('Calculator: ');
    },
    //상태변화체크
    async changeProcCode(rProcCode)
    {
      if(rProcCode === "CX")
      {
        this.$swal.fire("","취소사유를 반드시 선택해주세요!", "question")
        return false;
      }
      this.reserveInfo.reqStatus = (rProcCode == '') ? this.reserveInfo.reqStatus : rProcCode
      //console.log("selectedProcCode:", this.reserveInfo.reqStatus)

      //예약확인상태로 변경
      if(this.reserveInfo.rCopyFlag == 'N' && this.reqStatus_old !== rProcCode)
      {
        let updResConfirm = await this.$api("/api/updResConfirm", {param: [this.user.handlerId, this.reqRsvCode]});
        if(updResConfirm.affectedRows < 0 || updResConfirm.affectedRows == undefined)
        {
          this.$swal.fire("","예약확인상태 변경 중에 에러가 발생했습니다. 다시 진행해주세요", "warning")
          return false
        }
        else
        {
          this.$swal.fire("","당해 예약은 확인이 되었습니다", "info")
        }
      }
    },

    //취소사유추적
    changeCxlCode(cxCode)
    {
      let cxlName_tmp = this.cxlFlagList.filter((item) => {
        return item.procCode === cxCode
      })

      this.cxlName = cxlName_tmp[0].procName
      this.reserveInfo.cxlFlag = (cxCode == '') ? this.reserveInfo.cxlFlag : cxCode
      //console.log("selectedCxlCode:", this.reserveInfo.cxlFlag, "cxlName:", cxlName)
    },    

    //선택옵션 설정변경
    async changeEtcOpt(){
      let checkBox = document.getElementsByName('checkedBox')

      let checkedOptCodes = []
      let checkedOptPrice = []
      let checkedOptions = []

      for (var j = 0; j < checkBox.length; j++) 
      {
        if (checkBox[j].checked)
        {
          checkedOptCodes.push([
            this.etcOptionInfo[checkBox[j].id].etcOptCode
          ])
          checkedOptPrice.push([
            this.etcOptionInfo[checkBox[j].id].etcOptPrice, this.etcOptionInfo[checkBox[j].id].etcChild, this.etcOptionInfo[checkBox[j].id].etcInf
          ])
          checkedOptions.push([
            this.etcOptionInfo[checkBox[j].id].etcOptCode, this.etcOptionInfo[checkBox[j].id].etcOptPrice, this.etcOptionInfo[checkBox[j].id].etcChild, this.etcOptionInfo[checkBox[j].id].etcInf
          ])          
        }
      }
      this.checkedOptions = checkedOptions
      //console.log("checkedOptions:",this.checkedOptions)

      let i=0
      let res=""
      let updReserve_d_etcOpt = []

      //옵션적용일 초기화(Y->N)
      let upd_ResEtcOptApplyStat = await this.$api("/api/upd_ResEtcOptApplyStat", {param:[this.reqRsvCode]})
      if(upd_ResEtcOptApplyStat.affectedRows <  0 || upd_ResEtcOptApplyStat.affectedRows == undefined)
      {
        this.$swal.fire('','에러가 발생했습니다(코드: upd_ResEtcOptApplyStat). 재시도해주세요.', 'warning')
        return false;
      }

      //옵션선택 초기화(Y->N)
      let upd_ResEtcOptStat = await this.$api("/api/upd_ResEtcOptStat", {param:[this.reqRsvCode]})

      if(upd_ResEtcOptStat.affectedRows < 0 || upd_ResEtcOptStat.affectedRows == undefined)
      {
        this.$swal.fire('','에러가 발생했습니다(코드: upd_ResEtcOptStat). 재시도해주세요.', 'warning')
        return false;
      }
      else
      {
        while(i < checkedOptions.length)
        {
          updReserve_d_etcOpt = await this.$api("/api/proc_updResEtcOpt", {param:[
            this.reqRsvCode, this.checkedOptions[i][1], this.checkedOptions[i][2], this.checkedOptions[i][3], 0, this.checkedOptions[i][0]
          ]})

          if(updReserve_d_etcOpt.affectedRows < 0 || updReserve_d_etcOpt.affectedRows == undefined)
          {
            res = "error"
            break
          }
          i++
        }
        if(res == "error")
        {
          this.$swal.fire('','처리 중 문제가 발생했습니다.('+ updReserve_d_etcOpt.error.sqlMessage +')', 'warning')
          return false;
        }
        else
        {
          this.$swal.fire('','선택옵션이 변경되었습니다.', 'success')
          location.reload(true)
        }
      }
    },

    //선택옵션 적용일 변경
    async changeEtcOptApply(){
      let checkBox = document.getElementsByName('checkedApply')

      let checkedOptCodes = []
      let checkedOptApply = []
      // //let checkedOptPrice = []
      let checkedOptions = []

      for (var j = 0; j < checkBox.length; j++) 
      {
        if (checkBox[j].checked)
        {
          //checkedOptions.push(checkBox[j].id);
          checkedOptCodes.push([
            this.selectedEtcOptApplyDate[checkBox[j].id].etcOptCode
          ])
          checkedOptApply.push([
            this.selectedEtcOptApplyDate[checkBox[j].id].applyDate,
          ])

          checkedOptions.push([
            this.selectedEtcOptApplyDate[checkBox[j].id].etcOptCode, this.selectedEtcOptApplyDate[checkBox[j].id].applyDate
          ])
        }
      }
      this.checkedOptionsApply = checkedOptions
      //console.log("checkedOptionsApply:",this.checkedOptionsApply)

      let i=0
      let res=""
      let updResEtcOpt_Apply = []

      //옵션적용일 초기화(Y->N)
      updResEtcOpt_Apply = await this.$api("/api/upd_ResEtcOptApplyStat", {param:[this.reqRsvCode]})
      if(updResEtcOpt_Apply.affectedRows <  0 || updResEtcOpt_Apply.affectedRows == undefined)
      {
        this.$swal.fire('','에러가 발생했습니다(코드: upd_ResEtcOptApplyStat). 재시도해주세요.', 'warning')
        return false;
      }
      else
      {
        while(i < checkedOptions.length)
        {
          updResEtcOpt_Apply = await this.$api("/api/proc_updResEtcOpt_Apply", {param:[
            this.reqRsvCode, this.checkedOptionsApply[i][0], this.getDateFormat(this.checkedOptionsApply[i][1])
          ]})

          if(updResEtcOpt_Apply.affectedRows < 0 || updResEtcOpt_Apply.affectedRows == undefined)
          {
            res = "error" 
            break
          }
          i++
        }
        if(res == "error")
        {
          this.$swal.fire('','처리 중 문제가 발생했습니다.('+ updResEtcOpt_Apply.error.sqlMessage +')', 'warning')
          return false;
        }
        else
        {
          this.$swal.fire('','선택옵션이 변경되었습니다.', 'success')
          location.reload(true)
        }
      }
    },

    async getReserInfo()
    {
      //예약정보마스터
      let reserveInfo = await this.$api("/api/resConsultDetail", {param: [this.reqRsvCode]});
      //예약정보디테일(금액정보)
      let reserveInfo_detail = await this.$api("/api/reservePriceInfo", {param: [this.reqRsvCode]});

      if(reserveInfo.length > 0)
      {
        this.reqStatus_old = reserveInfo[0].reqStatus
        this.reserveInfo = reserveInfo[0];
        this.productCode = reserveInfo[0].reqPdtCode;
        this.reserveInfo.conEmail =
          (this.reserveInfo.conEmail == "undefined" || this.reserveInfo.conEmail == null) ? this.reserveInfo.reqEmail : this.reserveInfo.conEmail;

        //출발예정일-리턴예정일
        this.reserveInfo.reqDptDate = this.getDateFormat(this.reserveInfo.reqDptDate);
        this.reserveInfo.reqRtnDate = this.getDateFormat(this.reserveInfo.reqRtnDate);
        this.reserveInfo.reqDate = this.getDateFormat(this.reserveInfo.reqDate); //insDate 예약일
        this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"

        this.reqMarginRate = (this.reserveInfo.reqMarginRate == null) ? this.productInfo.basicMargin : this.reserveInfo.reqMarginRate;
        this.reqMarginMethod = (this.reserveInfo.reqMarginMethod == null) ? this.productInfo.marginMethod : this.reserveInfo.reqMarginMethod;
      }
      console.log("reserveInfo:",this.reserveInfo)

      //예약정보(권종)
      let reservePriceInfo = await this.$api("/api/reservePriceInfo_new", {param: [this.reqRsvCode]});
      this.reservePriceInfo = reservePriceInfo;
      console.log("reservePriceInfo:",this.reservePriceInfo)

      //상품 여정일자 리스트
      let arr_journeyDate = [];
        arr_journeyDate = this.getDatesStartToLast(this.reserveInfo.reqDptDate, this.reserveInfo.reqRtnDate)
        this.journeyDate = arr_journeyDate;

      //카카오알림톡용 여행인원
      let personCntK = (this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1);
      this.personCntK = personCntK*1;
      //console.log("personCntK:",personCntK);

      //예약디테일 금액정보(할인/환급용)
      if(reserveInfo_detail.length > 0)
      {
        //총환급
        if(reserveInfo_detail[0].pFlag === 'c1' || reserveInfo_detail[0].pFlag === 'c3')
        {
          // this.totRefundAmt = (reserveInfo_detail[0].reqAdultMemP * this.reserveInfo.reqAdultCnt) + (reserveInfo_detail[0].reqChildMemP * this.reserveInfo.reqChildCnt) + (reserveInfo_detail[0].reqInfMemP * this.reserveInfo.reqInfCnt)
          //환급대상은 당사자본인
          this.totRefundAmt = reserveInfo_detail[0].reqAdultMemP * 1
        }
        //총할인
        else if(reserveInfo_detail[0].pFlag === 'c2' || reserveInfo_detail[0].pFlag === 'c4'  || reserveInfo_detail[0].pFlag === 'c5')
        {
          // this.totDiscountAmt = (reserveInfo_detail[0].reqAdultMemP * this.reserveInfo.reqAdultCnt) + (reserveInfo_detail[0].reqChildMemP * this.reserveInfo.reqChildCnt) + (reserveInfo_detail[0].reqInfMemP * this.reserveInfo.reqInfCnt)
          this.totDiscountAmt = reserveInfo_detail[0].reqAdultMemP * 1
        }

        this.reserveInfo_detail = reserveInfo_detail[0]
      }
      //console.log(this.reserveInfo_detail.pFlag,"reserveInfo_detail:",this.reserveInfo_detail," totRefundAmt:", this.totRefundAmt, "totDiscountAmt:",this.totDiscountAmt)

      this.getPdtPriceName(this.productId); //권종이명체크

      //가용포인트 체크
      this.getAvailPoint();

      //선택한 기타옵션, 적용일 체크
      //this.getSelectedEtcOption();

      //적용가능한 프로모션
      this.getPdtPromotion_apply();
      // PNI
      this.getPniList();

      //결제요청 여부
      this.getReqPayInfo(this.reqRsvCode);

      //당해예약 지불포인트
      this.getUserPayPoint(this.reqRsvCode)

      // 선택된 옵션
      this.getSelOptions_new(this.reqRsvCode)

      // 선택된 추가상품
      this.getSelAddProductions(this.reqRsvCode)
    },

    // //선택옵션획득
    // async getSelOptions(resCode) {
    //   let selOptions = []
    //   let useFlag = "Y"
    //   //let selOptNames_tmp = []

    //   selOptions = await this.$api("/api/getSelOptions", {param: [resCode, useFlag]});

    //   if(selOptions.length > 0)
    //   {
    //     this.selOptions = selOptions
    //     this.selOptFlag = "Y"

    //     //선택옵션가격*수량 & 선택옵션명
    //     selOptions.forEach(so => {
    //       this.tot_selOptPrice += (so.prcOpt * so.qtyOpt)
    //       this.selOptNames.push(so.optPdtName)
    //       // this.selOptNames = selOptNames_tmp.map(selOpt => selOpt.replace(/^\[|"|]$/g, ''));
    //     });
    //   }
    //   else
    //   {
    //     this.selOptFlag  = "N"
    //     this.selOptNames = "없음"
    //   }
    //   console.log("selOptions:",this.selOptions, "tot_selOptPrice:", this.tot_selOptPrice);
    //   console.log("selOptNames:", this.selOptNames)
    // },

    // //선택옵션획득
    // async getSelOptions(resCode) {
    //   let selOptions = []
    //   let useFlag = "Y"
    //   //let selOptNames_tmp = []

    //   selOptions = await this.$api("/api/getSelOptions_new", {param: [resCode, useFlag]});
    //   console.log("selOptions:",selOptions);

    //   if(selOptions.length > 0)
    //   {
    //     this.selOptions = selOptions
    //     this.selOptFlag = "Y"

    //     //선택옵션명
    //     selOptions.forEach(so => {
    //       this.selOptNames.push(so.optPdtName)
    //     });

    //     let optName = []                     //선택옵션명 
    //     let selOptPriceSelf = []             //선택옵션 본인
    //     let selOptPriceRef  = []             //선택옵션 환급액
    //     let selOptPriceAdu  = []
    //     let selOptPriceTot_exSelf  = []      //본인제외 동반옵션액(할인포함)
    //     let selOptPriceTot  = []             //본인포함 동반옵션액(할인포함)
    //     let selOPtPriceDCTot_self = 0        //옵션할인본인
    //     let selOPtPriceDCTot_exSelf = 0      //옵션할인동반인(본인제외)
    //     let selOptPriceRef_tot = 0           //환급액총액
    //     let selOptPriceTot_tot = 0           //선택옵션총액(본인포함, 할인포함)

    //     for(let i=0; i<selOptions.length; i++)
    //     {
    //       optName[i] = selOptions[i].optPdtName
    //       selOptPriceSelf[i] = selOptions[i].optPrice - selOptions[i].optPriceDCForStd
    //       selOptPriceRef[i]  = (selOptions[i].optPriceRef_adu == undefined) ? 0 : selOptions[i].optPriceRef_adu
    //       selOptPriceAdu[i]  = (selOptions[i].cntAdu > 1) ? (selOptions[i].optPrice - selOptions[i].optPriceDC_adu) : 0
          
    //       selOptPriceTot_exSelf[i]  = selOptPriceAdu[i]*(selOptions[i].cntAdu-1) + ((selOptions[i].optPrice_chi-selOptions[i].optPriceDC_chi)*selOptions[i].cntChi) + ((selOptions[i].optPrice_inf-selOptions[i].optPriceDC_inf)*selOptions[i].cntInf)

    //       selOptPriceTot[i] = (selOptPriceRef[i] > 0) ? (selOptPriceRef[i]+selOptPriceTot_exSelf[i]) : (selOptPriceSelf[i]+selOptPriceTot_exSelf[i])
    //       selOPtPriceDCTot_self += (selOptions[i].optPrice - selOptPriceSelf[i])

    //       selOPtPriceDCTot_exSelf += (selOptions[i].optPrice*(selOptions[i].cntAdu-1)) + ((selOptions[i].optPrice_chi)*selOptions[i].cntChi) + ((selOptions[i].optPrice_inf)*selOptions[i].cntInf)

    //       selOptPriceRef_tot += selOptPriceRef[i]

    //       selOptPriceTot_tot += selOptPriceTot[i]

    //       console.log(selOptPriceSelf, selOptPriceRef, selOptPriceAdu, selOptPriceTot_exSelf, selOptPriceTot, selOPtPriceDCTot_self, selOPtPriceDCTot_exSelf, selOptPriceRef_tot, selOptPriceTot_tot)
    //     }
    //     this.optName = optName
    //     this.selOptPriceTot = selOptPriceTot
    //     this.selOPtPriceDCTot_self = selOPtPriceDCTot_self
    //     this.selOPtPriceDCTot_exSelf = selOPtPriceDCTot_exSelf
    //     this.selOptPriceRef_tot = selOptPriceRef_tot
    //     this.selOptPriceTot_tot = selOptPriceTot_tot
    //     console.log("this.selOptPriceTot:>>>>>>>", this.selOptPriceTot, this.optName, this.selOPtPriceDCTot_self, this.selOptPriceRef_tot)

    //   }
    //   else
    //   {
    //     this.selOptFlag  = "N"
    //     this.optName = "없음"
    //   }
    //   //console.log(selOptPriceSelf)
    //   // console.log("selOptNames:", this.selOptNames)
    // },

    //선택옵션획득(선택옵션 중복선택방지 반영처리: 20240730)
    async getSelOptions_new(resCode) {
      let selOptions = []
      let useFlag = "Y"

      selOptions = await this.$api("/api/getSelOptions_new", {param: [resCode, useFlag]});

      if(selOptions.length > 0)
      {
        this.selOptions = selOptions
        this.selOptFlag = "Y"

        let optName = ""                     //선택옵션명 
        let selOptPriceSelf = 0              //선택옵션 본인
        let selOptPriceRef  = 0              //선택옵션 환급액
        let selOptPriceAdu  = 0
        let selOptPriceTot_exSelf  = 0       //본인제외 동반옵션액(할인포함)
        let selOptPriceTot  = 0              //본인포함 동반옵션액(할인포함)
        let selOPtPriceDCTot_self = 0        //옵션할인본인
        let selOPtPriceDCTot_exSelf = 0      //옵션할인동반인(본인제외)
        let selOptPriceRef_tot = 0           //환급액총액

        optName = selOptions[0].optPdtName
        selOptPriceSelf =  selOptions[0].optPrice - selOptions[0].optPriceDCForStd
        selOptPriceRef  = (selOptions[0].optPriceRef_adu == undefined) ? 0 : selOptions[0].optPriceRef_adu
        selOptPriceAdu  = (selOptions[0].cntAdu > 1) ? (selOptions[0].optPrice - selOptions[0].optPriceDC_adu) : 0
          
        selOptPriceTot_exSelf  =
          selOptPriceAdu*(selOptions[0].cntAdu-1) + ((selOptions[0].optPrice_chi-selOptions[0].optPriceDC_chi)*selOptions[0].cntChi) + ((selOptions[0].optPrice_inf-selOptions[0].optPriceDC_inf)*selOptions[0].cntInf)

        selOptPriceTot = (selOptPriceRef > 0) ? (selOptPriceRef+selOptPriceTot_exSelf) : (selOptPriceSelf+selOptPriceTot_exSelf)
        selOPtPriceDCTot_self = (selOptions[0].optPrice - selOptPriceSelf)

        selOPtPriceDCTot_exSelf = 
          (selOptions[0].optPriceDC_adu*(selOptions[0].cntAdu-1)) + ((selOptions[0].optPriceDC_chi)*selOptions[0].cntChi) + ((selOptions[0].optPriceDC_inf)*selOptions[0].cntInf)

        selOptPriceRef_tot = selOptPriceRef 

        this.optName = optName
        this.selOptPriceTot = selOptPriceTot*1
        this.selOPtPriceDCTot_self = selOPtPriceDCTot_self
        this.selOPtPriceDCTot_exSelf = selOPtPriceDCTot_exSelf
        this.selOptPriceRef_tot = selOptPriceRef_tot
        console.log("this.selOptPriceTot:>>>>>>>", this.selOptPriceTot, this.optName, this.selOPtPriceDCTot_self, this.selOptPriceRef_tot)

      }
      else
      {
        this.selOptFlag  = "N"
        this.optName = "없음"
        this.selOptPriceTot = 0
      }
      //console.log(selOptPriceSelf)
      // console.log("selOptNames:", this.selOptNames)
    },


    //선택추가상품획득
    async getSelAddProductions(resCode) {
      let selAddPdt = []
      let useFlag = "Y"
      selAddPdt = await this.$api("/api/getSelAddProductions", {param: [resCode, useFlag]});

      if(selAddPdt.length > 0)
      {
        this.selAddPdt = selAddPdt
        this.selAddFlag = "Y"
        
        //선택추상가격*수량
        selAddPdt.forEach(sa => {
          this.tot_selAddPdtPrice += (sa.addPdtPrice * sa.qty_addPdt)
          this.selAddPdtNames.push(sa.addPdtName)
        });
      }
      else
      {
        this.selAddFlag = "N"
        this.selAddPdtNames = "없음"
      }
      console.log("selAddPdtNames:", this.selAddPdtNames)
      //console.log("selAddPdt:",this.selAddPdt, "tot_selAddPdtPrice:", this.tot_selAddPdtPrice);
    },

    //권종이명이 있는 경우, 우선표시
    async getPdtPriceName(pCode)
    {
      let pdtPriceNameInfo = await this.$api("/api/pdtPriceNameInfo", {param: [pCode, this.reserveInfo.reqDptDate]});
      if(pdtPriceNameInfo.length > 0)
      {
        //this.rtnRPflag = "Y"

        this.pdtPriceNameInfo = pdtPriceNameInfo
      }
      //console.log("pdtPriceNameInfo:", this.pdtPriceNameInfo)
    },    
    async getReqPayInfo(val)
    {
      //포인트결제내역
      let payPointInfo = await this.$api("/api/paidPoint", {param: [val]});
      console.log("payPointInfo:", payPointInfo)
      
      //결제포인트액수
      if(payPointInfo.length > 0)
      {
        if(payPointInfo[0].userPoint > 0 && payPointInfo[0].useFlag === 'U')
        {
          this.payPointAmt = payPointInfo[0].userPoint
          this.payPointDate = payPointInfo[0].pointAddDate
        }
        else
        {
          this.payPointAmt = 0
        }
      }


      let resCode = ""
      if(this.reserveInfo.partFlag === "1")
      {
        resCode = val+"-1"
      }
      else if(this.reserveInfo.partFlag === "2")
      {
        resCode = val+"-1"
      }
      else
      {
        resCode = val
      }

      let rtnRPflag_tmp = []

      if(this.reserveInfo.reqStatus === "WP")
      {
        rtnRPflag_tmp = await this.$api("/api/getReqPayInfo_vir", {param: [resCode]});
      }
      else
      {
        rtnRPflag_tmp = await this.$api("/api/getReqPayInfo", {param: [resCode]});
      }
      console.log("rtnRPflag_tmp:", rtnRPflag_tmp, rtnRPflag_tmp.length)

      if(rtnRPflag_tmp.length > 0)
      {
        this.rtnRPflag = "Y"    //결제여부

        if(rtnRPflag_tmp[0].payFlag === 'p1')        //부분결제 시, 잔여대금
        {
          this.balance = (rtnRPflag_tmp[0].reqTotPayAmt)*1 - (rtnRPflag_tmp[0].actualPayAmount)*1
        }
        
        if(rtnRPflag_tmp[0].payFlag === 'p2' || rtnRPflag_tmp[0].payFlag === 'fu')
        {
          if(this.payPointAmt*1 > 0)
          {
            if(rtnRPflag_tmp[0].reqTotPayAmt*1 == (rtnRPflag_tmp[0].actualPayAmount*1 + this.payPointAmt*1))
            {
              this.rtnRPflag = "Y"
            }
            else if(rtnRPflag_tmp[0].reqTotPayAmt != (rtnRPflag_tmp[0].actualPayAmount*1 + this.payPointAmt*1))
            {
              this.$swal.fire("","최종결제금액과 상품대금이 일치하지 않습니다. 실제결제된 금액을 확인해주세요", "question")
              return false
            }
          }
          else if(rtnRPflag_tmp[0].actualPayAmount == rtnRPflag_tmp[0].reqTotPayAmt)
          {
            this.rtnRPflag = "Y"
          }
          else if(rtnRPflag_tmp[0].actualPayAmount != rtnRPflag_tmp[0].reqTotPayAmt)
          {
            //가상계좌는 후불형식이므로 제외
            if(this.reserveInfo.reqStatus != "WP")
            {
              this.$swal.fire("","최종결제금액과 상품대금이 일치하지 않습니다. 실제결제된 금액을 확인해주세요", "question")
              return false
            }
          }          
        }
        this.userPayInfo = rtnRPflag_tmp[0]
      }
      else
      {
        this.rtnRPflag = "N"
      }
      console.log("userPayInfo:", this.userPayInfo)

      this.p1PayAmt = (this.userPayInfo.p1PayAmt == null) ? 0 :          this.userPayInfo.p1PayAmt
      this.p2PayAmt = (this.userPayInfo.p2PayAmt == null) ? 0 :          this.userPayInfo.p2PayAmt
      this.p1PayDate = (this.userPayInfo.p1PayDate == null) ? "" :       this.getKorDateFormat(this.userPayInfo.p1PayDate)
      this.p2PayDate = (this.userPayInfo.p2PayDate == null) ? "" :       this.getKorDateFormat(this.userPayInfo.p2PayDate)
      this.payPointAmt = (this.userPayInfo.payPointAmt == null) ? 0 :    this.userPayInfo.payPointAmt
      this.payPointDate = (this.userPayInfo.payPointDate == null) ? "" : this.getKorDateFormat(this.userPayInfo.payPointDate)

      this.totalPay = this.p1PayAmt*1 + this.p2PayAmt*1 + this.payPointAmt*1

      let fPayFlag = ""       //전액Y, 부분N
      if(this.userPayInfo.reqTotPayAmt == this.p1PayAmt)
      {
        fPayFlag = "Y"         //부분결제
      }
      else
      {
        fPayFlag = "N"
      }

      this.fPayFlag = fPayFlag
    },

    //예약확정->포인트적립
    pSetAside()
    {
      //console.log(this.userPayInfo.actualPayAmount)
      let setAsidePoint = 0;

      if(this.reserveInfo.rePointMethod === 'P')
      {
        setAsidePoint = (this.userPayInfo.actualPayAmount*1) * ((this.reserveInfo.rePoint*1) / 100)
      }
      else
      {
        setAsidePoint = this.reserveInfo.rePoint*1
      }
      
      this.$swal.fire({
      icon: 'question',
      html: setAsidePoint + '포인트를 적립하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '적립',
      cancelButtonText: '나중에'
      }).then(async (result) => 
      {
        if (result.isConfirmed) 
        {
          let pSetAside = await this.$api("/api/payByPoint", {param: [
            this.reserveInfo.reqEmail,setAsidePoint,'A',this.reqRsvCode, '구매확정'
            ]});
            if(Object.keys(pSetAside)[0] === 'error') 
            {
              this.$swal.fire('', '포인트적립 중 오류가 발생하였습니다립(코드: pSetAside).<br>관리자에게 문의해 주세요.', 'error');
              return false;
            }
            else
            {
              this.$swal.fire('', setAsidePoint+'포인트 적립되었습니다.', 'success');              
            }
        }
      })
    },
    async getPniList()
    {
      let pniList = await this.$api("/api/getPniList", {param: [this.reqRsvCode]});
      this.pniList = pniList;
      this.pniList = (pniList.length > 0) ? pniList : [];
      this.pniLength = pniList.length;
      
      //console.log("pniList1:",this.pniList);
    },
    addPniInfo()
    {
      if(this.vCnt*1 >= (this.personCnt*1) - (this.pTotCnt*1))
      {
        this.$swal.fire("","추가가능한 인원이 없습니다.<br>인원수 변경이 있는 경우, 상담가격계산을 먼저 진행하세요.","error");
        return false;  
      }
      this.vCnt++;
    },
    removePniInfo(val) 
    {
      this.vCnt--;

      this.passNameKor[val] = "";
      this.passNameEng[val] = "";
      //this.passBirthDate[val] = "";
      this.pBirthDate[val] = "";
      //this.passExpDate[val] = "";
      this.pExpDate[val] = "";
      this.passFlag[val] = "";
      this.passLicenseNo[val] = "";
      this.passNo[val] = "";
      this.pniTel[val] = "";
      this.priceType[val] = ""
      document.getElementById(val).remove();
    },


    /** 두날짜 사이의 날짜추출함수 */
    getDatesStartToLast(startDate, lastDate) {
      //var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      //if(!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
      if(!(startDate && lastDate)) return "Not Date Format";

      var result = [];
      var curDate = new Date(startDate);
      while(curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    }, 

    async getReqProcLevel() {
      //예약진행상태
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }

      //취소사유
      let cxlFlagList = await this.$api("/api/reqStatusInfo2", {param: ['CXL', 'Y']});
      if(cxlFlagList.length > 0) {
        this.cxlFlagList = cxlFlagList
      }
      //console.log("cxlFlagList:",this.cxlFlagList);

    },

    //카카오처리
    // callKakao(param)
    // {
    //   if(param === "RP")
    //   {
    //     this.sendKakao(param);
    //   }
    //   else if(param === "CX")
    //   {
    //     this.sendKakao(param);
    //   }
    //   else if(param === "nonRP")
    //   {
    //     this.sendKakao(param);
    //   }
    //   else if(param === "nonCO")
    //   {
    //     this.sendKakao(param);
    //   }
    //   else if(param === "nonCX")
    //   {
    //     this.sendKakao(param);
    //   }                  
    //   else
    //   {
    //     this.$swal.fire('', '정확한 발송을 위해 상태를 선택 후 진행을 해주세요', 'info');
    //     return false;
    //   }
    // },

    // 잔액결제청구
    async payBalance(){
      let reqPayBalanceStat = await this.$api("/api/reqPayBalanceStat", {param: [
        "Y", this.balance, this.reqRsvCode+"-1"
      ]});
      
      if(reqPayBalanceStat.affectedRows < 0)
      {
        this.$swal.fire("","잔액결제청구에 문제가 발생했습니다. 재시도 또는 관리자에게 문의해주세요", "warning")
        return false
      }
      else
      {
        this.sendKakao("RP", this.reserveInfo.resMFlag, "p2")
      }
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
      //console.log("handTel: ", this.handTel)
    },

    /** 카카오알림 발송 */
    async sendKakao(param, mFlag, pFlag)   //예약상태, 회원여부
    {
      //let mParam = ""
      if(mFlag === 'N')
      {
        param = "non" + param
      }

      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.reqRsvCode, param, this.user.handlerId
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.reserveInfo.reqTelNo})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0) 
      {
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm_tmp = today.getMonth()+1;
        let dd_tmp = today.getDate();
        
        let mm = "";
        if(mm_tmp < 10) {mm = "0" + mm_tmp}
        else mm = mm_tmp;
        let dd = "";
        if(dd_tmp < 10) {dd = "0" + dd_tmp}
        else dd = dd_tmp;

        let curDate = yyyy + "-" + mm + "-" + dd

        //부분결제의 잔액청구 시, 처리
        let resPayAmount = 0
        if(pFlag === "p2")
        {
          resPayAmount = this.balance
        }
        else
        {
          resPayAmount = this.cstPrice
        }        

        let promises = []         //promise들의 배열
        if(param == "RP") 
        {
          let i = 0
          while(i < this.handTelCnt)
          {
            //결제요청 카카오 알림톡
            let kakaoAlimData = 
            {
              //카카오 알림톡 발송(결제요청)
              clientName: this.reserveInfo.reqName,
              resName: this.productInfo.pdtNameKor,
              resPaxName: this.reserveInfo.reqName,
              resPaxCnt: this.personCntK,
              dptDate: this.reserveInfo.reqDptDate,
              resPhone: this.reserveInfo.reqTelNo,
              resCode: this.reqRsvCode,
              resPayAmount: this.getCurrencyFormat(resPayAmount),
              resManager: this.productInfo.handlerNameKor,
              alimFlag: param,                                         //알림유형(결제요청)
              pdtCode: this.productInfo.pdtCode,                       //결제처리용 상품코드
              toNumber: this.handTel[i].aimCall,                       //알림톡발송대상
            };
            let promise = this.$api("api/kakaoAlimSend2", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)
            i++
          }

          //반복문 이후 처리
          Promise.all(promises).then((results) => {
            console.log(results)
            this.$swal.fire('', '결제요청 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }

        else if(param == "nonRP") 
        {
          let j = 0
          while(j < this.handTelCnt)
          {
            //비회원용 결제요청 카카오 알림톡
            let kakaoAlimData = 
            {
              //카카오 알림톡 발송(결제요청)
              clientName: this.reserveInfo.reqName,
              resName: this.productInfo.pdtNameKor,
              resPaxName: this.reserveInfo.reqName,
              resPaxCnt: this.personCntK,
              dptDate: this.reserveInfo.reqDptDate,
              resPhone: this.reserveInfo.reqTelNo,
              resCode: this.reqRsvCode,
              resPayAmount: this.getCurrencyFormat(resPayAmount),
              resManager: this.productInfo.handlerNameKor,
              alimFlag: param,                                        //알림유형(결제요청)
              pdtCode: this.productInfo.pdtCode,                      //비회원예약 결제처리용 상품코드
              toNumber: this.handTel[j].aimCall,                      //알림톡발송대상
            };
            let promise = this.$api("api/kakaoAlimSend2", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)

            j++
          }

          //반복문 이후 처리
          Promise.all(promises).then((results) => {
            console.log(results)
            this.$swal.fire('', '결제요청 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }


        else if(param == "CO" || param == "nonCO") 
        {
          let i = 0
          while(i < this.handTelCnt)
          {
            //카카오 알림톡 발송(확정)
            let kakaoAlimData = 
            {
              clientName: this.reserveInfo.reqName,
              resCode: this.reqRsvCode,
              resName: this.productInfo.pdtNameKor,
              resPrice: this.getCurrencyFormat(this.cstPrice),
              alimCall: this.reserveInfo.reqTelNo,
              alimFlag: param,
              pdtCode: this.productInfo.pdtCode,                     //비회원예약 결제처리용 상품코드
              toNumber: this.handTel[i].aimCall,                    //알림톡발송대상
            };

            let promise = this.$api("api/kakaoAlimSend", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)

            i++
          }
          Promise.all(promises).then((results) => {
            console.log(results)
            this.$swal.fire('', '예약확정 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }

        //취소처리
        else if(param == "CX" || param == "nonCX") 
        {
          let i = 0
          while(i < this.handTelCnt)
          {
            //카카오 알림톡 발송(취소)
            let kakaoAlimData = 
            {
              clientName: this.reserveInfo.reqName,
              resCode: this.reqRsvCode,
              resDate: this.getDateFormat(this.reserveInfo.insDate),
              dptDate: this.reserveInfo.reqDptDate,
              cxlDate: curDate,
              cxlRemark: this.cxlName,                                    //취소사유 
              alimCall: this.reserveInfo.reqTelNo,
              clientCall: this.handTel[i].aimCall,                        //카톡발송연락처
              pdtCode: this.productInfo.pdtCode,                          //비회원예약 결제처리용 상품코드            
              alimFlag: param,
            };

            let promise = this.$api("api/kakaoAlimSend2", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)

            i++
          }

          Promise.all(promises).then((results) => {
            console.log(results)
            this.$swal.fire('', '예약취소 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })

        }
      }
    },
    //해당예약의 유저가 사용한 포인트
    async getUserPayPoint(resCode)
    {
      let userPayPoint = [];
      userPayPoint = await this.$api("/api/getUserPayPoint", {param: [this.reserveInfo.reqEmail, resCode]});
      if(userPayPoint.length > 0)
      {
        this.userPayPoint = userPayPoint[0];
      }
      //console.log("userPayPoint:", this.userPayPoint)
    },

    //가용포인트 확인
    async getAvailPoint() 
    {
      let totalPoint = await this.$api("/api/userTotalPoint", {param: [this.reserveInfo.reqEmail]});
      //console.log("totalPoint:",totalPoint)

      let unavailPoint = await this.$api("/api/userUnavailablePoint", {param: [this.reserveInfo.reqEmail]});
      console.log("unavailPoint:",unavailPoint)

      //let availPoint = 0;
      if(totalPoint.length > 0) 
      {
        //적립
        let sumA = totalPoint.filter((itme) => {return itme.useFlag === 'A'})
        sumA = (sumA.length > 0) ? sumA[0].total_A : 0;

        //사용
        let sumU = totalPoint.filter((itme) => {return itme.useFlag === 'U'})
        sumU = (sumU.length > 0) ? sumU[0].total_U : 0;

        //사용대기
        let sumS = totalPoint.filter((itme) => {return itme.useFlag === 'S'})
        sumS = (sumS.length > 0) ? sumS[0].total_S : 0;

        //적립대기
        let sumW = totalPoint.filter((itme) => {return itme.useFlag === 'W'})
        sumW = (sumW.length > 0) ? sumW[0].total_W : 0;
        //console.log(sumA,"/", sumU,"/", sumS,"/", sumW)

        //조회시점 가용포인트
        this.availPoint = (sumA*1 + sumW*1) - (sumU*1 + sumS*1);
      }
      else
      {
        this.availPoint = 0;
      }
    },
    // 가용포인트 사용처리
    payByPoint()
    {
      if(!this.cstPrice || this.cstPrice == 0) 
      {
        this.$swal.fire("","결제할 금액이 없거나 0원입니다.","warning");
        return;
      }
      else
      {
        this.$swal.fire({
        icon: 'question',
        text: '포인트를 사용하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '사용',
        cancelButtonText: '나중에'
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            //포인트 사용처리(포인트결제 한도없음)
            let availPoint = this.availPoint
            let amtAfterPayingPoint =  this.cstPrice*1 - availPoint*1

            if(amtAfterPayingPoint > 0)
            {
              this.paidPoint = availPoint
              this.availPoint = 0
              this.cstPrice = amtAfterPayingPoint;
              //this.paymentInFull = "Y" //완납여부
            }
            else
            {
              this.paidPoint = this.cstPrice
              this.availPoint = availPoint - this.cstPrice 
              this.cstPrice = 0
              this.paymentInFull = "Y" //완납여부
            }
          }
        })
      }
    },
    // //선택된 기타옵션
    // async getSelectedEtcOption() {
    //   //고객이 선택한옵션
    //   let selectedEtcOption = []            //선택된 옵션정보
    //   let selectedEtcOptApplyDate = []      //선택된 옵션적용일

    //   selectedEtcOption = await this.$api("/api/selEctOptList", {param: [this.reqRsvCode]});
    //   if(selectedEtcOption.length > 0)
    //   {
    //     this.selectedEtcOption = selectedEtcOption;
    //   }

    //   selectedEtcOptApplyDate = await this.$api("/api/selectedEtcOptApplyDate_new", {param: [this.reqRsvCode]});
      
    //   if(selectedEtcOptApplyDate.length > 0) 
    //   {
    //     this.selectedEtcOptApplyDate = selectedEtcOptApplyDate;
    //     //선택된 기타옵션의 적용일이 존재
    //     this.applyDateFlag = "Y";
    //   }


    //   //선택옵션별 적용일 저장
    //   if(selectedEtcOption.length > 0 && this.applyDateFlag != "Y") 
    //   {
    //     let i = 0
    //     while(i < selectedEtcOption.length)
    //     {
    //       //선택된 기타옵션 존재->기본데이타구조 생성
    //       let j = 0;
    //       let res = "";
    //       while(j < this.journeyDate.length)
    //       {
    //         let insEtcOptApplyDate = await this.$api("/api/insEtcOptApplyDate", {param: [
    //           this.reqRsvCode, j, 'Y', this.journeyDate[j], this.selectedEtcOption[i].etcOptCode 
    //         ]});
    //         if(Object.keys(insEtcOptApplyDate)[0] === 'error') 
    //         {
    //           res = "error";
    //           break;
    //         }
    //         j++;
    //       }
    //       if(res == "error")
    //       {
    //         this.$swal.fire("","선택사항 적용일자 등록중 오류가 발생하였습니다.<br>관리자에게 문의해주세요.","error");
    //         return false;
    //       }
    //       else
    //       {
    //         this.selectedEtcOptApplyDate = selectedEtcOptApplyDate
    //         this.applyDateFlag = "Y";
    //       }
    //       i++
    //     }
    //   }
    // },

    //적용가능한 프로모션정보
    async getPdtPromotion_apply() {
      let pdtPromoInfo_apply = await this.$api("/api/pdtPromo_apply", {param: [this.productId, this.reserveInfo.reqDptDate]});
      if(pdtPromoInfo_apply.length > 0) {
        this.pdtPromoInfo_apply = pdtPromoInfo_apply[0];
        if(this.pdtPromoInfo_apply.promoStart != undefined){
          this.pdtPromoInfo_apply.promoStart = this.getDateFormat(this.pdtPromoInfo_apply.promoStart);
        }
        if(this.pdtPromoInfo_apply.promoEnd != undefined) {
          this.pdtPromoInfo_apply.promoEnd = this.getDateFormat(this.pdtPromoInfo_apply.promoEnd);
        }
      }
      //console.log("pdtPromoInfo_apply:",this.pdtPromoInfo_apply);
    },

    // //기타옵션획득
    // async getEtcOptionInfo() {
    //   let etcOptionInfo = []
    //   etcOptionInfo = await this.$api("/api/etcOptionInfo1", {param: [this.productId]});

    //   if(etcOptionInfo.length > 0)
    //   {
    //     this.etcOptionInfo = etcOptionInfo
    //   }
    //   //console.log("etcOptionInfo:",this.etcOptionInfo);
    // },

  /////////////상담가격계산(터미널이용료/유류할증료 별도표시)
    /** 여정기간 중 상품가격합산식 */
    calcCstPrice() 
    {
      let aduPrice = 0;
      let chiPrice = 0;
      let infPrice = 0;
      let disPrice = 0;
      let pdtPrice = 0;

      //여행참가인원수
      let personCnt = (this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1);
      this.personCnt = personCnt*1;
      //console.log("personCnt:",personCnt);

      //1권종가격기준
      if(this.reserveInfo.reqTripProp == "D")
      {
        aduPrice = (this.reserveInfo.reqAdultPrice*1) * (this.reserveInfo.reqAdultCnt*1);
        chiPrice = (this.reserveInfo.reqChildPrice*1) * (this.reserveInfo.reqChildCnt*1);
        infPrice = (this.reserveInfo.reqInfPrice*1) * (this.reserveInfo.reqInfCnt*1);
        disPrice = 0
        //disPrice = (this.reserveInfo.reqDiscountPrice*1) * (this.reserveInfo.reqDiscountCnt*1);
        pdtPrice = (aduPrice*1) + (chiPrice*1) + (infPrice*1) + (disPrice*1);
        //alert(pdtPrice)
      }
      else //F속성의 daily price를 배열로 취득
      {
        let fAduPrice = document.getElementsByName("fAduPrice[]");
        let fChiPrice = document.getElementsByName("fChiPrice[]");
        let fInfPrice = document.getElementsByName("fInfPrice[]");
        //let fDisPrice = document.getElementsByName("fDisPrice[]");
        let fDisPrice = 0

        let i = 0;
        while(i<fAduPrice.length)
        {
          aduPrice += (fAduPrice[i].value)*1;
          chiPrice += (fChiPrice[i].value)*1;
          infPrice += (fInfPrice[i].value)*1;
          //disPrice += (fDisPrice[i].value)*1;
          disPrice = fDisPrice + 0
          i++;
        }
        pdtPrice = aduPrice*1 + chiPrice*1 + infPrice*1 + disPrice*1;
      }
      //console.log("pdtPrice1:",pdtPrice);

      //2.마진적용
      //let margin = 0;
      let pdtPriceMargin = 0;
      if(this.reqMarginMethod ==="A")
      {
        //pdtPrice = (pdtPrice) + (this.productInfo.basicMargin*1);
        pdtPriceMargin = this.reqMarginRate*1
      }
      else
      {
        if(this.reqMarginRate*1 > 0)
        {
          //pdtPrice = pdtPrice + (pdtPrice * (this.productInfo.basicMargin/100));
          pdtPriceMargin = (pdtPrice*1) * ((this.reqMarginRate*1)/100);
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
        else
        {
          //pdtPrice = pdtPrice + 0;
          pdtPriceMargin = 0;
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
      }
      this.reserveInfo.pdtMargin = pdtPriceMargin*1;
      pdtPrice = pdtPrice*1 + pdtPriceMargin*1;

      //멤버십할인 적용
      if(this.totDiscountAmt > 0)
      {
        pdtPrice = pdtPrice - this.totDiscountAmt*1
      }

      //동반할인 적용
      if(this.reserveInfo.tot_comDC > 0)
      {
        pdtPrice = pdtPrice - (this.reserveInfo.tot_comDC*1)
      }

      //console.log("pdtPrice2:",pdtPrice);
      
      //3.프로모션 적용
      let promoRateApply = this.pdtPromoInfo_apply.promoRate * 1;
      let promoMethodApply = this.pdtPromoInfo_apply.promoMethod;
      let pdtPromo = 0;
      //let pdtPrice_promo = 0;
      if(promoMethodApply === "A") {
        pdtPromo = promoRateApply*1;
        //pdtPrice = pdtPrice - promoRateApply;
      }
      else if(promoMethodApply === "P") {
        pdtPromo = (pdtPrice*1) * ((promoRateApply*1)/100);
        //pdtPrice = pdtPrice - (pdtPrice * (promoRateApply/100));
      }
      else {
        pdtPromo = 0;
      }
      this.reserveInfo.pdtPromo = pdtPromo*1;
      pdtPrice = pdtPrice*1 - pdtPromo*1;
      //console.log("pdtPrice3:",pdtPrice);


      //4.선택된 기타옵션 적용
      let ac = this.reserveInfo.reqAdultCnt    //권종별인원수
      let cc = this.reserveInfo.reqChildCnt
      let ic = this.reserveInfo.reqInfCnt
      let dc = 0
      //let dc = this.reserveInfo.reqDiscountCnt

      //옵션별 적용일자 카운트정보
      let obj_selectedEtcOptApply = {}

      this.selectedEtcOptApplyDate.forEach(item => {
        let etcOptCode = item.etcOptCode

        if(!obj_selectedEtcOptApply[etcOptCode])
        {
          obj_selectedEtcOptApply[etcOptCode] = [];
        }
        obj_selectedEtcOptApply[etcOptCode].push(item)
      });

      //배열로 반환
      let selectedEtcOptApply = Object.values(obj_selectedEtcOptApply)
      //console.log("선택옵션적용일배열정보:", selectedEtcOptApply)

      let j = 0
      let selectedEtcOptApplyCount = []       //선택옵션별 적용일자 카운트배열
      while(j < selectedEtcOptApply.length)
      {
        selectedEtcOptApplyCount.push([selectedEtcOptApply[j][0].etcOptCode, selectedEtcOptApply[j].length])
        j++
      }
      //console.log("selectedEtcOptApplyCount:", selectedEtcOptApplyCount)

      let i = 0
      let tot_etcOptCode = 0
      let tot_etcOptCodePrice = 0
      while(i < this.selectedEtcOption.length)
      {

        //console.log("선택옵션코드",this.selectedEtcOption[i])
        let eCode = this.selectedEtcOption[i].etcOptCode

        let matchingOptCode = selectedEtcOptApplyCount.find(item => item[0] === eCode)
        
        if(matchingOptCode)
        {
          tot_etcOptCode = (this.selectedEtcOption[i].eAduPrice*ac + this.selectedEtcOption[i].eChiPrice*cc + this.selectedEtcOption[i].eInfPrice*ic + dc) * matchingOptCode[1]
        }

        tot_etcOptCodePrice += tot_etcOptCode

        i++
      }
      //console.log("선택옵션 전체합산액:", tot_etcOptCodePrice)

      //특별할인 처리
      pdtPrice = (pdtPrice*1) + (tot_etcOptCodePrice*1) - (this.reserveInfo.optDcPrice*1)
      //console.log("pdtPrice4:",pdtPrice);

      //5.payPoint처리
      if(this.reserveInfo.payPoint > 0)
      {
        this.payPointFlag = "Y";
        pdtPrice = pdtPrice*1 - (this.reserveInfo.payPoint*1);
      }


      //6.부가세 적용
      let vatR = 0;
      //console.log("vat:R",vatR);
      if(this.reserveInfo.reqVatPrice > 0) 
      {
        vatR = (pdtPrice*1) * ((this.reserveInfo.reqVatPrice*1)/100);
      }
      else
      {
        vatR = 0;
      }
      this.reserveInfo.vatPrice = vatR;
      pdtPrice = pdtPrice*1 + vatR*1;
      
      
      //7.소숫점아래 절사처리
      pdtPrice = Math.floor(pdtPrice);

      this.cstPrice = pdtPrice*1  + this.selOptPriceTot + this.tot_selAddPdtPrice
      //this.cstPrice = pdtPrice*1  +  this.tot_selAddPdtPrice
      //alert(pdtPrice + "/" + this.selOptPriceTot_tot + "/" + this.tot_selAddPdtPrice)
      //alert(pdtPrice + "/" + this.selOptPriceTot + "/" + this.tot_selAddPdtPrice)
    },
    async getProductInfo() 
    {
      this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"
      let productInfo = {};
      if(this.pdtFlag === "SG") 
      {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      else if(this.pdtFlag === "MX") 
      {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else 
      {
        return this.$swal.fire("","상품이 검색되지 않습니다<br>재확인 후 시도해 주세요.","error");
      }
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      //console.log("productInfo:",this.productInfo);
    },
    //여권사본 첨부오픈
    passImgUp(pNo)
    {
      // let result = this.checkCorporateRegiNumber(this.cDetail.licenseNumber)
      //alert("pNo:"+pNo);
      if(pNo == "" || pNo == undefined) 
      {
        //this.$refs.txt_cLicense.value = "";
        this.passUpFlag = "";
        //console.log("passUpFlag:",this.passUpFlag);
        this.$swal.fire("","해당 여권번호를 입력하세요", "warning");
        return false;
      }
      else
      {
        //alert("pNo:" + pNo);
        this.passUpFlag = "Y";
        this.enteredPassNo = pNo;
        //console.log("passUpFlag:",this.passUpFlag);
      }
    },
    /** 이미지 사본등록 */
    async uploadFile(files, id, passNo) {
      let fileLength = 0
      let fileType_tmp = "";
      //let fileType = ""
      let licenseFile = "";
      //let passFile = "";
      let data = null;
      if (files) {
        fileLength = files[0].length;
        fileType_tmp = files[0].name.lastIndexOf(".");
        this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();
        //licenseNumber = files[0].name;
        //passFile = this.cDetail.licenseNumber+"."+this.fileType;
        licenseFile = passNo+"."+this.fileType;
        data = await this.$base64(files[0]);
      }
      //console.log("files:",files, "id:",id, "File:",licenseFile,"licenseNo:",this.licenseNumber);
      //const { error } = await this.$api(`/upload/passport/${licenseFile}/${passNo}/${id}`, { data });
      const { error } = await this.$api(`/upload/passport/${id}/${licenseFile}`, { data });
      if (error) {
        return this.$swal("업로드(uploadFile) 오류발생, 재시도 필요.");
      }
      else{
        this.$swal("업로드 완료");
        location.reload();
      }


      // setTimeout(() => {
      //   this.getProductImage();
      // }, 1000);
    },      
    //기존상품 프로모션정보
    async getPdtPromotion() {
      let pdtPromoInfo = await this.$api("/api/pdtPromo_existed", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0];
        if(this.pdtPromoInfo.promoStart != undefined){
          this.pdtPromoInfo.promoStart = this.getDateFormat(this.pdtPromoInfo.promoStart);
        }
        if(this.pdtPromoInfo.promoEnd != undefined) {
          this.pdtPromoInfo.promoEnd = this.getDateFormat(this.pdtPromoInfo.promoEnd);
        }
      }
      //console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },
    //기존상품 유류할증료
    async getPdtOilSurchargeExited() {
      let pdtOilSCInfo1 = await this.$api("/api/pdtOilSurchage_existed", {param: [this.productId]});
      if(pdtOilSCInfo1.length > 0) {
        this.pdtOilSCInfo1 = pdtOilSCInfo1[0];
      }
      //console.log("pdtOilSCInfo1:",pdtOilSCInfo1)
    },
    //기존상품 터미널이용료
    async getPdtTerminalFeeExited() {
      let pdtTermFeeInfo_dp = await this.$api("/api/pdtTerminalFeeD_existed", {param: [this.productId]});
      if(pdtTermFeeInfo_dp.length > 0) {
        this.pdtTermFeeInfo_dp = pdtTermFeeInfo_dp;
        this.dTerm = pdtTermFeeInfo_dp[0].dTerm;
        this.dCur = pdtTermFeeInfo_dp[0].dCur;        
      }
      //console.log("pdtTermFeeInfo_dp:",this.pdtTermFeeInfo_dp);
      
      let pdtTermFeeInfo_rt = await this.$api("/api/pdtTerminalFeeR_existed", {param: [this.productId]});
      if(pdtTermFeeInfo_rt.length > 0) {
        this.pdtTermFeeInfo_rt = pdtTermFeeInfo_rt;
        this.rTerm = pdtTermFeeInfo_rt[0].rTerm;
        this.rCur = pdtTermFeeInfo_rt[0].rCur;
        //console.log("pdtTermFeeInfo_rt:",this.pdtTermFeeInfo_rt);
      }
    },
    async getSalesRoute() {
      this.sRoute = await this.$api("/api/salesRoute", {});
      //console.log("sRoute",this.sRoute);
    },

    //기존상품 교통정보
    async getProductTransInfoExited() {
      let pdtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.productId]});
      if(pdtTransInfo1.length > 0) {
        this.pdtTransInfo1 = pdtTransInfo1[0];
      }
      //console.log("pdtTransInfo1:",this.pdtTransInfo1);
    },
    async getProductTransInfo() {
      let pdtTransInfo = await this.$api("/api/pdtTransInfo", {param: [this.productId]});
      if(this.pdtTransInfo.length > 0){
        this.pdtTransInfo = pdtTransInfo[0];
      }
      //console.log("pdtTransInfo:",this.pdtTransInfo);
      this.pageOpen();
      //this.getAddRoute_dp()
    },

    /** 배열내 중복제거*/
    // func_Distinct(value, index, self) {
    //   return self.indexOf(value) === index;
    // },

    //상품별 수량관리정보
    async getSalesVolumeInfo() {
      let salesVolumeInfo = await this.$api("/api/salesVolumeInfo_mix", {param: [this.productId]});
      if(salesVolumeInfo.length > 0) {
        this.salesVolumeInfo = salesVolumeInfo[0];
      }
      //console.log("salesVolumeInfo",this.salesVolumeInfo);
    },
    //상품프로모션
    async getProductPromotion() {
      let productPromotion = await this.$api("/api/productPromotion", {param: [this.productId]});
      //console.log("pPromo:",productPromotion);

      if(productPromotion.length > 0) {
        this.productPromotion = productPromotion[0]
      }
      else{
        return false;
      }
      //console.log("productPromotion: ",this.productPromotion.promoName);
    },
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      if(cityList.length > 0) {
        this.cityList = cityList;
      }
      //console.log("nationList",nationList);
    },    
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    //넘어온 파라메타로 교통편표시
    async pageOpen(){
      //alert("trans:"+this.productInfo.transFlag);
      this.getAreaList_pageOpen()
      if(this.salesRoute === "T" || this.salesRoute === "L") {
        this.viewDpMobilityList_pageOpen();
        this.viewRtMobilityList_pageOpen();
        this.getDpTermList_pageOpen();
        this.getRtTermList_pageOpen();
      }
    },
    //페이지 오픈시 교통정보 호출
    async getAreaList_pageOpen() {
      //console.log("productInfo:",this.productInfo);
      let areaList1 = await this.$api("/api/areaList", {});
      this.areaList = areaList1.filter(e => {return e.categoryCode === this.ntCd});
    },
    async viewDpMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_dp = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd1});
    },
    async viewRtMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_rt = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd2});
    },
    async getDpTermList_pageOpen() {
      // let param4 = (this.pdtTransInfo.trCd1 === "SHP") ? "ptCd"  : "apCd"
      let param4 = "";
      if(this.pdtTransInfo.trCd1 === "SHP") {
        param4 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd1 === "FLT") {
        param4 = "apCd";
      }
      else {
        param4 = "";
      }
      if(param4 === "ptCd" || param4 === "apCd") {
        this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
        //출발도착터미널
        this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", this.ntCd, param4]});
      }
      else{
        this.termList_dpd = "";
        this.termList_dpa = "";
      }
      //console.log("TR:",param4,"/termListD:",this.termList_dpd,"/termListA:",this.termList_dpa);
    },
    async getRtTermList_pageOpen() {
      //let param5 = (this.pdtTransInfo.trCd2 === "SHP") ? "ptCd" : "apCd"
      let param5 = "";
      if(this.pdtTransInfo.trCd2 === "SHP") {
        param5 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd2 === "FLT") {
        param5 = "apCd";
      }
      else {
        param5 = "";
      }
      //this.termList_rtd = await this.$api("/api/termList", {param: [this.productInfo.ntCd, param5]});
      if(param5 === "ptCd" || param5 === "apCd") {
        this.termList_rtd = await this.$api("/api/termList", {param: [this.ntCd, param5]});
        this.termList_rta = await this.$api("/api/termList1", {param: ["KR", this.ntCd, param5]});
      }
    },
    async getAreaList() {
      this.areaList_tmp = await this.$api("/api/areaList", {});
    },

    viewAreaList(param1){
      this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode === param1});
    },

    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      //console.log("transList",transList);
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      //console.log("mobilityList_tmp",this.mobilityList_tmp);
    },

    viewMobilityList(param2){
      this.mobilityList = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList);
    },

    async getTermList(param3, param4) {
      let param5 = (param4 === "SHP") ? "ptCd" : "apCd"
      this.termList_dp = await this.$api("/api/termList", {param: ['KR', param5]});
      this.termList_rt = await this.$api("/api/termList", {param: [param3, param5]});
    },

    viewDpMobilityList(param1){
      this.mobilityList_dp = this.mobilityList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("mobility:", this.mobilityList_dp);
    },
    viewRtMobilityList(param2){
      this.mobilityList_rt = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList_rt);
    },

    async getDpTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
      //출발도착터미널
      this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_dp);
    },
    async getRtTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      this.termList_rtd = await this.$api("/api/termList", {param: [param2, param4]});
      this.termList_rta = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_rt);
    },
    //상품가격
    async getProductPrice() {
      let productPrice = await this.$api("/api/productPrice", {param: [this.pdtCode]});
      this.productPrice = productPrice;

      // if(this.productInfo.etcOptionFlag === "Y") {
      //   this.getEtcOptionInfo();
      // }
    },
    //수정영역 표시(비표시)
    showArea(param) {
      this.areaFlag = (this.areaFlag === param) ? "N"+param : param;
      if(this.areaFlag === "NT") {
        //alert(param+"의 반대: N"+param);
        this.pdtTransInfo.trCd1 = "";
        this.pdtTransInfo.mbCd1 = "";
        this.pdtTransInfo.dpdCd = "";
        this.pdtTransInfo.dpaCd = "";
        this.pdtTransInfo.transRemarks_dp = "";
        this.pdtTransInfo.oilCd_dp = "";
        this.pdtTransInfo.termCd_dp = "";
        
        this.pdtTransInfo.trCd2 = "";
        this.pdtTransInfo.mbCd2 = "";
        this.pdtTransInfo.rtdCd = "";
        this.pdtTransInfo.rtaCd = "";
        this.pdtTransInfo.transRemarks_ar = "";
        this.pdtTransInfo.oilCd_rt = "";
        this.pdtTransInfo.termCd_rt = "";
      }
      else if(this.areaFlag === "NP") {
        alert("NP/"+this.areaFlag);
      }
    },

    async getEtcFeeInfo(p1,p2,p3,p4) {
      let drFlag = p1;
      let liCd = (this.productInfo.ntCd === "KR") ? "L" : "G"
      let trCd = p2;
      let mbCd = p3;
      let tmCd = p4
      //console.log(tmCd)
      //유류할증료
      this.getOilFeeInfo(drFlag, liCd, trCd, mbCd);
      //터미널이용료
      this.getTermFeeInfo(drFlag, tmCd);
    },
    async getOilFeeInfo(f,o1,o2,o3){
      //console.log(f+"//"+o1+"//"+o2+"//"+o3);
      if(f === "D") {
        //츨발편 유류할증료
        let oilSurcharge_dp = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,'KR']});
        this.oilSurcharge_dp = oilSurcharge_dp;
        //console.log("oilSurcharge_dp",this.oilSurcharge_dp);
      }
      else if(f === "R") {
        //리턴편 유류할증료
        let oilSurcharge_rt = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,this.productInfo.ntCd]});
        this.oilSurcharge_rt = oilSurcharge_rt;
        //console.log("oilSurcharge_rt",this.oilSurcharge_rt);
      }
    },
    async getTermFeeInfo(f,t1){
      //console.log(f+"//"+t1);
      if(f === "D") {
        // let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        // this.termFee_dp = termFee_dp;
        let termFeeNameList_dp = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_dp = termFeeNameList_dp;
        //console.log("termFeeNameList_dp",this.termFeeNameList_dp);
      }
      else if(f === "R") {
        let termFeeNameList_rt = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_rt = termFeeNameList_rt;
        //console.log("termFeeNameList_rt",this .termFeeNameList_rt);
      }
    },

    async getPromotionList() {
      let promotionList = await this.$api("/api/promotionList", {param: [this.productId]});
      this.promoList = promotionList;
      // console.log("promoList:",this.promoList, "pDetail:",this.productDetail.deliCategory);
    },

    goToList() {
      this.$router.push({path: '/reserveInfoList_admin'});
      //this.$router.push({path: '/myPage'});
    },

    //PNI 특기사항처리
    async spRemarkSave() {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;
      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
      let oldSp = ""; 
      let newSp = "";
      oldSp = this.reserveInfo.pniRemarks;
      newSp = this.reserveInfo.nPniRemarks;
      let insSp = oldSp +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newSp;
      //console.log("insSp:",insSp);

      let updPniRemarks = await this.$api("/api/updPniRemarks", {param: [insSp, this.reqRsvCode]});
      if(updPniRemarks.affectedRows < 0 || updPniRemarks.affectedRows === undefined)
      {
        this.$swal.fire('', '특기사항 저장(SP Saving) 중 오류가 발생하였습니다.', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '저장이 완료되었습니다', 'success');
        location.reload();
      }
    },
    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },
    
    dataBaseTransaction(p1,p2){ 
      if(p1 === "U" && p2 ==="R") 
      {
        let changedFlag = ""      //담당자 확인플래그
        //예약상태가 변경되면 접수에서 신규에서 뺀다
        if(this.reqStatus_old != this.reserveInfo.reqStatus)
        {
          changedFlag = "Y"
        }

        if(this.reserveInfo.reqStatus === "CX" && this.userPayPoint.userPoint > 0)
        {
          if(this.reserveInfo.cxlFlag == null || this.reserveInfo.cxlFlag == '')
          {
            this.$swal.fire("","취소사유를 선택하세요!","error")
            return false;
          }
          this.$swal.fire({
            icon: 'question',
            text: '사용한 포인트를 복구합니까?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then(async (result) => 
          {
            if (result.isConfirmed) 
            {
              //포인트 복구처리
              let payByPoint = await this.$api("/api/payByPoint", {param: [
                this.reserveInfo.reqEmail,this.userPayPoint.userPoint,'A',this.reqRsvCode, '예약취소'
              ]});
              if(Object.keys(payByPoint)[0] === 'error') 
              {
                this.$swal.fire('', '포인트 복구처리 중 오류가 발생하였습니다.<br>관리자에게 문의해 주세요.', 'error');
                return false;
              }
              else
              {
                if(this.rtnRPflag === "Y")
                {
                  this.updReqPay();
                }
                else
                {
                  this.insReqPay();
                }

                let today = new Date();
                let yyyy = today.getFullYear();
                let mm_tmp = today.getMonth()+1;
                let dd_tmp = today.getDate();
                let hour = today.getHours();
                let min = today.getMinutes();
                let sec = today.getSeconds();
                
                let mm = "";
                if(mm_tmp < 10) {mm = "0" + mm_tmp}
                else mm = mm_tmp;
                let dd = "";
                if(dd_tmp < 10) {dd = "0" + dd_tmp}
                else dd = dd_tmp;
                
                let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
                let oldComment = ""; 
                let newComment = "";
                oldComment = this.reserveInfo.oldComment;
                newComment = this.reserveInfo.newComment;
                let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newComment;

                let checkReqCxl = await this.$api("/api/checkReqCxl", {param: [this.reqRsvCode]});
                //console.log("checkReqCxl:",checkReqCxl)
                
                let cxl_reserve_m = await this.$api("/api/cxl_reserve_m", {param: [
                  this.reserveInfo.reqStatus,this.user.handlerId,insComment,this.reserveInfo.cxlFlag,
                  this.reqRsvCode
                ]});
                if(cxl_reserve_m.affectedRows < 0 || cxl_reserve_m.affectedRows === undefined)
                {
                  this.$swal.fire('', '예약취소 중 중 오류가 발생하였습니다.', 'error');
                  return false;
                }
                else
                {
                  if(checkReqCxl.length > 0)
                  {
                    let upd_reqCxl = await this.$api("/api/upd_reqCxl", {param: [
                      this.user.handlerId,'Y',this.reqRsvCode
                    ]});
                    if(upd_reqCxl.affectedRows < 0 || upd_reqCxl.affectedRows === undefined)
                    {
                      this.$swal.fire('', '예약취소 중 중 오류가 발생하였습니다.', 'error');
                      return false;
                    }
                  }
                  else
                  {
                    this.$swal.fire('', '예약이 취소되었습니다.', 'success');
                    location.reload();
                  }
                }
              }
            }
          })
          //}
        }
        else
        {
          if(this.reserveInfo.reqStatus === "CX" && (this.reserveInfo.cxlFlag == null || this.reserveInfo.cxlFlag == ''))
          {
            this.$swal.fire("","취소사유를 선택하세요!","error")
            return false;
          }

          if(this.cstPrice =="0" && this.paymentInFull != "Y")
          {
            this.$swal.fire("","상품가격 재계산을 하십시오,","warning");
            return false;
          }
          let totPgr = this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1;

          //console.log("selJourneyDate:",this.selJourneyDate);

          //결제요청 또는 예약취소의 경우, 결제요청상태 체크
          if(this.reserveInfo.reqStatus === "RP" || this.reserveInfo.reqStatus === "CO")
          {
            if(this.rtnRPflag === "Y")
            {
              this.updReqPay();
            }
            else
            {
              this.insReqPay();
            }
          }
          
          let today = new Date();
          let yyyy = today.getFullYear();
          let mm_tmp = today.getMonth()+1;
          let dd_tmp = today.getDate();
          let hour = today.getHours();
          let min = today.getMinutes();
          let sec = today.getSeconds();
          
          let mm = "";
          if(mm_tmp < 10) {mm = "0" + mm_tmp}
          else mm = mm_tmp;

          let dd = "";
          if(dd_tmp < 10) {dd = "0" + dd_tmp}
          else dd = dd_tmp;
          
          let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;

          let oldComment = ""; 
          let newComment = "";
          oldComment = this.reserveInfo.oldComment;
          newComment = this.reserveInfo.newComment;
          let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newComment;

          this.$swal.fire({
          icon: 'question',
          text: '예약정보를 저장하겠습니까?',
          showCancelButton: true,
          confirmButtonText: '저장',
          cancelButtonText: '취소'
          }).then(async (result) => 
          {
            if (result.isConfirmed) 
            {
              //예약취소인 경우, 취소요청이 들어온 예약인지 체크
              if(this.reserveInfo.reqStatus == "CX")
              {
                let checkReqCxl1 = await this.$api("/api/checkReqCxl", {param: [this.reqRsvCode]});
                //console.log("checkReqCxl1:",checkReqCxl1)
                if(checkReqCxl1.length > 0)
                {
                  this.reqCxlHandling(this.reqRsvCode)
                }
              }

              //포인트 결제가 있으면 처리한다(>0)
              if(this.paidPoint > 0)
              {
                let payByPoint = await this.$api("/api/payByPoint", {param: [
                  this.reserveInfo.reqEmail,this.paidPoint,'U',this.reqRsvCode,''
                ]});
                if(Object.keys(payByPoint)[0] === 'error') 
                {
                  this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다.<br>관리자에게 문의해 주세요.', 'error');
                  return false;
                }
              }

              if(this.reserveInfo.payPoint > 0)
              {
                this.paidPoint = this.reserveInfo.payPoint;
              }

              if(changedFlag === "Y")
              {
                this.reserveInfo.rCopyFlag = "Y"
              }              

              let upd_reserve_m = await this.$api("/api/upd_reserve_m_new", {param: [
                this.reserveInfo.reqStatus,this.reserveInfo.reqName,this.reserveInfo.reqTelNo,this.productInfo.ctCd,
                this.reserveInfo.reqAdultCnt*1,this.reserveInfo.reqChildCnt*1,this.reserveInfo.reqInfCnt*1,this.reserveInfo.reqDiscountCnt*1,totPgr*1,
                this.reservePriceInfo[0].reqAdultPrice*1,this.reservePriceInfo[0].reqChildPrice*1,this.reservePriceInfo[0].reqInfPrice*1,this.reservePriceInfo[0].reqDiscountPrice*1,
                this.user.handlerId,this.reserveInfo.pdtMargin,this.reserveInfo.vatPrice*1,this.user.handlerId,this.reserveInfo.pdtPromo,this.cstPrice,insComment,
                this.reqMarginRate,this.reqMarginMethod,this.reserveInfo.optDcPrice,this.paidPoint, this.reserveInfo.conEmail,this.reserveInfo.cxlFlag,this.reserveInfo.rePoint,this.reserveInfo.rePointMethod, this.reserveInfo.rCopyFlag,
                this.reqRsvCode
              ]});
              if(upd_reserve_m.affectedRows < 0 || upd_reserve_m.affectedRows === undefined)
              {
                this.$swal.fire('', '정보변경작업(upd_reserve_m) 중 오류가 발생하였습니다.', 'error');
                return false;
              }
              else
              {
                let i = 0;
                let res = ""; //처리상태
                while(i<this.reservePriceInfo.length)
                {
                  let upd_reserve_d = await this.$api("/api/upd_reserve_d", {param: [
                    this.reservePriceInfo[i].reqAdultPrice*1,this.reservePriceInfo[i].reqChildPrice*1,this.reservePriceInfo[i].reqInfPrice*1,this.reservePriceInfo[i].reqDiscountPrice*1,
                    this.getDateFormat(this.reservePriceInfo[i].reqDate),this.user.handlerId,i,this.reqRsvCode
                  ]});
                  if(upd_reserve_d.affectedRows < 0 || upd_reserve_d.affectedRows === undefined)
                  {
                    res = "error";
                    break;
                  }
                  else
                  {
                    res = "ok";
                  }
                  i++;
                }
                if(res === "error")
                {
                  this.$swal.fire('', '정보변경작업(upd_reserve_d) 중 오류가 발생하였습니다.', 'error');
                  return false;
                }
                else
                {
                  this.$swal.fire('', '정보변경작업이 완료되었습니다.', 'success');
                }
              }
              //카톡자동발송코드
              this.sendKakao(this.reserveInfo.reqStatus, this.reserveInfo.resMFlag)
              //this.$swal.fire('', '저장(변경)이 완료되었습니다', 'success');
              //location.reload();
            }
          })
        }
      }
      //PNI등록
      else if(p1==="U" && p2==="P") 
      { 
        //console.log("인원수변경:",this.reserveInfo.reqAdultCnt,this.reserveInfo.reqChildCnt,this.reserveInfo.reqInfCnt,this.reserveInfo.reqDiscountCnt);

        this.$swal.fire({
        icon: 'question',
        text: '인적사항을 등록(변경)합니까?',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let pniIndex = document.getElementsByName("pniIndex");

            //권종별 인적사항 등록(변경update or 추가insert)
            let i=0;
            let res1 = "";
            //let updPniData = [];
            while(i < pniIndex.length)
            {
              if(i < this.pniList.length)
              {
                let updPniData = await this.$api("/api/updPniData", {param: [
                  this.pniList[i].passNameEng,this.pniList[i].passNameKor,this.pniList[i].pBirthDate,this.pniList[i].passLicenseNo,this.pniList[i].passNo,this.pniList[i].pExpDate,this.pniList[i].passFlag,
                  '',this.pniList[i].imgFlag,this.user.handlerId,this.pniList[i].priceType,this.pniList[i].pniTel,this.pniList[i].pniUseFlag,this.reqRsvCode,pniIndex[i].value
                ]});
                if(updPniData.affectedRows < 0 || updPniData.affectedRows === undefined)
                {
                  res1 = "error";
                  break;
                }
                else
                {
                  res1 = "ok";
                }
              }
              else if(i >= this.pniList.length)
              {
                let insPniData = await this.$api("/api/insPniData", {param: [
                  this.reqRsvCode, pniIndex[i].value,this.passNameEng[i],this.passNameKor[i],this.pBirthDate[i],this.passLicenseNo[i],this.passNo[i],this.pExpDate[i],this.passFlag[i],'','N',this.user.handlerId,'Y',this.priceType[i],this.pniTel[i]
                ]});
                if(Object.keys(insPniData)[0] === 'error') 
                {
                  res1 = "error";
                  break;
                }
                else
                {
                  res1 = "ok";
                }
              }
              i++;
            }
            if(res1 == "error")
            {
              this.$swal.fire('', '인적사항 처리 중(PNI updating) 오류가 발생하였습니다.', 'error');
              return false;
            }
            else
            {
              //PNI 카운트변경 동기처리
              let updPniAndReserveM = await this.$api("/api/updPniAndReserveM", {param: [
                this.reserveInfo.reqAdultCnt,this.reserveInfo.reqChildCnt,this.reserveInfo.reqInfCnt,this.reserveInfo.reqDiscountCnt,(this.reserveInfo.reqAdultCnt*1+this.reserveInfo.reqChildCnt*1+this.reserveInfo.reqInfCnt*1+this.reserveInfo.reqDiscountCnt),this.user.handlerId,this.reqRsvCode
                ]});
                if(updPniAndReserveM.affectedRows < 0 || updPniAndReserveM.affectedRows === undefined)
                {
                  this.$swal.fire('', '처리 중 오류(Pnicount Updating)가 발생했습니다.<br>관리자에게 문의해 주세요.', 'error');
                }
                else
                {
                  this.$swal.fire('', '인적사항 저장완료', 'success')
                  location.reload();
                }
            }
          }

        })
      }      
      else if (p1==="M" && p2 ==="P")
      {
        // 상담가격 재계산 후 업데이트 진행
        this.calcCstPrice();
      }
      // else if(p1==="E" && p2==="O") { //기타옵션
      //   this.$swal.fire({
      //   icon: 'question',
      //   text: '기타옵션 생성?',
      //   showCancelButton: true,
      //   confirmButtonText: '생성',
      //   cancelButtonText: '취소'
      //   }).then(async (result) => {
      //     if (result.isConfirmed) {
      //       this.InsertEtcOption();
      //     }
      //   })
      // }
      // else if(p1==="C" && p2==="O") { //기타옵션 필수선택정도
      //   this.$swal.fire({
      //   icon: 'question',
      //   text: '기타옵션 선택수준 변경?',
      //   showCancelButton: true,
      //   confirmButtonText: '변경',
      //   cancelButtonText: '취소'
      //   }).then(async (result) => {
      //     if (result.isConfirmed) {
      //       let i = 0;
      //       while(i < this.etcOptionInfo.length) {
      //       let res_etcOptFlagUpdate = await this.$api("/api/etcOptFlagUpdate", 
      //         {param: [this.etcOptionInfo[i].reqOptFlag,this.user.handlerId,this.productId,this.etcOptionInfo[i].etcOptName]});
      //         i++;
      //         if(res_etcOptFlagUpdate.affectedRows < 0){
      //           alert("변경작업 중 에러발생\n관리자에게 문의해주세요.");
      //           return false;
      //         }
      //       }
      //       this.$swal.fire('옵션선택수준 변경완료', '', 'success')
      //       location.reload();
      //     }
      //   })
      // }      
      // else {
      //   return this.$swal.fire("","Case-DBTransation: <br>오류가 발생했습니다.<br>관리자에 문의해주세요.","error");
      // }
    },
    //기타옵션 처리공통함수
    // async InsertEtcOption()
    // {
    //   //기타옵션
    //   let objEtcOpt = new Object();
    //   for(let j=1; j<this.etcOptName.length; j++) {
    //     let key = this.etcOptName[j];
    //     objEtcOpt[key] = this.etcOptPrice [j];
    //   }
    //   let objEtcOptSize = Object.keys(objEtcOpt).length;
    //   console.log("objEtcOptSize:",objEtcOptSize);
    //   //if(this.productPrice.etcOptionFlag === "Y") {
    //     for(let j=0; j < objEtcOptSize; j++){
    //       if(this.reqOptFlag[j] == null) {
    //         this.reqOptFlag[j] = "N";
    //       }
    //       //pdtCode, etcOptName, etcOptPrice, useFlag, insId
    //       let resIns_etcOption = await this.$api("/api/etcOptInsert", {param: [this.productId, Object.keys(objEtcOpt)[j],
    //         Object.values(objEtcOpt)[j], 'Y', this.user.handlerId, this.reqOptFlag[j+1]]});
    //         //console.log(resIns_etcOption);
    //         // 인서트오류처리
    //       if(Object.keys(resIns_etcOption)[0] === 'error') {
    //         alert("기타옵션 저장오류");
    //         return false;
    //       }
    //     }
    //     let res_UpdPdtEtcOptFlag = await this.$api("/api/changePdtEtcOptFlag_mix", {param: [this.productId]});
    //     if(res_UpdPdtEtcOptFlag.affectedRows < 0 || res_UpdPdtEtcOptFlag.affectedRows === undefined){
    //       this.$swal.fire('','상품리스트에서 기타옵션 적용표시(Y)확인필요<br>미표시이면 관리자에게 문의하세요', 'warning')
    //     }
    //     else {
    //       this.$swal.fire('','기타옵션 생성완료', 'success')
    //       location.reload();
    //     }
    //   //}
    // },

    //결제요청 정보처리
    async insReqPay()
    {
      let insReqPayInfo = await this.$api("/api/insReqPayInfo",{param: [
        this.reqRsvCode,this.reserveInfo.reqStatus,this.cstPrice,'N',this.user.handlerId
      ]});
      if(Object.keys(insReqPayInfo)[0] === 'error') 
      {
        this.$swal.fire('','처리 중 오류발생(insReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    },
    async updReqPay()
    {
      let updReqPayInfo = await this.$api("/api/updReqPayInfo",{param: [
        this.reserveInfo.reqStatus,this.cstPrice,this.user.handlerId,this.reqRsvCode
      ]});
      if(updReqPayInfo.affectedRows < 0 || updReqPayInfo.affectedRows === undefined)
      {
        this.$swal.fire('','처리 중 오류발생(updReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    },
    //고객취소요청예약인 경우, 마이페이지용 처리
    async reqCxlHandling(rCode)
    {
      let upd_reqCxl = await this.$api("/api/upd_reqCxl", {param: [
        this.user.handlerId,'Y',rCode
      ]});
      if(upd_reqCxl.affectedRows < 0 || upd_reqCxl.affectedRows === undefined)
      {
        this.$swal.fire('', '예약취소 중 중 오류가 발생하였습니다.', 'error');
        return false;
      }      
    },

    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //한국표준시로 변경
    getKorDateFormat(date) {
      return this.$korDateFormat(date)
    },
    

    //적용 터미널이용료
    // async getPdtTerminalFee_apply() {
    //   let pdtTermFeeInfo_dp_apply = await this.$api("/api/pdtTerminalFeeD_apply", {param: [this.productId, this.reserveInfo.reqDptDate]});
    //   if(pdtTermFeeInfo_dp_apply.length > 0) {
    //     this.pdtTermFeeInfo_dp_apply = pdtTermFeeInfo_dp_apply;
    //     this.dTerm = pdtTermFeeInfo_dp_apply[0].dTerm;
    //     this.dCur = pdtTermFeeInfo_dp_apply[0].dCur;
    //   }
    //   //console.log("pdtTermFeeInfo_dp_apply:",this.pdtTermFeeInfo_dp_apply);
    //   let pdtTermFeeInfo_rt_apply = await this.$api("/api/pdtTerminalFeeR_apply", {param: [this.productId, this.reserveInfo.reqRtnDate]});
    //   if(pdtTermFeeInfo_rt_apply.length > 0) {
    //     this.pdtTermFeeInfo_rt_apply = pdtTermFeeInfo_rt_apply;
    //     this.rTerm = pdtTermFeeInfo_rt_apply[0].rTerm;
    //     this.rCur = pdtTermFeeInfo_rt_apply[0].rCur;
    //   }
    //   //console.log("pdtTermFeeInfo_rt_apply:",this.pdtTermFeeInfo_rt_apply);
    // },
    //적용 유류할증료
    // async getPdtOilSurcharge_apply() {
    //   console.log("dpOil:",this.pdtOilSCInfo1.oilCd_dp, "rtOil:",this.pdtOilSCInfo1.oilCd_rt)
    //   //적용가능 유류비 체크
    //   let oilSCDp_apply = await this.$api("/api/pdtOilSurchage_apply", {param: [this.pdtOilSCInfo1.oilCd_dp, this.reserveInfo.reqDptDate]});
    //   if(oilSCDp_apply.length > 0) {
    //     this.oilSCDp_apply = oilSCDp_apply[0];
    //   }
    //   let oilSCRt_apply = await this.$api("/api/pdtOilSurchage_apply", {param: [this.pdtOilSCInfo1.oilCd_rt, this.reserveInfo.reqRtnDate]});
    //   if(oilSCRt_apply.length > 0) {
    //     this.oilSCRt_apply = oilSCRt_apply[0];
    //   }
    // },        
  }
}
</script>