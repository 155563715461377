<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>상 품 수 정</u></h2>
      <div class="mb-3 row">
        <!-- 수정불가 -->
        <label class="col-md-3 col-form-label">상품코드</label>
        <div class="col-md-9 text-primary">
          <!-- <input type="text" class="form-control" readonly> -->
          {{ productId }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명(국문)</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productDetail.pdtNameKor">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명(영문)</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productDetail.pdtNameEng">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">판매루트<font class="text-secondary" style="font-size: small;">(변경불가)</font></label>
        <div class="col-md-9" v-if="productDetail.salesRoute === 'B'">
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" name="rbt_route" id="rbt_b2b" value="B" checked disabled>
            <label class="form-check-label text-primary" for="flexRadioDefault1">
              B2B
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" name="rbt_route" id="rbt_b2c" value="C" disabled>
            <label class="form-check-label text-danger" for="flexRadioDefault2">
              B2C
            </label>
          </div>
        </div>
        <div class="col-md-9" v-else>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" name="rbt_route" id="rbt_b2b" value="B" disabled>
            <label class="form-check-label text-primary" for="flexRadioDefault1">
              B2B
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" name="rbt_route" id="rbt_b2c" Value="C" checked disabled>
            <label class="form-check-label text-danger" for="flexRadioDefault2">
              B2C
            </label>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">단위</label>
        <div class="col-md-9">
          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="productDetail.salesUnit">
            <!-- <option>판매단위선택({{ productDetail.salesUnit }})</option> -->
            <option value="B" v-if="productDetail.salesUnit == 'B'" selected>박스(Box)</option>
            <option value="B" v-else>박스(Box)</option>

            <option value="S" v-if="productDetail.salesUnit == 'S'" selected>세트(Set)</option>
            <option value="S" v-else>세트(Set)</option>

            <option value="E" v-if="productDetail.salesUnit == 'E'" selected>개(ea)</option>
            <option value="E" v-else>개(ea)</option>

            <option value="Z" v-if="productDetail.salesUnit == 'Z'" selected>기타(Etc)</option>
            <option value="Z" v-else>기타(Etc)</option>
          </select>
        </div>
      </div>
      <!-- <div class="mb-3 row" v-if="productDetail.salesRoute == 'B'">
        <label class="col-md-3 col-form-label">단위</label>
        <div class="col-md-9">
          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="productDetail.salesUnit">
            <option value="B" v-if="productDetail.salesUnit == 'B'" selected>박스(Box)</option>
            <option value="B" v-else>박스(Box)</option>

            <option value="P" v-if="productDetail.salesUnit == 'S'" selected>세트(Set)</option>
            <option value="P" v-else>세트(Set)</option>

            <option value="E" v-if="productDetail.salesUnit == 'E'" selected>개(ea)</option>
            <option value="E" v-else>개(ea)</option>

            <option value="E" v-if="productDetail.salesUnit == 'Z'" selected>기타(Etc)</option>
            <option value="E" v-else>기타(Etc)</option>
          </select>
        </div>
      </div> -->

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">기본가격</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="number" class="form-control text-end" ref="txt_unitPrice" v-model="productDetail.unitPrice">
            <span class="input-group-text">원</span>
          </div>
        </div>
      </div>      
      <!-- B2B상품과 B2C상품의 가격등록 분기 -->
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">판매가격
          <p><font class="text-secondary" style="font-size:small;">(B2C: 기본가격=판매가격)</font></p>
          </label>
        <!-- <div class="col-md-9" v-if="!productDetail.salesRoute">
        </div> -->
        <div class="col-md-9" v-if="productDetail.salesRoute === 'B'">
          <!-- <div class="input-group mb-3" :key="i" v-for="i in 4"> -->
          <div class="input-group mb-3" :key="i" v-for="(prc,i) in productPrice">
            <span class="input-group-text">{{i+1}}</span>
            <!-- <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice[i]" value="1212"> -->
            <input type="number" class="form-control-sm input-sm text-end" v-model="prc.pdtPrice">
            <span class="input-group-text">원</span>
          </div>
        </div>
        <div class="col-md-9" v-else-if="productDetail.salesRoute === 'C'">
          <div class="input-group mb-3">
            <span class="input-group-text">1</span>
            <input type="number" class="form-control-sm text-end" v-model="productDetail.unitPrice">
            <span class="input-group-text">원</span>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">배송비&nbsp;(현재:<font class="text-danger" style="font-size:small;">
          {{this.productDetail.deliName}},{{this.productDetail.deliCategory}}</font>)</label>
        <div class="col-md-9">
          <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_seqDeli" v-model="seqDeli" @change="changeDelivery">
            <!-- <option :value="delivery.seqDeli" :key=i v-for="(delivery,i) in deliList.filter(c=>c.seqDeli===this.productDetail.deliCategory)">
              {{delivery.deliName}}</option> -->
            <option value="null" disabled selected>배송기준변경</option>
            <option :value="delivery.seqDeli" :key=i v-for="(delivery,i) in deliList">{{delivery.deliName}}</option>
          </select>
          <div v-if="choiceDelivery.stdDeliCharge === undefined">
            <div class="input-group mt-2">
              <span class="input-group-text">기본배송료</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceA" v-model="productDetail.stdDeliCharge" readonly>
              <span class="input-group-text">원</span>
            </div>
            <div class="input-group mt-2">
              <span class="input-group-text">추가 (제주)</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceB" v-model="productDetail.addJejuFee" readonly>
              <span class="input-group-text">원</span>
            </div>
            <div class="input-group mt-2">
              <span class="input-group-text">추가 (그외)</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceC" v-model="productDetail.addEtcFee" readonly>
              <span class="input-group-text">원</span>
            </div>
            <div class="input-group mt-2">
              <span class="input-group-text">반송 (편도)</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceD" v-model="productDetail.retDeliCharge" readonly>
              <span class="input-group-text">원</span>
            </div>
            <div class="input-group mt-2">
              <span class="input-group-text">배송비면제</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceD" v-model="productDetail.freeLine" readonly>
              <span class="input-group-text">원 이상</span>
            </div>
          </div>
          <div v-else>
            <div class="input-group mt-2">
              <span class="input-group-text">기본배송료</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceA" v-model="choiceDelivery.stdDeliCharge" readonly>
              <span class="input-group-text">원</span>
            </div>
            <div class="input-group mt-2">
              <span class="input-group-text">추가 (제주)</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceB" v-model="choiceDelivery.addJejuFee" readonly>
              <span class="input-group-text">원</span>
            </div>
            <div class="input-group mt-2">
              <span class="input-group-text">추가 (그외)</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceC" v-model="choiceDelivery.addEtcFee" readonly>
              <span class="input-group-text">원</span>
            </div>
            <div class="input-group mt-2">
              <span class="input-group-text">반송 (편도)</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceD" v-model="choiceDelivery.retDeliCharge" readonly>
              <span class="input-group-text">원</span>
            </div>
            <div class="input-group mt-2">
              <span class="input-group-text">배송비면제</span>
              <input type="number" class="form-control-sm text-end" ref="txt_pdtPriceD" v-model="choiceDelivery.freeLine" readonly>
              <span class="input-group-text">원 이상</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">부가세</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="number" class="form-control text-end" v-model="productDetail.vatPrice">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>

      <!-- <div class="mb-3 row">
        <label class="col-md-3 col-form-label">판매기간(현재:<font class="text-danger" style="font-size:small;">
          {{`${productDetail.salesStart}~${productDetail.salesEnd}`}}</font>)</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <date-picker class="form-control" v-model="productDetail.salesStart" ref="txt_fromDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <date-picker class="form-control" v-model="productDetail.salesEnd" ref="txt_toDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            <div class="input-group input-sm mb-3 mt-1">
              <input type="date" class="form-control text-end" v-model="productDetail.salesStart">
              <input type="text" class="form-control text-end" v-model="productDetail.salesEnd">
            </div>
          </div>
        </div>
      </div>       -->
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">판매기간</label>
        <div class="col-md-9">
          <div class="input-group input-sm mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <input type="date" class="form-control" v-model="productDetail.salesStart">
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" v-model="productDetail.salesEnd">
          </div>
        </div>
      </div>
      
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품카테고리<font class="text-secondary" style="font-size: small;">(변경불가)</font></label>
        <div class="col-md-9">
          <select class="form-select form-select-sm" aria-label=".form-select-sm" disabled>
            <!-- <option :value="cat.catCode" :key=i v-for="(cat,i) in productDetail">
              {{productDetail.categoryName}}</option> -->
            <option value="">카테고리선택({{ productDetail.catCode }})</option>
            <option value="N" v-if="productDetail.catCode == 'N'" selected>면류(noodles)</option>
            <option value="N" v-else>면류(noodles)</option>

            <option value="S" v-if="productDetail.catCode == 'S'" selected>양념류(seasonings)</option>
            <option value="S" v-else>양념류(seasonings)</option>

            <option value="K" v-if="productDetail.catCode == 'K'" selected>부엌용품류(kitchenware)</option>
            <option value="K" v-else>부엌용품류(kitchenware)</option>

            <option value="E" v-if="productDetail.catCode == 'E'" selected>기타(etc)</option>
            <option value="E" v-else>기타(etc)</option>
          </select>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">토픽(주제어)</label>
          <div class="col-md-9">
            <input type="text" class="form-control" v-model="productDetail.pdtTopic">
          </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">프로모션</label>
        <div class="col-md-9">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="productDetail.promoWork" value="C" name="rbt_promoWork">
            <label class="form-check-label text-primary" for="promoWork1">신규</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="productDetail.promoWork" value="U" name="rbt_promoWork">
            <label class="form-check-label" for="promoWork2">변경</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="productDetail.promoWork" value="N" name="rbt_promoWork" checked>
            <label class="form-check-label text-success" for="promoWork3">유지</label>
          </div>
        </div>
      </div>

      <!-- 프로모션 작업선택 분기 -->
      <div class="col-md-9" v-if="productDetail.promoWork == 'N'"></div>
      <div class="border p-2" v-else-if="productDetail.promoWork === 'U'">
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">적용프로모션<font class="text-danger" style="font-size: small;">(0:실행)</font></label>
          <div class="col-md-9">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_pdtPromotion" 
              v-model="seqPromo" @change="changePdtPromotion">
              <option :value="promo.seqPromo" :key=i v-for="(promo,i) in promoList">
                {{`${getDateFormat(promo.promoStart)} ~ ${getDateFormat(promo.promoEnd)}>> ${promo.promoName}(${promo.useFlag})`}}</option>
                <!-- {{`${(promo.promoStart).substring(0,10)} ~ ${(promo.promoEnd).substring(0,10)}>> ${promo.promoName}(${promo.useFlag})`}}</option> -->
            </select>
          </div>
        </div>      
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">프로모션명</label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <input type="text" class="form-control input-sm" v-model="choicePromotion.promoName">
            </div>        
          </div>
        </div>

        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">프로모션상태</label>
          <div class="col-md-9">
            <!-- <div class="form-check-inline pull-left" v-if="this.choicePromotion.useFlag === 'Y'">
              <input class="form-check-input" type="checkbox" name="ckb_proN" id="ckb_proN" v-model="promoList.useFlag" true-value="Y" false-value="N" 
                autocomplete="off" checked>
              <label class="form-check-label text-primary" for="ckb_proN">
                &nbsp;실행&nbsp;<font class="text-secondary" style="font-size: small;">(중지하려면 체크해제)</font>
              </label>
            </div>
            <div class="form-check-inline pull-left" v-else-if="this.choicePromotion.useFlag === 'N'">
              <input class="form-check-input" type="checkbox" name="ckb_proN" id="ckb_proN" v-model="promoList.useFlag" true-value="Y" false-value="N"
                autocomplete="off">
              <label class="form-check-label text-primary" for="ckb_proN">
                &nbsp;실행&nbsp;<font class="text-secondary" style="font-size: small;">(중지하려면 체크해제)</font>
              </label>
            </div> -->
            <div class="mb-3 row">
              <div class="col-md-9" v-if="cPromoFlag === '0'">
                <div class="form-check-inline">
                  <input class="form-check-input" type="radio" v-model="this.cPromoFlag" name="rbt_route" id="rbt_b2b" value="0" checked>
                  <label class="form-check-label text-primary" for="flexRadioDefault1">
                    실행
                  </label>
                </div>
                <div class="form-check-inline">
                  <input class="form-check-input" type="radio" v-model="this.cPromoFlag" name="rbt_route" id="rbt_b2c" value="1">
                  <label class="form-check-label text-danger" for="flexRadioDefault2">
                    중지
                  </label>
                </div>
              </div>
              <div class="col-md-9" v-else-if="cPromoFlag === '1'">
                <div class="form-check-inline">
                  <input class="form-check-input" type="radio" v-model="this.cPromoFlag" name="rbt_route" id="rbt_b2b" value="0">
                  <label class="form-check-label text-primary" for="flexRadioDefault1">
                    실행
                  </label>
                </div>
                <div class="form-check-inline">
                  <input class="form-check-input" type="radio" v-model="this.cPromoFlag" name="rbt_route" id="rbt_b2c" Value="1" checked>
                  <label class="form-check-label text-danger" for="flexRadioDefault2">
                    중지
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>      
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">할인<font class="text-secondary" style="font-size: small;">(원/%)</font></label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <input type="number" class="form-control text-end" v-model="choicePromotion.promoRate" disabled>
              <select class="form-select form-select-md" aria-label=".form-select-md" disabled>
                <option value="A" v-if="choicePromotion.promoMethod == 'A'" selected>원</option>
                <option value="A" v-else>원</option>
                <option value="P" v-if="choicePromotion.promoMethod == 'P'" selected>%</option>
                <option value="P" v-else>%</option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">프로모션기간</label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">개시</span>
              <input type="date" class="form-control" v-model="choicePromotion.promoStart">
              <span class="input-group-text" id="basic-addon1">종료</span>
              <input type="date" class="form-control" v-model="choicePromotion.promoEnd">
            </div>
          </div>
          <!-- <div class="col-md-9">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">개시</span>
              <date-picker class="form-control" v-model="choicePromotion.promoStart" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
              <span class="input-group-text" id="basic-addon1">종료</span>
              <date-picker class="form-control" v-model="choicePromotion.promoEnd" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            </div>
          </div> -->
        </div>
      
      <!-- 프로모션신규   -->
      </div>
      <div class="border p-2" v-else-if="productDetail.promoWork === 'C'">
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">프로모션상태</label>
          <div class="col-md-9">
            <div class="form-check-inline pull-left">
              <input class="form-check-input" type="checkbox" v-model="promotionStatus" true-value="0" false-value="1">
              <label class="form-check-label text-info">
                &nbsp;실행상태로 생성
              </label>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">프로모션명</label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <input type="text" class="form-control" v-model="promoName">
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">할인<font class="text-secondary" style="font-size: small;">(원/%)</font></label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <input type="number" class="form-control text-end" v-model="promoRate">
              <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productPromotion.promoMethod">
                <option value="A" id="promoMethod" selected>원</option>
                <option value="P" id="promoMethod">%</option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">프로모션기간</label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">개시</span>
              <input type="date" class="form-control" v-model="choicePromotion.promoStart">
              <span class="input-group-text" id="basic-addon1">종료</span>
              <input type="date" class="form-control" v-model="choicePromotion.promoEnd">
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="productUpdate(productDetail.promoWork);">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList">취소</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>
    </div>
  </main>
</template>

<script>
//import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
//import _ from 'underscore';

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      productId: '',
      productDetail: {}, // 상세정보를 오브젝트형으로 변수처리
      productPrice: {},
      productPromotion: {},
      catList: [], // 상품카테고리리스트
      unitList: [], //판매단위리스트
      // pdtCatgory: "",
      // pdtUnit: "",
      deliList: [], // 배송비리스트
      choiceDelivery: {}, // 선택된 배송비정보
      choicePromotion: {}, //선택된 프로모션정보
      //param: "",
      promoList: [], //해당상품의 프로모션목록
      promoStart: "",
      promoEnd: "",
      cPromoFlag: ""
    };
  },
  // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;
    this.getProductDetail();
    this.getUnitList();
    this.getProductPrice();
    this.getProductPromotion();
    this.getDeliveryList();
    this.getPromotionList();

    console.log("buyerInfo:",this.$store.state.user.buyerId, "bGrade:", this.$store.state.user.buyerGrade);
    console.log("userInfo:",this.$store.state.user.email);
  },
  mounted() {
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.admin == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        text: '접근권한이 없습니다. 로그인을 해주세요.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    // mixins에 있는 date처리펑션
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async getUnitList() {
      let unitList = await this.$api("/api/unitList", {});
      this.unitList = unitList;
    },
    async getCategoryList() {
      let categoryList = await this.$api("/api/categoryList", {});
      this.catList = categoryList;
    },
    async getDeliveryList() {
      let deliveryList = await this.$api("/api/deliveryList", {});
      this.deliList = deliveryList;
      console.log("deliList:",this.deliList);
    },
    async getPromotionList() {
      let promotionList = await this.$api("/api/promotionList", {param: [this.productId]});
      this.promoList = promotionList;
      // console.log("promoList:",this.promoList, "pDetail:",this.productDetail.deliCategory);

    },
    goToList() {
      this.$router.push({path: '/sales'});
    },
    async getProductDetail() {
      // axios공통모듈을 호출하여 app.js를 타고 sql.js를 타고 데이타호출, 상품코드도 파라메타로 넘김
      if(this.salesRoute === "B") {
        this.productDetail = await this.$api("/api/productDetailB", {param: ['%', this.productId]});
      }
      else if(this.salesRoute === "C") {
        this.productDetail = await this.$api("/api/productDetailC", {param: [this.productId]});
      }
      else {
        this.$swal.fire('선택상품 정보에 오류가 있습니다.\n상품관리화면으로 복귀합니다.', '', 'error')
        this.$router.push({path: '/sales'});
      }
      // 상세정보가 있다면 첫번째값 하나만 가져온다
      if(this.productDetail.length > 0) {
        this.productDetail = this.productDetail[0]
      }
      this.productDetail.salesStart = moment( this.productDetail.salesStart).format("YYYY-MM-DD");
      this.productDetail.salesEnd = moment( this.productDetail.salesEnd).format("YYYY-MM-DD");
      console.log("product:",this.productDetail);
    },
    async getProductPrice() {
      let productPrice = await this.$api("/api/productPrice", {param: [this.productId]});
      this.productPrice = productPrice;
      // console.log("B2BPrice: " + Object.values(productB2BPrice[0]));
      console.log("pPrice:",this.productPrice);
    },
    async getProductPromotion() {
      let productPromotion = await this.$api("/api/productPromotion", {param: [this.productId]});
      //console.log(this.productId);
      console.log("pPromo:",this.productPromotion);

      if(productPromotion.length > 0) {
        this.productPromotion = productPromotion[0]
      }
      else{
        return false;
      }
      console.log("pM: " + productPromotion[0].promoMethod);
    },
    async changeDelivery() {
      // console.log(this.seqDeli);
      // this.param = "choice";
      let choiceDelivery = await this.$api("/api/choiceDelivery", {param: [this.seqDeli]});
      this.choiceDelivery = choiceDelivery[0];
      this.productDetail.deliCategory = this.seqDeli;
      //console.log("param:",this.param)
      // console.log(`deliCat:  ${this.productDetail.deliCategory}`);
    },
    async changePdtPromotion() {
      console.log("seqPromo:",this.seqPromo);
      //let choicePromotion = await this.$api("/api/choiceDelivery", {param: [this.seqDeli]});
      let choicePromotion = this.promoList.filter(e => {return e.seqPromo === this.seqPromo});
      this.choicePromotion = choicePromotion[0];
      this.choicePromotion.promoStart = moment(this.choicePromotion.promoStart).format("YYYY-MM-DD");
      this.choicePromotion.promoEnd = moment(this.choicePromotion.promoEnd).format("YYYY-MM-DD");
      console.log("cPromo:",this.choicePromotion, "cPromoFlag:", this.choicePromotion.useFlag);
      this.cPromoFlag = this.choicePromotion.useFlag;
      //console.log("cPromoFlag2:", this.choicePromotion.useFlag);
      // let tIamge_temp = this.productImage.filter((e)=>{
      //   return e.catImg === 'T'
      // })
      // this.choiceDelivery = choiceDelivery[0];
      // this.productDetail.deliCategory = this.seqDeli;
      //console.log("param:",this.param)
      // console.log(`deliCat:  ${this.productDetail.deliCategory}`);
    },
    async commonProductUpdate() { //상품정보 업데이트 처리(공통파라메타)
        let res_updateProductM = await this.$api("/api/updateProductM", 
          {param: [this.productDetail.pdtNameKor, this.productDetail.pdtNameEng, this.productDetail.salesUnit, this.productDetail.unitPrice, this.productDetail.deliCategory,
            this.productDetail.vatPrice, this.productDetail.salesStart, this.productDetail.salesEnd, this.productDetail.pdtTopic, this.productId]});
        if(res_updateProductM.affectedRows > 0) {
          //console.log("update Complete");
          if(this.productDetail.salesRoute === "B") {
            //console.log("b2b상품");
            let i = 0;
            while(i < 4) {
              let res_updateProductP = await this.$api("/api/updateProductP", 
                {param: [this.productPrice[i].pdtPrice, this.productDetail.vatPrice,this.productId,i+1]});
                console.log("res_upPrice:",res_updateProductP);
                  if(Object.keys(res_updateProductP)[0] === 'error') {
                    //alert("상품가격정보 수정오류\n결과확인 후 재실행");
                    this.$swal.fire('상품가격 수정오류\n현재정보확인 후 재실행필요', '', 'error')
                    return false;
                  }
              i++;
            }
            this.$swal.fire('상품정보 수정완료', '', 'success')
          }
          else {
            //console.log("B2C상품");
            let res_updateProductP = await this.$api("/api/updateProductP", 
              {param: [this.productDetail.pdtPrice, this.productDetail.vatPrice,this.productId,1]});
            //console.log("res_upPrice:",res_updateProductP.affectedRows);
            // if(Object.keys(res_updateProductP)[0] === 'error') {
            //   //alert("상품가격정보 수정오류\n결과확인 후 재실행");
            //   this.$swal.fire('상품가격 수정오류\n결과확인 후 재실행', '', 'alert')
            // }
            if(res_updateProductP.affectedRows < 0 || res_updateProductP.affectedRows === undefined) {
              //alert("상품가격정보 수정오류\n결과확인 후 재실행");
              this.$swal.fire('상품가격 수정오류\n현재정보확인 후 재실행 필요', '', 'error')
            }
            else {
              this.$swal.fire('상품정보 수정완료', '', 'success')
            }
          }
        }
        else {
          //console.log("update defeat");
          this.$swal.fire('상품정보 수정오류', '', 'error')
        }      
    },
    async productUpdate(uFlag) {
      // console.log("updateFlag:",uFlag, "checkPromoFlag:",this.cPromoFlag);
      if(uFlag === "N" || uFlag === undefined){
        this.commonProductUpdate(); //공통처리함수
      }
      else if(uFlag === "U") {
        if(this.seqPromo === undefined) {
          this.$swal.fire('적용프로모션 선택필요', '', 'question')
        }
        else {
          this.commonProductUpdate();
          // let datePromoStart = this.getDateFormat(this.productPromotion.promoStart);
          // let datePromoEnd = this.getDateFormat(this.productPromotion.promoEnd);
          let res_updateProductPromo = await this.$api("/api/updateProductPromo", 
            {param: [this.choicePromotion.promoStart, this.choicePromotion.promoEnd, this.cPromoFlag, this.choicePromotion.promoName, this.productId, this.seqPromo]});
            console.log("res_updateProductPromo:",res_updateProductPromo);
          if(res_updateProductPromo.affectedRows > 0) {
            this.$swal.fire('상품정보 수정완료', '', 'success')
          }
          else {
            this.$swal.fire('상품정보 수정에러\n현재정보확인 후 재실행 필요', '', 'error')
          }
        }
      }
      else if(uFlag === "C") {
        //console.log("Create", "pMethod:",this.promoRate, "pMethod:",this.productPromotion.promoMethod);
        this.commonProductUpdate();
        //프로모션 신규생성
        if(this.promotionStatus === undefined) {
          this.promotionStatus = "N"
        }
        //let sStatus = (saleStatus == "Y") ? "N" : "Y"
        //Insert Into Tb_Product_promo (pdtCode, salesRoute, promoStart, promoEnd, promoMethod, promoRate, insId, insDate, useFlag, promoName)
        // console.log("choicePromotion.promoName:", this.promoName, "promoStatus:",this.promotionStatus,","
        // , this.choicePromotion.promoStart," / ",this.choicePromotion.promoEnd,"promoRate:",this.promoRate,"promoMethod:",this.productPromotion.promoMethod);
        let resIns_product_promo = await this.$api("/api/productPromoInsert", 
          {param: [this.productId, this.productDetail.salesRoute, this.choicePromotion.promoStart, this.choicePromotion.promoEnd,
            this.productPromotion.promoMethod, this.promoRate, 'admin', this.promotionStatus, this.promoName]}); 
        console.log(resIns_product_promo);
      }
    }
  }
}
</script>
