<template>
  <div id="cp_page">
    <section>
      <!-- 메인 -->
      <div class="msmain">
        <div class="container">
          <!-- 로고 -->
          <!-- <div class="mlogo">
            <img src="../assets/img/new-test/ms/ntabi.png" class="logo ntabi">
            <img src="../assets/img/new-test/ms/nday.png" class="logo ndayt">
          </div> -->
          <div class="m-cont">
            <div class="text-center">
              <img src="../assets/img/coupon/secretCoupon01.jpg" class="img-fluid">
            </div>
          </div>
        </div>
      </div>

      <!-- 가입하기 -->
      <!-- <div class="join">
        <div class="container">
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productListForSales_ndaytrip_mo: [],  //스크롤상품

      autoScrollTimer: null,
      currentIndex: 0         //현재스크롤의 인덱스
    }
  },
  created() {
    this.user = this.$store.state.user;
    //this.memberId = this.$store.state.email;
    this.conEmail = this.user.email;
    //console.log("user:",this.user, "/", this.memberId, "/", this.conEmail);

    //우클릭 & 드래그 금지
    document.oncontextmenu = function()
    {
      return false;
    }
    document.ondragstart = function() {
      return false;
    }

    this.getProductList();
  },
  computed: {
    user() {
      // 로그인전: email정보없음, 로그인후: email정보있음
      return this.$store.state.user;
    },
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/");
    // }
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value){
      return this.$currencyFormat(value);
    },

    async getProductList()
    {
      //멤버쉽대상 버스투어
      let productListForSales_ndaytrip = await this.$api("/api/membershipForNDT", {param: ['%', '%']});
      if(productListForSales_ndaytrip.length > 0)
      {
        this.productListForSales_ndaytrip = productListForSales_ndaytrip
        //순환스크롤을 위해 배열복제
        this.productListForSales_ndaytrip_mo = [...this.productListForSales_ndaytrip, ...this.productListForSales_ndaytrip];
      }
      //console.log("productListForSales_ndaytrip_mo:",this.productListForSales_ndaytrip_mo.length)
    },

    stopAutoScroll() {
      clearInterval(this.autoScrollTimer);
    },

    stopAutoScroll_mo() {
      clearInterval(this.autoScrollTimer);
    },
    goToJoin() {
      this.$router.push({path: '/myPageInfoChange_joinM'});
    },
  }
}
</script>


<style>
@import "../assets/css/style.css";
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

</style>


