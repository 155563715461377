<template>
  <main class="mt-5">
    <div class="container qudecu">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <h2 class="d-flex justify-content-center" style="font-weight:600">견 적 답 변</h2>
          <p class="d-flex justify-content-center">[재요청이 가능하오니, 부담없이 요청주세요]</p>

          <div class="row justify-content-center">
            <div class="col-12 col-md-10">
              <div class="box">
                <hr>
                <p class="head">
                  ※ 안녕하세요 고객님! 어떤 여행을 준비하고 계신가요?<br>
                  담당 직원이 확인하여 곧 답변을 드리겠습니다.<br><br>
                  카카오톡 플러스 친구로 문의를 주시면,<br>
                  더욱 빠른 답변을 받아보실 수 있습니다.
                </p>
                <!-- <p class="rinfo"><input type="checkbox" v-model="this.quotationInfo.ruleAgree" class="align-bottom" ref="chb_ruleAgree" true-value="Y" false-value="N"> &nbsp;개인정보 수집에 동의합니다.</p> -->

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <input class="form-check-input" type="checkbox" v-model="this.quotationInfo.ruleAgree" ref="chb_ruleAgree" true-value="Y" false-value="N">
                      <label class="form-check-label" for="defaultCheck3">
                        <span class="text-danger px-2">[필수]</span> 개인정보 수집 및 이용동의
                      </label>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      엔타비 글로벌 (이하 '엔타비')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br>
    
                      1. 개인정보 수집 및 이용목적<br>
                      엔타비는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br>
                      &nbsp; 1) 홈페이지 회원가입 및 관리<br>
                      &nbsp; 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.<br>
                      &nbsp; 2) 서비스 제공<br>
                      &nbsp;  물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.<br>
                      &nbsp; 3) 마케팅 및 광고에의 활용<br>
                      &nbsp; 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
                      <br>
                      2. 개인정보 수집 항목<br>
                      엔타비는 다음의 개인정보 항목을 처리하고 있습니다.<br>
                      &nbsp; 1) 홈페이지 회원가입 및 관리/마케팅 정보 제공의 목적 (회원탈퇴 시 까지)<br>
                      &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름<br>
                      &nbsp; 2) 상담 및 해외 및 국내 여행 예약의 목적 (신용카드정보, 은행계좌정보)<br>
                      &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 여권사본, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호<br>
                      &nbsp; 3) 여행자 보험가입의 목적 (신용카드정보, 은행계좌정보)<br>
                      &nbsp;  이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름<br>
                      &nbsp; 4) 대금결제의 목적 (이용목적이 달성되면 파기)<br>
                      &nbsp;  신용카드정보, 은행계좌정보<br>
                        <br>
                      3. 개인정보 수집 항목 보유 및 이용기간<br>
                      &nbsp; 1) 엔타비는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.<br>
                      &nbsp; 2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
                      &nbsp;  ① 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                      &nbsp;  ② 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                      &nbsp;  ③ 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                      &nbsp;  ④ 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                      &nbsp;  ⑤ 표시/광고에 관한 기록 : 6개월<br>
                    </div>
                  </div>
                </div>

                <hr>                
                <div class="row my-1">
                  <!-- 견적상태/제목 -->
                  <div class="row my-1">
                    <div class="col-md-6 gx-3 my-2">
                      <label class="name col-form-label my-1">견적상태/번호</label>
                      <div class="input-group">
                        <select class="top form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_inquiry" v-model="this.quotationInfo.reqStatus" disabled>
                          <option :value="noVal" disabled>-- 진행상황 --</option>
                          <option :value="catList.procCode" :key="i" v-for="(catList, i) in inquiryCatList">
                            {{ catList.procName }}
                          </option>
                        </select>
                        <input type="text" class="form-control w-75 text-success" v-model = "this.quotationInfo.qtCode" readonly>
                      </div>
                    </div>
                    <div class="col-md-6 gx-3 my-2">
                      <label class="name col-form-label my-1">유형/제목</label>
                      <div class="input-group">
                        <select class="top form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_inquiry" v-model="this.quotationInfo.selCat" disabled>
                          <option :value="noVal" disabled>-- 여행유형 --</option>
                          <option :value="tc.procCode" :key="i" v-for="(tc, i) in tripCategroy">
                            {{ tc.procName }}
                          </option>
                        </select>
                        <input type="text" class="form-control w-75" ref="txt_reqTitle" v-model="this.quotationInfo.reqTitle" readonly>            
                      </div>
                    </div>
                  </div>
                  <!-- 요청자/인원수 -->
                  <div class="row my-1">
                    <div class="col-6 gx-3">
                      <label class="name col-form-label">요청자</label>
                      <input type="text" maxlength="12" class="form-control" ref="txt_reqPersonName" v-model="this.quotationInfo.reqPersonName" placeholder="요청자 성명">
                    </div>
                    <div class="col-6 gx-3">
                      <label class="name col-form-label">인원수</label>
                      <input type="number" maxlength="4" min = 1 class="form-control" ref="txt_perCnt" v-model="this.quotationInfo.perCnt" placeholder="인원수">
                    </div>
                  </div>
                  <!-- 이메일,연락처 -->
                  <div class="row my-1">
                    <div class="col-6 gx-3">
                      <label class="name col-form-label my-1">연락처</label>
                      <input type="text" maxlength="11" id="reqTel" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.quotationInfo.reqTel">
                    </div>
                    <div class="col-6 gx-3">
                      <label class="name col-form-label my-1">이메일</label>
                      <input type="email" maxlength="40" class="form-control" ref="txt_cEmail" v-model="this.quotationInfo.reqEmail">
                    </div>
                  </div>
                  <!-- 예상일정 -->
                  <div class="row mt-3">
                    <div class="col-md-12 gx-3">
                      <label class="name col-form-label">예상일정<font class="text-danger"> *</font></label>
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon1">시작</span>
                        <input type="date" class="form-control" ref="txt_StartDate" v-model="this.quotationInfo.startDate">
                        <span class="input-group-text" id="basic-addon1">종료</span>
                        <input type="date" class="form-control" ref="txt_CloseDate" v-model="this.quotationInfo.closeDate">
                      </div>
                    </div>
                  </div> 
                  <!-- 출발지등 -->
                  <div class="row mt-4">
                    <div class="col-md-12 gx-3">
                      <label class="name col-form-label">출발지/여행국가/여행지역<font class="text-danger"> *</font></label>
                      <div class="input-group mb-3" >
                        <!-- 출발도시 -->
                        <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_deptCity" v-model="this.quotationInfo.ctCd">
                          <option :value="cityList[i].baseCode" :key=i v-for="(baseCode,i) in cityList">{{cityList[i].codeNameKor}}</option>
                        </select> 
                        <!-- 여행국가 -->
                        <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripNation" v-model="this.quotationInfo.ntCd" @change="changeNationList(this.quotationInfo.ntCd)">
                          <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
                        </select>
                        <!-- 여행지역 -->
                        <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripArea" v-model="this.quotationInfo.arCd">
                          <option :value="areaList[j].baseCode" :key=j v-for="(baseCode,j) in areaList">{{areaList[j].codeNameKor}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- 교통편 -->
                  <div class="row my-2 justify-content-center">
                    <div class="col-md-12 gx-3">
                      <label class="gx-3 col-form-label name">교통편</label>
                      <div class="look form-check-inline align-middle">
                        <input class="form-check-input" type="checkbox" v-model="this.quotationInfo.transFlag" true-value="Y" false-value="N" @change="openTrans(this.quotationInfo.transFlag);">
                        <label class="form-check-label" for="ckb_transFlag">&nbsp; 보기 /{{this.quotationInfo.transFlag}}</label>
                      </div>
                    </div>    
                    <div v-if="this.quotationInfo.transFlag === 'Y'" class="col-md-10 ">
                      <!-- 출발편 -->
                      <div class="row border p-sm-2 border-info my-3">
                        <div class="mb-1 row">
                          <label class="col-md-3 col-form-label name">출발편<font class="text-danger">*</font></label>
                          <div class="col-md-9">
                            <div class="input-group mb-1">
                              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="this.quotationInfo.trCd1" @change="changeDpTransList()">
                                <option :value="noVal">비이용</option>
                                <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
                              </select>
                              <!-- <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="this.quotationInfo.mbCd1">
                                <option :value="mobilityList_dp[m].transCode" :key=m v-for="(transCode,m) in mobilityList_dp">{{mobilityList_dp[m].codeNameKor}}</option>
                              </select> -->
                              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="this.quotationInfo.mbCd1">
                                <option :value="noVal" disabled>선호교통편</option>
                                <option :value="mList.transCode" :key=m v-for="(mList,m) in mobilityList_dp">{{mList.codeNameKor}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="mb-1 row">
                          <label class="col-md-3 col-form-label name">추가내용</label>
                          <div class="col-md-9">
                            <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="quotationInfo.transRemarks_dp" maxlength="210"></textarea>
                          </div>
                        </div>
                      </div>
                      <!-- 리턴편 -->
                      <div class="row border p-sm-2 border-warning mt-3">
                        <div class="mb-1 row">
                          <label class="col-md-3 col-form-label name">리턴편</label>
                          <div class="col-md-9">
                            <div class="input-group mb-1">
                              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="quotationInfo.trCd2" @change="changeRtTransList">
                                <option :value="noVal">비이용</option>
                                <option :value="transList[q].baseCode" :key=q v-for="(baseCode,q) in transList">{{transList[q].codeNameKor}}</option>
                              </select>
                              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="quotationInfo.mbCd2">
                                <option :value="noVal" disabled>선호교통편</option>
                                <option :value="mobilityList_rt[r].transCode" :key=r v-for="(transCode,r) in mobilityList_rt">{{mobilityList_rt[r].codeNameKor}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="mb-1 row">
                          <label class="col-md-3 col-form-label name">추가내용</label>
                          <div class="col-md-9 mb-2">
                            <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="quotationInfo.transRemarks_ar" maxlength="210"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>

                  <!-- 문의사항 -->
                  <div class="row my-3">
                    <div class="col-md-12 gx-3">
                      <label class="name col-form-label">문의사항</label>
                      <textarea class="form-control" maxlength="250"  placeholder="기타문의사항(250자)" ref="txt_reqSpRemarks" id="floatingTextarea" style="height:150px" v-model="this.quotationInfo.reqSpRemarks"></textarea>
                    </div>
                  </div>

                  <hr style="margin:2.5rem 0 2rem">

                  <!-- 문의답변 -->
                  <div class="col-12 gx-3 my-1">
                    <label class="col-form-label my-1 name">
                      문의답변 / <span class="answer text-primary">답변자 ({{this.requestAnswer.handlerNameKor}})</span>
                    </label>
                    <textarea class="form-control" maxlength="999" ref="txt_ansContent" id="floatingTextarea" style="height:200px" v-model="this.requestAnswer.ansContent" readonly></textarea>
                  </div>
                </div>
              </div>

              <!-- 버튼 -->
              <div class="mt-5 row justify-content-center">
                <button class="btn cxl col-5 col-lg-4 mx-2"  @click="goToMyPage();">마이페이지</button>
                <button class="btn go col-5 col-lg-4 mx-2" @click="requestInsert()">재요청</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
export default {
  data() {
    return {
      inquiryCatList: [], //질의영역분류
      tripCategroy: [], //여행자카테고리
      cityList: [],
      nationList: [],
      areaList: [],
      areaList_tmp: [],
      transList: [],
      mobilityList: [],
      mobilityList_dp: [], //출발편
      mobilityList_rt: [], //리턴편

      //견적요청정보
      quotationInfo: [],

      respoComment_new: "", //신규코멘트
      requestAnswer: [], //답변내용
      answerFlag: "N" //답변여부
    };
  },
  created(){
    this.qtCode = this.$route.query.qtCode;
    this.seqReq = this.$route.query.seqReq;
    console.log("qtCode:",this.qtCode,"seqReq:",this.seqReq);

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }    

    this.getInquiryCatList();

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = "nonMember"
    }
    //console.log("userInfo:",this.user,"uName:",this.$store.state.user.handlerNameKor);
    console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);

    this.getRequestDetail();

    this.getCityList();
    this.getNationList();
    this.getAreaList();
  },
  mounted() {
    if(document.referrer == "") 
    {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }
  },  
  methods: {
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    //견적관련 프로세스 코드
    async getInquiryCatList() {
      let inquiryCatList = await this.$api("/api/inquiryCatList2", {param:["B"]});
      let tripCategroy = await this.$api("/api/inquiryCatList2", {param:["Q"]});

      if (inquiryCatList.length > 0) {
        this.inquiryCatList = inquiryCatList;
      }
      if (tripCategroy.length > 0) {
        this.tripCategroy = tripCategroy;
      }      
      console.log("inquiryCatList:",this.inquiryCatList);
    },
    //견적요청내용
    async getRequestDetail() 
    {
      let quotationInfo = await this.$api("/api/requestQuotationDetail", {param: [this.qtCode, this.seqReq]});
      if(quotationInfo.length > 0)
      {
        this.quotationInfo = quotationInfo[0];
        this.quotationInfo.startDate = this.getDateFormat(this.quotationInfo.startDate);
        this.quotationInfo.closeDate = this.getDateFormat(this.quotationInfo.closeDate);
      }
      console.log("quotationInfo:",this.quotationInfo);

      if(this.quotationInfo.transFlag === 'Y')
      {
        this.getTransList();
        this.getMobilityList1();
      }
      this.getReqAnswer(); //답변데이타      
    },
    //문의답변체크
    async getReqAnswer() {
      let requestAnswer = await this.$api("/api/requestAnswerForQuotation", {param: [this.qtCode, this.seqReq]});
      if(requestAnswer.length > 0)
      {
        this.requestAnswer = requestAnswer[0];
      }
      console.log("requestAnswer:",this.requestAnswer);
    },
    async getCityList() 
    {
      let cityList = await this.$api("/api/cityList", {});
      this.cityList = cityList;
      //console.log("cityList",cityList);
    },
    async getNationList() 
    {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    async getAreaList() 
    {
      //this.areaList_tmp = await this.$api("/api/areaList", {});
      this.areaList = await this.$api("/api/areaList", {});
      console.log("areaList_tmp",this.areaList_tmp);
    },
    async changeNationList(param) 
    {
      //this.nationCode = this.productInfo.baseCode;
      this.areaList = await this.$api("/api/areaList", {});      
      this.viewAreaList(param);
    },
    viewAreaList(param1)
    {
      this.areaList = this.areaList.filter(e => {return e.categoryCode === param1});
    },

    openTrans(val) {
      if(val === "Y")
      {
        this.getTransList();
        this.getMobilityList();
      }
    },
    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      console.log("transList",transList);
    },
    async getMobilityList() {
      //this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      this.mobilityList = await this.$api("/api/mobilityList", {});
      console.log("mobilityList:",this.mobilityList);
    },
    async getMobilityList1() {
      let mobilityList_tmp = await this.$api("/api/mobilityList", {});
      console.log("mobilityList_tmp:", mobilityList_tmp);
      this.mobilityList_dp = mobilityList_tmp.filter(e => {return e.transCode === this.quotationInfo.mbCd1});
      this.mobilityList_rt = mobilityList_tmp.filter(e => {return e.transCode === this.quotationInfo.mbCd2});
    },    
    //교통수단변경->교통편획득
    changeDpTransList() {
      this.quotationInfo.mbCd1 = "";
      this.viewDpMobilityList(this.quotationInfo.trCd1);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    changeRtTransList() {
      this.quotationInfo.mbCd2 = "";
      this.viewRtMobilityList(this.quotationInfo.trCd2);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    async viewDpMobilityList(param1){
      //alert("categoryCode:" + param1);
      let mobilityList_d = await this.$api("/api/mobilityList", {});
      this.mobilityList_dp = mobilityList_d.filter(e => {return e.categoryCode === param1});
      //console.log("mobility:", this.mobilityList_dp);
    },
    async viewRtMobilityList(param2){
      //alert("categoryCode:" + param2);
      let mobilityList_r = await this.$api("/api/mobilityList", {});
      this.mobilityList_rt = mobilityList_r.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList_rt);
    },
    async requestInsert() {
      //alert(this.quotationInfo.passFlag);
      //오늘날짜추출
      // const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      // const time = new Date().toTimeString().split(" ")[0];
      // let today = date + ' ' + time;
      // let timeStamp = new Date().getTime();
      //let qtCode = "QT-" + date.replace(/-/g, '') + "-" + timeStamp;
      // this.quotationInfo.qtCode = qtCode;
      // this.quotationInfo.insId = this.user;
      console.log("quotationInfo:",this.quotationInfo);

      if(this.quotationInfo.ruleAgree === "N" || this.quotationInfo.ruleAgree == undefined) 
      { 
        this.$refs.chb_ruleAgree.focus();
        return this.$swal.fire("","진행을 위해 개인정보 수집의 동의가 필요합니다.", "warning");
      }
      if(this.quotationInfo.selCat === "noVal" || this.quotationInfo.selCat == undefined) { 
        this.$refs.sel_inquiry.focus();
        return this.$swal.fire("","유형을 선택해주세요.", "question");
      }
      if(!this.quotationInfo.reqTitle) { 
        this.$refs.txt_reqTitle.focus();
        return this.$swal.fire("","문의제목을 입력하세요", "warning");
      }
      if(!this.quotationInfo.reqPersonName) {
        this.$refs.txt_reqPersonName.focus();
        return this.$swal.fire("","요청자 성명을 입력하세요", "warning");
      }
      if(!this.quotationInfo.perCnt) {
        this.$refs.txt_perCnt.focus();
        return this.$swal.fire("","인원수를 입력하세요", "warning");
      }
      else if(this.quotationInfo.perCnt < 1) {
        this.$refs.txt_perCnt.select();
        return this.$swal.fire("","최소 1인 이상으로 입력해주세요.", "info");
      }      
      // else if(this.quotationInfo.perCnt > 15) {
      //   this.$refs.txt_perCnt.select();
      //   return this.$swal.fire("","15인 이상은 별도 문의를 해주세요<br>(TEL) 051-466-4602", "info");
      // }
      if(!this.quotationInfo.reqEmail) {
        this.$refs.txt_cEmail.focus();
        return this.$swal.fire("","이메일을 입력하세요", "warning");
      }
      if(!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]+$/.test(this.quotationInfo.reqEmail)) {
        this.$refs.txt_cEmail.focus();
        return this.$swal.fire('','이메일 양식이 불명확합니다', 'question');
      }
      if(!this.quotationInfo.reqTel) {
        this.$refs.txt_cTel.focus();
        return this.$swal.fire("","연락처를 입력하세요", "warning");
      }
      if(!/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(this.quotationInfo.reqTel)) {
        this.$refs.txt_cTel.focus();
        return this.$swal.fire('','연락처가 불명확합니다', 'question');
      }
      if(!this.quotationInfo.startDate) {
        //this.$refs.startDate.focus();
        return this.$swal.fire("","출발일을 입력하세요", "warning");
      }
      if(!this.quotationInfo.closeDate) {
        //this.$refs.closeDate.focus();
        return this.$swal.fire("","종료일을 입력하세요", "warning");
      }
      if(!this.quotationInfo.reqSpRemarks) {
        this.$refs.txt_reqSpRemarks.focus();
        return this.$swal.fire("","문의내용을 입력하세요", "warning");
      }
      //this.quotationInfo.insDate = today;
      //let reRequestQuotationTitle = "[재요청]"+this.quotationInfo.reqTitle
      this.quotationInfo.reqStatus = "IQ";
      this.quotationInfo.ansFlag = 'N'
      //this.quotationInfo.insId = this.user;

      let resIns_Request = await this.$api("/api/re_reqQuotationInsert", {param: [
        this.quotationInfo.reqTitle,this.quotationInfo.reqPersonName,this.quotationInfo.perCnt,this.quotationInfo.reqEmail,this.quotationInfo.reqTel,this.quotationInfo.transFlag,this.quotationInfo.reqSpRemarks,this.quotationInfo.ruleAgree,this.quotationInfo.reqStatus,this.user,this.quotationInfo.qtCode,this.quotationInfo.startDate,this.quotationInfo.closeDate,this.quotationInfo.ctCd,this.quotationInfo.ntCd,this.quotationInfo.arCd,this.quotationInfo.trCd1,this.quotationInfo.trCd2,this.quotationInfo.mbCd1,this.quotationInfo.mbCd2,this.quotationInfo.transRemarks_dp,this.quotationInfo.transRemarks_ar,this.quotationInfo.selCat,this.quotationInfo.ansFlag,'Y'
      ]});
        console.log("errCheck:", Object.keys(resIns_Request)[0]);
        // 인서트오류
        if(Object.keys(resIns_Request)[0] === 'error') 
        {
          this.$swal.fire('', '견적재요청 저장 중 오류가 발생했습니다.<br>(문의처: 1660-4602)', '')
        }      
      else 
      {
        this.$swal.fire('','감사합니다<br>빠른 시간안에 확인연락을 드리겠습니다.', '');
        this.$router.push({path: '/myPage'});
      }
      //console.log("resIns_Request:",resIns_Request);
    },    

    goToMyPage() {
      this.$router.push({path: '/myPage'});
    }
  }
}
</script>



<style>
.qudecu{
  text-align:left
}
.qudecu .head{
  margin-top:1.5rem;
  text-align: center;
}
.qudecu .qinfo{
  text-align: center;
  margin:1.5rem 0 2rem;
}
.qudecu .name{
  font-size:.9rem;
  font-weight:bold
}
.qudecu .top{
  background:none;
  background-color: #e9ecef;
  border-top-right-radius: 0;
  border-bottom-right-radius:0;
  padding: 0.3rem 0.4rem ;
  text-align: center;
}

.qudecu .input-group{
  height: 40px;
}
.qudecu .form-select{
  border-radius: 0.375rem;
}

.qudecu .look{
  padding-left:1.5rem;
  font-size:.9rem
}

.qudecu .answer{
  font-size:.75rem;
  color:blue
}

.qudecu .go {
  padding:15px 0;
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.qudecu .cxl{
  padding:15px 0;
  background-color: #eee;
  border-radius:0;
}
.qudecu .go:hover{
  background-color: #444;
}
.qudecu .cxl:hover{
  border:1px solid #777
}


/* 동의부분 */
.qudecu .accordion-button{
  display:block;
  text-align: center;
  padding:10px 0 15px
}

.qudecu .accordion-body{
  font-size:.8rem;
  line-height: 20px;
}
</style>
