<template>
  <div id="product">
    <div class="item-area section-padding pb-bottom">
      <div class="container">
        <div class="row">

          <!-- left area -->
          <div class="col-12 col-lg-9 pr-left">
            <!-- 프로모션기간이 있으면 프로모션기간 -->
            <div class="ppro mb-2">
              <span class="badge text-white fs-6"  style="background-color:rgba(179, 6, 35, 0.8);" v-if="pdtPromoInfo.promoName != undefined">
                <b style="font-size:small;"><i class="bi bi-megaphone-fill"></i></b>&nbsp;
                  <small>{{getDateFormat(pdtPromoInfo.promoStart)}} ~ {{getDateFormat(pdtPromoInfo.promoEnd)}}&nbsp; 출발할인!</small>
              </span>&nbsp;&nbsp;
              <span class="badge text-white fs-6"  style="background-color:goldenrod;" v-if="productDetail.memLevel_P == 'Y'">
                <small style="vertical-align: top;">
                  Premium
                  <!-- <span v-if="maxDC.maxP < 0">({{ maxDC.maxP }}%환급)</span>
                  <span v-else>(제외상품)</span> -->
                </small>
              </span>&nbsp;&nbsp;
              <span class="badge text-white fs-6"  style="background-color:royalblue;" v-if="productDetail.memLevel_S == 'Y'">
                <small style="vertical-align: top;">
                  Standard
                  <!-- <span v-if="maxDC.maxS > 0">({{ maxDC.maxS }}%환급)</span>
                  <span v-else>(제외상품)</span> -->
                </small>
              </span>
            </div>

            <!-- 타이틀 -->
            <div class="section-tittle mt-2 mb-10">
              <h2>{{productDetail.pdtNameKor}}</h2>
              <p class="text-secondary" style="font-size:small; padding-left: 10px;">{{productDetail.pdtRemarks}}</p>
              <hr style="opacity:1; border-top:2px solid">
            </div>

            <!-- 사진 -->
            <div v-if="productImage.length > 0">
              <div id="carouselDetail" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                <div class="carousel-indicators">
                  <button type="button"
                          v-for="(slide, index) in productImage.length"
                          :key="index"
                          :data-bs-target="'#carouselDetail'"
                          :data-bs-slide-to="index"
                          :class="{ active: index === 0 }"
                          :aria-current="index === 0 ? 'true' : null"
                          :aria-label="'Slide ' + (index + 1)">
                  </button>
                </div>
                <div class="carousel-inner">
                  <div :class="`carousel-item ${i==0 ? 'active' : ''}`" :key="i" v-for="(dImg, i) in productImage">
                    <img :src="`/download/${productId}/${dImg.imgName}`" class="d-block w-100" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselDetail" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselDetail" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <!-- <div v-else>
              <img :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
            </div> -->

            <!-- 상품번호 -->
            <div class="text-end">
              <span class="subheading">상품번호 : {{productDetail.pdtCode}}</span>
            </div>

            <!-- 교통편,체크포인트 -->
            <div class="vech">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="stand">출&nbsp;&nbsp;&nbsp;&nbsp;발</td>
                    <td class="sub" v-if="productDetail.salesRoute === 'E'"> - </td>
                    <td class="sub" v-else>
                      <div v-if="productDetail.transFlag != '0'">
                        <b>교통편 </b> <span style="color:dimgray ">불포함</span>
                      </div>
                      <div v-else>
                        <b v-if="this.productDetail.salesRoute === 'L'"><span class="depart-de"></span>
                          <span style="color:black">
                            {{pdtTransInfo1.dpTpCode}}
                            <!-- {{pdtTransInfo1.dmobileCodeName}} {{pdtTransInfo1.dpTpCode}} -->
                          </span>
                        </b>
                        <b v-else style="color:black; font-size:1rem">[출  국]&nbsp;&nbsp;
                          <span style="color:black">
                            {{pdtTransInfo1.dmobileCodeName}} ({{pdtTransInfo1.dmobileCode}}{{pdtTransInfo1.dpTpCode}})
                          </span>
                        </b>
                        <span class="brdown" v-if="this.productDetail.salesRoute === 'T' || this.productDetail.salesRoute === 'F'">
                          <span style="color:black">
                            {{pdtTransInfo1.ddCodeName}}({{pdtTransInfo1.d_dpTime}})
                            <i class="bi bi-caret-right-fill"></i>
                            {{pdtTransInfo1.daCodeName}}({{pdtTransInfo1.d_arTime}})
                          </span>
                        </span>
                      </div>
                      <div>
                        <small v-html="getStringFormat(this.productDetail.transRemarks_dp)"></small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="stand">도&nbsp;&nbsp;&nbsp;&nbsp;착</td>
                    <td class="sub" v-if="productDetail.salesRoute === 'E'"> - </td>
                    <td class="sub" v-else>
                      <div v-if="productDetail.transFlag != '0'">
                        <b>교통편 </b>  <span style="color:black ">불포함</span>
                      </div>
                      <div v-else>
                        <b v-if="this.productDetail.salesRoute==='L'">
                          <span style="color:black">
                            {{pdtTransInfo1.rtTpcode}}
                            <!-- {{pdtTransInfo1.rmobileCodeName}} {{pdtTransInfo1.rtTpcode}} -->
                          </span>
                        </b>
                        <b v-else style="color:black; font-size:1rem">[귀  국]&nbsp;&nbsp;
                          <span style="color:black">
                            {{pdtTransInfo1.rmobileCodeName}} ({{pdtTransInfo1.rmobileCode}}{{pdtTransInfo1.rtTpcode}})
                          </span>
                        </b>
                        <span class="brdown" v-if="this.productDetail.salesRoute === 'T' || this.productDetail.salesRoute === 'F'">
                          <span style="color:black">
                            {{pdtTransInfo1.rdCodeName}}({{pdtTransInfo1.r_dpTime}})
                            <i class="bi bi-caret-right-fill"></i>
                            {{pdtTransInfo1.raCodeName}}({{pdtTransInfo1.r_arTime}})
                          </span>
                        </span>
                      </div>
                      <div>
                        <small v-html="getStringFormat(this.productDetail.transRemarks_ar)"></small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="stand text-danger">체크사항</td>
                    <td class="sub">
                      <div class="text-start">
                        <small class="checkPoint">{{this.productDetail.supLookup}}</small>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="pdtOptFlag === 'Y'">
                    <td class="stand text-primary">
                      선택옵션
                      <!-- <p style="font-size:small; font-weight:normal; color:black;">(금일기준)</p> -->
                    </td>
                    <td class="sub">
                      <div class="text-start">
                        <p class="mt-0 mb-1" :key="i" v-for="(op, i) in pdtOptInfo_start">
                          <small class="checkPoint"><b><i class="bi bi-check2 text-primary"></i> {{ op.optPdtName }}</b>
                            <p style="padding-left:2rem;">{{ op.optPdtRemarks }}</p>
                          </small>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="addPdtFlag === 'Y'">
                    <td class="stand text-success">
                      추가상품
                      <!-- <p style="font-size:small; font-weight:normal; color:black;">(금일기준)</p> -->
                    </td>
                    <td class="sub">
                      <div class="text-start">
                        <p class="mt-0 mb-1" :key="j" v-for="(ap, j) in addPdtInfo_start">
                          <small class="checkPoint"><b><i class="bi bi-check2 text-success"></i> {{ ap.addPdtName }}</b>
                            <p style="padding-left:2rem;">{{ ap.addPdtRemarks }}</p>
                          </small>
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- 포함,불포함 -->
            <div class="in-non">
              <table class="table">
                <thead>
                  <tr>
                    <th style="color: #0004d6"><i class="fa-regular fa-circle fa-xs"></i> &nbsp;포함사항</th>
                    <th style="color: #e20400"><i class="fa-solid fa-x fa-xs"></i> &nbsp;불포함사항</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-6"><pre class="inclusion">{{ this.productDetail.inContent }}</pre></td>
                    <td class="col-6"><pre class="notInclusion">{{ this.productDetail.exContent }}</pre></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- 카테고리 -->
            <div class="category">
              <div class="container">
                <div class="tab-content mt-4">
                  <!-- 구분 -->
                  <section class="clist">
                    <div class="p-1">
                      <ul class="nav nav-tabs justify-content-center">
                        <li class="nav-item">
                          <a data-bs-toggle="tab">
                            <a class="nav-link active show" href="#tab-1"><h4>상품설명</h4></a>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a data-bs-toggle="tab">
                            <a class="nav-link" href="#tab-2"><h4>안내사항</h4></a>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a data-bs-toggle="tab">
                            <a class="nav-link" href="#tab-3"><h4>취소안내</h4></a>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a data-bs-toggle="tab">
                            <a class="nav-link" href="#tab-4"><h4>이용후기</h4></a>
                          </a>
                        </li>
                        <li class="nav-item" @click="inquiryProduct()">
                          <a data-bs-toggle="tab">
                            <a class="nav-link" href="#"><h4>상품문의</h4></a>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </section>

                  <!-- 상품상세이미지 -->
                  <div class="tab-pane mb-5 active show" id="tab-1">
                    <div class="row">
                      <div class="row" v-if="this.productDetailImage.length > 0">
                        <div class="col-12" :key="i" v-for="(detailImg, i) in productDetailImage">
                          <img :src="`/download/${productId}/${detailImg.imgName}`" class="img-fluid" />
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-12">
                          <img :src="`/download/noImage/noImage.jpg`" class="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 안내사항 -->
                  <div class="tab-pane my-5" id="tab-2">
                    <div class="row">
                      <div class="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                        <h4>안내사항</h4>
                        <hr style="color:#bbb;">
                        <pre class="supPre">{{ this.productDetail.supPrecaution }}</pre>
                      </div>
                    </div>
                  </div>

                  <!-- 취소안내 -->
                  <div class="tab-pane my-5" id="tab-3">
                    <div class="row">
                      <div class="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                        <h4>취소수수료 안내</h4>
                        <hr style="color:#bbb;">
                        <!-- <p>{{ getStringFormat(this.productDetail.supCancellation) }}</p> -->
                        <pre class="supCxl">{{ this.productDetail.supCancellation }}</pre>
                      </div>
                    </div>
                  </div>

                  <!-- 이용후기 -->
                  <div class="tab-pane creview my-5" id="tab-4">
                    <div class="row d-flex justify-content-between align-items-start">
                      <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                        <h4>이용후기</h4>
                        <!-- <p class="text-secondary mt-0">
                          상품이용 후, 30일 이내에 작성된 내용입니다.
                        </p> -->
                      </div>
                    </div>
                    <hr style="color:#bbb;">

                    <!-- 상품별 후기리스트 -->
                    <div class="container">
                      <div v-if="this.reviewList.length > 0">
                        <div class="row" :key=i v-for="(rl, i) in this.reviewList">
                          <div class="col-lg-3 mt-2">
                            <img v-if="!rl.imgName || rl.iUseFlag == 'N'" :src="`/download/noImage/noImage2.jpg`" class="img-fluid" style="width: 250px; height:200px; border: 1px dotted lightgrey;" title="리뷰어의 이미지가 없습니다" />
                            <img v-else :src="`/download/review/${rl.revCode}/${rl.imgName}`" class="img-fluid" style="width: 250px; height:200px" />
                          </div>
                          <div class="c-content col-lg-9 text-start align-self-center">
                            <div class="reviewt d-inline-block text-truncate" style="max-width: 330px;" v-if="rl.insId === this.user.email">
                              {{ rl.nTitle }}&nbsp;
                              <button type="button" class="btn btn-outline-secondary btn-sm me-1 align-top" @click="changeReview(rl.revCode, rl.seqNotice, rl.seqImg);">
                                <span style="font-size:small">변경</span>
                              </button>
                            </div>
                            <div class="reviewt d-inline-block text-truncate" style="max-width: 330px;" v-else>{{ rl.nTitle }}</div>
                            <div class="reviewn text-end">
                              <span class="name">{{ rl.rId.substring(0,3) }}○○○님</span>
                              <span class="date">{{ this.getDateFormat(rl.rDate) }}</span>
                            </div>
                            <div class="reviewc">
                              <pre class="reviewContent">{{ rl.nContent }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="row">
                          <div class="col-lg-3 mt-2 text-center">
                            아직 등록된 후기가 없습니다.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 오른쪽 리모콘 -->
          <div class="col-md-3">
            <div class="pr-right">
              <!-- 달력 -->
              <div class="container">
                <!-- <div class="top">
                  <span>이용일자</span>
                </div> -->
                <div class="calendar">
                  <div class="calendar-header">
                    <button class="calendarbtn" @click="previousMonth()">
                      <i class="bi bi-chevron-left"></i>
                    </button>
                    <div class="d-flex align-items-center">
                      <button class="calendarbtn mt-1" @click="goToCurrentMonth()">
                        <i class="bi bi-calendar3"></i>
                      </button>
                      <div class="calendar-header-date">
                        {{ this.currentDate ? this.currentDate.format('YYYY-MM') : '' }}
                      </div>
                    </div>
                    <button class="calendarbtn" @click="nextMonth()">
                      <i class="bi bi-chevron-right"></i>
                    </button>
                  </div>
                  <div class="calendar-body">
                    <div class="calendar-row">
                      <div class="calendar-day-header text-danger">일</div>
                      <div class="calendar-day-header">월</div>
                      <div class="calendar-day-header">화</div>
                      <div class="calendar-day-header">수</div>
                      <div class="calendar-day-header">목</div>
                      <div class="calendar-day-header">금</div>
                      <div class="calendar-day-header text-primary">토 </div>
                    </div>
                    <div v-for="(week, index) in weeks" :key="index" class="calendar-row">
                      <div v-for="(day, index) in week" :key="index" class="calendar-day"
                          :class="{ today: isToday(day.date), selected: isSelected(day.date)}" @click="goTo('resBtn');selectDate(day.date)">
                        <div v-if="day.date && day.date.day() === 0" class="calendar-date text-danger">
                          {{ day.date.date() }}
                        </div>
                        <div v-else-if="day.date && day.date.day() === 6" class="calendar-date text-primary">
                          {{ day.date.date() }}
                        </div>
                        <div v-else-if="day.date && (day.date.day() !== 6 && day.date.day() !== 0)" class="calendar-date">
                          {{ day.date.date() }}
                        </div>
                        <div v-if="day.price" class="calendar-price">
                          {{ getCurrencyFormat(formatManCurrency(day.price)) }}<span style="font-size: smaller; color:darkgray;">만</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style="margin:0px 20px 0; opacity:.5" v-if="this.inquiryFlag === 'Y'">
              <!-- 가격 -->
              <div class="price" v-if="this.inquiryFlag === 'Y'">
                <table>
                  <tr class="row col-12 text-center">
                    <td><i class="bi bi-check2-circle" style="font-size:small; color:brown;"></i> {{ this.selectedDate }} ({{ this.getDayOfWeek(this.selectedDate) }})</td>
                  </tr>
                  <tr class="row col-12" :key=i v-for="(scp,i) in this.selectedCalPdtPrice">
                    <td class="col-5 text-start gx-4"
                      v-if="(this.salesRoute === 'T' || this.salesRoute === 'F' || this.salesRoute === 'L' || this.salesRoute === 'E') && scp.agegroup != '4'">
                      <div v-if="scp.pdtPriceName == '' || scp.pdtPriceName == undefined" style="font-weight: normal;">
                        {{scp.ageName}}&nbsp;
                        <a href="javascript: void(0)" @click="popover('A','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="만 12세 이상" v-if="scp.ageName==='성인'">
                          <i class="bi bi-question-square text-sm-muted"></i>
                        </a>
                        <a href="javascript: void(0)" @click="popover('C','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="만 02세 이상" v-else-if="scp.ageName==='소아'">
                          <i class="bi bi-question-square text-sm-muted"></i>
                        </a>
                        <a href="javascript: void(0)" @click="popover('I','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="생후 24개월 미만" v-else-if="scp.ageName==='유아'">
                          <i class="bi bi-question-square text-sm-muted"></i>
                        </a>
                        <a href="javascript: void(0)" @click="popover('D','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="할인조건 만족시" v-else-if="scp.ageName==='할인'">
                          <i class="bi bi-question-square text-sm-muted"></i>
                        </a>
                      </div>
                      <div v-else-if="scp.pdtPriceName != ''">
                        {{scp.pdtPriceName}}&nbsp;
                      </div>
                      <div v-if="scp.agegroup != '4'">
                        <b style="color:darkgreen; font-size:1.3rem;">{{getCurrencyFormat(scp.sMinPrice)}}</b>&nbsp;
                        <small class="text-muted">원</small>
                      </div>
                    </td>
                    <td class="col-5" style="text-align:center; background-color: white" v-else-if="this.salesRoute === 'S' && scp.agegroup != '4'">
                      <div v-if="scp.pdtPriceName == '' || scp.pdtPriceName == undefined">
                        가격&nbsp;
                      </div>
                      <div v-else-if="scp.pdtPriceName != ''">
                        {{scp.pdtPriceName}}&nbsp;
                      </div>
                    </td>

                    <td class="col-7 count align-self-center">
                      <div class="btn-group" v-if="scp.pdtPrice > 0 || scp.agegroup != '4' ">
                        <button class="btn left" @click="changeUncheckedSelOptions_new(),minus(scp.ageName)"><i class="bi bi-dash-lg text-danger"></i></button>
                        <span class="number">{{ this.ageNumber[i+1] }}</span>
                        <button class="btn right" @click="changeUncheckedSelOptions_new(),plus(scp.ageName)"><i class="bi bi-plus-lg text-primary"></i></button>
                      </div>
                    </td>
                  </tr>

                  <!-- 선택옵션 -->
                  <!-- <tr class="row col-12" v-if="this.selectableOptInfo.length > 0">
                    <td class="row col-12">
                      <div style="text-align:start; background-color: white; font-weight: normal;">
                        선택옵션
                        <div class="border rounded" style="width:295px;">
                          <div class="d-flex align-items-center p-1" :key="i" v-for="(op, i) in selectableOptInfo">
                            <span style="font-size:0.9rem; padding-left:15px; width:65%">
                              {{ op.optPdtName }}&nbsp;
                              <span style="font-size: small; color:gray;">({{ getCurrencyFormat(op.optPdtPrice) }})</span>
                            </span>
                            <span style="font-size:0.9rem; width:35%; margin-left: 20px;">
                              <input type="checkbox" v-model="op.isChecked" :value="op.optCode"  ref="optCode" class="align-baseline mt-3" @change="checkSelOption(op.optCode, op.isChecked),scrollToPosition('p_resBtn')">
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr> -->

                  <tr class="row col-12" v-if="this.selectableOptInfo.length > 0">
                    <td class="row col-12">
                      <div style="text-align:start; background-color: white; font-weight: normal;">
                        선택옵션
                        <div class="border rounded" style="width:295px;">
                          <select class="form-select form-select-sm" v-model="optCode" @change="checkSelOption_new(optCode),scrollToPosition('p_resBtn')">
                            <option :value="noVal" disabled>
                              -- 선택옵션리스트 --
                            </option>
                            <option value="noCode">
                              선택안함
                            </option>
                            <option :value="op.optCode" :key="j" v-for="(op,j) in selectableOptInfo ">
                              {{ op.optPdtName }}
                              <!-- &nbsp;
                              <span style="font-size: small; color:gray;">({{ getCurrencyFormat(op.optPdtPrice) }})</span> -->
                            </option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>


                  <!-- 추가상품 -->
                  <tr class="row col-12" v-if="this.selectableAddPdtInfo.length > 0">
                    <td class="row col-12">
                      <div style="text-align:start; background-color: white; font-weight: normal;">
                        추가상품
                        <div class="border rounded" style="width:295px;">
                          <div class="d-flex align-items-center p-1" :key="j" v-for="(ap, j) in selectableAddPdtInfo">
                            <span style="font-size:0.9rem; width:65%; margin-left: 15px;">
                              {{ ap.addPdtName }}
                            </span>
                            <span style="font-size:0.9rem; width:35%; margin-left: 20px;">
                              <button class="btn btn-sm left" @click="calCnt('a', 'm', ap.addPdtCode),scrollToPosition('p_resBtn')"><i class="bi bi-dash-circle-fill text-danger"></i></button>
                              <span class="number">{{ addPdtCnts[ap.addPdtCode] }}</span>
                              <button class="btn btn-sm right" @click="calCnt('a', 'p', ap.addPdtCode),scrollToPosition('p_resBtn')"><i class="bi bi-plus-circle-fill text-primary"></i></button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <hr class="mt-4 mb-5" style="margin:0px 20px 0; opacity:.5"  v-if="this.etcOptionInfo.length > 0 &&  this.inquiryFlag === 'Y'">

              <div id="resBtn"></div>

              <!-- 상품가격 -->
              <div class="total-P mt-1 mb-2" v-if="this.inquiryFlag === 'Y'">
                <table>
                  <tr>
                    <td style="width:50%;">
                      <div>
                        <b style="font-size:1.2rem;">가격비교</b>
                      </div>
                    </td>

                    <!-- 시스템에서 사용하는 가격 -->
                    <td style="text-align:end; width:50%; display:none;" v-if="this.dispFlag === 'c2' || this.dispFlag === 'c4' || this.dispFlag === 'c5'">
                      <b class="text-danger fs-3">{{ this.getCurrencyFormat(this.showPrice*1 - this.tot_memDC*1) }}</b> 원
                    </td>
                    <td style="text-align:end; width:50%; display:none;" v-if="this.dispFlag == undefined || this.dispFlag == ''">
                      <b class="text-danger fs-3">{{ this.getCurrencyFormat(this.showPrice) }}</b> 원
                    </td>
                    <td style="text-align:end; width:50%; display:none;" v-if="this.dispFlag == 'c1' || this.dispFlag == 'c3'">
                      <b class="text-danger fs-3">{{ this.getCurrencyFormat(this.showPrice) }}</b> 원
                    </td>

                  </tr>
                  <tr style="font-size: 1.0rem">
                    <td style="width:50%">
                      <div>
                        <b style="font-size:1.0rem">
                          <img src="../assets/img/gBus.png" alt="" style="width:2.5rem; height:auto">일반회원
                        </b>
                      </div>
                      <div>
                        <b style="font-size:1.0rem">
                          <img src="../assets/img/sBus.png" alt="" style="width:2.5rem; height:auto">스탠다드
                        </b>
                      </div>
                      <div>
                        <b style="font-size:1.0rem">
                          <img src="../assets/img/pBus.png" alt="" style="width:2.5rem; height:auto">프리미엄
                        </b>
                      </div>
                    </td>
                    <td style="text-align:end; width:50%;">
                      <div>
                        <b style="color:slategray; font-size:1.5rem; font-weight:bold; padding-left:30px;">{{ this.getCurrencyFormat(this.showPrice_G) }}</b> 원
                      </div>
                      <div>
                        <b style="color:royalblue; font-size:1.5rem; font-weight:bold; padding-left:30px;">{{ this.getCurrencyFormat(this.showPrice_S) }}</b> 원
                      </div>
                      <div>
                        <b style="color:goldenrod; font-size:1.5rem; font-weight:bold; padding-left:30px;">{{ this.getCurrencyFormat(this.showPrice_P) }}</b> 원
                      </div>
                    </td>

                    <td style="text-align:end; width:50%; display:none;" v-if="this.dispFlag == 'c1'">
                      <b style="color:goldenrod; font-size:1.5rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memRefund) }}</b> 원
                    </td>
                    <td style="text-align:end; width:50%; display:none;" v-else-if="this.dispFlag == 'c3'">
                      <b style="color:slategray; font-size:1.5rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memRefund) }}</b> 원
                    </td>
                  </tr>

                  <!-- DB처리영역 -->
                  <tr v-if="this.dispFlag == 'c1' || this.dispFlag == 'c3'" style="display:none;">
                    <td style="width:50%">
                      <div>
                        <b style="font-size:1.0rem" v-if="this.dispFlag == 'c1'">
                          <img src="../assets/img/pBus.png" alt="" style="width:2.5rem; height:auto">
                          멤버십 환급액
                        </b>
                        <b style="font-size:1.0rem" v-else-if="this.dispFlag == 'c3'">
                          <img src="../assets/img/sBus.png" alt="" style="width:2.5rem; height:auto">
                          멤버십 환급액
                        </b>
                      </div>
                    </td>
                    <td style="text-align:end; width:50%;" v-if="this.dispFlag == 'c1'">
                      <b style="color:goldenrod; font-size:1.5rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memRefund) }}</b> 원
                    </td>
                    <td style="text-align:end; width:50%;" v-else-if="this.dispFlag == 'c3'">
                      <b style="color:slategray; font-size:1.5rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memRefund) }}</b> 원
                    </td>
                  </tr>
                  <tr v-else-if="this.dispFlag == 'c2' || this.dispFlag == 'c4' || this.dispFlag == 'c5'" style=" display:none;">
                    <td style="width:50%">
                      <div>
                        <b style="font-size:1.0rem" v-if="this.dispFlag == 'c2'">
                          <img src="../assets/img/pBus.png" alt="" style="width:2.5rem; height:auto"> 할인액
                        </b>
                        <b style="font-size:1.0rem" v-else-if="this.dispFlag == 'c4' || this.dispFlag == 'c5'">
                          <img src="../assets/img/sBus.png" alt="" style="width:2.5rem; height:auto"> 할인액
                        </b>
                      </div>
                    </td>
                    <td style="text-align:end; width:50%;" v-if="this.dispFlag == 'c2'">
                      <b style="color:goldenrod; font-size:1.5rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memDC) }}</b> 원
                    </td>
                    <td style="text-align:end; width:50%;" v-else-if="this.dispFlag == 'c4' || this.dispFlag == 'c5'">
                      <b style="color:slategray; font-size:1.5rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memDC) }}</b> 원
                    </td>
                  </tr>


                  <tr v-if="this.selectedCalPdtPrice.length > 0">
                    <td class="text-center align-middle" colspan="2">
                      <div class="pr-go" id="p_resBtn">
                        <a href="javascript: void(0)" @click="orderCheck('p')">
                            <p>예약하기</p>
                        </a>
                      </div>
                    </td>
                  </tr>

                  <!-- 멤버십 배너 -->
                  <tr v-if="this.user.memLevel != 'P'">
                    <td class="text-center align-middle" colspan="2">
                      <div class="mem-go">
                        <a href="javascript: void(0)" onclick="location.href='/membershipPage'">
                          <img src="../assets/img/new-test/product-m.jpg" class="img-fluid mobile">
                        </a>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <!-- 모바일화면용 예약달력 -->
          <div class="container mobile-cal">
            <a class="openm js-click-modal" @click="toggleModal()">날짜선택</a>
            <div class="modal2" v-if="this.showModal2" @click.self="closeModal2()">
              <div class="body">
                <div class="container">
                  <div class="calendar">
                    <div class="calendar-header">
                      <button class="calendarbtn" @click="previousMonth()">
                        <i class="bi bi-chevron-left"></i>
                      </button>
                      <div class="d-flex align-items-center">
                        <button class="calendarbtn mt-1" @click="goToCurrentMonth()">
                          <i class="bi bi-calendar3"></i>
                        </button>
                        <div class="calendar-header-date">
                          {{ this.currentDate ? this.currentDate.format('YYYY-MM') : '' }}
                        </div>
                      </div>
                      <button class="calendarbtn" @click="nextMonth()">
                        <i class="bi bi-chevron-right"></i>
                      </button>
                    </div>
                    <div class="calendar-body">
                      <div class="calendar-row">
                        <div class="calendar-day-header text-danger">일</div>
                        <div class="calendar-day-header">월</div>
                        <div class="calendar-day-header">화</div>
                        <div class="calendar-day-header">수</div>
                        <div class="calendar-day-header">목</div>
                        <div class="calendar-day-header">금</div>
                        <div class="calendar-day-header text-primary">토 </div>
                      </div>
                      <div v-for="(week, index) in weeks" :key="index" class="calendar-row">
                        <div v-for="(day, index) in week" :key="index" class="calendar-day"
                            :class="{ today: isToday(day.date), selected: isSelected(day.date)}" @click="goTo('resBtn');selectDate(day.date)">
                          <div v-if="day.date && day.date.day() === 0" class="calendar-date text-danger">
                            {{ day.date.date() }}
                          </div>
                          <div v-else-if="day.date && day.date.day() === 6" class="calendar-date text-primary">
                            {{ day.date.date() }}
                          </div>
                          <div v-else-if="day.date && (day.date.day() !== 6 && day.date.day() !== 0)" class="calendar-date">
                            {{ day.date.date() }}
                          </div>
                          <div v-if="day.price" class="calendar-price">
                            {{ getCurrencyFormat(formatManCurrency(day.price)) }}<span style="font-size: smaller; color:darkgray;">만</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr style="margin:0px 20px 0; opacity:.5" v-if="this.inquiryFlag === 'Y'">

                <!-- 가격 -->
                <div class="price" v-if="this.inquiryFlag === 'Y'">
                  <table>
                    <tr class="row col-12 text-center">
                      <td style="font-size:small;">
                        <i class="bi bi-check2-circle text-danger"></i> {{ this.selectedDate }} ({{ this.getDayOfWeek(this.selectedDate) }})
                      </td>
                    </tr>
                    <tr class="row col-12 my-2" :key=i v-for="(scp,i) in this.selectedCalPdtPrice">
                      <td class="col-6 text-center gx-5" v-if="(this.salesRoute === 'T' || this.salesRoute === 'F' || this.salesRoute === 'L' || this.salesRoute === 'E') && scp.agegroup != '4'">
                        <div v-if="scp.pdtPriceName == '' || scp.pdtPriceName == undefined" style="font-weight:normal; font-size:small;">
                          {{scp.ageName}}&nbsp;
                          <a href="javascript: void(0)" @click="popover('A','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="만 12세 이상" v-if="scp.ageName==='성인'">
                            <i class="bi bi-question-square text-sm-muted"></i>
                          </a>
                          <a href="javascript: void(0)" @click="popover('C','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="만 02세 이상" v-else-if="scp.ageName==='소아'">
                            <i class="bi bi-question-square text-sm-muted"></i>
                          </a>
                          <a href="javascript: void(0)" @click="popover('I','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="생후 24개월 미만" v-else-if="scp.ageName==='유아'">
                            <i class="bi bi-question-square text-sm-muted"></i>
                          </a>
                          <a href="javascript: void(0)" @click="popover('D','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="할인조건 만족시" v-else-if="scp.ageName==='할인'">
                            <i class="bi bi-question-square text-sm-muted"></i>
                          </a>
                        </div>
                        <div v-else-if="scp.pdtPriceName != ''" style="font-weight:normal; font-size:small;">
                          {{scp.pdtPriceName}}&nbsp;
                        </div>
                        <div v-if="scp.agegroup != '4'"  style="font-weight:bold; font-size:small;">
                          <b style="color:darkgreen; font-size:1.1rem;">{{getCurrencyFormat(scp.sMinPrice)}}</b>&nbsp;
                        <small class="text-muted">원</small></div>
                      </td>

                      <td class="col-5" style="text-align:center; background-color: white" v-else-if="this.salesRoute === 'S' && scp.agegroup != '4'">
                        <div v-if="scp.pdtPriceName == '' || scp.pdtPriceName == undefined">
                          가격&nbsp;
                        </div>
                        <div v-else-if="scp.pdtPriceName != ''">
                          {{scp.pdtPriceName}}&nbsp;
                        </div>
                      </td>

                      <td class="col-6 count text-center align-self-center">
                        <div class="btn-group" v-if="scp.pdtPrice > 0 || scp.agegroup != '4' ">
                          <button class="btn left btn-sm" @click="changeUncheckedSelOptions_new(),minus(scp.ageName)"><i class="bi bi-dash text-danger"></i></button>
                          <span class="number" style="font-size:small;">{{ this.ageNumber[i+1] }}</span>
                          <button class="btn right btn-sm" @click="changeUncheckedSelOptions_new(),plus(scp.ageName)"><i class="bi bi-plus text-primary"></i></button>
                        </div>
                      </td>
                    </tr>

                    <!-- 선택옵션 (old select option logic) -->
                    <!-- <tr class="row col-12 mb-2" v-if="this.selectableOptInfo.length > 0">
                      <td class="row col-12">
                        <div style="text-align:start; background-color: white;font-weight: normal; margin:0 auto; width:90%; box-sizing:border-box;">
                          선택옵션
                          <div class="border rounded" style="width:100%;">
                            <div class="d-flex align-items-center" :key="i" v-for="(op, i) in selectableOptInfo">
                              <span style="font-size:0.9rem; width:65%; margin-left: 3%;">
                                {{ op.optPdtName }}&nbsp;
                                <span style="font-size: small;color:gray;">({{ getCurrencyFormat(op.optPdtPrice) }})</span>
                              </span>
                              <span style="font-size:0.9rem; width:35%; margin-left: 4px;">
                                <input type="checkbox" v-model="op.isChecked" :value="op.optCode"  ref="optCode" class="align-baseline mt-3" @change="checkSelOption(op.optCode, op.isChecked),scrollToPosition('m_resBtn')">
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr> -->

                    <tr class="row col-12 mb-2" v-if="this.selectableOptInfo.length > 0">
                      <td class="row col-12">
                        <div style="text-align:start; background-color: white;font-weight: normal; margin:0 auto; width:90%; box-sizing:border-box;">
                          선택옵션
                          <div class="border rounded" style="width:100%;">
                            <select class="form-select form-select-sm" v-model="optCode" @change="checkSelOption_new(optCode),scrollToPosition('p_resBtn')">
                              <option :value="noVal" disabled>
                                -- 선택옵션리스트 --
                              </option>
                              <option value="noCode">
                                선택안함
                              </option>
                              <option :value="op.optCode" :key="j" v-for="(op,j) in selectableOptInfo ">
                                {{ op.optPdtName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </td>
                    </tr>

                  <!-- 추가상품 -->
                  <tr class="row col-12 mb-2" v-if="this.selectableAddPdtInfo.length > 0">
                    <td class="row col-12">
                      <div style="text-align:start; background-color: white;font-weight: normal; width:90%; margin: 0 auto;">
                        추가상품
                        <div class="border rounded" style="width:100%;">
                          <div class="d-flex align-items-center" :key="j" v-for="(ap, j) in selectableAddPdtInfo">
                            <span style="font-size:0.9rem; width:70%; margin-left: 3%;">
                              {{ ap.addPdtName }}
                            </span>
                            <span style="font-size:0.9rem; width:30%; margin-left: 4px;">
                              <button class="btn btn-sm left" @click="calCnt('a', 'm', ap.addPdtCode), scrollToPosition('m_resBtn')"><i class="bi bi-dash-circle-fill text-danger"></i></button>
                              <span class="number">{{ addPdtCnts[ap.addPdtCode] }}</span>
                              <button class="btn btn-sm right" @click="calCnt('a', 'p', ap.addPdtCode), scrollToPosition('m_resBtn')"><i class="bi bi-plus-circle-fill text-primary"></i></button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                </table>
                </div>

                <hr class="mt-4 mb-5" style="margin:0px 20px 0; opacity:.5"  v-if="this.etcOptionInfo.length > 0 &&  this.inquiryFlag === 'Y'">

                <div id="resBtn"></div>

                <!-- 상품가격 -->
                <div class="total-P mb-3" v-if="this.inquiryFlag === 'Y'">
                  <table>
                    <tr>
                      <td style="width:50%">
                        <div>
                          <b style="font-size:1.1rem; font-weight:border;">가격비교</b>
                        </div>
                      </td>

                      <td style="text-align:end; width:50%; display:none;" v-if="this.dispFlag === 'c2' || this.dispFlag === 'c4' || this.dispFlag === 'c5'">
                        <b class="text-danger fs-4">{{ this.getCurrencyFormat(this.showPrice*1 - this.tot_memDC*1) }}</b> 원
                      </td>
                      <td style="text-align:end; width:50%; display:none;" v-if="this.dispFlag == undefined || this.dispFlag == ''">
                        <b class="text-danger fs-4">{{ this.getCurrencyFormat(this.showPrice) }}</b> 원
                      </td>
                      <td style="text-align:end; width:50%; display:none;" v-if="this.dispFlag == 'c1' || this.dispFlag == 'c3'">
                        <b class="text-danger fs-4">{{ this.getCurrencyFormat(this.showPrice) }}</b> 원
                      </td>
                    </tr>

                    <tr style="font-size: 1.0rem">
                      <td style="width:50%">
                        <div>
                          <b style="font-size:1.0rem">
                            <img src="../assets/img/gBus.png" alt="" style="width:2.0rem; height:auto">일반회원
                          </b>
                        </div>
                        <div>
                          <b style="font-size:1.0rem">
                            <img src="../assets/img/sBus.png" alt="" style="width:2.0rem; height:auto">스탠다드
                          </b>
                        </div>
                        <div>
                          <b style="font-size:1.0rem">
                            <img src="../assets/img/pBus.png" alt="" style="width:2.0rem; height:auto">프리미엄
                          </b>
                        </div>
                      </td>
                      <td style="text-align:end; width:50%;">
                        <div>
                          <b style="color:slategray; font-size:1.2rem; font-weight:bold; padding-left:20px;">{{ this.getCurrencyFormat(this.showPrice_G) }}</b> 원
                        </div>
                        <div>
                          <b style="color:royalblue; font-size:1.2rem; font-weight:bold; padding-left:20px;">{{ this.getCurrencyFormat(this.showPrice_S) }}</b> 원
                        </div>
                        <div>
                          <b style="color:goldenrod; font-size:1.2rem; font-weight:bold; padding-left:20px;">{{ this.getCurrencyFormat(this.showPrice_P) }}</b> 원
                        </div>
                      </td>

                      <td style="text-align:end; width:50%; display:none;" v-if="this.dispFlag == 'c1'">
                        <b style="color:goldenrod; font-size:1.5rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memRefund) }}</b> 원
                      </td>
                      <td style="text-align:end; width:50%; display:none;" v-else-if="this.dispFlag == 'c3'">
                        <b style="color:slategray; font-size:1.5rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memRefund) }}</b> 원
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="font-size:small; color:darkgray;">※ 표시된 가격은 <b>비교용 금액</b>입니다</td>
                    </tr>

                    <!-- DB처리영역 -->
                    <tr v-if="this.dispFlag == 'c1' || this.dispFlag == 'c3'" style="display:none;">
                      <td style="width:50%">
                        <div>
                          <b style="font-size:1.0rem" v-if="this.dispFlag == 'c1'">프리미엄 환급</b>
                          <b style="font-size:1.0rem" v-else-if="this.dispFlag == 'c3'">스탠다드 환급</b>
                        </div>
                      </td>
                      <td style="text-align:end; width:50%;" v-if="this.dispFlag == 'c1'">
                        <b style="color:goldenrod; font-size:1rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memRefund) }}</b> 원
                      </td>
                      <td style="text-align:end; width:50%;" v-else-if="this.dispFlag == 'c3'">
                        <b style="color:slategray; font-size:1rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memRefund) }}</b> 원
                      </td>
                    </tr>

                    <tr v-else-if="this.dispFlag == 'c2' || this.dispFlag == 'c4' || this.dispFlag == 'c5'" style="display:none;">
                      <td style="width:50%">
                        <div>
                          <b style="font-size:1.0rem" v-if="this.dispFlag == 'c2'">프리미엄 할인</b>
                          <b style="font-size:1.0rem" v-else-if="this.dispFlag == 'c4' || this.dispFlag == 'c5'">스탠다드 할인</b>
                        </div>
                      </td>
                      <td style="text-align:end; width:50%;" v-if="this.dispFlag == 'c2'">
                        <b style="color:goldenrod; font-size:1.0rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memDC) }}</b> 원
                      </td>
                      <td style="text-align:end; width:50%;" v-else-if="this.dispFlag == 'c4' || this.dispFlag == 'c5'">
                        <b style="color:slategray; font-size:1.0rem; font-weight:bold;">{{ this.getCurrencyFormat(this.tot_memDC) }}</b> 원
                      </td>
                    </tr>

                    <tr v-if="this.selectedCalPdtPrice.length > 0">
                      <td class="text-center align-middle" colspan="2">
                        <div class="pr-go" id="m_resBtn">
                          <a href="javascript: void(0)" @click="orderCheck('p')">
                              <p style="font-size:1.0rem;">예약하기</p>
                          </a>
                        </div>
                      </td>
                    </tr>

                    <!-- 멤버십 배너 -->
                    <tr v-if="this.user.memLevel != 'P'">
                      <td class="text-center align-middle" colspan="2">
                        <div class="mem-go">
                          <a href="javascript: void(0)" onclick="location.href='/membershipPage'">
                            <img src="../assets/img/new-test/product-m.jpg" class="img-fluid mobile">
                          </a>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>

              <!-- 닫기버튼 -->
              <a class="closem js-close-modal">
                <i class="bi bi-x-square text-danger" style="cursor:pointer" @click.stop="closeModal2()"></i>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

</template>

<script>
import moment from 'moment';
import {genCode} from '../assets/js/genCode'
//import { reactive } from 'vue';

export default {
  // components:
  // {
  //   DatePicker
  // },
  // DB에서 가져온 데이타
  data() {
    return {
      showModal2: true,                   //모바일달력창

      currentDate: null,                  //가격이 있는 달을 시작월로 변경
      selectedDate: null,
      selectedPrice: null,
      priceData: {},
      //calPdtPrice_full: [],           //상품가격리스트
      selectedCalPdtPrice: [],          //선택된 날짜의 권종가격
      optPricesByCode: {},              //선택된 날짜의 선택옵션금액(코드, 권종가격)
      addPricesByCode: {},              //선택된 날짜의 추가상품금액(코드, 가격)

      selectedOptions: {},              //옵션 중 선택값
      selectedAddProduct: {},           //추가상품 중 선택값

      selOptInfo: [],                   //선택옵션정보
      selAddInfo: [],                   //선택추상정보

      selOptCode:  [],                  //선택된 옵션코드
      // selAddCode:  [],                  //선택된 추가상품코드

      optCnts: {},                      //상품코드별 선택옵션 구매수량객체
      addPdtCnts: {},                   //상품코드별 추가상품 구매수량객체

      tot_selOptPrices: {},             //선택된 옵션의 금액정보객체
      tot_selAddPrices: {},             //선택된 추가상품의 금액정보객체
      totalOptions: 0,                  //옵션총액
      totalAddProducts: 0,              //추가상품총액



      inquiryFlag: "N",                 //예약영역 표시플래그

      pdtOptInfo_start: [],             //상품선택옵션정보
      pdtOptInfo: [],                   //상품선택옵션정보
      pdtOptFlag: "N",                  //선택옵션유무
      //calOptFlag: "N",                  //선택옵션유무(예약용)
      selectableOptInfo:  [],           //일자별 선택가능한 옵션정보

      addPdtInfo_start: [],             //추가상품정보
      addPdtInfo: [],                   //추가상품정보
      addPdtFlag: "N",                  //추가상품유무
      calAddFlag: "N",                  //추가상품유무(예약용)
      selectableAddPdtInfo:  [],        //일자별 선택가능한 추가상품정보

      productId: '',
      productDetail: [],            // 상세정보를 오브젝트형으로 변수처리

      pdtTransInfo1: {},            //상품교통정보

      productImage: [],
      productDetailImage: [],
      pdtFlag: "",                  //단일-결합 구분자
      detailPrice: [],              //상세페이지 표시용 기본가격
      pdtAddOptExisted: [],         //기존추가옵션
      etcOptionInfo: [],            //기타옵션
      //etcOptionInfo_existed: [],  //기타옵션(기존)

      reviewList: [], //리뷰리스트

      ageNumber: [0,0,0,0,0],       //연령별 인원

      pdtPromoInfo: [],             //상품프로모션정보

      memberFlag: "",               //회원여부
      calLastDate: "",              //상품종료일

      reqReserveCode: "",           //예약번호

      //예약달력정보
      aduPrice: 0,            //인당금액
      chiPrice: 0,            //인당금액
      infPrice: 0,            //인당금액
      showPrice: 0,           //상품가액(게시용)
      tot_aduPrice: 0,        //권종별가격토탈
      tot_chiPrice: 0,        //권종별가격토탈
      tot_infPrice: 0,        //권종별가격토탈
      tot_aduCount: 0,        //권종별인원토탈
      tot_chiCount: 0,        //권종별인원토탈
      tot_infCount: 0,        //권종별인원토탈
      tot_perCount: 0,        //유효인원총수
      tot_margin: 0,          //마진토탈
      tot_promo:0,            //프로모션토탈
      tot_vatPrice: 0,        //부가세

      rShowFlag: "N",         //리뷰작성영역표시

      noPopFlag: "N",         //페이지이탈 경고팝업표시(Y:없음, N:경고)

      defaultValFlag: "N",    //인원&가격정보(Y:초기화)

      reqDptDate: "",         //출발예정일  = selectedDate
      reqRtnDate: "",         //리턴예정일

      memFlag: "N",           //멤버십상품 플래그
      maxDC: [],              //멤버십상품 최대할인율
      mDailyDCInfo: [],       //멤버십상품 데일리할인율

      dispFlag: "",           //환급,할인구분(c1:PreRF/ c2:PreDC/ c3:StdRF/ c4,c5:StdDC)
      priceForMem: [],        //환급,할인표시액(DB처리용)
      tot_memRefund: 0,       //총환급액(대상자는 본인만)
      tot_memDC: 0,           //총할인액(대상자는 본인만)

      companionDC_P: 0,   //동반할인율(프리미엄)
      companionDC_S: 0,   //동반할인율(스탠다드)

      /////가격비교화면용
      n_memRf_P: 0,                        //프리미엄환급률(할인계산)
      n_memRf_S: 0,                        //스탠다드환급률

      n_dcForStd: 0,                       //스탠다드멤버의 프리미엄상품 혜택할인율

      n_dcRateForMem_P: "",                //이외상품 프리미엄 할인방식
      n_dcRateForMem_S: "",                //이외상품 스탠다드 할인방식
      n_dcForMem_P: 0,                     //이외상품 프리미엄 할인율
      n_dcForMem_S: 0,                     //이외상품 스탠다드 할인율

      n_companionDC_P: 0,                  //동반할인율(프리미엄)
      n_companionDC_S: 0,                  //동반할인율(스탠다드)

      showPrice_G: 0,                      //표시용 일반가격
      showPrice_S: 0,                      //표시용 스탠다드
      showPrice_P: 0,                      //표시용 프리미엄

      base_showPrice: 0,
      base_showPrice_G: 0,                 //베이스 일반가격
      base_showPrice_S: 0,                 //베이스 스탠다드
      base_showPrice_P: 0,                 //베이스 프리미엄

      selectedOptCodes: [],                //체크된 선택옵션코드값

      //선택옵션 추가전 표시금액
      showPrice_G_org: 0,
      showPrice_S_org: 0,
      showPrice_P_org: 0,

      //선택옵션별 합산가격
      newOptTotal_G: 0,
      newOptTotal_S: 0,
      newOptTotal_P: 0,

      selOptRefund_P: 0,                  //프리미엄 본인환급
      selOptCompDC_P: 0,                  //프리미엄 동반할인
      selOptDCForStd: 0,                  //프리미엄 스탠다드할인
      selOptRefund_S: 0,                  //스탠다드 본인환급
      selOptCompDC_S: 0,                  //스탠다드 동반할인

      obj_selectedOptPrice: {},           //선택된 옵션의 권종정보(dB처리용)
      tot_memRefund_base: 0,              //환급총액 계산기준가(본인기준)
      tot_memDC_base: 0,                  //할인총액 계산기준가(본인기준)

      optCode: "noCode",                  //선택옵션 선택기본값
      sp: [],                             //선택된 옵션정보
    };
  },
  created() {
    // path에서 사용된 productId
    this.user = this.$store.state.user;
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;

    this.conEmail = this.user.email;

    if (this.$store.state.user.handlerId != undefined) {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined) {
      this.userInfo = this.$store.state.user.email;
    }
    else {
      this.userInfo = "";
    }
    //console.log("userInfo:", this.user);

    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.userInfo.joinFlag == 'H')
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.phone.replace("+82 ", "0")
      }
      this.k_tel = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }

    //단일-결합구분자
    // if(this.productId.substring(0,3)==="MX-") {
    //   this.pdtFlag = "MIX";
    // }
    this.getFirstAvailableDate()  //가격이 있는 날짜를 달력시작월로

    this.getProductDetail();
    this.getPdtOptInfo()          //상품선택옵션정보
    this.getAddPdtInfo()          //추가상품정보
  },
  mounted() {
    window.scrollTo(0, 0);

    // if(document.referrer == "")
    // {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/");
    // }

    //페이지 새로고침 리스너
    window.addEventListener('beforeunload', this.showPopupOnRefresh)

    document.getElementById("notice").style.display = "none";

    //달력 열기
    document.querySelector(".js-click-modal").addEventListener("click", () => {
      document.querySelector(".modal2").classList.toggle("open");
    });
    //달력 닫기
    document.querySelector(".js-close-modal").addEventListener("click", () => {
      document.querySelector(".modal2").classList.remove("open");
    });

    if(this.user.email != undefined)
    {
      document.getElementById("recent-item").style.display = "none";
      document.getElementById("recent-item-m").style.display = "none";
      document.getElementById("btn-recentM").style.display = "none";
    }
  },

  beforeUnmount()
  {
    window.removeEventListener('beforeunload', this.showPopupOnRefresh)
  },

  beforeRouteLeave(to, from, next) {
    //뒤로가기 시 호출
    if (this.inquiryFlag === 'Y' && this.noPopFlag != 'Y')
    {
      this.showPopupOnBack().then(shouldProceed => {
        if (shouldProceed) {
          next()
        } else {
          next(false)
        }
      })
    } else {
      next()
    }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    },


    //new calendar
    weeks()
    {
      const weeks = [];
      let weekDays = [];

      let day = this.currentDate ? this.currentDate.clone().startOf('month').startOf('week') : moment().startOf('month').startOf('week');

      const startOfMonth = this.currentDate ? this.currentDate.clone().startOf('month') : moment().startOf('month');
      const endOfMonth = this.currentDate ? this.currentDate.clone().endOf('month') : moment().endOf('month');
      const endDate = this.currentDate ? this.currentDate.clone().endOf('month').endOf('week') : moment().endOf('month').endOf('week');

      while (day.isSameOrBefore(endDate)) {
        if (day.isSameOrAfter(startOfMonth) && day.isSameOrBefore(endOfMonth)) {
          weekDays.push({
            date: day.clone(),
            price: this.getPrice(day.format('YYYY-MM-DD'))
          });
        } else {
          weekDays.push({
            date: null,
            price: null,
          });
        }

        if (weekDays.length === 7) {
          weeks.push(weekDays);
          weekDays = [];
        }

        day.add(1, 'day');
      }
      return weeks;
    }
  },

  watch: {
    //예약정보 전개 + 예약변수 초기화 분개처리
    inquiryFlag: function(newVal) {
      if(newVal === "N")
      {
        this.ageNumber = ['0','0','0','0']
        this.showPrice = 0
      }
    },

    defaultValFlag: function(newVal, oldVal) {
      if((newVal==='Y' || newVal==='N') && oldVal === 'Y')
      {
        this.ageNumber = ['0','0','0','0']
        this.showPrice = 0
      }
    },
  },

  methods:
  {
    //예약하기 버튼으로 스크롤이동
    scrollToPosition(param){
      //console.log(param)
      const element = document.getElementById(param);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },

    toggle(optCode){
      if(this.selectedOptions[optCode])
      {
        this.selectedOptions[optCode] =false
      }
      else
      {
        this.selectedOptions[optCode] =true
      }
      this.$nextTick(() => {
        this.scrollToSection();
      });

    },


    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      //return this.$currencyFormat(value);
      if(isNaN(value))
      {
        location.reload()
      }
      else
      {
        return this.$currencyFormat(value);
      }
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    /** 날짜기반요일추출 */
    getDayOfWeek(dateString) {
      return this.$getDayOfWeek(dateString)
    },

    // 모든 카운트와 가격 누적액을 초기화하는 메서드
    initializeCounts() {
      for (let code in this.addPdtCnts) {
        this.addPdtCnts[code] = 0;
      }
      // for (let code in this.optCnts) {
      //   this.optCnts[code] = 0;
      // }
      // for (let code in this.tot_selOptPrices) {
      //   this.tot_selOptPrices[code] = 0;
      // }
      for (let code in this.tot_selAddPrices) {
        this.tot_selAddPrices[code] = 0;
      }
      //this.totalOptions = 0;
      this.totalAddProducts = 0;
    },

    //유효상품옵션정보획득
    async getPdtOptInfo(){
      let pdtOptInfo_start = await this.$api("/api/getPdtOptInfo_start", {param: [this.productId]});

      if(pdtOptInfo_start.length > 0)
      {
        this.pdtOptInfo_start = pdtOptInfo_start
        this.pdtOptFlag = "Y"
      }
      else
      {
        this.pdtOptFlag = "N"
      }
      //console.log("pdtOptInfo_start:", this.pdtOptInfo_start)
    },

    //유효추가상품정보획득
    async getAddPdtInfo(){
      let addPdtInfo_start = await this.$api("/api/getAddPdtInfo_start", {param: [this.productId]});

      if(addPdtInfo_start.length > 0)
      {
        this.addPdtInfo_start = addPdtInfo_start
        this.addPdtFlag = "Y"
      }
      else
      {
        this.addPdtFlag = "N"
      }
      //console.log("addPdtInfo:", this.addPdtInfo)
    },


    closeModal2()
    {
      this.showModal2 = false
    },

    toggleModal()
    {
      this.showModal2 = true
      //console.log("showModal2:", this.showModal2)
      //this.showModal2 = !this.showModal2
    },

    showPopupOnRefresh(event)
    {
      if (this.noPopFlag == 'N') // && this.inquiryFlag === 'Y'
      {
        const message = '페이지 새로고침을 하시겠습니까?작성 중 내용이 지워지게 됩니다.'
        event.returnValue = message // 반환값을 설정합니다.
      }
    },
    async showPopupOnBack()
    {
      const message = '이 페이지에서 나가시겠습니까?<br>작성 중 내용이 지워지게 됩니다.'
      const result = await this.$swal.fire({
        title: '',
        html: message,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: '네',
        cancelButtonText: '아니요'
      })

      if (result.isConfirmed)
      {
        this.inquiryFlag = 'N'
        return true
      }
      else
      {
        return false
      }
    },

    checkAllConfirm()
    {
      if(this.allConfirmFlag === "Y")
      {
        this.confirm_Terms = "Y" //약관동의
        this.confirm_spTerms = "Y"
        this.confirm_Info = "Y"
        this.confirm_Privacy = "Y"
        this.confirm_3rd = "Y"
      }
      else if(this.allConfirmFlag === "N")
      {
        this.confirm_Terms = "N" //약관동의
        this.confirm_spTerms = "N"
        this.confirm_Info = "N"
        this.confirm_Privacy = "N"
        this.confirm_3rd = "N"
      }
    },

    // //선택옵션/추가상품체크를 해제처리
    // unCheckSelectedOptions(){
    //   //옵션 선택해제
    //   Object.keys(this.selectedOptions).forEach(key => {
    //     this.selectedOptions[key] = false;
    //   })

    //   //추가상품 선택해제
    //   Object.keys(this.selectedAddProduct).forEach(key => {
    //     this.selectedAddProduct[key] = false;
    //   })
    // },

    plus(p){
      this.initializeCounts()
      if(p == "성인")
      {
        this.ageNumber[1]++
        if(this.ageNumber[1] > 30)
        {
          this.ageNumber[1] = 30
          this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
          return false;
        }
        this.checkDptDate("P")
      }
      else if(p == "소아")
      {
        //성인0이면 예약불가
        if(this.ageNumber[1] <=0)
        {
          this.ageNumber[2] = 0   //소인 0처리
          this.ageNumber[3] = 0   //유아 0처리
          this.$swal.fire("","성인보호자 없이 소인단독예약은 할 수 없습니다.<br>당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","warning");
          return false;
        }
        else
        {
          this.ageNumber[2]++
          if(this.ageNumber[2] > 30)
          {
            this.ageNumber[2] = 30
            this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
            return false;
          }
          this.checkDptDate("P")
        }
        // this.ageNumber[2]++
        // if(this.ageNumber[2] > 30)
        // {
        //   this.ageNumber[2] = 30
        //   this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
        //   return false;
        // }
        // this.checkDptDate("P")
      }
      else if(p == "유아")
      {
        if(this.ageNumber[1] <=0 && this.ageNumber[2] <= 0)
        {
          this.ageNumber[3] = 0   //유아 0처리
          this.$swal.fire("","성인보호자 없이 유아단독예약은 할 수 없습니다.<br>당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","warning");
          return false;
        }
        else
        {
          this.ageNumber[3]++
          if(this.ageNumber[3] > 30)
          {
            this.ageNumber[3] = 30
            this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
            return false;
          }
          this.checkDptDate("P")
        }
      }
      else if(p == "기타")
      {
        this.ageNumber[4]++
        if(this.ageNumber[4] > 30)
        {
          this.ageNumber[4] = 30
          this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
          return false;
        }
        this.checkDptDate("P")
      }
    },

    minus(p)
    {
      this.initializeCounts()

      if(p == "성인")
      {
        this.ageNumber[1]--
        if(this.ageNumber[1] <= 0)
        {
          this.ageNumber[1] = 0   //성인 0처리
          this.ageNumber[2] = 0   //소인 0처리
          this.ageNumber[3] = 0   //유아 0처리

          //this.initializeCounts()
        }
        this.checkDptDate("P")
      }
      else if(p == "소아")
      {
        this.ageNumber[2]--
        if(this.ageNumber[2] < 0)
        {
          this.ageNumber[2] = 0
          //return false;
        }
        this.checkDptDate("P")
      }
      else if(p == "유아")
      {
        this.ageNumber[3]--
        if(this.ageNumber[3] < 0)
        {
          this.ageNumber[3] = 0
          //return false;
        }
        this.checkDptDate("P")
      }
      else if(p == "기타")
      {
        this.ageNumber[4]--
        if(this.ageNumber[4] < 0)
        {
          this.ageNumber[4] = 0
          //return false;
        }
        this.checkDptDate("P")
      }
    },
    //리뷰작성자는 리뷰를 수정할 수 있다
    changeReview(rvCode, nSeq, iSeq)
    {
      //alert("rvCode:"+rvCode+"nSeq:"+nSeq)
      this.$router.push({path: '/reviewModify', query: {rvCode: rvCode, nSeq: nSeq, iSeq: iSeq}});
    },
    /**달력표시금액 간략컨버팅*/
    formatManCurrency(amount) {
      // 만 단위로 처리하기 위해 10,000으로 나누기
      const amountInMan = amount / 10000;

      // 만 원 미만일 경우 단순히 숫자를 소수점 첫째 자리까지 표시
      if (amountInMan < 1) {
        return amountInMan.toFixed(1);
      } else if (amount >= 1000000) {
        // 100만원 초과+소숫점이 있으면 절상처리
        return Math.ceil(amountInMan).toLocaleString();
        //return Math.floor(amountInMan).toLocaleString();
      } else {
        // 만 원 이상일 경우 소수점 이하 1자리까지 표시
        const decimalPart = amountInMan - Math.floor(amountInMan);
        const roundedDecimalPart = Math.round(decimalPart * 10) / 10;

        if (roundedDecimalPart === 0) {
          return Math.floor(amountInMan).toLocaleString();
        } else {
          return (Math.floor(amountInMan) + roundedDecimalPart).toLocaleString();
        }
      }
    },

    //가격이 0이 아닌 날이 있는 월정보
    async getFirstAvailableDate()
    {
      await this.getPdtPriceForCalendar();
      const firstAvailableDate = Object.keys(this.priceData).find(date => this.priceData[date] !== null);
      if (firstAvailableDate) {
        this.currentDate = moment(firstAvailableDate).startOf('month');
      } else {
        this.goToCurrentMonth();
      }
    },
    //상품리뷰리스트
    async getReviewList()
    {
      let reviewList = await this.$api("/api/reviewList", {param: [this.productId]});
      if(reviewList.length > 0)
      {
        this.reviewList = reviewList
      }
      //console.log("reviewList:",this.reviewList)
    },
    //new calendar
    previousMonth()
    {
      this.inquiryFlag = "N"
      //compute 원할한 처리를 위해 clone()이용
      this.currentDate = this.currentDate.clone().subtract(1, 'month');
      this.selectedDate = null;
    },
    nextMonth()
    {
      this.inquiryFlag = "N"
      this.currentDate = this.currentDate.clone().add(1, 'month');
      this.selectedDate = null;
    },
    goToCurrentMonth()
    {
      this.inquiryFlag = "N"
      const today = moment();
      this.currentDate = moment([today.year(), today.month(), today.date()]);
      this.selectedDate = null;
    },
    selectDate(date)
    {
      //alert("opt:" + this.pdtOptFlag + "," + "add:" + this.addPdtFlag)
      this.initializeCounts()

      this.inquiryFlag = 'N'
      this.defaultValFlag = 'Y'

      let selectedDate = date;
      this.selectedDate = moment(selectedDate).format('YYYY-MM-DD')
      this.selectedPrice = this.getPrice(date.format('YYYY-MM-DD'));

      //날짜이동 -> 가격비교 초기화
      this.showPrice_G = 0
      this.showPrice_S = 0
      this.showPrice_P = 0

      this.tot_aduCount = 0
      this.tot_chiCount = 0
      this.tot_infCount = 0

      if (this.selectedPrice > 0)
      {
        // 선택된 날짜를 `selDate_new` 함수에 전달
        this.selDate_new(this.selectedDate);
      }
    },

    //New 달력용 가격리스트 호출
    async getPdtPriceForCalendar()
    {
      let calPdtPrice = [];
      calPdtPrice = await this.$api("/api/calPdtPriceNew", {param: [this.productId]});

      //달력에는 성인요금만 표시
      this.calPdtPrice = calPdtPrice

      // 달력표시용 가격데이타
      let priceData = {};
      if(this.productDetail.marginMethod === "P")
      {
        priceData = calPdtPrice.reduce((obj, item) =>
        {
          if(item.sellStat === 'N' || item.pdtPrice == 0)
          {
            obj[item.saleDate] = null;
          }
          else
          {
            obj[item.saleDate] = item.pdtPrice + (item.pdtPrice * this.productDetail.basicMargin/100);
          }
          return obj;
        }, {});
      }
      else
      {
        priceData = calPdtPrice.reduce((obj, item) =>
        {
          if(item.sellStat === 'N' || item.pdtPrice == 0)
          {
            obj[item.saleDate] = null;
          }
          else
          {
            obj[item.saleDate] = item.pdtPrice*1 + (this.productDetail.basicMargin*1);
          }
          return obj;
        }, {});
      }
      //this.calPdtPrice = calPdtPrice;
      this.priceData = priceData;
    },
    getPrice(date)
    {
      if (!this.priceData)
      {
        // priceData 객체가 로딩되지 않았다면 로딩하기
        this.getPdtPriceForCalendar();
      }
      if (date in this.priceData)
      {
        // priceData 객체에서 날짜에 해당하는 가격 추출하여 숫자로 변환하여 반환
        const price = this.priceData[date];
        return Number(price);
      }
      else
      {
        // const price = null
        // console.log("price:",price)
        return null
      }
    },
    isToday(date)
    {
      return moment().isSame(date, 'day');
    },
    //모먼트를 이용한 일자검증
    isSelected(date)
    {
      if (!this.selectedDate)
      {
        return false;
      }
      else if (moment(this.selectedDate).isSame(date, 'day'))
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    goTo(id) {
      var element = document.getElementById(id);
      if (element)
      {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      else
      {
        console.error('Element with the specified ID not found');
      }
    },

    showFlag() {
      this.rShowFlag = (this.rShowFlag == 'Y') ? 'N' : 'Y'
      //alert(this.rShowFlag)
    },
    //권종&옵션설명
    popover(p1, p2)
    {
      let optPrice = 0;
      if(p1==="P")
      {
        optPrice = this.getCurrencyFormat(this.etcOptionInfo[p2].etcOptPrice)
      }

      switch(p1) {
        //case "A": this.$swal.fire("","만 12세 이상","","customClass:{width:10px}");
        case "A": this.$swal.fire({
                    html: '<small class="text-muted"><small>만 12세 이상</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        case "C": this.$swal.fire({
                    html: '<small class="text-success text-muted"><small>만 02세 이상</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        case "I": this.$swal.fire({
                    html: '<small class="text-success text-muted"><small>생후 24개월 미만</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        case "D": this.$swal.fire({
                    html: '<small class="text-success text-muted"><small>할인조건 만족 시</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        case "P": this.$swal.fire({
                    html: '<small class="text-success text-muted"><small>'+ optPrice +'원</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        //default: return this.$swal.fire("","권종안내입니다","info");
      }
    },

    /** 지정된 id로 화면이동 */
    // scrollToId(id)
    // {
    //   const element = document.getElementById(id);
    //   if (element)
    //   {``
    //     element.scrollIntoView({ behavior: 'smooth' });
    //   }
    // },

    //모바일디바이스 대응
    scrollToId(id)
    {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth'
        });
      }
    },

    //달력예약일 선택 시 처리루틴
    async selDate_new(selDate)
    {
      this.inquiryFlag = 'Y'
      this.reqRtnDate = "";
      this.priceForMem = []       //권종별 멤버십 초기화
      this. n_priceForMemP = []   //가격비교 프리미엄 권종별멤버십 초기화
      this. n_priceForMemS = []   //가격비교 스탠다드 권종별멥머십 초기화

      //프로모션 처리
      let promoFlag = "N"
      let promoMethod = ""
      let promoRate = 0

      if(this.pdtPromoInfo.promoName != "")
      {

        let promoSDate = this.getDateFormat(this.pdtPromoInfo.promoStart)
        let promoEDate = this.getDateFormat(this.pdtPromoInfo.promoEnd)
        promoMethod = this.pdtPromoInfo.promoMethod
        promoRate = this.pdtPromoInfo.promoRate

        //if(this.selectedDate >= promoSDate && this.selectedDate <= promoEDate)
        if(selDate >= promoSDate && selDate <= promoEDate)
        {
          promoFlag = "Y"
        }
        else
        {
          promoFlag = "N"
        }
      }

      if(this.productDetail.tripProp === "D")
      {
        //let selecDate_tmp = new Date(this.selectedDate);
        let selecDate_tmp = new Date(selDate);
        let reqRtnDate_tmp = "";

        if(this.productDetail.stayCnt == "0") //당일리턴
        {
          reqRtnDate_tmp = new Date(selecDate_tmp.setDate(selecDate_tmp.getDate() + (this.productDetail.stayCnt)));
        }
        else if(this.productDetail.stayCnt > 0) //무박1일 포함(=당일리턴)
        {
          reqRtnDate_tmp = new Date(selecDate_tmp.setDate(selecDate_tmp.getDate() + (this.productDetail.stayCnt - 1)));
        }
        else
        {
          this.$swal.fire('', '날짜선택에 오류가 발생했습니다.<br>담당자에 문의하여 주십시오.', 'error');
          return false;
        }
        this.reqRtnDate = this.getDateFormat(reqRtnDate_tmp);
      }
      //선택일의 권종별 가격
      let selectedCalPdtPrice = await this.$api("/api/selectedCalPdtPrice_New", {param:
        //[this.productId, this.selectedDate]
        [this.productId, selDate]
      });

      //선택일의 권종별 멤버십환급율 - 당사자1인만 환급대상 + 나머지는 할인대상
      let selectedCalPdtRefund = await this.$api("/api/selectedCalPdtRefund_New", {param:
        //[this.productId, this.selectedDate]
        [this.productId, selDate]
      });

      if(selectedCalPdtRefund.length <= 0)
      {
        selectedCalPdtRefund = [0,0,0,0]
      }

      //선택일의 선택가능 옵션정보
        let selectableOptInfo = await this.$api("/api/getPdtOptInfo", {param: [
          this.productId, selDate
        ]})
        this.selectableOptInfo = selectableOptInfo

      //선택일의 선택가능 추가상품정보
      let selectedCalAddPdtPrice = []
      let addPricesByCode = {}                //addPdtCode로 분류될 객체

      //선택일자의 선택가능 추상
      let selectableAddPdtInfo = await this.$api("/api/getAddPdtInfo", {param: [this.productId, selDate]});

      this.selectableAddPdtInfo = selectableAddPdtInfo
      //console.log("selectableAddPdtInfo:", this.selectableAddPdtInfo)

      let j = 0
      while(j < this.selectableAddPdtInfo.length)
      {
        selectedCalAddPdtPrice = await this.$api("/api/selectedCalAddPdtPrice", {param:
          //[this.productId, this.selectedDate]
          [this.productId, this.selectableAddPdtInfo[j].addPdtCode, selDate]
        });
        //가격이 있는 추가상품만 개체에 포함
        if(selectedCalAddPdtPrice.length > 0)
        {
          //리턴값을 추가상품가격 배열로 변환
          let addPdtCode = selectedCalAddPdtPrice[0].addPdtCode                        //addPdtCode는 공통
          let addPdtPrice = selectedCalAddPdtPrice.map(item => item.addPdtPrice)       //추가상품별 추가가격
          // addPdtCode를 키로 하여 가격 정보 배열 저장
          addPricesByCode[addPdtCode] = addPdtPrice;
        }
        j++
      }
      this.addPricesByCode = addPricesByCode
      if(Object.keys(this.addPricesByCode).length > 0)
      {
        this.calAddFlag = "Y"
      }
      else
      {
        this.calAddFlag = "N"
      }
      //일자별 선택가능한 추가상품만 추려낸다
      //let selectableAddPdtInfo = []
      for(let key in this.addPricesByCode)
      {
        let matchAdd = this.addPdtInfo.find(item =>  item.addPdtCode === key)
        if(matchAdd)    //일치된 것만 선택가능배열에 추가
        {
          selectableAddPdtInfo.push(matchAdd)
        }
      }
      // this.selectableAddPdtInfo = selectableAddPdtInfo
      // console.log("selectableAddPdtInfo:", this.selectableAddPdtInfo)
      //console.log("addPricesByCode:", this.addPricesByCode)

      //추가상품 구매수량 초기화
      this.selectableAddPdtInfo.forEach((product) => {
        this.addPdtCnts[product.addPdtCode] = 0
      })

      //권종별 표시가격(예약단계)
      let i = 0;
      let sMargin = 0;         //상품마진값
      let sPromo = 0;          //프로모션금액
      let sMinPrice = 0;       //상품가격

      let memRf_P = 0;          //프리미엄 환급율
      let memRf_S = 0;          //스탠다드 환급율
      let price_memP = 0        //프리미엄 환급액
      let price_memS = 0        //스탠다드 환급액
      let dcForStd = 0          //프리이엄을 스탠다드가 구매시 할인율

      let dcRateForMem_P = ""   //이외상품 프리미엄 할인방식
      let dcRateForMem_S = ""   //이외상품 스탠다드 할인방식
      let dcForMem_P = 0        //이외상품 프리미엄 할인율
      let dcForMem_S = 0        //이외상품 스탠다드 할인율

      let price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
      let price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액

      let price_dcForStd = 0    //프리미엄을 스탠다드가 구매시 할인액

      let companionDC_P = 0     //프리미엄 동반할인율
      let companionDC_S = 0     //스탠다드 동반할인율


      //// 가격비교표시용
      let n_memRf_P = 0           //프리미엄환급률
      let n_memRf_S = 0           //스탠다드환급률

      let n_dcForStd = 0          //프리이엄을 스탠다드가 구매시 할인율

      let n_dcRateForMem_P = ""   //이외상품 프리미엄 할인방식
      let n_dcRateForMem_S = ""   //이외상품 스탠다드 할인방식
      let n_dcForMem_P = 0        //이외상품 프리미엄 할인율
      let n_dcForMem_S = 0        //이외상품 스탠다드 할인율

      let n_companionDC_P = 0     //프리미엄 동반할인율
      let n_companionDC_S = 0     //스탠다드 동반할인율

      // let n_price_memP = 0        //프리미엄 환급액(할인계산)
      // let n_price_memS = 0        //스탠다드 환급액(할인계산)


      // let n_price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
      // let n_price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액

      // let n_price_dcForStd = 0    //프리미엄을 스탠다드가 구매시 할인액


      /////////////////////////// 가격비교화면용
      //버스투어 멤버십 환급률(달력표시는 할인률)
      n_memRf_P = (selectedCalPdtRefund[i].memRf_P > 0) ? selectedCalPdtRefund[i].memRf_P : 0
      n_memRf_S = (selectedCalPdtRefund[i].memRf_S > 0) ? selectedCalPdtRefund[i].memRf_S : 0
      this.n_memRf_P = n_memRf_P
      this.n_memRf_S = n_memRf_S

      //멤버십 동반할인율
      n_companionDC_P = (this.productDetail.companionDC_P > 0) ? this.productDetail.companionDC_P : 0
      n_companionDC_S = (this.productDetail.companionDC_S > 0) ? this.productDetail.companionDC_S : 0
      this.n_companionDC_P = n_companionDC_P
      this.n_companionDC_S = n_companionDC_S

      //스탠다드를 위한 할인율
      n_dcForStd = (this.productDetail.dcForStd > 0) ? this.productDetail.dcForStd : 0
      this.n_dcForStd = n_dcForStd

      //멤버십 이외상품 할인율
      n_dcForMem_P = (this.productDetail.dcForMem_P > 0) ? this.productDetail.dcForMem_P : 0
      n_dcForMem_S = (this.productDetail.dcForMem_S > 0) ? this.productDetail.dcForMem_S : 0
      this.n_dcForMem_P = n_dcForMem_P
      this.n_dcForMem_S = n_dcForMem_S

      //멤버십 이외상품 할인방법
      n_dcRateForMem_P = this.productDetail.dcRateForMem_P
      n_dcRateForMem_S = this.productDetail.dcRateForMem_S
      this.n_dcRateForMem_P = n_dcRateForMem_P
      this.n_dcRateForMem_S = n_dcRateForMem_S

      // //멤버십 할인액
      // n_price_memP = (sMinPrice * n_memRf_P/100).toFixed(0)*1
      // n_price_memS = (sMinPrice * n_memRf_S/100).toFixed(0)*1


      // //스탠다드멤버 혜택할인액
      // n_price_dcForStd = selectedCalPdtPrice[0].pdtPrice * (n_dcForStd/100)
      // this.n_price_dcForStd = n_price_dcForStd



      //구매자가 멤버십
      if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
      {
        //비환급상품(패키지,엔데어,버라이어티,멤버십미지정버스투어)
        if((this.productDetail.salesRoute == 'T' || this.productDetail.salesRoute == 'F') && (this.productDetail.dcForMem_P > 0 || this.productDetail.dcForMem_S > 0))
        {
          //할인정보
          dcForMem_P = (this.productDetail.dcForMem_P > 0) ? this.productDetail.dcForMem_P : 0
          dcForMem_S = (this.productDetail.dcForMem_S > 0) ? this.productDetail.dcForMem_S : 0

          //할인방식(P/A)
          dcRateForMem_P = this.productDetail.dcRateForMem_P
          dcRateForMem_S = this.productDetail.dcRateForMem_S
        }

        if((this.productDetail.salesRoute == 'L' && (this.productDetail.nrFlag == 'Y' || this.productDetail.vrFlag =='Y')) || (this.productDetail.salesRoute == 'L' && ((this.productDetail.memLevel_P == null || this.productDetail.memLevel_P == 'N') && (this.productDetail.memLevel_S == null || this.productDetail.memLevel_S == 'N'))))
        {
          //할인정보
          dcForMem_P = (this.productDetail.dcForMem_P > 0) ? this.productDetail.dcForMem_P : 0
          dcForMem_S = (this.productDetail.dcForMem_S > 0) ? this.productDetail.dcForMem_S : 0

          //할인방식(P/A)
          dcRateForMem_P = this.productDetail.dcRateForMem_P
          dcRateForMem_S = this.productDetail.dcRateForMem_S
          //console.log("checkFlag:",dcForMem_P,dcRateForMem_P,dcForMem_S,dcRateForMem_S)
        }

        //스탠다드가 프리미엄 구매 시(할인혜택이 있는 경우)
        if(this.user.memLevel === 'S' && (this.productDetail.memLevel_S === 'N' || this.productDetail.memLevel_S == null) && this.productDetail.dcForStd > 0)
        {
          //DC-rate for stanard membership
          dcForStd = this.productDetail.dcForStd
        }
      }

      while(i < selectedCalPdtPrice.length)
      {
        if(this.productDetail.marginMethod === "P")
        {
          sMargin = (selectedCalPdtPrice[i].pdtPrice*1) * ((this.productDetail.basicMargin*1) / 100)
        }
        else
        {
          sMargin = this.productDetail.basicMargin*1;
        }

        if(promoFlag === "Y")
        {
          if(promoMethod === "P")
          {
            sPromo = (selectedCalPdtPrice[i].pdtPrice*1) * ((promoRate*1) / 100)
          }
          else if(promoMethod === "A")
          {
            sPromo = promoRate*1;
          }
        }

        //0원 권종은 추가계산없이 0원확정
        if(selectedCalPdtPrice[i].pdtPrice*1 <= "0")
        {
          sMinPrice = 0             //상품가액(마진반영)
          sPromo = 0                //프로모션
          sMargin = 0               //마진
          price_memP = 0            //프리미엄환급액
          price_memS = 0            //스탠다드환급액
          price_dcForMem_P = 0      //프리미엄할인액
          price_dcForMem_S = 0      //스탠다드할인액
          price_dcForStd = 0        //스탠다드를 위한 프리미엄할인액
        }
        //권종가격이 0원 이상
        else
        {
          //기타계산 전 권종가액
          sMinPrice = (selectedCalPdtPrice[i].pdtPrice*1) + sMargin*1

          //구매자가 멤버십
          if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
          {
            //멤버십상품
            if(this.productDetail.memLevel_P === 'Y' || this.productDetail.memLevel_S === 'Y')
            {
              //권종별 멤버십 환급율
              memRf_P = (selectedCalPdtRefund[i].memRf_P != undefined) ? selectedCalPdtRefund[i].memRf_P : 0
              memRf_S = (selectedCalPdtRefund[i].memRf_S != undefined) ? selectedCalPdtRefund[i].memRf_S : 0

              //권종별 멤버십 환급액
              price_memP = (sMinPrice * memRf_P/100).toFixed(0)*1
              price_memS = (sMinPrice * memRf_S/100).toFixed(0)*1

              //멤버십동반할인(버스투어에만 존재)
              companionDC_P = (this.productDetail.companionDC_P > 0) ? this.productDetail.companionDC_P : 0
              companionDC_S = (this.productDetail.companionDC_S > 0) ? this.productDetail.companionDC_S : 0

              this.companionDC_P = companionDC_P
              this.companionDC_S = companionDC_S
              //console.log("companionDC: ", this.companionDC_P, this.companionDC_S)

              //스탠다드가 프리미엄 구매 시(할인혜택이 있는 경우)
              if(this.user.memLevel === 'S' && (this.productDetail.memLevel_S === 'N' || this.productDetail.memLevel_S == null) && dcForStd > 0)
              {
                price_dcForStd = (sMinPrice * dcForStd/100).toFixed(0)*1
              }
            }
            //일반상품 또는 비지정 현지투어 또는 엔데어 또는 버라이어티팩
            else if(this.productDetail.salesRoute == 'T' || this.productDetail.salesRoute == 'F' || (this.productDetail.salesRoute == 'L' && (this.productDetail.nrFlag == 'Y' || this.productDetail.vrFlag =='Y')) || (this.productDetail.salesRoute == 'L' && ((this.productDetail.memLevel_P == null || this.productDetail.memLevel_P == 'N') && (this.productDetail.memLevel_S == null || this.productDetail.memLevel_S == 'N'))))
            {
              //멤버십 할인액
              if(dcRateForMem_P === 'P')
              {
                price_dcForMem_P =  ((sMinPrice*1) * ((dcForMem_P*1)/100)).toFixed(0)*1
              }
              else if(dcRateForMem_P === 'A')
              {
                price_dcForMem_P =  dcForMem_P*1
              }

              if(dcRateForMem_S === 'P')
              {
                price_dcForMem_S =  ((sMinPrice*1) * ((dcForMem_S*1)/100)).toFixed(0)*1
              }
              else if(dcRateForMem_S === 'A')
              {
                price_dcForMem_S =  dcForMem_S*1
              }

              //console.log("price_dcForMem_P:",price_dcForMem_P, "/price_dcForMem_S:",price_dcForMem_S)
            }
          }
        }

        selectedCalPdtPrice[i].sMinPrice = sMinPrice;     //권종가격(마진반영)
        selectedCalPdtPrice[i].sMargin = sMargin;
        selectedCalPdtPrice[i].sPromo = sPromo;


        ///////////////////////가격표시(c1:PreRF/ c2:PreDC/ c3:StdRF/ c4,c5:StdDC)
        //멤버십가입자
        if(this.productDetail.memLevel_P === 'Y' || this.productDetail.memLevel_S === 'Y')
        {
          //프리미엄 멤버십 + 환급상품 + 환급액>0
          if(this.user.memLevel === 'P')
          {
            //프리미엄 환급상품 + 환급액>0
            if(this.productDetail.memLevel_P === 'Y')
            {
              this.dispFlag = "c1"
              selectedCalPdtPrice[i].dispFlag = "c1";
              selectedCalPdtPrice[i].priceForMem = price_memP*1;

              this.priceForMem.push(price_memP*1)
            }
          }

          //스탠다드 멤버십
          else if(this.user.memLevel === 'S')
          {
            //스탠다드 환급상품 + 환급액>0
            //if(this.productDetail.memLevel_S === 'Y' && price_memS > 0)
            if(this.productDetail.memLevel_S === 'Y')
            {
              this.dispFlag = "c3"
              selectedCalPdtPrice[i].dispFlag = "c3";
              selectedCalPdtPrice[i].priceForMem = price_memS*1;

              this.priceForMem.push(price_memS*1)
            }
            //스탠다드에 할인되는 프리미엄상품 + 할인율>0
            else if(this.productDetail.memLevel_P === 'Y' && ((this.productDetail.memLevel_S === 'N' || this.productDetail.memLevel_S == null) && dcForStd > 0))
            {
              this.dispFlag = "c4"
              selectedCalPdtPrice[i].dispFlag = "c4";
              selectedCalPdtPrice[i].priceForMem = price_dcForStd*1;

              //console.log(selectedCalPdtPrice[i].priceForMem)
              if(selectedCalPdtPrice[i].sMinPrice > 0)
              {
                this.priceForMem.push(price_dcForStd*1)
              }
              else
              {
                this.priceForMem.push("0")
              }
            }
          }
        }
        ///// 선박상품 신규추가(2024-04-23)
        //프리미엄멤버
        if(this.user.memLevel == "P" && (this.productDetail.salesRoute == 'T' || this.productDetail.salesRoute == 'F' || (this.productDetail.salesRoute == 'L' && (this.productDetail.nrFlag == 'Y' || this.productDetail.vrFlag =='Y')) || (this.productDetail.salesRoute == 'L' && ((this.productDetail.memLevel_P == null || this.productDetail.memLevel_P == 'N')))) && this.productDetail.dcForMem_P > 0)
        {
          this.dispFlag = "c2"
          selectedCalPdtPrice[i].dispFlag = "c2";
          selectedCalPdtPrice[i].priceForMem = price_dcForMem_P*1;

          this.priceForMem.push(price_dcForMem_P*1)
        }
        //스탠다드멤버
        else if(this.user.memLevel == "S" && (this.productDetail.salesRoute == 'T' || this.productDetail.salesRoute == 'F' || (this.productDetail.salesRoute == 'L' && (this.productDetail.nrFlag == 'Y' || this.productDetail.vrFlag =='Y')) || (this.productDetail.salesRoute == 'L' && ((this.productDetail.memLevel_S == null || this.productDetail.memLevel_S == 'N')))) && this.productDetail.dcForMem_S > 0)
        {
          this.dispFlag = "c5"
          selectedCalPdtPrice[i].dispFlag = "c5";
          selectedCalPdtPrice[i].priceForMem = price_dcForMem_S*1;

          this.priceForMem.push(price_dcForMem_S*1)
        }
        i++;
      }
      this.selectedCalPdtPrice = selectedCalPdtPrice;
      this.defaultValFlag = "N"   //예약변수초기화 플래그 해제
      // console.log("calPrice: >>>>>>>>>>", this.selectedCalPdtPrice, "priceForMem::::::::>>", this.priceForMem)
    },

    async getProductDetail()
    {
      let productDetail = [];
      if(this.pdtFlag === "MIX") {
        productDetail = await this.$api("/api/productDetail_mix", {param: [this.productId]});
        // if(productDetail.length > 0) {
        //   this.productDetail = productDetail[0];
        // }
      }
      else {
        productDetail = await this.$api("/api/productDetail", {param: [this.productId]});
        // if(productDetail.length > 0) {
        //   this.productDetail = productDetail;
        // }
      }

      if(this.user != undefined || this.user != "")
      {

        if(this.user.joinFlag === 'N')
        {
          this.reqName = (!this.user.name) ? "" : this.user.name;
          this.reqTelNo = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
        }
        else
        {
          this.reqName = (!this.user.nickname) ? "" : this.user.nickname;

          let kakaoPhone = ""
          if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
          {
            //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
            kakaoPhone = this.user.phone.replace("+82 ", "0")
          }
          this.reqTelNo = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거
        }

        this.reqEmail = (!this.user.email) ? "" : this.user.email;
      }

      if(productDetail.length > 0) {
        this.productDetail = productDetail[0];
        this.tripProp = this.productDetail.tripProp;
      }
      //console.log("productDetail:", this.productDetail)

      //멤버십이면 환급률추출
      if(this.productDetail.memLevel_P === 'Y' || this.productDetail.memLevel_S === 'Y')
      {
        this.memFlag = "Y"        //멤버십대상 상품플래그
        //멤버십할인상품의 경우
        let maxDC = []
        maxDC = await this.$api("/api/maxDCOfMemPdt", {param: [this.productId]})
        if(maxDC.length > 0)
        {
          this.maxDC = maxDC[0]
        }
        //console.log("maxDC:",this.maxDC)
      }

      //this.defaultValue = 'Y'
      this.getProductImage();
      this.getProductDetailImage(); // 제품상세이미지호출
      this.getProductPrice(); //상품가격
      this.getPromoInfo(); //프로모션체크

      this.getReviewList() //여행후기

      this.getProductTransInfoExited(); //상품교통정보

      this.getPdtPriceForCalendar();
    },

    //상품교통정보
    async getProductTransInfoExited() {
      let pdtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.productId]});
      if(pdtTransInfo1.length > 0) {
        this.pdtTransInfo1 = pdtTransInfo1[0];
      }
      //console.log("pdtTransInfo1:",this.pdtTransInfo1);
    },

    async getProductImage() {
      // 상세페이지용 카로우셀 이미지
      let productImage = await this.$api("/api/productImage", {param: [this.productId]});
      this.productImage = (productImage.length > 0) ? productImage : "";
      //console.log("productImage:", this.productImage)
    },
    async getProductDetailImage() {
      // 상세설명이미지 호출
      // let noImage = this.noImage;
      let productDetailImage = await this.$api("/api/productDetailImage", {param: [this.productId]});
      this.productDetailImage = (productDetailImage.length > 0) ? productDetailImage : "";
      //console.log("productDetailImage:", this.productDetailImage);
    },
    //프로모션 정보추출
    async getPromoInfo() {
      let pdtPromoInfo = [];
      pdtPromoInfo = await this.$api("/api/productPromotion", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0]
      }
      //console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },

    //표시용 상품가격
    async getProductPrice()
    {
      // 조건에 부합되는 상품최소가격일자
      let pdtDetailPriceDate = await this.$api("/api/pdtDetailPriceDate", {param: [this.productId,this.productId]});

      let minPriceDate = []
      if(pdtDetailPriceDate.length > 0)
      {
        minPriceDate = pdtDetailPriceDate[0].pdtDate;
      }
      else
      {
        minPriceDate = '%'
      }

      let detailPrice = await this.$api("/api/pdtDetailminPrice", {param: [this.productId,minPriceDate]});

      if(detailPrice.length > 0)
      {
        let i = 0;
        let sMargin = 0;
        let sMinPrice = 0;

        while (i < detailPrice.length)
        {
          if(this.productDetail.marginMethod === "P")
          {
            sMargin = (detailPrice[i].productPrice*1) * ((this.productDetail.basicMargin*1) / 100);
            sMinPrice = (detailPrice[i].productPrice*1) + sMargin;
          }
          else
          {
            sMargin = this.productDetail.basicMargin*1;
            sMinPrice = (detailPrice[i].productPrice*1) + sMargin;
          }
          detailPrice[i].sMinPrice = sMinPrice;
          //detailPrice[3].sMinPrice = 0;
          i++;
        }
        this.detailPrice = detailPrice;
        //console.log("detailPrice:",this.detailPrice)
      }

      if(this.productDetail.addOptionFlag === "Y")
      {
        this.getPdtAddOptionExited();
        //this.getAddOption();
      }
      if(this.productDetail.etcOptionFlag === "Y")
      {
        this.getEtcOptionInfo();
      }
    },
    //기존상품 추가옵션정보
    async getPdtAddOptionExited() {
      //대상상품에 적용된 추가옵션코드 추출
      let addOptCodes = {};
      let addOptInfo = {};
      if(this.pdtFlag === 'MIX') {
        addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed_mix", {param: [this.productId]});
        this.addOptCodes = addOptCodes;
        addOptInfo = await this.$api("/api/pdtAddOption_existed_mix", {param: [this.productId]});
        //console.log("addOptInfo:",addOptInfo);
      }
      else {
        addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed", {param: [this.productId]});
        this.addOptCodes = addOptCodes;
        addOptInfo = await this.$api("/api/pdtAddOption_existed", {param: [this.productId]});
        //console.log("addOptInfo:",addOptInfo);
      }
      //console.log("addOptCodes:",this.addOptCodes);

      let i=0;
      let pdtAddOptExisted = [];
      while(i<addOptCodes.length) {
        //alert(addOptCodes[i].pdtCode);
        pdtAddOptExisted.push(addOptInfo.filter(e => {return e.pdtCode === addOptCodes[i].pdtCode}));
        i++;
      }
      if(pdtAddOptExisted.length > 0){
        this.pdtAddOptExisted = pdtAddOptExisted;
      }
      //console.log("pdtAddOptExisted:",this.pdtAddOptExisted);
    },
    //기타옵션획득(-선택옵션)
    async getEtcOptionInfo() {
      //this.etcOptionInfo = await this.$api("/api/etcOptionInfo", {param: [this.productId]});
      let etcOptionInfo = await this.$api("/api/etcOptionInfo1", {param: [this.productId]});
      this.etcOptionInfo = etcOptionInfo;
      //console.log("etcOptionInfo:",this.etcOptionInfo);
    },
    setRtnDate(){
      //console.log("selectedSDate:",this.selecDate);
      this.reqRtnDate = "";
      // 선택가능 리턴일 구간지정
      if(this.inquiryFlag === 'Y')
      {
        //리턴일=출발일가능(당일리턴)
        let dateVal_tmp = new Date(this.selecDate);
        //let tomorrow_tmp = new Date(dateVal_tmp.setDate(dateVal_tmp.getDate() + 1));
        let tomorrow_tmp = new Date(dateVal_tmp.setDate(dateVal_tmp.getDate()));
        let tomorrow = this.getDateFormat(tomorrow_tmp);
        //console.log("내일 : ", tomorrow);

        //let todayFin = this.reqDptDate;
        let todayFin = tomorrow;

        //console.log("rtnDate:",this.reqRtnDate);

        let tStart = this.getDateFormat(this.productDetail.tripStart);
        let tEnd = this.getDateFormat(this.productDetail.tripEnd);
        let sStart = this.getDateFormat(this.productDetail.salesStart);
        let sEnd = this.getDateFormat(this.productDetail.salesEnd);
        let rtDate_s = "";
        let rtDate_e = "";

        if(tStart == sStart) {
          rtDate_s = sStart;
        }
        else if(tStart < sStart) {
          rtDate_s = sStart;
        }
        else if(tStart > sStart) {
          rtDate_s = tStart;
        }

        if(tEnd == sEnd) {
          rtDate_e = sEnd;
        }
        else if(tEnd > sEnd) {
          rtDate_e = sEnd;
        }
        else if(tEnd < sEnd) {
          rtDate_e = tEnd;
        }

        document.getElementById("rtDate").setAttribute("min", todayFin);
        //document.getElementById("rtDate").setAttribute("max", rtDate_e);
        document.getElementById("rtDate").setAttribute("max", this.calLimitedEnd);
        console.log(rtDate_s,rtDate_e);
      }
    },
    //단순상품문의
    inquiryProduct() {
      //alert(this.productId);
      this.$router.push({path: '/inquiryProduct', query: {productId: this.productId, salesRoute:this.productDetail.salesRoute}});
    },
    checkDptDate(param)
    {
      let vatRate = this.productDetail.vatPrice
      console.log(param)

      let aduCnt = this.ageNumber[1]*1
      let chiCnt = this.ageNumber[2]*1
      let infCnt = this.ageNumber[3]*1
      //let disCnt = this.ageNumber[4]*1

      //성인 숫자가 0이면 계산무용
      if(aduCnt <= 0)
      {
        this.showPrice_G = 0
        this.showPrice_S = 0
        this.showPrice_P = 0

        this.showPrice = 0

        this.tot_aduPrice = 0
        this.tot_chiPrice = 0
        this.tot_infPrice = 0

        this.tot_aduCount = 0
        this.tot_chiCount = 0
        this.tot_infCount = 0
        this.tot_perCount = 0

        this.tot_margin = 0
        this.tot_promo = 0
        this.tot_vatPrice = 0

        //환급과 할인도 초기화
        this.tot_memRefund = 0
        this.tot_memDC = 0
        // this.tot_memRefund_base = 0
        // this.tot_memDC_base = 0
      }
      else
      {
        let aduPrice = this.selectedCalPdtPrice[0].sMinPrice*1
        let chiPrice = this.selectedCalPdtPrice[1].sMinPrice*1
        let infPrice = this.selectedCalPdtPrice[2].sMinPrice*1

        //저장할 1인당 금액
        this.aduPrice = aduPrice
        this.chiPrice = chiPrice
        this.infPrice = infPrice
        //let disPrice = this.selectedCalPdtPrice[3].sMinPrice*1

        let tot_aduPrice = aduCnt * aduPrice
        let tot_chiPrice = chiCnt * chiPrice
        let tot_infPrice = infCnt * infPrice
        //let tot_disPrice = disCnt * disPrice

        let tot_price_tmp = tot_aduPrice + tot_chiPrice + tot_infPrice
        let vatPrice = 0

        if(vatRate > 0)
        {
          vatPrice = tot_price * (vatRate/100)
        }

        let tot_price = tot_price_tmp + vatPrice

        //마진정보 별도계산
        let aduMargin = this.selectedCalPdtPrice[0].sMargin*1
        let chiMargin = this.selectedCalPdtPrice[1].sMargin*1
        let infMargin = this.selectedCalPdtPrice[2].sMargin*1
        let tot_aduMargin = aduCnt * aduMargin
        let tot_chiMargin = chiCnt * chiMargin
        let tot_infMargin = infCnt * infMargin

        let tot_Margin = tot_aduMargin + tot_chiMargin + tot_infMargin

        //프로모션정보 별도계산
        let aduPromo = this.selectedCalPdtPrice[0].sPromo*1
        let chiPromo = this.selectedCalPdtPrice[1].sPromo*1
        let infPromo = this.selectedCalPdtPrice[2].sPromo*1
        let tot_aduPromo = aduCnt * aduPromo
        let tot_chiPromo = chiCnt * chiPromo
        let tot_infPromo = infCnt * infPromo

        let tot_Promo = tot_aduPromo + tot_chiPromo + tot_infPromo

        /////가격비교 >>> 일반회원가격
        this.showPrice_G = tot_price
        this.base_showPrice_G = tot_price             //이전가격용

        /////가격비교
        //버스투어의 경우 동반할인처리
        if(this.productDetail.memLevel_P == 'Y' || this.productDetail.memLevel_S == 'Y')
        {
          if(this.productDetail.salesRoute === 'L' && (this.productDetail.nrFlag != 'Y' && this.productDetail.vrFlag != 'Y'))
          {
            if(aduCnt ==0)
            {
              this.showPrice_S = ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

              this.showPrice_P = ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
            }
            else if(aduCnt == 1)
            {
              this.showPrice_S = (this.selectedCalPdtPrice[0].pdtPrice * aduCnt - (this.selectedCalPdtPrice[0].pdtPrice * (this.n_memRf_S/100))) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

              this.showPrice_P = (this.selectedCalPdtPrice[0].pdtPrice * aduCnt - (this.selectedCalPdtPrice[0].pdtPrice * (this.n_memRf_P/100))) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
            }
            else if(aduCnt >= 2)
            {
              this.showPrice_S = ((this.selectedCalPdtPrice[0].pdtPrice * 1 - (this.selectedCalPdtPrice[0].pdtPrice * (this.n_memRf_S/100))) + (this.selectedCalPdtPrice[0].pdtPrice - (this.selectedCalPdtPrice[0].pdtPrice * this.n_companionDC_S/100)) * (aduCnt-1)) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

              this.showPrice_P = ((this.selectedCalPdtPrice[0].pdtPrice * 1 - (this.selectedCalPdtPrice[0].pdtPrice * (this.n_memRf_P/100))) + (this.selectedCalPdtPrice[0].pdtPrice- (this.selectedCalPdtPrice[0].pdtPrice * this.n_companionDC_P/100)) * (aduCnt-1)) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
            }
          }
          // //프리미엄전용 + 스탠다드혜택
          if((this.productDetail.memLevel_S === 'N' || this.productDetail.memLevel_S == null) && this.productDetail.dcForStd*1 > 0)
          {
            if(aduCnt == 0)
            {
              this.showPrice_S = ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

              this.showPrice_P = ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
            }
            else if(aduCnt == 1)
            {
              this.showPrice_S = (this.selectedCalPdtPrice[0].pdtPrice * aduCnt - (this.selectedCalPdtPrice[0].pdtPrice * (this.n_dcForStd/100))) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

              this.showPrice_P = (this.selectedCalPdtPrice[0].pdtPrice * aduCnt - (this.selectedCalPdtPrice[0].pdtPrice * (this.n_memRf_P/100))) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
            }
            else if(aduCnt >= 2)
            {
              this.showPrice_S = ((this.selectedCalPdtPrice[0].pdtPrice * 1 - (this.selectedCalPdtPrice[0].pdtPrice * (this.n_dcForStd/100))) + (this.selectedCalPdtPrice[0].pdtPrice - (this.selectedCalPdtPrice[0].pdtPrice * this.n_companionDC_S/100)) * (aduCnt-1)) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

              this.showPrice_P = ((this.selectedCalPdtPrice[0].pdtPrice * 1 - (this.selectedCalPdtPrice[0].pdtPrice * (this.n_memRf_P/100))) + (this.selectedCalPdtPrice[0].pdtPrice- (this.selectedCalPdtPrice[0].pdtPrice * this.n_companionDC_P/100)) * (aduCnt-1)) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
            }
          }
        }
        //일반상품 가격비교화면
        else
        {
          let n_dc_P = 0
          let n_dc_S = 0

          //할인방법반영
          if(this.n_dcRateForMem_P === 'P')
          {
            n_dc_P = this.selectedCalPdtPrice[0].pdtPrice * (this.n_dcForMem_P/100)
          }
          else
          {
            n_dc_P = this.n_dcForMem_P
          }
          if(this.n_dcRateForMem_S === 'P')
          {
            n_dc_S = this.selectedCalPdtPrice[0].pdtPrice * (this.n_dcForMem_S/100)
          }
          else
          {
            n_dc_S = this.n_dcForMem_S
          }

          if(aduCnt == 0)
          {
            this.showPrice_S = ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[0].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

            this.showPrice_P = ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
          }
          else if(aduCnt == 1)
          {
            this.showPrice_S = (this.selectedCalPdtPrice[0].pdtPrice * aduCnt - n_dc_S) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

            this.showPrice_P = (this.selectedCalPdtPrice[0].pdtPrice * aduCnt - n_dc_P) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
          }
          else if(aduCnt >= 2)
          {
            this.showPrice_S = ((this.selectedCalPdtPrice[0].pdtPrice * 1 - n_dc_S) + (this.selectedCalPdtPrice[0].pdtPrice - (this.selectedCalPdtPrice[0].pdtPrice * this.n_companionDC_S/100)) * (aduCnt-1)) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_S/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_S/100)) * infCnt)

            this.showPrice_P = ((this.selectedCalPdtPrice[0].pdtPrice * 1 - n_dc_P) + (this.selectedCalPdtPrice[0].pdtPrice - (this.selectedCalPdtPrice[0].pdtPrice * this.n_companionDC_P/100)) * (aduCnt-1)) + ((this.selectedCalPdtPrice[1].pdtPrice - (this.selectedCalPdtPrice[1].pdtPrice * this.n_companionDC_P/100)) * chiCnt) + ((this.selectedCalPdtPrice[2].pdtPrice - (this.selectedCalPdtPrice[2].pdtPrice * this.n_companionDC_P/100)) * infCnt)
          }
        }

        let tot_memRefund = 0
        let tot_memDC = 0

        //총환급금(대상은 본인1인 뿐)
        if(this.dispFlag === 'c1' || this.dispFlag === 'c3')
        {
          if(aduCnt > 0 )
          {
            tot_memRefund = this.priceForMem[0] * 1
          }
          else
          {
            tot_memRefund = 0
          }
          this.tot_memRefund_base = tot_memRefund     //기본상품가 기준 환급액
          this.tot_memRefund = tot_memRefund
        }
        //총할인액
        else if(this.dispFlag === 'c2' || this.dispFlag === 'c4' || this.dispFlag === 'c5')
        {
          //console.log("aduCnt:>>>", aduCnt, "priceForMem:::::::", this.priceForMem)
          //tot_memDC = (this.priceForMem[0] * aduCnt) + (this.priceForMem[1] * chiCnt) + (this.priceForMem[2] * infCnt)
          if(aduCnt > 0)
          {
            tot_memDC = this.priceForMem[0] * 1
          }
          else
          {
            tot_memDC = 0
          }
          this.tot_memDC_base = tot_memDC         //기본상품 기준할인총액
          this.tot_memDC = tot_memDC
        }
        // console.log("aduCnt:::", aduCnt, "tot_memDC:<<<>>>>", this.tot_memDC, "tot_memRefund<<<>>>>>", this.tot_memRefund)

        this.showPrice = tot_price
        this.base_showPrice_G = this.showPrice
        this.base_showPrice_S = this.showPrice_S
        this.base_showPrice_P = this.showPrice_P

        this.tot_aduPrice = tot_aduPrice
        this.tot_chiPrice = tot_chiPrice
        this.tot_infPrice = tot_infPrice

        this.tot_aduCount = aduCnt
        this.tot_chiCount = chiCnt
        this.tot_infCount = infCnt
        this.tot_perCount = aduCnt + chiCnt + infCnt

        this.tot_margin = tot_Margin
        this.tot_promo = tot_Promo
        this.tot_vatPrice = vatPrice

        //옵션 또는 추상변화 반영
        // this.showPrice_G = this.base_showPrice_G + this.totalOptions + this.totalAddProducts         //표시용
        // this.showPrice_S = this.base_showPrice_S + this.totalOptions + this.totalAddProducts         //표시용
        // this.showPrice_P = this.base_showPrice_P + this.totalOptions + this.totalAddProducts         //표시용

      }
      //console.log("tot_memRefund:", this.tot_memRefund,",tot_memDC:", this.tot_memDC, " ,showPrice:",this.showPrice, ",priceForMem:",this.priceForMem[0], "dispFlag:", this.dispFlag)
    },

    //예약코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++)
      {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_resCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N")
      {
        this.$swal.fire("","예약번호 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      }
      else
      {
        return rtnCode
      }
    },

  async orderCheck(flag){
    //멤버십 회원의 경우 버스투어 동시예약 3회제한
    if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
    {
      let check_memBooking = await this.$api("/api/check_memBooking", { param: [this.user.email] });
      if(check_memBooking.length >= 3)
      {
        if(this.productDetail.memLevel_P == 'Y' || this.productDetail.memLevel_S == 'Y')
        {
          this.$swal.fire("","버스투어의 예약은 <b>최대 3건<b>까지 동시 예약이 가능하며 <br>3건이 넘어가는 예약은 제한됩니다.","warning")
          return false;
        }
      }
    }

    this.showModal2 = false
    console.log(flag+'21K23!98h40D')
    //로그인체크
    if(this.userInfo == undefined || this.userInfo == "" || !this.userInfo)
    {
      //this.$swal.fire("","로그인이 필요합니다.","info");
      this.$swal.fire({
        html: "<b>선택옵션과 추가상품</b>이 있는 경우,<br>구매여부를 확인해주세요<br><br><b>예약</b>을 시작합니다",
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: "로그인 페이지로 이동",
        denyButtonText: "비회원으로 예약진행",
        imageUrl: '/IMGs/welcome.png',
        imageWidth: 400,
        imageHeight: 200,
      })
      .then((result) => {
          if(result.isConfirmed)
          {
            this.$router.push({path: '/b2CLogin', query:{st: "Y"}});
          }
          else if(result.isDenied)
          {
            this.memberFlag = "N"
            //console.log("choiceMemberFlag:", this.memberFlag)
            this.order(flag)
          }
        })
      }
      else
      {
        this.order(flag)
      }
    },

    // //체크된 선택옵션 일괄해제
    // changeUncheckedSelOptions(){
    //   this.newOptTotal_G = 0
    //   this.newOptTotal_S = 0
    //   this.newOptTotal_P = 0

    //   this.selectableOptInfo.forEach(opt => {
    //     opt.isChecked = false;
    //   })
    // },

    //선택옵션 초기화
    changeUncheckedSelOptions_new(){
      this.newOptTotal_G = 0
      this.newOptTotal_S = 0
      this.newOptTotal_P = 0

      this.optCode = 'noCode'
      //this.checkSelOption_new("noCode")
    },

    //선택옵션 중복불가에 따른 로직변경반영(20240729)
    async checkSelOption_new(oCode){
      //alert(oCode)
      if(this.tot_aduCount <= 0)
      {
        this.changeUncheckedSelOptions_new();

        if (window.innerWidth <= 768) {       // 모바일 화면인 경우
          this.$swal.fire({
            icon: 'warning',
            title: '구매인원이 없습니다',
            toast: true,
            width: '270px',
            position: 'center',
            showConfirmButton: false,
            customClass: {
              popup: 'high-z-index'
            },
            timer: 2000                     // 토스트 메시지를 보여주는 시간 (ms)
          });
        } else {                            // 데스크톱 화면인 경우
          this.$swal.fire("", "구매인원이 없습니다", "warning");
        }
        return false;
      }

      // 새로 계산된 금액을 임시 저장할 변수 초기화
      let newOptTotal_G = 0;
      let newOptTotal_S = 0;
      let newOptTotal_P = 0;

      let prices = []
      let totPrices = 0

      //멤버십 본인할인계산용
      let selOptPrice_adu = 0
      //멤버십 소인할인계산용
      let selOptPrice_chi = 0
      //멤버십 유아할인계산용
      let selOptPrice_inf = 0

      if(oCode === "noVal" || oCode === "noCode")
      {
        prices = [{
          optPdtPrice: 0,
          optPdtPrice_chi: 0,
          optPdtPrice_inf: 0
        }]
        totPrices = 0
        selOptPrice_adu = 0
        selOptPrice_chi = 0
        selOptPrice_inf = 0
      }
      else
      {
        prices = await this.$api("/api/selOptPriceInfo", { param: [oCode] });
        totPrices = (prices[0].optPdtPrice * this.tot_aduCount) + (prices[0].optPdtPrice_chi * this.tot_chiCount) + (prices[0].optPdtPrice_inf * this.tot_infCount);
        selOptPrice_adu = (prices[0].optPdtPrice < 0 || prices[0].optPdtPrice == undefined || !prices[0].optPdtPrice) ? 0 : prices[0].optPdtPrice*1
        selOptPrice_chi = (prices[0].optPdtPrice_chi < 0  || prices[0].optPdtPrice_chi == undefined || !prices[0].optPdtPrice_chi) ? 0 : prices[0].optPdtPrice_chi*1
        selOptPrice_inf = (prices[0].optPdtPrice_inf < 0  || prices[0].optPdtPrice_inf == undefined || !prices[0].optPdtPrice_inf) ? 0 : prices[0].optPdtPrice_inf*1

      }
      
      console.log(selOptPrice_chi+"dksdfiss", selOptPrice_inf+"2lk9sdf")

      //////////////// 가격비교용
      //일반상품 또는 버스투어&&엔데어||버라이어티팩
      if(this.productDetail.salesRoute != "L" || (this.productDetail.salesRoute === "L" && (this.productDetail.nrFlag === "Y" || this.productDetail.vrFlag === "Y")))
      {
        //비회원 또는 일반회원
        newOptTotal_G = totPrices*1;

        //스탠다드멤버십할인이 있는 경우
        if(this.productDetail.dcForMem_S > 0)
        {
          newOptTotal_S = (totPrices*1 - (selOptPrice_adu*1 * ((this.productDetail.dcForMem_S*1)/100)))
        }
        else
        {
          newOptTotal_S = totPrices*1
        }
        //프리미엄멤버십할인이 있는 경우
        if(this.productDetail.dcForMem_P > 0)
        {
          newOptTotal_P = (totPrices*1 - (selOptPrice_adu*1 * ((this.productDetail.dcForMem_P*1)/100)))
        }
        else
        {
          newOptTotal_P = totPrices*1
        }
      }
      //로컬투어
      else
      {
        let selOptRefund_P =  0                     //프리미엄 본인환급
        let selOptCompDC_P =  0                     //프리미엄 동반할인
        let selOptRefund_S =  0                     //스탠다드 본인환급
        let selOptCompDC_S =  0                     //스탠다드 동반할인
        let selOptDCForStd =  0                     //프리미엄 스탠다드할인

        //비회원 또는 일반회원
        newOptTotal_G = totPrices;

        // 멤버십
        ////1. 프리미엄
        //////본인환급
        if(this.productDetail.dcRateForPre > 0 && this.productDetail.memLevel_P === "Y")
        {
          selOptRefund_P = (selOptPrice_adu*1) * ((this.productDetail.dcRateForPre*1)/100)
        }
        //////동반할인
        if(this.productDetail.companionDC_P > 0)
        {
          let tot_tmp = totPrices*1 - selOptPrice_adu*1
          selOptCompDC_P = (tot_tmp - (tot_tmp * ((this.productDetail.companionDC_P*1)/100)))
          newOptTotal_P = (selOptPrice_adu*1 - selOptRefund_P) + selOptCompDC_P
        }

        ////2. 스탠다드
        //////본인환급
        if(this.productDetail.dcRateForStd > 0 && this.productDetail.memLevel_S === "Y")
        {
          selOptRefund_S = (selOptPrice_adu*1) * ((this.productDetail.dcRateForStd*1)/100)
          //newOptTotal_S += (op.selOptPrice_adu - selOptRefund_S) + selOptCompDC_S

        //////동반할인
          if(this.productDetail.companionDC_S > 0)
          {
            let tot_tmp = (this.tot_aduCount > 1) ? totPrices*1 - selOptPrice_adu*1 : 0       //성인1인이면 동반인=0

            selOptCompDC_S = (tot_tmp - (tot_tmp * ((this.productDetail.companionDC_S*1)/100)))
            //console.log("selOptCompDC_S:", selOptCompDC_S)

            newOptTotal_S = (selOptPrice_adu - selOptRefund_S) + selOptCompDC_S
          }
        }
        //프리미엄구매 시 스탠다드에 할인
        else if(this.productDetail.dcForStd > 0 && this.productDetail.memLevel_S === "N")
        {
          selOptDCForStd = selOptPrice_adu*1 - (selOptPrice_adu*1 * ((this.productDetail.dcForStd)*1/100))
          //newOptTotal_S += selOptCompDC_S + selOptDCForStd
          //동반할인
          if(this.productDetail.companionDC_S > 0)
          {
            let tot_tmp = (this.tot_aduCount > 1) ? totPrices*1 - selOptPrice_adu*1 : 0       //성인1인이면 동반인=0

            selOptCompDC_S = (tot_tmp - (tot_tmp * ((this.productDetail.companionDC_S*1)/100)))
            //console.log("selOptCompDC_S:", selOptCompDC_S)
            newOptTotal_S = selOptDCForStd + selOptCompDC_S
          }
        }
      }
      this.newOptTotal_G = newOptTotal_G
      this.newOptTotal_S = newOptTotal_S
      this.newOptTotal_P = newOptTotal_P
      //console.log("newOptTotal:AA>>>>", this.newOptTotal_G,this.newOptTotal_S,this.newOptTotal_P)

      this.showPrice_G = this.base_showPrice_G + this.newOptTotal_G + this.totalAddProducts;
      this.showPrice_S = this.base_showPrice_S + this.newOptTotal_S + this.totalAddProducts;
      this.showPrice_P = this.base_showPrice_P + this.newOptTotal_P + this.totalAddProducts;


      /////////////////////////DB처리용 계산
      //console.log("selectableOptInfo:", this.selectableOptInfo)
      // 선택된 옵션들의 금액 계산
      let optPriceDC_adu = 0    //일반or엔데어or버라이어티 성인할인
      let optPriceDC_chi = 0    //옵션권종별 할인가격
      let optPriceDC_inf = 0    //옵션권종별 할인가격
      let optPriceRef_adu= 0    //멤버십 성인환급
      let optPriceDCStd  = 0    //프리미엄을 스탠다드가 구매시 특전
      let optPrice_basic = []   //선택옵션 기본권종가격

      //선택된 선택옵션 정보
      let sp = this.selectableOptInfo.filter(item => {
        return item.optCode === oCode
      })

      if(sp.length > 0)
      {
        this.sp = sp
      }

      //console.log("sp:",sp, optPriceDC_adu, optPriceDC_chi, optPriceDC_inf, optPriceRef_adu, optPriceDCStd, "optPrice_basic:", optPrice_basic)

      if(sp.length > 0)
      {
        //옵션기본권종가격
        optPrice_basic = [sp[0].optPdtPrice, sp[0].optPdtPrice_chi, sp[0].optPdtPrice_inf]
        //console.log("optPrice_basic:",optPrice_basic)
        /////// 멤버십회원
        if(this.user.memLevel === "P" || this.user.memLevel === "S")
        {
          //일반상품 || 버라이어티 || 엔데어
          if(this.productDetail.salesRoute != "L" || (this.productDetail.salesRoute === "L" && (this.productDetail.nrFlag === "Y" || this.productDetail.vrFlag === "Y")))
          {
            //프리미엄멤버십할인(본인)
            if(this.productDetail.dcForMem_P > 0)
            {
              optPriceDC_adu = sp[0].optPdtPrice*(this.productDetail.dcForMem_P/100)
            }
            //스탠다드멤버십할인(본인)
            else if(this.productDetail.dcForMem_S > 0)
            {
              optPriceDC_adu = sp[0].optPdtPrice*(this.productDetail.dcForMem_S/100)
            }
            else
            {
              optPriceDC_adu = 0
            }
            this.addObject("ic",oCode, [optPriceDC_adu, 0, 0, 0, 0], optPrice_basic)
          }
          //로컬투어
          else
          {
            //프리미엄환급 && 동반할인
            if(this.user.memLevel === "P")
            {
              if(this.productDetail.dcRateForPre > 0)
              {
                optPriceRef_adu = (sp[0].optPdtPrice*1) * ((this.productDetail.dcRateForPre*1)/100)
              }
              else
              {
                optPriceRef_adu = 0
              }
              if(this.productDetail.companionDC_P > 0)
              {
                optPriceDC_adu = (this.tot_aduCount > 1) ? sp[0].optPdtPrice*(this.productDetail.companionDC_P/100) : 0
                optPriceDC_chi = sp[0].optPdtPrice_chi*(this.productDetail.companionDC_P/100)
                optPriceDC_inf = sp[0].optPdtPrice_inf*(this.productDetail.companionDC_P/100)
              }
              else
              {
                optPriceDC_adu = 0
                optPriceDC_chi = 0
                optPriceDC_inf = 0
              }
              this.addObject("ic",oCode, [optPriceDC_adu, optPriceDC_chi, optPriceDC_inf, optPriceRef_adu, 0], optPrice_basic)
            }
            //스탠다드환급 || 스탠다드혜택할인 && 동반할인
            else if(this.user.memLevel === "S")
            {
              if(this.productDetail.companionDC_S > 0)
              {
                optPriceDC_adu = (this.tot_aduCount > 1) ? sp[0].optPdtPrice*(this.productDetail.companionDC_S/100) : 0
                optPriceDC_chi = sp[0].optPdtPrice_chi*(this.productDetail.companionDC_S/100)
                optPriceDC_inf = sp[0].optPdtPrice_inf*(this.productDetail.companionDC_S/100)
              }
              else
              {
                optPriceDC_adu = 0
                optPriceDC_chi = 0
                optPriceDC_inf = 0
              }

              if(this.productDetail.dcRateForStd > 0)
              {
                optPriceRef_adu = (sp[0].optPdtPrice*1) * ((this.productDetail.dcRateForStd*1)/100)
                optPriceDCStd = 0
              }
              //프리미엄상품의 스탠다드 혜택할인
              else if(this.productDetail.dcForStd > 0)
              {
                optPriceDCStd = sp[0].optPdtPrice*(this.productDetail.dcForStd/100)
                optPriceRef_adu = 0
              }
              this.addObject("ic",oCode, [optPriceDC_adu, optPriceDC_chi, optPriceDC_inf, optPriceRef_adu, optPriceDCStd], optPrice_basic)
              //console.log(oCode, [optPriceDC_adu, optPriceDC_chi, optPriceDC_inf, optPriceRef_adu, optPriceDCStd], optPrice_basic)
            }
          }
        }
        else
        {
          //비회원은 할인도 환급도 없음
          this.addObject("ic",oCode, [0, 0, 0, 0], optPrice_basic)
        }
      }
      else      //옵션선택없음
      {
        // this.$swal.fire("","옵션가격이 확인되지 않습니다. 재시도해주세요.<br>반복되면 문의처(1660-4602)로 연락주십시오", "warining")
        // location.reload(true)
        this.addObject("ic",oCode, [0, 0, 0, 0], optPrice_basic)
      }
    },


    //선택옵션 체크 시 처리로직(옵션 중복선택을 허용하는 old logic)
/************************** Old login start (Select Option)*****************************************    
    async checkSelOption(oCode, cFlag)
    {
      //console.log("this.showPrice************", this.showPrice)
      //계산가능 인원수 체크
      if(this.tot_aduCount <= 0)
      {
        this.changeUncheckedSelOptions();

        if (window.innerWidth <= 768) {       // 모바일 화면인 경우
          this.$swal.fire({
            icon: 'warning',
            title: '구매인원이 없습니다',
            toast: true,
            width: '270px',
            position: 'center',
            showConfirmButton: false,
            customClass: {
              popup: 'high-z-index'
            },
            timer: 2000                     // 토스트 메시지를 보여주는 시간 (ms)
          });
        } else {                            // 데스크톱 화면인 경우
          this.$swal.fire("", "구매인원이 없습니다", "warning");
        }
        return false;
      }


      // 새로 계산된 금액을 임시 저장할 변수 초기화
      let newOptTotal_G = 0;
      let newOptTotal_S = 0;
      let newOptTotal_P = 0;
      //let tot_optDC = 0;                //멤버십관련 옵션할인총액

      // 선택된 옵션들의 금액 계산
      for (let op of this.selectableOptInfo)
      {
        if (op.isChecked)
        {
          let prices = await this.$api("/api/selOptPriceInfo", { param: [op.optCode] });

          op.totPrices = (prices[0].optPdtPrice * this.tot_aduCount) +
                          (prices[0].optPdtPrice_chi * this.tot_chiCount) +
                          (prices[0].optPdtPrice_inf * this.tot_infCount);
          op.selOptPrice_adu = (prices[0].optPdtPrice < 0 || prices[0].optPdtPrice == undefined || !prices[0].optPdtPrice) ? 0 : prices[0].optPdtPrice*1                      //멤버십 본인할인계산용
          op.selOptPrice_chi = (prices[0].optPdtPrice_chi < 0  || prices[0].optPdtPrice_chi == undefined || !prices[0].optPdtPrice_chi) ? 0 : prices[0].optPdtPrice_chi*1        //멤버십 소인할인계산용
          op.selOptPrice_inf = (prices[0].optPdtPrice_inf < 0  || prices[0].optPdtPrice_inf == undefined || !prices[0].optPdtPrice_inf) ? 0 : prices[0].optPdtPrice_inf*1        //멤버십 유아할인계산용
          //console.log(op.selOptPrice_adu,"//", op.selOptPrice_chi, "//", op.selOptPrice_inf)
        }
        else if(!op.isChecked)
        {
          op.totPrices = 0        //선택옵션 해제 시
          op.selOptPrice_adu = 0
          op.selOptPrice_chi = 0
          op.selOptPrice_inf = 0
        }
        // console.log("op.PriceInfo", op.totPrices,"///", op.selOptPrice_adu)

        //////////////// 가격비교용
        //일반상품 또는 버스투어&&엔데어||버라이어티팩
        if(this.productDetail.salesRoute != "L" || (this.productDetail.salesRoute === "L" && (this.productDetail.nrFlag === "Y" || this.productDetail.vrFlag === "Y")))
        {
          //비회원 또는 일반회원
          newOptTotal_G += op.totPrices*1;

          //스탠다드멤버십할인이 있는 경우
          if(this.productDetail.dcForMem_S > 0)
          {
            newOptTotal_S += (op.totPrices*1 - (op.selOptPrice_adu*1 * ((this.productDetail.dcForMem_S*1)/100)))
          }
          else
          {
            newOptTotal_S += op.totPrices*1
          }
          //프리미엄멤버십할인이 있는 경우
          if(this.productDetail.dcForMem_P > 0)
          {
            newOptTotal_P += (op.totPrices*1 - (op.selOptPrice_adu*1 * ((this.productDetail.dcForMem_P*1)/100)))
          }
          else
          {
            newOptTotal_P += op.totPrices*1
          }
        }
        //로컬투어
        else
        {
          let selOptRefund_P =  0                     //프리미엄 본인환급
          let selOptCompDC_P =  0                     //프리미엄 동반할인

          let selOptRefund_S =  0                     //스탠다드 본인환급
          let selOptCompDC_S =  0                     //스탠다드 동반할인

          let selOptDCForStd =  0                     //프리미엄 스탠다드할인

          //비회원 또는 일반회원
          newOptTotal_G += op.totPrices;

          // 멤버십
            //1. 프리미엄
              //본인환급
          if(this.productDetail.dcRateForPre > 0 && this.productDetail.memLevel_P === "Y")
          {
            selOptRefund_P = (op.selOptPrice_adu*1) * ((this.productDetail.dcRateForPre*1)/100)
          }
              //동반할인
          if(this.productDetail.companionDC_P > 0)
          {
            let tot_tmp = op.totPrices*1 - op.selOptPrice_adu*1
            selOptCompDC_P = (tot_tmp - (tot_tmp * ((this.productDetail.companionDC_P*1)/100)))

            newOptTotal_P += (op.selOptPrice_adu*1 - selOptRefund_P) + selOptCompDC_P
          }

          //2. 스탠다드
          //본인환급
          if(this.productDetail.dcRateForStd > 0 && this.productDetail.memLevel_S === "Y")
          {
            selOptRefund_S = (op.selOptPrice_adu*1) * ((this.productDetail.dcRateForStd*1)/100)
            //newOptTotal_S += (op.selOptPrice_adu - selOptRefund_S) + selOptCompDC_S

            //동반할인
            if(this.productDetail.companionDC_S > 0)
            {
              let tot_tmp = (this.tot_aduCount > 1) ? op.totPrices*1 - op.selOptPrice_adu*1 : 0       //성인1인이면 동반인=0

              selOptCompDC_S = (tot_tmp - (tot_tmp * ((this.productDetail.companionDC_S*1)/100)))
              //console.log("selOptCompDC_S:", selOptCompDC_S)

              newOptTotal_S += (op.selOptPrice_adu - selOptRefund_S) + selOptCompDC_S
            }
          }
          //프리미엄구매 시 스탠다드에 할인
          else if(this.productDetail.dcForStd > 0 && this.productDetail.memLevel_S === "N")
          {
            selOptDCForStd = op.selOptPrice_adu*1 - (op.selOptPrice_adu*1 * ((this.productDetail.dcForStd)*1/100))
            //newOptTotal_S += selOptCompDC_S + selOptDCForStd
            //동반할인
            if(this.productDetail.companionDC_S > 0)
            {
              let tot_tmp = (this.tot_aduCount > 1) ? op.totPrices*1 - op.selOptPrice_adu*1 : 0       //성인1인이면 동반인=0

              selOptCompDC_S = (tot_tmp - (tot_tmp * ((this.productDetail.companionDC_S*1)/100)))
              //console.log("selOptCompDC_S:", selOptCompDC_S)

              newOptTotal_S += selOptDCForStd + selOptCompDC_S
            }
          }
        }
      }
      // console.log("memRefund:", this.tot_memRefund, "/ memberDC:", this.tot_memDC)
      this.newOptTotal_G = newOptTotal_G
      this.newOptTotal_S = newOptTotal_S
      this.newOptTotal_P = newOptTotal_P
      //console.log("newOptTotal:AA>>>>", this.newOptTotal_G,this.newOptTotal_S,this.newOptTotal_P)

      this.showPrice_G = this.base_showPrice_G + this.newOptTotal_G + this.totalAddProducts;
      this.showPrice_S = this.base_showPrice_S + this.newOptTotal_S + this.totalAddProducts;
      this.showPrice_P = this.base_showPrice_P + this.newOptTotal_P + this.totalAddProducts;



      /////////////////////////DB처리용 계산
      // 선택된 옵션들의 금액 계산
      //console.log("this.selectableOptInfo:>>>>>>",this.selectableOptInfo, cFlag)
      let optPriceDC_adu = 0    //일반or엔데어or버라이어티 성인할인
      let optPriceDC_chi = 0    //옵션권종별 할인가격
      let optPriceDC_inf = 0    //옵션권종별 할인가격
      let optPriceRef_adu = 0   //멤버십 성인환급
      let optPriceDCStd = 0     //프리미엄을 스탠다드가 구매시 특전
      let optPrice_basic = []   //선택옵션 기본권종가격

      let sp = this.selectableOptInfo.filter(item => {
        return item.optCode === oCode
      })
      //console.log("sp:",sp)
      if(sp.length > 0)
      {
        if(cFlag)
        {
          //옵션기본권종가격
          optPrice_basic = [sp[0].optPdtPrice, sp[0].optPdtPrice_chi, sp[0].optPdtPrice_inf]
          //console.log("optPrice_basic:",optPrice_basic)

          /////// 멤버십회원
          if(this.user.memLevel === "P" || this.user.memLevel === "S")
          {
            //일반상품 || 버라이어티 || 엔데어
            if(this.productDetail.salesRoute != "L" || (this.productDetail.salesRoute === "L" && (this.productDetail.nrFlag === "Y" || this.productDetail.vrFlag === "Y")))
            {
              //프리미엄멤버십할인(본인)
              if(this.productDetail.dcForMem_P > 0)
              {
                optPriceDC_adu = sp[0].selOptPrice_adu*(this.productDetail.dcForMem_P/100)
              }
              //스탠다드멤버십할인(본인)
              else if(this.productDetail.dcForMem_S > 0)
              {
                optPriceDC_adu = sp[0].selOptPrice_adu*(this.productDetail.dcForMem_S/100)
              }
              else
              {
                optPriceDC_adu = 0
              }

              this.addObject("ic",oCode, [optPriceDC_adu, 0, 0, 0, 0], optPrice_basic)
            }
            //로컬투어
            else
            {
              //프리미엄환급 && 동반할인
              if(this.user.memLevel === "P")
              {
                if(this.productDetail.dcRateForPre > 0)
                {
                  optPriceRef_adu = (sp[0].selOptPrice_adu*1) * ((this.productDetail.dcRateForPre*1)/100)
                }
                else
                {
                  optPriceRef_adu = 0
                }

                if(this.productDetail.companionDC_P > 0)
                {
                  optPriceDC_adu = (this.tot_aduCount > 1) ? sp[0].selOptPrice_adu*(this.productDetail.companionDC_P/100) : 0
                  optPriceDC_chi = sp[0].selOptPrice_chi*(this.productDetail.companionDC_P/100)
                  optPriceDC_inf = sp[0].selOptPrice_inf*(this.productDetail.companionDC_P/100)
                }
                else
                {
                  optPriceDC_adu = 0
                  optPriceDC_chi = 0
                  optPriceDC_inf = 0
                }
                this.addObject("ic",oCode, [optPriceDC_adu, optPriceDC_chi, optPriceDC_inf, optPriceRef_adu, 0], optPrice_basic)
              }
              //스탠다드환급 || 스탠다드혜택할인 && 동반할인
              else if(this.user.memLevel === "S")
              {
                if(this.productDetail.companionDC_S > 0)
                {
                  optPriceDC_adu = (this.tot_aduCount > 1) ? sp[0].selOptPrice_adu*(this.productDetail.companionDC_S/100) : 0
                  optPriceDC_chi = sp[0].selOptPrice_chi*(this.productDetail.companionDC_S/100)
                  optPriceDC_inf = sp[0].selOptPrice_inf*(this.productDetail.companionDC_S/100)
                }
                else
                {
                  optPriceDC_adu = 0
                  optPriceDC_chi = 0
                  optPriceDC_inf = 0
                }

                if(this.productDetail.dcRateForStd > 0)
                {
                  optPriceRef_adu = (sp[0].selOptPrice_adu*1) * ((this.productDetail.dcRateForStd*1)/100)
                  optPriceDCStd = 0
                }
                //프리미엄상품의 스탠다드 혜택할인
                else if(this.productDetail.dcForStd > 0)
                {
                  optPriceDCStd = sp[0].selOptPrice_adu*(this.productDetail.dcForStd/100)
                  optPriceRef_adu = 0
                }
                this.addObject("ic",oCode, [optPriceDC_adu, optPriceDC_chi, optPriceDC_inf, optPriceRef_adu, optPriceDCStd], optPrice_basic)
              }
            }
          }
          else
          {
            //비회원은 할인도 환급도 없음
            this.addObject("ic",oCode, [0, 0, 0, 0], optPrice_basic)
          }
        }
      }
      else
      {
        this.$swal.fire("","옵션가격이 확인되지 않습니다. 재시도해주세요.<br>반복되면 문의처(1660-4602)로 연락주십시오", "warining")
        location.reload(true)
      }
      if(!cFlag)        //체크해제한 옵션처리
      {
        this.addObject("ec",sp[0].optCode)
      }
    },
************************************************* old logic end(select Otion) ******************************************************************/

    //선택옵션 권종가격정보
    //value0=> dcAdu, dcChi, dcInf, refAdu, dcStd \\ value2: priceAdu, priceChi, priceInf
    addObject(flag, key, value, b_value){
      this.showPrice = this.showPrice_G

      if(flag === "ic")                       //추가
      {
        //key가 부존재 -> 새로운 배열생성
        if(!this.obj_selectedOptPrice[key])
        {
          this.obj_selectedOptPrice[key] = [];
        }
        this.obj_selectedOptPrice[key].push(value, b_value)
      }
      else if(flag === "ec")                //삭제
      {
        if(key in this.obj_selectedOptPrice)
        {
          delete this.obj_selectedOptPrice[key]
        }
      }
      this.showPrice = this.base_showPrice_G + this.newOptTotal_G + this.totalAddProducts;            //DB처리용
    },

    //추가상품 계산처리
    calCnt(st, flag, aCode){
      //console.log("aCode:", aCode)
      if(this.tot_aduCount <= 0)
      {
        if (window.innerWidth <= 768) {       // 모바일 화면인 경우
          this.$swal.fire({
            icon: 'warning',
            title: '구매인원이 없습니다',
            toast: true,
            width: '270px',
            position: 'center',
            showConfirmButton: false,
            customClass: {
              popup: 'high-z-index'
            },
            timer: 2000                     // 토스트 메시지를 보여주는 시간 (ms)
          });
        } else {                            // 데스크톱 화면인 경우
          this.$swal.fire("", "구매인원이 없습니다", "warning");
        }
        return false;
      }
      if(flag === "m")
      {
        if(st === "a" && this.addPdtCnts[aCode] > 0)
        {
          this.addPdtCnts[aCode]--
        }
        else if(st === "a" && this.addPdtCnts[aCode] <= 0)
        {
          this.addPdtCnts[aCode] = 0
        }

        else
        {
          this.$swal.fire("","누적연산에 오류가 발생했습니다. 화면갱신(F5) 후 재시도 해주세요", "warning")
        }
        // else if(st === "o" && this.optCnts[aCode] > 0)
        // {
        //   this.optCnts[aCode]--
        // }
      }
      // 플러스 버튼이 클릭된 경우
      else if(flag === 'p') {
        if(st === "a")
        {
          this.addPdtCnts[aCode]++
        }
        else
        {
          this.$swal.fire("","누적연산에 오류가 발생했습니다. 화면갱신(F5) 후 재시도 해주세요", "warning")
        }
      }

      //전체 추가상품누적액
      let totalAddProducts = 0

      if(st === "a")
      {
        //현재 선택된 추가상품수량의 가격합
        let tot_selAddPrices_tmp = (this.addPricesByCode[aCode][0])*this.addPdtCnts[aCode]
        this.tot_selAddPrices[aCode] = tot_selAddPrices_tmp

        for(let adCode in this.tot_selAddPrices)
        {
          totalAddProducts += this.tot_selAddPrices[adCode]
          this.totalAddProducts = totalAddProducts
        }
      }

      //선택추가상품정보
      let selAddInfo = []

      for (let code in this.addPdtCnts)
      {
        let qty = this.addPdtCnts[code]

        if(qty > 0)
        {
          let amt = this.addPricesByCode[code]
          let codeInfo = {
            code: code,
            qty: qty,
            amt: amt
          }

          selAddInfo.push(codeInfo)
        }
      }
      this.selAddInfo = selAddInfo

      //console.log("selOptions::::::", this.newOptTotal_G,this.newOptTotal_S,this.newOptTotal_P)

      this.showPrice_G = this.base_showPrice_G + this.newOptTotal_G + this.totalAddProducts;          //표시용
      this.showPrice_S = this.base_showPrice_S + this.newOptTotal_S + this.totalAddProducts;          //표시용
      this.showPrice_P = this.base_showPrice_P + this.newOptTotal_P + this.totalAddProducts;          //표시용

      this.showPrice = this.base_showPrice_G + this.newOptTotal_G + this.totalAddProducts;            //DB처리용
    },


    async order(flag)
    {
      const selOptCode = [];

      //선택옵션 중복선택허용 로직(old)
      // this.selectableOptInfo.forEach((op) => {
      //   if (op.isChecked)
      //   {
      //     // 선택된 옵션의 optCode 값을 selectedOptCodes 배열에 추가
      //     selOptCode.push(op.optCode);
      //   }
      // });

      //선택옵션 중복선택불가 로직반영
      if(this.sp.length > 0)
      {
        selOptCode.push(this.sp[0].optCode);
      }
      
      // 선택된 옵션들의 optCode 값을 담은 배열을 반환
      //return selectedOptCodes;
      this.selOptCode = selOptCode

      this.noPopFlag = "Y"    //새로고침방지

     //예약번호생성
      let catCode = "RQ-" + this.salesRoute;
      let reqReserveCode = await this.genCode(catCode)
      this.reqReserveCode = reqReserveCode
      // console.log("resCode:", this.reqReserveCode)
      console.log("flag1:", flag+'21K23!98h40D')

      this.reqRtnDate = (this.reqRtnDate == "") ? null : this.reqRtnDate;

      //소숫점절사
      let reqPdtPriceFin = (this.showPrice).toFixed(0)*1

      if(reqReserveCode === undefined || this.showPrice === undefined || this.showPrice < 0 || this.productDetail.pdtCode === undefined) {
        var swal = require('sweetalert2');
        swal.fire({
          html: '예약진행 중 오류가 있습니다.<br>확인 후 재신청하여 주십시오',
          icon: 'warning'
        });
        return false;
      }
      else if(this.tot_aduCount == 0 && this.tot_chiCount == 0) {
        this.$swal.fire("","예약인원수를 확인해주세요","question");
        return false;
      }
      else
      {
        let disCnt = 0
        let disPrice = 0
        let rCopyFlag = "N"
        let reqStatus = "IQ"
        let completeFlag = "N"

        let priceForMem1 = 0
        let priceForMem2 = 0
        let priceForMem3 = 0

        //this.userInfo = handlerId OR user.email

        //예약정보 일부생성(환급/할인은 당사자에게만 적용반영)
        let res_insReserveM = await this.$api("/api/proc_insReserve_m_new",
          {param: [this.reqReserveCode,this.productId,reqStatus,this.selectedDate,this.tot_infCount,this.tot_aduCount,this.tot_chiCount,disCnt,this.tot_perCount,this.aduPrice,this.chiPrice,
          this.infPrice,disPrice,this.tot_margin,this.tot_vatPrice,this.reqRtnDate,this.tripProp,this.tot_promo,this.productDetail.stayCnt,reqPdtPriceFin,reqPdtPriceFin,rCopyFlag,
          this.productDetail.basicMargin,this.productDetail.marginMethod,this.productDetail.ctCd,completeFlag,this.pdtPromoInfo.promoCode,
          this.memberFlag, this.user.memLevel, this.dispFlag, this.priceForMem[0], priceForMem1, priceForMem2, priceForMem3,
          this.productDetail.companionDC_P, this.productDetail.companionDC_S, this.productDetail.dcForMem_P, this.productDetail.dcRateForMem_P, this.productDetail.dcForMem_S,
          this.productDetail.dcRateForMem_S,
          this.productDetail.dcRateForPre, this.productDetail.dcRateForStd, this.productDetail.dcForStd, this.productDetail.memLevel_P, this.productDetail.memLevel_S,
          this.productDetail.nrFlag, this.productDetail.vrFlag,
          this.showPrice_P, this.showPrice_S //, this.showPrice_G,
          //'',this.aduPrice,
          //this.memberFlag, this.user.memLevel, this.dispFlag, this.priceForMem[0], this.priceForMem[1], this.priceForMem[2], this.priceForMem[3]
        ]});

        if(res_insReserveM.affectedRows < 0 || res_insReserveM.affectedRows === undefined)
        {
          this.$swal.fire('','<b class="text-danger">죄송합니다</b><br>처리 중 문제가 발생했습니다.('+ res_insReserveM.error.sqlMessage +')', 'warning')
          return false;
        }
        else
        {
          // 옵션상품 선택 시 저장처리
          if(this.selOptCode.length  > 0)
          {
            let successCount = 0;
            let errors = [];

            for(let i=0; i<this.selOptCode.length; i++)
            {
              let optCode = this.selOptCode[i]
              let optUseFlag = "Y"
              let optCompFlag = "N"
              //console.log("optCode:::://///:", optCode)

              //value0=> dcAdu, dcChi, dcInf, refAdu, dcStd \\ value2: priceAdu, priceChi, priceInf
              try {
                await this.$api("/api/proc_insReserve_opt", {param:
                  [
                    this.reqReserveCode, this.productId, optCode, optUseFlag, optCompFlag, this.obj_selectedOptPrice[optCode][1][0], this.obj_selectedOptPrice[optCode][1][1], this.obj_selectedOptPrice[optCode][1][2], this.obj_selectedOptPrice[optCode][0][3], this.obj_selectedOptPrice[optCode][0][0], this.obj_selectedOptPrice[optCode][0][1], this.obj_selectedOptPrice[optCode][0][2], this.obj_selectedOptPrice[optCode][0][4]
                  ]
                });
                successCount++;
              } catch (error) {
                console.log(error)
                errors.push(error);
              }
            }

            if(successCount != this.selOptCode.length)
            {
              this.$swal.fire("", `일부 선택옵션의 예약등록에 실패했습니다. 재진행을 부탁드립니다.<br>반복오류 발생 시, 문의처(1566-4602)`, "error");
              return false
            }
          }

          // 추가상품 선택 시 저장처리
          if(this.selAddInfo.length > 0)
          {
            let successCount = 0;
            let errors = [];

            for(let j=0; j<this.selAddInfo.length; j++)
            {
              let addCode = this.selAddInfo[j].code
              let addQty = this.selAddInfo[j].qty
              let addPrice1 = this.selAddInfo[j].amt
              let addUseFlag = "Y"
              let addCompFlag = "N"

              try {
                await this.$api("/api/proc_insReserve_add", {param:
                  [
                    this.reqReserveCode, this.productId, addCode, addPrice1, addQty, addUseFlag, addCompFlag
                  ]
                });
                successCount++;
              } catch (error) {
                console.log(error)
                errors.push(error);
              }
            }

            if(successCount != this.selAddInfo.length)
            {
              this.$swal.fire("", `일부 선택된 추가상품의 예약등록에 실패했습니다. 재진행을 부탁드립니다.<br>반복오류 발생 시, 문의처(1566-4602)`, "error");
              return false
            }
          }

          this.$router.push({path: "/reservation", query: {
            resCode: this.reqReserveCode, prodId: this.productId
          }})
        }
      }
    },
  }
}
</script>

<style>
@import "../assets/css/style.css";

#product{
  margin-top:4rem;
}

#recent-item{
  display: none;
}


/* -------------------------------------------------
  # 왼쪽
--------------------------------------------------- */
#product .pr-left{
  padding-right:1rem
}
@media (max-width:991px){
  #product .pr-left{
    padding-right:0
  }
}


/* -----------------
  타이틀
----------------- */
#product .pr-left .section-tittle {
  margin: 2rem 0 1rem;
  text-align: left !important
}
#product .pr-left .section-tittle h2 {
  font-size: 1.8rem;
  display: block;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 5px;
  padding-left:10px;
  color: #111;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #product .pr-left .section-tittle h2 {
    font-size: 1.7rem
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  #product .pr-left .section-tittle h2 {
    font-size: 1.6rem
  }
}
@media (max-width: 575px) {
  #product .pr-left .section-tittle h2 {
    font-size: 1.3rem
  }
}


/* -----------------
  사진
----------------- */
#product .pr-left .carousel-inner img {
  height: 500px;
  object-fit: cover
}

@media (max-width:991px){
  #product .pr-left .carousel-inner img{
    height: 350px;
  }
}


/* -----------------
  상품번호
----------------- */
#product .pr-left .subheading{
  font-size: .8rem;
  color: #999;
  margin-bottom: 5px;
}


/* -----------------
  교통편,체크포인트
----------------- */
#product .pr-left .vech{
  margin-top:2.5rem;
  border-top:2px solid #000
}
#product .pr-left .vech .stand{
  background-color: #F8F8F8;
  color:#000;
  font-size:1.1rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 5px;
  width:170px;
  vertical-align:middle
}
#product .pr-left .vech .sub{
  padding:1rem 0 1rem 1.5rem
}

@media (max-width:991px){
  #product .pr-left .vech .stand{
    font-size:.95rem;
    letter-spacing: 0;
    width:85px;
  }
  #product .pr-left .vech .sub{
    padding:1rem 0 1rem .5rem;
    font-size:.9rem
  }
}



/* -----------------
  포함,불포함
----------------- */
#product .pr-left .in-non{
  margin-top:5rem;
  border-top:2px solid #000
}
#product .pr-left .in-non thead th{
  background-color: #F8F8F8;
  height:4rem;
  text-align: center;
  vertical-align:middle;
  font-size:1.1rem;
  font-weight: 600
}
#product .pr-left .in-non thead th:first-child,
#product .pr-left .in-non tbody td:first-child{
  border-right: 1px solid #ddd;
}
#product .pr-left .in-non tbody td{
  text-align: center;
  vertical-align:middle;
  padding:20px 0
}

@media (max-width:991px){
  #product .pr-left .in-non thead th{
    font-size:1rem;
    height:3.5rem;
  }
  #product .pr-left .in-non tbody td{
    font-size:.9rem;
    padding:15px 0
  }
}



/* -----------------
  카테고리
----------------- */
#product .pr-left .category {
  margin: 7rem 0;
}
#product .pr-left .category .clist{
  margin: 4rem 0;
  border-bottom: 3px solid #000;
  padding-bottom:1rem
}
#product .pr-left .category .nav-tabs {
  border:none
}
#product .pr-left .category ul li{
  display: inline-block;
  margin-right: 3rem
}
#product .pr-left .category ul li:last-child{
  margin-right:0
}

#product .pr-left .category .nav-link{
  border:none;
  color: #999;
}

#product .pr-left .category .nav-item h4{
  position: relative;
  font-size: 1.3rem;
  margin: 0;
  font-weight: 600;
  transition: all 500ms ease-out;
}

#product .pr-left .category .nav-item h4::after{
  position: absolute;
  display:block;
  content: '';
  border-top:none;
  border-right:none;
  border-left:none;
  border-bottom: 5px solid #000;
  transition: all 250ms ease-out;
  bottom:-29px;
  left: 50%;
  width: 0;
}

#product .pr-left .category .nav-item:hover h4::after{
  transition: all 250ms ease-out;
  left: 0%;
  width: 100%;
}

#product .pr-left .category .nav-item:hover h4{
  color:#000
}


#product .pr-left .category .nav-tabs .nav-link.active {
  color:#000;
}


@media (max-width:1200px) {
  #product .pr-left .category ul li{
    margin-right: 1.5rem
  }
  #product .pr-left .category .nav-item h4{
    font-size:1.1rem
  }
}
@media (max-width:767px) {
  #product .pr-left .category ul li{
    margin-right: .8rem
  }
  #product .pr-left .category ul li .nav-link{
    padding:0 5px
  }
  #product .pr-left .category .nav-item h4{
    font-size:.95rem
  }
  #product .pr-left .category .nav-item h4::after{
    bottom:-21px;
  }
}
@media (max-width:453px) {
  #product .pr-left .category ul li{
    margin-right: .5rem
  }
  #product .pr-left .category .nav-item h4{
    font-size:.8rem
  }
}

/* ---- 카테고리제목 ---- */
#product .pr-left .category .tab-pane h4{
  font-weight: 600;
}

/* ---- 이용후기 ---- */
#product .pr-left .category .creview{
  position:relative
}
#product .pr-left .category .creview p {
  font-size: 0.8rem;
  margin-top: 20px
}
#product .pr-left .category .creview img{
  width:auto;
  height:220px !important;
  object-fit: cover;
}
#product .pr-left .category .creview .c-content{
  padding-top:10px
}
#product .pr-left .category .creview .reviewt{
  font-size:1.4rem;
  font-weight: bold;
}
#product .pr-left .category .creview .reviewn{
  font-size:.75rem;
  color:#aaa
}
#product .pr-left .category .creview .reviewn .name{
  border-right:1px solid #aaa;
  padding-right:15px
}
#product .pr-left .category .creview .reviewn .date{
  padding-left:15px
}
#product .pr-left .category .creview .reviewc{
  font-size:.9rem;
  padding:10px 0 30px
}
@media (min-width:992px){
  #product .pr-left .category .creview .c-content{
    padding-left:30px
  }
}




/* -------------------------------------------------
  # 오른쪽
--------------------------------------------------- */
#product .pr-right{
  display: block;
  margin-top:0rem;
  padding-bottom:0rem;
  border:1px solid #000;
  border-radius: 15px;
  background-color:rgba(255, 252, 252, 0.8);
  width: 22rem;
}

/* #product .pr-right .container{
  align-items: center;
  background-color:rgba(255, 252, 252, 0.8);
} */


/* -----------------
  달력
----------------- */
/* 글자 */
/* #product .pr-right .top{
  text-align:center;
  margin-top:0.5rem;
  font-size:1.5rem;
  color:#000;
  font-weight: 600;
} */

/* 윗부분 */
#product .pr-right .calendar{
  width:100%
}
#product .pr-right .calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color:rgba(2, 13, 39, 0.8);
}
#product .pr-right .calendar-header-date {
  font-size: 1.3rem;
  font-weight: bold;
  position: relative;
  top:50%;
  transform: translateY(30%);
  color:white;
}

/* 버튼 아이콘 */
#product .pr-right .calendarbtn {
  border: 0px;
  background-color: transparent;
  position: relative;
  top:50%;
  transform: translateY(30%);
  color:white;
}

/* 안에 내용 */
#product .pr-right .calendar-row {
  display: flex;
  cursor: pointer;
}
#product .pr-right .calendar-day-header {
  width: calc(100% / 7);
  text-align: center;
  padding: 1px;
  font-weight: 700;
  font-size: 1.1rem;
}
#product .pr-right .calendar-day {
  width: calc(100% / 7);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}
#product .pr-right .calendar-date {
  font-size: 1.0rem;
  font-weight: normal;
}

/* 오늘 날짜 */
#product .pr-right .calendar-day.today .calendar-date{
  border:2px solid #f00;
  text-align: center;
}
#product .pr-right .calendar-day.today .calendar-date{
  border:2px solid #f00;
  border-radius: 25%;
  width:30px;
  height:34px;
  text-align: center;
}

#product .pr-right .calendar-day.today::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  z-index: 1;
  background-color: transparent;
}
#product .pr-right .calendar-day.today .calendar-date {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/* 가격들 */
#product .pr-right .calendar-price {
  margin-top: 0.1rem;
  font-size: 0.8rem;
}


/* -----------------
  금액
----------------- */
#product .pr-right .price{
  margin-top:.5rem;
}
#product .pr-right .price table{
  margin-left:auto;
  margin-right:auto;
}
#product .pr-right .price td{
  font-size:.9rem;
  font-weight:600;
  color:#000;
  margin:5px 0
}
#product .pr-right .price .bi-question-square{
  font-size:.9rem;
  font-weight: normal;
  color:darkblue;
}

/* 인원수조정 */
#product .pr-right .price .count{
  text-align: center;
  padding: 0 10px;
}
#product .pr-right .price .count .btn-group{
  border:1px solid #000;
  border-radius: 0;
}
#product .pr-right .price .count .number{
  border-right:1px solid #000;
  border-left:1px solid #000;
  width: 3.5rem;
  padding:8px 0
}
#product .pr-right .price .count .left,
#product .pr-right .price .count .right{
  font-size:1rem
}
#product .pr-right .price .count .btn-check:focus+.btn,
#product .pr-right .price .count .btn:focus{
  border-radius: 0;
}


/* -----------------
  총금액
----------------- */
#product .pr-right .total-P table{
  margin-left:auto;
  margin-right:auto;
}
#product .pr-right .total{
  margin-top:1rem
}
#product .pr-right .total span{
  font-size:1.0rem;
  padding-left:1px
}
#product .pr-right .total .result{
  padding-left:5px;
  font-size:1.5rem;
  font-weight: 600;
  color:#000
}


/* ----------------------------------
  예약하기 버튼
---------------------------------- */
#product .pr-right .pr-go{
  margin-top:0.5rem;
  text-align: center;
}
#product .pr-right .pr-go p{
  color:#fff;
  font-size:1.2rem;
  font-weight: 600;
  letter-spacing: 5px;
  background-color: #676666;
  padding:13px 0;
  border-radius: 10px;
  margin-bottom:0
}
#product .pr-right .pr-go p:hover{
  color:gold;
  background-color: #000;
}

.checkPoint, .supPre, .supCxl,
.inclusion, .notInclusion,
.reviewContent {
  word-wrap:break-word;
  white-space:pre-wrap;
}

/* ----------------------------------
  멤버십 배너버튼
---------------------------------- */
#product .pr-right .mem-go{
  margin-top:0.5rem;
  text-align: center;
}
#product .pr-right .mem-go p{
  color:#fff;
  font-size:1.0rem;
  font-weight: 600;
  letter-spacing: 5px;
  background-color:royalblue;
  padding:13px 0;
  border-radius: 10px;
  margin-bottom:0
}
#product .pr-right .mem-go p:hover{
  color: white;
  background-color: goldenrod;
}

/* .checkPoint, .supPre, .supCxl,
.inclusion, .notInclusion,
.reviewContent {
  word-wrap:break-word;
  white-space:pre-wrap;
} */


/* 오른쪽 화면 모바일에서 사라지게 */
@media (max-width:991px){
  #product .pr-right{
    display:none
  }
}


/* -------------------------------------------------
  # 모바일 예약하기
--------------------------------------------------- */
#product .mobile-cal{
  display: block;
}

#product .mobile-cal .openm{
  background: rgba(80, 80, 80);
  color: #fff;
  text-align: center;
  padding:15px 0;
  transition: all .4s ease;
  cursor: pointer;
  width:100%;
  position: fixed;
  left:0;
  bottom: 0;
}

#product .mobile-cal .modal2 {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y:scroll;
  margin: 0;
  padding: 0;
  transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
  top: 100%;
  position: fixed;
  left: 0;
  text-align: left;
}
#product .mobile-cal .modal2 .body {
  padding: 40px 10px 90px 10px;
  position: relative;
}

#product .mobile-cal .modal2 .closem{
  position:absolute;
  top:5px;
  right:3%;
}


/* -----------------
  모바일 달력
----------------- */
#product .modal2 .calendar{
  width:100%;
  margin:0
}
#product .modal2 .calendar-header-date{
  font-size:1.5rem
}
#product .modal2 .calendar-row{
  margin:10px 0
}
#product .modal2 .calendar-day{
  height:auto
}
#product .modal2 .calendar-date{
  font-size:1rem
}
#product .modal2 .calendar-day.today .calendar-date{
  border:2px solid #f00;
  border-radius: 25%;
  width:34px;
  height:34px;
  text-align: top;
}




/* -----------------
  모바일 가격
----------------- */
#product .modal2 .price table{
  width:100%
}
/* 인원수조정 */
#product .modal2 .price .count{
  text-align: center;
  padding: 0 0px;
}
#product .modal2 .price .count .btn-group{
  border:1px solid #000;
  border-radius: 0;
}
#product .modal2 .price .count .number{
  border-right:1px solid #000;
  border-left:1px solid #000;
  width: 3.5rem;
  font-weight:600;
  padding:11px 0
}
#product .modal2 .price .count .left,
#product .modal2 .price .count .right{
  font-size:1.3rem
}
#product .modal2 .price .count .btn-check:focus+.btn,
#product .modal2 .price .count .btn:focus{
  border-radius: 0;
}

/* 총금액 */
#product .modal2 .total-P table{
  margin-left:auto;
  margin-right:auto;
}
#product .modal2 .total .result{
  padding-left:10px;
  font-size:2.3rem;
  font-weight: 600;
  color:#000
}

/* 예약하기 버튼 */
#product .modal2 .pr-go{
  margin-top:0.5rem;
  text-align: center;
}
#product .modal2 .pr-go p{
  color:#fff;
  font-size:1.0rem;
  font-weight: 600;
  letter-spacing: 2px;
  background-color: #676666;
  padding:15px 0;
  border-radius: 12px;
  margin-bottom:.5rem
}
#product .modal2 .pr-go p:hover{
  color:gold;
  background-color: #000;
}



/* 열었을 때 */
#product .mobile-cal .open{
  top: 10%;
  z-index: 1000;
  box-shadow: 0 -5px 20px rgba(0,0,0,0.2);
}

.high-z-index {
  z-index: 9000 !important;
}

/* pc 화면 안 보임 */
@media (min-width:992px){
  #product .mobile-cal{
    display: none;
  }
}



/* -------------------------------------------------
  # 캘린더에 필요한 것!
--------------------------------------------------- */
.calendar {
  display: inline-block;
  border: 0px solid #ccc;
  font-family: sans-serif;
  width: 90%
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0 10px ;
  /*background-color: #eee;*/
  border-bottom: 2px solid #ccc;
}

.calendar-header-date {
  font-size: 2rem;
  font-weight: bold;
}

.calendar-body {
  display: flex;
  flex-direction: column;
}

.calendar-row {
  display: flex;
  margin:3px 0;
  cursor: pointer;
}

.calendar-header-row {
  font-weight: bold;
}

.calendar-day-header {
  width: calc(100% / 7);
  text-align: center;
  padding: 5px;
}

.calendar-day {
  width: calc(100% / 7);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;
  height:auto
  /* day border
  border-right: 0px solid #ccc;
  border-bottom: 0px solid #ccc;
  */
}

.calendar-day.today {
  background-color: transparent;
  /*position: relative;*/
}
.calendar-day.today .calendar-date{
  border:3px solid #f00;
  border-radius: 25%;
  width:40px;
  height:38px;
}

.calendar-day.today .calendar-date {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.calendar-day.today::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
}

.calendar-day:last-child {
  border-right: none;
}

.calendar-day.selected {
  background-color: #ccc;
}

.calendar-date {
  font-size: 1.3rem;
  font-weight: normal;
  color:#666;
}

.calendar-price {
  margin-top: 3px;
  font-size: 0.8rem;
  font-weight: bold;
  color:darkslategray
}

.calendarbtn {
  border: 0px;
  background-color: transparent;
}

</style>


<style scoped>
.pr-right {
  position: sticky;
  top: 0;
}
</style>
