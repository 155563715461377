<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>공지/이벤트 등록</u></h2>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">분류/제목<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <select class="form-select form-select-sm text-primary" aria-label=".form-select-sm" ref="sel_ctCd" v-model="notice.ctCd">
              <option value="NT">공지</option>
              <option value="EV">이벤트</option>
              <option value="TR">여행관련</option>
              <option value="MO">항공선박</option>
            </select>          
            <input type="text" class="form-control w-75" ref="txt_nTitle" v-model="notice.nTitle">
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">공지내용<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:150px" v-model="notice.nContent"></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">공지기간<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <!-- <input type="text" class="form-control" ref="txt_salesStart" v-model="productInfo.salesStart"> -->
            <date-picker class="form-control" v-model="notice.nFrom" ref="txt_fromDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <!-- <input type="text" class="form-control" v-model="productInfo.salesEnd"> -->
            <date-picker class="form-control" v-model="notice.nTo" ref="txt_toDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <center>
          <button type="button" class="btn btn-md btn-success" @click="addImage();">
            이미지첨부&nbsp;&nbsp;(jpg파일만 가능!)
          </button>
        </center>
      </div>

      <div class="mb-3 row" v-if="this.notice.addImageFlag == 'Y'">
        <label class="col-md-3 col-form-label">이미지<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="col">
            <div class="form-control" type="file" accept="image/jpg">
              <!-- <div class="position-relative">
                <img :src="`/download/notice/${this.notice.ctCd}/${this.noticeCode}.jpg` " class="img-fluid" />
              </div> -->
              <input type="file" class="form-control" id="fileSelector" accept="image/png,image/jpeg">
            </div>
            <div class="alert alert-secondary" role="alert" style="font-size:small;">
              <div>File 확장자: jpg</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="noticeInsert();">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList">공지리스트</button>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      notice: {
        nTitle: "",
        nContent: "",
        nFrom: "",
        nTo: "",
        insDate: "",
        insId: "",
        nUseFlag: "", //공지상태 default: 비공지
        ctCd: "", //공지(NT), 이벤트(EV)
        noticeCode: "", //공지코드
        addImageFlag: "N", //  이미지첨부 호출여부
      },
      //fileList: "" //선택된 이미지파일
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() 
  {
    console.log("USER:",this.user);
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    goToList() {
      this.$router.push({path: '/noticeList'});
    },
    // changePdtCategory() {
    //   this.catCode = this.productInfo.catCode;
    // },
    async noticeInsert() 
    {
      //오늘날짜추출
      const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      const time = new Date().toTimeString().split(" ")[0];
      let today = date + ' ' + time;
      let tStamp = "";
      tStamp = new Date().getTime();

      let noticeCode =  this.notice.ctCd + "-" + tStamp;
      //console.log("today:", date + ' ' + time);
      console.log("noticeCode:", noticeCode);

      if(!this.notice.nTitle) {
        this.$refs.txt_nTitle.focus();
        return this.$swal.fire("필수누락: 공지제목");
      }
      if(!this.notice.nContent) {
        this.$refs.txt_nContent.focus();
        return this.$swal.fire("필수누락: 공지내용");
      }
      if(!this.notice.nFrom) {
        this.$refs.txt_fromDate.focus();
        return this.$swal.fire("필수누락: 개시일");
      }
      if(!this.notice.nTo) {
        this.$refs.txt_toDate.focus();
        return this.$swal.fire("필수누락: 종료일");
      }
      if(this.notice.nStart > this.notice.nEnd) {
        return this.$swal.fire("입력오류: 개시일&종료일");
      }
      // if(!this.productInfo.catCode) {
      //   this.$refs.sel_pdtCategoryt.focus();
      //   return this.$swal.fire("필수누락: 상품카테고리");
      // }
      this.notice.noticeCode = noticeCode;
      this.notice.nFrom = moment(this.notice.nFrom).format("YYYY-MM-DD");
      this.notice.nTo = moment(this.notice.nTo).format("YYYY-MM-DD");
      this.notice.insDate = today;
      this.notice.insId = this.user.handlerId;
      this.notice.nUseFlag = "N";
      //console.log("this.notice:", this.notice);

      let resIns_notice = await this.$api("/api/noticeInsert", {param: [this.notice]});
      if(Object.keys(resIns_notice)[0] === 'error') 
      {
        this.$swal.fire('','저장실패', 'error')
      }
      else
      {
        //console.log("this.notice.addImageFlag:",this.notice.addImageFlag)
        if(this.notice.addImageFlag == "Y")
        {
          let imgFile = document.getElementById('fileSelector').files;
          this.uploadFile(imgFile, this.notice.ctCd, this.notice.noticeCode);
        }
        else
        {
          this.$swal.fire('','저장완료', 'success');
          this.$router.push({path: '/noticeList'});
        }
      }
      // else 
      // {
      //   let imgFile = document.getElementById('fileSelector').files;
      //   if(imgFile.length > 0)
      //   {
      //     this.uploadFile(imgFile, this.notice.ctCd, this.notice.noticeCode);
      //   }
      //   else
      //   {
      //     this.$swal.fire('','저장완료', 'success');
      //     this.$router.push({path: '/noticeList'});
      //   }
      // }
    },
    addImage()
    {
      this.notice.addImageFlag = (this.notice.addImageFlag === "N") ? "Y" : "N";
    },
    /** 이미지 사본등록 */
    async uploadFile(files, id, passNo) {
      //console.log("uploadFile:",files, "ctCd:",id, "noticeCode:",passNo)
      let fileLength = 0
      let fileType_tmp = "";
      //let fileType = ""
      let licenseFile = "";
      //let passFile = "";
      let data = null;
      if (files) {
        fileLength = files[0].length;
        fileType_tmp = files[0].name.lastIndexOf(".");
        this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();
        //licenseNumber = files[0].name;
        //passFile = this.cDetail.licenseNumber+"."+this.fileType;
        licenseFile = passNo+"."+this.fileType;
        data = await this.$base64(files[0]);
      }
      console.log("files:",files, "id:",id, "File:",licenseFile,"licenseNo:",this.licenseNumber);
      //const { error } = await this.$api(`/upload/passport/${licenseFile}/${passNo}/${id}`, { data });
      const { error } = await this.$api(`/upload/notice/${id}/${licenseFile}`, { data });
      if (error) {
        return this.$swal("업로드(uploadFile) 오류발생, 재시도 필요.");
      }
      else{
        this.$swal("업로드 완료");
        this.$router.push({path: '/noticeList'});
      }
      // setTimeout(() => {
      //   this.getProductImage();
      // }, 1000);
    },    
  }
}
</script>