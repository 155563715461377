<template>
  <main>
    <div id="reserdetail">
      <section class="py-1">
        <div class="container my-3">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-11">
              <h2 class="d-flex justify-content-center m-title">비회원예약 상세내역</h2>

              <!-- 상품안내 -->
              <div class="r-name container">
                <div class="row col-12" v-if="ctCode === 'nbus'">
                  <div class="name text-start align-self-start">
                    <p class="nnum fs-4">엔버스번호 : <b>{{ this.reserveInfo.inqCode }}</b></p>
                  </div>
                </div>
                <div class="row col-12" v-else>
                  <div class="col-lg-6">
                    <img v-if="this.productImage.imgName != undefined" :src="`/download/${this.productId}/${this.productImage.imgName}`" class="d-block w-100" alt="...">
                    <!-- <img v-else :src="`/download/noImage/noImage.jpg`" class="d-block w-100" alt="..."> -->
                  </div>
                  <div class="name col-lg-6 text-start align-self-start">
                    <p class="code">상품번호 : {{ this.productInfo.pdtCode }}</p>
                    <p>{{ this.productInfo.pdtNameKor }}</p>
                    <p class="nnum">예약 번호 : <b>{{ this.reserveInfo.reqReserveCode }}</b></p>
                  </div>
                </div>                
              </div>

              <!-- 여행 상품 정보 -->
              <div class="traval" v-if="ctCode === 'nbus'">
                <div class="container">
                  <div class="title text-start">
                    <p>엔버스 일정/경로</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">출발일자 (이용일자)</th>
                          <td class="col-4">
                            {{ this.getDateFormat(reserveInfo.rentStartDate) }} ({{ reserveInfo.rentTotDays }}일간)
                          </td>

                          <th class="ta-name col-2">인원/가이드/버스</th>
                          <td class="col-4">{{this.reserveInfo.personCnt}}인 / {{ reserveInfo.guideLv }} / {{ reserveInfo.busLv }} ({{ reserveInfo.busDetail }})</td>
                        </tr>
                        <tr>
                          <th class="ta-name">일정/경로</th>
                          <td colspan="3">
                            <div :key="i" v-for="(rd, i) in exist_nbRoute">
                              <div class="input-group mt-1">
                                <span class="input-group-text input-group-sm" style="font-size: small;background-color:gray;color: white;">{{ i+1 }}일차</span>
                                <input type="hidden" class="form-control form-control-sm" style="font-size: small;" ref="iSubNo" v-model="rd.iSubNo">
                                <input type="text" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="deptPoint" v-model="rd.deptPoint" disabled>
                                <input type="text" class="form-control form-control-sm" style="font-size: small; color:green;" ref="viaPoint" v-model="rd.viaPoint" disabled>
                                <input type="text" class="form-control form-control-sm" style="font-size: small; color:red;" ref="arrPoint" v-model="rd.arrPoint" disabled>
                              </div>
                              <div class="input-group mt-1 mb-4">
                                <span class="input-group-text input-group-sm" style="font-size: small;background-color:gray;color: white;">{{ i+1 }}일차 시작시간</span>
                                <input type="time" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="rentStartTime" v-model="rd.rentStartTime" disabled>
                                <span class="input-group-text input-group-sm" style="font-size: small;background-color:gray;color: white;">{{ i+1 }}일차 종료시간</span>
                                <input type="time" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="rentCloseTime" v-model="rd.rentCloseTime" disabled>
                              </div>
                            </div>                            
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">담당자 안내사항</th>
                          <td class="col-md-4">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.addComment" maxlength="1000" disabled></textarea>
                          </td>
                          <th class="ta-name">
                            예약상태
                            <p class="mb-0" style="font-size:small;" v-if="this.rpCode === 'nonWP'">(가상계좌)</p>
                          </th>
                          <td class="payStatus">
                            <div v-if="this.rpCode === 'nonWP'">
                              {{ this.accPayStatus }}
                            </div>
                            <div v-else>
                              <div class="text-danger" v-if="this.payStyle === 'f'">
                                전액결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-warning" v-else-if="this.payStyle === 'p'">
                                부분결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-danger" v-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag == 'Y'">
                                결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-primary" v-else-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag != 'Y'">
                                결제요청
                              </div>
                              <div class="text-danger" v-else-if="this.reserveInfo.inqStatus === 'PY' &&  this.reserveInfo.firstPayment == null">
                                결제완료 (담당매니저 확인 중)
                              </div>
                              <div v-else-if="this.reserveInfo.reqStatus === 'CX'">
                                <s>{{ this.reserveInfo.procName }}</s>
                              </div>
                              <div v-else-if="this.payStyle != 'f' && this.payStyle != 'p'">
                                {{ this.reserveInfo.procName }}
                              </div>
                            </div>
                            <div>
                              <div v-if="this.cxlFlag === 'Y'">
                                <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl_nbus(this.reserveInfo.inqCode)" disabled>취소요청 중</button>
                              </div>
                              <div v-else>
                                <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl_nbus(this.reserveInfo.inqCode)">취소요청</button>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">예약안내사항</th>
                          <td class="col-md-4">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.supPrecaution" maxlength="5000" disabled></textarea>
                          </td>
                          <th class="ta-name">취소안내사항</th>
                          <td class="col-md-4">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.supCancellation" maxlength="5000" disabled></textarea>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">출발일자</th>
                          <td class="col-8">{{ this.getDateFormat(reserveInfo.rentStartDate) }} ({{ reserveInfo.rentTotDays }}일간)</td>
                        </tr>
                        
                        <tr>
                          <th class="ta-name" style="font-size:small;">인원/가이드/버스</th>
                          <td style="font-size:small;">{{this.reserveInfo.personCnt}}인 / {{ reserveInfo.guideLv }} / {{ reserveInfo.busLv }} ({{ reserveInfo.busDetail }})</td>
                        </tr>
                        <tr>
                          <th class="ta-name">일정/경로</th>
                          <td>
                            <div :key="i" v-for="(rd, i) in exist_nbRoute">
                              <span style="font-weight: 600;font-size:small;text-align: start;">{{ i + 1 }} 일차</span>
                              <table class="table table-bordered" style="font-size:small;border-color:lightgray; border-radius: 10px;">
                                <tr>
                                  <th style="width:25%;">출발지</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.deptPoint }}</td>
                                </tr>
                                <tr>
                                  <th style="width:25%;">경유지</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.viaPoint }}</td>
                                </tr>
                                <tr>
                                  <th style="width:25%;">도착지</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.arrPoint }}</td>
                                </tr>
                                <tr>
                                  <th style="width:25%;">시작</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.rentStartTime }}</td>
                                </tr>
                                <tr>
                                  <th style="width:25%;">종료</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.rentCloseTime }}</td>
                                </tr>
                              </table>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">
                            예약상태
                            <p class="mt-0" style="font-size:small;" v-if="this.rpCode === 'nonWP'">(가상계좌)</p>
                          </th>
                          <td class="payStatus mb-0" style="display: flex; align-items: bottom; text-align: center;">
                            <div class="col-md-9 text-danger" v-if="this.payStyle === 'f'">
                              전액결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-warning" v-else-if="this.payStyle === 'p'">
                              부분결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-danger" v-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag == 'Y'">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-primary" v-else-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag != 'Y'">
                              결제요청
                            </div>
                            <div class="col-md-9 text-danger" v-else-if="this.reserveInfo.inqStatus === 'PY' &&  this.reserveInfo.firstPayment == null">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 " v-else-if="this.reserveInfo.inqStatus === 'CX'">
                              <s>{{ this.reserveInfo.procName }}</s>
                            </div>
                            <div class="col-md-9 " v-else-if="this.payStyle != 'f' && this.payStyle != 'p'">
                              {{ this.reserveInfo.procName }}
                            </div>

                            <div v-if="this.cxlFlag === 'Y'">
                              &nbsp; <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl_nbus(this.reserveInfo.reqReserveCode)" disabled>취소요청</button>
                            </div>
                            <div v-else>
                              &nbsp; <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl_nbus(this.reserveInfo.reqReserveCode)">취소요청</button>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">담당자 안내사항</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px;font-size:small;" v-model="reserveInfo.addComment" readonly></textarea>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">예약안내사항</th>
                          <td colspan="3">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.supPrecaution" maxlength="5000" readonly></textarea>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">취소안내사항</th>
                          <td colspan="3">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.supCancellation" maxlength="5000" readonly></textarea>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="traval" v-else>
                <div class="container">
                  <div class="title text-start">
                    <p>여행 일정 정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2" v-if="productInfo.salesRoute === 'F'">출발일자</th>
                          <th class="ta-name col-2" v-else>여행기간</th>

                          <td class="col-4"  v-if="productInfo.salesRoute === 'F'">
                            {{this.reserveInfo.reqDptDate}}
                          </td>
                          <td class="col-4" v-else>
                            {{this.reserveInfo.reqDptDate}} ~ {{this.reserveInfo.reqRtnDate}}
                          </td>

                          <th class="ta-name col-2">예약일</th>
                          <td class="col-4">{{this.reserveInfo.reqDate}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행지역</th>
                          <td> {{this.productInfo.ntName}} / {{this.productInfo.arName}}</td>
                          <th class="ta-name">
                            예약상태
                            <p class="mb-0" style="font-size:small;" v-if="this.rpCode === 'nonWP'">(가상계좌)</p>
                          </th>
                          <td class="payStatus" style="display: flex; align-items: center; padding: 12px;">
                            <div v-if="this.rpCode === 'nonWP'">
                              {{ this.accPayStatus }}
                            </div>
                            <div v-else>
                              <div class="text-danger" v-if="this.payStyle === 'f'">
                                전액결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-warning" v-else-if="this.payStyle === 'p'">
                                부분결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-danger" v-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag == 'Y'">
                                결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-primary" v-else-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                                결제요청
                              </div>
                              <div class="text-danger" v-else-if="this.reserveInfo.reqStatus === 'PY' &&  this.reserveInfo.firstPayment == null">
                                결제완료 (담당매니저 확인 중)
                              </div>
                              <div v-else-if="this.reserveInfo.reqStatus === 'CX'">
                                <s>{{ this.reserveInfo.procName }}</s>
                              </div>
                              <div v-else-if="this.payStyle != 'f' && this.payStyle != 'p'">
                                {{ this.reserveInfo.procName }}
                              </div>
                            </div>

                            <div v-if="this.cxlFlag === 'Y'">
                              &nbsp; <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl(this.reserveInfo.reqReserveCode, this.productId)" disabled>취소요청</button>
                            </div>
                            <div v-else>
                              &nbsp; <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl(this.reserveInfo.reqReserveCode, this.productId)">취소요청</button>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">출발 → 도착</th>
                          <td v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                            한국&nbsp;{{this.pdtTransInfo1.ddCodeName}} → &nbsp;{{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.daCodeName}}
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'L' || productInfo.salesRoute == 'E'">현지일정</td>
                          <td v-else></td>
                          <th class="ta-name">출발편</th>
                          <td class="trans-info">
                            {{this.pdtTransInfo1.dmobileCodeName}}
                            <small v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                              ({{this.pdtTransInfo1.dmobileCode}}{{this.pdtTransInfo1.dpTpCode}},&nbsp;{{this.pdtTransInfo1.d_dpTime}} → {{this.pdtTransInfo1.d_arTime}})
                            </small>
                          </td>
                        </tr>
                        
                        <tr>
                          <th class="ta-name">귀국 → 도착</th>
                          <td v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                            {{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.rdCodeName}} → &nbsp;한국&nbsp;{{this.pdtTransInfo1.raCodeName}} 
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'L' || productInfo.salesRoute == 'E'">현지일정</td>
                          <td v-else></td>
                          <th class="ta-name" v-if="productInfo.salesRoute == 'T'">귀국편</th>
                          <th class="ta-name" v-else>리턴편</th>
                          <td class="trans-info">
                            {{this.pdtTransInfo1.rmobileCodeName}}
                            <small v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                              ({{this.pdtTransInfo1.rmobileCode}}{{this.pdtTransInfo1.rtTpcode}},&nbsp;{{this.pdtTransInfo1.r_dpTime}} → {{this.pdtTransInfo1.r_arTime}})
                            </small>  
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">선택옵션</th>
                          <td>
                            <div>{{ this.optName }}</div>
                            <!-- <div v-if="this.selOptions.length <=0">선택된 옵션이 없습니다.</div>
                            <div v-else :key=i v-for="(eo, i) in this.selOptions">
                              {{eo.optPdtName}} 
                            </div> -->
                          </td>
                          <th class="ta-name">선택추가상품</th>
                          <td>
                            <div v-if="this.selAddPdt.length <=0">선택된 추가상품이 없습니다.</div>
                            <div v-else :key=i v-for="(ea, i) in this.selAddPdt">
                              {{ea.addPdtName}} : <i></i>&nbsp;<span style="color:darkgray">
                                &nbsp;{{ ea.qty_addPdt }} 개 
                              </span>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr v-if="productInfo.salesRoute === 'F'">
                          <th class="ta-name col-4">출발일자</th>
                          <td class="col-8">{{this.reserveInfo.reqDptDate}}</td>
                        </tr>
                        <tr v-else>
                          <th class="ta-name col-4">여행기간</th>
                          <td class="col-8">{{this.reserveInfo.reqDptDate}} ~ {{this.reserveInfo.reqRtnDate}}</td>
                        </tr>
                        
                        <tr>
                          <th class="ta-name">예약일</th>
                          <td>{{this.reserveInfo.reqDate}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행지역</th>
                          <td> {{this.productInfo.ntName}} / {{this.productInfo.arName}}</td>
                        </tr>

                        <tr>
                          <th class="ta-name">
                            예약상태
                            <p class="mt-0" style="font-size:small;" v-if="this.rpCode === 'nonWP'">(가상계좌)</p>
                          </th>
                          <td class="payStatus mb-0" style="display: flex; align-items: bottom; text-align: center;">
                            <div class="col-md-9 text-danger" v-if="this.payStyle === 'f'">
                              전액결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-warning" v-else-if="this.payStyle === 'p'">
                              부분결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-danger" v-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag == 'Y'">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-primary" v-else-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                              결제요청
                            </div>
                            <div class="col-md-9 text-danger" v-else-if="this.reserveInfo.reqStatus === 'PY' &&  this.reserveInfo.firstPayment == null">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 " v-else-if="this.reserveInfo.reqStatus === 'CX'">
                              <s>{{ this.reserveInfo.procName }}</s>
                            </div>
                            <div class="col-md-9 " v-else-if="this.payStyle != 'f' && this.payStyle != 'p'">
                              {{ this.reserveInfo.procName }}
                            </div>

                            <div v-if="this.cxlFlag === 'Y'">
                              &nbsp; <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl(this.reserveInfo.reqReserveCode, this.productId)" disabled>취소요청</button>
                            </div>
                            <div v-else>
                              &nbsp; <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl(this.reserveInfo.reqReserveCode, this.productId)">취소요청</button>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">출발 → 도착</th>
                          <td v-if="productInfo.salesRoute == 'T'">
                            한국&nbsp;{{this.pdtTransInfo1.ddCodeName}} → &nbsp;{{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.daCodeName}}
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'F'">
                            <p class="mb-0">한국&nbsp;{{this.pdtTransInfo1.ddCodeName}} → </p>
                            <p class="mt-0">{{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.daCodeName}}</p>
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'L' || productInfo.salesRoute == 'E'">현지일정</td>
                          <td v-else></td>
                        </tr>
                        <tr>
                          <th class="ta-name">출발편</th>
                          <td>
                            {{this.pdtTransInfo1.dmobileCodeName}}
                            <small v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                              ({{this.pdtTransInfo1.dmobileCode}}{{this.pdtTransInfo1.dpTpCode}},&nbsp;{{this.pdtTransInfo1.d_dpTime}} → {{this.pdtTransInfo1.d_arTime}})
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">귀국 → 도착</th>
                          <td v-if="productInfo.salesRoute == 'T'">
                            {{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.rdCodeName}} → &nbsp;한국&nbsp;{{this.pdtTransInfo1.raCodeName}} 
                          </td>
                          <td v-if="productInfo.salesRoute == 'F'">
                            <p class="mb-0">{{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.rdCodeName}} → </p>
                            <p class="mt-0">한국&nbsp;{{this.pdtTransInfo1.raCodeName}}</p>
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'L' || productInfo.salesRoute == 'E'">현지일정</td>
                          <td v-else></td>
                        </tr>       
                        <tr>
                          <th class="ta-name" v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">귀국편</th>
                          <th class="ta-name" v-else>리턴편</th>
                          <td>
                            {{this.pdtTransInfo1.rmobileCodeName}}
                            <small v-if="productInfo.salesRoute == 'T'">
                              ({{this.pdtTransInfo1.rmobileCode}}{{this.pdtTransInfo1.rtTpcode}},&nbsp;{{this.pdtTransInfo1.r_dpTime}} → {{this.pdtTransInfo1.r_arTime}})
                            </small>  
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">선택옵션</th>
                          <td>
                            <div>{{ this.optName }}</div>                            
                            <!-- <div v-if="this.selOptions.length <=0 ">선택된 옵션이 없습니다.</div>
                            <div v-else :key=i v-for="(eo, i) in this.selOptions">
                              {{eo.optPdtName}}
                            </div> -->
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">선택추가상품</th>
                          <td>
                            <div v-if="this.selAddPdt.length <=0">선택된 추가상품이 없습니다.</div>
                            <div v-else :key=i v-for="(ea, i) in this.selAddPdt">
                              {{ea.addPdtName}} : <i></i>&nbsp;<span style="color:darkgray">
                                &nbsp;{{ ea.qty_addPdt }} 개 
                              </span>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


              <!-- 예약자 정보 -->
              <div class="reser" v-if="ctCode === 'nbus'">
                <div class="container">
                  <div class="title text-start">
                    <p>예약자 정보</p>
                  </div>
                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">성명</th>
                          <td class="col-4">{{this.reserveInfo.inqName}}</td>
                          <th class="ta-name col-2">연락처</th>
                          <td  class="col-4">{{this.reserveInfo.inqTel}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">상품문의</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px;font-size:small;" v-model="reserveInfo.etcInquiry" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">성명</th>
                          <td class="col-8">{{this.reserveInfo.inqName}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">연락처</th>
                          <td>{{this.reserveInfo.inqTel}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">상품문의</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px;font-size:small;" v-model="reserveInfo.etcInquiry" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="reser" v-else>
                <div class="container">
                  <div class="title text-start">
                    <p>예약자 정보</p>
                  </div>
                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">이름</th>
                          <td class="col-4">{{this.reserveInfo.reqName}}</td>
                          <th class="ta-name col-2">연락처</th>
                          <td  class="col-4">{{this.reserveInfo.reqTelNo}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.email}}</td>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">상품문의</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="reserveInfo.reqRemarks" maxlength="200" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">성명</th>
                          <td class="col-8">{{this.reserveInfo.reqName}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">연락처</th>
                          <td>{{this.reserveInfo.reqTelNo}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.email}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">상품문의</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="reserveInfo.reqRemarks" maxlength="200" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


              <!-- 대표 여행자 정보 -->
              <div class="tourist" v-if="this.reserveInfo.myselfFlag === 'N'">
                <div class="container">
                  <div class="title text-start">
                    <p>대표여행자정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc" v-if="this.reserveInfo.myselfFlag === 'N'">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">이름</th>
                          <td class="col-4">{{this.reserveInfo.tripName}}</td>
                          <th class="ta-name col-2">연락처</th>
                          <td class="col-4">{{this.reserveInfo.tripCall}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.reserveInfo.tripEmail}}</td>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile" v-if="this.reserveInfo.myselfFlag === 'N'">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">이름</th>
                          <td class="col-8">{{this.reserveInfo.tripName}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">연락처</th>
                          <td>{{this.reserveInfo.tripCall}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.reserveInfo.tripEmail}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- 대표 여행자 정보 -->
              <div class="tourist" v-if="this.reserveInfo.myselfFlag === 'N'">
                <div class="container">
                  <div class="title text-start">
                    <p>대표여행자정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc" v-if="this.reserveInfo.myselfFlag === 'N'">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">이름</th>
                          <td class="col-4">{{this.reserveInfo.tripName}}</td>
                          <th class="ta-name col-2">연락처</th>
                          <td class="col-4">{{this.reserveInfo.tripCall}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.reserveInfo.tripEmail}}</td>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile" v-if="this.reserveInfo.myselfFlag === 'N'">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">이름</th>
                          <td class="col-8">{{this.reserveInfo.tripName}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">연락처</th>
                          <td>{{this.reserveInfo.tripCall}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.reserveInfo.tripEmail}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


              <!-- 엔버스 결제 정보 -->
              <div class="payment" v-if="ctCode === 'nbus'">
                <div class="container">
                  <div class="title text-start">
                    <p>결제정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr> 
                          <th class="ta-name col-3">할인금액</th>
                          <td class="col-9" colspan="2">
                            <div class="text-start text-primary">
                              {{this.getCurrencyFormat(sumPrice_dc)}}원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name col-3"><b>총결제금액</b></th>
                          <td class="total text-end">
                            <div>
                              {{ this.getCurrencyFormat(this.nbusPriceTot) }} 원
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay <= 0">
                              <hr>
                              <p style="padding-left: 2rem;">- 결제액 ({{ this.rtnRPflag_tmp.fName }}): &nbsp;{{ getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount) }}
                              </p>
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay > 0">
                              <hr>
                              <p style="padding-left: 2rem;" v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
                              <p style="padding-left: 2rem;" v-else-if="fPayFlag === 'Y' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="payPointAmt != '' && payPointAmt > 0"> 
                                <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                <span v-if="payPointDate != ''" style="font-size:small;"> ( {{ payPointDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="p2_paidAmt != '' && p2PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                                <span v-if="p2PayDate != ''" style="font-size:small;"> ( {{ p2PayDate }} )</span>
                                <span v-else></span>
                              </p>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">할인금액</th>
                          <td class="col-8">
                            <div class="text-center text-primary">
                              {{this.getCurrencyFormat(sumPrice_dc)}}원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name"><b>총결제금액</b></th>
                          <td>
                            <div style="font-size:medium; font-weight: 600; color:red; text-align: end;">
                              {{ this.getCurrencyFormat(this.nbusPriceTot) }} 원
                            </div>
                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay <= 0">
                              <hr>
                              <p style="padding-left: 2rem;">- 결제액 ({{ this.rtnRPflag_tmp.fName }}): &nbsp;{{ getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount) }}
                              </p>
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay > 0">
                              <hr>
                              <p style="padding-left: 2rem;" v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
                              <p style="padding-left: 2rem;" v-else-if="fPayFlag === 'Y' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="payPointAmt != '' && payPointAmt > 0"> 
                                <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                <span v-if="payPointDate != ''" style="font-size:small;"> ( {{ payPointDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="p2_paidAmt != '' && p2PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                                <span v-if="p2PayDate != ''" style="font-size:small;"> ( {{ p2PayDate }} )</span>
                                <span v-else></span>
                              </p>
                            </div>                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col d-grid text-end" v-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag != 'Y'">
                    <div v-if="this.reserveInfo.nbusPriceTot >= 200">
                      <button type="button" class="btn ht-pay btn-primary" @click="pgPay();">
                        <b class="fs-5">온라인결제</b>
                      </button>
                    </div>
                    <div v-else-if="this.reserveInfo.nbusPriceTot == 0">
                      <button type="button" class="btn ht-pay btn-success" @click="freePay(this.reqRsvCode);">
                        <b class="fs-5">무료결제</b>
                      </button>
                    </div>
                    <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                    <div>
                      <button @click="requestPayment(this.reqRsvCode)" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                        <b class="fs-6">결제하기</b>
                      </button>
                    </div>
                  </div>

                  <!-- 부분결제청구: 엔버스부분결제 없음 -->
                  <!-- <div class="col d-grid text-end" v-if="this.rtnRPflag_tmp.reqPayBalance === 'Y'">
                    <div v-if="this.balance >= 200">
                      <button type="button" class="btn ht-pay btn-primary" @click="pgPay('p2');">
                        <b class="fs-5">온라인 잔액결제 ({{ this.getCurrencyFormat(this.balance) }}원)</b>
                      </button>
                    </div>
                    <div v-else-if="this.reserveInfo.cstPrice_new == 0">
                      <button type="button" class="btn ht-pay btn-success" @click="freePay(this.reqRsvCode, 'p2');">
                        <b class="fs-5">무료잔액결제</b>
                      </button>
                    </div>
                    <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                    <div>
                      <button @click="requestPayment(this.reqRsvCode, 'p2')" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                        <b class="fs-6">잔액결제</b>
                      </button>
                    </div>
                  </div> -->

                  <div class="col d-grid text-end">
                    <div class="mt-3 ht-ex">
                      <p>
                        <i class="bi bi-exclamation-triangle-fill text-danger"></i>&nbsp;
                        결제금액이 <b>200원 미만</b> 또는 온라인 결제가 어려운 경우, <span class="mobile-new-line">아래 계좌로 송금을 하실 수 있습니다.</span>
                      </p>
                      <p class="ht-big">
                        <b class="text-success">우리은행 1006-901-267484</b> (엔타비글로벌)
                      </p>
                      <p >(송금 후 <b>1660-4602</b> 또는 <b>카카오톡</b>으로 연락을 주시면 빠른 확인이 가능합니다)</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 여행상품 결제정보영역 -->
              <div class="payment" v-else>
                <div class="container">
                  <div class="title text-start">
                    <p>결제정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-3">할인금액</th>
                          <td class="col-9" colspan="2">
                            <div class="text-start text-primary">
                              {{this.getCurrencyFormat(this.reserveInfo.optDcPrice)}}원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name col-3"><b>총결제금액</b></th>
                          <td class="col-6">
                            <div style="font-size:small; font-weight: 600">
                              <p v-if="this.reserveInfo.reqAdultCnt>0">
                                성인 {{ getCurrencyFormat(this.reserveInfo.reqAdultPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqAdultCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqChildCnt>0">
                                소아 {{ getCurrencyFormat(this.reserveInfo.reqChildPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqChildCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqInfCnt>0">
                                유아 {{ getCurrencyFormat(this.reserveInfo.reqInfPrice * this.reserveInfo.reqInfCnt) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqInfCnt }} 명
                              </p>
                            </div>

                            <div class="" v-if="this.selOptions.length > 0">
                              <hr>
                              <div><i class="bi bi-check-lg text-primary"><b>선택옵션</b></i>
                              </div>
                              <div>
                                {{ this.optName }}
                                <span> : {{ getCurrencyFormat(selOptPriceTot ) }} 원</span>
                              </div>
                            </div>
                            <div class="" v-if="this.selAddPdt.length > 0">
                              <hr>
                              <div><i class="bi bi-check-lg text-info"><b>선택추가상품</b></i></div>
                              <div :key=i v-for="(ea, i) in this.selAddPdt">
                                {{ea.addPdtName}}<i class="bi bi-x-lg"></i><span style="color:darkgray">
                                  &nbsp;{{ ea.qty_addPdt }}개&nbsp;&nbsp;( <b class="text-info">{{ this.getCurrencyFormat((ea.aduPdtPrice * ea.qty_addPdt)) }}</b> 원 )
                                </span>
                              </div>
                            </div>                            
                          </td>
                          <td class="total text-end">
                            <div>
                              {{ this.getCurrencyFormat(this.reserveInfo.cstPrice_new) }} 원
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay <= 0">
                              <hr>
                              <p style="padding-left: 2rem;">- 결제액 ({{ this.rtnRPflag_tmp.fName }}): &nbsp;{{ getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount) }}
                              </p>
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay > 0">
                              <hr>
                              <p style="padding-left: 2rem;" v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
                              <p style="padding-left: 2rem;" v-else-if="fPayFlag === 'Y' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="payPointAmt != '' && payPointAmt > 0"> 
                                <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                <span v-if="payPointDate != ''" style="font-size:small;"> ( {{ payPointDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="p2_paidAmt != '' && p2PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                                <span v-if="p2PayDate != ''" style="font-size:small;"> ( {{ p2PayDate }} )</span>
                                <span v-else></span>
                              </p>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">할인금액</th>
                          <td class="col-8">
                            <div class="text-center text-primary">
                              {{this.getCurrencyFormat(this.reserveInfo.optDcPrice)}}원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th rowspan="2" class="ta-name"><b>총결제금액</b></th>
                          <td>
                            <div style="font-size:small; font-weight: 600">
                              <p v-if="this.reserveInfo.reqAdultCnt>0">
                                성인 {{ getCurrencyFormat(this.reserveInfo.reqAdultPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqAdultCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqChildCnt>0">
                                소아 {{ getCurrencyFormat(this.reserveInfo.reqChildPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqChildCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqInfCnt>0">
                                유아 {{ getCurrencyFormat(this.reserveInfo.reqInfPrice * this.reserveInfo.reqInfCnt) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqInfCnt }} 명
                              </p>
                            </div>
                            <div v-if="this.selAddPdt.length > 0">
                              <hr>
                              <div class="text-start mb-1" style="font-size:small;"><i class="bi bi-check-lg text-primary"><b>선택옵션</b></i>
                              </div>
                              <div>
                                {{ this.optName }}
                                <span> : {{ getCurrencyFormat(selOptPriceTot ) }} 원</span>
                              </div>
                            </div>
                            <div v-if="this.selAddPdt.length > 0">
                              <hr>
                              <div class="text-start mb-1" style="font-size:small;"><i class="bi bi-check-lg text-info"><b>선택추가상품</b></i></div>
                              <div class="text-start mt-0 mb-0" style="font-size:small;" :key=i v-for="(ea, i) in this.selAddPdt">
                                {{ea.addPdtName}}<i class="bi bi-x-lg"></i><span style="color:darkgray">&nbsp;{{ ea.qty_addPdt }}개
                                  <p>( <b class="text-info">{{ this.getCurrencyFormat((ea.aduPdtPrice * ea.qty_addPdt)) }}</b> 원 )</p>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="2" class="total text-end">
                            <div>
                              {{ this.getCurrencyFormat(this.reserveInfo.cstPrice_new) }} 원
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay <= 0">
                              <hr>
                              <p style="padding-left: 2rem;">- 결제액 ({{ this.rtnRPflag_tmp.fName }}): &nbsp;{{ getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount) }}
                              </p>
                              <!-- <p style="padding-left: 2rem;">- 포인트결제: &nbsp;{{ getCurrencyFormat(this.payPointAmt) }}</p> -->
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay > 0">
                              <hr>
                              <p style="padding-left: 2rem;" v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <br>
                                <span v-if="p1PayDate != ''"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
                              <p style="padding-left: 2rem;" v-else-if="fPayFlag === 'Y' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <br>
                                <span v-if="p1PayDate != ''"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="payPointAmt != '' && payPointAmt > 0"> 
                                <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                <br>
                                <span v-if="payPointDate != ''"> ( {{ payPointDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="p2_paidAmt != '' && p2PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                                <br>
                                <span v-if="p2PayDate != ''"> ( {{ p2PayDate }} )</span>
                                <span v-else></span>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col d-grid text-end" v-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                    <div v-if="this.reserveInfo.cstPrice_new >= 200">
                      <button type="button" class="btn ht-pay btn-primary" @click="pgPay();">
                        <b class="fs-5">온라인결제</b>
                      </button>
                    </div>
                    <div v-else-if="this.reserveInfo.cstPrice_new == 0">
                      <button type="button" class="btn ht-pay btn-success" @click="freePay(this.reqRsvCode);">
                        <b class="fs-5">무료결제</b>
                      </button>
                    </div>
                    <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                    <div>
                      <button @click="requestPayment(this.reqRsvCode)" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                        <b class="fs-6">결제하기</b>
                      </button>
                    </div>
                  </div>

                  <!-- 부분결제청구 -->
                  <div class="col d-grid text-end" v-if="this.rtnRPflag_tmp.reqPayBalance === 'Y'">
                    <div v-if="this.balance >= 200">
                      <button type="button" class="btn ht-pay btn-primary" @click="pgPay('p2');">
                        <b class="fs-5">온라인 잔액결제 ({{ this.getCurrencyFormat(this.balance) }}원)</b>
                      </button>
                    </div>
                    <div v-else-if="this.reserveInfo.cstPrice_new == 0">
                      <button type="button" class="btn ht-pay btn-success" @click="freePay(this.reqRsvCode, 'p2');">
                        <b class="fs-5">무료잔액결제</b>
                      </button>
                    </div>
                    <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                    <div>
                      <button @click="requestPayment(this.reqRsvCode, 'p2')" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                        <b class="fs-6">잔액결제</b>
                      </button>
                    </div>
                  </div>

                  <div class="col d-grid text-end">
                    <div class="mt-3 ht-ex">
                      <p>
                        <i class="bi bi-exclamation-triangle-fill text-danger"></i>&nbsp;
                        결제금액이 <b>200원 미만</b> 또는 온라인 결제가 어려운 경우, <span class="mobile-new-line">아래 계좌로 송금을 하실 수 있습니다.</span>
                      </p>
                      <p class="ht-big">
                        <b class="text-success">우리은행 1006-901-267484</b> (엔타비글로벌)
                      </p>
                      <p >(송금 후 <b>1660-4602</b> 또는 <b>카카오톡</b>으로 연락을 주시면 빠른 확인이 가능합니다)</p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import axios from 'axios';
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  data() {
    return {
      showFlag: false,        //결제창 표시여부

      //토스페이결제용
      paymentWidget: null,
      paymentMethodWidget: null,
      //clientKey: "test_ck_YyZqmkKeP8gG2Klanln8bQRxB9lG",
      clientKey: "live_ck_jZ61JOxRQVEZXa1aoR0rW0X9bAqw",
      customerKey: "Ntabi-1936=@",

      loginInfo_E: [],          //비회원예약확인정보

      isReviewFlag: "",         //기존리뷰 존재여부
      aPromoFlag: "N",          //적용프로모션 존재여부
      reserveInfo: [],          //예약정보
      selEctOptList: [],        //예약된 선택옵션
      productCode: "",          //상품코드
      email: "",                //예약자이메일(원본 또는 수정)

      //userPayInfo: [],          //결제내역정보
      payPointAmt: 0,           //포인트결제액
      
      availPoint: 0,            //가용포인트

      productInfo: [],          //상품정보

      paidPoint: 0,             //사용포인트
      payPointFlag: "N",        //포인트사용여부

      pointForPay: 0,           //결제에 사용할 포인트

      payByPointFlag: "N",      //포인트사용 플래그
      pointForPay_tmp: 0,       //포인트 복구용 직전 사용예정 포인트
      rtnRPflag_tmp: [],        //결제 후 포인트와 결제정보

      rtnRPflag: "N",           //결제요청여부
      paidFlag: "N",            //결제여부

      //selectedEtcOptApplyDate: [],  //선택옵션 적용일

      tot_checkedOptPrice: 0,   //선택된 기타옵션 총액

      productTrans: [],
      pdtTransInfo1: [],        //기존교통정보
      pdtTransInfo: [],
      pdtPromoInfo: [],         //프로모션정보

      pdtFlag: "",              //상품종류

      productImage: [],         //상품이미지

      selectedProcCode: "",     //예약처리상태

      sRoute: [],               //상품구분

      reqMarginRate: 0,         //당해예약마진
      reqMarginMethod: "P",     //당해예약마진계산방법(P/A)

      productPromotion: [],
      choicePromotion: [],    //선택된 프로모션정보
      promoList: [],          //해당상품의 프로모션목록

      optFlag: "",            //옵션선택플래그

      pdtcode: "",

      productPrice: [],

      promoStart: "",
      promoEnd: "",

      cxlFlag: "N",        //취소요청상태

      // 구매된 옵션 & 추가상품
      selOptFlag: "N",              //선택옵션유무
      selAddFlag: "N",              //선택추상유무
      pdtOptInfo: [],               //상품선택옵션정보
      pdtOptFlag: "N",              //선택옵션유무
      calOptFlag: "N",              //선택옵션유무(예약용)
      selOptions: [],               //선택된 옵션정보
      tot_selOptPrice:  0,          //선택된 옵션총액
      selOptNames: [],              //선택옵션명

      addPdtInfo: [],               //추가상품정보
      addPdtFlag: "N",              //추가상품유무
      calAddFlag: "N",              //추가상품유무(예약용)
      selAddPdt: [],                //선택된 추가상품정보
      tot_selAddPdtPrice:  0,       //선택된 추상총액
      selAddPdtNames: [],           //선택추상명      

      //신규추가변수
      balance: 0,                   //잔여대금
      payStyle: "",                 //전액f, 부분p

      /////결제히스토리용 정보
      userPayInfo: [],              //히스토리용 결제내역(향후 내역은 이 배열을 이용)
      p1PayAmt: 0, 
      p1PayDate: "",
      p2PayAmt: 0, 
      p2PayDate:"",
      payPointDate: "",
      fPayFlag: "",                //예약금결제N, 전액결제Y
      totalPay: 0,                 //히스토리합산

      optName: "",                //선택된 옵션명
      selOptPriceTot: [],         //선택옵션별 총액
      // selOPtPriceDCTot_self: 0,   //선택옵션 본인할인액
      // selOPtPriceDCTot_exSelf: 0, //선택옵션 동반할인액(본인제외)
      // selOptPriceRef_tot: 0,      //선택옵션 환급총액
      paymentInFull: "N",         //완납여부

      virAccInfo: [],             //가상계좌결제정보
      accPayStatus: "",           //가상계좌결제상태

      //엔버스 정보
      nbusInfo: [],               //엔버스 마스타정보
      exist_nbRoute: [],          //엔버스 경로정보   
      
      sumPrice_dc: 0,             //엔버스할인총액
      nbusPriceTot: 0,            //엔버스결제총액

      handTel: [],                //매니저연락처
      handTelCnt: 0,              //수신처갯수      
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    },
    total_amount() {
      return this.$store.state.total_amount;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.reqRsvCode = this.$route.query.seqReq;
    this.productId = this.$route.query.pCode || ""
    this.copyFlag = this.$route.query.cCode || ""
    this.rpCode = this.$route.query.rpCode || ""
    this.ctCode = this.$route.query.ctCode || ""             //엔버스? (nbus or '')
  },
  async mounted() {
    if(this.rpCode === 'nonRP' || this.rpCode === 'nonCO' || this.rpCode === 'nonWP' || this.ctCode === 'nbus')
    {
      this.checkNonMem(this.reqRsvCode, this.rpCode, this.ctCode)
    }
  },
  methods: {
    //mixin함수
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //한국표준시로 변경
    getKorDateFormat(date) {
      return this.$korDateFormat(date)
    },
    //원단위절사
    getTruncateToWon(value){
      return this.$truncateToWon(value)
    },

    async checkNonMem(rCode, rpCode, ctCode){
      //console.log(rpCode)
      const { value: formValues } = await this.$swal.fire({
        // title: '아이디찾기(직접 가입회원만 가능)',
        html:
          '<div class="searchNonRes">' +
          '<b class="text-danger">비회원예약</b> <b>확인</b><br>' +
          '<input id="nResName" class="swal2-input" placeholder="예약자성명" style="font-size:1.0rem;">' +
          '<input id="nResCall" class="swal2-input" placeholder="예약자연락처(01012345678)" style="font-size:1.0rem;">' +
          '<label id="nResCode" class="swal2-input" style="background:darkgray; font-size:1.0rem; color:white; padding-top:6px; vertical-align:middle; width:210px;" placeholder="예약번호">'+ rCode +'</label>' +
          '</div>',
        confirmButtonText: "확인",
        showCancelButton: true,
        cancelButtonText: "취소",
        imageUrl: '/IMGs/payment.png',
        imageWidth: 400,
        imageHeight: 200,        
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('nResName').value,
            document.getElementById('nResCall').value,
            //document.getElementById('nResCode').value
          ]
        }
      })

      if (!formValues)    //cancel 선택시
      {
        //location.href = "/"
        location.reload(true)
      }
      else
      {
        let nResName = formValues[0]
        let nResCall = formValues[1]

        let loginInfo_E = []

        if(ctCode === "nbus")
        {
          loginInfo_E = await this.$api("/api/checkResNbus", {param: [nResName, nResCall, rCode]});
        }
        else
        {
          loginInfo_E = await this.$api("/api/checkResNonMember", {param: [nResName, nResCall, rCode]});
        }
        //console.log("loginInfo_E:",loginInfo_E)

        if(loginInfo_E.length > 0)
        {
          this.loginInfo_E = loginInfo_E[0]
          this.$swal.fire("", '<b>'+ loginInfo_E[0].reqName + '님</b>, 반갑습니다(비회원예약)', "success")

          this.getReserInfo();
          if(this.ctCode == "")
          {
            this.getProductInfo();
            this.getProductImage();
            //적용가능한 프로모션
            this.getPdtPromotion_apply();
            this.getProductPromotion();           //상품프로모션정보
            this.getPromotionList();
          }

          this.getReqProcLevel();
          this.getHandlerInfo()                   //알림톡 발송대상 체크
        }
        else
        {
          this.$swal.fire("","카카오알림톡으로 안내된 정보를 확인해주세요<br>확인이 어려운 경우 문의처: <b class='text-danger'>1660-4062</b>","warning")
          location.reload(true)
        }
      }
    },

    //엔버스가격구성
    async getNbusPrice(nbCode){

      //할인총액
      let pmFlag = "M"
      let nUseFlag = "Y"
      let nbusPriceSum = await this.$api("/api/nbusPriceSum", {param: [ nbCode, pmFlag, nUseFlag ]})
      //console.log("nbusPriceSum:", nbusPriceSum)
      if(nbusPriceSum.length > 0)
      {
        this.sumPrice_dc = (nbusPriceSum[0].sumPrice < 0 || nbusPriceSum[0].sumPrice ==  null) ? 0 : nbusPriceSum[0].sumPrice
      }
      //console.log("sumPrice_dc:", this.sumPrice_dc)

      let nbusPriceTot = await this.$api("/api/resNbusPrice", {param: [ nbCode, "Y"]})
      this.nbusPriceTot = (nbusPriceTot[0].result === undefined || !nbusPriceTot[0].result) ? 0 : nbusPriceTot[0].result
      //console.log("nbusPriceTot:", this.nbusPriceTot)      
    },

    async getReserInfo()
    {
      //예약 또는 엔버스 정보마스터
      let reserveInfo = []

      //엔버스정보
      //let nbusInfo = []
      let exist_nbRoute = []

      if(this.ctCode === "nbus")
      {
        reserveInfo = await this.$api("/api/reqResConsultDetail_nbus", {param: [this.reqRsvCode]});
        //엔버스문의 디테일(일정루트)
        exist_nbRoute = await this.$api("/api/nbusInqInfo_d", {param: [this.reqRsvCode]});

        if(reserveInfo.length > 0)
        {
          this.reserveInfo = reserveInfo[0]
          //이용시작일, 이용종료일
          this.reserveInfo.rentStartDate = this.getDateFormat(this.reserveInfo.rentStartDate);
          this.reserveInfo.insDate = this.getDateFormat(this.reserveInfo.insDate);
        }
        //console.log("reserveInfo:",this.reserveInfo)

        if(exist_nbRoute.length > 0)
        {
          this.exist_nbRoute = exist_nbRoute
        }
        //console.log("exist_nbRoute:",this.exist_nbRoute)
      }
      else
      {
        reserveInfo = await this.$api("/api/resConsultDetail", {param: [this.reqRsvCode]});

        if(reserveInfo.length > 0)
        {
          this.reserveInfo = reserveInfo[0];
          this.productCode = reserveInfo[0].reqPdtCode;
          this.reserveInfo.optDcPrice = (this.reserveInfo.optDcPrice == null) ? 0 : this.reserveInfo.optDcPrice
          
          this.email = (this.reserveInfo.conEmail == undefined || this.reserveInfo.conEmail == null) ? this.reserveInfo.reqEmail : this.reserveInfo.conEmail
          
          //출발예정일-리턴예정일
          this.reserveInfo.reqDptDate = this.getDateFormat(this.reserveInfo.reqDptDate);
          this.reserveInfo.reqRtnDate = this.getDateFormat(this.reserveInfo.reqRtnDate);
          this.reserveInfo.reqDate = this.getDateFormat(this.reserveInfo.reqDate); //insDate 예약일
          //this.pdtFlag = this.pdtCode.substring(0,2)
          this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"

          this.reqMarginRate = (this.reserveInfo.reqMarginRate == null) ? this.productInfo.basicMargin : this.reserveInfo.reqMarginRate;
          this.reqMarginMethod = (this.reserveInfo.reqMarginMethod == null) ? this.productInfo.marginMethod : this.reserveInfo.reqMarginMethod;
        }
      }
      
      if(this.ctCode == "")
      {
      // 선택된 옵션
      this.getSelOptions_new(this.reqRsvCode)
      // 선택된 추가상품
      this.getSelAddProductions(this.reqRsvCode)
      }
      
      //엔버스인 경우 가격정보
      if(this.ctCode === "nbus")
      {
        this.getNbusPrice(this.reqRsvCode)
      }
      //결제요청 여부
      this.getReqPayInfo(this.reqRsvCode);

      //취소요청여부
      this.getResCxlCheck(this.reqRsvCode)

      //리뷰작성가능체크
      //this.checkReviewFlag();

      // //결제완료, 확정, 취소 시 결제수단 정보획득
      // if((this.reserveInfo.reqStatus === 'RP' && this.paidFlag == 'Y') || this.reserveInfo.reqStatus === 'CO' || this.reserveInfo.reqStatus === 'CX')
      // {
      //   this.getPayDetailInfo1(this.reqRsvCode)
      // }
    },
    
    //선택옵션획득(선택옵션 중복선택방지 반영처리: 20240730)
    async getSelOptions_new(resCode) {
      let selOptions = []
      let useFlag = "Y"
      //let selOptNames_tmp = []

      selOptions = await this.$api("/api/getSelOptions_new", {param: [resCode, useFlag]});
      //console.log("selOptions:",selOptions);

      if(selOptions.length > 0)
      {
        this.selOptions = selOptions
        this.selOptFlag = "Y"

        let optName = ""                     //선택옵션명 
        let selOptPriceTot  = 0              //선택옵션총액

        optName = selOptions[0].optPdtName

        selOptPriceTot = (selOptions[0].optPrice*selOptions[0].cntAdu) + (selOptions[0].optPrice_chi*selOptions[0].cntChi) + (selOptions[0].optPrice_inf*selOptions[0].cntInf)

        this.optName = optName
        this.selOptPriceTot = selOptPriceTot
      }
      else
      {
        this.selOptFlag  = "N"
        this.optName = "선택된 옵션이 없습니다"
      }
      //console.log(selOptPriceSelf)
      // console.log("selOptNames:", this.selOptNames)
    },

    //선택추가상품획득
    async getSelAddProductions(resCode) {
      let selAddPdt = []
      let useFlag = "Y"
      selAddPdt = await this.$api("/api/getSelAddProductions", {param: [resCode, useFlag]});

      if(selAddPdt.length > 0)
      {
        this.selAddPdt = selAddPdt
        this.selAddFlag = "Y"
        
        //선택추상가격*수량
        selAddPdt.forEach(sa => {
          this.tot_selAddPdtPrice += (sa.addPdtPrice * sa.qty_addPdt)
          this.selAddPdtNames.push(sa.addPdtName)
        });
      }
      else
      {
        this.selAddFlag = "N"
        this.selAddPdtNames = "없음"
      }
      // console.log("selAddPdtNames:", this.selAddPdtNames)
      // console.log("selAddPdt:",this.selAddPdt, "tot_selAddPdtPrice:", this.tot_selAddPdtPrice);
    },

    async getProductInfo()
    {
      this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"
      let productInfo = {};
      if(this.pdtFlag === "SG")
      {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      else if(this.pdtFlag === "MX")
      {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else
      {
        return this.$swal.fire("","상품이 검색되지 않습니다<br>재확인 후 시도해 주세요.","error");
      }
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      //console.log("productInfo:",this.productInfo);

      this.getProductTransInfoExited(); //기존교통정보
    },

    async getProductImage() {
      // 상세페이지용 카로우셀 이미지
      let productImage = await this.$api("/api/productImage_new", {param: [this.productId,'T']});
      //this.productImage = (productImage.length > 0) ? productImage : "";
      if(productImage.length > 0)
      {
        this.productImage = productImage[0]
      }
      //console.log("productImage:",this.productImage)
    },

    async getResCxlCheck(rCode){
      let cxlStatus = await this.$api("/api/checkReqCxl", {param: [rCode]});

      if(cxlStatus.length > 0)
      {
        this.cxlFlag = "Y"
      }

      //console.log("cxlFlag:", this.cxlFlag)
    },
    //기존상품 프로모션정보
    async getPdtPromotion() {
      let pdtPromoInfo = await this.$api("/api/pdtPromo_existed", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0];
        if(this.pdtPromoInfo.promoStart != undefined){
          this.pdtPromoInfo.promoStart = this.getDateFormat(this.pdtPromoInfo.promoStart);
        }
        if(this.pdtPromoInfo.promoEnd != undefined) {
          this.pdtPromoInfo.promoEnd = this.getDateFormat(this.pdtPromoInfo.promoEnd);
        }
      }
      //console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },

    //비회원 취소요청처리
    async reqCxl(rCode, pCode)
    {
      //상품별 취소안내사항
      let res_supCxl = await this.$api("/api/supCxl", {param: pCode})

      let supCxl = ""
      if(res_supCxl.length > 0)
      {
        supCxl = res_supCxl[0]
      }
      this.$swal.fire({
        html: "예약취소를 요청하시겠습니까?<br><br><p class='text-start swal-popup'>"+ supCxl.supCancellation.split('\n').join('<br>') +"</p>",
        showCancelButton: true,
        confirmButtonText: '동의하고 요청합니다',
        cancelButtonText: '요청하지 않습니다',
        width: 1000,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let conFlag = "Y"   //취소약관동의
          let insReqCxlReserve = await this.$api("/api/insReqCxlReserve", {param: [
            rCode, this.user.email,conFlag
          ]});
          if(Object.keys(insReqCxlReserve)[0] === 'error') 
          {
            this.$swal.fire('','요청처리 중 오류가 발생했습니다.<br>온라인 문의 또는 연락(1660-4602)을 주시면 신속하게 처리해드리겠습니다.', 'warning')
            return false;
          }
          else
          {
            this.sendKakao("RCX", rCode)
          }
        }
      })
    },

    //비회원 엔버스예약 취소요청
    async reqCxl_nbus(nCode){
      //상품별 취소안내사항
      let res_supCxl = await this.$api("/api/supCxl_nbus", {param: nCode})

      let supCxl = ""
      if(res_supCxl.length > 0)
      {
        supCxl = res_supCxl[0]
      }
      this.$swal.fire({
        html: "예약취소를 요청하시겠습니까?<br><br><p class='text-start swal-popup'>"+ supCxl.supCancellation.split('\n').join('<br>') +"</p>",
        showCancelButton: true,
        confirmButtonText: '동의하고 요청합니다',
        cancelButtonText: '요청하지 않습니다',
        width: 1000,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let conFlag = "Y"   //취소약관동의
          let insReqCxlReserve = await this.$api("/api/insReqCxlReserve", {param: [
            nCode, this.user.email,conFlag
          ]});
          if(Object.keys(insReqCxlReserve)[0] === 'error') 
          {
            this.$swal.fire('','요청처리 중 오류가 발생했습니다.<br>온라인 문의 또는 연락(1660-4602)을 주시면 신속하게 처리해드리겠습니다.', 'warning')
            return false;
          }
          else
          {
            this.sendKakao("RCX", nCode)
          }
        }
      })
    },

    /** 카카오페이 처리로직 */
    async kPay()
    {
      //포인트처리
      let payByPoint = await this.$api("/api/payByPoint", {param: [
        this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기'
      ]});
      if(Object.keys(payByPoint)[0] === 'error') 
      {
        this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
        return false;
      }
      else
      {
        // 성공 URL(핸들러등록)
        // const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay&${this.reserveInfo.reqReserveCode}`;
        const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay`;
        // 실패 or 취소 URL(핸들러등록)
        const failUrl = `${window.location.protocol}//${window.location.host}/fail_kPay`;

        let kakaoData = {
          'cid': '',
          'partner_order_id': this.reserveInfo.reqReserveCode,
          'partner_user_id': 'ntabi',
          'item_name': this.productInfo.pdtNameKor,
          'item_code': this.productId,
          'quantity': 1,
          'total_amount': this.reserveInfo.cstPrice_new,
          'vat_amount': this.reserveInfo.reqVatPrice,
          'tax_free_amount': this.reserveInfo.cstPrice_new - this.reserveInfo.reqVatPrice,
          'approval_url': successUrl,
          'fail_url': failUrl,
          'cancel_url': failUrl
        }

        //결제승인처리를 Success_kPay로 이동(20230321)
        try
        {
          const response = await axios.post('/api/kakaoPay', { param: kakaoData });
          const tid = response.data.tid;

          localStorage.setItem('tid', tid)
          localStorage.setItem('partner_order_id',kakaoData.partner_order_id)
          localStorage.setItem('total_amount',kakaoData.total_amount)

          const nextUrl = response.data.next_redirect_pc_url;
          window.location.href = nextUrl;
        }
        catch (error)
        {
          console.log(error);
        }
      }
    },

    //토스페이먼츠PG결제
    async pgPay(pFlag)
    {
      //console.log(pFlag)
      
      let veriSign = "N"
      let insVeriData = []

      //잔액결제청구처리
      let payPointAmt = 0
      let payAmount = 0
      if(pFlag === "p2")
      {
        payAmount = this.balance
        insVeriData = await this.$api("/api/proc_setVeriData_1", {param:
          [this.reserveInfo.reqReserveCode+"-2", payAmount, veriSign, pFlag, payPointAmt ]
        });
        // insVeriData = await this.$api("/api/proc_setVeriData_1", {param:
        //   [this.reserveInfo.reqReserveCode+"-2", payAmount, veriSign, pFlag ]
        // });
      }
      else
      {
        if(this.ctCode === "nbus")
        {
          payAmount = this.reserveInfo.nbusPriceTot
          insVeriData = await this.$api("/api/proc_setVeriData", {param:
            [this.reserveInfo.inqCode, payAmount, veriSign, payPointAmt ]
          });
        }
        else
        {
          payAmount = this.reserveInfo.cstPrice_new
          insVeriData = await this.$api("/api/proc_setVeriData", {param:
            [this.reserveInfo.reqReserveCode, payAmount, veriSign, payPointAmt ]
          });
        }
      }

      if(insVeriData.affectedRows < 0)
      {
        this.$swal.fire("","검증데이타가 생성되지 않았습니다. 재시도해주세요", "warning")
        return false;
      }

      //localStorage.setItem("total_amount", this.reserveInfo.cstPrice_new)

      // ------  결제위젯 초기화 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
      this.paymentWidget = await loadPaymentWidget(this.clientKey, ANONYMOUS);
      // ------  결제 UI 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
      this.paymentMethodWidget = this.paymentWidget.renderPaymentMethods("#payment-method", { value: payAmount }, { variantKey: "DEFAULT" });
      // ------  이용약관 UI 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
      //this.paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });
      this.showFlag = !this.showFlag
      
    },

    async requestPayment(rNo, pFlag) {
      // 부분결제청구 처리
      let taxFreeAmount = 0
      let resCode = ""
      if(pFlag === "p2")
      {
        taxFreeAmount = this.balance
        resCode = rNo + "-2"
      }
      else
      {
        resCode = rNo
      }


      //엔버스 결제금액
      if(this.ctCode === "nbus")
      {
        taxFreeAmount = this.reserveInfo.nbusPriceTot
      }
      else
      {
        taxFreeAmount = this.reserveInfo.cstPrice_new
      }

      try {
        if (this.paymentWidget) {
          if(this.ctCode === "nbus")
          {
            // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
            await this.paymentWidget.requestPayment({
              orderId: resCode,
              orderName: rNo,
              //successUrl: 'http://localhost/success_pg',
              //failUrl: 'http://localhost/fail_pg',
              successUrl: 'https://ntabi.co.kr/success_pg',
              ailUrl: 'https://ntabi.co.kr/fail_pg',
              customerEmail: this.reserveInfo.inqEmail,
              customerName: this.reserveInfo.inqName,
              taxFreeAmount: taxFreeAmount,
            });
          }
          else
          {
            // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
            await this.paymentWidget.requestPayment({
              orderId: resCode,
              orderName: this.productInfo.pdtNameKor,
              //successUrl: 'http://localhost/success_pg',
              //failUrl: 'http://localhost/fail_pg',
              successUrl: 'https://ntabi.co.kr/success_pg',
              failUrl: 'https://ntabi.co.kr/fail_pg',
              customerEmail: this.reserveInfo.conEmail,
              customerName: this.reserveInfo.reqName,
              taxFreeAmount: taxFreeAmount,
            });
          }
        }
      } catch (error) {
        // 에러 처리하기
        console.error(error);
      }
    },


    /** 토스페이 처리로직 */
    async tPay(param, value)
    {
      if(value === "T")
      {
        //포인트처리
        let payByPoint = await this.$api("/api/payByPoint", {param: [
          this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기'
        ]});
        if(Object.keys(payByPoint)[0] === 'error') 
        {
          this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
          return false;
        }
        else
        {
          let tossData = {
            orderId: param,
            amount: this.reserveInfo.cstPrice_new,
            amountTaxFree: this.reserveInfo.cstPrice_new,
            //orderName: this.productInfo.pdtNameKor,
            productDesc: this.productInfo.pdtNameKor,
            autoExecute: true,
            retUrl: "https://ntabi.co.kr/success?amount="+this.reserveInfo.cstPrice_new,
            retCancelUrl: "https://ntabi.co.kr/fail",
            resultCallback: "https://ntabi.co.kr/callback",
            // retUrl: "http://localhost/success?amount="+this.reserveInfo.cstPrice_new,
            // retCancelUrl: "http://localhost/fail",
            // resultCallback: "http://ntabi.co.kr/callback",
          }
          //console.log("tossData:",tossData)

          // connect with api on backend
          this.$api("api/tossPay/start",
          {
            param: tossData
          })
          .then((res) => {
            console.log(res);
            window.location.href = res.checkoutPage;
          })
          .catch((err) => {
            console.error(err);
          });
        }
      }
    },

    async freePay(rNo, pFlag)
    {
      // 부분결제청구 처리
      let taxFreeAmount = 0
      if(pFlag === "p2")
      {
        taxFreeAmount = this.balance
      }
      else
      {
        taxFreeAmount = this.reserveInfo.cstPrice_new
      }

      //포인트처리
      let payByPoint = await this.$api("/api/payByPoint", {param: [
        this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기'
      ]});
      if(payByPoint.affectedRows < 0) 
      {
        this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
        return false;
      }
      else
      {
        //결제처리
        let payByUser = await this.$api("/api/payByUser", {param: [taxFreeAmount, this.user.email, '000', rNo]});
        
        if(payByUser.affectedRows > 0) 
        {
          let upd_UsePoint = await this.$api("/api/upd_UsePoint", {param: [ this.user.email, rNo ]});
          if(upd_UsePoint.affectedRows < 0 || upd_UsePoint.affectedRows == undefined )
          {
            this.$swal.fire("","죄송합니다. 결제처리에 오류가 발생했습니다(코드:upd_UsePoint).<br>문의처: 1660-4602","warning")
            return false        
          }
          else
          {
            this.getPayDetailInfo(rNo)
            this.$swal.fire('','전액 포인트 결제되었습니다. 감사합니다,', 'success');
            //location.reload(true);
          }
        }
        else 
        {
          this.$swal.fire('','결제처리 중 에러가 발생했습니다(코드: payByUser)<br>문의처(1660-4602)에 확인을 요청해주세요.', 'error')
          return false;
        }
      }
    },
    // //카톡발송 없이 결제상세만 체크
    // async getPayDetailInfo1(orderId)
    // {
    //   let resNo = orderId
    //   let payDetailInfo = await this.$api("/api/payDetailInfo", {param: [resNo]});
    //   if(payDetailInfo.length > 0)
    //   {
    //     this.payDetailInfo = payDetailInfo[0];
    //     console.log("payDetailInfo1:", this.payDetailInfo)
    //   }
    // },    
    async getPayDetailInfo(orderId)
    {
      let resNo = orderId
      let payDetailInfo = []
      if(this.ctCode === "nbus")
      {
        payDetailInfo = await this.$api("/api/payDetailInfo_nbus", {param: [resNo]});        
      }
      else
      {
        payDetailInfo = await this.$api("/api/payDetailInfo", {param: [resNo]});
      }

      if(payDetailInfo.length > 0)
      {
        this.payDetailInfo = payDetailInfo[0];
        //console.log("payDetailInfo:", this.payDetailInfo)
        //결제완료 카톡안내
        this.sendKakao("PY", resNo);
      }
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
      //console.log("handTel: ", this.handTel)
    },

    /** 카카오알림 발송 */
    async sendKakao(param, resNo)
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        resNo, param, this.reserveInfo.reqTelNo
      ]});

      //알림톡 발송대상 확정
      if(this.ctCode === "nbus")      //엔버스
      {
        this.handTel.push({aimCall: this.reserveInfo.inqTel})        
      }
      else                            //일반여행상품
      {
        this.handTel.push({aimCall: this.reserveInfo.reqTelNo})
      }
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0) 
      {
        let promises = []         //promise들의 배열

        if(param == "PY") 
        {
          for(let i=0; i<this.handTelCnt; i++)
          {
            //결제요청 카카오 알림톡
            let kakaoAlimData = 
            {
              //카카오 알림톡 발송(결제완료)
              clientName: this.payDetailInfo.clientName,
              resName: this.payDetailInfo.resName,
              resPaxName: this.payDetailInfo.resPaxName,
              resPaxCnt: this.payDetailInfo.resPaxCnt,
              dptDate: this.payDetailInfo.dptDate,
              resPhone: this.payDetailInfo.resPhone,
              resCode: resNo,
              resPayAmount: this.getCurrencyFormat(this.payDetailInfo.resPayAmount),
              resManager: this.payDetailInfo.resManager,
              alimFlag: "nonPY",                                           //알림유형(결제요청)
              toNumber: this.handTel[i].aimCall,                           //알림톡발송대상
            };

            let promise = this.$api("api/kakaoAlimSend2", {
              param: [ { kakaoAlimData: kakaoAlimData} ]
            })

            promises.push(promise)
          }
          //반복문 이후 처리
          Promise.all(promises).then((results) => {
            console.log(results)
            this.$swal.fire('', '결제완료 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }

        else if(param == "RCX") 
        {

          let resPaxCnt = 0
          if(this.ctCode === "nbus")
          {
            resPaxCnt = this.reserveInfo.personCnt*1
          }
          else
          {
            resPaxCnt = this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1
          }

          //취소요청 카카오 알림톡
          let kakaoAlimData = {}
          if(this.ctCode === "nbus")
          {
            for(let i=0; i<this.handTelCnt; i++)
            {
              kakaoAlimData = 
              {
                inqName: this.reserveInfo.inqName,
                inqCode: resNo,
                rentStartDate: this.getDateFormat(this.reserveInfo.rentStartDate),
                inqTel: this.reserveInfo.inqTel,
                alimFlag: "nonRCX",                                             //알림유형(RCX:취소요청)
                toNumber: this.handTel[i].aimCall,                           //알림톡발송대상
                memFlag: "N",
              };
              //console.log("kakaoAlimData:", kakaoAlimData)

              let promise_n = this.$api("api/kakaoAlimSend7", {
                param: [ { kakaoAlimData: kakaoAlimData} ]
              })

              promises.push(promise_n)
            }
          }
          else
          {
            for(let i=0; i<this.handTelCnt; i++)
            {
              kakaoAlimData = 
              {
                //카카오 알림톡 발송(취소요청)
                clientName: this.reserveInfo.reqName,
                resName: this.productInfo.pdtNameKor,
                resPaxName: this.reserveInfo.reqName,
                resPaxCnt: resPaxCnt,
                dptDate: this.reserveInfo.reqDptDate,
                resPhone: this.reserveInfo.reqTelNo,
                //resCode: this.reserveInfo.reqReserveCode,
                resCode: resNo,
                resManager: this.productInfo.handlerNameKor,
                alimFlag: "nonRCX",                                          //알림유형(비회원예약 취소요청)
                toNumber: this.handTel[i].aimCall,                           //알림톡발송대상
              };
              let promise = this.$api("api/kakaoAlimSend2", {
                param: [ { kakaoAlimData: kakaoAlimData} ]
              })

              promises.push(promise)
            }
          }
          //반복문 이후 처리
          Promise.all(promises).then((results) => {
            console.log("result:", results)
            this.$swal.fire('', '취소요청 알림톡이 발송되었습니다', 'success').then(() => {
              //location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error.response ? error.response.data : error);
          })
        }
      }
    },

    async getReqPayInfo(val)
    {
      let resCode = ""
      if(this.reserveInfo.partFlag === "1")
      {
        resCode = val+"-1"
      }
      else if(this.reserveInfo.partFlag === "2")
      {
        resCode = val+"-1"
      }
      else
      {
        resCode = val
      }

      let rtnRPflag_tmp = []
      if(this.rpCode === "nonWP")     //가상계좌는 즉시결제가 아닌 결제전이므로 actualPayment1 == null이므로
      {
        rtnRPflag_tmp = await this.$api("/api/getReqPayInfo_vir", {param: [resCode]});
        let virAccInfo = await this.$api("/api/res_virAccPayInfo", {param: [val]});
        if(virAccInfo.length > 0)
        {
          this.virAccInfo = virAccInfo[0]
        }
        //console.log("virAccInfo:", this.virAccInfo,"rtnRPflag_tmp:",rtnRPflag_tmp)
        
        let accPayStatus= ""
        if((this.virAccInfo.payStyle === "fu" && this.virAccInfo.payFlag1 === "Y") || (this.virAccInfo.payStyle === "p2" && this.virAccInfo.payFlag2 === "Y"))
        {
          accPayStatus = "결제완료(담당자 확인 중)" 
        }
        else if((this.virAccInfo.payStyle === "p1" && this.virAccInfo.payFlag1 === "Y") || (this.virAccInfo.payStyle === "p2" && this.virAccInfo.payFlag2 === "N"))
        {
          accPayStatus = "잔액입금대기" 
        }
        else
        {
          accPayStatus = "입금대기"
        }
        this.accPayStatus = accPayStatus
      }
      else if(this.rpCode != "nonWP" && this.ctCode === "nbus")     //엔버스는 즉시결제가 아닌 결제전이므로 actualPayment1 == null이므로
      {
        rtnRPflag_tmp = await this.$api("/api/getReqPayInfo_vir", {param: [resCode]});
      }      
      else
      {
        rtnRPflag_tmp = await this.$api("/api/getReqPayInfo", {param: [resCode]});
      }
      //console.log("rtnRPflag_tmp:", rtnRPflag_tmp)

      if(rtnRPflag_tmp.length > 0)
      {
        //this.rtnRPflag = "Y"    //결제여부

        if(rtnRPflag_tmp[0].reqPayStatus === "CO")      //확정
        {
          //this.payStyle = "f"
          this.paidFlag = "Y"
        }        
        else if(rtnRPflag_tmp[0].reqPayStatus === "PY" && (rtnRPflag_tmp[0].payFlag === "fu" || rtnRPflag_tmp[0].payFlag === "p2" || rtnRPflag_tmp[0].payFlag == null))
        {
          this.payStyle = "f"
          this.paidFlag = "Y"
        }
        else if(rtnRPflag_tmp[0].reqPayStatus === "PY" && rtnRPflag_tmp[0].payFlag === "p1")
        {
          this.payStyle = "p"
          this.paidFlag = "Y"
          this.balance = (rtnRPflag_tmp[0].reqTotPayAmt)*1 - (rtnRPflag_tmp[0].actualPayAmount)*1
        }
        else if(rtnRPflag_tmp[0].reqPayStatus === "RP" && rtnRPflag_tmp[0].payFlag == null)
        {
          this.paidFlag = "Y"
          this.rtnRPflag = "Y"      //과거 로직에 사용하던 변수로 미사용일 가능성이 높으나 일단 변수값은 생성함
        }
        else if(rtnRPflag_tmp[0].reqPayStatus === "RP" && rtnRPflag_tmp[0].payFlag === 'p1')        //부분결제 시, 잔여대금
        {
          this.paidFlag = "Y"           //부분결제
          this.balance = (rtnRPflag_tmp[0].reqTotPayAmt)*1 - (rtnRPflag_tmp[0].actualPayAmount)*1
        }
        else if(rtnRPflag_tmp[0].reqPayStatus === "RP" && (rtnRPflag_tmp[0].payFlag === 'p2' || rtnRPflag_tmp[0].payFlag === 'fu'))
        {
          if(rtnRPflag_tmp[0].actualPayAmount != rtnRPflag_tmp[0].reqTotPayAmt)
          {
            if(this.rpCode === "nonWP")
            {
              this.$swal.fire("","가상계좌 입금대기 상태입니다.", "info")
              //return false
            }
            else if(this.ctCode === "nbus")
            {
              this.$swal.fire("","엔버스 결제대기 중입니다.", "info")
              //return false
            }            
            else
            {
              this.$swal.fire("","최종결제금액과 상품대금이 일치하지 않습니다. 실제결제된 금액을 확인해주세요", "question")
              return false
            }
          }
          else
          {
            this.paidFlag = "Y"       //전액결제
            this.rtnRPflag = "Y"
          }
        }

        //관리자화면의 펑션에서는 this.userPayInfo로 변경됨
        //this.userPayInfo = rtnRPflag_tmp[0]
        this.rtnRPflag_tmp = rtnRPflag_tmp[0]
        //console.log("balance:", this.balance, "payStyle:", this.payStyle)

        //포인트결제내역
        let payPointInfo = await this.$api("/api/payPointInfo", {param: [val]});
        
        //결제포인트액수
        if(payPointInfo[0].payPoint > 0)
        {
          this.payPointAmt = payPointInfo[0].payPoint
        }
        else
        {
          this.payPointAmt = 0
        }
      }
      else
      {
        this.rtnRPflag = "N"
        this.paidFlag = "N"
      }
      //console.log("rtnRPflag_tmp:>>>>>", this.rtnRPflag_tmp, "paidFlag:", this.paidFlag)

      // 결제히스토리용
      if(this.paidFlag != "N")       //결제요청이 이루어진 이후에 의미가 있으므로
      {
        this.userPayInfo = rtnRPflag_tmp[0]
        this.p1PayAmt = (this.userPayInfo.p1PayAmt == null) ? 0 :          this.userPayInfo.p1PayAmt
        this.p2PayAmt = (this.userPayInfo.p2PayAmt == null) ? 0 :          this.userPayInfo.p2PayAmt
        this.p1PayDate = (this.userPayInfo.p1PayDate == null) ? "" :       this.getKorDateFormat(this.userPayInfo.p1PayDate)
        this.p2PayDate = (this.userPayInfo.p2PayDate == null) ? "" :       this.getKorDateFormat(this.userPayInfo.p2PayDate)
        this.payPointAmt = (this.userPayInfo.payPointAmt == null) ? 0 :    this.userPayInfo.payPointAmt
        this.payPointDate = (this.userPayInfo.payPointDate == null) ? "" : this.getKorDateFormat(this.userPayInfo.payPointDate)

        this.totalPay = this.p1PayAmt*1 + this.p2PayAmt*1 + this.payPointAmt*1
      }


      let fPayFlag = ""       //전액Y, 부분N
      if(this.userPayInfo.reqTotPayAmt == this.p1PayAmt)
      {
        fPayFlag = "Y"         //부분결제
      }
      else
      {
        fPayFlag = "N"
      }

      this.fPayFlag = fPayFlag
    },

    /** 두날짜 사이의 날짜추출함수 */
    getDatesStartToLast(startDate, lastDate) {
      //var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      //if(!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
      if(!(startDate && lastDate)) return "Not Date Format";

      var result = [];
      var curDate = new Date(startDate);
      while(curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    },

    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      //console.log("rProc:",this.rProc);
    },

    //적용가능한 프로모션정보
    async getPdtPromotion_apply() {
      let pdtPromoInfo_apply = await this.$api("/api/pdtPromo_apply", {param: [this.productId, this.reserveInfo.reqDptDate]});
      
      if(pdtPromoInfo_apply.length > 0) {
        this.pdtPromoInfo_apply = pdtPromoInfo_apply[0];
        if(this.pdtPromoInfo_apply.promoStart != undefined){
          this.pdtPromoInfo_apply.promoStart = this.getDateFormat(this.pdtPromoInfo_apply.promoStart);
        }
        if(this.pdtPromoInfo_apply.promoEnd != undefined) {
          this.pdtPromoInfo_apply.promoEnd = this.getDateFormat(this.pdtPromoInfo_apply.promoEnd);
        }
        this.aPromoFlag = "Y"
      }
      //console.log("pdtPromoInfo_apply:",this.pdtPromoInfo_apply,"/", pdtPromoInfo_apply.length);
    },

    //기존상품 교통정보
    async getProductTransInfoExited() {
      let pdtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.productId]});
      if(pdtTransInfo1.length > 0) {
        this.pdtTransInfo1 = pdtTransInfo1[0];
      }
      //console.log("pdtTransInfo1:",this.pdtTransInfo1);
    },


    //상품프로모션
    async getProductPromotion() {
      let productPromotion = await this.$api("/api/productPromotion", {param: [this.productId]});
      //console.log("pPromo:",productPromotion);

      if(productPromotion.length > 0) {
        this.productPromotion = productPromotion[0]
      }
      else{
        return false;
      }
      //console.log("productPromotion: ",this.productPromotion.promoName);
    },
    //상품가격
    // async getProductPrice() {
    //   let productPrice = await this.$api("/api/productPrice", {param: [this.pdtCode]});
    //   this.productPrice = productPrice;
    // },
    // async getPromotionList() {
    //   let promotionList = await this.$api("/api/promotionList", {param: [this.productId]});
    //   this.promoList = promotionList;
    //   // console.log("promoList:",this.promoList, "pDetail:",this.productDetail.deliCategory);
    // },
    goToList() {
      //this.$router.push({path: '/reserveInfoList_client'});
      this.$router.push({path: '/myPage'});
    },
    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },
  }
}
</script>

<style>
@media (max-width:992px){
  #reserdetail{
    margin-top:3rem
  }
}

#reserdetail .m-title{
  font-weight: 600;
  color:#000;
  margin-bottom:5rem
}

@media (max-width:991px){
  #reserdetail .m-title{
    margin:1rem 0 3rem
  }
}

/* 기본 스타일(PC 화면) */
.r-name.container .row.col-12 {
  display: flex;
  flex-wrap: wrap;
}

.r-name.container .col-lg-6 {
  width: 50%;
  box-sizing: border-box;
  padding: 0 10px; /* 좌우 여백 추가 */
}

.name.col-lg-6 {
  width: 50%;
  box-sizing: border-box;
  padding: 0 10px; /* 좌우 여백 추가 */
}

/* 모바일 화면 스타일 */
@media (max-width: 768px) {
  .r-name.container .col-lg-6 {
    width: 100%; /* 모바일 화면에서 이미지를 100%로 표시 */
  }

  .name.col-lg-6 {
    width: 100%; /* 모바일 화면에서 .name 클래스를 100%로 표시 */
  }
}





/* -------------------------------------------------
  # 상품안내
--------------------------------------------------- */
#reserdetail .r-name img{
  height:22rem;
  object-fit: cover;
}
#reserdetail .r-name .code{
  font-size:.8rem;
  color:#777;
  font-weight: 300;
}
#reserdetail .r-name .name{
  align-self:center;
  font-size:1.3rem;
  font-weight: 600;
  color:#000;
  padding-left:30px
}
#reserdetail .r-name .nnum{
  font-size:1rem;
  font-weight: 300;
}
#reserdetail .r-name .nreview{
  font-size:1.2rem;
  color:#777;
  padding-top:20px
}

@media (max-width:991px){
  #reserdetail .r-name img{
    height:15rem;
  }
  #reserdetail .r-name .code{
    font-size:.7rem;
    margin-bottom:.5rem
  }
  #reserdetail .r-name .name{
    padding-left:5px;
    font-size:1.2rem;
  }
  #reserdetail .r-name .nnum{
    font-size:.9rem;
  }
  #reserdetail .r-name .nreview{
    padding-top:0
  }
}




/* -------------------------------------------------
  # 내용 공통부분
--------------------------------------------------- */
#reserdetail .pc{
  display: block;
}
#reserdetail .mobile{
  display: none;
}

#reserdetail .title{
  border-bottom: 2px solid #000;
}
#reserdetail .title p{
  font-size:1.4rem;
  color:#000;
}
#reserdetail .table{
  vertical-align: middle;
}
#reserdetail .table tr{
  font-size:.9rem;
  height:4rem;
}
#reserdetail .table td{
  padding-top:1rem;
  padding-left:1.5rem
}
#reserdetail .table .ta-name{
  background-color:#f8f8f8;
  color:#000;
  font-weight: normal;
  text-align: center;
}
@media (max-width:991px){
  #reserdetail .pc{
    display: none;
  }
  #reserdetail .mobile{
    display: block;
  }
  #reserdetail .title p{
    font-size:1.2rem;
  }
  #reserdetail .table tr{
    font-size:.85rem;
    height:3rem;
  }
  #reserdetail .table td{
    text-align: center;
  }
}



/* -------------------------------------------------
  # 여행 상품 정보
--------------------------------------------------- */
#reserdetail .traval{
  margin:4rem 0
}
@media (max-width:991px){
  #reserdetail .traval{
    margin:2rem 0
  }
}


/* -------------------------------------------------
  # 예약자 정보
--------------------------------------------------- */
#reserdetail .tourist{
  margin:4rem 0
}


/* -------------------------------------------------
  # 대표 여행자 정보
--------------------------------------------------- */
#reserdetail .tourist{
  margin:4rem 0
}
@media (max-width:991px){
  #reserdetail .tourist{
    margin:2rem 0
  }
}


/* -------------------------------------------------
  # 결제 정보
--------------------------------------------------- */
#reserdetail .payment{
  margin: 4rem 0
}
#reserdetail .payment .total{
  color:#f00;
  font-weight:600;
  font-size:1.7rem
} 
#reserdetail .payment .addpay{
  font-size:.9rem;
  color:#777;
  text-align: right;
}
#reserdetail .payment .addpay p{
  margin-bottom:.5rem
}
@media (max-width:991px){
  #reserdetail .payment{
    margin: 2rem 0
  }
  #reserdetail .payment .total{
    font-size:1.2rem;
    padding-right:0;
    text-align: right;
  } 
  #reserdetail .payment .addpay p{
    font-size:.8rem;
  }
}


/* -------------------------------------------------
  # 마이페이지 버튼
--------------------------------------------------- */
#reserdetail .go{
  --bs-btn-padding-y: 0;
  margin:5rem 0;
  border-radius: 0;
}
#reserdetail .go{
  background-color: #000;
  color:#fff;
  padding:20px 5px
}
#reserdetail .go:hover{
  background-color: #333;
}

@media (max-width:991px){
  #reserdetail .go{
    margin:3rem 0;
  }
}

/*--------------------------------------------------------------
  # 비회원예약 취소요청버튼
--------------------------------------------------------------*/
.cxlBtn {
  background-color:#fff; /* 배경 색상 */
  color:red; /* 텍스트 색상 */
  padding: 5px 5px; /* 패딩 (상하 10px, 좌우 20px) */
  border: solid; /* 테두리 없음 */
  border-width: 1px;
  border-radius: 5px; /* 모서리 둥글게 */
  cursor: pointer; /* 커서 모양 변경 (마우스 호버 시) */
  font-size: smaller; /* 폰트 크기 */
}

.cxlBtn:hover {
  background-color: #f7eded; /* 마우스 호버 시 배경 색상 변경 */
}


/*--------------------------------------------------------------
  # 모바일화면 아이디,패스찾기
--------------------------------------------------------------*/
/* CSS 파일이나 <style> 태그 안에 추가 */
@media (max-width: 768px) {
  .searchNonRes
  {
    font-size: 1rem;
    vertical-align: middle;
  }
}

/** 모바일화면에서 개행처리 */
@media screen and (max-width: 768px) {
  /* 모바일 화면에서 적용할 스타일 */
  .trans-info small {
    display: block;
    /* margin-bottom: 10px; */
  }
}

/* --------
  결제하기
-------- */
.ht-pay{
  padding:.5rem 3rem
}
.ht-ex P{
  margin-bottom:0;
  font-size:.9rem
}
.ht-ex .ht-big{
  font-size:1.1rem;
  padding:.5rem 0
}
/* 모바일 장치를 위한 스타일 */
@media (max-width: 768px) {
  .ht-ex p, .ht-ex b {
    /* 모바일에서의 텍스트 크기 */
    font-size: .7rem; /* 모바일에 적합한 크기로 조정 */
  }
  .ht-big {
    /* 모바일에서 큰 텍스트의 크기 */
    font-size: .9rem /* 모바일에 적합한 크기로 조정 */
  }
  .mobile-new-line {
    display: block; /* 모바일 화면에서는 블록으로 설정하여 새로운 줄에서 시작 */
  }
}
</style>
