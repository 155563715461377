<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div  v-if="this.selectedPayMethod === 'virAcc'">
          <div class="alert alert-primary" role="alert">
            ※ 가상계좌 정보를 확인 후, 기한 내에 입금을 완료하여 주십시오. ※
          </div>
        </div>

        <div v-else-if="this.selectedPayMethod === 'ETC'">
          <div class="alert alert-danger" role="alert">
            ※ 결제가 되었습니다. 담당매니저의 확인 후, 확정처리됩니다. ※
          </div>
        </div>        

        <!-- <div class="fs-3">결제정보</div>
        <div class="fs-5">
          - 결제금액: {{ this.amount }}
        </div> -->
      </div>
    </main>    
  </div>
</template>

<script>
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  data() {
    return {
      handTel: [],            //매니저연락처
      handTelCnt: 0,          //수신처갯수
      getVerifyData: 0,
      payStyle: "",           //p1:1차결제, p2:2차결제 / fu or NULL : 전액결제

      selectedPayMethod: "",  //선택된 결제방법
      virtualAccInfo: {},     //가상계좌정보
      bankName: "",           //은행명

      //total_amount: localStorage.getItem("total_amount")       //검증용결제듬액
    };
  },
  created() {
    this.user = this.$store.state.user;

    if (this.$store.state.user.handlerId != undefined) 
    {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined) 
    {
      this.userInfo = this.$store.state.user.email;
    }
    else 
    {
      this.userInfo = "";
    }
    //console.log("userInfo:", this.userInfo);
    this.paymentKey = this.$route.query.paymentKey;     //결제키
    this.orderId = this.$route.query.orderId;           //주문아이디
    this.amount = this.$route.query.amount;             //결제금액 

    this.getHandlerInfo()                               //알림톡 발송대상 체크
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    // total_amount() {
    //   return this.$store.state.total_amount;
    // }
  },
  async mounted() {
    //this.total_amount = localStorage.getItem("total_amount")
    //this.total_amount = this.$store.state.total_amount

    await this.getVeriData(this.orderId)

    let toss_pg = {
      paymentKey: this.paymentKey,
      amount:     this.amount,
      orderId:    this.orderId
    }
    
    //if(this.amount == this.total_amount)
    if(this.amount == this.getVerifyData)
    {
      // connect with api on backend
      this.$api("/api/tossPG/success",
      {
        param: toss_pg
      })
      .then(async (res) => {
        //console.log("res:", res);
        if(res.method === "가상계좌")
        {
          this.selectedPayMethod = "virAcc"
          this.virtualAccInfo = res.virtualAccount
          let bankName = await this.$api("/api/selBankName", { param: [ this.virtualAccInfo.bankCode ] })
          if(bankName.length > 0)
          {
            this.bankName = bankName        //this.bankName[0].fName 호출값
          }
          else
          {
            this.bankName = this.virtualAccInfo.bankCode
          }

          let orderId = ""
          if(this.payStyle === "fu")
          {
            orderId = this.orderId
          }
          else if(this.payStyle === 'p1' || this.payStyle === 'p2')
          {
            orderId = this.orderId.slice(0, -2)
          }

          //전액 or 1차결제 가상계좌
          let ins_virAccInfo = []
          if(this.payStyle === "fu" || this.payStyle === "p1")
          {
            let payFlag1 = "N"
            //가상계좌 정보저장
            ins_virAccInfo = await this.$api("/api/ins_virAccInfo_fu", { param: [
              orderId, this.virtualAccInfo.accountNumber, this.bankName[0].fName,  this.amount, this.getKorDateFormat(this.virtualAccInfo.dueDate), payFlag1, this.payStyle
            ] })
          }
          else if(this.payStyle === "p2")
          {
            let payFlag2 = "N"
            //가상계좌 정보저장
            ins_virAccInfo = await this.$api("/api/upd_virAccInfo_p2", { param: [
              this.virtualAccInfo.accountNumber, this.bankName[0].fName, this.amount, this.getKorDateFormat(this.virtualAccInfo.dueDate), payFlag2, this.payStyle, orderId
            ] })
          }

          if(ins_virAccInfo.affectedRows < 0)
          {
            this.$swal.fire("","죄송합니다. 가상계좌처리에 문제가 발생했습니다.<br>다시 진행하여 주십시오.<br>문제가 계속되면 당사로 문의를 주시면 감사하겠습니다(1660-4602)", "error").then(() => {
              this.$router.push({ path: "/" })
            })
          }
          else
          {
            //예약정보를 입금대기로 변경 => 관리자 예약목록에 표시
            let reqStatus = "WP"
            let compFlag = "Y"
            
            let partFlag = ""       //1,2차 결제예번 구분용 partFlag

            if(this.payStyle === "p1")
            {
              partFlag = "1"
            }
            else if(this.payStyle === "p2")
            {
              partFlag = "2"
            }

            let upd_resStatusByVirAcc = await this.$api("/api/upd_reserve_mByVirAcc", { param: [
              reqStatus, compFlag, partFlag, orderId
            ] })

            if(upd_resStatusByVirAcc.affectedRows < 0)
            {
              this.$swal.fire("","죄송합니다. 가상계좌처리에 문제가 발생했습니다.<br>다시 진행하여 주십시오.<br>문제가 계속되면 당사로 문의를 주시면 감사하겠습니다(1660-4602)", "error").then(() => {
                this.$router.push({ path: "/" })
              })
            }
            else
            {
              this.getPayDetailInfo(orderId, "VA");    //VA: 가상계좌
            }
          }          
        }
        else
        {
          this.selectedPayMethod = "ETC"
          this.changeFlag(this.orderId);
        }
      })
      .catch((err) => {
        console.error(err);
      });      
    }
    else
    {
      this.$swal.fire('', '금액검증에 문제가 발생하여 결제를 중지합니다. 문의: 1660-4602', 'error');
      //return false
      //location.href = "/fail_pg"
    }
  },
  methods:
  {
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },    
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getKorDateFormat(date)
    {
      return this.$korDateFormat(date);
    },

    goToMain(){
      this.$router.push({ path: "/" })
    },

    async getVeriData(oId){
      let getVerifyData = await this.$api("/api/getVeriData", {param: [oId]})
      if(getVerifyData.length > 0)
      {
        this.getVerifyData = getVerifyData[0].payAmt
        this.payStyle = getVerifyData[0].payFlag
        this.payPointAmt = getVerifyData[0].payPointAmt
      }
      //console.log("getVerifyData:", this.getVerifyData)
    },

    async changeFlag(oId)
    {
      // -1 또는 -2가 붙는 토스용 예번을 예약마스터의 조회조건으로 사용하기 위해 원래예번으로 돌린다
      let orderId = ""
      if(oId.endsWith("-1") || oId.endsWith("-2"))
      {
        orderId = oId.slice(0, -2)
      }
      else
      {
        orderId = oId
      }

      //여행상품과 엔버스 분리
      let pdt_cat = ""
      pdt_cat = oId.substring(0, 2)

      let updCheckFlag = []
      if(this.payStyle === 'p2')      //잔액결제처리
      {
        updCheckFlag = await this.$api("/api/updCheckFlag_1", {param: 
          [
            'Y', this.userInfo, this.amount, this.amount, 'PG', 'PY', 'N', this.payStyle, this.amount, this.payPointAmt, orderId+"-1"
          ]
        });
      }
      else
      {
        updCheckFlag = await this.$api("/api/updCheckFlag", {param: 
          [
            'Y', this.userInfo, this.amount, 'PG', 'PY', 'N', this.amount, this.payPointAmt, oId
          ]
        });
      }


      if(updCheckFlag.affectedRows >= 0) 
      {
        //포인트 사용확정
        if(this.payStyle === 'p2' || this.payStyle === 'fu' || this.payStyle === null)
        {
          let upd_UsePoint = await this.$api("/api/upd_UsePoint", {param: [ this.userInfo, orderId ]});
        
          if(upd_UsePoint.affectedRows < 0 || upd_UsePoint.affectedRows === undefined)  //쿼리가 실행된 것(=0) 자체는 정상으로 처리
          {
            this.$swal.fire("","죄송합니다. 포인트 처리에 문제가 발생했습니다(얘약번호:"+ orderId +").<br>포인트문의: 1660-4602","warning")
          }
          // else
          // {
          //   this.getPayDetailInfo(orderId);
          // }
        }

        //부분결제의 -1.-2가 붙은 예번과 원예변의 비교처리용
        let updResveStat = []
        let partFlag = ""
        let completeFlag = "Y"    //결제완료 후 Y로 변경(변경된 결제프로세스 반영)

        if(this.payStyle === "p1")
        {
          partFlag = "1"
          updResveStat = await this.$api("/api/updResveStat", {param: ['PY', this.userInfo, this.amount, partFlag, completeFlag, orderId] });
        }
        else if(this.payStyle === "p2")
        {
          partFlag = "2"
          updResveStat = await this.$api("/api/updResveStat_1", {param: ['PY', this.userInfo, this.amount, partFlag, completeFlag, orderId] });
        }
        else
        {
          partFlag = "fu"

          if(pdt_cat === "NB")
          {
            updResveStat = await this.$api("/api/updResveStat_nbus", {param: ['PY', this.userInfo, this.amount, partFlag, orderId] });
          }
          else
          {
            updResveStat = await this.$api("/api/updResveStat", {param: ['PY', this.userInfo, this.amount, partFlag, completeFlag, orderId] });
          }

        }

        if(updResveStat.affectedRows < 0)
        {
          this.$swal.fire("","예약진행상테 업데이트에 실패했습니다(얘약번호:"+ orderId +").<br>포인트문의: 1660-4602","warning")
        }

        //포인트 문의가 발생해도 결제프로세스는 진행한다
        this.getPayDetailInfo(orderId, "");
      }
      else 
      {
        this.$swal.fire('','결제확인(코드: updCheckFlag)이 되지 않습니다.<br>문의처(1660-4602)에 확인을 요청해주세요.', 'error')
      }
    },

    async getPayDetailInfo(orderId, param)
    {
      let payDetailInfo = []
      let flag = ""

      if(orderId.slice(0,2) === 'SP')                                                     //외부결제정보
      {
        payDetailInfo = await this.$api("/api/spPayDetailInfo", {param: [orderId]});      //callNo
        flag = "SP"
      }
      else if(orderId.slice(0,2) === 'NB')                                                //엔버스결제정보
      {
        payDetailInfo = await this.$api("/api/payDetailInfo_nbus", {param: [orderId]});   //nbus
        flag = "NB"
      }
      else                                                                                //일반결제정보
      {
        payDetailInfo = await this.$api("/api/payDetailInfo", {param: [orderId]});        //resPhone
        flag = "notSP"
      }

      if(payDetailInfo.length > 0)
        {
          this.payDetailInfo = payDetailInfo[0];

          if(param === "VA")        //결제선택이 가상계좌인 경우
          {
            //console.log("virtualAccInfo:", this.virtualAccInfo, "bankName:", this.bankName)
            this.sendKakao("WP", flag, orderId);   //wp:입금대기 flag:SP or notSP

          }
          else                    // 그 외
          {
            this.sendKakao("PY", flag, orderId);   //py:결제완료 flag:SP or notSP
          }
        }
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
    },


    /** 카카오알림 발송 */
    async sendKakao(param, val, orderId) //PY or WP, SP/NB or notSP
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        orderId, param, this.userInfo
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.payDetailInfo.resPhone})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0) 
      {
        let kakaoAlimData = {}

        let promises = []         //promise들의 배열
        
        if(param == "PY") 
        {
          if(this.payDetailInfo.resMFlag === 'N' || this.payDetailInfo.resMFlag == "" || this.payDetailInfo.resMFlag == null)
          {
            param = 'non'+param                 //nonPY
          }

          if(val === 'SP' && param === 'PY')
          {
            let i = 0
            while(i < this.handTelCnt)
            {
              kakaoAlimData = 
              {
                //카카오 알림톡 발송(외부결제완료)
                clientName: this.payDetailInfo.payerName,
                resName: this.payDetailInfo.spName,
                resPaxName: this.payDetailInfo.payerName,
                resPaxCnt: this.payDetailInfo.spPaxCnt,
                dptDate: this.getDateFormat(this.payDetailInfo.tripDate),
                resPhone: this.payDetailInfo.resPhone,                            //고객연락처
                resCode: orderId,
                resPayAmount: this.getCurrencyFormat(this.payDetailInfo.payAmt),
                // resManager: this.payDetailInfo.resManager,
                alimCall: this.handTel[i].aimCall,                                //알림톡발송대상
                alimFlag: "nonPY",                                                //알림유형(결제완료)
                pdtCode: this.payDetailInfo.spResCode                             //외부결제예약코드
              };
              
              let promise = this.$api("api/kakaoAlimSend2", {
                param: [{ kakaoAlimData: kakaoAlimData}]
              })

              promises.push(promise)
              i++
            }

            //반복문 이후 처리
            Promise.all(promises).then(() => {
              //console.log(results)
              this.$swal.fire('', '결제완료 알림톡이 발송되었습니다', 'success').then(() => {
                //location.reload(true)
                //location.href = '/'
                this.$router.push({ path: "/" });
              })
            }).catch((error) => {
              console.log("Error calling api: ", error)
            })
          }
          
          //결제확인안내
          else if(val != "SP")                  //여행상품 + 엔버스
          {
            let resPayAmount = 0
            if(this.payStyle === "p2")
            {
              resPayAmount = this.payDetailInfo.balance
            }
            else
            {
              resPayAmount = this.payDetailInfo.resPayAmount
            }

            let j = 0
            while(j < this.handTelCnt)
            {
              kakaoAlimData = 
              {
                //카카오 알림톡 발송(결제완료)
                clientName: this.payDetailInfo.clientName,
                resName: this.payDetailInfo.resName,
                resPaxName: this.payDetailInfo.resPaxName,
                resPaxCnt: this.payDetailInfo.resPaxCnt,
                dptDate: this.payDetailInfo.dptDate,
                resPhone: this.payDetailInfo.resPhone,
                resCode: orderId,
                resPayAmount: this.getCurrencyFormat(resPayAmount),
                resManager: this.payDetailInfo.resManager,
                alimFlag: param,                                                  //알림유형(결제완료)
                alimCall: this.handTel[j].aimCall,                                //알림톡발송대상
                pdtCode: this.payDetailInfo.reqPdtCode                            //비회원예약 결제처리용 상품코드
              };
              let promise = this.$api("api/kakaoAlimSend2", {
                param: [{ kakaoAlimData: kakaoAlimData}]
              })

              promises.push(promise)
              j++
            }
            
            //반복문 이후 처리
            Promise.all(promises).then(() => {
              //console.log(results)
              this.$swal.fire('', '결제완료 알림톡이 발송되었습니다', 'success').then(() => {
                //location.reload(true)
                //location.href = '/'
                this.$router.push({ path: "/" });
              })
            }).catch((error) => {
              console.log("Error calling api: ", error)
            })
          }
        }

        //가상계좌결제요청
        else if(param === "WP")
        {
          if(this.payDetailInfo.resMFlag === 'N' || this.payDetailInfo.resMFlag === '' || this.payDetailInfo.resMFlag == null)
          {
            param = 'non'+param                 //nonWP
          }

          //외부결제+가상계좌입금대기
          if(val === 'SP' && param === 'WP')
          {
            let i = 0
            while(i < this.handTelCnt)
            {
              kakaoAlimData = 
              {
                //카카오 알림톡 발송(가상계좌입금대기)
                clientName: this.payDetailInfo.payerName,
                resName: this.payDetailInfo.spName,
                resPaxName: this.payDetailInfo.payerName,
                resPaxCnt: this.payDetailInfo.spPaxCnt,
                dptDate: this.getDateFormat(this.payDetailInfo.tripDate),
                resPhone: this.payDetailInfo.resPhone,                            //고객연락처
                resCode: orderId,
                resPayAmount: this.getCurrencyFormat(this.amount),
                // resManager: this.payDetailInfo.resManager,
                
                //가상계좌정보
                resBankName: this.bankName[0].fName,
                resVirAccount: this.virtualAccInfo.accountNumber,
                resDueDate: this.getKorDateFormat(this.virtualAccInfo.dueDate),                
                
                alimCall: this.handTel[i].aimCall,                                //알림톡발송대상
                alimFlag: "nonWP",                                                //알림유형(결제완료)
                pdtCode: this.payDetailInfo.spResCode                             //외부결제예약코드
              };
              
              let promise = this.$api("api/kakaoAlimSend2", {
                param: [{ kakaoAlimData: kakaoAlimData}]
              })

              promises.push(promise)
              i++
            }

            //반복문 이후 처리
            Promise.all(promises).then(() => {
              //console.log(results)
              this.$swal.fire('', '가상계좌 입금요청 알림톡이 발송되었습니다', 'success').then(() => {
                //location.reload(true)
                this.$router.push({ name: 'VirtualAccountInfo', params: { 
                  oId:orderId, pStyle:this.payStyle
                }})
              })
            }).catch((error) => {
              console.log("Error calling api: ", error)
            })
          }
          
          //일반결제+가상계좌입금대기
          else if(val != "SP")
          {
            let resPayAmount = 0
            if(this.payStyle === "p2")
            {
              resPayAmount = this.payDetailInfo.balance
            }
            else
            { 
              resPayAmount = this.amount
            }

            let j = 0
            while(j < this.handTelCnt)
            {
              kakaoAlimData = 
              {
                //카카오 알림톡 발송(결제완료)
                clientName: this.payDetailInfo.clientName,
                resName: this.payDetailInfo.resName,
                resPaxName: this.payDetailInfo.resPaxName,
                resPaxCnt: this.payDetailInfo.resPaxCnt,
                dptDate: this.payDetailInfo.dptDate,
                resPhone: this.payDetailInfo.resPhone,
                resCode: orderId,
                resPayAmount: this.getCurrencyFormat(resPayAmount),
                resManager: this.payDetailInfo.resManager,
                //가상계좌정보
                resBankName: this.bankName[0].fName,
                resVirAccount: this.virtualAccInfo.accountNumber,
                resDueDate: this.getKorDateFormat(this.virtualAccInfo.dueDate),

                alimFlag: param,                                                  //알림유형(결제완료)
                alimCall: this.handTel[j].aimCall,                                //알림톡발송대상
                pdtCode: this.payDetailInfo.reqPdtCode                            //비회원예약 결제처리용 상품코드
              };
              let promise = this.$api("api/kakaoAlimSend2", {
                param: [{ kakaoAlimData: kakaoAlimData}]
              })

              promises.push(promise)
              j++
            }
            
            //반복문 이후 처리
            Promise.all(promises).then(() => {
              //console.log(results)
              this.$swal.fire('', '가상계좌 입금요청 알림톡이 발송되었습니다', 'success').then(() => {
                //location.reload(true)
                //this.$router.push({ path: "/" });
                this.$router.push({ name: 'VirtualAccountInfo', params: {
                  oId:orderId, pStyle:this.payStyle
                }})
              })
            }).catch((error) => {
              console.log("Error calling api: ", error)
            })
          }
        }
      }
    },
  }
}
</script>

<style>
.vAccount th {
  background-color: lightgray;
  width: 40%;
  height: 45px;
  text-align: center;
  border: 2px solid white;
}
.vAccount td {
  text-align: center;
  font-weight: 600;
  text-align: start;
  padding-left: 15px;
}
</style>