<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="text-start mt-2 mb-1 text-success"> [접수중 &nbsp;예약] </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class = "small">
            <tr>
              <th>일정</th>
              <th>예약번호</th>
              <th>예약명</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="j" v-for="(rqList, j) in reqListReqRes">
              <td>{{ getDateFormat(rqList.startDate) }}~{{ getDateFormat(rqList.closeDate) }}</td>
              <td class="text-success">
                <a @click="this.$swal.fire('', '접수이후 확인이 가능합니다', '')" style="cursor:pointer;">
                  {{ rqList.resCode }}
                </a>
              </td>
              <td>{{ rqList.resName  }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container">
        <div class="mt-3 mb-3">
          <div class="float-start">
            <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="rProcCode" @change="changeProcCode()">
              <option :value="noVal" disabled>진행상태선택</option>
              <option value="A" >전체보기</option>
              <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
            </select>
          </div>
          <div class="float-end">
            <input v-model="dataInput" @input="submitAutoComplete" type="text" class="form-control form-control-sm text-primary" placeholder="신청자 또는 상담번호" />
            <div class="autocomplete disabled">
              <div class="text-success" style="cursor: pointer" :key="i" v-for="(res,i) in result" @click="searchData(res)"><small>{{ res }}</small></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="text-start mt-5 text-primary">[접수된 &nbsp;예약] </div>
          <table class="table table-bordered table-striped fs-6">
            <thead class = "small">
              <tr>
                <th>상태</th>
                <th>일정</th>
                <th>예약번호</th>
                <th>예약명</th>
              </tr>
            </thead>
            <tbody class = "small">
              <tr :key="i" v-for="(rList, i) in reqList">
                <td v-if="rList.reqStatus === 'RP'" class="text-danger">{{ rList.procName }}</td>
                <td v-else>{{ rList.procName }}</td>
                <td>{{ getDateFormat(rList.startDate) }}~{{ getDateFormat(rList.closeDate) }}</td>
                <td class="text-primary">
                  <a @click="goToReqReserveDetail(rList.resCode, rList.reqPdtCode, rList.ntCd1);" style="cursor:pointer;">
                    {{ rList.resCode }}
                  </a>
                </td>
                <td>{{ rList.resName  }}</td>
              </tr>
            </tbody>
          </table>
      </div>



      <!-- </div> -->
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      reqListReqRes: [], //접수중 예약
      reqList: [], //마이상품
      reqListEvt: [], //마이행사
      reqListAll: [], //전체
      rProc: [],
      resName: "", //상품, 행사명 (통합)

      skillInput: null,
      result: null,
      arrSearchData: []
    };
  },
  created() {
    //this.bSeq = this.$route.query.bSeq;
    //this.getProductMaList();
    this.getReqProcLevel();
    this.getRequestConsultList();

    console.log("AccessInfo:",this.$store.state.user,",Email:",this.user.email);
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    getDateFormat(date) 
    {
      return this.$dateFormat(date);
    },
    async getReqProcLevel() 
    {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) 
      {
        this.rProc = rProc
      }
      console.log("rProc:",this.rProc);
    },
    async getRequestConsultList() 
    {
      let reqListReqRes = await this.$api("/api/getMyReqResList", {param:[this.user.email]});
      let reqListRes = await this.$api("/api/getMyResList", {param:[this.user.email]});
      let reqListEvt = await this.$api("/api/getMyEvtList", {param:[this.user.email]});
      let reqList = reqListRes.concat(reqListEvt);
      this.reqListReqRes = reqListReqRes;
      this.reqListAll = reqList;
      console.log("reqListReqRes:",reqListReqRes)
      console.log("reqListRes:",reqListRes,",reqListEvt:",reqListEvt);
      console.log("reqList:",reqList);
      
      let i=0;
      let arrSearchData = []; //상품
      if(reqList.length > 0) 
      {
        this.reqList = reqList;
        while(i<reqList.length) 
        {
          arrSearchData.push(reqList[i].resCode, reqList[i].myName);
          i++;
        }
      }
      this.arrSearchData = arrSearchData;
      //console.log("arrSearchData:",arrSearchData)
    },
    //자동완성기능
    submitAutoComplete() 
    {
      const autocomplete = document.querySelector(".autocomplete");
      let result_tmp = [];
      if (this.dataInput) 
      {
        autocomplete.classList.remove("disabled");
        autocomplete.style.display = "block"
        result_tmp = this.arrSearchData.filter((e) => {
          return String(e).match(new RegExp("^" + this.dataInput.toUpperCase(), "i"));
        });
        //결과값 중복제거
        this.result = Array.from(new Set(result_tmp));        
      }
      else 
      {
        autocomplete.classList.add("disabled");
        autocomplete.style.display = "none";
        this.getRequestConsultList()
      }
    },
    async searchData(res) 
    {
      console.log("reqList1:", this.reqList);
      console.log("res:",res);
      let reqList_tmp = this.reqList;
      if(res.substr(0,2) === "RQ" || res.substr(0,2) === "EV") 
      {
        this.reqList = await reqList_tmp.filter(e => {return e.resCode === res});
      }
      // else if(res.substr(0,2) === "EV") 
      // {
      //   this.reqList = await reqList_tmp.filter(e => {return e.reqReserveCode === res});
      // }
      else
      {
        this.reqList = await reqList_tmp.filter(e => {return e.myName === res});
      }
    },
    refProduct(pdtCode) 
    {
      this.$router.push({path: '/detail', query: {productId: pdtCode}});
    },
    goToReqReserveDetail(seqReq, pCode, ntCode1) 
    {
      //goToReqReserveDetail(rList.resCode, rList.reqPdtCode, rList.ntCd1, rList.ntCd2)
      if(seqReq.substr(0,2) === "RQ")
      {
        this.$router.push({path: '/reserveInfoDetail_client', query: {seqReq: seqReq, pCode: pCode, cCode: 'Y'}});
      }
      else
      {
        this.$router.push({path: '/eventInfo_client', query: {eCode: seqReq, ntCd: ntCode1}});
      }
      //this.$router.push({path: '/reserveInfoDetail_client', query: {seqReq: seqReq, pCode: pCode, cCode: cCode}});
      //this.$router.push({path: '/eventInfo', query: {eCode: eventCode, ntCd: ntCd}});
      //console.log("oId:", orderId);
    },
    async changeProcCode() 
    {
      if(this.rProcCode === 'A') 
      {
        this.getRequestConsultList();
      }
      else 
      {
        //let reqList_tmp = await this.$api("/api/getMyResList", {param: [this.user.email]})
        this.reqList = this.reqListAll.filter(e => {return e.reqStatus === this.rProcCode});
      }
    }
  }
}
</script>