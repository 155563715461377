<template>
  <div id="mrequest">
    <section>
      <div class="container my-3">
        <div class="row justify-content-center">
          <h3>문의내역</h3>

          <!-- 문의들 구분 탭 -->
          <div class="mr-tap">
            <button type="button" class="one" id="one" @click="changeIquiry('one')">1:1 문의</button>
            <button type="button" class="item" id="item" @click="changeIquiry('item')">상품문의</button>
            <button type="button" class="estimate" id="estimate" @click="changeIquiry('estimate')">견적문의</button>
          </div>

          <!-- 1:1 pc-->
          <div class="row mr-cont pc" v-if="this.reqFlag == 'one'">
            <p>총 {{this.reqList.length}}건</p>
            <table class="table">
              <thead>
                <tr>
                  <th class="col-2">상태</th>
                  <th class="col-2">유형</th>
                  <th class="col-4">제목</th>
                  <th class="col-2">작성일</th>
                  <th class="col-2">답변</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="j" v-for="(ca, j) in reqList">
                  <th>{{ ca.status }}</th>
                  <th>{{ ca.cat }}</th>
                  <th>
                    <a @click="goToCustomerAsk_client(ca.askCode);" class="text-primary" style="cursor:pointer;">
                      {{ ca.reqTitle }}
                    </a>
                  </th>
                  <th>{{ this.getDateFormat(ca.reqDate) }}</th>
                  <th v-if="ca.answerId == undefined">N</th>
                  <th v-else>Y</th>
                </tr> 
              </tbody>
            </table>
          </div>

          <!-- 1:1 모바일-->
          <div class="row mr-cont mobile" v-if="this.reqFlag == 'one'">
            <p>총 {{this.reqList.length}}건</p>
            <table :key="j" v-for="(ca, j) in reqList" class="table">
              <tbody>
                <tr>
                  <td class="col-10 cname">
                    [{{ ca.status }}]
                    <a @click="goToCustomerAsk_client(ca.askCode);" class="text-primary" style="cursor:pointer; font-weight: 600;">
                      {{ ca.reqTitle }}
                    </a>
                  </td>
                  <td class="col-2 answer" rowspan="2" v-if="ca.answerId == undefined">답변대기</td>
                  <td class="col-2 answer" rowspan="2" v-else>답변완료</td>
                </tr>
                <tr>
                  <td class="cetc" colspan="2">{{ ca.cat }} / {{ this.getDateFormat(ca.reqDate) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 문의하기 버튼 -->
          <div class="mt-5 row justify-content-center" v-if="this.reqFlag == 'one'">
            <button class="btn go col-5 col-lg-3 mx-2" @click="goToCustomerAsk()">1:1 문의하기</button>
          </div>



          <!-- 상품문의 pc -->
          <div class="row mr-cont pc" v-if="this.reqFlag == 'item'">
            <p>총 {{this.reqList_inq.length}}건</p>
            <table class="table">
              <thead>
                <tr>
                  <th class="col-1">상태</th>
                  <th class="col-1">유형</th>
                  <th class="col-3">제목</th>
                  <th class="col-5">상품</th>
                  <th class="col-1">작성일</th>
                  <th class="col-1">답변</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="j" v-for="(item, j) in reqList_inq">
                  <th>{{ item.status }}</th>
                  <th>{{ item.cat }}</th>
                  <th>
                    <a @click="goToInquiryDetail_client(item.iqCode);" class="text-primary" style="cursor:pointer;">
                      {{ item.reqTitle }}
                    </a>
                  </th>
                  <th>
                    <a @click="goToProductDetail(item.pdtCode);" class="text-success" style="cursor:pointer; font-weight: normal; font-size: small">
                      {{ item.pdtNameKor }}
                    </a>
                  </th>                  
                  <th>{{ this.getDateFormat(item.reqDate) }}</th>
                  <th v-if="item.ansId == undefined">N</th>
                  <th v-else>Y</th>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 상품문의 모바일 -->
          <div class="row mr-cont mobile" v-if="this.reqFlag == 'item'">
            <p>총 {{this.reqList_inq.length}}건</p>
            <table :key="j" v-for="(item, j) in reqList_inq" class="table">
              <tbody>
                <tr>
                  <td class="col-10 cname">
                    [{{ item.status }}]
                    <a @click="goToInquiryDetail_client(item.iqCode);" class="text-primary" style="cursor:pointer; font-weight: 600;">
                      {{ item.reqTitle }}
                    </a>
                  </td>
                  <td class="col-2 answer" rowspan="3" v-if="item.ansId == undefined">답변대기</td>
                  <td class="col-2 answer" rowspan="3" v-else>답변완료</td>
                </tr>
                <tr>
                  <td class="col-10">
                    <a @click="goToProductDetail(item.pdtCode);" class="text-success" style="cursor:pointer; font-size:.7rem;">
                      {{ item.pdtNameKor }}
                    </a>
                  </td>
                  <td class="col-2"></td>
                </tr>
                <tr>
                  <td class="cetc" colspan="2">{{ item.cat }} / {{ this.getDateFormat(item.reqDate) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <div class="mt-5 row justify-content-center" v-if="this.reqFlag == 'item'">
            <button class="btn go col-5 col-lg-3 mx-2" @click="goToCustomerAskDetail()">상품문의하기</button>
          </div> -->



          <!-- 견적문의 pc -->
          <div class="row mr-cont pc" v-if="this.reqFlag == 'estimate'">
            <p>총 {{ this.reqList_quo.length}}건</p>
            <table class="table">
              <thead>
                <tr>
                  <th class="col-2">상태</th>
                  <th class="col-2">유형</th>
                  <th class="col-4">제목</th>
                  <th class="col-2">작성일</th>
                  <th class="col-2">답변</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="j" v-for="(estimate, j) in reqList_quo">
                  <th>{{ estimate.status }}</th>
                  <th>{{ estimate.pCategory }}</th>
                  <th>
                    <a @click="goToQuotationDetail_client(estimate.qCode, estimate.seqReq);" class="text-primary" style="cursor:pointer;">
                      {{ estimate.reqTitle }}
                    </a>
                  </th>
                  <th>{{ this.getDateFormat(estimate.reqDate) }}</th>
                  <th v-if="estimate.ansId == undefined">N</th>
                  <th v-else>Y</th>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 견적문의 모바일 -->
          <div class="row mr-cont mobile" v-if="this.reqFlag == 'estimate'">
            <p>총 {{ this.reqList_quo.length}}건</p>
            <table :key="j" v-for="(estimate, j) in reqList_quo" class="table">
              <tbody>
                <tr>
                  <td class="col-10 cname">
                    [{{ estimate.status }}]
                    <a @click="goToQuotationDetail_client(estimate.qCode, estimate.seqReq);" class="text-primary" style="cursor:pointer; font-weight: 600;">
                      {{ estimate.reqTitle }}
                    </a>
                  </td>
                  <td class="col-2 answer" rowspan="2" v-if="estimate.ansId == undefined">답변대기</td>
                  <td class="col-2 answer" rowspan="2" v-else>답변완료</td>
                </tr>
                <tr>
                  <td class="cetc" colspan="2">{{ estimate.pCategory }} / {{ this.getDateFormat(estimate.reqDate) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-5 row justify-content-center" v-if="this.reqFlag == 'estimate'">
            <button class="btn go col-5 col-lg-3 mx-2" @click="goToRequestQuoDetail()">견적문의하기</button>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
//import { getAvailPoint } from "../assets/js/commonFunctions.js"
export default {
  data() {
    return {
      reqFlag: "", //문의플래그
      userInfo: [], //유저정보

      //1:1문의관련
      reqList: [], //1:1문의

      //상품문의관련
      reqList_inq: [],      

      //견적관련
      reqList_quo: [],
      
      resUserPoint: [],
      availPoint: 0, // 사용자 가용포인트

      rProc: [], //프로세스레벨

      //예약관련
      reqListReqRes: [], //접수중 예약
      reqListEvt: [], //마이행사
      reqListAll: [], //전체
      resName: "", //상품, 행사명 (통합) 
      dFlag: "", //시기구분플래그
    };
  },
  created(){
    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }
    
    this.reqFlag = this.$route.query.askCode;
  
    //console.log("user:",this.$store.state.user);

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")

    //회원정보
    this.getUserInfo();

    //1:1문의리스트
    this.getCustomerAskList(this.user.email)

    //상품문의리스트
    this.getInquiryProduct(this.user.email)

    //견적관련
    this.getRequestQuotationList(this.user.email)


    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.userInfo.joinFlag == 'H')
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.phone.replace("+82 ", "0")
      }
      this.k_tel = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }
    //console.log("k_name:",this.k_name, "k_tel:",this.k_tel)
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },  
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      this.$router.push({path: '/'});
    }

    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) 
    {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }

    // 파라미터에 따라 버튼의 폰트 컬러 변경
    if (this.reqFlag === "one") 
    {
      document.getElementById("one").style.color = "blue"; // 원하는 폰트 컬러로 변경
    } 
    else if (this.reqFlag === "item") 
    {
      document.getElementById("item").style.color = "blue";
    } else if (this.reqFlag === "estimate") 
    {
      document.getElementById("estimate").style.color = "blue";
    }
  },  
  methods: {
    // 숫자 3자리마다 콤마 찍기
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },

    async getUserInfo() 
    {
      let userInfo = await this.$api("/api/checkMemEmail", {param: [this.user.email]});
      if(userInfo.length > 0) {
        this.userInfo = userInfo[0];
      }
    },

    //문의내용 화면변경
    changeIquiry(param){
      this.reqFlag = param

      document.getElementById("one").style.color = "black"
      document.getElementById("item").style.color = "black"
      document.getElementById("estimate").style.color = "black"

      if (this.reqFlag === "one") 
      {
        document.getElementById("one").style.color = "blue"; // 원하는 폰트 컬러로 변경
      } 
      else if (this.reqFlag === "item") 
      {
        document.getElementById("item").style.color = "blue";
      } else if (this.reqFlag === "estimate") 
      {
        document.getElementById("estimate").style.color = "blue";
      }
    },

    //1:1문의
    async getCustomerAskList(uId) {
      let reqList = await this.$api("/api/customerAskList_myPage", {param: uId});
      if(reqList.length > 0) {
        this.reqList = reqList;
      }
      //console.log("reqList:",this.reqList);
    },
    //1:1문의 상세정보
    goToCustomerAsk_client(code) {  //code: 질문번호
      this.$router.push({path: '/customerAsk_client', query:{askCode: code}});
    },    
    //1:1문의 바로가기
    goToCustomerAsk() {
      this.$router.push({path: '/customerAsk'});
    },

    //상품문의
    async getInquiryProduct(uId) {
      let reqList_inq = await this.$api("/api/inquiryProcudt_myPage", {param: uId});
      if(reqList_inq.length > 0) {
        this.reqList_inq = reqList_inq;
      }
      //console.log("reqList_inq:",this.reqList_inq);
    },
    //상품문의상세
    goToInquiryDetail_client(code) {  //code: 질문번호
      this.$router.push({path: '/inquiryDetail_client', query:{iqCode: code}});
    },
    goToProductDetail(pdtCode){
      let salesRoute = pdtCode.charAt(0)
      this.$router.push({path: '/detail', query:{productId: pdtCode, salesRoute: salesRoute}});
    },

    //견적문의
    async getRequestQuotationList(uId) 
    {
      let reqList_quo = await this.$api("/api/reqQuotationList_myPage", {param: uId});
      if(reqList_quo.length > 0) {
        this.reqList_quo = reqList_quo;
      }
      //console.log("reqList_quo:",this.reqList_quo);
    },
    //문의상세
    goToQuotationDetail_client(code, seq) {  //code: 질문번호
      this.$router.push({path: '/QuotationDetail_client', query:{qtCode: code, seqReq: seq}});
    },    
    //견적문의 바로가기
    goToRequestQuoDetail() {
      this.$router.push({path: '/requestQuotation'});
      //console.log("oId:", orderId);
    },

    goToHome() {
      this.$router.push({path: '/'});
    }
  }
}
</script>

<style>
#mrequest{
  margin:4rem 0 8rem;
}
/* @media (max-width:992px){
  #mrequest{
    margin:0rem 0 4rem;
  }
} */

#mrequest h3{
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1.5rem;
  text-transform: uppercase;
  text-align: center;
}

#mrequest .pc{
  display: block;
}
#mrequest .mobile{
  display: none;
}
@media (max-width:991px){
  #mrequest .pc{
    display: none;
  }
  #mrequest .mobile{
    display: block;
  }
}


/*--------------------------------------------------------------
	# 문의들 구분 탭
--------------------------------------------------------------*/
#mrequest .mr-tap{
  margin:3rem 0;
  text-align: center;
}
#mrequest .mr-tap button{
  width:33.3%;
  padding:1.2rem 0;
  background-color: #f1f1f1;
  color:#777;
  border-width: 1px;
  border-color:#ccc;
  font-size:1.1rem;
  font-weight: 600;
}
#mrequest .mr-tap .one,
#mrequest .mr-tap .item,
#mrequest .mr-tap .estimate{
  border-right:none
}

#mrequest .mr-tap button:hover,
#mrequest .mr-tap button:active{
  background-color: #fff;
  color:#000;
  border-color:#000;
  border-top-width: 2px;
  border-left-width: 2px;
  border-right:2px solid #000;
  border-bottom: none;
}

@media (max-width:991px){
  #mrequest .mr-tap{
    margin:2rem 0 0;
  }
}

.blueText {
  color: blue;
}


/*--------------------------------------------------------------
# 문의 내용
--------------------------------------------------------------*/
#mrequest .mr-cont{
  margin:3rem 0
}
#mrequest .mr-cont .table{
  border-color:#e0e0e0; 
  border-top:2px solid #000;
  text-align: center;
}
#mrequest .mr-cont thead th{
  font-size:.9rem;
  font-weight:600;
  max-width:50px;
  padding:15px 0;
  background-color: #f8f8f8;
}
#mrequest .mr-cont tbody th{
  font-weight:300
}





/*--------------------------------------------------------------
	# 모바일 공통
--------------------------------------------------------------*/
#mrequest .mobile{
  margin:3rem 0 0;
}
#mrequest .mobile .table{
  border-top:1px dotted #000;
}
#mrequest .mobile tr{
  border-color: transparent;
}
#mrequest .mobile td{
  font-size:.9rem;
  text-align: left;
  padding:0.3rem
}
#mrequest .mobile .cname{
  font-size:1rem
}
#mrequest .mobile .cetc{
  font-size:.7rem;
  color:#aaa
}
#mrequest .mobile .answer{
  font-size:small;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
}




/*--------------------------------------------------------------
# 버튼
--------------------------------------------------------------*/
#mrequest .go {
  padding:20px 0;
  background-color: #111;
  color:#fff;
  border-radius:0;
}
#mrequest .go:hover{
  background-color: #333;
}
</style>
