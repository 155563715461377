<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <!-- list search-condition -->
      <div class="container">

        <!-- 알림영역 -->
        <div  class="col-md-6 alert alert-dismissible fade show border-success" style="background-color:seashell; font-size:small" role="alert">
          (국민은행) <strong>123-45-6789</strong> (엔타비글로벌주식회사)
          <!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> -->
        </div>
        <div  class="col-md-6 alert alert-dismissible fade show border-success border-1" style="background-color:paleturquoise; font-size:small" role="alert">
          <i class="bi bi-volume-up text-success"></i>&nbsp;<font class="text-grey">업무시간:</font>&nbsp;&nbsp;<strong>09:00 ~ 18:00</strong> 
            <br>
            (점심12:00~13:00과 휴일제외)
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="col-md-6 alert alert-dismissible fade show border-dark" style="background-color:lightslategray; font-size:small" role="alert">
          <i class="bi bi-send-fill text-white"></i>&nbsp;<font style="color:white">승선권/항권권 구입과 기타문의는</font>&nbsp;
          <strong><a href="/inquiryBoadingPass" class="alert-link fs-5" style="text-decoration-line:none; color:gainsboro">클릭</a></strong>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div v-if="this.notice.seqNotice == undefined"></div>
        <div class="col-md-6 alert alert-dismissible fade show border-dark" style="background-color:darkslategray; font-size:small" role="alert" v-else>
          <i class="bi bi-megaphone text-white"></i>&nbsp;<strong style="color:white"><u>공 지 사 항</u></strong>
          <br>
          <p class="mt-2 text-start text-white"></p>
            <pre class="text-white text-start">{{ this.notice.nContent }}</pre>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <!-- Search Condition -->
        <div class="row">
          <div class="col-md-6">
            <div class="input-group">
              <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_nationList"
              v-model="ntCd" @change="chage_ntCodeList(ntCd,pdtCd)">
              <!-- <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_nationList"
              v-model="ntCd" @change="changePdtCategory()">               -->
                <option :value="noVal" disabled>-- 여행지역 선택 --</option>
                <option value="">전체</option>
                <option :value="ntCd.baseCode" :key="i" v-for="(ntCd, i) in nationList">
                  {{ ntCd.codeNameKor }}
                </option>
              </select>&nbsp;&nbsp;
              <i class="bi bi-plus-circle-dotted text-primary align-middle fs-5"></i>&nbsp;&nbsp;
              <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_pdtCatList"
              v-model="pdtCd" @change="selSearchCondition(ntCd,pdtCd)">
                <option :value="noVal" disabled>-- 상품카테고리 선택 --</option>
                <option value="">전체</option>
                <option :value="pdtCd.baseCode" :key="i" v-for="(pdtCd, i) in pdtCatList">
                  {{ pdtCd.codeNameKor }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- 상품리스트 -->
        <!-- sales items area imgaeSize: 640*450 -->
        <div class="row mt-2 mb-2" :key="i" v-for="(product, i) in productListForSales">
          <div class="col-md-6">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-primary" style="text-align: left">
                  <span class="badge mx-1 fs-6 text-white" style="background-color:goldenrod" v-if="product.promoStatus == 'Y'">
                    &nbsp;할인상품&nbsp;</span>
                  <span class="badge bg-warning" v-else></span>
                </strong>
                <!-- <div class="mb-4 text-wrap text-start" style="text-align:right; color:indigo;">
                  <font class="align-top text-small" style="color:navy"><i class="fas fa-indent"></i>&nbsp;{{ product.pdtCode }}</font>
                </div> -->
                <strong class="d-inline-block mb-2 text-primary text-end">
                  <span class="badge bg-primary mx-1 fs-6" v-if="product.trCd1 == 'FLT'"><i class="fas fa-plane"></i></span>
                  <span class="badge bg-primary mx-1 fs-6" v-else-if="product.trCd1 == 'SHP'"><i class="fa-solid fa-sailboat"></i></span>
                  <span class="badge bg-primary mx-1 fs-6" v-else-if="product.trCd1 == 'BUS'"><i class="fa-solid fa-van-shuttle"></i></span>
                  <span class="badge bg-primary" v-else></span>
                  <span v-if="product.trCd1 != undefined && product.trCd2 != undefined"><i class="bi bi-arrow-repeat text-secondary fs-5"></i></span>
                  <span class="badge bg-danger mx-1 fs-6" v-if="product.trCd2 == 'FLT'"><i class="fas fa-plane"></i></span>
                  <span class="badge bg-danger mx-1 fs-6" v-else-if="product.trCd2 == 'SHP'"><i class="fa-solid fa-sailboat"></i></span>
                  <span class="badge bg-danger mx-1 fs-6" v-else-if="product.trCd2 == 'BUS'"><i class="fa-solid fa-van-shuttle"></i></span>
                  <span class="badge bg-danger" v-else></span>
                </strong>
                <h2 class="mb-0 text-wrap" style="text-align:right; color:indigo; font-weight:bold">
                  <!-- <h6 class="badge border rounded-pill mx-1 text-white align-middle" style="background-color:seagreen"> -->
                  <div v-if="product.cityName != undefined">
                    <small><small><small><small class="align-top fs-6" style="color:mediumseagreen; font-weight:normal"><i>{{product.cityName}}출발</i></small></small></small></small>&nbsp;
                    <!-- <h6 class="btn btn-outline-success btn-sm disabled">
                      <small class="align-middle" style="color:mediumseagreen">{{product.cityName}}출발</small>
                    </h6> -->
                    {{ product.pdtNameKor }}
                  </div>
                  <div v-else>
                    {{ product.pdtNameKor }}
                  </div>
                  <!-- <font class="fs-4 align-top" style="color:tomato">[{{product.codeNameKor}}]</font>&nbsp;{{ product.pdtNameKor }} -->
                </h2>
                <div class="mt-3 mb-1 d-flex justify-content-between align-item-center" style="text-align: left">
                  <button type="button" class="btn btn-sm btn-outline-primary" @click="goToDetail(product.pdtCode,product.salesRoute)">
                    <a href="#" style="text-decoration-line: none" v-if="product.autoSales === 'Y'" class="text-danger">즉시구매가능</a>
                    <a href="#" style="text-decoration-line: none" v-else>구매문의</a>
                  </button>
                  <div v-if="product.promoStatus === 'Y'">
                    <!-- <small class="text-secondary fs-6 text-decoration-line-through" v-if="product.pdtPrice == undefined">
                      {{ getCurrencyFormat(product.nominalAmt)}}<font class="text-muted">원</font></small>
                    <small class="text-secondary fs-6 text-decoration-line-through" v-else>
                      {{ getCurrencyFormat(product.pdtPrice)}}<font class="text-muted">원</font></small> -->
                    <small class="text-secondary fs-6 text-decoration-line-through">
                      {{ getCurrencyFormat(product.minPrice)}}<font class="text-muted">원</font></small>
                    <small class="text-danger fs-5 mx-1">{{ getCurrencyFormat(product.pPrice)}}<font class="text-muted">원~</font></small>
                  </div>
                  <div v-else-if="product.promoStatus === 'N'">
                    <small class="text-danger fs-5 mx-1">{{ getCurrencyFormat(product.pPrice)}}<font class="text-muted">원~</font></small>
                  </div>
                  <div v-else-if="product.promoStatus === 'E'">
                    <small class="text-danger fs-5 mx-1">{{ getCurrencyFormat(product.pPrice)}}<font class="text-muted">원~</font></small>
                  </div>
                </div>
              </div>
              <!-- <div class="col-auto d-none d-lg-block"> -->
              <!-- <div class="col-auto d-none d-xs-block"> -->
              <div class="col-auto d-block mb-1">
                <a @click="goToDetail(product.pdtCode,product.salesRoute)" style="cursor: pointer">
                  <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..."/>
                  <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                </a>
              </div>
              <!-- <div class="col-auto d-block mt-1 fs-6 text-wrap text-start"> -->
              <div class="col-auto text-sm-start text-truncate text-secondary" style="max-width: 95%">
                &nbsp;&nbsp;<b><i class="bi bi-chat-dots text-primary"></i></b>&nbsp;{{product.pdtRemarks}}
              </div>
            </div>
          </div>
        </div>
        <!-- sales items area imgaeSize: 640*450 -->
      </div>
    </main>
  </div>
</template>

<script>
//import _ from 'underscore';
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      nationList: {}, //여행지역리스트
      pdtCatList: {}, //상품카테고리
      productListForSales: {}, //판매용 상품리스트
      pPriceY: 0,
      pPriceN: 0,
      pPriceE: 0,
      // catList: [],
      // catVal: "",
      notice: [],
    };
  },
  created() {
    // 페이지생성과 동시에 리스트정보를 get
    this.getNationList();
    this.getPdtCatList();
    this.getProductList();
    // //네이버로그인 처리용
    // //this.seqReq = this.$route.query.seqReq;
    // this.code = this.$route.query.code;
    // this.state = this.$route.query.state;
    // console.log("code:", this.code, "state:", this.state);

    console.log(
      "userInfo:",this.$store.state.user,
    );
    console.log(
      "userInfo:",
      this.$store.state.user.email,
      "alimCall:",
      this.$store.state.user.alimCallC
    );
  },
  mounted() {
    if (this.$store.state.user.handlerId != undefined) {
      document.querySelector(".b2cTag").style.display = "none";
    }
    if (this.$store.state.user.email != undefined) {
      document.querySelector(".b2bTag").style.display = "none";
    }
    // if(this.$store.state.user.buyerId == undefined && this.$store.state.user.email == undefined) {
    //   // document.querySelector(".b2bTag").style.display="none";
    // }
      this.getNoticeList();
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },  
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  methods: {
    async getNoticeList() {
      let notice = await this.$api("/api/notice", {});
      if (notice.length > 0) {
        this.notice = notice[0];
      }
      //console.log("notice:",this.notice);
    },
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList:", this.nationList);
    },
    async getPdtCatList() {
      let pdtCatList = await this.$api("/api/pdtCatList", {});
      this.pdtCatList = pdtCatList;
      //console.log("pdtCatList:", this.pdtCatList);
    },
    chage_ntCodeList(c1,c2) {
      //alert(c1+"//"+c2)
      this.getProductList(c1, c2)
    },
    //메인화면 상품검색
    selSearchCondition(c1, c2){
      //console.log("c1:",c1,"c2:",c2);
      this.getProductList(c1, c2)
    },
    async getProductList(p1, p2) {
      let ntCd = "";
      let sRoute = "";
      ntCd = (p1 == undefined || p1 == "") ? "%" : p1;
      sRoute = (p2 == undefined || p2 == "") ? "%" : p2;
      //alert(ntCd+"/"+sRoute);

      let productListForSales = await this.$api("/api/productListForSales", {param: [ntCd, sRoute]});

      let productListForSales_mix = await this.$api("/api/productListForSales_mix", {param: [ntCd, sRoute]});
      console.log("productListForSales_mix:", productListForSales_mix);
      this.productListForSales = productListForSales;

      let j = 0;
      while(j < productListForSales_mix.length) {
        //결합상품을 앞쪽에 위치하도록 넣는다(push는 뒤에 넣기)
        this.productListForSales.unshift(productListForSales_mix[j]);
        //console.log("productListForSales_mix:",productListForSales_mix)
        j++;
      }
      //상품가격계산 최저가기준 표시방식으로 변경(20220920)
      for (let i = 0; i < this.productListForSales.length; i++) {
        if (this.productListForSales[i].promoStatus === "Y") {
          if (this.productListForSales[i].promoMethod === "P") {
              this.pPriceY =this.productListForSales[i].minPrice - this.productListForSales[i].minPrice *(this.productListForSales[i].promoRate / 100);
            console.log(`pPrice${i}: ${this.pPriceY}`);
            this.productListForSales[i].pPrice = this.pPriceY;
          }
          else if (this.productListForSales[i].promoMethod == "A") {
              this.pPriceY =this.productListForSales[i].minPrice - this.productListForSales[i].promoRate;
            //console.log(`pPrice${i}: ${this.pPriceY}`);
            this.productListForSales[i].pPrice = this.pPriceY;
          }
        } 
        else if (this.productListForSales[i].promoStatus == "E") {
            this.pPriceE = this.productListForSales[i].minPrice;
          //console.log("pdtCode:",this.productListForSales[i].pdtNameKor,this.productListForSales[i].pdtPrice);
          this.productListForSales[i].pPrice = this.pPriceE;
        } 
        else if (this.productListForSales[i].promoStatus == "N") {
            this.pPriceN = this.productListForSales[i].minPrice;
          this.productListForSales[i].pPrice = this.pPriceN;
        }
      }
      console.log("productListForSales:",this.productListForSales);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
    // 숫자콤마표시
    // getCurrencyFormat(value) {
    //   // console.log('value' + value) 
    //   return (value+"").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  }
};
</script>