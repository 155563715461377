<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div>
          <div class="alert alert-primary fs-5 fw-bold text-center" role="alert">
            ※ 가상계좌 ※
          </div>
          <div class="text-center">
            <table class="table vAccount">
              <tr>
                <th>입금은행</th>
                <td v-if="this.payStyle==='fu' || this.payStyle === 'p1'">{{ this.res_virAccInfo[0].bankName1 }}</td>
                <td v-else-if="this.payStyle==='p2'">{{ this.res_virAccInfo[0].bankName2 }}</td>
              </tr>
              <tr>
                <th>입금계좌</th>
                <td v-if="this.payStyle==='fu' || this.payStyle === 'p1'">{{ this.res_virAccInfo[0].virAccount1 }}</td>
                <td v-else-if="this.payStyle==='p2'">{{ this.res_virAccInfo[0].virAccount1 }}</td>
              </tr>
              <tr>
                <th>입금액</th>
                <td v-if="this.payStyle==='fu' || this.payStyle === 'p1'">{{ this.getCurrencyFormat(this.res_virAccInfo[0].amount1) }}</td>
                <td v-else-if="this.payStyle==='p2'">{{ this.getCurrencyFormat(this.res_virAccInfo[0].amount2) }}</td>
              </tr>
              <tr>
                <th>입금기한</th>
                <td v-if="this.payStyle==='fu' || this.payStyle === 'p1'">{{ this.getKorDateFormat(this.res_virAccInfo[0].dueDate1) }}</td>
                <td v-else-if="this.payStyle==='p2'">{{ this.getKorDateFormat(this.res_virAccInfo[0].dueDate2) }}</td>
              </tr>
            </table>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-12 d-grid">
              <button type="button" class="btn btn-lg" style="background-color: lightblue; font-weight: 600;" @click="goToMain()">확인</button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
//import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  data() {
    return {
      res_virAccInfo: []      //가상계좌정보
    };
  },
  created() {
    this.user = this.$store.state.user;

    if (this.$store.state.user.handlerId != undefined)
    {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined)
    {
      this.userInfo = this.$store.state.user.email;
    }
    else
    {
      this.userInfo = "";
    }
    let resCode = this.$route.params.oId
    this.payStyle = this.$route.params.pStyle

    this.getVirAccInfo(resCode, this.payStyle)
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods:
  {
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },

    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getKorDateFormat(date)
    {
      return this.$korDateFormat(date);
    },

    //가상계좌표시용
    async getVirAccInfo(rCode, pStyle){
      let res_virAccInfo = await this.$api("/api/sel_virAccInfoByResCode", { param: [ rCode, pStyle] })
      if(res_virAccInfo.length > 0)
      {
        this.res_virAccInfo = res_virAccInfo
      }
      //console.log(this.res_virAccInfo)
    },

    goToMain(){
      this.$router.push({ path: "/" })
    }
  }
}
</script>

<style>
.vAccount th {
  background-color: lightgray;
  width: 40%;
  height: 45px;
  text-align: center;
  border: 2px solid white;
}
.vAccount td {
  text-align: center;
  font-weight: 600;
  text-align: start;
  padding-left: 15px;
}
</style>