<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>공지상세</u></h2>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">공지제목<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_nTitle" v-model="this.notice.nTitle">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">공지내용<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:150px" 
            v-model="this.notice.nContent"></textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">공지기간<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <!-- <date-picker class="form-control" v-model="this.notice.nFrom" ref="txt_fromDate" type="date" lang="en"></date-picker> -->
            <input type="date" class="form-control" v-model="this.notice.nFrom" ref="txt_fromDate">
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" v-model="this.notice.nTo" ref="txt_endDate">
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">바로가기</label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_nTitle" v-model="this.notice.link" disabled>
        </div>
      </div>      
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">
          이미지
          <p class="text-secondary mt-1" style="font-size: small"> 이미지제외&nbsp;{{ this.notice.addImageFlag }}
            <input class="form-check-input my-auto align-bottom" type="checkbox" v-model="this.notice.addImageFlag" true-value="N" false-value="Y" id="chb_addItemFlag">
          </p>
        </label>
        <div class="col-md-9">
          <div class="col">
            <div class="form-control" type="file" accept="image/jpg">
              <div class="position-relative">
                <img v-if="this.notice.addImageFlag === 'Y'" :src="`/download/notice/${this.notice.ctCd}/${this.notice.noticeCode}.jpg`" class="img-fluid" />
                <div>
                  <p class="position-absolute top-0 end-70" style="cursor:pointer;" @click="sealingImage(this.noticeCode, 'N')">
                    <i class="bi bi-eye-slash text-warning fs-3"></i></p>
                </div>                
              </div>
              <!-- <input type="file" class="form-control" accept="image/png,image/jpeg" @change="uploadFile($event.target.files, this.notice.ctCd, this.notice.noticeCode)"> -->
              <input type="file" class="form-control" id="fileSelector" accept="image/png,image/jpeg">
            </div>
            <div class="alert alert-secondary" role="alert" style="font-size:small;">
              <div>File 확장자: jpg</div>
            </div>
          </div>
        </div>
      </div>      

      <div class="mb-3 row">
        <div class="col-auto d-grid p-1" v-if="this.notice.nStatus != 'N'">
          <button type="button" class="btn btn-lg btn-success" @click="noticeUpdate();">변경</button>
        </div>
        <div class="col-auto d-grid p-1" v-else>
          <button type="button" class="btn btn-lg btn-success" disabled>변경</button>
        </div>
        
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList">공지리스트</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>
    </div>
  </main>

</template>

<script>
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      notice: {},
      showImage: "" //이미지표시여부
      // notice: {
      //   nTitle: "",
      //   nContent: "",
      //   nFrom: "",
      //   nTo: "",
      //   insDate: "",
      //   insId: "",
      //   nUseFlag: "" //공지상태 default: 비공지
      // }
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    //this.seqNotice = this.$route.query.seqNotice;
    this.noticeCode = this.$route.query.seqNotice;
    //console.log("seqNotice:", this.seqNotice);
    this.getNoticeDetail();
  },  
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async getNoticeDetail() {
      let nDetail = await this.$api("/api/noticeDetail", {param: [this.noticeCode]});
      if(nDetail.length > 0) {
        this.notice = nDetail[0];
      }
      this.notice.nFrom = this.getDateFormat(this.notice.nFrom);
      this.notice.nTo = this.getDateFormat(this.notice.nTo);
      if(this.notice.status === "Y") {
        this.notice.nStatus = "Y";
        //alert("수정불가")
      }
      console.log("notice:",this.notice)
    },
    goToList() 
    {
      this.$router.push({path: '/noticeList'});
    },
    // changePdtCategory() {
    //   this.catCode = this.productInfo.catCode;
    // },
    async noticeUpdate()
    {
      //오늘날짜추출
      // const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      // const time = new Date().toTimeString().split(" ")[0];
      // let today = date + ' ' + time;
      // console.log("today:", date + ' ' + time);

      if(!this.notice.nTitle) {
        this.$refs.txt_nTitle.focus();
        return this.$swal.fire("필수누락: 공지제목");
      }
      if(!this.notice.nContent) {
        this.$refs.txt_nContent.focus();
        return this.$swal.fire("필수누락: 공지내용");
      }
      if(!this.notice.nFrom) {
        this.$refs.txt_fromDate.focus();
        return this.$swal.fire("필수누락: 개시일");
      }
      if(!this.notice.nTo) {
        this.$refs.txt_toDate.focus();
        return this.$swal.fire("필수누락: 종료일");
      }
      if(this.notice.nStart > this.notice.nEnd) {
        return this.$swal.fire("입력오류: 개시일&종료일");
      }
      this.notice.nFrom = moment(this.notice.nFrom).format("YYYY-MM-DD");
      this.notice.nTo = moment(this.notice.nTo).format("YYYY-MM-DD");
      //this.notice.updDate = today;
      this.notice.updId = this.user.handlerId;
      //this.notice.nUseFlag = "N";
      console.log("this.notice:", this.notice);

      let imgFile = document.getElementById('fileSelector').files;
      console.log("imgFile:",imgFile)

      let addImgFlag = ""
      
      //이미지변경 발생
      if(imgFile.length > 0 || this.notice.addImageFlag === 'Y')
      {
        addImgFlag = "Y"
      }
      else if(this.notice.addImageFlag == "N" && imgFile.length < 1)
      {
        addImgFlag = "N"
      }
      console.log("addImgFlag:",addImgFlag)

      // let resUpd_notice = await this.$api("/api/noticeUpdate", {param: [this.notice.nTitle, this.notice.nContent, this.notice.nFrom, 
      //   this.notice.nTo, this.notice.updId, this.seqNotice]});
      
      let resUpd_notice = await this.$api("/api/noticeUpdate2", {param: [
        this.notice.nTitle, this.notice.nContent, this.notice.nFrom, this.notice.nTo, this.notice.updId, addImgFlag, this.noticeCode
      ]});
      if(resUpd_notice.affectedRows>0) 
      {
        //let imgFile = document.getElementById('fileSelector').files;
        this.uploadFile(imgFile, this.notice.ctCd, this.notice.noticeCode);

        this.$swal.fire('','수정완료', 'success');
        this.$router.push({path: '/noticeList'});
      }
      else {
        this.$swal.fire('','수정실패', 'error');
        //this.$router.push({path: '/noticeList'});
      }
    },
    sealingImage(nSeqNo, flag) {
      //console.log(`id:${pdtCode}, catImg:${catImg}, imgName:${imgName}`);
      this.$swal.fire({
        text: '이미지 첨부를 취소합니까?',
        showCancelButton: true,
        confirmButtonText: `Yes`,
        cancelButtonText: `No`
      }).then(async (result) => {
        if (result.isConfirmed) 
        {
          await this.$api("/api/noticeImageSealing",{param:[this.user.handlerId, flag, nSeqNo]});
          //this.getProductImage();
          this.$swal.fire('미사용 처리', '', 'success');
        } 
      });
    },    
    /** 이미지 사본등록 */
    async uploadFile(files, id, passNo) {
      //console.log("uploadFile:",files, "ctCd:",id, "noticeCode:",passNo)
      let fileLength = 0
      let fileType_tmp = "";
      //let fileType = ""
      let licenseFile = "";
      //let passFile = "";
      let data = null;
      if (files) {
        fileLength = files[0].length;
        fileType_tmp = files[0].name.lastIndexOf(".");
        this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();
        //licenseNumber = files[0].name;
        //passFile = this.cDetail.licenseNumber+"."+this.fileType;
        licenseFile = passNo+"."+this.fileType;
        data = await this.$base64(files[0]);
      }
      console.log("files:",files, "id:",id, "File:",licenseFile,"licenseNo:",this.licenseNumber);
      //const { error } = await this.$api(`/upload/passport/${licenseFile}/${passNo}/${id}`, { data });
      const { error } = await this.$api(`/upload/notice/${id}/${licenseFile}`, { data });
      if (error) {
        return this.$swal("업로드(uploadFile) 오류발생, 재시도 필요.");
      }
    },        
  }
}
</script>