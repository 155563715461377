<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>취소/유의항목 템플릿패턴등록</u></h2>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">적용상품/분류/제목<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <!-- <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ctCd" v-model="this.pattern.pdtCat" @change="selPdtCategory(this.pattern.pdtCat)" style="width:auto;" disabled>
              <option :value="pcl.baseCode" :key=i v-for="(pcl,i) in pdtCatList">{{pcl.codeNameKor}}</option>
              <option value="" class="text-danger">미지정</option>
            </select> -->
            <input type="text" class="form-control" ref="txt_pTitle" v-model="pattern.pdtCategory" style="width:20%;" disabled>
            <select class="form-select form-select-s" aria-label=".form-select-sm" ref="sel_ctCd" v-model="this.pattern.pCat"  style="width:15%;" disabled>
              <option :value="rProcCode.baseCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.codeNameKor}}</option>
              <option value="A" class="text-danger" :style="{ color: getOptionColor('A') }">알림톡안내</option>
            </select>
            <input type="text" class="form-control" ref="txt_pTitle" v-model="pattern.pTitle" style="width:65%;">
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">표시템플릿<font class="text-danger">*</font></label>
        <div class="col-md-9" v-if="this.pattern.pCat === 'A'">
          <textarea class="form-control" placeholder="2※알림톡용은 700자 이내(중요)" ref="txt_pContent" id="floatingTextarea" style="height:250px" v-model="pattern.pContent" maxlength="700"></textarea>
        </div>
        <div class="col-md-9" v-else>
          <textarea class="form-control" placeholder="2,000자 이내" ref="txt_pContent" id="floatingTextarea" style="height:250px" v-model="pattern.pContent" maxlength="2000"></textarea>
        </div>        
      </div>

      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="patternUpdate();">변경</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList">리스트</button>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
export default {
  data() {
    return {
      pattern: {},
      patternCode: "",
      rProc: [], //요청처리단계
      //fileList: "" //선택된 이미지파일

      pdtCatList: [],       //적용상품카테고리
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() 
  {
    console.log("USER:",this.user);
    this.patternCode = this.$route.query.patternCode;
    this.getReqProcLevel();
    this.getPatternDetail();
    
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) 
    {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    async getReqProcLevel() 
    {
      //alert("param:"+param)
      let rProc = await this.$api("/api/baseCategory", {param:["spCd"]});
      if(rProc.length > 0) 
      {
        this.rProc = rProc
      }
      //console.log("rProc:",rProc);
    },
    goToList() 
    {
      this.$router.push({path: '/patternList'});
    },

    //옵션컬러처리
    getOptionColor(pCode) {
      switch(pCode) 
      {
        case 'A':  return 'red';
        default: return 'black';
      }
    },

    async getPatternDetail() {
      let pDetail = await this.$api("/api/patternDetail", {param: [this.patternCode]});
      console.log("pDetail:",pDetail);
      if(pDetail.length > 0) {
        this.pattern = pDetail[0];
      }
      if(this.pattern.status === "Y") {
        this.pattern.nStatus = "Y";
        //alert("수정불가")
      }
    },    
    async patternUpdate()
    {
      let resUpd_pattern = await this.$api("/api/patternUpdate", {param: [this.pattern.pTitle, this.pattern.pContent, this.pattern.pUseFlag, this.user.handlerId, this.pattern.pCat, this.patternCode]});
      if(resUpd_pattern.affectedRows>0) 
      {
        this.$swal.fire('','수정완료', 'success');
        location.reload();
      }
      else {
        this.$swal.fire('','수정(resUpd_pattern)실패', 'error');
        return false;
        //this.$router.push({path: '/noticeList'});
      }
    },
  }
}
</script>