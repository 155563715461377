import { createStore } from 'vuex'
import persistedstate from 'vuex-persistedstate'

const store = createStore({
  state() { // 유저정보를 받아 다른 모듈들이 접근하여 이용할 수 있게
    return {
      user: {} // mutations의 user에 들어온 정보
      // b2bTag: "",
      // b2cTag: ""
    }
  },
  mutations: { // 유저정보를 담아 state()넣어준다
    user(state, data) {
      //console.log("data:",data);
      state.user = data;
    }
    // b2bTag(state,data) {
    //   state.b2bTag = data;
    // },
    // b2cTag(state,data) {
    //   state.b2bTag = data;
    // }
  },
  plugins: [
    // paths에 값을 유지하고자 대상을 넣는다
    persistedstate({
      paths: ['user']
    })
  ]
});

// store를 export시켜준다(main.js에서 사용하기 위해)
export default store;