<template>
  <main class="mt-3">
  <div class="p-1 text-center">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center"><u>&nbsp;가 격 정 보&nbsp;</u></h2>
          <div class="text-success text-start fs-6 mb-2">
            <b>
              [ 일괄수정&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('U','B');">&nbsp;UPDATE</i> ] &nbsp;
              <i class="bi bi-exclamation-triangle-fill text-primary"></i>일별설정값을 일괄수정합니다
              </b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th scope="row" class="table-secondary col-md-1">기간지정</th>
                <td class="col-md-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">개시</span>
                    <input type="date" class="form-control" ref="txt_batchPriceStart" id="bpStart" style="font-size: small" v-model="batchPriceStart" @click="setStart()">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">종료</span>
                    <input type="date" class="form-control" ref="txt_batchPriceEnd" id="bpEnd" style="font-size: small" v-model="batchPriceEnd" @click="setEnd()">
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-1">
                  <input class="form-check-input" type="checkbox" v-model="dOw_Flag" true-value="Y" false-value="N">&nbsp;요일
                </th>
                <td class="col-md-5">
                  <div class="input-group">
                    <span class="input-group-text text-white bg-danger" style="font-size: small"><b>제외</b>&nbsp;<input class="form-check-input" type="checkbox" v-model="prUseFlagNot" true-value="Y" false-value="N"></span>
                    &nbsp;&nbsp;
                    <span class="input-group-text text-danger" style="font-size: small">일&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[0]" true-value="0" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">월&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[1]" true-value="1" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">화&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[2]" true-value="2" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">수&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[3]" true-value="3" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">목&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[4]" true-value="4" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">금&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[5]" true-value="5" false-value="N"></span>
                    <span class="input-group-text text-primary" style="font-size: small">토<input class="form-check-input" type="checkbox" v-model="dayOfWeek[6]" true-value="6" false-value="N"></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">
                  <input class="form-check-input" type="checkbox" v-model="price_Flag" true-value="Y" false-value="N">&nbsp;
                  변경금액
                </th>
                <td colspan="3">
                  <div class="input-group" v-if="this.sRoute === 'S'">
                    <span class="input-group-text">1</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice1" v-model="batchPrice[0]">
                  </div>                  
                  <div class="input-group" v-else>
                    <span class="input-group-text form-control-sm">1</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice1" v-model="batchPrice[0]" style="font-size: small; width:100px">
                    <span class="input-group-text">2</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice2" v-model="batchPrice[1]" style="font-size: small; width:100px">
                    <span class="input-group-text">3</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice3" v-model="batchPrice[2]" style="font-size: small; width:100px">
                    <span class="input-group-text">4</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice4" v-model="batchPrice[3]" style="font-size: small; width:100px">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>
              [ 일별설정 ]
              <div class="float-end mb-1">
                <!-- <button type="button" class="btn btn-sm btn-outline-warning" @click="updPriceSetflag('S')">선택일자 변경적용</button>&nbsp;&nbsp; -->
                <button type="button" class="btn btn-sm btn-outline-primary" @click="updPriceSetflag('C')">선택일자 변경금액적용</button>&nbsp;&nbsp;
                <button type="button" class="btn btn-sm btn-outline-success" @click="updPriceSetflag('A')">선택일자 판매개시</button>&nbsp;&nbsp; 
                <button type="button" class="btn btn-sm btn-outline-danger" @click="updPriceSetflag('D')">선택일자 판매중지</button>
              </div>              
            </b>
          </div>
        <table class="table table-bordered table-striped fs-6 w-100 h-auto">
          <thead class = "small">
            <tr>
              <th class="col-1">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th>
              <th class="col-md-1">적용일자</th>
              <th class="col-md-1">요일</th>
              <th class="col-md-7">권종금액</th>
              <th class="col-md-1">제외</th>
              <th class="col-md-2"></th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(prDate, i) in this.productPriceDetailCnt">
              <td>
                <input type="checkbox" v-model="prDate.checkFlag"  ref="chb_Seq" class="align-baseline mt-3" :id=i name="checkedBox">
              </td>
              <td class="text-success align-middle" v-if="getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)==='토'">
                <small class="text-primary">{{this.productPriceDetailCnt[i].prDate}}</small>
              </td>
              <td class="text-success align-middle" v-else-if="getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)==='일'">
                <small class="text-danger">{{this.productPriceDetailCnt[i].prDate}}</small>
              </td>
              <td clasas="text-success align-middle" v-else>
                <small>{{this.productPriceDetailCnt[i].prDate}}</small>
              </td>

              <td class="text-success align-middle" v-if="getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)==='토'">
                <small class="text-primary">{{getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)}}</small>
              </td>
              <td class="text-success align-middle" v-else-if="getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)==='일'">
                <small class="text-danger">{{getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)}}</small>
              </td>
              <td class="text-success align-middle" v-else>
                <small>{{getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)}}</small>
              </td>

              <td>
                <div class="input-group" v-if="this.sRoute === 'S'">
                  <span class="input-group-text">1</span>
                  <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice1" v-model="this.arr_pdtPrice1[i]">
                  <!-- <span class="input-group-text">원</span> -->
                </div>                
                <div class="input-group" v-else>
                  <span class="input-group-text text-sm">1</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice1" v-model="this.arr_pdtPrice1[i]" style="font-size: small; width:100px">
                  <span class="input-group-text text-sm">2</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice2" v-model="this.arr_pdtPrice2[i]" style="font-size: small; width:100px">
                  <span class="input-group-text text-sm">3</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice3" v-model="this.arr_pdtPrice3[i]" style="font-size: small; width:100px">
                  <span class="input-group-text text-sm">4</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice4" v-model="this.arr_pdtPrice4[i]" style="font-size: small; width:100px">
                </div>
              </td>
              <td class="align-middle">
                <input class="form-check-input" type="checkbox" v-model="this.priceUseFlag[i]" true-value="1" false-value="0" id="pUseFlag">
              </td>
              <td class="align-middle">
                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="updatePdtPrice(this.productId, this.productPriceDetailCnt[i].prDate, this.priceUseFlag[i], this.arr_pdtPrice1[i], this.arr_pdtPrice2[i], this.arr_pdtPrice3[i], this.arr_pdtPrice4[i])">
                  <small><small>변경</small></small>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToBack()">돌아가기</button>
      </div>
    </div>
  </div>
  
  </main>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      dayOfWeek: ["N","N","N","N","N","N","N"], //요일정보
      numberOfWeek: [], //요일숫자정보
      productInfo: {}, //상품정보
      productPrice: {}, //권종가격
      productPriceDetail: {}, //기간별 적용가격
      productPriceDetailCnt: {}, //기간별 적용가격 카운트
      dInterval: 0, //날짜수
      arr_nextDay: [],
      arr_dayOfWeek: [],
      arr_numberOfWeek: [],
      //arr_pdtPrice: [],
      arr_pdtPrice1: [],
      arr_pdtPrice2: [],
      arr_pdtPrice3: [],
      arr_pdtPrice4: [],
      priceUseFlag: [], //금액사용여부
      pdtPrice_d: [], //특수가격(상품별)

      batchPriceStart: "", //일괄변경시작
      batchPriceEnd: "",
      batchPrice: [0,0,0,0],
      arr_batchPrice: [], //일괄변경금액

      dOw_Flag: "N", //요일선택여부
      price_Flag: "N", //가격변동선택여부
      prUseFlagNot: "N", //일괄제외선택

      dpDate: "", //적용시작일
      rtDateFin: "", //적용종료일
      firstDay: "", //적용첫날
      allSelected: "N" //전체체크여부
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.reqRsvCode = this.$route.query.seqReq;
    this.productId = this.$route.query.productId;
    this.sRoute = this.$route.query.sRoute;

    this.getProductInfo();
    this.getProductPrice();
    this.getProdctPriceDetail();
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    //상세정보와 무관한 내용 비표시처리
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: 
  {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDayOfWeekFormat(text) {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text) {
      return this.$numberOfWeekFormat(text);
    },
    allChoice() 
    {
      if(this.allSelected)
      {
        this.productPriceDetailCnt.forEach((prDate) => {
          prDate.checkFlag = true;
        })
      }
      else
      {
        this.productPriceDetailCnt.forEach((prDate) => {
          prDate.checkFlag = false;
        })
      }
    },
    //선택일자 상태변경
    async updPriceSetflag(flag)
    {
      let selectedDate = [];
      this.productPriceDetailCnt.forEach((prDate) => {
        if(prDate.checkFlag)
        {
          selectedDate.push(prDate.prDate)
        }
      })
      //console.log("selectedCount:",selectedDate.length, ",selectedDate:",selectedDate)

      //체크박스의 아이디
      //let checkBox = document.querySelectorAll('input[type="checkbox"]')
      let checkBox = document.getElementsByName('checkedBox')
      //체크된 박스의 아이디들
      //let checkedIds = [];
      let selectedDatePrice = []

      for (var i = 0; i < checkBox.length; i++) 
      {
        //let checkBoxes = checkBox[i];
        if (checkBox[i].checked)
        //if(checkBoxes.id.endsWith('i') && checkBoxes.checked)
        {
          //checkedIds.push(checkBox[i].id);
          selectedDatePrice.push([
            this.productPriceDetailCnt[checkBox[i].id].prDate, this.arr_pdtPrice1[checkBox[i].id], this.arr_pdtPrice2[checkBox[i].id], this.arr_pdtPrice3[checkBox[i].id], this.arr_pdtPrice4[checkBox[i].id], this.priceUseFlag[checkBox[i].id]
          ])
        }
      }
      //console.log(checkedIds);
      console.log("selectedDatePrice:",selectedDatePrice);

      //판매중지처리
      if(flag === "D")
      {
        this.$swal.fire({
        html: '선택한 날짜들의 판매를 중지합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let updPriceSellFlag = await this.$api("/api/updPriceSellFlag", {param: [ '1',this.user.handlerId,this.productId,selectedDate[i]]});
              if(updPriceSellFlag.affectedRows < 0 || updPriceSellFlag.affectedRows === undefined)
              {
                res = "error"
                break;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","삭제 중 에러가 발생했습니다.","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","선택일자가 판매중지로 변경되었습니다.","success");
              location.reload(true)
            }
          } 
        });
      }
      //판매개시처리
      else if(flag === "A")
      {
        this.$swal.fire({
        html: '선택한 날짜들의 판매를 개시합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let updPriceSellFlag = await this.$api("/api/updPriceSellFlag", {param: [ '0',this.user.handlerId,this.productId,selectedDate[i]]});
              if(updPriceSellFlag.affectedRows < 0 || updPriceSellFlag.affectedRows === undefined)
              {
                res = "error"
                break;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","삭제 중 에러가 발생했습니다.","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","선택일자가 판매개시로 변경되었습니다.","success");
              location.reload(true)
            }
          } 
        });
      }
      //판매가격변경처리
      else if(flag === "C")
      {
        let arr_batchPrice = [this.batchPrice[0],this.batchPrice[1],this.batchPrice[2],this.batchPrice[3]];

        this.$swal.fire({
        html: '선택한 날짜들의 판매가격을 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let j = 0;
              //권종별 금액업데이트
              while(j < arr_batchPrice.length)
              {
                let updateCheckedPrice = await this.$api("/api/updateCheckedPrice", {param: [ 
                  arr_batchPrice[j],this.user.handlerId,this.productId,selectedDate[i], j+1
                ]});
                if(updateCheckedPrice.affectedRows < 0 || updateCheckedPrice.affectedRows === undefined)
                {
                  res = "error"
                  break;
                }
                j++;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","금액 업데이트 중 에러가 발생했습니다(코드: updateCheckedPrice)","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","선택일자가 판매가격이 변경되었습니다","success");
              location.reload(true)
            }
          } 
        });
      }
      //선택일자 변경내용 일괄적용
      else if(flag === "S")
      {
        let arr_batchPrice = [this.batchPrice[0],this.batchPrice[1],this.batchPrice[2],this.batchPrice[3]];

        this.$swal.fire({
        html: '선택한 날짜들의 변경된 내용을 적용합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let j = 0;
              //권종별 금액업데이트
              while(j < arr_batchPrice.length)
              {
                let updateCheckedPrice = await this.$api("/api/updateCheckedPrice", {param: [ 
                  arr_batchPrice[j],this.user.handlerId,this.productId,selectedDate[i], j+1
                ]});
                if(updateCheckedPrice.affectedRows < 0 || updateCheckedPrice.affectedRows === undefined)
                {
                  res = "error"
                  break;
                }
                j++;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","금액 업데이트 중 에러가 발생했습니다(코드: updateCheckedPrice)","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","선택일자가 판매가격이 변경되었습니다","success");
              //location.reload(true)
            }
          } 
        });
      }
    },    
    async getProductInfo() {
      this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"

      let productInfo = {};
      if(this.pdtFlag === "SG") {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      else if(this.pdtFlag === "MX") {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else {
        return this.$swal.fire("","상품이 검색되지 않습니다<br>재확인 후 시도해 주세요.","error");
      }
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      //console.log("productInfo:",this.productInfo);
    },
    async getProductPrice()
    {
      let productPrice = await this.$api("/api/productPrice", {param: [this.productId]});
      this.productPrice = productPrice;
      //console.log("productPrice:",productPrice)

      //세팅된 날짜의 시작~끝일
      let minMaxDate = await this.$api("/api/getMinMaxDate", {param: [this.productId]});

      this.firstDay = this.getDateFormat(minMaxDate[0].startDate);
      this.rtDateFin = this.getDateFormat(minMaxDate[0].endDate);
      //console.log("firstDay:", this.firstDay, "rtDateFin:", this.rtDateFin)
    },
/** 특수가격 스타일로 관리할 경우 로직 -> 지나치게 복잡해서 적용안함
    async getProductPrice() {
      let productPrice = await this.$api("/api/productPrice", {param: [this.productId]});
      this.productPrice = productPrice;
      //console.log("productPrice:",this.productPrice);

      let pdtPrice1 = ""
      let pdtPrice2 = ""
      let pdtPrice3 = ""
      let pdtPrice4 = ""

      if(this.sRoute === "S") {
        pdtPrice1 = this.productPrice[0].pdtPrice;
      }
      else {
        pdtPrice1 = this.productPrice[0].pdtPrice;
        pdtPrice2 = this.productPrice[1].pdtPrice;
        pdtPrice3 = this.productPrice[2].pdtPrice;
        pdtPrice4 = this.productPrice[3].pdtPrice;
      }


      let dpDate = new Date(this.getDateFormat(this.productPrice[0].pdtPriceStart));
      let rtDate = new Date(this.getDateFormat(this.productPrice[0].pdtPriceEnd));
      let dateDiff = dpDate.getTime() - rtDate.getTime();        
      this.dInterval = Math.abs(dateDiff / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
      //console.log("dInterval:",this.dInterval)

      let p = 0;
      let nextDay_tmp = "";
      let nextDay_tmp2 = "";
      let nextDay = "";
      let dayOfWeek = "";
      let numberOfWeek = "";
      let arr_nextDay = [];
      let arr_dayOfWeek = [];
      let arr_numberOfWeek = [];
      let arr_pdtPrice1 = [];
      let arr_pdtPrice2 = [];
      let arr_pdtPrice3 = [];
      let arr_pdtPrice4 = [];
      let arr_priceUseFlag = [];
      let firstDay = this.getDateFormat(dpDate.toISOString());

      this.dpDate = dpDate;
      this.rtDateFin = this.getDateFormat(rtDate.toISOString());
      this.firstDay = firstDay;
      //console.log("rtDate:",this.rtDateFin);

      //특별요금 존재여부체크
      let pdtPrice_d = await this.$api("/api/checkPdtPriceDetail", {param: [this.productId, dpDate, rtDate]});
      this.pdtPrice_d = pdtPrice_d;
      //console.log("pdtPrice_d:",this.pdtPrice_d);

      while(p <= this.dInterval) {
        nextDay_tmp = dpDate.setDate(dpDate.getDate()+1);
        nextDay_tmp2 = new Date(nextDay_tmp);
        nextDay = this.getDateFormat(nextDay_tmp2.toISOString())

        //기본가격을 대체할 특별가격추출
        let spPrice = pdtPrice_d.filter(e => {return e.prDate === nextDay})
        //console.log("spPrice:",spPrice);

        if(spPrice.length > 0) {
          if(this.sRoute === "S") {
            arr_pdtPrice1.push(spPrice[0].pdtPrice);
            arr_priceUseFlag.push(spPrice[0].useFlag)
          }
          else {
            arr_pdtPrice1.push(spPrice[0].pdtPrice);
            arr_pdtPrice2.push(spPrice[1].pdtPrice);
            arr_pdtPrice3.push(spPrice[2].pdtPrice);
            arr_pdtPrice4.push(spPrice[3].pdtPrice);
          }
          // arr_pdtPrice1.push(spPrice[0].pdtPrice);
          // arr_pdtPrice2.push(spPrice[1].pdtPrice);
          // arr_pdtPrice3.push(spPrice[2].pdtPrice);
          // arr_pdtPrice4.push(spPrice[3].pdtPrice);
          // arr_priceUseFlag.push(spPrice[0].useFlag)
        }
        else {
          if(this.sRoute === "S") {
            arr_pdtPrice1.push(pdtPrice1);
            arr_priceUseFlag.push(productPrice.useFlag)
          }
          else {
            arr_pdtPrice1.push(pdtPrice1);
            arr_pdtPrice2.push(pdtPrice2);
            arr_pdtPrice3.push(pdtPrice3);
            arr_pdtPrice4.push(pdtPrice4);
            arr_priceUseFlag.push(productPrice.useFlag)
          }
          // arr_pdtPrice1.push(pdtPrice1);
          // arr_pdtPrice2.push(pdtPrice2);
          // arr_pdtPrice3.push(pdtPrice3);
          // arr_pdtPrice4.push(pdtPrice4);
          // arr_priceUseFlag.push(productPrice.useFlag)
        }
        dayOfWeek = this.getDayOfWeekFormat(nextDay);
        numberOfWeek = this.getNumberOfWeekFormat(nextDay);
        arr_nextDay.push(nextDay);
        arr_dayOfWeek.push(dayOfWeek);
        arr_numberOfWeek.push(numberOfWeek);
        p++;
      }

      //가격설정된 기간정보
      arr_nextDay.unshift(firstDay);
      arr_dayOfWeek.unshift(this.getDayOfWeekFormat(firstDay));
      arr_numberOfWeek.unshift(this.getNumberOfWeekFormat(firstDay));
      arr_pdtPrice1.unshift(pdtPrice1);
      arr_pdtPrice2.unshift(pdtPrice2);
      arr_pdtPrice3.unshift(pdtPrice3);
      arr_pdtPrice4.unshift(pdtPrice4);
      arr_priceUseFlag.unshift(this.productPrice.useFlag);
      //console.log("day:",arr_nextDay,"dOw:", arr_dayOfWeek);
      this.arr_nextDay = arr_nextDay;
      this.arr_dayOfWeek = arr_dayOfWeek;
      this.arr_numberOfWeek = arr_numberOfWeek;
      this.arr_pdtPrice1 = arr_pdtPrice1
      this.arr_pdtPrice2 = arr_pdtPrice2
      this.arr_pdtPrice3 = arr_pdtPrice3
      this.arr_pdtPrice4 = arr_pdtPrice4
      this.priceUseFlag = arr_priceUseFlag;
      //console.log("day:",this.arr_nextDay,"dOw:", this.arr_dayOfWeek,"nOw:",this.numberOfWeek, "price:", this.arr_pdtPrice1, "useFlag:",this.priceUseFlag);
    },
*/
    //기간별 적용가격정보
    async getProdctPriceDetail() {
      //일자+요일별 금액
      let productPriceDetailCnt = await this.$api("/api/productPriceDetailCnt", {param: [this.productId]});
      let productPriceDetail = await this.$api("/api/productPriceDetail", {param: [this.productId]});
      this.productPriceDetailCnt = productPriceDetailCnt;
      this.productPriceDetail = productPriceDetail;
      //console.log("productPriceDetailPrice:",productPriceDetail);
      //console.log("productPriceDetailCnt:",productPriceDetailCnt);

      let arr_pdtPrice1 = [];
      let arr_pdtPrice2 = [];
      let arr_pdtPrice3 = [];
      let arr_pdtPrice4 = [];
      let arr_priceUseFlag = [];

      this.arr_pdtPrice1 = arr_pdtPrice1
      this.arr_pdtPrice2 = arr_pdtPrice2
      this.arr_pdtPrice3 = arr_pdtPrice3
      this.arr_pdtPrice4 = arr_pdtPrice4
      this.priceUseFlag = arr_priceUseFlag;
      
      if(this.productPriceDetailCnt.length < 1)
      {
        this.$swal.fire("","설정된 금액이 없습니다","info");
        history.back();
      }
      else {
        let p = 0;
        while(p < this.productPriceDetailCnt.length) 
        {
          //console.log("P:",p,"/productPriceDetailCnt:",productPriceDetailCnt[p].prDate);
          let spPrice = this.productPriceDetail.filter(e => {return e.prDate === this.productPriceDetailCnt[p].prDate})
          //console.log("spPrice:",spPrice);

          if(this.sRoute === "S") {
              arr_pdtPrice1.push(spPrice[0].pdtPrice);
              arr_priceUseFlag.push(spPrice[0].useFlag)
          }
          else 
          {
            arr_pdtPrice1.push(spPrice[0].pdtPrice);
            arr_pdtPrice2.push(spPrice[1].pdtPrice);
            arr_pdtPrice3.push(spPrice[2].pdtPrice);
            arr_pdtPrice4.push(spPrice[3].pdtPrice);
            arr_priceUseFlag.push(spPrice[0].useFlag);
          }
          p++;
        }
      }
      //console.log("arr_pdtPrice1:",arr_priceUseFlag);
    },

    async updatePdtPrice(pCode, pDate, pUse, p1, p2, p3, p4) {
      // console.log("pCode:",pCode, "pDate:",pDate, "pUse:",pUse, p1, p2, p3, p4);
      let dubCheck = [];
      let dubCheckFlag = "";
      dubCheck = this.productPriceDetail.filter(e => {return e.prDate === pDate});
      dubCheckFlag = (dubCheck.length > 0) ? "Y" : "N"
      
      let arr_p = [p1, p2, p3, p4]
      let prUse = (pUse != undefined) ? pUse : "0";
      let pDow = this.getNumberOfWeekFormat(pDate);

      //console.log("dubCheckFlag:",dubCheckFlag,"/pDow:",pDow);

      //기존 특수가격의 존재에 따라 ins / upd를 진행
      if(dubCheckFlag === "N") {
        let timeStamp = "";
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = today.getMonth()+1;
        let dd = today.getDate();
        let ymd = `${yyyy}${mm}${dd}`
        timeStamp = today.getTime(); //가격코드에 들어갈 타임스탬프

        let prcCode = "P"+this.productId.charAt(0)+"-"+ymd+timeStamp; //기본가격과는 다른 가격을 위한 고유코드
        //alert("prcCode:" + prcCode);

        let a = 0
        while(a < 4) {
          let resIns_pdtPrice_d = await this.$api("/api/priceDetailInsert", {param: [this.productId, prcCode, a+1, arr_p[a], pDow, pDate, prUse, this.user.handlerId]});
          //console.log("errCheck:", Object.keys(resIns_pdtPrice_d)[0]);
          if(Object.keys(resIns_pdtPrice_d)[0] === "error") {
            this.$swal.fire("","저장오류","error");
            return false;
          }
          a++;
        }
      }
      else {
        let b = 0
        while(b < 4) {
          let resIns_pdtPrice_d = await this.$api("/api/priceDetailUpdate", {param: [arr_p[b], prUse,  this.user.handlerId, this.productId, pDate, b+1]});
            if(resIns_pdtPrice_d.affectedRows < 0 || resIns_pdtPrice_d.affectedRows === undefined){
              this.$swal.fire('', '상품기본정보 업데이트 오류', 'error');
              return false;
            }
          b++;
        }
      }
      location.reload();
    },
    setStart(){
      //this.batchPriceStart = "";
      document.getElementById("bpStart").setAttribute("min", this.firstDay);
      document.getElementById("bpStart").setAttribute("max", this.rtDateFin);
    },
    setEnd(){
      //this.batchPriceEnd = "";
      document.getElementById("bpEnd").setAttribute("min", this.firstDay);
      document.getElementById("bpEnd").setAttribute("max", this.rtDateFin);
    },
    goToBack() {
      //this.$router.push({path: '/reserveInfoList'});
      history.back();
    },
    //일괄가격설정
    dataBaseTransaction(p1, p2)
    {
      //this.productInfo.modId = this.user.handlerId;
      let useFlag = "";
      //let weekNumber = ["0","1","2","3","4","5","6"];

      //상품가격 일괄변경용 배열
      if(this.price_Flag === "Y")
      {
        if(this.sRoute === "S")
        {
          this.arr_batchPrice = [this.batchPrice[0]];
        }
        else
        {
          this.arr_batchPrice = [this.batchPrice[0],this.batchPrice[1],this.batchPrice[2],this.batchPrice[3]];
        }
        //console.log("arr_batchPrice:",this.arr_batchPrice);
      }
      
      if(p1 === "U" && p2 ==="B") 
      {
        let diffDate = this.batchPriceStart.replace(/-/g, "") - this.batchPriceEnd.replace(/-/g,"")
        if(diffDate > 0 )
        {
          return this.$swal.fire('', '시작일은 종료일보다 이전이어야 합니다.', 'error')
        }

        if(!this.batchPriceStart || !this.batchPriceEnd ) {
          return this.$swal.fire('', '적용기간을 입력하세요', 'question')
        }

        //선택된 요일체크
        let arr_selDoW = [];
          this.dayOfWeek.forEach((array) => 
          {
            if(array != "N") {
              arr_selDoW.push(array)
            }
          });
        //console.log("selDoW:", arr_selDoW,"/string:", arr_selDoW.toString());

        this.$swal.fire({
        icon: 'question',
        text: '정보를 변경하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            //요일일괄처리루틴
            if(this.price_Flag === "N" && this.dOw_Flag === "Y")
            {
              //일괄제외or취소처리
              if(this.prUseFlagNot === "Y")
              {
                useFlag = "1";
                this.$swal.fire('', '선택요일은 가격적용에서 제외되어 판매되지 않습니다.', 'info');
              }
              else 
              {
                useFlag = "0";
                this.$swal.fire('', '지정기간의 요일제외가 취소됩니다.', 'info');
              }
              let i = 0
                while(i < arr_selDoW.length) 
                {
                  console.log(arr_selDoW[i]);
                  let resUpd_DoWStatus = await this.$api("/api/setNotUseDoWStatus", {param: [useFlag, this.user.handlerId,this.productId, arr_selDoW[i], this.batchPriceStart, this.batchPriceEnd]});
                  //console.log("resUpd_DoWStatus:", resUpd_DoWStatus);
                  if(resUpd_DoWStatus.affectedRows < 0 || resUpd_DoWStatus.affectedRows === undefined)
                  {
                    //this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                    alert("업데이트 중, 오류가 발생했습니다.");
                    return false;
                  }
                  i++;
                }
                location.reload();              
            }
            else if(this.price_Flag === "Y" && this.dOw_Flag === "Y")
            {
              if(this.prUseFlagNot === "Y") 
              {
                return this.$swal.fire('', '요일제외가 선택되어 있습니다. <br> 제외체크 해지 후, 재시도해 주세요.', 'warning');
              }
              else
              {
                if(this.sRoute === "S")
                {
                  //useFlag = (this.prUseFlagNot === "Y") ? "0" : "1"

                  let i = 0;
                  while(i < arr_selDoW.length)
                  {
                    let updatePricesAndDoW = await this.$api("/api/updatePricesAndDoW", {param: [this.arr_batchPrice[0], this.user.handlerId, this.productId, this.batchPriceStart, this.batchPriceEnd, arr_selDoW[i], "1"]});
                    //console.log("updatePricesAndDoW:", updatePricesAndDoW);
                    if(updatePricesAndDoW.affectedRows < 0 || updatePricesAndDoW.affectedRows === undefined)
                    {
                      //this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                      alert("업데이트 중, 오류가 발생했습니다.");
                      return false;
                    }
                    i++;
                  }
                  this.$swal.fire('', '변경되었습니다.', 'success');
                  location.reload();
                }
                //결합상품의 기간+요일 상품가격 일괄변경
                else
                {
                  let j = 0;
                  while(j < arr_selDoW.length)
                  {
                    let i = 0;
                    while(i < 4)
                    {
                      let updatePricesAndDoW = await this.$api("/api/updatePricesAndDoW", {param: [this.arr_batchPrice[i], this.user.handlerId, this.productId, this.batchPriceStart, this.batchPriceEnd, arr_selDoW[j], i+1]});
                      if(updatePricesAndDoW.affectedRows < 0 || updatePricesAndDoW.affectedRows === undefined)
                      {
                        alert("업데이트 중, 오류가 발생했습니다.");
                        return false;
                      }
                      i++;
                    }
                    j++;
                  }
                  this.$swal.fire('', '변경되었습니다.', 'success');
                  location.reload();
                }              
              }
            }
            else if(this.price_Flag === "Y" && this.dOw_Flag === "N")
            {
              this.$swal.fire('', '지정기간의 상품가격을 변경합니다.', 'info');
              if(this.sRoute === "S") 
              {
                //기간 중 비용일괄변경(단일가 상품)
                let resUpd_Prices = await this.$api("/api/updatePrices", {param: [this.arr_batchPrice[0], this.user.handlerId, this.productId, "1", this.batchPriceStart, this.batchPriceEnd]});
                  //console.log("resUpd_Prices:", resUpd_Prices);
                  if(resUpd_Prices.affectedRows < 0 || resUpd_Prices.affectedRows === undefined)
                  {
                    this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                    //alert("업데이트 중, 오류가 발생했습니다.");
                    return false;
                  }
                  else
                  {
                    this.$swal.fire('', '변경되었습니다.', 'success');
                    location.reload();
                  }
              }
              else
              {
                //기간 중 비용일괄변경(다중가상품)
                let j=0
                while(j < 4)
                {
                  let resUpd_Prices = await this.$api("/api/updatePrices", {param: [this.arr_batchPrice[j], this.user.handlerId, this.productId, j+1, this.batchPriceStart, this.batchPriceEnd]});
                  console.log("resUpd_Prices:", resUpd_Prices);
                  if(resUpd_Prices.affectedRows < 0 || resUpd_Prices.affectedRows === undefined)
                  {
                    //this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                    alert("업데이트 중, 오류가 발생했습니다.");
                    return false;
                  }
                  j++;
                }
                this.$swal.fire('', '변경되었습니다.', 'success');
                  location.reload();
              }
            }
          }
        })
      }
    }
  }
}
</script>