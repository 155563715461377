<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="card mx-auto" style="width: 18rem;">
          <!-- <img src="/../IMGs/login_.jpg" class="card-img-top"> -->
          <div class="card-body">
            <h5 class="card-title">테스터 로그인</h5>
            <form>
              <div class="form-floating mt-3">
                <input type="text" class="form-control" placeholder="id" v-model="bId" ref="txt_bId">
                <label for="floatingInput">아이디</label>
              </div>
              <div class="form-floating mt-2">
                <input type="password" class="form-control" id="floatingPassword" placeholder="Password" v-model="bPass" ref="txt_bPass">
                <label for="floatingPassword">비밀번호</label>
              </div>
              <button class="w-100 btn btn-lg btn-primary mt-3" type="button" ref="enterBtn" @click="loginCheck()">Log-In</button>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import moment from 'moment';
export default {
  data() {
    return {
      loginInfo: [],
    }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },
  mounted() {
    document.addEventListener("keydown",this.handleEnterKeyPress);
  },
  beforeUnmount() {
    document.removeEventListener("keydown",this.handleEnterKeyPress);
  },
  methods: {
    handleEnterKeyPress(event)
    {
      if(event.keyCode === 13)
      {
        this.$refs.enterBtn.click();
      }
    },
    async loginCheck() 
    {
      //let swal = require('sweetalert2');
      let loginInfo = await this.$api("/api/checkLoginTester", {param: [this.bId]});
      // if(loginInfo.length > 0)
      // {
      //   this.loginInfo = loginInfo;
      // }
      console.log("loginInfo.length:", loginInfo.length)
      
      if(loginInfo.length <= 0) {
        this.$refs.txt_bId.select();
        this.$swal.fire('', '아이디를 확인해주십시오.', 'warning');
        return false;  
        //var swal = require('sweetalert2');
        // swal.fire({
        //   text: 'ID를 확인해 주세요',
        //   icon: 'error'
        // });
        // this.$router.push({path: '/'});
      }
      else{
        loginInfo.forEach(loginInfo=>{
          if(loginInfo.uPass === this.bPass) 
          {
            let logAccount = {
              email: loginInfo.uEmail, 
              //nickname: kakao_account.profile.nickname,
              nickname: loginInfo.uNickName,
              joinFlag: loginInfo.joinFlag,
              phone: loginInfo.uPhone,
              alimCallC: loginInfo.alimCallC,
              birthday: loginInfo.uBirthday,
              gender: loginInfo.uGender
            }

            // 자유가입회원 또는 테스터 로그인 & 세션 처리
            this.$store.commit("user", logAccount);
              this.$swal.fire({
                html: logAccount.nickname + '님, 환영합니다!',
                imageUrl: '/IMGs/loginout/welcome.png',
                imageWidth: 300,
                imageHeight: 150,
                imageAlt: 'WelcomeImage',
              })
              //this.$router.back();
              this.$router.push({path: '/'});
          }
          else {
            this.$refs.txt_bPass.select();
            this.$swal.fire('', '비밀번호를 확인해주십시오.', 'warning');
            return false;  
            // swal.fire({
            //   text: 'Password를 확인해주세요',
            //   icon: 'error'
            // });
          }
        })
      }
    }
  }
}
</script>