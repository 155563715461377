<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="alert alert-danger" role="alert">
          ※ ResultCallback Page입니다 ※
        </div>
        <div>
          <h1>결제 완료</h1>
          <p>{{ paymentInfo }}</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
//import axios from 'axios'

export default {
  data() {
    return {
      paymentInfo: null
    };
  },
  created() {
    //alert("SUCCESS");
    this.user = this.$store.state.user;

    if (this.$store.state.user.handlerId != undefined) {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined) {
      this.userInfo = this.$store.state.user.email;
    }
    else {
      this.userInfo = "";
    }
    console.log("userInfo:", this.userInfo);

    this.$api("api/tossPay/callback", 
        {
          param: {
          // "status": "PAY_COMPLETE",
          // "payToken": payToken,
          // "orderNo": orderNo,
          // "payMethod": payMethod,
          // "amount": amount,
          // //"discountedAmount": 600,
          // "paidAmount": paidAmount,
          // "paidTs": paidTs,
          // "transactionId" : transactionId,
          // "cardCompanyCode": cardCompanyCode,
          // "cardAuthorizationNo": cardAuthorizationNo,
          // "spreadOut": 0,
          // "noInterest": false,
          // "cardMethodType": "CREDIT",
          // "salesCheckLinkUrl": "https://pay.toss.im/payfront/web/external/sales-check?payToken=example-payToken&transactionId=2da1ca05-d91d-410f-976d-7a610242da8a"
          }
        })
        .then((res) => {
          console.log(res);
          //window.location.href = res.checkoutPage;
        })
        .catch((err) => {
          console.error(err);
        });    
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    // this.paymentInfo = this.$route.query; // 결제 정보를 받아옵니다.
    // console.log(this.paymentInfo); // 결제 정보를 콘솔에 출력합니다.

  },
  // unmounted() {
  //   this.$el.removeEventListener('click', this.test());
  // },
  
  methods: 
  {
    // async changeFlag(orderNo)
    // {
    //   //return await this.$api("/api/getReqPayInfo", {param: [this.reqRsvCode]});
    //   let updCheckFlag = await this.$api("/api/updCheckFlag", {param: ['Y', this.userInfo, oId]});
    //   if(updCheckFlag.affectedRows > 0)
    //   {
    //     this.$swal.fire('','결제되었습니다. 감사합니다,', 'success');
    //     //location.reload();
    //   }
    //   else 
    //   {
    //     this.$swal.fire('','결제확인(updCheckFlag)이 되지 않습니다.<br>문의처(1660-4602)에 확인을 요청해주세요.', 'error')
    //   }
    // },    
  }
}
</script>