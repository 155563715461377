<template>
  <div id="evex">
    <!-- 카로셀 -->
    <div id="carousel-evex" class="carousel slide front" data-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carousel-evex" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carousel-evex" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carousel-evex" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <!-- <button type="button" data-bs-target="#carousel-evex" data-bs-slide-to="3" aria-label="Slide 4"></button> -->
      </div>

      <div class="carousel-inner">
        <!-- <div class="carousel-item active">
          <a href="/detail?productId=L-2024620-1718861928872&salesRoute=L">
            <img src="../assets/img/new-test/baseball.jpg" class="exmain d-block vw-100 vh-100" alt="slide-img-1">
          </a>
        </div> -->

        <div class="carousel-item active">
          <img src="../assets/img/main/ure.jpg" class="exmain d-block vw-100 vh-100 soldout" alt="slide-img-1">
          <div class="carousel-caption cimg justify-content-center">
            <img src="../assets/img/main/sold_out_pc.png" class="img-fluid mx-auto pc">
            <img src="../assets/img/main/sold_out_m.png" class="img-fluid mx-auto mobile">
          </div>
        </div>
        <div class="carousel-item">
          <img src="../assets/img/main/mongol.jpg" class="exmain d-block vw-100 vh-100 soldout" alt="slide-img-1">
          <div class="carousel-caption cimg justify-content-center">
            <img src="../assets/img/main/sold_out_pc.png" class="img-fluid mx-auto pc">
            <img src="../assets/img/main/sold_out_m.png" class="img-fluid mx-auto mobile">
          </div>
        </div>
        <div class="carousel-item">
          <img src="../assets/img/main/ucc.jpg" class="exmain d-block vw-100 vh-100 soldout" alt="slide-img-1">
          <div class="carousel-caption cimg justify-content-center">
            <img src="../assets/img/main/ucc_pc2.png" class="img-fluid mx-auto pc">
            <img src="../assets/img/main/ucc_m2.png" class="img-fluid mx-auto mobile">
          </div>
        </div>
      </div>

      <button class="carousel-control-prev" type="button" data-bs-target="#carousel-evex" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carousel-evex" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- 설명 -->
    <section class="evex-container">
      <div class="container">
        <div class="row gx-0">
          <div class="col-lg-12 pt-1">
            <img src="../assets/img/Variety.png">
            <p class="mt-2">일반적인 패키지 여행이 아닌 시즌별, 기간별 한정 이벤트여행, 기획여행, 테마여행 상품 브랜드 입니다.</p>
          </div>
        </div>
      </div>
    </section>   

    <!-- 상품 -->
    <section class="evex-section">
      <div class="container">
        <div class="col-12" :key="i" v-for="(product, i) in this.productListForSales">
          <div class="position-relative item">
            <a class="evexitem" href="javascript: void(0)" @click='goToDetail(product.pdtCode,product.salesRoute)' style="cursor: pointer">     
              <div class="row col-12 mb-5">
                <!-- 사진 -->
                <div class="col-lg-7 pic">   
                  <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..."/>
                  <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>

                  <div class="badge">
                    <div v-if="product.badgeFlag === 'Y'">
                      <div class="badge1">
                        <small class="badge1-text">{{ product.badge }}</small>
                      </div>
                    </div>
                    <div v-if="product.badge2Flag === 'Y'">
                      <div v-if="product.badgeFlag != 'Y'" class="badge1">
                        <small class="badge1-text">{{ product.badge2 }}</small>
                      </div>
                      <div v-else class="badge2" style="margin-left: 180px;">
                        <small class="badge2-text">{{ product.badge2 }}</small>
                      </div>
                    </div>
                  </div>                  
                </div>

                <!-- 내용 -->
                <div class="col-lg-5 einfo align-self-center">
                  <div class="text mt-2 text-dark">
                    <div class="departure">
                      <small v-if="product.trCd1 == 'FLT'">
                        <small>[{{product.cityName}}출발항공]</small>
                      </small>
                      <small v-else-if="product.trCd1 == 'SHP'">
                        <small>[{{product.cityName}}출발선박]</small>
                      </small>
                      <small v-else-if="product.trCd1 == 'BUS'">
                        <small>[현지출발]</small>
                      </small>
                    </div>
                    <h4 class="title">{{ product.pdtNameKor }}</h4>
                    <p class="binfo">{{ product.pdtRemarks }}</p>
                    <span class="price">
                      <div class="text-wrap">
                        <!-- 프리미엄+이외상품구매 -->
                        <div v-if="this.user.memLevel === 'P'">
                          <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                          <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                          <small><font>원~</font></small>
                        </div>
                        <div v-else-if="this.user.memLevel === 'S'">
                          <div>
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                            <small><font>원~</font></small>  
                          </div>
                          <div>
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                            <small><font>원~</font></small>  
                          </div>
                        </div>

                        <!-- 비로그인상태의 멤버십표시 -->
                        <div v-else>
                          <!-- 일반회원가격(default) -->
                          <div class="my-1" v-if="product.promoStatus === 'Y' || product.dispPrice == 'promo'">
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(product.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                            <small><font>원~</font></small>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'E'">
                            <div>
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div> 
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <div v-if="(product.dcForMem_S > 0)">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                            <div v-else class="my-1">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                              <b class="mx-1">{{ getCurrencyFormat(product.pPrice)}}</b>
                              <small><font>원~</font></small>
                            </div>
                          </div>

                          <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || (product.nrFlag == 'Y' || product.vrFlag == 'Y')">
                            <div class="my-1" v-if="product.promoStatus === 'E'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div> 
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'N'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_S)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                  <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                  <b class="mx-1">{{ getCurrencyFormat(product.sMinPrice*1 - product.price_dcForMem_P)}}</b>
                                  <small><font>원~</font></small>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <div class="emore row justify-content-center">
                      <p class="col-6">View More</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      pdtCatList: [], //상품카테고리
      productListForSales: [], //판매용 상품리스트
      //productListForSales_recomend: [], //추천상품리스트
      pPriceY: 0,
      pPriceN: 0,
      pPriceE: 0,

      /////멤버십 금액반영
      memLv: "",            //멤버십등급(S,P)
      memDC: [0,0,0,],      //멤버십환급율(성인,소인,유아)

      // maxDC_P: 0,        //프리미엄 최대환급율
      // maxDC_S: 0,        //스탠다드 최대환급율
      // dcForMem_P: 0,     //프리미엄 일반상품 할인율
      // dcForMem_S: 0,     //스탠다드 일반상품 할인율
      // dcForStd: 0,       //프리미엄전용을 스탠다드가 구매할 경우 할인율
      
      dispPrice: [],        //가격표시방법(mem / promo)

      promoPrice: 0,        //프로모션할인액
      price_memP: 0,        //프리미엄 환급액
      price_memS: 0,        //스탠다드 환급액
      price_dcForStd: 0,    //프리미엄전용을 스탠다드가 구매할 경우 할인액
      price_dcForMem_P: 0,  //일반상품을 프리미엄이 구매시 할인액
      price_dcForMem_S: 0,  //일반상품을 스탠다드가 구매시 할인액
    };
  },
  created() {
    // 페이지생성과 동시에 리스트정보를 get
    //this.getNationList();
    this.getPdtCatList();
    this.getProductList();

    // console.log(
    //   "userInfo:",this.$store.state.user,
    // );
    // console.log(
    //   "userInfo:",
    //   this.$store.state.user.email,
    //   "alimCall:",
    //   this.$store.state.user.alimCallC
    // );
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   //this.$router.push({path: '/'});
    //   location.replace("/");
    // }

    if (this.$store.state.user.handlerId != undefined) {
      document.querySelector(".b2cTag").style.display = "none";
    }
    if (this.$store.state.user.email != undefined) {
      document.querySelector(".b2bTag").style.display = "none";
    }
    // if(this.$store.state.user.buyerId == undefined && this.$store.state.user.email == undefined) {
    //   // document.querySelector(".b2bTag").style.display="none";
    // }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },  
  methods: {
    async getPdtCatList() 
    {
      let pdtCatList = await this.$api("/api/pdtCatList", {});
      this.pdtCatList = pdtCatList;
    },

    async getProductList() 
    {
      let sMinPrice = 0;
      let sMargin = 0;
 
      //국가코드에 따른 상품리스트
      let productListForSales_tmp = await this.$api("/api/productListForSales", {param: ['%', '%']});
      //console.log("productListForSales_tmp:", productListForSales_tmp);
      
      let i = 0;
      while(i < productListForSales_tmp.length)
      {
        if(productListForSales_tmp[i].marginMethod === "P")
        {
          sMargin = productListForSales_tmp[i].minPrice * (productListForSales_tmp[i].basicMargin / 100);
        }
        else
        {
          sMargin = productListForSales_tmp[i].basicMargin;
        }
        sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
        productListForSales_tmp[i].sMinPrice = sMinPrice;
        i++;
      }

      let productListForSales_mix_tmp = await this.$api("/api/productListForSales_mix", {param: ['%', '%']});
      let k = 0;
      while(k < productListForSales_mix_tmp.length)
      {
        if(productListForSales_mix_tmp[k].marginMethod === "P")
        {
          sMargin = productListForSales_mix_tmp[k].minPrice * (productListForSales_mix_tmp[k].basicMargin / 100);
        }
        else
        {
          sMargin = productListForSales_mix_tmp[k].basicMargin;
        }
        sMinPrice = productListForSales_mix_tmp[k].minPrice + sMargin;
        productListForSales_mix_tmp[k].sMinPrice = sMinPrice;
        k++;
      }
      this.productListForSales = productListForSales_tmp;

      let j = 0;
      while(j < productListForSales_mix_tmp.length) {
          //결합상품을 앞쪽에 위치하도록 넣는다(push는 뒤에 넣기)
        this.productListForSales.unshift(productListForSales_mix_tmp[j]);
          //console.log("productListForSales_mix:",productListForSales_mix)
        j++;
      }
      //버라어티팩 상품만 추출
      this.productListForSales = this.productListForSales.filter((item) => 
				{
					return item.vrFlag == "Y";
				});      
      //console.log("productListForSales:", this.productListForSales);

      // 상품가격계산 최저가기준 표시 + 마진적용(성인기준)
      let dcForMem_P = 0      //이외상품 프리미엄 할인율
      let dcForMem_S = 0      //이외상품 스탠다드 할인율

      let marginPrice = 0;    //마진가격
      let promoPrice = 0;     //프로모션금액

      let price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
      let price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액


      for (let i = 0; i < this.productListForSales.length; i++) 
      {
        if(this.productListForSales[i].marginMethod == "P")
        {
          marginPrice = this.productListForSales[i].minPrice * (this.productListForSales[i].basicMargin / 100);
        }
        else
        {
          marginPrice = this.productListForSales[i].basicMargin;
        }
        //console.log("marginPrice:",marginPrice);

        if (this.productListForSales[i].promoStatus === "Y") 
        {
          if (this.productListForSales[i].promoMethod === "P") 
          {
            promoPrice =  (this.productListForSales[i].minPrice+marginPrice)*(this.productListForSales[i].promoRate / 100);
          }
          else
          {
            promoPrice =  this.productListForSales[i].promoRate;
          }
          this.promoPrice = promoPrice.toFixed(0)*1  //프로모션 할인액
        }

        //일반상품, 엔데어||버라이어티의 멤버십등급에 따른 할인액1
        if(this.productListForSales[i].salesRoute == "T" || (this.productListForSales[i].salesRoute == 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
        {   
          dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
          dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

          //할인방식에 따라 분기처리
          if(this.productListForSales[i].dcRateForMem_P === "P")
          {
            price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
          }
          else
          {
            price_dcForMem_P = this.productListForSales[i].dcForMem_P*1
          }
          
          if(this.productListForSales[i].dcRateForMem_S === "P")
          {
            price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)
          }
          else
          {
            price_dcForMem_S = this.productListForSales[i].dcForMem_S*1
          }

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1

          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
        }

        //멤버십미지정 로컬투어
        else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
        {
          dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
          dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

          price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
          price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
          
          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1
        }


        //화면표시용 가격
        //구매자 멤버십
        if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
        {
          //this.productListForSales[i].pPrice = "멤버십가격"
          //멤버십 + 멤버십상품
          if(this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y")
          {
            //this.productListForSales[i].pPrice = "멤버용 상품가격" 
            
            //유효프로모션 존재
            if (this.productListForSales[i].promoStatus === "Y")
            {
              if(this.user.memLevel === 'P')
              {
                //멤버십환급과 프로모션할인을 비교하여 큰 금액으로 표시
                if(this.price_memP >= this.promoPrice && this.price_memP > 0)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memP = this.price_memP  //환급액
                  this.productListForSales[i].forStd = this.price_dcForStd  //스탠다드를 위한 할인율
                }
                else if(this.price_memP < this.promoPrice || this.price_memP == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 프로모션
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
              else if(this.user.memLevel === 'S' && this.price_memS > 0)
              {
                if(this.price_memS >= this.promoPrice)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memS = this.price_memS  //환급액
                }
                else if(this.price_memS < this.promoPrice || this.price_memS == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
            }

            //프로모션 없음
            else
            {
              if(this.user.memLevel === 'P')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memP = this.price_memP  //환급액
              }
              else if(this.user.memLevel === 'S')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memS = this.price_memS  //환급액
              }
            }
          }

          //멤버십+일반상품
          else
          {
            //일반상품지정(패키지, 로컬+엔데어 || 버라이어티)
            if(this.productListForSales[i].salesRoute == 'T' || (this.productListForSales[i].salesRoute === 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
            {
              //this.productListForSales[i].pPrice = "멤버용 일반상품가격1" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].memDC = "Y"       //멤버십할인적용
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].memDC = "Y"     //멤버십할인적용
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"       //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_S = "promo"       //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                }
              }
            }
            
            //멤버십+일반상품유형2(로컬+멤버십미지정)
            else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
            {
              this.productListForSales[i].pPrice = "멤버용 일반상품가격2" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"  //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"  //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"          //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"   //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"           //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                }
              }
            }
          }
        }

        //구매자 비멤버십
        else
        {
          //프로모션이 있으면 할인처리
          if (this.productListForSales[i].promoStatus === "Y")
          {
            this.pPriceY = (this.productListForSales[i].minPrice + marginPrice) - promoPrice;
              //console.log(`pPrice${i}: ${this.pPriceY}`);
              this.productListForSales[i].pPrice = this.pPriceY;
          } 
          else if (this.productListForSales[i].promoStatus == "E") 
          {
            this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceE;
          } 
          else if (this.productListForSales[i].promoStatus == "N") 
          {
            this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceN;
          }
        }
      }      
      //console.log("productListForSales:",this.productListForSales);
    },

    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
  }
};
</script>

<style>
@media (min-width:991px){
  #evex{
    margin-top:-6rem
  }
}

/*--------------------------------------------------------------
	# 버라이어티 카로셀
--------------------------------------------------------------*/
#evex .carousel-indicators .active {
  width:50px
}
#evex .carousel-item .exmain{
  min-height:500px;
  max-height:1000px;
  object-fit:cover
}
@media (max-width: 600px){
  #evex .carousel-item .exmain{
    max-height:600px
  }
}
#evex .carousel-item .cimg{
  position:absolute;
  bottom:40%;
  left:15%;
}
#evex .carousel-item .cimg img{
  width:25rem; 
}
#evex .carousel-item .cimg2{
  position:absolute;
  bottom:30%;
  left:15%;
}
#evex .carousel-item .cimg2 img{
  width:45rem; 
}
#evex .carousel-item .cimg .pc, 
#evex .carousel-item .cimg2 .pc{
  display: block;
}
#evex .carousel-item .cimg .mobile, 
#evex .carousel-item .cimg2 .mobile{
  display: none;
}

#evex .carousel-item .text p{
  font-family: 'Black Han Sans', sans-serif;
  font-size:4rem
}

@media (max-width:991px){
  #evex .carousel-item .cimg{
    bottom:30%;
  }
  #evex .carousel-item .cimg .pc, 
  #evex .carousel-item .cimg2 .pc{
    display: none;
  }
  #evex .carousel-item .cimg .mobile, 
  #evex .carousel-item .cimg2 .mobile{
    display: block;
  }
  #evex .carousel-item .text p{
    font-size:3rem
  }
}
@media (max-width:665px){
  #evex .carousel-item .text p{
    font-size:2rem
  }
}
@media (max-width:450px){
  #evex .carousel-item .text p{
    font-size:1.6rem
  }
}


/* 지난상품(솔드아웃) */
#evex .soldout{
  filter: brightness(50%);
}



/*--------------------------------------------------------------
	# 버라이어티 설명
--------------------------------------------------------------*/
#evex .evex-container{
  margin-top:80px
}
#evex .evex-container img{
  width:auto;
  height:3rem
}
#evex  .evex-container p{
  font-size:1.1rem;
  font-weight: 600;
  padding-top:10px;
  color:#000;
}

@media (max-width:991px){
  #evex .evex-container{
    text-align: center;
  }
  #evex  .evex-container p{
    font-size:.9rem;
  }
}


/*--------------------------------------------------------------
	# 버라이어티 상품
--------------------------------------------------------------*/
#evex .evex-section{
  margin: 1rem 0 10rem;
}

/* 이미지 */
#evex .evexitem .pic .card-img-top{
  width:100%;
  height:30rem;
  object-fit:cover;
}
/* 멤버십 아이콘 */
#evex .evexitem .mbus-icon{
  width: 2.5rem !important;
  height: auto !important;
}


/* 내용 */
#evex .evexitem .einfo{
  padding-left: 2rem
}
#evex .evexitem .einfo .departure{
  color:#000;
  font-size:1.3rem;
  margin-bottom:15px
}
#evex .evexitem .einfo .binfo{
  color:#999;
  margin: 1.5rem 0 3rem
}
#evex .evexitem .einfo .price{
  color:#000;
  font-size:1.5rem
}

#evex .evexitem .einfo .emore{
  text-align: center;
  margin-top: 6rem
}
#evex .evexitem .einfo .emore p{
  font-size:1.3rem;
  border:1px solid #000;
  padding:.7rem 2rem;
  transition:all .5s
}
#evex .evexitem:hover .einfo .emore p{
  color:#fff;
  background-color: #000;
}

@media (max-width:991px){
  #evex .evexitem .pic img{
    height:25rem;
  }
  #evex .evexitem .einfo{
    padding-left: 0.5rem
  }
  #evex .evexitem .einfo .departure{
    font-size:1rem;
    margin-bottom:5px
  }
  #evex .evexitem .einfo .title{
    font-size:1.2rem
  }
  #evex .evexitem .einfo .binfo{
    margin: 1rem 0;
    font-size:.9rem
  }
  #evex .evexitem .einfo .emore{
    margin-top: 2rem
  }
  #evex .evexitem .einfo .emore p{
    font-size:1rem;
  }
}
@media (max-width:575px){
  #evex .evexitem .pic img{
    height:20rem;
  }
}

/* 뱃지 */
#evex .badge {
  /* width: 120px; */
  text-align: center;
}

#evex .badge .badge1{
  position: absolute;
  top:0;
  left:0;
  background-color:red;
  opacity: 0.7 !important;
  padding:15px 15px;
  width: 160px;
}
#evex .badge .badge1 .badge1-text{
  /* color:#fff; */
  color: white;
  font-size:1.2rem;
  padding:0
}

#evex .badge .badge2{
  position: absolute;
  top:0;
  left:0;
  /* left:100px; */
  background-color:white;
  opacity: 0.8 !important;
  padding:15px 15px;
  width: 160px;
  /* background:linear-gradient(#dacd4e, #97ca55); */
}
#evex .badge .badge2 .badge2-text{
  /* color:#fff; */
  color:#000;
  font-size:1.2rem;
  padding:0
}
</style>

