<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="alert alert-danger" role="alert">
          ※ 결제가 되었습니다. 최종확인 후, 확정처리됩니다. ※
        </div>
        <!-- <div class="fs-3">결제정보</div>
        <div class="fs-5">
          - 결제금액: {{ this.amount }}
        </div> -->
      </div>
    </main>    
  </div>
</template>

<script>
export default {
  data() {
    return {
      tossParam: [], // 토스페이파라미터
      payDetailInfo: [] //결제상세정보
    };
  },
  created() {
    //alert("SUCCESS");
    this.user = this.$store.state.user;

    if (this.$store.state.user.handlerId != undefined) {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined) {
      this.userInfo = this.$store.state.user.email;
    }
    else {
      this.userInfo = "";
    }
    console.log("userInfo:", this.userInfo);
    this.payStatus = this.$route.query.status;
    this.orderId = this.$route.query.orderNo;
    this.payMethod = this.$route.query.payMethod;
    this.amount = this.$route.query.amount;

    console.log("orderId:",this.orderId, "payStatus:", this.payStatus, "amount:",this.amount, "payMethod:", this.payMethod)

    // let tossParam = 
    // {
    //   payStatus: payStatus,
    //   orderId: orderId,
    //   payMethod: payMethod,
    //   amount: amount
    // };

    /** 토스페이 승인처리 */
    // this.$api("api/tossSuccess", 
    // {
    //   param: tossParam
    // })
    // .then((res) => {
    //   console.log(res);
    //   this.changeFlag(orderId);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });

    this.changeFlag(this.orderId);
    //카톡테스트용 호출
    //this.getPayDetailInfo(this.orderId)
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
  },
  // unmounted() {
  //   this.$el.removeEventListener('click', this.test());
  // },
  methods: 
  {
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },    
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    async changeFlag(oId)
    {
      let orderId = oId
      //return await this.$api("/api/getReqPayInfo", {param: [this.reqRsvCode]});
      await this.$api("/api/upd_UsePoint", {param: [this.userInfo, oId]});
      let updCheckFlag = await this.$api("/api/updCheckFlag", {param: ['Y', this.userInfo, this.amount, 'TP', oId]});
      if(updCheckFlag.affectedRows > 0) 
      {
        //포인트 사용확정
        let upd_UsePoint = await this.$api("/api/upd_UsePoint", {param: [ this.userInfo, oId ]});
          if(upd_UsePoint.affectedRows < 0 || upd_UsePoint.affectedRows == undefined )
          {
            this.$swal.fire("","죄송합니다. 결제처리에 오류가 발생했습니다(코드:upd_UsePoint).<br>문의처: 1660-4602","warning")
            return false
          }
          else
          {
            this.getPayDetailInfo(orderId);
            this.$swal.fire('','결제되었습니다. 감사합니다,', 'success');
          }
        // this.$swal.fire('','결제되었습니다. 감사합니다,', 'success');
      }
      else 
      {
        this.$swal.fire('','결제확인(코드: updCheckFlag)이 되지 않습니다.<br>문의처(1660-4602)에 확인을 요청해주세요.', 'error')
      }
    },
    async getPayDetailInfo(orderId)
    {
      let payDetailInfo = await this.$api("/api/payDetailInfo", {param: [orderId]});
      if(payDetailInfo.length > 0)
      {
        this.payDetailInfo = payDetailInfo[0];
        console.log("payDetailInfo:", this.payDetailInfo)
        //결제완료 카톡안내
        this.sendKakao("PY");
      }
    },

    /** 카카오알림 발송 */
    async sendKakao(param)
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.orderId, param, this.userInfo
      ]});
      // #{clientName}님, 감사합니다!
      // 여행금액 결제가 완료되었습니다.
      // 행복하고 즐거운 여행 되세요^^
      // - 예약상품: #{resName}
      // - 예약자명: #{resPaxName}
      // - 여행인원: #{resPaxCnt}
      // - 여행일자: #{dptDate}
      // - 고객연락처: #{resPhone}
      // - 예약번호: #{resCode}
      // - 결제금액: #{resPayAmount}
      // - 담당자: #{resManager} 매니저
      // - 예약확인: #{resCheck}
      if(insKSendHistory.affectedRows > 0) {
        if(param == "PY") 
        {
          //결제요청 카카오 알림톡
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제완료)
            clientName: this.payDetailInfo.clientName,
            resName: this.payDetailInfo.resName,
            resPaxName: this.payDetailInfo.resPaxName,
            resPaxCnt: this.payDetailInfo.resPaxCnt,
            dptDate: this.payDetailInfo.dptDate,
            resPhone: this.payDetailInfo.resPhone,
            resCode: this.orderId,
            resPayAmount: this.getCurrencyFormat(this.payDetailInfo.resPayAmount),
            resManager: this.payDetailInfo.resManager,
            alimFlag: param //알림유형(결제요청)
          };
          this.$api("api/kakaoAlimSend2", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '결제완료 알림톡이 발송되었습니다', 'success');
          this.$router.push({path: '/'});
          //history.back();
        }
      }
    },    
  }
}
</script>