<template>
  <main>
    <div id="reservation">
      <section>
        <div class="container mb-3">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-11 text-center">
              <div class="m-title">
                <h3 style="font-weight:600; text-decoration:underline;">예 약 정 보 확 인</h3>
                <p>
                  <b class="text-danger" v-if="this.reserveInfo.resMFlag === 'N'">(비회원예약)</b>
                  <b style="color:goldenrod;" v-else-if="this.reserveInfo.memLevel === 'P'">(프리미엄 회원)</b>
                  <b style="color:royalblue;" v-else-if="this.reserveInfo.memLevel === 'S'">(스탠다드 회원)</b>
                </p> 
              </div>
              <p class="m-info">
                <!-- <i class="bi bi-bell-fill text-danger"></i> 담당자가 내용확인 후, 확정을 위한 연락 또는 카카오 알림톡을 드립니다. -->
                <i class="bi bi-music-note-list text-primary"></i> 감사합니다 고객님!<br> 순차적으로 담당자 확인 후 진행될 예정입니다.<br> 조금만 기다려 주세요!
              </p>

              <!-- 예약상품안내 -->
              <div class="re-check">
                <div class="row col-12">
                  <p class="title" style="text-align: center;">{{ this.productInfo.pdtNameKor }}</p>
                  <table class="table">
                    <tbody>
                      <tr v-if="this.productInfo.salesRoute === 'F'">
                        <th class="name">출발일자</th>
                        <td>{{this.reserveInfo.reqDptDate}}</td>
                      </tr>
                      <tr v-else>
                        <th class="name">여행기간</th>
                        <td>{{this.reserveInfo.reqDptDate}} ~ {{this.reserveInfo.reqRtnDate}}</td>
                      </tr>

                      <tr>
                        <th class="name">여행지역</th>
                        <td>{{this.productInfo.ntName}} / {{this.productInfo.arName}}</td>
                      </tr>

                      <tr>
                        <th class="name align-middle text-primary">
                          선택옵션
                          <p style="font-size:small; color:darkgray">(성인 / 소아 / 유아)</p>
                        </th>
                        <td class="selOpt">
                          <div v-if="this.selOptions.length <=0">선택된 옵션이 없습니다.</div>
                          <div v-else>
                            {{this.selOptions[0].optPdtName}} 
                            (<span class="text-primary">{{ getCurrencyFormat( selOptPrice_self + selOptPrice_adu) }} / {{ getCurrencyFormat(selOptPrice_chi) }} / {{ getCurrencyFormat(selOptPrice_inf) }} 원</span>)
                          </div>
                          <!-- <div v-else :key=i v-for="(eo, i) in this.selOptions">
                            {{eo.optPdtName}} 
                            (<span class="text-primary">{{ getCurrencyFormat( selOptPrice_self[i] + selOptPrice_adu[i]) }} / {{ getCurrencyFormat(selOptPrice_chi[i]) }} / {{ getCurrencyFormat(selOptPrice_inf[i]) }} 원</span>)
                          </div> -->
                        </td>
                      </tr>

                      <tr>
                        <th class="name align-middle text-info">
                          추가상품
                        </th>
                        <td class="selAdd">
                          <div v-if="this.selAddPdt.length <=0">선택된 추가상품이 없습니다.</div>
                          <div v-else :key=i v-for="(ea, i) in this.selAddPdt">
                            {{ea.addPdtName}} : <i></i>&nbsp;<font style="color:darkgray">
                              &nbsp;{{ ea.qty_addPdt }} 개 ( <b class="text-info">{{ this.getCurrencyFormat((ea.aduPdtPrice * ea.qty_addPdt)) }}</b> 원 )
                            </font>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th class="name align-middle">
                          멤버십 동반자할인
                        </th>
                        <td class="compDC-info">
                          <!-- <div v-if="this.user.memLevel != 'P' && this.user.memLevel != 'S'">멤버십회원이 아닙니다.</div> -->
                          <div v-if="this.comDC_S == 0 && this.comDC_P == 0">적용되지 않는 상품입니다</div>
                          <div v-else>프리미엄회원 동반자(<b style="color:goldenrod;">{{ this.comDC_P }}</b>%) 할인&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i></i>스탠다드회원 동반자(<b style="color:royalblue;">{{ this.comDC_S }}</b>%) 할인</div>
                        </td>
                      </tr>
                      <tr v-if="this.reserveInfo.promoCode != null || this.reserveInfo.promoCode != undefined">
                        <th class="name">할인프로모션</th>
                        <td>
                          <div v-if="prMethod === 'P'">
                            {{ this.reserveInfo.prName }} <font style="font-size: small; color:darkgrey">({{ this.reserveInfo.prRate }}%, 1인당)</font>
                          </div>
                          <div v-else>
                            {{ this.reserveInfo.prName }} <font style="font-size: small; color:darkgrey">({{ this.getCurrencyFormat(this.reserveInfo.prRate) }}원, 1인당)</font>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="this.reserveInfo.resMFlag != 'N' || this.reserveInfo.resMFlag == null">
                        <th class="name">적립포인트</th>
                        <td>
                          <div v-if="this.productInfo.userPointMethod === 'P'">
                            {{ this.uPoint }}%
                            <font v-if="this.reserveInfo.memLevel === 'P' || this.reserveInfo.memLevel === 'S'">
                              &nbsp;(멤버십 특별포인트)
                            </font>
                          </div>
                          <div v-else>
                            {{ this.getCurrencyFormat(this.uPoint) }}
                            <font v-if="this.reserveInfo.memLevel === 'P' || this.reserveInfo.memLevel === 'S'">
                              &nbsp;(멤버십 특별포인트)
                            </font>                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- 인원수 -->
              <div class="re-count">
                <div class="row">
                  <div class="col-12 col-sm-4 align-self-center">
                    <div class="row inside justify-content-sm-center">
                      <div class="col-6 col-sm-12 official">
                        <p class="ro-tit">성인</p>
                        <p class="text-secondary">만 12세 이상</p>
                      </div>
                      <div class="col-6 col-sm-auto btn-group">
                        <button class="btn left" style="cursor:default;">
                          <b><i class="bi bi-person-fill text-primary"></i></b>
                        </button>
                        <span class="number" style="background-color:linen;">{{ this.reserveInfo.reqAdultCnt }}</span>
                        <button class="btn right" style="cursor:default;">
                          <b class="text-primary">인</b>
                        </button>
                      </div>
                      <div class="col-12 ro-price">
                        {{ this.getCurrencyFormat((this.reserveInfo.reqAdultPrice * this.reserveInfo.reqAdultCnt)) }} <font class="text-secondary fs-4">원</font>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 my-3 align-self-center division">
                    <div class="row inside justify-content-sm-center">
                      <div class="col-6 col-sm-12 official">
                        <p class="ro-tit">소아</p>
                        <p class="text-secondary">만 12세 미만</p>
                      </div>
                      <div class="col-6 col-sm-auto btn-group">
                        <button class="btn left" style="cursor:default;">
                          <b><i class="bi bi-person-fill text-success"></i></b>
                        </button>
                        <span class="number" style="background-color:linen;">{{ this.reserveInfo.reqChildCnt }}</span>
                        <button class="btn right" style="cursor:default;">
                          <b class="text-success">인</b>
                        </button>
                      </div>
                      <div class="col-12 ro-price">
                        {{ this.getCurrencyFormat(this.reserveInfo.reqChildPrice * this.reserveInfo.reqChildCnt) }} <font class="text-secondary fs-4">원</font>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 align-self-center">
                    <div class="row inside justify-content-sm-center">
                      <div class="col-6 col-sm-12 official">
                        <p class="ro-tit">유아</p>
                        <p class="text-secondary">생후 24개월 미만</p>
                      </div>
                      <div class="col-6 col-sm-auto btn-group">
                        <button class="btn left" style="cursor:default;">
                          <b><i class="bi bi-person-fill text-info"></i></b>
                        </button>
                        <span class="number" style="background-color:linen;">{{ this.reserveInfo.reqInfCnt }}</span>
                        <button class="btn right" style="cursor:default;">
                          <b class="text-info">인</b>
                        </button>
                      </div>
                      <div class="col-12 ro-price">
                        {{ this.getCurrencyFormat(this.reserveInfo.reqInfPrice * this.reserveInfo.reqInfCnt) }} <font class="text-secondary fs-4">원</font>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center ro-final">
                  <p>결제금액 <sapn style="font-size:small;"> (선택된 옵션 및 추가상품 포함)</sapn></p>
                  <p class="sum text-danger">{{ this.getCurrencyFormat(this.totalPrice + this.tot_selOptPrice + this.tot_selAddPdtPrice) }}
                    <!-- <br>
                    {{ this.totalPrice }} / {{ this.tot_selOptPrice }} / {{ this.tot_selAddPdtPrice }} -->
                    <font class="text-secondary fs-4">원</font>
                  </p>

                  <div v-if="this.tot_comDC > 0">
                    <div class="col-12 ro-mem-price mt-0 mb-1">
                      동반자 할인금액: {{ this.getCurrencyFormat(tot_comDC + tot_selOptPrice_exSelfDC) }} <font class="text-secondary fs-6">원</font>
                    </div>
                  </div>                  

                  <div v-if="reserveInfo.dispFlag === 'c1' || reserveInfo.dispFlag === 'c3'">
                    <div class="col-12 ro-mem-price mt-0 mb-1">
                      멤버십 환급금액: {{ this.getCurrencyFormat(tot_price_mem + tot_selOptPrice_ref) }} <font class="text-secondary fs-6">원</font>
                    </div>
                  </div>
                  <div v-if="reserveInfo.dispFlag === 'c2' || reserveInfo.dispFlag === 'c4' || reserveInfo.dispFlag === 'c5'">
                    <div class="col-12 ro-mem-price mt-0 mb-1">
                      멤버십 할인금액: {{ this.getCurrencyFormat(tot_price_mem + tot_selOptPrice_selfDC) }} <font class="text-secondary fs-6">원</font>
                    </div>
                  </div>

                  <hr>

                  <div v-if="this.user.memLevel === 'P'">
                    <div class="col-12 ro-mem-price">
                      실제 투어이용액은  <b style="color:goldenrod;">{{ this.getCurrencyFormat(reserveInfo.showPrice_P) }}</b> <font class="text-secondary fs-6">원</font> 입니다.
                    </div>
                    <!-- <div class="col-12 ro-mem-price">
                      실제 투어이용액은  <b style="color:goldenrod;">{{ this.getCurrencyFormat(showPrice_P + this.tot_selOptPrice + this.tot_selAddPdtPrice) }}</b> <font class="text-secondary fs-6">원</font> 입니다.
                    </div> -->
                  </div>
                  <div v-else-if="this.user.memLevel === 'S'">
                    <div class="col-12 ro-mem-price">
                      실제 투어이용액은  <b style="color:royalblue;">{{ this.getCurrencyFormat(reserveInfo.showPrice_S) }}</b> <font class="text-secondary fs-6">원</font> 입니다.
                    </div>
                    <!-- <div class="col-12 ro-mem-price">
                      실제 투어이용액은  <b style="color:royalblue;">{{ this.getCurrencyFormat(showPrice_S + this.tot_selOptPrice + this.tot_selAddPdtPrice) }}</b> <font class="text-secondary fs-6">원</font> 입니다.
                    </div> -->
                  </div>

                  <div>
                    <p class="memPrc" v-if="this.user.memLevel == 'P'"></p>
                    <!-- <p class="memPrc" v-else>
                      프리미엄멤버십 가입 시, <i></i>투어의 실제 이용가는  <b style="color:goldenrod">{{ this.getCurrencyFormat(showPrice_P + this.tot_selOptPrice + this.tot_selAddPdtPrice) }}</b> <font class="text-secondary fs-6">원</font> 입니다.
                    </p> -->
                    <p class="memPrc" v-else>
                      프리미엄멤버십 가입 시, <i></i>투어의 실제 이용가는  <b style="color:goldenrod">{{ this.getCurrencyFormat(this.reserveInfo.showPrice_P) }}</b> <font class="text-secondary fs-6">원</font> 입니다.
                    </p>                    
                  </div>                  
                  <p class="op-ch">(※ 상기금액은 <b>판매조건</b>에 따라 변경될 수 있습니다)</p>
                </div>
              </div>

              <!-- 예약자 정보 -->
              <div class="re-subsc">
                <div class="container">
                  <div class="title text-start">
                    <div style="margin-bottom:1rem">
                      <span>예약자정보</span>
                      <div class="form-check notme">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="this.myselfFlag" true-value="Y" false-value="N" checked>
                        <label class="form-check-label" for="flexCheckDefault">
                          본인참가
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="text-start">
                    <table class="table">
                      <tbody v-if="this.reserveInfo.resMFlag === 'N'">
                        <tr>
                          <th class="col-3">예약자</th>
                          <td class="col-7">
                            <input class="form-control" type="text" placeholder="필수, 한글성명(ex.홍길동)" aria-label="한글 성명(ex.홍길동)" v-model="this.resName" ref="resName" maxlength="30">
                          </td>
                        </tr>
                        <tr>
                          <th>연락처</th>
                          <td>
                            <input type="text" class="form-control" placeholder="필수, 모바일연락처(ex.01023456789)" aria-label="모바일연락처(ex.01023456789)" v-model="this.resCall" ref="resCall" maxlength="11">
                          </td>
                        </tr>
                        <tr>
                          <th>이메일</th>
                          <td> 
                            <input type="text" class="form-control" placeholder="이메일(ex.abc@def.com)" aria-label="이메일(ex.abc@def.com)" v-model="this.resEmail" ref="resEmail"  maxlength="50">
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-else>
                        <tr>
                          <th class="col-3">예약자</th>
                          <td class="col-7">
                            <input class="form-control" type="text" placeholder="필수, 한글성명(ex.홍길동)" aria-label="한글 성명(ex.홍길동)" v-model="this.resName" ref="resName" maxlength="30" disabled>
                          </td>
                        </tr>
                        <tr>
                          <th>연락처</th>
                          <td>
                            <input type="text" class="form-control" placeholder="필수, 모바일연락처(ex.01023456789)" aria-label="모바일연락처(ex.01023456789)" v-model="this.resCall" ref="resCall" maxlength="11" disabled>
                          </td>
                        </tr>
                        <tr>
                          <th>이메일</th>
                          <td> 
                            <input type="text" class="form-control" placeholder="이메일(ex.abc@def.com)" aria-label="이메일(ex.abc@def.com)" v-model="this.resEmail" ref="resEmail"  maxlength="50">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="container my-4" v-if="this.myselfFlag === 'N'">
                  <div class="title text-start">
                    <p>여행객정보</p>
                  </div>

                  <div class="text-start">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="col-4">대표여행객</th>
                          <td class="col-8">
                            <input class="form-control" type="text" placeholder="한글성명(ex.전우치)" aria-label="한글성명(ex.전우치)" v-model="this.tripName" ref="tripName" maxlength="30">
                          </td>
                        </tr>
                        <tr>
                          <th>연락처</th>
                          <td class="col-8">
                            <input type="text" class="form-control" placeholder="모바일연락처(ex.01023456789)" aria-label="모바일연락처(ex.01023456789)" v-model="this.tripCall" ref="tripCall" maxlength="11">
                          </td>
                        </tr>
                        <tr>
                          <th>이메일</th>
                          <td class="col-8">
                            <input type="text" class="form-control" placeholder="이메일(ex.tester@test.com)" aria-label="이메일(ex.tester@test.com)" v-model="this.tripEmail" ref="tripEmail" maxlength="50">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="container mt-4">
                  <div class="title text-start">
                    <p>기타정보</p>
                  </div>

                  <div class="text-start">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th cclass="col-4">추가요청사항</th>
                          <td class="col-8">
                            <textarea type="text" class="form-control" ref="ask" placeholder="문의내용을 적어주세요(200자 이내)" v-model="this.askQuestion" maxlength="200" style="height: 150px"></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                
                <div class="notice">
                  <ul>
                    <li> 최종상품가격은 <b class="text-danger">상담 중 확인 및 결정</b>됩니다.</li>
                    <li> 여권정보가 필요한 여행 상품의 경우 담당자를 통해 여행자의 여권정보를 수집할 수 있습니다.</li>
                    <li> 고객정보는 여권상 표기된 정보와 일치해야 합니다.(영문성함의 스펠링 등 여권과 상이할 경우 출국이 제한될 수 있습니다.)</li>
                    <li> 여권의 유효기간은 출국일 기준 최소 6개월 이상 여유를 권장합니다.</li>
                    <li> 출입국 등, 여권으로 인한 문제에 대해서는 여행사가 일체 관여하거나 책임지지 않습니다.</li>
                  </ul>
                </div>
              </div>

              <!-- 약관동의 -->
              <div class="re-terms">
                <p class="title">약관 동의</p>
                <div class="form-check mt-1">
                  <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="this.allConfirmFlag" ref="allConfirmFlag" @change="checkAllConfirm()">
                  <label class="form-check-label" for="defaultCheck">
                    <p>전체 동의</p>
                  </label>
                </div>
                
                <hr style="border-top:2px solid; opacity:1; margin:0">
                    
                <div class="accordion accordion-flush" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="this.confirm_spTerms" ref="confirm_spTerms">
                        <label class="form-check-label" for="defaultCheck1">
                          <span class="check">[필수]</span>특별약관 설명 및 확인
                        </label>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        본 여행상품의 최소출발 인원 기준 미충족 시 여행표준약관 제17조에 따라 여행사는 여행출발 7일전까지 여행계약을 일방적으로 해제하고 소비자에게 통보 할 수 있습니다.<br>
                        ※취소수수료의 발생 시점과 부과 기준 정보 고지 본 여행상품은 계약금 지불 시점부터계약이 체결되며,계약해제 요청 시 귀책사유에 따라 취소수수료가 부과됩니다. 취소수수료 부과 기준은 (국외여행표준약관/특별약관)에 따릅니다.<br>
                        <br>
                        -국외여행 특별약관-<br>
                        ･여행 개시 30일 전(~30일) 통보 시 : 전액 환불<br>
                        ･여행 개시 20일 전(29~20일) 통보 시 : 상품가의 20% 공제 후 환불<br>
                        ･여행 개시 10일 전(19~10일) 통보 시 : 상품가의 30% 공제 후 환불<br>
                        ･여행 개시 7일 전(9~7일) 통보 시 : 상품가의 50% 공제 후 환불<br>
                        ･여행 개시 1일 전(6~1일) 통보 시 : 상품가의 70% 공제 후 환불<br>
                        ･여행 개시 당일 통보 시 : 상품가의 100% 공제, 환불 불가<br>
                        단, 여행참가자 수의 미달로 여행 개시 7일전까지 여행계약 해제 통지시: 계약금 100%환불 해드립니다. (본인계좌만 가능)<br>
                        <br>
                        ※ 취소 접수는 업무 시간 내에 유선으로 가능하며, 영업일(월~금-09:00~18:00, 공휴일 제외) 기준입니다.<br>
                        ※ 취소는 출발일 기준에서 제외됩니다.<br>
                        <br>
                        본 여행상품은 항공사의 요청에 의해 항공권이 여행개시 30일전 선발권(구매)이 진행 될 수 있으며 이 경우 고객의 동의 하에 특별약관으로 전환 됩니다.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="this.confirm_Terms" ref="confirm_Terms">
                        <label class="form-check-label" for="defaultCheck2">
                          <span class="check">[필수]</span>약관동의
                        </label>
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        제1조 (목적)<br>
                          이 약관은 엔타비글로벌(주)가 운영하는 엔타비 사이버 몰(이하 "몰"이라 한다)에서 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.<br>
                          PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.<br>
                          <br>
                          제2조 (정의)<br>
                          ① "몰"이란 엔타비 회사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.<br>
                          ② "이용자"란 "몰"에 접속하여 이 약관에 따라 "몰"이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br>
                          ③ '회원'이라 함은 "몰"에 회원등록을 한 자로서, 계속적으로 "몰"이 제공하는 서비스를 이용할 수 있는 자를 말합니다.<br>
                          ④ '비회원'이라 함은 회원에 가입하지 않고 "몰"이 제공하는 서비스를 이용하는 자를 말합니다.<br>
                          <br>
                          제3조 (약관 등의 명시와 설명 및 개정)<br>
                          여행의 종류 및 정의, 해외 여행수속 대행업의 정의는 다음과 같습니다.<br>
                          ① "몰"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 엔타비 사이버몰의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br>
                          ② "몰"은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.<br>
                          ③ "몰"은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br>
                          ④ "몰"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "몰"은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.<br>
                          ⑤ "몰"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다.<br>
                          다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 "몰"에 송신하여 "몰"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.<br>
                          ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.<br>
                          <br>
                          제4조 (서비스의 제공 및 변경)<br>
                          ① "몰"은 다음과 같은 업무를 수행합니다.<br>
                            1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결<br>
                            2. 구매계약이 체결된 재화 또는 용역의 배송<br>
                            3. 기타 "몰"이 정하는 업무<br>
                          ② "몰"은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.<br>
                          ③ "몰"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.<br>
                          ④ 전항의 경우 "몰"은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "몰"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.<br>
                          <br>
                          제5조 (서비스의 중단)<br>
                          ① "몰"은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br>
                          ② "몰"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, "몰"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.<br>
                          ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "몰"은 제8조에 정한 방법으로 이용자에게 통지하고 당초 "몰"에서 제시한 조건에 따라 소비자에게 보상합니다.<br>
                          다만, "몰"이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 "몰"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.<br>
                          <br>
                          제6조 (회원가입)<br>
                          ① 이용자는 "몰"이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.<br>
                          ② "몰"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.<br>
                            1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "몰"의 회원재가입 승낙을 얻은 경우에는 예외로 한다.<br>
                            2. 등록 내용에 허위, 기재누락, 오기가 있는 경우<br>
                            3. 기타 회원으로 등록하는 것이 "몰"의 기술상 현저히 지장이 있다고 판단되는 경우<br>
                          ③ 회원가입계약의 성립 시기는 "몰"의 승낙이 회원에게 도달한 시점으로 합니다.<br>
                          ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "몰"에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.<br>
                          <br>
                          제7조 (회원 탈퇴 및 자격 상실 등)<br>
                          ① 회원은 "몰"에 언제든지 탈퇴를 요청할 수 있으며 "몰"은 즉시 회원탈퇴를 처리합니다.<br>
                          ② 회원이 다음 각 호의 사유에 해당하는 경우, "몰"은 회원자격을 제한 및 정지시킬 수 있습니다.<br>
                            1. 가입 신청 시에 허위 내용을 등록한 경우<br>
                            2. "몰"을 이용하여 구입한 재화 등의 대금, 기타 "몰"이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우<br>
                            3. 다른 사람의 "몰" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br>
                            4. "몰"을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br>
                          ③ "몰"이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "몰"은 회원자격을 상실시킬 수 있습니다.<br>
                          ④ "몰"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.<br>
                          <br>
                          제8조 (회원에 대한 통지)<br>
                          ① "몰"이 회원에 대한 통지를 하는 경우, 회원이 "몰"과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.<br>
                          ② "몰"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "몰" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.<br>
                          <br>
                          제9조 (구매신청 및 개인정보 제공 동의 등)<br>
                          ① "몰"이용자는 "몰"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "몰"은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.<br>
                            1. 재화 등의 검색 및 선택<br>
                            2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br>
                            3. 약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인<br>
                            4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)<br>
                            5. 재화등의 구매신청 및 이에 관한 확인 또는 "몰"의 확인에 대한 동의<br>
                            6. 결제방법의 선택<br>
                          ② "몰"이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우<br>
                            1) 개인정보를 제공받는 자<br>
                            2)개인정보를 제공받는 자의 개인정보 이용목적<br>
                            3) 제공하는 개인정보의 항목<br>
                            4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)<br>
                          ③ "몰"이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 개인정보 취급위탁을 받는 자, 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)<br>
                          다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.<br>
                          <br>
                          제10조 (계약의 성립)<br>
                          ① "몰"은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.<br>
                            1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br>
                            2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우<br>
                            3. 기타 구매신청에 승낙하는 것이 "몰" 기술상 현저히 지장이 있다고 판단하는 경우<br>
                          ② "몰"의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.<br>
                          ③ "몰"의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.<br>
                          <br>
                          제11조 (지급방법)<br>
                          "몰"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, "몰"은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.<br>
                            1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체<br>
                            2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제<br>
                            3. 온라인무통장입금<br>
                            4. 전자화폐에 의한 결제<br>
                            5. 수령 시 대금지급<br>
                            6. 마일리지 등 "몰"이 지급한 포인트에 의한 결제<br>
                            7. "몰"과 계약을 맺었거나 "몰"이 인정한 상품권에 의한 결제<br>
                            8. 기타 전자적 지급 방법에 의한 대금 지급 등<br>
                            <br>
                          제12조 (수신확인 톨지, 구매신청 변경 및 취소)<br>
                          ① "몰"은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.<br>
                          ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 "몰"은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.<br>
                          <br>
                          제13조 (재화 등의 공급)<br>
                          ① "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 "몰"은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.<br>
                          ② "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 "몰"이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 "몰"이 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다.<br>
                          <br>
                          제14조 (환급)<br>
                          "몰"은 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.<br>
                          <br>
                          제15조 (청약철회 등)<br>
                          ① "몰"과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.<br>
                          ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.<br>
                            1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)<br>
                            2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우<br>
                            3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우<br>
                            4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우<br>
                          ③ 제2항제2호 내지 제4호의 경우에 "몰"이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.<br>
                          ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.<br>
                          <br>
                          제16조 (청약철회 등의 효과)<br>
                          ① "몰"은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 "몰"이 이용자에게 재화등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.<br>
                          ② "몰"은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.<br>
                          ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. "몰"은 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 "몰"이 부담합니다.<br>
                          ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 "몰"은 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.<br>
                          <br>
                          제17조 (개인정보 보호)<br>
                          ① "몰"은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.<br>
                          ② "몰"은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.<br>
                          ③ "몰"은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.<br>
                          ④ "몰"은 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.<br>
                          ⑤ "몰"이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.<br>
                          ⑥ 이용자는 언제든지 "몰"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "몰"은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "몰"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.<br>
                          ⑦ "몰"은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.<br>
                          ⑧ "몰" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.<br>
                          ⑨ "몰"은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.<br>
                          <br>
                          제18조 ("몰"의 의무)<br>
                          ① "몰"은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.<br>
                          ② "몰" 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.<br>
                          ③ "몰"이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.<br>
                          ④ "몰"은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.<br>
                          <br>
                          제19조 (회원의 ID 및 비밀번호에 대한 의무)<br>
                          ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.<br>
                          ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.<br>
                          ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "몰"에 통보하고 "몰"의 안내가 있는 경우에는 그에 따라야 합니다.<br>
                          <br>
                          제20조 (이용자의 의무)<br>
                          이용자는 다음 행위를 하여서는 안 됩니다.<br>
                            1. 신청 또는 변경시 허위 내용의 등록<br>
                            2. 타인의 정보 도용<br>
                            3. "몰"에 게시된 정보의 변경<br>
                            4. "몰"이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br>
                            5. "몰" 기타 제3자의 저작권 등 지적재산권에 대한 침해<br>
                            6. "몰" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br>
                            7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위<br>
                            <br>
                          제21조 (연결 "몰"과 피연결 "몰" 간의 관계)<br>
                          ① 상위 "몰"과 하위 "몰"이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 "몰"(웹 사이트)이라고 하고 후자를 피연결 "몰"(웹사이트)이라고 합니다.<br>
                          ② 연결"몰"은 피연결"몰"이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결"몰"의" 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.<br>
                          <br>
                          제22조 (저작권의 귀속 및 이용제한)<br>
                          ① "몰"이 작성한 저작물에 대한 저작권 기타 지적재산권은 '몰'에 귀속합니다.<br>
                          ② 이용자는 "몰"을 이용함으로써 얻은 정보 중 "몰"에게 지적재산권이 귀속된 정보를 "몰"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.<br>
                          ③ "몰"은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.<br>
                          <br>
                          제23조 (분쟁해결)<br>
                          ① "몰"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.<br>
                          ② "몰"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.<br>
                          ③ "몰"과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.<br>
                          <br>
                          제24조 (재판권 및 준거법)<br>
                          ① "몰"과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.<br>
                          ② "몰"과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.<br>
                        
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="this.confirm_Info" ref="confirm_Info">
                        <label class="form-check-label" for="defaultCheck3">
                          <span class="check">[필수]</span>개인정보 수집 및 이용동의
                        </label>
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        엔타비 글로벌 (이하 '엔타비')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br>

                        1. 개인정보 수집 및 이용목적<br>
                        엔타비는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br>
                        &nbsp; 1) 홈페이지 회원가입 및 관리<br>
                        &nbsp; 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.<br>
                        &nbsp; 2) 서비스 제공<br>
                        &nbsp;  물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.<br>
                        &nbsp; 3) 마케팅 및 광고에의 활용<br>
                        &nbsp; 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
                        <br>
                        2. 개인정보 수집 항목<br>
                        엔타비는 다음의 개인정보 항목을 처리하고 있습니다.<br>
                        &nbsp; 1) 홈페이지 회원가입 및 관리/마케팅 정보 제공의 목적 (회원탈퇴 시 까지)<br>
                        &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름<br>
                        &nbsp; 2) 상담 및 해외 및 국내 여행 예약의 목적 (신용카드정보, 은행계좌정보)<br>
                        &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 여권사본, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호<br>
                        &nbsp; 3) 여행자 보험가입의 목적 (신용카드정보, 은행계좌정보)<br>
                        &nbsp;  이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름<br>
                        &nbsp; 4) 대금결제의 목적 (이용목적이 달성되면 파기)<br>
                        &nbsp;  신용카드정보, 은행계좌정보<br>
                          <br>
                        3. 개인정보 수집 항목 보유 및 이용기간<br>
                        &nbsp; 1) 엔타비는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.<br>
                        &nbsp; 2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
                        &nbsp;  ① 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                        &nbsp;  ② 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                        &nbsp;  ③ 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                        &nbsp;  ④ 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                        &nbsp;  ⑤ 표시/광고에 관한 기록 : 6개월<br>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="this.confirm_Privacy" ref="confirm_Privacy">
                        <label class="form-check-label" for="defaultCheck4">
                          <span class="check">[필수]</span>고유식별정보 수집 및 이용동의
                        </label>
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        고유식별정보 동의 안내<br>
                        <br>
                        당사는 개인정보보호법을 준수하며 서비스 수행의 목적에 한하여 최소한의 고유식별정보를 수집,이용하며 기준은 아래와 같습니다.<br>  
                        1. 고유식별 정보 수집/이용 목적 : 해외여행 상품예약시 출국가능 여부파악 및 여행자 본인식별, 여행자보험 가입<br>
                        2. 수집하는 고유식별 정보의 항목 : 여권번호 (여권만료일)<br>
                        3. 고유식별정보의 보유 및 이용기간 : 여행상품 서비스 수행목적의 완료시점까지<br>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="this.confirm_3rd" ref="confirm_3rd">
                        <label class="form-check-label" for="defaultCheck5">
                          <span class="check">[필수]</span>개인정보 3자제공
                        </label>
                      </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        '엔타비'는 고객(동반자포함) 개인정보의 보호를 위하여 당사가 보관하고 있는 고객의 개인정보를 제3자와 공유하거나 무단으로 제공하지 않습니다. <br>
                        여행상품의 예약 및 원활한 여행서비스 제공 등을 위한 목적으로 고객의 개인정보 중 일부가 제3자에게 제공될 수 있습니다.(개인정보를 제공받는 업체, 개인정보 항목, 이용 목적 및 이용기간 추후 변동될 수 있습니다.)<br>
                        서비스 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적 기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우나 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우에는 고객의 동의 없이 개인정보를 제공할 수 있습니다.
                        <br>
                        '엔타비'는 고객(동반자포함)의 개인정보가 본래의 수집 목적 및 이용 목적에 반하여 무분별하게 사용되지 않도록 최선의 노력을 다할 것입니다.<br>
                        1) 엔타비는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br>
                        2) 엔타비는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.<br>
                        &nbsp;① 항공사/선박업체/여행업체/은행·카드 CP사/비자발급대행사/인솔자(여행을 다녀온 시점에 즉시 파기)<br>
                        &nbsp; &nbsp;> 콘텐츠 제공, 구매 및 요금 결제,개인 식별, 불만처리 등 민원처리, 고지사항 전달<br>
                        &nbsp; &nbsp;> 성명(국/영문), 여권 및 비자 사본, 전화번호, 주소, 회원 종류 위탁<br>
                        &nbsp;② 숙박업체<br>
                        &nbsp; &nbsp;> 숙박 예약 및 개인 식별의 목적<br>
                        &nbsp; &nbsp;> 성명(국/영문), 여권 및 비자 사본, 전화번호<br>
                        &nbsp;③ 토파스<br>
                        &nbsp; &nbsp;> 항공권 카드 결제의 목적<br>
                        &nbsp; &nbsp;> 성명(국/영문), 여권 및 비자 사본, 전화번호, 카드사명, 카드번호, 카드유효기간 위탁<br>
                        &nbsp;④ 현대해상, 메리츠화재, KB손해보험<br>
                        &nbsp; &nbsp;> 여행자 보험가입의 목적<br>
                        &nbsp; &nbsp;> 한글명, 영문명, 주민번호<br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 필수확인사항 -->
              <div class="re-result">
                <div class="row justify-content-center">
                  <div class="col-12 col-lg-10 bg-light">
                    <div class="incont">
                      <p>필수 확인 사항</p>
                      <ul>
                        <li>- 예약 후 결제 완료, 예약 확정 시 취소 시점에 따라 위약금이 발생할 수 있습니다.</li>
                        <li>- 취소위약금 규정은 각 상품 상세 페이지에서 확인가능합니다.</li>
                        <li>- 예약 완료 후 즉시 확정이 아니며 담당자 확인 전화로 추가 안내 및 예악 확인 절차가 진행됩니다.</li>
                        <li>- 여행 상품의 특성상 경우에 따라 예약이 완료된 후에도 처리가 불가능 할 수 있습니다.</li>
                        <li>- 여행상품의 약관 규정은 재경부 고시 소비자 피해보상 규정을 바탕으로 하나, 특정 상품의 경우 엔타비글로벌 특별 약관을 적용하고 있습니다.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 버튼 -->
              <div class="mt-5 row justify-content-center">
                <button class="btn cxl col-5 col-lg-3 mx-2"  @click="goToList()">메인페이지로</button>
                <button class="btn go col-5 col-lg-3 mx-2" data-bs-target="#reservationDone" @click="reserComplete(this.reqRsvCode, 'fu')">예약하기</button>
                <button class="btn part col-5 col-lg-3 mx-2" data-bs-target="#reservationDone" @click="reserComplete(this.reqRsvCode, 'p1')" v-if="productInfo.firstPayment > '0' && productInfo.firstPayment < '100'">예약금결제</button>
              </div>

              <div id="payment-method" v-show="showFlag" style="height:auto"></div>
              <div style="text-align:center; padding-right: 35px;"  v-show="showFlag">
                <button @click="requestPayment(this.reqRsvCode, this.reserveInfo.resMFlag, this.payStyle)" class="btn btn-primary" id="payment-button" style="width:80%">
                  <b class="fs-5" v-if="this.payStyle === 'fu'"><i class="bi bi-credit-card"></i> 전액결제하기</b>
                  <b class="fs-5" v-else-if="this.payStyle === 'p1'"><i class="bi bi-credit-card"></i> 부분결제하기 ({{ this.getCurrencyFormat(firstPayment) }}원)</b>
                  <b class="fs-5" v-else>결제선택이 불분명합니다</b>
                </button>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
//import axios from 'axios';
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      showFlag: false,        //결제창 표시여부

      // 토스PG결제용 정보
      paymentWidget: null,
      paymentMethodWidget: null,
      //clientKey: "test_ck_YyZqmkKeP8gG2Klanln8bQRxB9lG",
      clientKey: "live_ck_jZ61JOxRQVEZXa1aoR0rW0X9bAqw",
      customerKey: "Ntabi-1936=@",

      productInfo: [],              //상품정보
      reserveInfo: [],              //예약정보

      // 옵션 & 추가상품 판매용 신규(2024-04-22)
      selOptFlag: "N",              //선택옵션유무
      selAddFlag: "N",              //선택추상유무
      pdtOptInfo: [],               //상품선택옵션정보
      pdtOptFlag: "N",              //선택옵션유무
      calOptFlag: "N",              //선택옵션유무(예약용)
      selOptions: [],               //선택된 옵션정보
      selOptNames: [],              //선택옵션명

      addPdtInfo: [],               //추가상품정보
      addPdtFlag: "N",              //추가상품유무
      calAddFlag: "N",              //추가상품유무(예약용)
      selAddPdt: [],                //선택된 추가상품정보
      selAddPdtNames: [],           //선택추상명

      myselfFlag: "Y",              //본인여부(Y/N)

      resName: "",                  //예약자명
      resCall: "",                  //예약자연락처
      resEmail: "",                 //예약자이메일
      tripName: "",                 //대표여행객
      tripCall: "",                 //대표여행객
      tripEmail: "",                //대표여행객

      askQuestion: "",              //기타문의사항

      //약관동의
      allConfirmFlag: "",           //동의체크-전체선택
      confirm_spTerms: "",          //특별약관
      confirm_Terms: "",            //약관동의
      confirm_Info: "",             //개인정보수집이용동의
      confirm_Privacy: "",          //고유식별정보
      confirm_3rd: "",              //개인정보3자제공

      reserConfirm: "N",            //예약컨펌창

      checkedOptCodes: [],          //선택된옵션코드
      checkedOptPrice: [],          //선택된옵션금액
      checkedOptions: [],           //선택된옵션정보


      aPromoFlag: "N",              //적용프로모션 존재여부
      
      reservePriceInfo: [],         //예약권종정보
      productCode: "",              //상품코드
      pdtFlag: "",                  //상품종류
      availPoint: 0,                //가용포인트
      // etcSelectedOption: {},        //선택된 기타옵션
      // journeyDate: [],              //여정일자
      // sJDate: [],                   //옵션적용 여정일자(추가옵션용)
      selectedProcCode: "",         //예약처리상태
      productImage: [],             //상품이미지

      // selectedEtcOpt: [],           //선택된 옵션의 확인선택
      // selectedEtcOptApplyDate: [],  //선택된 옵션의 적용일자(기존)
      // applyDateFlag: "N",           //적용일자선택여부(기존)

      sEtcOpt: [],                  //선택된 기타옵션 중 이용할 옵션

      cstPrice: 0,                  //상담가격

      personCnt: 0,                 //총인원(화면상)

      reqMarginRate: 0,             //당해예약마진
      reqMarginMethod: "P",         //당해예약마진계산방법(P/A)

      insComment: "",               //예약상담내용

      paidPoint: 0,                 //사용포인트
      payPointFlag: "N",            //포인트사용여부

      pointForPay: 0,               //결제에 사용할 포인트

      payByPointFlag: "N",          //포인트사용 플래그
      pointForPay_tmp: 0,           //포인트 복구용 직전 사용예정 포인트
      rtnRPflag_tmp: [],            //결제 후 포인트와 결제정보


      pdtPromoInfo_apply: {},     //적용가능 프로모션(출발일기준)
      pdtPromoInfo: {},           //프로모션정보
      pdtPromo: 0,                //프로모션할인액


      pdtAddOptExisted: {},       //기존추가옵션
      addOptInfo: {},             //화면표시용 추가옵션

      areaFlag: "",

      sRoute: {},                 //상품구분


      promoWork: "",             //프로모션 적용방식(C/U)
      cPromoFlag: "",            //선택프로모션 진행?종료

      productPromotion: {},
      choicePromotion: {},      //선택된 프로모션정보
      promoList: [],            //해당상품의 프로모션목록

      optFlag: "",             //옵션선택플래그

      pdtcode: "",

      etcOptName: [],
      etcOptPrice: [],
      vCnt: 0,

      // reqOptFlag: [],         //기타옵션플래그

      productPrice: {},

      promoStart: "",
      promoEnd: "",

      rtnRPflag: "N",          //결제요청여부
      paidFlag: "N",           //결제여부

      showPrice_P: 0,         //멤버십가격(가입유도)
      showPrice_S: 0,         //멤버십가격(가입유도)
      showPrice_G: 0,         //멤버십가격(가입유도)

      handTel: [],            //매니저연락처
      handTelCnt: 0,          //수신처갯수

      firstPayment: 0,        //부분 또는 전부결제금액
      balance: 0,             //2차결제액(잔액)
      payStyle: "",           // 부분(p), 전액(f)결제스타일


      totalPrice: 0,                //권종합산금
      tot_perCount: 0,              //인원총수
      comDC_S: 0,                   //동반할인(스탠다드)
      comDC_P: 0,                   //동반할인(프리미엄)
      tot_comDC: 0,                 //동반자할인 합산액

      uPoint: 0,                    //지급포인트

      tot_adultPrice_mem: 0,        //권종별 멤버십 환급 또는 할인액(당사자1인만 대상)
      tot_childPrice_mem: 0, 
      tot_infantPrice_mem: 0,
      tot_price_mem: 0,             //환급 또는 할인총액
      tot_selOptPrice:  0,          //선택된 옵션총액
      tot_selAddPdtPrice:  0,       //선택된 추상총액

      selOptPrice_self: [],         //선택옵션권종가격(본인)
      selOptPrice_adu: [],          //선택옵션권종가격(성인)
      selOptPrice_chi: [],
      selOptPrice_inf: [],

      tot_selOptPrice_selfDC: 0,    //멤버십할인총액
      tot_selOptPrice_exSelfDC: 0,  //동반인할은총액
      tot_selOptPrice_ref: 0.       //멤버십환급총액
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.reqRsvCode = this.$route.query.resCode;
    this.productId = this.$route.query.prodId;
    this.assoFlag = this.$route.query.assoFlag;

    this.getReserInfo();
    this.getProductInfo();

    //console.log("USER:",this.user);

    this.getProductPrice();
    this.getPdtPromotion();        //상품프로모션

    this.getProductPromotion();    //상품프로모션정보
    this.getPromotionList();

    this.getCityList();
    this.getNationList();
    this.getAreaList();

    this.getHandlerInfo()

    //this.getPdtOptInfo()          //상품선택옵션정보
    //this.getAddPdtInfo()          //추가상품정보    
  },
  mounted() {
    window.scrollTo(0, 0);

    // if(this.assoFlag === '' || this.assoFlag == undefined)
    // {
    //   if(document.referrer == "")
    //   {
    //     this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //     location.replace("/")
    //   }
    // }

    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.user.joinFlag === "H")
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.alimCallC != undefined && this.user.alimCallC.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.alimCallC.replace("+82 ", "0")
      }
      else
      {
        kakaoPhone = this.user.alimCallC
      }
      this.k_tel = (!this.user.alimCallC) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }
    //console.log("k_name:",this.k_name, "k_tel:",this.k_tel)
    
    this.resName = this.k_name      //예약자명
    this.resCall = this.k_tel       //예약자연락처
    this.resEmail= this.user.email  //예약자이메일

    //상세정보와 무관한 내용 비표시처리
    document.getElementById("notice").style.display = "none";

    //비회원 접근 시 대응처리
    // if(!this.$store.state.user.email || this.$store.state.user.email == undefined)
    // {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '',
    //     text: '로그인 후 이용해주세요.',
    //     icon: 'warning'
    //   });
    //   this.$router.push({path: '/'});
    // }

  },
  watch: {
    myselfFlag: function(newVal) {
      if(newVal === 'Y')
      {
        this.tripName = ""
        this.tripCall = ""
        this.tripEmail = ""
      }
    }
  },
  methods: {
    //전체약관동의
    checkAllConfirm()
    {
      if(this.allConfirmFlag === "Y")
      {
        this.confirm_Terms = "Y"
        this.confirm_spTerms = "Y"
        this.confirm_Info = "Y"
        this.confirm_Privacy = "Y"
        this.confirm_3rd = "Y"
      }
      else if(this.allConfirmFlag === "N")
      {
        this.confirm_Terms = "N"
        this.confirm_spTerms = "N"
        this.confirm_Info = "N"
        this.confirm_Privacy = "N"
        this.confirm_3rd = "N"
      }      
    },

    async getProductInfo()
    {
      let productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});

      if(productInfo.length > 0)
      {
        this.productInfo = productInfo[0]
      }

      //this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);  //게시기간
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart); //판매기간
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      //console.log("productInfo:",this.productInfo);
    },

    async getReserInfo()
    {
      //예약정보마스터
      let reserveInfo = await this.$api("/api/resConsultDetail", {param: [this.reqRsvCode]});
      this.getProductInfo();

      if(reserveInfo.length > 0)
      {
        this.reserveInfo = reserveInfo[0];
        this.productCode = reserveInfo[0].reqPdtCode;
        // this.reserveInfo.conEmail =
        //   (this.reserveInfo.conEmail == "undefined" || this.reserveInfo.conEmail == null) ? this.reserveInfo.reqEmail : this.reserveInfo.conEmail;
        //출발예정일-리턴예정일
        this.reserveInfo.reqDptDate = this.getDateFormat(this.reserveInfo.reqDptDate);
        this.reserveInfo.reqRtnDate = this.getDateFormat(this.reserveInfo.reqRtnDate);
        this.reserveInfo.reqDate = this.getDateFormat(this.reserveInfo.reqDate); //insDate 예약일
        //this.pdtFlag = this.pdtCode.substring(0,2)
        this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"

        this.reqMarginRate = (this.reserveInfo.reqMarginRate == null) ? this.productInfo.basicMargin : this.reserveInfo.reqMarginRate;
        this.reqMarginMethod = (this.reserveInfo.reqMarginMethod == null) ? this.productInfo.marginMethod : this.reserveInfo.reqMarginMethod;

        this.comDC_P = (this.reserveInfo.companionDC_P > 0) ? this.reserveInfo.companionDC_P : 0
        this.comDC_S = (this.reserveInfo.companionDC_S > 0) ? this.reserveInfo.companionDC_S : 0
      }
      //console.log("reserveInfo:>>>>>>>",this.reserveInfo, this.comDC_P, this.comDC_S)

      //권종정보
      let reservePriceInfo = await this.$api("/api/reservePriceInfo", {param: [this.reqRsvCode]});

      if(reserveInfo.length > 0)
      {
        this.reservePriceInfo = reservePriceInfo;
      }
      //console.log("reservePriceInfo:",this.reservePriceInfo)

      //상품 여정일자 리스트
      // let arr_journeyDate = [];
      //   arr_journeyDate = this.getDatesStartToLast(this.reserveInfo.reqDptDate, this.reserveInfo.reqRtnDate)
      //   this.journeyDate = arr_journeyDate;
      //   //console.log("reserveInfo:",this.reserveInfo);

      //적용가능한 프로모션
      this.getPdtPromotion_apply();

      //기본표시총액
      this.calcPrice()

      //지급포인트추출
      this.getPoint(this.reserveInfo.memLevel)

      // 선택된 옵션
      this.getSelOptions_new(this.reqRsvCode)

      // 선택된 추가상품
      this.getSelAddProductions(this.reqRsvCode)
    },
    
    //선택옵션획득(선택옵션 중복허용로직 old logic)
/***************************************************************************
    async getSelOptions(resCode) {
      let selOptions = []
      let useFlag = "Y"
      //let selOptNames_tmp = []

      //selOptions = await this.$api("/api/getSelOptions", {param: [resCode, useFlag]});

      //옵션에 가격정보가 추가되어 수정(20240705)
      selOptions = await this.$api("/api/getSelOptions_new", {param: [resCode, useFlag]});
      console.log("selOptions:", selOptions)
      
      if(selOptions.length > 0)
      {
        this.selOptions = selOptions
        this.selOptFlag = "Y"

        let selOptPriceSelf_gen = []         //선택옵션 기본권종금액(본인)
        let selOptPriceAdu_gen = []
        let selOptPriceChi_gen = []
        let selOptPriceInf_gen = []

        let selOptPriceAdu_ref = []          //선택옵션 환급금액(성인본인)

        let selOptPriceAdu_std = []          //프리미엄선택옵션 스탠다드할인(성인본인)

        let selOptPriceSelf_dc = []          //멤버십본인할인

        let selOptPriceAdu_dc = []           //선택옵션 할인권종금액(동반자)
        let selOptPriceChi_dc = []
        let selOptPriceInf_dc = []

        let tot_selOptPriceSelf_gen = 0     //선택옵션 본인권종 합계금액
        let tot_selOptPrice_gen = 0         //선택옵션 기본권종 합계금액
        let tot_selOptPriceExSelf_gen = 0   //선택옵션 기본권종 합계금액(본인제외)
        let tot_selOptPrice_ref = 0         //선택옵션 환급액 합계금액
        let tot_selOptPrice_std = 0         //프리미엄선택옵션 스탠다드할인 합계금액
        let tot_selOptPriceSelf_dc = 0      //멥버십 본인할인액 합계금액
        let tot_selOptPrice_dc = 0          //선택옵션 할인액 합산금액

        for(let i=0; i<selOptions.length; i++)
        {
          //선택옵션 기본권종가격(1인당)
          selOptPriceSelf_gen[i]= selOptions[i].optPrice*1          //성인본인
          selOptPriceAdu_gen[i] = (selOptions[i].cntAdu > 1) ? selOptions[i].optPrice : 0
          selOptPriceChi_gen[i] = selOptions[i].optPrice_chi
          selOptPriceInf_gen[i] = selOptions[i].optPrice_inf

          //기본성인본인합산
          tot_selOptPriceSelf_gen += selOptPriceSelf_gen[i]
          //기본권종옵션전체합산
          tot_selOptPrice_gen += (selOptPriceSelf_gen[i] + (selOptPriceAdu_gen[i]*(selOptions[i].cntAdu-1)) + (selOptPriceChi_gen[i]*selOptions[i].cntChi) + (selOptPriceInf_gen[i]*selOptions[i].cntInf))
          //기본권종성인본인제외 전체합산
          tot_selOptPriceExSelf_gen += ((selOptPriceAdu_gen[i]*(selOptions[i].cntAdu-1)) + (selOptPriceChi_gen[i]*selOptions[i].cntChi) + (selOptPriceInf_gen[i]*selOptions[i].cntInf))


          //선택옵션 환급금액
          selOptPriceAdu_ref[i] = selOptions[i].optPriceRef_adu*1

          tot_selOptPrice_ref += selOptPriceAdu_ref[i]


          //프리미엄선택옵션 스탠다드할인(성인본인)
          selOptPriceAdu_std[i] = selOptPriceSelf_gen[i] - selOptions[i].optPriceDCForStd*1

          tot_selOptPrice_std += selOptPriceAdu_std[i]

          //멤버십 본인할인
          selOptPriceSelf_dc[i] = selOptPriceSelf_gen[i] - selOptions[i].optPriceDC_adu*1
          tot_selOptPriceSelf_dc += selOptPriceSelf_dc[i]
          

          //선택옵션 할인권종(동반자)
          selOptPriceAdu_dc[i] = (selOptions[i].cntAdu > 1) ? ((selOptPriceAdu_gen[i]*(selOptions[i].cntAdu-1)) - (selOptions[i].optPriceDC_adu*(selOptions[i].cntAdu-1))) : 0
          //selOptPriceAdu_dc[i] = (selOptPriceAdu_gen[i]*selOptions[i].cntAdu) - (selOptions[i].optPriceDC_adu*selOptions[i].cntAdu)
          selOptPriceChi_dc[i] = (selOptPriceChi_gen[i]*selOptions[i].cntChi) - (selOptions[i].optPriceDC_chi*selOptions[i].cntChi)
          selOptPriceInf_dc[i] = (selOptPriceInf_gen[i]*selOptions[i].cntInf) - (selOptions[i].optPriceDC_inf*selOptions[i].cntInf)

          tot_selOptPrice_dc += (selOptPriceAdu_dc[i] + selOptPriceChi_dc[i] + selOptPriceInf_dc[i])

        }
        //console.log("tot_selOptPrice_gen:", tot_selOptPrice_gen, ",tot_selOptPrice_ref:", tot_selOptPrice_ref, ",tot_selOptPrice_std:", tot_selOptPrice_std, ",tot_selOptPrice_dc:", tot_selOptPrice_dc, "tot_selOptPriceSelf_gen:",tot_selOptPriceSelf_gen)
        console.log(tot_selOptPrice_ref,tot_selOptPrice_std,tot_selOptPrice_dc)


        if(this.user.memLevel === "P" || this.user.memLevel === "S")
        {
          if(this.productInfo.salesRoute != "L" || (this.productInfo.salesRoute === "L" && (this.productInfo.nrFlag === "Y" || this.productInfo.vrFlag === "Y")))
          {
            //프리미엄멤버십할인(본인)
            if(this.productInfo.dcForMem_P > 0)
            {
              this.selOptPrice_self = selOptPriceSelf_dc
              this.tot_selOptPrice = tot_selOptPriceSelf_dc + tot_selOptPriceExSelf_gen
            }
            //스탠다드멤버십할인(본인)
            else if(this.productInfo.dcForMem_S > 0)
            {
              this.selOptPrice_self = selOptPriceSelf_dc
              this.tot_selOptPrice = tot_selOptPriceSelf_dc + tot_selOptPriceExSelf_gen
            }
            else
            {
              this.tot_selOptPrice = tot_selOptPrice_gen
            }
          }

          //멤버십 + 로컬투어
          else
          {
            //프리미엄환급 && 동반할인
            if(this.user.memLevel === "P")
            {
              //프리미엄 환급아리
              if(this.productInfo.dcRateForPre > 0 && this.productInfo.memLevel_P === "Y")
              {
                this.selOptPriceAdu_ref = selOptPriceAdu_ref          //프리미엄본인환급
                this.selOptPrice_self   = selOptPriceAdu_gen          //결제 후 환급이므로
                
                if(this.productInfo.companionDC_P > 0)                //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                  //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }
                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_ref = this.calcArrayValue(this.selOptPriceAdu_ref)
                let tot_selOptPrice_adu = this.calcArrayValue(this.selOptPrice_adu)
                let tot_selOptPrice_chi = this.calcArrayValue(this.selOptPrice_chi)
                let tot_selOptPrice_inf = this.calcArrayValue(this.selOptPrice_inf)

                this.tot_selOptPrice_ref = tot_selOptPrice_ref                                                                      //멤버십본인 선택옵션환급총액

                let tot_selOptPrice_exSelf =  tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                        //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                                  //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPriceSelf_gen + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf    //전원옵션총액(본인은 전액지불)
                //console.log("tot_selOptPrice_ref:",tot_selOptPrice_ref, this.tot_selOptPrice_exSelfDC)
              }
              else
              {
                this.selOptPriceAdu_ref = 0                           //환급대상아님
                this.selOptPrice_self   = selOptPriceAdu_gen          //결제 후 환급이므로
                
                if(this.productInfo.companionDC_P > 0)                //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                 //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }
                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_ref = this.calcArrayValue(this.selOptPriceAdu_ref)
                let tot_selOptPrice_adu = this.calcArrayValue(this.selOptPrice_adu)
                let tot_selOptPrice_chi = this.calcArrayValue(this.selOptPrice_chi)
                let tot_selOptPrice_inf = this.calcArrayValue(this.selOptPrice_inf)

                this.tot_selOptPrice_ref = 0                                                                                    //멤버십본인 선택옵션환급총액

                let tot_selOptPrice_exSelf = tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                    //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                              //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_ref + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf    //전원옵션총액
                //console.log("tot_selOptPrice_ref:",tot_selOptPrice_ref, this.tot_selOptPrice_exSelfDC)
              }
              //console.log("pre_tot_selOptPrice:", this.tot_selOptPrice, this.selOptPrice_self)
            }

            //스탠다드환급 || 스탠다드혜택할인 && 동반할인
            else if(this.user.memLevel === "S")
            {
              //스탠다드 환급아리
              if(this.productInfo.dcRateForStd > 0 && this.productInfo.memLevel_S === "Y")
              {
                this.selOptPriceAdu_ref = selOptPriceAdu_ref          //스탠다드 본인환급
                this.selOptPrice_self   = selOptPriceAdu_gen          //결제 후 환급이므로
                
                if(this.productInfo.companionDC_S > 0)                //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                 //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }
                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_ref = this.calcArrayValue(this.selOptPriceAdu_ref)
                let tot_selOptPrice_adu = this.calcArrayValue(this.selOptPrice_adu)
                let tot_selOptPrice_chi = this.calcArrayValue(this.selOptPrice_chi)
                let tot_selOptPrice_inf = this.calcArrayValue(this.selOptPrice_inf)

                this.tot_selOptPrice_ref = tot_selOptPrice_ref                                                                  //멤버십본인 선택옵션환급총액

                let tot_selOptPrice_exSelf = tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                    //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                              //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_ref + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf    //전원옵션총액
                //console.log("tot_selOptPrice_ref:",tot_selOptPrice_ref, this.tot_selOptPrice_exSelfDC)
              }
              //프리미엄의 스탠다드 혜택할인
              else if(this.productInfo.dcForStd > 0)
              {
                this.selOptPrice_self  = selOptPriceAdu_std          //스탠다드 본인할인
                
                if(this.productInfo.companionDC_S > 0)               //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                 //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }
                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_self = this.calcArrayValue(this.selOptPrice_self)
                let tot_selOptPrice_adu = this.calcArrayValue(this.selOptPrice_adu)
                let tot_selOptPrice_chi = this.calcArrayValue(this.selOptPrice_chi)
                let tot_selOptPrice_inf = this.calcArrayValue(this.selOptPrice_inf)

                this.tot_selOptPrice_selfDC = tot_selOptPriceSelf_gen - tot_selOptPrice_self                                    //멤버십본인 선택옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_self                                                                     //멤버십본인 선택옵션총액

                let tot_selOptPrice_exSelf = tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                    //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                              //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_self + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf   //전원옵션총액
                //console.log("tot_selOptPrice_self:",tot_selOptPrice_self, this.tot_selOptPrice_exSelfDC)
              }
              //적용혜택없음
              else
              {
                this.selOptPrice_self  = selOptPriceAdu_gen           //결제 후 환급이므로

                if(this.productInfo.companionDC_S > 0)                //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                  //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }

                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_self = this.calcArrayValue(this.selOptPrice_self)
                let tot_selOptPrice_adu = this.calcArrayValue(this.selOptPrice_adu)
                let tot_selOptPrice_chi = this.calcArrayValue(this.selOptPrice_chi)
                let tot_selOptPrice_inf = this.calcArrayValue(this.selOptPrice_inf)

                this.tot_selOptPrice_ref = 0                                                                                    //멤버십본인 선택옵션환급총액

                let tot_selOptPrice_exSelf = tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                    //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                              //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_self + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf    //전원옵션총액
                //console.log("tot_selOptPrice_self:",tot_selOptPrice_self, this.tot_selOptPrice_exSelfDC)
              }
              //console.log("pre_tot_selOptPrice:", this.tot_selOptPrice, this.selOptPrice_self)
            }
          }
        }

        //일반회원 또는 비회원
        else
        {
          this.selOptPrice_self = selOptPriceSelf_gen
          this.selOptPrice_adu = selOptPriceAdu_gen
          this.selOptPrice_chi = selOptPriceChi_gen
          this.selOptPrice_inf = selOptPriceInf_gen

          this.tot_selOptPrice = tot_selOptPrice_gen
          //console.log("selOptPrices:::::",this.selOptPrice_adu, this.selOptPrice_chi, this.selOptPrice_inf)
          //console.log("tot_selOptPrice:", this.tot_selOptPrice)
        }
      }
      else
      {
        this.selOptFlag  = "N"
        this.selOptNames = "없음"
      }
    },
******************************************** old logic end ***********************************************************/


    //선택옵션획득(선택옵션 중복불가로직 반영: 20240730)
    async getSelOptions_new(resCode) {
      let selOptions = []
      let useFlag = "Y"
      //let selOptNames_tmp = []

      //selOptions = await this.$api("/api/getSelOptions", {param: [resCode, useFlag]});

      //옵션에 가격정보가 추가되어 수정(20240705)
      selOptions = await this.$api("/api/getSelOptions_new", {param: [resCode, useFlag]});
      //console.log("selOptions:", selOptions)
      
      if(selOptions.length > 0)
      {
        this.selOptions = selOptions
        this.selOptFlag = "Y"

        let selOptPriceSelf_gen = 0         //선택옵션 기본권종금액(본인)
        let selOptPriceAdu_gen = 0
        let selOptPriceChi_gen = 0
        let selOptPriceInf_gen = 0
        let selOptPriceAdu_ref = 0          //선택옵션 환급금액(성인본인)

        let selOptPriceAdu_std = 0          //프리미엄선택옵션 스탠다드할인(성인본인)

        let selOptPriceSelf_dc = 0          //멤버십본인할인

        let selOptPriceAdu_dc = 0           //선택옵션 할인권종금액(동반자)
        let selOptPriceChi_dc = 0
        let selOptPriceInf_dc = 0

        let tot_selOptPriceSelf_gen = 0     //선택옵션 본인권종 합계금액
        let tot_selOptPrice_gen = 0         //선택옵션 기본권종 합계금액
        let tot_selOptPriceExSelf_gen = 0   //선택옵션 기본권종 합계금액(본인제외)
        let tot_selOptPrice_ref = 0         //선택옵션 환급액 합계금액
        let tot_selOptPrice_std = 0         //프리미엄선택옵션 스탠다드할인 합계금액
        let tot_selOptPriceSelf_dc = 0      //멥버십 본인할인액 합계금액
        let tot_selOptPrice_dc = 0          //선택옵션 할인액 합산금액

        // for(let i=0; i<selOptions.length; i++)
        // {
          //선택옵션 기본권종가격(1인당)
          selOptPriceSelf_gen= selOptions[0].optPrice*1          //성인본인
          selOptPriceAdu_gen = (selOptions[0].cntAdu > 1) ? selOptions[0].optPrice : 0
          selOptPriceChi_gen = selOptions[0].optPrice_chi
          selOptPriceInf_gen = selOptions[0].optPrice_inf

          //기본성인본인합산
          tot_selOptPriceSelf_gen = selOptPriceSelf_gen
          //기본권종옵션전체합산
          tot_selOptPrice_gen = (selOptPriceSelf_gen + (selOptPriceAdu_gen*(selOptions[0].cntAdu-1)) + (selOptPriceChi_gen*selOptions[0].cntChi) + (selOptPriceInf_gen*selOptions[0].cntInf))
          //기본권종성인본인제외 전체합산
          tot_selOptPriceExSelf_gen = ((selOptPriceAdu_gen*(selOptions[0].cntAdu-1)) + (selOptPriceChi_gen*selOptions[0].cntChi) + (selOptPriceInf_gen*selOptions[0].cntInf))


          //선택옵션 환급금액
          selOptPriceAdu_ref = selOptions[0].optPriceRef_adu*1

          tot_selOptPrice_ref = selOptPriceAdu_ref


          //프리미엄선택옵션 스탠다드할인(성인본인)
          selOptPriceAdu_std = selOptPriceSelf_gen - selOptions[0].optPriceDCForStd*1

          tot_selOptPrice_std = selOptPriceAdu_std

          //멤버십 본인할인
          selOptPriceSelf_dc = selOptPriceSelf_gen - selOptions[0].optPriceDC_adu*1
          tot_selOptPriceSelf_dc = selOptPriceSelf_dc
          

          //선택옵션 할인권종(동반자)
          selOptPriceAdu_dc = (selOptions[0].cntAdu > 1) ? ((selOptPriceAdu_gen*(selOptions[0].cntAdu-1)) - (selOptions[0].optPriceDC_adu*(selOptions[0].cntAdu-1))) : 0
          //selOptPriceAdu_dc[i] = (selOptPriceAdu_gen[i]*selOptions[i].cntAdu) - (selOptions[i].optPriceDC_adu*selOptions[i].cntAdu)
          selOptPriceChi_dc = (selOptPriceChi_gen*selOptions[0].cntChi) - (selOptions[0].optPriceDC_chi*selOptions[0].cntChi)
          selOptPriceInf_dc = (selOptPriceInf_gen*selOptions[0].cntInf) - (selOptions[0].optPriceDC_inf*selOptions[0].cntInf)

          tot_selOptPrice_dc = (selOptPriceAdu_dc + selOptPriceChi_dc + selOptPriceInf_dc)

        // }
        // console.log("tot_selOptPrice_gen:", tot_selOptPrice_gen, ",tot_selOptPrice_ref:", tot_selOptPrice_ref, ",tot_selOptPrice_std:", tot_selOptPrice_std, ",tot_selOptPrice_dc:", tot_selOptPrice_dc, "tot_selOptPriceSelf_gen:",tot_selOptPriceSelf_gen)
        console.log(tot_selOptPrice_ref+"32uuhs32q4",tot_selOptPrice_std+"iidks8873wskk",tot_selOptPrice_dc+"129077redd")


        if(this.user.memLevel === "P" || this.user.memLevel === "S")
        {
          if(this.productInfo.salesRoute != "L" || (this.productInfo.salesRoute === "L" && (this.productInfo.nrFlag === "Y" || this.productInfo.vrFlag === "Y")))
          {
            //프리미엄멤버십할인(본인)
            if(this.productInfo.dcForMem_P > 0)
            {
              this.selOptPrice_self = selOptPriceSelf_dc
              this.tot_selOptPrice = tot_selOptPriceSelf_dc + tot_selOptPriceExSelf_gen
            }
            //스탠다드멤버십할인(본인)
            else if(this.productInfo.dcForMem_S > 0)
            {
              this.selOptPrice_self = selOptPriceSelf_dc
              this.tot_selOptPrice = tot_selOptPriceSelf_dc + tot_selOptPriceExSelf_gen
            }
            else
            {
              this.tot_selOptPrice = tot_selOptPrice_gen
            }
          }

          //멤버십 + 로컬투어
          else
          {
            //프리미엄환급 && 동반할인
            if(this.user.memLevel === "P")
            {
              //프리미엄 환급아리
              if(this.productInfo.dcRateForPre > 0 && this.productInfo.memLevel_P === "Y")
              {
                this.selOptPriceAdu_ref = selOptPriceAdu_ref          //프리미엄본인환급
                this.selOptPrice_self   = selOptPriceAdu_gen          //결제 후 환급이므로
                
                if(this.productInfo.companionDC_P > 0)                //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                  //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }
                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_ref = this.selOptPriceAdu_ref
                let tot_selOptPrice_adu = this.selOptPrice_adu
                let tot_selOptPrice_chi = this.selOptPrice_chi
                let tot_selOptPrice_inf = this.selOptPrice_inf

                this.tot_selOptPrice_ref = tot_selOptPrice_ref                                                                      //멤버십본인 선택옵션환급총액

                let tot_selOptPrice_exSelf =  tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                        //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                                  //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPriceSelf_gen + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf    //전원옵션총액(본인은 전액지불)
                //console.log("tot_selOptPrice_ref:",tot_selOptPrice_ref, this.tot_selOptPrice_exSelfDC)
              }
              else
              {
                this.selOptPriceAdu_ref = 0                           //환급대상아님
                this.selOptPrice_self   = selOptPriceAdu_gen          //결제 후 환급이므로
                
                if(this.productInfo.companionDC_P > 0)                //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                 //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }
                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_ref = this.selOptPriceAdu_ref
                let tot_selOptPrice_adu = this.selOptPrice_adu
                let tot_selOptPrice_chi = this.selOptPrice_chi
                let tot_selOptPrice_inf = this.selOptPrice_inf

                this.tot_selOptPrice_ref = 0                                                                                    //멤버십본인 선택옵션환급총액

                let tot_selOptPrice_exSelf = tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                    //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                              //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_ref + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf    //전원옵션총액
                //console.log("tot_selOptPrice_ref:",tot_selOptPrice_ref, this.tot_selOptPrice_exSelfDC)
              }
              //console.log("pre_tot_selOptPrice:", this.tot_selOptPrice, this.selOptPrice_self)
            }

            //스탠다드환급 || 스탠다드혜택할인 && 동반할인
            else if(this.user.memLevel === "S")
            {
              //스탠다드 환급아리
              if(this.productInfo.dcRateForStd > 0 && this.productInfo.memLevel_S === "Y")
              {
                this.selOptPriceAdu_ref = selOptPriceAdu_ref          //스탠다드 본인환급
                this.selOptPrice_self   = selOptPriceAdu_gen          //결제 후 환급이므로
                
                if(this.productInfo.companionDC_S > 0)                //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                 //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }
                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_ref = this.selOptPriceAdu_ref
                let tot_selOptPrice_adu = this.selOptPrice_adu
                let tot_selOptPrice_chi = this.selOptPrice_chi
                let tot_selOptPrice_inf = this.selOptPrice_inf

                this.tot_selOptPrice_ref = tot_selOptPrice_ref                                                                  //멤버십본인 선택옵션환급총액

                let tot_selOptPrice_exSelf = tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                    //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                              //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_ref + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf    //전원옵션총액
                //console.log("tot_selOptPrice_ref:",tot_selOptPrice_ref, this.tot_selOptPrice_exSelfDC)
              }
              //프리미엄의 스탠다드 혜택할인
              else if(this.productInfo.dcForStd > 0)
              {
                this.selOptPrice_self  = selOptPriceAdu_std          //스탠다드 본인할인
                
                if(this.productInfo.companionDC_S > 0)               //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                 //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }
                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_self = this.selOptPrice_self
                let tot_selOptPrice_adu  = this.selOptPrice_adu
                let tot_selOptPrice_chi  = this.selOptPrice_chi
                let tot_selOptPrice_inf  = this.selOptPrice_inf

                this.tot_selOptPrice_selfDC = tot_selOptPriceSelf_gen - tot_selOptPrice_self                                    //멤버십본인 선택옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_self                                                                     //멤버십본인 선택옵션총액

                let tot_selOptPrice_exSelf = tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                    //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                              //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_self + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf   //전원옵션총액
                //console.log("tot_selOptPrice_self:",tot_selOptPrice_self, this.tot_selOptPrice_exSelfDC)
              }
              //적용혜택없음
              else
              {
                this.selOptPrice_self  = selOptPriceAdu_gen           //결제 후 환급이므로

                if(this.productInfo.companionDC_S > 0)                //동반할인된 금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_dc
                  this.selOptPrice_chi = selOptPriceChi_dc
                  this.selOptPrice_inf = selOptPriceInf_dc
                }
                else                                                  //동반할인이 아니면 일반권종금액
                {
                  this.selOptPrice_adu = selOptPriceAdu_gen
                  this.selOptPrice_chi = selOptPriceChi_gen
                  this.selOptPrice_inf = selOptPriceInf_gen
                }

                //각 배열값들의 합산액을 계산하여 선택옵션총액을 산출
                let tot_selOptPrice_self = this.selOptPrice_self
                let tot_selOptPrice_adu  = this.selOptPrice_adu
                let tot_selOptPrice_chi  = this.selOptPrice_chi
                let tot_selOptPrice_inf  = this.selOptPrice_inf

                this.tot_selOptPrice_ref = 0                                                                                    //멤버십본인 선택옵션환급총액

                let tot_selOptPrice_exSelf = tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf                    //동반인 옵션총액
                this.tot_selOptPrice_exSelfDC = tot_selOptPriceExSelf_gen - tot_selOptPrice_exSelf                              //동반인 옵션할인총액
                this.tot_selOptPrice = tot_selOptPrice_self + tot_selOptPrice_adu + tot_selOptPrice_chi + tot_selOptPrice_inf    //전원옵션총액
                //console.log("tot_selOptPrice_self:",tot_selOptPrice_self, this.tot_selOptPrice_exSelfDC)
              }
              //console.log("pre_tot_selOptPrice:", this.tot_selOptPrice, this.selOptPrice_self)
            }
          }
        }

        //일반회원 또는 비회원
        else
        {
          this.selOptPrice_self = selOptPriceSelf_gen
          this.selOptPrice_adu = selOptPriceAdu_gen
          this.selOptPrice_chi = selOptPriceChi_gen
          this.selOptPrice_inf = selOptPriceInf_gen

          this.tot_selOptPrice = tot_selOptPrice_gen
          //console.log("selOptPrices:::::",this.selOptPrice_adu, this.selOptPrice_chi, this.selOptPrice_inf)
          //console.log("tot_selOptPrice:", this.tot_selOptPrice)
        }
      }
      else
      {
        this.selOptFlag  = "N"
        this.selOptNames = "없음"
      }
    },


    calcArrayValue(arrData){
      //받은 배열의 값을 합산(acc:신규값, curVal:누적값, 0:초기값)
      let sum = arrData.reduce((acc, curVal) => {
        return acc + curVal
      },0)

      return sum
    },

    //선택추가상품획득
    async getSelAddProductions(resCode) {
      let selAddPdt = []
      let useFlag = "Y"
      selAddPdt = await this.$api("/api/getSelAddProductions", {param: [resCode, useFlag]});

      if(selAddPdt.length > 0)
      {
        this.selAddPdt = selAddPdt
        this.selAddFlag = "Y"
        
        //선택추상가격*수량
        selAddPdt.forEach(sa => {
          this.tot_selAddPdtPrice += (sa.addPdtPrice * sa.qty_addPdt)
          this.selAddPdtNames.push(sa.addPdtName)
        });
      }
      else
      {
        this.selAddFlag = "N"
        this.selAddPdtNames = "없음"
      }
      //console.log("selAddPdtNames:", this.selAddPdtNames)
      //console.log("selAddPdt:",this.selAddPdt, "tot_selAddPdtPrice:", this.tot_selAddPdtPrice);
    },

    //지급포인트
    getPoint(val){
      if(val === 'P' || val === 'S')
      {
        this.uPoint = this.productInfo.userPoint * 2
      }
      else
      {
        this.uPoint = this.productInfo.userPoint
      }
    },

    async calcPrice(){
      if(this.reserveInfo.reqAdultCnt === 0)
      {
        this.totalPrice = 0
        this.tot_price_mem = 0
        this.tot_comDC = 0
        this.showPrice_P = 0

        this.$swal.fire("","예약진행이 불가능한 인원수입니다.","warning")
        location.reload(true)
      }
      else
      {
        let tot_adultPrice =  0
        let tot_childPrice =  0
        let tot_infantPrice = 0

        //동반할인액 합산
        let tot_comDC = 0
        //let tot_comDC = 0

        //권종별 기본합산액
        tot_adultPrice =  this.reserveInfo.reqAdultPrice * this.reserveInfo.reqAdultCnt
        tot_childPrice =  this.reserveInfo.reqChildPrice * this.reserveInfo.reqChildCnt
        tot_infantPrice = this.reserveInfo.reqInfPrice * this.reserveInfo.reqInfCnt

        let salesRoute = this.reserveInfo.reqPdtCode.charAt(0)
        //멤버십 가격표시(가입유도용)
        if(this.reserveInfo.memLevel_P == 'Y' || this.reserveInfo.memLevel_S == 'Y')
        {
          if(salesRoute === 'L' && (this.reserveInfo.nrFlag != 'Y' && this.reserveInfo.vrFlag != 'Y'))
          {
            if(this.reserveInfo.reqAdultCnt ==0)
            {
              this.showPrice_S = ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

              this.showPrice_P = ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)              
            }
            else if(this.reserveInfo.reqAdultCnt == 1)
            {
              this.showPrice_S = (this.reserveInfo.reqAdultPrice - (this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcRateForStd/100))) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

              this.showPrice_P = (this.reserveInfo.reqAdultPrice - (this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcRateForPre/100))) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)
            }
            else if(this.reserveInfo.reqAdultCnt >= 2)
            {
              this.showPrice_S = ((this.reserveInfo.reqAdultPrice * 1 - (this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcRateForStd/100))) + (this.reserveInfo.reqAdultPrice - (this.reserveInfo.reqAdultPrice * this.reserveInfo.companionDC_S/100)) * (this.reserveInfo.reqAdultCnt-1)) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

              this.showPrice_P = ((this.reserveInfo.reqAdultPrice * 1 - (this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcRateForPre/100))) + (this.reserveInfo.reqAdultPrice - (this.reserveInfo.reqAdultPrice * this.reserveInfo.companionDC_P/100)) * (this.reserveInfo.reqAdultCnt-1)) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)
            }
          }

          // //프리미엄전용 + 스탠다드혜택
          if((this.reserveInfo.memLevel_S === 'N' || this.reserveInfo.memLevel_S == null) && this.reserveInfo.dcForStd*1 > 0)
          {
            if(this.reserveInfo.reqAdultCnt == 0)
            {
              this.showPrice_S = ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

              this.showPrice_P = ((this.reserveInfo.reqChildPrice  - (this.reserveInfo.reqChildPrice  * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)
            }
            else if(this.reserveInfo.reqAdultCnt == 1)
            {
              this.showPrice_S = (this.reserveInfo.reqAdultPrice * this.reserveInfo.reqAdultCnt - (this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcForStd/100))) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

              this.showPrice_P = (this.reserveInfo.reqAdultPrice * this.reserveInfo.reqAdultCnt - (this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcRateForPre/100))) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)
            }          
            else if(this.reserveInfo.reqAdultCnt >= 2)
            {
              this.showPrice_S = ((this.reserveInfo.reqAdultPrice * 1 - (this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcForStd/100))) + (this.reserveInfo.reqAdultPrice - (this.reserveInfo.reqAdultPrice * this.reserveInfo.companionDC_S/100)) * (this.reserveInfo.reqAdultCnt-1)) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

              this.showPrice_P = ((this.reserveInfo.reqAdultPrice * 1 - (this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcRateForPre/100))) + (this.reserveInfo.reqAdultPrice- (this.reserveInfo.reqAdultPrice * this.reserveInfo.companionDC_P/100)) * (this.reserveInfo.reqAdultCnt-1)) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)
            }
          }          
        }
        //일반상품 케이스
        else
        {
          let n_dc_P = 0
          let n_dc_S = 0

          //할인방법반영
          if(this.reserveInfo.dcRateForMem_P === 'P')
          {
            n_dc_P = this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcForMem_P/100)
          }
          else
          {
            n_dc_P = this.reserveInfo.dcForMem_P
          }

          if(this.reserveInfo.dcRateForMem_S === 'P')
          {
            n_dc_S = this.reserveInfo.reqAdultPrice * (this.reserveInfo.dcForMem_S/100)
          }
          else
          {
            n_dc_S = this.reserveInfo.dcForMem_S
          }

          if(this.reserveInfo.reqAdultCnt == 0)
          {
            this.showPrice_S = ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

            this.showPrice_P = ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)
          }
          else if(this.reserveInfo.reqAdultCnt == 1)
          {
            this.showPrice_S = (this.reserveInfo.reqAdultPrice * this.reserveInfo.reqAdultCnt - n_dc_S) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

            this.showPrice_P = (this.reserveInfo.reqAdultPrice * this.reserveInfo.reqAdultCnt - n_dc_P) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)
          }
          else if(this.reserveInfo.reqAdultCnt >= 2)
          {
            this.showPrice_S = (this.reserveInfo.reqAdultPrice * 1 - n_dc_S) + ((this.reserveInfo.reqAdultPrice - (this.reserveInfo.reqAdultPrice * this.reserveInfo.companionDC_S/100)) * (this.reserveInfo.reqAdultCnt-1)) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_S/100)) * this.reserveInfo.reqInfCnt)

            this.showPrice_P = (this.reserveInfo.reqAdultPrice * 1 - n_dc_P) + ((this.reserveInfo.reqAdultPrice - (this.reserveInfo.reqAdultPrice * this.reserveInfo.companionDC_P/100)) * (this.reserveInfo.reqAdultCnt-1)) + ((this.reserveInfo.reqChildPrice - (this.reserveInfo.reqChildPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice - (this.reserveInfo.reqInfPrice * this.reserveInfo.companionDC_P/100)) * this.reserveInfo.reqInfCnt)
          }          
        }

        //동반할인 반영
        //프리미엄 동반할인(성인1인은 제외)
        if(this.user.memLevel === 'P')
        {
          if(this.reserveInfo.reqAdultCnt < 2)
          {
            tot_comDC = ((this.reserveInfo.reqChildPrice*(this.comDC_P/100))*this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice*(this.comDC_P/100))*this.reserveInfo.reqInfCnt)
          }
          else if(this.reserveInfo.reqAdultCnt >= 2)
          {
            tot_comDC = ((this.reserveInfo.reqAdultPrice*(this.comDC_P/100))*(this.reserveInfo.reqAdultCnt-1)) + ((this.reserveInfo.reqChildPrice*(this.comDC_P/100))*this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice*(this.comDC_P/100))*this.reserveInfo.reqInfCnt)
          }        
          //console.log("tot_comDC_P:>>>>>>", tot_comDC)
        }
        else if(this.user.memLevel === 'S')
        {
          if(this.reserveInfo.reqAdultCnt < 2)
          {
            tot_comDC = ((this.reserveInfo.reqChildPrice*(this.comDC_S/100))*this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice*(this.comDC_S/100))*this.reserveInfo.reqInfCnt)
          }
          else if(this.reserveInfo.reqAdultCnt >= 2)
          {
            tot_comDC = ((this.reserveInfo.reqAdultPrice*(this.comDC_S/100))*(this.reserveInfo.reqAdultCnt-1)) + ((this.reserveInfo.reqChildPrice*(this.comDC_S/100))*this.reserveInfo.reqChildCnt) + ((this.reserveInfo.reqInfPrice*(this.comDC_S/100))*this.reserveInfo.reqInfCnt)
          }
          //console.log("tot_comDC_S:>>>>>>", tot_comDC)
        }

        this.tot_comDC = tot_comDC

        //환급처리(당사자만)
        if((this.reserveInfo.memLevel === 'P' || this.reserveInfo.memLevel === 'S') && (this.reserveInfo.dispFlag === 'c1' || this.reserveInfo.dispFlag === 'c3'))
        {
          //this.tot_adultPrice_mem =  this.reserveInfo.reqAdultMemP * this.reserveInfo.reqAdultCnt
          // this.tot_childPrice_mem =  this.reserveInfo.reqChildMemP * this.reserveInfo.reqChildCnt
          // this.tot_infantPrice_mem = this.reserveInfo.reqInfMemP * this.reserveInfo.reqInfCnt

          if(this.reserveInfo.reqAdultCnt > 0)
          {
            this.tot_adultPrice_mem =  this.reserveInfo.reqAdultMemP * 1
          }
          else
          {
            this.tot_adultPrice_mem =  0
          }

          this.tot_childPrice_mem =  0
          this.tot_infantPrice_mem = 0
        }
        //할인처리
        else if((this.reserveInfo.memLevel === 'P' || this.reserveInfo.memLevel === 'S') && (this.reserveInfo.dispFlag === 'c2' || this.reserveInfo.dispFlag === 'c4' || this.reserveInfo.dispFlag === 'c5'))
        {
          // this.tot_adultPrice_mem =  this.reserveInfo.reqAdultMemP * this.reserveInfo.reqAdultCnt
          // this.tot_childPrice_mem =  this.reserveInfo.reqChildMemP * this.reserveInfo.reqChildCnt
          // this.tot_infantPrice_mem = this.reserveInfo.reqInfMemP * this.reserveInfo.reqInfCnt

          if(this.reserveInfo.reqAdultCnt > 0)
          {
            this.tot_adultPrice_mem =  this.reserveInfo.reqAdultMemP * 1
          }
          else
          {
            this.tot_adultPrice_mem =  0
          }
          this.tot_childPrice_mem =  0
          this.tot_infantPrice_mem = 0
        }

        this.tot_perCount = this.reserveInfo.reqAdultCnt+this.reserveInfo.reqChildCnt+this.reserveInfo.reqInfCnt
        this.tot_price_mem = this.tot_adultPrice_mem*1 + this.tot_childPrice_mem*1 + this.tot_infantPrice_mem*1 
        

        let totalPrice_tmp = 0        //동반자 할인 등 적용 전 총액
        if((this.reserveInfo.memLevel === 'P' || this.reserveInfo.memLevel === 'S') && (this.reserveInfo.dispFlag === 'c1' || this.reserveInfo.dispFlag === 'c3'))
        {
          totalPrice_tmp = tot_adultPrice + tot_childPrice + tot_infantPrice //+ tot_checkedOptPrice
        }
        else if((this.reserveInfo.memLevel === 'P' || this.reserveInfo.memLevel === 'S') && (this.reserveInfo.dispFlag === 'c2' || this.reserveInfo.dispFlag === 'c4' || this.reserveInfo.dispFlag === 'c5'))
        {
          //totalPrice_tmp = tot_adultPrice + tot_childPrice + tot_infantPrice + tot_checkedOptPrice - this.tot_price_mem
          totalPrice_tmp = tot_adultPrice + tot_childPrice + tot_infantPrice - this.tot_price_mem
        }
        else
        {
          totalPrice_tmp = tot_adultPrice + tot_childPrice + tot_infantPrice // + tot_checkedOptPrice
        }

        this.totalPrice = totalPrice_tmp*1 - tot_comDC*1
        // if(this.reserveInfo.reqAdultCnt == 0 && this.reserveInfo.reqChildCnt == 0 && this.reserveInfo.reqInfCnt == 0)
        // {
        //   this.totalPrice = 0  
        // }
        // else
        // {
        //   this.totalPrice = totalPrice_tmp*1 - tot_comDC*1
        // }

        //추가상품가격 합산처리
        //this.totalPrice = this.totalPrice + this.tot_selAddPdtPrice
      }
    },

    /** 두날짜 사이의 날짜추출함수 */
    getDatesStartToLast(startDate, lastDate) {
      //var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      //if(!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
      if(!(startDate && lastDate)) return "Not Date Format";

      var result = [];
      var curDate = new Date(startDate);
      while(curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    },

    //적용가능한 프로모션정보
    async getPdtPromotion_apply() {
      let pdtPromoInfo_apply = await this.$api("/api/pdtPromo_apply", {param: [this.productId, this.reserveInfo.reqDptDate]});
      
      if(pdtPromoInfo_apply.length > 0) {
        this.pdtPromoInfo_apply = pdtPromoInfo_apply[0];
        if(this.pdtPromoInfo_apply.promoStart != undefined){
          this.pdtPromoInfo_apply.promoStart = this.getDateFormat(this.pdtPromoInfo_apply.promoStart);
        }
        if(this.pdtPromoInfo_apply.promoEnd != undefined) {
          this.pdtPromoInfo_apply.promoEnd = this.getDateFormat(this.pdtPromoInfo_apply.promoEnd);
        }
        this.aPromoFlag = "Y"
      }
      
      //console.log("pdtPromoInfo_apply:",this.pdtPromoInfo_apply,"/", pdtPromoInfo_apply.length);
    },
  
    /** 여정기간 중 상품가격합산식 */
    calcCstPrice()
    {
      //alert("Calc!");
      let aduPrice = 0;
      let chiPrice = 0;
      let infPrice = 0;
      let disPrice = 0;
      let pdtPrice = 0;

      //여행참가인원수
      let personCnt = (this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1);
      this.personCnt = personCnt*1;
      //console.log("personCnt:",personCnt);

      //1권종가격기준
      if(this.reserveInfo.tripProp === "D")
      {
        aduPrice = (this.reserveInfo.reqAdultPrice*1) * (this.reserveInfo.reqAdultCnt*1);
        chiPrice = (this.reserveInfo.reqChildPrice*1) * (this.reserveInfo.reqChildCnt*1);
        infPrice = (this.reserveInfo.reqInfPrice*1) * (this.reserveInfo.reqInfCnt*1);
        disPrice = (this.reserveInfo.reqDiscountPrice*1) * (this.reserveInfo.reqDiscountCnt*1);
        pdtPrice = (aduPrice*1) + (chiPrice*1) + (infPrice*1) + (disPrice*1);
      }
      else //F속성의 daily price를 배열로 취득
      {
        let fAduPrice = document.getElementsByName("fAduPrice[]");
        let fChiPrice = document.getElementsByName("fChiPrice[]");
        let fInfPrice = document.getElementsByName("fInfPrice[]");
        let fDisPrice = document.getElementsByName("fDisPrice[]");

        let i = 0;
        while(i<fAduPrice.length)
        {
          aduPrice += (fAduPrice[i].value)*1;
          chiPrice += (fChiPrice[i].value)*1;
          infPrice += (fInfPrice[i].value)*1;
          disPrice += (fDisPrice[i].value)*1;
          i++;
        }
        pdtPrice = aduPrice*1 + chiPrice*1 + infPrice*1 + disPrice*1;
        //console.log("pdtPrice:",pdtPrice);
      }
      //2.마진적용
      //let margin = 0;
      let pdtPriceMargin = 0;
      if(this.reqMarginMethod ==="A")
      {
        //pdtPrice = (pdtPrice) + (this.productInfo.basicMargin*1);
        pdtPriceMargin = this.reqMarginRate*1
      }
      else
      {
        if(this.reqMarginRate*1 > 0)
        {
          //pdtPrice = pdtPrice + (pdtPrice * (this.productInfo.basicMargin/100));
          pdtPriceMargin = (pdtPrice*1) * ((this.reqMarginRate*1)/100);
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
        else
        {
          //pdtPrice = pdtPrice + 0;
          pdtPriceMargin = 0;
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
      }
      this.reserveInfo.pdtMargin = pdtPriceMargin*1;
      pdtPrice = pdtPrice*1 + pdtPriceMargin*1;

      //3.프로모션 적용
      let promoRateApply = this.pdtPromoInfo_apply.promoRate * 1;
      let promoMethodApply = this.pdtPromoInfo_apply.promoMethod;
      let pdtPromo = 0;
      //let pdtPrice_promo = 0;
      if(promoMethodApply === "A") {
        pdtPromo = promoRateApply*1;
        //pdtPrice = pdtPrice - promoRateApply;
      }
      else if(promoMethodApply === "P") {
        pdtPromo = (pdtPrice*1) * ((promoRateApply*1)/100);
        //pdtPrice = pdtPrice - (pdtPrice * (promoRateApply/100));
      }
      else {
        pdtPromo = 0;
      }
      //console.log("pdtPromo:", this.pdtPromo)
      this.reserveInfo.pdtPromo = pdtPromo*1;
      pdtPrice = pdtPrice*1 - pdtPromo*1;

      //4.payPoint처리
      if(this.reserveInfo.payPoint > 0)
      {
        this.payPointFlag = "Y";
        pdtPrice = pdtPrice*1 - (this.reserveInfo.payPoint*1);
      }
      //console.log("pdtPricePPoint:",pdtPrice);

      //6.부가세 적용
      let vatR = 0;
      //console.log("vat:R",vatR);
      if(this.reserveInfo.reqVatPrice > 0)
      {
        vatR = (pdtPrice*1) * ((this.reserveInfo.reqVatPrice*1)/100);
      }
      else
      {
        vatR = 0;
      }
      this.reserveInfo.vatPrice = vatR;
      pdtPrice = pdtPrice*1 + vatR*1;


      //7.소숫점아래 절사처리
      pdtPrice = Math.floor(pdtPrice);

      this.cstPrice = pdtPrice*1;

      // //8. 추가상품의 합산액을 더함
      // this.cstPrice  = this.cstPrice + this.tot_selAddPdtPrice*1
    },

    //기존상품 프로모션정보
    async getPdtPromotion() {
      let pdtPromoInfo = await this.$api("/api/pdtPromo_existed", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0];
        if(this.pdtPromoInfo.promoStart != undefined){
          this.pdtPromoInfo.promoStart = this.getDateFormat(this.pdtPromoInfo.promoStart);
        }
        if(this.pdtPromoInfo.promoEnd != undefined) {
          this.pdtPromoInfo.promoEnd = this.getDateFormat(this.pdtPromoInfo.promoEnd);
        }
      }
      //console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },


    //상품프로모션
    async getProductPromotion() {
      let productPromotion = await this.$api("/api/productPromotion", {param: [this.productId]});
      //console.log("pPromo:",productPromotion);

      if(productPromotion.length > 0) {
        this.productPromotion = productPromotion[0]
      }
      else{
        return false;
      }
      //console.log("productPromotion: ",this.productPromotion.promoName);
    },
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      if(cityList.length > 0) {
        this.cityList = cityList;
      }
      //console.log("nationList",nationList);
    },
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },

    ///////////공통함수
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getTruncateToWon(value){
      return this.$truncateToWon(value)
    },

    async getAreaList() {
      this.areaList_tmp = await this.$api("/api/areaList", {});
    },
    // changeNationList() {
    //   //this.nationCode = this.productInfo.baseCode;
    //   this.viewAreaList(this.productInfo.ntCd);
    // },
    viewAreaList(param1){
      //console.log("selectedNation:", param);
      this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },

    //상품가격
    async getProductPrice() {
      let productPrice = await this.$api("/api/productPrice", {param: [this.pdtCode]});
      this.productPrice = productPrice;
      
      // if(this.productInfo.etcOptionFlag === "Y") 
      // {
      //   this.getEtcOptionInfo();
      // }
    },

    async getPromotionList() {
      let promotionList = await this.$api("/api/promotionList", {param: [this.productId]});
      this.promoList = promotionList;
      // console.log("promoList:",this.promoList, "pDetail:",this.productDetail.deliCategory);
    },
    goToList() {
      //this.$router.push({path: '/reserveInfoList_client'});
      this.$router.push({path: '/'});
    },
    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },

    //예약컨펌창
    showConfirm(){
      this.reserConfirm = "Y"
    },
    clsoeConfirm()
    {
      const reserConfirm = document.querySelector('.modal');
      reserConfirm.style.display = 'none';
    },    

    checkMobile(val){
      const koreanMobileRegex = /^01[0]\d{3,4}\d{4}$/;
      return koreanMobileRegex.test(val);
    },

    checkEmail(val){
      const emailRegex = /^(?!.*\.{2})[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return emailRegex.test(val);
    },

    //예약완료
    async reserComplete(rCode, payFlag){
      //console.log(payFlag)
      if(this.reserveInfo.reqAdultCnt <= 0 && this.reserveInfo.reqChildCnt <= 0)
      {
        this.$swal.fire("","인원수를 체크해주세요.","warning")
        return false
      }

      if(!this.resName)
      {
        this.$refs.resName.focus()
        this.$swal.fire("","예약자명을 입력하세요.","warning")
        return false
      }

      if(!this.resCall)
      {
        this.$refs.resCall.focus()
        this.$swal.fire("","예약자의 연락처를 입력하세요.","warning")
        return false
      }
      else
      {
        let checkResult = this.checkMobile(this.resCall)      //mobile number 유효체크
        if(!checkResult)
        {
          this.$refs.resCall.select()
          this.$swal.fire("","유효한 전화번호가 아닙니다.","warning")
          return false
        }
      }

      if(this.myselfFlag === 'N')
      {
        if(!this.tripName)
        {
          this.$refs.tripName.focus()
          this.$swal.fire("","여행객명을 입력하세요.","warning")
          return false
        }

        if(!this.tripCall)
        {
          this.$refs.tripCall.focus()
          this.$swal.fire("","여행객의 연락처를 입력하세요.","warning")
          return false
        }
        else
        {
          let checkResult = this.checkMobile(this.tripCall)      //mobile number 유효체크
          if(!checkResult)
          {
            this.$refs.tripCall.select()
            this.$swal.fire("","유효한 전화번호가 아닙니다.","warning")
            return false
          }
        }
      }

      if(this.confirm_spTerms != "Y")
      {
        this.$refs.confirm_spTerms.focus()
        this.$swal.fire("","특별약관에 동의를 해주세요","warning")
        return false
      }

      if(this.confirm_Terms != "Y")
      {
        this.$refs.confirm_Terms.focus()
        this.$swal.fire("","약관에 동의를 해주세요","warning")
        return false
      }

      if(this.confirm_Info != "Y")
      {
        this.$refs.confirm_Info.focus()
        this.$swal.fire("","개인정보수집이용에 동의를 해주세요","warning")
        return false
      }

      if(this.confirm_Privacy != "Y")
      {
        this.$refs.confirm_Privacy.focus()
        this.$swal.fire("","고유식별정보수집이용에 동의를 해주세요","warning")
        return false
      }
      
      if(this.confirm_3rd != "Y")
      {
        this.$refs.confirm_3rd.focus()
        this.$swal.fire("","개인정보3자제공에 동의를 해주세요","warning")
        return false
      }

      let reqDiscountCnt = 0
      let reqDiscountPrice = 0
      let completeFlag = "N"            //결제가 완료될 때까지 N(변경된 결제프로세스 반영)
      let reqDiscountMemP = 0

      //1차결제액 설정(전액결제진행)
      let totalPayAmount = this.totalPrice + this.tot_selOptPrice + this.tot_selAddPdtPrice     //결제할 총액
      let firstPayment = 0  //1차결제액
      let balance = 0       //잔액(2차결제액)
      let pFlag = ""

      if(payFlag === "fu")
      {
        //firstPayment = this.getTruncateToWon((this.totalPrice + this.tot_selAddPdtPrice + this.tot_selOptPrice))
        firstPayment = this.getTruncateToWon(totalPayAmount)
      }
      else if(payFlag === "p1")
      {
        if(this.productInfo.paymentMethod === "P")
        {
          // firstPayment = this.getTruncateToWon((this.totalPrice + this.tot_selAddPdtPrice + this.tot_selOptPrice) * (this.productInfo.firstPayment/100))
          firstPayment = this.getTruncateToWon(totalPayAmount * (this.productInfo.firstPayment/100))
        }

        else if(this.productInfo.paymentMethod === "A")
        {
          firstPayment = this.getTruncateToWon(this.productInfo.firstPayment * 1)
        }
      }
      // p1 or fu
      pFlag = payFlag
      this.payStyle= payFlag
      
      //balance = (this.totalPrice + this.tot_selAddPdtPrice + this.tot_selOptPrice) - firstPayment        //잔액계산
      balance = totalPayAmount - firstPayment        //잔액계산

      this.firstPayment = firstPayment
      this.balance = balance
      //console.log("firstPayment:", firstPayment, "balance:", this.balance)

      let upd_completeReserInfo = await this.$api("/api/proc_updCompleteReserve", {param:[
        rCode,
        this.reserveInfo.reqInfCnt, this.reserveInfo.reqAdultCnt, this.reserveInfo.reqChildCnt, reqDiscountCnt, this.tot_perCount, 
        this.askQuestion, completeFlag, this.resName, this.resCall, this.resEmail,
        //(this.totalPrice + this.tot_selAddPdtPrice + this.tot_selOptPrice), this.myselfFlag, this.tripName, this.tripCall, this.tripEmail, 
        totalPayAmount, this.myselfFlag, this.tripName, this.tripCall, this.tripEmail, 
        this.confirm_spTerms, this.confirm_Terms, this.confirm_Info, this.confirm_Privacy, this.confirm_3rd,
        this.reserveInfo.reqAdultPrice, this.reserveInfo.reqChildPrice, this.reserveInfo.reqInfPrice, reqDiscountPrice, this.productInfo.userPointMethod,this.uPoint,
        this.reserveInfo.dispFlag, this.reserveInfo.reqAdultMemP, this.reserveInfo.reqChildMemP, this.reserveInfo.reqInfMemP, reqDiscountMemP,
        this.tot_comDC, this.selOptFlag, this.selAddFlag, firstPayment, balance, this.tot_selOptPrice
      ]})
      if(upd_completeReserInfo.affectedRows >= 0)
      {
        this.pgPay(rCode, firstPayment, pFlag)
      }
      else
      {
        this.$swal.fire('','<b class="text-danger">죄송합니다</b><br>처리 중 문제가 발생했습니다.('+ upd_completeReserInfo.error.sqlMessage +')', 'warning')
        return false;        
      }
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
    },

    /** 카카오알림 발송(예약접수BO) */
    //param: BO/BO_non
    async sendKakao(param, resCode)
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        resCode, param, this.resCall
      ]});
      //console.log("insKSendHistory:",insKSendHistory);

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.resCall})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows >= 0) 
      {
        let kakaoAlimData = {}

        if(param == "BO") 
        {
          //카카오 알림톡 발송(비회원 예약접수)
          if(this.reserveInfo.resMFlag === "N")
          {
            let i = 0
            while(i < this.handTelCnt)
            {
              kakaoAlimData = 
              {
                clientName: this.resName,               //수신자명
                clientCall: this.handTel[i].aimCall,    //수신자전번
                resName: this.productInfo.pdtNameKor,   //예약상품
                resPaxName: this.resName,               //예약자명
                resPaxCnt: this.tot_perCount,           //여행인원
                dptDate: this.reserveInfo.reqDptDate,   //여행일자(출발일)
                resPhone: this.resCall,                 //대표연락처
                resCode: resCode,                       //예약번호
                alimFlag: "nonBO",                      //알림유형(예약접수)
                // resSelOpt: this.selOptNames,            //선택옵션명
                // resSelAdd: this.selAddPdtNames          //선택추상명
                //toNumber: this.handTel[i].aimCall       //알림톡 발송처
                //alimFlag: "r"
              };

              //예약접수안내 API호출
              this.$api("api/kakaoAlimSend2", {
                param: [
                  { kakaoAlimData: kakaoAlimData},
                ]
              }).then((res) => {console.log(res)});
              i++
            }
          }

          //카카오 알림톡 발송(회원 예약접수)
          else
          {
            let j = 0
            while(j < this.handTelCnt)
            {
              kakaoAlimData = 
              {
                clientName: this.k_name,                //수신자명
                clientCall: this.handTel[j].aimCall,    //수신자전번
                resName: this.productInfo.pdtNameKor,   //예약상품
                resPaxName: this.resName,               //예약자명
                resPaxCnt: this.tot_perCount,           //여행인원
                dptDate: this.reserveInfo.reqDptDate,   //여행일자(출발일)
                resPhone: this.resCall,                 //대표연락처
                resCode: resCode,                       //예약번호
                alimFlag: param,                        //알림유형(예약접수)
                // resSelOpt: this.selOptNames,            //선택옵션명
                // resSelAdd: this.selAddPdtNames          //선택추상명
                //toNumber: this.handTel[j].aimCall       //알림톡 발송처
                //alimFlag: "r"
              };

              //예약접수안내 API호출
              this.$api("api/kakaoAlimSend2", {
                param: [
                  { kakaoAlimData: kakaoAlimData},
                ]
              }).then((res) => {console.log(res)});
              j++
            }
          }
        }
      }
    },

    //결제요청 정보처리
    async insReqPay(rCode, stat, payAmt, resEmail, pFlag, rCode_org)    //pFlag: p1,p2(1차,2차결제) / fu,NULL(전액결제)
    {
      // 중복클릭 시 update처리(프로지서로 변경)
      // let insReqPayInfo = await this.$api("/api/insReqPayInfo_1",{param: [
      //   //this.reqRsvCode,this.reserveInfo.reqStatus,this.cstPrice,'N',this.user.handlerId
      //   rCode, stat, payAmt, 'N', resEmail, pFlag, (this.totalPrice + this.tot_selAddPdtPrice)*1
      // ]});

      let insReqPayInfo = []
      if(pFlag === "p1")
      {
        insReqPayInfo = await this.$api("/api/proc_insReqPayInfo_1",{param: [
          //this.reqRsvCode,this.reserveInfo.reqStatus,this.cstPrice,'N',this.user.handlerId
          rCode, stat, payAmt, 'N', resEmail, pFlag, (this.totalPrice + this.tot_selAddPdtPrice + this.tot_selOptPrice)*1, rCode_org
        ]});
      }
      else
      {
        insReqPayInfo = await this.$api("/api/proc_insReqPayInfo_1",{param: [
          //this.reqRsvCode,this.reserveInfo.reqStatus,this.cstPrice,'N',this.user.handlerId
          rCode, stat, payAmt, 'N', resEmail, pFlag, (this.totalPrice + this.tot_selAddPdtPrice + this.tot_selOptPrice)*1, ""
        ]});
      }      
      if(insReqPayInfo.affectedRows < 0) 
      {
        this.$swal.fire('','처리 중 오류발생(insReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
      else
      {
        return "OK"
      }
    },

    /************************* 즉시결제로 연결*******************************/
    //토스페이먼츠PG결제
    async pgPay(rCode, fPayment, pFlag)     //pFlag: p1 or fu
    {
      //부분결제-전액결제 분류처리
      let payPointAmt = 0
      let resCode = ""
      if(pFlag === "p1")
      {
        resCode = rCode + "-1"
      }
      else
      {
        resCode = rCode
      }

      let veriSign = "N"
      let insVeriData = await this.$api("/api/proc_setVeriData_1", {param:
        [resCode, fPayment, veriSign, pFlag, payPointAmt ]
      });
      if(insVeriData.affectedRows < 0)
      {
        this.$swal.fire("","검증데이타가 생성되지 않았습니다. 재시도해주세요", "warning")
        return false;
      }
      else
      {
        //결제요청정보저장(시스템 내부처리용)
        await this.insReqPay(resCode, "RP", fPayment, this.resEmail, pFlag, rCode)
      }
     //localStorage.setItem("total_amount", this.reserveInfo.cstPrice_new)

      // ------  결제위젯 초기화 ------
      this.paymentWidget = await loadPaymentWidget(this.clientKey, ANONYMOUS);

      // ------  결제 UI 렌더링 ------
      this.paymentMethodWidget = this.paymentWidget.renderPaymentMethods("#payment-method", { value: fPayment }, { variantKey: "DEFAULT" });

      // ------  이용약관 UI 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
      //this.paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });
      this.showFlag = !this.showFlag
    },

    async requestPayment(rNo, mFlag, payStyle) {
      //console.log("mFlag:",mFlag)
      try {
        if (this.paymentWidget) 
        {
          let resCode = ""
          if(payStyle === "p1")
          {
            resCode = rNo + "-1"
          }
          else if(payStyle === "fu")
          {
            resCode = rNo
          }
          else
          {
            this.$swal.fire("","전액 또는 부분결제가 불명확합니다. 재시도해주세요.", "question")
            return false
          }
          //console.log("selectedPaymentMethod:", this.selectedPaymentMethod)
          // 비회원결제
          if(mFlag === 'N')
          {
            await this.paymentWidget.requestPayment({
              orderId: resCode,
              orderName: this.productInfo.pdtNameKor,
              //successUrl: 'http://localhost/success_pg',
              //failUrl: 'http://localhost/fail_pg',
              successUrl: 'https://ntabi.co.kr/success_pg',
              failUrl: 'https://ntabi.co.kr/fail_pg',
              customerEmail: this.resEmail,
              customerName: this.resName,
              taxFreeAmount: this.firstPayment,
            });
          }
          // 회원결제
          else
          {
            await this.paymentWidget.requestPayment({
              orderId: resCode,
              orderName: this.productInfo.pdtNameKor,
              //successUrl: 'http://localhost/success_pg',
              //failUrl: 'http://localhost/fail_pg',
              successUrl: 'https://ntabi.co.kr/success_pg',
              failUrl: 'https://ntabi.co.kr/fail_pg',
              customerEmail: this.resEmail,
              customerName: this.resName,
              // customerEmail: this.reserveInfo.conEmail,
              // customerName: this.reserveInfo.reqName,
              taxFreeAmount: this.firstPayment
            });
          }
        }
      } catch (error) {
        // 에러 처리하기
        console.error(error);
      }
    },

    async freePay(rNo)
    {
      //포인트처리
      let payByPoint = await this.$api("/api/payByPoint", {param: [
        this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기'
      ]});
      if(Object.keys(payByPoint)[0] === 'error') 
      {
        this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
        return false;
      }
      else
      {
        //결제처리
        let payByUser = await this.$api("/api/payByUser", {param: [this.reserveInfo.cstPrice_new, this.user.email, '000', rNo]});
        
        if(payByUser.affectedRows > 0) 
        {
          let upd_UsePoint = await this.$api("/api/upd_UsePoint", {param: [ this.user.email, rNo ]});
          if(upd_UsePoint.affectedRows < 0 || upd_UsePoint.affectedRows == undefined )
          {
            this.$swal.fire("","죄송합니다. 결제처리에 오류가 발생했습니다(코드:upd_UsePoint).<br>문의처: 1660-4602","warning")
            return false        
          }
          else
          {
            this.getPayDetailInfo(rNo)
            this.$swal.fire('','전액 포인트 결제되었습니다. 감사합니다,', 'success');
            //location.reload(true);
          }
        }
        else 
        {
          this.$swal.fire('','결제처리 중 에러가 발생했습니다(코드: payByUser)<br>문의처(1660-4602)에 확인을 요청해주세요.', 'error')
          return false;
        }
      }
    },

    async getPayDetailInfo(orderId)
    {
      let resNo = orderId
      let payDetailInfo = await this.$api("/api/payDetailInfo", {param: [resNo]});
      if(payDetailInfo.length > 0)
      {
        this.payDetailInfo = payDetailInfo[0];
        //console.log("payDetailInfo:", this.payDetailInfo)
        //결제완료 카톡안내
        this.sendKakao("PY", resNo);
      }
    },



    // /** 카카오페이 처리로직 */
    // async kPay()
    // {
    //   //포인트처리
    //   let payByPoint = await this.$api("/api/payByPoint", {param: [
    //     this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기'
    //   ]});
    //   if(Object.keys(payByPoint)[0] === 'error') 
    //   {
    //     this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
    //     return false;
    //   }
    //   else
    //   {
    //     // 성공 URL(핸들러등록)
    //     // const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay&${this.reserveInfo.reqReserveCode}`;
    //     const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay`;
    //     // 실패 or 취소 URL(핸들러등록)
    //     const failUrl = `${window.location.protocol}//${window.location.host}/fail_kPay`;

    //     let kakaoData = {
    //       'cid': '',
    //       'partner_order_id': this.reserveInfo.reqReserveCode,
    //       'partner_user_id': 'ntabi',
    //       'item_name': this.productInfo.pdtNameKor,
    //       'item_code': this.productId,
    //       'quantity': 1,
    //       'total_amount': this.reserveInfo.cstPrice_new,
    //       'vat_amount': this.reserveInfo.reqVatPrice,
    //       'tax_free_amount': this.reserveInfo.cstPrice_new - this.reserveInfo.reqVatPrice,
    //       'approval_url': successUrl,
    //       'fail_url': failUrl,
    //       'cancel_url': failUrl
    //     }

    //     //결제승인처리를 Success_kPay로 이동(20230321)
    //     try
    //     {
    //       //const response = await this.$api("api/kakaoPay", { param: kakaoData });
    //       const response = await axios.post('/api/kakaoPay', { param: kakaoData });
    //       const tid = response.data.tid;

    //       localStorage.setItem('tid', tid)
    //       localStorage.setItem('partner_order_id',kakaoData.partner_order_id)
    //       localStorage.setItem('total_amount',kakaoData.total_amount)

    //       const nextUrl = response.data.next_redirect_pc_url;
    //       window.location.href = nextUrl;
    //     }
    //     catch (error)
    //     {
    //       console.log(error);
    //     }
    //   }
    // },

    // /** 토스페이 처리로직 */ 
    // async tPay(param, value)
    // {
    //   if(value === "T")
    //   {
    //     //포인트처리
    //     let payByPoint = await this.$api("/api/payByPoint", {param: [
    //       this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기'
    //     ]});
    //     if(Object.keys(payByPoint)[0] === 'error') 
    //     {
    //       this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
    //       return false;
    //     }
    //     else
    //     {
    //       let tossData = {
    //         orderId: param,
    //         amount: this.reserveInfo.cstPrice_new,
    //         amountTaxFree: this.reserveInfo.cstPrice_new,
    //         //orderName: this.productInfo.pdtNameKor,
    //         productDesc: this.productInfo.pdtNameKor,
    //         autoExecute: true,
    //         retUrl: "http://localhost/success?amount="+this.reserveInfo.cstPrice_new,
    //         retCancelUrl: "http://localhost/fail",
    //         resultCallback: "http://ntabi.co.kr/callback",
    //         // resultCallback: "http://ntabi.co.kr/callback",
    //         // retUrl: "http://ntabi.co.kr/success?amount="+this.reserveInfo.cstPrice_new,
    //         // retCancelUrl: "http://ntabi.co.kr/fail"
    //       }
    //       //console.log("tossData:",tossData)

    //       // connect with api on backend
    //       this.$api("api/tossPay/start",
    //       {
    //         param: tossData
    //       })
    //       .then((res) => {
    //         console.log(res);
    //         window.location.href = res.checkoutPage;
    //       })
    //       .catch((err) => {
    //         console.error(err);
    //       });
    //     }
    //   }
    // },
  }
}
</script>

<style>
#reservation{
  margin:4rem 0
}
#reservation .m-title{
  font-weight: 600;
  color:#000;
  margin:1rem 0 2rem
}
#reservation .m-info{
  font-size:1.2rem;
  color:#000
}

@media (max-width:991px){
  #reservation .m-info{
    font-size:1rem;
  }
}
#reservation .table{
  vertical-align: middle;
}

/* -------------------------------------------------
  # 예약상품안내
--------------------------------------------------- */
#reservation .re-check{
  margin-top:3rem;
}
#reservation .re-check .title{
  text-align:left;
  color:white;
  background-color: rgba(12, 108, 106, 0.768);
  font-size:1.5rem;
  padding:10px;
  margin-bottom:1rem
}
#reservation .re-check .table{
  border:2px solid #ddd
}
#reservation .re-check tr{
  font-size:.9rem;
  height:4rem;
}
#reservation .re-check .name{
  background-color:#f9f9f9;
  width:15%;
}
#reservation .re-check td{
  text-align: left;
  padding-left: 1.7rem
}

@media (max-width:991px){
  #reservation .re-check{
    margin-top:2rem
  }
  #reservation .re-check .title{
    font-size:1.1rem;
  }
  #reservation .re-check .name{
    font-size:.8rem;
    width:25%;
  }
}


/* -------------------------------------------------
  # 인원수
--------------------------------------------------- */
#reservation .re-count{
  margin:3rem 0 
}
#reservation .re-count .inside{
  padding:0 .5rem
}
#reservation .re-count .official{
  font-size:.9rem;
  font-weight: 600;
  color:#000;
  margin-bottom:1.5rem
}
#reservation .re-count .official .ro-tit{
  font-size:1.4rem;
  margin-bottom:0
}
#reservation .re-count .division{
  border-left:2px solid #eee;
  border-right:2px solid #eee;
}
#reservation .re-count .btn-group{
  border:1px solid #000;
  border-radius: 0;
}
#reservation .re-count .number{
  font-size:1.4rem;
  font-weight: 600;
  color:#000;
  border-right:1px solid #000;
  border-left:1px solid #000;
  width: 4rem;
  padding:5px 0
}
#reservation .re-count .left,
#reservation .re-count .right{
  font-size:1.3rem;
}
#reservation .re-count .btn-check:focus+.btn,
#reservation .re-count .btn:focus{
  border-radius: 0;
}
#reservation .re-count .ro-price{
  font-size:1.7rem;
  font-weight: 600;
  color:#000;
  margin:1.8rem 0
}

/* 환급 또는 환불금액 */
#reservation .re-count .ro-mem-price{
  font-size:1.5rem;
  font-weight: normal;
  color:#000;
  margin:1.5rem 0
}

#reservation .re-count .ro-final{
  margin-top: 3rem
}
#reservation .re-count .ro-final p{
  font-size:1.2rem;
  font-weight: 600;
  color:#000;
  margin-bottom:.3rem
}
#reservation .re-count .ro-final .sum{
  font-size:2.1rem
}
#reservation .re-count .ro-final .op-ch{
  color:#f00;
  font-weight:normal;
  font-size:1rem
}

#reservation .re-count .ro-final .memPrc{
  color:black;
  font-weight:normal;
  font-size:1rem
}

@media (max-width:575px){
  #reservation .re-count{
    margin:1.5rem 0 
  }
  #reservation .re-count .official{
    font-size:.8rem;
    margin:0
  }
  #reservation .re-count .official p{
    margin-bottom:0
  }
  #reservation .re-count .official .ro-tit{
    font-size:1.2rem;
  }
  #reservation .re-count .btn-group{
    height:3.3rem
  }
  #reservation .re-count .left,
  #reservation .re-count .right{
    font-size:1rem;
  }
  #reservation .re-count .number{
    font-size:1.2rem;
    padding:10px 0
  }
  #reservation .re-count .ro-price{
    font-size:1.3rem;
    margin:5px 0;
    text-align:right
  }
  #reservation .re-count .division{
    border-left: none;
    border-right: none;
  }
  #reservation .re-count .ro-final{
    margin-top: 1rem
  }
  #reservation .re-count .ro-final p{
    font-size:1rem;
  }
  #reservation .re-count .ro-final .sum{
    font-size:1.8rem
  }
  #reservation .re-count .ro-final .op-ch{
    font-size:.8rem
  }
}


/* -------------------------------------------------
  # 예약자 정보
--------------------------------------------------- */
#reservation .re-subsc .title{
  font-size:1.5rem;
  font-weight: 600;
  color:#000;
}
#reservation .re-subsc table{
  border-top: 2px solid #000;
}
#reservation .re-subsc tr{
  border-color:transparent
}
#reservation .re-subsc th{
  width:13%;
  text-align: center;
  padding: 25px 0
}
#reservation .re-subsc .form-control,
#reservation .re-subsc .form-select{
  border:1px solid #000;
  border-radius: 0;
  height:3rem
}
#reservation .re-subsc .input-group-text{
  background-color: transparent;
  border:none;
  font-size:1.4rem;
  padding-left: 0;
}
#reservation .re-subsc ::placeholder{
  color:#b1b1b1;
  font-size:.9rem
}
#reservation .re-subsc .myself,
#reservation .re-subsc .notme{
  display: inline-block;
  content: "";
  font-size:1.1rem;
  margin-left:1rem
}
#reservation .re-subsc .notme{
  margin-left:2.5rem
}

@media (max-width:991px){
  #reservation .re-subsc{
    margin-top:3.5rem
  }
  #reservation .re-subsc .title{
    font-size:1.2rem;
  }
  #reservation .re-subsc th{
    font-size:.8rem
  }
  #reservation .re-subsc .notme{
    font-size:.75rem;
    margin-left:15px;
  }
}


/* --------------
  자잘한 안내
------------------ */
#reservation .re-subsc .notice{
  margin:1.5rem 0 5rem;
  text-align: left;
  font-size:.85rem;
  color:#999
}



/* -------------------------------------------------
  # 약관동의
--------------------------------------------------- */
#reservation .re-terms{
  text-align: left;
}
#reservation .re-terms .title{
  color:#000;
  margin-bottom:1.3rem;
  font-size:1.5rem;
  font-weight: 600;
  color:#000;
}
#reservation .re-terms .form-check p{
  font-size:1.2rem; 
  color:#000;
  margin:-10px 0 10px 5px;
}
#reservation .re-terms .accordion-item{
  border:transparent
}
#reservation .re-terms .check{
  color:rgb(211, 0, 0);
  font-weight: 600;
  padding:0 5px 0 15px
}
#reservation .re-terms .accordion-body{
  font-size:.85rem
}

@media (max-width:991px){
  #reservation .re-terms .title{
    font-size:1.2rem;
    margin-bottom:.8rem
  }
  #reservation .re-terms .form-check p{
    font-size:1rem; 
    margin:-10px 0 10px 0;
  }
}




/* -------------------------------------------------
  # 필수확인사항
--------------------------------------------------- */
#reservation .re-result{
  margin-top:3.5rem;
}
#reservation .re-result .incont{
  padding:1.5rem 2rem 1rem;
  text-align: left;
}
#reservation .re-result .incont p{
  font-size:1.1rem;
  font-weight: 600;
  color:#333;
  margin-bottom:.5rem
}
#reservation .re-result ul{
  padding-left:0
}
#reservation .re-result ul li{
  list-style: none;
  font-size:.85rem;
  line-height:30px
}
@media (max-width:991px){
  #reservation .re-result .incont{
    padding:1rem 1.5rem .5rem;
  }
  #reservation .re-result .incont p{
    font-size:1rem;
  }
  #reservation .re-result ul li{
    font-size:.7rem;
    line-height:25px
  }
}




/* -------------------------------------------------
  # 버튼
--------------------------------------------------- */
#reservation .cxl,
#reservation .go,
#reservation .part{
  --bs-btn-padding-y: 0;
  margin:5rem 0;
  border-radius: 0;
  font-size: 1.15rem;
  transition: all .5s;
}
#reservation .cxl{
  background-color: #f3f3f3;
}
#reservation .cxl:hover{
  border:1px solid #bbb
}
#reservation .go{
  background-color: #000;
  color:#fff;
  padding:20px 5px;
}

#reservation .part{
  background-color: #172b83;
  color:#fff;
  padding:20px 5px;
}

#reservation .go:hover{
  background-color: #333;
}

#reservation .part:hover{
  background-color: #3a4fad;
}

@media (max-width:991px){
  #reservation .cxl,
  #reservation .go,
  #reservation .part{
    margin:.5rem .3rem;
    font-size: .8rem;
    padding: 8px 1px;
    width:100px;
  }
}

/* -------------------------------------------------
  # 예약확인 팝업창
--------------------------------------------------- */
#reservationDone .modal-header{
  border-bottom:none
}
#reservationDone .up{
  margin-bottom:1rem
}
#reservationDone .up img{
  width:10rem;
  margin-bottom:1rem
}
#reservationDone .up .done{
  font-size:2.5rem;
  font-weight: 600;
  color:#000;
  margin-bottom:1rem
}
#reservationDone .up p{
  font-size:1.1rem;
  color:#000
}
#reservationDone .up span{
  font-size:.85rem;
  color:#aaa;
}

/* 아래 내용 표 */
#reservationDone .down tbody{
  border-top:3px solid #000
}
#reservationDone .down td{
  vertical-align: middle;
  padding:15px;
  background-color: #F8F8F8;
  font-weight: 600;
  width:20%;
}
#reservationDone .down .recont{
  background-color: #fff;
  text-align: left;
  padding-right:0;
  font-weight: normal;
  width:70%
}

/* 예약상세보기 */
#reservationDone .modal-footer{
  border-top:none;
  justify-content: center;
}
#reservationDone .modal-footer .mgo{
  border-radius: 0;
  font-size:1.1rem;
  color:#fff;
  background-color: #000;
  padding:1.2rem 5rem;
  margin: .5rem 0 2rem
}
#reservationDone .modal-footer .mgo:hover{
  background-color: #333;
}

/** 모바일화면에서 개행처리 */
@media screen and (max-width: 768px) {
  /* 모바일 화면에서 적용할 스타일 */
  .compDC-info i,
  .memPrc i,
  .selOpt i,
  .selAdd i {
    display: block;
    /* margin-bottom: 10px; */
  } 
}
</style>
