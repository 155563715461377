<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="pl-1 text-center">
        <div class="alert alert-success" role="alert">
          <b class="fs-4">회 원 리 스 트</b>
          <p class="mb-0">총원( {{ totMem }}) / 프리미엄( {{ totPre }} ) / 스탠다드( {{ totStd }} )</p>
        </div>
        <div class="float-end mb-1">
          <button type="button" class="btn btn-success" @click="showRegistMember()">회원등록화면</button>
        </div>

        <!-- register season area -->
        <table class="table table-bordered table-striped fs-6" v-if="this.createFlag == 'Y'">
          <thead class="small">
            <tr>
              <th style="width:10%;">아이디</th>
              <th style="width:10%;">성명</th>
              <th style="width:5%;">가입경로</th>
              <th style="width:8%;">기본연락처</th>
              <th style="width:10%;">카카오톡연락처</th>
              <th style="width:5%;">생일</th>
              <th style="width:3%;">성별</th>
              <th style="width:12%;">비고사항</th>
              <th style="width:5%;">등록</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr>
              <td>
                <input type="text"  class="text-success" v-model="this.uEmail" size="15" style="font-size: smaller" maxlength="40" ref="uEmail">
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.uNickName" size="15" style="font-size: smaller" maxlength="40" ref="uNickName">
              </td>
              <td>수동(H)</td>
              <td>
                <input type="text"  class="text-success" v-model="this.uPhone" size="11" style="font-size: smaller" maxlength="15" ref="uPhone">
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.alimCallC" size="11" style="font-size: smaller" maxlength="15" ref="alimCallC">
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.uBirthyear" size="4" maxlength="4" style="font-size: smaller" ref="uBirthyear" placeholder="연도만">                
                <input type="text"  class="text-success" v-model="this.uBirthday" size="4" maxlength="4" style="font-size: smaller" ref="uBirthday" placeholder="월일만">
              </td>              
              <td>
                <select class="form-select form-select-sm"  aria-label=".form-select-sm" ref="uGender" v-model="this.uGender">
                  <option value="male" selected>남성</option>
                  <option value="female">여성</option>
                </select>
              </td>
              <td>
                <textarea class="form-control" placeholder="100자 이내" ref="spRemarks" id="floatingTextarea" style="height:40px; font-size: smaller;" v-model="this.spRemarks" maxlength="100"></textarea>
              </td>
              <td>
                <!-- me-1: rigt margin1 -->
                <button type="button" class="btn btn-outline-primary btn-sm" @click="registMember()">OK</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-2 mb-2 align-middle fs-4">
          <i class="bi bi-arrow-down-square-fill text-secondary"  v-if="this.createFlag == 'Y'"></i>
        </div>
        <div class="float-start mb-1">
          <!-- <button type="button" class="btn btn-primary" @click="goToInsert">TEST</button> -->
          <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode"
            v-model="rProcCode" @change="changeProcCode(rProcCode)">
            <option :value="noVal" disabled>회원분류</option>
            <option value="A">전체보기</option>
            <option value="Pre">프리미엄</option>            
            <option value="Std">스탠다드</option>
            <option value="N">네이버경유</option>
            <option value="K">카카오경유</option>
            <option value="E">홈페이지경유</option>
            <option value="H">수동등록</option>
          </select>
        </div>        
        
        <!-- 회원리스트 -->
        <table class="table table-bordered table-striped fs-6 align-item-center">
          <thead class = "small">
            <tr>
              <th style="width:40px;">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th>
              <th style="width:5%;">멤버십</th>
              <th style="width:4%;">번호</th>
              <th style="width:7%;">아이디</th>
              <th style="width:7%;">성명</th>
              <th style="width:5%;">경로</th>
              <th style="width:7%;">가입일</th>
              <th style="width:8%;">연락처/알림톡</th>
              <th style="width:8%;">여권정보</th>
              <th style="width:5%;">생일</th>
              <th style="width:7%;">성별</th>
              <th style="width:6%;">직가입비번</th>
              <th style="width:5%;">포인트</th>
              <th style="width:10%;">비고사항</th>
              <th style="width:8%;">기능</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(m, i) in memberInfo" style="font-size:small;">
              <td>
                <input type="checkbox" v-model="m.checkFlag"  ref="chb_Seq" class="align-baseline mt-3" :id=i name="checkedBox">
              </td>
              
              <td style="color:goldenrod" v-if="m.memLevel === 'P'">{{ m.memLv }}</td>
              <td style="color:royalblue" v-else-if="m.memLevel === 'S'">{{ m.memLv }}</td>
              <td style="color:royalblue" v-else></td>

              <td>{{ m.memNo }}</td>

              <td style="font-size: smaller">{{ m.uEmail }}</td>

              <td v-if="m.joinFlag == 'E' || m.joinFlag == 'H'">
                <input type="text" v-model="m.uNickName" style="font-size: small;">
              </td>
              <td v-else>{{ m.uNickName }}</td>
              
              <td>{{ m.joinRoute }}</td>

              <td v-if="m.insDate == undefined">미확인</td>
              <td v-else class="text-secondary">{{ this.getDateFormat(m.insDate) }}</td>

              <td>
                <input type="text"  class="text-success" v-model="m.uPhone" size="10" style="font-size: smaller">
                <p>
                  <input type="text" v-model="m.alimCallC" size="10" style="font-size: smaller" class="mt-1">
                </p>
              </td>
              <td>
                <input type="text" v-model="m.uPassport" size="10" placeholder="여권번호" style="font-size: smaller">
                <p>
                  <input type="date" v-model="m.passExpDate" style="font-size: smaller">
                  <input type="text" v-model="m.engName" size="10" placeholder="여권명(영문)" style="font-size: smaller">
                </p>
              </td>
              
              <td v-if="m.joinFlag === 'K' || m.joinFlag === 'N'">
                <input type="text" v-model="m.uBirthyear" style="font-size: small">
                <input type="text" v-model="m.uBirthday"  style="font-size: small" readonly>                
              </td>
              <td v-else>
                <input type="text" v-model="m.uBirthyear" style="font-size: smaller">
                <input type="text" v-model="m.uBirthday"  style="font-size: smaller">
              </td>

              <td>
                <select class="form-select form-select-sm"  aria-label=".form-select-sm" ref="sel_procCode" v-model="m.uGender">
                  <option value="male">남성</option>
                  <option value="female">여성</option>
                </select>
              </td>

              <td v-if="m.uPass === undefined">-</td>
              <td v-else>{{ m.uPass }}</td>

              <td v-if="m.memberPoint == undefined" class="text-secondary">0</td>
              <td v-else class="text-primary">
                <a @click="goToPoint(m.uEmail);" style="cursor:pointer;">{{ this.getCurrencyFormat(m.memberPoint) }}</a>
              </td>
              
              <td style="width:12px;">
                <textarea class="form-control" ref="spRemarks" id="floatingTextarea" style="height:60px; font-size: small;" v-model="m.spRemarks" maxlength="100"></textarea>
              </td>
              
              <td style="width:80px; vertical-align:middle;">
                <button type="button" class="btn btn-outline-primary btn-sm" style="font-size: smaller;" @click="changeStatus(m.uBirthday ,m.uNickName, m.alimCallC, m.uPhone, m.uGender, m.uEmail, m.spRemarks, m.uBirthyear, m.engName)">변경</button>
                <button type="button" class="btn btn-outline-danger btn-sm" style="font-size: smaller;" @click="memWithdrawal(m.uEmail)">탈퇴</button>
                <button type="button" class="btn btn-outline-success btn-sm" style="font-size: smaller;" @click="addMem(m.uEmail, m.uNickName, m.alimCallC, m.mShipFlag)">멤버가입</button>                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  // DB에서 가져온 데이타
  data() {
    return {
      memberInfo: [],   //회원정보
      allSelected: "N", //전체체크여부
      
      //회원등록정보
      uEmail: "",
      uNickName: "",
      //joinFlag: "",
      uPhone: "",
      alimCallC: "",
      uBirthday: "",
      uGender: "",
      spRemarks: "",

      //회원등록란 표시플래그
      createFlag: "N",
      totMem: 0,              //회원총수
      totPre: 0,              //프리미엄총수
      totStd: 0,              //스탠다드총수
      memberInfo_org: [],     //조건별 소팅용 원형리스트
    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;
    this.getMemberList();

    this.getHandlerInfo()                               //알림톡 발송대상 체크    
  }, 
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    //memberInfo: 회원정보리스트용 sql
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    //회원정보리스트
    async getMemberList() {
      let memberInfo = await this.$api("/api/memberInfo", {});

      let pre = []
      let std = []
      if(memberInfo.length > 0)
      {
        this.memberInfo_org = memberInfo           //for 조건별 소팅

        this.memberInfo = memberInfo
        this.totMem = memberInfo.length
        
        pre = memberInfo.filter(item => {
          return (item.memLevel === 'P' && item.testFlag != 'Y')
        })
        this.totPre = pre.length
        
        std = memberInfo.filter(item => {
          return (item.memLevel === 'S' && item.testFlag != 'Y')
        })
        this.totStd = std.length
      }
    },

    //검색조건변경
    async changeProcCode(param) {
      console.log("memberORG:", this.memberInfo_org, "param:", param)
      if(param === 'A') 
      {
        this.getMemberList();
      }
      else if(param === "Pre")
      {
        this.memberInfo = this.memberInfo_org.filter(item => {
          return (item.memLevel === 'P' && item.testFlag != 'Y')
        })
      }
      else if(param === "Std")
      {
        this.memberInfo = this.memberInfo_org.filter(item => {
          return (item.memLevel === 'S' && item.testFlag != 'Y')
        })
      }
      else
      {
        this.memberInfo = this.memberInfo_org.filter(item => {
          return (item.joinFlag === param)
        })
      }
    },

    allChoice() 
    { 
      if(this.allSelected)
      {
        this.memberInfo.forEach((uNickName) => {
          uNickName.checkFlag = true;
        })
      }
      else
      {
        this.memberInfo.forEach((uNickName) => {
          uNickName.checkFlag = false;
        })
      }
    },
    //회원정보수정
    changeStatus(uBirthday, uNickName, alimCallC, uPhone, uGender, uEmail, spRemarks, uBirthyear, uEngName) 
    {
      //변경전 확인
      this.$swal.fire({
        title: '회원정보를 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let upd_memberInfo = await this.$api("/api/upd_memberInfo", {param: [
            uBirthday, uNickName, alimCallC, uPhone, uGender, spRemarks, uBirthyear, uEngName, uEmail
          ]});
          // 상태변경 후, 갱신
          //this.getSeasonList();
          if(upd_memberInfo.affectedRows > 0)
          {
            this.$swal.fire('', '변경완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:upd_memberInfo)', 'warning')
            return false;
          }
        }
      })
    },

    //멤버십가입 수동처리
    async addMem(uEmail, uNickName, alimCallC, memFlag){
      if(memFlag === "Y")
      {
        this.$swal.fire("","이미 멤버십에 가입한 회원입니다.","info")
        return false
      }

      await this.$swal.fire({
        title: "[여 권 정 보]",
        html: `
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <p>
              <input type="text" id="passNo" class="swal2-input" placeholder="여권번호">
            </p>
            <p style="margin: 0;" class="text-center">
              <b>만료일</b> <input type="date" id="passExp" class="swal2-input" style="margin:5; padding:5; width: 230px;"  placeholder="여권만료일">
            </p>
            <div style="display:flex; width:300px;" class="mt-3">
              <span>(스탠다드)</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div class="form-check form-switch">
                <input class="form-check-input fs-3" type="checkbox" id="memLv">
              </div>
              <span> (프리미엄)</span>
            </div>
          </div>
        `,
        //inputOptions,
        focusConfirm: false,
      });

      let in_mShipFlag = "Y"
      let in_insId = this.user.handlerId
      let in_uPassport = document.getElementById("passNo").value
      let in_uPassportExp = document.getElementById("passExp").value
      let switchElement = document.getElementById("memLv")
      let in_memLevel = switchElement.checked ? "P" : "S";

      if(!in_uPassport || !in_uPassportExp || !in_memLevel)
      {
        this.$swal.fire("","여권관련 정보 또는 멤버십 등급정보가 누락되었습니다.<br>확인해주세요", "warning")
        return false;
      }

      //멤버십 가입안내용 변수
      //let mName = ""
      let today = new Date();
      let yyyy = today.getFullYear()
      let mm_tmp = today.getMonth()+1
      let dd_tmp = today.getDate();

      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;

      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;

      let payDate = yyyy +"-" + mm + "-" + dd

      let joinDate_tmp = yyyy +""+ mm +""+ dd
      let joinDate
      let oneYearLater_tmp
      let oneYearLater

      // if(in_memLevel == "P")
      // {
      //   mName = "프리미엄멤버십"
      // }
      // else if(in_memLevel == "S")
      // {
      //   mName = "스탠다드멤버십"
      // }
      // else if(this.mLv == "U")
      // {        
      //   mName = "멤버십업그레이드"
      // }
      // else if(this.mLv == "ES" || this.mLv == "EP")
      // {
      //   mName = "멥버십기한연장"
      // }

      if(in_memLevel == "P" || in_memLevel == "S")  //멥버쉽가입(혜택기간반영)
      {
        if(joinDate_tmp < '20240304')
        {
          joinDate = '2024-03-03'
          oneYearLater = '2025-04-30'
        }
        else
        {
          joinDate = yyyy +""+ mm +""+ dd
          // 1년 후의 날짜 계산
          oneYearLater_tmp = new Date(today.getFullYear() + 1, today.getMonth(), 1);
          // 해당 달의 말일로 설정
          oneYearLater = this.getDateFormat(oneYearLater_tmp.setMonth(oneYearLater_tmp.getMonth() + 1, 0))
        }
      }
      console.log("joinDate: ",joinDate)

      let res_addMember = await this.$api("/api/proc_addMemebership", {param: [
        uEmail, in_mShipFlag, in_insId, in_uPassport, in_uPassportExp, in_memLevel
      ]})

      // 에러가 포함되어 있다면 에러 메시지 표시
      if (res_addMember.error) 
      {
        this.$swal.fire(
          "에러 발생",
          "멤버십 등록 진행 중 오류가 발생했습니다: " + res_addMember.error.sqlMessage,
          "error"
        )
        return false
      }
      else
      {
        this.$swal.fire("","멤버십 가입처리가 완료되었습니다.<br>카카오알림톡을 발송합니다.", "info").then(() => {
          //alert("SendKakao")
          this.sendKakao("MJO", payDate, oneYearLater, in_memLevel, uEmail, alimCallC, uNickName)
          //location.reload(true)
        })
      }
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
    },

    /** 멤버십가입 카카오알림 발송 */
    async sendKakao(param, payDate, expDate, in_memLevel, uEmail, alimCallC, uNickName)
    {
      console.log(param, payDate, expDate, in_memLevel, uEmail, alimCallC, uNickName)

      //연락처를 발송용으로 컨버전
      //let alimCallC = ""
      if(alimCallC.charAt(0) === "+")
      {
        alimCallC = alimCallC.replace("+82 ", "0")
      }
      alimCallC = alimCallC.replace(/-/g,"")
      console.log("alimCallC:>>>>>>", alimCallC)


      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        uEmail, param, alimCallC
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: alimCallC})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0) 
      {
        let promises = []         //promise들의 배열

        let membership = ""
        let mPayAmt = 0
        if(in_memLevel === "P")
        {
          membership = "엔데이트립 프리미엄멤버십"
          mPayAmt = "199,000"
        }
        else if(in_memLevel == "S") 
        {
          membership = "엔데이트립 스탠다드멤버십"
          mPayAmt = "99,000"
        }
        // else if(in_memLevel == "ES" || in_memLevel == "EP") 
        // {
        //   membership = "엔데이트립 멤버십연장"
        // }        
        //console.log(membership)


        //결제요청 카카오 알림톡(결제자)
        let i = 0
        while(i < this.handTelCnt)
        {
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제완료)
            clientName: uNickName,
            membership: membership,
            memPayDate: payDate,
            memPayAmt: mPayAmt,
            memExpDate: expDate,
            resCode: "",
            alimFlag: param, //알림유형(결제요청)
            clientCall: this.handTel[i].aimCall,                                //알림톡발송대상
          };

          let promise = this.$api("api/kakaoAlimSend6", {
            param: [{ kakaoAlimData: kakaoAlimData}]
          })

          promises.push(promise)
          i++
        }

        //반복문 이후 처리
        Promise.all(promises).then((results) => {
          console.log(results)
          this.$swal.fire('', '멤버십가입 알림톡이 발송되었습니다', 'success').then(() => {
            location.reload(true)
          })
        }).catch((error) => {
          console.log("Error calling api: ", error)
        })        
      }
    },

    //회원탈퇴
    memWithdrawal(uEmail) 
    {
      //변경전 확인
      this.$swal.fire({
        html: '회원정보를 삭제합니까?<br>삭제 이후 복구는 되지 않습니다.<br>포인트와 멤버십정보도 모두 삭제되므로<br>고객께 이점을 꼭 안내하여 주십시오.',
        showCancelButton: true,
        confirmButtonText: '삭제',
        cancelButtonText: '취소',
        icon: 'info'
      }).then(async (result) => {
        if (result.isConfirmed) {
          //await this.$api("/api/del_memberPoint", {param: [uEmail]})

          let del_memberInfo = await this.$api("/api/proc_memberDelete", {param: [uEmail]});
          // 상태변경 후, 갱신
          if(del_memberInfo.affectedRows > 0)
          {
            this.$swal.fire('', '탈퇴완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다('+ del_memberInfo.error.sqlMessage +')', 'warning')
            return false;
          }
        }
      })
    },
    //회원등록
    async registMember() {
      if(!this.uEmail) 
      {
        this.$refs.uEmail.focus();
        return this.$swal.fire("","등록아이디를 입력하세요.","warning");
      }
      if(!this.uNickName) 
      {
        this.$refs.uNickName.focus();
        return this.$swal.fire("","회원성명을 입력하세요.","warning");
      }
      if(!this.uPhone) 
      {
        this.$refs.uPhone.focus();
        return this.$swal.fire("","기본연락처를 입력하세요.","warning");
      }
      if(!this.alimCallC) 
      {
        this.$refs.alimCallC.focus();
        return this.$swal.fire("","카카오알림을 받을 연락처를 입력하세요.","warning");
      }      
      if(!this.uBirthyear) 
      {
        this.$refs.uBirthyear.focus();
        return this.$swal.fire("","생년(예:2002)을 입력하세요","warning");
      }      
      if(!this.uBirthday) 
      {
        this.$refs.uBirthday.focus();
        return this.$swal.fire("","생일(예:0120)을 입력하세요","warning");
      }
      if(!this.uGender) 
      {
        this.$refs.uGender.focus();
        return this.$swal.fire("","성별을 선택하세요.","warning");
      }
      
      let insId = this.user.handlerId;
      let joinFlag = "H"

      //임시비번 생성
      let characters = '0123456789';
      let length = 5;
      let randomString = ''
      for (var i = 0; i < length; i++) {
        var randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters[randomIndex];
      }

      let pass = randomString
      console.log("pass:", pass)

      let ins_memberInfo = await this.$api("/api/ins_memberInfo", {param: [
        this.uEmail, this.uNickName, joinFlag, this.uPhone, this.alimCallC, this.uBirthday, this.uGender, this.spRemarks, insId, pass, this.uBirthyear
      ]});
      if(Object.keys(ins_memberInfo)[0] === 'error') {
        this.$swal.fire('','등록실패(코드:ins_memberInfo)', 'error')
      }
      else {
        this.$swal.fire('','회원정보를 생성했습니다', 'success');
        location.reload();
      }
    },
    //포인트 내역화면
    goToPoint(uEmail){
      this.$router.push({path: '/pointList_admin', query: {id: uEmail}});
    },

    showRegistMember() {
      this.createFlag = (this.createFlag === "Y") ? "N" : "Y";
      this.uEmail = ""
      this.uNickName = ""
      this.uPhone = ""
      this.alimCallC = ""
      this.uBirthyear = ""
      this.uBirthday = ""
      this.uGender = ""
      this.spRemarks = ""
    },
    
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
  }
}
</script>

<style>
 /* input의 폭을 td에 맞춤 */
  input {
    width: 100%;
    box-sizing: border-box;
  }
</style>