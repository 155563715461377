<template>
  <main class="mt-5">
    <div class="container inquiryde">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="d-flex justify-content-center" style="font-weight:600">문 의 답 변</h2>

          <div class="row justify-content-center">
            <div class="col-12">
              <div class="row my-4">
                <!-- 진행상태 -->
                <div class="col-6 gx-3 my-1">
                  <label class="col-form-label my-1 name">진행상태</label>
                  <div class="input-group">
                    <input type="text" class="form-control" ref="txt_status" v-model="this.jDetail.status" disabled>
                  </div>
                </div>
                <!-- 문의유형 -->
                <div class="col-6 gx-3 my-1">
                  <label class="col-form-label my-1 name">문의유형</label>
                  <div class="input-group">
                    <input type="text" class="form-controgl text-danger" ref="txt_cName" v-model="this.jDetail.cat" disabled>
                  </div>
                </div>
                <!-- 제목 -->
                <div class="col-12 gx-3 my-1">
                  <label class="col-form-label my-1 name">제목</label>
                  <div class="input-group">
                    <input type="text" class="form-control" ref="txt_reqTitle" v-model="this.jDetail.reqTitle" disabled>
                  </div>
                </div>
                <!-- 문의자,인원수 -->
                <div class="row my-1">
                  <div class="col-6 gx-3">
                    <label class="name col-form-label my-1">문의자</label>
                    <input type="text" maxlength="20" class="form-control" ref="txt_reqPersonName" v-model="this.jDetail.reqPersonName" readonly>
                  </div>
                  <div class="col-6 gx-3">
                    <label class="name col-form-label my-1">인원수</label>
                    <input type="number" class="form-control" ref="txt_perCnt" v-model="this.jDetail.perCnt" readonly>
                  </div>
                </div>
                <!-- 연락처,이메일 -->
                <div class="row my-1">
                  <div class="col-6 gx-3">
                    <label class="name col-form-label my-1">연락처</label>
                    <input type="tel" maxlength="11" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.jDetail.reqTel" readonly>
                  </div>
                  <div class="col-6 gx-3">
                    <label class="name col-form-label my-1">이메일</label>
                    <input type="email" maxlength="40" class="form-control" ref="txt_cEmail" v-model="this.jDetail.reqEmail" readonly>
                  </div>
                </div>
                <!-- 문의내용 -->
                <div class="col-12 gx-3 mt-2">
                  <label class="col-form-label my-1 name">문의내용</label>
                  <textarea class="form-control" maxlength="245"  placeholder="250자 이내" ref="txt_nContent" id="floatingTextarea" style="height:150px" v-model="this.jDetail.reqSpRemarks" disabled></textarea>
                </div>

                <hr style="margin:3rem 0 2rem">

                <!-- 문의답변 -->
                <div class="col-12 gx-3 my-1">
                  <label class="col-form-label my-1 name">문의답변</label>
                  <textarea class="form-control" maxlength="999" ref="txt_ansContent" id="floatingTextarea" style="height:200px" v-model="this.requestAnswer.ansContent" readonly></textarea>
                </div>
              </div>

              <!-- 목록으로 -->
              <div class="mt-5 row justify-content-center">
                <button class="btn go col-5 col-lg-4 mx-2" @click="goToList()">마이페이지</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
export default {
  data() {
    return {
      jDetail: [],
      rProc: [],
      rProcCode: "",
      openTag: "N", //여권사진업로드 영역호출
      respoComment_new: "", //담당자 코멘트
      requestAnswer: [], //답변내용
      ansContent: "", //신규답변
      answerFlag: "N" //답변글 플래그
      //passExpireDate: "" //여권만료일
    };
  },
  created() {
    this.iqCode = this.$route.query.iqCode;

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }

    this.getRequestDetail();
    this.getReqProcLevel();
    this.getReqAnswer(); //답변데이타
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }
    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요',
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'});
    // }
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async getRequestDetail() {
      let reqJoinDetail = await this.$api("/api/requestDetail", {param: [this.iqCode]});
      this.jDetail = reqJoinDetail[0];
      this.jDetail.passExpire = (this.jDetail.passExpire == null) ? null : this.getDateFormat(this.jDetail.passExpire);
    },
    async getReqAnswer() {
      let requestAnswer = await this.$api("/api/requestAnswer", {param: [this.iqCode]});
      if(requestAnswer.length > 0) {
        this.requestAnswer = requestAnswer[0];
        this.answerFlag = "Y";
      }
      console.log("requestAnswer:",this.requestAnswer);
    },    
    async getReqProcLevel() {
      this.rProc = await this.$api("/api/reqProcLevel", {});
      console.log("rProc:",this.rProc);
    },
    goToPage() {
      this.$router.push({path: '/detail', query: {productId: this.jDetail.pdtCode}});
    },
    goToList() {
      //this.$router.push({path: '/requestList_client'});
      this.$router.push({path: '/myPage'});
    }
  }
}
</script>


<style>
.inquiryde{
  text-align:left
}
.inquiryde .name{
  font-size:.9rem;
  font-weight:bold
}
.inquiryde .form-select{
  border-radius: 0.375rem !important
}


.inquiryde .go {
  padding:15px 0;
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.inquiryde .go:hover{
  background-color: #444;
}
</style>
