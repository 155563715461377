<template>
  <main class="mt-3">
    <div class="p-1 text-center">
      <h2 class="text-center"><u>상 품 옵 션 등 록</u></h2>
      <div class="mb-3 row">
        <!-- 수정불가 -->
        <label class="col-md-3 col-form-label">상품코드</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="this.productId" readonly>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품구분</label>
        <div class="col-md-9">
          <div class="form-check-inline" :key=i v-for="(baseCode,i) in this.sRoute">

            <div v-if="this.sRoute[i].baseCode === this.salesRoute">
              <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value=this.sRoute[i].baseCode ref="rbt_salesRoute" checked disabled>
              <label class="form-check-label text-primary" for="flexRadioDefault2">
                {{ this.sRoute[i].codeNameKor }}
              </label>
            </div>
            <div v-else>
              <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value=this.sRoute[i].baseCode ref="rbt_salesRoute" disabled>
              <label class="form-check-label text-secondary" for="flexRadioDefault2">
                {{ this.sRoute[i].codeNameKor }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<span class="text-secondary" style="font-size: small;">(국문)</span></label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_pdtNameKor" v-model="productInfo.pdtNameKor" style="font-size: small;" disabled>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">국가 / 지역</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="this.productInfo.ntName" style="font-size: small;" disabled>
            <input type="text" class="form-control" v-model="this.productInfo.arName" style="font-size: small;" disabled>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3 row">
        <label class="col-md-3 col-form-label">여행/유효기간</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1" style="font-size: small;">개시</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.tripStart" style="font-size: small;" disabled>
            <span class="input-group-text" id="basic-addon1" style="font-size: small;">종료</span>
            <input type="date" class="form-control" v-model="productInfo.tripEnd" style="font-size: small;" disabled>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">판매기간</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1" style="font-size: small;">개시</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.salesStart" style="font-size: small;" disabled>
            <span class="input-group-text" id="basic-addon1" style="font-size: small;">종료</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.salesEnd" style="font-size: small;" disabled>
          </div>
        </div>
      </div>

      <!-- <div class="mb-3 row">
        <label class="col-md-3 col-form-label">옵션리스트</label>
        
        <div class="col-md-9"  v-if="dptOPtCount>0">
          <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="pdtOptionSelect" v-model="j" @change="changePdtOption(j)">
            <option value="none" style="color:blue; font-weight:600;">옵션생성</option>
            <option :value="j" :key=j v-for="(op,j) in optPrcInfo">({{ op.useFlag }}) {{op.optName}}</option>
          </select>
        </div>
        <div class="col-md-9 text-danger"  v-else>
          옵션없음
        </div>        
      
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">옵션명<span style="font-size: small; color:darkgrey;"> (생성 or 변경)</span></label>
        <div class="col-md-9">
          <input type="hidden" class="form-control" v-model="optCode" ref="optCode">
          <input type="text" class="form-control" v-model="optName" ref="optName">
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">옵션설명<span style="font-size: small; color:darkgrey;"> (선택사항)</span></label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
            v-model="optRemarks" maxlength="210"></textarea>
        </div>
      </div>

      
      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-info" @click="pdtOptSave();">옵션정보생성</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-danger" @click="change_OptStat();">옵션판매 상태변경</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-secondary" @click="goToList()">리스트로 이동</button>
        </div>
      </div>

      <hr style="border: none; height: 5px; background-color: darkgray; margin-top: 20px; margin-bottom: 30px;">

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">옵션요금
          <p v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'L' || productInfo.salesRoute == 'F'">
            <span class="text-danger" style="font-size:small;">1:성인 / 2:소아 / 3:인펀트 / <del>4:기타할인</del></span></p>
          <p v-else-if="productInfo.salesRoute === 'H'">
            <span class="text-danger" style="font-size:small;">기준(성인+싱글룸)</span></p>
          <p><span class="text-secondary" style="font-size:small;">(미입력시 0원)</span></p>
        </label>
        <div class="col-md-9">
          <table class="table table-bordered border-success align-middle">
            <tr>
              <td class="text-center">
                기본옵션가격 / 적용기간
              </td>
              <td class="text-primary" colspan="1">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">개시</span>
                    <input type="month" class="form-control" ref="optPriceStart" style="font-size: small" v-model="optPriceStart">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">종료</span>
                    <input type="month" class="form-control" ref="optPriceEnd" style="font-size: small" v-model="optPriceEnd">
                </div>
              </td>
            </tr>
            <tr v-if="!productInfo.salesRoute">
              <td colspan="2">상품정보가 부족합니다</td>
            </tr>
            <tr v-else>
              <td v-if="['T', 'L', 'F', 'E'].includes(productInfo.salesRoute)">
                <div class="input-group mb-3 justify-content-center" v-for="i in 4" :key="i">
                  <span class="input-group-text">{{i}}</span>
                  <input type="number" class="form-control-sm text-end" v-model="optPrice[i]" style="font-size:small; width:40%;" min="0">
                </div>
              </td>
              <td v-else-if="['S', 'H'].includes(productInfo.salesRoute)">
                <div class="input-group mb-3 justify-content-center">
                  <input type="number" class="form-control-sm text-end" v-model="optPrice1" style="font-size:small;" min="0">
                </div>
              </td>
              <td v-else colspan="2">Unknown sales route: {{ productInfo.salesRoute }}</td>

              <td>
                <div class="col-auto d-grid p-1">
                  <button type="button" class="btn btn-lg btn-info" @click="goToPriceSetPage(optCode);">옵션가격 관리페이지<small>(기본옵션가격 등록우선)</small></button>
                </div>
              </td>              
            </tr>
          </table>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-primary" @click="priceInsert(optPriceStart, optPriceEnd, this.optCode);">옵션요금저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-secondary" @click="goToList()">리스트로 이동</button>
        </div>
      </div> -->


      <!-- New 선택옵션: 20240620 선택옵션로직변경: 가격추가/기본상품가격에 포함처리 -->
      <div class="mb-3 row border p-sm-2 border-primary">
        <label class="col-md-3 col-form-label mt-2 fs-5">
          선택옵션&nbsp;<i class="bi bi-plus-circle-fill text-primary" style="cursor: pointer;" @click="makeSelOption()"></i>
          <p class="text-muted text-sm-center" style="font-size: small;">(명칭|요금(성인/소인/유아)|설명|판매시작일|판매종료일|삭제)</p>
          <p style="font-size:small; text-align: left; padding-left:1rem;">
            <span class="text-danger">※ 기본상품과 동일한 할인율이 적용됩니다.</span>
            <br>
            ※ 이용자는 중복선택을 할 수 없습니다.
            <br>
            ※ 옵션 변경 시, 해당옵션의 판매를 중지한 후 진행하세요.
            <br>
            ※ 상태버튼은 판매중 <i class="bi bi-arrow-left-right text-danger fs-6"></i>  미판매 전환기능을 합니다
          </p>

          <div style="padding-top: 5px;text-align: start; padding-left: 15px;">
            <p class="mb-0" style="font-size:medium;">옵션일괄등록</p>
            <input type="file" class="btn btn-primary btn-sm" style="width:300px;text-align: start; padding-left: 15px;" ref="searchExcel_opt" id="searchExcel_opt" @change="handleFileUpload">
          </div>
        </label>

        <div class="col-md-9">
          <div :key="j" v-for="(eo, j) in exist_optPdt">
            <div class="col">
              <div class="input-group mb-3">
                <input type="hidden" class="form-control-sm" v-model="eo.optCode" style="width: 10%;" disabled>
                <input type="text" class="form-control-sm" placeholder="선택옵션명(50자)" v-model="eo.optPdtName" style="width:20%;" maxlength="50">

                <input type="number" class="form-control-sm text-end" placeholder="성인요금" v-model="eo.optPdtPrice" min="0" style="width:6%;">
                <input type="number" class="form-control-sm text-end" placeholder="소인요금" v-model="eo.optPdtPrice_chi" min="0" style="width:6%;">
                <input type="number" class="form-control-sm text-end" placeholder="유아요금" v-model="eo.optPdtPrice_inf" min="0" style="width:6%;">

                <input type="text" class="form-control-sm" placeholder="옵션소개(100자)" v-model="eo.optPdtRemarks" style="width:20%;" maxlength="100">
                <input type="date" class="form-control-sm" placeholder="판매시작일" v-model="eo.optPdtSDate" style="width:13%;" maxlength="100">
                <input type="date" class="form-control-sm" placeholder="판매종료일" v-model="eo.optPdtEDate" style="width:13%;" maxlength="100">
                <button type="button" class="btn btn-warning btn-sm" style="cursor: pointer; width:5%;" @click="updateOptPdt('U', eo.optCode, eo.useFlag, j)" :disabled="eo.useFlag === 'Y'">변경</button>

                <button type="button" class="btn btn-primary btn-sm" style="cursor: pointer; width:5%;" @click="updateOptPdt('C', eo.optCode, eo.useFlag, j)" v-if="eo.useFlag === 'Y'">판매중</button>
                <button type="button" class="btn btn-danger btn-sm" style="cursor: pointer; width:5%;" @click="updateOptPdt('C', eo.optCode, eo.useFlag, j)" v-if="eo.useFlag === 'N'">미판매</button>                
              </div>
            </div>
          </div>

          <div v-for="(ao, i) in optPdt" :key="i">
            <div class="col">
              <div class="input-group mb-3">
                <input type="hidden" class="form-control-sm" :ref="'optCode'+ i" v-model="ao.optCode" style="width: 10%;" disabled>
                <input type="text" class="form-control-sm" placeholder="선택옵션명(50자)" :ref="'optPdtName'+ i" v-model="ao.optPdtName" style="width:20%;" maxlength="50">
                <input type="number" class="form-control-sm text-end" placeholder="성인요금" :ref="'optPdtPrice'+ i" v-model="ao.optPdtPrice" min="0" style="width:6%;">
                <input type="number" class="form-control-sm text-end" placeholder="소인요금" :ref="'optPdtPrice_chi'+ i" v-model="ao.optPdtPrice_chi" min="0" style="width:6%;">
                <input type="number" class="form-control-sm text-end" placeholder="유아요금" :ref="'optPdtPrice_inf'+ i" v-model="ao.optPdtPrice_inf" min="0" style="width:6%;">
                <input type="text" class="form-control-sm" placeholder="옵션소개(100자)" :ref="'optPdtRemarks'+ i" v-model="ao.optPdtRemarks" style="width:25%;" maxlength="100">
                <input type="date" class="form-control-sm" placeholder="판매종료일" :ref="'optPdtEDate'+ i" v-model="ao.optPdtSDate" style="width:15%;" maxlength="100">
                <input type="date" class="form-control-sm" placeholder="판매시작일" :ref="'optPdtSDate'+ i" v-model="ao.optPdtEDate" style="width:15%;" maxlength="100">
                <button type="button" class="btn btn-outline-danger btn-sm" style="cursor: pointer; width:3%;" @click="removeOptProduct(i);">
                  <i class="bi bi-dash-circle-fill text-danger"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-primary " @click="saveOptProduct(this.productId);">선택옵션저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-secondary" @click="goToList()">리스트로이동</button>
        </div>
      </div>

      <hr style="border: none; height: 5px; background-color: darkgray; margin-top: 20px; margin-bottom: 30px;">

      <!-- 추가상품처리영역 -->
      <div class="mb-3 row border p-sm-2 border-success">
        <label class="col-md-3 col-form-label mt-2 fs-5">
          추가상품&nbsp;<i class="bi bi-plus-circle-fill text-success" style="cursor: pointer;" @click="makeAddProduct()"></i>
          <p class="text-muted text-sm-center" style="font-size: small;">(명칭|요금|설명|판매시작일|판매종료일|삭제)</p>
          <p style="font-size:small; text-align: left; padding-left:1rem;">
            ※ 추가상품 변경 시, 해당추가상품의 판매를 중지한 후 진행하세요.
            <br>
            ※ 상태버튼은 판매중 <i class="bi bi-arrow-left-right text-danger fs-6"></i>  미판매 전환기능을 합니다
          </p>

          <div style="padding-top: 5px;text-align: start; padding-left: 15px;">
            <p class="mb-0" style="font-size:medium;">추가상품일괄등록</p>
            <input type="file" class="btn btn-success btn-sm" style="width:300px;text-align: start; padding-left: 15px;" ref="searchExcel_add" id="searchExcel_add" @change="handleFileUpload">
          </div>
        </label>

        <div class="col-md-9">
          <div :key="j" v-for="(eP, j) in exist_addPdt">
            <div class="col">
              <div class="input-group mb-3">
                <input type="hidden" class="form-control form-control-sm" v-model="eP.addPdtCode" style="width: 10%;" disabled>
                <input type="text" class="form-control form-control-sm" placeholder="추가상품명(50자)" v-model="eP.addPdtName" style="width:20%;" maxlength="50">
                <input type="number" class="form-control form-control-sm text-end" placeholder="추가요금" v-model="eP.addPdtPrice" min="0" style="width:6%;">
                <input type="text" class="form-control form-control-sm" placeholder="상품소개(100자)" v-model="eP.addPdtRemarks" style="width:28%;" maxlength="100">
                <input type="date" class="form-control form-control-sm" placeholder="판매시작일" v-model="eP.addPdtSDate" style="width:13%;" maxlength="100">
                <input type="date" class="form-control form-control-sm" placeholder="판매종료일" v-model="eP.addPdtEDate" style="width:13%;" maxlength="100">
                <button type="button" class="btn btn-warning btn-sm" style="cursor: pointer; width:5%;" @click="updateAddPdt('U', eP.addPdtCode, eP.useFlag, j)" :disabled="eP.useFlag === 'Y'">변경</button>

                <button type="button" class="btn btn-success btn-sm" style="cursor: pointer; width:5%;" @click="updateAddPdt('C', eP.addPdtCode, eP.useFlag, j)" v-if="eP.useFlag === 'Y'">판매중</button>
                <button type="button" class="btn btn-danger btn-sm" style="cursor: pointer; width:5%;" @click="updateAddPdt('C', eP.addPdtCode, eP.useFlag, j)" v-if="eP.useFlag === 'N'">미판매</button>                
              </div>
            </div>
          </div>

          <div v-for="(aP, i) in addPdt" :key="i">
            <div class="col">
              <div class="input-group mb-3">
                <input type="hidden" class="form-control-sm" :ref="'addPdtCode'+ i" v-model="aP.addPdtCode" style="width: 10%;" disabled>
                <input type="text" class="form-control-sm" placeholder="추가상품명(50자)" :ref="'addPdtName'+ i" v-model="aP.addPdtName" style="width:20%;" maxlength="50">
                <input type="number" class="form-control-sm text-end" placeholder="추가요금" :ref="'addPdtPrice'+ i" v-model="aP.addPdtPrice" min="0" style="width:10%;">
                <input type="text" class="form-control-sm" placeholder="상품소개(100자)" :ref="'addPdtRemarks'+ i" v-model="aP.addPdtRemarks" style="width:25%;" maxlength="100">
                <input type="date" class="form-control-sm" placeholder="판매시작일" :ref="'addPdtSDate'+ i" v-model="aP.addPdtSDate" style="width:15%;" maxlength="100">
                <input type="date" class="form-control-sm" placeholder="판매종료일" :ref="'addPdtEDate'+ i" v-model="aP.addPdtEDate" style="width:15%;" maxlength="100">
                <button type="button" class="btn btn-outline-danger btn-sm" style="cursor: pointer; width:3%;" @click="removeAddProduct(i);">
                  <i class="bi bi-dash-circle-fill text-danger"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-success" @click="saveAddProduct(this.productId);">추가상품저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-md btn-secondary" @click="goToList()">리스트로이동</button>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
import {genCode} from '../assets/js/genCode'
import * as XLSX from 'xlsx'
export default {
  data() { 
    return {
      productInfo: {},                    //상품기본정보
      sRoute: {},                         //상품구분
      
      //선택옵션변경반영(2024-04-25)
      optPdt: [],                         //신규선택옵션정보
      exist_optPdt: [],                   //기존선택옵션정보
      
      addPdt: [],                         //신규추가상품정보
      exist_addPdt: [],                   //기존추가상품정보

      // sel_optCode: "",                    //선택된 옵션코드
      // dptOPtCount: 0,                     //상품옵션갯수
      optCode: "",                        //옵션코드(선택 또는 생성)
      optName: "",                        //옵션네임(선택 또는 생성)
      optRemarks: "",                     //옵션내용(선택 또는 생성)
      useFlag: "",                        //판매상태(선택 또는 생성)
      insDate: "",
      insId: "",
      excelUpData: [],                    //엑셀데이타      


      // optPrice: [0,0,0,0,0],              //옵션가격(T,L,F,E)
      // optPrice1: 0,                       //옵션가격(S,H)
      //ageGroup: "",                       //옵션권종
      //optClass: "",                       //T(trip) L(local) E(etc) S(single) H(Hotel) F(Ship)
      //optPriceStart: "",                  //금액적용기간
      //optPriceEnd: "",
      // dInterval: 0,                       //가격등록기간
      // lastDay: "",                        //마지막날짜
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;

    this.getProductInfo();
    this.getSalesRoute();
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    //this.getSalesRoute();
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDayOfWeekFormat(text) {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text) {
      return this.$numberOfWeekFormat(text);
    },

    // //상품별 옵션리스트
    // async getPdtOptList(){
    //   let optPrcInfo = await this.$api("/api/pdtOptList", {param: [this.productId]});
    //   console.log("optPrcInfo.length: ", optPrcInfo.length)
    //   if(optPrcInfo.length > 0)
    //   {
    //     this.optPrcInfo = optPrcInfo;
    //     this.dptOPtCount = optPrcInfo.length * 1
    //   }
    //   console.log("optPrcInfo",this.optPrcInfo, "dptOPtCount: ", this.dptOPtCount);
    // },

    handleFileUpload(event) {
      const file = event.target.files[0];
      
      const input = event.target
      let id = input.id
      console.log("id:", id)

      if(!file) return

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        this.processExcel(workbook, id);
      };

      reader.readAsBinaryString(file);
    },    
    
    async processExcel(workbook, st) {
      console.log("st:", st)
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      //const jsonData = XLSX.utils.sheet_to_json(worksheet);

      let startLine = 0
      // 날짜 형식 옵션을 사용하여 JSON 변환
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        raw: false, // 날짜 데이터를 숫자가 아닌 텍스트로 변환
        dateNF: 'yyyy-mm-dd HH:MM:SS', // 날짜 형식 지정
        range: startLine
      });
      console.log("jsonData:",jsonData);

      if(jsonData.length > 2001)
      {
        this.$swal.fire("","일괄입력데이타의 최대값은 2000건입니다.", "warning")
        return false
      }
      else
      {
        this.$swal.fire("","데이타의 양에 따라 시간이 소요될 수 있습니다.<br>종료안내까지 대기해주세요.", "info")
      }

      if(st === undefined)
      {
        this.$swal.fire("", "입력대상이 지정되지 않았습니다. 재시도해주세요", "warning")
        location.reload(true)
      }
      else if(st === "searchExcel_opt")        //선택옵션일괄등록
      {
        let res = ""
        let i=0

        while(i < jsonData.length) 
        {
          let optCode = await this.genCode("OP-", "o")
          // this.optPdtCode = optPdtCode
          // this.excelUpData.pdtCode         = this.productId                                         //상품코드
          //this.excelUpData.optPdtCode      = this.optPdtCode                                        //선택옵션코드
          this.excelUpData.optPdtName      = jsonData[i].optPdtName                                 //옵션명
          this.excelUpData.optPdtPrice     = jsonData[i].optPdtPrice                                //성인요금
          this.excelUpData.optPdtPrice_chi = jsonData[i].optPdtPrice_chi                            //소인요금
          this.excelUpData.optPdtPrice_inf = jsonData[i].optPdtPrice_inf                            //유아요금
          this.excelUpData.optPdtRemarks   = jsonData[i].optPdtRemarks                              //옵션소개
          this.excelUpData.optPdtSDate     = jsonData[i].optPdtSDate                                //옵션판매개시
          this.excelUpData.optPdtEDate     = jsonData[i].optPdtEDate                                //옵션판매종료
          this.excelUpData.insId           = this.user.handlerId;
          // this.excelUpData.insDate         = todayDate;

          let useFlag = "N"

          //옵션정보등록
          let res_insOptPdtInfo =  await this.$api("/api/insOptPdtInfo", {param: [
            this.productId, optCode, this.excelUpData.optPdtName, useFlag, this.excelUpData.optPdtRemarks, this.excelUpData.optPdtSDate, this.excelUpData.optPdtEDate, this.excelUpData.insId, this.excelUpData.optPdtPrice, this.excelUpData.optPdtPrice_chi, this.excelUpData.optPdtPrice_inf
          ]});

          if(res_insOptPdtInfo.affectedRows > 0)
          {
            res = "OK"
          }
          else
          {
            res = "Error"
            break
          }
          i++
        }
        if(res === "error")
        {
          this.$swal.fire('', '처리 중 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', jsonData.length+'개의 옵션이 등록되었습니다..', 'success');
          location.reload(true)
        }
      }
      else if(st === "searchExcel_add")        //추가상품 일괄등록
      {
        let res = ""
        let j=0

        while(j < jsonData.length) 
        {
          let addPdtCode = await this.genCode("AP-", "a")
          // this.optPdtCode = optPdtCode
          // this.excelUpData.pdtCode         = this.productId                                         //상품코드
          //this.excelUpData.optPdtCode      = this.optPdtCode                                        //추가상품코드
          this.excelUpData.addPdtName      = jsonData[j].addPdtName                                   //추가상품명
          this.excelUpData.addPdtPrice     = jsonData[j].addPdtPrice                                  //추가상품요금
          this.excelUpData.addPdtRemarks   = jsonData[j].addPdtRemarks                                //추가상품소개
          this.excelUpData.addPdtSDate     = jsonData[j].addPdtSDate                                  //추가상품판매개시
          this.excelUpData.addPdtEDate     = jsonData[j].addPdtEDate                                  //추가상품판매종료
          this.excelUpData.insId           = this.user.handlerId;
          // this.excelUpData.insDate         = todayDate;

          let useFlag = "N"

          //옵션정보등록
          let res_insAddPdtInfo =  await this.$api("/api/insAddPdtInfo", {param: [
            this.productId, addPdtCode, this.excelUpData.addPdtName,  this.excelUpData.addPdtPrice, useFlag, this.excelUpData.addPdtRemarks, this.excelUpData.addPdtSDate, this.excelUpData.addPdtEDate, this.excelUpData.insId
          ]});

          if(res_insAddPdtInfo.affectedRows > 0)
          {
            res = "OK"
          }
          else
          {
            res = "Error"
            break
          }
          j++
        }
        if(res === "error")
        {
          this.$swal.fire('', '처리 중 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', jsonData.length+'개의 추가상품이 등록되었습니다.', 'success');
          location.reload(true)
        }
      }
      else
      {
        this.$swal.fire("", "첨부파일의 적절한 분류가 되지 않았습니다. 재시도해주세요", "warning")
        location.reload(true)
      }
    },
    

    //선택옵션리스트
    async getOptPdtList(pCode) {
      let exist_optPdt = await this.$api("/api/pdtOptList", {param: [pCode]});

      if(exist_optPdt.length > 0)
      {
        this.exist_optPdt = exist_optPdt

        let i = 0
        while(i < exist_optPdt.length)
        {
          this.exist_optPdt[i].optPdtSDate = this.getDateFormat(this.exist_optPdt[i].optPdtSDate)
          this.exist_optPdt[i].optPdtEDate = this.getDateFormat(this.exist_optPdt[i].optPdtEDate)
          i++
        }
      }
      //console.log(this.exist_addPdt)
    },

    //추가상품리스트
    async getAddPdtList(pCode) {
      let exist_addPdt = await this.$api("/api/addPdtList", {param: [pCode]});

      if(exist_addPdt.length > 0)
      {
        this.exist_addPdt = exist_addPdt

        let i = 0
        while(i < exist_addPdt.length)
        {
          this.exist_addPdt[i].addPdtSDate = this.getDateFormat(this.exist_addPdt[i].addPdtSDate)
          this.exist_addPdt[i].addPdtEDate = this.getDateFormat(this.exist_addPdt[i].addPdtEDate)
          i++
        }
      }
      //console.log(this.exist_addPdt)
    },

    async getNationList() {
      let nationList_via = await this.$api("/api/nationList", {});
      this.nationList_via = nationList_via;
      //console.log("nationList_via",nationList_via);
    },
    async getSalesRoute() {
      this.sRoute = await this.$api("/api/salesRoute", {});
      //console.log("sRoute",this.sRoute);
    },
    async getProductInfo() {
      let productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);

      this.getOptPdtList(this.productId)    //선택옵션리스트

      this.getAddPdtList(this.productId)    //추가상품리스트
      //console.log("productInfo",this.productInfo);
    },

    //선택옵션저장
    async saveOptProduct(pCode){
      if(this.optPdt.length <= 0)
      {
        this.$swal.fire("", "저장할 선택옵션이 없습니다.", "warning")
        return false;
      }
      
      let i = 0
      //let addUseFlag = "N"

      while(i < this.optPdt.length)
      {
        if(!this.optPdt[i].optPdtName || !this.optPdt[i].optPdtSDate || !this.optPdt[i].optPdtEDate || this.optPdt[i].optPdtPrice < 0 || this.optPdt[i].optPdtPrice_chi < 0 || this.optPdt[i].optPdtPrice_inf < 0)
        {
          this.$swal.fire("", "선택옵션의 필수정보가 누락 또는 가격세팅에 문제가 발견되었습니다", "warning")
          return false;
        }
        i++
      }

      let j = 0
      let res = ""
      while(j < this.optPdt.length)
      {
        let res_insOptPdtInfo =  await this.$api("/api/insOptPdtInfo", {param: 
          [
            pCode, this.optPdt[j].optCode, this.optPdt[j].optPdtName, this.optPdt[j].optPdtUseFlag, this.optPdt[j].optPdtRemarks, this.optPdt[j].optPdtSDate, this.optPdt[j].optPdtEDate, this.user.handlerId, this.optPdt[j].optPdtPrice, this.optPdt[j].optPdtPrice_chi, this.optPdt[j].optPdtPrice_inf
          ]
        });

        
        if(res_insOptPdtInfo.affectedRows > 0)
        {
          res = "ok"
        }
        else
        {
          res = "error"
          break
        }
        j++
      }

      if(res == "ok")
      {
        this.$swal.fire("","선택옵션 등록이 완료되었습니다","info")
        location.reload(true)
      }
      else if(res == "error")
      {
        this.$swal.fire("","선택옵션 등록 중 에러가 발생했습니다","warning")
          return false        
      }
    },

    //추가상품저장
    async saveAddProduct(pCode){
      if(this.addPdt.length <= 0)
      {
        this.$swal.fire("", "저장할 추가상품이 없습니다.", "warning")
        return false;
      }
      
      let i = 0
      //let addUseFlag = "N"

      while(i < this.addPdt.length)
      {
        //추가상품 0원가능토록
        // if(!this.addPdt[i].addPdtName || !this.addPdt[i].addPdtPrice || !this.addPdt[i].addPdtSDate || !this.addPdt[i].addPdtEDate)
        if(!this.addPdt[i].addPdtName || this.addPdt[i].addPdtPrice < 0 || !this.addPdt[i].addPdtSDate || !this.addPdt[i].addPdtEDate)        
        {
          this.$swal.fire("", "추가상품의 필수정보가 누락되어 있습니다", "warning")
          return false;
        }
        i++
      }

      let j = 0
      let res = ""
      while(j < this.addPdt.length)
      {
        let res_insAddPdtInfo =  await this.$api("/api/insAddPdtInfo", {param: 
          [
            pCode, this.addPdt[j].addPdtCode, this.addPdt[j].addPdtName, this.addPdt[j].addPdtPrice, this.addPdt[j].addPdtUseFlag, this.addPdt[j].addPdtRemarks, this.addPdt[j].addPdtSDate, this.addPdt[j].addPdtEDate, this.user.handlerId
          ]
        });

        
        if(res_insAddPdtInfo.affectedRows > 0)
        {
          res = "ok"
        }
        else
        {
          res = "error"
          break
        }
        j++
      }

      if(res == "ok")
      {
        this.$swal.fire("","추가상품 등록이 완료되었습니다","info")
        location.reload(true)
      }
      else if(res == "error")
      {
        this.$swal.fire("","추가상품 등록 중 에러가 발생했습니다","warning")
          return false        
      }
    },

    //선택옵션생성
    async makeSelOption() {
      let aHeader = "OP-"
      let optCode = await this.genCode(aHeader, "o")
      this.optPdt.push({
        optCode: optCode, optPdtName: "", optPdtPrice: 0, optPdtPrice_chi: 0, optPdtPrice_inf: 0, optPdtUseFlag: 'N', optPdtSDate: "", optPdtEDate: ""
      })
    },
    //선택옵션삭제
    removeOptProduct(val) {
      this.optPdt.splice(val, 1);
      //this.etcOptions.splice(val, 1);
    },

    //선택옵션업데이트
    async updateOptPdt(flag, aCode, uFlag, idx){
      let useFlag = (uFlag === "Y") ? "N" : "Y"
      
      if(flag === 'C')
      {
        let change_existOptPdt = await this.$api("/api/change_existOptPdt", {param: 
          [
            useFlag, this.user.handlerId, aCode
          ]
        });
        if(change_existOptPdt.affectedRows < 0)
        {
          this.$swal.fire("","판매상태 전환 중 오류가 발생했습니다.", "warning")
          return false
        }
        else
        {
          this.$swal.fire("","판매상태가 전환되었습니다.", "warning")
          location.reload(true)
        }
      }
      else if(flag === 'U')
      {
        let update_existOptPdt = await this.$api("/api/update_existOptPdt", {param: 
          [
            this.exist_optPdt[idx].optPdtName, this.exist_optPdt[idx].optPdtRemarks, this.exist_optPdt[idx].optPdtSDate, this.exist_optPdt[idx].optPdtEDate, this.user.handlerId, this.exist_optPdt[idx].optPdtPrice, this.exist_optPdt[idx].optPdtPrice_chi, this.exist_optPdt[idx].optPdtPrice_inf, aCode
          ]
        });
        if(update_existOptPdt.affectedRows < 0)
        {
          this.$swal.fire("","추가상품 정보변경 중 오류가 발생했습니다.", "warning")
          return false
        }
        else
        {
          this.$swal.fire("","추가상품 정보가가 변경되었습니다.", "warning")
          location.reload(true)
        }
      }
    },


    //추가상품생성
    async makeAddProduct() {
      let aHeader = "AP-"
      let addPdtCode = await this.genCode(aHeader, "a")
      this.addPdt.push({
        addPdtCode: addPdtCode, addPdtName: "", addPdtPrice: 0, addPdtUseFlag: 'N', addPdtSDate: "", addPdtEDate: ""
      })
    },

    //추가상품삭제
    removeAddProduct(val) {
      this.addPdt.splice(val, 1);
      //this.etcOptions.splice(val, 1);
    },

    async updateAddPdt(flag, aCode, uFlag, idx){
      let useFlag = (uFlag === "Y") ? "N" : "Y"
      
      if(flag === 'C')
      {
        let change_existAddPdt = await this.$api("/api/change_existAddPdt", {param: 
          [
            useFlag, this.user.handlerId, aCode
          ]
        });
        if(change_existAddPdt.affectedRows < 0)
        {
          this.$swal.fire("","판매상태 전환 중 오류가 발생했습니다.", "warning")
          return false
        }
        else
        {
          this.$swal.fire("","판매상태가 전환되었습니다.", "warning")
          location.reload(true)
        }
      }
      else if(flag === 'U')
      {
        let update_existAddPdt = await this.$api("/api/update_existAddPdt", {param: 
          [
            this.exist_addPdt[idx].addPdtName, this.exist_addPdt[idx].addPdtPrice, this.exist_addPdt[idx].addPdtRemarks, this.exist_addPdt[idx].addPdtSDate, this.exist_addPdt[idx].addPdtEDate, this.user.handlerId, aCode
          ]
        });
        if(update_existAddPdt.affectedRows < 0)
        {
          this.$swal.fire("","추가상품 정보변경 중 오류가 발생했습니다.", "warning")
          return false
        }
        else
        {
          this.$swal.fire("","추가상품 정보가가 변경되었습니다.", "warning")
          location.reload(true)
        }
      }
    },

    //추가상품코드 생성
    async genCode(param, flag) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) 
      {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        //옵션 또는 추상 중복검사
        let chk_genCode = []
        if(flag === "a")
        {
          chk_genCode = await this.$api("/api/checkDub_addPdtCode", { param: [rtnCode] });
        }
        else if(flag === "o")
        {
          chk_genCode = await this.$api("/api/checkDub_optPdtCode", { param: [rtnCode] });
        }
        else
        {
          this.$swal.fire("","선택옵션 또는 추가상품 여부가 불명확합니다. 화면리로드(F5) 후 다시 진행해 주세요", "warning")
        }

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","코드 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },

    goToList() {
      if(this.pdtFlag === 'MIX') {
        this.$router.push({path: '/salesMix'});
      }
      else {
        this.$router.push({path: '/sales'});
      }
    },

    // changePdtOption(idx){
    //   if(idx === "none" || idx === "")
    //   {
    //     this.optName = ""
    //     this.optCode = ""
    //     this.optRemarks = ""
    //   }
    //   else if(idx >= 0)
    //   {
    //     let selOptInfo = this.optPrcInfo[idx]

    //     this.optName = selOptInfo.optName
    //     this.optCode = selOptInfo.optCode
    //     this.optRemarks = selOptInfo.optRemarks
    //     this.useFlag = selOptInfo.useFlag             //판매상태
    //     //this.getAddPdtList(this.productId, this.optCode)
    //   }
    // },

    // async pdtOptSave(){
    //   if(this.optCode != "")
    //   {
    //     this.$swal.fire("","기존 예약보호를 위해 옵션명 변경은 불가능합니다.<br>옵션생성을 선택하여 신규로 진행해주세요", "warning")
    //     return false;
    //   }
    //   else
    //   {
    //     if(!this.optName)
    //     {
    //       this.$refs.optName.focus()
    //       this.$swal.fire("","옵션명을 입력하세요", "warning")
    //       return false;
    //     }
    //     else
    //     {
    //       let timeStamp = "";
    //       let today = new Date();   
    //       let yyyy = today.getFullYear();
    //       let mm = today.getMonth()+1;
    //       let dd = today.getDate();
    //       let ymd = `${yyyy}${mm}${dd}`

    //       timeStamp = today.getTime(); // 옵션코드에 들어갈 타임스탬프
    //       let catCode = "Q"+this.productInfo.salesRoute;

    //       this.optCode = catCode+"-"+ymd+"-"+timeStamp;
    //       this.insId = this.user.handlerId;
    //       this.optClass = this.salesRoute;
    //       let useFlag = "Y"

    //       let res_insPdtOPtion = []
    //       res_insPdtOPtion = await this.$api("/api/createPdtOption", {param: [
    //         this.productId, this.optCode, this.optName, useFlag, this.insId, this.priceClass, this.optRemarks
    //       ]});
    //       //console.log("res_insPdtOPtion:", res_insPdtOPtion);
    //       // 인서트오류처리
    //       if(res_insPdtOPtion.affectedRows < 0) 
    //       {
    //         this.$swal.fire("", "옵션정보 생성 중 오류가 발생했습니다", "warning")
    //         return false;
    //       }
    //       else
    //       {
    //         this.$swal.fire("", "옵션이 생성되었습니다.<br>옵션가격을 설정하세요", "success")
    //         location.reload(true)
    //       }
    //     }
    //   }
    //   //console.log("optPrcInfo",this.optPrcInfo);
    // },

    // //옵션판매중지
    // change_OptStat(){
    //   if(!this.optCode)
    //   {
    //     this.$swal.fire("","판매중지할 옵션을 선택해주세요.", "warning")
    //     return false
    //   }
    //   else
    //   {
    //     this.$swal.fire({
    //       title: "",
    //       text: '선택된 옵션의 판매상태를 판매 <-> 중지 변경합니까?',
    //       showCancelButton: true,
    //       confirmButtonText: '변경',
    //       cancelButtonText: '취소',
    //     }).then(async (result) => {
    //       if(result.isConfirmed)
    //       {
    //         let statFlag = (this.useFlag === "Y") ? "N" : "Y"
    //         let updatePdtOptionStat = []
    //         updatePdtOptionStat = await this.$api("/api/updatePdtOptionStat", {param: [statFlag, this.insId, this.optCode]});

    //         if(updatePdtOptionStat.affectedRows > 0)
    //         {
    //           this.$swal.fire("","변경되었습니다","success")
    //           location.reload(true)
    //         }
    //         else
    //         {
    //           this.$swal.fire("","상태변경 중 오류가 발생했습니다.<br>재시도해주세요.","success")
    //           location.reload(true)
    //         }
    //       }
    //     })
    //   }
    // },

    // priceInsert(sDate, eDate, oCode)
    // {
    //   if(!this.optCode)
    //   {
    //     this.$refs.pdtOptionSelect.focus();
    //     this.$swal.fire("","설정할 옵션을 선택하세요","warning");
    //     return false
    //   }
    //   if(!this.optPriceStart) 
    //   {
    //     return this.$swal.fire("","옵션등록 시작일을 입력하세요","warning");
    //   }
    //   if(!this.optPriceEnd) 
    //   {
    //     return this.$swal.fire("","옵션등록 종료일을 입력하세요","warning");
    //   }

    //   // 가격입력 종료일은 선택월의 말일로 자동설정
    //   let lastDay_tmp = new Date(eDate);
    //   let year = lastDay_tmp.getFullYear();
    //   let month = lastDay_tmp.getMonth()+1;
    //   let lastDay = new Date(year,month,0);
    //   this.lastDay = this.getDateFormat(lastDay);
    //   //console.log("/endDay:",this.getDateFormat(lastDay));

    //   let diffDate = sDate.replace(/-/g, "") - eDate.replace(/-/g,"")
    //   if(diffDate > 0 )
    //   {
    //     return this.$swal.fire('', '시작일은 종료일보다 이전이어야 합니다.', 'error')
    //   }

    //   //금액등록기간
    //   let dpDate = new Date(this.getDateFormat(sDate));
    //   let rtDate = lastDay;
    //   let dateDiff = dpDate.getTime() - rtDate.getTime();        
    //   this.dInterval = Math.abs(dateDiff / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일

    //   this.pdtCode = this.productId;
    //   //this.optCode = catCode+"-"+ymd+"-"+timeStamp;
    //   //this.optCode = oCode
    //   //this.optPrcInfo.insDate = `${yyyy}-${mm}-${dd}`;
    //   this.insId = this.user.handlerId;

    //   this.optClass = this.salesRoute;

    //   // 등록전 확인
    //   this.$swal.fire({
    //     title: "",
    //     text: '옵션가격등록 중, 중복된 기간이 있는 경우 업데이트됩니다.',
    //     showCancelButton: true,
    //     confirmButtonText: '등록',
    //     cancelButtonText: '취소',
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       let funcType = "";
    //       let sFlag = "";
    //       let sStat = "";

    //       //기존 세팅값 정리(중복된 월의 세팅값 무효처리)
    //       let nullifyDubDate = await this.$api("/api/nullifyDubDate_opt", {param: [
    //         this.optCode, sDate+'-01', eDate+'-01'
    //       ]});
    //       if(nullifyDubDate.affectedRows < 0 || nullifyDubDate.affectedRows === undefined){
    //         this.$swal.fire('','중복된 날짜가 없습니다', 'info')
    //       }
    //       if(['T', 'L', 'F', 'E'].includes(this.productInfo.salesRoute))
    //       {
    //         funcType = "M";     //멀티가격

    //         //시즌구분폐지
    //         sFlag = "G"
    //         sStat = "Y"
    //         await this.insertseasonPrice(sFlag, sStat, funcType, oCode);            
    //       }
    //       else if(['H', 'S'].includes(this.productInfo.salesRoute))
    //       {
    //         funcType = "S";     //싱글가격

    //         //시즌제 폐지
    //         sFlag = "G"
    //         sStat = "Y"
    //         await this.insertseasonPrice(sFlag, sStat, funcType, oCode);
    //       }
    //     }
    //   })
    // },

    // async insertseasonPrice(sFlag, sStat, funcType, oCode) {    //시즌속성, 적용상태
    //   //let seasonFlag = sFlag;                                 //시즌속성
    //   let spUseFlag = sStat;                                    //시즌가격 이용여부(0:Y 1:N)
    //   let sfuncType = funcType;                                 //m
    //   let optCode = oCode
      
    //   let resIns_product_prc = []
    //   // let prcCode = "Q"+this.productId.charAt(0)+"-"+ymd+timeStamp; //기본옵션가격과는 다른 옵션가격을 가지는  고유옵션가격코드
      
    //   let arr_p = [];
    //   if(this.productInfo.salesRoute === "S") 
    //   {
    //     arr_p = [this.optPrice1];
    //   }
    //   else 
    //   {
    //     arr_p = [this.optPrice[1],this.optPrice[2],this.optPrice[3],this.optPrice[4]];
    //   }

    //   //Multi Price
    //   if(sfuncType === "M") 
    //   {
    //     if(sFlag === "G") 
    //     {
    //       resIns_product_prc = await this.$api("/api/proc_setPdtOption", {param: [
    //         //this.pdtCode, this.optCode, i, this.optPrice[i], spUseFlag, this.insId, this.getDateFormat(this.optPriceStart), this.lastDay
    //         sfuncType,
    //         this.pdtCode, optCode, arr_p[0], arr_p[1], arr_p[2], arr_p[3], 
    //         spUseFlag, this.insId, this.getDateFormat(this.optPriceStart), this.lastDay
    //       ]});
    //           console.log("resIns_product_prc:", resIns_product_prc);
    //           // 인서트오류처리
    //         if (resIns_product_prc.error) 
    //         {
    //           this.$swal.fire(
    //             "",
    //             "옵션가격생성 중 오류발생: " + resIns_product_prc.error.message,
    //             "error"
    //           );
    //           // 에러 로깅 (옵션)
    //           console.error("resIns_product_prc 호출 에러:", resIns_product_prc.error);
    //           return false;
    //         }

    //       this.$swal.fire('','지정된 기간의 기본가격등록이 완료되었습니다', 'success')
    //     }
    //   }
    //   //Single Price
    //   else if(sfuncType === "S") {
    //     if(sFlag === "G") 
    //     {
    //       resIns_product_prc = await this.$api("/api/proc_setPdtOption", {param: [
    //           sfuncType,
    //           this.pdtCode, optCode, arr_p[0], '0', '0', '0', 
    //           spUseFlag, this.insId, this.getDateFormat(this.optPriceStart), this.lastDay
    //         ]});

    //         // 인서트오류처리
    //         if (resIns_product_prc.error) 
    //         {
    //           this.$swal.fire(
    //             "",
    //             "옵션가격생성 중 오류발생: " + resIns_product_prc.error.message,
    //             "error"
    //           );
    //           // 에러 로깅 (옵션)
    //           console.error("resIns_product_prc 호출 에러:", resIns_product_prc.error);
    //           return false;
    //         }
    //         else
    //         {
    //           this.$swal.fire('','지정된 기간의 기본가격등록이 완료되었습니다', 'success')
    //           location.reload(true)
    //         }
    //       }
    //   }
    // },

    //가격관리페이지로 이동
    // goToPriceSetPage(optCode) {
    //   //alert(optCode)
    //   if(!this.optCode)
    //   {
    //     this.$refs.pdtOptionSelect.focus();
    //     this.$swal.fire("","설정할 옵션을 선택하세요", "warning")
    //     return false
    //   }
    //   this.$router.push({path: '/productOptSet', query: {productId: this.productId, optCode: optCode}});
    // }
  }
}
</script>