<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="alert alert-success" role="alert">
          <b><i class="bi bi-megaphone fs-4 text-danger"></i></b>&nbsp; 시즌기간이 중복되지 않도록 특별히 주의하시기 바랍니다.
        </div>
        <div class="float-end mb-1">
          <button type="button" class="btn btn-success" @click="createSeason()">시즌등록</button>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class = "small">
            <tr>
              <th>시즌구분</th>
              <th>시즌기간</th>
              <th>비고사항</th>
              <th>적용상태</th>
              <th>시즌여부</th>
              <th>상태변경</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(season, i) in seasonList">
              <td class="text-success">{{season.codeNameKor}} ({{season.seasonCode}})</td>
              <!-- <td class="text-success" v-if="season.seasonCode === 'T'">최성수(P)</td>
              <td class="text-success" v-else-if="season.seasonCode === 'P'">성수기(P)</td>
              <td class="text-info" v-else-if="season.seasonCode === 'O'">비수기(O)</td> -->

              <td>{{ getDateFormat(season.seasonStart) }} ~ {{ getDateFormat(season.seasonEnd) }}</td>
              <td class="text-small">{{ season.ssRemarks }}</td>
              <td class="text-secondary">{{ season.sUseFlag }}</td>

              <td class="text-danger" v-if="season.sStatus === 'Y'">{{ season.sStatus }}</td>
              <td v-else>{{ season.sStatus }}</td>
              
              <td>
                <!-- me-1: rigt margin1 -->
                <button type="button" class="btn btn-warning btn-xs" @click="changeStatus(season.sUseFlag, season.sSeq)">변경</button>
              </td>
            </tr>
          </tbody>
        </table>
        
        <!-- register season area -->
        <div class="mt-2 mb-2 align-middle fs-4"><i class="bi bi-capslock-fill text-danger"  v-if="this.createFlag == 'Y'"></i></div>
        <table class="table table-bordered table-striped fs-6" v-if="this.createFlag == 'Y'">
          <thead class = "small">
            <tr>
              <th class = "col-2">시즌구분</th>
              <th class = "col-4">시즌기간</th>
              <th class = "col-6">비고사항</th>
              <th class= "col-2">등록</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr>
              <td>
                <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_seasonCode" v-model="seasonCode">
                  <option :value="sCodeList.baseCode" :key=i v-for="(sCodeList,i) in seasonCodeList">{{sCodeList.codeNameKor}}({{sCodeList.baseCode}})</option>
                </select>
              </td>
              <td>
                <div class="input-group mb-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">개시</span>
                    <input type="date" class="form-control" ref="txt_seasonStart" v-model="seasonStart">
                    <span class="input-group-text" id="basic-addon1">종료</span>
                    <input type="date" class="form-control" ref="txt_seasonEnd" v-model="seasonEnd">
                  </div>
                </div>
              </td>
              <td>
                <textarea class="form-control" placeholder="100자 이내" ref="txt_seasonRemarks" id="floatingTextarea" style="height:20px" 
                  v-model="ssRemarks" maxlength="100"></textarea>
              </td>
              <td>
                <!-- me-1: rigt margin1 -->
                <button type="button" class="btn btn-outline-primary btn-xs" @click="registerSeason()">OK</button>
              </td>
              <!-- <td class="d-none">{{ notice.seqNotice }}</td>
              <td class="d-none">{{ notice.nUseFlag }}</td> -->
            </tr>
          </tbody>
        </table>

      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      seasonList: [],
      createFlag: "N",
      seasonCodeList: [], //시즌코드리스트
      seasonCode: "",
      seasonStart: "",
      seasonEnd: "",
      ssRemarks: ""
    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;
    this.getSeasonList();
    this.getSeasonCodeList();
    //console.log("USER:",this.user);
  }, 
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },    
    async getSeasonList() {
      this.seasonList = await this.$api("/api/seasonList", {});
      console.log("seasonList:",this.seasonList);
    },
    createSeason() {
      this.createFlag = (this.createFlag === "Y") ? "N" : "Y";
      this.seasonCode = "";
      this.seasonStart = "";
      this.seasonEnd = "";
      this.ssRemarks = "";
    },
    async getSeasonCodeList() {
      this.seasonCodeList = await this.$api("/api/seasonFlag", {});
      console.log("seasonCodeList:",this.seasonCodeList);
    },
    // async registerSeason() {
    //   this.seasonCodeList = await this.$api("/api/seasonFlag", {});
    //   console.log("seasonCodeList:",this.seasonCodeList);
    // },
    async registerSeason() {
      if(!this.seasonCode) {
        this.$refs.sel_seasonCode.focus();
        return this.$swal.fire("","시즌코드를 선택해 주세요","warning");
      }
      if(!this.seasonStart) {
        this.$refs.txt_seasonStart.focus();
        return this.$swal.fire("","시즌시작일이 누락되어 있습니다.","warning");
      }
      if(!this.seasonEnd) {
        this.$refs.txt_seasonEnd.focus();
        return this.$swal.fire("","시즌종료일이 누락되어 있습니다.","warning");
      }

      if(this.seasonStart > this.seasonEnd) {
        return this.$swal.fire("","시작일과 종료일을 체크해 주세요.","error");
      }
      let insId = this.user.handlerId;
      let sUseFlag = "N"; //시즌속성
      //console.log(insId,"/",sUseFlag);

      let resIns_seasonInfo = await this.$api("/api/sesonInsert", {param: [this.seasonCode, this.seasonStart, this.seasonEnd, insId, sUseFlag, this.ssRemarks]});
      if(Object.keys(resIns_seasonInfo)[0] === 'error') {
        this.$swal.fire('','저장실패', 'error')
      }
      else {
        this.$swal.fire('','저장완료', 'success');
        location.reload();
      }
    },    
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    changeStatus(nUseFlag, sSeq) {
      console.log(nUseFlag,"/",sSeq);
      //변경전 확인
      this.$swal.fire({
        title: '적용상태(실행<->중지)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let sStatus = (nUseFlag == "Y") ? "N" : "Y"
          await this.$api("/api/changeSeasonStatus", {param: [sStatus, sSeq]});
          // 상태변경 후, 갱신
          this.getSeasonList();
          this.$swal.fire('', '변경완료', 'success')
        }
      })
    }
  }
}
</script>