<template>  
  <div id="app">
    <header id="header" class="header navbar-area print-hide">
      <div class="container">
        <!-- PC사이즈 대응 -->
        <div class="pcUser" id="user">
          <div class="mlogo">
            <a href="/">
              <img src="../src/assets/img/Ntabi.png" class="logo" alt="Ntabi">
            </a>
            <img src="../src/assets/img/pBus.png" alt="" style="width:30px;" v-if="this.user.email != undefined && this.user.memLevel =='P'">
            <img src="../src/assets/img/sBus.png" alt="" style="width:30px;" v-else-if="this.user.email != undefined && this.user.memLevel =='S'">
            <img src="../src/assets/img/gBus.png" alt="" style="width:30px;" v-else-if="this.user.email != undefined && (this.user.memLevel != 'P' && this.user.memLevel != 'S')">
          </div>
          <div class="topMenu text-end">
            <div class="nav-sub text-end">
              <button class="tablink2 b2cTag" @click="toB2CLoginPage" v-if="this.user.email == undefined">로그인</button>
              <button class="tablink2 b2cTag text-danger" @click="toB2CLogout" v-else>로그아웃</button>
              <button class="tablink2" @click="toMyPage()">마이페이지</button>
              <button class="tablink2" @click="toReqQuotation()">기업/단체문의</button>
              <button class="tablink2" onclick="location.href='/customerService'">고객센터</button>
            </div>
            <div class="nav-main text-end">
              <button class="tablink tmenu" @mouseover="openPage('Ndaytrip', $event.target)">
                <a href="/bustour">엔데이트립현지투어</a>
              </button>
              <button class="tablink tmenu noClose" @mouseover="openPage_0('Ntabi', $event.target)">
                <a href="/subList?nt=JP">패키지여행</a>
              </button>
              <button class="tablink tmenu" @mouseover="openPage('Ship', $event.target)">
                <a href="/shipTour">일본선박</a>
              </button>              
              <button class="tablink tmenu" @mouseover="openPage('VrPack', $event.target)">
                <a href="/exhibitionEvent">버라이어티팩</a>
              </button>
              <!-- <button class="tablink tmenu noClose" @mouseover="openPage_0('Quotation', $event.target)">
                <a href="/requestQuotation">기업/단체문의</a>
              </button> -->
              <button class="tablink tmenu" @mouseover="openPage_0('MemShip', $event.target)">
                <a href="/membershipPage">
                  <!-- <img class="lvar" src="../src/assets/img/pBus.png"> -->멤버십
                </a>
              </button>
              <button class="tablink tmenu" @mouseover="openPage('TktPass', $event.target)">
                <a href="/tktPass">티켓/패스</a>
              </button>
              <button class="tablink tmenu noClose" @mouseover="openPage_0('Naver', $event.target)">
                <a href="https://smartstore.naver.com/ntabi" target='_blank' class="blink">
                  <span style="color:green;">네이버예약</span>
                </a>
              </button>
              <button class="tablink tmenu noClose" @mouseover="openPage('Somen', $event.target)">
                <a href="https://smartstore.naver.com/somen" target='_blank'>
                  <span style="color:green;">소면공방</span>
                </a>
              </button>
            </div>
          </div>
          <!-- 서브 메뉴 -->
          <div class="subMenu">
            <button class="close" @click="clsoeSubMenu()" id="subClose">
              <i class="bi bi-x-square"></i>
            </button>
  
            <div id="Ndaytrip" class="tabcontent">
              <div class="sub-ndaytrip row justify-content-center">
                <table class="table" style="width:1300px;">
                  <tr>
                    <td style="width:100px;"></td>
                    <td class="center" style="width:25%;border-right:1px solid #aaa; padding:30px 50px 0 0">
                      <img src="../src/assets/img/Ndaytrip.png" class="s-logo img-fluid">
                      <p class="s-logoinfo" style="word-wrap: break-word; white-space: normal;">
                        지역 전문 가이드가 동행, 옵션으로 여행기간 중 1일을 알차게, 간편하게, 실속있게 여행 할 수 있는 현지 일일 버스투어 브랜드입니다.
                      </p>
                    </td>
                    <td class="text-start">
                      <div class="n-country text-start" style="margin-left:25px; margin-right:auto;" :key="ntCd" v-for="(ba,ntCd) in this.ntToAr">
                        <p class="big">
                          <a :href="`/bustour?nt=${ba[0].ntCd}&ar=`">{{ ba[0].NT }}</a>
                        </p>
                        <p>
                          <a :href="`/bustour?nt=${info.ntCd}&ar=${info.arCd}`" :key="info.arCd" v-for="info in ba" class="p-2">{{ info.AR }}</a>
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            
            <div id="Ntabi" class="tabcontent">
              <div class="sub-ntabi row" style="height: 100%">
                <table class="table">
                  <tr>
                    <td class="align-top" style="padding-right:20px"></td>
                    <td class="col-9">
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div id="Ship" class="tabcontent">
              <div class="sub-ship row justify-content-center">
                <table class="table" style="width:1300px;">
                  <tr>
                    <td style="width:100px;"></td>
                    <td class="center" style="width:25%;border-right:1px solid #aaa; padding:30px 50px 0 0">
                      <img src="../src/assets/img/cruise.png" class="s-logo img-fluid">
                      <p class="s-logoinfo" style="word-wrap: break-word; white-space: normal;">
                        부산항은 일본으로 향하는 선박편의 중요한 출발지로, 다양한 일본의 해안도시로 직행합니다.<br>
                        선박을 이용하여 유유자적 편안한 바다여행을 즐기세요.
                      </p>
                    </td>
                    <td class="text-start">
                      <div class="n-ship text-start" style="margin-left:25px; margin-right:auto;" :key="dtCd" v-for="(ba,dtCd) in this.spToDt">
                        <p class="big">
                          <a :href="`/shipTour?dt=${ba[0].dtCd}&tr=`">{{ ba[0].DT }}</a>
                        </p>
                        <p>
                          <a :href="`/shipTour?dt=${info.dtCd}&tr=${info.trCd}`" :key="info.trCd" v-for="info in ba" class="p-2">{{ info.TR }}</a>
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div id="VrPack" class="tabcontent">
              <div class="sub-vrPack row justify-content-center">
                <!-- <table class="table table-bordered border-1 border-dark" style="width:1300px;"> -->
                <table class="table" style="width:1300px;">
                  <tr>
                    <td style="width:100px;"></td>
                    <td class="center" style="width:25%;border-right:1px solid #aaa; padding:30px 50px 0 0">
                      <img src="../src/assets/img/Variety.png" class="s-logo-vr img-fluid">
                      <p class="s-logoinfo" style="word-wrap: break-word; white-space: normal;">
                        일반적인 패키지 여행이 아닌 시즌별, 기간별 한정이벤트여행, 기획여행, 테마여행 상품 브랜드 입니다.
                      </p>
                    </td>
                    <td class="text-start">
                      <!-- <div class="n-country text-start" style="margin-left:25px; margin-right:auto;" :key="ntCd" v-for="(ba,ntCd) in this.ntToAr">
                        <p class="big"><a href="/">{{ ba[0].NT }}</a></p>
                        <p>
                          <a href="javascript:void(0)" :key="info.arCd" v-for="info in ba" @click="goToBustour(info.ntCd, info.arCd)" class="p-2">{{ info.AR }}</a>
                        </p>
                      </div> -->
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            
            <!-- <div id="Quotation" class="tabcontent">
              <div class="sub-vrPack row" style="height: 100%">
                <table>
                  <tr>
                    <td class="align-top" style="padding-right:20px"></td>
                    <td class="col-9">
                    </td>
                  </tr>
                </table>
              </div>
            </div> -->

            <div id="MemShip" class="tabcontent">
              <div class="sub-membership row" style="height: 100%">
                <table>
                  <tr>
                    <td class="align-top" style="padding-right:20px"></td>
                    <td class="col-9">
                    </td>
                  </tr>
                </table>
              </div>
            </div>


            <div id="TktPass" class="tabcontent">
              <div class="sub-tktPass row  justify-content-center">
                <table class="table" style="width:1300px;">
                  <tr>
                    <td style="width:100px;"></td>
                    <td class="center" style="width:25%;border-right:1px solid #aaa; padding:30px 50px 0 0">
                      <img src="../src/assets/img/tktPass.png" class="tk-logo img-fluid">
                      <p class="s-logoinfo" style="word-wrap: break-word; white-space: normal;">
                        더욱 즐겁고 편안한 여행을 제공해 드리는 패스상품과 쿠폰입니다
                      </p>
                    </td>
                    <td class="text-start">
                      <div class="n-country text-start" style="margin-left:25px; margin-right:auto;" :key="ntCd" v-for="(ba,ntCd) in this.tkNtToAr">
                        <p class="big">
                          <a :href="`/tktPass?nt=${ba[0].ntCd}&ar=`">{{ ba[0].NT }}</a>
                        </p>
                        <p>
                          <a :href="`/tktPass?nt=${info.ntCd}&ar=${info.arCd}`" :key="info.arCd" v-for="info in ba" class="p-2">{{ info.AR }}</a>
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div id="Somen" class="tabcontent">
              <div class="sub-vrPack row justify-content-center">
                <table class="table" style="width:1300px;">
                  <tr>
                    <td style="width:100px;"></td>
                    <td class="center" style="width:25%;border-right:1px solid #aaa; padding:30px 50px 0 0">
                      <img src="../src/assets/img/somenlogo.jpg" class="s-logo-somen img-fluid">
                      <p class="s-logoinfo" style="word-wrap: break-word; white-space: normal;">
                        <!-- 일반적인 패키지 여행이 아닌 시즌별, 기간별 한정이벤트여행, 기획여행, 테마여행 상품 브랜드 입니다. -->
                      </p>
                    </td>
                    <td class="text-start">
                      <pre>
                        <b>소면공방</b>은 <b>엔타비글로벌(주)</b>의 식품수입판매브랜드입니다.
                        코로나19 상황에서도 여행업을 유지하기 위한 일환으로
                        평소 긴밀한 관계를 유지하던 일본 현지 지자체의 협조를 받아
                        일본의 대기업이 아닌 소상공인으로 이루어진
                        최상의 품질을 자랑하는 <b>소면장인의 도시 '시마바라'의 소면협회</b>와
                        직접 계약을 체결, <b>'프리미엄 시마바라 수연소면'</b>을 수입하여
                        판매, 납품하고 있습니다
                        <span style="color:slategrey; padding-top:0">
                          - 드셔보실 수있는 곳(납품음식점) -
                        이치류 홍대본점 외 23개 지점, 스탠딩바 전기, 젠스시, 무양도원, 소문, 캇포킨, 꼬치주간 등
                          - 구입하실 수 있는 곳 -
                        사단법인 제주올레, 쿠팡, 쿠팡로켓, 네이버쇼핑, 텐바이텐, 1300K
                        </span>
                      </pre>
                    </td>
                  </tr>
                </table>
              </div>
            </div>



          </div>
        </div>


        <!-- 모바일 내용 -->
        <div class="mobile-menu" id="userM">
          <nav id="navbarExample" class="navbar navbar-expand-lg navbar-light justify-content-center" aria-label="Main navigation">
            <div class="row container">
              <div class="col-4">
                <button class="border-0 m-recent" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileCollapseRecent" aria-controls="mobileCollapseRecent" id="btn-recentM" style="display:none">
                  <i class="bi bi-clock-history fs-5"></i>
                </button>
              </div>
              <div class="col-4"> <!-- 홈 -->
                <a href="/"><img src="../src/assets/img/Ntabi.png" class="logo" alt="Logo" style="width:70px"></a>
              </div>
              <div class="col-4 text-end"> <!-- 메뉴 -->
                <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileCollapseMenu" aria-controls="mobileCollapseMenu">
                  <span class="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
          </nav>

          <!-- 모바일 메뉴 -->
          <div class="row mmenu">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="mobileCollapseMenu" aria-labelledby="mobileCollapseMenuLabel">
              <div class="offcanvas-header">
                <!-- 로그인 -->
                <div class="toolbar-login">
                  <a class="b2cTag" href="javascript: void(0)" style="text-decoration-line: none; font-weight:normal;" @click="toB2CLoginPage" v-if="user.email == undefined">
                    로그인
                  </a>
                  <a class="b2cTag text-danger" href="javascript: void(0)" style="text-decoration-line: none; font-weight:normal;" @click="toB2CLogout" v-else>
                    로그아웃
                  </a>
                  <a class="b2cTag" href="javascript: void(0)" style="text-decoration-line: none; font-weight:normal;" @click="toMyPage" v-if="user.email != undefined">
                    마이페이지
                  </a>
                  <a class="b2cTag" href="javascript: void(0)" style="text-decoration-line: none; font-weight:normal;" @click="toReqQuotation" v-if="user.email != undefined">
                    기업/단체문의
                  </a>                  
                  <a href="/customerService">고객센터</a>
                </div>
                <!-- 닫기 -->
                <button type="button" class="btn-close text-reset mbtn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>

              <!-- 메뉴 -->
              <div class="offcanvas-body text-start">
                <ul class="navbar-nav ms-auto navbar-nav-scroll">
                  <li class="nav-item dropdown" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle" href="javascipt:void(0)" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">엔데이트립현지투어</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                      <li class="nav-item2 dropdown">
                        <div class="n-country text-start" style="margin-left:10px; margin-right:auto;" :key="ntCd" v-for="(ba,ntCd) in this.ntToAr">
                          <div>
                            <a :href="`/bustour?nt=${ba[0].ntCd}&ar=`" class="fw-bold">{{ ba[0].NT }}</a>
                          </div>
                          <div class="mssun-menu">
                            <a :href="`/bustour?nt=${info.ntCd}&ar=${info.arCd}`" :key="info.arCd" v-for="info in ba" class="mt-0 p-0">
                              &nbsp;&nbsp;&nbsp;{{ info.AR }}
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle" href="javascript: void(0)" id="dropdown03" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none" onclick="location.href='/subList?nt=JP'">
                      패키지여행
                    </a>
                  </li>

                  <li class="nav-item dropdown" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle" href="javascipt:void(0)" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">일본선박</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                      <li class="nav-item2 dropdown">
                        <div class="n-country text-start" style="margin-left:10px; margin-right:auto;" :key="dtCd" v-for="(ba,dtCd) in this.spToDt">
                          <div>
                            <a :href="`/shipTour?dt=${ba[0].dtCd}&tr=`" class="fw-bold">{{ ba[0].DT }}</a>
                          </div>
                          <div class="mssun-menu">
                            <a :href="`/shipTour?dt=${info.dtCd}&tr=${info.trCd}`" :key="info.trCd" v-for="info in ba" class="mt-0 p-0">
                              &nbsp;&nbsp;&nbsp;{{ info.TR }}
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle" href="javascript: void(0)" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none" onclick="location.href='/exhibitionEvent'">
                      버라이어티팩 (테마기획상품)
                    </a>
                  </li>

                  <li class="nav-item dropdown" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle" href="javascipt:void(0)" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">티켓/패스</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                      <li class="nav-item2 dropdown">
                        <div class="n-country text-start" style="margin-left:10px; margin-right:auto;" :key="ntCd" v-for="(ba,ntCd) in this.tkNtToAr">
                          <div>
                            <a :href="`/tktPass?nt=${ba[0].ntCd}&ar=`" class="fw-bold">{{ ba[0].NT }}</a>
                          </div>
                          <div class="mssun-menu">
                            <a :href="`/tktPass?nt=${info.ntCd}&ar=${info.arCd}`" :key="info.arCd" v-for="info in ba" class="mt-0 p-0">
                              &nbsp;&nbsp;&nbsp;{{ info.AR }}
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <!-- <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle" href="javascript: void(0)" id="dropdown05" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none" onclick="location.href='/requestQuotation'">
                      기업/단체문의
                    </a>
                  </li> -->
                  <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle" href="javascript: void(0)" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none" onclick="location.href='/membershipPage'">
                      멤버십<!-- <img class="lvar-m" src="../src/assets/img/pBus.png"> -->
                    </a>
                  </li>
                  <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle blink" href="https://smartstore.naver.com/ntabi" target='_blank'>
                      <span style="color:green;">네이버예약</span>
                    </a>
                  </li>

                  <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                    <a class="nav-link dropdown-toggle" href="https://smartstore.naver.com/somen">
                      <span style="color:green;">소면공방</span>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>

          <!-- 모바일 최근 본 상품 -->
          <div class="row mrecent" id="recent-item-m">
            <div class="offcanvas off-recent offcanvas-top" tabindex="-1" id="mobileCollapseRecent" aria-labelledby="mobileCollapseRecentLabel">
              <div class="offcanvas-header">
                <h5 class="offcanvas-title">최근 본 상품</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>

              <!-- 메뉴 -->
              <div class="offcanvas-body">
                <div class="recent-items" :style="{ height: this.recentFlag === 'Y' ? 'auto' : '0', overflow: 'hidden', transition: 'height 0.3s ease' }" 
                  :key="i" v-for="(rv,i) in this.recentViewInfo">
                  <a href="javascript:void(0)" @click="goToDetail(rv.pdtCode, rv.salesRoute)">
                    <div class="row item recent">
                      <div class="col-4"><img v-if="rv.imgName != 'noImage'" :src="`/download/${rv.pdtCode}/${rv.imgName}`" class="card-img-top" alt="상품이미지"/></div>
                      <div class="col-8 align-self-center"><p class="name">{{ rv.pdtNameKor }}</p></div> 
                    </div>
                  </a>
                  <hr style="margin:20px 3px">
                </div>
              </div>
            </div>
          </div>
        </div>



        <!-- 어드민 메뉴시작 -->
        <div class="admin" id="admin">
          <!-- <div class="mlogo">
            <a href ="/">
              <img src="../src/assets/img/Ntabi.png" class="logo" alt="Ntabi">
            </a>
          </div> -->
          <nav id="navbarExample" class="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
            <div class="navbar-collapse main-nav offcanvas-collapse" id="navbarsExampleDefault">
              <ul class="navbar-nav ms-auto navbar-nav-scroll">
                <li class="nav-item dropdown" v-if="user.handlerGrade < 20">
                  <a class="nav-link dropdown-toggle" href="javascript: void(0)" id="dropdown06" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">
                    행사관리
                  </a>
                  <ul class="dropdown-menu two" aria-labelledby="dropdown06">
                    <li><a class="dropdown-item" href="/eventList" style="text-decoration-line: none; color:black ">행사목록</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown" v-if="user.handlerGrade < 20">
                  <a class="nav-link dropdown-toggle" href="javascript: void(0)"  id="dropdown07" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">상품관리</a>
                  <ul class="dropdown-menu two" aria-labelledby="dropdown07">
                    <li><a class="dropdown-item" href="/sales" style="text-decoration-line: none; color:black">여행상품목록</a></li>
                    <li><a class="dropdown-item" href="/etcPrice" style="text-decoration-line: none; color:black">기타비용목룍</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown" v-if="user.handlerGrade < 20">
                  <a class="nav-link dropdown-toggle" href="#"  id="dropdown08" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">예약관리</a>
                  <ul class="dropdown-menu two" aria-labelledby="dropdown08">
                    <li><a class="dropdown-item" href="/reserveInfoList_admin" style="text-decoration-line: none; color:black">예약목록</a></li>
                    <li><a class="dropdown-item" href="/SpPayList_admin" style="text-decoration-line: none; color:black">외부결제</a></li>
                    <li><a class="dropdown-item" href="/nbusInquiryList_admin" style="text-decoration-line: none; color:black">엔버스문의리스트</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown" v-if="user.handlerGrade < 20">
                  <a class="nav-link dropdown-toggle" href="#"  id="dropdown09" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">대시보드</a>
                  <ul class="dropdown-menu two" aria-labelledby="dropdown09">
                    <li><a class="dropdown-item" href="#" style="text-decoration-line: none; color:black">대시보드(준비중)</a></li>
                    <li><a class="dropdown-item" href="/noticeList" style="text-decoration-line: none; color:black">공지관리</a></li>
                    <li><a class="dropdown-item" href="/requestList" style="text-decoration-line: none; color:black">상품문의리스트</a></li>
                    <li><a class="dropdown-item" href="/quotationList" style="text-decoration-line: none; color:black">견적요청리스트</a></li>
                    <li><a class="dropdown-item" href="/paymentList" style="text-decoration-line: none; color:black">결제리스트</a></li>
                    <li><a class="dropdown-item" href="/requestCancelList" style="text-decoration-line: none; color:black">취소요청리스트</a></li>
                    <li><a class="dropdown-item" href="/customerAskList" style="text-decoration-line: none; color:black">1:1문의리스트</a></li>
                    <li><a class="dropdown-item" href="/memberList" style="text-decoration-line: none; color:black">회원리스트</a></li>                  
                    <li><a class="dropdown-item" href="#" style="text-decoration-line: none; color:black">판매현황(준비중)</a></li>
                    <li><a class="dropdown-item" href="/bulkNoticeCall" style="text-decoration-line: none; color:black">일괄알림톡전송</a></li>
                    <li><a class="dropdown-item" href="/bulkNoticeCallSKD_list" style="text-decoration-line: none; color:black">알림톡예약전송리스트</a></li>                    
                    <li><a class="dropdown-item" href="/patternList" style="text-decoration-line: none; color:black">텍스트템플릿관리</a></li>
                    <!-- <li><a class="dropdown-item" href="/supExSettlement" style="text-decoration-line: none; color:black">외부정산지원</a></li> -->
                  </ul>
                </li>

                <li class="nav-item dropdown" v-if="user.handlerGrade < 20">
                    <a class="nav-link dropdown-toggle" href="#"  id="dropdown09" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">정산관리</a>
                    <ul class="dropdown-menu two" aria-labelledby="dropdown09">
                      <li><a class="dropdown-item" href="/supExSettlement" style="text-decoration-line: none; color:black">입금내역정보</a></li>
                      <li><a class="dropdown-item" href="/supExpenseList" style="text-decoration-line: none; color:black">출금내역정보</a></li>
                      <li><a class="dropdown-item" href="/businessInfo" style="text-decoration-line: none; color:black">정산거래정보</a></li>
                      <li><a class="dropdown-item" href="/businessInfo_profit_loss" style="text-decoration-line: none; color:black">수지계산서</a></li>
                      <li><a class="dropdown-item" href="/businessInvoice_profit_loss_fixed" style="text-decoration-line: none; color:black">확정수지계산리스트</a></li>                      
                    </ul>
                  </li>

                  <li class="nav-item dropdown" v-if="user.handlerGrade < 20">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown09" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">
                      지출품의
                      <!-- 품의중 -->
                      <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger" style="font-size:0.7rem;">
                        +{{ cntReqLv1 }}
                        <span class="visually-hidden">unchecked requisitions</span>
                      </span>
                      <!-- 품의반려 -->
                      <span class="position-absolute top-0 start-3 translate-middle badge rounded-pill bg-primary" style="font-size:0.7rem;">
                        +{{ cntRejectedReqLv1 }}
                        <span class="visually-hidden">unchecked requisitions</span>
                      </span>
                    </a>
                    <ul class="dropdown-menu two" aria-labelledby="dropdown09">
                      <li><a class="dropdown-item" href="/expenseReportList" style="text-decoration-line: none; color:black">지출품의리스트</a></li>
                    </ul>
                  </li>

                <li class="nav-item dropdown" v-if="user.handlerGrade < 5">
                  <a class="nav-link dropdown-toggle" href="#" id="dropdown09" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">EIS</a>
                  <ul class="dropdown-menu two" aria-labelledby="dropdown09">
                    <li><a class="dropdown-item" href="#" style="text-decoration-line: none; color:black">EIS(준비중)</a></li>
                  </ul>
                </li>

                <li class="nav-item dropdown" v-if="user.handlerGrade < 20">
                    <a class="nav-link dropdown-toggle" href="#"  id="dropdown10" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">기초정보관리</a>
                    <ul class="dropdown-menu two" aria-labelledby="dropdown10">
                      <li>
                        <a class="dropdown-item" href="/partnerList" style="text-decoration-line: none; color:black">거래처</a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/financeRateInfo" style="text-decoration-line: none; color:black">환율/기관-수수료</a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/expenditureInfo" style="text-decoration-line: none; color:black">지출항목관리</a>
                      </li>                    
                    </ul>
                  </li>
              </ul>
            </div>
            <div class="login-button3" style="padding-left: 1rem;">
              <a class="b2bTag" href="javascript: void(0)" style="text-decoration-line: none; font-weight:normal;"  @click="toB2BLoginPage" v-if="user.handlerId == undefined">
              </a>
              <a class="b2bTag text-danger" href="javascript: void(0)" style="text-decoration-line: none; font-weight:normal;" @click="b2bLogout" v-else>
                관리자로그아웃
              </a>
            </div>
          </nav>
        </div>
        <!-- 어드민 메뉴영역 끝 -->

      </div>

    </header>

    <!-- 최근본상품(PC) -->
    <div id="recent-item" v-if="this.conEmail != undefined">
      <p class="title">최근 본 상품&nbsp;
        <i class="bi bi-caret-up-fill" @click="noShow_rc()" v-if="this.recentFlag === 'Y'" style="cursor: pointer;"></i>
        <i class="bi bi-caret-down-fill" @click="noShow_rc()" v-else  style="cursor: pointer;"></i>
      </p>
      <div class="recent-items" :style="{ height: this.recentFlag === 'Y' ? 'auto' : '0', overflow: 'hidden', transition: 'height 0.3s ease' }" 
      :key="i" v-for="(rv,i) in this.recentViewInfo">
        <div class="item recent">
          <a href="javascript:void(0)" @click="goToDetail(rv.pdtCode, rv.salesRoute)">
            <img v-if="rv.imgName != 'noImage'" :src="`/download/${rv.pdtCode}/${rv.imgName}`" class="card-img-top" alt="상품이미지"/>
          </a>
          <p class="name">{{ rv.pdtNameKor }}</p>
        </div>
        <hr style="margin:20px 3px">
      </div>
      <!-- <div class="item toTop">
        <a href="#"><img src="../src/assets/img/new-test/top.png" style="width: 3rem; height:auto">맨위로</a>
      </div> -->
    </div>

    <router-view />


    <!-- ======= 이벤트, 공지 등 ======= -->
    <section id="notice" class="notice">
      <div class="container">
        <div class="row">
          <!-- 진행 중 이벤트 -->
          <div class="col-lg-5 my-4 event">
            <div class="section-title">
              <p style="font-weight: 600;">이벤트</p>
              <div id="more"><a href="javascript:void(0)" onclick="location.href='/customerNotice?param=E'">더보기</a></div>
            </div>
            <div id="carouselvarietyControls" class="carousel slide mx-auto" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div :class="`carousel-item ${i==0 ? 'active' : ''}`" :key="i" v-for="(ev, i) in this.ingEvent" id="evImage">
                  <div v-if="ev.addImageFlag == 'Y'">
                    <img :src="`/download/notice/EV/${ev.noticeCode}.jpg`" class="card-img-top" id="evImg" v-if="ev.link != undefined" @click="goToLink(ev.link)" style="cursor: pointer;">
                    <img :src="`/download/notice/EV/${ev.noticeCode}.jpg`" class="card-img-top" id="evImg" v-else>
                  </div>
                  <div v-else class="evtext">
                    <img src="../src/assets/img/noImage.jpg" class="img-fluid">
                    <div class="evname">
                      {{ ev.nTitle }}
                    </div>
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselvarietyControls" data-bs-slide="prev">
                <div class="back">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </div>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselvarietyControls" data-bs-slide="next">
                <div class="back">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </div>
              </button>
            </div>              
          </div>
          <!-- 공지사항,빠른메뉴 -->
          <div class="col-lg-7 my-4">
            <!-- 공지사항 -->
            <div class="section-title">
              <p style="font-weight: 600;">공지사항</p>
              <div id="more"><a href="/customerNotice">더보기</a></div>
            </div>
            <hr style="color:#000; margin:0 0 .7rem">
            <div class="row">
              <div class="col-lg-12" :key="j" v-for="(notice, j) in this.noticeList.slice(0,3)">
                <div class="post-entry d-flex">
                  <div class="row contents order-md-1 pl-0">
                    <div class="col-10">
                      <a href="javascript:void(0)" @click="showArea(j)">
                        <h6 v-if="notice.ctCd === 'NT'"><b style="color:black">[공지사항]</b> &nbsp; {{notice.nTitle}}</h6>
                        <h6 v-else-if="notice.ctCd === 'EV'"><b style="color:darkgreen">[이 &nbsp;벤&nbsp; 트]</b>&nbsp; {{notice.nTitle}}</h6>
                        <h6 v-else-if="notice.ctCd === 'TR'"><b style="color:darkred">[여행관련]</b>&nbsp; {{notice.nTitle}}</h6>
                        <h6 v-else-if="notice.ctCd === 'MO'"><b style="color:darkblue">[항공선박]</b>&nbsp; {{notice.nTitle}}</h6>
                        <h6 v-else>{{notice.nTitle}}</h6> 
                      </a>
                    </div>
                    <div class="col-2 text-end">
                      <span class="date-read">{{this.getDateFormat(notice.nFrom)}}</span>
                    </div>
                  </div>
                </div>
                <!-- 공지사항 내용 -->
                <div class="nContent" :id="j">
                  <div class="container py-1 px-4">
                    <p class="mb-5 text-start" style="font-size: smaller;">{{notice.nContent}}</p>
                    <div class="position-relative mb-3 text-center">
                        <img v-if="notice.addImageFlag == 'Y'" :src="`/download/notice/${notice.ctCd}/${notice.noticeCode}.jpg`" class="card-img-top" alt="..." style="width:60%; height:60%; object-fit:cover;" />
                    </div>
                    <p class="row col-lg-3 gopro" v-if="notice.linkFlag === 'Y'">
                      <a :href="`${notice.link}`" class="text-center"> 바로가기 </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- 빠른메뉴 -->
            <div class="section-title mt-4">
              <p style="font-weight: 600;">빠른메뉴</p>
            </div>
            <hr style="color:#000; margin:0 0 .7rem">
            <div class="container quick">
              <div class="row gx-2 text-center">
                <div class="col-3">
                  <a href="/customerAsk">
                    <img src="../src/assets/img/new-test/m-ask.png">
                    <p>1:1 문의</p>
                  </a>
                </div>
                <div class="col-3">
                  <a href="/requestQuotation">
                    <img src="../src/assets/img/new-test/m-group.png">
                    <p>기업/단체 문의</p>
                  </a>
                </div>
                <div class="col-3">
                  <a href="/customerService">
                    <img src="../src/assets/img/new-test/m-question.png">
                    <p>자주하는 질문</p>
                  </a>
                </div>
                <div class="col-3">
                  <a href="/pointList_client">
                    <img src="../src/assets/img/new-test/m-point.png">
                    <p>포인트 안내</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- footer pc -->
    <footer id="footer-pc" class="print-hide">
      <div class="container">
				<div class="row footer-content">

          <!-- 왼쪽 -->
          <div class="col-4">
            <p class="ftext">엔타비글로벌주식회사</p>
            <!-- 전화번호 -->
            <div class="customer">
              <div class="text-start">
                <p class="telnumber"><a href="tel:1660-4602"><i class="bi bi-telephone-fill"></i>1660-4602</a></p>
                <p>평일 09:30 ~ 17:30 (점심시간 12:00 ~ 13:00)<br>
                  *주말,공휴일 및 회사지정휴일은 휴무입니다.
                </p>
              </div>
            </div>
            <!-- sns -->
            <div class="foot-icons">
              <ul>
                <li><a href="https://www.instagram.com/ntabi_insta/" target="_blank">
                  <img src="../src/assets/img/new-test/insta.png" class="ficon"></a>
                </li>
                <li><a href="https://www.facebook.com/ntabi.co.kr/" target="_blank">
                  <img src="../src/assets/img/new-test/faceb.png" class="ficon"></a>
                </li>
                <li><a href="https://www.youtube.com/channel/UCvOSoeJtpyr-Djp0TWzU6nw" target="_blank">
                  <img src="../src/assets/img/new-test/ytube.png" class="ficon"></a>
                </li>
                <li><a href="http://pf.kakao.com/_xnmVhu/chat" target="_blank"><img src="../src/assets/img/new-test/kakao.png" class="ficon"></a></li>
                <li><a href="https://cafe.naver.com/ndaytrip" target="_blank"><img src="../src/assets/img/new-test/ncafe.png" class="ficon"></a></li>
              </ul>
            </div>
          </div>

          <!-- 오른쪽 -->
          <div class="col-8">
            <!-- 회사안내 -->
            <div class="footer-cinfo text-end">
              <ul>
                <!-- <li><a href="/companyInfo">회사소개</a></li> -->
                <li><a href="/location">위치안내</a></li>
                <li><a href="/terms">여행약관</a></li>
                <li><a href="/termsHomepage">이용약관</a></li>
                <li><a href="/privacy">개인정보취급방침</a></li>
                <li>
                  <a href="https://b2b.travelover.co.kr/main/100" target='_blank'>
                    <span class="text-container">해외여행자보험
                      <span class="text"><span class="superscript blink" style="padding-left:20%; padding-bottom: 2px;">10%추가할인혜택</span></span>
                    </span>
                  </a>
                </li> 
                <li><a href="https://ap.wifidosirak.com/ApIntro.aspx?ntabi" target='_blank'>와이파이도시락/USIM/eSIM</a></li> 
              </ul>
            </div>
            <!-- 회사정보 -->
            <div class="cinfo">
              <div class="text-end">
                <ul>
                  <li>부산시 동구 중앙대로286번길 10, 상가동 1층 1호</li>
                  <li>대표이사 : 김윤중</li>
                  <li>사업자등록번호 : 602-81-43288</li>
                  <li>통신판매업신고번호 : 제2021-부산동구-0046</li>
                  <li>우리은행: 1006-901-267484 (엔타비글로벌)</li>
                </ul>
              </div>
            </div>
            <!-- 지사 -->
            <div class="bo">
              <div class="text-end">
                <ul>
                  <li>서울지사 : 서울특별시 양천구 목동 525-2 402호</li>
                  <li>일본지사 : 후쿠오카현 후쿠오카시 조난쿠 니시카타에 2-11-4</li>
                  <li>일본지사 : 북해도 삿포로시 츄오구 미나미5죠 히가시3초메 11-1 B-307</li>
                  <li>일본지사 : 오키나와현 나하시 아메쿠1초메 11-5 206</li>
                </ul>
                <p class="copy">&copy; Copyright Ntabi. All Rights Reserved.</p>
              </div>
            </div>

            <!-- 로고 -->
            <div class="text-end">
              <img src="../src/assets/img/Ntabi.png" class="flogo">
              <img src="../src/assets/img/Ndaytrip.png" class="flogo fnday">
              <!-- <a href="https://www.visit-jy.com/ko/" target="_blank"><img src="../src/assets/img/logoForPuk.jpg" class="flogo fpuk"></a> -->
            </div>
          </div>
          <div class="text-end mt-2">
            <!-- <a href="https://sectigo.com/trust-seal" style="font-family: arial; font-size: 10px; color: #212121; text-decoration: none;">
              <img src="https://sectigo.com/images/seals/sectigo_trust_seal_lg.png" srcset="https://sectigo.com/images/seals/sectigo_trust_seal_lg.png, https://sectigo.com/images/seals/sectigo_trust_seal_lg_2x.png 2x" width="140" height="54" alt="Protected by Sectigo SSL" border="0" />
            </a> -->
            <img src="https://sectigo.com/images/seals/sectigo_trust_seal_lg.png" srcset="https://sectigo.com/images/seals/sectigo_trust_seal_lg.png, https://sectigo.com/images/seals/sectigo_trust_seal_lg_2x.png 2x" width="140" height="54" alt="Protected by Sectigo SSL"  border="0" />
            <div style="font-family: arial;font-weight:bold;font-size:15px;color:#86BEE0; padding-right: 10px;">
              SSL Certificate
              <!-- <a href="https://sectigo.com" style="color:#86BEE0; text-decoration: none;">SSL Certificate
              </a> -->
            </div>
          </div>
				</div>
			</div>
    </footer>

    
    <div id="top" class="item toTop_m">
      <a href="#"><img src="../src/assets/img/new-test/top.png" style="width: 3.5rem; height:auto"></a>
    </div>

    <!-- footer 모바일 -->
    <footer id="footer-m" class="print-hide">
      <div class="container">
				<div class="row footer-content">

          <div class="col-12 text-center">
            <!-- sns -->
            <div class="foot-icons">
              <ul>
                <li><a href="https://www.instagram.com/ntabi_insta/" target="_blank">
                  <img src="../src/assets/img/new-test/insta.png" class="ficon"></a>
                </li>
                <li><a href="https://www.facebook.com/ntabi.co.kr/" target="_blank">
                  <img src="../src/assets/img/new-test/faceb.png" class="ficon"></a>
                </li>
                <li><a href="https://www.youtube.com/channel/UCvOSoeJtpyr-Djp0TWzU6nw" target="_blank">
                  <img src="../src/assets/img/new-test/ytube.png" class="ficon"></a>
                </li>
                <li><a href="http://pf.kakao.com/_xnmVhu/chat" target="_blank"><img src="../src/assets/img/new-test/kakao.png" class="ficon"></a></li>
                <li><a href="https://cafe.naver.com/ndaytrip" target="_blank"><img src="../src/assets/img/new-test/ncafe.png" class="ficon"></a></li>
              </ul>
            </div>

            <hr>

            <!-- 회사안내 -->
            <div class="footer-cinfo text-center">
              <ul>
                <!-- <li><a href="/companyInfo">회사소개</a></li> -->
                <li><a href="/location">위치안내</a></li>
                <li><a href="/terms">여행약관</a></li>
                <li><a href="/termsHomepage">이용약관</a></li>
                <li><a href="/privacy">개인정보취급방침</a></li>
                <li>
                  <a href="https://b2b.travelover.co.kr/main/100" target='_blank'>
                    <span class="text-container">해외여행자보험
                      <span class="text"><span class="superscript blink" style="padding-left:10%; padding-bottom: 1px;">10%추가할인혜택</span></span>
                    </span>
                  </a>
                </li>
                <li><a href="https://ap.wifidosirak.com/ApIntro.aspx?ntabi" target='_blank'>와이파이도시락/USIM/eSIM</a></li> 
              </ul>
            </div>

            <p class="ftext">엔타비글로벌주식회사</p>

            <!-- 전화번호 -->
            <div class="customer">
              <div class="text-center">
                <p class="telnumber"><a href="tel:1660-4602"><i class="bi bi-telephone-fill"></i>1660-4602</a></p>
                <p>평일 09:30 ~ 17:30 (점심시간 12:00 ~ 13:00)<br>
                  *주말,공휴일 및 회사지정휴일은 휴무입니다.
                </p>
              </div>
            </div>

            <!-- 회사정보 -->
            <div class="cinfo">
              <div class="text-center">
                <ul>
                  <li>부산시 동구 중앙대로286번길 10, 상가동 1층 1호</li>
                  <li>대표이사 : 김윤중</li>
                  <li>사업자등록번호 : 602-81-43288</li>
                  <li>통신판매업신고번호 : 제2021-부산동구-0046</li>
                  <li>우리은행: 1006-901-267484 (엔타비글로벌)</li>
                </ul>
              </div>
            </div>
            <!-- 지사 -->
            <div class="bo">
              <div class="text-center">
                <ul>
                  <li>서울지사 : 서울특별시 양천구 목동 525-2 402호</li>
                  <li>일본지사 : 후쿠오카현 후쿠오카시 조난쿠 니시카타에 2-11-4</li>
                  <li>일본지사 : 북해도 삿포로시 츄오구 미나미5죠 히가시3초메 11-1 B-307</li>
                  <li>일본지사 : 오키나와현 나하시 아메쿠1초메 11-5 206</li>
                </ul>
              </div>
            </div>

            <!-- 로고 -->
            <div class="text-center">
              <img src="../src/assets/img/Ntabi.png" class="flogo">
              <img src="../src/assets/img/Ndaytrip.png" class="flogo fnday">
              <!-- <a href="https://www.visit-jy.com/ko/" target="_blank"><img src="../src/assets/img/logoForPuk.jpg" class="flogo fpuk"></a> -->
            </div>
            <div class="text-end mt-2">
              <!-- <a href="https://sectigo.com/trust-seal" style="font-family: arial; font-size: 10px; color: #212121; text-decoration: none;"> -->
                <img src="https://sectigo.com/images/seals/sectigo_trust_seal_sm.png" srcset="https://sectigo.com/images/seals/sectigo_trust_seal_sm.png, https://sectigo.com/images/seals/sectigo_trust_seal_sm_2x.png 2x" width="82" height="32" alt="Protected by Sectigo SSL" border="0" />
              <!-- </a> -->
              <div style="font-family: arial;font-weight:bold;font-size:10px;color:#86BEE0; padding-right: 5px;">
                SSL Certificate
                <!-- <a href="https://sectigo.com" style="color:#86BEE0; text-decoration: none;">SSL Certificate</a> -->
              </div>
            </div>
            <p class="copy">&copy; Copyright Ntabi. All Rights Reserved.</p>

          </div>
				</div>
			</div>
    </footer>

  </div>  
</template>

<script>
//import ImagePopup from '@/views/ImagePopup.vue'
export default {
  data() {
    return {
      closeBrowser: false,    //브라우저 클로징 체크플래그

      recentFlag: "Y",        //최근구경

      b2bTag: "",
      b2cTag: "",

      myPage: "",
      seasonFlag: "",

      bustourArea: [],        //선택가능한 버스투어지역
      ntToAr: {},             //버스투어지역

      transportList: [],      //선택가능한 교통편정보
      spToDt: {},             //선박운항지

      tktPassArea: [],        //선택가능한 티켓패스
      tpNtToAr: {},           //티켓패스 이용지역
  
      noticeList: [],         //공지사항 리스트
      showNoticeFlag: "N",
      nContent: "",
      ingEvent: [],           //진행 중인 이벤트

      recentViewInfo: [],    //최근 본 상품정보
      recentViewInfo_m: [],  //최근 본 상품정보(Mobile)

      cntReqLv1: 0,          //발의된 지출품의수
      cntRejectedReqLv1: 0,  //반려된 지출품의수
      internalId: null       //setInterval ID

      //팝업이미지
      //showImagePopup: true,
      // showImagePopup: this.shouldShowPopup(),
      // imageSrc: "/IMGs/popup/chuseok2023.jpg"
    };
  },
  // components: {
  //   ImagePopup
  // },

  created() {
    // path에서 사용된 productId
    this.user = this.$store.state.user;
    //this.memberId = this.$store.state.email;
    this.conEmail = this.user.email;
    //console.log("user:",this.user, "/", this.memberId, "/", this.conEmail);

    //우클릭 & 드래그 금지
    document.oncontextmenu = function()
    {
      return false;
    }
    document.ondragstart = function() {
      return false;
    }

    this.getBustourArea()
    this.getNoticeList("Y")
    this.getTransportInfo()
    this.getTktPassArea()

    //관리자용(발의된 품의카운트)
    this.getCountReqLv1()
    this.intervalId = setInterval(this.getCountReqLv1, 20 * 60 * 1000); // 20분단위로 지품서체크
  },
  mounted() {
    //console.log("user:",this.$store.state.user);
    if (this.$store.state.user.handlerId != undefined && this.$store.state.user.email == undefined)
    {
      document.querySelector(".b2cTag").style.display = "none";
    }
    else if (this.$store.state.user.email != undefined && this.$store.state.user.handlerId == undefined) 
    {
      document.querySelector(".b2bTag").style.display = "none";
    }

    if(this.conEmail != undefined)
    {
      this.getRecentViewProduct(this.conEmail)
    }

    //페이지 오픈 시에 체크하여 리로드
    if (localStorage.getItem('reloadCheck') === 'true') 
    {
      localStorage.removeItem('reloadCheck'); // 플래그 제거
      location.reload(true)
    }


    //Top Menu 컨트롤
    const topMenu = document.querySelector('.nav-main');
    //const topMenu = document.querySelector('.container');
    const subMenu = document.querySelector('.subMenu');

    topMenu.addEventListener('mouseenter', () => {
      topMenu.style.display = 'block';
      subMenu.style.display = 'block';
    });

    subMenu.addEventListener('mouseleave', () => {
      topMenu.style.display = 'block';
      subMenu.style.display = 'none';
    });


    //페이지 생성시, 서브닫기버튼 보이지 않도록
    document.getElementById("subClose").style.display = "none";

    //CSS컨트롤과 연계하기 위해 해상도를 체크하여 표시
    if (this.user.email != undefined) 
    {
      if (window.innerWidth > 768) { // PC 화면
        document.getElementById("recent-item").style.display = "block";
        document.getElementById("recent-item-m").style.display = "none";
      } else { // 모바일 화면
        document.getElementById("recent-item").style.display = "none";
        document.getElementById("recent-item-m").style.display = "block";
      }
    }

    if(this.user.handlerId != undefined)
    {
      document.getElementById("footer-pc").style.display = "none";
      document.getElementById("footer-m").style.display = "none";
    }

    // 페이지 로드 시 세션 만료 확인
    if(this.conEmail != undefined)
    {
      this.checkSessionExpiration();
    }
    // 사용자 액션에 대해 세션 만료 확인
    //window.addEventListener('mousemove', this.checkSessionExpiration);
    window.addEventListener('keydown', this.checkSessionExpiration);


    /* 팝업컨트롤 START */
    // const lastPopupTime = localStorage.getItem('popupClosedTime');
    // const currentTime = new Date().getTime();

    // if (lastPopupTime && currentTime - lastPopupTime < 86400000) {
    //   return;
    // }

    // this.$swal.fire({
    //   // html: '<b style="color:darkgreen">워크샵 안내.<b><br><font style="font-size:small; color: darkgray">엔타비글로벌</font>',
    //   imageUrl: '/IMGs/workshop.jpg',
    //   imageWidth: '100%',
    //   imageHeight: '95%',
    //   imageAlt: '워크샵안내',
    //   showCancelButton: true,
    //   confirmButtonText: '닫기',
    //   cancelButtonText: '오늘 하루 그만보기',
    //   confirmButtonColor: 'royalblue',
    //   cancelButtonColor: 'grey'
    // }).then((result) => {
    //   if (result.dismiss === this.$swal.DismissReason.cancel) {
    //     // '더 이상 보지 않기'를 선택한 경우 로컬 스토리지에 현재 시간을 저장
    //     localStorage.setItem('popupClosedTime', new Date().getTime());
    //   }
    // });
    /* 팝업컨트롤 END */

  },

  beforeUnmount(){
    window.removeEventListener('mousemove', this.checkSessionExpiration);
    window.removeEventListener('keydown', this.checkSessionExpiration); 
    
    clearInterval(this.intervalId); // 컴포넌트가 파괴될 때 interval 해제
  },

  computed: {
    user() {
      // 로그인전: email정보없음, 로그인후: email정보있음
      return this.$store.state.user;
    },
  },
  methods: {
    getDateFormat(date) 
    {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) 
    {
      return this.$currencyFormat(value);
    },

    //세션유지시간체크
    checkSessionExpiration() {
      const sessionExpiration = localStorage.getItem('sessionExpiration');
      const currentTime = new Date().getTime();
      //console.log("sessionExpiration:",sessionExpiration)
      
      if (sessionExpiration < currentTime) 
      {
        localStorage.removeItem('sessionExpiration'); // 세션 만료 시간 삭제(1시간)
        window.sessionStorage.clear(); // 로그아웃->세션스토리지를 모두 삭제
        window.localStorage.clear();
        //location.reload();
      }
    },

    async getCountReqLv1()
    {
      let level = this.user.handlerGrade
      let cntReqLv1 = []
      let cntRejectedReqLv1 = []
      
      if(level <= 5)
      {
        cntReqLv1 = await this.$api("/api/countReqisitionLv1ForLeader", {});
        cntRejectedReqLv1 = await this.$api("/api/countRejectedReqisitionLv1ForLeader", {});
      }
      else
      {
        cntReqLv1 = await this.$api("/api/countReqisitionLv1", { param: [this.user.handlerId] });
        cntRejectedReqLv1 = await this.$api("/api/countRejectedReqisitionLv1", { param: [this.user.handlerId] });
      }

      //console.log("recentViewInfo:", recentViewInfo)

      if(cntReqLv1.length > 0)
      {
        this.cntReqLv1 = cntReqLv1[0].cntReqLv1
      }

      if(cntRejectedReqLv1.length > 0)
      {
        this.cntRejectedReqLv1 = cntRejectedReqLv1[0].cntRJReqLv1
      }      

      //this.cntReqLv1 = new Date().toLocaleTimeString();
    },


    async getRecentViewProduct(mId)
    {
      let recentViewInfo = await this.$api("/api/proc_viewRecentProduct", {param: [mId]});

      //console.log("recentViewInfo:", recentViewInfo)

      if(recentViewInfo.length > 0)
      {
        this.recentViewInfo = recentViewInfo[0]
        this.recentViewInfo_m = recentViewInfo[0].slice(0,2)
      }
    },

    async getNoticeList(param) 
    {
      let noticeList_tmp = await this.$api("/api/noticeList", {});
      this.noticeList = noticeList_tmp.filter((item) => {
        return item.status == param;
      });

      //console.log("noticeList_tmp: ", noticeList_tmp)
      
      let ingEvent = await this.$api("/api/ingEventInfo", {})
      
      if(ingEvent.length > 0)
      {
        this.ingEvent = ingEvent
      }
    },

    showArea(j){
      document.querySelectorAll(".nContent").forEach((item) => {
        item.style.display = "none";
      });

      this.showNoticeFlag = (this.showNoticeFlag === "Y") ? "N" : "Y"

      //선택된 항목만 보이도록
      if(this.showNoticeFlag == "Y")
      {
        document.getElementById(j).style.display = "block";
      }
      else
      {
        document.getElementById(j).style.display = "none";
      }
    },

    clsoeSubMenu()
    {
      const subMenu = document.querySelector('.subMenu');
      subMenu.style.display = 'none';
    },

    openPage(pageName, elmnt, color) 
    {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tmenu");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].style.backgroundColor = "";
      }
      document.getElementById(pageName).style.display = "block";
      
      //element별 컨트롤
      if (elmnt) {
        elmnt.style.backgroundColor = color;
        document.querySelector(".close").style.display = "block"
      }
    },
    openPage_0(pageName, elmnt, color) 
    {
      let i 
      let tabcontent 
      let tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tmenu");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].style.backgroundColor = "";
      }
      document.getElementById(pageName).style.display = "none";

      if (elmnt) {
        elmnt.style.backgroundColor = color;
        document.querySelector(".close").style.display = "none"
      }
    },

    //선택가능한 버스투어 지역정보
    async getBustourArea() {
      let bustourArea = await this.$api("/api/getBustourArea", {})

      let ntToAr = {}

      if(bustourArea.length > 0)
      {
        this.bustourArea = bustourArea
        //국가별 지역으로 정리
        bustourArea.forEach(item => {
          if(!ntToAr[item.ntCd])
          {
            ntToAr[item.ntCd] = [];
          }
          ntToAr[item.ntCd].push({
            ntCd: item.ntCd,
            arCd: item.arCd,
            NT: item.NT,
            AR: item.AR
          })
        })
      }
      this.ntToAr = ntToAr
      //console.log("ntToAr:", this.ntToAr)
    },

    //선택가능한 티켓패스 지역정보
    async getTktPassArea() {
      let salesRoute = "E"
      let tktPassArea = await this.$api("/api/getTktPassArea", {param: [salesRoute]})

      let tkNtToAr = {}

      if(tktPassArea.length > 0)
      {
        this.tktPassArea = tktPassArea
        //국가별 지역으로 정리
        tktPassArea.forEach(item => {
          if(!tkNtToAr[item.ntCd])
          {
            tkNtToAr[item.ntCd] = [];
          }
          tkNtToAr[item.ntCd].push({
            ntCd: item.ntCd,
            arCd: item.arCd,
            NT: item.NT,
            AR: item.AR
          })
        })
      }
      this.tkNtToAr = tkNtToAr
      console.log("tkNtToAr:", this.tkNtToAr)
    },

    //선택가능한 선박
    async getTransportInfo() {
      //선박상품용 변수
      let ctCode = "SHP"
      let uFlag = "Y"
      let rtCode = "JP"

      let transportList = await this.$api("/api/transportList", {param: 
        [
          ctCode, uFlag, rtCode
        ]
      })

      let spToDt = {}

      if(transportList.length > 0)
      {
        //console.log("transportList:", transportList)
        this.transportList = transportList
        //운항지별로 정리
        transportList.forEach(item => {
          if(!spToDt[item.dtCode])
          {
            spToDt[item.dtCode] = [];
          }
          spToDt[item.dtCode].push({
            dtCd: item.dtCode,              //운항지코드
            trCd: item.transCode,           //선박코드
            DT: item.destName,              //운항지명
            TR: item.transName              //선박명
          })
        })
      }
      this.spToDt = spToDt
      //console.log("spToDt:", this.spToDt)

    },

    // goToBustour(ntCd, arCd) {
    //   this.$router.push({path: '/bustour', query: {nt: ntCd, ar:arCd}});
    // },
    goToBustour() {
      this.$router.push({path: '/bustour', query: {}});
    },

    goToLink(link) {
      window.open(link, '_blank')
    },

    noShow_rc(){
      this.recentFlag = (this.recentFlag === 'Y') ? 'N' : 'Y'
      //console.log("recentFlag:", this.recentFlag)
    },

    toNonMemberPage() 
    {
      location.href = "/nonMemberLogin";
    },    
    toB2BLoginPage() 
    {
      location.href = "/b2bLogin";
    },
    toB2CLoginPage() 
    {
      location.href = "/b2cLogin";
    },
    toMyPage() 
    {
      location.href = "/myPage";
    },
    //기업/단체문의
    toReqQuotation() 
    {
      location.href = "/requestQuotation";
    },    
    kakaoLogin() 
    {
      window.Kakao.Auth.login({
        scope: "profile, account_email, gender, birthday",
        success: this.getProfile,
      });
    },

    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },

    getProfile(authObj) {
      console.log(authObj+"12ddfdfER-dr32112365KK");
      window.Kakao.API.request({
        url: "/v2/user/me",
        success: (res) => {
          const kakao_account = res.kakao_account;
          //카카오로그인이 성공하면 자사서버에 정보입력
          this.login(kakao_account);
          // alert("LogIn OK!!");
          var swal = require("sweetalert2");
          swal.fire({
            text: "로그인 되었습니다.",
            icon: "success",
          });
        },
      });
    },
    async login(kakao_account) {
      await this.$api("/api/login", {
        // 라우터에서 sql호출시, 파라메타를 같이 넘긴다
        // 처음 파라메터: 기존여부체크용, 두번째 파라메터: 업데이트용
        param: [
          {
            uEmail: kakao_account.email,
            uNickName: kakao_account.profile.nickname,
          },
          { uNickName: kakao_account.profile.nickname },
        ],
      });

      // login으로 넘어온 유저정보를 store쪽으로
      this.$store.commit("user", kakao_account);
      location.href = "/"

      //this.$router.push({ path: "/" });
    },
//to-do: 일단 통합로그아웃(향후 분기하여 네이버 전용로그아웃 구성할 것-20220616)
toB2CLogout() {
      window.Kakao.Auth.logout((response) => {
        this.$store.commit("user", {}); // 로그아웃->카카오유저 정보를 빈값으로 대체

        window.sessionStorage.clear(); // 로그아웃->세션스토리지를 모두 삭제
        window.localStorage.clear();
        console.log(response);
      });
      this.$api("/api/logoutB2C", {});

      var swal = require("sweetalert2");
      swal.fire({
        text: "안전하게 로그아웃되었습니다.",
        icon: "success",
      });

      //로그아웃 후, 리로드를 시킨 후 메인으로 보낸다
      //location.href = "/"
      localStorage.setItem("reloadCheck", "true")
      this.$router.push({ path: "/" }); // 로그아웃->메인페이지로
    },
    b2bLogout() {
      // document.querySelector(".b2cTag").style.display="";
      // window.Kakao.Auth.logout((response => {
      this.$store.commit("user", {}); // store에 저장된 공통값을 빈값으로 치환
      this.$api("/api/logoutB2B", {});
      var swal = require("sweetalert2");
      swal.fire({
        text: "안전하게 로그아웃되었습니다.",
        icon: "success",
      });
      location.href = "/b2bLogin"
      //this.$router.push({ path: "/b2bLogin" }); // 로그아웃->메인페이지로
    },
  },
};
</script>

<style>
/* -------------------------------------------------
  # 헤더
--------------------------------------------------- */
.pcUser * {
  box-sizing: border-box;
}

#app .header {
  background-color:#fff;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 0 0px !important;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom:6rem;
  z-index:9999
}
@media (max-width:992px){
  #app .header{
    margin-bottom:0;
  }
}

/* 로고 */
#app .header .mlogo{
  display: block;
  padding-top:5px !important;
  padding-left:0 !important;
  margin-left:0 !important
}
#app .header .mlogo .logo{
  width:80px;
  height: auto;
}

/* 메뉴들 */
.nav-main, .nav-sub{
  float:right;
}
#app .header .mlogo,
#app .header .tablink {
  position:relative;
  background-color: rgba(0, 0, 0, 0.0);
  color: #000;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 14px 15px 0;
  margin:0 10px;
  line-height: 20px;
}
#app .header .tablink a{
  color:#000;
  transition:all .3s
}
#app .header .tablink2 {
  background-color: rgba(0, 0, 0, 0.0);
  color:#aaa;
  float: between;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 15.5px 8px;
  font-size: 13.5px;
  line-height: 20px;
  transition:all .2s
}

@media (max-width:1399px){
  #app .header .tablink{
    font-size: 16px;
    padding: 14px 10px 0;
    margin:0 5px;
  }
  #app .header .tablink2{
    font-size: 12px;
    padding: 15.5px 5px;
  }
}

@media (max-width:1199px){
  #app .header .tablink{
    font-size: 14px;
    padding: 12px 9px 0;
    margin:0 5px;
  }
  #app .header .tablink2{
    font-size: 10px;
    padding: 14.5px 4px;
  }
}

/* 좌에서 우로 밑줄 */
#app .header .tablink:hover a{
  color:#9fcd53
}

#app .tmenu::after {
  content: '';
  width: 0%;
  left: 0%;
  height: 22px;
  display: block;
  position: relative;
  border-bottom: 4px solid #a1c96d;
  transition:all 0.5s;
}
#app .tmenu:hover::after{
  content: '';
  width: 100%;
  left: 0%;
  height: 22px;
  border-bottom: 4px solid #a1c96d;
  transition:all 0.5s;
}

#app .header .tablink2:hover{
  color:cornflowerblue
}


/* ----------------
  서브메뉴
---------------- */
#app .subMenu{
  position:absolute;
  top:80px;
  left:0;
  width:100%;
  height: auto;
  background-color: #00000000;
  /* box-shadow: 0 4px 4px -4px #aaa; */
}

#Ndaytrip { background: linear-gradient(to bottom,white,rgba(255, 255, 255, 1.0),rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.0));}
#Ntabi {background-color: #00000000;}
#Ship { background: linear-gradient(to bottom,white,rgba(255, 255, 255, 1.0),rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.0));}
#VrPack { background: linear-gradient(to bottom,white,rgba(255, 255, 255, 1.0),rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.0));}
#TktPass { background: linear-gradient(to bottom,white,rgba(255, 255, 255, 1.0),rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.0));}
#Somen { background: linear-gradient(to bottom,white,rgba(255, 255, 255, 1.0),rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.0));}
#Quotation {background-color: #00000000;}
#MemShip {background-color: #00000000;}
#app .subMenu .close{
  position:absolute;
  top:10px;
  right:5%;
  border:none;
  background-color: transparent;
}
#app .subMenu .close .btn-close{
  display: block;
  width:2.5rem;
  height:2.5rem;
  padding:5px
}

#app .subMenu .tabcontent {
  color: #000;
  display: none;
  padding: 10px 20px 100px;
  height: 100%;
}
#app .subMenu table{
  border-color: transparent;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}

#app .subMenu .s-logo {
  width: auto;
  height:4.5rem
}

#app .subMenu .tk-logo {
  width: auto;
  height: 6.5em
}

#app .subMenu .s-logo-vr {
  width: auto;
  height:3.2rem
}

#app .subMenu .s-logo-somen {
  width: auto;
  height:7rem;
  padding-left: 50%;
}

#app .subMenu .s-logoinfo{
  color:#000;
  font-size:1rem;
  padding-top:1.5rem;
}

#app .subMenu .n-country{
  padding-top: 1rem;
}
#app .subMenu .n-country a{
  color:#000;
  line-height:2rem;
}
#app .subMenu .n-country a:hover{
  color:#9fcd53
}
#app .subMenu .n-country .big a{
  font-size: 1.2rem;
  font-weight: 600;
}
#app .subMenu .n-country .big a:hover{
  color:#9fcd53
}

/** 선박상품 */
#app .subMenu .n-ship{
  padding-top: 1rem;
  font-size: 1.1rem;
}
#app .subMenu .n-ship a{
  color:#000;
  line-height: 1rem;
}
#app .subMenu .n-ship a:hover{
  color:#9fcd53
}
#app .subMenu .n-ship .big a{
  font-size: 1.2rem;
  font-weight: 600;
}
#app .subMenu .n-ship .big a:hover{
  color:#9fcd53
}


/* -------------------------------------------------
  # 최근본상품
--------------------------------------------------- */
/* 큰 화면 (PC)을 위한 CSS */
#recent-item {
  display: block; /* PC에서 표시 */
}

#recent-item-m {
  display: none; /* PC에서 숨김 */
}

/* 작은 화면 (모바일)을 위한 CSS */
@media screen and (max-width: 768px) { /* 필요에 따라 max-width 조정 */
  #recent-item {
    display: none; /* 모바일에서 숨김 */
  }

  #recent-item-m {
    display: block; /* 모바일에서 표시 */
  }
}


#recent-item{
  z-index: 999;
  position:fixed;
  right: 120px;
  top: 10%;
  /* bottom: 10%; */
  border:1px solid grey;
  width:10rem;
  height:auto;
  text-align: center;
  /* background-color:rgba(255, 252, 252, 0.8);  */
  background-color:#fff; 
}
#recent-item .title{
  background-color: #eee;
  padding:10px 0;
  font-size: medium;
}
#recent-item .title span{
  color:#1100ff;
  font-weight: 500;
}
#recent-item .item{
  padding:0 5px
}
#recent-item .item .name{
  font-size: .8rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin:.5rem 0 .2rem
}
#recent-item img{
  width: 8rem;
  height:5.5rem;
  object-fit: cover;
}


/* -------------------------------------------------
  # 푸터 pc
--------------------------------------------------- */
#footer-pc{
  display: block;
  border-top: 1px solid #000;
  padding-top:4rem;
  margin-bottom:3rem
}

/* ---------------
  왼쪽
--------------- */
#footer-pc .ftext{
  font-size:1.7rem;
  font-weight: 600;
  color:#000
}

/* 전화번호 */
#footer-pc .customer{
  font-size:.9rem
}
#footer-pc .customer .telnumber{
  margin-bottom:0
}
#footer-pc .customer .telnumber a{
  font-size:2.5rem;
  font-weight: 600;
}
#footer-pc .customer .telnumber .bi{
  padding-right:15px
}

/* sns */
#footer-pc .foot-icons ul{
  margin-top:5rem;
  padding-left:0
}
#footer-pc .foot-icons ul li{
  list-style: none;
  display: inline-block; 
  padding:0 15px;
}
#footer-pc .foot-icons ul li:first-child{
  padding-left:0
}
#footer-pc .foot-icons .ficon{
  width:2rem;
  height:auto
}


/* ---------------
  오른쪽
--------------- */
/* 회사안내 */
#footer-pc .footer-cinfo{
  margin-top:5px
}
#footer-pc .footer-cinfo ul li{
  list-style: none;
  display: inline-block; 
  padding:0 15px;
  font-weight: 600;
}
#footer-pc .footer-cinfo ul li:last-child{
  padding-right:0
}
#footer-pc .footer-cinfo a{
  color:#555;
  transition: all .3s;
}
#footer-pc .footer-cinfo a:hover{
  color:#000
}

/* 회사정보 */
#footer-pc .cinfo{
  margin-top:1.5rem;
}
#footer-pc .cinfo ul{
  margin-bottom:.3rem
}
#footer-pc .cinfo ul li{
  list-style: none;
  display: inline-block; 
  padding:0 15px;
  font-size:.8rem;
}
#footer-pc .cinfo ul li:last-child,
#footer-pc .cinfo ul li:nth-child(3){
  padding-right:0
}

/* 지사 */
#footer-pc .bo ul li{
  list-style: none;
  display: inline-block; 
  padding:0 10px;
  font-size:.75rem;
}
#footer-pc .bo ul li:last-child,
#footer-pc .bo ul li:nth-child(2){
  padding-right:0;
}
#footer-pc .bo .copy{
  font-size:.7rem;
  opacity:.6;
  margin-bottom:3rem
}

/* 로고 */
#footer-pc .flogo{
  width:auto;
  height:3rem;
  /* 부관용로고삽입(기한아리) */
  padding-right: 2rem;
}
#footer-pc .fnday{
  /* 부관용로고삽입(기한아리 기한종료하면 주석헤제) */
  /* margin-left:0 */

  /* 부관용로고삽입(기한아리) */
  padding-right: 2rem;
}

/* 부관용로고삽입(기한아리) */
#footer-pc .fpuk{
  height:3rem;
  padding-right: 0%;
}



/* -------------------------------------------------
  # 푸터
--------------------------------------------------- */
#footer-m{
  display: none;
  border-top: 1px solid #000;
  padding-top:3rem;
  margin-bottom:1rem
}

/* sns */
#footer-m .foot-icons ul{
  margin-top:0;
  margin-bottom:2rem;
  padding-left:0
}
#footer-m .foot-icons ul li{
  list-style: none;
  display: inline-block; 
  padding:0 15px;
}
#footer-m .foot-icons ul li:first-child{
  padding-left:0
}
#footer-m .foot-icons .ficon{
  width:1.8rem;
  height:auto
}

/* 회사안내 */
#footer-m .footer-cinfo ul{
  padding-left:0
}
#footer-m .footer-cinfo ul li{
  list-style: none;
  display: inline-block; 
  padding:0 15px;
  font-weight: 600;
  line-height: 35px;
  font-size:.9rem
}
#footer-m .footer-cinfo ul li:last-child{
  padding-right:15px
}
#footer-m .footer-cinfo a{
  color:#555;
  transition: all .3s;
}
#footer-m .footer-cinfo a:hover{
  color:#000
}

/* 엔타비글자 */
#footer-m .ftext{
  font-size:1.5rem;
  font-weight: 600;
  color:#000;
  margin-top:2rem;
  margin-bottom:0.5rem
}

/* 전화번호 */
#footer-m .customer{
  font-size:.9rem
}
#footer-m .customer .telnumber{
  margin-bottom:0
}
#footer-m .customer .telnumber a{
  font-size:2rem;
  font-weight: 600;
}
#footer-m .customer .telnumber .bi{
  padding-right:15px
}

/* 회사정보 */
#footer-m .cinfo{
  margin-top:1.5rem;
}
#footer-m .cinfo ul{
  padding-left: 0;
}
#footer-m .cinfo ul li{
  list-style: none;
  display: inline-block; 
  padding:0 15px;
  font-size:.7rem;
}
#footer-m .cinfo ul li:last-child,
#footer-m .cinfo ul li:nth-child(3){
  padding-right:15px
}

/* 지사 */
#footer-m .bo ul{
  padding-left: 0;
}
#footer-m .bo ul li{
  list-style: none;
  display: inline-block; 
  padding:0 10px;
  font-size:.7rem;
}
#footer-m .bo ul li:last-child,
#footer-m .bo ul li:nth-child(2){
  padding-right:15px;
}
#footer-m .bo .copy{
  font-size:.7rem;
  opacity:.6;
  margin-bottom:3rem
}

/* 로고 */
#footer-m .flogo{
  width:auto;
  height:2rem;
  /* 부관용로고삽입(기한아리) */
  padding-right: 1rem;
}

/* 부관용로고삽입(기한아리 종료하면 주석해제) */
/* #footer-m .fnday{
  margin-left:2rem
} */

/* 부관용로고삽입(기한아리 종료하면 주석처리) */
#footer-m .fpuk{
  height:2rem;
  padding-right: 0%;
}





/* copyright */
#footer-m .copy{
  font-size:.7rem;
  color:#aaa;
  margin-top:2rem
}


@media (max-width:991px){
  #footer-pc{
    display:none
  }
  #footer-m{
    display:block
  }
}






/*--------------------------------------------------------------
# 진행중 이벤트
--------------------------------------------------------------*/
#notice .notice .event .carousel{
  width:100%;
  position: relative;
}

#notice .notice .event .evBox {
  position: relative;
  overflow: hidden;
}

/* #main .notice .event .card-img-top .evBox{
  width: 500px;
  height: 350px;
} */

#evImg {
  /*width: 500px;*/
  height: 350px;
  object-fit:contain;
}

#notice .notice .event .evtext{
  color:#fff;
  position:absolute;
  bottom:0%;
  left:50%;
  transform: translateX(-50%);
  width:90%;
  padding:10px 0;
}
#notice .notice .event .evtext .evname{
  font-size:smaller;
  font-weight: 600;
  padding-bottom:10px
}
#notice .notice .event .carousel-control-prev{
  left: 0%;
  background-color: #000;
  height: 13%;
  width: 6%;
  top: 40%;
}
#notice .notice .event .carousel-control-next{
  right: 0%;
  background-color: #000;
  height: 13%;
  width: 6%;
  top: 40%;
}

#notice .carousel-control-prev{
  left:-8%;
  opacity: 1;
}
#notice .carousel-control-next{
  right:-8%;
  opacity:1
}
#notice .back{
  width:3rem;
  height:3rem;
  background-color:#000;
  opacity: 0.2;
}

#notice .back:hover {
  opacity: 0.8;
}

#notice .back .carousel-control-prev-icon, 
#notice .back .carousel-control-next-icon{
  margin-top:10px
}

@media (max-width:991px){
  #notice .carousel-control-prev{
    left:0;
  }
  #notice .carousel-control-next{
    right:0;
  }
}


/*--------------------------------------------------------------
# 이벤트 공지 등
--------------------------------------------------------------*/
@media (max-width:991px){
  #notice .section-title{
    padding-left:0
  }
}

#notice{
  margin:8rem 0rem
}

#notice #more{
  float: right;
  padding:0 12px;
  margin: -35px 0 0 3px;
}
#notice #more a{
  font-size:.75rem;
  color:#444
}

#notice .section-title{
  margin-bottom:0;
  text-align: left;
  padding-left: 15px
}
#notice .section-title p{
  font-size:1.6rem;
  margin-bottom: .5rem;
}



/* 이벤트 */
#notice .event{
  padding-right:3rem
}
#notice .event img{
  border-radius: 30px;
}

@media (max-width:991px){
  #notice .event{
    padding-right:0;
  }
}


/* 공지사항 */
#notice .post-entry {
  text-align: left;
  padding: 0 15px;
  font-size: .9rem;
  margin-bottom: 10px;
}
#notice .post-entry .contents {
  width: 100%;
  padding: 5px 5px;
}
#notice .post-entry a h6 {
  font-size: 0.9rem;
  color: #777;
  transition: all 0.3s;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
#notice .post-entry .contents:hover a h6 {
  color: #000;
}
#notice .post-entry .date-read {
  font-size: .75rem;
  float: right;
  color: #aaa
}

@media (max-width:991px){
  #notice .post-entry {
    padding:0 
  }
  #notice .post-entry .date-read{
    font-size: .45rem;
  }
}


/* 공지사항내용 */

/**공지사항표시 초기세팅 */
#notice .nContent {
  display: none;
}

#notice .nContent {
  word-wrap:break-word;
  white-space:pre-wrap;
}

#notice .nContent .gopro{
  float: none; 
  margin:3rem auto 4rem
}
#notice .nContent .gopro a{
  color:#fff;
  background-color: #333;
  padding:15px 0;
  font-weight: 600;
  letter-spacing: 2px;
  transition: all .5s;
}
#notice .nContent .gopro a:hover{
  background-color: #000
}

/* 빠른메뉴 */
#notice .quick{
  margin-top:1.2rem
}
#notice .quick img{
  width:4.5rem;
  height:auto
}
#notice .quick p{
  color:#777;
  font-size:1.1rem;
  margin-top:5px;
  transition:all 0.5s
}
#notice .quick a:hover p{
  color:#000
}

@media (max-width:991px){
  #notice .quick img{
    width:3.5rem;
  }
  #notice .quick p{
    font-size: .8rem;
  }
}



/* -------------------------------------------------
  # Mobile 화면처리
--------------------------------------------------- */
@media (min-width:992px){
  #app .header .mobile{
    display: none;
  }
}
@media (max-width:991px){
  #app .header .pcUser{
    display: none;
  }
  #app .header .mobile{
    display: block;
  }
  #app .header .admin{
    margin-top:-30px
  }
}





/* -------------------------------------------------
  # 모바일 메뉴
--------------------------------------------------- */
#app .mobile-menu{
  display: block;
  background: #fff;
  color: #fff;
  text-align: center;
  transition: all .4s ease;
  cursor: pointer;
  width:100%;
  position: fixed;
  left:0;
  top: 0;
  border-bottom:1px solid rgba(0,0,0,0.2);
  padding-bottom:5px
}

#app .mobile-menu .m-recent{
  background-color:transparent
}



/* -----------------
  모바일 메뉴 내용들
  ----------------- */
/* 로그인 */
#app .mobile-menu .mmenu .toolbar-login a{
  display: inline-block;
  color: #000;
  font-weight: 500;
  font-size: .8rem;
  padding:0 8px;
}
#app .mobile-menu .mmenu .mbtn-close{
  padding-right:30px
}

/* 메뉴 */
#app .mobile-menu .mmenu .navbar-nav .nav-item {
  z-index: 1;
  position: relative;
  margin-left: 20px;
}
#app .mobile-menu .mmenu .navbar .nav-item.dropdown.show .nav-link,
#app .mobile-menu .mmenu .navbar .nav-item .nav-link:hover,
#app .mobile-menu .mmenu .navbar .nav-item .nav-link.active {
	color: #9fcd53;
}
#app .mobile-menu .mmenu .navbar-nav .nav-item a {
  color: #000;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 50px;
  
}
#app .mobile-menu .mmenu .navbar-nav .one a::after {
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px){
  #app .mobile-menu .mmenu .navbar-nav .nav-item a {
    display: flex;
    /* justify-content: space-between; */
    justify-content: left;
  }
}

/* 드롭다운 메뉴 */
#app .mobile-menu .mmenu .dropdown .dropdown-menu{
  position:sticky !important;
  transform: translateY(0px) !important;
  margin-top:-20px !important;
  border:none;
}

#app .mobile-menu .mmenu .dropdown .nav-item2 a{
  font-weight: 300;
  font-size:1rem;
}
#app .mobile-menu .mmenu .dropdown .nav-item2 .mssun-menu a{
  display: block;
  font-size:.9rem;
  line-height:40px
}



/* -----------------
  모바일 최근 본 상품
  ----------------- */
#app .mobile-menu .mrecent{
  color:#000
}
#app .mobile-menu .mrecent .offcanvas-title{
  font-weight: 600;
}
#app .mobile-menu .mrecent img{
  width:6rem;
  height:6rem;
  object-fit: cover;
}
#app .mobile-menu .mrecent .recent p{
  text-align: left;
  vertical-align: middle;
  font-size:.9rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom:0
}


@media (max-width: 991px){
  #app .mobile-menu .offcanvas {
    max-width: 100%;
  }
  #app .mobile-menu .off-recent{
    max-width: 100%;
    height:50%;
  }
}

/* pc 화면 안 보임 */
@media (min-width:992px){
  #app .mobile-menu{
    display: none;
  }
}

/**메인메뉴 사이드로고이미지(멤버십) */
/* .tablink .lvar{
  height:1.7rem;
  width:auto;
  margin-top:-5px
} */

/**메인메뉴 사이드로고이미지(멤버십 모바일) */
.lvar-m{
  height:2.5rem;
  width:auto;
  margin-top:0px;
  padding-left:0px;
  padding-right:0px;
}

/* -------------------------------------------------
  # 모바일 탑메뉴
--------------------------------------------------- */
#app .toTop_m{
  position:fixed;
  z-index: 999;
  bottom:5%;
  right:0%
}
@media (min-width:1670px){
  #app .toTop_m{
    bottom:5%;
    right:5%
  }
}

/*
.mbus-icon {
  transform: scale(0.5);
}
*/

/* 텍스트점멸 */
.blink {
    animation: blinker 1.5s linear infinite;
    /* color: darkgreen; */
}

@keyframes blinker {
50% 
  {
    opacity: 0;
  }
}

/* 텍스트 첨자 */
.text-container {
  position: relative;
  display: inline-block;
}
.text {
  display: inline-block;
}
.superscript {
  position: absolute;
  top: -1em; /* adjust this value to move the superscript up or down */
  left: 0;
  color:red;
  font-size: 0.7rem;
}
</style>
