<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>1:1 문의상세</u></h2>
      <div class="mb-3 mt-3 row">
        <label class="col-md-3 col-form-label">진행상태</label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" class="form-control" ref="txt_status" v-model="this.jDetail.status" disabled>
            <select class="form-select form-select-sm text-primary" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="this.rProcCode">
              <!-- <option value="" disabled>진행상태변경</option> -->
              <option value="" checked>진행상태유지</option>
              <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">문의유형 / 제목</label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" class="form-control text-danger" ref="txt_cName" v-model="this.jDetail.cat" disabled>
            <input type="text" class="form-control" ref="txt_reqTitle" v-model="this.jDetail.reqTitle" disabled>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">문의자 / 여행지 <font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" maxlength="20" class="form-control" ref="txt_reqPersonName" v-model="this.jDetail.reqPersonName" readonly>
            <input type="text" class="form-control" ref="txt_perCnt" v-model="this.jDetail.nation" readonly>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">연락처 / 이메일<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="tel" maxlength="11" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.jDetail.reqTel" readonly>
            <input type="email" maxlength="40" class="form-control" ref="txt_cEmail" v-model="this.jDetail.reqEmail" readonly>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">문의내용</label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" maxlength="300"  placeholder="250자 이내" ref="txt_nContent" id="floatingTextarea" style="height:150px" v-model="this.jDetail.reqSpRemarks" disabled></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">담당자 비고사항
          <p><small>내부용(외부표시안됨)</small></p>
        </label>
        <div class="col-md-9">
            <div class="row">
            <textarea class="form-control" ref="txt_nContent" id="floatingTextarea" style="height:150px; font-size:small" v-model="this.jDetail.respoComment" disabled></textarea>
            </div>
            <div class="row">
            <textarea class="form-control" maxlength="500"  placeholder="500자 이내" ref="txt_respoComment_new" id="floatingTextarea" style="height:150px; font-size:small" v-model="respoComment_new"></textarea>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-success" @click="requestUpdate();">수정</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">취소</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>

      <hr class = "text-danger">

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">
          직전답변
          <p class="text-secondary">답변자 ({{this.requestAnswer.handlerNameKor}})</p>
        </label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" maxlength="999" ref="txt_ansContent" id="floatingTextarea" style="height:100px" v-model="this.requestAnswer.ansContent" readonly></textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">
          신규답변
          <p>
            <button type="button" class="btn btn-primary btn-sm me-1 align-text-middle" @click="sendAnswer();">
              <font style="font-size:small" class="align-bottom"><small>답변처리</small></font>
            </button>
          </p>
          <p class="text-danger">
            ※ 상기내용의 '수정'과 별개로 진행됩니다
          </p>
        </label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" maxlength="999"  placeholder="1,000자 이내" ref="txt_ansContent" id="floatingTextarea" style="height:150px" v-model="this.ansContent"></textarea>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
export default {
  data() {
    return {
      jDetail: [],
      rProc: [],
      rProcCode: "",
      respoComment_new: "", //담당자 코멘트
      requestAnswer: [], //답변내용
      ansContent: "", //신규답변
      answerFlag: "N" //답변글 플래그
    };
  },
  created() {
    this.askCode = this.$route.query.askCode;
    this.getRequestDetail();
    this.getReqProcLevel();
    this.getReqAnswer(); //답변데이타
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async getRequestDetail() {
      let reqJoinDetail = await this.$api("/api/customerAskDetail ", {param: [this.askCode]});
      this.jDetail = reqJoinDetail[0];
      console.log("jDetail:",this.jDetail)
      //this.jDetail.passExpire = (this.jDetail.passExpire == null) ? null : this.getDateFormat(this.jDetail.passExpire);
    },
    //답변정보
    async getReqAnswer() {
      let requestAnswer = await this.$api("/api/customerAskAnswer", {param: [this.askCode]});
      if(requestAnswer.length > 0) {
        this.requestAnswer = requestAnswer[0];
        this.answerFlag = "Y";
      }
      console.log("requestAnswer:",this.requestAnswer);
    },
    async getReqProcLevel() {
      this.rProc = await this.$api("/api/reqProcLevel", {});
      console.log("rProc:",this.rProc);
    },
    noTag() {
      this.openTag = "N";
    },
    goToPage() {
      this.$router.push({path: '/detail', query: {productId: this.jDetail.pdtCode}});
    },
    //1:1 문의자에 답글달기
    async sendAnswer()
    {
      let ins_requestAnswer = await this.$api("/api/ins_customerAsk_Answer", {param: 
        [
          this.jDetail.seqReq,this.askCode,this.ansContent, this.user.handlerId
        ]});
      //답글여부처리
      await this.$api("/api/upd_customerAsk_AnsFlag", {param: [
        this.user.handlerId,this.askCode
      ]});
        //console.log("ins_requestAnswer:", ins_requestAnswer);
        if(Object.keys(ins_requestAnswer)[0] === 'error')
        {
          this.$swal.fire('','답글등록(ins_requestAnswer)중 오류가 발생했습니다.<br>관리자에게 문의해주세요', 'warning')
        }
        else
        {
          this.$swal.fire('','답글등록이 완료되었습니다.', 'success')
          location.reload();
          
          //카카오 알림톡 전송
        }
    },
    async requestUpdate() {
      // if(this.jDetail.passNo != "" || this.jDetail.passExpire != "") {
      //   this.jDetail.passFlag = "Y";
      // }
      if(this.rProcCode == "") 
      {
        this.rProcCode = this.jDetail.reqStatus;
      }

      if(!this.respoComment_new) 
      {
        this.respoComment_new = this.jDetail.respoComment;
      }
      else
      {
        this.respoComment_new = this.jDetail.respoComment + '\n' + "["+this.jDetail.respodent +"("+ this.jDetail.respoComDate +")" +"] " + this.respoComment_new;
      }

      let resUpd_Request = await this.$api("/api/customerAskUpdate", 
        {param: [this.rProcCode,this.user.handlerId,this.respoComment_new,this.askCode]});
      console.log("resUpd_Request:",resUpd_Request);
      if(resUpd_Request.affectedRows > 0) 
      {
        this.$swal.fire('','수정완료', 'success');
        location.reload();
      }
      else 
      {
        this.$swal.fire('','수정 중 오류가 발생했습니다.', 'error')
        return false;
      }
      //console.log("resUpd_Request:",this.resUpd_Request);
    },
    goToList() {
      this.$router.push({path: '/customerAskList'});
    }
  }
}
</script>