<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container-fluid">
        <div class="alert alert-primary" role="alert">
          <b class="fs-4">거 래 처 리 스 트</b>
        </div>
        <div class="text-end">
          <button type="button" class="btn btn-primary" @click="showRegistPartner()">신규등록</button>
        </div>

        <div class="text-success text-start fs-6">
          <b>[ &nbsp; 거래처 리스트 &nbsp; ]</b>
        </div>        
        <table class="table table-bordered table-striped fs-6 text-center" v-if="this.createFlag == 'Y'">
          <thead>
            <tr>
              <th style="width:5%;">코드</th>
              <th style="width:10%;">상호명</th>
              <th style="width:5%;">카테고리</th>
              <th style="width:10%;">연락처</th>
              <th style="width:5%;">국적</th>
              <th style="width:10%;">사업자번호</th>
              <th style="width:7%;">은헁</th>
              <th style="width:10%;">계좌/예금주</th>
              <th style="width:10%;">담당자</th>
              <th style="width:10%;">담당연락처</th>
              <th style="width:5%;">내부용</th>
              <th style="width:10%;">비고사항</th>
              <th style="width:10%;">등록</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr>
              <td>
                <input type="text"  class="text-success" v-model="this.pCode" size="5" style="font-size: smaller" maxlength="2" ref="pCode" placeholder="영문2자">
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.pName" size="17" style="font-size: smaller" maxlength="40" ref="pName" placeholder="최대40자">
              </td>
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="pCategory" v-model="this.pCategory">
                  <option :value="c.baseCode" :key="i" v-for="(c,i) in this.categoryInfo"> {{ c.codeNameKor }}</option>
                </select>
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.pTel" size="11" style="font-size: smaller" maxlength="15" ref="pTel" placeholder="숫자만">
              </td>
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="pCountry" v-model="this.pCountry">
                  <option :value="n.baseCode" :key="i" v-for="(n,i) in this.countryInfo">{{ n.codeNameKor }}</option>
                </select>
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.pLicenseNo" size="10" maxlength="15" style="font-size: smaller" ref="pLicenseNo" placeholder="숫자만">
              </td>

              <!-- 은행계좌정보 -->
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="bankCode" v-model="this.bankCode">
                  <option :value="b.fCodeNo" :key="i" v-for="(b,i) in this.bankInfo"> {{ b.fName }}</option>
                </select>
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.bAccount" size="7" maxlength="30" style="font-size: smaller" ref="bAccount" placeholder="계좌번호">
                <p class="mt-1 mb-0">
                  <input type="text"  class="text-success" v-model="this.accHolder" size="7" maxlength="50" style="font-size: smaller; background-color: blanchedalmond;" ref="accHolder" placeholder="예금주">                  
                </p>
              </td>

              <td>
                <input type="text"  class="text-success" v-model="this.pCounter" size="7" maxlength="15" style="font-size: smaller" ref="pCounter">
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.pCounterTel" size="11" style="font-size: smaller" maxlength="15" ref="pCounterTel" placeholder="숫자만">
              </td>
              <td>
                <input class="form-check-input my-auto" type="checkbox" v-model="inCdFlag" true-value="Y" false-value="N" id="chb_addItemFlag">
              </td>              
              <td>
                <textarea class="form-control" placeholder="100자 이내" ref="spRemarks" id="floatingTextarea" style="height:15px; font-size: small; width:100%;" v-model="this.pRemarks" maxlength="100"></textarea>
              </td>
              <td class="text-center">
                <!-- me-1: rigt margin1 -->
                <button type="button" class="btn btn-outline-primary btn-sm" @click="registMember()">OK</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-2 mb-2 align-middle fs-4">
          <i class="bi bi-arrow-down-square-fill text-secondary"  v-if="this.createFlag == 'Y'"></i>
        </div>
        
        <table class="table table-bordered table-striped fs-6 align-item-center">
          <thead class="small text-center">
            <tr>
              <th style="width:40px;">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th>
              <th style="width:70px;">코드</th>
              <th style="width:140px;">상호명</th>
              <th style="width:70px;">거래중</th>
              <th style="width:100px;">카테고리</th>
              <th style="width:90px;">연락처</th>
              <th style="width:90px;">국적</th>
              <th style="width:100px;">사업자번호</th>

              <th style="width:100px;">은헁</th>
              <th style="width:100px;">계좌/예금주</th>              
              
              <th style="width:100px;">담당자</th>
              <th style="width:110px;">담당연락처</th>
              <th style="width:30px;">내부용</th>
              <th style="width:150px;">비고사항</th>
              <th style="width:90px;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(m, i) in partnerInfo">
              <td>
                <input type="checkbox" v-model="m.checkFlag"  ref="chb_Seq" class="align-baseline mt-3" :id=i name="checkedBox">
              </td>
              <td class="form-control-sm text-success">
                <b>{{ m.pCode }}</b>
              </td>
              <td class="form-control-sm">
                <input type="text" v-model="m.pName" size="10" style="font-size: smaller">
              </td>
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_pUseFlag" v-model="m.pUseFlag">
                  <option value="Y" class="text-success">Y</option>
                  <option value="N" class="text-danger">N</option>
                </select>
              </td>
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="pCategory" v-model="m.pCategory">
                  <option :value="c.baseCode" :key="i" v-for="(c,i) in this.categoryInfo">{{ c.codeNameKor }}</option>
                </select>
              </td>
              <td style="font-size: smaller;">
                <input type="text" v-model="m.pTel" size="8" style="font-size: smaller">
              </td>
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="pCategory" v-model="m.pCountry">
                  <option :value="n.baseCode" :key="k" v-for="(n,k) in this.countryInfo">{{ n.codeNameKor }}</option>
                </select>
              </td>
              
              <td><input type="text" v-model="m.pLicenseNo" size="8" style="font-size: smaller"></td>

              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="pCategory" v-model="m.bankCode">
                  <option :value="c.fCodeNo" :key="i" v-for="(c,i) in this.bankInfo">{{ c.fName }}</option>
                </select>
              </td>
              <td>
                <input type="text" v-model="m.bankAccount" size="8" style="font-size: smaller">
                <p class="mt-1 mb-0">
                  <input type="text" v-model="m.accHolder" size="8" style="font-size: smaller; background-color: blanchedalmond;">
                </p>
              </td>

              <td><input type="text" v-model="m.pCounter" size="8" style="font-size: smaller"></td>
              <td><input type="text" v-model="m.pCounterTel" size="8" style="font-size: smaller"></td>

              <td>
                <input class="form-check-input my-auto" type="checkbox" v-model="m.inCdFlag" true-value="Y" false-value="N" id="chb_addItemFlag">
              </td>
              <td style="width:150px;">
                <textarea class="form-control-sm" ref="pRemarks" id="floatingTextarea" style="height:10px; font-size: smaller;" v-model="m.pRemarks" maxlength="100"></textarea>
              </td>
              
              <td style="width:80px;" class="text-center">
                <button type="button" class="btn btn-outline-primary btn-sm" style="font-size: small;" @click="changeStatus(m.pCode ,m.pName,m.pUseFlag,m.pCategory,m.pTel,m.pCountry,m.pLicenseNo,m.pCounter,m.pCounterTel,m.pRemarks,m.bankCode,m.bankAccount,m.accHolder,m.inCdFlag)">변경</button>
                <button type="button" class="btn btn-outline-danger btn-sm" style="font-size: small;" @click="memWithdrawal(m.pCode)">중지</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      partnerInfo: [],    //거래처정보
      allSelected: "N",   //전체체크여부
      
      //거래처정보
      pCode: "",          //거래처코드
      pCategory: "",      //거래처카테고리
      pName: "",
      pTel: "",
      pCounter: "",      //거래처담당자
      pLicenseNo: "",    //사업자등록번호
      pCountry: "",      //소재국
      pUseFlag: "",      //거래유무
      insId: "",
      insDate: "",
      modId: "",
      modDate: "",
      pRemarks: "",
      pCounterTel: "",   //담당자연락처

      categoryInfo: [],  //거래처카테고리
      countryInfo: [],   //국가리스트

      //거래처등록란 표시플래그
      createFlag: "N",

      bankInfo: [],     //은행정보
      bankCode: "",     //거래처 은행코드
      bAccount: "",     //거래처 은행계좌
      accHolder: "",    //예금주

      inCdFlag: ""      //내부용플래그
    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;
    this.getPartnerList();
    this.getCategoryList();

  }, 
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    //memberInfo: 회원정보리스트용 sql
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    showRegistPartner() {
      this.createFlag = (this.createFlag === "Y") ? "N" : "Y";

      this.pCode = ""
      this.pCategory = ""
      this.pName = ""
      this.pTel = ""
      this.pCounter = ""
      this.pLicenseNo = ""
      this.pCountry = ""
      this.pRemarks = ""
      this.pCounterTel = ""
    },
    
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },    
    //카테고리정보
    async getCategoryList() 
    {
      //업체카테고리
      let categoryInfo = await this.$api("/api/searchBasecode", {param: ['pnCd']});
      //국가리스트
      let countryInfo = await this.$api("/api/searchBasecode", {param: ['ntCd']});
      //은행정보
      let bankInfo = await this.$api("/api/finBankingInfo", {param: ['Inst', 'Bank', 'Y']});

      if(categoryInfo.length > 0)
      {
        this.categoryInfo = categoryInfo
      }
      if(countryInfo.length > 0)
      {
        this.countryInfo = countryInfo
      }
      if(bankInfo.length > 0)
      {
        this.bankInfo = bankInfo
      }
      console.log("bankInfo:", this.bankInfo);
    },
    //거래처등록
    async registMember() {
      //console.log("pCat:", this.pCategory)
      if(!this.pCode) 
      {
        this.$refs.pCode.focus();
        return this.$swal.fire("","거래처의 코드를 입력하세요.<br>코드는 중복되지 않아야 합니다.","warning");
      }
      if(!this.pName) 
      {
        this.$refs.pName.focus();
        return this.$swal.fire("","업체명을 입력하세요.","warning");
      }
      // if(!this.pCategory) 
      // {
      //   this.$refs.pCategory.focus();
      //   return this.$swal.fire("","업체카테고리를 선택하세요.","warning");
      // }
      // if(!this.pTel) 
      // {
      //   this.$refs.pTel.focus();
      //   return this.$swal.fire("","업체연락처를 입력하세요.","warning");
      // }
      // if(!this.pCountry) 
      // {
      //   this.$refs.pCountry.focus();
      //   return this.$swal.fire("","업체소재국을 선택하세요.","warning");
      // }
      // if(!this.pLicenseNo) 
      // {
      //   this.$refs.pLicenseNo.focus();
      //   return this.$swal.fire("","사업자번호를 입력하세요","warning");
      // }
      // if(!this.pCounter) 
      // {
      //   this.$refs.pCounter.focus();
      //   return this.$swal.fire("","담당자 성명을입력하세요.","warning");
      // }
      // if(!this.pCounterTel) 
      // {
      //   this.$refs.pCounterTel.focus();
      //   return this.$swal.fire("","담당자 연락처를 입력하세요.","warning");
      // }      
      
      let insId = this.user.handlerId;
      let pUseFlag = "Y"

      //임시비번 생성
      // let characters = '0123456789';
      // let length = 5;
      // let randomString = ''
      // for (var i = 0; i < length; i++) {
      //   var randomIndex = Math.floor(Math.random() * characters.length);
      //   randomString += characters[randomIndex];
      // }

      // let pass = randomString
      // console.log("pass:", pass)

      let ins_partnerInfo = await this.$api("/api/ins_partnerInfo", {param: 
      [
        this.pCode, this.pCategory, this.pName, this.pTel, this.pCounter, this.pLicenseNo, this.pCountry, pUseFlag, insId, this.pRemarks, this.pCounterTel, this.bankCode, this.bAccount, this.accHolder, this.inCdFlag
      ]});
      console.log("ins_partnerInfo:",ins_partnerInfo)
      if(ins_partnerInfo.affectedRows < 0) {
        this.$swal.fire('','등록실패('+ ins_partnerInfo.error.sqlMessage +')', 'error')
      }
      else {
        this.$swal.fire('','거래처 정보를 생성했습니다', 'success');
        location.reload();
      }
    },    
    allChoice() 
    {
      if(this.allSelected)
      {
        this.memberInfo.forEach((uNickName) => {
          uNickName.checkFlag = true;
        })
      }
      else
      {
        this.memberInfo.forEach((uNickName) => {
          uNickName.checkFlag = false;
        })
      }
    },

    //거래처리스트
    async getPartnerList() {
      let partnerInfo = await this.$api("/api/partnerInfo", {});

      if(partnerInfo.length > 0)
      {
        this.partnerInfo = partnerInfo
      }
      console.log("partnerInfo:",this.partnerInfo);
    },

    //거래처정보수정
    changeStatus(pCode,pName,pUseFlag,pCategory,pTel,pCountry,pLicenseNo,pCounter,pCounterTel,pRemarks,bCode,bAccount,accHolder,inCdFlag) 
    {
      //console.log(pCode,pName,pUseFlag,pCategory,pTel,pCountry,pLicenseNo,pCounter,pCounterTel,pRemarks);
      //let modId = this.user.handlerId;
      //변경전 확인
      this.$swal.fire({
        title: '거래처정보를 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let upd_partnerInfo = await this.$api("/api/upd_partnerInfo", {param: [
            pName,pUseFlag,pCategory,pTel,pCountry,pLicenseNo,pCounter,pCounterTel,pRemarks,this.user.handlerId,bCode,bAccount,accHolder,inCdFlag,
            pCode
          ]});
          console.log("upd_partnerInfo:",upd_partnerInfo)
          // 상태변경 후, 갱신
          if(upd_partnerInfo.affectedRows > 0)
          {
            this.$swal.fire('', '변경완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:upd_partnerInfo)', 'warning')
            return false;
          }
        }
      })
    },
    //거래중지
    memWithdrawal(pCode) 
    {
      //console.log(uBirthday, uNickName, alimCallC, uPhone, uGender, uPass, uEmail);
      //변경전 확인
      this.$swal.fire({
        html: '거래처관계를 종료합니까?',
        showCancelButton: true,
        confirmButtonText: '종료',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let del_partnerInfo = await this.$api("/api/del_partnerInfo", {param: [this.user.handlerId, pCode]});
          // 상태변경 후, 갱신
          if(del_partnerInfo.affectedRows > 0)
          {
            this.$swal.fire('', '처리완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:del_partnerInfo)', 'warning')
            return false;
          }
        }
      })
    },
  }
}
</script>