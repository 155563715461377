<template>
  <div id="taibei">
    <!-- 메인사진 -->
    <section class="taiwanmain">
      <div class="container">
        <img src="../assets/img/taiwan/title2.png" class="img-fluid">
      </div>
    </section>
<!--object-->
<div class="tab_fade1">
  <img id="show_click" src="../assets/img/taiwan/left.png" alt="">
</div>
<div class="tab_fade2">
  <img id="show_click" src="../assets/img/taiwan/right.png" alt="">
</div>
<div class="cloud1">
        <img src="../assets/img/taiwan/cloud1.png" alt="">
      </div>
      <div class="cloud2">
        <img src="../assets/img/taiwan/cloud2.png" alt="">
      </div>
      <div class="cloud3">
        <img src="../assets/img/taiwan/cloud3.png" alt="">
      </div>
      <div class="cloud4">
        <img src="../assets/img/taiwan/cloud4.png" alt="">
      </div>
      <div class="cloud5">
        <img src="../assets/img/taiwan/cloud5.png" alt="">
      </div>
      <div class="airplane">
        <img src="../assets/img/taiwan/airplane.png" alt="">
      </div>
      <div class="airplane2">
        <img src="../assets/img/taiwan/airplane2.png" alt="">
      </div>
      <!--object-->

    <!--bg carousel-->
    <div class="carousel_main">
      <div class="carousel_wrapper">
        <div class="carousel_slide">
          <img src="../assets/img/taiwan/back2.jpg" class="" alt="javascript:void(0)">
        </div>
        <div class="carousel_slide">
          <img src="../assets/img/taiwan/back3.jpg" class="" alt="javascript:void(0)">
        </div>
        <div class="carousel_slide">
          <img src="../assets/img/taiwan/back4.jpg" class="" alt="javascript:void(0)">
        </div>
        <div class="carousel_slide">
          <img src="../assets/img/taiwan/back5.jpg" class="" alt="javascript:void(0)">
        </div>
        <div class="carousel_slide">
          <img src="../assets/img/taiwan/back6.jpg" class="" alt="javascript:void(0)">
        </div>
        <div class="carousel_slide">
          <img src="../assets/img/taiwan/back7.jpg" class="" alt="javascript:void(0)"> 
        </div>
        <div class="carousel_slide">
          <img src="../assets/img/taiwan/back.jpg" class="" alt="javascript:void(0)">
        </div>
      </div>
      <!--bg carousel-->

      <!--tab-->
      <div class="tab_line">
        <div class="tab_basic">
          <div class="tab_list_wrap">
            <div class="tab_list tab_active" data-tabnum="0">
              <a class="carousel_circle bd" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-if="chkSize >800">
                자오시
                <br>카발란 코스
              </a>
              <a class="carousel_circle bd" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>
                자오시
                <br>카발란 코스
              </a>
            </div>

            <div class="tab_list" data-tabnum="1">
              <a class="carousel_circle bd" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-if="chkSize >800" >잉거
                <br>우라이 코스</a>
                <a class="carousel_circle bd" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>잉거
                <br>우라이 코스</a>
            </div>

            <div class="tab_list" data-tabnum="2">
              <a  class="carousel_circle bd last" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-if="chkSize >800" >먀오커우
                <br>야시장(예스지)
              </a>
              <a  class="carousel_circle bd last" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>먀오커우
                <br>야시장(예스지)
              </a>
            </div>

            <div class="tab_list" data-tabnum="3">
              <a class="carousel_circle bd top" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-if="chkSize >800">
                타이중 투어
                <br>고미습지 코스
              </a>
              <a class="carousel_circle bd top" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>
                타이중 투어
                <br>고미습지 코스
              </a>
            </div>

            <div class="tab_list" data-tabnum="4">
              <a class="carousel_circle bd top" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-if="chkSize >800">
                타이중 투어
                <br>일월담 코스
              </a>
              <a class="carousel_circle bd top" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>
                타이중 투어
                <br>일월담 코스
              </a>
            </div>

            <div class="tab_list" data-tabnum="5">
              <a class="carousel_circle last top" href="javascript:void(0)" style="font-family: 'TAEBAEKfont'; line-height:1.2em"  v-if="chkSize >800">
                타이중 투어
                <br>1박 2일 코스
              </a>
              <a class="carousel_circle last top" style="font-family: 'TAEBAEKfont'; line-height:1.2em" v-else>
                타이중 투어
                <br>1박 2일 코스
              </a>
            </div>

          </div>
        </div>
        <div id="tab_container">
          <div class="tab_content">
            <img src="../assets/img/taiwan/kavalan.jpg" alt="">
          </div>
          <div class="tab_content">
            <img src="../assets/img/taiwan/inger.jpg" alt="">
          </div>
          <div class="tab_content">
            <img src="../assets/img/taiwan/yeszi.jpg" alt="">
          </div>
          <div class="tab_content">
            <img src="../assets/img/taiwan/taichung1.jpg" alt="">
          </div>
          <div class="tab_content">
            <img src="../assets/img/taiwan/taichung2.jpg" alt="">
          </div>
          <div class="tab_content">
            <img src="../assets/img/taiwan/taichungall.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--tab-->


    <!-- 예약 -->
    <div id="reser0" class="container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-2024125-1706174265647','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
    <div id="reser1" class="container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-2024126-1706238253044','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
    <div id="reser2" class="container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-2023223-1677113592080','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
    <div id="reser3" class="container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-2432112QTC','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
    <div id="reser4" class="container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-2432111PSC','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
    <div id="reser5" class="container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('L-2432158PZC','L')">
          <p class="go">예약하기</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import defaultPath from '@/assets/img/taiwan/back.jpg';

export default {
  // DB에서 가져온 데이타
  data() {
    return {
      //imagePath: "/IMGs/testtaiwan/back.jpg"   //상단이미지 default
      imagePath: defaultPath,   //상단이미지 default
      animateImage: false
    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
    this.chkSize = window.innerWidth
    //alert(this.chkSize) 화면 사이즈 체크
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
    
  },
  methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  },
  mounted() {

    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요', 
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'}); 
    // }



  
    // tab---------------------------------------------------------------------------
    var tabList = document.querySelectorAll('.tab_list_wrap .tab_list')

    Array.prototype.forEach.call(tabList, function (list) {
      list.children[0].addEventListener('click', function () {

        var tabContent = document.querySelectorAll('#tab_container .tab_content')
        var tabNum = this.parentElement.getAttribute('data-tabnum')

        Array.prototype.forEach.call(tabContent, function (cont, i) {
          cont.style.display = 'none'
          tabList[i].className = 'tab_list'
        })
        tabContent[tabNum].style.display = 'block'

        if (list.className.indexOf('tab_active') == -1) {
          list.className = 'tab_list tab_active'
        }
      })
    })
    
    //tab----------------------------------------------------------------------------

    //document.querySelector('[data-tabnum="0"]').addEventListener('click', function() {
    //document.querySelector('#reser0').style.display = 'block';
//});
      




    //carousel-----------------------------------------------------------
    const swiper = document.querySelector('.carousel_wrapper');
    const bullets = document.querySelectorAll('.carousel_circle');

    let currentSlide = 2;

    function showSlide(slideIndex) {
      swiper.style.transform = `translateX(-${slideIndex * 100}vw)`;
      currentSlide = slideIndex;

      bullets.forEach((bullet, index) => {
        if (index === currentSlide) {
          bullet.classList.add('active');
        } else {
          bullet.classList.remove('active');
        }
      });
    }
    bullets.forEach((bullet, index) => {
      bullet.addEventListener('click', () => {
        showSlide(index);
      });
    });

    showSlide(6);
    //carousel---------------------------------------------------------------
    window.addEventListener('scroll', function() {
    var tabBasic = document.querySelector('.tab_basic');
    var scrollPosition = window.scrollY || window.pageYOffset;
    var windowWidth = window.innerWidth;

    

    if (scrollPosition > 1300 && windowWidth >= 1921 && windowWidth <= 2560) {
    tabBasic.style.position = 'fixed';
    tabBasic.style.top = '0';
      } else if (scrollPosition > 940 && windowWidth >= 800 && windowWidth <= 1920) {
    tabBasic.style.position = 'fixed';
    tabBasic.style.top = '0';
      } else {
    tabBasic.style.position = 'static';
      }
});






for (let i = 1; i <= 5; i++) {
    document.querySelector('[data-tabnum="' + i + '"]').addEventListener('click', function() {
        // 새로 클릭한 요소만 block으로 변경
        for (let j = 0; j <= 5; j++) {
            if (j !== i) {
                document.querySelector('#reser' + j).style.display = 'none';
            }
        }
        document.querySelector('#reser' + i).style.display = 'block';
    });
    }

  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@300;400;500;700&display=swap');

@font-face {
    font-family: 'TAEBAEKfont';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2310@1.0/TAEBAEKfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'KCCChassam';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/KCCChassam.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hahmlet-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2110@1.0/Hahmlet-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KCC-Ganpan';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/KCC-Ganpan.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KCC-Jeongbeom';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202@1.0/KCC-Jeongbeom.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.firstimg{
  position: relative;
  top: 0;
  left: 0;
  z-index: 8;
}
/* carousel */
.carousel_main {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  user-select: none;
}

.carousel_wrapper {
  display: flex;
  transition: transform 1s;
}

.carousel_slide {
  flex: 0 0 100%;
  /* 이미지 크기 설정 */
  position: relative;
}
.carousel_slide>img{
  width: 100vw;
  height: 100vh;
}
/*fade_start*/
@keyframes fadeLeft {
  0% {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeRight {
  0% {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeUpRight {
      0% { right: 27%; top: 27%;}
      50% { right: 29%; top: 30%; }
      100% { right: 27%; top: 27%; }
}


@keyframes smokefade {
  0% {
    opacity: 0;
    transform: rotate3d(-70%, 100%, 0);
  }
}

@keyframes leftright {
  0% { left: 10%; top: 200%;}
  50%{left:15%; top:200%;}
  100% { left: 10%; top: 200%; }
}

@keyframes right1 {
  0%{right:10%; top:300%;}
  50%{right:12%; top:300%;}
  100%{right:10%; top:300%;}
}

@keyframes right2 {
  0%{right:10%; top:500%;}
  50%{right:12%; top:500%;}
  100%{right:10%; top:500%;}
}

@keyframes left1 {
  0%{left:10%; top:400%;}
  50%{left:12%; top:400%;}
  100%{left:10%; top:400%;}
}

.tab_fade1{
  position: absolute;
  top: 124%;
  left: 0;
  z-index: 20;
  display: none;
}

.tab_fade2{
  position: absolute;
  top: 124%;
  right: 83%;
  z-index: 21;
  display: none;
}
.tab_fade1 img{
  width: 100px;
}

.tab_fade2 img{
  width: 80px;
}
.cloud1 {
  animation: fadeLeft 2s;
  position: absolute;
  top: 35%;
  left: 20%;
  z-index: 5;
}

.cloud1 img {
  width: 20vw;
}

.cloud2 {
  animation: fadeRight 2s;
  position: absolute;
  top: 50%;
  right: 22.9%;
  z-index: 12;
}

.cloud2 img {
  width: 20vw;
}

.cloud3 {
  animation: right1 3s infinite ease-in-out;
  position: absolute;
  top: 300%;
  right: 10%;
  z-index: 12;
}

.cloud3 img {
  width: 20vw;
}

.cloud4 {
  animation: left1 3s infinite ease-in-out;
  position: absolute;
  top: 400%;
  right: 10%;
  z-index: 12;
}

.cloud4 img {
  width: 20vw;
}
.cloud5 {
  animation: right2 3s infinite ease-in-out;
  position: absolute;
  top: 400%;
  right: 10%;
  z-index: 12;
}

.cloud5 img {
  width: 20vw;
}

.airplane {
  animation: fadeUpRight 3s infinite ease-in-out;
  position: absolute;
  top: 47%;
  right: 26%;
  z-index: 5;
}

.airplane img {
  width: 13vw;
}

.airplane2 {
  animation: leftright 3s infinite ease-in-out;
  position: absolute;
  bottom: -130%;
  left: 10%;
  z-index: 11;
}

.airplane2 img {
  width: 20vw;
}

/*fade_end*/

/*tabmenu*/
.tab_line {
  width: 100%;
  position: relative;
}

.tab_basic {
  margin: 0 auto;
  width: 100%;
  height: 10vh;
  background-color: rgb(255, 183, 0);
  position: sticky;
  top: 0;
  z-index: 100;
}

.tab_list_wrap {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
}

.tab_list {
  display: inline-block;
  width: 16.6666%;
  height: 100%;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
}


.tab_active {
  background-color: rgb(184, 132, 0);
}
.tab_active a{
  color: rgb(245, 188, 42);
}

.tab_list:hover {
  border-bottom: 8px solid rgb(184, 132, 0);
}

.tab_list a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #ffffff;
}
.bd{
  border-right: 1px solid #fff;
}



.tab_content {
  display: none;
  width: 800px;
  margin: 0 auto;
  min-height: 100px;
  background: #eee;
  box-sizing: border-box;
}

.tab_content:first-child {
  display: block;
}

.tab_content img {
  width: 800px;
  box-shadow: 0 10px 12px 4px rgba(0,0,0,0.3);
}

#tab_container {
  padding-bottom: 120px;
  background: url(../assets/img/taiwan/bg.jpg)no-repeat top;
  background-size: 100%;
}
/*tapmenu-end*/
#taibei{
  margin-top: -56px;
}
/* 메인 */
#taibei .taiwanmain img {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: 40%
}

#taibei .taiwanmain img:hover {
  box-shadow: 5px 5px 5px 0.3;
}

#taibei .taiwanmain p {
  animation: new 20s;
  display: block;
  position: absolute;
  top: 27%;
  left: 28%;
  z-index: 90;
  color: #fff;
  font-size: 35px;
  font-family: 'KCC-Jeongbeom';
}

/* 예약 */
#reser1{
  display: none;
}
#reser2{
  display: none;
}
#reser3{
  display: none;
}
#reser4{
  display: none;
}
#reser5{
  display: none;
}
.resergo{
  margin-bottom: 5rem;
}
#taibei .resergo {
  margin-top: 5rem;
  text-align: center;
}

#taibei .resergo .go {
  transition: all 0.5s;
  box-shadow: 0px 8px 0px rgb(52, 52, 52);
  border: solid 1px rgb(167, 167, 167);
}

#taibei .resergo a {
  color: #000;
  transition: all 0.5s;
  display: block;
  width: 250px;
  padding: 20px 0;
  margin: 0 auto;
}

#taibei .resergo a:hover .go {
  background-color: rgb(255, 183, 0);
  box-shadow: none;
  transform: translate(0px, 10px);
  color: #fff;
}

.go {
  padding: 20px 0;
  border-radius: 10px;
  display: block;
}
@media (max-width: 375px) {
  @keyframes fadeUpRight {
    0%{right: 11%; top: 11%;}
    50%{right:13%; top: 13%;}
    100%{right:11%; top:11%;}
  }
  .tab_list a {
    padding-top: 12%;
    font-size: 17px;
  }

  .cloud1 {
    top: 15%;
    left: 0%;
  }

  .cloud1 img {
    width: 40vw;
  }

  .cloud2 {
    top: 25%;
    right: 2%;
  }

  .cloud2 img {
    width: 35vw;
  }

  .airplane {
    top: 15%;
    right: 11%;
  }

  .airplane img {
    width: 25vw;
  }
}

@media (max-width: 767px) and (min-width: 376px) {
  @keyframes fadeUpRight {
    0%{right: 11%; top: 10%;}
    50%{right:15%; top: 12%;}
    100%{right:11%; top:10%;}
  }
  .tab_list a {
    padding-top: 17%;
    font-size: 19px;
  }

  .cloud1 {
    top: 14%;
    left: 5%;
  }

  .cloud1 img {
    width: 35vw;
  }

  .cloud2 {
    top: 21%;
    right: 4%;
  }

  .cloud2 img {
    width: 35vw;
  }

  .airplane {
    top: 12%;
    right: 9%;
  }

  .airplane img {
    width: 25vw;
  }

  .smoke {
    display: none;
  }
}

@media (max-width:375px) {
  #taibei .taiwanmain p {
    top: 13%;
    left: 8%;
    font-size: 15px;
  }
  #taibei .taiwanmain img{
    width: 70%;
    top: 26%;
  }
}

@media (min-width:376px) and (max-width:767px) {
  #taibei .taiwanmain p {
    top: 11%;
    left: 8%;
    font-size: 18px;
  }
  #taibei .taiwanmain img{
    width: 70%;
    top: 22%;
  }
}

@media(max-width: 767px) {
  .carousel_slide img{
    width: 100%;
  }
  #tab_container {
    padding-bottom: 0;
  }
  .tab_basic {
    height: 100%;
  }
  .tab_list{
    display: inline-flex;
    flex-wrap: wrap;
  }
  .tab_list a{
    padding: 10% 0;
  }
  .last{
  border: none;
  }
  .top{
  border-top: 1px solid #fff;
  }
  .airplane2{
    display: none;
  }
  .cloud3{
    display: none;
  }
  .cloud4{
    display: none;
  }
  .cloud5{
    display: none;
  }
  .smoke {
    display: none;
  }
}
@media (max-width:1500px) and (min-width: 1201px) {
  #taibei .taiwanmain img {
    width: 60%
  }
}

@media (min-width:768px) and (max-width:1200px) {
  #taibei .taiwanmain img {
    width: 65%
  }
}

@media (max-width:767px) {
  #taibei .taiwanmain {
    width: 100%;
  }
}

@media (max-width:767px) {
  .tab_list_warp .tab_basic .tab_list.tab_active .tab_list a {
    width: 100%;
  }
  .tab_list:hover {
  border-bottom: none;
  }
}

@media (max-width:767px) {
  .tab_list_wrap {
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    padding: 0;
  }
}

@media (max-width:767px) {
  .tab_list {
    width: 33.33%;
    font-size: 12px;
    padding: 0px;
  }
}

@media (max-width:767px) {
  .tab_content img {
    width: 100%;
  }
}

@media (max-width:767px) {
  .tab_content {
    width: 100%;
  }
}

@media (min-width:768px) {
  .tab_list a {
    padding-top: 7%;
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .carousel_slide img{
    width: 133%;
    height: auto;
    margin-top: 35px;
  }
}
@media (max-width:2560px) and (min-width:1921px){
  .tab_line{
    top: -210px;
  }
}
@media (max-width:1920px){
  .tab_line{
    top: -175px;
  }
}
@media (max-width:1919px){
  .tab_line{
    top: 0;
  }
}
</style>

