<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>결합상품 가격등록</u></h2>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품코드</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="this.productId" readonly>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품구분{{this.salesRoute}}</label>
        <div class="col-md-9">
          <div class="form-check-inline" :key=i v-for="(baseCode,i) in this.sRoute">
            <div v-if="this.sRoute[i].baseCode === this.salesRoute">
              <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value=this.sRoute[i].baseCode ref="rbt_salesRoute" checked disabled>
              <label class="form-check-label text-primary" for="flexRadioDefault2">
                {{ this.sRoute[i].codeNameKor }}
              </label>
            </div>
            <div v-else>
              <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value=this.sRoute[i].baseCode ref="rbt_salesRoute" disabled>
              <label class="form-check-label text-secondary" for="flexRadioDefault2">
                {{ this.sRoute[i].codeNameKor }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<font class="text-secondary" style="font-size: small;">(국문)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_pdtNameKor" v-model="productInfo.pdtNameKor" disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<font class="text-secondary" style="font-size: small;">(영문)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productInfo.pdtNameEng" disabled>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">국가 / 지역</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="this.productInfo.ntName" disabled>
            <input type="text" class="form-control" v-model="this.productInfo.arName" disabled>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3 row">
        <label class="col-md-3 col-form-label">여행/유효기간</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.tripStart" disabled>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" v-model="productInfo.tripEnd" disabled>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">기본마진</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="number" class="form-control text-end" ref="txt_basicMargin" v-model="productInfo.basicMargin" disabled>
            <select class="form-select form-select-md text-end" aria-label=".form-select-md" v-model="productInfo.marginMethod" disabled>
              <option value="A" id="promoMethod" selected>원</option>
              <option value="P" id="promoMethod">%</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">판매기간</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.salesStart" disabled>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.salesEnd" disabled>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">특기사항</label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
            v-model="productInfo.pdtRemarks" maxlength="210" disabled></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">요금설정<font class="text-danger">*</font>
          <p v-if="productInfo.salesRoute != 'S' && productInfo.salesRoute != 'H'">
            <font class="text-danger" style="font-size:small;">1:성인 / 2:소아 / 3:인펀트 / 4:기타할인</font></p>
          <p v-else-if ="productInfo.salesRoute === 'H'">
            <font class="text-danger" style="font-size:small;">기준(성인+싱글룸)</font></p>
          <p><font class="text-secondary" style="font-size:small;">(미입력시 0원)</font></p>
        </label>
        <div class="col-md-9">
          <table class="table table-bordered border-success align-middle">
            <tr>
              <td class="text-start">
                기본가격 / 적용기간
              </td>
              <td class="text-primary" colspan="2">
                <!-- <input type="range" class="form-range" min="1" max="12" step="1" id="customRange3" v-model="month"> -->
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">개시</span>
                    <input type="month" class="form-control" ref="txt_pdtPriceStart" v-model="productPrice.pdtPriceStart">
                    <span class="input-group-text" id="basic-addon1">종료</span>
                    <input type="month" class="form-control" ref="txt_pdtPriceEnd" v-model="productPrice.pdtPriceEnd">
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="1">
                <div v-if="!productInfo.salesRoute">
                </div>
                <div v-else-if="productInfo.salesRoute === 'T' || productInfo.salesRoute === 'L' || productInfo.salesRoute === 'E'">
                  <div class="input-group mb-3" :key="i" v-for="i in 4">
                    <span class="input-group-text">{{i}}</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice[i]" v-model="productPrice.pdtPrice[i]">
                    <!-- <span class="input-group-text">원</span> -->
                  </div>
                </div>
                <div v-else-if="productInfo.salesRoute === 'S' || productInfo.salesRoute === 'H'">
                  <div class="input-group mb-3">
                    <span class="input-group-text">1</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice1" v-model="productPrice.pdtPrice1">
                    <!-- <span class="input-group-text">원</span> -->
                  </div>
                </div>
              </td>
              <td>
                <div class="col-auto d-grid p-1">
                  <button type="button" class="btn btn-lg btn-success" @click="goToPriceSetPage(this.productId);">상품가격 관리페이지<small>(기본가격 등록우선)</small></button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- 추가옵션 영역: 기능홀딩(202210) -->
      <!-- <div class="mb-2 row" v-if="productInfo.salesRoute === 'T'">
        <label class="col-md-3 col-form-labe text-success">추가옵션</label>
        <div class="col-md-9">
          <div class="form-check-inline align-middle">
            <input class="form-check-input" type="checkbox" v-model="optionFlag1" true-value="Y" false-value="N" @click="addOptCheck(productInfo.salesRoute)">
            <label class="form-check-label text-success" for="ckb_transFlag">&nbsp;포함&nbsp;(Y:포함 / N:불포함)</label>
          </div>
        </div>
      </div>
      <div  v-if="optionFlag1 === 'Y'">
        <div class="row border p-sm-2 border-success">
          <label class="col-md-3 col-form-label text-primary">현지투어
            <p>
              <font class="text-secondary" style="font-size:small;">(성인기준)</font>
            </p>
          </label>
          <div class="col-md-9">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_seqDeli" v-model="addOptNameList_l.pdtCode" @change="changeAddOptN('L', addOptNameList_l.pdtCode)">
              <option :value="noVal">적용상품없음</option>
              <option :value="addOptL.pdtCode" :key=i v-for="(addOptL,i) in addOptNameList_l">
                {{addOptL.pdtNameKor}}({{addOptL.saleStatus}}&nbsp;/&nbsp;{{addOptL.pdtCode}})
              </option>
            </select>
            <table class="table align-middle text-samll">
              <tr>
                <td class="col-md-4" colspan="2">
                  <div class="input-group" :key=j v-for="(pdtPrice,j) in addOptList_l_g">
                    <span class="input-group-text">최고가</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_l_g[j].maxPrice" readonly>
                    &nbsp;<i class="bi bi-slash-lg"></i>&nbsp;
                    <span class="input-group-text">최저가</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_l_g[j].minPrice" readonly>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <label class="col-md-3 col-form-label text-danger">숙소
            <p>
              <font class="text-secondary" style="font-size:small;">※성인+싱글기준</font>
            </p>
          </label>
          <div class="col-md-9">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_seqDeli" v-model="addOptNameList_h.pdtCode" @change="changeAddOptN('H', addOptNameList_h.pdtCode)">
              <option :value="noVal">적용상품없음</option>
              <option :value="addOptH.pdtCode" :key=i v-for="(addOptH,i) in addOptNameList_h">
                {{addOptH.pdtNameKor}}({{addOptH.saleStatus}}}&nbsp;/&nbsp;{{addOptH.pdtCode}})
              </option>
            </select>
            <table class="table align-middle text-small">
              <tr>
                <td class="col-md-4" colspan="2">
                  <div class="input-group" :key=j v-for="(pdtPrice,j) in addOptList_h_g">
                    <span class="input-group-text">최고가</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_h_g[j].maxPrice" readonly>
                    &nbsp;<i class="bi bi-slash-lg"></i>&nbsp;
                    <span class="input-group-text">최저가</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_h_g[j].minPrice" readonly>
                  </div>
                </td>
              </tr>
            </table>            
          </div>

          <label class="col-md-3 col-form-label text-info">기타상품</label>
          <div class="col-md-9">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_seqDeli" v-model="addOptNameList_e.pdtCode" @change="changeAddOptN('E', addOptNameList_e.pdtCode)">
              <option :value="noVal">적용상품없음</option>
              <option :value="addOptN.pdtCode" :key=i v-for="(addOptN,i) in addOptNameList_e">
                {{addOptN.pdtNameKor}}({{addOptN.saleStatus}}}&nbsp;/&nbsp;{{addOptN.pdtCode}})
              </option>
            </select>
            <table class="table align-middle text-small">
              <tr>
                <td class="col-md-4" colspan="2">
                  <div class="input-group" :key=j v-for="(pdtPrice,j) in addOptList_e_g">
                    <span class="input-group-text">최고가</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_e_g[j].maxPrice" readonly>
                    &nbsp;<i class="bi bi-slash-lg"></i>&nbsp;
                    <span class="input-group-text">최저가</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_e_g[j].minPrice" readonly>
                  </div>              
                </td>
              </tr>
            </table>            
          </div>

          <label class="col-md-3 col-form-label text-warning">단일가상품</label>
          <div class="col-md-9">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_seqDeli" v-model="addOptNameList_s.pdtCode" @change="changeAddOptN('S', addOptNameList_s.pdtCode)">
              <option :value="noVal">적용상품없음</option>
              <option :value="addOptS.pdtCode" :key=i v-for="(addOptS,i) in addOptNameList_s">
                {{addOptS.pdtNameKor}}({{addOptS.saleStatus}}}&nbsp;/&nbsp;{{addOptS.pdtCode}})
              </option>
            </select>
            <table class="table align-middle text-small">
              <tr>
                <td class="col-md-4" colspan = "2">
                  <div class="input-group" :key=j v-for="(pdtPrice,j) in addOptList_s_g">
                    <span class="input-group-text">최고가</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_s_g[j].maxPrice" readonly>
                    &nbsp;<i class="bi bi-slash-lg"></i>&nbsp;
                    <span class="input-group-text">최저가</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_s_g[j].minPrice" readonly>
                  </div>              
                </td>
              </tr>
            </table>            
          </div>
        </div>
      </div>

      <div class="mb-2 row" v-if="productInfo.salesRoute === 'L'" @click="addOptCheck(productInfo.salesRoute)">
        <label class="col-md-3 col-form-labe text-primary">추가옵션</label>
        <div class="col-md-9">
          <div class="form-check-inline align-middle">
            <input class="form-check-input" type="checkbox" v-model="optionFlag2" true-value="Y" false-value="N" @click="addOptCheck(productInfo.salesRoute)">
            <label class="form-check-label text-success" for="ckb_transFlag">&nbsp;포함&nbsp;(Y:판매중 / N:판매중지)</label>
          </div>
        </div>
      </div>
      <div  v-if="optionFlag2 === 'Y'">
        <div class="row border p-sm-2 border-primary">
          <label class="col-md-3 col-form-label text-info">기타상품</label>
          <div class="col-md-9">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_seqDeli" v-model="addOptNameList_e.pdtCode" @change="changeAddOptN('E', addOptNameList_e.pdtCode)">
              <option :value="noVal">적용상품없음</option>
              <option :value="addOptN.pdtCode" :key=i v-for="(addOptN,i) in addOptNameList_e">{{addOptN.pdtNameKor}}({{addOptN.saleStatus}})</option>
            </select>
          </div>
          <table class="table align-middle text-small">
            <tbody>
              <tr>
                <td></td>
                <td class="col-md-4">
                  <div class="input-group" :key=j v-for="(pdtPrice,j) in addOptList_e_g">
                    <span class="input-group-text">{{j+1}}</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_e_g[j].pdtPrice" readonly>
                    <span class="input-group-text">원</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <label class="col-md-3 col-form-label text-warning">단일가상품</label>
          <div class="col-md-9">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_seqDeli" v-model="addOptNameList_s.pdtCode" @change="changeAddOptN('S', addOptNameList_s.pdtCode)">
              <option :value="noVal">적용상품없음</option>
              <option :value="addOptN.pdtCode" :key=i v-for="(addOptN,i) in addOptNameList_s">{{addOptN.pdtNameKor}}({{addOptN.saleStatus}})</option>
            </select>
          </div>
          <table class="table align-middle text-small">
            <tbody>
              <tr>
                <td></td>
                <td class="col-md-4">
                  <div class="input-group" :key=j v-for="(pdtPrice,j) in addOptList_s_g">
                    <span class="input-group-text">{{j+1}}</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_s_g[j].pdtPrice" readonly>
                    <span class="input-group-text">원</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>          
        </div>
      </div>

      <div class="mb-2 row" v-if="productInfo.salesRoute === 'E'" @click="addOptCheck(productInfo.salesRoute)">
        <label class="col-md-3 col-form-labe text-warning">추가옵션</label>
        <div class="col-md-9">
          <div class="form-check-inline align-middle">
            <input class="form-check-input" type="checkbox" v-model="optionFlag3" true-value="Y" false-value="N">
            <label class="form-check-label text-primary" for="ckb_transFlag">&nbsp;포함</label>
          </div>
        </div>
      </div>
      <div  v-if="optionFlag3 === 'Y'">
        <div class="row border p-sm-2 border-warning">
          <label class="col-md-3 col-form-label text-warning">단일가상품</label>
          <div class="col-md-9">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_seqDeli" v-model="addOptNameList_s.pdtCode" @change="changeAddOptN('S', addOptNameList_s.pdtCode)">
              <option :value="noVal">적용상품없음</option>
              <option :value="addOptN.pdtCode" :key=i v-for="(addOptN,i) in addOptNameList_s">{{addOptN.pdtNameKor}}({{addOptN.saleStatus}})</option>
            </select>
          </div>
          <table class="table align-middle text-small">
            <tbody>
              <tr>
                <td></td>
                <td class="col-md-4">
                  <div class="input-group mt-2" :key=j v-for="(pdtPrice,j) in addOptList_s_g">
                    <span class="input-group-text">{{j+1}}</span>
                    <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="addOptList_s_g[j].pdtPrice" readonly>
                    <span class="input-group-text">원</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>          
        </div>
      </div> -->

      <div class="mb-3 row border p-sm-2 border-info">
        <label class="col-md-3 col-form-label mt-2">기타옵션
          <i class="bi bi-plus-circle-fill text-primary" v-if="this.vCnt < 5" @click="addEtcOption()"></i>
          <p clss="text-secondary" style="font-size: small;">(최대 5)</p>
        </label>
          <div class="col-md-9">
            <div v-if="this.vCnt <= 5">
              <div class="input-group mb-1" :id=via :key="via" v-for="via in this.vCnt">
                <div class="align-self-center col-md-1" v-if="this.vCnt === via">
                  <i class="bi bi-dash-circle-fill text-danger" @click="removeEtcOption(via)"></i>
                </div>
              <div class="col-md-9">
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="옵션명" ref="txt_vatPrice" v-model="etcOptName[via]">
                  <input type="number" class="form-control text-end" ref="txt_vatPrice" v-model="etcOptPrice[via]">
                  <span class="input-group-text">원</span>&nbsp;&nbsp;
                  <input class="form-check-input my-auto" type="checkbox" v-model="reqOptFlag[via]" true-value="Y" false-value="N" id="chb_reqOptFlag">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="priceInsert();">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">취소</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>
    </div>
  </main>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
//import moment from 'moment';

export default {
  // components: {
  //   DatePicker
  // },
  data() { 
    return {
      productInfo: {}, //상품기본정보
      sRoute: {}, //상품구분
      seasonFlag: "G", //시즌정보
      etcOptName: [],
      etcOptPrice: [],
      reqOptFlag: [], //필수기타옵션플래그
      vCnt: 0,
      optionFlag1: "N", //추가옵션
      optionFlag2: "N",
      optionFlag3: "N",
      addOptNameList_l: {}, //추가옵션명(중복제거, Local)
      addOptNameList_h: {}, //추가옵션명(중복제거, Hotel)
      addOptNameList_e: {}, //추가옵션명(중복제거, Etc)
      addOptNameList_s: {}, //추가옵션명(중복제거, Single)
      //addOptNameList: {},
      pdtcode: "",
      addOptList_l: {}, //추가옵션가격(local)
      addOptList_h: {},
      addOptList_e: {},
      addOptList_s: {},

      //추가옵션-시즌별 가격
      addOptList_l_g: {},
      addOptList_l_t: {},
      addOptList_l_p: {},

      addOptList_h_g: {},
      addOptList_h_t: {},
      addOptList_h_p: {},

      addOptList_e_g: {},
      addOptList_e_t: {},
      addOptList_e_p: {},

      addOptList_s_g: {},
      addOptList_s_t: {},
      addOptList_s_p: {},

      productPrice: {
        // TB_Product_prc
        pdtCode: "",
        prcCode: "", //가격코드
        ageGroup: "",
        
        pdtPrice1: 0, //평시
        pdtPrice: [0,0,0,0,0],
        pdtPrice1_t: 0, //성수
        pdtPrice_t: [0,0,0,0,0],
        pdtPrice1_p: 0, //비수
        pdtPrice_p: [0,0,0,0,0],
        
        useFlag: "",
        addOptionFlag: "", //추가옵션유무Y0/N1
        etcOptionFlag: "", //기타옵션유무Y/N
        insDate: "",
        insId: "",
        priceClass: "",
        pdtPriceStart: "", //금액적용기간
        pdtPriceEnd: ""
      },
      seasonG: "Y", //시즌관리 폐지로 Y로 고정
      // seasonT: "",
      // seasonP: "",
      addOption: {}, //추가옵션
      etcOption: {}, //기타옵션
      dInterval: 0, //가격등록기간
      lastDay: "" //마지막날
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;

    this.getProductInfo();
    this.getSalesRoute();
  },
  mounted() {
    console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    //this.getSalesRoute();
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDayOfWeekFormat(text) {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text) {
      return this.$numberOfWeekFormat(text);
    },
    async getNationList() {
      let nationList_via = await this.$api("/api/nationList", {});
      this.nationList_via = nationList_via;
      //console.log("nationList_via",nationList_via);
    },
    async getSalesRoute() {
      this.sRoute = await this.$api("/api/salesRoute", {});
      //console.log("sRoute",this.sRoute);
    },
    async getProductInfo() {
      let productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      if(productInfo.length > 0) {
        this.productInfo = productInfo[0]
        this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
        this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
        this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
        this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      }
      //console.log("productInfo",this.productInfo);
    },
    //추가옵션 리스트박스용 상품명 추출(중복제거)
    async addOptCheck(val) {
      //추가옵션은 단일상품에서 추출
      let addOptName_tmp = await this.$api("/api/addOptionNameList", {param: [this.productInfo.ntCd]});
      console.log("addOptName_tmp:",addOptName_tmp);
      //let summaryData = this.eOrderList.filter(e => {return e.orderStatus == "30"}); //배열로 반환
      if(val === "T") {
        this.addOptNameList_l = addOptName_tmp.filter(e => {return e.salesRoute === "L"});
        this.addOptNameList_h = addOptName_tmp.filter(e => {return e.salesRoute === "H"});
        this.addOptNameList_e = addOptName_tmp.filter(e => {return e.salesRoute === "E"});
        this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
        //this.addOptNameList = addOptName_tmp.filter(e => {return e.priceClass != undefined && e.priceClass != '' && e.priceClass != "T"});
      }
      else if(val === "L") {
        this.addOptNameList_e = addOptName_tmp.filter(e => {return e.salesRoute === "E"});
        //this.addOptNameList = addOptName_tmp.filter(e => {return e.salesRoute != undefined && e.priceClass != '' &&e.priceClass != "T" && e.salesRoute != "L"});
        this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
      }
      else if(val === "E") {
        this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
      }
      //배열내부 중복제거
      //this.addOptName_local = Array.from(new Set(addOptName_local_tmp));
    },

    //기타옵션추가
    addEtcOption() {
        this.vCnt++;
        //this.vCnt = 1+this.vCnt;
        if(this.vCnt > 5) {
          return this.$swal.fire("","경유지 추가는 5까지입니다.","error");
        }
    },
    //기타옵션제거
    removeEtcOption(val) {
      this.vCnt--;
      this.etcOptName[val] = undefined;
      this.etcOptPrice[val] = undefined;
      this.reqOptFlag[val] = undefined;
      document.getElementById(val).remove();
    },
    async changeAddOptN(param1, param2) {
      //alert(param1+"/"+param2);
      let addOptList_tmp = await this.$api("/api/addOptionList", {param: [this.productInfo.ntCd]});
      let addOptList_filterd = [];
      addOptList_filterd = addOptList_tmp.filter(e => {return e.salesRoute === param1 && e.pdtCode === param2});

      //옵션별 시즌가격추출 -> 시즌관리 폐지로 시즌G로 통일
      switch(param1) {
        case "L": this.addOptList_l = addOptList_filterd;
                  this.addOptList_l_g = addOptList_filterd.filter(e => {return e.seasonCode === "G"});
                  // this.addOptList_l_t = addOptList_filterd.filter(e => {return e.seasonCode === "T"});
                  // this.addOptList_l_p = addOptList_filterd.filter(e => {return e.seasonCode === "P"});
        //console.log("addOptList_l_g:",this.addOptList_l_g[0]);
        break;
        case "H": this.addOptList_h = addOptList_filterd;
                  this.addOptList_h_g = addOptList_filterd.filter(e => {return e.seasonCode ===   "G"});
                  // this.addOptList_h_t = addOptList_filterd.filter(e => {return e.seasonCode === "T"});
                  // this.addOptList_h_p = addOptList_filterd.filter(e => {return e.seasonCode === "P"});
        //console.log("addOptList_h:",this.addOptList_h);
        break;
        case "E": this.addOptList_e = addOptList_filterd;
                  this.addOptList_e_g = addOptList_filterd.filter(e => {return e.seasonCode === "G"});
                  // this.addOptList_e_t = addOptList_filterd.filter(e => {return e.seasonCode === "T"});
                  // this.addOptList_e_p = addOptList_filterd.filter(e => {return e.seasonCode === "P"});
        //console.log("addOptList_e:",this.addOptList_e);
        break;
        case "S": this.addOptList_s = addOptList_filterd;
                  this.addOptList_s_g = addOptList_filterd.filter(e => {return e.seasonCode === "G"});
                  // this.addOptList_s_t = addOptList_filterd.filter(e => {return e.seasonCode === "T"});
                  // this.addOptList_s_p = addOptList_filterd.filter(e => {return e.seasonCode === "P"});
        //console.log("addOptList_s:",this.addOptList_s);
        break;
        default: return this.$swal.fire("","오류가 발생했습니다.<br>관리자에 문의해주세요.","error");
      }
    },
    vCntReset(cVal) {
      if(cVal === "0") {
        this.vCnt = 0;
      }
    },

    goToList() {
      this.$router.push({path: '/salesMix'});
    },
    priceInsert() {
      // e.preventDefault();
      // 입력 validation check
      let timeStamp = "";
      let today = new Date();   
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime(); // 상품코드에 들어갈 타임스탬프
      let catCode = "P"+this.productInfo.salesRoute;
      
      //추가옵션처리
      let arrCode = [];
      let arrCat = [];

      if(!this.productPrice.pdtPriceStart) 
      {
        return this.$swal.fire("","가격등록 시작일을 입력하세요","warning");
      }
      if(!this.productPrice.pdtPriceEnd) 
      {
        return this.$swal.fire("","가격등록 종료일을 입력하세요","warning");
      }

      // 가격입력 종료일은 선택월의 말일로 자동설정
      let lastDay_tmp = new Date(this.productPrice.pdtPriceEnd);
      let year = lastDay_tmp.getFullYear();
      let month = lastDay_tmp.getMonth()+1;
      let lastDay = new Date(year,month,0);
      this.lastDay = this.getDateFormat(lastDay);
      //console.log("/endDay:",this.getDateFormat(lastDay));

      let diffDate = this.productPrice.pdtPriceStart.replace(/-/g, "") - this.productPrice.pdtPriceEnd.replace(/-/g,"")
      if(diffDate > 0 )
      {
        return this.$swal.fire('', '시작일은 종료일보다 이전이어야 합니다.', 'error')
      }      

      //비용등록기간
      let dpDate = new Date(this.getDateFormat(this.productPrice.pdtPriceStart));
      //let rtDate = new Date(this.getDateFormat(this.productPrice.pdtPriceEnd));
      let rtDate = lastDay;
      let dateDiff = dpDate.getTime() - rtDate.getTime();        
      this.dInterval = Math.abs(dateDiff / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
      //console.log("dInterval:",this.dInterval)
      
      if(this.addOptNameList_l.pdtCode != "" && this.addOptNameList_l.pdtCode != undefined) {
        //arrCode.push(this.addOptNameList_l.pdtCode)
        arrCode.push(this.addOptNameList_l[0].prcCode)
        arrCat.push(this.addOptNameList_l.pdtCode.charAt(0))
      }
      if(this.addOptNameList_e.pdtCode != "" && this.addOptNameList_e.pdtCode != undefined) {
        //arrCode.push(this.addOptNameList_e.pdtCode)
        arrCode.push(this.addOptNameList_e[0].prcCode)
        arrCat.push(this.addOptNameList_e.pdtCode.charAt(0))
      }
      if(this.addOptNameList_h.pdtCode != "" && this.addOptNameList_h.pdtCode != undefined) {
        //arrCode.push(this.addOptNameList_h.pdtCode)
        arrCode.push(this.addOptNameList_h[0].prcCode)
        arrCat.push(this.addOptNameList_h.pdtCode.charAt(0))
      }
      if(this.addOptNameList_s.pdtCode != "" && this.addOptNameList_s.pdtCode != undefined) {
        //arrCode.push(this.addOptNameList_s.pdtCode)
        arrCode.push(this.addOptNameList_s[0].prcCode)
        arrCat.push(this.addOptNameList_s.pdtCode.charAt(0))
      }

      let objAddOpt = new Object();

      for(let i=0;i<arrCat.length;i++) {
        let key = arrCat[i];
        objAddOpt[key] = arrCode[i];
      }
      //객체크기
      let objAdtOptSize = Object.keys(objAddOpt).length;
      //console.log("objAddOpt:",objAddOpt);

      // //기타옵션
      // let objEtcOpt = new Object();
      // for(let j=1; j<this.etcOptName.length; j++) {
      //   let key = this.etcOptName[j];
      //   objEtcOpt[key] = this.etcOptPrice [j];
      // }
      // let objEtcOptSize = Object.keys(objEtcOpt).length;
      // console.log(objEtcOptSize);

      this.productPrice.pdtCode = this.productId;
      this.productPrice.prcCode = catCode+"-"+ymd+"-"+timeStamp;
      this.productPrice.insDate = `${yyyy}-${mm}-${dd}`;
      // console.log(this.productInfo.insDate);
      this.productPrice.insId = this.user.handlerId;
      this.productPrice.useFlag = '0';
      //console.log("optionFlag1:",this.optionFlag1, "optionFlag2:",this.optionFlag1, "optionFlag3:",this.optionFlag3, "objAdtOptSize:",objAdtOptSize);

      //추가옵션 유무
      if((this.optionFlag1 === 'Y' || this.optionFlag2 === 'Y' || this.optionFlag3 === 'Y') && objAdtOptSize > 0) {
        this.productPrice.addOptionFlag = "Y"
      }
      else {
        this.productPrice.addOptionFlag = "N"
      }
      //console.log("addOptionFlag:",this.productPrice.addOptionFlag);

      //기타옵션 유무
      if(this.vCnt> 0 && ((this.etcOptName[1] != undefined && this.etcOptName[1] != "") & (this.etcOptPrice[1] != undefined && this.etcOptPrice[1] != ""))) {
        this.productPrice.etcOptionFlag = "Y"
      }
      else {
        this.productPrice.etcOptionFlag = "N"
      }
      this.productPrice.priceClass = this.salesRoute;

      // if(!this.productInfo.pdtNameKor) {
      //   this.$refs.txt_pdtNameKor.focus();
      //   return this.$swal.fire("필수누락: 제품명(국문)");
      // }
      // if(!this.productInfo.salesRoute) {
      //   this.$refs.rbt_salesRoute.focus();
      //   return this.$swal.fire("필수누락: 판매루트");
      // }      
      // if(!this.salesUnit) {
      //   this.$refs.sel_salesUnit.focus();
      //   return this.$swal.fire("필수누락: 판매단위");
      // }
      // if(!this.productInfo.unitPrice) {
      //   this.$refs.txt_unitPrice.focus();
      //   return this.$swal.fire("필수누락: 기본가격");
      // }
      // if(!this.seqDeli) {
      //   this.$refs.sel_seqDeli.focus();
      //   return this.$swal.fire("필수누락: 배송비");
      // }
      // if(!this.productInfo.vatPrice) {
      //   this.$refs.txt_vatPrice.focus();
      //   return this.$swal.fire("필수누락: 부가세");
      // }
      // if(!this.productInfo.salesStart) {
      //   this.$refs.txt_fromDate.focus();
      //   return this.$swal.fire("필수누락: 판매개시일");
      // }
      // if(!this.productInfo.salesEnd) {
      //   this.$refs.txt_toDate.focus();
      //   return this.$swal.fire("필수누락: 판매종료일");
      // }
      // if(this.productInfo.salesStart > this.productInfo.salesEnd) {
      //   return this.$swal.fire("입력오류: 개시일&종료일");
      // }
      // if(!this.productInfo.catCode) {
      //   this.$refs.sel_pdtCategoryt.focus();
      //   return this.$swal.fire("필수누락: 상품카테고리");
      // }
      // 등록전 확인
      this.$swal.fire({
        title: "",
        text: '가격등록 진행?',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            let funcType = "";
            let sFlag = "";
            let sStat = "";          
          //let resIns_product_prc = {}
          if(this.productInfo.salesRoute === "T" || this.productInfo.salesRoute === "L" || this.productInfo.salesRoute === "E")
          {
            funcType = "m";
            
            if(this.seasonG === "Y") {
              sFlag = "G"
              sStat = "0"
              this.insertseasonPrice(sFlag, sStat, funcType);
            }
            else {
              sFlag = "G"
              sStat = "1"
              this.insertseasonPrice(sFlag, sStat, funcType);
            }

            // if(this.seasonT === "Y") {
            //   sFlag = "T"
            //   sStat = "0"
            //   this.insertseasonPrice(sFlag, sStat, funcType);
            // }
            // else {
            //   sFlag = "T"
            //   sStat = "1"
            //   this.insertseasonPrice(sFlag, sStat, funcType);
            // }

            // if(this.seasonP === "Y") {
            //   sFlag = "P"
            //   sStat = "0"
            //   this.insertseasonPrice(sFlag, sStat, funcType);
            // }
            // else {
            //   sFlag = "P"
            //   sStat = "1"
            //   this.insertseasonPrice(sFlag, sStat, funcType);
            // }

            if(this.productPrice.addOptionFlag === "Y") {
              for(let j=0; j < objAdtOptSize; j++){
                let resIns_addOption = await this.$api("/api/addOptInsert", {param: [this.productPrice.pdtCode, Object.values(objAddOpt)[j],
                  Object.keys(objAddOpt)[j], 'Y', this.productPrice.insId]});
                  //console.log(resIns_addOption);
                  // 인서트오류처리
                if(Object.keys(resIns_addOption)[0] === 'error') {
                  alert("상품가격정보 저장오류->관리자에게 문의해주세요!");
                }
              }
              let res_UpdPdtAddOptFlag = await this.$api("/api/changePdtAddOptFlag_mix", {param: [this.productId]});
              if(res_UpdPdtAddOptFlag.affectedRows < 0 || res_UpdPdtAddOptFlag.affectedRows === undefined){
                this.$swal.fire('','상품리스트에서 추가옵션 적용표시(Y)확인필요<br>미표시이면 관리자에게 문의하세요', 'warning')
              }
            }
          }
          else if(this.productInfo.salesRoute === "H" || this.productInfo.salesRoute === "S") {
            funcType = "S";

            if(this.seasonG === "Y") {
              sFlag = "G"
              sStat = "0"
              this.insertseasonPrice(sFlag, sStat, funcType);
            }
            else {
              sFlag = "G"
              sStat = "1"
              this.insertseasonPrice(sFlag, sStat, funcType);
            }

            //시즌관리 중지
            // if(this.seasonT === "Y") {
            //   sFlag = "T"
            //   sStat = "0"
            //   this.insertseasonPrice(sFlag, sStat, funcType);
            // }
            // else {
            //   sFlag = "T"
            //   sStat = "1"
            //   this.insertseasonPrice(sFlag, sStat, funcType);
            // }

            // if(this.seasonP === "Y") {
            //   sFlag = "P"
            //   sStat = "0"
            //   this.insertseasonPrice(sFlag, sStat, funcType);
            // }
            // else {
            //   sFlag = "P"
            //   sStat = "1"
            //   this.insertseasonPrice(sFlag, sStat, funcType);
            // }
          }
          //기타옵션처리
          this.InsertEtcOption();
          // 등록 후 라우터를 통해 상품관리리스트로
          //this.$router.push({path: '/salesMix'});
          //입력완료
          this.$swal.fire('','지정된 기간의 기본가격등록이 완료되었습니다', 'success')          
        }
      })
    },
    async insertseasonPrice(sFlag, sStat, funcType) { //시즌속성, 적용상태
      let seasonFlag = sFlag; //시즌속성
      let spUseFlag = sStat; //시즌가격 이용여부
      //let seasonPrice = [0,0,0,0,0]; //시즌가격
      let sfuncType = funcType;
      
      let resIns_product_prc = {};
      //console.log("GP:",this.productPrice.pdtPrice[1], "PP:",this.productPrice.pdtPrice_t[1]);

      //상세가격용 prcCode
      let timeStamp = "";
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime(); //가격코드에 들어갈 타임스탬프
      let prcCode = "P"+this.productId.charAt(0)+"-"+ymd+timeStamp; //기본가격과는 다른 가격을  고유코드
      //alert("prcCode:" + prcCode);      

      let arr_p = [];
      if(this.productInfo.salesRoute === "S") {
        arr_p = [this.productPrice.pdtPrice1];
      }
      else {
        arr_p = [this.productPrice.pdtPrice[1],this.productPrice.pdtPrice[2],this.productPrice.pdtPrice[3],this.productPrice.pdtPrice[4]];
      }

      if(sfuncType === "m") {
        if(sFlag === "G") {
          //seasonPrice = this.productPrice.pdtPrice[i]
          let i=1;
          while(i < 5) {
            resIns_product_prc = await this.$api("/api/productPrcInsert", {param: [this.productPrice.pdtCode, this.productPrice.prcCode, i,
              this.productPrice.pdtPrice[i], spUseFlag, this.productPrice.addOptionFlag, this.productPrice.etcOptionFlag, this.productPrice.insId, 
              this.productPrice.priceClass, seasonFlag,this.getDateFormat(this.productPrice.pdtPriceStart),this.lastDay]});
              console.log("resIns_product_prc:", resIns_product_prc);
              // 인서트오류처리
            if(Object.keys(resIns_product_prc)[0] === 'error') {
              alert("평시 가격정보 저장오류");
            }
            i++;
          }
          //데일리가격등록
          let j = 0
          while(j < this.dInterval + 1) { 
            console.log(j);
            let firstDay = new Date(this.productPrice.pdtPriceStart);
            let nextDay_tmp = firstDay.setDate(firstDay.getDate()+j);
            let nextDay_tmp2 = new Date(nextDay_tmp);
            let nextDay = this.getDateFormat(nextDay_tmp2.toISOString())
            let pDow = this.getNumberOfWeekFormat(nextDay);
            console.log("nextDay:",nextDay);

            let a = 0
            while(a < 4) {
              let resIns_pdtPrice_d = await this.$api("/api/priceDetailInsert", {param: [this.productId, prcCode, a+1, arr_p[a], pDow, nextDay, "0", this.user.handlerId]});
              //console.log("errCheck:", Object.keys(resIns_pdtPrice_d)[0]);
              if(Object.keys(resIns_pdtPrice_d)[0] === "error") {
                this.$swal.fire("","저장오류","error");
                return false;
              }
              a++;
            }
            j++;
          }
        }
      }
      else if(sfuncType === "S") 
      {
        if(sFlag === "G") 
        {
          resIns_product_prc = await this.$api("/api/productPrcInsert", {param: [this.productPrice.pdtCode, this.productPrice.prcCode, "1",
              this.productPrice.pdtPrice1, spUseFlag, this.productPrice.addOptionFlag, this.productPrice.etcOptionFlag, this.productPrice.insId, 
              this.productPrice.priceClass, seasonFlag,this.getDateFormat(this.productPrice.pdtPriceStart),this.getDateFormat(this.productPrice.pdtPriceEnd)]});
              console.log("resIns_product_prc:", resIns_product_prc);
          if(Object.keys(resIns_product_prc)[0] === 'error') 
          {
              alert("평상시 가격정보 저장오류");
              return false;
          }
          else
          {
            //데일리가격등록
            let j = 0
            while(j < this.dInterval + 1) 
            { 
              //console.log(j);
              let firstDay = new Date(this.productPrice.pdtPriceStart);
              let nextDay_tmp = firstDay.setDate(firstDay.getDate()+j);
              let nextDay_tmp2 = new Date(nextDay_tmp);
              let nextDay = this.getDateFormat(nextDay_tmp2.toISOString())
              let pDow = this.getNumberOfWeekFormat(nextDay);
              //console.log("nextDay:",nextDay);
              let resIns_pdtPrice_d = await this.$api("/api/priceDetailInsert", {param: [this.productId, prcCode, "1", arr_p, pDow, nextDay, "0", this.user.handlerId]});
                //console.log("errCheck:", Object.keys(resIns_pdtPrice_d)[0]);
                if(Object.keys(resIns_pdtPrice_d)[0] === "error") 
                {
                  this.$swal.fire("","저장오류","error");
                  return false;
                }
              j++;
            }
          }          
        }
      }
    },
    //기타옵션 처리공통함수
    async InsertEtcOption(){
      //기타옵션
      let objEtcOpt = new Object();
      for(let j=1; j<this.etcOptName.length; j++) {
        let key = this.etcOptName[j];
        objEtcOpt[key] = this.etcOptPrice [j];
      }
      let objEtcOptSize = Object.keys(objEtcOpt).length;
      //console.log(objEtcOptSize);

      if(this.productPrice.etcOptionFlag === "Y") {
        for(let j=0; j < objEtcOptSize; j++){
          if(this.reqOptFlag[j] == null) {
            this.reqOptFlag[j] = "N";
          }          
          //pdtCode, etcOptName, etcOptPrice, useFlag, insId
          let resIns_etcOption = await this.$api("/api/etcOptInsert", {param: [this.productPrice.pdtCode, Object.keys(objEtcOpt)[j],
            Object.values(objEtcOpt)[j], 'Y', this.productPrice.insId, this.reqOptFlag[j+1]]});
            //console.log(resIns_etcOption);
            // 인서트오류처리
          if(Object.keys(resIns_etcOption)[0] === 'error') {
            alert("기타옵션 저장오류");
          }
        }
        let res_UpdPdtEtcOptFlag = await this.$api("/api/changePdtEtcOptFlag_mix", {param: [this.productId]});
        if(res_UpdPdtEtcOptFlag.affectedRows < 0 || res_UpdPdtEtcOptFlag.affectedRows === undefined){
          this.$swal.fire('','상품리스트에서 기타옵션 적용표시(Y)확인필요<br>미표시이면 관리자에게 문의하세요', 'warning')
        }
      }
    },
    //가격관리페이지로 이동
    goToPriceSetPage(productId) {
      this.$router.push({path: '/productPriceSet', query: {productId: productId, sRoute: this.salesRoute}});
    },    
  }
}
</script>