<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="float-end mb-1">
          <button type="button" class="btn btn-primary" @click="goToInsert">비용등록</button>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class = "small">
            <tr>
              <th>코드</th>
              <th>구분</th>
              <th>명칭</th>
              <th>코드</th>
              <!-- <th>이용료</th> -->
              <th>유효기간</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(product, i) in etcFeeList">
              <!-- <td>
                <img v-if="product.useFlag == '0'" :src="`/download/${product.pdtCode}/${product.imgName}`" style="height:50px;width:auto;" />
                <img v-else :src="`/download/noImage/noImage.jpg`" style="height:50px;width:auto;" />
              </td> -->
              <td class="text-primary">{{ product.etcFeeCode }}</td>
              <td v-if="product.feeClass == 'T'" class="text-secondary">터미널</td>
              <td v-else-if="product.feeClass == 'O'" class="text-danger">유류비</td>
              <td v-else>{{product.feeClass}}</td>
              <td>{{ product.etcFeeNameKor }}</td>
              <td>{{ product.tmCd }}</td>
              <!-- <td>
                {{ getCurrencyFormat(product.ageFee) }}
                <br>
                <font class="text-primary" v-if="product.promoMethod === 'P'">-{{ getCurrencyFormat(Math.floor(product.promoRate)) }}%</font>
                <font class="text-primary" v-else-if="product.promoMethod === 'A'">-{{ getCurrencyFormat(Math.floor(product.promoRate)) }}</font>
              </td> -->
              <td>
                {{ getDateFormat(product.fromDate) }} ~ {{ getDateFormat(product.toDate) }}
              </td>
              <td v-if="product.useFlag === '0'">Y</td>
              <td v-else class="text-warning">N</td>
              <td>
                <!-- me-1: rigt margin1 -->
                <button type="button" class="btn btn-secondary btn-xs me-1" @click="goToUpdate(product.etcFeeCode, product.feeClass);">수정</button>
                <button type="button" class="btn btn-warning btn-xs me-1" @click="changeStatus(product.useFlag, product.etcFeeCode)">상태</button>
                <button type="button" class="btn btn-success btn-xs" @click="goToCopy(product.etcFeeCode)">복사</button>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      etcFeeList: {}
    };
  },
  created() {
    this.getEtcFeeList();
    this.productId = this.$route.query.productId;
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }    
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    //기타비용 리스트
    async getEtcFeeList() {
      this.etcFeeList = await this.$api("/api/etcFeeList", {});
      //console.log("etcFeeList:",this.etcFeeList);
    },
    goToInsert() {
      this.$router.push({path: '/etcPriceCreate'});
    },
    goToUpdate(etcFeeCode, feeClass) {
      // 이미지 저장패스
      this.$router.push({path: '/etcPriceUpdate', query: {etcFeeCode: etcFeeCode, feeClass: feeClass}});
    },
    goToCopy(etcFeeCode) {
      this.$swal.fire(etcFeeCode, '이 기능은 추후오픈됩니다.', 'info');
    },
    //상세페이지 추후개발
    // goToDetail(pdtCode) {
    //   //console.log(pdtCode);
    //   this.$router.push({path: '/detail', query: {productId: pdtCode}});
    // },

    changeStatus(uStatus, etcFeeCode) {
      // 변경전 확인
      //alert(uStatus+"/"+etcFeeCode);
      this.$swal.fire({
        title: '적용상태(적용<->중지)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let sStatus = (uStatus == "0") ? "1" : "0"
          // console.log(sStatus, pdtCode);
          await this.$api("/api/changeEtcFeeStatus", {param: [sStatus, etcFeeCode]});
          // 상태변경 후, 갱신
          this.getEtcFeeList();
          this.$swal.fire('변경완료!', '', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  }
}
</script>