<template>

<div class="reserdetail">
      <section class="py-1">
        <div class="container my-3">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-11">
              <h2 class="d-flex justify-content-center mt-5" style="font-weight:600">나 의 예 약</h2>

              <!-- 상품안내 -->
              <div class="r-name container">
                <div class="row col-12">
                  <!-- <div class="col-lg-3">
                    <img :src="`/download/${productId}/${this.productImage[0].imgName}`" class="d-block w-100" alt="...">
                  </div> -->
                  <div class="name col-lg-9 text-start">
                    <p class="code">{{ this.eventInfo.eventCode }}</p>
                    <p>{{ this.eventInfo.eventNameKor }}</p>
                    <p class="ndate">{{this.eventInfo.evtStartDate}} ~ {{this.eventInfo.evtCloseDate}}</p>
                    <!-- <p class="text-between">
                      <i class="bi bi-vector-pen text-primary" style="cursor: pointer;" @click="toReview(this.reqRsvCode)"> <small class="text-secondary">후기작성(여행 후 30일 이내 작성가능)</small></i>
                    </p> -->
                  </div>
                </div>
              </div>

              <!-- 여행자정보 -->
              <div class="traval">
                <div class="container">
                  <div class="title text-start">
                    <p>행사자정보</p>
                  </div>

                  <div class="text-start">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="col-3">예약자</th>
                          <td class="col-9 text-start">{{this.eventInfo.reqName}}</td>
                        </tr>
                        <tr>
                          <th>연락처</th>
                          <td class="col-9 text-start">{{this.eventInfo.reqTelNo}} / {{this.eventInfo.conEmail}}</td>
                        </tr>
                        <tr>
                          <th>신청일</th>
                          <td class="col-9 text-start">{{this.getDateFormat(this.eventInfo.reqDate)}}</td>
                        </tr>
                        <tr>
                          <th>여행인원</th>
                          <td class="col-9 text-start">
                            {{this.eventInfo.adultCnt*1 + this.eventInfo.childCnt*1 + this.eventInfo.infCnt*1 + this.eventInfo.discountCnt*1}} 인
                          </td>
                        </tr>                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- 예약정보 -->
              <div class="reser">
                <div class="container">
                  <div class="title text-start">
                    <p>행사정보 <font class="recode">({{ this.eventInfo.eventCode }})</font></p>
                  </div>
  
                  <div class="text-start">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="col-3">상태</th>
                          <td class="col-9 text-start">
                            <div class="col-md-9" v-if="this.eventInfo.reqStatus === 'RP' && this.paidFlag == 'Y'">
                              결제가 완료되었습니다.
                              <p><small class="text-muted">(담당자가 확인 중에 있습니다.)</small></p>
                            </div>
                            <div class="col-md-9" v-else-if="this.eventInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                              결제요청
                            </div>                            
                            <div class="col-md-9" v-else>
                              {{ this.eventInfo.procName }}
                            </div>                            
                          </td>
                        </tr>                        
                        <tr>
                          <th>행사명</th>
                          <td class="col-9 text-start">{{ this.eventInfo.eventNameKor }}</td>
                        </tr>
                        <tr>
                          <th>행사지역</th>
                          <td class="col-9 text-start">
                            {{this.eventInfo.ctName}} <i class="bi bi-arrow-right text-success"></i> {{this.eventInfo.ntName}} / {{this.eventInfo.arName}}
                          </td>
                        </tr>
                        <tr>
                          <th>포함사항</th>
                          <td>
                            <textarea class="form-control text-primary" ref="txt_includeContent" id="floatingTextarea" placeholder="490자 이내" style="height:80px; font-size:small" v-model="this.eventInfo.includeContent" maxlength="499" readonly></textarea>
                          </td>
                        </tr>
                        <tr>
                          <th>불포함사항</th>
                          <td>
                            <textarea class="form-control text-danger" ref="txt_includeContent" id="floatingTextarea" placeholder="490자 이내" style="height:80px; font-size:small" v-model="this.eventInfo.notIncludeContent" maxlength="499" readonly></textarea>
                          </td>
                        </tr>
                        <tr>
                          <th>추가사항</th>
                          <td class="col-9 text-start">
                            <div :key=i v-for="(a, i) in this.addItemInfo">
                              {{a.addContentName}}&nbsp;&nbsp;[ {{ a.addContentPrice }}원 ]
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>할인사항</th>
                          <td class="col-9 text-start">
                            <div :key=i v-for="(d, i) in this.addDisInfo">
                              {{d.addDiscountName}}&nbsp;&nbsp;[ {{ d.addDiscountPrice }}원 ]
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>행사금액</th>
                          <td class="col-9 text-start">
                            <div class="fs-6">
                              {{getCurrencyFormat(this.eventInfo.cstPrice_new)}}원
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- 교통정보 -->
              <div class="container vehicle">
                <div class="row col-12">
                  <div class="title text-start">
                    <p>교통정보</p>
                  </div>

                  <div class="text-start arrive">
                    <table class="table">
                      <tbody>
                        <tr style="font-size:1rem">
                          <th class="col-3" style="vertical-align:middle">출발<i class="bi bi-arrow-right-short"></i>도착</th>
                          <td class="col-9 text-start" style="vertical-align:middle">
                            한국({{this.evtTransInfo1.ddCodeName}}) → {{this.eventInfo.ntName}}({{this.evtTransInfo1.daCodeName}})
                          </td>
                        </tr>
                        <tr>
                          <th>출발편</th>
                          <td class="col-9 text-start">
                            {{this.evtTransInfo1.dmobileCodeName}}
                            <small>
                              ({{this.evtTransInfo1.dmobileCode}}{{this.evtTransInfo1.dpTpCode}},&nbsp;{{this.evtTransInfo1.d_dpTime}} → {{this.evtTransInfo1.d_arTime}})
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <th>추가사항</th>
                          <td class="col-9 text-start">
                            <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:100px" v-model="this.evtTransInfo1.transRemarks_dp" maxlength="300" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="text-start arrive mt-4">
                    <table class="table">
                      <tbody>
                        <tr style="font-size:1rem">
                          <th class="col-3" style="vertical-align:middle">귀국<i class="bi bi-arrow-right-short"></i>도착</th>
                          <td class="col-9 text-start" style="vertical-align:middle">
                            {{this.eventInfo.ntName}}({{this.evtTransInfo1.rdCodeName}}) → 한국({{this.evtTransInfo1.raCodeName}})
                          </td>
                        </tr>
                        <tr>
                          <th>귀국편</th>
                          <td class="col-9 text-start">
                            {{this.evtTransInfo1.rmobileCodeName}}
                            <small>
                              ({{this.evtTransInfo1.rmobileCode}}{{this.evtTransInfo1.rtTpcode}},&nbsp;{{this.evtTransInfo1.r_dpTime}} → {{this.evtTransInfo1.r_arTime}})
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <th>추가사항</th>
                          <td class="col-9 text-start">
                            <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:100px" v-model="this.evtTransInfo1.transRemarks_ar" maxlength="300" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- 결제선택 -->
              <div class="reser">
                <div class="container">
                  <div class="title text-start">
                    <p>행사결제</p>
                  </div>

                  <div class="text-start">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="col-3">행사명</th>
                          <td class="col-9 text-start">{{ this.eventInfo.eventNameKor }}</td>
                        </tr>
                        <tr>
                          <th class="col-3">인원(금액)</th>
                          <td class="col-9 text-start">
                            <div>
                              <p v-if="this.eventInfo.adultCnt>0">
                                성인: {{ this.eventInfo.adultCnt }} 인 ({{ getCurrencyFormat(this.eventInfo.adultPrice * this.eventInfo.adultCnt) }} 원)
                              </p>
                              <p v-if="this.eventInfo.childCnt>0">
                                소아: {{ this.eventInfo.childCnt }} 인 ({{ getCurrencyFormat(this.eventInfo.childPrice * this.eventInfo.childCnt) }} 원)
                              </p>
                              <p v-if="this.eventInfo.infCnt>0">
                                유아: {{ this.eventInfo.infCnt }} 인 ({{ getCurrencyFormat(this.eventInfo.infPrice * this.eventInfo.infCnt) }} 원)
                              </p>
                              <p class="text-primary">
                                소계: {{ getCurrencyFormat((this.eventInfo.adultPrice * this.eventInfo.adultCnt)+(this.eventInfo.childPrice * this.eventInfo.childCnt)+(this.eventInfo.infPrice * this.eventInfo.infCnt)) }} 원
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>추가사항</th>
                          <td class="col-9 text-start">
                            <div :key=i v-for="(a, i) in this.addItemInfo">
                              {{a.addContentName}}&nbsp;&nbsp;[ {{ a.addContentPrice }}원 ]
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>할인사항</th>
                          <td class="col-9 text-start">
                            <div :key=i v-for="(d, i) in this.addDisInfo">
                              {{d.addDiscountName}}&nbsp;&nbsp;[ {{ d.addDiscountPrice }}원 ]
                            </div>
                          </td>
                        </tr>
                        <tr v-if="this.availPoint > 0">
                          <th>가용포인트 (<b class="text-primary fs-5">{{ this.getCurrencyFormat(this.availPoint) }}</b>)</th>
                          <td class="col-9 text-start">
                            <div class="input-group">
                              <input type="number" class="form-control text-end" ref="txt_payPoint" min="0" v-model="this.pointForPay">
                              <button type="button" class="btn btn-primary btn-sm me-1" @click="payByPoint();" v-if="this.eventInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                                <font style="font-size:small" class="align-bottom">사용</font>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="this.paidFlag == 'Y'">
                          <th>결제액</th>
                          <td class="col-9 text-start">
                            <div class="text-danger fs-5" v-if="this.eventInfo.reqStatus === 'RP' || this.eventInfo.reqStatus === 'CO' || this.eventInfo.reqStatus === 'CX'">
                              {{getCurrencyFormat(this.eventInfo.cstPrice_new)}}원
                              (
                                <font class="text-secondary fs-6">
                                  {{this.rtnRPflag_tmp.fName}} {{getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount)}} 원 + {{getCurrencyFormat(this.rtnRPflag_tmp.reqPayAmount - this.rtnRPflag_tmp.actualPayAmount)}}포인트
                                </font>
                              )
                            </div>
                            <div class="text-muted fs-6" v-else>
                              상담완료 후, 안내됩니다.
                            </div>
                          </td>
                        </tr>
                        <tr v-else>
                          <th>결제액</th>
                          <td class="col-9 text-start">
                            <div class="text-danger fs-5" v-if="this.eventInfo.reqStatus === 'RP' || this.eventInfo.reqStatus === 'CO' || this.eventInfo.reqStatus === 'CX'">
                              {{getCurrencyFormat(this.eventInfo.cstPrice_new)}} 원
                            </div>
                            <div class="text-muted fs-6" v-else>
                              상담완료 후, 안내됩니다.
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col d-grid" v-if="this.eventInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                    <div v-if="this.eventInfo.cstPrice_new > 0">
                      <button type="button" class="btn pay btn-md" @click="pgPay(this.eventCode);">
                        <b class="fs-5">예약결제</b>
                      </button>
                      <!-- <button type="button" class="paybtn btn" @click="kPay();">
                        <img src="../assets/img/payBtn/kakaopay.png">
                      </button>
                      <button type="button" class="paybtn btn" @click="tPay(this.eventCode, 'T');">
                        <img src="../assets/img/payBtn/tosspay.png">
                      </button> -->
                    </div>
                    <div v-else>
                      <button type="button" class="btn btn-lg btn-outline-success" @click="freePay(this.eventCode);">
                        <b class="fs-5">결제 (Free)</b>
                      </button>
                    </div>
                    <div class="mt-4">
                      계좌송금: &nbsp;<b>우리은행 1006-901-267484</b> (엔타비글로벌)
                    </div>
                  </div>
                  <div id="payment-widget" style="display:none;">></div>
                  <div id="agreement"></div>
                </div>
              </div>

              <div class="mb-0 row">
                <div class="col-auto d-grid p-1" v-if="this.ntCd != 'nonM'">
                  <button type="button" class="btn btn-lg btn-success" @click="goToList()">마이페이지</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
</div>  
</template>

<script>
//import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from 'axios';
import { getAvailPoint } from '../assets/js/commonFunctions.js';

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      eventInfo: [], //이벤트정보
      addItemInfo: [] , //추가사항
      addDisInfo: [], //추가할인정보
      obj_addItem: [], //신규추가사항
      obj_addItemSize: 0, //신규추가사항 사이즈
      obj_addDisItem: [], //신규추가할인사항
      obj_addDisItemSize: 0, //신규추가할인사항 사이즈

      cstPrice: 0, //행사금액
      cstPrice_new: 0, //행사금액(최신)
      cstPrice_org: 0, //행사금액(이전)

      //추가사항관련
      addItemUseFlag: "N", //추가사항사용여부
      addItemName: [],
      addItemPrice: [],
      vCnt: 0,
      
      addItemFlag: [], //기타옵션플래그

      //추가할인관련
      addDisName: [], //추가할인명
      addDisPrice: [], //추가할인금액
      dCnt: 0,
      addDisFlag: [], //추가할인 플래그

      //추가마진
      addDisUseFlag: "N", //추가할인사용여부
      addMargin: 0, //추가마진
      addMarginMethod: "P", //추가마진형태

      //포인트정보
      availPoint: 0, //가용포인트      
      paidPoint: 0, //사용포인트
      payPointFlag: "N", //포인트사용여부
      pointForPay: 0, //결제에 사용할 포인트
      payByPointFlag: "N", //포인트사용 플래그
      pointForPay_tmp: 0, //포인트 복구용 직전 사용예정 포인트
      rtnRPflag_tmp: [], //결제 후 포인트와 결제정보

      newComment: "", //최신코멘트

      //////// PNI정보
      pniList: [], //pni리스트
      pniLength: 0, //pni리스트길이

      passNameKor: [], //pni성인
      passNameEng: [],
      //passBirthDate: [],
      pBirthDate: [],
      passLicenseNo: [], //주민번호(여행자보험용)
      passNo: [], //여권번호
      //passExpDate: [], //여권만료일
      pExpDate: [], //여권만료일
      passFlag: [], //단수복수구분(S단수, D복수)
      pniTel: [],
      imgFlag: [], //여권사본등록여부(Y/N)
      priceType: [], //권종유형(A,C.I.D)

      nPniRemarks: "", //인적사항 특기사항(마스터 저장데이타)

      passUpFlag: "", //여권 업로드영역 호출여부
      enteredPassNo: "", //입력된 여권번호

      personCnt: 0, //총인원(화면상)

      areaFlag: "",

      cityList: {},
      nationList: {},
      areaList: {},
      areaList_tmp: {},
      transList: {},
      //tranList_via: {},
      mobilityList_tmp: {},
      mobilityList_dp: {}, //출발편
      mobilityList_rt: {}, //리턴편
      termList_dpd: {}, //출발시작터미널
      termList_dpa: {}, //출발도착터미널
      termList_rtd: {}, //리턴시작터미널
      termList_rta: {}, //리턴도착터미널

      //sRoute: {}, //상품구분

      termList_dp: {}, //출발터미널
      termList_rt: {}, //리턴국터미널
      oilSurcharge_dp: {}, //유류할증
      oilSurcharge_rt: {},
      termFee_dp: {}, //터미널이용료
      termFee_rt : {},
      termFeeNameList_dp: {}, //터미널이용료 명칭
      termFeeNameList_rt : {},

      //productTrans: {},
      evtTransInfo1: {}, //기존교통정보
      pdtTransInfo: {},
      //pdtPromoInfo: {}, //프로모션정보
      pdtOilSCInfo1: {}, //기존유류할증
      pdtTermFeeInfo_dp: {}, //기존터미널료
      pdtTermFeeInfo_rt: {},
      salesVolumeInfo: {}, //판매수량관리

      dTerm: "", //출발터미널
      rTerm: "", //리턴터미널
      dCur: "", //터미널이용료화폐
      rCur: "",

      pdtTermFeeInfo_dp_apply: {}, //적용터미널료(출발)
      pdtTermFeeInfo_rt_apply: {}, //적용터미널료(리턴)
      oilSCDp_apply: {}, //적용유류할증료(출발)
      oilSCRt_apply: {}, //적용유류할증료(리턴)

      journeyDate: [], //여정일자

      productInfo: {}, //교통용 예약정보
      insComment: "", //예약상담내용

      rtnRPflag: "N", //결제요청여부
      paidFlag: "N" //결제여부
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.eventCode = this.$route.query.eCode;
    this.ntCd = this.$route.query.ntCd;

    console.log("USER:",this.user);
    this.getEventInfo();

    //this.getReqProcLevel();

    this.getCityList();
    this.getNationList();
    this.getAreaList();
    this.getTransList();
    this.getMobilityList();    
    
    //this.getProductTransInfoExited(); //기존교통정보
    //this.getProductTransInfo();

    // this.getPdtOilSurchargeExited(); //기존상품 유류할증료
    // this.getPdtTerminalFeeExited(); //기존상품 유류할증료
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // if(document.referrer == "") 
    // {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //비회원 접근 시 대응처리
    if((this.ntCd != "nonM") && (!this.$store.state.user.email || this.$store.state.user.email == undefined)) 
    {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }
    // 로그인한 사람이 아니라면 메인화면으로
    // if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '',
    //     text: '로그인 권한이 없습니다.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'});
    // }
  },
  methods: {
    /** 카카오페이 처리로직 */
    async kPay()
    {
      //포인트처리
      let payByPoint = await this.$api("/api/payByPoint", {param: 
      [
        this.eventInfo.reqEmail,this.paidPoint,'S',this.eventCode,'사용대기'
      ]});
      if(Object.keys(payByPoint)[0] === 'error') 
      {
        this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
        return false;
      }
      else
      {
        // 성공 URL(핸들러등록)
        // const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay&${this.reserveInfo.reqReserveCode}`;
        const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay`;
        // 실패 or 취소 URL(핸들러등록)
        const failUrl = `${window.location.protocol}//${window.location.host}/fail_kPay`;

        let kakaoData = {
          'cid': '',
          'partner_order_id': this.eventInfo.eventCode,
          'partner_user_id': 'ntabi',
          'item_name': this.eventInfo.eventNameKor,
          'item_code': this.eventCode,
          'quantity': 1,
          'total_amount': this.eventInfo.cstPrice_new,
          'vat_amount': this.eventInfo.vatPrice,
          'tax_free_amount': this.eventInfo.cstPrice_new - this.eventInfo.vatPrice,
          'approval_url': successUrl,
          'fail_url': failUrl,
          'cancel_url': failUrl
        }

        //결제승인처리를 Success_kPay로 이동(20230321)
        try
        {
          //const response = await this.$api("api/kakaoPay", { param: kakaoData });
          const response = await axios.post('/api/kakaoPay', { param: kakaoData });
          const tid = response.data.tid;

          localStorage.setItem('tid', tid)
          localStorage.setItem('partner_order_id',kakaoData.partner_order_id)
          localStorage.setItem('total_amount',kakaoData.total_amount)

          const nextUrl = response.data.next_redirect_pc_url;
          window.location.href = nextUrl;
        }
        catch (error)
        {
          console.log(error);
        }
      }
    },

    //토스페이먼츠결제(PG)
    async pgPay(rNo)
    {
      ///////  위젯방식
      //const clientKey = 'test_gck_E92LAa5PVbJpRg9mpLyBV7YmpXyJ';           //For Test(wiget)
      //const clientKey = 'live_gck_26DlbXAaV06Kn2546A9K8qY50Q9R';             //For Real(wiget)

      ///////  Api방식
      //const clientKey = 'test_ck_YyZqmkKeP8gG2Klanln8bQRxB9lG';         //For Test(api)
      const clientKey = 'live_ck_jZ61JOxRQVEZXa1aoR0rW0X9bAqw';         //For Real(api)


      //고객ID-임의생성가능
      const customerKey = 'Ntabi-1936=@';

      // 결제위젯 객체 초기화
      const paymentWidget = window.PaymentWidget(clientKey, customerKey); // 회원 결제
      // 비회원 결제를 하고 싶다면 아래 코드로 결제위젯 객체를 초기화하세요.
      // const paymentWidget = PaymentWidget(clientKey, PaymentWidget.ANONYMOUS); // 비회원 결제

      //결제수단 랜더링
      paymentWidget.renderPaymentMethods('#payment-widget', this.eventInfo.cstPrice_new);

      //localStorage.setItem('partner_order_id',kakaoData.partner_order_id)
      localStorage.setItem('total_amount',this.eventInfo.cstPrice_new)      

      //결제창 호출
      paymentWidget.requestPayment({
        orderId: rNo,
        orderName: this.eventInfo.eventNameKor,
        // successUrl: 'http://localhost:8080/success_pg',
        // failUrl: 'http://localhost:8080/fail_pg',

        successUrl: 'https://ntabi.co.kr/success_pg',
        failUrl: 'https://ntabi.co.kr/fail_pg',

        // successUrl: 'http://test.somen.co.kr:8088/success_pg',
        // failUrl: 'http://test.somen.co.kr:8088/fail_pg',
        customerEmail: this.eventInfo.conEmail,
        customerName: this.eventInfo.reqName,
        taxFreeAmount: this.eventInfo.cstPrice_new,
      })
    },


    /** 토스페이 처리로직: 간편결제(미사용) */
    async tPay(param, value)
    {
      if(value === "T") 
      {
        //포인트처리
        let payByPoint = await this.$api("/api/payByPoint", {param: 
        [
          this.eventInfo.reqEmail,this.paidPoint,'S',this.eventCode,'사용대기'
        ]});
        if(Object.keys(payByPoint)[0] === 'error') 
        {
          this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
          return false;
        }
        else
        {
          let tossData = {
          orderId: param,
          amount: this.eventInfo.cstPrice_new,
          amountTaxFree: this.eventInfo.cstPrice_new,
          //orderName: this.productInfo.pdtNameKor,
          productDesc: this.eventInfo.eventNameKor,
          autoExecute: true,
          // retUrl: "http://localhost:8080/success?amount="+this.eventInfo.cstPrice_new,
          // retCancelUrl: "http://localhost:8080/fail",
          // resultCallback: "http://ntabi.co.kr/callback",
          // retUrl: "http://test.somen.co.kr:8088/success?amount="+this.eventInfo.cstPrice_new,
          // retCancelUrl: "http://test.somen.co.kr:8088/fail",
          // resultCallback: "http://ntabi.co.kr/callback",
          retUrl: "https://ntabi.co.kr/success?amount="+this.eventInfo.cstPrice_new,
          retCancelUrl: "https://ntabi.co.kr/fail",
          resultCallback: "https://ntabi.co.kr/callback",          
          }
        
        // connect with api on backend
          this.$api("api/tossPay/start", 
          {
            param: tossData
          })
          .then((res) => {
            console.log(res);
            window.location.href = res.checkoutPage;
          })
          .catch((err) => {
            console.error(err);
          });
        }
      }
    },

    /** 윈도우즈 계산기호출*/
    callWinCalc() 
    { 
      window.open('Calculator: ');
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },

    //당해 이벤트 정보
    async getEventInfo() {
      let eventInfo = await this.$api("/api/getEventInfo", {param:[this.eventCode]});
      if(eventInfo.length > 0) {
        this.eventInfo = eventInfo[0];
        this.eventInfo.conEmail = 
          (this.eventInfo.conEmail == "undefined" || this.eventInfo.conEmail == null) ? this.eventInfo.reqEmail : this.eventInfo.conEmail;
        this.eventInfo.evtStartDate = this.getDateFormat(this.eventInfo.evtStartDate);
        this.eventInfo.evtCloseDate = this.getDateFormat(this.eventInfo.evtCloseDate);
      }
      //console.log("eventInfo:",this.eventInfo);

      //행사추가사항
      let addItemInfo = await this.$api("/api/getEventAddContent", {param:[this.eventCode]});
      if(addItemInfo.length > 0) 
      {
        this.addItemInfo = addItemInfo;
      }
      //console.log("addItemInfo:",this.addItemInfo);

      //행사추가할인
      let addDisInfo = await this.$api("/api/getEventDiscount", {param:[this.eventCode]});
      if(addDisInfo.length > 0)
      {
        this.addDisInfo = addDisInfo;
      }
      //console.log("addDisInfo:",this.addDisInfo);

      this.getAvailPoint();
      // this.getPniList();

      //교통편정보
      if(this.eventInfo.evtTransFlag === "Y")
      {
        this.getProductTransInfoExisted();
      }
      
      //결제요청 여부
      this.getReqPayInfo(this.eventCode);
    },
    async getReqPayInfo(val)
    {
      let rtnRPflag_tmp = await this.$api("/api/getReqPayInfo", {param: [val]});

      if(rtnRPflag_tmp.length > 0) 
      {
        this.rtnRPflag_tmp = rtnRPflag_tmp[0];
        
        this.paidFlag = rtnRPflag_tmp[0].checkFlag

        this.rtnRPflag = (rtnRPflag_tmp.length > 0) ? "Y" : "N";
      }

      //console.log("rtnRPflag:",this.rtnRPflag);
    },
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      if(cityList.length > 0) {
        this.cityList = cityList;
      }
    },    
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
    },

    async getAreaList() {
      //this.areaList_tmp = await this.$api("/api/areaList", {});
      let areaList = await this.$api("/api/areaList", {});
      this.areaList = areaList;
    },

    /////////////상담가격계산(터미널이용료/유류할증료 별도표시)
    /** 여정기간 중 상품가격합산식 */
    calcCstPrice() 
    {
      //alert("Calc!");
      let aduPrice = 0;
      let chiPrice = 0;
      let infPrice = 0;
      let disPrice = 0;
      let pdtPrice = 0;

      //여행참가인원수
      let personCnt = (this.eventInfo.adultCnt*1 + this.eventInfo.childCnt*1 + this.eventInfo.infCnt*1 + this.eventInfo.discountCnt*1);
      this.personCnt = personCnt*1;
      //console.log("personCnt:",personCnt);

      //1. 권종수 기준가격 계산
      aduPrice = (this.eventInfo.adultPrice*1) * (this.eventInfo.adultCnt*1);
      chiPrice = (this.eventInfo.childPrice*1) * (this.eventInfo.childCnt*1);
      infPrice = (this.eventInfo.infPrice*1) * (this.eventInfo.infCnt*1);
      disPrice = (this.eventInfo.discountPrice*1) * (this.eventInfo.discountCnt*1);
      pdtPrice = (aduPrice*1) + (chiPrice*1) + (infPrice*1) + (disPrice*1);
      //console.log("pdtPrice1:",pdtPrice);

      //2. 추가사항적용
      ////기존 추가사항처리
      if(this.addItemInfo.length > 0)
      {
        let i = 0;
        //let testPrice = 0;
        while(i < this.addItemInfo.length)
        {
          if(this.addItemInfo[i].addContentFlag === "Y")
          {
            pdtPrice = pdtPrice*1 + (this.addItemInfo[i].addContentPrice*1);
            //testPrice += (this.addItemInfo[i].addContentPrice*1);
          }
          i++;
        }
      }
      console.log("pdtPriceAddItem:",pdtPrice);

      if(this.addItemName.length > 0) // 추가사항이 있는 경우
      {
        this.addItemUseFlag = 'Y';
        let arr_addItemName = [];
        let arr_addItemPrice = [];
        let arr_addItemFlag = [];
        let j = 1;

        while(j < this.addItemName.length)
        {
          arr_addItemName.push(this.addItemName[j]);
          arr_addItemPrice.push(this.addItemPrice[j]);
          arr_addItemFlag.push(this.addItemFlag[j]);
          j++;
        }
        //console.log("addItemName:",arr_addItemName,"addItemPrice:",arr_addItemPrice,"addItemFlag:",arr_addItemFlag);
        
        //추가사항 리스트
        let obj_addItem = new Object();
        obj_addItem.addItemName = arr_addItemName;
        obj_addItem.addItemPrice = arr_addItemPrice;
        obj_addItem.addItemFlag = arr_addItemFlag;
        //console.log("obj_addItem:",obj_addItem);
        this.obj_addItem = obj_addItem;

        let obj_addItemSize = Object.keys(obj_addItem).length;
        this.obj_addItemSize = obj_addItemSize;

        let k = 0;
        while(k<obj_addItemSize)
        {
          if(obj_addItem.addItemFlag[k] === "Y")
          {
            pdtPrice += (obj_addItem.addItemPrice[k]*1);
          }
          k++;
        }
        //pdtPrice = aduPrice*1 + chiPrice*1 + infPrice*1 + disPrice*1;
        //console.log("pdtPrice2:",pdtPrice);
      }

      //3.추가마진 적용
      //let margin = 0;
      let pdtPriceMargin = 0;
      if(this.eventInfo.addMarginMethod ==="A")
      {
        //pdtPrice = (pdtPrice) + (this.productInfo.basicMargin*1);
        pdtPriceMargin = this.eventInfo.addMargin*1
      }
      else
      {
        if(this.eventInfo.addMargin*1 > 0)
        {
          //pdtPrice = pdtPrice + (pdtPrice * (this.productInfo.basicMargin/100));
          pdtPriceMargin = (pdtPrice*1) * ((this.eventInfo.addMargin*1)/100);
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
        else
        {
          //pdtPrice = pdtPrice + 0;
          pdtPriceMargin = 0;
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
      }
      this.pdtMargin = pdtPriceMargin*1;
      pdtPrice = pdtPrice*1 + pdtPriceMargin*1;
      //console.log("priceMargin:",pdtPrice);

      //4. 추가할인적용
      ////기존 추가할인처리
      if(this.addDisInfo.length > 0)
      {
        let i = 0;
        //let testPrice = 0;
        while(i < this.addDisInfo.length)
        {
          if(this.addDisInfo[i].addDiscountFlag === "Y")
          {
            pdtPrice = pdtPrice*1 - (this.addDisInfo[i].addDiscountPrice*1);
            //testPrice += (this.addItemInfo[i].addContentPrice*1);
          }
          i++;
        }
      }
      //console.log("pdtPriceAddDiscount:",pdtPrice*1);
      
      if(this.addDisName.length > 0) // 추가할인이 있는 경우
      {
        this.addDisUseFlag = 'Y'; // 추가할인사용여부
        let arr_addDisName = [];
        let arr_addDisPrice = [];
        let arr_addDisFlag = [];
        let j = 1;

        while(j < this.addDisName.length)
        {
          arr_addDisName.push(this.addDisName[j]);
          arr_addDisPrice.push(this.addDisPrice[j]);
          arr_addDisFlag.push(this.addDisFlag[j]);
          j++;
        }
        //console.log("addItemName:",arr_addItemName,"addItemPrice:",arr_addItemPrice,"addItemFlag:",arr_addItemFlag);
        
        //추가사항 리스트
        let obj_addDisItem = new Object();
        obj_addDisItem.addDisName = arr_addDisName;
        obj_addDisItem.addDisPrice = arr_addDisPrice;
        obj_addDisItem.addDisFlag = arr_addDisFlag;

        this.obj_addDisItem = obj_addDisItem;

        let obj_addDisItemSize = Object.keys(obj_addDisItem).length;
        this.obj_addDisItemSize = obj_addDisItemSize;

        let k = 0;
        while(k<obj_addDisItemSize)
        {
          if(obj_addDisItem.addDisFlag[k] === "Y")
          {
            pdtPrice -= (obj_addDisItem.addDisPrice[k]*1);
          }
          k++;
        }
      }
      
      //5.PayPoint처리
      if(this.eventInfo.payPoint > 0)
      {
        pdtPrice = pdtPrice*1 - (this.eventInfo.payPoint*1);
      }

      //6.부가세 적용
      let vatR = 0;
      if(this.eventInfo.vatPrice > 0) 
      {
        vatR = (pdtPrice*1) * (this.eventInfo.vatPrice*1/100);
      }
      else
      {
        vatR = 0;
      }
      //this.vatPrice = vatR;
      pdtPrice = pdtPrice*1 + vatR*1;

      //7.소숫점아래 절사처리
      pdtPrice = Math.floor(pdtPrice);

      this.cstPrice = pdtPrice*1;
    },
    //가용포인트 확인
    async getAvailPoint()
    {
      this.availPoint = await getAvailPoint(this.$api, this.eventInfo.reqEmail)
    },
    // 가용포인트 사용처리
    payByPoint()
    {
      if(this.payByPointFlag === 'Y')
      {
        this.$swal.fire("","이전에 입력한 사용포인트가 있어 재계산을 합니다.<br>사용할 포인트를 다시 입력해주세요.","warning")
        this.availPoint = this.availPoint*1 + this.pointForPay_tmp*1
        this.eventInfo.cstPrice_new = this.eventInfo.cstPrice_new*1 + this.pointForPay_tmp*1
        this.pointForPay = 0;
        this.payByPointFlag = 'N'
        return false
      }
      else
      {
        if(this.pointForPay <= 0)
        {
          this.$swal.fire("","사용하는 포인트는 0보다 커야 합니다.","warning")
          this.pointForPay = 0;
          return false
        }
        else if(this.pointForPay > this.availPoint)
        {
          this.$swal.fire("","사용하려는 포인트가 가용포인트보다 큽니다.","warning")
          this.pointForPay = 0;
          return false
        }
        else if(this.pointForPay > this.eventInfo.cstPrice_new)
        {
          this.$swal.fire("","사용하려는 포인트는 결제금액보다 클 수 없습니다.","warning")
          this.pointForPay = 0;
          return false
        }
        else
        {
          this.$swal.fire({
          icon: 'question',
          text: '포인트를 사용하시겠습니까?',
          showCancelButton: true,
          confirmButtonText: '예',
          cancelButtonText: '아니요'
          }).then(async (result) => 
          {
            if (result.isConfirmed) 
            {
              let amtAfterPayingPoint =  this.eventInfo.cstPrice_new*1 - this.pointForPay*1

              if(amtAfterPayingPoint > 0) //일부결제
              {
                this.paidPoint = this.pointForPay //paidPoint => standby상태로 처리
                this.availPoint = this.availPoint - this.pointForPay
                this.eventInfo.cstPrice_new = amtAfterPayingPoint;
                this.pointForPay_tmp = this.pointForPay
                //this.paymentInFull = "Y" //완납여부
              }
              else //완납
              {
                this.paidPoint = this.eventInfo.cstPrice_new
                this.availPoint = this.availPoint - this.eventInfo.cstPrice_new
                this.eventInfo.cstPrice_new = 0
                this.pointForPay_tmp = this.pointForPay
                this.paymentInFull = "Y" //완납여부
              }
            }
          })
        }
      }
      this.payByPointFlag = (this.payByPointFlag == 'Y') ? 'N' : 'Y'
    },
    
    //행사 교통정보(기존)
    async getProductTransInfoExisted() {
      let evtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.eventCode]});
      if(evtTransInfo1.length > 0) {
        this.evtTransInfo1 = evtTransInfo1[0];
      }
    },
    //행사 교통정보(신규)
    async getProductTransInfo() {
      let pdtTransInfo = await this.$api("/api/pdtTransInfo", {param: [this.eventCode]});
      if(this.pdtTransInfo.length > 0){
        this.pdtTransInfo = pdtTransInfo[0];
      }
      this.pageOpen();
      //this.getAddRoute_dp()
    },
    //넘어온 파라메타로 교통편표시
    async pageOpen(){
      this.getAreaList_pageOpen()
      this.viewDpMobilityList_pageOpen();
        this.viewRtMobilityList_pageOpen();
        this.getDpTermList_pageOpen();
        this.getRtTermList_pageOpen();      
    },
    //페이지 오픈시 교통정보 호출
    async getAreaList_pageOpen() {
      //console.log("productInfo:",this.productInfo);
      let areaList1 = await this.$api("/api/areaList", {});
      this.areaList = areaList1.filter(e => {return e.categoryCode === this.ntCd});
    },
    async viewDpMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_dp = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd1});
    },
    async viewRtMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_rt = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd2});
    },
    async getDpTermList_pageOpen() {
      let param4 = "";
      if(this.pdtTransInfo.trCd1 === "SHP") {
        param4 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd1 === "FLT") {
        param4 = "apCd";
      }
      else {
        param4 = "";
      }
      //console.log("param4:",param4);
      if(param4 === "ptCd" || param4 === "apCd") {
        this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
        //출발도착터미널
        this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", this.eventInfo.ntCd, param4]});
      }
      else{
        this.termList_dpd = "";
        this.termList_dpa = "";
      }
    },

    async getRtTermList_pageOpen() {
      let param5 = "";
      if(this.pdtTransInfo.trCd2 === "SHP") {
        param5 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd2 === "FLT") {
        param5 = "apCd";
      }
      else {
        param5 = "";
      }
      //this.termList_rtd = await this.$api("/api/termList", {param: [this.productInfo.ntCd, param5]});
      if(param5 === "ptCd" || param5 === "apCd") {
        this.termList_rtd = await this.$api("/api/termList", {param: [this.eventInfo.ntCd, param5]});
        this.termList_rta = await this.$api("/api/termList1", {param: ["KR", this.eventInfo.ntCd, param5]});
      }
    },


    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
    },
    changeTransList() {
      this.viewMobilityList(this.productInfo.trCd);
    },
    viewMobilityList(param2){
      this.mobilityList = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
    },
    changeMobilityList() {
      this.getTermList(this.productInfo.ntCd, this.productInfo.trCd);
    },
    async getTermList(param3, param4) {
      let param5 = (param4 === "SHP") ? "ptCd" : "apCd"
      this.termList_dp = await this.$api("/api/termList", {param: ['KR', param5]});
      this.termList_rt = await this.$api("/api/termList", {param: [param3, param5]});
    },

    //교통수단변경->교통편획득
    changeDpTransList() {
      this.pdtTransInfo.mbCd1 = "";
      this.pdtTransInfo.dpdCd = "";
      this.pdtTransInfo.dpaCd = "";
      this.viewDpMobilityList(this.pdtTransInfo.trCd1);
    },
    changeRtTransList() {
      this.pdtTransInfo.mbCd2 = "";
      this.pdtTransInfo.rtdCd = "";
      this.pdtTransInfo.rtaCd = "";
      this.viewRtMobilityList(this.pdtTransInfo.trCd2);
    },
    viewDpMobilityList(param1){
      this.mobilityList_dp = this.mobilityList_tmp.filter(e => {return e.categoryCode === param1});
    },
    viewRtMobilityList(param2){
      this.mobilityList_rt = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
    },
    changeDpMobilityList() {
      this.getDpTermList(this.eventInfo.ntCd, this.pdtTransInfo.trCd1);
    },
    changeRtMobilityList() {
      this.getRtTermList(this.eventInfo.ntCd, this.pdtTransInfo.trCd2);
    },
    async getDpTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
      //출발도착터미널
      this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
    },
    async getRtTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      this.termList_rtd = await this.$api("/api/termList", {param: [param2, param4]});
      this.termList_rta = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
    },
    //수정영역 표시(비표시)
    showArea(param) {
      this.areaFlag = (this.areaFlag === param) ? "N"+param : param;
      if(this.areaFlag === "NT") {
        this.pdtTransInfo.trCd1 = "";
        this.pdtTransInfo.mbCd1 = "";
        this.pdtTransInfo.dpdCd = "";
        this.pdtTransInfo.dpaCd = "";
        this.pdtTransInfo.transRemarks_dp = "";
        this.pdtTransInfo.oilCd_dp = "";
        this.pdtTransInfo.termCd_dp = "";
        
        this.pdtTransInfo.trCd2 = "";
        this.pdtTransInfo.mbCd2 = "";
        this.pdtTransInfo.rtdCd = "";
        this.pdtTransInfo.rtaCd = "";
        this.pdtTransInfo.transRemarks_ar = "";
        this.pdtTransInfo.oilCd_rt = "";
        this.pdtTransInfo.termCd_rt = "";
      }
      else if(this.areaFlag === "NP") {
        alert("NP/");
      }
      this.getProductTransInfo();      
    },
    async getEtcFeeInfo(p1,p2,p3,p4) {
      console.log(p1, p2, p3, p4)
      let drFlag = p1;
      let liCd = (this.eventInfo.ntCd === "KR") ? "L" : "G"
      let trCd = p2;
      let mbCd = p3;
      let tmCd = p4

      //유류할증료
      this.getOilFeeInfo(drFlag, liCd, trCd, mbCd);
      //터미널이용료
      this.getTermFeeInfo(drFlag, tmCd);
    },
    async getOilFeeInfo(f,o1,o2,o3){
      if(f === "D") {
        //츨발편 유류할증료
        let oilSurcharge_dp = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,'KR']});
        this.oilSurcharge_dp = oilSurcharge_dp;
      }
      else if(f === "R") {
        //리턴편 유류할증료
        let oilSurcharge_rt = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,this.productInfo.ntCd]});
        this.oilSurcharge_rt = oilSurcharge_rt;
      }
    },
    async getTermFeeInfo(f,t1){
      if(f === "D") {
        let termFeeNameList_dp = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_dp = termFeeNameList_dp;
        //console.log("termFeeNameList_dp",this.termFeeNameList_dp);
      }
      else if(f === "R") {
        let termFeeNameList_rt = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_rt = termFeeNameList_rt;
        //console.log("termFeeNameList_rt",this .termFeeNameList_rt);
      }
    },
    async changeTermFeeNameList(f,t1){
      if(f === "D") {
        let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_dp = termFee_dp;
        //console.log("termFee_dp",this.termFee_dp);
      }
      else if(f === "R") {
        let termFee_rt = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_rt = termFee_rt;
      }
    },
    /** 두날짜 사이의 날짜추출함수 */
    getDatesStartToLast(startDate, lastDate) {
      if(!(startDate && lastDate)) return "Not Date Format";

      var result = [];
      var curDate = new Date(startDate);
      while(curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    }, 
    //적용 터미널이용료
    async getPdtTerminalFee_apply() {
      let pdtTermFeeInfo_dp_apply = await this.$api("/api/pdtTerminalFeeD_apply", {param: [this.eventCode, this.reserveInfo.reqDptDate]});
      if(pdtTermFeeInfo_dp_apply.length > 0) {
        this.pdtTermFeeInfo_dp_apply = pdtTermFeeInfo_dp_apply;
        this.dTerm = pdtTermFeeInfo_dp_apply[0].dTerm;
        this.dCur = pdtTermFeeInfo_dp_apply[0].dCur;
      }
      let pdtTermFeeInfo_rt_apply = await this.$api("/api/pdtTerminalFeeR_apply", {param: [this.eventCode, this.reserveInfo.reqRtnDate]});
      if(pdtTermFeeInfo_rt_apply.length > 0) {
        this.pdtTermFeeInfo_rt_apply = pdtTermFeeInfo_rt_apply;
        this.rTerm = pdtTermFeeInfo_rt_apply[0].rTerm;
        this.rCur = pdtTermFeeInfo_rt_apply[0].rCur;
      }
    },
    //적용 유류할증료
    async getPdtOilSurcharge_apply() {
      //적용가능 유류비 체크
      let oilSCDp_apply = await this.$api("/api/pdtOilSurchage_apply", {param: [this.pdtOilSCInfo1.oilCd_dp, this.reserveInfo.reqDptDate]});
      if(oilSCDp_apply.length > 0) {
        this.oilSCDp_apply = oilSCDp_apply[0];
      }
      let oilSCRt_apply = await this.$api("/api/pdtOilSurchage_apply", {param: [this.pdtOilSCInfo1.oilCd_rt, this.reserveInfo.reqRtnDate]});
      if(oilSCRt_apply.length > 0) {
        this.oilSCRt_apply = oilSCRt_apply[0];
      }
    },    

    //기존상품 유류할증료
    async getPdtOilSurchargeExited() {
      let pdtOilSCInfo1 = await this.$api("/api/pdtOilSurchage_existed", {param: [this.eventCode]});
      if(pdtOilSCInfo1.length > 0) {
        this.pdtOilSCInfo1 = pdtOilSCInfo1[0];
      }
    },
    //기존상품 터미널이용료
    async getPdtTerminalFeeExited() {
      let pdtTermFeeInfo_dp = await this.$api("/api/pdtTerminalFeeD_existed", {param: [this.eventCode]});
      if(pdtTermFeeInfo_dp.length > 0) {
        this.pdtTermFeeInfo_dp = pdtTermFeeInfo_dp;
        this.dTerm = pdtTermFeeInfo_dp[0].dTerm;
        this.dCur = pdtTermFeeInfo_dp[0].dCur;
      }
      let pdtTermFeeInfo_rt = await this.$api("/api/pdtTerminalFeeR_existed", {param: [this.eventCode]});
      if(pdtTermFeeInfo_rt.length > 0) {
        this.pdtTermFeeInfo_rt = pdtTermFeeInfo_rt;
        this.rTerm = pdtTermFeeInfo_rt[0].rTerm;
        this.rCur = pdtTermFeeInfo_rt[0].rCur;
      }
    },

    goToList() {
      //this.$router.push({path: '/reserveInfoList_client'});
      this.$router.push({path: '/myPage'});
    },


    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },

    dataBaseTransaction(p1, p2)
    {
      if(p1==="U" && p2==="E") 
      { //행사내용 수정처리
        this.$swal.fire({
        icon: 'question',
        text: '행사내용을 저장합니까?',
        showCancelButton: true,
        confirmButtonText: '저장',
        cancelButtonText: '취소'
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            //행사예약취소의 경우
            if(this.eventInfo.reqStatus === "CX")
            {
              this.$swal.fire({
                icon: 'question',
                text: '행사예약을 취소합니까?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              }).then(async (result) => 
              {
                if (result.isConfirmed) 
                {
                  //사용포인트 복구처리
                  if(this.eventInfo.payPoint > 0)
                  {
                    let payByPoint = await this.$api("/api/payByPoint", {param: [
                      this.eventInfo.reqEmail,this.eventInfo.payPoint,'A',this.eventCode,'행사취소'
                    ]});

                    if(Object.keys(payByPoint)[0] === 'error') 
                    {
                      this.$swal.fire('', '포인트 복구처리 중 오류가 발생하였습니다.<br>관리자에게 문의해 주세요.', 'error');
                      return false;
                    }
                    if(this.rtnRPflag === "Y")
                    {
                      this.updReqPay();
                    }
                    else
                    {
                      this.insReqPay();
                    }
                  }
                  let today = new Date();
                  let yyyy = today.getFullYear();
                  let mm_tmp = today.getMonth()+1;
                  let dd_tmp = today.getDate();
                  let hour = today.getHours();
                  let min = today.getMinutes();
                  let sec = today.getSeconds();
                  
                  let mm = "";
                  if(mm_tmp < 10) {mm = "0" + mm_tmp}
                  else mm = mm_tmp;
                  let dd = "";
                  if(dd_tmp < 10) {dd = "0" + dd_tmp}
                  else dd = dd_tmp;
                  
                  let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
                  let oldComment = ""; 
                  let newComment = "";
                  oldComment = this.eventInfo.eventComent;
                  newComment = "취소처리";
                  let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newComment;
                  let cxl_event_m = await this.$api("/api/cxl_event_m", {param: [
                    this.eventInfo.reqStatus,this.user.handlerId,insComment,
                    this.eventCode
                  ]});
                  if(cxl_event_m.affectedRows < 0 || cxl_event_m.affectedRows === undefined)
                  {
                    this.$swal.fire('', '행사예약취소(cxl_event_m) 중 중 오류가 발생하였습니다.', 'error');
                    return false;
                  }   
                  else
                  {
                    this.$swal.fire('', '행사예약이 취소되었습니다.', 'success');
                    location.reload();
                  }
                }
              })
            }
            //행사상태-취소이외
            else
            {
              if(this.cstPrice =="0")
              {
                this.$swal.fire("","상품가격 재계산을 하십시오,","warning");
                return false;
              }

              if(this.eventInfo.reqStatus === "RP")
              {
                if(this.rtnRPflag === "Y")
                {
                  this.updReqPay();
                }
                else
                {
                  this.insReqPay();
                }                
              }

              //포인트 결제가 있으면 처리한다(>0)
              if(this.paidPoint > 0)
              {
                let payByPoint = await this.$api("/api/payByPoint", {param: [
                  this.eventInfo.reqEmail,this.paidPoint,'U',this.eventCode,''
                ]});
                if(Object.keys(payByPoint)[0] === 'error') 
                {
                  this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다.<br>관리자에  문의해 주세요.', 'error');
                  return false;
                }
              }
              // this.eventIfno.cstPrice_org = this.cstPrice;
              this.eventInfo.cstPrice_new = this.cstPrice;
              //let timeStamp = "";
              let today = new Date();
              let yyyy = today.getFullYear();
              let mm_tmp = today.getMonth()+1;
              let dd_tmp = today.getDate();
              let hour = today.getHours();
              let min = today.getMinutes();
              let sec = today.getSeconds();
              
              let mm = "";
              if(mm_tmp < 10) {mm = "0" + mm_tmp}
              else mm = mm_tmp;

              let dd = "";
              if(dd_tmp < 10) {dd = "0" + dd_tmp}
              else dd = dd_tmp;
              
              let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;

              let oldComment = ""; 
              //let newComment = "";
              oldComment = this.eventInfo.eventComent;
              let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ this.newComment;
              //console.log("insComment:",insComment);

              if(this.eventInfo.payPoint > 0)
              {
                this.paidPoint = this.eventInfo.payPoint;
              }

              let updEvent_m = await this.$api("/api/updEvent_m", {param: 
                [
                  this.eventInfo.eventNameKor, this.eventInfo.eventNameEng, this.eventInfo.reqName, this.eventInfo.reqTelNo, this.eventInfo.reqEmail, this.eventInfo.dptCtCode, this.eventInfo.ntCd, this.eventInfo.arCd, this.eventInfo.evtStartDate, this.eventInfo.evtCloseDate, this.eventInfo.includeContent, this.eventInfo.notIncludeContent, this.eventInfo.adultCnt, this.eventInfo.childCnt, this.eventInfo.infCnt, this.eventInfo.discountCnt, this.eventInfo.adultPrice, this.eventInfo.childPrice, this.eventInfo.infPrice, this.eventInfo.discountPrice, this.eventInfo.addItemUseFlag, this.eventInfo.addMargin, this.eventInfo.addMarginMethod, this.eventInfo.addDisUseFlag, this.eventInfo.cstPrice_new, this.eventInfo.cstPrice_org, this.eventInfo.vatPrice, this.paidPoint, insComment, this.eventInfo.evtTransFlag, this.eventInfo.reqStatus, this.eventInfo.pniUseFlag, this.user.handlerId, this.eventInfo.settleFlag, this.eventInfo.salesStopflag, this.eventCode
                ]
              });
              if(updEvent_m.affectedRows < 0 || updEvent_m.affectedRows === undefined)
                {
                  this.$swal.fire('', '행사저장(updEvent_m) 중 오류가 발생하였습니다.', 'error');
                  return false;
                }
              else
              {
                //기존추가사항 업데이트
                if(this.addItemInfo.length > 0)
                {
                  let i = 0;
                  let res_updAddItemInfo = "";
                  while(i < this.addItemInfo.length)
                  {
                    let updAddItemInfo = await this.$api("/api/updAddItemInfo", {param: 
                      [
                        this.addItemInfo[i].addContentName, this.addItemInfo[i].addContentPrice, this.addItemInfo[i].addContentFlag, this.user.handlerId,this.eventCode, this.addItemInfo[i].subNo
                      ]
                    });
                    if(updAddItemInfo.affectedRows < 0 || updAddItemInfo.affectedRows === undefined)
                    {
                      res_updAddItemInfo = "error";
                      break;
                    }
                    else
                    {
                      res_updAddItemInfo = "ok";
                    }
                    i++;
                  }
                  if(res_updAddItemInfo == "error")
                  {
                    this.$swal.fire('', '오류발생(updAddItemInfo)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                }
                //신규추가사항 존재시 등록
                if(this.addItemUseFlag === "Y")
                {
                  console.log("obj_addItem_check:",this.obj_addItem);
                  let addNo = (this.addItemInfo.length > 0) ? this.addItemInfo.length : 0;
                  //console.log("addNo:",addNo, "addItemUSeFlag:",this.addItemUseFlag);

                  let k = 0;
                  let res_addItem = "";
                  while(k<this.obj_addItemSize)
                  {
                    console.log("addNo_k:",addNo*1 + k);

                    let insAddEventItem = await this.$api("/api/insAddEventItem", {param: 
                      [
                        this.eventCode, (addNo*1) + k , this.obj_addItem.addItemName[k], this.obj_addItem.addItemPrice[k], this.obj_addItem.addItemFlag[k], this.user.handlerId
                      ]
                    });
                    console.log("insAddEventItem:",insAddEventItem[k]);
                    if(Object.keys(insAddEventItem)[0] === 'error')
                    {
                      res_addItem = "error";
                      //console.log(res_addItem)
                      break;
                    }
                    else
                    {
                      res_addItem = "ok";
                    }
                    k++;
                  }
                  if(res_addItem === "error")
                  {
                    this.$swal.fire('', '오류발생(insAddEventIetm)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                }

                //기존추가할인 업데이트
                if(this.addDisInfo.length > 0)
                {
                  let i = 0;
                  let res_updAddDisInfo = "";
                  while(i < this.addDisInfo.length)
                  {
                    let updAddDisInfo = await this.$api("/api/updAddDisInfo", {param: 
                      [
                        this.addDisInfo[i].addDiscountName, this.addDisInfo[i].addDiscountPrice, this.addDisInfo[i].addDiscountFlag, this.user.handlerId,this.eventCode, this.addDisInfo[i].subNo
                      ]
                    });
                    if(updAddDisInfo.affectedRows < 0 || updAddDisInfo.affectedRows === undefined)
                    {
                      res_updAddDisInfo = "error";
                      break;
                    }
                    else
                    {
                      res_updAddDisInfo = "ok";
                    }
                    i++;
                  }
                  if(res_updAddDisInfo == "error")
                  {
                    this.$swal.fire('', '오류발생(updAddItemInfo)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                }
                //추가할인 존재시 처리
                if(this.addDisUseFlag === "Y")
                {
                  //console.log("obj_addDisItem:",this.obj_addDisItem);
                  let addNo = (this.addDisInfo.length > 0) ? this.addDisInfo.length : 0;

                  let m = 0;
                  let res_addDis = "";
                  while(m<this.obj_addDisItemSize)
                  {
                    let insAddDiscountItem = await this.$api("/api/insAddDiscountItem", {param: 
                      [
                        this.eventCode, (addNo*1) + m, this.obj_addDisItem.addDisName[m], this.obj_addDisItem.addDisPrice[m], this.obj_addDisItem.addDisFlag[m], this.user.handlerId
                      ]
                    });
                    if(Object.keys(insAddDiscountItem)[0] === 'error')
                    {
                      res_addDis = "error";
                      break;
                    }
                    else
                    {
                      res_addDis = "ok";
                    }
                    m++;
                  }
                  if(res_addDis === "error")
                  {
                    this.$swal.fire('', '오류발생(insAddEventIetm)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                  // else
                  // {
                  //   this.$swal.fire('', '행사상품 저장이 완료되었습니다', 'success')
                  //   location.reload();
                  // }
                }
              }
              this.$swal.fire('', '행사상품 저장이 완료되었습니다', 'success')
              location.reload();              
            }
          }
        })
      }

      //PNI등록
      else if(p1==="U" && p2==="P") 
      {
        //console.log("eventCode:",this.eventCode);
        this.$swal.fire({
        icon: 'question',
        text: '행사참가인원을 등록(변경)합니까?',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            if(this.eventCode == undefined || this.eventCode == "" || this.eventCode == null) 
            {
              this.$swal.fire('', '행사코드가 존재하지 않습니다.<br> 우선 행사를 생성해주십시오.', 'warning');
              return false;          
            }

            let pniIndex = document.getElementsByName("pniIndex");

            //권종별 인적사항 등록(변경update or 추가insert)
            let i=0;
            let res1 = "";
            //let updPniData = [];
            while(i < pniIndex.length)
            {
              if(i < this.pniList.length)
              {
                let updPniData = await this.$api("/api/updPniData_evt", {param: [
                  this.pniList[i].passNameEng,this.pniList[i].passNameKor,this.pniList[i].pBirthDate,this.pniList[i].passLicenseNo,this.pniList[i].passNo,this.pniList[i].pExpDate,this.pniList[i].passFlag,'',this.pniList[i].imgFlag,this.user.handlerId,this.pniList[i].priceType,this.pniList[i].pniTel,this.pniList[i].pniUseFlag,this.eventCode,pniIndex[i].value
                ]});
                if(updPniData.affectedRows < 0 || updPniData.affectedRows === undefined)
                {
                  res1 = "error";
                  break;
                }
                else
                {
                  res1 = "ok";
                }
              }
              else if(i >= this.pniList.length)
              {
                let insPniData = await this.$api("/api/insPniData_evt", {param: [
                  this.eventCode, pniIndex[i].value,this.passNameEng[i],this.passNameKor[i],this.pBirthDate[i],this.passLicenseNo[i],this.passNo[i],this.pExpDate[i],this.passFlag[i],'','N',this.user.handlerId,'Y',this.priceType[i],this.pniTel[i]
                ]});
                if(Object.keys(insPniData)[0] === 'error') 
                {
                  res1 = "error";
                  break;
                }
                else
                {
                  res1 = "ok";
                }
              }
              i++;
            }
            if(res1 == "error")
            {
              this.$swal.fire('', '인적사항 처리 중(PNI updating) 오류가 발생하였습니다.', 'error');
              return false;
            }
            else
            {
              //PNI 카운트변경 동기처리
              let updPniAndEventM = await this.$api("/api/updPniAndEventM", {param: [
                this.eventInfo.reqAdultCnt,this.eventInfo.reqChildCnt,this.eventInfo.reqInfCnt,this.eventInfo.reqDiscountCnt,
                //this.eventInfo.reqAdultCnt*1+this.eventInfo.reqChildCnt*1+this.eventInfo.reqInfCnt*1+this.eventInfo.reqDiscountCnt),
                this.user.handlerId,this.eventCode
                ]});
                if(updPniAndEventM.affectedRows < 0 || updPniAndEventM.affectedRows === undefined)
                {
                  this.$swal.fire('', '처리 중 오류(updPniAndEventM)가 발생했습니다.<br>관리자에게 문의해 주세요.', 'error');
                }
                else
                {
                  this.$swal.fire('', '인적사항 저장완료', 'success')
                  location.reload();
                }
            }
          }
        })
      }      
      else if(p1==="U" && p2==="T") {
        this.$swal.fire({
        icon: 'question',
        text: '교통정보을 저장합니까?',
        showCancelButton: true,
        confirmButtonText: '저장',
        cancelButtonText: '취소'
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            if(this.eventInfo.evtTransFlag === "Y") //기존교통편 업데이트
            {
              let resUpd_pdtTransInfo = await this.$api("/api/pdtTransInfoUpdate", {param: [
                this.pdtTransInfo.trCd1,this.pdtTransInfo.mbCd1,this.pdtTransInfo.dpdCd,this.pdtTransInfo.dpaCd,this.pdtTransInfo.transRemarks_dp,
                this.pdtTransInfo.oilCd_dp,this.pdtTransInfo.termCd_dp,
                this.pdtTransInfo.trCd2,this.pdtTransInfo.mbCd2,this.pdtTransInfo.rtdCd,this.pdtTransInfo.rtaCd,this.pdtTransInfo.transRemarks_ar,
                this.pdtTransInfo.oilCd_rt,this.pdtTransInfo.termCd_rt,
                this.user.handlerId, this.pdtTransInfo.dpTpCode, this.pdtTransInfo.d_dpTime, this.pdtTransInfo.d_arTime, this.pdtTransInfo.rtTpCode, this.pdtTransInfo.r_dpTime, this.pdtTransInfo.r_arTime,
                this.eventCode
              ]});
              //console.log("errCheck:", Object.keys(resUpd_pdtTransInfo)[0]);
              // 인서트오류
              if(resUpd_pdtTransInfo.affectedRows < 0 || resUpd_pdtTransInfo.affectedRows === undefined){
                this.$swal.fire('', '교통정보 업데이트 오류', 'alert')
              }
              else{
                this.$swal.fire('', '교통정보 변경완료', 'success')
                location.reload();
              }
            }
            else //신규고통편 생성
            {
              let timeStamp = "";
              let today = new Date();   
              let yyyy = today.getFullYear();
              let mm = today.getMonth()+1;
              let dd = today.getDate();
              let ymd = `${yyyy}${mm}${dd}`      
              //console.log(`ymd: ${ymd}`);
              timeStamp = today.getTime(); // 교통코드에 들어갈 타임스탬프
              let catCode = "TR";
              this.pdtTransInfo.pdtCode = this.eventCode;
              this.pdtTransInfo.pdtTransCode = catCode+"-"+ymd+"-"+timeStamp;
              this.pdtTransInfo.insDate = `${yyyy}-${mm}-${dd}`;
              this.pdtTransInfo.insId = this.user.handlerId;

              let resIns_product_m = await this.$api("/api/pdtTransInsert", {param: [this.pdtTransInfo]});

              if(Object.keys(resIns_product_m)[0] === 'error')  //인서트오류
              {
                this.$swal.fire('', '교통편 저장오류(resIns_product_m)<br>관리자에게 문의해 주세요.', 'error')
              }
              else 
              {
                let updTransInfo_evt = await this.$api("/api/updTransInfo_evt", {param: ['Y', this.eventCode]});
                if(updTransInfo_evt.affectedRows < 0 || updTransInfo_evt.affectedRows === undefined) 
                {
                //alert("상품가격정보 수정오류\n결과확인 후 재실행");
                this.$swal.fire('','오류발생(updTransInfo_evt)<br>관리자에게 문의해 주세요.', 'error')
                }
                else
                {
                  this.$swal.fire('','정보등록완료','success')
                  location.reload();
                }                
              }
              //this.$swal.fire('', '교통정보 변경완료', 'success')
              //location.reload();
            }
          }
        })
      }

      else if (p1==="M" && p2 ==="P")
      {
        // 상담가격 재계산 후 업데이트 진행
        this.calcCstPrice();
      }

      else {
        return this.$swal.fire("","Case-DBTransation: <br>오류가 발생했습니다.<br>관리자에 문의해주세요.","error");
      }
    },
    //결제요청 정보처리
    async insReqPay()
    {
      let insReqPayInfo = await this.$api("/api/insReqPayInfo",{param: [
        this.eventCode,this.eventInfo.reqStatus,this.cstPrice,'N',this.user.handlerId
      ]});
      if(Object.keys(insReqPayInfo)[0] === 'error') 
      {
        this.$swal.fire('','처리 중 오류발생(insReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    },
    async updReqPay()
    {
      let updReqPayInfo = await this.$api("/api/updReqPayInfo",{param: [
        this.eventInfo.reqStatus,this.cstPrice,this.user.handlerId,this.eventCode
      ]});
      if(updReqPayInfo.affectedRows < 0 || updReqPayInfo.affectedRows === undefined)
      {
        this.$swal.fire('','처리 중 오류발생(updReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    }    
  }
}
</script>

<style>
.reserdetail .r-name{
  margin-top:3rem;
}
.reserdetail .r-name .name{
  font-size:1.3rem;
  font-weight: 600;
  color:#000;
  padding-top:10px
}
.reserdetail .r-name .code{
  font-size:.8rem;
  color:#aaa;
  font-weight: 300;
}
.reserdetail .r-name .ndate{
  font-size:1rem;
  font-weight: 300;
}

.reserdetail .table{
  border-color:transparent; 
}
.reserdetail .table{
  margin-top:10px
}
.reserdetail .table tbody tr{
  font-weight:600;
  font-size:.9rem;
  height:2.5rem;
}

.reserdetail .title{
  border-bottom: 2px solid #000;
}
.reserdetail .title p{
  font-size:1.4rem;
  letter-spacing: 3px;
  color:#000;
}

.reserdetail .traval, .reserdetail .vehicle{
    margin:3.7rem 0
  }

.reserdetail .paybtn{
  background-color: transparent;
}
.reserdetail .paybtn img{
  height: 2rem;
}

.reserdetail .go{
  margin-top:5rem;
  background-color: #000;
  color:#fff;
  padding:15px 5px
}
.reserdetail .go:hover{
  background-color: #333;
}

/** 결제버튼 */
.reserdetail .pay{
  background-color:cadetblue;
  color:#fff;
  padding:15px 5px
}
.reserdetail .pay:hover{
  background-color:darkcyan;
}

@media (min-width:992px){
  .reserdetail .r-name .name{
    padding-left:30px
  }
  .reserdetail .table{
    margin:10px 0 0 10px
  }
  .reserdetail .table tbody tr{
    height:3.2rem;
  }
  .reserdetail .traval, .reserdetail .vehicle{
    margin:4.5rem 0
  }
}
</style>
