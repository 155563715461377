<template>
  <main class="mt-3">
  <div class="p-1 text-center">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center"><u>&nbsp;옵 션 가 격 정 보&nbsp;</u></h2>
        <p class="fs-4">
          <span class="text-secondary fs-5">PDT: {{ this.optionInfo.pdtNameKor }} / OPT: {{ this.optionInfo.optName }}</span> 
        </p>

          <div class="text-success text-start fs-6 mb-2">
            <b>
              [ 일괄수정&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('U','B');">&nbsp;UPDATE</i> ] &nbsp;
              <i class="bi bi-exclamation-triangle-fill text-primary"></i>일별설정값을 일괄수정합니다
              </b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th scope="row" class="table-secondary col-md-1">기간지정</th>
                <td class="col-md-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">개시</span>
                    <input type="date" class="form-control" ref="txt_batchPriceStart" id="bpStart" style="font-size: small" v-model="batchPriceStart" @click="setStart()">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">종료</span>
                    <input type="date" class="form-control" ref="txt_batchPriceEnd" id="bpEnd" style="font-size: small" v-model="batchPriceEnd" @click="setEnd()">
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-1">
                  <input class="form-check-input" type="checkbox" v-model="dOw_Flag" true-value="Y" false-value="N">&nbsp;요일
                </th>
                <td class="col-md-5">
                  <div class="input-group">
                    <span class="input-group-text text-white bg-danger" style="font-size: small"><b>제외</b>&nbsp;<input class="form-check-input" type="checkbox" v-model="prUseFlagNot" true-value="Y" false-value="N"></span>
                    &nbsp;&nbsp;
                    <span class="input-group-text text-danger" style="font-size: small">일&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[0]" true-value="0" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">월&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[1]" true-value="1" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">화&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[2]" true-value="2" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">수&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[3]" true-value="3" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">목&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[4]" true-value="4" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">금&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[5]" true-value="5" false-value="N"></span>
                    <span class="input-group-text text-primary" style="font-size: small">토<input class="form-check-input" type="checkbox" v-model="dayOfWeek[6]" true-value="6" false-value="N"></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">
                  <input class="form-check-input" type="checkbox" v-model="price_Flag" true-value="Y" false-value="N">&nbsp;
                  변경금액
                </th>
                <td colspan="3">
                  <div class="input-group">
                    <span class="input-group-text form-control-sm">1</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice1" v-model="batchPrice[0]" style="font-size: small; width:100px">
                    <span class="input-group-text">2</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice2" v-model="batchPrice[1]" style="font-size: small; width:100px">
                    <span class="input-group-text">3</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice3" v-model="batchPrice[2]" style="font-size: small; width:100px">
                    <span class="input-group-text">4</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice4" v-model="batchPrice[3]" style="font-size: small; width:100px">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>
              [ 일별설정 ]
              <div class="float-end mb-1">
                <!-- <button type="button" class="btn btn-sm btn-outline-warning" @click="updPriceSetflag('S')">선택일자 변경적용</button>&nbsp;&nbsp; -->
                <button type="button" class="btn btn-sm btn-outline-primary" @click="updPriceSetflag('C')">선택일자 변경금액적용</button>&nbsp;&nbsp;
                <button type="button" class="btn btn-sm btn-outline-success" @click="updPriceSetflag('A')">선택일자 옵션개시</button>&nbsp;&nbsp; 
                <button type="button" class="btn btn-sm btn-outline-danger" @click="updPriceSetflag('D')">선택일자 옵션중지</button>
              </div>              
            </b>
          </div>
        <table class="table table-bordered table-striped fs-6 w-100 h-auto">
          <thead class = "small">
            <tr>
              <th class="col-1">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th>
              <th class="col-md-1">적용일자</th>
              <th class="col-md-1">요일</th>
              <th class="col-md-7">요금 & 옵션금액</th>
              <th class="col-md-1">제외</th>
              <th class="col-md-2">기능</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(prDate, i) in this.optPriceDetailCnt">
              <td  class="align-middle">
                <input type="checkbox" v-model="prDate.checkFlag"  ref="chb_Seq" :id=i name="checkedBox">
              </td>
              <td class="align-middle" v-if="getDayOfWeekFormat(this.optPriceDetailCnt[i].prDate)==='토'">
                <small class="text-primary fs-6">{{this.optPriceDetailCnt[i].prDate}}</small>
              </td>
              <td class="align-middle" v-else-if="getDayOfWeekFormat(this.optPriceDetailCnt[i].prDate)==='일'">
                <small class="text-danger fs-6">{{this.optPriceDetailCnt[i].prDate}}</small>
              </td>
              <td class="align-middle" v-else>
                <small class="fs-6">{{this.optPriceDetailCnt[i].prDate}}</small>
              </td>

              <td class="align-middle" v-if="getDayOfWeekFormat(this.optPriceDetailCnt[i].prDate)==='토'">
                <small class="text-primary fs-6">{{getDayOfWeekFormat(this.optPriceDetailCnt[i].prDate)}}</small>
              </td>
              <td class="align-middle" v-else-if="getDayOfWeekFormat(this.optPriceDetailCnt[i].prDate)==='일'">
                <small class="text-danger fs-6">{{getDayOfWeekFormat(this.optPriceDetailCnt[i].prDate)}}</small>
              </td>
              <td class="align-middle" v-else>
                <small class="fs-6">{{getDayOfWeekFormat(this.optPriceDetailCnt[i].prDate)}}</small>
              </td>

              <td>
                <div id="pdt-price">
                  <div class="input-group">
                    <span class="input-group-text text-sm">1</span>
                    <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice1" v-model="this.arr_pdtPrice1[i]" style="font-size: small; width:100px" disabled>
                    <span class="input-group-text text-sm">2</span>
                    <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice2" v-model="this.arr_pdtPrice2[i]" style="font-size: small; width:100px" disabled>
                    <span class="input-group-text text-sm">3</span>
                    <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice3" v-model="this.arr_pdtPrice3[i]" style="font-size: small; width:100px" disabled>
                    <span class="input-group-text text-sm">4</span>
                    <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice4" v-model="this.arr_pdtPrice4[i]" style="font-size: small; width:100px" disabled>
                  </div>
                </div>

                <div id="pdt-option" class="mt-2">
                  <div class="input-group">
                    <span class="input-group-text text-sm bg-success text-white">1</span>
                    <input type="number" class="form-control-sm text-sm text-end" ref="txt_optPrice1" v-model="this.arr_optPrice1[i]" style="font-size: small; width:100px">
                    <span class="input-group-text text-sm bg-success text-white">2</span>
                    <input type="number" class="form-control-sm text-sm text-end" ref="txt_optPrice2" v-model="this.arr_optPrice2[i]" style="font-size: small; width:100px">
                    <span class="input-group-text text-sm bg-success text-white">3</span>
                    <input type="number" class="form-control-sm text-sm text-end" ref="txt_optPrice3" v-model="this.arr_optPrice3[i]" style="font-size: small; width:100px">
                    <span class="input-group-text text-sm bg-success text-white">4</span>
                    <input type="number" class="form-control-sm text-sm text-end" ref="txt_optPrice4" v-model="this.arr_optPrice4[i]" style="font-size: small; width:100px">
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <input class="form-check-input" type="checkbox" v-model="this.optUseFlag[i]" true-value="N" false-value="Y" id="optUseFlag">
              </td>
              <td class="align-middle">
                <button type="button" class="btn btn-success btn-sm me-1" @click="updateOptPrice(this.productId, this.optCode, this.optPriceDetailCnt[i].prDate, this.optUseFlag[i], this.arr_optPrice1[i], this.arr_optPrice2[i], this.arr_optPrice3[i], this.arr_optPrice4[i])">변경
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToBack()">돌아가기</button>
      </div>
    </div>
  </div>
  
  </main>
</template>

<script>
export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      dayOfWeek: ["N","N","N","N","N","N","N"],   //요일정보
      numberOfWeek: [],                           //요일숫자정보
      productInfo: {},                            //상품정보
      productPrice: {},                           //권종가격
      productPriceDetail: {},                     //기간별 적용가격
      productPriceDetailCnt: {},                  //기간별 적용가격 카운트

      optionInfo: [],                             //옵션기본정보
      optPriceDetail: [],                         //데일리 옵션가격
      optPriceDetailCnt: [],                      //데일리 옵션가격카운트

      dInterval: 0, //날짜수
      arr_nextDay: [],
      arr_dayOfWeek: [],
      arr_numberOfWeek: [],
      //arr_pdtPrice: [],
      arr_pdtPrice1: [],
      arr_pdtPrice2: [],
      arr_pdtPrice3: [],
      arr_pdtPrice4: [],
      priceUseFlag: [],         //금액사용여부
      pdtPrice_d: [],           //특수가격(상품별)

      //옵션가격
      arr_optPrice1: [],
      arr_optPrice2: [],
      arr_optPrice3: [],
      arr_optPrice4: [],
      optUseFlag: [],           //옵션사용여부

      batchPriceStart: "",      //일괄변경시작
      batchPriceEnd: "",
      batchPrice: [0,0,0,0],
      arr_batchPrice: [],       //일괄변경금액

      dOw_Flag: "N",            //요일선택여부
      price_Flag: "N",          //가격변동선택여부
      prUseFlagNot: "N",        //일괄제외선택

      dpDate: "",               //적용시작일
      rtDateFin: "",            //적용종료일
      firstDay: "",             //적용첫날
      allSelected: "N"          //전체체크여부
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    //this.reqRsvCode = this.$route.query.seqReq;
    this.productId = this.$route.query.productId;
    this.optCode = this.$route.query.optCode;
    this.sRoute = this.$route.query.sRoute;

    this.getProductInfo();
    this.getProductPrice();
    this.getProdctPriceDetail();
    this.getOptionInfo(this.productId, this.optCode)
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    //상세정보와 무관한 내용 비표시처리
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: 
  {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDayOfWeekFormat(text) {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text) {
      return this.$numberOfWeekFormat(text);
    },
    allChoice() 
    {
      if(this.allSelected)
      {
        this.optPriceDetailCnt.forEach((prDate) => {
          prDate.checkFlag = true;
        })
      }
      else
      {
        this.optPriceDetailCnt.forEach((prDate) => {
          prDate.checkFlag = false;
        })
      }
    },
    //선택일자 상태변경
    async updPriceSetflag(flag)
    {
      let selectedDate = [];
      this.optPriceDetailCnt.forEach((prDate) => {
        if(prDate.checkFlag)
        {
          selectedDate.push(prDate.prDate)
        }
      })
      //console.log("selectedCount:",selectedDate.length, ",selectedDate:",selectedDate)

      //체크박스의 아이디
      //let checkBox = document.querySelectorAll('input[type="checkbox"]')
      let checkBox = document.getElementsByName('checkedBox')
      //체크된 박스의 아이디들
      //let checkedIds = [];
      let selectedDatePrice = []

      for (var i = 0; i < checkBox.length; i++) 
      {
        //let checkBoxes = checkBox[i];
        if (checkBox[i].checked)
        //if(checkBoxes.id.endsWith('i') && checkBoxes.checked)
        {
          //checkedIds.push(checkBox[i].id);
          selectedDatePrice.push([
            this.optPriceDetailCnt[checkBox[i].id].prDate, this.arr_optPrice1[checkBox[i].id], this.arr_optPrice2[checkBox[i].id], this.arr_optPrice3[checkBox[i].id], this.arr_optPrice4[checkBox[i].id], this.optUseFlag[checkBox[i].id]
          ])
        }
      }

      // 판매중지 처리
      if (flag === "D") {
        this.$swal.fire({
          html: '선택한 날짜들의 옵션판매를 중지합니까?',
          showCancelButton: true,
          confirmButtonText: '예',
          cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const requests = selectedDate.map(date => 
                this.$api("/api/updPriceSellFlag_opt", {
                  param: ['N', this.user.handlerId, this.productId, this.optCode, date]
                })
              );
              let responses = await Promise.all(requests);      //모든 api호출의 완료까지 대기

              let isError = responses.some(response =>          //호출응답의 이상체크
                response.affectedRows < 0 || response.affectedRows === undefined
              );

              if (isError) {
                this.$swal.fire("", "처리 중 에러가 발생했습니다.", "error");
                return false
              } else {
                this.$swal.fire("", "선택일자의 옵션이 판매중지로 변경되었습니다.", "success");
                location.reload(true);
              }
            } catch (error) {
              console.error("에러 발생:", error);
              this.$swal.fire("", "요청 처리 중 에러가 발생했습니다.", "error");
            }
          }
        });
      }

      else if(flag === "A")
      {
        this.$swal.fire({
          html: '선택한 날짜들의 옵션판매를 개시합니까?',
          showCancelButton: true,
          confirmButtonText: '예',
          cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const requests = selectedDate.map(date => 
                this.$api("/api/updPriceSellFlag_opt", {
                  param: ['Y', this.user.handlerId, this.productId, this.optCode, date]
                })
              );
              let responses = await Promise.all(requests);      //모든 api호출의 완료까지 대기

              let isError = responses.some(response =>          //호출응답의 이상체크
                response.affectedRows < 0 || response.affectedRows === undefined
              );

              if (isError) {
                this.$swal.fire("", "처리 중 에러가 발생했습니다.", "error");
                return false
              } else {
                this.$swal.fire("", "선택일자의 옴션이 판매개시로 변경되었습니다.", "success");
                location.reload(true);
              }
            } catch (error) {
              console.error("에러 발생:", error);
              this.$swal.fire("", "요청 처리 중 에러가 발생했습니다.", "error");
            }
          }
        });
      }

      //옵션 판매가격 일괄변경
      else if (flag === "C") 
      {
        this.$swal.fire("", "화면상단의 변경금액에 옵션금액을 입력하세요", "info").then(() => {
          const arr_batchPrice = [this.batchPrice[0], this.batchPrice[1], this.batchPrice[2], this.batchPrice[3]];

          this.$swal.fire({
            html: '선택한 날짜들의 판매가격을 변경합니까?',
            showCancelButton: true,
            confirmButtonText: '예',
            cancelButtonText: '아니오'
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {

                //2중 map
                const requests = selectedDate.map(date => {
                  const updatePromises = arr_batchPrice.map((price, index) =>
                    this.$api("/api/updateCheckedPrice_opt", {
                      param: [price, this.user.handlerId, this.productId, this.optCode, date, index + 1]
                    })
                  );
                  return Promise.all(updatePromises);
                });

                const responses = await Promise.all(requests);

                const isError = responses.some(dateResponses =>
                  dateResponses.some(response =>
                    response.affectedRows < 0 || response.affectedRows === undefined
                  )
                );

                if (isError) {
                  this.$swal.fire("", "금액 업데이트 중 에러가 발생했습니다(코드: updateCheckedPrice)", "error");
                  return false;
                } else {
                  this.$swal.fire("", "선택한 날짜들의 판매가격이 변경되었습니다", "success");
                  location.reload(true);
                }
              } catch (error) {
                console.error("에러 발생:", error);
                this.$swal.fire("", "요청 처리 중 에러가 발생했습니다.", "error");
              }
            }
          });
        });
      }

      //선택일자 변경내용 일괄적용
/*
      else if(flag === "S")
      {
        let arr_batchPrice = [this.batchPrice[0],this.batchPrice[1],this.batchPrice[2],this.batchPrice[3]];

        this.$swal.fire({
        html: '선택한 날짜들의 변경된 내용을 적용합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let j = 0;
              //권종별 금액업데이트
              while(j < arr_batchPrice.length)
              {
                let updateCheckedPrice = await this.$api("/api/updateCheckedPrice", {param: [ 
                  arr_batchPrice[j],this.user.handlerId,this.productId,selectedDate[i], j+1
                ]});
                if(updateCheckedPrice.affectedRows < 0 || updateCheckedPrice.affectedRows === undefined)
                {
                  res = "error"
                  break;
                }
                j++;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","금액 업데이트 중 에러가 발생했습니다(코드: updateCheckedPrice)","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","선택일자가 판매가격이 변경되었습니다","success");
              //location.reload(true)
            }
          } 
        });
      }
*/      
    },    
    async getProductInfo() {
      this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"

      let productInfo = {};
      if(this.pdtFlag === "SG") {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      else if(this.pdtFlag === "MX") {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else {
        return this.$swal.fire("","상품이 검색되지 않습니다<br>재확인 후 시도해 주세요.","error");
      }
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
    },

    //옵션기본정보
    async getOptionInfo(pCode, oCode) {
      let optionInfo = await this.$api("/api/selOptionInfo", {param: [pCode, oCode]});

      if(optionInfo.length > 0)
      {
        this.optionInfo = optionInfo[0]
      }
      // console.log("optionInfo: ", this.optionInfo)
    },


    async getProductPrice()
    {
      let productPrice = await this.$api("/api/productPrice", {param: [this.productId]});
      this.productPrice = productPrice;
      //console.log("productPrice:",productPrice)

      //세팅된 날짜의 시작~끝일
      let minMaxDate = await this.$api("/api/getMinMaxDate", {param: [this.productId]});

      this.firstDay = this.getDateFormat(minMaxDate[0].startDate);
      this.rtDateFin = this.getDateFormat(minMaxDate[0].endDate);
      //console.log("firstDay:", this.firstDay, "rtDateFin:", this.rtDateFin)
    },

    //기간별 적용가격정보(상품 & 옵션)
    async getProdctPriceDetail() {
      //일자+요일별 금액 상품가격
      let productPriceDetailCnt = await this.$api("/api/productPriceDetailCnt", {param: [this.productId]});
      let productPriceDetail = await this.$api("/api/productPriceDetail", {param: [this.productId]});
      this.productPriceDetailCnt = productPriceDetailCnt;
      this.productPriceDetail = productPriceDetail;

      //일자+요일별 금액 옵션가격
      let optPriceDetailCnt = await this.$api("/api/optPriceDetailCnt", {param: [this.productId, this.optCode]});
      let optPriceDetail = await this.$api("/api/optPriceDetail", {param: [this.productId, this.optCode]});
      this.optPriceDetailCnt = optPriceDetailCnt;
      this.optPriceDetail = optPriceDetail;      
      console.log("optPriceDetailCnt:",optPriceDetailCnt, "/ length: ", this.optPriceDetailCnt.length);
      console.log("optPriceDetail:",optPriceDetail);

      //상품가격
      let arr_pdtPrice1 = [];
      let arr_pdtPrice2 = [];
      let arr_pdtPrice3 = [];
      let arr_pdtPrice4 = [];
      let arr_priceUseFlag = [];

      this.arr_pdtPrice1 = arr_pdtPrice1
      this.arr_pdtPrice2 = arr_pdtPrice2
      this.arr_pdtPrice3 = arr_pdtPrice3
      this.arr_pdtPrice4 = arr_pdtPrice4
      this.priceUseFlag = arr_priceUseFlag;

      //옵션가격
      let arr_optPrice1 = [];
      let arr_optPrice2 = [];
      let arr_optPrice3 = [];
      let arr_optPrice4 = [];
      let arr_optUseFlag = [];
      
      this.arr_optPrice1 = arr_optPrice1
      this.arr_optPrice2 = arr_optPrice2
      this.arr_optPrice3 = arr_optPrice3
      this.arr_optPrice4 = arr_optPrice4
      this.optUseFlag = arr_optUseFlag;


      if(this.productPriceDetailCnt.length < 1)
      {
        this.$swal.fire("","설정된 금액이 없습니다","info");
        history.back();
      }
      else {
        let p = 0;
        while(p < this.productPriceDetailCnt.length) 
        {
          //console.log("P:",p,"/productPriceDetailCnt:",productPriceDetailCnt[p].prDate);
          let spPrice = this.productPriceDetail.filter(e => {return e.prDate === this.productPriceDetailCnt[p].prDate})
          //console.log("spPrice:",spPrice);

          if(this.sRoute === "S") {
              arr_pdtPrice1.push(spPrice[0].pdtPrice);
              arr_priceUseFlag.push(spPrice[0].useFlag)
          }
          else 
          {
            arr_pdtPrice1.push(spPrice[0].pdtPrice);
            arr_pdtPrice2.push(spPrice[1].pdtPrice);
            arr_pdtPrice3.push(spPrice[2].pdtPrice);
            arr_pdtPrice4.push(spPrice[3].pdtPrice);
            arr_priceUseFlag.push(spPrice[0].useFlag);
          }
          p++;
        }
      }

      if(this.optPriceDetailCnt.length < 1)
      {
        this.$swal.fire("","설정된 옴션금액이 없습니다","info");
        history.back();
      }
      else {
        let t = 0;
        while(t < this.optPriceDetailCnt.length) 
        {
          //console.log("t:",t,"/optPriceDetailCnt:",optPriceDetailCnt[t].prDate);
          let opPrice = this.optPriceDetail.filter(e => {return e.prDate === this.optPriceDetailCnt[t].prDate})

          arr_optPrice1.push(opPrice[0].optPrice);
          arr_optPrice2.push(opPrice[1].optPrice);
          arr_optPrice3.push(opPrice[2].optPrice);
          arr_optPrice4.push(opPrice[3].optPrice);
          arr_optUseFlag.push(opPrice[0].useFlag);

          t++;
        }
      }      
      //console.log("arr_optPrice1:",arr_optPrice1);
    },

    async updateOptPrice(pCode, oCode, pDate, pUse, p1, p2, p3, p4) {
      //console.log("pCode:",pCode, "oCode:",oCode, "pDate:",pDate, "pUse:",pUse, p1, p2, p3, p4);
      //console.log("this.optPriceDetail: ", this.optPriceDetail)
      
      let dubCheck = [];
      let dubCheckFlag = "";
      dubCheck = this.optPriceDetail.filter(e => {return e.prDate === pDate});
      dubCheckFlag = (dubCheck.length > 0) ? "Y" : "N"
      
      let arr_p = [p1, p2, p3, p4]                      //권종별 옵션가격(추가금액)
      let prUse = (pUse != undefined) ? pUse : "0";
      let pDow = this.getNumberOfWeekFormat(pDate);

      //console.log("dubCheckFlag:",dubCheckFlag,"/pDow:",pDow);

      //기존 특수가격의 존재에 따라 ins / upd를 진행
      if(dubCheckFlag === "N") 
      {
        //중복금액이 없으므로 인서트처리

        // let timeStamp = "";
        // let today = new Date();
        // let yyyy = today.getFullYear();
        // let mm = today.getMonth()+1;
        // let dd = today.getDate();
        // let ymd = `${yyyy}${mm}${dd}`
        // timeStamp = today.getTime(); //가격코드에 들어갈 타임스탬프

        //let prcCode = "P"+this.productId.charAt(0)+"-"+ymd+timeStamp; //기본가격과는 다른 가격을 위한 고유코드
        //alert("prcCode:" + prcCode);

        let a = 0
        while(a < 4) {
          let resIns_optPrice_d = await this.$api("/api/optPrcDetailInsert", {param: [
            pCode, oCode, a+1, arr_p[a], pDow, pDate, prUse, this.user.handlerId
          ]});
          //console.log("errCheck:", Object.keys(resIns_pdtPrice_d)[0]);
          if(resIns_optPrice_d.affectedRows < 0) {
            this.$swal.fire("","저장오류","error");
            return false;
          }
          a++;
        }
      }
      else 
      {
        //중복금액이 있으므로 변경처리
        let b = 0
        while(b < 4) {
          let resIns_optPrice_d = await this.$api("/api/optPriceDetailUpdate", {param: [
            arr_p[b], prUse,  this.user.handlerId, pCode, oCode, pDate, b+1
          ]});
            if(resIns_optPrice_d.affectedRows < 0 || resIns_optPrice_d.affectedRows === undefined){
              this.$swal.fire('', '옵션가격 업데이트 오류', 'error');
              return false;
            }
          b++;
        }
      }
      this.$swal.fire("","해당일의 옵션가격(판매상태)이 변경되었습니다.", "info")
      location.reload();
    },

    setStart(){
      //this.batchPriceStart = "";
      document.getElementById("bpStart").setAttribute("min", this.firstDay);
      document.getElementById("bpStart").setAttribute("max", this.rtDateFin);
    },
    setEnd(){
      //this.batchPriceEnd = "";
      document.getElementById("bpEnd").setAttribute("min", this.firstDay);
      document.getElementById("bpEnd").setAttribute("max", this.rtDateFin);
    },
    goToBack() {
      //this.$router.push({path: '/reserveInfoList'});
      history.back();
    },
    //일괄가격설정
    dataBaseTransaction(p1, p2)
    {
      let useFlag = "";

      //상품가격 일괄변경용 배열
      if(this.price_Flag === "Y")
      { 
        this.arr_batchPrice = [this.batchPrice[0],this.batchPrice[1],this.batchPrice[2],this.batchPrice[3]];
      }
      
      //옵션가격 일괄변경
      if(p1 === "U" && p2 ==="B") 
      {
        let diffDate = this.batchPriceStart.replace(/-/g, "") - this.batchPriceEnd.replace(/-/g,"")
        if(diffDate > 0 )
        {
          return this.$swal.fire('', '시작일은 종료일보다 이전이어야 합니다.', 'error')
        }

        if(!this.batchPriceStart || !this.batchPriceEnd ) {
          return this.$swal.fire('', '적용기간을 입력하세요', 'question')
        }

        //선택된 요일체크
        let arr_selDoW = [];
          this.dayOfWeek.forEach((array) => 
          {
            if(array != "N") {
              arr_selDoW.push(array)
            }
          });
        //console.log("selDoW:", arr_selDoW,"/string:", arr_selDoW.toString());

        this.$swal.fire({
        icon: 'question',
        text: '정보를 변경하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            //요일일괄처리루틴
            if(this.price_Flag === "N" && this.dOw_Flag === "Y")
            {
              //일괄제외or취소처리
              if(this.prUseFlagNot === "Y")
              {
                useFlag = "N";
                this.$swal.fire('', '선택요일은 가격적용에서 제외되어 판매되지 않습니다.', 'info');
              }
              else 
              {
                useFlag = "Y";
                this.$swal.fire('', '지정기간의 요일제외가 취소됩니다.', 'info');
              }
              let i = 0
                while(i < arr_selDoW.length) 
                {
                  let resUpd_DoWStatus = await this.$api("/api/setNotUseDoWStatus_opt", {param: [useFlag, this.user.handlerId,this.productId, this.optCode, arr_selDoW[i], this.batchPriceStart, this.batchPriceEnd]});
                  //console.log("resUpd_DoWStatus:", resUpd_DoWStatus);
                  if(resUpd_DoWStatus.affectedRows < 0 || resUpd_DoWStatus.affectedRows === undefined)
                  {
                    //this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                    alert("업데이트 중, 오류가 발생했습니다.");
                    return false;
                  }
                  i++;
                }
                location.reload();
            }
            else if(this.price_Flag === "Y" && this.dOw_Flag === "Y")
            {
              if(this.prUseFlagNot === "Y") 
              {
                return this.$swal.fire('', '요일제외가 선택되어 있습니다. <br> 제외체크 해지 후, 재시도해 주세요.', 'warning');
              }
              else
              {
                // if(this.sRoute === "S")
                // {
                //   //useFlag = (this.prUseFlagNot === "Y") ? "0" : "1"

                //   let i = 0;
                //   while(i < arr_selDoW.length)
                //   {
                //     let updatePricesAndDoW = await this.$api("/api/updatePricesAndDoW", {param: [this.arr_batchPrice[0], this.user.handlerId, this.productId, this.batchPriceStart, this.batchPriceEnd, arr_selDoW[i], "1"]});
                //     //console.log("updatePricesAndDoW:", updatePricesAndDoW);
                //     if(updatePricesAndDoW.affectedRows < 0 || updatePricesAndDoW.affectedRows === undefined)
                //     {
                //       //this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                //       alert("업데이트 중, 오류가 발생했습니다.");
                //       return false;
                //     }
                //     i++;
                //   }
                //   this.$swal.fire('', '변경되었습니다.', 'success');
                //   location.reload();
                // }
                // //결합상품의 기간+요일 상품가격 일괄변경
                // else
                // {
                //   let j = 0;
                //   while(j < arr_selDoW.length)
                //   {
                //     let i = 0;
                //     while(i < 4)
                //     {
                //       let updatePricesAndDoW = await this.$api("/api/updatePricesAndDoW", {param: [this.arr_batchPrice[i], this.user.handlerId, this.productId, this.batchPriceStart, this.batchPriceEnd, arr_selDoW[j], i+1]});
                //       if(updatePricesAndDoW.affectedRows < 0 || updatePricesAndDoW.affectedRows === undefined)
                //       {
                //         alert("업데이트 중, 오류가 발생했습니다.");
                //         return false;
                //       }
                //       i++;
                //     }
                //     j++;
                //   }
                  
                //   this.$swal.fire('', '변경되었습니다.', 'success');
                //   location.reload();
                // }
                
                let j = 0;
                  while(j < arr_selDoW.length)
                  {
                    let i = 0;
                    while(i < 4)
                    {
                      let updatePricesAndDoW = await this.$api("/api/updatePricesAndDoW_opt", {param: [this.arr_batchPrice[i], this.user.handlerId, this.productId, this.optCode, this.batchPriceStart, this.batchPriceEnd, arr_selDoW[j], i+1]});

                      if(updatePricesAndDoW.affectedRows < 0 || updatePricesAndDoW.affectedRows === undefined)
                      {
                        alert("업데이트 중, 오류가 발생했습니다.");
                        return false;
                      }
                      i++;
                    }
                    j++;
                  }
                this.$swal.fire('', '변경되었습니다.', 'success');
                //location.reload();                
              }
            }
            else if(this.price_Flag === "Y" && this.dOw_Flag === "N")
            {
              this.$swal.fire('', '지정기간의 상품가격을 변경합니다.', 'info');
              //기간 중 비용일괄변경(옵션)
              let j=0
              while(j < 4)
              {
                let resUpd_Prices = await this.$api("/api/updatePrices_opt", {param: [
                  this.arr_batchPrice[j], this.user.handlerId, this.productId, this.optCode, j+1, this.batchPriceStart, this.batchPriceEnd
                ]});
                console.log("resUpd_Prices:", resUpd_Prices);
                if(resUpd_Prices.affectedRows < 0 || resUpd_Prices.affectedRows === undefined)
                {
                  //this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                  alert("업데이트 중, 오류가 발생했습니다.");
                  return false;
                }
                j++;
              }
              this.$swal.fire('', '변경되었습니다.', 'success');
              location.reload();
            }
          }
        })
      }
    }
  }
}
</script>

<style>
  /** 가격과 옵션폰트지정 */
  #pdt-price {
    font-size: small
  }
</style>