<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="main p-2">
        <div class="alert alert-primary print-hide" role="alert">
          <b class="fs-3">정 산 용&nbsp;&nbsp;&nbsp;거 래 정 보</b>
        </div>
          
        <div class="text-end mb-2 print-hide">
          <button type="button" class="btn btn-outline-secondary btn-sm" @click="showRegitEvent();">
            <i class="bi bi-caret-down"></i> 행사등록
          </button>
          <!-- <button type="button" class="btn btn-outline-secondary btn-sm" @click="genCode('DL-')">TEST</button> -->
        </div>
        <!-- register season area -->
        <table class="table table-bordered table-striped fs-6 print-hide text-center" v-if="this.createFlag == 'Y'">
          <thead class="small">
            <tr>
              <th style="width:120px;">행사명(<font class="text-danger">*</font>국문/영문)</th>
              <!-- <th style="width:90px;"><font class="text-danger">*</font>거래처</th> -->
              <th style="width:90px;"><font class="text-danger">*</font>판매금액</th>
              <th style="width:70px;"><font class="text-danger">*</font>예약자</th>
              <th style="width:70px;"><font class="text-danger">*</font>전화/이메일</th>
              <th style="width:50px;"><font class="text-danger">*</font>인원</th>
              <th style="width:100px;"><font class="text-danger">*</font>행사기간</th>
              <th style="width:70px;">출발도시</th>
              <th style="width:70px;">여행지</th>
              <th style="width:80px;"><font class="text-danger">*</font>진행담당</th>
              <th style="width:150px;">비고사항</th>
              <th style="width:50px;">등록</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr>
              <td>
                <input type="text"  class="text-success mb-1" v-model="this.dealNameKor" size="25" style="font-size: smaller" maxlength="70" ref="dealNameKor" placeholder="국문,최대70자">
                <input type="text" v-model="this.dealNameEng" size="25" style="font-size: smaller" maxlength="100" ref="dealNameEng" placeholder="영문,최대100자">
              </td>
              <td>
                <!-- <select class="form-select form-select-sm" v-model="this.dealPartner" style="font-size: smaller;" ref="dealPartner">
                  <option :value="p.pCode" :key="i" v-for="(p,i) in this.partnerInfo">{{ p.pName }}</option>
                </select> -->
                <input type="text" v-model="this.dealPrice_tot" size="10" style="font-size: smaller" maxlength="10" ref="dealPrice_tot" placeholder="숫자만">
                <p class="mt-2 text-secondary text-sm-end">
                  {{ this.getCurrencyFormat(this.dealPrice_tot) }} 원
                </p>
              </td>
              <td>
                <input type="text" v-model="this.reqName" size="10" style="font-size: smaller" maxlength="30" ref="reqName">
              </td>
              <td>
                <div class="mb-1">
                  <input type="text" v-model="this.reqTelNo" size="10" style="font-size: smaller" maxlength="15" ref="reqTelNo" placeholder="전화(숫자만)">
                </div>
                <div>
                  <input type="text" v-model="this.reqEmail" size="16" style="font-size: smaller" maxlength="50" ref="reqEmail" placeholder="이메일">
                </div>
              </td>
              <td>
                <input type="text" v-model="this.paxCnt" size="5" style="font-size: smaller" maxlength="15" ref="paxCnt" placeholder="숫자만">
              </td>
              <td>
                <div class="input-group mb-1">
                  <span class="input-group-text input-group-sm" id="basic-addon1" style="font-size:smaller">시작</span>
                  <input type="date" class="form-control form-control-sm" ref="evtStartDate" v-model="this.evtStartDate" style="font-size:smaller">
                </div>
                <div class="input-group">
                  <span class="input-group-text input-group-sm" id="basic-addon1" style="font-size:smaller">종료</span>
                  <input type="date" class="form-control form-controm-sm" ref="evtCloseDate" v-model="this.evtCloseDate" style="font-size:smaller">
                </div>                
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.dptCtCode" style="font-size: smaller;" ref="dptCtCode">
                  <option :value="d.baseCode" :key="i" v-for="(d,i) in this.dptCtList">{{ d.codeNameKor }}</option>
                </select>
              </td>
              <td>
                <div class="mb-1">
                  <select class="form-select form-select-sm" v-model="this.ntCd" style="font-size: smaller;" @change="changeNationList(this.ntCd)" ref="ntCd">
                    <option :value="c.baseCode" :key="i" v-for="(c,i) in this.tripNtList">{{ c.codeNameKor }}</option>
                  </select>
                </div>
                <div>
                  <select class="form-select form-select-sm" v-model="this.arCd" style="font-size: smaller;" ref="arCd">
                    <option :value="a.baseCode" :key="i" v-for="(a,i) in this.tripArList">{{ a.codeNameKor }}</option>
                  </select>
                </div>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.dealChargeId" style="font-size: smaller;" ref="dealChargeId">
                  <option :value="p.handlerId" :key="i" v-for="(p,i) in this.chargePList">{{ p.handlerNameKor }}</option>
                </select>
              </td>
              <td>
                <textarea class="form-control" placeholder="1,000자 이내" ref="evRemarks" style="height:60px; font-size: small;" v-model="this.evRemarks" maxlength="1000"></textarea>
              </td>
              <td>
                <!-- me-1: rigt margin1 -->
                <button type="button" class="btn btn-outline-primary btn-sm" @click="registEvent()">OK</button>
              </td>
            </tr>
          </tbody>
          <br>
          <br>
        </table>

        <div class="text-primary text-start fs-6 mb-2 print-hide">
          <b>[ &nbsp; 정산자료 검색조건 &nbsp; ]</b>
        </div>
        <table class="table table-bordered border-success align-middle w-100 h-auto print-hide text-center">
          <tbody>
            <tr>
              <th class="table-secondary">
                <select class="form-select form-select-sm" v-model="this.searchDuration">
                  <option value="G" selected>행사시작일</option>
                  <option value="E">정산완료일</option>
                </select>
              </th>
              <td colspan="3">
                <div class="input-group">
                  <span class="input-group-text input-group-sm" style="font-size:smaller">시작</span>
                  <input type="date" class="form-control form-control-sm" ref="searchStart" v-model="this.searchStart" style="font-size:smaller">
                  <span class="input-group-text input-group-sm" style="font-size:smaller">종료</span>
                  <input type="date" class="form-control form-controm-sm" ref="searchEnd" v-model="this.searchEnd" style="font-size:smaller">
                </div>
              </td>
              <th class="table-secondary col-md-1" style="font-size:smaller">
                행사명(유의어)
              </th>
              <td>
                <input type="text" v-model="this.dealName" style="font-size:smaller" size="40">
              </td>
              <th class="table-secondary col-md-1" style="font-size:smaller">
                담당자
              </th>
              <td>
                <select class="form-select form-select-sm" v-model="this.chargePerson" style="font-size: smaller;" ref="dealChargeId">
                  <option :value="p.handlerId" :key="i" v-for="(p,i) in this.chargePList">{{ p.handlerNameKor }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <!-- <th class="table-secondary col-md-1" style="font-size:smaller">
                거래처
              </th> -->
              <th class="table-secondary col-md-1" style="font-size:smaller">
                여행지
              </th>
              <td>
                <div class="row">
                  <select class="form-select form-select-sm" v-model="this.searchNtCd" style="font-size: smaller; width: 100px" @change="changeNationListforSearch(this.searchNtCd)" ref="searchNtCd">
                    <option value="ntAll">-- 국가전체 --</option>
                    <option :value="c.baseCode" :key="i" v-for="(c,i) in this.tripNtList">{{ c.codeNameKor }}</option>
                  </select>&nbsp;&nbsp;
                  <select class="form-select form-select-sm" v-model="this.searchArCd" style="font-size: smaller; width:110px" ref="searchArCd">
                    <option value="arAll">-- 지역전체 --</option>
                    <option :value="a.baseCode" :key="i" v-for="(a,i) in this.tripArListforSearch">{{ a.codeNameKor }}</option>
                  </select>
                </div>
              </td>
              <th class="table-secondary col-md-1" style="font-size:smaller">
                예약자
              </th>
              <td>
                <input type="text" v-model="this.resName" style="font-size:smaller" size="40">
              </td>
              <th class="table-secondary col-md-1" style="font-size:smaller">
                선택조건
              </th>
              <td>
                <select class="form-select form-select-sm" v-model="this.searchCon">
                  <option value="">행사전체</option>
                  <option value="EM">행사생성</option>
                  <option value="EG">행사진행</option>
                  <option value="EE">행사마감</option>
                  <option value="EC">행사취소</option>
                  <option value="SG">정산중</option>
                  <option value="SE">정산완료</option>
                  <option value="OB">미수행사</option>
                </select>
              </td>
              <th class="table-secondary col-md-1" style="font-size:smaller">
                행사코드
              </th>
              <td>
                <input type="text" v-model="this.dCode" style="font-size:smaller" size="40">
              </td>
            </tr>
          </tbody> 
        </table>
        <div class="text-success text-end fs-6 mb-2 print-hide print-hide">
          <b><button type="button" class="btn btn-outline-danger btn-sm" @click="searchSettleData();">검색하기</button></b>
        </div>

        <!-- 모달영역 -->
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <span @click="showModal = false" class="close print-hide">&times;</span>
            <Modal_BusinessInvoice :showModal="showModal" :modalContent="this.sel_dealCode" @close="closeModal"/>
          </div>
        </div>

        <!-- 정산정보 조회결과 -->
        <div class="mt-3 print-hide" style="height: auto;">
          <ag-grid-vue3 
            style="width: 100%; height: 800px;" 
            class="ag-theme-balham" 
            :columnDefs="columnDefs"
            :rowData="rowData"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
            @cell-clicked="onCellClicked"
            >
          </ag-grid-vue3>
        </div>

      </div>
    </main>
  </div>
</template>

<script>
import Modal_BusinessInvoice from "./Modal_BusinessInvoice.vue";
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
//import moment from 'moment';

import {genCode} from '../assets/js/genCode'


export default {
  components: {
    Modal_BusinessInvoice,
    'ag-grid-vue3': AgGridVue
  },  
  data() {
    return {
      //modal컨트롤
      showModal: false,
      modalContent: "",
      sel_dealCode: "",      //선택된 딜코드

      searchDuration: "G",   //검색기간기준
      searchStart: "",       //검색기준일
      searchEnd: "",
      dealName: "",          //검색용 거래명
      chargePerson: "",      //검색명 담당자명
      searchPartner: "",     //검색용 거래처
      searchNtCd: "",        //검색용 국가코드
      searchArCd: "",        //검색용 지역코드
      resName: "",           //검색용 예약자
      searchCon: "",         //검색용 선택조건(SAG,SAE,STG,STE,OSB)
      dCode: "",             //검색용 행사코드

      partnerInfo: [],       //검색용 거래처정보

      //정산용 거래생성란 표시여부
      createFlag: "N",

      //여정지역데이타
      dptCtList: [],            //출발도시리스트
      tripNtList: [],           //여행국가리스트
      tripArList: [],           //국가하위 지역리스트
      tripArListforSearch: [],  //국가하위 지역리스트(검색용)

      //담당자데이타
      chargePList: [],    //담당자리스트

      //행사생성
      dealCode: "",       //행사코드
      dealNameKor: "",
      dealNameEng: "",
      dealPartner: "",    //행사거래처코드
      dealPrice_tot: 0,   //판매금액
      reqName: "",        //행사예약자
      reqTelNo: "",       //예약자연락처
      reqEmail: "",
      paxCnt: 0,          //참가인원
      evtStartDate: "",   //행사시작일
      evtCloseDate: "",   //행사종료일
      dptCtCode: "",      //출발도시코드
      ntCd: "",
      arCd: "",
      dealChargeId: "",   //담당자Id
      evRemarks: "",      //행사비고사항

      settleDataInfo: [], //검색된 정산데이타

      //agGrid
      columnDefs: [
        { headerCheckboxSelection: true, checkboxSelection: true, resizable: true, width: 50 },
        { 
          headerName: '',     field: '',   sortable: true, resizable: false, width: 70,
          cellRenderer: (params) => {
            if (params.data.reqStatus === 'SE') {
              return '<button type="button" class="btn btn-success btn-sm justify-content-center" style="font-size: smaller">정산서</button>';
            }
            return params.value;
          }
        },
        { headerName: '상품명',     field: 'dealNameKor',   sortable: true, resizable: true },
        { 
          headerName: '상태',       field: 'procName',      sortable: true, resizable: true, width: 100,
          cellStyle: (params) => {
            if(params.data.reqStatus === "SE")
            {
              return { color: 'red', fontWeight:'bold'}
              //return 'red-font-bold'
            }
            else if(params.data.reqStatus === "EE")
            {
              return { color: 'blue', fontWeight: 'bold'}
            }
            return null;
          }
        },
        { headerName: '행사시작',   field: 'startDate',     sortable: true, resizable: true, width: 110 },
        { headerName: '행사종료',   field: 'closeDate',     sortable: true, resizable: true, width: 110 },
        { headerName: '예약자',     field: 'reqName',       sortable: true, resizable: true, width: 120 },
        { headerName: '인원',       field: 'paxCnt',        sortable: true, resizable: true, width: 80 },        
        { headerName: '연락처',     field: 'reqTelNo',      sortable: true, resizable: true, width: 120 },
        { headerName: '담당자',     field: 'handlerNameKor',sortable: true, resizable: true, width: 120 },
        { headerName: '정산일',     field: 'seDate',        sortable: true, resizable: true, width: 110 },
        { headerName: '여행지',     field: 'codeNameKor',   sortable: true, resizable: true, width: 100 },
        { headerName: '판매가',     field: 'priceTot',      sortable: true, resizable: true, width: 100,
          cellStyle: () => {
            return { color: 'red'}
          }
        },
        // { headerName: '판매수수료', field: 'evRemarks',     sortable: true, resizable: true, width: 100 },
        // { headerName: '미수/과입',  field: 'evRemarks',     sortable: true, resizable: true, width: 100 },
        // { headerName: '총수익',     field: 'evRemarks',     sortable: true, resizable: true, width: 100 },
        // { headerName: '총수익률',   field: 'evRemarks',     sortable: true, resizable: true, width: 100 },
        { headerName: '비고',       field: 'evRemarks',     sortable: true, resizable: true, width: 300 }, 
      ],
      rowData: [],  //정산검색결과 초기값
      gridOptions: {
        rowSelection: "multiple"
        //onGridReady: 'onGridReady'
      },
    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;
    this.getPartnerList();
    this.getCategoryList();

    if(this.$route.query.bFlag == 'B')
    {
      this.searchSettleData();
    }
  }, 
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    this.dealChargeId = this.user.handlerId
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: {
    openModal(sel_dealCode){
      this.showModal = true

      // let selectedRows = this.gridOptions.api.getSelectedNodes();
      // let sel_rowData = selectedRows.map(node => node.data);
      // let sel_dealCode = sel_rowData[0].dealCode
      this.sel_dealCode = sel_dealCode

      //console.log("sel_dealCode:", this.sel_dealCode);
    },
    closeModal(){
      this.showModal = false;
    },

    //그리드에서 선택된 로우정보취득
    onGridReady(params) { 
      this.gridOptions.api = params.api;    //할당을 해야 함(실서버용)
      const gridApi = params.api;

      //gridApi에 이벤트리스너 등록
      gridApi.addEventListener("selectionChanged:", this.onSelectionChanged)

      // 체크된 노드
      let selectedNodes = gridApi.getSelectedNodes();

      // 선택된 로우의 데이터를 배열로 저장
      let selectedData = selectedNodes.map(node => node.data);

      console.log("selectedData:", selectedData);
    },

    // //그리드에서 선택된 로우정보취득
    // onGridReady(params) {
    //   this.gridOptions.api = params.api;    //할당을 해야 함(실서버용)
    //   const gridApi = params.api;

    //   //gridApi에 이벤트리스너 등록
    //   gridApi.addEventListener("selectionChanged:", this.onSelectionChanged)

    //   // 체크된 노드
    //   let selectedNodes = gridApi.getSelectedNodes();

    //   // 선택된 로우의 데이터를 배열로 저장
    //   let selectedData = selectedNodes.map(node => node.data);

    //   console.log("selectedData:", selectedData);
    // },

    //선택된 그리드값 취득
    onSelectionChanged(){
      let selectedRows = this.gridOptions.api.getSelectedNodes();
      let selectedData = selectedRows.map(node => node.data);
      console.log("selectedData:", selectedData);
    },

    //클릭된 그리드의 dealCode취득
    onCellClicked(event){
      let selectedRows = this.gridOptions.api.getSelectedNodes();
      let sel_rowData = selectedRows.map(node => node.data);
      console.log("sel_rowData:",sel_rowData)
      
      let sel_dealCode = sel_rowData[0].dealCode
      console.log("sel_dealCode:", sel_dealCode);

      //정산서 클릭
      if(event.event.target.innerText === "정산서")
      {
        //this.openModal(sel_dealCode)
        this.goToBizInvoice(sel_dealCode);
      }
      else
      {
        //상세페이지로 이동
        this.goToBizInfoDetail(sel_dealCode);
      }
    },
    // //상세정보로 이동
    // goToBizInfoDetail(dCode){
    //   //console.log("dCode:",dCode)
    //   this.$router.push({path:'/businessInfo_detail', query: {dCode: dCode}})
    // },
    //상세정보로 이동(새탭에서 열기)
    goToBizInfoDetail(dCode){
      const url = `/businessInfo_detail?dCode=${dCode}`;
      window.open(url, '_blank');      
    },

    // //상세정보로 이동
    // goToBizInvoice(dCode){
    //   //console.log("dCode:",dCode)
    //   this.$router.push({path:'/businessInvoice', query: {dCode: dCode}})
    // },

    //새로운 탭에서 열리도록
    // goToBizInvoice(dCode) {
    //   const url = `/businessInvoice?dCode=${dCode}`;
    //   window.open(url, '_blank');
    // },
    
    goToBizInvoice(dCode) {
      const width = window.innerWidth * 0.65; // 현재 창의 너비의 65%
      const height = window.innerHeight * 1; // 현재 창의 높이의 100%
      const left = (window.innerWidth - width) / 2; // 가운데 정렬을 위한 왼쪽 위치 계산
      const top = (window.innerHeight - height) / 2; // 가운데 정렬을 위한 위쪽 위치 계산

      const url = `/businessInvoice?dCode=${dCode}`;
      window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    },


    //memberInfo: 회원정보리스트용 sql
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //거래처리스트
    async getPartnerList() {
      let partnerInfo_tmp = await this.$api("/api/partnerInfo", {});
      
      //거래중지된 파트너 제외
      let partnerInfo = partnerInfo_tmp.filter((item) => {
        return item.pUseFlag === 'Y'
      })

      if(partnerInfo.length > 0)
      {
        this.partnerInfo = partnerInfo
      }
      //console.log("partnerInfo:",this.partnerInfo);
    },

    showRegitEvent() 
    {
      this.createFlag = (this.createFlag === "Y") ? "N" : "Y";
    },

    //여행지역정보
    async getCategoryList() 
    {
      //출발도시
      let dptCtList = await this.$api("/api/searchBasecode", {param: ['ctCd']});
      if(dptCtList.length > 0)
      {
        this.dptCtList = dptCtList
      }
      //console.log("dptCtList:", this.dptCtList);

      //여행국가리스트
      let tripNtList = await this.$api("/api/searchBasecode", {param: ['ntCd']});
      if(tripNtList.length > 0)
      {
        this.tripNtList = tripNtList
      }
      
      //여행지역리스트
      let tripArList = await this.$api("/api/searchBasecode", {param: ['arCd']});
      if(tripArList.length > 0)
      {
        this.tripArList_tmp = tripArList
      }

      //담당자정보
      let chargePList = await this.$api("/api/handlerInfo", {})
      if(chargePList.length > 0)
      {
        this.chargePList = chargePList;
      }
      //console.log("chargePList:", this.chargePList)
    },
    changeNationListforSearch(param){
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaListforSearch(param);
    },   
    viewAreaListforSearch(param1){
      this.tripArListforSearch = this.tripArList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },
    changeNationList(param) {
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaList(param);
    },
    viewAreaList(param1){
      this.tripArList = this.tripArList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },

    //행사코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 500 && chkFlag === "N"; attempts++) {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_dealCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","코드의 생성횟수 한계에 도달하였습니다.<br>관리자에게 문의하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },

    //행사등록
    async registEvent() {
      // //오늘날짜추출
      // let today = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0].replace(/-/g, '');
      // //const time = new Date().toTimeString().split(" ")[0];
      // let tStamp = "";
      // tStamp = new Date().getTime();

      // let dealCode =  "DL-" + today + "-" + tStamp;
      //let rtnCode = ""
      let rtnCode = await this.genCode('DL-')
      this.dealCode = rtnCode
      console.log("dealCode:", this.dealCode)

      //this.dealCode = dealCode
      //console.log("dealCode:", this.dealCode);

      if(!this.dealNameKor) 
      {
        this.$refs.dealNameKor.focus();
        return this.$swal.fire("","행사명(국문)을 입력하세요.","warning");
      }
      // if(!this.dealPartner) 
      // {
      //   this.$refs.dealPartner.focus();
      //   return this.$swal.fire("","거래처를 선택하세요.","warning");
      // }
      if(!this.dealPrice_tot) 
      {
        this.$refs.dealPrice_tot.focus();
        return this.$swal.fire("","판매금액을 입력하세요.","warning");
      }      
      if(!this.reqName) 
      {
        this.$refs.reqName.focus();
        return this.$swal.fire("","예약자명을 입력하세요.","warning");
      }
      if(!this.reqTelNo) 
      {
        this.$refs.reqTelNo.focus();
        return this.$swal.fire("","예약자의 연락처를 입력하세요.","warning");
      }
      if(!this.paxCnt) 
      {
        this.$refs.paxCnt.focus();
        return this.$swal.fire("","행사참가 인원수를 입력하세요.","warning");
      }
      if(!this.evtStartDate) 
      {
        this.$refs.evtStartDate.focus();
        return this.$swal.fire("","행사시작일을 입력하세요","warning");
      }
      if(!this.evtCloseDate) 
      {
        this.$refs.evtCloseDate.focus();
        return this.$swal.fire("","행사종료일을 입력하세요","warning");
      }
      // if(!this.ntCd)
      // {
      //   this.$refs.ntCd.focus();
      //   return this.$swal.fire("","여행국가를 선택하세요.","warning");
      // }
      // if(!this.arCd)
      // {
      //   this.$refs.arCd.focus();
      //   return this.$swal.fire("","여행지역을 선택하세요.","warning");
      // }
      if(!this.dealChargeId) 
      {
        this.$refs.dealChargeId.focus();
        return this.$swal.fire("","담당자를 선택하세요.","warning");
      }
      
      let insId = this.user.handlerId;
      let eUseFlag = "Y"
      let reqStatus = 'EM'

      //프로시저로 교체(deal과 dealhistory 마스터에 등록)
      let ins_dealData = await this.$api("/api/proc_createDeal", {param: 
      [
        this.dealCode, this.dealNameKor, this.dealNameEng, this.reqName, this.reqTelNo, this.reqEmail, this.dptCtCode, this.ntCd, this.arCd, this.evtStartDate, this.evtCloseDate, this.dealPrice_tot, this.evRemarks, reqStatus, insId, this.dealChargeId, this.dealPartner, this.paxCnt, eUseFlag
      ]});

      if(Object.keys(ins_dealData)[0] === 'error') {
        this.$swal.fire('','등록실패('+ ins_dealData.error.sqlMessage +')', 'error')
      }
      else {
        this.$swal.fire('','거래처 정보를 생성했습니다', 'success');
        location.reload();
      }
    },
    //정산데이타 검색(프로시저이용)
    async searchSettleData(){
      let searchNtCd = (this.searchNtCd === "ntAll") ? "" : this.searchNtCd
      let searchArCd = (this.searchArCd === "arAll") ? "" : this.searchArCd

      let settleDataInfo_tmp = await this.$api("/api/proc_searchSettleData", {param: [
        this.searchDuration, this.searchStart, this.searchEnd, this.dealName, this.chargePerson, searchNtCd, searchArCd, this.resName, this.searchCon, ""
      ]});
      console.log("settleDataInfo_tmp:",settleDataInfo_tmp)
      
      if(settleDataInfo_tmp.length > 0)
      {
        let settleDataInfo = settleDataInfo_tmp[1];
        this.settleDataInfo = settleDataInfo_tmp[1];
        this.rowData = this.settleDataInfo;
        console.log("settleDataInfo:", settleDataInfo)
      }

      if(this.dCode != "")
      {
        this.rowData = this.settleDataInfo.filter(item => {
          return item.dealCode === this.dCode.trim()
        })
      }
    },

  }
}
</script>

<style scoped>
.ag-header-cell-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ag-theme-balham ::ng-deep .ag-header-cell .ag-header-cell-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

/** 그리드 컬럼값 컨트롤 */
.red-font-bold {
  color:red;
  font-weight: bold;
}

/**Modal CSS */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;;
  /*z-index: 1;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 5px;
  border: 1px solid #888;
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  /*box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);*/
}

/*
.close {
  color: #aaa;
  float: center;
  font-size: 22px;
  font-weight: bold;
  text-align: end;
  cursor: pointer;
}
*/
</style>