<template>
  <div id="exmong">
    <div id="carouselMain" class="carousel slide" data-bs-ride="carousel"  data-bs-interval="4000">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselMain" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselMain" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselMain" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselMain" data-bs-slide-to="3" aria-label="Slide 4"></button>
      </div>

      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/img/mongol/main1.jpg" class="momain" alt="...">
          <div class="carousel-caption justify-content-center">
            <img src="../assets/img/mongol/mong.png" class="img-fluid">
          </div>
        </div> 
        <div class="carousel-item">
          <img src="../assets/img/mongol/main2.jpg" class="momain" alt="...">
          <div class="carousel-caption justify-content-center">
            <img src="../assets/img/mongol/mong.png" class="img-fluid">
          </div>
        </div>
        <div class="carousel-item">
          <img src="../assets/img/mongol/main3.jpg" class="momain" alt="...">
          <div class="carousel-caption justify-content-center">
            <img src="../assets/img/mongol/mong.png" class="img-fluid"> 
          </div>
        </div>
        <div class="carousel-item">
          <img src="../assets/img/mongol/main4.jpg" class="momain" alt="...">
          <div class="carousel-caption justify-content-center">
            <img src="../assets/img/mongol/mong.png" class="img-fluid">
          </div>
        </div>
      </div>

      <button class="carousel-control-prev" type="button" data-bs-target="#carouselMain" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselMain" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- 몽골 설명 -->
    <section class="motit">
      <div class="container">
        <div class="row">
          <div class="text-center">
            <h3>대자연이 만든 낭만, 몽골 와일드!</h3>
            <p class="main-text">광활하게 펼쳐진 생명력 가득한 초록의 초원<br>
              대지와 맞닿은 가슴 뚫리는 파란 하늘<br>
              구름과 하늘을 투영하는 드넓은 호수<br>
              석양이 만들어 낸 황금빛 물결의 사막<br>
              밤 하늘을 찬란하게, 미지를 탐하는 은하수
            </p>
            <p class="sub-text">대자연이 기획한 낭만 속에서<br>
              온몸으로 녹아드는 <span style="font-size:4rem">몽골 와일드,</span> </p>
            <div class="subject">
              <p>come with us!</p>
              <p class="mt-5">야생<img src="../assets/img/mongol/ma.png" class="ma">, 백패킹 in 몽골</p>
            </div>
          </div>
        </div>
      </div>
    </section>   

    <!-- 사진 단락 -->
    <section class="moimg">
      <div class="container my-2">
        <div class="row col-12">
          <!-- 첫번째 단락 -->
          <div class="col-lg-6">
            <div class="row col-12 gx-1 bg video-controls-hide" style="cursor: pointer">
              <video ref="video" @click="togglePlay()" v-on:ended="showWhere = false" playsinline controls>
                <source src="../assets/img/mongol/tent.mp4" type="video/mp4">
              </video>
              <span class="where" v-show="!showWhere">자연의 일부가 되다! 캠프는 어디에? [클릭]</span>
            </div>
            <div class="row col-12 g-1">
              <div class="col-6 pt-1 moth" @click="showCaro('R')">
                <a href="javascript:void(0)"><figure><img src="../assets/img/mongol/roman.jpg" class="img-fluid" alt="" @click="pcScrollToId('avFile')"></figure></a>
                <span class="thema">ROMAN</span>
                <span class="thema2">대자연 오프로드를 상쾌하게<br>내달리는 '푸르공'을 타고<br>몽골 와일드를 온몸으로 느껴보자</span>
              </div>
              <div class="col-6 pt-1 moth" @click="showCaro('C')">
                <a href="javascript:void(0)"><figure><img src="../assets/img/mongol/camp.jpg" class="img-fluid" alt="" @click="pcScrollToId('avFile')"></figure></a>
                <span class="thema">CAMP</span>
                <span class="thema3">풀과 나무가 바람에 흔들리는 소리,<br>따닥 따닥 장작 타는 소리,<br>대자연의 BGM을 배경삼아<br>숲의 일부가 된 우리</span>
              </div>
            </div>
          </div>

          <!-- 모바일 사진 클릭시 -->
          <div class="container clis mobile">
            <!-- 로망 -->
            <div class="container clis">
              <div class="row">
                <div id="carouselMobile" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="2000">
                  <div v-if="this.caroFlag === 'R'">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src="../assets/img/mongol/ro1.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/ro2.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/ro3.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/ro4.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/ro5.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/ro6.jpg" class="d-block w-100" alt="...">
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselMobile" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselMobile" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>

                  <div v-if="this.caroFlag === 'C'">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src="../assets/img/mongol/c1.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/c2.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/c3.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/c4.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/c5.jpg" class="d-block w-100" alt="...">
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselMobile" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselMobile" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>

                  <div v-if="this.caroFlag === 'N'">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src="../assets/img/mongol/n1.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/n2.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/n3.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/n4.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/n5.jpg" class="d-block w-100" alt="...">
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselMobile" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselMobile" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>

                  <div v-if="this.caroFlag === 'F'">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src="../assets/img/mongol/f1.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/f2.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/f3.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/f4.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol/f5.jpg" class="d-block w-100" alt="...">
                      </div>
                      <div class="carousel-item">
                        <img src="../assets/img/mongol//f6.jpg" class="d-block w-100" alt="...">
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselMobile" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselMobile" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>


          <!-- 두번째 단락 -->
          <div class="col-lg-6">
            <div class="row col-12 g-1">
              <div class="col-6 pb-1 moth" @click="showCaro('N')">
                <a href="javascript:void(0)"><figure><img src="../assets/img/mongol/nature.jpg" class="img-fluid" alt="" @click="pcScrollToId('avFile')"></figure></a>
                <span class="thema">NATURE</span>
                <span class="thema2">하늘,초원,사막,호수 그리고 은하수<br>태초의 모습을 그대로 간직한<br>대자연의 몽골 와일드</span>
              </div>
              <div class="col-6 pb-1 moth" @click="showCaro('F')">
                <a href="javascript:void(0)"><figure><img src="../assets/img/mongol/food.jpg" class="img-fluid" alt="" @click="pcScrollToId('avFile')"></figure></a>
                <span class="thema">WILD FOOD</span>
                <span class="thema3">부러진 나무와 나뭇잎,<br>통통하게 살이 오른 신선한 고기<br>지금 먹고 있는 건<br>음식이 아닌 자연 그 자체</span>
              </div>
            </div>
            <div class="row col-12 g-1">
              <a href="#" data-bs-toggle="modal" data-bs-target="#camping" v-if="this.isMobile == true">
                <video muted autoplay loop>
                  <source src="../assets/img/mongol/camping_m2.mp4" type="video/mp4">
                </video>
              </a>
              <a href="#" data-bs-toggle="modal" data-bs-target="#camping" v-else>
                <video muted autoplay loop>
                  <source src="../assets/img/mongol/camping.mp4" type="video/mp4">
                </video>
              </a>
              <div id="avFile"></div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- pc화면 클릭시 -->
    <div class="container clis pc">
      <div id="carouselPc" class="carousel slide carousel-fade" data-bs-ride="carousel"  data-bs-interval="2000">
        <div v-if="caroFlag === 'R'">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../assets/img/mongol/ro1.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/ro2.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/ro3.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/ro4.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/ro5.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/ro6.jpg" class="d-block w-100" alt="...">
            </div>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#carouselPc" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselPc" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        <div v-if="caroFlag === 'C'">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../assets/img/mongol/c1.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/c2.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/c3.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/c4.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/c5.jpg" class="d-block w-100" alt="...">
            </div>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#carouselPc" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselPc" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        <div v-if="caroFlag === 'N'">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../assets/img/mongol/n1.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/n2.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/n3.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/n4.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/n5.jpg" class="d-block w-100" alt="...">
            </div>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#carouselPc" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselPc" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>        

        <div v-if="caroFlag === 'F'">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../assets/img/mongol/f1.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/f2.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/f3.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/f4.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/f5.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/img/mongol/f6.jpg" class="d-block w-100" alt="...">
            </div>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#carouselPc" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselPc" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>


    <!-- 선택옵션 -->
    <section class="moption">
      <div class="container">
        <div class="row">
          <div class="text-center">
            <img src="../assets/img/mongol/play.png" class="op-top">
            <h4>놓치면 후회막심!</h4>
            <div class="row">
              <div class="col-lg-4 px-1 my-3">
                <div class="card">
                  <img src="../assets/img/mongol/op1.jpg" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">초원을 달리는 승마</h5>
                    <h6 class="card-subtitle mb-2 text-muted">(선택옵션 20$)</h6>
                    <p class="card-text">몽골의 광활한 대자연을 말을 타고 마음껏 즐겨보세요!</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 px-1 my-3">
                <div class="card">
                  <img src="../assets/img/mongol/Rafting.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">톨강을 따라 자연을 만끽하는 래프팅</h5>
                    <h6 class="card-subtitle mb-2 text-muted">(선택옵션 - 별도문의)</h6>
                    <p class="card-text">스릴 넘치는 강을 따라 신나는 래프팅!</p>
                  </div>
                </div>
              </div>
              
              <div class="col-lg-4 px-1 my-3">
                <div class="card">
                  <img src="../assets/img/mongol/ATV.png" class="card-img-top" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">원시림을 내달리는 ATV 체험</h5>
                    <h6 class="card-subtitle mb-1 text-muted">(선택옵션 30분 40$ / 1시간 80$)</h6>
                    <p class="card-text">몽골의 대자연 속으로 직접 들어갈 수 있는 기회!</p>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </section>  


    <!-- 스케줄 -->
    <div>
      <img src="../assets/img/mongol/schedule.jpg" class="img-fluid">
    </div>

    <!-- 예약 -->
    <div class="container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('T-202358-1683534859762','T')">
          <p class="go">예약하러 가기 <i class="bi bi-arrow-right align-middle"></i></p>
        </a>
      </div>
    </div>


    <!-- 모달팝업 -->
    <div class="modal fade" id="camping" tabindex="-1" aria-labelledby="campingLabel" aria-hidden="true">
      <div class="modal-dialog modal-full modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <video muted autoplay loop>
              <source src="../assets/img/mongol/camping.mp4" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      // reqList: [],
      // rProc: [],
      isMobile: false, //디바이스 종류체크(m or pc)
      playing: false,
      showWhere: false, //어딧을까 표시용 플래그
      caroFlag: '' //표시할 카로우셀
    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요',
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'});
    // }
    this.isMobile = this.checkIfMobile();
  },
  methods: {
    goToDetail(productId, salesRoute) 
    {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },      
    checkIfMobile() {
      const userAgent = window.navigator.userAgent;
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return mobileRegex.test(userAgent);
    },
    //PC화면에서만 작동제한
    pcScrollToId(id) 
    {
      if (window.innerWidth > 992) {
        this.scrollToId(id);
      }
    },
    /** 지정된 id로 화면이동 */
    scrollToId(id) 
    {
      const element = document.getElementById(id);
      if (element) 
      {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },

    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    togglePlay() 
    {
      //비디오 영상이 시작되면 자막사라지고, 끝나면 다시 보임
      this.showWhere = true;
      const video = this.$refs.video;
      if (video.paused) 
      {
        video.play();
        this.playing = true;
      } 
      else 
      {
        video.pause();
        this.playing = false;
      }
    },
    showCaro(flag)
    {
      this.caroFlag = flag
    }
  }
}
</script>



<style>
@import url('https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&family=Nanum+Myeongjo&family=Sunflower:wght@300&display=swap');

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@media (min-width: 991px) {
  .pc {
    display: block;
  }
}
@media (max-width: 992px) {
  .pc {
    display: none;
  }
}


/* 메인사진 */
#exmong .carousel-item .momain{
  min-height:600px;
  max-height:1100px;
  object-fit:cover;
  /* -webkit-filter: brightness(0.7);
  filter: brightness(0.7); */
}
#exmong .carousel-caption{
  font-family: 'East Sea Dokdo', cursive;
  --bs-body-font-family: 'East Sea Dokdo', cursive;
  /* font-size: 2.8rem; */
  text-align: left;
  animation: fadeInUp 1.5s;
  left:3%;
  bottom:8%;
}
#exmong .carousel-caption img{
  width:17rem
}
@media (min-width:1200px){
  #exmong .carousel-caption{
    /* font-size: 5rem; */
    left:17%;
    bottom:10%;
  }
  #exmong .carousel-caption img{
  width:30rem
}
}

/* 설명 */
#exmong .motit{
  margin:8rem 0;
  color:#000
}
#exmong .motit h3{
  font-family: 'East Sea Dokdo', cursive;
  --bs-body-font-family: 'East Sea Dokdo', cursive;
  font-size:4rem;
  padding:.5rem 0 3rem
}
/* #exmong .motit .main-text{
  font-family: 'Sunflower', sans-serif;
  --bs-body-font-family:'Sunflower', sans-serif;
  font-size:1.2rem;
  color:#777;
  line-height: 70px;
} */
#exmong .motit .main-text{
  font-family: 'Nanum Myeongjo', serif;
  --bs-body-font-family:'Nanum Myeongjo', serif;
  font-size:1rem;
  color:#777;
  line-height: 60px;
}
#exmong .motit .sub-text{
  font-family: 'East Sea Dokdo', cursive;
  --bs-body-font-family: 'East Sea Dokdo', cursive;
  padding-top:4rem;
  color:#000;
  font-size:2.3rem;
}
#exmong .motit .subject{
  font-family: 'East Sea Dokdo', cursive;
  --bs-body-font-family: 'East Sea Dokdo', cursive;
  padding-top:1rem;
  color:#000;
  font-size:3.5rem;
  font-style: italic;
}
#exmong .motit .subject .ma{
  width: auto;
  height:45px;
  margin-right:5px
}
@media (max-width:575px){
  #exmong .motit h3{
    font-size:2.3rem;
  }
  #exmong .motit .sub-text{
    font-size:2rem
  }
  #exmong .motit .subject{
    font-size:2.5rem
  }
  #exmong .motit .subject .ma{
    height:35px
  }
}


/* 어디잇게용 */
#exmong .moimg{
  font-family: 'East Sea Dokdo', cursive;
  --bs-body-font-family: 'East Sea Dokdo', cursive;
}
#exmong .moimg .bg{
  position: relative;
}
#exmong .moimg .noh {
  margin: 0px auto;
}
#exmong .moimg .yesh {
  position: absolute;
  top:0;
  left:0;
  margin-top:0;
  height:auto;
  display: none;
}
#exmong .moimg .bg .where{
  position: absolute;
  bottom:5%;
  left:0;
  font-size:2.5rem;
  color:#fff;
}
#exmong .moimg .bg:hover .yesh{
  display: block;
}
@media (max-width:575px){
  #exmong .moimg .bg .where{
    font-size:1.5rem;
  }
}


/* 사진 */
#exmong .moimg .moth{
  position:relative;
}
#exmong .moimg figure{
  background: #000;
  margin:0;
}
#exmong .moimg figure img {
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
#exmong .moimg figure:hover img {
  opacity: .5;
}

#exmong .moimg .thema{
  position:absolute;
  bottom:8%;
  left:50%;
  color:#fff;
  font-size:2.5rem;
  transform: translate(-50%);
  transition:all 0.1s 
}
#exmong .moimg .moth:hover .thema{
  visibility:collapse
}
#exmong .moimg .thema2, #exmong .moimg .thema3{
  position:absolute;
  text-align:left;
  bottom:7%;
  left:5%;
  color:#fff;
  font-size:2rem;
  line-height:35px;
  transition:all 0.3s;
  visibility:collapse;
  pointer-events: none;
}
#exmong .moimg .moth:hover .thema2{
  visibility:visible;
  bottom:50%;
}
#exmong .moimg .moth:hover .thema3{
  visibility:visible;
  bottom:35%;
}
@media (max-width:767px){
  #exmong .moimg .thema{
    font-size:2.3rem;
  }
  #exmong .moimg .thema2, #exmong .moimg .thema3{
    font-size:1.5rem;
    line-height:30px;
  }
  #exmong .moimg .moth:hover .thema2{
    bottom:48%;
  }
  #exmong .moimg .moth:hover .thema3{
    bottom:34%;
  }
}
@media (max-width:434px){
  #exmong .moimg .thema{
    font-size:1.5rem;
  }
  #exmong .moimg .thema2, #exmong .moimg .thema3{
    font-size:1.2rem;
    line-height:20px;
  }
}
@media (max-width:375px){
  #exmong .moimg .thema2, #exmong .moimg .thema3{
    font-size:.9rem;
    line-height:15px;
  }
}


/* 동영상 */
#exmong .moimg video{
  width:100%;
  object-fit: cover
}
@media (min-width:992px){
  #exmong .moimg video{
    height:348px; 
  }
}
@media (min-width:1200px){
  #exmong .moimg video{
    height:416px;
  }
}
@media (min-width:1400px){
  #exmong .moimg video{
    height:483px;
  }
}
/* 팝업모달창 */
#exmong .modal-header{
  border-bottom: none;
}
#exmong .modal-full {
  width: 97vw;
  max-width: none;
  height: 90%;
}
#exmong .modal-body{
  padding:0
}
#exmong .modal-body video{
  width:100%;
  height:90%
}
@media (min-width:2000px){
  #exmong .modal-full{
    width: 80vw;
  }
  #exmong .modal-body{
    margin-bottom:50px
  }
  #exmong .modal-body video{
    width:85%
  }
}



/* 추가 캐러셀 */
#exmong .pc img{
  height:50rem;
  object-fit: cover;
}

/* 모바일버전 */
#exmong .moimg .mobile{
  padding:.3rem 0
}
#exmong .moimg .mobile img{
  height: 20rem 
}


/* 선택옵션 */
#exmong .moption{
  margin:8rem 0;
  padding:4rem 0;
  color:#000;
  position: relative;
}
#exmong .moption::before{
  content: "";
  background: url(../assets/img/mongol/background.jpg);
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.7;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
#exmong .moption .op-top{
  position: relative;
  height:4rem
}
#exmong .moption h4{
  font-family: 'East Sea Dokdo', cursive;
  --bs-body-font-family: 'East Sea Dokdo', cursive;
  position: relative;
  font-size:3.5rem;
  padding:1rem 0
}
#exmong .moption .card{
  border:none
}
#exmong .moption .card img{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height:20rem;
  object-fit: cover;
}
#exmong .moption .card .card-body{
  text-align: left;
  padding:.5rem 0.5rem
}
#exmong .moption .card .card-title{
  font-size:1.3rem;
  font-weight: 600;
  padding:.5rem 0 .2rem
}
#exmong .moption .card .card-subtitle{
  font-size:.8rem
}
#exmong .moption .card .card-text{
  font-size:1.1rem;
  padding:.5rem 0 1rem
}


/* 예약 */
#exmong .resergo{
  margin-top:5rem
}
#exmong .resergo .go{
  border:1px solid #777;
  padding:15px;
  transition:all .5s
}
#exmong .resergo a{
  color:#000;
  font-size:1.2rem;
  transition:all .5s
}
#exmong .resergo a:hover .go{
  background-color: #000;
  color:#fff;
  padding-left:30px
}
#exmong .resergo .bi::before{
  vertical-align:0.5px;
  padding-left:5px
}

/** 동영상 컨트롤바 */
.video-controls-hide video::-webkit-media-controls {
  display: none !important;
}
.video-controls-hide video::-webkit-media-controls-enclosure {
  height: 0 !important;
  overflow: hidden !important;
}
.where {
  display: block;
}
</style>

