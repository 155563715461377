<template>
  <div id="exure">

    <!-- 메인사진 -->
    <section class="urmain">
      <div class="container">
        <img src="../assets/img/ureshino/text.png" class="img-fluid">
        <p>- 힐링팩이란? 일본의 자연친화적 캠핑과 전통 천연온천 료칸 숙박이 포함된 체험형 패키지 -</p>
      </div>
    </section>  


    <!-- 우레시노 지도 -->
    <section class="mt-5">
      <div class="container">
        <div class="row justify-content-center">
          <img src="../assets/img/ureshino/map.jpg" class="img-fluid control">
        </div>
      </div>
    </section>


    <!-- 포인트 -->
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <img src="../assets/img/ureshino/point.jpg" class="img-fluid control">
        </div>
      </div>
    </section>


    <!-- 히로코우라 -->
    <section class="urcont">
      <div class="urinfo">
        <img src="../assets/img/ureshino/camp.png" class="img-fluid mtit">
        <img src="../assets/img/ureshino/camp2.png" class="img-fluid etc">
      </div>
    </section> 

    <!-- 히로코우라 사진 -->
    <section class="urpic">
      <div class="container">
        <div class="row">
          <p class="utit">우레시노 히로코우라 캠프장</p>
          <p class="uinfo">휴대폰의 전파도 들어오지 않는 자연 그대로 보존된 숲에 둘러싸인 히로코우라 캠프장.<br>
            이른 아침 호수를 옅게 뒤덮은 몽환적인 물안개, 물가에 자리잡은 메타세콰이어 나무와
            어우러진 그림같은 풍경, 나무들 사이로 기분 좋게 불어오는 바람.
            가만히 앉아 햇살이 부서지는 호수를 바라보며 커피를 마시고, 해가 뉘엿뉘엿 넘어갈 즈음엔
            장작 타는 소리와 함께 출출한 배를 채울 바베큐 파티.<br>
            사람의 발길이 닿지 않는 숲이 주는 힐링을 만끽하세요.
          </p>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h1.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h5.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h6.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h7.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h8.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/h9.jpg" class="img-fluid">
          </div>
        </div>       
      </div>
    </section>

    <!-- 방갈로 소개 pc -->
    <section class="bungal1">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 gx-2">
            <img src="../assets/img/ureshino/h12.jpg" class="img-fluid lefthi">
          </div>
          <div class="col-lg-6 gx-2">
            <div class="row">
              <div class="col-12 text-start hitext">
                <img src="../assets/img/ureshino/1.png" class="img-fluid bicon">
                <p class="hbmain">히로코우라 캠프장, 방갈로 소개!</p>
                <p class="hbinfo">
                  * 5인 1실 기준(단독 사용시 문의)<br>
                  * 전등 및 콘센트 구비<br>
                  * 자연친화적 목재 건물<br>
                  * 침낭 및 개별 방한 도구 지참
                </p>
              </div>
              <div class="col-6 col-lg-8 py-2">
                <img src="../assets/img/ureshino/h10.jpg" class="img-fluid rihi1">
              </div>
              <div class="col-6 col-lg-12 loca py-2">
                <img src="../assets/img/ureshino/h11.jpg" class="img-fluid rihi2">
              </div>
            </div>
            
          </div>
          

        </div>
      </div>
    </section>
    <!-- 방갈로 소개 mobile-->
    <section class="bungal2">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 gx-2">
            <div class="row">
              <div class="col-12 text-center hitext">
                <img src="../assets/img/ureshino/1.png" class="img-fluid bicon">
                <p class="hbmain">히로코우라 캠프장, 방갈로 소개!</p>
                <p class="hbinfo">
                  * 5인 1실 기준(단독 사용시 문의)<br>
                  * 전등 및 콘센트 구비<br>
                  * 자연친화적 목재 건물<br>
                  * 침낭 및 개별 방한 도구 지참
                </p>
              </div>
              <div class="col-12 gx-2 py-2">
                <img src="../assets/img/ureshino/h10.jpg" class="img-fluid rihi1">
              </div>
              <div class="col-12 gx-2 py-2">
                <img src="../assets/img/ureshino/h11.jpg" class="img-fluid rihi2">
              </div>
            </div>
            
          </div>
          <div class="col-12 gx-3">
            <img src="../assets/img/ureshino/h12.jpg" class="img-fluid lefthi">
          </div>
        </div>
      </div>
    </section>


    <!-- 료칸 -->
    <section class="urcont rokan">
      <div class="urinfo">
        <img src="../assets/img/ureshino/omo.png" class="img-fluid mtit">
        <img src="../assets/img/ureshino/omo2.png" class="img-fluid etc">
      </div>
    </section> 

    <!-- 료칸 사진 -->
    <section class="urpic">
      <div class="container">
        <!-- 세이류 -->
        <div class="row">
          <p class="utit">우레시노 온천 료칸 유야도 세이류</p>
          <p class="uinfo">100년의 역사를 새겨운 우레시노의 '다이쇼야' 그룹이 운영하는 3개의 온천 료칸 중 한 곳으로,
            2층짜리 일본풍 건물과 넓은 정원이 인상적인 전통 온천 료칸 입니다.<br>
            세심하고 섬세한 배려가 곳곳에 녹아있으며 제철 재료로 대접하는 식사가 일품입니다.<br>
            '다이쇼야' 그룹이 운영하는 5개 온천을 모두 체험하실 수 있는게 특징입니다.
          </p>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/se1.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/se2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/se3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/se4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/se5.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/se6.jpg" class="img-fluid">
          </div>
        </div>

        <!-- 와라쿠엔 -->
        <div class="row mt-5">
          <p class="utit">우레시노 온천 료칸 와라쿠엔</p>
          <p class="uinfo">2019년 미슐랭 가이드 3스타로 평가 받은 우레시노의 유명 천연 온천 료칸 '와라쿠엔'.
            우레시노 명물 녹차물을 우려낸 녹차 온천탕과 정갈하고 일본의 아름다움을 담아낸 일본식
            정원, 제철 요리와 사가의 명물 사가규(牛)를 맛볼 수 있는 전통 가이세키 요리.<br>
            와라쿠엔이 선사하는 'おもてなし(최고의 대접, 환대)' 일본 문화를 느껴보세요. 
          </p>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/wa1.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/wa2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/wa3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/wa4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
              <img src="../assets/img/ureshino/wa5.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4 gx-2 py-1">
            <img src="../assets/img/ureshino/wa6.jpg" class="img-fluid">
          </div>
        </div>
      </div>
    </section>


    <!-- 예약 -->
    <div class="container resergo">
      <div class="row col-lg-5 mx-auto">
        <a href="javascript:void(0)" @click="goToDetail('T-202368-1686185654664','T')">
          <p class="go">예약하러 가기 <i class="bi bi-arrow-right align-middle"></i></p>
        </a>
      </div>
    </div>    
  </div>
  
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {

    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요',
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'});
    // }
    //this.isMobile = this.checkIfMobile();
  },
  methods: {
    goToDetail(productId, salesRoute) 
    {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
  }
}
</script>



<style>
@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@300;400;500;700&display=swap');



/* 메인 */
#exure .urmain{
  position: relative;
  background: url(../assets/img/ureshino/back.jpg) center;
  background-size: cover;
  height:75vh
}
#exure .urmain img{
  position: absolute;
  top:45%;
  left:50%;
  transform: translate(-50%, -50%);
  width:33%
}
#exure .urmain p{
  width:100%;
  position: absolute;
  bottom:5px;
  left:50%;
  transform: translateX(-50%);
  color:#fff;
  font-size:1.2rem
}



/* 설명 */
#exure .urcont{
  margin:4rem 0 1rem;
  padding:8rem 0;
  color:#000;
  position: relative;
}
#exure .urcont:before{
  content: "";
  background: url(../assets/img/ureshino/camp.jpg);
  background-attachment: fixed;
  background-size: cover;
  filter: brightness(60%);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
#exure .rokan{
  margin-top:10rem;
}
#exure .rokan:before{
  content: "";
  background: url(../assets/img/ureshino/rokan.jpg);
  background-attachment: fixed;
  background-size: cover;
  filter: brightness(60%);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

#exure .urcont .urinfo .mtit{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width:25%
}
#exure .urcont .urinfo .etc{
  position: absolute;
  bottom: 20px;
  left:55%;
  width:20%;
}




/* 사진 */
#exure .urpic{
  margin-top:3rem
}
#exure .urpic img{
  height:20rem;
  object-fit: cover;
}
#exure .urpic .utit{
  font-family: 'Hahmlet', serif;
  text-align: left;
  font-size:2rem;
  font-weight: 700;
  margin-bottom: .5rem;
}
#exure .urpic .utit::after {
  content: "";
  width: 5rem;
  height: 3px;
  display: inline-block;
  background: #000;
  margin: 0 10px 7px;
}
#exure .urpic .uinfo{
  text-align: left;
  padding-left:5px;
  font-size:1.1rem
}


/* 방갈로 소개 */
#exure .bungal1{
  display: none;
  margin-top:5rem
}
#exure .bungal1 .hitext{
  padding-left:30px
}
#exure .bungal1 .hitext .bicon{
  width:8rem
}
#exure .bungal1 .hitext .hbmain{
  font-family: 'Hahmlet', serif;
  font-size:2.3rem;
  padding-left:10px;
  font-weight: 700;
}
#exure .bungal1 .hitext .hbinfo{
  font-size: 1.3rem;
  padding-left:20px
}
#exure .bungal1 .lefthi{
  border-top-right-radius:100px;
  border-top-left-radius:100px;
}
#exure .bungal1 .rihi1, #exure .bungal1 .rihi12{
  width:35rem
}
#exure .bungal1 .rihi1{
  border-top-right-radius:50px;
  border-bottom-left-radius:50px;
}
#exure .bungal1 .rihi2{
  border-top-left-radius:50px;
  border-bottom-right-radius:50px;
}
#exure .bungal1 .loca{
  padding-left:10rem
}
@media (max-width:1200px){
  #exure .bungal1 .hitext .bicon{
    width:7rem
  }
  #exure .bungal1 .hitext .hbmain{
    font-size:1.8rem;
  }
  #exure .bungal1 .hitext .hbinfo{
    font-size: 1rem;
  }
}
@media (min-width:991px){
  #exure .bungal1{
    display: block;
  }
}

#exure .bungal2{
  display: none;
  margin-top:5rem
}
#exure .bungal2 .hitext .bicon{
  width:8rem
}
#exure .bungal2 .hitext .hbmain{
  font-family: 'Hahmlet', serif;
  font-size:1.6rem;
  font-weight: 700;
}
#exure .bungal2 .hitext .hbinfo{
  font-size: 1.1rem;
  line-height: 30px;
}
#exure .bungal2 .rihi1{
  border-top-right-radius:30px;
  border-bottom-left-radius:30px;
}
#exure .bungal2 .rihi2{
  border-top-left-radius:30px;
  border-bottom-right-radius:30px;
}
@media (min-width:576px){
  #exure .bungal2 .hitext .hbmain{
    font-size:1.9rem;
  }
}
#exure .bungal2 .lefthi{
  margin-top:1rem;
  border-top-right-radius:100px;
  border-top-left-radius:100px;
}
@media (max-width:990px){
  #exure .bungal2{
    display: block;
  }
}



/* 예약 */
#exure .resergo{
  margin-top:5rem
}
#exure .resergo .go{
  border:1px solid #777;
  padding:15px;
  transition:all .5s
}
#exure .resergo a{
  color:#000;
  font-size:1.2rem;
  transition:all .5s
}
#exure .resergo a:hover .go{
  background-color: #000;
  color:#fff;
  padding-left:30px
}
#exure .resergo .bi::before{
  vertical-align:0.5px;
  padding-left:5px
}

@media (max-width:1500px){
  #exure .urmain img{
    width:60%
  }
}
@media (min-width:768px) and (max-width:1200px){
  #exure .urmain img{
    width:65%
  }
  #exure .urcont{
    padding:6rem 0
  }
  #exure .urcont .urinfo .mtit, #exure .urcont .urinfo .etc{
    width:40%
  }
}


@media (max-width:767px){
  #exure .urmain{
    height:60vh
  }
  #exure .urmain img{
    width:95%;
    top:50%;
  }
  #exure .urmain p{
    bottom:0;
    font-size:.8rem;
    padding:0 20px
  }
  #exure .urcont{
    padding:5rem 0;
  }
  #exure .urcont .urinfo .mtit{
    width:70%
  }
  #exure .urcont .urinfo .etc{
    width:40%;
    left:50%;
    transform: translateX(-50%);
  }
  #exure .urpic img{
    height: 10rem;
  }
  #exure .urpic .utit{
    font-size:1.5rem;
  }
  #exure .urpic .utit::after{
    width:3rem
  }
  #exure .urpic .uinfo{
    font-size:.9rem
  }
  #exure .rokan{
    margin-top:7rem
  }
}

@media (max-width:430px){
  #exure .urpic .utit{
    font-size:1.2rem;
  }
  #exure .urpic .utit::after{
    width:1rem
  }
} 

@media (min-width:1400px){
  #exure .control{
    width:80%
  }
}
</style>

