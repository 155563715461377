<template>
  <main class="mt-3">
  <div class="container">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center"><u>&nbsp;행  사  정  보&nbsp;</u></h2>
        <div class="text-warning" v-if="this.eventInfo.evtMFlag === 'N'">
          (비회원)
        </div>
          <div class="text-success text-start fs-6 mb-2">
            <b>[행사정보&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('U','E');">&nbsp;수정</i>&nbsp; ]</b>&nbsp;&nbsp;
            <button type="button" class="btn btn-sm btn-outline-primary align-right" @click="callWinCalc();">
              <i class="bi bi-calculator-fill">계산기이용</i>
            </button>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">행사코드</th>
                <td class="col-md-4 text-primary">{{this.eventCode}}</td>
                <th class="table-secondary col-md-2">상태</th>
                <td class="col-md-4">
                  <div class="input-group">
                      <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="this.eventInfo.reqStatus">
                      <!-- <option :value="noVal" disabled>{{this.reserveInfo.procName}}</option> -->
                      <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
                      <option value="nonRP">비회원결제요청(카톡전용)</option>
                      <option value="nonCO">비회원확정(카톡전용)</option>
                      <option value="nonCX">비회원취소(카톡전용)</option>
                    </select>
                    <button type="button" class="btn btn-warning btn-sm me-1" @click="callKakao(this.eventInfo.reqStatus);">
                      <font style="font-size:small" class="align-bottom">카톡발송(결제요청 또는 취소)</font>
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>행사명(국문)</th>
                <td class="col-md-4"><input type="text" class="form-control" ref="txt_eventNameKor" v-model="this.eventInfo.eventNameKor"></td>
                <th class="table-secondary col-md-2">행사명(영문)</th>
                <td class="col-md-4"><input type="text" class="form-control" ref="txt_eventNameKor" v-model="this.eventInfo.eventNameEng"></td>
              </tr>              
              <tr>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>예약자/연락처/이메일</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" class="form-control form-control-sm" style="font-size: xx-small" ref="txt_reqName" v-model="this.eventInfo.reqName">
                    <input type="text" class="form-control form-control-sm" style="font-size: xx-small" ref="txt_reqTelNo" v-model="this.eventInfo.reqTelNo">
                    <input type="text" class="form-control form-control-sm" style="font-size: xx-small" ref="txt_conEmail" v-model="this.eventInfo.conEmail">
                  </div>
                  
                </td>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>신청일</th>
                <td class="col-md-4 text-start p-3">
                  {{ this.getDateFormat(this.eventInfo.reqDate) }}
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>출발지/여행국가/지역</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <!-- 출발도시 -->
                    <select class="form-select form-select-sm text-primary" aria-label=".form-select-sm" ref="sel_dptCtCode" v-model="eventInfo.dptCtCode">
                      <option :value="cityList[i].baseCode" :key=i v-for="(baseCode,i) in cityList">{{cityList[i].codeNameKor}}</option>
                    </select>
                    <!-- 여행국가 -->
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripNation" v-model="eventInfo.ntCd" @change="changeNationList()">
                      <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
                    </select>
                    <!-- 여행지역 -->
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripArea" v-model="eventInfo.arCd">
                      <option :value="areaList[j].baseCode" :key=j v-for="(baseCode,j) in areaList">{{areaList[j].codeNameKor}}</option>
                    </select>
                  </div>
                </td>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>행사기간</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">개시</span>
                    <input type="date" class="form-control" style="font-size: xx-small" ref="txt_evtStartDate" v-model="this.eventInfo.evtStartDate">
                    <span class="input-group-text" id="basic-addon1">종료</span>
                    <input type="date" class="form-control" style="font-size: xx-small" ref="txt_evtCloseDate" v-model="this.eventInfo.evtCloseDate">
                  </div>                  
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">포함사항</th>
                <td class="col-md-4">
                  <textarea class="form-control text-primary" ref="txt_includeContent" id="floatingTextarea" placeholder="490자 이내" style="height:80px" v-model="this.eventInfo.includeContent" maxlength="499"></textarea>
                </td>
                <th class="table-secondary col-md-2">불포함사항</th>
                <td class="col-md-4">
                  <textarea class="form-control text-danger" placeholder="490자 이내" ref="txt_notInCludeContent" id="floatingTextarea" style="height:80px" v-model="this.eventInfo.notIncludeContent" maxlength="499"></textarea>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2" rowspan="2">
                  <i class="bi bi-check text-danger"></i>금액 X 인원
                </th>
                <td class="col-md-4" rowspan="2">
                    <div class="text-success text-start">
                      <i class="bi bi-align-end text-primary"></i> {{this.eventInfo.evtStartDate}} ~ {{this.eventInfo.evtCloseDate}}
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">성인</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_adultPrice" v-model="this.eventInfo.adultPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 max=100 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_adultCnt" v-model="this.eventInfo.adultCnt">
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totAdultPrice" :value="getCurrencyFormat(this.eventInfo.adultPrice * this.eventInfo.adultCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totAdultPrice" :value="this.eventInfo.adultPrice * this.eventInfo.adultCnt" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">소인</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_childPrice" v-model="this.eventInfo.childPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 max=100 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_childCnt" v-model="this.eventInfo.childCnt">
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totChildPrice" :value="getCurrencyFormat(this.eventInfo.childPrice * this.eventInfo.childCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totChildPrice" :value="this.eventInfo.childPrice * this.eventInfo.childCnt" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">유아</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_infPrice" v-model="this.eventInfo.infPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 max=100 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_infCnt" v-model="this.eventInfo.infCnt">
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totInfPrice" :value="getCurrencyFormat(this.eventInfo.infPrice * this.eventInfo.infCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totInfPrice" :value="this.eventInfo.infPrice * this.eventInfo.infCnt" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">할인</span>
                      <input type="number" min=0 class="form-control text-end" style="font-size:small" ref="txt_discountPrice" v-model="this.eventInfo.discountPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 max=100 class="form-control-sm text-small text-end col-md-2" style="font-size:small" ref="txt_discountCnt" v-model="this.eventInfo.discountCnt">
                      <span class="input-group-text">=</span>
                      <input type="text" min=0 class="form-control text-end" style="font-size:small" ref="txt_totDiscountPrice" :value="getCurrencyFormat(this.eventInfo.discountPrice * this.eventInfo.discountCnt)" disabled>
                      <input type="hidden" min=0 class="form-control text-end" style="font-size:small" ref="txt_totDiscountPrice" :value="this.eventInfo.discountPrice * this.eventInfo.discountCnt" disabled>
                    </div>
                </td>

                <th class="table-secondary col-md-2 align-middle">
                  추가사항 / 금액
                  <p class="mt-2">
                    <button type="button" class="btn btn-sm btn-outline-primary align-right" @click="addItem()">
                      추가
                    </button>
                  </p>
                </th>
                <td class="col-md-4 text-start small">
                  <div class="col">
                    <div class="input-group mb-1" :key=i v-for="(addItemInfo, i) in this.addItemInfo">
                      <div class="col">
                        <div class="input-group mb-1">
                          <input type="text" class="form-control" placeholder="추가사항" ref="txt_addItemName" v-model="addItemInfo.addContentName">
                          <input type="number" class="form-control text-end" ref="txt_addItemPrice" v-model="addItemInfo.addContentPrice">
                          <span class="input-group-text">원</span>&nbsp;&nbsp;
                          <input class="form-check-input my-auto" type="checkbox" v-model="addItemInfo.addContentFlag" true-value="Y" false-value="N" id="chb_addItemFlag">
                        </div>
                      </div>
                    </div>
                    <div v-if="this.vCnt <= 20">
                      <div class="input-group mb-1" :id=via :key="via" v-for="via in this.vCnt">
                        <div class="align-self-center col-md-1" v-if="this.vCnt === via">
                          <i class="bi bi-dash-circle-fill text-danger" @click="removeItem(via)"></i>
                        </div>
                        <div class="col">
                          <div class="input-group mb-1">
                            <input type="text" class="form-control" placeholder="추가사항" ref="txt_vatPrice" v-model="addItemName[via]">
                            <input type="number" class="form-control text-end" ref="txt_vatPrice" v-model="addItemPrice[via]">
                            <span class="input-group-text">원</span>&nbsp;&nbsp;
                            <input class="form-check-input my-auto" type="checkbox" v-model="addItemFlag[via]" true-value="Y" false-value="N" id="chb_addItemFlag">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-mb-2">
                  추가마진 / 금액(비율)
                  <br>
                  <small class="text-secondary">(총액기준)</small>
                </th>
                <td class="col-md-4 text-start small">
                  <div class="input-group mb-1">
                    <input type="number" class="form-control text-end" ref="txt_basicMargin" v-model="this.eventInfo.addMargin">
                    <select class="form-select form-select-md text-end" aria-label=".form-select-md" v-model="this.eventInfo.addMarginMethod">
                      <option value="A" id="promoMethod" selected>원</option>
                      <option value="P" id="promoMethod">%</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">
                  추가할인
                  <p class="mt-2">
                    <button type="button" class="btn btn-sm btn-outline-primary align-right" @click="addDisItem()">
                      추가
                    </button>
                  </p>
                </th>
                <td class="col-md-4 text-start small">
                  <div class="col">
                    <div class="input-group mb-1" :key=j v-for="(addDisInfo,j) in this.addDisInfo">
                      <div class="col">
                        <div class="input-group mb-1">
                          <input type="text" class="form-control" placeholder="추가할인" ref="txt_addDisName" v-model="addDisInfo.addDiscountName">
                          <input type="number" class="form-control text-end" ref="txt_addDisPrice" v-model="addDisInfo.addDiscountPrice">
                          <span class="input-group-text">원</span>&nbsp;&nbsp;
                          <input class="form-check-input my-auto" type="checkbox" v-model="addDisInfo.addDiscountFlag" true-value="Y" false-value="N" id="chb_addDisFlag">
                        </div>
                      </div>
                    </div>                    
                    <div v-if="this.dCnt <= 20">
                      <div class="input-group mb-1" :name=dia :key="dia" v-for="dia in this.dCnt">
                        <div class="align-self-center col-md-1" v-if="this.dCnt === dia">
                          <i class="bi bi-dash-circle-fill text-danger" @click="removeDisItem(dia)"></i>
                        </div>
                        <div class="col">
                          <div class="input-group mb-1">
                            <input type="text" class="form-control" placeholder="추가할인" ref="txt_addDisName" v-model="addDisName[dia]">
                            <input type="number" class="form-control text-end" ref="txt_addDisPrice" v-model="addDisPrice[dia]">
                            <span class="input-group-text">원</span>&nbsp;&nbsp;
                            <input class="form-check-input my-auto" type="checkbox" v-model="addDisFlag[dia]" true-value="Y" false-value="N" id="chb_addDisFlag">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <th class="table-secondary col-md-2">
                  <button type="button" class="btn btn-primary btn-sm me-1" @click="calcCstPrice();">
                    <font style="font-size:small" class="align-bottom">상담가격계산</font>
                  </button>
                  <p><samll class="text-secondary">(소숫점 이하 절사)</samll></p>
                </th>
                <td class="col-md-4">
                  <div class="input-group">
                    <div class="text-secondary" v-if="this.eventInfo.cstPrice_org == this.eventInfo.cstPrice_new">
                      * 계산전: {{getCurrencyFormat(this.eventInfo.cstPrice_org)}}
                    </div>
                    <div class="text-secondary" v-else>
                      * 계산전: {{getCurrencyFormat(this.eventInfo.cstPrice_new)}}
                    </div>                    
                  </div>                  
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_cstPrice" v-model="this.cstPrice" readonly>
                    <span class="input-group-text">원</span>
                  </div>
                  <div class="text-start">
                    &nbsp;<small><small class="text-secondary">-수식: 인원가격+추가금액+추가마진-추가할인-포인트(사용)</small></small>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">부가세(0 ~ 10%)</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" max="10" min="0" class="form-control text-end" ref="txt_vatPrice" v-model="this.eventInfo.vatPrice">
                    <span class="input-group-text">%</span>
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-1">
                  가용포인트
                  <p><small class="text-danger">(한도: 결제금액의 70%)</small></p>
                </th>
                <td class="col-md-4">
                  <div class="input-group" v-if="this.eventInfo.payPoint > 0">
                    {{this.getCurrencyFormat(this.availPoint)}} / 
                    ({{this.getCurrencyFormat(this.eventInfo.payPoint)}})사용
                  </div>
                  <div class="input-group" v-else>
                    <input type="number" class="form-control text-end" ref="txt_userPoint" v-model="this.availPoint" readonly>
                    <button type="button" class="btn btn-primary btn-sm me-1" @click="payByPoint();">
                      <font style="font-size:small" class="align-bottom">사용</font>
                    </button>
                  </div>
                  <div class="text-start">
                    <small class="text-danger">사용한 포인트는 복구불가(단, 예약취소 예외)</small>
                  </div>
                </td>                
              </tr>
              <tr>
                <th class="table-secondary col-md-2">기존업무코멘트</th>
                <td class="col-md-4">
                  <textarea class="form-control" ref="txt_oldComment" id="floatingTextarea" style="height:110px" 
                    v-model="this.eventInfo.eventComent" maxlength="1000" disabled></textarea>
                </td>
                <th class="table-secondary col-md-2">업무코멘트</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="250자 이내" ref="txt_newComment" id="floatingTextarea" style="height:110px" 
                    v-model="this.newComment" maxlength="250"></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>[인적사항&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('U','P');">&nbsp;저장</i> ]</b>&nbsp;
            <i class="bi bi-cone-striped text-danger">정확한 작업을 위해, 요청정보의 예약저장을 우선 진행하세요</i> 
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>
                  인원정보
                  <p class="mt-2">
                    <button type="button" class="btn btn-sm btn-outline-primary align-right">
                    <i class="bi bi-person-plus-fill" @click="addPniInfo()"> 인원추가</i>
                    </button>
                    <br>
                    <small class="text-danger">상담가격계산 수행 후 진행</small>
                  </p>
                </th>
                <td class="col-md-10" colspan="3">
                  <div class="mt-0 px-1 mb-2 text-start text-success">
                    ※ 여행자보험은 주민번호필요 &nbsp;/&nbsp;
                    <button type="button" class="btn btn-sm btn-warning" @click="pniClosing();"><small>PNI-Closing</small></button>
                    <!-- <p><small class="text-secondary text-danger">(인적사항저장 후, 여권등록)</small></p> -->
                  </div>
                  <div class="input-group mb-1" :key=i v-for="(pList, i) in this.pniList">
                    <!-- <input type="text" class="form-control" ref="txt_pniAduPassNo" v-model="pList.pniUseFlag" placeholder="사용여부"> -->
                    <input class="form-check-input my-auto align-middle" type="checkbox" true-value="Y" false-value="N" v-model="pList.pniUseFlag">&nbsp;
                    <input type="hidden" class="form-control text-success" ref="txt_pniIndex" name="pniIndex" :value=i readonly>

                    <input type="text" class="form-control text-success" ref="txt_passNameKor" v-model="pList.passNameKor" placeholder="국문명">
                    
                    <input type="text" class="form-control" ref="txt_passNameEng" v-model="pList.passNameEng" placeholder="영문명">

                    <input type="text" class="form-control" maxlength="11" ref="txt_pniTel" v-model="pList.pniTel" placeholder="연락처">

                    <input type="date" class="form-control" ref="txt_passBirthDate" v-model="pList.pBirthDate" placeholder="생년월일">

                    <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="pList.priceType">
                      <option :value=noVal disabled>{{pList.priceType}}</option>
                      <option value="A" id="ageGroupA">성인</option>
                      <option value="C" id="ageGroupC">소인</option>
                      <option value="I" id="ageGroupI">유아</option>
                      <option value="D" id="ageGroupD">할인</option>
                    </select>

                    <input type="text" class="form-control" ref="txt_passLicenseNo" maxlength="13" v-model="pList.passLicenseNo" placeholder="주민번호">

                    <input type="text" class="form-control" ref="txt_passNo" v-model="pList.passNo" placeholder="여권번호">

                    <input type="date" class="form-control" ref="txt_passExpDate" v-model="pList.pExpDate">&nbsp;

                    <div class="form-check form-switch align-bottom">
                      <input class="form-check-input" type="checkbox" role="switch" ref="txt_passFlag" v-model="pList.passFlag" true-value="D" false-value="S" checked>
                      <label class="form-check-label text-small" for="txt_pniAduPassFlag"><small>복수</small></label>
                    </div>&nbsp;
                    
                    <div>
                      <button type="button" class="btn btn-sm btn-success align-top" @click="passImgUp(pList.passNo)">여권</button>
                    </div>
                  
                  </div>
                  <div class="input-group mb-1" :key=pni-1 v-for="pni in this.vCnt">
                    <div class="input-group mb-1">
                      <div class="align-self-center col-md-1" v-if="this.vCnt === pni">  
                        <i class="bi bi-dash-circle-fill text-danger" @click="removePniInfo([this.pniLength*1+pni*1-1])"></i>
                      </div>
                      <input type="hidden" class="form-control text-success" ref="txt_pniIndex" name="pniIndex" size="1" :value=this.pniLength*1+pni*1-1 readonly>

                      <input type="text" class="form-control text-success" ref="txt_pniAduKor" v-model="this.passNameKor[this.pniLength*1+pni*1-1]" :id=[this.pniLength*1+pni*1-1] placeholder="국문명">

                      <input type="text" class="form-control" ref="txt_pniAduEng" v-model="this.passNameEng[this.pniLength*1+pni*1-1]" placeholder="영문명" :id=[this.pniLength*1+pni*1-1]>

                      <input type="text" class="form-control text-success" maxlength="11" ref="txt_pniAduKor" v-model="this.pniTel[this.pniLength*1+pni*1-1]" placeholder="연락처">

                      <input type="date" class="form-control" ref="txt_pniAduBirth" v-model="this.pBirthDate[this.pniLength*1+pni*1-1]" placeholder="생년월일">

                      <select class="form-select form-select-md" aria-label=".form-select-md" v-model="this.priceType[this.pniLength*1+pni*1-1]">
                        <option value="A" id="ageGroupA">성인</option>
                        <option value="C" id="ageGroupC">소인</option>
                        <option value="I" id="ageGroupI">유아</option>
                        <option value="D" id="ageGroupD">할인</option>
                      </select>

                      <input type="text" class="form-control" ref="txt_pniAduLcNo" maxlength="13" v-model="this.passLicenseNo[this.pniLength*1+pni*1-1]" placeholder="주민번호">

                      <input type="text" class="form-control" ref="txt_pniAduPassNo" v-model="this.passNo[this.pniLength*1+pni*1-1]" placeholder="여권번호">

                      <input type="date" class="form-control" ref="txt_pniAduPassExp" v-model="this.pExpDate[this.pniLength*1+pni*1-1]">&nbsp;
                      
                      <div class="form-check form-switch align-bottom">
                      <input class="form-check-input" type="checkbox" role="switch" ref="txt_pniAduPassFlag" v-model="this.passFlag[this.pniLength*1+pni*1-1]" true-value="D" false-value="S" checked>
                      <label class="form-check-label text-small" for="txt_pniAduPassFlag"><small>복수</small></label>
                      </div>&nbsp;

                      <div>
                        <button type="button" class="btn btn-sm btn-outline-success align-top" @click="passImgUp(this.passNo[this.pniLength*1+pni*1-1])">여권</button>
                      </div>
                    </div>
                  </div>                  
                </td>
              </tr>

              <tr>
                <th class="table-secondary col-md-2">여권사본</th>
                <td class="col-md-10" colspan="3">
                  <div v-if="this.passUpFlag === 'Y'">
                    <label class="col-md-3 col-form-label">[ {{this.enteredPassNo}} ] 여권사본</label>
                    <div class="col-md-9">
                      <div class="form-control" type="file" accept="image/jpg">
                        <div class="position-relative">
                          <img :src="`/download/passport/${this.eventCode}/${this.enteredPassNo}.jpg`" class="img-fluid" />
                        </div>
                        <input type="file" class="form-control" accept="image/png,image/jpeg" @change="uploadFile($event.target.files, this.eventCode, this.enteredPassNo)">
                      </div>
                      <div class="alert alert-secondary" role="alert" style="font-size:small;">
                        <span>File 확장자: jpg</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <th class="table-secondary col-md-2">기존내용</th>
                <td class="col-md-4">
                  <textarea class="form-control" ref="txt_pniRemarks" id="floatingTextarea" style="height:110px" 
                    v-model="this.eventInfo.pniRemarks" maxlength="3000" disabled></textarea>
                </td>
                <th class="table-secondary col-md-2">
                  신규특기사항
                  <p class="mt-2">
                    <button type="button" class="btn btn-sm btn-outline-primary align-right">
                      <i class="bi bi-save-fill" @click="spRemarkSave()"> 내용저장</i>
                    </button>
                  </p>
                </th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="250자 이내" ref="txt_nPniRemarks" id="floatingTextarea" style="height:110px" 
                    v-model="this.nPniRemarks" maxlength="250"></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>[교통정보&nbsp; <i class="mb-2 bi bi bi-caret-down-fill text-end text-primary" style="cursor:pointer" @click="showArea('T');">&nbsp;EDIT</i>&nbsp; ]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">출발&nbsp;<i class="bi bi-arrow-right-circle"></i>&nbsp;도착</th>
                <td class="col-md-4">
                  한국({{this.evtTransInfo1.ddCodeName}}) → {{this.productInfo.ntName}}({{this.evtTransInfo1.daCodeName}})
                </td>
                <th class="table-secondary col-md-2">리턴&nbsp;<i class="bi bi-arrow-right-circle"></i>&nbsp;도착</th>
                <td class="col-md-4">
                  {{this.productInfo.ntName}}({{this.evtTransInfo1.rdCodeName}}) → 한국({{this.evtTransInfo1.raCodeName}})
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">출발편</th>
                <td class="col-md-4">
                  {{this.evtTransInfo1.dmobileCodeName}}
                  <small>
                    ({{this.evtTransInfo1.dmobileCode}}{{this.evtTransInfo1.dpTpCode}},&nbsp;{{this.evtTransInfo1.d_dpTime}} → {{this.evtTransInfo1.d_arTime}})
                  </small>
                </td>
                <th class="table-secondary col-md-2">리턴편</th>
                <td class="col-md-4">
                  {{this.evtTransInfo1.rmobileCodeName}}
                  <small>
                    ({{this.evtTransInfo1.rmobileCode}}{{this.evtTransInfo1.rtTpcode}},&nbsp;{{this.evtTransInfo1.r_dpTime}} → {{this.evtTransInfo1.r_arTime}})
                  </small>
                </td>
              </tr>
              <tr>
                <th class="table-secondary col-md-2">추가사항</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
                    v-model="evtTransInfo1.transRemarks_dp" maxlength="300" readonly></textarea>
                </td>
                <th class="table-secondary col-md-2">추가사항</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
                    v-model="evtTransInfo1.transRemarks_ar" maxlength="300" readonly></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- 교통정보 업데이트 -->
          <table class="table table-bordered border-success align-middle w-100 h-auto" v-if="this.areaFlag === 'T'">
            <i class="bi bi-capslock-fill text-danger fs-4" style="cursor:pointer" @click="dataBaseTransaction('U', 'T');"></i>&nbsp;&nbsp;[UPDATE]
            <div class="row border p-sm-2 border-info">
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">출발정보<font class="text-danger">*</font></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.trCd1" @change="changeDpTransList()">
                      <option :value="noVal">비이용</option>
                      <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.mbCd1"  @change="changeDpMobilityList()">
                      <option :value="mobilityList_dp[m].transCode" :key=m v-for="(transCode,m) in mobilityList_dp">{{mobilityList_dp[m].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.dpdCd">
                      <option :value="noVal">비이용</option>
                      <option :value="termList_dpd[p].baseCode" :key=p v-for="(baseCode,p) in termList_dpd">{{termList_dpd[p].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.dpaCd" 
                      @change="getEtcFeeInfo('D',pdtTransInfo.trCd1,pdtTransInfo.mbCd1,pdtTransInfo.dpdCd)">
                      <option :value="noVal">비이용</option>
                      <option :value="termList_dpa[p].baseCode" :key=p v-for="(baseCode,p) in termList_dpa">{{termList_dpa[p].codeNameKor}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">교통편정보<font class="text-danger">*</font></label>
                <div class="col-md-9">
                  <div class= "input-group">
                    <input type="text" class="form-control text-end" ref="txt_dFlt" maxlength="4" v-model="pdtTransInfo.dpTpCode">
                    <input type="time" class="form-control" ref="txt_d_dpTime" v-model="pdtTransInfo.d_dpTime">
                    <input type="time" class="form-control" ref="txt_d_arTime" v-model="pdtTransInfo.d_arTime">
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">추가정보</label>
                <div class="col-md-9">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
                    v-model="pdtTransInfo.transRemarks_dp" maxlength="210"></textarea>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">유류할증료<font class="text-danger">*</font></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_oilSurcharge_dp" v-model="pdtTransInfo.oilCd_dp">
                      <option :value="noVal">비이용</option>
                      <option :value="oilSurcharge_dp[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in oilSurcharge_dp">
                        {{oilSurcharge_dp[k].etcFeeNameKor}}&nbsp;({{getCurrencyFormat(oilSurcharge_dp[k].ageFee)}}<small>{{oilSurcharge_dp[k].codeNameKor}}</small>)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">터미널이용료<font class="text-danger">*</font></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.termCd_dp" 
                      @change="changeTermFeeNameList('D', pdtTransInfo.termCd_dp)">
                      <option :value="noVal">비이용</option>
                      <option :value="termFeeNameList_dp[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in termFeeNameList_dp">
                        {{termFeeNameList_dp[k].etcFeeNameKor}}&nbsp;({{termFeeNameList_dp[k].tmCd}})
                      </option>
                    </select>
                    <div class="input-group mt-2" :key=j v-for="(ageFee,j) in termFee_dp">
                      <span class="input-group-text">{{termFee_dp[j].agegroup}}</span>
                      <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="termFee_dp[j].ageFee" readonly>
                      <span class="input-group-text">{{termFee_dp[j].codeNameKor}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="row border p-sm-2 border-warning">
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">리턴정보</label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.trCd2" @change="changeRtTransList">
                      <option :value="noVal">비이용</option>
                      <option :value="transList[q].baseCode" :key=q v-for="(baseCode,q) in transList">{{transList[q].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.mbCd2"  @change="changeRtMobilityList">
                      <option :value="mobilityList_rt[r].transCode" :key=r v-for="(transCode,r) in mobilityList_rt">{{mobilityList_rt[r].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.rtdCd">
                      <option :value="noVal">비이용</option>
                      <option :value="termList_rtd[s].baseCode" :key=s v-for="(baseCode,s) in termList_rtd">{{termList_rtd[s].codeNameKor}}</option>
                    </select>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.rtaCd"
                      @change="getEtcFeeInfo('R',pdtTransInfo.trCd2,pdtTransInfo.mbCd2,pdtTransInfo.rtdCd)">
                      <option :value="noVal">비이용</option>
                      <option :value="termList_rta[s].baseCode" :key=s v-for="(baseCode,s) in termList_rta">{{termList_rta[s].codeNameKor}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">교통편정보<font class="text-danger">*</font></label>
                <div class="col-md-9">
                  <div class= "input-group">
                    <input type="text" class="form-control text-end" ref="txt_rFlt" maxlength="4" v-model="pdtTransInfo.rtTpCode">
                    <input type="time" class="form-control" ref="txt_r_dpTime" v-model="pdtTransInfo.r_dpTime">
                    <input type="time" class="form-control" ref="txt_r_arTime" v-model="pdtTransInfo.r_arTime">
                  </div>
                </div>
              </div>          
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">추가정보</label>
                <div class="col-md-9 mb-2">
                  <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
                    v-model="pdtTransInfo.transRemarks_ar" maxlength="210"></textarea>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">유류할증료<font class="text-danger">*</font></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_oilSurcharge_rt" v-model="pdtTransInfo.oilCd_rt">
                      <option :value="noVal">비이용</option>
                      <option :value="oilSurcharge_rt[j].etcFeeCode" :key=j v-for="(etcFeeCode,j) in oilSurcharge_rt">
                        {{oilSurcharge_rt[j].etcFeeNameKor}}&nbsp;({{getCurrencyFormat(oilSurcharge_rt[j].ageFee)}}<small>{{oilSurcharge_rt[j].codeNameKor}}</small>)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-1 row">
                <label class="col-md-3 col-form-label">터미널이용료<font class="text-danger">*</font></label>
                <div class="col-md-9">
                  <div class="input-group mb-1">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.termCd_rt" 
                      @change="changeTermFeeNameList('R', pdtTransInfo.termCd_rt)">
                      <option :value="noVal">비이용</option>
                      <option :value="termFeeNameList_rt[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in termFeeNameList_rt">
                        {{termFeeNameList_rt[k].etcFeeNameKor}}&nbsp;({{termFeeNameList_rt[k].tmCd}})
                      </option>
                    </select>
                    <div class="input-group mt-2" :key=j v-for="(ageFee,j) in termFee_rt">
                      <span class="input-group-text">{{termFee_rt[j].agegroup}}</span>
                      <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="termFee_rt[j].ageFee" readonly>
                      <span class="input-group-text">{{termFee_rt[j].codeNameKor}}</span>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </table>
      </div>
    </div>
    <div class="mb-3 row">
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
      </div> -->
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToList()">행사리스트</button>
      </div>
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
      </div> -->
    </div>
  </div>
  </main>
</template>

<script>
import { getAvailPoint } from '../assets/js/commonFunctions.js';

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      eventInfo: {}, //이벤트정보
      addItemInfo: {} , //추가사항
      addDisInfo: {}, //추가할인정보
      obj_addItem: {}, //신규추가사항
      obj_addItemSize: 0, //신규추가사항 사이즈
      obj_addDisItem: {}, //신규추가할인사항
      obj_addDisItemSize: 0, //신규추가할인사항 사이즈

      cstPrice: 0, //행사금액
      cstPrice_new: 0, //행사금액(최신)
      cstPrice_org: 0, //행사금액(이전)

      //추가사항관련
      addItemUseFlag: "N", //추가사항사용여부
      addItemName: [],
      addItemPrice: [],
      vCnt: 0,
      
      addItemFlag: [], //기타옵션플래그

      //추가할인관련
      addDisName: [], //추가할인명
      addDisPrice: [], //추가할인금액
      dCnt: 0,
      addDisFlag: [], //추가할인 플래그

      //추가마진
      addDisUseFlag: "N", //추가할인사용여부
      addMargin: 0, //추가마진
      addMarginMethod: "P", //추가마진형태

      //포인트정보
      availPoint: 0, //사용가능포인트
      paidPoint: 0, //사용포인트
      userPayPoint: [], //해당예약에서 유저지불 포인트

      newComment: "", //최신코멘트

      //////// PNI정보
      pniList: [], //pni리스트
      pniLength: 0, //pni리스트길이

      passNameKor: [], //pni성인
      passNameEng: [],
      //passBirthDate: [],
      pBirthDate: [],
      passLicenseNo: [], //주민번호(여행자보험용)
      passNo: [], //여권번호
      //passExpDate: [], //여권만료일
      pExpDate: [], //여권만료일
      passFlag: [], //단수복수구분(S단수, D복수)
      pniTel: [],
      imgFlag: [], //여권사본등록여부(Y/N)
      priceType: [], //권종유형(A,C.I.D)

      nPniRemarks: "", //인적사항 특기사항(마스터 저장데이타)

      passUpFlag: "", //여권 업로드영역 호출여부
      enteredPassNo: "", //입력된 여권번호

      personCnt: 0, //총인원(화면상)

      areaFlag: "",

      cityList: {},
      nationList: {},
      areaList: {},
      areaList_tmp: {},
      transList: {},
      //tranList_via: {},
      mobilityList_tmp: {},
      mobilityList_dp: {}, //출발편
      mobilityList_rt: {}, //리턴편
      termList_dpd: {}, //출발시작터미널
      termList_dpa: {}, //출발도착터미널
      termList_rtd: {}, //리턴시작터미널
      termList_rta: {}, //리턴도착터미널

      //sRoute: {}, //상품구분

      termList_dp: {}, //출발터미널
      termList_rt: {}, //리턴국터미널
      oilSurcharge_dp: {}, //유류할증
      oilSurcharge_rt: {},
      termFee_dp: {}, //터미널이용료
      termFee_rt : {},
      termFeeNameList_dp: {}, //터미널이용료 명칭
      termFeeNameList_rt : {},

      //productTrans: {},
      evtTransInfo1: {}, //기존교통정보
      pdtTransInfo: {},
      //pdtPromoInfo: {}, //프로모션정보
      pdtOilSCInfo1: {}, //기존유류할증
      pdtTermFeeInfo_dp: {}, //기존터미널료
      pdtTermFeeInfo_rt: {},
      salesVolumeInfo: {}, //판매수량관리

      dTerm: "", //출발터미널
      rTerm: "", //리턴터미널
      dCur: "", //터미널이용료화폐
      rCur: "",

      pdtTermFeeInfo_dp_apply: {}, //적용터미널료(출발)
      pdtTermFeeInfo_rt_apply: {}, //적용터미널료(리턴)
      oilSCDp_apply: {}, //적용유류할증료(출발)
      oilSCRt_apply: {}, //적용유류할증료(리턴)

      journeyDate: [], //여정일자

      productInfo: {}, //교통용 예약정보
      insComment: "", //예약상담내용

      rtnRPflag: "N", //결제요청여부

      personCntK: 0 //카카오알림톡용 인원수
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.eventCode = this.$route.query.eCode;
    this.ntCd = this.$route.query.ntCd;

    console.log("USER:",this.user);
    //console.log("EVENTCODE:",this.eventCode);
    this.getEventInfo();

    this.getReqProcLevel();

    this.getCityList();
    this.getNationList();
    this.getAreaList();
    this.getTransList();
    this.getMobilityList();    
    
    //this.getProductTransInfoExited(); //기존교통정보
    //this.getProductTransInfo();

    // this.getPdtOilSurchargeExited(); //기존상품 유류할증료
    // this.getPdtTerminalFeeExited(); //기존상품 유류할증료
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }    
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    /** 윈도우즈 계산기호출*/
    callWinCalc() 
    { 
      window.open('Calculator: ');
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      console.log("rProc:",this.rProc);
    },
    //카카오처리
    callKakao(param)
    {
      if(param === "RP")
      {
        this.sendKakao(param);
      }
      // else if(param === "CO")
      // {
      //   this.sendKakao(param);
      // }
      else if(param === "CX")
      {
        this.sendKakao(param);
      }
      else if(param === "nonRP") //비회원
      {
        this.sendKakao(param);
      }
      else
      {
        this.$swal.fire('', '카카오 알람은 결제요청, 취소 시에 발송됩니다', 'info');
        return false;        
      }
    },
    /** 카카오알림 발송 */
    async sendKakao(param)
    {
      //alert("param:"+param);
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.eventCode, param, this.user.handlerId
      ]});

      // - 예약상품: #{resName}
      // - 예약자명: #{resPaxName}
      // - 여행인원: #{resPaxCnt}
      // - 여행일자: #{dptDate}
      // - 고객연락처: #{resPhone}
      // - 예약번호: #{resCode}
      // - 결제금액: #{resPayAmount}
      // - 담당자: #{resManager} 매니저
      // - 예약확인: #{resCheck}

      if(insKSendHistory.affectedRows > 0) {
        if(param == "RP") 
        {
          //결제요청 카카오 알림톡
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제요청)
            clientName: this.eventInfo.reqName,
            resName: this.eventInfo.eventNameKor,
            resPaxName: this.eventInfo.reqName,
            resPaxCnt: this.personCntK,
            dptDate: this.eventInfo.evtStartDate,
            resPhone: this.eventInfo.reqTelNo,
            resCode: this.eventCode,
            resPayAmount: this.getCurrencyFormat(this.eventInfo.cstPrice_new),
            resManager: this.eventInfo.resManager,
            alimFlag: "RP" //알림유형(결제요청)
          };
          this.$api("api/kakaoAlimSend2", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '결제요청 알림톡이 발송되었습니다', 'success');
          //this.$router.push({path: '/'});
          //history.back();
          location.reload(true)
        }
        else if(param == "nonRP") 
        {
          //결제요청 카카오 알림톡
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제요청-비회원)
            clientName: this.eventInfo.reqName,
            resName: this.eventInfo.eventNameKor,
            resPaxName: this.eventInfo.reqName,
            resPaxCnt: this.personCntK,
            dptDate: this.eventInfo.evtStartDate,
            resPhone: this.eventInfo.reqTelNo,
            resCode: this.eventCode,
            resPayAmount: this.getCurrencyFormat(this.eventInfo.cstPrice_new),
            resManager: this.eventInfo.resManager,
            alimFlag: "nonRP" //알림유형(결제요청)
          };
          this.$api("api/kakaoAlimSend2", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '비회원용 결제요청 알림톡이 발송되었습니다', 'success');
          //this.$router.push({path: '/'});
          //history.back();
          location.reload(true)
        }        
        // else if(param == "CO") 
        // {
        //   //카카오 알림톡 발송(확정)
        //   let kakaoAlimData = 
        //   {
        //     clientName: this.eventInfo.reqName,
        //     resCode: this.eventCode,
        //     resName: this.eventInfo.eventNameKor,
        //     resPrice: this.getCurrencyFormat(this.eventInfo.cstPrice_new),
        //     alimCall: this.eventInfo.reqTelNo,
        //     alimFlag: "CO"
        //   };
        //   this.$api("api/kakaoAlimSend", {
        //     param: [
        //       { kakaoAlimData: kakaoAlimData},
        //     ]
        //   }).then((res) => {console.log(res)});
        //   this.$swal.fire('', '예약확정 알림톡이 발송되었습니다', 'success');
        //   //this.$router.push({path: '/'});
        //   history.back();
        // }
        else if(param == "CX") 
        {
          //카카오 알림톡 발송(취소)
          let kakaoAlimData = 
          {
            clientName: this.eventInfo.reqName,
            resCode: this.eventCode,
            resName: this.eventInfo.eventNameKor,
            resPrice: this.getCurrencyFormat(this.eventInfo.cstPrice_new),
            alimCall: this.eventInfo.reqTelNo,
            alimFlag: "CX"
          };
          this.$api("api/kakaoAlimSend", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '예약취소 알림톡이 발송되었습니다', 'success');
          //this.$router.push({path: '/'});
          history.back();
        }        
      }
    },
    //당해 이벤트 정보
    async getEventInfo() {
      let eventInfo = await this.$api("/api/getEventInfo", {param:[this.eventCode]});
      if(eventInfo.length > 0) {
        this.eventInfo = eventInfo[0];
        this.eventInfo.conEmail = (this.eventInfo.conEmail == undefined || this.eventInfo.conEmail == null) ? this.eventInfo.reqEmail : this.eventInfo.conEmail;
        this.eventInfo.evtStartDate = this.getDateFormat(this.eventInfo.evtStartDate);
        this.eventInfo.evtCloseDate = this.getDateFormat(this.eventInfo.evtCloseDate);
      }
      console.log("eventInfo:",this.eventInfo);

      //카카오알림톡용 여행인원
      let personCntK = (this.eventInfo.adultCnt*1 + this.eventInfo.childCnt*1 + this.eventInfo.infCnt*1 + this.eventInfo.discountCnt*1);
      this.personCntK = personCntK*1;
      console.log("personCntK:",personCntK);      

      if(this.eventInfo.addItemUseFlag === "Y")
      {
        let addItemInfo = await this.$api("/api/getAddItemInfo", {param:[this.eventCode]});
        this.addItemInfo = addItemInfo;
        console.log("addItemInfo:",this.addItemInfo);
      }
      if(this.eventInfo.addDisUseFlag === "Y")
      {
        let addDisInfo = await this.$api("/api/getAddDisInfo", {param:[this.eventCode]});
        this.addDisInfo = addDisInfo;
        console.log("addDisInfo:",this.addDisInfo);
      }
      this.getAvailPoint();
      this.getPniList();
      if(this.eventInfo.evtTransFlag === "Y")
      {
        this.getProductTransInfoExisted();
      }
      
      //결제요청 여부
      this.getReqPayInfo(this.eventCode);

      //당해행사 지불포인트
      this.getUserPayPoint(this.eventCode)      
    },
    //해당행사의 유저가 사용한 포인트
    async getUserPayPoint(resCode)
    {
      let userPayPoint = [];
      userPayPoint = await this.$api("/api/getUserPayPoint", {param: [this.eventInfo.reqEmail, resCode]});
      if(userPayPoint.length > 0)
      {
        this.userPayPoint = userPayPoint[0];
      }
      console.log("userPayPoint:", this.userPayPoint)
    },    
    async getReqPayInfo(val)
    {
      //return await this.$api("/api/getReqPayInfo", {param: [this.reqRsvCode]});
      let rtnRPflag_tmp = await this.$api("/api/getReqPayInfo", {param: [val]});
      this.rtnRPflag = (rtnRPflag_tmp.length > 0) ? "Y" : "N";
      console.log("rtnRPflag:",this.rtnRPflag);
    },
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      if(cityList.length > 0) {
        this.cityList = cityList;
      }
      //console.log("nationList",nationList);
    },    
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    async getAreaList() {
      //this.areaList_tmp = await this.$api("/api/areaList", {});
      this.areaList = await this.$api("/api/areaList", {});
    },
    changeNationList() {
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaList(this.eventInfo.ntCd);
    },
    async viewAreaList(param1){
      //this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode === param1});
      this.areaList = await this.$api("/api/areaList", {});
      this.areaList = this.areaList.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },
    //추가항목처리
    addItem() {
      this.vCnt++;
      if(this.vCnt > 20) {
        return this.$swal.fire("","항목추가는 20까지입니다.","error");
      }
    },
    removeItem(val) {
      this.vCnt--;
      // this.addItemFlag[val] = undefined;
      // this.addItemName[val] = undefined;
      // this.addItemPrice[val] = undefined;
      document.getElementById(val).remove();
      this.addItemFlag.splice(val);
      this.addItemName.splice(val);
      this.addItemPrice.splice(val);
      //console.log("addItemName:",this.addItemName,"addItemPrice:",this.addItemPrice);
    },

    //추가할인처리
    addDisItem() {
      this.dCnt++;
      if(this.dCnt > 20) {
        return this.$swal.fire("","항목추가는 20까지입니다.","error");
      }
    },
    //기타옵션제거
    removeDisItem(dal) {
      this.dCnt--;
      // this.addDisName[dal] = undefined;
      // this.addDisPrice[dal] = undefined;
      document.querySelector("div[name=dal]")
      this.addDisFlag.splice(dal);
      this.addDisName.splice(dal);
      this.addDisPrice.splice(dal);
      console.log("addDisFlag:",this.addDisFlag,"addDisName:",this.addDisName);
    },
  /////////////상담가격계산(터미널이용료/유류할증료 별도표시)
    /** 여정기간 중 상품가격합산식 */
    calcCstPrice() 
    {
      //alert("Calc!");
      let aduPrice = 0;
      let chiPrice = 0;
      let infPrice = 0;
      let disPrice = 0;
      let pdtPrice = 0;

      //여행참가인원수
      let personCnt = (this.eventInfo.adultCnt*1 + this.eventInfo.childCnt*1 + this.eventInfo.infCnt*1 + this.eventInfo.discountCnt*1);
      this.personCnt = personCnt*1;
      console.log("personCnt:",personCnt);

      //1. 권종수 기준가격 계산
      aduPrice = (this.eventInfo.adultPrice*1) * (this.eventInfo.adultCnt*1);
      chiPrice = (this.eventInfo.childPrice*1) * (this.eventInfo.childCnt*1);
      infPrice = (this.eventInfo.infPrice*1) * (this.eventInfo.infCnt*1);
      disPrice = (this.eventInfo.discountPrice*1) * (this.eventInfo.discountCnt*1);
      pdtPrice = (aduPrice*1) + (chiPrice*1) + (infPrice*1) + (disPrice*1);
      console.log("pdtPrice1:",pdtPrice);

      //2. 추가사항적용
      ////기존 추가사항처리
      if(this.addItemInfo.length > 0)
      {
        let i = 0;
        //let testPrice = 0;
        while(i < this.addItemInfo.length)
        {
          if(this.addItemInfo[i].addContentFlag === "Y")
          {
            pdtPrice = pdtPrice*1 + (this.addItemInfo[i].addContentPrice*1);
            //testPrice += (this.addItemInfo[i].addContentPrice*1);
          }
          i++;
        }
      }
      console.log("pdtPriceAddItem:",pdtPrice);
      console.log("this.addItemNameLength:",this.addItemName.length-1);
      console.log("addItemUseFlag1:",this.addItemUseFlag);

      if(this.addItemName.length-1 <= 0) // 신규추가사항이 없는 경우
      {
        this.addItemUseFlag = 'N';
      }
      else
      {
        this.addItemUseFlag = 'Y';
        let arr_addItemName = [];
        let arr_addItemPrice = [];
        let arr_addItemFlag = [];
        let j = 1;
        console.log("addItemUseFlag2:",this.addItemUseFlag);

        while(j < this.addItemName.length)
        {
          console.log("addItemName",j,":",this.addItemName[j]);
          if(this.addItemName[j] != "")
          {
            arr_addItemName.push(this.addItemName[j]);
            arr_addItemPrice.push(this.addItemPrice[j]);
            arr_addItemFlag.push(this.addItemFlag[j]);
          }
          j++;
        }
        console.log("addItemName:",arr_addItemName,"addItemPrice:",arr_addItemPrice,"addItemFlag:",arr_addItemFlag);
        
        //추가사항 리스트
        let obj_addItem = new Object();
        obj_addItem.addItemName = arr_addItemName;
        obj_addItem.addItemPrice = arr_addItemPrice;
        obj_addItem.addItemFlag = arr_addItemFlag;
        
        console.log("obj_addItem:",obj_addItem);
        this.obj_addItem = obj_addItem;

        let obj_addItemSize = Object.keys(obj_addItem.addItemName).length;
        this.obj_addItemSize = obj_addItemSize;

        let k = 0;
        while(k<obj_addItemSize)
        {
          if(obj_addItem.addItemFlag[k] === "Y")
          {
            pdtPrice += (obj_addItem.addItemPrice[k]*1);
          }
          k++;
        }
        //pdtPrice = aduPrice*1 + chiPrice*1 + infPrice*1 + disPrice*1;
        console.log("pdtPrice2:",pdtPrice);
      }

      //3.추가마진 적용
      //let margin = 0;
      let pdtPriceMargin = 0;
      if(this.eventInfo.addMarginMethod ==="A")
      {
        //pdtPrice = (pdtPrice) + (this.productInfo.basicMargin*1);
        pdtPriceMargin = this.eventInfo.addMargin*1
      }
      else
      {
        if(this.eventInfo.addMargin*1 > 0)
        {
          //pdtPrice = pdtPrice + (pdtPrice * (this.productInfo.basicMargin/100));
          pdtPriceMargin = (pdtPrice*1) * ((this.eventInfo.addMargin*1)/100);
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
        else
        {
          //pdtPrice = pdtPrice + 0;
          pdtPriceMargin = 0;
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
      }
      this.pdtMargin = pdtPriceMargin*1;
      pdtPrice = pdtPrice*1 + pdtPriceMargin*1;
      console.log("priceMargin:",pdtPrice);

      //4. 추가할인적용
      ////기존 추가할인처리
      if(this.addDisInfo.length > 0)
      {
        let i = 0;
        //let testPrice = 0;
        while(i < this.addDisInfo.length)
        {
          if(this.addDisInfo[i].addDiscountFlag === "Y")
          {
            pdtPrice = pdtPrice*1 - (this.addDisInfo[i].addDiscountPrice*1);
            //testPrice += (this.addItemInfo[i].addContentPrice*1);
          }
          i++;
        }
      }
      //console.log("pdtPriceAddDiscount:",pdtPrice*1);
      
      if(this.addDisName.length-1 <= 0) // 신규추가할인이 없는 경우
      {
        this.addDisUseFlag = 'N';

      }
      else 
      {
        this.addDisUseFlag = 'Y'; // 추가할인사용여부
        let arr_addDisName = [];
        let arr_addDisPrice = [];
        let arr_addDisFlag = [];
        let j = 1;

        while(j < this.addDisName.length)
        {
          if(this.addDisName[j] != "")
          {
            arr_addDisName.push(this.addDisName[j]);
            arr_addDisPrice.push(this.addDisPrice[j]);
            arr_addDisFlag.push(this.addDisFlag[j]);
          }
          j++;
        }
        //console.log("addItemName:",arr_addItemName,"addItemPrice:",arr_addItemPrice,"addItemFlag:",arr_addItemFlag);
        
        //추가할인 리스트
        let obj_addDisItem = new Object();
        obj_addDisItem.addDisName = arr_addDisName;
        obj_addDisItem.addDisPrice = arr_addDisPrice;
        obj_addDisItem.addDisFlag = arr_addDisFlag;
        //console.log("obj_addDisItem:",obj_addDisItem);
        this.obj_addDisItem = obj_addDisItem;

        let obj_addDisItemSize = Object.keys(obj_addDisItem.addDisName).length;
        this.obj_addDisItemSize = obj_addDisItemSize;
        // console.log("obj_addItemSize:",obj_addItemSize);
        // console.log("obj_addItem:",obj_addItem);

        let k = 0;
        while(k<obj_addDisItemSize)
        {
          if(obj_addDisItem.addDisFlag[k] === "Y")
          {
            pdtPrice -= (obj_addDisItem.addDisPrice[k]*1);
          }
          k++;
        }
        //pdtPrice = aduPrice*1 + chiPrice*1 + infPrice*1 + disPrice*1;
        console.log("pdtPrice:",pdtPrice);
      }
      
      //5.PayPoint처리
      if(this.eventInfo.payPoint > 0)
      {
        pdtPrice = pdtPrice*1 - (this.eventInfo.payPoint*1);
      }
      console.log("pdtPricePPoint:",pdtPrice);

      //6.부가세 적용
      let vatR = 0;
      //console.log("vat:R",vatR);
      if(this.eventInfo.vatPrice > 0) 
      {',                '
        vatR = (pdtPrice*1) * (this.eventInfo.vatPrice*1/100);
      }
      else
      {
        vatR = 0;
      }
      //this.vatPrice = vatR;
      pdtPrice = pdtPrice*1 + vatR*1;

      //7.소숫점아래 절사처리
      pdtPrice = Math.floor(pdtPrice);

      this.cstPrice = pdtPrice*1;
    },
    //가용포인트 확인
    async getAvailPoint() 
    {
      // let totalPoint = await this.$api("/api/userTotalPoint", {param: [this.eventInfo.reqEmail]});
      // let unavailPoint = await this.$api("/api/userUnavailablePoint", {param: [this.eventInfo.reqEmail]});
      // console.log("totalPoint:",totalPoint[0].totPoint*1, "unavailPoint:",unavailPoint[0].unavilpoint*1);

      // if(totalPoint.length > 0) {
      //   this.availPoint = (totalPoint[0].totPoint*1) - (unavailPoint[0].unavilpoint*1);
      // }
      // else 
      // {
      //   this.availPoint = 0;
      // }
      this.availPoint = await getAvailPoint(this.$api, this.eventInfo.reqEmail)      
      //console.log("availPoint:",this.availPoint);
    },
    // 가용포인트 사용처리
    payByPoint()
    {
      if(!this.cstPrice || this.cstPrice == 0) 
      {
        this.$swal.fire("","결제할 금액이 없거나 0원입니다.","warning");
        return;
      }
      else
      {
        this.$swal.fire({
        icon: 'question',
        text: '포인트를 사용하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '사용',
        cancelButtonText: '나중에'
        }).then(async (result) => 
        {
          if (result.isConfirmed) {
            //포인트 사용처리
            let limitPoint = Math.floor((this.cstPrice*1)*0.7); //소숫점절사
            console.log("limitPoint:",limitPoint);
            if(this.availPoint > limitPoint) 
            {
              
              this.$swal.fire("","가용포인트가 사용한도를 초과하여 한도만큼 사용됩니다.","info");
              this.paidPoint = limitPoint;
              //this.availPoint = this.availPoint*1 - this.cstPrice*1;
              this.availPoint = this.availPoint*1 - limitPoint*1;
              this.cstPrice = this.cstPrice*1 - limitPoint*1;
            }
            else
            {
              this.paidPoint = this.availPoint*1;
              this.cstPrice = (this.cstPrice*1) - (this.availPoint*1);
              this.availPoint = 0;
            }
            //console.log("paidPoint:",this.paidPoint);
          }
        })
      }
    },
    async getPniList()
    {
      let pniList = await this.$api("/api/getPniList_evt", {param: [this.eventCode]});
      this.pniList = pniList;
      // this.pniList[0].passBirthDate = this.getDateFormat(this.pniList[0].passBirthDate); 
      // this.pniList[1].passBirthDate = this.getDateFormat(this.pniList[1].passBirthDate); 
      this.pniList = (pniList.length > 0) ? pniList : [];
      this.pniLength = pniList.length;
      
      console.log("pniList1:",this.pniList);
    },
    addPniInfo()
    {
      //alert("totPgr:"+this.personCnt);
      if(this.vCnt*1 >= (this.personCnt*1) - (this.pniLength*1))
      {
        this.$swal.fire("","추가가능한 인원이 없습니다.<br>인원수 변경이 있는 경우, 상담가격계산을 먼저 진행하세요.","error");
        return false;  
      }
      this.vCnt++;
    },
    removePniInfo(val) {
      //alert("vCntRemove:"+this.vCnt);
      this.vCnt--;

      this.passNameKor[val] = "";
      this.passNameEng[val] = "";
      //this.passBirthDate[val] = "";
      this.pBirthDate[val] = "";
      //this.passExpDate[val] = "";
      this.pExpDate[val] = "";
      this.passFlag[val] = "";
      this.passLicenseNo[val] = "";
      this.passNo[val] = "";
      this.pniTel[val] = "";
      this.priceType[val] = ""
      document.getElementById(val).remove();
    },
    //PNI 특기사항처리
    async spRemarkSave() {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;
      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
      let oldSp = ""; 
      let newSp = "";
      oldSp = this.eventInfo.pniRemarks;
      newSp = this.nPniRemarks;
      let insSp = oldSp +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newSp;
      console.log("insSp:",insSp);

      let updPniRemarks = await this.$api("/api/updPniRemarks_evt", {param: [insSp, this.eventCode]});
      if(updPniRemarks.affectedRows < 0 || updPniRemarks.affectedRows === undefined)
      {
        this.$swal.fire('', '특기사항 저장(SP Saving) 중 오류가 발생하였습니다.', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '저장이 완료되었습니다', 'success');
        location.reload();
      }
    },
    //여권사본 첨부오픈
    passImgUp(pNo)
    {
      // let result = this.checkCorporateRegiNumber(this.cDetail.licenseNumber)
      //alert("pNo:"+pNo);
      if(pNo == "" || pNo == undefined) 
      {
        //this.$refs.txt_cLicense.value = "";
        this.passUpFlag = "";
        console.log("passUpFlag:",this.passUpFlag);
        this.$swal.fire("","해당 여권번호를 입력하세요", "warning");
        return false;
      }
      else
      {
        //alert("pNo:" + pNo);
        this.passUpFlag = "Y";
        this.enteredPassNo = pNo;
        console.log("passUpFlag:",this.passUpFlag);
      }
    },
    /** 이미지 사본등록 */
    async uploadFile(files, id, passNo) {
      let fileLength = 0
      let fileType_tmp = "";
      //let fileType = ""
      let licenseFile = "";
      //let passFile = "";
      let data = null;
      if (files) {
        fileLength = files[0].length;
        fileType_tmp = files[0].name.lastIndexOf(".");
        this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();
        //licenseNumber = files[0].name;
        //passFile = this.cDetail.licenseNumber+"."+this.fileType;
        licenseFile = passNo+"."+this.fileType;
        data = await this.$base64(files[0]);
      }
      console.log("files:",files, "id:",id, "File:",licenseFile,"licenseNo:",this.licenseNumber);
      //const { error } = await this.$api(`/upload/passport/${licenseFile}/${passNo}/${id}`, { data });
      const { error } = await this.$api(`/upload/passport/${id}/${licenseFile}`, { data });
      if (error) {
        return this.$swal("업로드(uploadFile) 오류발생, 재시도 필요.");
      }
      else{
        this.$swal("업로드 완료");
        location.reload();
      }
      // setTimeout(() => {
      //   this.getProductImage();
      // }, 1000);
    },
    //PNI closing
    async pniClosing() 
    {
      this.$swal.fire({
        icon: 'question',
        text: 'PNI를 마감하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => 
      {
        if (result.isConfirmed) 
        {
          let updPniClosing = await this.$api("/api/updPniClosing", {param: [this.eventCode]});
          if(updPniClosing.affectedRows < 0 || updPniClosing.affectedRows === undefined)
          {
            this.$swal.fire('', '행사저장(updEvent_m) 중 오류가 발생하였습니다.', 'error');
            return false;
          }
          else
          {
            this.$swal.fire('', 'PNI 마감이 완료되었습니다', 'success');
            location.reload();
          }
        }      
      });     
      
    },    
    //행사 교통정보(기존)
    async getProductTransInfoExisted() {
      let evtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.eventCode]});
      if(evtTransInfo1.length > 0) {
        this.evtTransInfo1 = evtTransInfo1[0];
      }
      //console.log("evtTransInfo1:",this.evtTransInfo1);
    },
    //행사 교통정보(신규)
    async getProductTransInfo() {
      //console.log("eventCode111:",this.eventCode);
      let pdtTransInfo = await this.$api("/api/pdtTransInfo", {param: [this.eventCode]});
      if(this.pdtTransInfo.length > 0){
        this.pdtTransInfo = pdtTransInfo[0];
      }
      console.log("pdtTransInfo:",this.pdtTransInfo);
      this.pageOpen();
      //this.getAddRoute_dp()
    },
    //넘어온 파라메타로 교통편표시
    async pageOpen(){
      //alert("trans:"+this.productInfo.transFlag);
      this.getAreaList_pageOpen()
      this.viewDpMobilityList_pageOpen();
        this.viewRtMobilityList_pageOpen();
        this.getDpTermList_pageOpen();
        this.getRtTermList_pageOpen();      
      // if(this.salesRoute === "T" || this.salesRoute === "L") {
      //   this.viewDpMobilityList_pageOpen();
      //   this.viewRtMobilityList_pageOpen();
      //   this.getDpTermList_pageOpen();
      //   this.getRtTermList_pageOpen();
      // }
    },
    //페이지 오픈시 교통정보 호출
    async getAreaList_pageOpen() {
      //console.log("productInfo:",this.productInfo);
      let areaList1 = await this.$api("/api/areaList", {});
      this.areaList = areaList1.filter(e => {return e.categoryCode === this.ntCd});
    },
    async viewDpMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_dp = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd1});
    },
    async viewRtMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_rt = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd2});
    },
    async getDpTermList_pageOpen() {
      //console.log("evtNtCd:",this.eventInfo.ntCd);
      // let param4 = (this.pdtTransInfo.trCd1 === "SHP") ? "ptCd" : "apCd"
      let param4 = "";
      if(this.pdtTransInfo.trCd1 === "SHP") {
        param4 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd1 === "FLT") {
        param4 = "apCd";
      }
      else {
        param4 = "";
      }
      //console.log("param4:",param4);
      if(param4 === "ptCd" || param4 === "apCd") {
        this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
        //출발도착터미널
        this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", this.eventInfo.ntCd, param4]});
      }
      else{
        this.termList_dpd = "";
        this.termList_dpa = "";
      }
      //console.log("TR:",param4,"/termListD:",this.termList_dpd,"/termListA:",this.termList_dpa);
    },
    async getRtTermList_pageOpen() {
      //let param5 = (this.pdtTransInfo.trCd2 === "SHP") ? "ptCd" : "apCd"
      let param5 = "";
      if(this.pdtTransInfo.trCd2 === "SHP") {
        param5 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd2 === "FLT") {
        param5 = "apCd";
      }
      else {
        param5 = "";
      }
      //this.termList_rtd = await this.$api("/api/termList", {param: [this.productInfo.ntCd, param5]});
      if(param5 === "ptCd" || param5 === "apCd") {
        this.termList_rtd = await this.$api("/api/termList", {param: [this.eventInfo.ntCd, param5]});
        this.termList_rta = await this.$api("/api/termList1", {param: ["KR", this.eventInfo.ntCd, param5]});
      }
    },


    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      //console.log("transList",transList);
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      //console.log("mobilityList_tmp",this.mobilityList_tmp);
    },
    changeTransList() {
      this.viewMobilityList(this.productInfo.trCd);
      //console.log("trCd:",this.transCode);
    },
    viewMobilityList(param2){
      this.mobilityList = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList);
    },
    changeMobilityList() {
      this.getTermList(this.productInfo.ntCd, this.productInfo.trCd);
    },
    async getTermList(param3, param4) {
      //console.log("prarms:",param3,"/",param4);
      let param5 = (param4 === "SHP") ? "ptCd" : "apCd"
      this.termList_dp = await this.$api("/api/termList", {param: ['KR', param5]});
      this.termList_rt = await this.$api("/api/termList", {param: [param3, param5]});
    },

    //교통수단변경->교통편획득
    changeDpTransList() {
      this.pdtTransInfo.mbCd1 = "";
      this.pdtTransInfo.dpdCd = "";
      this.pdtTransInfo.dpaCd = "";
      this.viewDpMobilityList(this.pdtTransInfo.trCd1);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    changeRtTransList() {
      this.pdtTransInfo.mbCd2 = "";
      this.pdtTransInfo.rtdCd = "";
      this.pdtTransInfo.rtaCd = "";
      this.viewRtMobilityList(this.pdtTransInfo.trCd2);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    viewDpMobilityList(param1){
      //alert("categoryCode:" + param1);
      this.mobilityList_dp = this.mobilityList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("mobility:", this.mobilityList_dp);
    },
    viewRtMobilityList(param2){
      //alert("categoryCode:" + param2);
      this.mobilityList_rt = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList_rt);
    },
    changeDpMobilityList() {
      this.getDpTermList(this.eventInfo.ntCd, this.pdtTransInfo.trCd1);
    },
    changeRtMobilityList() {
      this.getRtTermList(this.eventInfo.ntCd, this.pdtTransInfo.trCd2);
    },
    async getDpTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
      //출발도착터미널
      this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_dp);
    },
    async getRtTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_rtd = await this.$api("/api/termList", {param: [param2, param4]});
      this.termList_rta = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_rt);
    },
    //수정영역 표시(비표시)
    showArea(param) {
      //console.log("param:",param);
      this.areaFlag = (this.areaFlag === param) ? "N"+param : param;
      if(this.areaFlag === "NT") {
        //alert(param+"의 반대: N"+param);
        this.pdtTransInfo.trCd1 = "";
        this.pdtTransInfo.mbCd1 = "";
        this.pdtTransInfo.dpdCd = "";
        this.pdtTransInfo.dpaCd = "";
        this.pdtTransInfo.transRemarks_dp = "";
        this.pdtTransInfo.oilCd_dp = "";
        this.pdtTransInfo.termCd_dp = "";
        
        this.pdtTransInfo.trCd2 = "";
        this.pdtTransInfo.mbCd2 = "";
        this.pdtTransInfo.rtdCd = "";
        this.pdtTransInfo.rtaCd = "";
        this.pdtTransInfo.transRemarks_ar = "";
        this.pdtTransInfo.oilCd_rt = "";
        this.pdtTransInfo.termCd_rt = "";
      }
      else if(this.areaFlag === "NP") {
        alert("NP/"+this.areaFlag);
      }
      this.getProductTransInfo();      
    },
    async getEtcFeeInfo(p1,p2,p3,p4) {
      console.log(p1, p2, p3, p4)
      let drFlag = p1;
      let liCd = (this.eventInfo.ntCd === "KR") ? "L" : "G"
      let trCd = p2;
      let mbCd = p3;
      let tmCd = p4
      //console.log(tmCd)
      //유류할증료
      this.getOilFeeInfo(drFlag, liCd, trCd, mbCd);
      //터미널이용료
      this.getTermFeeInfo(drFlag, tmCd);
    },
    async getOilFeeInfo(f,o1,o2,o3){
      //console.log(f+"//"+o1+"//"+o2+"//"+o3);
      if(f === "D") {
        //츨발편 유류할증료
        let oilSurcharge_dp = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,'KR']});
        this.oilSurcharge_dp = oilSurcharge_dp;
        //console.log("oilSurcharge_dp",this.oilSurcharge_dp);
      }
      else if(f === "R") {
        //리턴편 유류할증료
        let oilSurcharge_rt = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,this.productInfo.ntCd]});
        this.oilSurcharge_rt = oilSurcharge_rt;
        //console.log("oilSurcharge_rt",this.oilSurcharge_rt);
      }
    },
    async getTermFeeInfo(f,t1){
      //console.log(f+"//"+t1);
      if(f === "D") {
        // let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        // this.termFee_dp = termFee_dp;
        let termFeeNameList_dp = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_dp = termFeeNameList_dp;
        //console.log("termFeeNameList_dp",this.termFeeNameList_dp);
      }
      else if(f === "R") {
        let termFeeNameList_rt = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_rt = termFeeNameList_rt;
        //console.log("termFeeNameList_rt",this .termFeeNameList_rt);
      }
    },
    async changeTermFeeNameList(f,t1){
      //console.log(f+"//"+t1);
      if(f === "D") {
        let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_dp = termFee_dp;
        //console.log("termFee_dp",this.termFee_dp);
      }
      else if(f === "R") {
        let termFee_rt = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_rt = termFee_rt;
        //console.log("termFee_rt",this .termFee_rt);
      }
    },
    /** 두날짜 사이의 날짜추출함수 */
    getDatesStartToLast(startDate, lastDate) {
      //var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      //if(!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
      if(!(startDate && lastDate)) return "Not Date Format";

      var result = [];
      var curDate = new Date(startDate);
      while(curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    },

    goToList() {
      this.$router.push({path: '/eventList'});
    },


    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },

    dataBaseTransaction(p1, p2)
    {
      if(p1==="U" && p2==="E") 
      { //행사내용 수정처리
        this.$swal.fire({
        icon: 'question',
        text: '행사내용을 저장합니까?',
        showCancelButton: true,
        confirmButtonText: '저장',
        cancelButtonText: '취소'
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            //행사예약취소의 경우
            if(this.eventInfo.reqStatus === "CX")
            {
              this.$swal.fire({
                icon: 'question',
                text: '사용한 포인트를 복구합니까?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              }).then(async (result) => 
              {
                if (result.isConfirmed) 
                {
                  //사용포인트 복구처리
                  if(this.userPayPoint.userPoint > 0)
                  {
                    let payByPoint = await this.$api("/api/payByPoint", {param: [
                      this.eventInfo.reqEmail,this.userPayPoint.userPoint,'A',this.eventCode,'행사취소'
                    ]});

                    if(Object.keys(payByPoint)[0] === 'error') 
                    {
                      this.$swal.fire('', '포인트 복구처리 중 오류가 발생하였습니다.<br>관리자에게 문의해 주세요.', 'error');
                      return false;
                    }
                    if(this.rtnRPflag === "Y")
                    {
                      this.updReqPay();
                    }
                    else
                    {
                      this.insReqPay();
                    }
                  }
                  let today = new Date();
                  let yyyy = today.getFullYear();
                  let mm_tmp = today.getMonth()+1;
                  let dd_tmp = today.getDate();
                  let hour = today.getHours();
                  let min = today.getMinutes();
                  let sec = today.getSeconds();
                  
                  let mm = "";
                  if(mm_tmp < 10) {mm = "0" + mm_tmp}
                  else mm = mm_tmp;
                  let dd = "";
                  if(dd_tmp < 10) {dd = "0" + dd_tmp}
                  else dd = dd_tmp;
                  
                  let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
                  let oldComment = ""; 
                  let newComment = "";
                  oldComment = this.eventInfo.eventComent;
                  newComment = "취소처리";
                  let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newComment;
                  let cxl_event_m = await this.$api("/api/cxl_event_m", {param: [
                    this.eventInfo.reqStatus,this.user.handlerId,insComment,
                    this.eventCode
                  ]});
                  if(cxl_event_m.affectedRows < 0 || cxl_event_m.affectedRows === undefined)
                  {
                    this.$swal.fire('', '행사예약취소(cxl_event_m) 중 중 오류가 발생하였습니다.', 'error');
                    return false;
                  }   
                  else
                  {
                    this.$swal.fire('', '행사예약이 취소되었습니다.', 'success');
                    location.reload();
                  }
                }
              })
            }
            //행사상태-취소이외
            else
            {
              if(this.cstPrice =="0")
              {
                this.$swal.fire("","상품가격 재계산을 하십시오,","warning");
                return false;
              }

              if(this.eventInfo.reqStatus === "RP" || this.eventInfo.reqStatus === "CO") //결제요청or확정
              {
                if(this.rtnRPflag === "Y")
                {
                  this.updReqPay();
                }
                else
                {
                  this.insReqPay();
                }                
              }

              //포인트 결제가 있으면 처리한다(>0)
              if(this.paidPoint > 0)
              {
                let payByPoint = await this.$api("/api/payByPoint", {param: [
                  this.eventInfo.reqEmail,this.paidPoint,'U',this.eventCode,''
                ]});
                if(Object.keys(payByPoint)[0] === 'error') 
                {
                  this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다.<br>관리자에  문의해 주세요.', 'error');
                  return false;
                }
              }
              // this.eventIfno.cstPrice_org = this.cstPrice;
              this.eventInfo.cstPrice_new = this.cstPrice;
              //let timeStamp = "";
              let today = new Date();
              let yyyy = today.getFullYear();
              let mm_tmp = today.getMonth()+1;
              let dd_tmp = today.getDate();
              let hour = today.getHours();
              let min = today.getMinutes();
              let sec = today.getSeconds();
              
              let mm = "";
              if(mm_tmp < 10) {mm = "0" + mm_tmp}
              else mm = mm_tmp;

              let dd = "";
              if(dd_tmp < 10) {dd = "0" + dd_tmp}
              else dd = dd_tmp;
              
              let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;

              let oldComment = ""; 
              //let newComment = "";
              oldComment = this.eventInfo.eventComent;
              let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ this.newComment;
              //console.log("insComment:",insComment);

              if(this.eventInfo.payPoint > 0)
              {
                this.paidPoint = this.eventInfo.payPoint;
              }

              let updEvent_m = await this.$api("/api/updEvent_m", {param: 
                [
                  this.eventInfo.eventNameKor, this.eventInfo.eventNameEng, this.eventInfo.reqName, this.eventInfo.reqTelNo, this.eventInfo.reqEmail, this.eventInfo.dptCtCode, this.eventInfo.ntCd, this.eventInfo.arCd, this.eventInfo.evtStartDate, this.eventInfo.evtCloseDate, this.eventInfo.includeContent, this.eventInfo.notIncludeContent, this.eventInfo.adultCnt, this.eventInfo.childCnt, this.eventInfo.infCnt, this.eventInfo.discountCnt, this.eventInfo.adultPrice, this.eventInfo.childPrice, this.eventInfo.infPrice, this.eventInfo.discountPrice, this.eventInfo.addItemUseFlag, this.eventInfo.addMargin, this.eventInfo.addMarginMethod, this.eventInfo.addDisUseFlag, this.eventInfo.cstPrice_new, this.eventInfo.cstPrice_org, this.eventInfo.vatPrice, this.paidPoint, insComment, this.eventInfo.evtTransFlag, this.eventInfo.reqStatus, this.eventInfo.pniUseFlag, this.user.handlerId, this.eventInfo.settleFlag, this.eventInfo.salesStopflag,this.eventInfo.conEmail, this.eventCode
                ]
              });
              if(updEvent_m.affectedRows < 0 || updEvent_m.affectedRows === undefined)
                {
                  this.$swal.fire('', '행사저장(updEvent_m) 중 오류가 발생하였습니다.', 'error');
                  return false;
                }
              else
              {
                //기존추가사항 업데이트
                if(this.addItemInfo.length > 0)
                {
                  let i = 0;
                  let res_updAddItemInfo = "";
                  while(i < this.addItemInfo.length)
                  {
                    let updAddItemInfo = await this.$api("/api/updAddItemInfo", {param: 
                      [
                        this.addItemInfo[i].addContentName, this.addItemInfo[i].addContentPrice, this.addItemInfo[i].addContentFlag, this.user.handlerId,this.eventCode, this.addItemInfo[i].subNo
                      ]
                    });
                    if(updAddItemInfo.affectedRows < 0 || updAddItemInfo.affectedRows === undefined)
                    {
                      res_updAddItemInfo = "error";
                      break;
                    }
                    else
                    {
                      res_updAddItemInfo = "ok";
                    }
                    i++;
                  }
                  if(res_updAddItemInfo == "error")
                  {
                    this.$swal.fire('', '오류발생(updAddItemInfo)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                }
                //신규추가사항 존재시 등록
                if(this.addItemUseFlag === "Y")
                {
                  //console.log("obj_addItem_check:",this.obj_addItem);
                  let addNo = (this.addItemInfo.length > 0) ? this.addItemInfo.length : 0;

                  let k = 0;
                  let res_addItem = "";
                  while(k<this.obj_addItemSize)
                  {
                    console.log("addNo_k:",addNo*1 + k);

                    let insAddEventItem = await this.$api("/api/insAddEventItem", {param: 
                      [
                        this.eventCode, (addNo*1) + k , this.obj_addItem.addItemName[k], this.obj_addItem.addItemPrice[k], this.obj_addItem.addItemFlag[k], this.user.handlerId
                      ]
                    });
                    console.log("insAddEventItem:",insAddEventItem[k]);
                    if(Object.keys(insAddEventItem)[0] === 'error')
                    {
                      res_addItem = "error";
                      //console.log(res_addItem)
                      break;
                    }
                    else
                    {
                      res_addItem = "ok";
                    }
                    k++;
                  }
                  if(res_addItem === "error")
                  {
                    this.$swal.fire('', '오류발생(insAddEventIetm)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                }

                //기존추가할인 업데이트
                if(this.addDisInfo.length > 0)
                {
                  let i = 0;
                  let res_updAddDisInfo = "";
                  while(i < this.addDisInfo.length)
                  {
                    let updAddDisInfo = await this.$api("/api/updAddDisInfo", {param: 
                      [
                        this.addDisInfo[i].addDiscountName, this.addDisInfo[i].addDiscountPrice, this.addDisInfo[i].addDiscountFlag, this.user.handlerId,this.eventCode, this.addDisInfo[i].subNo
                      ]
                    });
                    if(updAddDisInfo.affectedRows < 0 || updAddDisInfo.affectedRows === undefined)
                    {
                      res_updAddDisInfo = "error";
                      break;
                    }
                    else
                    {
                      res_updAddDisInfo = "ok";
                    }
                    i++;
                  }
                  if(res_updAddDisInfo == "error")
                  {
                    this.$swal.fire('', '오류발생(updAddItemInfo)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                }
                //추가할인 존재시 처리
                if(this.addDisUseFlag === "Y")
                {
                  console.log("obj_addDisItem:",this.obj_addDisItem);
                  let addNo = (this.addDisInfo.length > 0) ? this.addDisInfo.length : 0;

                  let m = 0;
                  let res_addDis = "";
                  while(m<this.obj_addDisItemSize)
                  {
                    // console.log("obj_addDisItem.addDisFlag:",this.obj_addDisItem[m].addDisFlag);
                    // console.log("obj_addDisItem.addDisName:",this.obj_addDisItem[m].addDisName);
                    // console.log("obj_addDisItem.addDisPrice:",this.obj_addDisItem[m].addDisPrice);
                    let insAddDiscountItem = await this.$api("/api/insAddDiscountItem", {param: 
                      [
                        this.eventCode, (addNo*1) + m, this.obj_addDisItem.addDisName[m], this.obj_addDisItem.addDisPrice[m], this.obj_addDisItem.addDisFlag[m], this.user.handlerId
                      ]
                    });
                    if(Object.keys(insAddDiscountItem)[0] === 'error')
                    {
                      res_addDis = "error";
                      //console.log(res_addItem)
                      break;
                    }
                    else
                    {
                      res_addDis = "ok";
                    }
                    m++;
                  }
                  if(res_addDis === "error")
                  {
                    this.$swal.fire('', '오류발생(insAddEventIetm)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                  // else
                  // {
                  //   this.$swal.fire('', '행사상품 저장이 완료되었습니다', 'success')
                  //   location.reload();
                  // }
                }
              }
              this.$swal.fire('', '행사상품 저장이 완료되었습니다', 'success')
              location.reload();              
            }
          }
        })
      }

      //PNI등록
      else if(p1==="U" && p2==="P") 
      {
        console.log("eventCode:",this.eventCode);
        this.$swal.fire({
        icon: 'question',
        text: '행사참가인원을 등록(변경)합니까?',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            if(this.eventCode == undefined || this.eventCode == "" || this.eventCode == null) 
            {
              this.$swal.fire('', '행사코드가 존재하지 않습니다.<br> 우선 행사를 생성해주십시오.', 'warning');
              return false;          
            }

            let pniIndex = document.getElementsByName("pniIndex");

            //권종별 인적사항 등록(변경update or 추가insert)
            let i=0;
            let res1 = "";
            //let updPniData = [];
            while(i < pniIndex.length)
            {
              if(i < this.pniList.length)
              {
                let updPniData = await this.$api("/api/updPniData_evt", {param: [
                  this.pniList[i].passNameEng,this.pniList[i].passNameKor,this.pniList[i].pBirthDate,this.pniList[i].passLicenseNo,this.pniList[i].passNo,this.pniList[i].pExpDate,this.pniList[i].passFlag,'',this.pniList[i].imgFlag,this.user.handlerId,this.pniList[i].priceType,this.pniList[i].pniTel,this.pniList[i].pniUseFlag,this.eventCode,pniIndex[i].value
                ]});
                if(updPniData.affectedRows < 0 || updPniData.affectedRows === undefined)
                {
                  res1 = "error";
                  break;
                }
                else
                {
                  res1 = "ok";
                }
              }
              else if(i >= this.pniList.length)
              {
                let insPniData = await this.$api("/api/insPniData_evt", {param: [
                  this.eventCode, pniIndex[i].value,this.passNameEng[i],this.passNameKor[i],this.pBirthDate[i],this.passLicenseNo[i],this.passNo[i],this.pExpDate[i],this.passFlag[i],'','N',this.user.handlerId,'Y',this.priceType[i],this.pniTel[i]
                ]});
                if(Object.keys(insPniData)[0] === 'error') 
                {
                  res1 = "error";
                  break;
                }
                else
                {
                  res1 = "ok";
                }
              }
              i++;
            }
            if(res1 == "error")
            {
              this.$swal.fire('', '인적사항 처리 중(PNI updating) 오류가 발생하였습니다.', 'error');
              return false;
            }
            else
            {
              this.$swal.fire('', '인적사항 저장완료', 'success')
              location.reload();              
              // //PNI 카운트변경 동기처리
              // let updPniAndEventM = await this.$api("/api/updPniAndEventM", {param: [
              //   this.eventInfo.reqAdultCnt,this.eventInfo.reqChildCnt,this.eventInfo.reqInfCnt,this.eventInfo.reqDiscountCnt,this.user.handlerId,this.eventCode
              //   ]});
              //   if(updPniAndEventM.affectedRows < 0 || updPniAndEventM.affectedRows === undefined)
              //   {
              //     this.$swal.fire('', '처리 중 오류(updPniAndEventM)가 발생했습니다.<br>관리자에게 문의해 주세요.', 'error');
              //   }
              //   else
              //   {
              //     this.$swal.fire('', '인적사항 저장완료', 'success')
              //     location.reload();
              //   }
            }
          }
        })
      }      
      else if(p1==="U" && p2==="T") {
        this.$swal.fire({
        icon: 'question',
        text: '교통정보을 저장합니까?',
        showCancelButton: true,
        confirmButtonText: '저장',
        cancelButtonText: '취소'
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            if(this.eventInfo.evtTransFlag === "Y") //기존교통편 업데이트
            {
              let resUpd_pdtTransInfo = await this.$api("/api/pdtTransInfoUpdate", {param: [
                this.pdtTransInfo.trCd1,this.pdtTransInfo.mbCd1,this.pdtTransInfo.dpdCd,this.pdtTransInfo.dpaCd,this.pdtTransInfo.transRemarks_dp,
                this.pdtTransInfo.oilCd_dp,this.pdtTransInfo.termCd_dp,
                this.pdtTransInfo.trCd2,this.pdtTransInfo.mbCd2,this.pdtTransInfo.rtdCd,this.pdtTransInfo.rtaCd,this.pdtTransInfo.transRemarks_ar,
                this.pdtTransInfo.oilCd_rt,this.pdtTransInfo.termCd_rt,
                this.user.handlerId, this.pdtTransInfo.dpTpCode, this.pdtTransInfo.d_dpTime, this.pdtTransInfo.d_arTime, this.pdtTransInfo.rtTpCode, this.pdtTransInfo.r_dpTime, this.pdtTransInfo.r_arTime,
                this.eventCode
              ]});
              //console.log("errCheck:", Object.keys(resUpd_pdtTransInfo)[0]);
              // 인서트오류
              if(resUpd_pdtTransInfo.affectedRows < 0 || resUpd_pdtTransInfo.affectedRows === undefined){
                this.$swal.fire('', '교통정보 업데이트 오류', 'alert')
              }
              else{
                this.$swal.fire('', '교통정보 변경완료', 'success')
                location.reload();
              }
            }
            else //신규고통편 생성
            {
              let timeStamp = "";
              let today = new Date();   
              let yyyy = today.getFullYear();
              let mm = today.getMonth()+1;
              let dd = today.getDate();
              let ymd = `${yyyy}${mm}${dd}`      
              //console.log(`ymd: ${ymd}`);
              timeStamp = today.getTime(); // 교통코드에 들어갈 타임스탬프
              let catCode = "TR";
              this.pdtTransInfo.pdtCode = this.eventCode;
              this.pdtTransInfo.pdtTransCode = catCode+"-"+ymd+"-"+timeStamp;
              this.pdtTransInfo.insDate = `${yyyy}-${mm}-${dd}`;
              this.pdtTransInfo.insId = this.user.handlerId;

              let resIns_product_m = await this.$api("/api/pdtTransInsert", {param: [this.pdtTransInfo]});

              if(Object.keys(resIns_product_m)[0] === 'error')  //인서트오류
              {
                this.$swal.fire('', '교통편 저장오류(resIns_product_m)<br>관리자에게 문의해 주세요.', 'error')
              }
              else 
              {
                let updTransInfo_evt = await this.$api("/api/updTransInfo_evt", {param: ['Y', this.eventCode]});
                if(updTransInfo_evt.affectedRows < 0 || updTransInfo_evt.affectedRows === undefined) 
                {
                //alert("상품가격정보 수정오류\n결과확인 후 재실행");
                this.$swal.fire('','오류발생(updTransInfo_evt)<br>관리자에게 문의해 주세요.', 'error')
                }
                else
                {
                  this.$swal.fire('','정보등록완료','success')
                  location.reload();
                }                
              }
              //this.$swal.fire('', '교통정보 변경완료', 'success')
              //location.reload();
            }
          }
        })
      }
      else if (p1==="M" && p2 ==="P")
      {
        // 상담가격 재계산 후 업데이트 진행
        this.calcCstPrice();
      }

      else {
        return this.$swal.fire("","Case-DBTransation: <br>오류가 발생했습니다.<br>관리자에 문의해주세요.","error");
      }
    },
    //결제요청 정보처리
    async insReqPay()
    {
      let insReqPayInfo = await this.$api("/api/insReqPayInfo",{param: [
        this.eventCode,this.eventInfo.reqStatus,this.cstPrice,'N',this.user.handlerId
      ]});
      if(Object.keys(insReqPayInfo)[0] === 'error') 
      {
        this.$swal.fire('','처리 중 오류발생(insReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    },
    async updReqPay()
    {
      let updReqPayInfo = await this.$api("/api/updReqPayInfo",{param: [
        this.eventInfo.reqStatus,this.cstPrice,this.user.handlerId,this.eventCode
      ]});
      if(updReqPayInfo.affectedRows < 0 || updReqPayInfo.affectedRows === undefined)
      {
        this.$swal.fire('','처리 중 오류발생(updReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    }    
  }
}
</script>