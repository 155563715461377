<template>
  <main class="mt-5">
    
    <div class="container request text-center">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <h3 class="d-flex justify-content-center" style="font-weight:600">견 적 요 청</h3>

          <div class="row justify-content-center">
            <div class="col-12 col-md-8 ">
              <div class="box">
                <!-- 안내사항 -->
                <p class="head mt-3">
                  ※ 안녕하세요 고객님! 어떤 여행을 준비하고 계신가요?<br>
                  담당 직원이 확인하여 곧 답변을 드리겠습니다.<br><br>
                  카카오톡 플러스 친구로 문의를 주시면,<br>
                  더욱 빠른 답변을 받아보실 수 있습니다.
                </p>
                <!-- <p class="rinfo"><input type="checkbox" v-model="this.quotationInfo.ruleAgree" class="align-bottom" ref="chb_ruleAgree" true-value="Y" false-value="N"> &nbsp;개인정보 수집에 동의합니다.</p> -->

                <hr>

                <!-- 여행유형 , 대표자 -->
                <div class="row my-3">
                  <div class="col-md-6 gx-3 my-2">
                    <label class="col-form-label my-1 name">여행유형 <font class="text-danger">*</font></label>
                    <div class="input-group">
                      <label class="text-secondary">{{ this.quotationInfo.reqStatus }}</label>
                      <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_inquiry" v-model="this.quotationInfo.selCat" style="line-height:30px">
                        <option :value="noVal" disabled>-- 유형선택 --</option>
                        <option :value="catList.procCode" :key="i" v-for="(catList, i) in inquiryCatList">
                          {{ catList.procName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 gx-3 my-2">
                    <label class="name col-form-label my-1">여행대표자/인원수<font class="text-danger"> *</font></label>
                    <div class="input-group">
                      <input type="text" class="form-control" ref="txt_reqPersonName" v-model="this.quotationInfo.reqPersonName" placeholder="요청자 성명">
                      <input type="number" class="form-control" ref="txt_perCnt" v-model="this.quotationInfo.perCnt" placeholder="인원수">
                    </div>
                  </div>
                </div>

                <!-- 제목 -->
                <div class="row my-3">
                  <div class="col-md-12 gx-3">
                    <label class="name col-form-label">제목<font class="text-danger"> *</font></label>
                    <input type="text" maxlength="22" class="form-control" ref="txt_reqTitle" v-model="this.quotationInfo.reqTitle">
                  </div>
                </div> 
                    
                <!-- 연락처, 이메일 -->
                <div class="row my-3">
                  <div class="col-md-6 gx-3 my-2">
                    <label class="name col-form-label my-1">연락처(휴대폰)<font class="text-danger"> *</font></label>
                    <input type="text" maxlength="11" id="reqTel" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.quotationInfo.reqTel">
                  </div>
                  <div class="col-md-6 gx-3 my-2">
                    <label class="name col-form-label my-1">이메일<font class="text-danger"> *</font></label>
                    <input type="email" maxlength="40" placeholder="EX) Hong123@ntabi.co.kr" class="form-control" ref="txt_cEmail" v-model="this.quotationInfo.reqEmail">
                  </div>
                </div>

                <!-- 예상일정 -->
                <div class="row my-3">
                  <div class="col-md-12 gx-3">
                    <label class="name col-form-label">여행일정<font class="text-danger"> *</font></label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1">시작</span>
                      <input type="date" class="form-control" ref="txt_evtStartDate" v-model="this.quotationInfo.startDate">
                      <span class="input-group-text" id="basic-addon1">종료</span>
                      <input type="date" class="form-control" ref="txt_evtCloseDate" v-model="this.quotationInfo.closeDate">
                    </div>
                  </div>
                </div> 

                <!-- 출발지등 -->
                <div class="row mt-4 mb-1">
                  <div class="col-md-12 gx-3">
                    <label class="name col-form-label">출발지/여행국가/여행지역<font class="text-danger"> *</font></label>
                    <div class="input-group mb-3" >
                      <!-- 출발도시 -->
                      <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_deptCity" v-model="this.quotationInfo.ctCd" style="line-height:30px">
                        <option :value="cityList[i].baseCode" :key=i v-for="(baseCode,i) in cityList">{{cityList[i].codeNameKor}}</option>
                      </select> 
                      <!-- 여행국가 -->
                      <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripNation" v-model="this.quotationInfo.ntCd" @change="changeNationList(this.quotationInfo.ntCd)">
                        <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
                      </select>
                      <!-- 여행지역 -->
                      <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripArea" v-model="this.quotationInfo.arCd">
                        <option :value="areaList[j].baseCode" :key=j v-for="(baseCode,j) in areaList">{{areaList[j].codeNameKor}}</option>
                      </select>
                    </div>
                  </div>
                </div> 

                <!-- 교통편 선택 -->
                <div class="row my-3">
                  <label class="col-12 col-lg-2 col-form-label name">선호하는 교통편</label>
                  <div class="col-12 col-lg-10 text-start vehicle" for="ckb_transFlag">
                    <div class="form-check align-middle">
                      <div class="row p-1">
                        <div class="mb-1 row">
                          <label class="col-md-2 col-form-label name">출발편<font class="text-danger"> *</font></label>
                          <div class="col-10">
                            <div class="input-group mb-1">
                              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="quotationInfo.trCd1" @change="changeDpTransList()" style="line-height:30px">
                                <option :value="noVal">비이용</option>
                                <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
                              </select>
                              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="quotationInfo.mbCd1"  @change="changeDpMobilityList">
                                <option :value="noVal" disabled>선호교통편</option>
                                <option :value="mobilityList_dp[m].transCode" :key=m v-for="(transCode,m) in mobilityList_dp">{{mobilityList_dp[m].codeNameKor}}</option>
                              </select>
                            </div>
                          </div>
                        </div> 
                        <div class="mb-1 row">
                          <label class="col-md-2 col-form-label name">추가내용</label>
                          <div class="col-md-10">
                            <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="quotationInfo.transRemarks_dp" maxlength="210"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row p-1">
                        <div class="mb-1 row">
                          <label class="col-md-2 col-form-label name">리턴편</label>
                          <div class="col-md-10">
                            <div class="input-group mb-1">
                              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="quotationInfo.trCd2" @change="changeRtTransList" style="line-height:30px">
                                <option :value="noVal">비이용</option>
                                <option :value="transList[q].baseCode" :key=q v-for="(baseCode,q) in transList">{{transList[q].codeNameKor}}</option>
                              </select>
                              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="quotationInfo.mbCd2"  @change="changeRtMobilityList">
                                <option :value="noVal" disabled>선호교통편</option>
                                <option :value="mobilityList_rt[r].transCode" :key=r v-for="(transCode,r) in mobilityList_rt">{{mobilityList_rt[r].codeNameKor}}</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="mb-1 row">
                          <label class="col-md-2 col-form-label name">추가내용</label>
                          <div class="col-md-10 mb-2">
                            <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="quotationInfo.transRemarks_ar" maxlength="210"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <!-- 문의사항 -->
                <div class="row mt-4">
                  <div class="col-md-12 gx-3">
                    <label class="name col-form-label">문의사항(500자)</label>
                    <textarea class="form-control" maxlength="250" style="height:200px" placeholder="EX) &#10; - 숙박등급: &#10; - 소아,유아 동반 유무: &#10; - 필수 관광지: &#10; - 기타 문의:" ref="txt_reqSpRemarks" id="floatingTextarea" v-model="this.quotationInfo.reqSpRemarks"></textarea>
                  </div>
                </div>     


                <!-- 약관 동의 -->
                <div class="row mt-2 px-2">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="this.quotationInfo.ruleAgree" ref="chb_ruleAgree" true-value="Y" false-value="N">
                    <label class="form-check-label" for="flexCheckDefault">
                      <span class="text-danger px-2">[필수]</span> 개인정보 수집 및 이용동의 <button class="totalsee" id="button2" data-toggle="modal" data-target="#popup_box" @click="isModalOpen = true">전체보기</button>
                    </label>
                  </div>

                  <!-- 모달창 -->
                  <div v-if="isModalOpen" class="modal">
                    <div class="modal-content">
                      <div class="row main-fix">
                        <div class="col-8">
                          <span>개인정보 수집 및 이용 동의</span>
                        </div>
                        <div class="col-4 text-end">
                          <span class="close" @click="isModalOpen = false">&times;</span>
                        </div>
                      </div>
                      <hr>
                      <p>
                        엔타비 글로벌 (이하 '엔타비')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br>
      
                        1. 개인정보 수집 및 이용목적<br>
                        엔타비는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br>
                        &nbsp; 1) 홈페이지 회원가입 및 관리<br>
                        &nbsp; 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.<br>
                        &nbsp; 2) 서비스 제공<br>
                        &nbsp;  물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.<br>
                        &nbsp; 3) 마케팅 및 광고에의 활용<br>
                        &nbsp; 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
                        <br>
                        2. 개인정보 수집 항목<br>
                        엔타비는 다음의 개인정보 항목을 처리하고 있습니다.<br>
                        &nbsp; 1) 홈페이지 회원가입 및 관리/마케팅 정보 제공의 목적 (회원탈퇴 시 까지)<br>
                        &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름<br>
                        &nbsp; 2) 상담 및 해외 및 국내 여행 예약의 목적 (신용카드정보, 은행계좌정보)<br>
                        &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 여권사본, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호<br>
                        &nbsp; 3) 여행자 보험가입의 목적 (신용카드정보, 은행계좌정보)<br>
                        &nbsp;  이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름<br>
                        &nbsp; 4) 대금결제의 목적 (이용목적이 달성되면 파기)<br>
                        &nbsp;  신용카드정보, 은행계좌정보<br>
                          <br>
                        3. 개인정보 수집 항목 보유 및 이용기간<br>
                        &nbsp; 1) 엔타비는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.<br>
                        &nbsp; 2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
                        &nbsp;  ① 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                        &nbsp;  ② 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                        &nbsp;  ③ 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                        &nbsp;  ④ 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                        &nbsp;  ⑤ 표시/광고에 관한 기록 : 6개월<br>
                      </p>
                    </div>
                  </div>
                </div>


                <!-- 버튼 -->
                <div class="mt-5 row justify-content-center">
                  <button class="btn cxl col-5 col-lg-4 mx-2"  @click="goToHome()">취소</button>
                  <button class="btn go col-5 col-lg-4 mx-2" @click="requestInsert();">문의</button>
                </div>

              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>

  </main>

</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,       //모달컨트롤
      
      inquiryCatList: {}, //질의영역분류
      cityList: {},
      nationList: {},
      areaList: {},
      areaList_tmp: {},
      transList: {},
      mobilityList_tmp: {},
      mobilityList_dp: {}, //출발편
      mobilityList_rt: {}, //리턴편

      //견적요청정보
      quotationInfo: {
        ruleAgree: "N", //약관동의
        qtCode: "", // 견적코드
        reqTitle: "", //제목
        reqPersonName: "", //문의자명
        perCnt: 1, //인원수
        reqEmail: "", //문의자 이메일
        reqTel: "", //문의자 연락처

        startDate: "", //출발일
        closeDate: "", //리턴일

        ctCd: "", //출발도시코드
        ntCd: "", //방문국가코드
        arCd: "", //방문지역코드

        transFlag: "N", //교통편 선택여부
        trCd1: "", //출발운송수단코드(예: 선박)
        trCd2: "", //리턴운송수단코드
        mbCd1: "", //출발교통편코드(예: 비틀)
        mbCd2: "", //리턴교통편코드
        transRemarks_dp: "", //출발편 추가정보
        transRemarks_ar: "", //리턴편 추가정보

        reqSpRemarks: "", //기타문의사항

        insDate: "",
        insId: "",
      }
    };
  },
  created(){
    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      //this.user = "nonMember"
      location.href = "/b2cLogin";
    }

    console.log("userInfo:",this.$store.state.user);
    console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);

    this.getTransList();
    this.getMobilityList();

    this.getInquiryCatList();
    this.getCityList();
    this.getNationList();
    this.getAreaList();
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/b2CLogin', query:{st: "Y"}});
    }    
  },  
  methods: {
    //견적관련 프로세스 코드
    async getInquiryCatList() {
      let inquiryCatList = await this.$api("/api/inquiryCatList2", {param:["Q"]});
      if (inquiryCatList.length > 0) {
        this.inquiryCatList = inquiryCatList;
      }
      console.log("inquiryCatList:",this.inquiryCatList);
    },
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      this.cityList = cityList;
      //console.log("cityList",cityList);
    },
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    async getAreaList() {
      this.areaList_tmp = await this.$api("/api/areaList", {});
    },
    changeNationList(param) {
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaList(param);
    },
    viewAreaList(param1){
      //console.log("selectedNation:", param);
      this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },

    // openTrans(val) {
    //   if(val === "Y")
    //   {
    //     this.getTransList();
    //     this.getMobilityList();
    //   }
    // },
    
    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      console.log("transList",transList);
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      console.log("mobilityList_tmp",this.mobilityList_tmp);
    },
    //교통수단변경->교통편획득
    changeDpTransList() {
      this.quotationInfo.mbCd1 = "";
      this.viewDpMobilityList(this.quotationInfo.trCd1);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    changeRtTransList() {
      this.quotationInfo.mbCd2 = "";
      this.viewRtMobilityList(this.quotationInfo.trCd2);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    viewDpMobilityList(param1){
      this.mobilityList_dp = this.mobilityList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("mobility:", this.mobilityList_dp);
    },
    viewRtMobilityList(param2){
      this.mobilityList_rt = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList_rt);
    },
    changeDpMobilityList() {
      this.getDpTermList(this.quotationInfo.ntCd, this.quotationInfo.trCd1);
    },
    changeRtMobilityList() {
      this.getRtTermList(this.quotationInfo.ntCd, this.quotationInfo.trCd2);
    },
    async getDpTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
      //출발도착터미널
      this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_dp);
    },
    async getRtTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      this.termList_rtd = await this.$api("/api/termList", {param: [param2, param4]});
      this.termList_rta = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
    },

    async requestInsert() {
      //오늘날짜추출
      const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      const time = new Date().toTimeString().split(" ")[0];
      let today = date + ' ' + time;
      let timeStamp = new Date().getTime();
      let qtCode = "QT-" + date.replace(/-/g, '') + "-" + timeStamp;
      this.quotationInfo.qtCode = qtCode;
      this.quotationInfo.insId = this.user;
      console.log("quotationInfo:",this.quotationInfo);

      // console.log("today:", date + ' ' + time);
      if(this.quotationInfo.ruleAgree === "N" || this.quotationInfo.ruleAgree == undefined) 
      { 
        this.$refs.chb_ruleAgree.focus();
        return this.$swal.fire("","진행을 위해 개인정보 수집의 동의가 필요합니다.", "warning");
      }
      if(this.quotationInfo.selCat === "noVal" || this.quotationInfo.selCat == undefined) { 
        this.$refs.sel_inquiry.focus();
        return this.$swal.fire("","유형을 선택해주세요.", "question");
      }
      if(!this.quotationInfo.reqTitle) { 
        this.$refs.txt_reqTitle.focus();
        return this.$swal.fire("","문의제목을 입력하세요", "warning");
      }
      if(!this.quotationInfo.reqPersonName) {
        this.$refs.txt_reqPersonName.focus();
        return this.$swal.fire("","문의자 성명을 입력하세요", "warning");
      }
      if(!this.quotationInfo.perCnt) {
        this.$refs.txt_perCnt.focus();
        return this.$swal.fire("","인원수를 입력하세요", "warning");
      }
      else if(this.quotationInfo.perCnt < 1) {
        this.$refs.txt_perCnt.select();
        return this.$swal.fire("","최소 1인 이상으로 입력해주세요.", "info");
      }      
      // else if(this.quotationInfo.perCnt > 15) {
      //   this.$refs.txt_perCnt.select();
      //   return this.$swal.fire("","15인 이상은 별도 문의를 해주세요<br>(TEL) 051-466-4602", "info");
      // }
      if(!this.quotationInfo.reqEmail) {
        this.$refs.txt_cEmail.focus();
        return this.$swal.fire("","이메일을 입력하세요", "warning");
      }
      if(!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]+$/.test(this.quotationInfo.reqEmail)) {
        this.$refs.txt_cEmail.focus();
        return this.$swal.fire('','이메일 양식이 불명확합니다', 'question');
      }
      if(!this.quotationInfo.reqTel) {
        this.$refs.txt_cTel.focus();
        return this.$swal.fire("","연락처를 입력하세요", "warning");
      }
      if(!/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(this.quotationInfo.reqTel)) {
        this.$refs.txt_cTel.focus();
        return this.$swal.fire('','연락처가 불명확합니다', 'question');
      }

      this.quotationInfo.insDate = today;
      this.quotationInfo.reqStatus = "IQ";

      let resIns_Request = await this.$api("/api/reqQuotationInsert", {param: [this.quotationInfo]});
        console.log("errCheck:", Object.keys(resIns_Request)[0]);
        // 인서트오류
        if(Object.keys(resIns_Request)[0] === 'error') 
        {
          this.$swal.fire('', '견적요청 저장 중 오류가 발생했습니다.<br>(문의처: 1660-4602)', '')
        }      
      else 
      {
        this.$swal.fire('','감사합니다<br>빠른 시간안에 확인연락을 드리겠습니다.', '');
        this.$router.push({path: '/'});
      }
      //console.log("resIns_Request:",resIns_Request);
    },
  goToHome() {
    this.$router.push({path: '/'});
  }
  }
}
</script>


<style>
.request{
  margin-top:5rem
}
.request .box {
  text-align:left
}
.request .box .head{
  text-align: center;
  font-size:.9rem;
  font-weight:bold;
}
.request .box .rinfo{
  text-align: center;
  margin:2rem 0 2.5rem;
}
.request .box .name{
  font-size:.9rem;
  font-weight:bold
}
.request .box .form-select-sm,
.request .box .form-control,
.request .box .input-group-text{
  border-radius: 0;
}
.request .box .form-select-sm{
  padding-top:.55rem;
  padding-bottom:.55zdrem
}
.request .box .form-control{
  padding:.75rem
}
@media (max-width:992px){
  .request .box .vehicle .form-check{
    padding-left:0
  }
}



.request .go {
  padding:15px 0;
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.request .cxl{
  padding:15px 0;
  background-color: #eee;
  border-radius:0;
}
.request .go:hover{
  background-color: #444;
}
.request .cxl:hover{
  border:1px solid #777
}



/* 약관 동의 */
.request .form-check-label{
  margin:-10px 0 0 -5px
}
.request .form-check-label .totalsee{
  font-size:.85rem;
  font-weight: 600;
  color:#333;
  background-color: #fff;
  border:transparent;
  text-decoration: underline;
  text-underline-position:under;
}

.request .modal-content{
  width:50%;
  height:65%;
  overflow-y: auto;  
}
.request .main-fix{
  padding-top:.5rem
}
.request .modal-content span{
  padding-left:1rem;
  font-size:1.2rem;
  font-weight: 600;
  color:#000
}
.request .modal-content{
  width:50%;
  height:65%;
  overflow-y: auto;  
}
.request .modal-content .close{
  padding-right:10px;
}
.request .modal-content p{
  padding:0 1rem;
  font-size:.9rem;
  line-height:25px
}

@media (max-width:992px){
  .request .modal-content{
    width:80%;
    height:65%;
  }
}
</style>
