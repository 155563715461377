<template>
  <div id="kavalan">
    <div class="main_bg">
      <!--<img class="kavalan-title" src="../assets/img/kavalan/title.png" alt="">-->
      <img class="kavalan-main-img" src="../assets/img/kavalan/kavalan-main.jpg" alt="">
    </div>
    <div class="bottom_btn">
      <a class="course" href="javascript:void(0)" @click="goToDetail('L-2024125-1706174265647','L')">코스보기</a>
      <a class="reservation" href="https://pf.kakao.com/_zGkTxl">신청하기</a>
    </div>
  </div>
    
     
  
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {

    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요',
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'});
    // }
    //this.isMobile = this.checkIfMobile();
  },
  methods: {
    goToDetail(productId, salesRoute) 
    {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
  }
}
</script>



<style>
@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@300;400;500;700&display=swap');
.kavalan-main-img{
  width: 100%;
}
.bottom_btn{
  width: 100%;
  height: 200px;
  margin: 100px auto;
  text-align: center;
}
.course{
  padding-top: 30px;
  margin: 30px 40px;
  display: inline-block;
  width: 300px;
  height: 100px;
  border: 2px solid #333;
  background-color: #fff;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.5s;
  color: #000;
}
.course:hover{
  background-color: #000;
  color: #fff;
}
.reservation{
  padding-top: 30px;
  margin: 30px 40px;
  display: inline-block;
  width: 300px;
  height: 100px;
  background-color: #fff;
  color: #000;
  border: 2px solid #333;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.5s;
}
.reservation:hover{
  background-color: #000;
  color: rgb(255, 255, 255);
}
@media (max-width:767px){
  .bottom_btn{
    margin: 0 auto;
    height: 100px;
  }
  .course{
  padding-top: 12px;
  margin: 30px 20px;
  width: 100px;
  height: 50px;
  font-size: 15px;
}
.reservation{
  padding-top: 12px;
  margin: 30px 20px;
  width: 100px;
  height: 50px;
  border: 2px solid #333;
  font-size: 15px;
  
}
}
</style>

