<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>비 용 수 정</u></h2>
      <p>
        <label><font class="text-warning" style="font-size: small;">(유류할증료는 초기금액으로 표시됨)</font></label>
      </p>
    <div class="mb-3 row">
        <!-- 수정불가 -->
        <label class="col-md-3 col-form-label">코드</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="this.etcFeeCode" readonly>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">분류</label>
        <div class="col-md-9">
          <div class="form-check-inline" :key=i v-for="(baseCode,i) in this.feeCode">
            <div v-if="this.feeCode[i].baseCode === this.feeClass">
              <input class="form-check-input" type="radio" v-model="productInfo.feeClass" value=this.sRoute[i].baseCode ref="rbt_salesRoute" checked>
              <label class="form-check-label text-primary" for="flexRadioDefault1">
                {{ this.feeCode[i].codeNameKor }}
              </label>
            </div>
            <div v-else>
              <input class="form-check-input" type="radio" v-model="productInfo.feeClass" value=this.sRoute[i].baseCode ref="rbt_salesRoute" disabled>
              <label class="form-check-label text-secondary" for="flexRadioDefault1">
                {{ this.feeCode[i].codeNameKor }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">명칭<font class="text-secondary" style="font-size: small;">(국문)</font> <font class="text-danger">*</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_etcFeeNameKor" v-model="productInfo.etcFeeNameKor">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">Name<font class="text-secondary" style="font-size: small;">(영문)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productInfo.etcFeeNameEng">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">적용기간<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.fromDate">
            <!-- <date-picker class="form-control" v-model="productInfo.fromDate" ref="txt_fromDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker> -->
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.toDate">
            <!-- <date-picker class="form-control" v-model="productInfo.toDate" ref="txt_toDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker> -->
          </div>
        </div>
      </div>

      <div>
        <div class="mb-3 row" v-if="productInfo.feeClass === 'T'">
          <label class="col-md-3 col-form-label">국가/수단/터미널<font class="text-danger">*</font>
          <p><font class="text-primary" style="font-size:small;">현재터미널:&nbsp;<b>{{productInfo.tmName}}</b></font></p>
          </label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ntCd" v-model="productInfo.ntCd">
                <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_trCd" v-model="productInfo.trCd" @change="changeTransList()">
                <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tmCd" v-model="productInfo.tmCd">
                <option :value="termList[p].baseCode" :key=p v-for="(baseCode,p) in termList">{{termList[p].codeNameKor}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-3 row" v-if="productInfo.feeClass === 'O'">
          <label class="col-md-3 col-form-label">노선/수단/출발/교통<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_liCd" v-model="productInfo.liCd">
                <option :value="lineList[i].baseCode" :key=i v-for="(baseCode,i) in lineList">{{lineList[i].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_trCd1" v-model="productInfo.trCd" @change="changeTrList()">
                <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ntCd1" v-model="productInfo.ntCd">
                <option :value="nationList[k].baseCode" :key=k v-for="(baseCode,k) in nationList">{{nationList[k].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_mbCd" v-model="productInfo.mbCd">
                <option :value="this.productInfo.mbCd" checked disabled>{{this.productInfo.codeNameKor}}</option>
                <option :value="mobilityList[j].transCode" :key=j v-for="(transCode,j) in mobilityList">{{mobilityList[j].codeNameKor}}</option>
              </select>
            </div>
          </div>
        </div>
        <div v-else></div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">결제통화<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_cuCd" v-model="this.etcFeeDetail.cuCd">
              <option :value="currencyList[i].baseCode" :key=i v-for="(baseCode,i) in currencyList">{{currencyList[i].codeNameKor}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">비용정보<font class="text-danger">*</font>
          <p v-if="productInfo.feeClass === 'T'"><font class="text-danger" style="font-size:small;">1:성인 / 2:소아 / 3:인펀트 / 4:기타할인</font></p>
          <p><font class="text-secondary" style="font-size:small;">(미입력시 0원)</font></p>
        </label>
        <div class="col-md-9" v-if="!productInfo.feeClass">
        </div>
        <div class="col-md-9" v-else-if="productInfo.feeClass === 'T'">
          <div class="input-group mb-3" :key="i" v-for="(fee, i) in this.etcFeeDetail">
            <span class="input-group-text">{{etcFeeDetail[i].agegroup}}</span>
            <input type="number" class="form-control-sm text-end" ref="txt_feePrice[i]" v-model="fee.ageFee">
          </div>
        </div>
        <div class="col-md-9" v-else-if="productInfo.feeClass === 'O'">
          <!-- <div class="input-group mb-3">
            <span class="input-group-text">1</span>
            <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice1" v-model="this.etcFeeDetail[0].ageFee">
          </div> -->
          <div class="input-group mb-3" :key="i" v-for="(fee, i) in this.etcFeeDetail">
            <span class="input-group-text">{{etcFeeDetail.agegroup}}</span>
            <input type="number" class="form-control-sm text-end" ref="txt_feePrice[i]" v-model="fee.ageFee">
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">특기사항</label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
            v-model="productInfo.pdtRemarks" maxlength="210"></textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="feeUpdate();">수정</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">이전</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>
    </div>
  </main>

</template>

<script>
//import DatePicker from 'vue2-datepicker';
//import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      productInfo: {},
      feeCode: {},
      etcFeeDetail: {},
      //openParams: {},

      nationList: {},
      transList: {},
      termList: {},
      mobilityList_tmp: {},
      mobilityList: {},
      lineList: {}, //노선(국제-국내)
      currencyList: {},
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.etcFeeCode = this.$route.query.etcFeeCode;
    this.feeClass = this.$route.query.feeClass;

    this.getEtcPriceInfo();
    this.getEtcFeeCode();
    this.getNationList();
    this.getTransList();
    this.getMobilityList();
    this.getLineList(); //노선리스트
    this.getCurrencyList(); //통화

    this.getEtcFeeDetail(); //금액상세
  },
  mounted() {
    console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    // callTest(){
    //   let i=0;
    //   while(i<4 ) {
    //     console.log("priceInof",i,":",this.etcFeeDetail[i].ageFee)
    //     i++;
    //   }
    // },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    //개별비용정보
    async getEtcPriceInfo() {
      let productInfo = await this.$api("/api/etcPriceInfo", {param:[this.etcFeeCode,this.etcFeeCode]});
      this.productInfo = productInfo[0];
      this.productInfo.fromDate = this.getDateFormat(this.productInfo.fromDate);
      this.productInfo.toDate = this.getDateFormat(this.productInfo.toDate);
      console.log("productInfo",this.productInfo);
    },
    //비용코드리스트
    async getEtcFeeCode() {
      this.feeCode = await this.$api("/api/etcFeeCode", {});
      //console.log("sRoute",this.feeCode);
    },
    //비용상세
    async getEtcFeeDetail() {
      let etcFeeDetail = await this.$api("/api/etcPriceDetail", {param:[this.etcFeeCode]});
      this.etcFeeDetail = etcFeeDetail;
      this.etcFeeDetail.cuCd = etcFeeDetail[0].cuCd;
      //console.log("etcFeeDetail",this.etcFeeDetail);
    },
    async getCurrencyList() {
      let currencyList = await this.$api("/api/currencyList", {});
      this.currencyList = currencyList;
      //console.log("currencyList",currencyList);
    },
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      //console.log("nationList",nationList);
    },
    changeTransList() {
      this.getTerminalList(this.productInfo.ntCd, this.productInfo.trCd);
      //console.log("trCd:",this.transCode);
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      //this.openParams = this.mobilityList_tmp.filter(e => {return e.transCode === this.productInfo.mbCd});
      //console.log("openP:", this.openParams);
    },
    changeTrList(){
      //alert("trCd:"+this.productInfo.trCd);
      this.mobilityList = this.mobilityList_tmp.filter(e => {return e.categoryCode === this.productInfo.trCd});
      console.log("mobilityList:", this.mobilityList);
    },
    async getTerminalList(param1, param2) {
      //console.log(param1, param2);
      //let param3 = (param2 === "SHP") ? "ptCd" : "apCd"
      let param3 = "";
      if(param2 === "SHP") {
        param3 = "ptCd"
      }
      else if (param2 === "FLT") {
        param3 = "apCd"
      }
      else {
        this.productInfo.trCd = "";
        this.productInfo.tmCd = "";
        return this.$swal.fire("","대상이 아닙니다.","info");
      }

      this.termList = await this.$api("/api/termList", {param: [param1, param3]});
      //console.log("mobilityList",this.mobilityList_tmp);
    },
    async getLineList() {
      let lineList = await this.$api("/api/lineList", {});
      this.lineList = lineList;
      //console.log("lineList",lineList);
    },
    goToList() {
      this.$router.push({path: '/etcPrice'});
    },
    feeUpdate() {
      this.productInfo.modId = this.user.handlerId;
      
      // if(!this.productInfo.feeClass) {
      //   return this.$swal.fire("","필수누락: 분류");
      // }
      if(!this.productInfo.etcFeeNameKor) {
        this.$refs.txt_etcFeeNameKor.focus();
        return this.$swal.fire("","필수누락: 명칭(국문)");
      }
      if(this.feeClass === "T") {
        if(!this.productInfo.ntCd || !this.productInfo.trCd || !this.productInfo.tmCd) {
          this.$refs.sel_tmCd.focus();
          return this.$swal.fire("","필수누락: 위치정보");
        }
      }
      if(!this.productInfo.fromDate) {
        this.$refs.txt_fromDate.focus();
        return this.$swal.fire("","필수누락: 적용개시일");
      }
      if(!this.productInfo.toDate) {
        this.$refs.txt_toDate.focus();
        return this.$swal.fire("","필수누락: 적용종료일");
      }
      if(!this.etcFeeDetail.cuCd) {
        this.$refs.sel_cuCd.focus();
        return this.$swal.fire("","필수누락: 통화");
      }
      // if(!this.etcFeeDetail.ageFee) {
      //   //this.$refs.sel_cuCd.focus();
      //   return this.$swal.fire("","필수누락: 비용");
      // }

      // if(this.feeClass === "T") {
      // }

      this.productInfo.fromDate = moment(this.productInfo.fromDate).format("YYYY-MM-DD");
      this.productInfo.toDate = moment(this.productInfo.toDate).format("YYYY-MM-DD");

      // 등록전 확인
      this.$swal.fire({
        title: '변경진행?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // await this.$api("/api/produtInsert", {param: [this.productInfo]});
          //SET tmCd=?, useFlag='0', fromDate=?, toDate=?, modId=?, modDate=now(), etcFeeNameKor=?, etcFeeNameEng=?, ntCd=?, liCd=?, pdtRemarks=?, trCd=?
          let resUpd_etcFee_m = await this.$api("/api/update_etcFeeM", {param: [this.productInfo.tmCd, this.productInfo.fromDate, this.productInfo.toDate,
            this.productInfo.modId, this.productInfo.etcFeeNameKor, this.productInfo.etcFeeNameEng, this.productInfo.ntCd, this.productInfo.liCd,
            this.productInfo.pdtRemarks, this.productInfo.trCd, this.productInfo.mbCd, this.productInfo.etcFeeCode]});

          //console.log("errCheck:", Object.keys(resUpd_etcFee_m)[0]);
          // 인서트오류
          //if(Object.keys(resUpd_etcFee_m)[0] === 'error') {
          if(resUpd_etcFee_m.affectedRows < 0 || resUpd_etcFee_m.affectedRows === undefined){
            this.$swal.fire('기본정보 업데이트 오류', '', 'alert')
          }
          else{
            //터미널이용료 수정
            if(this.productInfo.feeClass === "T")  {
              let i=0;
              while(i < 4) {
                console.log(this.etcFeeDetail[i].ageFee,"/",i);
                let resUpd_etcFee_prc = await this.$api("/api/update_etcFeePrc",
                // etcFeeCode, agegroup, ageFee, useFlag, cuCd, insId, insDate
                {param: [this.etcFeeDetail[i].ageFee, '0', this.etcFeeDetail.cuCd, this.productInfo.insId, this.productInfo.etcFeeCode, i+1]});
                  if(resUpd_etcFee_prc.affectedRows < 0 || resUpd_etcFee_prc.affectedRows === undefined){
                    alert("비용정보 업데이트 오류");
                  }
                  i = i+1;
              }
            }
            // 유류할증료수정
            else if(this.productInfo.feeClass === "O") {
              //await this.$api("/api/productTmpImgInsert",{param: this.productInfo.pdtCode});
              let resIns_product_prc = await this.$api("/api/update_etcFeePrc", 
                {param: [this.etcFeeDetail[0].ageFee, '0', this.etcFeeDetail.cuCd, this.productInfo.insId, this.productInfo.etcFeeCode, 1 ]});
                  if(Object.keys(resIns_product_prc)[0] === 'error') {
                    alert("비용정보 저장오류");
                  }
            }
          }
          // this.$swal.fire('등록완료!', '', 'success')
          // 등록 후 라우터를 통해 상품관리리스트로
          this.$router.push({path: '/etcPrice'});
        }
      })
    }
  }
}
</script>