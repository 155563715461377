
<template>
  
  <div id="msr_page_hj">
    <section>
      <!-- 메인 -->
      <div class="msr_main_hj">
        <div class="msr-fst-cont_hj">
          <div class="mtxt_hj">
            <p>엔데이트립 멤버십</p>
            <p class="msmalltxt">이제 일본&#183;대만 버스투어를 <br>연간무제한 <strong>공짜</strong>로 이용하세요.</p>
            <div class="go_hj">
              <button type="button" class="btn" @click="goToJoin()">자세히 보기</button>
            </div>
          </div><!-- mtxt_hj -->

        </div><!-- msr-fst-cont_hj -->

        <div class="msr-scd-cont_hj">
          <div class="c-tit_hj">
            <p class="stit_hj">같은 가격이라면,<br>연간 무제한으로!</p>
          </div> <!-- c-tit_hj -->
          <div class="c-img_hj">
          <img  class="img-fluids" src="../assets/img/newmembership/compare1.png">
          <p class="stit_hj pointtit">=</p>
          <img  class="img-fluid" src="../assets/img/newmembership/compare2.png">
          </div><!-- c-img_hj -->
        </div> <!-- msr-scd-cont_hj -->

      </div> <!-- msr_main_hj -->

      <!-- 혜택 -->
      <div class="msr_benefit_hj">
            <!-- 타이틀 -->
            <div class="msr_bntit_hj">
              <p>멤버십 혜택</p>
              <p>멤버십 회원만 누리는 혜택!</p>
            </div> <!-- msr_bntit_hj -->

            <!-- 내용 -->
              <div class="msr_bnimg_hj">
                <div class="benecard_hj">
                  <img src="../assets/img/newmembership/card1.png" alt="">
                  <img src="../assets/img/newmembership/card2.png" alt="">
                  <img src="../assets/img/newmembership/card3.png" alt="">
                  <img src="../assets/img/newmembership/card4.png" alt="">
                  <img src="../assets/img/newmembership/card5.png" alt="">
                </div><!-- benecard_hj -->
              </div><!-- msr_bnimg_hj -->



          

      </div> <!-- msr_benefit_hj -->

      <!-- 등급 -->
      <div class="msr_grade_hj">
          <!-- 타이틀 -->
          <div class="gratit_hj">
            <p>등급 안내</p>
            <p>등급별 혜택</p>
          </div> <!-- gratit_hj -->
          <!-- 탭메뉴 -->          
          <div class="gratab_hj">
            <div class="gratabmn_hj">
              <div class="gradetab-menu">
                <button class="tab-button active" data-tab="tab1">스탠다드 등급</button>
                <button class="tab-button" data-tab="tab2">프리미엄 등급</button>
              </div><!-- gradetab-menu -->
            </div> <!-- gratabmn_hj -->
            <div class="gradtabcont">
              <div class="tab-panel active" id="tab1">
              <img class="pcgr1" src="../assets/img/newmembership/grade1.png" alt="">
              <img class="mogr1" src="../assets/img/newmembership/mo_grade1.png" alt="">
            </div>
            <div class="tab-panel" id="tab2">
              <img class="pcgr2" src="../assets/img/newmembership/grade2.png" alt="">
              <img class="mogr2" src="../assets/img/newmembership/mo_grade2.png" alt="">
            </div>
            <div class="tab-panel" id="tab3">

            </div>
            <div class="tab-panel" id="tab4">

            </div>
            </div><!-- gradtabcont -->

            
          </div><!-- gratab_hj -->
      </div> <!-- msr_grade_hj -->

      <!-- 코스 -->
      <div class="msr_product_hj">
        <!-- 타이틀 -->
        <div class="protit_hj">
          <p>다양한 코스</p>
          <p class="protitsec">일본&#183;대만 주요 도시 어디든! <br>무제한으로!</p>
          <p class="protitfou">코스 총 24개 <br>★ 신규코스 COMING SOON ★</p>
        </div><!-- protit_hj -->
        <!-- 지도 -->
        <div class="pcmap_hj">
          <img class="pcmapimg" src="../assets/img/newmembership/pcmap.png">
          <div class="pcmover_hj">
            <div class="hkimg_hj">
              <img class="hokkaido_hj" src="../assets/img/newmembership/m_1.png" alt="">
              <div class="hokfile">
                <img class="hok1" src="../assets/img/newmembership/hok1.png" alt="">
                <img class="hok2" src="../assets/img/newmembership/hok2.png" alt="">
                <img class="hok3" src="../assets/img/newmembership/hok3.png" alt="">
                <img class="hok4" src="../assets/img/newmembership/hok4.png" alt="">
                <img class="hok5" src="../assets/img/newmembership/hok5.png" alt="">
                <img class="hok6" src="../assets/img/newmembership/hok6.png" alt="">
                <img class="hok7" src="../assets/img/newmembership/hok7.png" alt="">
                <img class="hok8" src="../assets/img/newmembership/hok8.png" alt="">
              </div><!-- hokfile -->
            </div> <!-- hkimg_hj -->
            <div class="tokimg_hj">
              <img class="tokyo_hj" src="../assets/img/newmembership/m_2.png" alt="">
              <div class="tokfile">
                <img class="tok1" src="../assets/img/newmembership/tok1.png" alt="">
              </div><!-- tokfile -->
            </div><!-- tokimg_hj -->
            <div class="nagimg_hj">
              <img class="nagoya_hj" src="../assets/img/newmembership/m_3.png" alt="">
              <div class="nagfile">
                <img class="nag1" src="../assets/img/newmembership/nag1.png" alt="">
              </div>
            </div><!-- nagimg_hj -->
            <div class="oskimg_hj">
              <img class="osaka_hj" src="../assets/img/newmembership/m_4.png" alt="">
              <div class="oskfile">
                <img class="osk1" src="../assets/img/newmembership/osk1.png" alt="">
                <img class="osk2" src="../assets/img/newmembership/osk2.png" alt="">
              </div><!-- oskfile -->
            </div><!-- oskimg_hj -->
            <div class="gitaimg_hj">
              <img class="gitakyusu_hj" src="../assets/img/newmembership/m_5.png" alt="">
              <div class="gitafile">
                <img class="gita1" src="../assets/img/newmembership/gita1.png" alt="">
              </div>
            </div><!-- gitaimg_hj -->
            <div class="hukuimg_hj">
              <img class="hukuoka_hj" src="../assets/img/newmembership/m_6.png" alt="">
              <div class="hukfile">
                <img class="huk1" src="../assets/img/newmembership/huk1.png" alt="">
                <img class="huk2" src="../assets/img/newmembership/huk2.png" alt="">
              </div>
            </div><!-- hukuimg_hj -->
            <div class="okinimg_hj">
              <img class="okinawa_hj" src="../assets/img/newmembership/m_7.png" alt="">
              <div class="okifile">
                <img class="oki1" src="../assets/img/newmembership/oki1.png" alt="">
              </div>
            </div><!-- okinimg_hj -->
            <div class="tabeimg_hj">
              <img class="taibei_hj" src="../assets/img/newmembership/m_8.png" alt="">
              <div class="taibfile">
                <img class="taib1" src="../assets/img/newmembership/taib1.png" alt="">
                <img class="taib2" src="../assets/img/newmembership/taib2.png" alt="">
                <img class="taib3" src="../assets/img/newmembership/taib3.png" alt="">
                <img class="taib4" src="../assets/img/newmembership/taib4.png" alt="">
                <img class="taib5" src="../assets/img/newmembership/taib5.png" alt="">
                <img class="taib6" src="../assets/img/newmembership/taib6.png" alt="">
              </div>
            </div><!-- tabeimg_hj -->
            <div class="tabezhimg_hj"> 
              <img class="taizhong_hj" src="../assets/img/newmembership/m_9.png" alt="">
              <div class="taizfile">
                <img class="taiz1" src="../assets/img/newmembership/taiz1.png" alt="">
                <img class="taiz2" src="../assets/img/newmembership/taiz2.png" alt="">
              </div>
              <img class="gaoshong_hj" src="../assets/img/newmembership/m_10.png" alt="">
              <div class="gaosfile">
                <img class="gaos1" src="../assets/img/newmembership/gaos1.png" alt="">
              </div>
            </div><!-- tabezhimg_hj -->
          </div><!-- pcmover_hj -->
        </div> <!-- pcmap_hj -->
        <div class="momap_hj">
          <img class="momap" src="../assets/img/newmembership/momap.png" alt="">
        </div>

      </div><!-- msr_product_hj -->

      <!-- 일본 상품 -->
      <div class="proimg_jp_hj">

        <div class="jpprotit">
          <p>일본</p>
        </div><!-- jpprotit -->

        <div class="jpproimg">
          <div class="jpprdfst">
            <a href="bustour?nt=JP&ar=HOK">
            <img class="jppd1" src="../assets/img/newmembership/product1.png" alt="">
            </a>
            <a href="bustour?nt=JP&ar=OSA">
              <img class="jppd2" src="../assets/img/newmembership/product2.png" alt="">
            </a>
            <a class="jmi1" href="bustour?nt=JP&ar=HOK">
              <img class="jmi1" src="../assets/img/newmembership/jppdimg_mo1.png" alt="">
            </a>
            <a class="jmi2" href="bustour?nt=JP&ar=OSA">
              <img class="jmi2" src="../assets/img/newmembership/jppdimg_mo2.png" alt="">
            </a>
          </div><!-- jpprdfst -->

          <div class="jpprdsec">
            <a href="bustour?nt=JP&ar=FUK">
              <img class="jppd3" src="../assets/img/newmembership/product3.png" alt="">
            </a>
            <a href="bustour?nt=JP&ar=NAG">
              <img class="jppd4" src="../assets/img/newmembership/product4.png" alt="">
            </a>
            <a class="jppd5" href="bustour?nt=JP&ar=TYO">
              <img class="jppd5" src="../assets/img/newmembership/product5.png" alt="">
            </a>
            <a class="jmi3" href="bustour?nt=JP&ar=FUK">
              <img class="jmi3" src="../assets/img/newmembership/jppdimg_mo3.png" alt="">
            </a>
            <a class="jmi4" href="bustour?nt=JP&ar=NAG">
              <img class="jmi4" src="../assets/img/newmembership/jppdimg_mo4.png" alt="">
            </a>
            <a class="jmi5" href="bustour?nt=JP&ar=TYO">
              <img class="jmi5" src="../assets/img/newmembership/jppdimg_mo5.png" alt="">
            </a>
          </div><!-- jpprdsec -->

          <div class="jpprdthr">
            <a href="bustour?nt=JP&ar=YAM">
              <img class="jppd6"  src="../assets/img/newmembership/product6.png" alt="">
            </a>
            <a href="bustour?nt=JP&ar=OKI">
              <img class="jppd7" src="../assets/img/newmembership/product7.png" alt="">
            </a>
            <a class="jmi6" href="bustour?nt=JP&ar=YAM">
              <img class="jmi6" src="../assets/img/newmembership/jppdimg_mo6.png" alt="">
            </a>
            <a class="jmi7" href="bustour?nt=JP&ar=OKI">
              <img class="jmi7" src="../assets/img/newmembership/jppdimg_mo7.png" alt="">
            </a>
          </div> <!-- jpprdthr -->
        </div><!-- jpproimg -->

      </div><!--proimg_jp_hj -->

      <!-- 대만 상품 -->
      <div class="proimg_tw_hj">
        <div class="twprotit">
  <p>대만</p>
        </div><!-- jpprotit -->
        <div class="twproimg">

  <div class="twprdfst">
    <a class="twpd1" href="bustour?nt=TW&ar=TPE">
      <img class="twpd1" src="../assets/img/newmembership/product8.png" alt="">
    </a>
    <a class="tmi1" href="bustour?nt=TW&ar=TPE">
    <img class="tmi1" src="../assets/img/newmembership/twpdimg_mo1.png" alt="">
    </a>
  </div><!-- twprdfst -->

  <div class="twprdsec">
    <a class="twpd2" href="bustour?nt=TW&ar=TPE">
      <img class="twpd2" src="../assets/img/newmembership/product9.png" alt="">
    </a>
    <a class="twpd3" href="bustour?nt=TW&ar=TPE">
      <img class="twpd3" src="../assets/img/newmembership/product10.png" alt="">
    </a>
    <a class="tmi2" href="bustour?nt=TW&ar=TPE">
      <img class="tmi2" src="../assets/img/newmembership/twpdimg_mo2.png" alt="">
    </a>
    <a class="tmi3" href="bustour?nt=TW&ar=TPE">
      <img class="tmi3" src="../assets/img/newmembership/twpdimg_mo3.png" alt="">
    </a>

  </div><!-- twprdsec -->

        </div><!-- twproimg -->
      </div><!--proimg_tw_hj -->

            <!-- 약관 -->
            <div class="msr_term_hj">
        <div class="termtxt_hj">
          <p class="termmain"><i class="bi bi-exclamation-circle"></i> &nbsp;엔데이트립 멤버십 약관</p>
          <div class="alltext">
          <p>- 본 약관은 엔타비글로벌(주)(이하 "당사")에서 제공하는 여행 상품 브랜드 "엔데이트립 멤버십 서비스"(이하 "멤버십")의 약관입니다. </p>
          <p>- 멤버십 가입 상품의 판매, 회원가입, 멤버십 서비스는 당사에서만 제공하며 당사가 진행한 이외의 경로를 통한 멤버십 상품의 구매, 회원가입, 멤버십 서비스의 제공은 멤버십 효력을 가질 수 없습니다.</p>
          <p>- 멤버십 상품의 판매는 당사에서만 판매하고 당사의 공식 홈페이지, 온라인 쇼핑몰, 여행 플랫폼 등에서 구매할 수 있습니다. 실제 가입과 서비스의 제공은 당사의 공식 홈페이지(<a href="https://ntabi.co.kr/" style="color:white;">https://ntabi.co.kr</a>)를 통해 제공됩니다.</p>
          <p>- 멤버십 등급은 엔데이트립에서 정한 기준에 따라 2개 등급(스탠다드, 프리미엄)으로 구분됩니다.</p><p>- 버스투어 이용 시 NO SHOW 및 취소 규정 적용을 위해 환급 형식(상품 금액 결제 후 탑승 종료 시 전액 환급)으로 혜택이 제공됩니다.</p>
          <p>- 멤버십 서비스 이용 후 환급을 위해 멤버십 회원의 환급 계좌를 사전에 확인, 보관합니다.(가입 종료 시까지)</p>
          <p>- 멤버십 서비스 이용 후 환급은 실제 버스투어 상품 이용(탑승일 기준) 후 3일 이내 알려주신 환급 계좌로 환급되며, 환급이 확인되지 않을 시 담당자에게 요청하실 수 있습니다.</p>
          <p>- 멤버십 가입 비용은 기존의 포인트로 결제하실 수 없습니다.</p>
          <p>- 멤버십 가입 시 엔타비글로벌(주), 엔데이트립 공식 홈페이지 회원가입이 포함되며 개인정보취급방침, 이용약관, 여행약관 등 공식 홈페이지에서 제공하는 약관에 동의하는 것으로 간주됩니다.</p>
          <p>- 멤버십 가입 시 가입자의 여권정보(여권사본)을 필수로 수집합니다.</p>
          <p>- 미성년자의 멤버십 가입의 경우 법정대리인(부모 등)의 동의(인감 날인 동의서, 인감증명서 등의 구비서류 제시)를 얻어야 합니다.</p>
          <p>- 멤버십 가입 전 코스 예약(구매) 고객의 경우 멤버십 가입 시 기존 코스 예약(구매) 금액은 환불 혹은 차액만 결제하시면 멤버십 가입이 가능합니다.</p>
          <p>- 멤버십 가입 완료 후 즉시 멤버십 혜택을 이용 하실 수 있습니다.</p>
          <p>- 멤버십 가입 완료 후 철회 및 취소, 환불은 일체 불가능합니다.(해외여행결격사유 발생에도 적용)</p>
          <p>- 엔타비글로벌(주), 엔데이트립 공식 홈페이지 회원 탈퇴 시 멤버십도 자동 해지되며 환불되지 않습니다.</p>
          <p>- 멤버십은 양도가 불가능하며 다음의 경우 1회 양도가 가능합니다.(가입자 당사자의 사망, 사망진단서 서류 제출 必)</p>
          <p>- 코스의 예약은 엔타비글로벌(주), 엔데이트립 공식 홈페이지 및 상담사를 통해 유선으로 예약이 가능합니다.</p>
          <p>- 버스투어의 예약은 최대 3건까지 동시 예약이 가능하며 3건이 넘어가는 예약은 제한됩니다.</p>
          <p>- 코스는 사전 예약이 필수입니다. 사전 예약 없이 현장에서 임의 탑승은 불가능합니다.</p>
          <p>- 멤버십 회원의 경우 투어 탑승 시 회원 본인 확인을 위해 여권 제시가 필수이며, 본인 확인이 불가능 한 경우 탑승을 거부할 수 있습니다.</p>
          <p>- 코스 이용 시 규정은 상품에 명시된 내용을 토대로 동일하게 적용됩니다.</p>
          <p>- 코스 예약 시 해당 일자의 잔여 좌석 유무에 따라 확정됩니다.</p>
          <p>- 코스 예약 후 취소는 1일 전까지 가능합니다.</p>
          <p>- 투어 당일 NO SHOW, 당일 취소의 경우 결제하신 상품 금액은 환불되지 않습니다.</p>
          <p>- 3회 이상 NO SHOW가 확인될 경우 멤버십 자격 박탈, 결제하신 상품금액과 멤버십 가입비는 환불되지 않으며 재가입이 불가능합니다.</p>
          <p>- 멤버십 특전은 엔타비글로벌(주), 엔데이트립 공식 홈페이지에서 제공하는 여행 상품 이용 시 적용됩니다.</p>
          <p>- 코스의 운행 종료는 사전에 고지 없이 종료 될 수 있습니다.</p>
          <p>- 등급별 이용 가능한 코스는 변동될 수 있으며 변동 시 적용 기간으로부터 30일 전 멤버십 가입자에게 사전고지 합니다.</p>
          <p>- 해외(일본, 대만 등) 현지 비용(차량, 가이드, 관광시설 및 현지 국가의 관할국에서 정한 비용) 인상으로 인한 단품의 요금 인상이 불가피할 경우 멤버십 가입비도 인상될 수 있으며 아래의 개정 시 통지 방법으로 고지됩니다.</p>
          <p>- 본 약관은 수시로 개정 가능하며, 개정하고자 할 경우 개정하고자 하는 날로부터 15일 이전에 약관이 개정된다는 사실과 개정된 내용 등을 다음의 방법 중 1가지 이상의 방법으로 회원에게 통지합니다. <br>(단, 회원에게 불리하게 약관이 변경되는 경우 최소 30일 이상의 유예기간을 두고 사전고지 합니다.) 
            <br> 
          &nbsp;&nbsp; a. e-mail 통보, b. SMS 통보, c. 공식 홈페이지 고시, d. 회원이 제공한 연락처 정보 등을 이용한 전화 안내 </p>
          <p>- 당사의 사정으로 인해 멤버십 서비스는 중단될 수 있으며 중단하고자 하는 날로부터 최소 30일 이전에 멤버십 서비스 중단 사실을 다음의 방법 중 1가지 이상의 방법으로 회원에게 통지합니다. 
            <br>
          &nbsp;&nbsp; a. e-mail 통보, b. SMS 통보, c. 공식 홈페이지 고시, d. 회원이 제공한 연락처 정보 등을 이용한 전화 안내 </p>
          <p>- 당사의 사정으로 인해 멤버십 서비스가 중단될 경우 멤버십 가입 회원의 서비스 이용 횟수(버스투어 상품 탑승 횟수)와 상관없이 아래의 내용을 토대로 환불 됩니다. 
            <br> 
            (멤버십 가입 금액 ÷ 12개월) x 잔여 개월 수 </p>
          </div><!-- alltext -->
        </div><!-- termtxt_hj -->
      </div>

      <!-- 가입하기 -->
      <div class="msr_join_hj">
        <div class="starttxt_mbs_hj">
          <img class="sttimg" src="../assets/img/newmembership/mbslogo.png" alt="">
          <div class="stt-text">
            <p class="sttmain">이제 일본&#183;대만에서 뭐 할지 고민하지 마세요</p>
            <p class="sttsub">엔데이트립 멤버십</p>
          </div>
          <div class="goend_hj">
            <button type="button" class="btn" @click="goToJoin()">자세히 보기</button>
          </div>
        </div><!-- starttxt_mbs_hj -->
      </div><!-- msr_join_hj -->
      


    </section>
  </div><!-- msr_page_hj -->

</template>
<script>

/* ---스크롤 애니메이션--- */
window.addEventListener('scroll', function() {
    const elementsLeft = document.querySelectorAll('.img-fluids'); // 왼쪽에서 나타날 요소들
    const elementsRight = document.querySelectorAll('.img-fluid'); // 오른쪽에서 나타날 요소들
    const windowHeight = window.innerHeight; // 브라우저 창 높이

    elementsLeft.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top; // 요소의 현재 위치
        if (elementPosition < windowHeight - 300) { // 요소가 뷰포트 안에 있을 때
            element.classList.add('show'); // 'show' 클래스 추가
        }
    });

    elementsRight.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top; // 요소의 현재 위치
        if (elementPosition < windowHeight - 300) { // 요소가 뷰포트 안에 있을 때
            element.classList.add('show'); // 'show' 클래스 추가
        }
    });
});

/* ---스크롤 애니메이션2--- */
window.addEventListener('scroll', function() {
    const elements = document.querySelectorAll('.benecard_hj img'); // 아래에서 나타날 요소들
    const windowHeight = window.innerHeight; // 브라우저 창 높이

    elements.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top; // 요소의 현재 위치
        if (elementPosition < windowHeight - 100) { // 요소가 뷰포트 안에 있을 때
            element.classList.add('show'); // 'show' 클래스 추가
        }
    });
});

window.addEventListener('scroll', function() {
    const elementfst = document.querySelectorAll('.jpprdfst'); // 아래에서 나타날 요소들
    const elementsec = document.querySelectorAll('.jpprdsec'); // 아래에서 나타날 요소들
    const elementthr = document.querySelectorAll('.jpprdthr'); // 아래에서 나타날 요소들
    const elementfor = document.querySelectorAll('.twprdfst'); // 아래에서 나타날 요소들
    const elementfiv = document.querySelectorAll('.twprdsec'); // 아래에서 나타날 요소들
    const windowHeight = window.innerHeight; // 브라우저 창 높이

    elementfst.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top; // 요소의 현재 위치
        if (elementPosition < windowHeight - 100) { // 요소가 뷰포트 안에 있을 때
            element.classList.add('show'); // 'show' 클래스 추가
        }
    });

    elementsec.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top; // 요소의 현재 위치
        if (elementPosition < windowHeight - 100) { // 요소가 뷰포트 안에 있을 때
            element.classList.add('show'); // 'show' 클래스 추가
        }
    });

    elementthr.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top; // 요소의 현재 위치
        if (elementPosition < windowHeight - 100) { // 요소가 뷰포트 안에 있을 때
            element.classList.add('show'); // 'show' 클래스 추가
        }
    });

    elementfor.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top; // 요소의 현재 위치
        if (elementPosition < windowHeight - 100) { // 요소가 뷰포트 안에 있을 때
            element.classList.add('show'); // 'show' 클래스 추가
        }
    });

    elementfiv.forEach(element => {
        const elementPosition = element.getBoundingClientRect().top; // 요소의 현재 위치
        if (elementPosition < windowHeight - 100) { // 요소가 뷰포트 안에 있을 때
            element.classList.add('show'); // 'show' 클래스 추가
        }
    });

    
});

/* ---탭메뉴--- */
document.addEventListener('DOMContentLoaded', function() {
    const tabButtons = document.querySelectorAll('.tab-button');
    const tabPanels = document.querySelectorAll('.tab-panel');

    tabButtons.forEach(button => {
        button.addEventListener('click', () => {
            const targetTab = button.getAttribute('data-tab');

            // 모든 탭 버튼에서 'active' 클래스 제거
            tabButtons.forEach(btn => btn.classList.remove('active'));

            // 클릭한 탭 버튼에 'active' 클래스 추가
            button.classList.add('active');

            // 모든 탭 콘텐츠에서 'active' 클래스 제거
            tabPanels.forEach(panel => panel.classList.remove('active'));

            // 선택된 탭 콘텐츠에 'active' 클래스 추가
            document.getElementById(targetTab).classList.add('active');
        });
    });
});
/* ---스크롤 확대--- */
window.addEventListener('scroll', function() {
    const scrollPosition = window.scrollY; // 스크롤 위치를 가져옴
    const scaleValue = 1 + scrollPosition / 10000; // 확대 비율 계산

    const backgroundContainer = document.querySelector('.msr-fst-cont_hj');
    backgroundContainer.style.transform = `scale(${scaleValue})`; // 확대 적용
});

export default {

  methods: {
    startAutoScroll_mo() {
      let lastTime = 0;
      const scrollStep = 1; // 스크롤할 픽셀 수
      const container_mo = document.querySelector('.row.scrolling-content.mo.gx-2');
      let totalWidth = container_mo.scrollWidth * 50; // 전체 스크롤 가능한 너비

      let animateScroll = (time) => {
        if (!lastTime) lastTime = time;
        let delta = time - lastTime;

        if (delta >= 3) { // 3밀리초마다 스크롤
          container_mo.scrollLeft += scrollStep;

          // 스크롤이 끝에 도달하면 처음으로 되돌림
          if (container_mo.scrollLeft >= (totalWidth)) {
            container_mo.scrollLeft = 0;
          }
          lastTime = time;
        }

        requestAnimationFrame(animateScroll);
      };

      requestAnimationFrame(animateScroll);
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value){
      return this.$currencyFormat(value);
    },

    async getProductList()
    {
      //멤버쉽대상 버스투어
      let productListForSales_ndaytrip = await this.$api("/api/membershipForNDT", {param: ['%', '%']});
      if(productListForSales_ndaytrip.length > 0)
      {
        this.productListForSales_ndaytrip = productListForSales_ndaytrip
        //순환스크롤을 위해 배열복제
        this.productListForSales_ndaytrip_mo = [...this.productListForSales_ndaytrip, ...this.productListForSales_ndaytrip];
      }
      //console.log("productListForSales_ndaytrip_mo:",this.productListForSales_ndaytrip_mo.length)
    },

    stopAutoScroll() {
      clearInterval(this.autoScrollTimer);
    },

    stopAutoScroll_mo() {
      clearInterval(this.autoScrollTimer);
    },
    goToJoin() {
      this.$router.push({path: '/myPageInfoChange_joinM'});
    },
  }
}
</script>

<style>

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
@font-face {
    font-family: 'HakgyoansimDunggeunmisoTTF-B';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-5@1.0/HakgyoansimDunggeunmisoTTF-B.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

#msr_page_hj{
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: -56px;
  overflow: hidden;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

    /* 메인 */

    .msr_main_hj{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: #132442;
    }

    .msr-fst-cont_hj {
      position: relative;
      width: 100%;
      height: 100vh;
      background-image: url(../assets/img/newmembership/m_bg.png);
      background-size: 100%;
      background-repeat: no-repeat;

    }


    .msr-fst-cont_hj .mtxt_hj{
      position: relative;
      display: flex;
      width: 80%;
      height: 100vh;
      flex-direction: column;
      justify-content: center;
      margin-left: 10%;
    }


    .msr-fst-cont_hj .mtxt_hj p:first-child{
      color: #fff;
      font-weight: bolder;
      font-size: 4rem;
    }

    .msr-fst-cont_hj .mtxt_hj p.msmalltxt{
      color: #fff;
      font-weight: 300;
      font-size: 40px;
      line-height: 1.2;
    }

    .msr-fst-cont_hj .mtxt_hj p.msmalltxt strong{
      color: #ebd42b;
      font-weight: bolder;
      font-size: 3rem;
      font-family: 'HakgyoansimDunggeunmisoTTF-B';
    }


    .msr-fst-cont_hj .msr_fsttxt_hj{
      position: absolute;
      width: 40%;
      top: 14rem;
      left: 10rem;
    }

    .go_hj .btn {
      background-color: #fff;
      color:#132442;
      font-size:2.2rem;
      font-weight: 900;
      width:20rem;
      height:4rem;
      margin-top:1.5rem;
      transition: all .5s;
      border-radius: 50px;
      
    }


    .go_hj .btn:hover{
      background-color: #132442;
      color:#fff;
      border-color: #fff;
    }

/* ------------------------------------------------------- */
    /* 구매유도 */

    .msr-scd-cont_hj{
      width: 100%;
      height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

    }

    .c-tit_hj{
      height: 20%;
    }
    .stit_hj {
      text-align: center;
      font-size: 4rem;
      color: #fff;
      font-weight: bolder;
      line-height: 1.2;
    }

    .c-img_hj{
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      gap: 50px;
    }

    .c-img_hj .img-fluids{
      width: 22%;
      opacity: 0;
      transform: translateX(-550px);
      transition: all 0.3s ease;
      padding-top: 30px;
    }

    .c-img_hj .img-fluid{
      width: 25%;
      opacity: 0;
      transform: translateX(500px); /* 초기 위치를 화면 왼쪽 밖으로 설정 */
      transition: all 0.3s ease;
    }

    .pointtit{
      font-size: 8rem;
    }

    
    .c-img_hj .img-fluids.show{
      opacity: 1;
      transform: translateX(0); 
    }

    .c-img_hj .img-fluid.show {
      opacity: 1;
      transform: translateX(0); 
    }

    /* 혜택 */
    
    .msr_benefit_hj {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #edf6f7;
    justify-content: center;
    align-items: center;
    }

    .msr_bntit_hj{
      width: 90%;
      font-size: 4rem;
      color: #000;
      font-weight: bolder;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      margin-top: 5rem;
      align-items: center;
    }

    .msr_bntit_hj p {
      width: 80%;
      font-weight: 800;
    }
    .msr_bntit_hj p:last-child{
      font-size: 2rem;
      margin-bottom: 6rem;
      font-weight: 600;
    }

    .msr_bnimg_hj {
      width: 90%;
      display: flex;
      justify-content: center;
    }
    .msr_bnimg_hj .benecard_hj{
      display: flex;
      justify-content: space-evenly;
      width: 80%;
    }

    .benecard_hj img{
      width: 18%;
      opacity: 0;
      transform: translateY(200px);
      transition: all 0.5s ease;
    }

    .benecard_hj img.show{
      opacity: 1;
      transform: translateY(0); 
    }

    /* 등급 */

    .msr_grade_hj {
      width: 100%;
      background-color: #efefef;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .gratit_hj {
      font-size: 3.5rem;
      color: #000;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      margin-top: 5rem;
      align-items: center;
    }
    .gratit_hj p{
      font-weight: 800;
    }
    .gratit_hj p:last-child{
      font-size: 2rem;
      font-weight: 600;
    }

    .gratab_hj {
      margin-top: 3rem;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .gratabmn_hj {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .gradetab-menu{
      width: 50%;
      height: 80px;
      display: flex;
      flex-direction: row;
      background-color: #132442;
      border-radius: 60px;
      gap: 25px;
      padding: 13px;
      padding-inline: 20px;
    }

    .gradetab-menu button{ 
      all: unset;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 45px;
      color: #fff;
      font-weight: bold;
      font-size: 1.6rem;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .gradetab-menu button.active{ 
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 45px;
      background-color: #fff;
      color: #132442;
      font-weight: bold;
      font-size: 1.6rem;
      cursor: pointer;
    }

    .gradtabcont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
    .tab-panel{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }
    .tab-panel img {
      width: 100%;
    }

    .pcgr1.active {
      display: flex;
    }

    .pcgr2.active {
      display: flex;
    }

    .tab-panel {
    display: none;
    }

    .tab-panel.active {
    display: block;
    }

    .mogr1{
      display: none;
    }

    .mogr2{
      display: none;
    }


    /* 코스 */

    .msr_product_hj {
      width: 100%;
      background-color: #efefef;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .msr_product_hj .protit_hj {
      font-size: 3.5rem;
      color: #000;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      margin-top: 8rem;
      align-items: center;
    }

    .msr_product_hj .protit_hj p{
      font-weight: 800;
    }
    .msr_product_hj .protit_hj p.protitsec{
      font-size: 2rem;
      text-align: center;
      font-weight: 600;
      margin-bottom: 0;
    }

    .msr_product_hj .protit_hj p.protitthd{
      font-size: 2rem;
      text-align: center;
      font-weight: bold;
      margin-top: 30px;
      margin-bottom: 0;
    }

    .msr_product_hj .protit_hj p.protitfou{
      background-color: #132442;
      color: #fff;
      font-size: 2rem;
      text-align: center;
      font-weight: bolder;
      padding-inline: 30px;
      margin-top: 25px;
      margin-bottom: 30px;
      padding: 10px;
    }


    /* 지도 */    

    .msr_product_hj{
      position: relative;
      width: 100%;

    }
    .pcmap_hj{
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
    }

    .pcmap_hj .pcmapimg {
      width: 80%;
    }
    .momap_hj {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
}

    .pcmap_hj .pcmover_hj{
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .momap_hj .momap {
      width: 80%;
      display: none;
    }
/* ------------------------------------------------------ */
    .hokfile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .hokfile img {
      width: 120px;
    }

    .hokfile .hok1 {
      position: absolute;
      right: 13px;
      top: 130px;
    }

    .hokfile .hok2 {
      position: absolute;
      right: 326px;
      top: -15px;
    }

    .hokfile .hok3 {
      position: absolute;
      right: 407px;
      top: 102px;
    }

    .hokfile .hok4 {
      position: absolute;
      right: -45px;
      top: 265px;
    }

    .hokfile .hok5 {
      position: absolute;
      right: 384px;
      top: 243px;
    }

    .hokfile .hok6 {
      position: absolute;
      right: -14px;
      top: -22px;
    }

    .hokfile .hok7 {
      position: absolute;
      right: 127px;
      top: 210px;
    }

    .hokfile .hok8 {
      position: absolute;
      right: 82px;
      top: 349px;
    }

    .pcmover_hj .hokkaido_hj {
      position: absolute;
      right: 105px;
    top: 1px;
    width: 272px;
    }

  .pcmover_hj .hokkaido_hj:hover + .hokfile {
      opacity: 1;
      visibility: visible;
    }

/* ------------------------------------------------------ */

    .tokfile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .tokfile img {
      width: 120px;
    }

    .tokfile .tok1 {
      position: absolute;
      bottom: -43rem;
      right: 190px;
    }

    .pcmover_hj .tokyo_hj {
      position: absolute;
      bottom: 229px;
      right: 345px;
      width: 46px;
    }


    .pcmover_hj .tokyo_hj:hover + .tokfile {
      opacity: 1;
      visibility: visible;
    }

/* ------------------------------------------------------ */

    .nagfile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .nagfile img {
      width: 120px;
    }

    .nagfile .nag1 {
      position: absolute;
      bottom: -28rem;
      right: 450px;
    }

    .pcmover_hj .nagoya_hj {
      position: absolute;
      bottom: 197px;
      right: 422px;
      width: 77px;
    }

    .pcmover_hj .nagoya_hj:hover + .nagfile {
      opacity: 1;
      visibility: visible;
    }

/* ------------------------------------------------------ */

    .oskfile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .oskfile img {
      width: 120px;
    }

    .oskfile .osk1 {
      position: absolute;
      bottom: -33rem;
      left: 23rem;
    }

    .oskfile .osk2 {
      position: absolute;
      bottom: -27rem;
      left: 30rem;
    }

    .pcmover_hj .osaka_hj {
      position: absolute;
      bottom: 199px;
      right: 500px;
      width: 81px;
    }

    .pcmover_hj .osaka_hj:hover + .oskfile {
      opacity: 1;
      visibility: visible;
    }


/* ------------------------------------------------------ */

    .gitafile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .gitafile img {
      width: 120px;
    }

    .gitafile .gita1 {
      position: absolute;
      bottom: -36rem;
      left: 230px;
    }

    .pcmover_hj .gitakyusu_hj {
      position: absolute;
      bottom: 161px;
      left: 242px;
      width: 93px;
    }

    .pcmover_hj .gitakyusu_hj:hover + .gitafile {
      opacity: 1;
      visibility: visible;
    }

/* ------------------------------------------------------ */

    .hukfile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .hukfile img {
      width: 120px;
    }

    .hukfile .huk1 {
      position: absolute;
      bottom: -30rem;
      left: 380px;
    }

    .hukfile .huk2 {
      position: absolute;
      bottom: -35rem;
      left: 240px;
    }
    .pcmover_hj .hukuoka_hj {
      position: absolute;
      bottom: 118.5px;
      left: 170px;
      width: 110px;
    }

    .pcmover_hj .hukuoka_hj:hover + .hukfile {
      opacity: 1;
      visibility: visible;
    }

/* ------------------------------------------------------ */    
    
    .okifile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .okifile img {
      width: 130px;
    }

    .okifile .oki1 {
      position: absolute;
      bottom: -35rem;
      left: 55px;
    }

    .pcmover_hj .okinawa_hj {
      position: absolute;
      bottom: 180px;
      left: 125px;
      width: 96px;
    }

    .pcmover_hj .okinawa_hj:hover + .okifile {
      opacity: 1;
      visibility: visible;
    }

/* ------------------------------------------------------ */    

    .taibfile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .taibfile img {
      width: 120px;
    }

    .taibfile .taib1 {
      position: absolute;
      top: 5rem;
      left: 5rem;
    }

    .taibfile .taib2 {
      position: absolute;
      top: 0rem;
      left: 26rem;
    }

    .taibfile .taib3 {
      position: absolute;
      top: -2rem;
      left: 9rem;
    }
    
    .taibfile .taib4 {
      position: absolute;
      top: 8rem;
      left: 25rem;
    }

    .taibfile .taib5 {
      position: absolute;
      top: 15rem;
      left: 30rem;
    }

    .taibfile .taib6 {
      position: absolute;
      top: 18rem;
      left: 21rem;
    }
    .pcmover_hj .taibei_hj {
      position: absolute;
      top: 95px;
      left: 255px;
    }

    .pcmover_hj .taibei_hj:hover + .taibfile {
      opacity: 1;
      visibility: visible;
    }

/* ------------------------------------------------------ */    

    .taizfile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .taizfile img {
      width: 120px;
    }

    .taizfile .taiz1 {
      position: absolute;
      top: 3rem;
      left: 5rem;
    }

    .taizfile .taiz2 {
      position: absolute;
      top: 10rem;
      left: -2rem;
    }

    
    .pcmover_hj .taizhong_hj {
      position: absolute;
      top: 223px;
      left: 133px;
      width: 193px;
    }

    .pcmover_hj .taizhong_hj:hover + .taizfile {
      opacity: 1;
      visibility: visible;
    }

    
    .gaosfile {
      position: absolute;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease, visibility 0.6s ease;
    }

    .pcmover_hj .gaoshong_hj {
      position: absolute;
      top: 330px;
      left: 112px;
      width: 153px;
    }

    .pcmover_hj .gaoshong_hj:hover + .gaosfile {
      opacity: 1;
      visibility: visible;
    }

    
    .gaosfile img {
      width: 120px;
    }


    .gaosfile .gaos1 {
      position: absolute;
      top: 19rem;
      left: -2rem;
    }

    /* 상품 */
    .proimg_jp_hj{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #efefef;
    }

    .proimg_jp_hj .jpprotit {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      margin-right: 50rem;
    }

    .proimg_jp_hj .jpprotit p{
      font-size: 4rem;
      color: #000;
      font-weight: bolder;
      display: flex;
      flex-direction: column;
      margin-top: 8rem;
      align-items: center;
    }

    .proimg_jp_hj .jpproimg{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 30px;
    }

    .jpprdfst a {
        display: inline-block;
        text-align: center;
    }
    .jpprdfst .jmi1 {
      display: none;
    }
    .jpprdfst .jmi2 {
      display: none;
    }


    .proimg_jp_hj .jpproimg .jpprdfst{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(50px);
      transition: all 0.7s ease;
    }

    .jpproimg .jpprdfst.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .jpprdsec a {
        display: inline-block;
        text-align: center;
    }

    .jpprdsec .jmi3 {
      display: none;
    }
    .jpprdsec .jmi4 {
      display: none;
    }
    .jpprdsec .jmi5 {
      display: none;
    }

    .proimg_jp_hj .jpproimg .jpprdsec{
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(80px);
      transition: all 0.7s ease;
    }

    .jpproimg .jpprdsec.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .jpprdthr a {
        display: inline-block;
        text-align: center;
    }

    .jpprdthr .jmi6 {
      display: none;
    }
    .jpprdthr .jmi7 {
      display: none;
    }



    .proimg_jp_hj .jpproimg .jpprdthr{
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(100px);
      transition: all 0.7s ease;
    }

    .jpproimg .jpprdthr.show{
      opacity: 1;
      transform: translateY(0); 
    }
/* ------------------------------------------------- */
    .proimg_tw_hj{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #efefef;
    }

    .proimg_tw_hj .twprotit{
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      margin-right: 50rem;
    }
    .proimg_tw_hj .twprotit p {
      font-size: 4rem;
      color: #000;
      font-weight: bolder;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      margin-top: 8rem;
      align-items: center;
    }

    .proimg_tw_hj .twproimg{
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 30px;
      margin-bottom: 150px;
    }
    
    .twprdfst a {
        display: inline-block;
        text-align: center;
    }


    .twprdfst .tmi1 {
      display: none;
    }

    .proimg_tw_hj .twproimg .twprdfst{
      display: flex;
      justify-content: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(50px);
      transition: all 0.7s ease;
    }

    .twproimg .twprdfst.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .twprdsec a {
        display: inline-block;
        text-align: center;
    }

    .twprdsec .tmi2 {
      display: none;
    }
    .twprdsec .tmi3 {
      display: none;
    }
    

    .proimg_tw_hj .twproimg .twprdsec{
      display: flex;
      justify-content: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(80px);
      transition: all 0.7s ease;
    }

    .twproimg .twprdsec.show{
      opacity: 1;
      transform: translateY(0); 

    }


/* 가입하기 */

.msr_join_hj {
      width: 100%;
      height: 100%;
      background-color: #132442;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
}

.msr_join_hj .starttxt_mbs_hj{
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5rem;
  
}

.msr_join_hj .starttxt_mbs_hj .stt-text{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.msr_join_hj .starttxt_mbs_hj .sttimg{
  position: absolute;
  width: 80%;

}



.msr_join_hj .starttxt_mbs_hj .stt-text p.sttmain {
      font-size: 3.5rem;
      color: #fff;
      font-weight: 600;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      margin-top: 8rem;
      align-items: center;
    }

    .msr_join_hj .starttxt_mbs_hj .stt-text p.sttimg {
    }

    .msr_join_hj .starttxt_mbs_hj .stt-text p.sttsub{
      font-size: 5rem;
      color: #fff;
      font-weight: bolder;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .goend_hj{
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5rem;

    }

    .goend_hj .btn {
      background-color: #fff;
      color:#132442;
      font-size:2.2rem;
      font-weight: 900;
      width:20rem;
      height:4rem;
      margin-top:1.5rem;
      transition: all .5s;
      border-radius: 50px;
      
    }
/* --------------------------------------------------- */
    /* 약관 */

.msr_term_hj {
  width: 100%;
      height: 100%;
      background-color: #132442;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      padding-top: 5rem;
}


.msr_term_hj .termtxt_hj{
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  margin-inline: 50px;
}

.msr_term_hj .termtxt_hj p.termmain{
  font-size: 2rem;
}

.alltext {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.termmain{
  display: flex;
}
/* ⭐⭐⭐media⭐⭐⭐ ------------------------------ */

@media (max-width: 1720px) and (min-width: 1401px){

  .msr_join_hj .starttxt_mbs_hj .sttimg{
  position: absolute;
  width: 80%;
}

}

@media (max-width: 1400px) and (min-width:1200px){

  .tab-panel img {
    width: 100%;
}

}

@media(max-width: 1199px) and (min-width: 767px){

  .msr-fst-cont_hj {
    position: relative;
    width: 100%;
    background-image: url(/img/m_bg.a97eb705.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.msr-fst-cont_hj .mtxt_hj p:first-child {
    color: #fff;
    font-weight: bolder;
    font-size: 3.5rem;
}

.msr-fst-cont_hj .mtxt_hj p.msmalltxt {
  color: #fff;
    font-weight: 300;
    font-size: 35px;
}

.msr-scd-cont_hj {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.go_hj .btn {
    background-color: #fff;
    color: #132442;
    font-size: 2.2rem;
    font-weight: 900;
    width: 19rem;
    height: 4rem;
    margin-top: 1.5rem;
    transition: all .5s;
    border-radius: 50px;
}

.stit_hj {
    text-align: center;
    font-size: 3rem;
    color: #fff;
    font-weight: bolder;
    line-height: 1.2;
}

.pointtit {
  font-size: 5rem;
}

.c-img_hj .img-fluid {
    width: 35%;
}
.c-img_hj .img-fluids {
    width: 32%;
}

.msr_bntit_hj {
    width: 90%;
    font-size: 3rem;
    color: #000;
    font-weight: bolder;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
}

.msr_bntit_hj p:last-child {
    font-size: 2rem;
    margin-bottom: 4rem;
}

.msr_bnimg_hj {
    width: 100%;
    display: flex;
    justify-content: center;
}

.msr_bnimg_hj .benecard_hj {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 80%;
    gap: 30px ;
  }

.benecard_hj img {
    width: 30%;
}

.gradetab-menu{
      width: 80%;
      height: 80px;
      display: flex;
      flex-direction: row;
      background-color: #132442;
      border-radius: 60px;
      gap: 25px;
      padding: 13px;
      padding-inline: 20px;
    }

.momap_hj {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
}

.momap_hj .momap {
      width: 91%;
      display: block;
    }

    .pcmap_hj .pcmapimg {
      width: 80%;
      display: none;
    }

    .pcmap_hj .pcmover_hj{
      display: none;
    }

    .gratit_hj {
    font-size: 3rem;
    color: #000;
    font-weight: bolder;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    align-items: center;
}

.gratab_hj {
    margin-top: 2rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#tab1.active {
  display: flex;
}
#tab2.active {
        display: flex;
    }
.tab-panel img {
    width: 90%;
}
.msr_product_hj .protit_hj {
    font-size: 3rem;
    color: #000;
    font-weight: bolder;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    align-items: center;
}

.msr_join_hj .starttxt_mbs_hj .sttimg{
  position: absolute;
  width: 100%;
}

















}

@media(max-width: 1023px) and (min-width: 600px){


  #msr_page_hj{
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 0px;
  overflow: hidden;
}

  .proimg_jp_hj .jpprotit {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-right: 75%;
}
.proimg_jp_hj .jpprotit p{
      font-size: 3rem;
      color: #000;
      font-weight: bolder;
      display: flex;
      flex-direction: column;
      margin-top: 12rem;
      align-items: center;
    }
.proimg_jp_hj .jpproimg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding-inline: 20px;
}
  
  .proimg_jp_hj .jpproimg .jpprdfst{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(50px);
      transition: all 0.7s ease;
    }

    .jpprdfst img{
      width: 100%;
    }

    .jpprdfst a {
      display: inline-block
    }



    .jpproimg .jpprdfst.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .proimg_jp_hj .jpproimg .jpprdsec{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 17px;
      opacity: 0;
      transform: translateY(80px);
      transition: all 0.7s ease;
    }

    .jpprdsec img{
      width: 100%;
    }


    .jpproimg .jpprdsec.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .proimg_tw_hj{
      width: 100%;
      height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #efefef;

    }

    .proimg_jp_hj .jpproimg .jpprdthr{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(100px);
      transition: all 0.7s ease;
    }

    .jpprdthr img{
      width: 100%;
    }


    .jpproimg .jpprdthr.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .proimg_tw_hj{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #efefef;
    }

    .proimg_tw_hj .twprotit{
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      margin-right: 75%;
    }

    .proimg_tw_hj .twprotit p {
      font-size: 3rem;
      color: #000;
      font-weight: bolder;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      margin-top: 8rem;
      align-items: center;
    }

    .proimg_tw_hj .twproimg{
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      padding-inline: 20px;
    }

    .proimg_tw_hj .twproimg .twprdfst{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(50px);
      transition: all 0.7s ease;
    }

    
    .twprdfst img{
      width: 100%;
    }

    .twprdfst a {
      display: inline-block
    }


    .twproimg .twprdfst.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .proimg_tw_hj .twproimg .twprdsec{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(80px);
      transition: all 0.7s ease;
    }



    .twprdsec img{
      width: 100%;
    }

    .twprdsec a {
      display: inline-block
    }


    .twproimg .twprdsec.show{
      opacity: 1;
      transform: translateY(0); 

    }







}


@media(max-width: 766px) and (min-width: 360px){

  .msr-fst-cont_hj {
    position: relative;
    width: 100%;
    background-image: url(/img/m_bg.a97eb705.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.msr-fst-cont_hj .mtxt_hj p:first-child {
    color: #fff;
    font-weight: bolder;
    font-size: 2.4rem;
}

.msr-fst-cont_hj .mtxt_hj p.msmalltxt {
    color: #fff;
    font-weight: 300;
    font-size: 25px;
}

.msr-fst-cont_hj .mtxt_hj p.msmalltxt strong{
      color: #ebd42b;
      font-weight: bolder;
      font-size: 2rem;
      font-family: 'HakgyoansimDunggeunmisoTTF-B';
    }
.go_hj .btn {
    background-color: #fff;
    color: #132442;
    font-size: 1.2rem;
    font-weight: 900;
    width: 13rem;
    height: 3rem;
    margin-top: 1.5rem;
    transition: all .5s;
    border-radius: 50px;
}

.msr-scd-cont_hj {
        width: 100%;
        height: 50vh;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px;
    }

.c-tit_hj {
    height: 25%;
}
.stit_hj {
    text-align: center;
    font-size: 2rem;
    color: #fff;
    font-weight: bolder;
    line-height: 1.2;
}
.c-img_hj {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.c-img_hj .img-fluids {
    width: 40%;
    opacity: 0;
    transform: translateX(-550px);
    transition: all 0.7s ease;
    padding-top: 30px;
}
.c-img_hj .img-fluid {
    width: 43%;
    opacity: 0;
    transform: translateX(500px);
    transition: all 0.7s ease;
}
.pointtit {
    font-size: 2rem;
    margin-bottom: 0;
}
.gradetab-menu{
      width: 86%;
      height: 65px;
      display: flex;
      flex-direction: row;
      background-color: #132442;
      border-radius: 60px;
      gap: 25px;
      padding: 13px;
      padding-inline: 20px;
    }

    .gradetab-menu button{ 
      all: unset;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 45px;
      color: #fff;
      font-weight: bold;
      font-size: 1.3rem;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .gradetab-menu button.active{ 
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 45px;
      background-color: #fff;
      color: #132442;
      font-weight: bold;
      font-size: 1.3rem;
      cursor: pointer;
    }

    .gradtabcont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    }
    .tab-panel{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }


    .pcgr1 {
      display: none;
    }

    .pcgr2 {
      display: none;
    }
    
    .mogr1{
      display: flex;
    }

    .mogr2{
      display: flex;
    }


    .mogr1.active{
      display: flex;
    }

    .mogr2.active{
      display: flex;
    }

    
    .tab-panel {
    display: none;
    }

    .tab-panel.active {
    display: block;
    }

    .msr_product_hj .protit_hj p:last-child {
    font-size: 1.5rem;
    text-align: center;
}

.msr_product_hj .protit_hj p.protitsec {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0;
}

    .momap_hj {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
}

.momap_hj .momap {
      width: 98%;
      display: block;
    }

    .pcmap_hj .pcmapimg {
      width: 80%;
      display: none;
    }

    .pcmap_hj .pcmover_hj{
      display: none;
    }

    .msr_benefit_hj {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #edf6f7;
    justify-content: flex-start;
    align-items: center;
}


.msr_bntit_hj {
    width: 90%;
    font-size: 3rem;
    color: #000;
    font-weight: bolder;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
}

  .msr_bntit_hj p {
    width: 94%;
    font-weight: bolder;
}
.msr_bntit_hj p:last-child {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.msr_bnimg_hj {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.msr_bnimg_hj .benecard_hj {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 80%;
    gap: 20px ;
  }

.benecard_hj img {
    width: 30%;
}

.msr_product_hj .protit_hj {
    font-size: 3rem;
    color: #000;
    font-weight: bolder;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    align-items: center;
}

.gratit_hj {
    font-size: 3rem;
    color: #000;
    font-weight: bolder;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    align-items: center;
}

.gratit_hj p:last-child {
    font-size: 1.5rem;
}
.msr_join_hj .starttxt_mbs_hj .sttimg {
        position: absolute;
        width: 100%;
    }

    .msr_join_hj .starttxt_mbs_hj .stt-text p.sttmain {
    font-size: 30px;
    color: #fff;
    font-weight: 500;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    align-items: center;
}
.msr_join_hj .starttxt_mbs_hj .stt-text p.sttsub {
    font-size: 2rem;
    color: #fff;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.msr_term_hj .termtxt_hj p.termmain {
    font-size: 1.5rem;
}

.goend_hj .btn {
  background-color: #fff;
    color: #132442;
    font-size: 1.2rem;
    font-weight: 900;
    width: 13rem;
    height: 3rem;
    margin-top: 1.5rem;
    transition: all .5s;
    border-radius: 50px;
}
}

@media(max-width: 599px) and (min-width: 360px){

  .msr_bntit_hj {
        width: 90%;
        font-size: 2.5rem;
        color: #000;
        font-weight: bolder;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        align-items: center;
    }
  .msr_bnimg_hj .benecard_hj {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        width: 80%;
        gap: 20px;
        margin-top: 30px;
    }

    .benecard_hj img {
        width: 44%;
    }

    .benecard_hj img:last-child {
      margin-right: 49%;
    }
    .gratit_hj {
        font-size: 2.5rem;
        color: #000;
        font-weight: bolder;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        margin-top: 5rem;
        align-items: center;
    }

    .gradetab-menu button.active {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 45px;
        background-color: #fff;
        color: #132442;
        font-weight: bold;
        font-size: 1rem;
        cursor: pointer;
    }
    .gradetab-menu button {
        all: unset;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 45px;
        color: #fff;
        font-weight: bold;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .msr_product_hj .protit_hj {
        font-size: 2.5rem;
        color: #000;
        font-weight: bolder;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        margin-top: 8rem;
        align-items: center;
    }

  .proimg_jp_hj .jpprotit {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-right: 75%;
    }

    .proimg_jp_hj .jpprotit p {
        font-size: 2.5rem;
        color: #000;
        font-weight: bolder;
        display: flex;
        flex-direction: column;
        margin-top: 10rem;
        align-items: center;
    }
    .proimg_jp_hj .jpproimg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0;
}
    .proimg_jp_hj .jpproimg .jpprdfst{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0;
      opacity: 0;
      transform: translateY(50px);
      transition: all 0.7s ease;
    }


    .jpprdfst img {
      width: 90%;
      margin-top: 20px;
    }

    .jpprdfst .jppd1{
      display: none;
    }
    .jpprdfst .jppd2{
      display: none;
    }
    .jpprdfst .jmi1 {
      display: inline-block;
      margin-top: 0;
    }
    .jpprdfst .jmi2 {
      display: inline-block;
    }

    .jpproimg .jpprdfst.show{
      opacity: 1;
      transform: translateY(0); 
    }
    
    .proimg_jp_hj .jpproimg .jpprdsec{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;
      opacity: 0;
      transform: translateY(80px);
      transition: all 0.5s ease;
    }

    .jpprdsec img {
      width: 90%;
      margin-top: 20px;
    }

    .jpprdsec .jppd3 {
      display: none;
    }
    .jpprdsec .jppd4 {
      display: none;
    }
    .jpprdsec .jppd5 {
      display: none;
    }
    .jpprdsec .jmi3 {
      display: inline-block;
    }
    .jpprdsec .jmi4 {
      display: inline-block;
    }
    .jpprdsec .jmi5 {
      display: inline-block;
    }
    .jpproimg .jpprdsec.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .proimg_jp_hj .jpproimg .jpprdthr{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;
      opacity: 0;
      transform: translateY(100px);
      transition: all 0.7s ease;
    }

    
    .jpprdthr img {
      width: 90%;
      margin-top: 20px;
    }

    .jpprdthr .jppd6 {
      display: none;
    }
    .jpprdthr .jppd7 {
      display: none;
    }

    .jpprdthr .jmi6 {
      display: inline-block;
    }
    .jpprdthr .jmi7 {
      display: inline-block;
    }

    
    .jpproimg .jpprdthr.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .proimg_tw_hj .twprotit{
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      margin-right: 75%;
    }
    .proimg_tw_hj .twprotit p {
      font-size: 2.5rem;
      color: #000;
      font-weight: bolder;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      margin-top: 8rem;
      align-items: center;
    }
    .proimg_tw_hj .twproimg {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

    .proimg_tw_hj .twproimg .twprdfst{
      display: flex;
      justify-content: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(50px);
      transition: all 0.7s ease;
    }

    .twprdfst img {
      width: 90%;
    }

    .twprdfst .twpd1 {
      display: none;
    }
    
    .twprdfst .tmi1 {
      display: inline-block;
    }

    .twproimg .twprdfst.show{
      opacity: 1;
      transform: translateY(0); 
    }

    .proimg_tw_hj .twproimg .twprdsec{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      opacity: 0;
      transform: translateY(80px);
      transition: all 0.7s ease;
    }

    .twprdsec img {
      width: 90%;
    }

    .twprdsec .twpd2 {
      display: none;
    }
    .twprdsec .twpd3 {
      display: none;
    }

    .twprdsec .tmi2 {
      display: inline-block;
    }

    .twprdsec .tmi3 {
      display: inline-block;
    }

    .twproimg .twprdsec.show{
      opacity: 1;
      transform: translateY(0); 

    }


}


</style>