<template>
  <div id="mypage">
    <section>
      <div class="container my-3">
        <div class="row justify-content-center">
          <h3>마이페이지</h3>

          <!-- 왼쪽 / 개인정보 -->
          <div class="row mleft align-self-start col-lg-3">
            <!-- 사진 -->
            <div class="photo col-5 col-lg-12">
              <div class="myimg">
                <div @click="changePhoto()" id="photoUp" class="photoUp" style="cursor:pointer">
                  <img v-if="(this.userInfo.photo == '' || this.userInfo.photo == null)" src="../assets/img/new-test/nonperson.png" class="img-fluid">
                  <img v-else :src="`/download/memberPhoto/${this.userInfo.photo}`" class="card-img-top" alt="..."/>
                </div>
                <button @click="deletePhoto()" id="photoDel" class="photoDel">
                  <i class="bi bi-x text-danger"></i>
                </button>
                <input type="file" ref="fileInput" style="display: none" @change="handleFileChange()" id="fileInput">
              </div>
              
            </div>
            
            <div class="col-7 col-lg-12">
              <!-- 개인정보 -->
              <div class="myinfo">
                <p>
                  <span class="name">{{ this.k_name }}</span> 님
                  <span style="color: goldenrod" v-if="this.userMemInfo.memLevel === 'P'">
                    <img src="../assets/img/new-test/ms/premium.png" class="mship img-fluid"> 
                    <span class="memNo">No. {{ this.userMemInfo.memNo }}</span>
                  </span>
                  <span style="color:royalblue" v-else-if="this.userMemInfo.memLevel === 'S'">
                    <img src="../assets/img/new-test/ms/standard.png" class="mship img-fluid"> 
                    <span class="memNo">No. {{ this.userMemInfo.memNo }}</span>
                  </span>
                </p>
                <p>{{ this.user.alimCallC }}</p>
                <p>{{ this.user.email }}</p>
                <button type="button" class="btn revise" @click="goToMyPageInfoChange()">
                  나의정보 수정
                </button>
                <button type="button" class="btn membership" @click="joinToMembership('J')" v-if="this.userInfo.mShipFlag == '' || this.userInfo.mShipFlag == null">
                  멤버십 가입
                </button>

                <button type="button" class="btn membership2" @click="joinToMembership('P')" v-else-if="this.userMemInfo.memLevel === 'P'">
                  나의 멤버십
                  <div style="font-size:small; color:black;" id="memExpDate">{{ this.getDateFormat(this.userMemInfo.expDate)}} 까지</div>
                </button>

                <button type="button" class="btn membership3" @click="joinToMembership('S')" v-else-if="this.userMemInfo.memLevel === 'S'">
                  나의 멤버십
                  <div style="font-size:small; color:white;" id="memExpDate">{{ this.getDateFormat(this.userMemInfo.expDate)}} 까지</div>
                </button>
                
              </div>

              <!-- 포인트 -->
              <div class="point">
                <img class="img-fluid" src="../assets/img/point.png" alt="">
                <a class="dropdown-item" href="/pointList_client">
                  <span class="text-primary">{{ this.getCurrencyFormat(this.availPoint) }} P</span>
                </a>
              </div>
            </div>
          </div>


          <!-- 오른쪽 / 예약관련 -->
          <div class="row mright col-lg-9">
            <!-- 여행상품 예약내역 -->
            <div class="reserlist">
              <div class="text-start mx-auto">
                <h4 class="text-primary">여행상품 예약내역</h4>
                <p class="text-end more">
                  <a href="javascript:void(0);" class="text-secondary" @click="getRequestConsultList('past')" v-if="this.dFlag != 'past'">지난예약보기</a>
                  <a href="javascript:void(0);" class="text-primary" @click="getRequestConsultList()" v-else>현재예약보기</a>
                </p>                  
              </div>

              <!-- pc -->
              <table class="table pc">
                <thead>
                  <tr class="text-center" style="background-color: lightblue;">
                    <th style="width:10%">예약날짜/엔버스번호</th>
                    <th style="width:7%">상태</th>
                    <th style="width:35%">
                      상&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;품&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명
                    </th>
                    <th style="width:5%">결제금액</th>
                    <th style="width:15%">일정</th>
                    <th style="width:8%">취소</th>
                  </tr>
                </thead>
                <tbody style="font-size:small">
                  <tr :key="i" v-for="(rList, i) in this.reqListAll">
                    <td>
                      {{ this.getDateFormat(rList.insDate) }}
                      <br>
                      <a @click="goToReqReserveDetail('R', rList.resCode, rList.reqPdtCode, rList.ntCd1);" style="cursor:pointer;color:blue;">
                        {{ rList.resCode }}
                      </a>
                    </td>

                    <td v-if="rList.reqStatus === 'WP'">
                      {{ rList.accPayStatus }}
                    </td>
                    <td v-else>
                      <div v-if="rList.reqStatus === 'RP' && rList.paidId == undefined " class="text-danger">
                      {{ rList.procName }}
                      </div>
                      <div v-else-if="rList.reqStatus === 'RP' && rList.paidId != undefined " class="text-danger">
                        {{ rList.procName }}<br>(결제완료)
                      </div>
                      <div v-else-if="rList.payStyle === 'f' " class="text-danger">
                        전액결제<br>
                      </div>
                      <div v-else-if="rList.payStyle === 'p' " class="text-warning">
                        부분결제<br>
                      </div>
                      <div v-else-if="rList.reqStatus === 'CX'" class="text-secondary">
                        <s>{{ rList.procName }}</s>
                      </div>
                      <div v-else-if="rList.reqStatus === 'CO'" class="text-primary">
                        {{ rList.procName }}
                      </div>
                      <div v-else>
                        {{ rList.procName }}
                      </div>
                    </td>
                    
                    <td class="trname">
                      <a @click="goToProductDetail(rList.reqPdtCode);" style="cursor:pointer;color:blue;">
                        {{ rList.resName }}
                      </a>
                    </td>
                    <td>
                      <!-- <b v-if="rList.reqStatus === 'IQ'">{{ this.getCurrencyFormat(rList.cstPrice_new) }}</b> -->
                      <div>
                        <b>{{ this.getCurrencyFormat(rList.cstPrice_new) }}</b>
                        <br>
                        <span class="text-warning" v-if="rList.payStyle === 'p'">({{ this.getCurrencyFormat(rList.actualPayAmount) }})</span>
                      </div>
                    </td>

                    <td v-if="rList.reqPdtCode.charAt(0) === 'F'">{{ getDateFormat(rList.startDate) }} (출발일)</td>
                    <td v-else>{{ getDateFormat(rList.startDate) }} ~ {{ getDateFormat(rList.closeDate) }}</td>

                    <td v-if="this.dFlag != 'past'">
                      <div v-if="rList.reqStatus != 'CX'">
                        <button type="button" class="btn btn-outline-danger btn-sm me-1 recxl" @click="reqCxl(rList.resCode,'i',i,rList.reqPdtCode)"  v-if="rList.reqCxlResCode == undefined">
                        <span style="font-size:smaller">요청</span>
                        </button>
                        <button type="button" class="btn btn-danger btn-sm me-1 recxl" @click="reqCxl(rList.resCode,'i',i,rList.reqPdtCode)" v-else disabled>
                          <span style="font-size:.8rem">요청중</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- 모바일 -->
              <table :key="i" v-for="(rList, i) in this.reqListAll" class="table mobile">
                <tbody>
                  <tr>
                    <td class="resname" colspan="3"><i class="bi bi-caret-right-fill"></i> {{ rList.resName }}</td>
                  </tr>
                  <tr>
                    <td class="mtit col-3">예약번호</td>
                    <td colspan="2" class="col-7">
                      <a @click="goToReqReserveDetail('R', rList.resCode, rList.reqPdtCode, rList.ntCd1);" style="cursor:pointer; color:blue; font-size:.9rem">
                        {{ rList.resCode }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="mtit">상태</td>
                    <td colspan="2" v-if="rList.reqStatus === 'RP' && rList.paidId == undefined " class="text-danger">
                      {{ rList.procName }}
                    </td>
                    <td colspan="2" v-else-if="rList.reqStatus === 'RP' && rList.paidId != undefined " class="text-danger">
                      {{ rList.procName }} (결제완료)
                    </td>
                    <td colspan="2" v-else-if="rList.payStyle === 'f' " class="text-danger">
                      전액결제<br>
                    </td>
                    <td colspan="2" v-else-if="rList.payStyle === 'p' " class="text-warning">
                      부분결제<br>
                    </td>                    
                    <td colspan="2" v-else-if="rList.reqStatus === 'CX'" class="text-secondary">
                      <s>{{ rList.procName }}</s>
                    </td>
                    <td colspan="2" v-else-if="rList.reqStatus === 'CO'" class="text-primary">
                      {{ rList.procName }}
                    </td>
                    <td colspan="2" v-else>
                      {{ rList.procName }}
                    </td>
                  </tr>
                  <tr>
                    <td class="mtit">결제금액</td>
                    <td colspan="2">
                      {{ this.getCurrencyFormat(rList.cstPrice_new) }}
                      <br>
                        <span class="text-warning" v-if="rList.payStyle === 'p'">({{ this.getCurrencyFormat(rList.actualPayAmount) }})</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="mtit">예약날짜</td>
                    <td colspan="2">{{ this.getDateFormat(rList.insDate) }}</td>
                  </tr>
                  <tr>
                    <td class="mtit">일정</td>
                    <td v-if="rList.reqPdtCode.charAt(0) === 'F'">{{ getDateFormat(rList.startDate) }} (출발일)</td>
                    <td v-else>{{ getDateFormat(rList.startDate) }} ~ {{ getDateFormat(rList.closeDate) }}</td>
                    
                    <td v-if="this.dFlag != 'past'" class="col-3 mtit">
                      <div v-if="rList.reqStatus != 'CX'">
                        <button type="button" class="btn btn-outline-danger btn-sm me-1 recxl" @click="reqCxl(rList.resCode,'i',i,rList.reqPdtCode)"  v-if="rList.reqCxlResCode == undefined">
                        <font style="font-size:smaller">취소요청</font>
                        </button>
                        <button type="button" class="btn btn-danger btn-sm me-1 recxl" @click="reqCxl(rList.resCode,'i',i,rList.reqPdtCode)" v-else disabled>
                          <font style="font-size:.8rem">취소요청중</font>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- 엔버스 예약내역 -->
            <div class="reserlist mt-5">
              <div class="text-start mx-auto">
                <h4 class="text-success">엔버스 예약내역</h4>
                <p class="text-end more">
                  <a href="javascript:void(0);" class="text-secondary" @click="getRequestConsultList_nbus('past')" v-if="this.dFlag_nbus != 'past'">지난예약보기</a>
                  <a href="javascript:void(0);" class="text-primary" @click="getRequestConsultList_nbus()" v-else>현재예약보기</a>
                </p>                  
              </div>

              <!-- pc -->
              <table class="table pc">
                <thead>
                  <tr class="text-center" style="background-color: lightgreen;">
                    <th style="width:10%">예약날짜/엔버스번호</th>
                    <th style="width:7%">상태</th>
                    <th style="width:35%">출발일(이용일수) / 출발장소(1일차)</th>
                    <th style="width:5%">결제금액</th>
                    <th style="width:15%">인원</th>
                    <th style="width:8%">취소</th>
                  </tr>
                </thead>
                <tbody style="font-size:small">
                  <tr :key="i" v-for="(rList, i) in this.reqListAll_nbus">
                    <td>
                      {{ this.getDateFormat(rList.insDate) }}
                      <br>
                      <a @click="goToReqReserveDetail('N', rList.inqCode);" style="cursor:pointer;color:green;">
                        {{ rList.inqCode }}
                      </a>
                    </td>

                    <td v-if="rList.inqStatus === 'WP'">
                      {{ rList.accPayStatus }}
                    </td>
                    <td v-else>
                      <div v-if="rList.inqStatus === 'RP' && rList.paidId == undefined " class="text-danger">
                      {{ rList.procName }}
                      </div>
                      <div v-else-if="rList.inqStatus === 'RP' && rList.paidId != undefined " class="text-danger">
                        {{ rList.procName }}<br>(결제완료)
                      </div>
                      <div v-else-if="rList.payStyle === 'f' " class="text-danger">
                        전액결제<br>
                      </div>
                      <div v-else-if="rList.payStyle === 'p' " class="text-warning">
                        부분결제<br>
                      </div>
                      <div v-else-if="rList.inqStatus === 'CX'" class="text-secondary">
                        <s>{{ rList.procName }}</s>
                      </div>
                      <div v-else-if="rList.inqStatus === 'CO'" class="text-primary">
                        {{ rList.procName }}
                      </div>
                      <div v-else>
                        {{ rList.procName }}
                      </div>
                    </td>
                    
                    <td class="trname">
                      {{ getDateFormat(rList.rentStartDate) }} ({{ rList.rentTotDays }}일간) / {{ rList.deptPoint }}
                    </td>
                    <td>
                      <div>
                        <b>{{ this.getCurrencyFormat(rList.nbusPriceTot) }}</b>
                        <br>
                        <span class="text-warning" v-if="rList.payStyle === 'p'">({{ this.getCurrencyFormat(rList.actualPayAmount) }})</span>
                      </div>
                    </td>

                    <td>{{ getCurrencyFormat(rList.personCnt) }}</td>

                    <td v-if="this.dFlag_nbus != 'past'">
                      <div v-if="rList.inqStatus != 'CX'">
                        <button type="button" class="btn btn-outline-danger btn-sm me-1 recxl" @click="reqCxl_nbus(rList.inqCode,'n',i)"  v-if="rList.reqCxlResCode == undefined">
                        <font style="font-size:smaller">요청</font>
                        </button>
                        <button type="button" class="btn btn-danger btn-sm me-1 recxl" @click="reqCxl_nbus(rList.inqCode,'n',i)" v-else disabled>
                          <font style="font-size:.8rem">요청중</font>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- 모바일 -->
              <table :key="i" v-for="(rList, i) in this.reqListAll_nbus" class="table mobile">
                <tbody>
                  <tr>
                    <td class="resname" colspan="3"><i class="bi bi-caret-right-fill"></i> {{ getDateFormat(rList.rentStartDate) }}</td>
                  </tr>
                  <tr>
                    <td class="mtit col-3">엔버스번호</td>
                    <td colspan="2" class="col-7">
                      <a @click="goToReqReserveDetail('N', rList.inqCode);" style="cursor:pointer;color:green; font-size:.9rem">
                        {{ rList.inqCode }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="mtit">상태</td>
                    <td colspan="2" v-if="rList.inqStatus === 'RP' && rList.paidId == undefined " class="text-danger">
                      {{ rList.procName }}
                    </td>
                    <td colspan="2" v-else-if="rList.inqStatus === 'RP' && rList.paidId != undefined " class="text-danger">
                      {{ rList.procName }} (결제완료)
                    </td>
                    <td colspan="2" v-else-if="rList.payStyle === 'f' " class="text-danger">
                      전액결제<br>
                    </td>
                    <td colspan="2" v-else-if="rList.payStyle === 'p' " class="text-warning">
                      부분결제<br>
                    </td>                    
                    <td colspan="2" v-else-if="rList.reqStatus === 'CX'" class="text-secondary">
                      <s>{{ rList.procName }}</s>
                    </td>
                    <td colspan="2" v-else-if="rList.inqStatus === 'CO'" class="text-primary">
                      {{ rList.procName }}
                    </td>
                    <td colspan="2" v-else>
                      {{ rList.procName }}
                    </td>
                  </tr>
                  <tr>
                    <td class="mtit">결제금액</td>
                    <td colspan="2">
                      {{ this.getCurrencyFormat(rList.nbusPriceTot) }}
                      <br>
                        <span class="text-warning" v-if="rList.payStyle === 'p'">({{ this.getCurrencyFormat(rList.actualPayAmount) }})</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="mtit">예약날짜</td>
                    <td colspan="2">{{ this.getDateFormat(rList.insDate) }}</td>
                  </tr>
                  <tr>
                    <td class="mtit">출발일 / 장소</td>
                    <td>
                      {{ getDateFormat(rList.rentStartDate) }} ({{ rList.rentTotDays }}일간)
                      <p class="mb-0">
                        {{ rList.deptPoint }} (1일차)
                      </p>
                    </td>
                    <td v-if="this.dFlag_nbus != 'past'" class="col-3 mtit">
                      <div v-if="rList.reqStatus != 'CX'">
                        <button type="button" class="btn btn-outline-danger btn-sm me-1 recxl" @click="reqCxl_nbus(rList.inqCode,'n',i)" v-if="rList.reqCxlResCode == undefined">
                        <font style="font-size:smaller">취소요청</font>
                        </button>
                        <button type="button" class="btn btn-danger btn-sm me-1 recxl" @click="reqCxl_nbus(rList.inqCode,'n',i)" v-else disabled>
                          <font style="font-size:.8rem">취소요청중</font>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- 문의들 -->
            <div class="container request">
              <div class="row col-12 gx-2">

                <!-- 1:1문의 -->
                <div class="col-lg-4 my-3">
                  <div class="bolder">
                    <div class="text-start">
                      <h4>1:1 문의</h4>
                      <p class="text-end more">
                        <a href="javascript:void(0);" @click="goToMyPageRequestList('one')">더보기</a>
                      </p> 
                    </div>
                    <hr style="margin:10px 0 0; color:#111">
                    <table class="table">
                      <tr>
                        <th>문의요청</th>
                        <th class="answer">{{ this.countCustomerAsk.totAsk}}<span>건</span></th>
                      </tr>
                      <tr>
                        <th>답변완료</th>
                        <th class="answer">{{ this.countCustomerAsk.totAns }}<span>건</span></th>
                      </tr>
                    </table>
                    <button class="button" @click="goToCustomerAskDetail()">1:1 문의하기</button>
                  </div>
                  
                </div>

                <!-- 상품문의 -->
                <div class="col-lg-4 my-3">
                  <div class="bolder productask">
                    <div class="text-start">
                      <h4>상품 문의</h4>
                      <p class="text-end more">
                        <a href="javascript:void(0);" @click="goToMyPageRequestList('item')">더보기</a>
                      </p> 
                    </div>
                    <hr style="margin:10px 0 0; color:#111">
                    <table class="table">
                      <tr>
                        <th>문의요청</th>
                        <th class="answer">{{ this.countInquiry.totAsk }}<span>건</span></th>
                      </tr>
                      <tr>
                        <th>답변완료</th>
                        <th class="answer">{{ this.countInquiry.totAns }}<span>건</span></th>
                      </tr>
                    </table>
                    <!-- <button class="button" @click="goToInquiryProduct()">상품 문의하기</button> -->
                  </div>
                  
                </div>

                <!-- 견적문의 -->
                <div class="col-lg-4 my-3">
                  <div class="bolder">
                    <div class="text-start">
                      <h4>견적 문의</h4>
                      <p class="text-end more">
                        <a href="javascript:void(0);" @click="goToMyPageRequestList('estimate')">더보기</a>
                      </p> 
                    </div>
                    <hr style="margin:10px 0 0; color:#111">
                    <table class="table">
                      <tr>
                        <th>문의요청</th>
                        <th class="answer">{{ this.countQuotation.totAsk }}<span>건</span></th>
                      </tr>
                      <tr>
                        <th>답변완료</th>
                        <th class="answer">{{ this.countQuotation.totAns }}<span>건</span></th>
                      </tr>
                    </table>
                    <button class="button" @click="goToRequestQuoDetail()">견적문의하기</button>
                  </div>
                  
                </div>
                  
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
import { getAvailPoint } from "../assets/js/commonFunctions.js"
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  data() {
    return {
      reqFlag: "R",          //문의플래그
      //포인트관련
      userInfo: [],          //유저정보
      resUserPoint: [],
      availPoint: 0,         // 사용자 가용포인트

      userMemInfo: [],       //멤버십정보

      rProc: [],             //프로세스레벨

      //예약관련
      reqListAll: [],       //전체
      reqListEvt: [],       //마이행사
      resName: "",          //상품, 행사명 (통합) 
      dFlag: "",            //시기구분플래그

      tot_selOptPrice:  0,          //선택된 옵션총액

      //문의카운트
      countCustomerAsk: 0,  //1:1문의카운트정보
      countInquiry: 0,      //상품문의카운트정보
      countQuotation: 0,    //견적문의카운트정보

      reqList: [], //1:1문의

      //문의관련
      reqList_inq: [],

      //견적관련
      reqList_quo: [],

      //엔버스마이페이지 리스트
      reqListNbus: [],
      reqListAll_nbus: [],

      dFlag_nbus: "",            //엔버스 시기구분플래그

      //payStyle: "",         //p: 부분 , f: 전액

      handTel: [],                //매니저연락처
      handTelCnt: 0               //수신처갯수
    };
  },
  created(){
    //console.log("user:",this.$store.state.user); 
    
    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")
    //console.log("today:",this.today)

    //회원정보
    this.getUserInfo();

    // //포인트관련(미확정이 있으면 제거가 먼저)
    // this.rmStandbyPoint();

    //예약관련
    this.getReqProcLevel();
    this.getRequestConsultList();
    this.getRequestConsultList_nbus();          //엔버스예약리스트

    this.getHandlerInfo()           //알림톡 발송대상 체크

    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.user.joinFlag === "H")
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.alimCallC != undefined && this.user.alimCallC.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.alimCallC.replace("+82 ", "0")
      }
      else
      {
        kakaoPhone = this.user.alimCallC
      }      
      this.k_tel = (!this.user.alimCallC) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }
    //console.log("k_name:",this.k_name, "k_tel:",this.k_tel)
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },  
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   this.$router.push({path: '/'});
    // }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/b2CLogin', query:{st: "Y"}});
    }

    const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
    //const time = new Date().toTimeString().split(" ")[0];
    this.today = date
    //this.today = date + ' ' + time;
    console.log("today:", this.today);
  },  
  methods: {
    // 숫자 3자리마다 콤마 찍기
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },

    //about UserInfo
    async getUserInfo() 
    {
      let userInfo = await this.$api("/api/checkMemEmail", {param: [this.user.email]});
      if(userInfo.length > 0) {
        this.userInfo = userInfo[0];
      }
      //console.log("userInfo:", this.userInfo)

      this.getCountCustomerAsk(this.userInfo.uEmail)    //1:1카운트
      this.getCountInquiry(this.userInfo.uEmail)        //상품문의카운트
      this.getCountQuotation(this.userInfo.uEmail)      //상품문의카운트
      //포인트관련(미확정이 있으면 제거가 먼저)
      this.rmStandbyPoint();


      //멤버십 유무
      if(userInfo[0].mShipFlag == "Y")
      {
        let userMemInfo = await this.$api("/api/checkMembership", {param: [this.user.email]});

        if(userMemInfo.length > 0)
        {
          this.userMemInfo = userMemInfo[0]
          //console.log("userMemInfo:",userMemInfo)
        }
      }
    },

    async deletePhoto(){
      //삭제할 파일명
      let param = this.userInfo.photo
      let flag = "myPage"
      let response = await this.$api("/api/deletePhoto", {param: param, flag})    //call back-end

      if(response != undefined)
      {
        let memberPhotoUpdate = await this.$api("/api/memberPhotoUpdate", {param: [
          '', this.user.email
        ]});


        if(memberPhotoUpdate.affectedRows >= 0)
        {
          this.$swal.fire("","사진이 삭제되었습니다", "info")
          location.reload(true)
        }
        else
        {
          this.$swal.fire("","삭제를 다시 진행해 주세요", "warning")
          return false;
        }
      }
    },

    changePhoto(){
      //숨겨진 이벤트를 호출
      this.$refs.fileInput.click();
    },
    async handleFileChange(){
      //let file = document.getElementById('fileInput').files[0];
      let file = this.$refs.fileInput.files[0];
      if(file)
      {
        let fileSizeInKB = file.size/1024

        if(fileSizeInKB > 1000)
        {
          this.$swal.fire("","이미지의 사이즈는 1mb를 초과할 수 없습니다.","warning")
          return false;
        }
        else
        {
          //아이디를 파일명으로 저장처리
          //let memId = this.user.email
          //let fileType = file.name.split(".").pop().toLowerCase()

          //backend의 multer이용
          const formData = new FormData()
          //let newFileName = imgName

          formData.append('image', file)
          formData.append('memberid', this.user.email)

          let config = {
            headers: {
              'memid': this.user.email
            }
          }
          //console.log("config:", config)

          //let response = await this.$api("http://localhost:5000/uploads/memberPhoto/", formData, config)
          let response = await this.$api("https://ntabi.co.kr/uploads/memberPhoto/", formData, config)

          if(response != undefined)
          {
            let memberPhotoUpdate = await this.$api("/api/memberPhotoUpdate", {param: [
              response.imageName, this.user.email
            ]});

            if(memberPhotoUpdate.affectedRows >= 0)
            {
              this.$swal.fire("","사진이 등록되었습니다", "info")
              location.reload(true)
            }
            else
            {
              this.$swal.fire("","사진을 재등록해주세요.", "warning")
              return false;
            }
          }
        }
      }
    },

    goToMyPageInfoChange(){
      this.$router.push({path: '/myPageInfoChange'});
    },
    joinToMembership(param){
      if(param === "J")
      {
        this.$router.push({path: '/MyPageInfoChange_joinM'});
      }
      if(param === "P")
      {
        this.$router.push({path: '/MyPageInfoChange_memberP'});
      }
      if(param === "S")
      {
        this.$router.push({path: '/MyPageInfoChange_memberS'});
      }
    },    


    //사용대기 포인트가 있으면 삭제 후, 포인트 재계산
    async rmStandbyPoint()
    {
      //console.log("rmStandbyPoint")
      let waitingPoint = await this.$api("/api/userStandbyPoint", {param: [this.user.email]})
      {
        if(waitingPoint.length > 0)
        {
          let rmPoint = await this.$api("/api/rmPoint", {param: [this.user.email, 'S']});
          //삭제성공
          if(rmPoint.affectedRows < 0 || rmPoint.affectedRows == undefined )
          {
            this.$swal.fire("","죄송합니다. 포인트 조회에 문제가 발생했습니다.","warning")
            return false
          }
          // else
          // {
          //   this.getUserPoint();
          //   this.getAvailPoint();
          // }
        }
        this.getUserPoint();
        this.getAvailPoint();        
      }
      //console.log("rmPoint:",rmPoint);
    },
    async getUserPoint(con) 
    {
      if (con === "all" || con === "" || con === undefined) 
      {
        con = "%"; 
      }
      let resUserPoint = await this.$api("/api/getUserPoint", {param: [this.user.email, con]});
      if(resUserPoint.length > 0) 
      {
        this.resUserPoint = resUserPoint;
      }
      //console.log("resUserPoint:",this.resUserPoint);
    },

    //공통함수사용(commonFunctions.js)
    async getAvailPoint() 
    {
      this.availPoint = await getAvailPoint(this.$api, this.user.email)
    },

    //About NBUS
    async getReqProcLevel() 
    {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) 
      {
        this.rProc = rProc
      }
      //console.log("rProc:",this.rProc);
    },

    async getRequestConsultList_nbus(f)
    {
      //console.log("dFlag:",this.dFlag)
      this.dFlag_nbus = (f==="past") ? 'past' : ''

      let reqListNbus = await this.$api("/api/nbusInqList_nbus", {param:[this.user.email]});
      //console.log("reqListNbus:",reqListNbus)

      if(f == "past")
      {
        this.reqListAll_nbus = reqListNbus.filter(e => {
          return this.getDateFormat(e.rentStartDate) < this.today
        });
      }
      else
      {
        this.reqListAll_nbus = reqListNbus.filter(e => {
          return this.getDateFormat(e.rentStartDate) >= this.today
        });
      }

      //console.log("reqListAll_nbus:", this.reqListAll_nbus)

      let i = 0
      while(i < this.reqListAll_nbus.length)
      {
        let accPayStatus = ""
        if(this.reqListAll_nbus[i].inqStatus === "WP")
        {
          let virAccInfo = await this.$api("/api/res_virAccPayInfo", {param: [this.reqListAll_nbus[i].inqCode]});
          //console.log("virAccInfo:", virAccInfo)
          if(virAccInfo.length > 0)
          {
            if((virAccInfo[0].payStyle === "fu" && virAccInfo[0].payFlag1 === "Y") || (virAccInfo[0].payStyle === "p2" && virAccInfo[0].payFlag2 === "Y"))
            {
              accPayStatus = "결제완료(담당자 확인 중)" 
            }
            else if((virAccInfo[0].payStyle === "p1" && virAccInfo[0].payFlag1 === "Y") || (virAccInfo[0].payStyle === "p2" && virAccInfo[0].payFlag2 === "N"))
            {
              accPayStatus = "잔액입금대기" 
            }
            else
            {
              accPayStatus = "입금대기"
            }
            //this.reqListAll[i].accPayStatus.push(accPayStatus)
            this.reqListAll_nbus[i].accPayStatus = accPayStatus
          }
        }

        else if (this.reqListAll_nbus[i].inqStatus === "PY") {
          if (this.reqListAll_nbus[i].payFlag === "fu" || this.reqListAll_nbus[i].payFlag === "p2" || this.reqListAll_nbus[i].payFlag == null) {
            this.reqListAll_nbus[i].payStyle = "f";
          } else if (this.reqListAll_nbus[i].payFlag === "p1") {
            this.reqListAll_nbus[i].payStyle = "p";
          }
        }
        i++
      }
    },

    //about Reservation
    async getRequestConsultList(f)
    {
      //console.log("dFlag:",this.dFlag)
      this.dFlag = (f==="past") ? 'past' : ''

      let reqListRes = await this.$api("/api/getMyResList_new", {param:[this.user.email]});
      let reqListEvt = await this.$api("/api/getMyEvtList", {param:[this.user.email]});
      let reqList = reqListRes.concat(reqListEvt);
      //console.log("reqListReqRes:",reqListReqRes)

      if(f == "past")
      {
        this.reqListAll = reqList.filter(e => {
          return this.getDateFormat(e.closeDate) < this.today
        });
      }
      else
      {
        this.reqListAll = reqList.filter(e => {
          return this.getDateFormat(e.closeDate) >= this.today
        });
      }

      //console.log("reqListAll:", this.reqListAll)

      let i = 0
      while(i < this.reqListAll.length)
      {
        let accPayStatus = ""
        if(this.reqListAll[i].reqStatus === "WP")             //가상계좌 + 입금대기
        {
          let virAccInfo = await this.$api("/api/res_virAccPayInfo", {param: [this.reqListAll[i].resCode]});
          //console.log("virAccInfo:", virAccInfo)
          if(virAccInfo.length > 0)
          {
            if((virAccInfo[0].payStyle === "fu" && virAccInfo[0].payFlag1 === "Y") || (virAccInfo[0].payStyle === "p2" && virAccInfo[0].payFlag2 === "Y"))
            {
              accPayStatus = "결제완료(담당자 확인 중)" 
            }
            else if((virAccInfo[0].payStyle === "p1" && virAccInfo[0].payFlag1 === "Y") || (virAccInfo[0].payStyle === "p2" && virAccInfo[0].payFlag2 === "N"))
            {
              accPayStatus = "잔액입금대기" 
            }
            else
            {
              accPayStatus = "입금대기"
            }
            //this.reqListAll[i].accPayStatus.push(accPayStatus)
            this.reqListAll[i].accPayStatus = accPayStatus
          }
        }

        else if (this.reqListAll[i].reqStatus === "PY") {
          if (this.reqListAll[i].payFlag === "fu" || this.reqListAll[i].payFlag === "p2" || this.reqListAll[i].payFlag == null) {
            this.reqListAll[i].payStyle = "f";
          } else if (this.reqListAll[i].payFlag === "p1") {
            this.reqListAll[i].payStyle = "p";
          }
        }
        i++
      }
    },

    //선택옵션획득
    async getSelOptions(resCode) {
      let selOptions = []
      let useFlag = "Y"
      let tot_selOptPrice = 0

      selOptions = await this.$api("/api/getSelOptions", {param: [resCode, useFlag]});

      if(selOptions.length > 0)
      {
        //this.selOptions = selOptions
        
        selOptions.forEach(so => {
          tot_selOptPrice += (so.prcOpt * so.qtyOpt)})
      }
      else
      {
        tot_selOptPrice = 0
      }
      //console.log("tot_selOptPrice:", tot_selOptPrice)
      return tot_selOptPrice
    },

    //선택추가상품획득
    async getSelAddProductions(resCode) {
      let selAddPdt = []
      let useFlag = "Y"
      let tot_selAddPdtPrice = 0
      selAddPdt = await this.$api("/api/getSelAddProductions", {param: [resCode, useFlag]});

      if(selAddPdt.length > 0)
      {
        //this.selAddPdt = selAddPdt
        selAddPdt.forEach(sa => {
          tot_selAddPdtPrice += (sa.addPdtPrice * sa.qty_addPdt)
        });
      }
      else
      {
        tot_selAddPdtPrice = 0
      }
      //console.log("tot_selAddPdtPrice:", tot_selAddPdtPrice)
      return tot_selAddPdtPrice
    },

    goToReqReserveDetail(path, seqReq, pCode, ntCode1) {
      if(path === "N")
      {
        this.$router.push({path: '/reserveInfoDetail_client', query: {seqReq: seqReq, cCode: 'N'}})
      }
      else
      {
        if(seqReq.substr(0,2) === "RQ")
        {
          this.$router.push({path: '/reserveInfoDetail_client', query: {seqReq: seqReq, pCode: pCode, cCode: 'Y'}});
        }
        else
        {
          this.$router.push({path: '/eventInfo_client', query: {eCode: seqReq, ntCd: ntCode1}});
        }
      }
    },

    goToProductDetail(pCode) {
      let sRoute = ""
      sRoute = pCode.charAt(0)

      this.$router.push({path: '/detail', query: {productId: pCode, salesRoute: sRoute}});
    },

    //1:1문의 건수
    async getCountCustomerAsk(uId){
      let countCustomerAsk = await this.$api("/api/countCustomerAsk", {param: [uId]})
      
      if(countCustomerAsk.length > 0)
      {
        this.countCustomerAsk = countCustomerAsk[0]
        this.countCustomerAsk.totNoAns = this.countCustomerAsk.totAsk*1 - this.countCustomerAsk.totAns*1
      }
      //console.log("countCustomerAsk:", this.countCustomerAsk)
    },

    //상품문의 건수
    async getCountInquiry(userId){
      let countInquiry = await this.$api("/api/countInquiry", {param: [userId]})
      
      if(countInquiry.length > 0)
      {
        this.countInquiry = countInquiry[0]
        this.countInquiry.totNoAns = this.countInquiry.totAsk*1 - this.countInquiry.totAns*1
      }
      //console.log("countInquiry:", this.countInquiry)
    },

    //견적문의 건수
    async getCountQuotation(mId){
      let countQuotation = await this.$api("/api/countQuotation", {param: [mId]})
      
      if(countQuotation.length > 0)
      {
        this.countQuotation = countQuotation[0]
        this.countQuotation.totNoAns = this.countQuotation.totAsk*1 - this.countQuotation.totAns*1
      }
      //console.log("countQuotation:", this.countQuotation)
    },    


    //1:1문의 바로가기
    goToCustomerAskDetail(askCode) {  //askCode: 질문번호
      this.$router.push({path: '/customerAsk', query: {askCode: askCode}});
      //console.log("oId:", orderId);
    },
    //견적문의 바로가기
    goToRequestQuoDetail() {
      this.$router.push({path: '/requestQuotation', query: {}});
      //console.log("oId:", orderId);
    },

    goToMyPageRequestList(tab) {  //askCode: 질문번호
      this.$router.push({path: '/myPageRequestList', query: {askCode: tab}});
    },




    // //about 1on1 inquiry
    // async getCustomerAskList() {
    //   this.reqFlag = 'C';
    //   let reqList = await this.$api("/api/customerAskList_myPage", {param: [this.user.email]});
    //   if(reqList.length > 0) {
    //     this.reqList = reqList;
    //   }
    //   console.log("reqList:",this.reqList);
    // },

    // //about Quotation
    // async getRequestQuotationList() 
    // {
    //   let reqList_quo = await this.$api("/api/reqQuotationList1", {param: [this.user.email]});
    //   if(reqList_quo.length > 0) {
    //     this.reqList_quo = reqList_quo;
    //   }
    //   console.log("reqList_quo:",this.reqList_quo);
    // },


    // //about Inquiry
    // async getRequestList() {
    //   this.reqFlag = 'R';
    //   let reqList_inq = await this.$api("/api/requestList_myPage", {param: [this.user.email]});
    //   if(reqList_inq.length > 0) {
    //     this.reqList_inq = reqList_inq;
    //   }
    //   console.log("reqList_inq:",this.reqList_inq);
    // },
    


    goToHome() {
      this.$router.push({path: '/'});
    },
    async changeAlimCall()
    {
      const { value: alim } = await this.$swal.fire({
          html: '알림을 받을 연락처를 변경합니다',
          input: 'text',
          //inputLabel: '뱃지표시',
          inputPlaceholder: '(예시: 000-1111-2222)'
        })

        //console.log("alim:",alim)
      let result = this.validatePhoneNumber(alim)
      if(!result)
      {
        this.$swal.fire('', '유효하지 않은 연락처 번호입니다.', 'warning')
        return false;
      }
      else
      {
        if (alim) 
        {
          this.$swal.fire('',`변경된 연락처: ${alim}`)
          let changeAlimNo = await this.$api("/api/changeAlimNo", {param: [alim, this.user.email]});
          if(changeAlimNo.affectedRows < 0 || changeAlimNo.affectedRows === undefined)
          {
            this.$swal.fire('', '변경 중 오류가 발생했습니다(코드:changeAlimNo)', 'alert')
          }
          else
          {
            this.$swal.fire('', '변경되었습니다. 감사합니다.', 'success')
            location.reload(true)
          }
        }
      }
    },
    //입력된 연락처 검증
    validatePhoneNumber(no) 
    {
      const regex = /^\d{3}-\d{3,4}-\d{4}$/;
      return regex.test(no);
    },

    //About point
    async changeCondition(con) {
      //alert("con:"+con)
      this.getUserPoint(con);
    },

    //취소요청처리
    async reqCxl(rCode, flag, seq, pCode)
    {
      //상품별 취소안내사항
      let res_supCxl = await this.$api("/api/supCxl", {param: pCode})

      let supCxl = ""
      if(res_supCxl.length > 0)
      {
        supCxl = res_supCxl[0]
      }
      this.$swal.fire({
        html: "예약취소를 요청하시겠습니까?<br><br><p class='text-start swal-popup'>"+ supCxl.supCancellation.split('\n').join('<br>') +"</p>",
        showCancelButton: true,
        confirmButtonText: '동의하고 요청합니다',
        cancelButtonText: '요청하지 않습니다',
        width: 1000,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let conFlag = "Y"   //취소약관동의
          let insReqCxlReserve = await this.$api("/api/insReqCxlReserve", {param: [
            rCode, this.user.email,conFlag
          ]});
          if(Object.keys(insReqCxlReserve)[0] === 'error') 
          {
            this.$swal.fire('','요청처리 중 오류가 발생했습니다.<br>온라인 문의 또는 연락(1660-4602)을 주시면 신속하게 처리해드리겠습니다.', 'warning')
            return false;
          }
          else
          {
            this.sendKakao("RCX", flag, seq, rCode)
          }
        }
      })
    },

    //엔버스취소요청처리
    async reqCxl_nbus(nCode, flag, seq)     //nbus 'n' i 
    {
      //상품별 취소안내사항
      let res_supCxl = await this.$api("/api/supCxl_nbus", {param: nCode})

      let supCxl = ""
      if(res_supCxl.length > 0)
      {
        supCxl = res_supCxl[0]
      }
      this.$swal.fire({
        html: "예약취소를 요청하시겠습니까?<br><br><p class='text-start swal-popup'>"+ supCxl.supCancellation.split('\n').join('<br>') +"</p>",
        showCancelButton: true,
        confirmButtonText: '동의하고 요청합니다',
        cancelButtonText: '요청하지 않습니다',
        width: 1000,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let conFlag = "Y"   //취소약관동의
          let insReqCxlReserve = await this.$api("/api/insReqCxlReserve", {param: [
            nCode, this.user.email,conFlag
          ]});
          if(Object.keys(insReqCxlReserve)[0] === 'error') 
          {
            this.$swal.fire('','요청처리 중 오류가 발생했습니다.<br>온라인 문의 또는 연락(1660-4602)을 주시면 신속하게 처리해드리겠습니다.', 'warning')
            return false;
          }
          else
          {
            this.sendKakao("RCX", flag, seq, nCode)           // "n" i NB-XXXXX
          }
        }
      })
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
      //console.log("handTel: ", this.handTel)
    },

    /** 카카오알림 발송 */
    async sendKakao(param, flag, seq, code)
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        code, param, this.k_tel
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.k_tel})
      this.handTelCnt = this.handTel.length
      //console.log("handTel:", this.handTel, "handTelCnt:", this.handTelCnt)
      // let reqCxlDate = ""           //취소요청일자
      // if(param === "RCX")
      // {
      //   let reqCxlInfo = this.$api("/api/checkReqCxl", {param: [ code ]})

      //   if(reqCxlInfo.length > 0)
      //   {
      //     reqCxlDate = this.getDateFormat(reqCxlInfo[0].insDate)
      //   }
      //   else
      //   {
      //     reqCxlDate = this.today
      //   }
      // }
      let kakaoAlimData = {}

      if(insKSendHistory.affectedRows > 0)
      {
        let promises = []

        if(flag ==='j')
        {
          for(let j=0; j<this.handTelCnt; j++)
          {
            kakaoAlimData = 
            {
              clientName: this.k_name,
              resName: this.reqListReqRes[seq].resName,
              resPaxName: this.reqListReqRes[seq].myName,
              resPaxCnt: this.reqListReqRes[seq].reqTotCnt,
              dptDate: this.getDateFormat(this.reqListReqRes[seq].startDate),
              resPhone: this.reqListReqRes[seq].myTel,
              resCode: this.reqListReqRes[seq].resCode,
              resManager: this.reqListReqRes[seq].managerName,
              alimFlag: param,                                             //알림유형(RCX:취소요청)
              toNumber: this.handTel[j].aimCall,                           //알림톡발송대상
            };

            let promise_j = this.$api("api/kakaoAlimSend2", {
              param: [ { kakaoAlimData: kakaoAlimData} ]
            })

            promises.push(promise_j)
          }

        }
        else if(flag === 'i')
        {
          for(let i=0; i<this.handTelCnt; i++)
          {
            kakaoAlimData = 
            {
              clientName: this.k_name,
              resName: this.reqListAll[seq].resName,
              resPaxName: this.reqListAll[seq].myName,
              resPaxCnt: this.reqListAll[seq].reqTotCnt,
              dptDate: this.getDateFormat(this.reqListAll[seq].startDate),
              resPhone: this.reqListAll[seq].myTel,
              resCode: this.reqListAll[seq].resCode,
              resManager: this.reqListAll[seq].managerName,
              alimFlag: param,                                             //알림유형(RCX:취소요청)
              toNumber: this.handTel[i].aimCall,                           //알림톡발송대상
            };

            let promise_i = this.$api("api/kakaoAlimSend2", {
              param: [ { kakaoAlimData: kakaoAlimData} ]
            })

            promises.push(promise_i)
          }
        }
        //엔버스 취소요청
        else if(flag === 'n')
        {
          for(let n=0; n<this.handTelCnt; n++)
          {
            kakaoAlimData = 
            {
              inqName: this.k_name,
              inqCode: this.reqListAll_nbus[seq].inqCode,
              rentStartDate: this.getDateFormat(this.reqListAll_nbus[seq].rentStartDate),
              inqTel: this.reqListAll_nbus[seq].inqTel,
              alimFlag: param,                                             //알림유형(RCX:취소요청)
              toNumber: this.handTel[n].aimCall,                           //알림톡발송대상
              memFlag: "Y",
            };
            console.log("kakaoAlimData:>>>>>>>", kakaoAlimData)

            let promise_n = this.$api("api/kakaoAlimSend7", {
              param: [ { kakaoAlimData: kakaoAlimData} ]
            })

            promises.push(promise_n)
          }
        }
        //반복문 이후 처리
        Promise.all(promises).then((results) => {
          console.log(results)
          this.$swal.fire('', '취소요청 알림톡이 발송되었습니다', 'success').then(() => {
            //location.reload(true)
          })
        }).catch((error) => {
          console.log("Error calling api: ", error)
        })
      }

      // let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
      //   kakaoAlimData.resCode, param, this.user.email
      // ]});

      // //알림톡 발송대상 확정
      // this.handTel.push({aimCall: this.userInfo.alimCallC})
      // this.handTelCnt = this.handTel.length

      // #{clientName}님, 요청하신 예약의 취소가 접수되었습니다.
      // 즉시 취소가 불가능한 경우 담당자가 상담연락을
      // 드릴 예정입니다.

      // - 예약상품: #{resName}
      // - 예약자명: #{resPaxName}
      // - 여행인원: #{resPaxCnt}
      // - 여행일자: #{dptDate}
      // - 고객연락처: #{resPhone}
      // - 예약번호: #{resCode}
      // - 담당자: #{resManager} 매니저
      // - 예약확인: #{resCheck}
      // if(insKSendHistory.affectedRows > 0) {
      //   if(param == "RCX") 
      //   {
      //     //kakaoAlimData
      //     this.$api("api/kakaoAlimSend2", {
      //       param: [
      //         { kakaoAlimData: kakaoAlimData},
      //       ]
      //     }).then((res) => {console.log(res)});
      //     this.$swal.fire('', '취소요청 알림톡이 발송되었습니다', 'success');
      //     location.reload(true)
      //   }
      // }
    },
  }
}
</script>



<style>
#mypage{
  margin:4rem 0 8rem;
}
@media (max-width:992px){
  #mypage{
    margin:3rem 0 4rem;
  }
}

#mypage h3{
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1.5rem;
  text-transform: uppercase;
  text-align: center;
}

/*--------------------------------------------------------------
# 왼쪽 / 개인정보
--------------------------------------------------------------*/
#mypage .mleft{
  text-align: center;
  margin-top:2.4rem
}

/* ------- 사진 ------- */
#mypage .mleft .photo{
  align-self: center;
}
#mypage .mleft .myimg{
  position: relative;
  height:10rem;
}
#mypage .mleft .photo img{
  position: absolute;
  top:5%;
  left:50%;
  transform: translateX(-50%);
  width:9rem;
  height:9rem;
  border-radius: 50%;
}

#mypage .mleft .photo .photoDel{
  position: absolute;
  top:70%;
  right:28%;
  border:1px solid #000;
  border-radius: 50%;
  background-color: #fff;
}
@media (min-width:992px){
  #mypage .mleft .photo{
    align-self: flex-end;
  }
}
@media (max-width:1200px){
  #mypage .mleft .photo .photoDel{
    right:20%;
  }
}
@media (max-width:991px){
  #mypage .mleft{
    margin-top:3rem
  }
  #mypage .mleft .photo img{
    top:0;
  }
  #mypage .mleft .photo .photoDel{
    top:65%;
    right:25%;
  }
}
@media (max-width:767px){
  #mypage .mleft .photo .photoDel{
    right:19%;
  }
}
@media (max-width:500px){
  #mypage .mleft .photo .photoDel{
    top:70%;
    right:10%;
  }
}

/* ------- 개인정보 ------- */
#mypage .mleft .myinfo{
  margin:1rem 0 3rem
}
#mypage .mleft .myinfo p{
  font-size:1.1rem;
  color:#000;
  margin-bottom:.7rem
}
#mypage .mleft .myinfo .name{
  font-size:1.8rem;
  font-weight: 600;
  color:#000
}
#mypage .mleft .myinfo .mship{
  width:auto;
  height:2rem;
  margin-top:-15px
}
#mypage .mleft .myinfo .memNo{
  font-size:.8rem;
  display: block;
}
#mypage .mleft .myinfo .revise{
  margin-top:2rem;
  width:60%;
  padding:10px 0;
  border-radius: 60px;
  background-color: #f9f9f9;
  border:1px solid #d4d4d4;
  color:#000;
  transition: all .5s;
}
#mypage .mleft .myinfo .revise:hover{
  background-color:#eee;
}

#mypage .mleft .myinfo .membership{
  margin-top:.5rem;
  width:60%;
  padding:10px 0;
  border-radius: 60px;
  background: linear-gradient(to right,#3baeef, #0b7fd4,  #0065bd);
  color:#fff
}
#mypage .mleft .myinfo .membership2{
  margin-top:.5rem;
  width:60%;
  padding:7px 0;
  border-radius: 60px;
  background: linear-gradient(to right, #fff400, #ffce00,  #ffb400);
  color:#000;
  font-weight: 600;
}
#mypage .mleft .myinfo .membership3{
  margin-top:.5rem;
  width:60%;
  padding:7px 0;
  border-radius: 60px;
  background: linear-gradient(to right,#3baeef, #0b7fd4,  #0065bd);
  color:#fff;
  font-weight: 600;
}
@media (max-width:991px){
  #mypage .mleft .myinfo{
    margin:0 0 1rem 2rem;
    text-align: left;
  }
  #mypage .mleft .myinfo .memNo{
    display: inline;
  }
  #mypage .mleft .myinfo p{
    font-size:.9rem;
    margin-bottom:0.3rem
  }
  #mypage .mleft .myinfo .name{
    font-size:1.3rem;
  }
  #mypage .mleft .myinfo .revise ,
  #mypage .mleft .myinfo .membership,
  #mypage .mleft .myinfo .membership2,
  #mypage .mleft .myinfo .membership3{
    width:100%;
    font-size:.8rem;
    margin-top:.5rem;
    padding:7px 0
  }
}

/* ------- 포인트 ------- */
#mypage .mleft .point img{
  width:7rem
}
#mypage .mleft .point span{
  font-size:2rem;
  font-weight: 600;
  color:#000
}
@media (max-width:991px){
  #mypage .mleft .point{
    text-align: left;
    padding-left:30px
  }
  #mypage .mleft .point img{
    width:4rem
  }
  #mypage .mleft .point .dropdown-item{
    display:inline;
    padding-left:10px
  }
  #mypage .mleft .point span{
    font-size: 1.6rem;
  }
}



/*--------------------------------------------------------------
# 오른쪽 / 예약관련
--------------------------------------------------------------*/
#mypage .mright{
  margin-top:3rem
}
#mypage .mright .pc{
  display: block;
}
#mypage .mright .mobile{
  display: none;
}
@media (max-width:991px){
  #mypage .mright .pc{
    display: none;
  }
  #mypage .mright .mobile{
    display: block;
  }
}

/* ------- 예약내역 ------- */
#mypage .mright h4{
  font-weight: 600;
  color:#000
}
#mypage .mright .reserlist .more{
  margin-top:-27px;
  font-size:.9rem;
  margin-bottom:.7rem;
  padding-right:5px
}
#mypage .mright .reserlist .more:hover a{
  color:#000
}
#mypage .mright .reserlist table{
  border:2px solid #000; 
}
#mypage .mright .reserlist thead{
  font-size:.9rem;
  text-align: center;
  background-color: #f1f1f1;
}
#mypage .mright .reserlist th{
  padding:15px 0
}
#mypage .mright .reserlist tr{
  text-align: center;
  vertical-align: middle;
  border-color:#ccc
}
#mypage .mright .reserlist td{
  padding:25px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
#mypage .mright .reserlist td a{
  font-size:.7rem
} 

/* 모바일버전 */
#mypage .mright .reserlist .mobile{
  border:none;
  background-color: #f4f5f7;
}
#mypage .mright .reserlist .mobile tr{
  border-color: transparent
}
#mypage .mright .reserlist .mobile td{
  text-align: left;
  font-size:.75rem;
  padding: 15px 5px;
}
#mypage .mright .reserlist .mobile .resname{
  font-weight: 600;
  font-size:1rem;
  padding:15px 5px 10px 10px;
}
#mypage .mright .reserlist .mobile .mtit{
  text-align: center;
}




/* ------- 문의들 ------- */
#mypage .mright .request .bolder{
  border: 1px solid #000;
  padding:20px 15px;
}

#mypage .mright .request h4{
  font-size:1.2rem;
  padding-left:5px
}

#mypage .mright .request .more{
  margin-top:-25px;
  font-size:.8rem;
  margin-bottom:.7rem;
  padding-right:10px
}
#mypage .mright .request .more:hover a{
  color:#000
}

#mypage .mright .request .table{
  margin:15px 0
}
#mypage .mright .request tr{
  border-color:transparent;
}
#mypage .mright .request th{
  height:50px;
  vertical-align: middle;
}
#mypage .mright .request .answer{
  text-align: right;
  font-size:1.3rem
}
#mypage .mright .request .answer span{
  font-size:.9rem;
  font-weight: 300;
}
#mypage .mright .request .button{
  width:100%;
  background-color: #fff;
  text-align: center;
  padding:10px 0;
  border-width: 1px;
  font-weight: 600;
}

#mypage .mright .request .productask{
  padding-bottom:4.1rem
}


@media (max-width:991px){
  #mypage .mright .request h4{
    font-size:1rem
  }
  #mypage .mright .request .more{
    font-size:.7rem;
  }
  #mypage .mright .request .table{
    margin:5px 0
  }
  #mypage .mright .request th{
    font-size:.85rem
  }
  #mypage .mright .request .answer{
    font-size:1rem
  }
  #mypage .mright .request .answer span{
    font-size:.8rem;
  }
  #mypage .mright .request .button{
    font-size:.9rem
  }
  #mypage .mright .request .productask{
    padding-bottom:0
  }
  /* 취송요청 확인팝업 */
  .swal-popup {
    font-size:0.9rem;
    overflow: hidden;
    word-wrap: break-word;
  }
}
</style>
