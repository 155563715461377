<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="float-end mb-1">
          <!-- <button type="button" class="btn btn-secondary" @click="goToInsert"><strong>단일상품등록</strong>&nbsp;(기본사항)</button> -->
          <button type="button" class="btn btn-outline-success" disabled><strong>결합상품리스트</strong></button>
        </div>
        <div class="float-start mb-1">
          <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="i" 
            @change="changeCondition()">
            <option :value="noVal" disabled>상품조회</option>
            <option value="all" >전체상품</option>
            <option value="T" >여행상품(T)</option>
            <option value="L" >현지투어(L)</option>
            <!-- <option value="H" >숙박상품(H)</option> -->
            <option value="E" >기타상품(E)</option>
            <option value="S" >단일가상품(S)</option>
            <option value="Y" >판매중</option>
            <option value="N" >판매중지</option>
          </select>
        </div>
        <table class="table table-bordered table-striped fs-6 w-100">
          <thead class = "small">
            <tr>
              <th>판매처</th>
              <th>상품코드</th>
              <th>구분</th>
              <th>속성</th>
              <th>출발</th>
              <th>상품명</th>
              <th>국가</th>
              <th>교통</th>
              <th>추가</th>
              <th>기타</th>
              <th>
                <font>가격</font>
                <br>
                <font class="text-primary">(할인)</font>
              </th>
              <th>
                상태
                <br>
                <font class="text-primary">현상</font>
              </th>
              <th>판매기간</th>
              <th>상세설정</th>
              <th>기타작업</th>
              <th>비고사항</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(product, i) in productMaList">
              <td>
                <div class="text-success" v-if="product.sellerCode_nt === 'Y' || product.sellerCode_nt == undefined">
                  NT
                </div>
                <div class="text-primary" v-if="product.sellerCode_nd === 'Y' || product.sellerCode_nd == undefined">
                  ND
                </div>                
              </td>
                            
              <td class="text-primary "><small>{{ product.pCode }}</small></td>
              <td>{{ product.salesRoute }}</td>
              <!-- <td v-if="product.salesRoute == 'T'" class="text-primary">여행상품</td>
              <td v-else-if="product.salesRoute == 'L'" class="text-danger">현지투어</td>
              <td v-else>{{product.salesRoute}}</td> -->

              <td  v-if="product.tripProp === 'D'" class="text-info"><i><b>{{ product.tripProp }}</b></i>
                <p>{{product.stayCnt}}</p>
              </td>
              <td v-else-if="product.tripProp === 'F'" class="text-warning"><i><b>{{ product.tripProp }}</b></i></td>
              <td v-else></td>

              <td>{{ product.dptCtName }}</td>
              <td>{{ product.pdtNameKor }}</td>
              <td>{{ product.ntCd }}</td>
              <td v-if="product.transFlag === '0'" class="text-danger">Y</td>
              <td v-else></td>
              <td class="text-success">{{ product.addOptFlag }}</td>
              <td class="text-warning">{{ product.etcOptFlag }}</td>
              <td>
                <div>
                  <small>
                    {{ getCurrencyFormat(product.maxPrice)}} ~ {{ getCurrencyFormat(product.minPrice)}}
                  </small>
                </div>
                <!-- <div class="text-primary" v-if="product.sFlag_g === 'Y'">{{ getCurrencyFormat(product.nominalAmt) }}</div>
                <div class="text-primary text-decoration-line-through" v-else>{{ getCurrencyFormat(product.nominalAmt) }}</div>

                <div class="text-danger" v-if="product.sFlag_t === 'Y'">{{ getCurrencyFormat(product.nominalAmt_t) }}</div>
                <div class="text-danger text-decoration-line-through" v-else>{{ getCurrencyFormat(product.nominalAmt_t) }}</div>
                
                <div class="text-success" v-if="product.sFlag_g === 'Y'">{{ getCurrencyFormat(product.nominalAmt_p) }}</div>
                <div class="text-success text-decoration-line-through" v-else>{{ getCurrencyFormat(product.nominalAmt_p) }}</div> -->
                <br>
                <div :key=i v-for="(seqPromo,i) in pdtPromoInfo">
                  <div v-if="pdtPromoInfo[i].pdtCode === product.pCode">
                    <div class="text-primary" v-if = "pdtPromoInfo[i].promoMethod === 'P'">
                      ({{getCurrencyFormat(this.pdtPromoInfo[i].promoRate)}}%)
                    </div>
                    <div class="text-primary" v-else-if = "pdtPromoInfo[i].promoMethod === 'A'">
                      ({{getCurrencyFormat(this.pdtPromoInfo[i].promoRate)}}원)
                    </div>
                  </div>
                </div>
              </td>
              <td> 
                <!-- 판매기간과 설정이 모두 만족해야 판매중 -->
                <small>{{ product.saleStatus }}</small>
                <br>
                <font class="text-danger">{{ product.curStatus }}</font>
              </td>
              <td>
                <small>
                  {{ getDateFormat(product.salesStart) }} ~ {{ getDateFormat(product.salesEnd) }}
                </small>
              </td>
              <td>
                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="goToPdtTrans(product.pCode, product.salesRoute);" v-if="product.salesRoute === 'T' || product.salesRoute === 'L'">
                  <font style="font-size:small">교통</font>
                </button>

                <button type="button" class="btn btn-outline-success btn-sm me-1" @click="goToPrcTrans(product.pCode, product.salesRoute);">
                  <font style="font-size:small">가격</font>
                </button>
                <!-- <button type="button" class="btn btn-outline-primary btn-xs me-1" @click="goToImgInsert(product.pdtCode);">
                  <font style="font-size:small">옵션</font></button> -->

                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="goToImgInsert(product.pCode);">
                  <font style="font-size:small">사진</font>
                </button>

                <button type="button" class="btn btn-outline-success btn-sm me-1" @click="changeStatus(product.saleStatus, product.pCode)">
                  <font style="font-size:small">상태</font>
                </button>
              </td>
              <td>
                <button type="button" class="btn btn-outline-success btn-sm me-1" @click="goToInfo(product.pCode, product.salesRoute, product.ntCd);">
                  <font style="font-size:small">수정</font>
                </button>
                
                <!-- <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="goToCopy(product.pCode)">
                  <font style="font-size:small">복사</font>
                </button> -->

                <button type="button" class="btn btn-warning btn-sm me-1" @click="changeChoiceFlag(product.mdChoice, product.pCode)" v-if="product.mdChoice === 'Y'">
                  <font style="font-size:small">추천</font>
                </button>
                <button type="button" class="btn btn-outline-warning btn-sm me-1" @click="changeChoiceFlag(product.mdChoice, product.pCode)" v-else>
                  <font style="font-size:small">추천</font>
                </button>

                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="previewProduct(product.pCode)">
                  <font style="font-size:small">보기</font>
                </button>                
                <!-- <button type="button" class="btn btn-warning btn-sm me-1" @click="changeAuotSales(product.autoSales, product.pCode)" v-if="product.autoSales === 'Y'">
                  <font style="font-size:small">자판</font>
                </button>
                <button type="button" class="btn btn-outline-warning btn-sm me-1" @click="changeAuotSales(product.autoSales, product.pCode)" v-else>
                  <font style="font-size:small">자판</font></button> -->
              </td>
              <td class="col-1 text-sm-start text-truncate" style="max-width: 35px"> 
                <!-- 상품소개 -->
                <small>{{ product.pdtRemarks }}</small>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- pagenation area -->
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" aria-label="Previous" @click="paging('p')">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item" :key="j" v-for="j in this.cntPage">
              <a class="page-link" @click="paging(pg = j)">{{j}}</a>
            </li>
            <li class="page-item">
              <a class="page-link" aria-label="Next" @click="paging('n')">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>

      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      productMaList: [],
      productMaList_tmp: {},
      cntProdList: 0,
      pageSize: 12, //페이지당 데이타수
      page: 1, //offset기준
      cntPage: 1, //페이지네이션 표시페이지수
      pdtPromoInfo: {} //프로모션 정보
    };
  },
  created() {
    this.productId = this.$route.query.productId;
    // 데이타베이스 쿼링테스트
    this.getSalesList("all");
    this.getPdtPrmomoInfo();
    //this.getProductPrice();
    console.log("AccessInfo:",this.$store.state.user);
    //this.getProductPromotionPrice();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    async getSalesList(pNum) {
      // this.productMaList = await this.$api("/api/salesList", {param:["%", "%"]});
      // console.log("salesList:",this.productMaList);
      //alert(pNum);
      this.productMaList_tmp = {};
      if(pNum === "all"){
        this.productMaList_tmp = await this.$api("/api/salesListMix", {});
      }
      else if(pNum === "T" || pNum === "L" || pNum === "H" || pNum === "E" || pNum === "S"){
        this.productMaList_tmp = await this.$api("/api/salesListMix1", {param:[pNum, "%"]});
      }
      else if(pNum === "Y" || pNum === "N"){
        this.productMaList_tmp = await this.$api("/api/salesListMix1", {param:["%", pNum]});
      }
      else {
        this.productMaList_tmp = await this.$api("/api/salesListMix", {});
      }
      this.productMaList = this.productMaList_tmp
      this.cntProdList = this.productMaList_tmp.length;
      this.cntPage = Math.ceil(this.cntProdList / this.pageSize); //올림처리
      
      this.productMaList = this.productMaList_tmp.slice(0, this.pageSize); //시작idx, 지정번째idx-1
      if(this.productMaList.length < 1) {
        this.$swal.fire('', '데이타가 없습니다', 'info');
        return false;
      }
      console.log("productMaList:",this.productMaList);
    },
    paging(val){
        this.cntProdList = this.productMaList_tmp.length;
        // console.log("productMaList_tmp",this.productMaList_tmp);
        // console.log("cntProdList:",this.cntProdList);
        // console.log("pNum:",val);

        this.cntPage = Math.ceil(this.cntProdList / this.pageSize); //올림처리
        //console.log("cntPage:",this.cntPage);

        let offSet = 0;
        console.log("page:",this.page);

        //페이지 초기
        if(val == undefined) {
          this.page = 1;
        }
        else {
          if(val === "p") {
            this.page--;
            if(this.page < 1) {
              this.page = 1;
              this.$swal.fire('', '시작페이지입니다', 'info')
            }
          }
          else if(val === "n") {
            this.page++;
            if(this.page > this.cntPage) {
              this.page = this.cntPage;
              this.$swal.fire('', '마지막페이지입니다', 'info')
            }
          }
          else{
            this.page = val;
          }
        }
        offSet = (this.page-1)*this.pageSize;
        this.productMaList = this.productMaList_tmp.slice(offSet, offSet+this.pageSize); //시작idx, 지정번째idx-1

        if(this.productMaList.length < 1) {
          this.$swal.fire('', '데이타가 없습니다', 'info');
          return false;
        }
    },
    async changeCondition() {
      this.getSalesList(this.i);
    },
    goToInsert() {
      this.$router.push({path: '/create'});
    },
    goToDetail(pdtCode) {
      //console.log(pdtCode);
      this.$router.push({path: '/detail', query: {productId: pdtCode}});
    },
    previewProduct(pdtCode) 
    {
      // 상품미리보기
      this.$router.push({path: '/productDetail_preview', query: {productId: pdtCode}});
    },    
    //상품 복사기능
    goToCopy() {
      //this.$router.push({path: '/create'});
      this.$swal.fire('', '개발 중 기능입니다.', 'info')
    },
    // mixins에 있는 공통함수 호출샘플
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    goToPdtTrans(pdtCode, salesRoute) {
      this.$router.push({path: '/productTransInsert', query: {productId: pdtCode, salesRoute: salesRoute}});
    },
    goToPrcTrans(pdtCode, salesRoute) {
      this.$router.push({path: '/productPriceInsertMix', query: {productId: pdtCode, salesRoute: salesRoute}});
    },

    goToImgInsert(pdtCode) {
      // 이미지 저장패스
      this.$router.push({path: '/image_insert', query: {productId: pdtCode}});
    },
    goToInfo(pdtCode, salesRoute, ntCd) {
      // 이미지 저장패스
      this.$router.push({path: '/productInfoMix', query: {productId: pdtCode, salesRoute: salesRoute, ntCd: ntCd}});
    },
    // getProductPrice() {
    //   let productPrice = this.$api("/api/productPrice", {param: [this.productId]});
    //   // console.log(typeof(productPrice));
    //   console.log("productPrice",productPrice);
    // },
    //상품별 프로모션 정보
    async getPdtPrmomoInfo() {
      let pdtPromoInfo = await this.$api("/api/pdtPromotionInfo_mix", {});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo;
      }
      //console.log("pdtPromoInfo_mix",this.pdtPromoInfo);
    },
    changeStatus(saleStatus, pdtCode) {
      // 변경전 확인
      this.$swal.fire({
        text: '판매상태(판매<->중지)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
        icon: 'question'
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let sStatus = (saleStatus == "Y") ? "N" : "Y"
          // console.log(sStatus, pdtCode);
          await this.$api("/api/changePdtSalesStatus_mix", {param: [sStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('변경완료!', '', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    changeChoiceFlag(mdChoice, pdtCode) {
      // 변경전 확인
      this.$swal.fire({
        title: '추천상태(추천Yes<->추천No)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let cStatus = (mdChoice === "Y") ? "N" : "Y"
          console.log("param:",cStatus, pdtCode);
          await this.$api("/api/changePdtChoiceStatus_mix", {param: [cStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('', '변경완료', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },        
    //이미 결합상품
    // changeMixProduct(pdtCode) {
    //   // 변경전 확인
    //   this.$swal.fire({
    //     title: '결합상품 생성전환?',
    //     showCancelButton: true,
    //     confirmButtonText: '전환',
    //     cancelButtonText: '취소',
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       // alert(pdtCode);
    //       // this.$router.push({path: '/salesMix', query: {}});
    //       let resIns_product_mix = await this.$api("/api/makeMixProduct", {param: [pdtCode]});
    //         if(Object.keys(resIns_product_mix)[0] === 'error') {
    //           this.$swal.fire('', '결합상품 기본정보 생성오류<br>관리자 문의요망', 'alert')
    //         }
    //         else {
    //           let resIns_product_mix_img = await this.$api("/api/productTmpImgInsert",{param: "MX-"+pdtCode});
    //           if(Object.keys(resIns_product_mix_img)[0] === 'error') {
    //             this.$swal.fire('', '결합상품 기초이미지 생성오류<br>관리자 문의요망', 'alert')
    //           }
    //           else {
    //             let resUpd_product_mix_code = await this.$api("/api/updateMixPdtCode", {param: [this.user.handlerId,pdtCode]});
    //             if(resUpd_product_mix_code.affectedRows < 0 || resUpd_product_mix_code.affectedRows === undefined){
    //               this.$swal.fire('', '결합상품 생성오류<br>관리자 문의요망', 'alert')
    //             }
    //             else {
    //               //this.getSalesList("all");
    //               this.$swal.fire('생성완료!', '', 'success')
    //               this.$router.push({path: '/salesMix', query: {}});
    //             }
    //           }
    //         }
    //     }
    //   })
    // },
    changeAuotSales(autoSales, pdtCode) {
      // 변경전 확인
      this.$swal.fire({
        text: '컨펌없는 자동판매(실행<->중지)상태',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
        icon: 'question'
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let aStatus = (autoSales == "Y") ? "N" : "Y"
          // console.log(sStatus, pdtCode);
          await this.$api("/api/changeAutoSalesStatus_mix", {param: [aStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('변경완료!', '', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  }
}
</script>