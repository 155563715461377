<template>
  <div id="infoc">
    <section>
      <div class="container my-3">
        <div class="row justify-content-center">
          <h3>회원정보 수정</h3>

          <!-- 기본 정보 pc-->
          <div class="row basic pc">
            <div class="container">
              <div class="title text-start">
                <p>기본정보<font class="text-secondary fs-6"> (필수, 간편인증 가입회원은 수정불가)</font></p>
              </div>
              
              <div class="text-start">
                <table class="table">
                  <tbody>
                    <tr>
                      <th class="ta-name col-2 text-center"><span>*</span>아이디(변경불가)</th>
                      <td class="col-4">
                        <input type="email" class="form-control" placeholder="name@example.com" v-model="this.userInfo.uEmail" disabled>
                      </td>
                      <th class="ta-name col-2 text-center"><span>*</span>비밀번호</th>
                      <td class="col-4">
                        <input type="password" class="form-control" id="inputPassword" v-model="this.userInfo.uPass" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="uPass">
                        <input type="password" class="form-control" id="inputPassword" disabled v-else>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center"><span>*</span>한국명</th>
                      <td>
                        <input type="text" class="form-control" placeholder="한글이름" v-model="this.userInfo.uNickName" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="uNickName">
                        <input type="text" class="form-control" placeholder="한글이름" v-model="this.userInfo.uNickName" disabled v-else>
                      </td>
                      <th class="ta-name text-center"><span>*</span>연락처(휴대전화)</th>
                      <td>
                        <input type="text" class="form-control" placeholder="휴대폰번호" v-model="this.userInfo.alimCallC" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="alimCallC">
                        <input type="text" class="form-control" placeholder="휴대폰번호" v-model="this.userInfo.alimCallC" disabled v-else>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p class="out"><a href="javascript:void(0)" @click="memWithdrawal(this.userInfo.uEmail)">회원탈퇴하기</a></p>
            </div>
          </div>

          <!-- 기본 정보 모바일 -->
          <div class="row basic mobile">
            <div class="container">
              <div class="title text-start">
                <p class="mb-0">기본정보<font class="text-secondary fs-6"> (필수)</font></p>
                <div class="text-secondary" style="font-size: small; font-weight: normal;"> ※ 카톡과 네이버 인증을 통한 간편인증은 수정불가</div>
              </div>
              
              <div class="text-start">
                <table class="table">
                  <tbody>
                    <tr class="text-center">
                      <th class="ta-name col-4 text-center">
                        <span>*</span>아이디
                        <div>(변경불가)</div>
                      </th>
                      <td class="col-8">
                        <input type="email" class="form-control" placeholder="name@example.com" v-model="this.userInfo.uEmail" disabled>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name col-2 text-center"><span>*</span>비밀번호</th>
                      <td class="col-4">
                        <input type="password" class="form-control" id="inputPassword" v-model="this.userInfo.uPass" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="uPass">
                        <input type="password" class="form-control" id="inputPassword" disabled v-else>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center"><span>*</span>한국명</th>
                      <td>
                        <input type="text" class="form-control" placeholder="한글이름" v-model="this.userInfo.uNickName" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="uNickName">
                        <input type="text" class="form-control" placeholder="한글이름" v-model="this.userInfo.uNickName" disabled v-else>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">
                        <span>*</span>연락처
                        <div>(휴대전화)</div>
                      </th>
                      <td>
                        <input type="text" class="form-control" placeholder="휴대폰번호" v-model="this.userInfo.alimCallC" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="alimCallC">
                        <input type="text" class="form-control" placeholder="휴대폰번호" v-model="this.userInfo.alimCallC" disabled v-else>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p class="out"><a href="javascript:void(0)" @click="memWithdrawal(this.userInfo.uEmail)">회원탈퇴하기</a></p>
            </div>
          </div>


          <!-- 여권 정보 -->
          <div class="row passport">
            <div class="container">
              <div class="title text-start">
                <p>여권정보<font class="text-secondary fs-6"> (선택)</font></p>
              </div>
              
              <div class="text-start">
                <table class="table">
                  <tbody>
                    <tr>
                      <th class="ta-name col-3 col-lg-3 text-center">영문명(여권)</th>
                      <td class="col-10 col-lg-9">
                        <input type="text" class="form-control" placeholder="영문명" v-model="this.userInfo.engName">
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">여권번호</th>
                      <td>
                        <input type="text" class="form-control" placeholder="여권번호" v-model="this.userInfo.uPassport">
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">만료기간</th>
                      <td>
                        <input type="date" class="form-control" placeholder="YYYY-mm-DD" v-model="this.userInfo.uPassportExp">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
          <!-- 마케팅 수신 -->
          <div class="row marketing">
            <div class="container">
              <div class="title text-start">
                <p>마케팅 수신 동의</p>
              </div>
              
              <div class="text-start">
                <table class="table">
                  <tbody>
                    <tr>
                      <th class="ta-name col-4 col-lg-3">(필수) 개인정보 수집 및 이용 동의</th>
                      <td class="col-8 col-lg-9">
                        <p class="check">
                          <input class="form-check-input" type="checkbox" id="fAgree" v-model="confirm_Privacy" ref="confirm_Privacy" true-value="Y" false-value="N">
                          <label class="form-check-label" for="fAgree">
                            동의
                          </label>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name">(선택) 마케팅 정보 수신 동의</th>
                      <td>
                        <p class="check">
                          <input class="form-check-input" type="checkbox" id="sAgree" v-model="confirm_Marketing" ref="confirm_Marketing" true-value="Y" false-value="N">
                          <label class="form-check-label" for="sAgree">
                            동의
                          </label>
                        </p>
                        <!-- <p class="check">
                          <input class="form-check-input" type="checkbox" id="email" v-model="confirm_Privacy" ref="confirm_Privacy" true-value="Y" false-value="N">
                          <label class="form-check-label" for="email">
                            이메일
                          </label>
                        </p>
                        <p class="check">
                          <input class="form-check-input" type="checkbox" id="sms" v-model="confirm_Privacy" ref="confirm_Privacy" true-value="Y" false-value="N">
                          <label class="form-check-label" for="sms">
                            SMS
                          </label>
                        </p> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- 버튼 -->
          <div class="mt-5 row justify-content-center">
            <button class="btn cxl col-5 col-lg-3 mx-2"  @click="goToMypage()">마이페이지</button>
            <button class="btn go col-5 col-lg-3 mx-2" @click="updateMyInfo();">수정</button>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      userInfo: [],               //유저정보
      confirm_Privacy: "",        //개인정보동의
      alimCallC: "",              //유저연락처

      reqFlag: "R", //문의플래그
      //포인트관련

      resUserPoint: [],
      availPoint: 0, // 사용자 가용포인트

      rProc: [], //프로세스레벨
    };
  },
  created(){
    console.log("user:",this.$store.state.user);
    
    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")
    //console.log("today:",this.today)

    //회원정보
    this.getUserInfo();

    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.userInfo.joinFlag == 'H')
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.phone.replace("+82 ", "0")
      }
      this.k_tel = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }
    console.log("k_name:",this.k_name, "k_tel:",this.k_tel)
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },  
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   this.$router.push({path: '/'});
    // }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
  },  
  methods: {
    // 숫자 3자리마다 콤마 찍기
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    goToMypage() {
      this.$router.push({path: '/myPage'});
    },

    async updateMyInfo(){
      if(!this.userInfo.uEmail)
      {
        this.$refs.uEmail.focus()
        this.$swal.fire("", "이메일아이디를 입력하세요", "warning")
        return false
      }

      //간편인증가입자는 패스체크 제외
      if(this.user.joinFlag === 'E' || this.userInfo.joinFlag == 'H')
      {
        if(!this.userInfo.uPass)
        {
          this.$refs.uPass.focus()
          this.$swal.fire("", "패스워드를 입력하세요", "warning")
          return false
        }
        else
        {
          if (this.userInfo.uPass.length < 5) 
          {
            this.$refs.uPass.select()
            this.$swal.fire("","비밀번호는 5자리 이상이어야 합니다","warning")
            return false;
          }
          else
          {
            // 영문자와 숫자를 포함하고 있는지 체크
            let hasLetter = /[a-zA-Z]/.test(this.userInfo.uPass);
            let hasNumber = /\d/.test(this.userInfo.uPass);

            if(!hasLetter || !hasNumber)
            {
              this.$refs.uPass.select()
              this.$swal.fire("","비밀번호는 숫자와 문자로 구성되어야 합니다.","warning")
              return false;
            }
          }
        }
      }

      if(!this.userInfo.uNickName)
      {
        this.$refs.uNickName.focus()
        this.$swal.fire("", "한국명을 입력하세요", "warning")
        return false
      }

      if(this.user.joinFlag === 'E' || this.userInfo.joinFlag == 'H')
      {
        if(!this.userInfo.alimCallC)
        {
          this.$refs.alimCallC.focus()
          this.$swal.fire("", "연락처를 입력하세요", "warning")
          return false
        }
        else
        {
          let checkResult = this.checkMobile(this.userInfo.alimCallC)      //mobile number 유효체크
          if(!checkResult)
          {
            this.$refs.alimCallC.select()
            this.$swal.fire("","유효한 전화번호가 아닙니다.","warning")
            return false
          }
        }
      }      


      if(!this.confirm_Privacy || this.confirm_Privacy == 'N')
      {
        this.$refs.confirm_Privacy.focus()
        this.$swal.fire("","개인정보 수집 및 이용에 동의해 주세요","warning")
        return false
      }

      if(this.userInfo.uPassportExp == "")
      {
        this.userInfo.uPassportExp = null
      }

      let upd_MyInfo = await this.$api("/api/upd_MyInfo", {param: [
        this.userInfo.alimCallC, this.userInfo.alimCallC, this.userInfo.uPass, this.userInfo.engName, this.userInfo.uPassport, this.userInfo.uPassportExp,
        this.userInfo.uEmail
      ]})
      if(upd_MyInfo.affectedRows >= 0)
      {
        this.$swal.fire("","마이정보가 업데이트 되었습니다","info")
        location.reload(true)
      }
      else
      {
        this.$swal.fire("","업데이트 중 오류가 발생했습니다.<br>다시 진행해 주세요.","warning")
        return false;
      }

    },
    checkMobile(val){
      const koreanMobileRegex = /^01[0]\d{3,4}\d{4}$/;
      return koreanMobileRegex.test(val);
    },

    memWithdrawal(mId){
      this.$swal.fire({
        title: "회원탈퇴를 진행하시겠습니까?",
        html: "※ 탈퇴와 동시에 포인트, 멤버십 정보는 즉시 삭제됩니다.<br>회원복구는 <b class='text-danger'>불가능</b>한 점 양지하여 주십시오.",
        showCancelButton: true,
        confirmButtonText: "탈퇴합니다",
        cancelButtonText: "취소합니다",
        confirmButtonColor: "darkblue"
      }).then(async (result)=>{
        if(result.isConfirmed)
        {
          let memberWithdrawal = await this.$api("/api/proc_memberWithdrawal", {param: [mId]})
          if(memberWithdrawal.affectedRows >= 0)
          {
            this.toB2CLogout()
            this.sendKakao("WT")
          }
          else
          {
            this.$swal.fire('','회원탈퇴 중 오류가 발생했습니다('+ memberWithdrawal.error.sqlMessage +')', 'error')
            return false;
          }
        }
      })
    },

    /** 카카오알림 발송 */
    async sendKakao(param)
    {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;
      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;

      let curDate = yyyy + "-" + mm + "-" + dd      

      let kakaoAlimData = {}
      kakaoAlimData = 
      {
        clientName: this.userInfo.uNickName,
        memberName: this.userInfo.uNickName,
        memberId: this.userInfo.uEmail,
        withdrwalDate: curDate,
        resPhone: this.userInfo.alimCallC,
        resCode: this.userInfo.uEmail,
        clientCall: this.userInfo.alimCallC,            //알림톡발송번호
        alimFlag: param //알림유형(결제요청)
      }

      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.userInfo.alimCallC, param, this.userInfo.uEmail
      ]});
      if(insKSendHistory.affectedRows > 0) {
        if(param === "WT") 
        {
          //kakaoAlimData
          this.$api("api/kakaoAlimSend5", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});

          this.$swal.fire("","회원탈퇴와 회원정보삭제가 완료되었습니다.<br>그 동안 감사했습니다.","info")
          this.$router.push({path:'/'})          
        }
      }
    },

    toB2CLogout() {
      window.Kakao.Auth.logout((response) => {
        this.$store.commit("user", {});       // 로그아웃->카카오유저 정보를 빈값으로 대체
        
        window.sessionStorage.clear();        // 로그아웃->세션스토리지를 모두 삭제
        window.localStorage.clear();
        console.log(response);
        
        //this.$router.push({ path: "/" });    // 로그아웃->메인페이지로
        location.href = "/"
      });
    },

    async changeAlimCall()
    {
      const { value: alim } = await this.$swal.fire({
          html: '알림을 받을 연락처를 변경합니다',
          input: 'text',
          //inputLabel: '뱃지표시',
          inputPlaceholder: '(예시: 000-1111-2222)'
        })

        //console.log("alim:",alim)
      let result = this.validatePhoneNumber(alim)
      if(!result)
      {
        this.$swal.fire('', '유효하지 않은 연락처 번호입니다.', 'warning')
        return false;
      }
      else
      {
        if (alim) 
        {
          this.$swal.fire('',`변경된 연락처: ${alim}`)
          let changeAlimNo = await this.$api("/api/changeAlimNo", {param: [alim, this.user.email]});
          if(changeAlimNo.affectedRows < 0 || changeAlimNo.affectedRows === undefined)
          {
            this.$swal.fire('', '변경 중 오류가 발생했습니다(코드:changeAlimNo)', 'alert')
          }
          else
          {
            this.$swal.fire('', '변경되었습니다. 감사합니다.', 'success')
            location.reload(true)
          }
        }
      }
    },
    //입력된 연락처 검증
    validatePhoneNumber(no) 
    {
      const regex = /^\d{3}-\d{3,4}-\d{4}$/;
      return regex.test(no);
    },

    //About point
    async changeCondition(con) {
      //alert("con:"+con)
      this.getUserPoint(con);
    },

    //about UserInfo
    async getUserInfo() 
    {
      let userInfo = await this.$api("/api/checkMemEmail", {param: [this.user.email]});
      if(userInfo.length > 0) 
      {
        this.userInfo = userInfo[0];
        if(userInfo[0].uPassportExp > '1800-01-01')
        {
          this.userInfo.uPassportExp = this.getDateFormat(this.userInfo.uPassportExp)
        }
      }

      // let alimCallC_tmp = ""
      // if(this.userInfo.alimCallC != undefined && this.userInfo.alimCallC.charAt(0) === '+')
      // {
      //   //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
      //   alimCallC_tmp = this.userInfo.alimCallC.replace("+82 ", "0")
      // }
      // this.alimCallC = (!this.userInfo.alimCallC) ? "" : alimCallC_tmp.replace(/-/g,""); //-제거       
      console.log("userInfo:", this.userInfo)
    },

    goToHome() {
      this.$router.push({path: '/'});
    }
  }
}
</script>



<style>
#infoc{
  margin:4rem 0 8rem;
}
@media (max-width:992px){
  #infoc{
    margin:0rem 0 4rem;
  }
}

#infoc h3{
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:5rem;
  text-transform: uppercase;
  text-align: center;
}



/* -------------------------------------------------
  # 내용 공통부분
--------------------------------------------------- */
#infoc .pc{
  display: block;
}
#infoc .mobile{
  display: none;
}


#infoc .title{
  border-bottom: 2px solid #000;
}
#infoc .title p{
  font-size:1.4rem;
  color:#000;
}
#infoc .table{
  vertical-align: middle;
}
#infoc .table tr{
  font-size:.9rem;
  height:5rem;
}
#infoc .table .ta-name{
  background-color:#f8f8f8;
  color:#000;
  font-weight: 600;
  text-align: left;
  padding-left: 1rem;
}
#infoc .table .ta-name span{
  color:#f00;
  padding-right:3px
}

#infoc .table .form-control{
  border-radius: 0;
  padding:10px
}

@media (max-width:991px){
  #infoc .pc{
    display: none;
  }
  #infoc .mobile{
    display: block;
  }
  #infoc .table .ta-name{
    font-size:.8rem
  }
}



/* -------------------------------------------------
  # 기본 정보
--------------------------------------------------- */
#infoc .basic .form-control{
  width:80%
}
#infoc .basic .out{
  text-align: right;
  font-size:.8rem
}
#infoc .basic .out a{
  color:#aaa
}
#infoc .basic .out:hover a{
  color:#333
}
@media (max-width:991px){
  #infoc .basic .form-control{
    width:100%
  }
}



/* -------------------------------------------------
  # 여권 정보
--------------------------------------------------- */
#infoc .passport{
  margin:4rem 0 5rem
}
#infoc .passport .form-control{
  width:40%
}
@media (max-width:991px){
  #infoc .passport .form-control{
    width:100%
  }
}



/* -------------------------------------------------
  # 마케팅 수신
--------------------------------------------------- */
#infoc .marketing .check{
  display: inline-block;
  margin-bottom:0;
  padding:0 30px;
  font-size:1rem;
}
#infoc .marketing .check label{
  padding-left:10px
}
#infoc .marketing .check .form-check-input[type=checkbox]{
  border-radius: 0;
}



/* -------------------------------------------------
  # 버튼
--------------------------------------------------- */
#infoc .cxl,
#infoc .go{
  --bs-btn-padding-y: 0;
  margin:5rem 0;
  border-radius: 0;
  font-size: 1.15rem;
  transition: all .5s;
}
#infoc .cxl{
  background-color: #f3f3f3;
}
#infoc .cxl:hover{
  border:1px solid #bbb
}
#infoc .go{
  background-color: #000;
  color:#fff;
  padding:15px 5px;
}
#infoc .go:hover{
  background-color: #333;
}
</style>
