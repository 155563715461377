<template>
  <main class="mt-3">
    <div class="container reviewc">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <h2 class="d-flex justify-content-center" style="font-weight:600">후 기 등 록</h2>
        </div>

        <div class="row justify-content-center rcont">
          <div class="mb-3 row">
            <label class="col-md-3 col-form-label rtit">후기제목<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class="input-group">
                <input type="text" class="form-control w-75" ref="txt_nTitle" placeholder="여백포함 20자 이내" 
                  maxlength="20" v-model="this.nTitle">
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-md-3 col-form-label rtit">후기내용<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="여백포함 200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:150px" maxlength="200" v-model="this.nContent"></textarea>
            </div>
          </div>
          <div class="mb-3 text-end" style="padding-right: 1rem;">
            <button type="button" class="btn btn-sm btn-success" @click="addImage();">
              <i class="bi bi-card-image"></i> 이미지첨부
            </button>
          </div>

          <div class="mb-3 row" v-if="this.displayFlag === 'Y'">

            <label class="col-md-3 col-form-label">리뷰이미지</label>
            <div class="col-md-9">
              <div class="form-control" type="file" accept="image/png, image/jpg, image/jpeg" :key="i" v-for="(img,i) in reviewImage.filter(c=>c.catImg=='T')">
                <div class="position-relative">
                  <img :src="`/download/review/${this.revCode}/${img.imgName}`" class="img-fluid" />
                </div>
              </div>
              <input type="file" class="form-control" accept="image/png,image/jpeg,image/jpg" id="fileSelector">
              <div class="alert alert-secondary text-start" role="alert" style="font-size:small;">
                <ul>
                  <li>등록가능: 1개까지</li>
                  <li>File 사이즈: 640kb 이하</li>
                  <li>File 확장자: jpg</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3 row justify-content-center">
          <div class="col-4 d-grid p-1">
            <button type="button" class="btn go" @click="noticeInsert();">저장</button>
          </div>
        </div>

      </div>
    </div>
  </main>

</template>

<script>
//import moment from 'moment';
//import ResizeImage from 'image-resize'

export default {
  data() {
    return {
      revCode: "",
      nTitle: "",
      nContent: "",
      nUseFlag: "", //리뷰사용여부 기본값은 Y
      insDate: "",
      insId: "",
      pdtCode: "", //상품코드
      addImageFlag: "N", //  이미지첨부 호출여부
      rsvCode: "", //예약번호      
      displayFlag: "N",
      //fileList: "" //선택된 이미지파일
      reviewImage: [], //리뷰이미지

      resizedFile: {}
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() 
  {
    //console.log("USER:",this.user);
    this.rsvNo = this.$route.query.rsvNo;
    this.pCode = this.$route.query.pCode;
    this.rDate = this.$route.query.tEndDate;
    this.ctCode = this.$route.query.cCode

    //console.log(this.rsvNo,"//",this.pCode,"//",this.rDate, "//", this.ctCode)
    this.getReviewImage();
  },
  mounted() {
    if(document.referrer == "") 
    {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) 
    {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    async getReviewImage() {
      let reviewImage = await this.$api("/api/imageList_review", {param: [this.revCode]});
      if(reviewImage.length > 0)
      {
        this.reviewImage = reviewImage
      }
    },
    addImage()
    {
      //this.review.addImageFlag = (this.review.addImageFlag === "N") ? "Y" : "N";
      this.displayFlag = (this.displayFlag === "N") ? "Y" : "N";
    },    
    async noticeInsert() 
    {
      //오늘날짜추출
      // const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      // const time = new Date().toTimeString().split(" ")[0];
      // let today = date + ' ' + time;
      let tStamp = "";
      tStamp = new Date().getTime();

      let reviewCode =   "RV-" + tStamp;
      //console.log("today:", date + ' ' + time);
      //console.log("reviewCode:", reviewCode);

      if(!this.nTitle) {
        this.$refs.txt_nTitle.focus();
        //return this.$swal.fire("","제목을 입력하세요.");
        this.$swal.fire({
          position: 'top-end',
          // icon: 'warning',
          html: '<h6 class="text-danger">제목을 입력해 주세요.</h6>',
          width: 'auto',
          height:'auto',
          toast: true,
          showConfirmButton: false,
          timer: 1000
        })
        return false;
      }
      if(!this.nContent) {
        this.$refs.txt_nContent.focus();
        this.$swal.fire({
          position: 'top-end',
          // icon: 'warning',
          html: '<h6 class="text-danger">내용을 입력해 주세요.</h6>',
          width: 'auto',
          height:'auto',
          toast: true,
          showConfirmButton: false,
          timer: 1000
        })
        return false;
      }
      //this.review.insDate = today;
      this.revCode = reviewCode;
      this.insId = this.user.email;
      this.nUseFlag = "Y";
      this.addImageFlag = this.displayFlag;
      
      let pCode = ""
      if(this.ctCode === "N")               //엔버스는 상품코드를 엔버스코드로 처리
      {
        pCode = this.rsvNo
      }
      else
      {
        pCode = this.pCode
      }

      let resIns_review = await this.$api("/api/reviewInsert", {param: [
        this.revCode,this.nTitle,this.nContent,this.nUseFlag,this.insId,pCode,this.addImageFlag,this.rsvNo
        ]});
      if(Object.keys(resIns_review)[0] === 'error') {
        this.$swal.fire('','리뷰저장에 오류(코드: resIns_review)가 발생했습니다.', 'error')
      }
      else {
        let imgFile = document.getElementById('fileSelector').files[0];

        // //이미지 640kb 초과시 640KB로 축소업로드
        const maxFileSize = 640*1024

        if (imgFile.size > maxFileSize) 
        { // 파일 크기 확인
          this.resizeImage(imgFile)
          .then((resizedImg) => {
            //console.log("resizeImg:",resizedImg);
            let resizedImageFile = new File([resizedImg], imgFile.name)
            this.uploadFile(resizedImageFile,"T","Y")
            //this.$swal.fire('','이미지 크기가 640KB이하로 저장됩니다.', '');
            this.$swal.fire({
              position: 'top-end',
              icon: 'success',
              html: '<h6 class="text-danger">이미지가 저장되었습니다</h6>',
              width: 'auto',
              height:'auto',
              toast: true,
              showConfirmButton: false,
              timer: 1000
            })
            history.back();
          })
          .catch((err) => {
            console.error(err);
            this.$swal.fire('','이미지 저장 중 오류가 발생했습니다.', 'warning');
          })
        }
        else
        {
          this.uploadFile(imgFile, 'T', 'Y') //이미지파일, 카테고리, 사용여부
        }
      }
    },
    /** 이미지 크기조정 후 blob으로 리턴 */
    resizeImage(file) 
    {
      return new Promise((resolve, reject) => {
        // FileReader 객체를 사용하여 이미지 파일을 읽습니다.
        const reader = new FileReader();
        reader.onload = function (event) {
          const img = new Image();
          img.onload = function () {
            // 이미지 해상도를 조정합니다.
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
            const maxFileSize = 640 * 1024;
            const maxSize = Math.sqrt(maxFileSize);

            if (width > maxSize || height > maxSize) {
              if (width > height) {
                height *= maxSize / width;
                width = maxSize;
              } else {
                width *= maxSize / height;
                height = maxSize;
              }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            // Canvas 객체에서 Blob 객체를 생성합니다.
            canvas.toBlob(
              (blob) => {
                resolve(blob);
              },
              'image/jpeg',
              0.9
            );
          };
          img.onerror = function (err) {
            reject(err);
          };
          img.src = event.target.result;
        };
        reader.onerror = function (err) {
          reject(err);
        };
        reader.readAsDataURL(file);
      });
    },

    async uploadFile(files, catImg, useFlag)
    {
      let fileLength = 0
      let fileType_tmp = "";
      let imgName = "";
      let data = null;
      //console.log("files:",files);
      if (files) {
        fileLength = files.length;
        imgName = files.name;
        fileType_tmp = files.name.lastIndexOf(".");
        this.fileType = files.name.substring(fileType_tmp+1, fileLength).toLowerCase();
        data = await this.$base64(files);
      }
      // console.log("files:",files, "name:",imgName, "catImg:",catImg, "pdtCode:",this.productId);
      //const { error } = await this.$api(`/upload/review/${this.revCode}/${imgName}/${useFlag}`, { data });
      const { error } = await this.$api(`/upload/review/${this.revCode}/${catImg}/${imgName}/${useFlag}/${this.user.email}`, { data });
      if (error) {
        return this.$swal("","이미지 저장에 실패했습니다","info");
      }

      this.$swal("","이미지가 업로드 되었습니다","");

      setTimeout(() => {
        this.getReviewImage();
      }, 1000);
    },
  }
}
</script>


<style>
.reviewc .rcont{
  margin-top:4rem
}

@media (max-width:767px){
  .reviewc .rtit{
    text-align: left;
  }
}


.reviewc .go {
  padding:15px 0; 
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.reviewc .go:hover{
  background-color: #444;
}

</style>
