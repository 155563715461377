<template>

  <div id="kyushuo">
    <!-- 메인 -->
    <div class="main">
      <div id="carouselOlleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="2500">
            <img src="../assets/img/KyushuOlle/fukuoka.png" class="vw-100 vh-100" alt="...">
          </div>
          <div class="carousel-item" data-bs-interval="2500">
            <img src="../assets/img/KyushuOlle/kurume.png" class="vw-100 vh-100" alt="...">
          </div>
          <!-- <div class="carousel-item" data-bs-interval="2500"> 
            <img src="../assets/img/KyushuOlle/munakata.png" class="vw-100 vh-100" alt="...">
          </div> -->
          <div class="carousel-item" data-bs-interval="2500">
            <img src="../assets/img/KyushuOlle/miyama.png" class="vw-100 vh-100" alt="...">
          </div>
          <div class="carousel-item" data-bs-interval="2500">
            <img src="../assets/img/KyushuOlle/yame.png" class="vw-100 vh-100" alt="...">
          </div>
          <div class="carousel-item" data-bs-interval="2500">
            <img src="../assets/img/KyushuOlle/chikuho.png" class="vw-100 vh-100" alt="...">
          </div>
        </div>
      </div>
    </div>

    <!-- 올레 설명 -->
    <section class="ollo-ex">
      <div class="container">
        <div class="row text-center justify-content-center">
          <span>후쿠오카 현내 5코스</span>
          <p class="tmain">규슈 올레 <img src="../assets/img/KyushuOlle/foot.png" class="img-fluid"></p>
          <div class="empty"></div>
          <div class="row justify-content-center">
            <p class="tsub">
              대한민국 제주도 올레길의 아름다움에 반해 2012년 오이타현 벳부 코스를 시작으로 
              일본 규슈 각 현에 벤치마킹하여 현재 총 18개 코스의 올레길을 걸을 수 있습니다.
              제주올레에서 그대로 가져온 간세(조랑말) 로고와 화살표, 리본을 따라 
              규슈 각 지역의 시골의 삶이 엿보이는 고즈넉한 마을, 푸르고 맑은 자연과 풍광,
              바다와 강과 산을 오감으로 느끼며 걸어 봅니다.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- 카테고리 PC -->
    <section class="cpc">
      <div class="cate">
        <ul class="nav nav-pills nav-justified">
          <li class="nav-item align-self-center">
            <a class="lhj tap1" data-tabnum="1" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab1' }" @click="bnChange('kurume2.jpg', 'tab1');">구루메・고라산 코스</a>
          </li>
          <li class="nav-item align-self-center">
            <a class="lhj tap2" data-tabnum="2" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab2' }" @click="bnChange('miyama2.jpg', 'tab2');">미야마・기요미즈야마 코스</a>
          </li>
          <li class="nav-item align-self-center">
            <a class="lhj tap3" data-tabnum="3" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab3' }" @click="bnChange('fukuoka2.jpg', 'tab3');">후쿠오카・신구 코스</a>
          </li>
          <li class="nav-item align-self-center">
            <a class="lhj tap4" data-tabnum="4" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab4' }" @click="bnChange('yame2.jpg', 'tab4');">야메 코스</a>
          </li>
          <li class="nav-item align-self-center">
            <a class="lhj tap5" data-tabnum="5" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab5' }" @click="bnChange('chikuho2.jpg', 'tab5');">지쿠호・가와라 코스</a>
          </li>
          <!-- <li class="nav-item align-self-center">
            <a class="nav-link tap6" href="javascript:void(0)" :class="{ 'active' : activeTab === 'tab6' }" @click="bnChange('munakata2.jpg', 'tab6');">무나카타・오시마 코스</a>
          </li> -->
        </ul>
      </div>
    </section>   

    <!-- 카테고리 Mobile -->
    <section class="cmobile">
      <div class="cate2">
        <ul class="nav nav-pills text-center">
          <li class="nav-item align-self-center">
            <a class="lhj tap1" data-tabnum="1" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab1' }" @click="bnChange('kurume2.jpg', 'tab1');">구루메・고라산 코스</a>
          </li>
          <li class="nav-item align-self-center">
            <a class="lhj tap2" data-tabnum="2" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab2' }" @click="bnChange('miyama2.jpg', 'tab2');">미야마・기요미즈야마 코스</a>
          </li>
          <li class="nav-item align-self-center">
            <a class="lhj tap3" data-tabnum="3" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab3' }" @click="bnChange('fukuoka2.jpg', 'tab3');">후쿠오카・신구 코스</a>
          </li>
          <li class="nav-item align-self-center">
            <a class="lhj tap4" data-tabnum="4" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab4' }" @click="bnChange('yame2.jpg', 'tab4');">야메 코스</a>
          </li>
          <li class="nav-item align-self-center">
            <a class="lhj tap5" data-tabnum="5" href="#kyushu-anchor" :class="{ 'active' : activeTab === 'tab5' }" @click="bnChange('chikuho2.jpg', 'tab5');">지쿠호・가와라 코스</a>
          </li>
          <!-- <li class="nav-item align-self-center">
            <a class="nav-link tap6" href="javascript:void(0)" :class="{ 'active' : activeTab === 'tab6' }" @click="bnChange('munakata2.jpg', 'tab6');">무나카타・오시마 코스</a>
          </li> -->
        </ul>
      </div>
    </section>

    <!-- 각 코스 -->
    <section class="in-cont">
      <div id="kyushu-anchor"></div>
      <div class="container">
        <div class="row justify-content-center tap1">
          <img :src=imgPath class="detail-img" id="imgPath">
        </div>
      </div>

      <!-- 예약 -->
      <div class="container resergo" id="bey1">
        <div class="row col-lg-3 mx-auto">
          <a href="javascript:void(0)" @click="goToDetail('L-202435-1709625994980','L')">
            <p class="go">예약하기</p>
          </a>
        </div>
      </div>
      <div class="container resergo" id="bey2">
        <div class="row col-lg-3 mx-auto">
          <a href="javascript:void(0)" @click="goToDetail('L-202435-1709628414626','L')">
            <p class="go">예약하기</p>
          </a>
        </div>
      </div>
      <div class="container resergo" id="bey3">
        <div class="row col-lg-3 mx-auto">
          <a href="javascript:void(0)" @click="goToDetail('L-202435-1709629045434','L')">
            <p class="go">예약하기</p>
          </a>
        </div>
      </div>
      <div class="container resergo" id="bey4">
        <div class="row col-lg-3 mx-auto">
          <a href="javascript:void(0)" @click="goToDetail('L-202435-1709629520506','L')">
            <p class="go">예약하기</p>
          </a>
        </div>
      </div>
      <div class="container resergo" id="bey5">
        <div class="row col-lg-3 mx-auto">
          <a href="javascript:void(0)" @click="goToDetail('L-202435-1709630061666','L')">
            <p class="go">예약하기</p>
          </a>
        </div>
      </div>
    
    </section>

    <!-- 꽃잎 -->
    <div class="flower">
      <img src="../assets/img/KyushuOlle/flower.png" class="img-fluid pc">
      <img src="../assets/img/KyushuOlle/m-flower.png" class="img-fluid mobile">
    </div>

  </div>
</template>

<script>
import defaultPath from '@/assets/img/KyushuOlle/kurume2.jpg';

export default {
  data() {
    return {
      imgPath: defaultPath,        //이미지 초기값
      activeTab: "",
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    bnChange(imageName, tab){
      this.imgPath = require(`@/assets/img/KyushuOlle/${imageName}`);
      this.activeTab = tab
    },
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  },

    
  
  created() {  
  },
  

  mounted() {
    document.getElementById("notice").style.display = "none";
    window.addEventListener('scroll', function() {
    var tabBasic = document.querySelector('.nav');
    var scrollPosition = window.scrollY || window.pageYOffset;
    var windowWidth = window.innerWidth;


    if (scrollPosition > 1800 && windowWidth >= 1921 && windowWidth <= 2560) {
    tabBasic.style.position = 'fixed';
    tabBasic.style.top = '0';
} else if (scrollPosition > 1500 && windowWidth >= 800 && windowWidth <= 1920) {
    tabBasic.style.position = 'fixed';
    tabBasic.style.top = '0';
} else {
    tabBasic.style.position = 'static';
}

});
for (let i = 1; i <= 5; i++) {
    document.querySelector('[data-tabnum="' + i + '"]').addEventListener('click', function() {
        for (let j = 1; j <= 5; j++) {
            if (j !== i) {
                document.querySelector('#bey' + j).style.display = 'none';
            }
        }
        document.querySelector('#bey' + i).style.display = 'block';
    });
    }
},
}

</script>



<style>
@font-face {
    font-family: 'JalnanGothic';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'KBO-Dia-Gothic_bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-2@1.0/KBO-Dia-Gothic_bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@media (max-width:991px){
  #kyushuo .container{
    padding-right: 0 !important;
    padding-left: 0 !important
  }
}



/* -----------------------------------------------------
  메인
  ----------------------------------------------------- */

#bey2{
  display: none;
}
#bey3{
  display: none;
}
#bey4{
  display: none;
}
#bey5{
  display: none;
}
#kyushuo{
  margin-top: -56px;
}
#kyushuo .main{
  background: url(../assets/img/KyushuOlle/back.jpg) no-repeat center;
  background-size: cover;
}
#kyushuo .main .carousel-inner img{
  object-fit:contain
}
.lhj{
  width: 100%;
  display: block;
  padding: 35px 0;
  background-color: #009D4E;
}
@media (max-width:992px){
  #kyushuo .main .carousel-inner img{
    position:relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top:4rem;
    width:180% !important;
    height:auto
  }
}
.lhj:nth-child(5){
  width: 100%;
}
.lhj a{
  width: 100%;
  padding: 20px 0;
}

.nav{
  width: 100%;
  text-align: center;
}

.lhj:hover{
  color: #fff;
}
.active{
  color: #fff;
}


/* -----------------------------------------------------
  올레 설명
  ----------------------------------------------------- */
#kyushuo .ollo-ex{
  background-color:#fff;
  margin:5rem 0;
}
#kyushuo .ollo-ex span{
  font-family: 'KBO-Dia-Gothic_bold';
  font-size:1.1rem;
  font-weight:600
}
#kyushuo .ollo-ex .tmain{
  font-family: 'KBO-Dia-Gothic_bold';
  font-size:3.5rem;
  color:#009D4E
}
#kyushuo .ollo-ex .empty{
  width:5rem;
  height:.5rem;
  background-color:#009D4E;
}
#kyushuo .ollo-ex .tsub{
  width:70%;
  margin-top:1rem;
  color:#000;
  font-size:1.1rem;
  font-weight:600;
  line-height: 35px;
}

@media (max-width:1400px){
  #kyushuo .ollo-ex .tsub{
    width:85%;
  }
}
@media (max-width:992px){
  #kyushuo .ollo-ex span{
    font-size:1rem;
  }
  #kyushuo .ollo-ex .tmain{
    font-size:3rem;
  }
  #kyushuo .ollo-ex .tsub{
    width:90%;
    margin-top:1rem;
    font-size:.9rem;
    line-height: 25px;
  }
}



/* -----------------------------------------------------
  카테고리
  ----------------------------------------------------- */
/* pc 모드 */
#kyushuo .cpc{
  display: none;
  background-color: #fff;
}
@media (min-width:991px){
  #kyushuo .cpc{
    display: block;
  }
}
#kyushuo .cate .nav{
  --bs-nav-link-color:#000;
  --bs-nav-link-hover-color:#fff;
  --bs-nav-link-padding-y: 0;
  font-size:1.1rem;
  font-family: 'JalnanGothic'
}
@media (max-width:1300px){
  #kyushuo .cate .nav{
    font-size:1rem;
  }
}
#kyushuo .cate .nav-pills {
  --bs-nav-pills-border-radius: 0;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #009D4E;
}
#kyushuo .cate .nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link{
  height:100%;
  vertical-align: middle;
}
@media (max-width:991px){
  #kyushuo .cate .nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link{
    padding:30px 0;
  }
}

/* 모바일모드 */
#kyushuo .cmobile{
  display: none;
  background-color: #fff;
}
#kyushuo .cmobile .cate2{
  flex-wrap: wrap;
  overflow: hidden;
}
#kyushuo .cmobile .cate2 .nav{
  --bs-nav-link-color:#000;
  --bs-nav-link-hover-color:#009D4E;
  font-family: 'JalnanGothic'
}
#kyushuo .cmobile .cate2 .nav-pills {
  --bs-nav-pills-border-radius: 0;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #009D4E;
}
#kyushuo .cmobile .cate2 .nav-item{
  width:50%
}
#kyushuo .cmobile .cate2 .nav-item:last-child{
  width: 100%;
}
#kyushuo .cmobile .cate2 .nav-item .nav-link{
  padding:25px 0;
}
@media (max-width:992px){
  #kyushuo .cmobile{
    display: block;
  }
  #kyushuo .cmobile .cate2 .nav{
    font-size:.9rem
  }
}



/* -----------------------------------------------------
  각 코스
  ----------------------------------------------------- */
#kyushuo .in-cont{
  background-color: #fff;
}
#kyushuo .in-cont .detail-img{
  margin-top:3rem;
  width: 70%;
  height: auto;
}
@media (max-width:992px){
  #kyushuo .in-cont{
    margin-top: -3rem;
  }
  #kyushuo .in-cont .detail-img{
    width:100%
  }
}



/* 예약 */
#kyushuo .resergo{
  margin-top:5rem
}
#kyushuo .resergo .go{
  border:4px solid #009D4E;
  padding:15px;
  text-align: center;
  transition: all 0.5s;
  margin:1rem 0 7rem
}
#kyushuo .resergo a{
  color:#009D4E;
  font-size:1.3rem;
  font-weight: 600;
}
#kyushuo .resergo a:hover .go{
  border:4px solid #009D4E;
  background-color: #009D4E;
  color:#fff;
}



/* -----------------------------------------------------
  꽃잎효과
  ----------------------------------------------------- */
#kyushuo .flower .pc{
  z-index: 999;
  position:absolute;
  top:-5%;
  left:-27%;
  opacity: 0.8;
}
#kyushuo .flower .pc{
  display: none;
}
@media (min-width:992px){
  #kyushuo .flower .pc{
    display: block;
  }
}

#kyushuo .flower .mobile{
  z-index: 999;
  position:absolute;
  top:-10%;
  left:50%;
  transform: translateX(-50%);
  opacity: 0.8;
}
#kyushuo .flower .mobile{
  display: block;
}
@media (min-width:992px){
  #kyushuo .flower .mobile{
    display: none;
  }
}


</style>

