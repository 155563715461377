<template>
<main class="mt-3">
    <!-- detail Search-condition -->
    <div class="container">
      <!-- 나중에 토스트형식으로 보여줄 필요가 있을 때 -->
      <!-- <div class="alert alert-primary alert-dismissible" role="alert" id="liveAlert">
        <strong>Nice!</strong> You've triggered this alert.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div> -->

      <!-- 구매결정 area mt: MarginTop-2 -->
      <div class="row mt-2">
        <!-- 컨텐츠 화면을 md이상인 경우 5:7로 분할 -->
        <div class="col-md-5" v-if="productImage.length > 0">
          <!-- 상품이미지 슬라이딩 size: 640*450-->
          <div id="carouselDetail" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselDetail" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselDetail" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselDetail" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
              <!-- 첫번째 이미지에만 carousel active를 주는 표현식처리 -->
                <!-- <img :src="`/download/noImage/noImage.jpg`" class="card-img-top"/> -->
              <div :class="`carousel-item ${i==0 ? 'active' : ''}`" :key="i" v-for="(dImg, i) in productImage">
                <!-- <img src="\IMGs\Products\somen001.jpg" class="d-block w-100" alt="..."> -->
                <img :src="`/download/${productId}/${dImg.imgName}`" class="d-block w-100" alt="...">
                <!-- <img :src="dImg.imgPath" class="d-block w-100" :alt="dImg.imgRemarks"> -->
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselDetail" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselDetail" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>

            </button>
          </div>
        </div>
        <div class="col-md-5" v-else>
          <div>
            <img :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
          </div>
        </div>

        <div class="col-md-7">
          <div class="card shadow-sm">
            <!-- <img src="./IMGs/kumamotoCastle.jpg" class="card-img-top" alt="..."> -->
            <div class="card-body">
              <h5 class="card-title pt-3 pb-3 fs-2 text-center" style="font-style: italic;">
                <b class="text-warning">[미리보기]</b> &nbsp;{{productDetail.pdtNameKor}}
              </h5>
              <p class="mt-1"><small class="align-middle">상품코드</small> <font class="text-primary">{{productDetail.pdtCode}}</font></p>
              <div class="card-text border-top pt-3 pb-3" style="text-align: left">
                <!-- 교통편 -->
                <div class="accordion text-small" id="accordionExample">
                  <div class="accordion-item border border-primary rounded-3 mb-1">
                    <h6 class="accordion-header" id="headingOne">
                      <button class="accordion-button btn-small text-small text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" 
                        aria-expanded="true" aria-controls="collapseOne">
                        <i class="bi bi-box-arrow-right"></i>&nbsp;
                        <small v-if="productDetail.transFlag != '0'">
                          <b>출 발 : </b> <font style="color:dimgray ">불포함</font>
                        </small>
                        <small v-else>
                          <b>[출  발]</b><br>
                            <font style="color:dimgray">
                              {{pdtTransInfo1.ddCodeName}}({{pdtTransInfo1.d_dpTime}})
                              <i class="bi bi-caret-right-fill"></i>
                              {{pdtTransInfo1.daCodeName}}({{pdtTransInfo1.d_arTime}})
                              &nbsp;/&nbsp;
                              {{pdtTransInfo1.dmobileCodeName}}({{pdtTransInfo1.dmobileCode}}{{pdtTransInfo1.dpTpCode}})
                            </font>
                            <!-- <font style="color:dimgray">
                              {{productDetail.cityName}}({{productDetail.ddTerm}})<i class="bi bi-caret-right-fill"></i>
                              {{productDetail.daTerm}} &nbsp;/&nbsp;{{productDetail.dpTrans}} ({{productDetail.dpMobility}})
                            </font>-->
                        </small>
                      </button>
                    </h6>
                    <div id="collapseOne" class="accordion-collapse collapse show text-start" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <small><small style="color:dimgray" v-html="getStringFormat(this.productDetail.transRemarks_dp)"></small></small>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item  border border-danger rounded-3">
                    <h6 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed btn-small text-small text-danger" type="button" data-bs-toggle="collapse" 
                        data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        <i class="bi bi-box-arrow-left"></i>&nbsp;
                        <small v-if="productDetail.transFlag != '0'">
                          <b>리 턴 :</b>  <font style="color:dimgray ">불포함</font>
                        </small>
                        <small v-else>
                          <b>[리  턴]</b><br>
                          <font style="color:dimgray">
                              {{pdtTransInfo1.rdCodeName}}({{pdtTransInfo1.r_dpTime}})
                              <i class="bi bi-caret-right-fill"></i>
                              {{pdtTransInfo1.raCodeName}}({{pdtTransInfo1.r_arTime}})
                              &nbsp;/&nbsp;
                              {{pdtTransInfo1.rmobileCodeName}}({{pdtTransInfo1.rmobileCode}}{{pdtTransInfo1.rtTpcode}})
                            </font>                          
                          <!-- <font style="color:dimgray">
                            {{productDetail.rdTerm}}<i class="bi bi-caret-right-fill"></i>{{productDetail.raTerm}} 
                            &nbsp;/&nbsp;{{productDetail.rtTrans}} ({{productDetail.rtMobility}})
                          </font> -->
                        </small>
                      </button>
                    </h6>
                    <div id="collapseTwo" class="accordion-collapse collapse text-start" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <small><small style="color:dimgray" v-html="getStringFormat(this.productDetail.transRemarks_ar)"></small></small>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <span class="badge bg-primary fs-6" v-if="productDetail.salesRoute == 'B'">B2B</span>
                <span class="badge bg-warning fs-6" v-else>B2C</span> -->
                <span class="badge text-white fs-6 mx-1 mt-3"  style="background-color:goldenrod" v-if="pdtPromoInfo.promoName != undefined">
                  <b><i class="bi bi-megaphone"></i></b>&nbsp; 
                    <small>{{getDateFormat(pdtPromoInfo.promoStart)}} ~ {{getDateFormat(pdtPromoInfo.promoEnd)}}&nbsp; 출발할인!</small>
                </span>
                <span class="badge bg-danger fs-6" v-else></span>

                <!-- 상품요금 -->
                <div class="col-12 mt-3" style="display:flex; justify-content:end">
                  <!-- On tables -->
                  <table class="col-10">
                    <tr :key=i v-for="(age,i) in this.detailPrice">
                      <td class="col-3 text-between" style="text-align:center; background-color: white" 
                        v-if="this.salesRoute === 'T' || this.salesRoute === 'L' || this.salesRoute === 'H'">
                        {{detailPrice[i].age}}
                      </td>
                      <td class="col-3 text-between" style="text-align:center; background-color: white" v-else>
                        가격
                      </td>
                      <td class="col-7 text-start">
                        <b>{{getCurrencyFormat(detailPrice[i].productPrice)}}</b>&nbsp;
                        <small><small><i class="bi bi-arrow-up-right text-secondary"></i></small></small>
                      </td>
                    </tr>

                    <!-- 추가상품은 오픈연기 -->
                    <!-- <tr>
                      <td colspan="2">
                        <hr style="border: dotted 1px blue">
                      </td>
                    </tr>

                    <tr>
                      <td style="text-align:center; background-color: white">
                        추가상품
                        <br>
                        <small><small>(성인,소인,유아,기타)</small></small>
                      </td>
                      <td>
                        <div class="text-start mb-2" :key=i v-for="(pdtNameKor,i) in this.pdtAddOptExisted">
                          <i class="bi bi-three-dots-vertical text-info"></i>{{this.pdtAddOptExisted[i][0].pdtNameKor}}
                          <br>
                          &nbsp;&nbsp;(<font class="small text-success" :key=j v-for="(agegroup,j) in this.pdtAddOptExisted[i]">
                            &nbsp;<b>{{getCurrencyFormat(this.pdtAddOptExisted[i][j].pdtPrice)}}</b>,</font>&nbsp;)
                        </div>                        
                      </td>
                    </tr> -->

                    <tr>
                      <td colspan="2">
                        <hr style="border: dotted 1px blue">
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align:center; background-color: white">선택옵션</td>
                      <td>
                      <div class="text-start" :key=i v-for="(etcOptName,i) in this.etcOptionInfo">
                        <i class="bi bi-three-dots-vertical text-warning"></i>{{this.etcOptionInfo[i].etcOptName}}
                        <br>
                        &nbsp;&nbsp;(<font class="text-success small"><b>{{getCurrencyFormat(this.etcOptionInfo[i].etcOptPrice)}}</b></font>)
                      </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2">
                        <hr style="border:solid 1px red">
                        <b class="text-danger"><i class="bi bi-cone-striped"></i> 주의사항</b>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="text-start p-2">
                        <small>
                        표시된 가격은 판매기간 중 <b class="text-primary">최소가격</b>입니다.
                        <br>
                        <small class="text-secondary"> &nbsp;(유류할증/터미널이용료, 제세금 등 불포함)</small>
                        <!-- <br>
                        추가상품과 선택옵션은 변경될 수 있습니다.
                        <br> -->
                        <br>
                        선택옵션의 적용은 상담 후, 확정됩니다.
                        <br>                        
                        결제액은 <u>상담 후</u> 안내됩니다.
                        </small>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- <p class="card-text text-end align-middle" style="font-size: medium;">
                {{ getCurrencyFormat(productDetail.b2bPrice) }}<font style="font-size: small;">원 </font>
                <font class="text-secondary text-end" style="font-size: small;">(VAT,배송비 별도)</font>
              </p> -->
              <p class="card-text text-end text-secondary align-middle" style="font-size: small;">
                <i class="fal fa-envelope-open-dollar"></i>&nbsp;국민은행 947801-01-412768(엔타비글로벌)
              </p>
              <p class="card-text text-end text-secondary" style="font-size: small;">
                <i class="bi bi-telephone-fill"></i>&nbsp;<a href="tel:051-466-4602" style="text-decoration:none" class="text-primary">051-466-4602</a>
              </p>
              <!-- <div class="col-auto d-grid p-1">
                <button type="button" class="btn btn-md btn-primary" style="background-color:midnightblue" @click="inquiryConsulting()" v-if="this.productDetail.autoSales ==='Y'">즉시구매</button>
                <button type="button" class="btn btn-md btn-primary" style="background-color:midnightblue" @click="inquiryConsulting()" v-else>상담문의</button>
              </div> -->
              <div class="col-auto d-grid p-1">
                <button type="button" class="btn btn-md btn-outline-primary" @click="inquiryProduct()">기타문의</button>
                <!-- <button type="button" class="btn btn-md btn-outline-secondary" @click="kPay()">카카오페이테스트</button> -->
                <!-- <button type="button" class="btn btn-md btn-outline-primary" @click="tPay()">토스페이테스트</button> -->
                <!-- <button type="button" class="btn btn-md btn-outline-success" id="naverPayBtn" value="네이버페이 결제 버튼" >NaverPay</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 상품가격달력 -->
      <div>
        <div class="calendar mb-2">
          <div class="cheader">
            <div class="year-month"></div>
            <div class="nav">
              <div v-if="this.navFlag === 'N'">
                <button class="nav-btn go-prev" @click="getCalPdtPriceForMonth('P')">
                  <i class="bi bi-caret-left-fill"></i>
                </button>
                <button class="nav-btn go-today" @click="getCalPdtPrice()">Today</button>
                <button class="nav-btn go-next" @click="getCalPdtPriceForMonth('N')">
                  <i class="bi bi-caret-right-fill"></i>
                </button>
              </div>
              <div v-else>
                <button class="nav-btn go-today text-center" @click="navChgStatus();">
                  재검색
                </button>
              </div>
            </div>
          </div>
          <div class="main">
            <div class="days">
              <div class="day">일</div>
              <div class="day">월</div>
              <div class="day">화</div>
              <div class="day">수</div>
              <div class="day">목</div>
              <div class="day">금</div>
              <div class="day">토</div>
            </div>
            <div class="dates"></div>
          </div>
        </div>
      </div>
      
      <!-- 예약정보입력 -->
      <div v-if="this.inquiryFlag === 'Y'">
        <div class="col-12 mt-3" style="display:flex; justify-content:end">
          <!-- On tables -->
          <table class="col-10">
            <tr>
              <td><i class="bi bi-check text-danger"></i>상담자</td>
              <td colspan="2">
                <input type="text" class="form-control" ref="txt_reqName" placeholder="성명" v-model="this.reqName">
              </td>
            </tr>
            <tr>
              <td><i class="bi bi-check text-danger"></i>연락처</td>
              <td colspan="2">
                <input type="text" class="form-control" ref="txt_reqTelNo" placeholder="숫자만(-제외)" v-model="this.reqTelNo">
              </td>
            </tr>
            <tr>
              <td>이메일</td>
              <td colspan="2">
                <input type="text" class="form-control" ref="txt_reqEmail" placeholder="@" v-model="this.reqEmail">
              </td>
            </tr>            
            <tr>
              <td colspan="3">
                <hr style="border: dotted 1px blue">
              </td>
            </tr>
            <tr>
              <td v-if="this.salesRoute === 'T' || this.salesRoute ==='L'"><i class="bi bi-check text-danger"></i>출발일</td>
              <td v-else-if="this.salesRoute === 'H'">숙박일</td>
              <td v-else-if="this.salesRoute === 'E' || this.salesRoute ==='S'"><i class="bi bi-check text-danger"></i>구매일</td>
              <td colspan="2">
                <!-- <input type="date" id="dpDate" class="form-control" ref="txt_reqDptDate" v-model="this.reqDptDate" @click="setDate();"> -->
                <!-- <input type="text" class="form-control" :value="this.selecDate" ref="txt_reqDptDate" readonly> -->
                <input type="text" class="form-control" v-model="this.selecDate" ref="txt_reqDptDate" readonly>
              </td>
            </tr>
            <tr v-if="this.productDetail.tripProp === 'F'">
              <td><i class="bi bi-check text-danger"></i>종료일</td>
              <td colspan="2">
                <input type="date" id="rtDate" class="form-control" ref="txt_reqRtnDate" v-model="this.reqRtnDate" @click="setRtnDate();">
                <!-- <date-picker class="form-control" v-model="this.reqRtnDate" ref="txt_reqRtnDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker> -->
              </td>
            </tr>
            <tr v-else-if="this.productDetail.tripProp === 'D'">
              <td><i class="bi bi-check text-danger"></i>리턴일</td>
              <td colspan="2">
                <input type="date" id="rtDate" class="form-control" ref="txt_reqRtnDate" v-model="this.reqRtnDate" readonly>
              </td>
            </tr>                        
            <tr class="border border-1">
              <td class="p-2" colspan="3"><i class="bi bi-exclamation-triangle-fill text-danger"></i> 하기일자의 출발여부는 상담필요</td>
            </tr>
            <tr class="border border-1">
              <td class="text-danger align-between" colspan="3">
                <pre class="pb-0">
                  {{this.productDetail.unableDpDate}}
                </pre>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <hr style="border: dotted 1px blue">
              </td>
            </tr>
            <tr :key=i v-for=" (agegroup, i) in this.selectedCalPdtPrice">
              <td class="col-3 text-between p-2" style="text-align:center; background-color: white" 
                v-if="this.salesRoute === 'T' || this.salesRoute === 'L' || this.salesRoute === 'H'">
                <i class="bi bi-check text-danger"></i>{{selectedCalPdtPrice[i].agegroup}} / <font class="text-primary">인원</font>
              </td>
              <td class="col-3 text-between p-2" style="text-align:center; background-color: white" v-else>
                <i class="bi bi-check text-danger"></i>가격 / <font class="text-primary">인원</font>
              </td>              
              <td class="col-3 text-end p-2">
                <div v-if="productDetail.tripProp === 'D'">
                  <b>{{getCurrencyFormat(selectedCalPdtPrice[i].pdtPrice)}}&nbsp;/&nbsp;</b>
                </div>
              </td>
              <td class="col-4 text-start text-wrap">
                <div class="col-10">
                  <input type="number" min="0" max="15" maxlength="2" placeholder="인원" class="form-control text-primary text-end" ref="txt_age[i+1]" 
                  v-model="this.ageNumber[i+1]">
                </div>
                
              </td>
            </tr>
            <!-- <tr>
              <td colspan="3">
                <hr style="border: dotted 1px blue">
              </td>
            </tr>
            <tr>
              <td style="text-align:center; background-color: white">
                추가상품
                <br>
                <small><small>(성인,소인,유아,기타)</small></small>
              </td>
              <td colspan="2">
                <div class="text-start mb-2" :key=i v-for="(pdtNameKor,i) in this.pdtAddOptExisted">
                  <i class="bi bi-three-dots-vertical text-info"></i>{{this.pdtAddOptExisted[i][0].pdtNameKor}}
                  <br>
                  &nbsp;&nbsp;(<font class="small text-success" :key=j v-for="(agegroup,j) in this.pdtAddOptExisted[i]">
                    &nbsp;<b>{{getCurrencyFormat(this.pdtAddOptExisted[i][j].pdtPrice)}}</b>,</font>&nbsp;)
                </div>                        
              </td>
            </tr> -->
            <tr>
              <td colspan="3">
                <hr style="border: dotted 1px blue">
              </td>
            </tr>
            <tr>
              <td style="text-align:center; background-color: white">
                선택옵션
                <p><small><small>(체크 후, 상담)</small></small></p>
              </td>
              <td colspan="2">
                <div class="text-start" :key=i v-for="(etcOptName,i) in this.etcOptionInfo">
                  <i class="bi bi-three-dots-vertical text-warning"></i>{{this.etcOptionInfo[i].etcOptName}}
                  <br>
                  &nbsp;&nbsp;(<font class="text-success small"><b>{{getCurrencyFormat(this.etcOptionInfo[i].etcOptPrice)}}</b></font>)
                  &nbsp;&nbsp;
                  <input class="form-check-input my-auto" type="checkbox" v-model="this.etcOptionInfo[i].reqOptFlag" 
                  true-value="Y" false-value="N" id="reqOptFlag" v-if="this.etcOptionInfo[i].reqOptFlag_ex === 'Y'" disabled>
                  <input class="form-check-input my-auto" type="checkbox" v-model="this.etcOptionInfo[i].reqOptFlag" 
                  true-value="Y" false-value="N" id="reqOptFlag" v-else>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <hr style="border: dotted 1px blue">
              </td>
            </tr>
            <tr>
              <td style="text-align:center; background-color: white">기타문의</td>
              <td colspan="2">
                  <textarea class="form-control small" placeholder="200자 이내" ref="txt_reqRemarks" id="floatingTextarea" style="height:80px" 
                    v-model="reqRemarks" maxlength="200"></textarea>
              </td>
            </tr>            
            <tr>
              <td colspan="3">
                <hr style="border: dotted 1px blue">
              </td>
            </tr>

            <tr>
              <td></td>
              <td colsapn="2">
                <div class="col-auto d-grid mb-2">
                  <button type="button" class="btn btn-md btn-primary btn-block" style="background-color:teal" @click="chekDptDate()">상담요청</button>
                  <!-- <button type="button" class="btn btn-md btn-primary btn-block" @click="callTest()">test</button> -->
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- 상품상세설명 area imageSize: width 2000이상-->
      <div class="row mt-2 mb-0">
        <div>
          <div v-if="this.productDetail.pdtRemarks == ''">
          </div>
          <div class="col-auto alert border-1 border-secondary" style="background-color:white; font-size:small" role="alert" v-else>
            <p class="mt-2 text-start"></p>
              <pre class="text-success text-start">{{ this.productDetail.pdtRemarks }}</pre>
          </div>
        </div>
      </div>
      
      <div class="row" v-if="this.productDetailImage.length > 0">
        <div class="col-12" :key="i" v-for="(detailImg, i) in productDetailImage">
          <!-- <img :src="detailImg.imgName" class="img-fluid" :alt="detailImg.imgRemarks"> -->
          <img :src="`/download/${productId}/${detailImg.imgName}`" class="img-fluid" />
        </div>
      </div>
      <!-- <div class="row d-none d-sm-block" v-else> -->
      <div class="row" v-else>
        <div class="col-12">
          <img :src="`/download/noImage/noImage.jpg`" class="img-fluid"/>
        </div>
      </div>
    </div>
  </main>
</template>

<style>
  @import "../assets/css/calendar.css";
</style>

<script>
//import moment from 'moment';
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';

export default {
  // components: 
  // {
  //   DatePicker
  // },  
  // DB에서 가져온 데이타
  data() {
    return {
      productId: '',
      productDetail: [], // 상세정보를 오브젝트형으로 변수처리

      pdtTransInfo1: {}, //상품교통정보

      productImage: [],
      productDetailImage: [],
      pdtFlag: "", //단일-결합 구분자
      detailPrice: [], //상세페이지 표시용 기본가격
      pdtAddOptExisted: [], //기존추가옵션
      etcOptionInfo: [], //기타옵션
      //etcOptionInfo_existed: [], //기타옵션(기존)
      inquiryFlag: "N", //상담창플래그
      ageNumber: [0,0,0,0,0], //연령별 인원

      pdtPromoInfo: [], //상품프로모션정보

      //////상담요청용 데이타
      //reqRsvInfo: {}, //상품상담내용
      reqReserveCode: "", //상담신청번호
      reqName: "", //상담자성명
      reqTelNo: "", //상담자연락처
      reqEmail: "", //상담자이메일
      reqRemarks: "", //상담신청 기타문의
      reqDptDate: "", //출발예정일  = selecDate
      reqRtnDate: "", //리턴예정일
      aduCnt : 0, //권종별인원
      chiCnt : 0,
      infCnt : 0,
      disCnt : 0,
      totCnt : 0, //유효인원수
      aduPrice : 0, //권종별 금액(상담신청시)
      chiPrice : 0,
      infPrice : 0,
      disPrice : 0,
      prodPrice: 0, //상품가격
      addOptCodes: [], //추가옵션코드
      selectedEtcOptInfo: [], //선택상품정보
      selectedEtcOpt: [], //유저선택옵션
      vatPrice: 0, //부가세
      dInterval: 0, //일정기간
      
      productPrice: [],

      productB2BPrice: [],
      total: 0, // 구매수량
      totalPrice: 0, // 구매총액
      buyPrice: 0, // 상품비용
      delPrice: 0, // 배송료
      stdDeliCharge: 0, // 기본배송료
      etcPrice: 0, // 제비용
      blank: '',
      pPrice: 0,

      pdtMargin: 0,
      pdtPromo: 0,
      //noImage: './IMGs/noImage.jpg'  // 이미지없음

      //상품달력
      navFlag: "N", //달력 월이동 표시여부
      calDate: "",
      calPdtPrice: [],
      selecDate: "", //선택일자
      arr_selectedCalPdtPrice: [], //달력선택월의 가격리스트
      selectedCalPdtPrice: [], //선택된 날짜의 가격
      calPrice: [], //상품의 데일리가격
      calPriceNew: [],
      dates: [],
      selYear: "",
      selMonth: "",
      calLast: "", //당해상품 최종판매월
      calLimitedEnd: "", //리턴자유상품 선택가능 최종일
      arr_reqGroupPrice: [], //예약상담디테일용 연령가격리스트
      arr_rtnFreePdtPrice: [], //리턴자유상품 선택기간중 가격리스트

      memberFlag: "", //회원여부
    };
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },    
  created() {
    // path에서 사용된 productId
    this.user = this.$store.state.user;
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;

    console.log("user:",this.user);

    if (this.$store.state.user.handlerId != undefined) {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined) {
      this.userInfo = this.$store.state.user.email;
    }
    else {
      this.userInfo = "";
    }
    console.log("userInfo:", this.userInfo);

    //단일-결합구분자
    if(this.productId.substring(0,3)==="MX-") {
      this.pdtFlag = "MIX";
    }
    console.log("buyerInfo:",this.$store.state.user.buyerId, "bGrade:", this.handlerGrade, "sRoute:",this.salesRoute);
    console.log("userInfo:",this.$store.state.user.email);

    this.getProductDetail();
    this.getProductImage();
    this.getProductDetailImage(); // 제품상세이미지호출
    this.getProductPrice(); //상품가격
    this.getPromoInfo(); //프로모션체크

    this.getProductTransInfoExited(); //상품교통정보

    this.getCalPdtPrice(); //달력표시용 상품가격취득

  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }
  },
  methods: 
  {
    //달력표시용 가격정보
    navChgStatus()
    {
      //달력이동 버튼에 의한 에러발생 방지용
      location.reload();
    },
    async getCalPdtPrice() 
    {
      let calDate = new Date();
      this.calDate = calDate;

      let viewYear = calDate.getFullYear();
      this.viewYear = viewYear;
      
      let viewMonth = calDate.getMonth();
      this.viewMonth = viewMonth;

      this.selYear = viewYear;
      let selMonth = viewMonth + 1;
      this.selMonth = (selMonth < 10) ? "0" + selMonth : selMonth;
      
      let selectedDate = this.selYear + "-" + this.selMonth + "%";

      let calPdtPrice = await this.$api("/api/calPdtPrice", {param: [this.productId, selectedDate]});
      let arr_selectedCalPdtPrice = await this.$api("/api/selectedCalPdtPrice", {param: [this.productId, selectedDate]});
      this.arr_selectedCalPdtPrice = arr_selectedCalPdtPrice;
      //console.log("arr_selectedCalPdtPrice:",arr_selectedCalPdtPrice);

      //해당상품의 최종판매일
      let calPdtLastDate = await this.$api("/api/calPdtLastDate", {param: [this.productId]});

      let calLastDay = calPdtLastDate[0].lastSaleDate;
      let calLastYear = calLastDay.substring(0,4);
      let calLastMonth = calLastDay.substring(5,7);
      let calLastDate = new Date(calLastDay);
      console.log("calLastDate:",calLastDate);

      let calLast = "";
      calLast = calLastYear + "-" + calLastMonth;
      this.calLast = calLast; //최종판매월

      //let calPrice = calPdtPrice.map(item => item.pdtPrice);
      let calPrice = calPdtPrice.map(item => {
        return {
          salePrice: item.pdtPrice,
          saleStat: item.sellStat
        };
      });
      this.calPdtPrice = calPdtPrice;
      this.calPrice = calPrice;

      this.mkCalendar();
    },
    // 월별 달력용 가격정보
    async getCalPdtPriceForMonth(val) 
    {
      // this.inquiryFlag = "N";
      // this.selecDate = "";
      
      //지난달로 이동저지
      let nowMonth = this.selYear + "-" + this.selMonth;
      //console.log("nowMonth:",nowMonth, "calDate:",this.calDate);

      if(val === "P")
      {
        this.calDate.setMonth(this.calDate.getMonth() -1);
        //console.log("viewMonth:",this.viewMonth);
        let pMonth = (this.calDate.getMonth()+1);
        console.log("pMonth:",pMonth);

        let cMonth = (pMonth < 10) ? "0" + (pMonth+1) : pMonth + 1;
        if(cMonth == "00")
        {
          cMonth = "12";
        }
        let checkMonth = "";
        checkMonth = this.calDate.getFullYear() + "-" + cMonth;
        console.log("checkMonth:",checkMonth);

        if(nowMonth > checkMonth)
        {
          checkMonth = nowMonth;
          this.$swal.fire('', '지난달은 예약이 되지않습니다.', 'warning');
          return;
        }
      }
      else if(val === "N")
      {
        this.viewMonth = this.calDate.setMonth(this.calDate.getMonth()+1);
        let nYear = this.calDate.getFullYear();
        let nMonth_tmp = this.calDate.getMonth();
        console.log("nMonth_tmp:",nMonth_tmp);

        let nMonth = (nMonth_tmp < 10) ? "0" + (nMonth_tmp) : nMonth_tmp;
        if(nMonth == "00")
        {
          nMonth = "12";
        }
        console.log("nMonth:",nMonth);
        let nDay = nYear +"-"+ nMonth;
        console.log("nDay:",nDay,"calLast:",this.calLast);

        if(this.calLast < nDay)
        {
          this.$swal.fire('', '판매기간이 아닙니다.', 'warning');
          return;
        }
      }
      this.viewYear = this.calDate.getFullYear();
      this.viewMonth = this.calDate.getMonth();
      console.log("viewYear:",this.viewYear,"viewMonth:",this.viewMonth);

      let selectedMonth = (this.calDate.getMonth()+1 < 10) ? "0" + (this.calDate.getMonth()+1) : this.calDate.getMonth()+1;
      let selectedCalDate = this.calDate.getFullYear() + "-" + selectedMonth+"%";
      // let selectedMonth = (this.viewMonth < 10) ? "0" + (this.viewMonth) : this.viewMonth;
      // let selectedCalDate = this.viewYear + "-" + selectedMonth+"%";      
      console.log("selectedCalDate:",selectedCalDate);

      let arr_selectedCalPdtPrice = await this.$api("/api/selectedCalPdtPrice", {param: [this.productId, selectedCalDate]});
      this.arr_selectedCalPdtPrice = arr_selectedCalPdtPrice;
      //console.log("selectedCalPdtPrice2:",selectedCalPdtPrice);
              
      let calPdtPrice = await this.$api("/api/calPdtPrice", {param: [this.productId, selectedCalDate]});
      //let calPrice = calPdtPrice.map(item => item.pdtPrice);
      let calPrice = calPdtPrice.map(item => {
        return {
          salePrice: item.pdtPrice,
          saleStat: item.sellStat
        };
      });
      this.calPdtPrice = calPdtPrice;
      this.calPriceNew = calPrice;
      this.mkCalendar('Z');
    },
    // 판매상품게시달력
    mkCalendar(val)
    {
      //console.log("val:",val);
      let calPriceCal = [];
      if(val == "Z")
      {
        //calPriceCal = this.calPriceNew;
        calPriceCal = (this.calPriceNew.length > 0) ? this.calPriceNew : undefined;
      }
      else
      {
        calPriceCal = this.calPrice;
      }
      //console.log("calPriceCal:", calPriceCal);

        document.querySelector('.year-month').textContent = `${this.viewYear}年${this.viewMonth+1}月`;

        const prevLast = new Date(this.viewYear, this.viewMonth, 0);
        const thisLast = new Date(this.viewYear, this.viewMonth+1, 0);
        console.log("prevLast:",prevLast,"/thisLast:",thisLast);

        const PLDate = prevLast.getDate();
        const PLDay = prevLast.getDay();

        const TLDate = thisLast.getDate();
        const TLDay = thisLast.getDay();

        const prevDates = [];
        const thisDates = [...Array(TLDate + 1).keys()].slice(1); //keys는 array의 index를 반환
        const nextDates = [];

        if(PLDay !== 6){
          for(let i = 0; i < PLDay + 1; i++){
            prevDates.unshift(PLDate - i);
          }
        }

        for(let i=1; i<7-TLDay; i++){
          nextDates.push(i);
        }

        let dates = prevDates.concat(thisDates, nextDates);
        this.dates = dates;
        let firstDateIndex = dates.indexOf(1);
        let lastDateIndex = dates.lastIndexOf(TLDate);

        dates.forEach((date,i) =>
        {
          const condition = i >= firstDateIndex && i < lastDateIndex + 1 ? 'this' : 'other';
          if(condition === "this" && calPriceCal != undefined) 
          {
            //일보정
            let date0 = date-1;
            date = (date < 10) ? "0" + date : date;

            if(calPriceCal[date0].salePrice === undefined)
            {
              calPriceCal[date0] = ""
            }
            else if(calPriceCal[date0].salePrice == "0")
            {
              calPriceCal[date0] = "미판매"
            }
            else if(calPriceCal[date0].saleStat == "N")
            {
              calPriceCal[date0] = "완료"
            }
            else
            {
              calPriceCal[date0] = this.getCurrencyFormat(calPriceCal[date0].salePrice);
            }

            dates[i] =
              `<div class="date">
                <span class = ${condition}>
                  <button type="button" id="btnCal" class="btn">${date}</button>
                </span>
                <p>
                    <samll><small>${calPriceCal[date0]}</samll></samll>
                </p>                
              </div>`;
          }
          else if(condition === "this" && calPriceCal == undefined)
          {
            dates[i] = `<div class="date">
            <span class = ${condition}>
              <button type="button" id="btnCal" class="btn" readonly disabled>${date}</button>
            </span>
            </div>`;
          }          
          else
          {
            dates[i] = `<div class="date">
            <span class = ${condition}>
              <button type="button" id="btnCal" class="btn" readonly disabled>${date}</button>
            </span>
            </div>`;
          }
        });

        //console.log("thisdates:",this.dates);
        document.querySelector('.dates').innerHTML = dates.join('');

        const buttons = document.querySelectorAll('#btnCal');
        [...buttons].forEach((button) => button.addEventListener('click', this.selDate, false));

        const today = new Date();
        let todayForCSS = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        if(this.viewMonth === today.getMonth() && this.viewYear === today.getFullYear())
        {
          for(let btnCal of document.querySelectorAll('.this')){
            if(+btnCal.innerText == todayForCSS){
              btnCal.classList.add('today');
              break;
            }
          }
        }
    },
    //달력 선택일자정보 + 유효기간체크
    selDate()
    {
      this.navFlag = "Y"; //listener에러대응 상태처리
      this.reqRtnDate = "";

      this.memberFlag = "";

      let selDay = event.target.innerText;
      let selecDate = this.viewYear + "-" + (this.viewMonth + 1) + "-" + selDay;
      this.selecDate = selecDate;

      if(this.productDetail.tripProp === "D")
      {
        let selecDate_tmp = new Date(this.selecDate);
        let reqRtnDate_tmp = "";

        if(this.productDetail.stayCnt == "0") //당일리턴
        {
          reqRtnDate_tmp = new Date(selecDate_tmp.setDate(selecDate_tmp.getDate() + (this.productDetail.stayCnt)));
        }
        else if(this.productDetail.stayCnt > 0) //무박1일 포함(=당일리턴)
        {
          reqRtnDate_tmp = new Date(selecDate_tmp.setDate(selecDate_tmp.getDate() + (this.productDetail.stayCnt - 1)));
        }
        else
        {
          this.$swal.fire('', '날짜설정에 오류가 있습니다.<br>담당자에 문의하여 주십시오.', 'error');
          return false;          
        }
        this.reqRtnDate = this.getDateFormat(reqRtnDate_tmp);
        //console.log("selecDate:",selecDate," ~ rnDate:",this.reqRtnDate);
      }

      this.inquiryFlag = "N";

      //today
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();

      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;

      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      //금일
      let todayFin = `${yyyy}-${mm}-${dd}`;
      //여행유효, 상품판매기간
      let tStart = this.getDateFormat(this.productDetail.tripStart);
      let tEnd = this.getDateFormat(this.productDetail.tripEnd);
      let sStart = this.getDateFormat(this.productDetail.salesStart);
      let sEnd = this.getDateFormat(this.productDetail.salesEnd);
      //달력게시제한기간
      let calLimitedStart = (tStart >= sStart) ? sStart : tStart;
      let calLimitedEnd = (tEnd >= sEnd) ? sEnd : tEnd;
      this.calLimitedEnd = calLimitedEnd; //리턴자유상품의 선택가능 최종일
      //console.log("calLimitedStart:",calLimitedStart,"calLimitedEnd:",calLimitedEnd);

      //선택일의 상품가격
      let selectedCalPdtPrice = this.arr_selectedCalPdtPrice.filter((item) => item.saleDate == selecDate);
      this.selectedCalPdtPrice = selectedCalPdtPrice;
      //console.log("selectedCalPdtPrice:",this.selectedCalPdtPrice[0].sellStat);

      if(selecDate <= todayFin)
      {
        this.$swal.fire('', '과거일(당일포함)의 예약은 불가능합니다', 'info');
        return;
      }
      else if(selecDate < calLimitedStart || selecDate > calLimitedEnd)
      {
        this.$swal.fire('', calLimitedEnd+' 이후 상품은 <b>기타문의 또는 전화연락</b>을 통해 문의주십시오.', 'warning');
        return false;
      }
      else if(this.selectedCalPdtPrice[0].sellStat == "N")
      {
        this.$swal.fire('', '판매완료 또는 미판매 일자입니다.', 'error');
        return false;
      }
      else
      {
        this.$swal.fire({
          //title: this.productDetail.pdtNameKor,
          html: selecDate + " 상품문의하시겠습니까?<br><small><i class='bi bi-exclamation-circle text-danger'></i> 결제금액은 상담 후 결정됩니다</small>",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonColor: "royalblue",
          denyButtonColor: "seagreen",
          cancelButtonColor: "gray",
          confirmButtonText: '<i class="bi bi-person-heart"></i> 회원',
          denyButtonText: '비회원',
          cancelButtonText: '취소',
          icon: 'question',
          iconColor: 'royalblue'
        }).then((result) => 
        {
          if(result.isConfirmed) 
          {
            if(this.userInfo == undefined || this.userInfo == null || this.userInfo == "")
            {
              location.href = "/b2cLogin";
            }
            else
            {
              //this.$swal.fire('', '로그인되어 있습니다', 'warning');
              this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                text: '로그인 상태입니다.',
                toast: true,
                showConfirmButton: false,
                timer: 1000
              })
              this.inquiryFlag = (this.inquiryFlag === "N") ? "Y" : "Y";
            }
          }
          else if(result.isDenied)
          {
            if(this.userInfo == undefined || this.userInfo == null || this.userInfo == "")
            {
              this.memberFlag = "N";
              this.inquiryFlag = (this.inquiryFlag === "N") ? "Y" : "Y";
            }
            else
            {
              this.$swal.fire({
                position: 'top-end',
                icon: 'warning',
                text: '회원로그인 상태입니다.',
                toast: true,
                showConfirmButton: false,
                timer: 1000
              })
            }
          }
        })
        //this.inquiryFlag = (this.inquiryFlag === "Y") ? "N" : "Y";
      }
      // console.log("todayFin:",todayFin,"/ tStart:",tStart,"/ tEnd:",tEnd,"/ sStart:",sStart,"/ sEnd:",sEnd);
      // console.log("calLimitedStart:",calLimitedStart,"/ calLimitedEnd:",calLimitedEnd);
    },
    getDateFormat(date) 
    {
      return this.$dateFormat(date);
    },
    async getProductDetail() {
      let productDetail = {};
      if(this.pdtFlag === "MIX") {
        productDetail = await this.$api("/api/productDetail_mix", {param: [this.productId]});
        // if(productDetail.length > 0) {
        //   this.productDetail = productDetail[0];
        // }
      }
      else {
        productDetail = await this.$api("/api/productDetail", {param: [this.productId]});
        // if(productDetail.length > 0) {
        //   this.productDetail = productDetail;
        // }
      }

      if(this.user != undefined || this.user != "") {
        this.reqName = (!this.user.name) ? "" : this.user.name;
        this.reqTelNo = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
        this.reqEmail = (!this.user.email) ? "" : this.user.email;
      }
      
      if(productDetail.length > 0) {
        this.productDetail = productDetail[0];
        this.tripProp = this.productDetail.tripProp;
        // this.productDetail.cityName = (productDetail[0].cityName == undefined) ? "" :  productDetail[0].cityName;
        // this.productDetail.ddTerm = (productDetail[0].ddTerm == undefined) ? "" :  productDetail[0].ddTerm;
        // this.productDetail.daTerm = (productDetail[0].daTerm == undefined) ? "" :  productDetail[0].daTerm;
        // this.productDetail.dpMobility = (productDetail[0].dpMobility == undefined) ? "" :  productDetail[0].dpMobility;
        // this.productDetail.rtMobility = (productDetail[0].rtMobility == undefined) ? "" :  productDetail[0].rtMobility;
        // this.productDetail.pdtRemarks = (productDetail[0].pdtRemarks == undefined) ? "" :  productDetail[0].pdtRemarks;
        // this.productDetail.transRemarks_dp = (productDetail[0].transRemarks_dp == undefined) ? "" :  productDetail[0].transRemarks_dp;
        // this.productDetail.transRemarks_ar = (productDetail[0].transRemarks_ar == undefined) ? "" :  productDetail[0].transRemarks_ar;
      }
      console.log("productDetail:",this.productDetail);
      console.log("tripProp:",this.tripProp);
    },
    //상품교통정보
    async getProductTransInfoExited() {
      let pdtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.productId]});
      if(pdtTransInfo1.length > 0) {
        this.pdtTransInfo1 = pdtTransInfo1[0];
      }
      console.log("pdtTransInfo1:",this.pdtTransInfo1);
    },

    async getProductImage() {
      // 상세페이지용 카로우셀 이미지
      let productImage = await this.$api("/api/productImage", {param: [this.productId]});
      this.productImage = (productImage.length > 0) ? productImage : "";
      // if(productImage.length > 0) {
      //   this.productImage = productImage;
      // }
      console.log("pImage:",this.productImage);
    },
    async getProductDetailImage() {
      // 상세설명이미지 호출
      // let noImage = this.noImage;
      let productDetailImage = await this.$api("/api/productDetailImage", {param: [this.productId]});
      this.productDetailImage = (productDetailImage.length > 0) ? productDetailImage : "";
      //console.log("productDetailImage:", this.productDetailImage);
    },
    //프로모션 정보추출
    async getPromoInfo() {
      let pdtPromoInfo = {};
      pdtPromoInfo = await this.$api("/api/productPromotion", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0]
      }
      //console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },
    //표시용 상품가격
    async getProductPrice() {
      // 조건에 부합되는 상품최소가격일자
      let pdtDetailPriceDate = await this.$api("/api/pdtDetailPriceDate", {param: [this.productId]});
      let minPriceDate = pdtDetailPriceDate[0].pdtDate;
      let detailPrice = await this.$api("/api/pdtDetailminPrice", {param: [this.productId, minPriceDate]});

      if(detailPrice.length > 0) 
      {
        this.detailPrice = detailPrice;
      }
      //   this.addOptCheck(this.productInfo.salesRoute)
      //   //this.getAddOption();
      if(this.productDetail.addOptionFlag === "Y"){
        this.getPdtAddOptionExited();
        //this.getAddOption();
      }
      if(this.productDetail.etcOptionFlag === "Y") {
        this.getEtcOptionInfo();
      }
    },
    //기존상품 추가옵션정보
    async getPdtAddOptionExited() {
      //대상상품에 적용된 추가옵션코드 추출
      let addOptCodes = {};
      let addOptInfo = {};
      if(this.pdtFlag === 'MIX') {
        addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed_mix", {param: [this.productId]});
        this.addOptCodes = addOptCodes;
        addOptInfo = await this.$api("/api/pdtAddOption_existed_mix", {param: [this.productId]});
        console.log("addOptInfo:",addOptInfo);
      }
      else {
        addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed", {param: [this.productId]});
        this.addOptCodes = addOptCodes;
        addOptInfo = await this.$api("/api/pdtAddOption_existed", {param: [this.productId]});
        console.log("addOptInfo:",addOptInfo);
      }
      console.log("addOptCodes:",this.addOptCodes);
      
      let i=0;
      let pdtAddOptExisted = [];
      while(i<addOptCodes.length) {
        //alert(addOptCodes[i].pdtCode);
        pdtAddOptExisted.push(addOptInfo.filter(e => {return e.pdtCode === addOptCodes[i].pdtCode}));
        i++;
      }
      if(pdtAddOptExisted.length > 0){
        this.pdtAddOptExisted = pdtAddOptExisted;
      }
      //console.log("pdtAddOptExisted:",this.pdtAddOptExisted);
    },
    //기타옵션획득
    async getEtcOptionInfo() {
      //this.etcOptionInfo = await this.$api("/api/etcOptionInfo", {param: [this.productId]});
      this.etcOptionInfo = await this.$api("/api/etcOptionInfo1", {param: [this.productId]});
      console.log("etcOptionInfo:",this.etcOptionInfo);
    },
    setRtnDate(){
      //console.log("selectedSDate:",this.reqDptDate);
      this.reqRtnDate = "";
      // 선택가능 리턴일 구간지정
      if(this.inquiryFlag === 'Y') {

      //리턴일=출발일가능
      let dateVal_tmp = new Date(this.selecDate);
      //let tomorrow_tmp = new Date(dateVal_tmp.setDate(dateVal_tmp.getDate() + 1));
      let tomorrow_tmp = new Date(dateVal_tmp.setDate(dateVal_tmp.getDate()));
      let tomorrow = this.getDateFormat(tomorrow_tmp);
      //console.log("내일 : ", tomorrow);

      //let todayFin = this.reqDptDate;
      let todayFin = tomorrow;

      //console.log("today:",todayFin);
        let tStart = this.getDateFormat(this.productDetail.tripStart);
        let tEnd = this.getDateFormat(this.productDetail.tripEnd);
        let sStart = this.getDateFormat(this.productDetail.salesStart);
        let sEnd = this.getDateFormat(this.productDetail.salesEnd);
        let rtDate_s = "";
        let rtDate_e = "";

        if(tStart == sStart) {
          rtDate_s = sStart;
        }
        else if(tStart < sStart) {
          rtDate_s = sStart;
        }
        else if(tStart > sStart) {
          rtDate_s = tStart;
        }
        
        if(tEnd == sEnd) {
          rtDate_e = sEnd;
        }
        else if(tEnd > sEnd) {
          rtDate_e = sEnd;
        }
        else if(tEnd < sEnd) {
          rtDate_e = tEnd;
        }

        document.getElementById("rtDate").setAttribute("min", todayFin);
        //document.getElementById("rtDate").setAttribute("max", rtDate_e);
        document.getElementById("rtDate").setAttribute("max", this.calLimitedEnd);
        console.log("rtDateS:",rtDate_s,"rtDateE:",rtDate_e);
      }
    },
    //단순상품문의
    inquiryProduct() {
      // this.$router.push({path: '/inquiryProduct', query: {productId: this.productId, salesRoute:this.productDetail.salesRoute}});
      return this.$swal.fire('', '미리보기이므로 작동되지 않습니다', 'warning')
    },
    chekDptDate()
    {
      return this.$swal.fire('', '미리보기이므로 작동되지 않습니다', 'warning')
      // //alert("mFlagFin:"+this.memberFlag);
      // if(!this.reqName)
      // {
      //   this.$refs.txt_reqName.focus();
      //   return this.$swal.fire('', '성함을 입력해 주세요', 'warning');
      // }
      // else if(!this.reqTelNo) {
      //   this.$refs.txt_reqTelNo.focus();
      //   return this.$swal.fire('', '연락처을 입력해 주세요', 'warning')
      // }
      // else if(!/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(this.reqTelNo)) {
      //   this.reqTelNo = "";
      //   this.$refs.txt_reqTelNo.focus();
      //   return this.$swal.fire('','연락처가 불명확합니다', 'question');
      // }

      // else if(!this.reqEmail) {
      //   this.$refs.txt_cEmail.focus();
      //   return this.$swal.fire("","이메일을 입력하세요", "warning");
      // }
      // else if(this.reqEmail != "") {
      //   if(!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]+$/.test(this.reqEmail)) {
      //     this.reqEmail = "";
      //     this.$refs.txt_reqEmail.focus();
      //     return this.$swal.fire('','이메일 양식이 불명확합니다<br><small>이메일은 필수입력사항은 아닙니다만,</small><br><small>회원이메일로 포인트가 적립됩니다.</small>', 'question');
      //   }
      // }
      // //출발예정일 체크
      // else if(!this.selecDate) {
      //   this.$refs.txt_reqDptDate.focus();
      //   return this.$swal.fire('', '일자가 불명확합니다', 'warning')
      // }
      // // else if(this.productDetail.tripProp === "F" && this.reqRtnDate == "") {
      // //   this.$refs.txt_reqRtnDate.focus();
      // //   return this.$swal.fire('', '일자가 불명확합니다', 'warning')
      // // }
      // else if(!this.reqRtnDate == "") {
      //   this.$refs.txt_reqRtnDate.focus();
      //   return this.$swal.fire('', '일자가 불명확합니다', 'warning')
      // }      
      // // else {
      // //   this.reqReserve()
      // // }
      // //console.log("selecDate:",this.selecDate);
      // this.reqReserve()
    },
    //상품예약 상담요청(상담용)
    reqReserve()
    {
      //상담요청번호
      let timeStamp = "";
      let today = new Date();   
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime(); // 상품코드에 들어갈 타임스탬프
      let catCode = this.salesRoute;
      this.reqReserveCode = "RQ-"+catCode+"-"+ymd+"-"+timeStamp;
      //this.reqRsvInfo.insDate = `${yyyy}-${mm}-${dd}`;
      //console.log("reqReserveCode:",this.reqReserveCode);

      //리턴자유상품의 체류일자
      if(this.tripProp === "F") 
      {
        let dInterval = 0;
        //let dpDate = new Date(this.reqDptDate);
        let dpDate = new Date(this.selecDate);
        let rtDate = new Date(this.reqRtnDate);
        let dateDiff = dpDate.getTime() - rtDate.getTime();        
        dInterval = Math.abs(dateDiff / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
        this.dInterval = dInterval+1;
        console.log("dInterval:",this.dInterval)
      }

      //권종별 인원수
      //let i = 0;
      // let aduCnt = 0;
      // let chiCnt = 0;
      // let infCnt = 0;
      // let disCnt = 0;
      // let totCnt = 0;
      //console.log("ageNumber",this.ageNumber.length);
      if(this.ageNumber.length > 1) {
        this.aduCnt = (this.ageNumber[1] != null) ? this.ageNumber[1] : 0;
        this.chiCnt = (this.ageNumber[2] != null) ? this.ageNumber[2] : 0;
        this.infCnt = (this.ageNumber[3] != null) ? this.ageNumber[3] : 0;
        this.disCnt = (this.ageNumber[4] != null) ? this.ageNumber[4] : 0;        
      }
      else {
        this.aduCnt = (this.ageNumber[1] != null) ? this.ageNumber[1] : 0;
      }
      this.totCnt = this.aduCnt*1 + this.chiCnt*1 + this.disCnt*1; //inf는 제외
      //console.log("totCnt:",this.totCnt);

      //총원>15부터는 단체로 분류
      if(this.totCnt > 15 && this.salesRoute != "S") {
        this.$swal.fire('', '16인 이상은 별도문의를 해주십시오.<br><small>051-466-4602 또는 기타문의</small>', 'info')
        return false;
      }
      if(this.totCnt == 0) {
        this.$swal.fire('', '인원수를 확인해 주세요', 'warning')
        //this.$refs.txt_reqDptDate.focus();
        return false;
      }
      //console.log("aduCnt:",this.aduCnt,"/ chiCnt:",this.chiCnt, "/ infCnt:",this.infCnt, "/ disCnt:", this.disCnt, "/totCnt:",this.totCnt);

      //상품표시가격
      //console.log("prodPrice:",this.detailPrice);

      //권종별 상품가격
      console.log("selectedCalPdtPrice:",this.selectedCalPdtPrice);
      
      if(this.selectedCalPdtPrice.length > 1) {
        this.aduPrice = (this.selectedCalPdtPrice[0].pdtPrice != null) ? this.selectedCalPdtPrice[0].pdtPrice : 0;
        this.chiPrice = (this.selectedCalPdtPrice[1].pdtPrice != null) ? this.selectedCalPdtPrice[1].pdtPrice : 0;
        this.infPrice = (this.selectedCalPdtPrice[2].pdtPrice != null) ? this.selectedCalPdtPrice[2].pdtPrice : 0;
        this.disPrice = (this.selectedCalPdtPrice[3].pdtPrice != null) ? this.selectedCalPdtPrice[3].pdtPrice : 0;
      }
      else {
        this.aduPrice = (this.selectedCalPdtPrice[0].pdtPrice != null) ? this.selectedCalPdtPrice[0].pdtPrice : 0;
      }      
      console.log("aduPrice:",this.aduPrice,"/ chiPrice:",this.chiPrice, "/ infPrice:",this.infPrice, "/ disPrice:", this.disPrice);  

      //추가상품정보
      //console.log("pdtAddOptExisted:", this.pdtAddOptExisted);

      //선택옵션정보
      //console.log("etcOptionInfo:", this.etcOptionInfo);
      //pdtAddOptExisted.push(addOptInfo.filter(e => {return e.pdtCode === addOptCodes[i].pdtCode}));

      /** 옵션상품 중 유저선택옵션 */
      let selectedEtcOpt =  this.etcOptionInfo.filter(e=>{ return e.reqOptFlag === 'Y'});
      this.selectedEtcOpt = selectedEtcOpt;
      console.log("selectedEtcOpt:",this.selectedEtcOpt);

      //할인액정보
      //console.log("pdtPromoInfo:", this.pdtPromoInfo.promoRate);
      let promoRate = 0;
      let promoMethod = "";

      //let dptDate = this.reqDptDate;
      let dptDate = this.selecDate;
      let pStartDate = this.pdtPromoInfo.promoStart;
      let pEndDate = this.pdtPromoInfo.promoEnd;
      
      if(this.pdtPromoInfo.promoName != undefined) {
        //프로모션 적용가능 체크루틴
        if(dptDate >= pStartDate && dptDate <= pEndDate) {
          promoRate = this.pdtPromoInfo.promoRate;
          promoMethod = this.pdtPromoInfo.promoMethod;
        }
        else {
          promoRate = 0
          promoMethod = "A"
        }
        console.log("pdtPromoInfo:", promoRate, promoMethod);
      }
      // if(this.reqDptDate >= this.pdtPromoInfo) {
      //   alert("Promotion target");
      // }

      //마진정보
      //console.log("pdtPromoInfo:", this.pdtPromoInfo.promoRate);
      let basicMargin = 0;
      let marginMethod = "";
      if(this.productDetail.marginMethod != undefined) {
        //console.log("pdtPromoInfo:", this.pdtPromoInfo.promoRate, this.pdtPromoInfo.promoMethod);
        basicMargin = this.productDetail.basicMargin;
        marginMethod = this.productDetail.marginMethod;
        console.log("marginInfo:", basicMargin, marginMethod);
      }

      //부가세 정보
      let vatPrice = 0;
      if(this.productDetail.vatPrice != undefined && this.productDetail.vatPrice > 0) {
        //console.log("pdtPromoInfo:", this.pdtPromoInfo.promoRate, this.pdtPromoInfo.promoMethod);
        vatPrice = this.productDetail.vatPrice;
      }

      //기타문의
      console.log("Remarks:", this.reqRemarks)

      //가격초기화
      // this.total = 0;
      // this.totalPrice = 0;

      //가격구성: ((prodPrice + addOptPrice + etcOptPrice) * pdtPromoPrice Or -pdtPromoPrice) * margin

      //상품가격
      //let prodPrice = 0;
      if(this.tripProp === "D") {
        this.prodPrice = (this.aduPrice * this.aduCnt) + (this.chiPrice * this.chiCnt) + (this.infPrice * this.infCnt) + (this.disPrice * this.disCnt)
        console.log("prodPrice:", this.prodPrice);
        this.vatPrice = this.prodPrice * (vatPrice / 100);
        this.arr_reqGroupPrice = [this.aduPrice, this.chiPrice, this.infPrice, this.disPrice];
        console.log("arr_reqGroupPrice:", this.arr_reqGroupPrice);
      }
      else if(this.tripProp === "F")
      {
        let fromSelecDate = this.selecDate;
        let toSelecDate = this.reqRtnDate;
        
        let arr_rtnFreePdtPrice = []; //선택기간 일자별 상품가격
        arr_rtnFreePdtPrice = this.arr_selectedCalPdtPrice.filter(e=>{ return e.saleDate >= fromSelecDate && e.saleDate <= toSelecDate});
        this.arr_rtnFreePdtPrice = arr_rtnFreePdtPrice;

        let arr_rtnSoldOutDate = []; //선택기간 일자별 품절여부
        arr_rtnSoldOutDate = arr_rtnFreePdtPrice.filter(e=>{ return (e.sellStat === "N" && e.agegroup === "1")});
        //console.log("arr_rtnSoldOutDate:", arr_rtnSoldOutDate);

        if(arr_rtnSoldOutDate.length > 0) 
        {
          this.reqRtnDate = "";
          return this.$swal.fire('', '이용이 어려운 날<small>(완료, 미판매)</small>이 포함되어 있습니다', 'warning');
          // this.$swal.fire({
          //   title:"",
          //   text: "기간 중, 이용이 힘든 날<small>(미판매, 완료)</small>이 있습니다.",
          //   showCancelButton: true,
          //   confirmButtonColor: "royalblue",
          //   cancelButtonColor: "gray",
          //   confirmButtonText: '해당일 제외하고 진행',
          //   cancelButtonText: '재선택',
          //   icon: 'question',
          //   iconColor: 'royalblue'
          // }).then((result) =>{
          //   if(result.isConfirmed) 
          //   {
          //     let i=0;
          //     while(i < arr_rtnFreePdtPrice.length) {
          //       this.prodPrice += arr_rtnFreePdtPrice[i].pdtPrice*1;
          //       i++;
          //     }
          //     this.vatPrice = this.prodPrice * (vatPrice / 100);              
          //   }
          //   else
          //   {
          //     alert("Reselect");
          //     return false;
          //   }
          // })
        }
        else
        {
          //console.log("arr_rtnFreePdtPrice:", arr_rtnFreePdtPrice);
          let i=0;
          let priceFRtn = 0;
          while(i < arr_rtnFreePdtPrice.length) 
          {
            //this.prodPrice += arr_rtnFreePdtPrice[i].pdtPrice*1;
            // this.prodPrice = (this.aduPrice * this.aduCnt) + (this.chiPrice * this.chiCnt) + (this.infPrice * this.infCnt) + (this.disPrice * this.disCnt)
            if(arr_rtnFreePdtPrice[i].agegroup == '1')
            {
              priceFRtn += arr_rtnFreePdtPrice[i].pdtPrice * this.aduCnt;
            }
            else if(arr_rtnFreePdtPrice[i].agegroup == '2')
            {
              priceFRtn += arr_rtnFreePdtPrice[i].pdtPrice * this.chiCnt;
            }
            else if(arr_rtnFreePdtPrice[i].agegroup == '3')
            {
              priceFRtn += arr_rtnFreePdtPrice[i].pdtPrice * this.infCnt;
            }
            else if(arr_rtnFreePdtPrice[i].agegroup == '4')
            {
              priceFRtn += arr_rtnFreePdtPrice[i].pdtPrice * this.disCnt;
            }
            i++;
          }
          this.prodPrice = priceFRtn;

          console.log("prodPrice:", this.prodPrice);
          this.vatPrice = this.prodPrice * (vatPrice / 100);
        }
      }
      else
      {
        this.$swal.fire('죄송합니다...', '현재 상담예약이 불가능합니다.<br>전화연락 또는 기타문의를 이용하여 주십시오. ', 'warning')
      }

      // //추가상품금액(홀딩->상세정책필요)
      // // console.log("pdtAddOptExisted:", this.pdtAddOptExisted);
      // // console.log("addOptCodes:", this.addOptCodes);
      // let addOptPrice = 0;
      // let addOptPriceT1 = 0;
      // let addOptPriceT2 = 0;
      // let k=0;
      // let aduAddPrc = [];
      // let chiAddPrc = [];
      // let infAddPrc = [];
      // let disAddPrc = [];
      // let aduAddOptPrc = 0;
      // let chiAddOptPrc = 0;
      // let infAddOptPrc = 0;
      // let disAddOptPrc = 0;

      // for(k=0; k < this.pdtAddOptExisted.length; k++) {
      //   if(this.pdtAddOptExisted[k].length === 4) {
      //       aduAddPrc = this.pdtAddOptExisted[k][0].pdtPrice;
      //       chiAddPrc = this.pdtAddOptExisted[k][1].pdtPrice;
      //       infAddPrc = this.pdtAddOptExisted[k][2].pdtPrice;
      //       disAddPrc = this.pdtAddOptExisted[k][3].pdtPrice;

      //       aduAddOptPrc = aduAddPrc * this.aduCnt;
      //       chiAddOptPrc = chiAddPrc * this.chiCnt;
      //       infAddOptPrc = infAddPrc * this.infCnt;
      //       disAddOptPrc = disAddPrc * this.disCnt;

      //       let addOptPriceT1_tmp = aduAddOptPrc + chiAddOptPrc + infAddOptPrc + disAddOptPrc;
      //       addOptPriceT1 = addOptPriceT1 + addOptPriceT1_tmp;
      //       //console.log("addOptPriceTP1:",addOptPriceT1);
      //   }
      //   else {
      //     aduAddPrc = this.pdtAddOptExisted[k][0].pdtPrice;
      //     //console.log(k,":", this.pdtAddOptExisted[k][0].pdtPrice);
      //     let addOptPriceT2_tmp = aduAddPrc * this.totCnt;
      //     addOptPriceT2 = addOptPriceT2 + addOptPriceT2_tmp;

      //     //addOptPriceT2 = addOptPriceT2 + addOptPriceT2_tmp;
      //     console.log("addOptPriceTP2:",addOptPriceT2);
      //   }
      //   addOptPrice = addOptPriceT1 + addOptPriceT2;
      // }
      // console.log("addOptPrice:", addOptPrice);

      //선택옵션가격
      // let etcOptPrice = 0;
      // let selectedEtcOptInfo = this.etcOptionInfo.filter(e => {return e.reqOptFlag === 'Y'});

      // this.selectedEtcOptInfo = selectedEtcOptInfo;
      // let n = 0;
      // while(n < selectedEtcOptInfo.length) {
      //   let etcOptPdt_tmp = selectedEtcOptInfo[n].etcOptPrice * this.totCnt;
      //   etcOptPrice = etcOptPrice + etcOptPdt_tmp;
      //   n++;
      // }

      //예상가격(상담용: 총가격->인당마진->프로모션)
      let reqPdtPrice = 0;
      //let reqPdtPrice_tmp = this.prodPrice + addOptPrice + etcOptPrice;
      //추가상품금액(홀딩->상세정책필요, 선택옵션 합산은 상담 시 결정)
      let reqPdtPrice_tmp = this.prodPrice;
      //console.log("reqPdtPrice_tmp:", reqPdtPrice_tmp,"/prodPrice:", this.prodPrice);
      let reqPdtPrice_tmp2 = 0;
      let pdtPromo = 0;
      let pdtMargin = 0;

      //여행속성에 따라 마진계산분리
      if(this.tripProp === "D")
      {
        if(marginMethod === "A") 
        {
          pdtMargin = (basicMargin*this.aduCnt) + (basicMargin*this.chiCnt) + (basicMargin*this.infCnt) + (basicMargin*this.disCnt);
          reqPdtPrice_tmp2 = reqPdtPrice_tmp + pdtMargin;
        }
        else if(marginMethod === "P") 
        {
          //0%처리
          if(basicMargin == 0) {
            pdtMargin = 0;
            reqPdtPrice_tmp2 = reqPdtPrice_tmp + pdtMargin;
          }
          else{
            //pdtMargin = (reqPdtPrice_tmp * (basicMargin / 100));
            pdtMargin = ((this.aduPrice * (basicMargin / 100))*this.aduCnt) + ((this.chiPrice * (basicMargin / 100))*this.chiCnt) + ((this.infPrice * (basicMargin / 100))*this.infCnt) + ((this.disPrice * (basicMargin / 100))*this.disCnt);
            reqPdtPrice_tmp2 = reqPdtPrice_tmp + pdtMargin;
          }
        }
        else {
          pdtMargin = 0;
          reqPdtPrice_tmp2 = reqPdtPrice_tmp;
        }
      }
      else
      {
        if(marginMethod === "A") 
        {
          pdtMargin = (this.totCnt * basicMargin)*this.dInterval;
          reqPdtPrice_tmp2 = reqPdtPrice_tmp + pdtMargin;
        }
        else if(marginMethod === "P") 
        {
          //0%처리
          if(basicMargin == 0) {
            pdtMargin = 0;
            reqPdtPrice_tmp2 = reqPdtPrice_tmp + pdtMargin;
          }
          else{
            pdtMargin = (reqPdtPrice_tmp * (basicMargin / 100));
            // pdtMargin = ((this.aduPrice * (basicMargin / 100))*this.aduCnt) + ((this.chiPrice * (basicMargin / 100))*this.chiCnt) + ((this.infPrice * (basicMargin / 100))*this.infCnt) + ((this.disPrice * (basicMargin / 100))*this.disCnt);
            reqPdtPrice_tmp2 = reqPdtPrice_tmp + pdtMargin;
          }
        }
        else {
          pdtMargin = 0;
          reqPdtPrice_tmp2 = reqPdtPrice_tmp;
        }
      }
      //console.log("reqPdtPrice_tmp2:",reqPdtPrice_tmp2,"/reqPdtPrice_tmp2:",reqPdtPrice_tmp2);

      if(promoMethod === "A") {
        pdtPromo = promoRate;
        reqPdtPrice = reqPdtPrice_tmp2 - promoRate + this.vatPrice;
      }
      else if(promoMethod === "P") {
        if(promoRate == 0) {
          pdtPromo = 0;
          reqPdtPrice = reqPdtPrice_tmp2 + this.vatPrice;
        }
        else {
          pdtPromo = (reqPdtPrice_tmp2 * (promoRate/100));
          reqPdtPrice = reqPdtPrice_tmp2 - pdtPromo + this.vatPrice;
        }
      }
      else {
        //reqPdtPrice_tmp2 = reqPdtPrice_tmp;
        pdtPromo = 0;
        reqPdtPrice = reqPdtPrice_tmp2;
      }

      this.pdtMargin = pdtMargin;
      this.pdtPromo = pdtPromo;
      //console.log("pdtMargin:",this.pdtMargin,"/pdtPromo:",this.pdtPromo);
      console.log("reqPdtPrice_tmp2:",reqPdtPrice_tmp2,"/reqPdtPrice_tmp:",reqPdtPrice_tmp);

      /*****************상담예상가격 ********************/
      console.log("reqPdtPrice:",reqPdtPrice);

      // 주문처리
      if(reqPdtPrice != undefined) {
        this.order(this.reqReserveCode, reqPdtPrice);
        //this.order(this.reqReserveCode, reqPdtPrice);
      }
    },
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },
    order(reqReserveCode, reqPdtPrice) 
    {
      //주문처리
      let reqPdtPriceFin_tmp = 0;
      let pdtPromo = pdtPromo;

      reqPdtPriceFin_tmp = reqPdtPrice;
      //reqPromo_tmp = reqPdtPromo;
      this.reqRtnDate = (this.reqRtnDate == "") ? null : this.reqRtnDate;

      //100원 미만 절사처리
      let reqPdtPriceFin = Math.floor(reqPdtPriceFin_tmp/100) * 100;
      //this.pdtPromo = Math.floor(reqPromo_tmp/100) * 100;

      //console.log("reqPdtPriceFin:",reqPdtPriceFin);
      if(this.selecDate == undefined) {
        this.$swal.fire('', '출발예정일이 불명확합니다', 'warning')
      }
      if(reqReserveCode === undefined || reqPdtPrice === undefined || reqPdtPrice < 0 || this.productDetail.pdtCode === undefined) {
        var swal = require('sweetalert2');
        swal.fire({
          text: '상담신청 내용 중 오류가 있습니다.<br>확인 후 재신청하여 주십시오',
          icon: 'question'
        });
        return false;
      }
      else {
        this.$swal.fire({
          //title: this.productDetail.pdtNameKor,
          title: `${this.productDetail.pdtNameKor}<small class="text-secondary">(상담금액: ${this.getCurrencyFormat(reqPdtPriceFin)})</small>`,
          html: '<i class="bi bi-check2-circle text-danger"></i> 표시된 상담금액은 선택옵션제외금액<small>(100원미만 절사)</small>이며,<br>결제액은 <b>상담 후</b> 안내됩니다.',
          showCancelButton: true,
          confirmButtonText: '요청',
          cancelButtonText: '취소',
        }).then(async (result) => {
          /* 상담신청 데이타 처리 */
          if (result.isConfirmed) {
            if(this.memberFlag === 'N') //비회원 상담요청처리
            {
              let insResNonMember = await this.$api("/api/insResNonMember", {param: [
                this.reqReserveCode,this.reqEmail,this.reqTelNo,this.reqName
              ]});
              console.log("insResNonMember:",insResNonMember);
              if(Object.keys(insResNonMember)[0] === 'error') 
              {
                this.$swal.fire('상담요청등록오류','죄송하지만 재요청을 해주세요<br>연속오류 발생 시 연락처<br> 051-466-4602)', 'error')
                return false;
              }
            }

            //상담마스타
            console.log("memberFlag:",this.memberFlag);
            
            let resIns_reqConsultReserveM = await this.$api("/api/reqConsultReserve_m", 
              {param: [this.reqReserveCode,this.productId,"IQ",this.selecDate,this.infCnt,this.aduCnt,this.chiCnt,this.disCnt,this.totCnt,this.aduPrice,this.chiPrice,this.infPrice,this.disPrice,
              this.reqRemarks,this.userInfo,this.reqName,this.reqTelNo,this.pdtMargin,this.vatPrice,this.reqRtnDate, this.reqEmail,this.tripProp,this.pdtPromo,this.productDetail.stayCnt,reqPdtPriceFin,reqPdtPriceFin,'N',this.memberFlag]});
            if(Object.keys(resIns_reqConsultReserveM)[0] === 'error') {
              this.$swal.fire('상담요청등록오류','죄송하지만 재요청을 해주세요<br>연속오류 발생 시 연락처<br> (051-466-4602)', 'error')
              return false;
            }
            else 
            {
              //상담상세데이타입력처리
              if(this.tripProp === "D")
              {
                let resIns_reqConsultReserveD = await this.$api("/api/reqConsultReserve_d_D", 
                {param: [this.reqReserveCode,'0',this.arr_reqGroupPrice[0],this.arr_reqGroupPrice[1],this.arr_reqGroupPrice[2],this.arr_reqGroupPrice[3],this.selecDate]});
                if(Object.keys(resIns_reqConsultReserveD)[0] === 'error') {
                  this.$swal.fire('상담요청등록오류','죄송하지만 재요청을 해주세요<br>연속오류 발생 시 연락처<br> (051-466-4602)', 'error')
                  return false;
                }
              }
              else
              {
                let arr_selectedDate = this.arr_rtnFreePdtPrice.filter((item) => item.agegroup === '1');
                console.log("arr_selectedDate:",arr_selectedDate);

                let obj_rtnFreePrice = new Object(); 
                let i=0;
                while(i<arr_selectedDate.length)
                {
                  let key = arr_selectedDate[i].saleDate;
                  obj_rtnFreePrice[key] = this.arr_rtnFreePdtPrice.filter((item) => item.saleDate === arr_selectedDate[i].saleDate);
                  i++;
                }
                //console.log("obj_rtnFreePrice:",obj_rtnFreePrice,"/obj_rtnFreePrice.length:",Object.keys(obj_rtnFreePrice).length);

                let keys = Object.keys(obj_rtnFreePrice);
                let values = Object.values(obj_rtnFreePrice);
                console.log("keys:",keys,"/values:",values);

                if(Object.keys(obj_rtnFreePrice).length == 1)
                {
                  let resIns_reqConsultReserveD = await this.$api("/api/reqConsultReserve_d_D", {
                    param: [this.reqReserveCode,'0',values[0][0].pdtPrice,'0','0','0',values[0][0].saleDate]});
                  console.log("resIns_reqConsultReserveD:",resIns_reqConsultReserveD);
                  if(Object.keys(resIns_reqConsultReserveD)[0] === 'error') 
                  {
                    this.$swal.fire('상담요청등록오류','죄송하지만 재요청을 해주세요<br>연속오류 발생 시 연락처<br> (051-466-4602)', 'error')
                    return false;
                  }
                }
                else if(Object.keys(obj_rtnFreePrice).length > 1) {
                  let j=0;
                  //let k=0;
                  while(j < Object.keys(obj_rtnFreePrice).length)
                  {
                    let resIns_reqConsultReserveD = await this.$api("/api/reqConsultReserve_d_D", {
                    param: [this.reqReserveCode,j,values[j][0].pdtPrice,values[j][1].pdtPrice,values[j][2].pdtPrice,values[j][3].pdtPrice,values[j][0].saleDate]});
                    console.log("resIns_reqConsultReserveD:",resIns_reqConsultReserveD);
                    if(Object.keys(resIns_reqConsultReserveD)[0] === 'error') 
                    {
                      alert('죄송합니다.\n상담등록 중 오류가 발생했습니다. \n기타문의 또는 유선(051-466-4602)으로 문의해 주세요.')
                      return false;
                    }
                    j++;
                  }
                }
              }

              // 추가상품 홀딩
              // if(this.addOptCodes.length > 0)
              // {
              //   //상담 추가상품정보
              //   let resIns_reqConsultReserveDaddOpt = {}
              //   let i=0;
              //   while(i < this.addOptCodes.length) 
              //   {
              //     if(this.pdtAddOptExisted[i].length > 1) {
              //       resIns_reqConsultReserveDaddOpt = await this.$api("/api/reqConsultReserve_d_addOpt", 
              //       //reqReserveCode, reqSubCode, reqAddOptCode, adultFee, childFee, infFee, discountFee,addUseFlag
              //         {param: [this.reqReserveCode,i,this.addOptCodes[i].pdtCode,this.pdtAddOptExisted[i][0].pdtPrice,this.pdtAddOptExisted[i][1].pdtPrice,this.pdtAddOptExisted[i][2].pdtPrice,
              //         this.pdtAddOptㅗㅗㅗㅗㅗExisted[i][3].pdtPrice,"Y"]});
              //     }
              //     else {
              //       resIns_reqConsultReserveDaddOpt = await this.$api("/api/reqConsultReserve_d_addOpt", 
              //       //reqReserveCode, reqSubCode, reqAddOptCode, adultFee, childFee, infFee, discountFee,addUseFlag
              //         {param: [this.reqReserveCode,i,this.addOptCodes[i].pdtCode,this.pdtAddOptExisted[i][0].pdtPrice,0,0,0,"Y"]});
              //     }
              //     if(Object.keys(resIns_reqConsultReserveDaddOpt)[0] === 'error') {
              //       alert("죄송합니다. 상담신청에 오류가 발생했습니다.");
              //       return false;
              //     }
              //     i++;
              //   }
              // }

              if(this.selectedEtcOpt.length > 0)
              {
                //상담 선택옵션정보
                let resIns_reqConsultReserveDetcOpt = {}
                let j=0;
                while(j < this.selectedEtcOpt.length) 
                {
                    resIns_reqConsultReserveDetcOpt = await this.$api("/api/reqConsultReserve_d_etcOpt", 
                    //reqReserveCode, reqSubCode, etcOptName, etcOptPrice, etcUseFlag
                      {param: [this.reqReserveCode,j,this.selectedEtcOpt[j].etcOptName,this.selectedEtcOpt[j].etcOptPrice,"Y"]});
                  if(Object.keys(resIns_reqConsultReserveDetcOpt)[0] === 'error') {
                    alert("죄송합니다. 상담신청에 오류가 발생했습니다.");
                    return false;
                  }
                  j++;
                }
              }

              // //상담요청 - 예약정보 복사저장
              // let copyReserve_m = await this.$api("/api/copyReserve_m", {param: [this.reqRsvCode]});
              
              // if(Object.keys(copyReserve_m)[0] === 'error')
              // {
              //   this.$swal.fire('', '오류발생(ReserveMaster Copy)<br>시스템 관리자에게 문의바랍니다.', 'error')
              //   return false;
              // }
              // else
              // {
              //   let copyReserve_d = await this.$api("/api/copyReserve_d", {param: [this.reqRsvCode]});
              //   if(Object.keys(copyReserve_d)[0] === 'error')
              //   {
              //     this.$swal.fire('', '오류발생(ReserveDetail Copy)<br>시스템 관리자에게 문의바랍니다.', 'error')
              //     return false;
              //   }
              //   else
              //   {
              //     let copyReserve_d_addOpt = await this.$api("/api/copyReserve_d_addOpt", {param: [this.reqRsvCode]});
              //     if(Object.keys(copyReserve_d_addOpt)[0] === 'error')
              //     {
              //       this.$swal.fire('', '오류발생(AddOption Copy)<br>시스템 관리자에게 문의바랍니다.', 'error')
              //       return false;
              //     }
              //     else
              //     {
              //       let copyReserve_d_etcOpt = await this.$api("/api/copyReserve_d_etcOpt", {param: [this.reqRsvCode]});
              //       if(Object.keys(copyReserve_d_etcOpt)[0] === 'error')
              //       {
              //         this.$swal.fire('', '오류발생(EtcOption Copy)<br>시스템 관리자에게 문의바랍니다.', 'error')
              //         return false;
              //       }
              //     }
              //   }
              // }
              // console.log("Reserve Copy End");

              this.$swal.fire('상담요청등록완료','감사합니다<br>빠른 시간안에 확인연락을 드리겠습니다.', 'success');
              this.$router.push({path: '/'});
            }
          }
        })
      }
    }
  }
}
</script>