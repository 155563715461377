<template>
  <div ref="tableContainer" id="profitAndLoss">

    <div class="text-end print-hide">
      <button type="button" class="btn btn-outline-primary btn-sm m-1" style="font-size: smaller;" @click="exportToExcel">
        Excel <i class="bi bi-filetype-xlsx"></i></button>
      <button type="button" class="btn btn-outline-success btn-sm m-1" style="font-size: smaller;" @click="confirmAndPrint">
        Print <i class="bi bi-printer"></i></button>
    </div>

    <table class="table table-bordered border-secondary w-100 h-auto text-center">
      <tr>
        <td colspan="13">
          <div>
            <b class="fs-3">{{ this.sMonth }} 수지계산서</b>
            <div class="mt-1 text-end">출력일자: {{ today }}</div>
            <!-- <div class="mt-1 text-end" v-if="dealInfoDetail.dSetDate != null || dealInfoDetail.dSetDate != ''">정산일자: {{ this.getDateFormat(dealInfoDetail.dSetDate) }}</div> -->
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="3" style="vertical-align: middle;"></td>        
        <th style="background-color:lightgray; vertical-align: middle;">부서</th>
        <td colspan="2" style="vertical-align: middle;">{{ this.handlerTeam }}</td>
        <th style="background-color:lightgray; vertical-align: middle;">담당자</th>
        <td colspan="2" style="vertical-align: middle;">{{ this.handlerName }}</td>
        <th style="background-color:lightgray; vertical-align: middle;">기준환율</th>
        <td colspan="3" style="vertical-align: middle;">[JPY: {{ this.curJPY }} ] / [USD: {{ this.curUSD }}] / [TWD: {{ this.curTWD }}]</td>
      </tr>

      <tr>
        <td colspan="7"></td>
      </tr>

      <!-- 수지계산결과 리스트 -->
      <tr style="background-color: lightgray;">
        <th style="width: 3%">No</th>
        <th style="width: 5%">기준일자</th>
        <th style="width: 12%">행사명</th>
        <th style="width: 8%">판매금액(KRW)</th>
        <th style="width: 8%">지출금액(KRW)</th>
        <th style="width: 8%">지출금액(JPY)</th>
        <th style="width: 8%">지출금액(USD)</th>
        <th style="width: 8%">지출금액(TWD)</th>
        <th style="width: 8%">알선수수료(KRW)</th>
        <th style="width: 8%">공급가액(KRW)</th>
        <th style="width: 8%">부가세(KRW)</th>
        <th style="width: 8%">미수금(KRW)</th>
        <th style="width: 8%">상품판매수익(KRW)</th>
      </tr>
      <tr :key="i" v-for="(pl, i) in this.settleDataInfo">
        <td>{{ i+1 }}</td>

        <td v-if="this.sDuration === 'G'">{{ pl.startDate }}</td>
        <td v-else-if="this.sDuration === 'W'">{{ pl.settleDate }}</td>
        <td v-else-if="this.fFlag === 'Y'">{{ this.getDateFormat(pl.plDate) }}</td>
        
        <td v-if="this.fFlag === 'Y'">{{ pl.plName }}</td>
        <td v-else>{{ pl.dealNameKor }}</td>

        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(pl.priceTot) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ pl.priceTot }}</td>

        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(pl.dPrice_curKRW_sum) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ pl.dPrice_curKRW_sum }}</td>

        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">¥</span>{{ this.getCurrencyFormat(pl.dPrice_curJPY_sum) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">¥</span>{{ pl.dPrice_curJPY_sum }}</td>
        
        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">US$</span>{{ this.getCurrencyFormat(pl.dPrice_curUSD_sum) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">US$</span>{{ pl.dPrice_curUSD_sum }}</td>
        
        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">TW$</span>{{ this.getCurrencyFormat(pl.dPrice_curTWD_sum) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">TW$</span>{{ pl.dPrice_curTWD_sum }}</td>
        
        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(pl.searchersFee) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ pl.searchersFee }}</td>
        
        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(pl.providePrice) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ pl.providePrice }}</td>
        
        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(pl.vatPrice) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ pl.vatPrice }}</td>
        
        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(pl.accountRecievalbe) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ pl.accountRecievalbe }}</td>
        
        <td class="text-end" v-if="this.fFlag === 'Y'"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(pl.profit) }}</td>
        <td class="text-end" v-else><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ pl.profit }}</td>
      </tr>

      <tr>
        <th colspan="3" style="background-color: lightgray;">합&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;계</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.sales_KRW_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.dPrice_curKRW_sum_n_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">¥</span>{{ this.getCurrencyFormat(this.dPrice_curJPY_sum_n_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">US$</span>{{ this.getCurrencyFormat(this.dPrice_curUSD_sum_n_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">TW$</span>{{ this.getCurrencyFormat(this.dPrice_curTWD_sum_n_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.searchersFee_n_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.providePrice_n_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.vatPrice_n_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.accountRecievalbe_n_tot) }}</th>
        <th class="text-end"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.profit_n_tot) }}</th>
      </tr>
      <tr>
        <th colspan="11" rowspan="2" style="background-color: white;;"></th>
        <th class="text-center" style="background-color: lightgray;">여행손실금</th>
        <th class="text-end text-danger"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.tripLoss_n_tot) }}</th>
      </tr>
      <tr>
        <th class="text-center" style="background-color: lightgray;">여행정산금</th>
        <th class="text-end text-primary"><span style="font-size: smaller; color: red; padding-right:0;">₩</span>{{ this.getCurrencyFormat(this.tripProfit_n_tot) }}</th>
      </tr>
    </table>

    <table class="table table-bordered border-secondary w-100 h-auto text-center">
      <tr style="background-color: lightgray;">
        <th>비&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;고</th>
      </tr>
      <tr>
        <td>
          <textarea class="form-control" placeholder="1,000자 이내" style="font-size:small; height:auto" v-model="this.profitLossRemarks" maxlength="1000"></textarea>
        </td>
      </tr>      
    </table>
  </div>
  <!-- <div class="text-center">
    <button type="button" class="btn btn-secondary btn-lg print-hide" style="font-size: medium;" @click="goBack()"><b>뒤로가기</b></button>
  </div> -->
</template>

<script>
import * as XLSX from "xlsx"
import moment from 'moment';

//import {genCode} from '../assets/js/genCode'

export default {
  data() {
    return {
      handlerInfo: [],              //담당자정보
      settleDataInfo: [],           //수지계산결과
      profitLossRemarks: "",        //수지계산서 비고


      dealCode: "",                 //행사코드
      dealInfoDetail: [],           //거래상세정보(마스터)
      dDepositInfo: [],             //입금내역정보
      sumOfConvMoney: 0,            //입금총액(원화환산)
      sumOfConvOutStMoney: 0,       //미수금총액(원화환산)
      expTotal: 0,                  //지출총액(원화환산)
      vatPrice: 0,                  //부가가치세

      dWInfoByCurrency: [],         //지출된 통화코드
      tot_dWPriceByCur: [],         //지출통화별총액

      //합계정보
      sales_KRW_tot: 0,             //매출총액합
      dPrice_curKRW_sum_n_tot: 0,   //원화지출총합
      dPrice_curJPY_sum_n_tot: 0,   //엔화지출총합
      dPrice_curUSD_sum_n_tot: 0,   //달러지출총합
      dPrice_curTWD_sum_n_tot: 0,   //대만달러지출총합
      searchersFee_n_tot: 0,        //알선수수료총합
      providePrice_n_tot: 0,        //공급가액총합
      vatPrice_n_tot: 0,            //부가세총합
      accountRecievalbe_n_tot: 0,   //미수금총합
      profit_n_tot: 0,              //상품판매총합

      tripProfit_n_tot: 0,          //여행손실금
      tripLoss_n_tot: 0,            //여행정산금

      currencyInfo: [],             //적용환율정보
      curJPY: 0,                    //외환환율
      curUSD: 0,
      curTWD: 0,

      handlerName: "",              //담당자명
      handlerTeam: "",              //담당팀
    }
  },
  created() {
    this.user = this.$store.state.user
    console.log("user:",this.user)

    this.fFlag = this.$route.query.fFlag || "";         //확정수지계산서여부

    this.plCode = this.$route.query.pCode || "";
    this.sDuration = this.$route.query.sD || "";
    this.sMonth = this.$route.query.sM || "";
    this.sNtCode = this.$route.query.sNt || "";
    this.sArCode = this.$route.query.sAr || "";
    this.sChPerson = this.$route.query.sCp || "";

    if(this.fFlag === "Y")
    {
      this.searchSettleData_fixed(this.plCode);
    }
    else
    {
      this.searchSettleData(this.sDuration, this.sMonth, this.sChPerson);
      this.getHandlerInfo(this.sChPerson)     //담당자정보
    }
    this.getCurrencyRate()

    // this.getDealInfoDetail(this.dealCode)   //행사정보
    // this.getDepositInfo(this.dealCode)      //입출금내역

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")

  },
  mounted() {
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
    document.getElementById("top").style.display = "none";
  },
  methods: {
    //mixins 공통함수
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value)
    {
      return this.$currencyFormat(value);
    },
    getDeCurrencyFormat(value)
    {
      return this.$deCurrencyFormat(value);
    },
    getDayOfWeekFormat(text)
    {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text)
    {
      return this.$numberOfWeekFormat(text);
    },

    // 적용환율체크
    async getCurrencyRate(){
      let fCat = "Currency"
      let fUseFlag = "Y"
      let currencyInfo = await this.$api("/api/currencyInfo_new", {param: [fCat, fUseFlag]})
      if(currencyInfo.length > 0)
      {
        this.currencyInfo = currencyInfo

        let curJPY = this.currencyInfo.filter(item => {
          return item.fCodeNo === "JPY"
        })
        this.curJPY = curJPY[0].feeRate

        let curUSD = this.currencyInfo.filter(item => {
          return item.fCodeNo === "USD"
        })
        this.curUSD = curUSD[0].feeRate

        let curTWD = this.currencyInfo.filter(item => {
          return item.fCodeNo === "TWD"
        })
        this.curTWD = curTWD[0].feeRate
      }
      // console.log("currencyInfo:", this.currencyInfo, "JPY:::::::::::::", this.curJPY)
    },
    // //수지계산서 코드생성
    // async genCode(param) {
    //   let chkFlag = "N";
    //   let rtnCode = "";

    //   for (let attempts = 0; attempts < 500 && chkFlag === "N"; attempts++) {
    //     let today = new Date();
    //     let yy = today.getFullYear().toString().slice(-2);
    //     let mm = today.getMonth() + 1;
    //     let dd = today.getDate();
    //     let ymd = `${yy}${mm}${dd}`;
    //     let dCd = param + ymd;

    //     rtnCode = await genCode(this.$api, dCd);
    //     console.log("rtnCode:",rtnCode)

    //     let chk_genCode = await this.$api("/api/checkDub_profitLossCode", { param: [rtnCode] });

    //     if (chk_genCode.length > 0) {
    //       chkFlag = "N";
    //     } else {
    //       chkFlag = "Y";
    //     }
    //   }
    //   //console.log("chkFlag:", chkFlag)
    //   if (chkFlag === "N") 
    //   {
    //     this.$swal.fire("","코드의 생성횟수 한계에 도달하였습니다.<br>관리자에게 문의하여 주세요.","warning")
    //     return false;
    //   } 
    //   else 
    //   {
    //     return rtnCode
    //   }
    // },

    //수지계산서 담당자정보
    async getHandlerInfo(hId){
      let handlerInfo = await this.$api("/api/handlerInfo_new", { param: [hId]} )

      if(handlerInfo.length)
      {
        //this.handlerInfo = handlerInfo[0]
        this.handlerName = handlerInfo[0].handlerNameKor
        this.handlerTeam = handlerInfo[0].tName
      }
      console.log("handlerInfo:", this.handlerInfo)
    },

    async searchSettleData_fixed(plCode){
      let plFix = "Y"
      let plUseFlag = "Y"
      let profitLoss_m = await this.$api("/api/fixedProfitLoss_m", {param: [ plCode, plFix, plUseFlag]})
      
      if(profitLoss_m.length > 0)
      {
        this.handlerName = profitLoss_m[0].handlerNameKor
        this.handlerTeam = profitLoss_m[0].tName
        
        this.curJPY = profitLoss_m[0].plExJPY
        this.curUSD = profitLoss_m[0].plExUSD
        this.curTWD = profitLoss_m[0].plExTWD

        this.sales_KRW_tot = profitLoss_m[0].sales_KRW_tot
        this.dPrice_curKRW_sum_n_tot = profitLoss_m[0].dPrice_curKRW_sum_n_tot
        this.dPrice_curJPY_sum_n_tot = profitLoss_m[0].dPrice_curJPY_sum_n_tot
        this.dPrice_curUSD_sum_n_tot = profitLoss_m[0].dPrice_curUSD_sum_n_tot
        this.dPrice_curTWD_sum_n_tot = profitLoss_m[0].dPrice_curTWD_sum_n_tot

        this.searchersFee_n_tot = profitLoss_m[0].searchersFee_n_tot
        this.providePrice_n_tot = profitLoss_m[0].providePrice_n_tot
        this.vatPrice_n_tot = profitLoss_m[0].vatPrice_n_tot
        this.accountRecievalbe_n_tot = profitLoss_m[0].accountRecievalbe_n_tot
        this.profit_n_tot = profitLoss_m[0].profit_n_tot

        this.tripProfit_n_tot = profitLoss_m[0].tripProfit_tot
        this.tripLoss_n_tot = profitLoss_m[0].tripLoss_tot

        this.profitLossRemarks = profitLoss_m[0].plRemarks

        // 마스터가 있으면 디테일을 추출
        let fixed_profitLoss_d = await this.$api("/api/fixedProfitLoss_d", {param: [ plCode ]})

        if(fixed_profitLoss_d.length > 0)
        {
          this.settleDataInfo = fixed_profitLoss_d
        }
      }
      console.log("profitLoss_m:", profitLoss_m, "settleDataInfo:", this.settleDataInfo)
    },

    //정산데이타 검색(프로시저이용)
    async searchSettleData(sD, sM, sP){
      //console.log(sD + "/" + sM + "/" + sP)
      let sNtCd = ""
      let sArCd = ""
      let sStart = ""
      let sEnd = ""
      let dName = ""
      let sName = ""
      let sCon = ""

      //alert(this.searchMonth)
      let settleDataInfo_tmp = await this.$api("/api/proc_searchSettleData", {param: [
        sD, sStart, sEnd, dName, sP, sNtCd, sArCd, sName, sCon,
        sM
      ]});
      //console.log("settleDataInfo_tmp:",settleDataInfo_tmp, "countOfsettleDataInfo_tmp:", settleDataInfo_tmp.length)
      
      if(settleDataInfo_tmp.length > 0)
      {
        if (settleDataInfo_tmp.length > 0) {
          this.settleDataInfo = settleDataInfo_tmp[1];
          //this.rowData = this.settleDataInfo;
          //console.log("settleDataInfo:", this.settleDataInfo);

          let pNlStatement = []         //지출금액정보
          let pNlStatement_d = []       //입금금액정보
          let dPrice_cur_sum = []
          let dPrice_exKRW_sum_tmp = []

          let incomeTot = 0                  //입금총액

          for (let i = 0; i < this.settleDataInfo.length; i++) {
            // API 호출로 수지계산서 데이터 가져오기
            let dFlag_w = "W"         //지출정보
            let dFlag_d = "D"         //입금정보
            
            let pNlStatement_tmp = await this.$api("/api/proc_profitAndlossStatment", {
              param: [this.settleDataInfo[i].dealCode, "Y", dFlag_w]
            });
            pNlStatement = pNlStatement_tmp[0]

            let pNlStatement_d_tmp = await this.$api("/api/proc_profitAndlossStatment", {
              param: [this.settleDataInfo[i].dealCode, "Y", dFlag_d]
            });
            pNlStatement_d = pNlStatement_d_tmp[0]
            if(pNlStatement_d.length > 0)
            {
              incomeTot = pNlStatement_d[0].dPrice_exKR_sum*1
            }
            else   //입금액 정보가 없는 경우 대응
            {
              incomeTot = 0
            }
            //pNlStatement_d = pNlStatement_tmp_d[0]

            // 입금정보는 있으므로 지출정보는 이미 추출
            dPrice_cur_sum = pNlStatement
            //.filter(item => item.dFlag === "W")
            .reduce((acc, item) => {
              acc[item.dCurCd] = (acc[item.dCurCd] || 0) + item.dPrice_cur_sum
              return acc
            }, {})        //객체의 초기값 {}

            dPrice_exKRW_sum_tmp = pNlStatement
            .reduce((acc, item) => {
              // acc[item.dCurCd] = (acc[item.dCurCd] || 0) + item.dPrice_exKR_sum
              acc = acc + item.dPrice_exKR_sum
              return acc
            }, 0)           //초기값 0

            // 통화별 계산
            let dPrice_curKRW_sum = (dPrice_cur_sum.KRW == undefined) ? 0 : dPrice_cur_sum.KRW || 0;
            let dPrice_curJPY_sum = (dPrice_cur_sum.JPY == undefined) ? 0 : dPrice_cur_sum.JPY || 0;
            let dPrice_curUSD_sum = (dPrice_cur_sum.USD == undefined) ? 0 : dPrice_cur_sum.USD || 0;
            let dPrice_curTWD_sum = (dPrice_cur_sum.TWD == undefined) ? 0 : dPrice_cur_sum.TWD || 0;

            // 원화환산
            let dPrice_exKRW_sum  = dPrice_curKRW_sum*1 + dPrice_exKRW_sum_tmp*1

            //알선수수료
            let searchersFee = 0
            searchersFee = this.settleDataInfo[i].dealPrice_tot - dPrice_exKRW_sum

            //공급가액
            let providePrice = 0
            providePrice = (searchersFee > 0) ? (searchersFee/1.1) : searchersFee

            //부가가치세
            let vatPrice = 0
            vatPrice = searchersFee - providePrice

            //미수금
            let accountRecievalbe = 0
            accountRecievalbe = this.settleDataInfo[i].dealPrice_tot - incomeTot
            console.log(this.settleDataInfo[i].dealPrice_tot, "////", incomeTot)

            //상품판매수익
            let profit = 0
            profit = providePrice

            //여행손실금 & 여행정산금 (판매수익 중 +/-)
            let tripLoss = 0
            let tripProfit = 0
            if(profit >= 0)
            {
              tripProfit = profit
            }
            else if(profit < 0)
            {
              tripLoss = profit
            }
            console.log("profit:",profit,"tripLoss:", tripLoss, "tripProfit:", tripProfit)

            //연산용 변수
            let dPrice_curKRW_sum_n = 0
            let dPrice_curJPY_sum_n = 0
            let dPrice_curUSD_sum_n = 0
            let dPrice_curTWD_sum_n = 0
            let dPrice_exKRW_sum_n  = 0
            let searchersFee_n      = 0
            let providePrice_n      = 0
            let vatPrice_n          = 0
            let profit_n            = 0
            let tripProfit_n        = 0
            let tripLoss_n          = 0
            let accountRecievalbe_n = 0

            //소숫점 이하는 절사처리 + 자릿수표시
            dPrice_curKRW_sum_n = Math.trunc(dPrice_curKRW_sum)
            dPrice_curJPY_sum_n = Math.trunc(dPrice_curJPY_sum)
            dPrice_curUSD_sum_n = Math.trunc(dPrice_curUSD_sum)
            dPrice_curTWD_sum_n = Math.trunc(dPrice_curTWD_sum)
            dPrice_exKRW_sum_n  = Math.trunc(dPrice_exKRW_sum)
            searchersFee_n      = Math.trunc(searchersFee)
            providePrice_n      = Math.trunc(providePrice)
            vatPrice_n          = Math.trunc(vatPrice)
            profit_n            = Math.trunc(profit)
            tripProfit_n        = Math.trunc(tripProfit)
            tripLoss_n          = Math.trunc(tripLoss)
            accountRecievalbe_n = Math.trunc(accountRecievalbe)

            dPrice_curKRW_sum = this.getCurrencyFormat(Math.trunc(dPrice_curKRW_sum))
            dPrice_curJPY_sum = this.getCurrencyFormat(Math.trunc(dPrice_curJPY_sum))
            dPrice_curUSD_sum = this.getCurrencyFormat(Math.trunc(dPrice_curUSD_sum))
            dPrice_curTWD_sum = this.getCurrencyFormat(Math.trunc(dPrice_curTWD_sum))

            dPrice_exKRW_sum = this.getCurrencyFormat(Math.trunc(dPrice_exKRW_sum))
            searchersFee = this.getCurrencyFormat(Math.trunc(searchersFee))
            providePrice = this.getCurrencyFormat(Math.trunc(providePrice))
            vatPrice = this.getCurrencyFormat(Math.trunc(vatPrice))
            profit = this.getCurrencyFormat(Math.trunc(profit))
            tripProfit = this.getCurrencyFormat(Math.trunc(tripProfit))
            tripLoss = this.getCurrencyFormat(Math.trunc(tripLoss))
            accountRecievalbe = this.getCurrencyFormat(Math.trunc(accountRecievalbe))

            // settleDataInfo 배열 객체에 수지계산 지출합산결과 추가
            this.settleDataInfo[i] = {
              ...this.settleDataInfo[i],
              dPrice_curKRW_sum,
              dPrice_curJPY_sum,
              dPrice_curUSD_sum,
              dPrice_curTWD_sum,
              dPrice_exKRW_sum,
              searchersFee,
              providePrice,
              vatPrice,
              profit,
              tripProfit,
              tripLoss,
              accountRecievalbe,
              dPrice_curKRW_sum_n,
              dPrice_curJPY_sum_n,
              dPrice_curUSD_sum_n,
              dPrice_curTWD_sum_n,
              dPrice_exKRW_sum_n,
              searchersFee_n, 
              providePrice_n,    
              vatPrice_n,    
              profit_n,
              tripProfit_n,
              tripLoss_n,
              accountRecievalbe_n
            };
          }
          this.rowData = this.settleDataInfo;

          //원화판매총액
          let sales_KRW_tot = 0
          sales_KRW_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.dealPrice_tot*1, 0)
          this.sales_KRW_tot = sales_KRW_tot
          console.log("sales_KRW_tot:", sales_KRW_tot)

          //KRW지출총액
          let dPrice_curKRW_sum_n_tot = 0
          dPrice_curKRW_sum_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.dPrice_curKRW_sum_n*1, 0)
          this.dPrice_curKRW_sum_n_tot = dPrice_curKRW_sum_n_tot
          console.log("dPrice_curKRW_sum_n_tot:", dPrice_curKRW_sum_n_tot)
        
          //JPY지출총액
          let dPrice_curJPY_sum_n_tot = 0
          dPrice_curJPY_sum_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.dPrice_curJPY_sum_n*1, 0)
          this.dPrice_curJPY_sum_n_tot = dPrice_curJPY_sum_n_tot
          console.log("dPrice_curJPY_sum_n_tot:", dPrice_curJPY_sum_n_tot)
          
          //USD지출총액
          let dPrice_curUSD_sum_n_tot = 0
          dPrice_curUSD_sum_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.dPrice_curUSD_sum_n*1, 0)
          this.dPrice_curUSD_sum_n_tot = dPrice_curUSD_sum_n_tot
          console.log("dPrice_curUSD_sum_n_tot:", dPrice_curUSD_sum_n_tot)
          
          //TWD지출총액
          let dPrice_curTWD_sum_n_tot = 0
          dPrice_curTWD_sum_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.dPrice_curTWD_sum_n*1, 0)
          this.dPrice_curTWD_sum_n_tot = dPrice_curTWD_sum_n_tot
          console.log("dPrice_curTWD_sum_n_tot:", dPrice_curTWD_sum_n_tot)

          //알선수수료총합
          let searchersFee_n_tot = 0
          searchersFee_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.searchersFee_n*1, 0)
          this.searchersFee_n_tot = searchersFee_n_tot
          console.log("searchersFee_n_tot:", searchersFee_n_tot)
          
          //공급가액총합
          let providePrice_n_tot = 0
          providePrice_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.providePrice_n*1, 0)
          this.providePrice_n_tot = providePrice_n_tot
          console.log("providePrice_n_tot:", providePrice_n_tot)
          
          //부가가치세총합
          let vatPrice_n_tot = 0
          vatPrice_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.vatPrice_n*1, 0)
          this.vatPrice_n_tot = vatPrice_n_tot
          console.log("vatPrice_n_tot:", vatPrice_n_tot)
          
          //미수금총합
          let accountRecievalbe_n_tot = 0
          accountRecievalbe_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.accountRecievalbe_n*1, 0)
          this.accountRecievalbe_n_tot = accountRecievalbe_n_tot
          console.log("accountRecievalbe_n_tot:", accountRecievalbe_n_tot)

          //상품판매수익총합
          let profit_n_tot = 0
          profit_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.profit_n*1, 0)
          this.profit_n_tot = profit_n_tot
          console.log("profit_n_tot:", profit_n_tot)

          //여행정산금(판매수익+)
          let tripProfit_n_tot = 0
          tripProfit_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.tripProfit_n*1, 0)
          this.tripProfit_n_tot = tripProfit_n_tot
          console.log("tripProfit_n_tot:", tripProfit_n_tot)

          //여행손실금(판매수익-)
          let tripLoss_n_tot = 0
          tripLoss_n_tot = Object.values(this.settleDataInfo).reduce((acc, item) => acc + item.tripLoss_n*1, 0)
          this.tripLoss_n_tot = tripLoss_n_tot
          console.log("tripLoss_n_tot:", tripLoss_n_tot)          
        }
        console.log("settleDataInfo:::::::::::::::::::::::", this.settleDataInfo)
      }
    },

    //엑셀다운로드
    exportToExcel() {
      // Get the table element
      let tableContainer = this.$refs.tableContainer;
      let tables = tableContainer.querySelectorAll('table');

      // Transfer many tables to one table
      const tempTable = document.createElement('table');
      tables.forEach((table) => {
        const rows = table.querySelectorAll('tr');
        rows.forEach((row) => {
          tempTable.appendChild(row.cloneNode(true));
        });
      });

      // Convert tempTable to workbook
      const workbook = XLSX.utils.table_to_book(tempTable);

      // Get the first worksheet (assuming only one)
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Function to convert RGB color to Hex
      function rgbToHex(rgb) {
        const rgbValues = rgb.match(/\d+/g);
        const hexColor = rgbValues
          ? '#' + rgbValues.map((x) => parseInt(x).toString(16).padStart(2, '0')).join('')
          : rgb;
        return hexColor.replace('#', ''); // Hex format without #
      }

      // Iterate through the rows of the tempTable
      tempTable.querySelectorAll('tr').forEach((row, rowIndex) => {
        row.querySelectorAll('td').forEach((cell, colIndex) => {
          // Get the background color of the HTML cell
          const bgColor = window.getComputedStyle(cell).backgroundColor;

          // Convert the color to a format that can be used in Excel
          const excelColor = rgbToHex(bgColor);

          // Get the corresponding Excel cell reference
          const cellRef = XLSX.utils.encode_cell({c: colIndex, r: rowIndex});
          const excelCell = worksheet[cellRef];

          if (excelCell) { // Check if Excel cell is defined
            // Set the background color in Excel
            excelCell.s = {
              ...excelCell.s, // Keep existing styles
              fill: {
                fgColor: { rgb: excelColor }
              },
              font: {
                sz: 14 // Font size in points
              },
              border: {
                top: {style: "thin"},
                bottom: {style: "thin"},
                left: {style: "thin"},
                right: {style: "thin"},
              }
            };
          }
        });
      });

      // Apply styles to header (assuming header is in the first row)
      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellRef = XLSX.utils.encode_cell({c: col, r: headerRange.s.r});
        const cell = worksheet[cellRef];

        if (cell) {
          cell.s = {
            font: {
              bold: true,
              sz: 12, // Font size in points
            },
            border: {
              top: {style: "thin"},
              bottom: {style: "thin"},
              left: {style: "thin"},
              right: {style: "thin"},
            }
          };
        }
      }


      // Create Excel file
      const excelFile = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });

      // Create a Blob with the Excel file
      const blob = new Blob([this.s2ab(excelFile)], { type: 'application/octet-stream' });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      //a.download = '정산서.xlsx'; // Filename
      a.download = this.dealCode +'.xlsx'; // Filename
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },

    // 지정영역 화면인쇄
    printScreen()
    {
      //화면전체
      window.print()
    },

    async confirmAndPrint() {
      if(this.fFlag == "Y")
      {
        this.printScreen_excel(); // 데이터 저장 후 인쇄 실행
      }
      else
      {
        let plUseFlag = "Y";
        let plFix = "Y";

        let res_chkFixedLProfitLoss = await this.$api("/api/chk_fixedProfitLoss", {param: [ this.sChPerson, this.sMonth, plUseFlag, plFix ]});
        
        if (res_chkFixedLProfitLoss.length > 0) {
          this.$swal.fire("", "이미 확정된 수지계산서가 존재합니다. 확정수지계산서를 파기한 후 진행해주세요", "info");
          return false;          
        }

        this.$swal.fire({
          html: "수지계산서를 확정합니까?<br>확정된 수지계산서의 변경은 파기 후 재생성해야 합니다.",
          showCancelButton: true,
          confirmButtonText: "확정 후 인쇄",
          cancelButtonText: "아니오"
        }).then(async (result) => {
          if (result.isConfirmed) {
            // 수지계산서 데이터 저장 후 인쇄 수행
            await this.saveProfitLossData();
            this.printScreen_excel(); // 데이터 저장 후 인쇄 실행
          }
        });
      }
    },

    async saveProfitLossData() {
      let plFix = "Y";
      let plUseFlag = "Y";

      try {
        // 마스터 프로시저 호출
        let ins_profitLossInfo_m = await this.$api("/api/proc_createProfitLossStatement", {
          param: [
            this.plCode, this.sDuration, this.sMonth, plFix, this.profitLossRemarks, this.sChPerson, plUseFlag,
            this.curJPY, this.curUSD, this.curTWD,
            this.sales_KRW_tot, this.dPrice_curKRW_sum_n_tot, this.dPrice_curJPY_sum_n_tot, this.dPrice_curUSD_sum_n_tot,
            this.dPrice_curTWD_sum_n_tot, this.searchersFee_n_tot, this.providePrice_n_tot, this.vatPrice_n_tot,
            this.accountRecievalbe_n_tot, this.profit_n_tot, this.user.handlerId, this.tripProfit_n_tot, this.tripLoss_n_tot
          ]
        });

        if (ins_profitLossInfo_m.affectedRows < 0) {
          this.$swal.fire("", "수지계산서 확정에 오류가 발생했습니다. 재시도해주세요", "warning");
          return false;
        }

        // 디테일 프로시저 반복 호출
        for (let i = 0; i < this.settleDataInfo.length; i++) {
          let plDate = (this.sDuration === "G") ? this.settleDataInfo[i].startDate : this.settleDataInfo[i].settleDate;

          let ins_profitLossInfo_d = await this.$api("/api/proc_createProfitLossStatement_d", {
            param: [
              this.plCode, i + 1, this.settleDataInfo[i].dealCode, plDate, this.settleDataInfo[i].dealNameKor,
              this.settleDataInfo[i].dealPrice_tot, this.settleDataInfo[i].dPrice_curKRW_sum_n, this.settleDataInfo[i].dPrice_curJPY_sum_n,
              this.settleDataInfo[i].dPrice_curUSD_sum_n, this.settleDataInfo[i].dPrice_curTWD_sum_n, this.settleDataInfo[i].searchersFee_n,
              this.settleDataInfo[i].providePrice_n, this.settleDataInfo[i].vatPrice_n, this.settleDataInfo[i].accountRecievalbe_n,
              this.settleDataInfo[i].profit_n
            ]
          });

          if (ins_profitLossInfo_d.affectedRows < 0) {
            this.$swal.fire("", "수지계산서 확정 중 오류가 발생하여 취소되었습니다. 재시도 해주세요.", "warning");
            return false;
          }
        }

        this.$swal.fire("", "수지계산서가 성공적으로 확정되었습니다.", "success");
      } catch (error) {
        this.$swal.fire("", "수지계산서 확정 중 예기치 않은 오류가 발생했습니다.", "error");
      }
    },

    async printScreen_excel() {
      const tableContainer = this.$refs.tableContainer;
      const clonedTableContainer = tableContainer.cloneNode(true);
      const buttons = clonedTableContainer.querySelectorAll('button');
      buttons.forEach(button => button.remove());

      const printContent = clonedTableContainer.innerHTML;

      const printWindow = window.open('', '_blank');
      const stylesheets = Array.from(document.styleSheets)
        .map(styleSheet => (styleSheet.href ? `<link rel="stylesheet" href="${styleSheet.href}">` : ''))
        .join('');

      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            ${stylesheets}
            <style>
              @page {
                size: A4 landscape;
                margin: 1cm;
              }
              body {
                margin: 0;
              }
              table {
                width: 100%;
                border: 1px solid black;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid black;
              }
              td[style*="background-color:lightgray"] {
                background-color: lightgray !important;
              }
              @media print {
                body {
                  font-size: 12px;
                }
                table {
                  font-size: 10px;
                }
              }
            </style>
          </head>
          <body>${printContent}</body>
        </html>
      `);
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => printWindow.close();
      };
    },

    goBack(){
      //history.back(-1)
      //this.$router.go(-1);
      this.$router.push({path:'/businessInfo', query: {bFlag: 'B'}})
    }
  }
}
</script>

<style>
.table_sup td {
  padding: 0
}

/* 컬럼 외곽선 스타일을 지정합니다. */
.table-bordered th,
.table-bordered td {
  border: 1px solid #000;
  font-size: small;
}

/* 테이블의 패딩을 설정합니다. */
.table-padding-1 td,
.table-padding-1 th {
  padding: 0.2rem;
}

/** 폰트기본사이즈 */
#profitAndLoss {
  font-size: 0.5rem;
}

/**스위치버튼 컬러처리 */
.custom-switch .form-check-input:checked {
    background-color: #007bff;
  }
  
  .custom-switch .form-check-input:checked+.form-check-label::before {
    background-color: #fff;
  }

  .bgColor {
    background-color: lightgray;
  }

  /* .sCol {
    width:100px;
  } */

/*인쇄화면에서 엑셀과 프린트 버튼은 보이지 않도록*/
@media print {
  .print-hide {
    display:none;
  }
  .bgColor {
    background-color: lightgray;
  }
}
</style>