<template>
  <div id="product">
    <div class="item-area section-padding pb-bottom">
      <div class="container">
        <a v-bind:href="`/bustour_asso?assoFlag=${this.asso}`">
          <!-- <img :src="`../../src/assets/img/asso/${this.asso}.png`" class="assoLogo"> -->
          <img :src="getImageUrl(asso)" class="assoLogo" v-if="assoFlag != ''">
        </a>
        
        <div class="row">
          <!-- left area -->
          <div class="col-12 col-lg-9 pr-left">
            <!-- 프로모션기간이 있으면 프로모션기간 -->
            <div class="ppro"> 
              <span class="badge text-white fs-6"  style="background-color:rgba(179, 6, 35, 0.8);" v-if="pdtPromoInfo.promoName != undefined">
                <b style="font-size:small;"><i class="bi bi-megaphone-fill"></i></b>&nbsp; 
                  <small>{{getDateFormat(pdtPromoInfo.promoStart)}} ~ {{getDateFormat(pdtPromoInfo.promoEnd)}}&nbsp; 출발할인!</small>
              </span>
            </div>

            <!-- 타이틀 -->
            <div class="section-tittle mt-2 mb-10">
              <h2>{{productDetail.pdtNameKor}}</h2>
              <p class="text-secondary" style="font-size:small; padding-left: 10px;">{{productDetail.pdtRemarks}}</p>
              <hr style="opacity:1; border-top:2px solid">
            </div>

            <!-- 사진 -->
            <div v-if="productImage.length > 0">
              <div id="carouselDetail" class="carousel slide front" data-bs-ride="carousel"  data-bs-interval="3000">
                <div class="carousel-indicators">
                  <button type="button" 
                          v-for="(slide, index) in productImage.length" 
                          :key="index"
                          :data-bs-target="'#carouselDetail'"
                          :data-bs-slide-to="index"
                          :class="{ active: index === 0 }"
                          :aria-current="index === 0 ? 'true' : null"
                          :aria-label="'Slide ' + (index + 1)">
                  </button>
                </div>
                <div class="carousel-inner">
                  <div :class="`carousel-item ${i==0 ? 'active' : ''}`" :key="i" v-for="(dImg, i) in productImage">
                    <img :src="`/download/${productId}/${dImg.imgName}`" class="d-block w-100" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselDetail" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselDetail" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <!-- <div v-else>
              <img :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
            </div> -->

            <!-- 상품번호 -->
            <div class="text-end">
              <span class="subheading">상품번호 : {{productDetail.pdtCode}}</span>
            </div>

            <!-- 교통편,체크포인트 -->
            <div class="vech">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="stand">출발</td>
                    <td class="sub" v-if="productDetail.salesRoute === 'E'"> - </td>
                    <td class="sub" v-else>
                      <div v-if="productDetail.transFlag != '0'">
                        <b>교통편 </b> <span style="color:dimgray ">불포함</span>
                      </div>
                      <div v-else>
                        <b v-if="this.productDetail.salesRoute === 'L'"><span class="depart-de"></span>
                          <span style="color:black">
                            {{pdtTransInfo1.dmobileCodeName}} ({{pdtTransInfo1.dpTpCode}})
                          </span>
                        </b>
                        <b v-else style="color:black; font-size:1rem">[출  국]&nbsp;&nbsp;
                          <span style="color:black">
                            {{pdtTransInfo1.dmobileCodeName}} ({{pdtTransInfo1.dmobileCode}}{{pdtTransInfo1.dpTpCode}})
                          </span>
                        </b>
                        <span class="brdown">
                          <span style="color:black">
                            {{pdtTransInfo1.ddCodeName}}({{pdtTransInfo1.d_dpTime}})
                            <i class="bi bi-caret-right-fill"></i>
                            {{pdtTransInfo1.daCodeName}}({{pdtTransInfo1.d_arTime}})
                          </span>
                        </span>
                      </div>
                      <div>
                        <small v-html="getStringFormat(this.productDetail.transRemarks_dp)"></small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="stand">도착</td>
                    <td class="sub" v-if="productDetail.salesRoute === 'E'"> - </td>
                    <td class="sub" v-else>
                      <div v-if="productDetail.transFlag != '0'">
                        <b>교통편 </b>  <span style="color:black ">불포함</span>
                      </div>
                      <div v-else>
                        <b v-if="this.productDetail.salesRoute==='L'">
                          <span style="color:black">
                            {{pdtTransInfo1.rmobileCodeName}} ({{pdtTransInfo1.rtTpcode}})
                          </span>
                        </b>
                        <b v-else style="color:black; font-size:1rem">[귀  국]&nbsp;&nbsp;
                          <span style="color:black">
                            {{pdtTransInfo1.rmobileCodeName}} ({{pdtTransInfo1.rmobileCode}}{{pdtTransInfo1.rtTpcode}})
                          </span>
                        </b>     
                        <span class="brdown">
                          <span style="color:black">
                            {{pdtTransInfo1.rdCodeName}}({{pdtTransInfo1.r_dpTime}})
                            <i class="bi bi-caret-right-fill"></i>
                            {{pdtTransInfo1.raCodeName}}({{pdtTransInfo1.r_arTime}})
                          </span>
                        </span>
                      </div>
                      <div>
                        <small v-html="getStringFormat(this.productDetail.transRemarks_ar)"></small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="stand text-danger">체크사항</td>
                    <td class="sub">
                      <div class="text-start">
                        <small class="checkPoint">{{this.productDetail.supLookup}}</small>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- 포함,불포함 -->
            <div class="in-non">
              <table class="table">
                <thead>
                  <tr>
                    <th style="color: #0004d6"><i class="fa-regular fa-circle fa-xs"></i> &nbsp;포함사항</th>
                    <th style="color: #e20400"><i class="fa-solid fa-x fa-xs"></i> &nbsp;불포함사항</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-7"><pre class="inclusion">{{ this.productDetail.inContent }}</pre></td>
                    <td class="col-5"><pre class="notInclusion">{{ this.productDetail.exContent }}</pre></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- 카테고리 -->
            <div class="category">
              <div class="container">
                <div class="tab-content mt-4">
                  <!-- 구분 -->
                  <section class="clist">
                    <div class="p-1">
                      <ul class="nav nav-tabs justify-content-center">
                        <li class="nav-item">
                          <a data-bs-toggle="tab">
                            <a class="nav-link active show" href="#tab-1"><h4>상품설명</h4></a>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a data-bs-toggle="tab">
                            <a class="nav-link" href="#tab-2"><h4>안내사항</h4></a>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a data-bs-toggle="tab">
                            <a class="nav-link" href="#tab-3"><h4>취소안내</h4></a>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a data-bs-toggle="tab">
                            <a class="nav-link" href="#tab-4"><h4>이용후기</h4></a>
                          </a>
                        </li>
                        <li class="nav-item" @click="inquiryProduct()">
                          <a data-bs-toggle="tab">
                            <a class="nav-link" href="#"><h4>상품문의</h4></a>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </section>

                  <!-- 상품상세이미지 -->
                  <div class="tab-pane mb-5 active show" id="tab-1">
                    <div class="row">
                      <div class="row" v-if="this.productDetailImage.length > 0">
                        <div class="col-12" :key="i" v-for="(detailImg, i) in productDetailImage">
                          <img :src="`/download/${productId}/${detailImg.imgName}`" class="img-fluid" />
                        </div>
                      </div>
                      <!-- <div class="row" v-else>
                        <div class="col-12">
                          <img :src="`/download/noImage/noImage.jpg`" class="img-fluid"/>
                        </div>
                      </div> -->
                    </div>
                  </div>

                  <!-- 안내사항 -->
                  <div class="tab-pane my-5" id="tab-2">
                    <div class="row">
                      <div class="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                        <h4>안내사항</h4>
                        <hr style="color:#bbb;">
                        <pre class="supPre">{{ this.productDetail.supPrecaution }}</pre>
                      </div>
                    </div>
                  </div>

                  <!-- 취소안내 -->
                  <div class="tab-pane my-5" id="tab-3">
                    <div class="row">
                      <div class="col-lg-12 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                        <h4>취소수수료 안내</h4>
                        <hr style="color:#bbb;">
                        <!-- <p>{{ getStringFormat(this.productDetail.supCancellation) }}</p> -->
                        <pre class="supCxl">{{ this.productDetail.supCancellation }}</pre>
                      </div>
                    </div>
                  </div>

                  <!-- 이용후기 -->
                  <div class="tab-pane creview my-5" id="tab-4">
                    <div class="row d-flex justify-content-between align-items-start">
                      <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                        <h4>이용후기</h4>
                        <p class="text-secondary mt-0">
                          상품이용 후, 30일 이내에 작성된 내용입니다.
                        </p>
                      </div>
                    </div>
                    <hr style="color:#bbb;">

                    <!-- 상품별 후기리스트 -->
                    <div class="container">
                      <div v-if="this.reviewList.length > 0">
                        <div class="row" :key=i v-for="(rl, i) in this.reviewList">
                          <div class="col-lg-3 mt-2">
                            <img v-if="!rl.imgName || rl.iUseFlag == 'N'" :src="`/download/noImage/noImage2.jpg`" class="img-fluid" style="width: 250px; height:200px; border: 1px dotted lightgrey;" title="리뷰어가 이미지를 업로드하지" />
                            <img v-else :src="`/download/review/${rl.revCode}/${rl.imgName}`" class="img-fluid" style="width: 250px; height:200px" />
                          </div>
                          <div class="c-content col-lg-9 text-start align-self-center">
                            <div class="reviewt d-inline-block text-truncate" style="max-width: 330px;" v-if="rl.insId === this.user.email">
                              {{ rl.nTitle }}&nbsp;
                              <button type="button" class="btn btn-outline-secondary btn-sm me-1 align-top" @click="changeReview(rl.revCode, rl.seqNotice, rl.seqImg);">
                                <span style="font-size:small">변경</span>
                              </button>
                            </div>
                            <div class="reviewt d-inline-block text-truncate" style="max-width: 330px;" v-else>{{ rl.nTitle }}</div>
                            <div class="reviewn text-end">
                              <span class="name">{{ rl.rId.substring(0,3) }}○○○님</span>  
                              <span class="date">{{ this.getDateFormat(rl.rDate) }}</span>
                            </div>
                            <div class="reviewc">
                              <pre class="reviewContent">{{ rl.nContent }}</pre>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="row">
                          <div class="col-lg-3 mt-2 text-center">
                            아직 등록된 후기가 없습니다.
                          </div>
                        </div>
                      </div>            
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>         

          <!-- 오른쪽 리모콘 -->
          <div class="col-lg-3">
            <div class="pr-right">
              <!-- 달력 -->
              <div class="container">
                <!-- <div class="top">
                  <span>이용일자</span>
                </div> -->
                <div class="calendar">
                  <div class="calendar-header">
                    <button class="calendarbtn" @click="previousMonth()">
                      <i class="bi bi-chevron-left"></i>
                    </button>
                    <div class="d-flex align-items-center">
                      <button class="calendarbtn mt-1" @click="goToCurrentMonth()">
                        <i class="bi bi-calendar3"></i>
                      </button>
                      <div class="calendar-header-date">
                        {{ this.currentDate ? this.currentDate.format('YYYY-MM') : '' }}
                      </div>
                    </div>
                    <button class="calendarbtn" @click="nextMonth()">
                      <i class="bi bi-chevron-right"></i>
                    </button>
                  </div>
                  <div class="calendar-body">
                    <div class="calendar-row">
                      <div class="calendar-day-header text-danger">일</div>
                      <div class="calendar-day-header">월</div>
                      <div class="calendar-day-header">화</div>
                      <div class="calendar-day-header">수</div>
                      <div class="calendar-day-header">목</div>
                      <div class="calendar-day-header">금</div>
                      <div class="calendar-day-header text-primary">토 </div>
                    </div>
                    <div v-for="(week, index) in weeks" :key="index" class="calendar-row">
                      <div v-for="(day, index) in week" :key="index" class="calendar-day"
                          :class="{ today: isToday(day.date), selected: isSelected(day.date)}" @click="goTo('resBtn');selectDate(day.date)">
                        <div v-if="day.date && day.date.day() === 0" class="calendar-date text-danger">
                          {{ day.date.date() }}
                        </div>
                        <div v-else-if="day.date && day.date.day() === 6" class="calendar-date text-primary">
                          {{ day.date.date() }}
                        </div>
                        <div v-else-if="day.date && (day.date.day() !== 6 && day.date.day() !== 0)" class="calendar-date">
                          {{ day.date.date() }}
                        </div>
                        <div v-if="day.price" class="calendar-price">
                          {{ getCurrencyFormat(formatManCurrency(day.price)) }}<span style="font-size: smaller; color:darkgray;">만</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style="margin:0px 20px 0; opacity:.5" v-if="this.inquiryFlag === 'Y'">
              <!-- 가격 -->
              <div class="price" v-if="this.inquiryFlag === 'Y'">  
                <table>
                  <tr class="row col-12" :key=i v-for="(scp,i) in this.selectedCalPdtPrice">
                    <td class="col-5 text-start gx-4" 
                      v-if="(this.salesRoute === 'T' || this.salesRoute === 'L' || this.salesRoute === 'E') && scp.agegroup != '4'">
                      <div v-if="scp.pdtPriceName == '' || scp.pdtPriceName == undefined" style="font-weight: normal;">
                        {{scp.ageName}}&nbsp;
                        <a href="javascript: void(0)" @click="popover('A','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="만 12세 이상" v-if="scp.ageName==='성인'">
                          <i class="bi bi-question-square text-sm-muted"></i>  
                        </a>
                        <a href="javascript: void(0)" @click="popover('C','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="만 02세 이상" v-else-if="scp.ageName==='소아'">
                          <i class="bi bi-question-square text-sm-muted"></i>  
                        </a>
                        <a href="javascript: void(0)" @click="popover('I','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="생후 24개월 미만" v-else-if="scp.ageName==='유아'">
                          <i class="bi bi-question-square text-sm-muted"></i>  
                        </a>
                        <a href="javascript: void(0)" @click="popover('D','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="할인조건 만족시" v-else-if="scp.ageName==='할인'">
                          <i class="bi bi-question-square text-sm-muted"></i>
                        </a>
                      </div>
                      <div v-else-if="scp.pdtPriceName != ''">
                        {{scp.pdtPriceName}}&nbsp;  
                      </div>
                      <div v-if="scp.agegroup != '4'">
                        <b style="color:darkgreen; font-size:1.3rem;">{{getCurrencyFormat(scp.sMinPrice)}}</b>&nbsp;
                      <small class="text-muted">원</small></div>
                    </td>
                    
                    <td class="col-5" style="text-align:center; background-color: white" v-else-if="this.salesRoute === 'S' && scp.agegroup != '4'">
                      <div v-if="scp.pdtPriceName == '' || scp.pdtPriceName == undefined">
                        가격&nbsp;  
                      </div>
                      <div v-else-if="scp.pdtPriceName != ''">
                        {{scp.pdtPriceName}}&nbsp;  
                      </div>
                    </td>

                    <td class="col-7 count align-self-center">
                      <div class="btn-group" v-if = "scp.pdtPrice > 0 || scp.agegroup != '4' ">
                        <button class="btn left" @click="minus(scp.ageName)"><i class="bi bi-dash-lg text-danger"></i></button>
                        <span class="number">{{ this.ageNumber[i+1] }}</span>
                        <button class="btn right" @click="plus(scp.ageName)"><i class="bi bi-plus-lg text-primary"></i></button>
                      </div>
                    </td>
                  </tr>

                  <!-- 옵션 -->
                  <tr class="row col-12" v-if="this.etcOptionInfo.length > 0">
                    <td class="col-3 text-start">
                      <div style="text-align:center; background-color: white; padding-right:0.7rem;font-weight: normal;">
                        옵션
                        <p style="font-size:small; color:blue">(1회 기준)</p>
                      </div>
                    </td>
                    <td class="row col-9">
                      <div class="text-start" :key=i v-for="(etcOptName,i) in this.etcOptionInfo">
                        <a href="javascript: void(0)" @click="popover('P', i)" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" :title="getCurrencyFormat(this.etcOptionInfo[i].etcOptPrice)">
                          <small v-if="this.etcOptionInfo[i].reqOptFlag === 'Y'">
                            <i class="bi bi-question-square text-danger" text-sm-muted></i>
                          </small>
                          <small v-else>
                            <i class="bi bi-question-square text-sm-muted"></i>
                          </small>
                        </a>
                        <span style="padding-left:0.5rem;">{{this.etcOptionInfo[i].etcOptName}}</span>
                        <small v-if="this.etcOptionInfo[i].reqOptFlag === 'Y'" class="text-danger" style="padding-left: 3px;">(필수)</small>
                      </div>
                    </td>                    
                  </tr>
                </table>
              </div>

              <hr class="mt-4 mb-5" style="margin:0px 20px 0; opacity:.5"  v-if="this.etcOptionInfo.length > 0 &&  this.inquiryFlag === 'Y'">

              <div id="resBtn"></div>

              <!-- 상품가격 -->
              <div class="total-P mb-3" v-if="this.inquiryFlag === 'Y'">
                <table>
                  <tr>
                    <td style="width:50%">
                      <div>
                        <b style="font-size:1.2rem">상품가격</b> <small>(옵션 불포함)</small>
                      </div>
                    </td>
                    <td style="text-align:end; width:50%;">
                      <b class="text-danger fs-3">{{ this.getCurrencyFormat(this.showPrice) }}</b> 원
                    </td>
                  </tr>
                  <tr v-if="this.selectedCalPdtPrice.length > 0">
                    <td class="text-center align-middle" colspan="2">
                      <div class="pr-go">
                        <a href="javascript: void(0)" @click="orderCheck('p')">
                            <p>예약하기</p>
                        </a>
                      </div>
                    </td>
                  </tr>

                  <!-- 멤버십 배너 -->
                  <tr>
                    <td class="text-center align-middle" colspan="2">
                      <div class="mem-go">
                        <a href="javascript: void(0)" onclick="location.href='/membershipPage'">
                          <img src="../assets/img/new-test/product-m.jpg" class="img-fluid mobile">
                        </a>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <!-- 모바일화면용 예약달력 -->
          <div class="container mobile-cal">
            <a class="openm js-click-modal" @click="toggleModal()">날짜선택</a>
            <div class="modal2" v-if="this.showModal2" @click.self="closeModal2()">
              <div class="body">
                <div class="container">
                  <div class="calendar">
                    <div class="calendar-header">
                      <button class="calendarbtn" @click="previousMonth()">
                        <i class="bi bi-chevron-left"></i>
                      </button>
                      <div class="d-flex align-items-center">
                        <button class="calendarbtn mt-1" @click="goToCurrentMonth()">
                          <i class="bi bi-calendar3"></i>
                        </button>
                        <div class="calendar-header-date">
                          {{ this.currentDate ? this.currentDate.format('YYYY-MM') : '' }}
                        </div>
                      </div>
                      <button class="calendarbtn" @click="nextMonth()">
                        <i class="bi bi-chevron-right"></i>
                      </button>
                    </div>
                    <div class="calendar-body">
                      <div class="calendar-row">
                        <div class="calendar-day-header text-danger">일</div>
                        <div class="calendar-day-header">월</div>
                        <div class="calendar-day-header">화</div>
                        <div class="calendar-day-header">수</div>
                        <div class="calendar-day-header">목</div>
                        <div class="calendar-day-header">금</div>
                        <div class="calendar-day-header text-primary">토 </div>
                      </div>
                      <div v-for="(week, index) in weeks" :key="index" class="calendar-row">
                        <div v-for="(day, index) in week" :key="index" class="calendar-day"
                            :class="{ today: isToday(day.date), selected: isSelected(day.date)}" @click="goTo('resBtn');selectDate(day.date)">
                          <div v-if="day.date && day.date.day() === 0" class="calendar-date text-danger">
                            {{ day.date.date() }}
                          </div>
                          <div v-else-if="day.date && day.date.day() === 6" class="calendar-date text-primary">
                            {{ day.date.date() }}
                          </div>
                          <div v-else-if="day.date && (day.date.day() !== 6 && day.date.day() !== 0)" class="calendar-date">
                            {{ day.date.date() }}
                          </div>
                          <div v-if="day.price" class="calendar-price">
                            {{ getCurrencyFormat(formatManCurrency(day.price)) }}<span style="font-size: smaller; color:darkgray;">만</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style="margin:0px 20px 0; opacity:.5" v-if="this.inquiryFlag === 'Y'">
                <!-- 가격 -->
                <div class="price" v-if="this.inquiryFlag === 'Y'">  
                  <table>
                    <tr class="row col-12 my-3" :key=i v-for="(scp,i) in this.selectedCalPdtPrice">
                      <td class="col-6 text-center gx-5" 
                        v-if="(this.salesRoute === 'T' || this.salesRoute === 'L' || this.salesRoute === 'E') && scp.agegroup != '4'">
                        <div v-if="scp.pdtPriceName == '' || scp.pdtPriceName == undefined" style="font-weight: normal;">
                          {{scp.ageName}}&nbsp;
                          <a href="javascript: void(0)" @click="popover('A','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="만 12세 이상" v-if="scp.ageName==='성인'">
                            <i class="bi bi-question-square text-sm-muted"></i>  
                          </a>
                          <a href="javascript: void(0)" @click="popover('C','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="만 02세 이상" v-else-if="scp.ageName==='소아'">
                            <i class="bi bi-question-square text-sm-muted"></i>  
                          </a>
                          <a href="javascript: void(0)" @click="popover('I','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="생후 24개월 미만" v-else-if="scp.ageName==='유아'">
                            <i class="bi bi-question-square text-sm-muted"></i>  
                          </a>
                          <a href="javascript: void(0)" @click="popover('D','')" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" title="할인조건 만족시" v-else-if="scp.ageName==='할인'">
                            <i class="bi bi-question-square text-sm-muted"></i>
                          </a>
                        </div>
                        <div v-else-if="scp.pdtPriceName != ''">
                          {{scp.pdtPriceName}}&nbsp;  
                        </div>
                        <div v-if="scp.agegroup != '4'">
                          <b style="color:darkgreen; font-size:1.2rem;">{{getCurrencyFormat(scp.sMinPrice)}}</b>&nbsp;
                        <small class="text-muted">원</small></div>
                      </td>
                      
                      <td class="col-5" style="text-align:center; background-color: white" v-else-if="this.salesRoute === 'S' && scp.agegroup != '4'">
                        <div v-if="scp.pdtPriceName == '' || scp.pdtPriceName == undefined">
                          가격&nbsp;  
                        </div>
                        <div v-else-if="scp.pdtPriceName != ''">
                          {{scp.pdtPriceName}}&nbsp;  
                        </div>
                      </td>

                      <td class="col-6 count text-center align-self-center">
                        <div class="btn-group" v-if = "scp.pdtPrice > 0 || scp.agegroup != '4' ">
                          <button class="btn left" @click="minus(scp.ageName)"><i class="bi bi-dash-lg text-danger"></i></button>
                          <span class="number">{{ this.ageNumber[i+1] }}</span>
                          <button class="btn right" @click="plus(scp.ageName)"><i class="bi bi-plus-lg text-primary"></i></button>
                        </div>
                      </td>
                    </tr>

                    <!-- 옵션 -->
                    <tr class="row col-12" v-if="this.etcOptionInfo.length > 0">
                      <td class="col-3 text-start">
                        <div style="text-align:end; background-color: white; padding-right:0.7rem;font-weight: normal;">
                          선택옵션
                        </div>
                      </td>
                      <td class="row col-9">
                        <div class="text-start" :key=i v-for="(etcOptName,i) in this.etcOptionInfo">
                          <a href="javascript: void(0)" @click="popover('P', i)" data-bs-toggle="popover" data-bs-trigger="hover focus click" data-bs-placement="top" :title="getCurrencyFormat(this.etcOptionInfo[i].etcOptPrice)">
                            <small v-if="this.etcOptionInfo[i].reqOptFlag === 'Y'">
                              <i class="bi bi-question-square text-danger" text-sm-muted></i>
                            </small>
                            <small v-else>
                              <i class="bi bi-question-square text-sm-muted"></i>
                            </small>
                          </a>
                          <span style="padding-left:0.5rem;">{{this.etcOptionInfo[i].etcOptName}}</span>
                          <small v-if="this.etcOptionInfo[i].reqOptFlag === 'Y'" class="text-danger" style="padding-left: 3px;">(필수)</small>
                        </div>
                      </td>                    
                    </tr>
                  </table>
                </div>

                <hr class="mt-4 mb-5" style="margin:0px 20px 0; opacity:.5"  v-if="this.etcOptionInfo.length > 0 &&  this.inquiryFlag === 'Y'">

                <div id="resBtn"></div>

                <!-- 상품가격 -->
                <div class="total-P mb-3" v-if="this.inquiryFlag === 'Y'">
                  <table>
                    <tr>
                      <td style="width:50%">
                        <div>
                          <b style="font-size:1.2rem; font-weight:border;">상품가격</b> <small>(옵션 불포함)</small>
                        </div>
                      </td>
                      <td style="text-align:end; width:50%;">
                        <b class="text-danger fs-3 fw-bold">{{ this.getCurrencyFormat(this.showPrice) }}</b> 원
                      </td>
                    </tr>
                    <tr v-if="this.selectedCalPdtPrice.length > 0">
                      <td class="text-center align-middle" colspan="2">
                        <div class="pr-go">
                          <a href="javascript: void(0)" @click="orderCheck('p')">
                              <p>예약하기</p>
                          </a>
                        </div>
                      </td>
                    </tr>

                    <!-- 멤버십 배너 -->
                    <tr>
                      <td class="text-center align-middle" colspan="2">
                        <div class="mem-go">
                          <a href="javascript: void(0)" onclick="location.href='/membershipPage'">
                            <img src="../assets/img/new-test/product-m.jpg" class="img-fluid mobile">
                          </a>
                        </div>
                      </td>
                    </tr>

                  </table>
                </div>

                <!-- 닫기버튼 -->
                <a class="closem js-close-modal">
                  <i class="bi bi-x-square text-danger" style="cursor:pointer" @click.stop="closeModal2()"></i>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</div>

</template>

<script>
import moment from 'moment';
import {genCode} from '../assets/js/genCode'

export default {
  // components: 
  // {
  //   DatePicker
  // },  
  // DB에서 가져온 데이타
  data() {
    return {
      showModal2: true,           //모바일달력보창

      currentDate: null,          //가격이 있는 달을 시작월로 변경
      selectedDate: null,
      selectedPrice: null,
      priceData: {},
      //calPdtPrice_full: [],     //상품가격리스트
      selectedCalPdtPrice: [],    //선택된 날짜의 권종가격
      
      inquiryFlag: "N",           //예약영역 표시플래그

      productId: '',
      productDetail: [],          // 상세정보를 오브젝트형으로 변수처리

      pdtTransInfo1: {},          //상품교통정보

      productImage: [],
      productDetailImage: [],
      pdtFlag: "",                  //단일-결합 구분자
      detailPrice: [],              //상세페이지 표시용 기본가격
      pdtAddOptExisted: [],         //기존추가옵션
      etcOptionInfo: [],            //기타옵션
      //etcOptionInfo_existed: [],  //기타옵션(기존)

      reviewList: [], //리뷰리스트

      ageNumber: [0,0,0,0,0],       //연령별 인원

      pdtPromoInfo: [],             //상품프로모션정보

      memberFlag: "",               //회원여부
      calLastDate: "",              //상품종료일

      reqReserveCode: "",           //예약번호

      //예약달력정보
      aduPrice: 0,        //인당금액
      chiPrice: 0,        //인당금액
      infPrice: 0,        //인당금액
      showPrice: 0,       //상품가액(게시용)
      tot_aduPrice: 0,    //권종별가격토탈
      tot_chiPrice: 0,    //권종별가격토탈
      tot_infPrice: 0,    //권종별가격토탈
      tot_aduCount: 0,    //권종별인원토탈
      tot_chiCount: 0,    //권종별인원토탈
      tot_infCount: 0,    //권종별인원토탈
      tot_perCount: 0,    //유효인원총수
      tot_margin: 0,      //마진토탈
      tot_promo:0,        //프로모션토탈
      tot_vatPrice: 0,    //부가세


      rShowFlag: "N", //리뷰작성영역표시

      noPopFlag: "N", //페이지이탈 경고팝업표시(Y:없음, N:경고)

      defaultValFlag: "N",   //인원&가격정보(Y:초기화)

      reqDptDate: "", //출발예정일  = selectedDate
      reqRtnDate: "", //리턴예정일
    };
  },
  created() {
    // path에서 사용된 productId
    this.user = this.$store.state.user;
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;
    this.asso = this.$route.query.asso
    //console.log("assoFlag:", this.asso)

    this.conEmail = this.user.email;

    if (this.$store.state.user.handlerId != undefined) {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined) {
      this.userInfo = this.$store.state.user.email;
    }
    else {
      this.userInfo = "";
    }
    //console.log("userInfo:", this.userInfo);

    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.userInfo.joinFlag == 'H')
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.phone.replace("+82 ", "0")
      }
      this.k_tel = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }

    //단일-결합구분자
    // if(this.productId.substring(0,3)==="MX-") {
    //   this.pdtFlag = "MIX";
    // }
    this.getFirstAvailableDate() //가격이 있는 날짜를 달력시작월로

    this.getProductDetail();
  },
  mounted() {
    window.scrollTo(0, 0);
    
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   //this.$router.push({path: '/'});
    //   location.replace("/");
    // }

    if(this.asso == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("https://ntabi.co.kr");
    }

    //페이지 새로고침 리스너
    window.addEventListener('beforeunload', this.showPopupOnRefresh)

    //상세정보와 무관한 내용 비표시처리
    if(this.asso != "zev1")
    {
      document.getElementById("user").style.display = "none";
      document.getElementById("userM").style.display = "none";
    }
    document.getElementById("notice").style.display = "none";

    // document.getElementById("recent-item").style.display = "none";
    // document.getElementById("recent-item-m").style.display = "none";

    //달력 열기
    document.querySelector(".js-click-modal").addEventListener("click", () => {
      document.querySelector(".modal2").classList.toggle("open");
    });
    //달력 닫기
    document.querySelector(".js-close-modal").addEventListener("click", () => {
      document.querySelector(".modal2").classList.remove("open");
    });
  },    

  beforeUnmount()
  {
    window.removeEventListener('beforeunload', this.showPopupOnRefresh)
  },

  beforeRouteLeave(to, from, next) {
    //뒤로가기 시 호출
    if (this.inquiryFlag === 'Y' && this.noPopFlag != 'Y') 
    {
      this.showPopupOnBack().then(shouldProceed => {
        if (shouldProceed) {
          next()
        } else {
          next(false)
        }
      })
    } else {
      next()
    }
  },  
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    },

    //new calendar
    weeks() 
    {
      const weeks = [];
      let weekDays = [];

      let day = this.currentDate ? this.currentDate.clone().startOf('month').startOf('week') : moment().startOf('month').startOf('week');
      
      const startOfMonth = this.currentDate ? this.currentDate.clone().startOf('month') : moment().startOf('month');
      const endOfMonth = this.currentDate ? this.currentDate.clone().endOf('month') : moment().endOf('month');
      const endDate = this.currentDate ? this.currentDate.clone().endOf('month').endOf('week') : moment().endOf('month').endOf('week');

      while (day.isSameOrBefore(endDate)) {
        if (day.isSameOrAfter(startOfMonth) && day.isSameOrBefore(endOfMonth)) {
          weekDays.push({
            date: day.clone(),
            price: this.getPrice(day.format('YYYY-MM-DD'))
          });
        } else {
          weekDays.push({
            date: null,
            price: null,
          });
        }
        
        if (weekDays.length === 7) {
          weeks.push(weekDays);
          weekDays = [];
        }

        day.add(1, 'day');
      }
      return weeks;
    }
  },

  watch: {
    //예약정보 전개 + 예약변수 초기화 분개처리
    inquiryFlag: function(newVal) {
      if(newVal === "N")
      {
        this.ageNumber = ['0','0','0','0']
        this.showPrice = 0
      }
    },

    defaultValFlag: function(newVal, oldVal) {
      if((newVal==='Y' || newVal==='N') && oldVal === 'Y')
      {
        this.ageNumber = ['0','0','0','0']
        this.showPrice = 0
      }
    },

  },

  methods: 
  {
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      //return this.$currencyFormat(value);
      if(isNaN(value)) 
      {
        location.reload()
      }
      else
      {
        return this.$currencyFormat(value);
      }      
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },    
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },

    closeModal2()
    {
      this.showModal2 = false
    },

    toggleModal()
    {
      this.showModal2 = true
      //console.log("showModal2:", this.showModal2)
      //this.showModal2 = !this.showModal2
    },


    showPopupOnRefresh(event) 
    {
      if (this.noPopFlag == 'N') // && this.inquiryFlag === 'Y'
      {
        const message = '페이지 새로고침을 하시겠습니까?작성 중 내용이 지워지게 됩니다.'
        event.returnValue = message // 반환값을 설정합니다.
      }
    },
    async showPopupOnBack() 
    {
      const message = '이 페이지에서 나가시겠습니까?<br>작성 중 내용이 지워지게 됩니다.'
      const result = await this.$swal.fire({
        title: '',
        html: message,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: '네',
        cancelButtonText: '아니요'
      })

      if (result.isConfirmed) 
      {
        this.inquiryFlag = 'N'
        return true
      } 
      else 
      {
        return false
      }
    },

    getImageUrl(flag) {
      try {
        // require를 사용하여 동적으로 이미지 로드
        return require(`@/assets/img/asso/${flag}.png`);
      } catch (error) {
        console.error("이미지를 찾을 수 없습니다:", error);
        return ''; // 이미지가 없을 경우 대체 이미지 경로 또는 빈 문자열 반환
      }
    },

    checkAllConfirm()
    {
      if(this.allConfirmFlag === "Y")
      {
        this.confirm_Terms = "Y" //약관동의
        this.confirm_spTerms = "Y"
        this.confirm_Info = "Y"
        this.confirm_Privacy = "Y"
        this.confirm_3rd = "Y"
      }
      else if(this.allConfirmFlag === "N")
      {
        this.confirm_Terms = "N" //약관동의
        this.confirm_spTerms = "N"
        this.confirm_Info = "N"
        this.confirm_Privacy = "N"
        this.confirm_3rd = "N"
      }      
    },
    plus(p)
    {
      if(p == "성인")
      {
        this.ageNumber[1]++
        if(this.ageNumber[1] > 30)
        {
          this.ageNumber[1] = 30
          this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
          return false;
        }
        this.checkDptDate("P")
      }
      else if(p == "소아")
      {
        this.ageNumber[2]++
        if(this.ageNumber[2] > 30)
        {
          this.ageNumber[2] = 30
          this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
          return false;
        }
        this.checkDptDate("P")
      }
      else if(p == "유아")
      {
        if(this.ageNumber[1] <=0 && this.ageNumber[2] <= 0)
        {
          this.ageNumber[3] = 0   //유아 0처리
          this.$swal.fire("","보호자가 없이 유아단독 예약은 할 수 없습니다.","warning");
          return false;          
        }
        else
        {
          this.ageNumber[3]++
          if(this.ageNumber[3] > 30)
          {
            this.ageNumber[3] = 30
            this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
            return false;
          }
          this.checkDptDate("P")
        }
      }
      else if(p == "기타")
      {
        this.ageNumber[4]++
        if(this.ageNumber[4] > 30)
        {
          this.ageNumber[4] = 30
          this.$swal.fire("","단체인원과 관련하여 당사로 문의(1660-4602)를 주시면 신속히 회신드리겠습니다.","info");
          return false;
        }
        this.checkDptDate("P")
      }
    },
    minus(p)
    {
      if(p == "성인")
      {
        this.ageNumber[1]--
        if(this.ageNumber[1] < 0)
        {
          this.ageNumber[1] = 0
          return false;
        }
        this.checkDptDate("P")        
      }
      else if(p == "소아")
      {
        this.ageNumber[2]--
        if(this.ageNumber[2] < 0)
        {
          this.ageNumber[2] = 0
          return false;
        }
        this.checkDptDate("P")
      }
      else if(p == "유아")
      {
        this.ageNumber[3]--
        if(this.ageNumber[3] < 0)
        {
          this.ageNumber[3] = 0
          return false;
        }
        this.checkDptDate("P")
      }
      else if(p == "기타")
      {
        this.ageNumber[4]--
        if(this.ageNumber[4] <= 0)
        {
          this.ageNumber[4] = 0
          return false;
        }
        this.checkDptDate("P")
      }
    },
    //리뷰작성자는 리뷰를 수정할 수 있다
    changeReview(rvCode, nSeq, iSeq)
    {
      //alert("rvCode:"+rvCode+"nSeq:"+nSeq)
      this.$router.push({path: '/reviewModify', query: {rvCode: rvCode, nSeq: nSeq, iSeq: iSeq}});      
    },    
    /**달력표시금액 간략컨버팅*/
    formatManCurrency(amount) {
      // 만 단위로 처리하기 위해 10,000으로 나누기
      const amountInMan = amount / 10000;

      // 만 원 미만일 경우 단순히 숫자를 소수점 첫째 자리까지 표시
      if (amountInMan < 1) {
        return amountInMan.toFixed(1);
      } else if (amount >= 1000000) {
        // 100만원 초과+소숫점이 있으면 절상처리
        return Math.ceil(amountInMan).toLocaleString();
        //return Math.floor(amountInMan).toLocaleString();
      } else {
        // 만 원 이상일 경우 소수점 이하 1자리까지 표시
        const decimalPart = amountInMan - Math.floor(amountInMan);
        const roundedDecimalPart = Math.round(decimalPart * 10) / 10;
        
        if (roundedDecimalPart === 0) {
          return Math.floor(amountInMan).toLocaleString();
        } else {
          return (Math.floor(amountInMan) + roundedDecimalPart).toLocaleString();
        }
      }
    },

    //가격이 0이 아닌 날이 있는 월정보
    async getFirstAvailableDate() 
    {
      await this.getPdtPriceForCalendar();
      const firstAvailableDate = Object.keys(this.priceData).find(date => this.priceData[date] !== null);
      if (firstAvailableDate) {
        this.currentDate = moment(firstAvailableDate).startOf('month');
      } else {
        this.goToCurrentMonth();
      }
    },
    //상품리뷰리스트
    async getReviewList() 
    {
      let reviewList = await this.$api("/api/reviewList", {param: [this.productId]});
      if(reviewList.length > 0)
      {
        this.reviewList = reviewList
      }
      //console.log("reviewList:",this.reviewList)
    },
    //new calendar
    previousMonth()
    {
      this.inquiryFlag = "N"
      //compute 원할한 처리를 위해 clone()이용
      this.currentDate = this.currentDate.clone().subtract(1, 'month');
      this.selectedDate = null;
    },
    nextMonth() 
    {
      this.inquiryFlag = "N"
      this.currentDate = this.currentDate.clone().add(1, 'month');
      this.selectedDate = null;
    },
    goToCurrentMonth() 
    {
      this.inquiryFlag = "N"
      const today = moment();
      this.currentDate = moment([today.year(), today.month(), today.date()]);
      this.selectedDate = null;
    },    
    selectDate(date)
    {
      this.inquiryFlag = 'N'
      this.defaultValFlag = 'Y'

      let selectedDate = date;
      this.selectedDate = moment(selectedDate).format('YYYY-MM-DD')
      this.selectedPrice = this.getPrice(date.format('YYYY-MM-DD'));

      if (this.selectedPrice > 0) 
      {
        // 선택된 날짜를 `selDate_new` 함수에 전달
        this.selDate_new(this.selectedDate);
      }
    },

    //New 달력용 가격리스트 호출
    async getPdtPriceForCalendar() 
    {
      let calPdtPrice = [];
      calPdtPrice = await this.$api("/api/calPdtPriceNew", {param: [this.productId]});

      //달력에는 성인요금만 표시
      this.calPdtPrice = calPdtPrice

      // 달력표시용 가격데이타
      let priceData = {};
      if(this.productDetail.marginMethod === "P")
      {
        priceData = calPdtPrice.reduce((obj, item) => 
        {
          if(item.sellStat === 'N' || item.pdtPrice == 0)
          {
            obj[item.saleDate] = null;
          }
          else
          {
            obj[item.saleDate] = item.pdtPrice + (item.pdtPrice * this.productDetail.basicMargin/100);
          }
          return obj;
        }, {});
      }
      else
      {
        priceData = calPdtPrice.reduce((obj, item) => 
        {
          if(item.sellStat === 'N' || item.pdtPrice == 0)
          {
            obj[item.saleDate] = null;
          }
          else
          {
            obj[item.saleDate] = item.pdtPrice*1 + (this.productDetail.basicMargin*1);
          }
          return obj;
        }, {});
      }
      //this.calPdtPrice = calPdtPrice;
      this.priceData = priceData;
    },
    getPrice(date) 
    {
      if (!this.priceData) 
      {
        // priceData 객체가 로딩되지 않았다면 로딩하기
        this.getPdtPriceForCalendar();
      }
      if (date in this.priceData)
      {
        // priceData 객체에서 날짜에 해당하는 가격 추출하여 숫자로 변환하여 반환
        const price = this.priceData[date];
        return Number(price);
      }
      else
      {
        // const price = null
        // console.log("price:",price)
        return null
      }
    },
    isToday(date) 
    {
      return moment().isSame(date, 'day');
    },
    //모먼트를 이용한 일자검증
    isSelected(date) 
    {
      if (!this.selectedDate) 
      {
        return false;
      }
      else if (moment(this.selectedDate).isSame(date, 'day')) 
      {
        return true;
      }
      else 
      {
        return false;
      }
    },

    goTo(id) {
      var element = document.getElementById(id);
      if (element) 
      {
        element.scrollIntoView({ behavior: 'smooth' });
      } 
      else 
      {
        console.error('Element with the specified ID not found');
      }
    },
    
    showFlag() {
      this.rShowFlag = (this.rShowFlag == 'Y') ? 'N' : 'Y'
      //alert(this.rShowFlag)
    },
    //권종&옵션설명
    popover(p1, p2)
    {
      let optPrice = 0;
      if(p1==="P")
      {
        optPrice = this.getCurrencyFormat(this.etcOptionInfo[p2].etcOptPrice)
      }

      switch(p1) {
        //case "A": this.$swal.fire("","만 12세 이상","","customClass:{width:10px}");
        case "A": this.$swal.fire({
                    html: '<small class="text-muted"><small>만 12세 이상</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        case "C": this.$swal.fire({
                    html: '<small class="text-success text-muted"><small>만 02세 이상</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        case "I": this.$swal.fire({
                    html: '<small class="text-success text-muted"><small>생후 24개월 미만</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        case "D": this.$swal.fire({
                    html: '<small class="text-success text-muted"><small>할인조건 만족 시</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        case "P": this.$swal.fire({
                    html: '<small class="text-success text-muted"><small>'+ optPrice +'원</small></small>',
                    width: 'auto',
                    height: 'auto',
                    showConfirmButton: false
                  })
        break;
        //default: return this.$swal.fire("","권종안내입니다","info");
      }
    },

    /** 지정된 id로 화면이동 */
    // scrollToId(id) 
    // {
    //   const element = document.getElementById(id);
    //   if (element) 
    //   {``
    //     element.scrollIntoView({ behavior: 'smooth' });
    //   }
    // },

    //모바일디바이스 대응
    scrollToId(id) 
    {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth'
        });
      }
    },
    
    //달력예약일 선택 시 처리루틴
    async selDate_new()
    {
      this.inquiryFlag = 'Y'
      this.reqRtnDate = "";

      //프로모션 처리
      let promoFlag = "N"
      let promoMethod = ""
      let promoRate = 0

      if(this.pdtPromoInfo.promoName != "")      
      {
        
        let promoSDate = this.getDateFormat(this.pdtPromoInfo.promoStart)
        let promoEDate = this.getDateFormat(this.pdtPromoInfo.promoEnd)
        promoMethod = this.pdtPromoInfo.promoMethod
        promoRate = this.pdtPromoInfo.promoRate

        if(this.selectedDate >= promoSDate && this.selectedDate <= promoEDate)
        {
          promoFlag = "Y"
        }
        else
        {
          promoFlag = "N"
        }
      }

      if(this.productDetail.tripProp === "D")
      {
        let selecDate_tmp = new Date(this.selectedDate);
        let reqRtnDate_tmp = "";

        if(this.productDetail.stayCnt == "0") //당일리턴
        {
          reqRtnDate_tmp = new Date(selecDate_tmp.setDate(selecDate_tmp.getDate() + (this.productDetail.stayCnt)));
        }
        else if(this.productDetail.stayCnt > 0) //무박1일 포함(=당일리턴)
        {
          reqRtnDate_tmp = new Date(selecDate_tmp.setDate(selecDate_tmp.getDate() + (this.productDetail.stayCnt - 1)));
        }
        else
        {
          this.$swal.fire('', '날짜선택에 오류가 발생했습니다.<br>담당자에 문의하여 주십시오.', 'error');
          return false;          
        }
        this.reqRtnDate = this.getDateFormat(reqRtnDate_tmp);
      }
      //선택일의 권종별 가격
      let selectedCalPdtPrice = await this.$api("/api/selectedCalPdtPrice_New", {param: 
        [this.productId, this.selectedDate]});

      //권종별 표시가격(예약단계)
      let i = 0;
      let sMargin = 0;
      let sPromo = 0;
      let sMinPrice = 0;

      while(i < selectedCalPdtPrice.length)
      {
        if(this.productDetail.marginMethod === "P")
        {
          sMargin = (selectedCalPdtPrice[i].pdtPrice*1) * ((this.productDetail.basicMargin*1) / 100)
          //sMinPrice = (selectedCalPdtPrice[i].pdtPrice*1) + sMargin*1;
        }
        else
        {
          sMargin = this.productDetail.basicMargin*1;
          //sMinPrice = (selectedCalPdtPrice[i].pdtPrice*1) + sMargin*1;
        }

        if(promoFlag === "Y")
        {
          if(promoMethod === "P")
          {
            sPromo = (selectedCalPdtPrice[i].pdtPrice*1) * ((promoRate*1) / 100)
          }
          else if(promoMethod === "A")
          {
            sPromo = promoRate*1;
          }
        }

        //0원 권종은 변동없음
        if(selectedCalPdtPrice[i].pdtPrice*1 > 0)
        {
          sMinPrice = (selectedCalPdtPrice[i].pdtPrice*1) + sMargin*1 - sPromo*1
        }
        else
        {
          sMinPrice = 0
          sPromo = 0
          sMargin = 0
        }

        selectedCalPdtPrice[i].sMinPrice = sMinPrice;
        selectedCalPdtPrice[i].sMargin = sMargin;
        selectedCalPdtPrice[i].sPromo = sPromo;
        i++;
      }
      this.selectedCalPdtPrice = selectedCalPdtPrice;
      this.defaultValFlag = "N"   //예약변수초기화 플래그 해제
      //console.log("selectedCalPdtPrice_Fin:", this.selectedCalPdtPrice);

    },

    async getProductDetail() 
    {
      let productDetail = [];
      if(this.pdtFlag === "MIX") {
        productDetail = await this.$api("/api/productDetail_mix", {param: [this.productId]});
        // if(productDetail.length > 0) {
        //   this.productDetail = productDetail[0];
        // }
      }
      else {
        productDetail = await this.$api("/api/productDetail", {param: [this.productId]});
        // if(productDetail.length > 0) {
        //   this.productDetail = productDetail;
        // }
      }

      if(this.user != undefined || this.user != "") 
      {

        if(this.user.joinFlag === 'N')
        {
          this.reqName = (!this.user.name) ? "" : this.user.name;
          this.reqTelNo = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
        }
        else
        {
          this.reqName = (!this.user.nickname) ? "" : this.user.nickname;
          
          let kakaoPhone = ""
          if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
          {
            //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
            kakaoPhone = this.user.phone.replace("+82 ", "0")
          }
          this.reqTelNo = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거
        }
        
        this.reqEmail = (!this.user.email) ? "" : this.user.email;
      }
      
      if(productDetail.length > 0) {
        this.productDetail = productDetail[0];
        this.tripProp = this.productDetail.tripProp;
      }
      console.log("productDetail:", this.productDetail)

      //this.defaultValue = 'Y'
      this.getProductImage();
      this.getProductDetailImage(); // 제품상세이미지호출
      this.getProductPrice(); //상품가격
      this.getPromoInfo(); //프로모션체크

      this.getReviewList() //여행후기

      this.getProductTransInfoExited(); //상품교통정보

      this.getPdtPriceForCalendar();
    },
    //상품교통정보
    async getProductTransInfoExited() {
      let pdtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.productId]});
      if(pdtTransInfo1.length > 0) {
        this.pdtTransInfo1 = pdtTransInfo1[0];
      }
      //console.log("pdtTransInfo1:",this.pdtTransInfo1);
    },

    async getProductImage() {
      // 상세페이지용 카로우셀 이미지
      let productImage = await this.$api("/api/productImage", {param: [this.productId]});
      this.productImage = (productImage.length > 0) ? productImage : "";
    },
    async getProductDetailImage() {
      // 상세설명이미지 호출
      // let noImage = this.noImage;
      let productDetailImage = await this.$api("/api/productDetailImage", {param: [this.productId]});
      this.productDetailImage = (productDetailImage.length > 0) ? productDetailImage : "";
      //console.log("productDetailImage:", this.productDetailImage);
    },
    //프로모션 정보추출
    async getPromoInfo() {
      let pdtPromoInfo = [];
      pdtPromoInfo = await this.$api("/api/productPromotion", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0]
      }
      //console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },

    //표시용 상품가격
    async getProductPrice() 
    {
      // 조건에 부합되는 상품최소가격일자
      let pdtDetailPriceDate = await this.$api("/api/pdtDetailPriceDate", {param: [this.productId,this.productId]});
      let minPriceDate = pdtDetailPriceDate[0].pdtDate;

      let detailPrice = await this.$api("/api/pdtDetailminPrice", {param: [this.productId,minPriceDate]});
      console.log("detailPrice:",detailPrice)

      if(detailPrice.length > 0) 
      {
        let i = 0;
        let sMargin = 0;
        let sMinPrice = 0;
        
        while (i < detailPrice.length)
        {
          if(this.productDetail.marginMethod === "P")
          {
            sMargin = (detailPrice[i].productPrice*1) * ((this.productDetail.basicMargin*1) / 100);
            sMinPrice = (detailPrice[i].productPrice*1) + sMargin;
          }
          else
          {
            sMargin = this.productDetail.basicMargin*1;
            sMinPrice = (detailPrice[i].productPrice*1) + sMargin;
          }
          detailPrice[i].sMinPrice = sMinPrice;
          //detailPrice[3].sMinPrice = 0;
          i++;
        }
        this.detailPrice = detailPrice;
        //console.log("detailPrice:",this.detailPrice)
      }

      if(this.productDetail.addOptionFlag === "Y")
      {
        this.getPdtAddOptionExited();
        //this.getAddOption();
      }
      if(this.productDetail.etcOptionFlag === "Y") 
      {
        this.getEtcOptionInfo();
      }
    },
    //기존상품 추가옵션정보
    async getPdtAddOptionExited() {
      //대상상품에 적용된 추가옵션코드 추출
      let addOptCodes = {};
      let addOptInfo = {};
      if(this.pdtFlag === 'MIX') {
        addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed_mix", {param: [this.productId]});
        this.addOptCodes = addOptCodes;
        addOptInfo = await this.$api("/api/pdtAddOption_existed_mix", {param: [this.productId]});
        //console.log("addOptInfo:",addOptInfo);
      }
      else {
        addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed", {param: [this.productId]});
        this.addOptCodes = addOptCodes;
        addOptInfo = await this.$api("/api/pdtAddOption_existed", {param: [this.productId]});
        //console.log("addOptInfo:",addOptInfo);
      }
      //console.log("addOptCodes:",this.addOptCodes);
      
      let i=0;
      let pdtAddOptExisted = [];
      while(i<addOptCodes.length) {
        //alert(addOptCodes[i].pdtCode);
        pdtAddOptExisted.push(addOptInfo.filter(e => {return e.pdtCode === addOptCodes[i].pdtCode}));
        i++;
      }
      if(pdtAddOptExisted.length > 0){
        this.pdtAddOptExisted = pdtAddOptExisted;
      }
      //console.log("pdtAddOptExisted:",this.pdtAddOptExisted);
    },
    //기타옵션획득(-선택옵션)
    async getEtcOptionInfo() {
      //this.etcOptionInfo = await this.$api("/api/etcOptionInfo", {param: [this.productId]});
      let etcOptionInfo = await this.$api("/api/etcOptionInfo1", {param: [this.productId]});
      this.etcOptionInfo = etcOptionInfo;
      //console.log("etcOptionInfo:",this.etcOptionInfo);
    },
    setRtnDate(){
      //console.log("selectedSDate:",this.selecDate);
      this.reqRtnDate = "";
      // 선택가능 리턴일 구간지정
      if(this.inquiryFlag === 'Y') 
      {
        //리턴일=출발일가능(당일리턴)
        let dateVal_tmp = new Date(this.selecDate);
        //let tomorrow_tmp = new Date(dateVal_tmp.setDate(dateVal_tmp.getDate() + 1));
        let tomorrow_tmp = new Date(dateVal_tmp.setDate(dateVal_tmp.getDate()));
        let tomorrow = this.getDateFormat(tomorrow_tmp);
        //console.log("내일 : ", tomorrow);

        //let todayFin = this.reqDptDate;
        let todayFin = tomorrow;

        //console.log("rtnDate:",this.reqRtnDate);

        let tStart = this.getDateFormat(this.productDetail.tripStart);
        let tEnd = this.getDateFormat(this.productDetail.tripEnd);
        let sStart = this.getDateFormat(this.productDetail.salesStart);
        let sEnd = this.getDateFormat(this.productDetail.salesEnd);
        let rtDate_s = "";
        let rtDate_e = "";
        
        if(tStart == sStart) {
          rtDate_s = sStart;
        }
        else if(tStart < sStart) {
          rtDate_s = sStart;
        }
        else if(tStart > sStart) {
          rtDate_s = tStart;
        }
        
        if(tEnd == sEnd) {
          rtDate_e = sEnd;
        }
        else if(tEnd > sEnd) {
          rtDate_e = sEnd;
        }
        else if(tEnd < sEnd) {
          rtDate_e = tEnd;
        }

        document.getElementById("rtDate").setAttribute("min", todayFin);
        //document.getElementById("rtDate").setAttribute("max", rtDate_e);
        document.getElementById("rtDate").setAttribute("max", this.calLimitedEnd);
        console.log(rtDate_s,rtDate_e);
      }
    },
    //단순상품문의
    inquiryProduct() {
      //alert(this.productId);
      this.$router.push({path: '/inquiryProduct', query: {productId: this.productId, salesRoute:this.productDetail.salesRoute}});
    },
    checkDptDate(param)
    {
      let p = ""
      p = param
      let vatRate = this.productDetail.vatPrice
      console.log(p)

      let aduCnt = this.ageNumber[1]*1
      let chiCnt = this.ageNumber[2]*1
      let infCnt = this.ageNumber[3]*1
      //let disCnt = this.ageNumber[4]*1

      let aduPrice = this.selectedCalPdtPrice[0].sMinPrice*1
      let chiPrice = this.selectedCalPdtPrice[1].sMinPrice*1
      let infPrice = this.selectedCalPdtPrice[2].sMinPrice*1

      //저장할 1인당 금액
      this.aduPrice = aduPrice
      this.chiPrice = chiPrice
      this.infPrice = infPrice
      //let disPrice = this.selectedCalPdtPrice[3].sMinPrice*1

      let tot_aduPrice = aduCnt * aduPrice
      let tot_chiPrice = chiCnt * chiPrice
      let tot_infPrice = infCnt * infPrice
      //let tot_disPrice = disCnt * disPrice

      let tot_price_tmp = tot_aduPrice + tot_chiPrice + tot_infPrice
      let vatPrice = 0

      if(vatRate > 0)
      {
        vatPrice = tot_price * (vatRate/100)
      }
      
      let tot_price = tot_price_tmp + vatPrice

      //마진정보 별도계산
      let aduMargin = this.selectedCalPdtPrice[0].sMargin*1
      let chiMargin = this.selectedCalPdtPrice[1].sMargin*1
      let infMargin = this.selectedCalPdtPrice[2].sMargin*1
      let tot_aduMargin = aduCnt * aduMargin
      let tot_chiMargin = chiCnt * chiMargin
      let tot_infMargin = infCnt * infMargin

      let tot_Margin = tot_aduMargin + tot_chiMargin + tot_infMargin

      //프로모션정보 별도계산
      let aduPromo = this.selectedCalPdtPrice[0].sPromo*1
      let chiPromo = this.selectedCalPdtPrice[1].sPromo*1
      let infPromo = this.selectedCalPdtPrice[2].sPromo*1
      let tot_aduPromo = aduCnt * aduPromo
      let tot_chiPromo = chiCnt * chiPromo
      let tot_infPromo = infCnt * infPromo

      let tot_Promo = tot_aduPromo + tot_chiPromo + tot_infPromo
      //console.log(tot_aduPrice,"/",tot_chiPrice,"/",tot_infPrice,"/",tot_disPrice)
      //console.log(tot_price)
      
      this.showPrice = tot_price

      this.tot_aduPrice = tot_aduPrice
      this.tot_chiPrice = tot_chiPrice
      this.tot_infPrice = tot_infPrice

      this.tot_aduCount = aduCnt
      this.tot_chiCount = chiCnt
      this.tot_infCount = infCnt
      this.tot_perCount = aduCnt + chiCnt + infCnt
      
      this.tot_margin = tot_Margin
      this.tot_promo = tot_Promo
      this.tot_vatPrice = vatPrice
    },

    //예약코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) 
      {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_resCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","예약번호 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },

  orderCheck(flag){
    this.showModal2 = false
    console.log(flag+'21K23!98h40D')
    //로그인체크
    if(this.userInfo == undefined || this.userInfo == "" || !this.userInfo)
    {
      //this.$swal.fire("","로그인이 필요합니다.","info");
      this.$swal.fire({
        html: "<b>예약</b>을 시작합니다",
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: "로그인 페이지로 이동",
        denyButtonText: "비회원으로 예약진행",
        imageUrl: '/IMGs/welcome.png',
        imageWidth: 400,
        imageHeight: 200,
      })
      .then((result) => {
          if(result.isConfirmed)
          {
            this.$router.push({path: '/b2CLogin', query:{st: "Y"}});
          }
          else if(result.isDenied)
          {
            this.memberFlag = "N"
            //console.log("choiceMemberFlag:", this.memberFlag)
            this.order(flag)
          }
        })
      }
      else
      {
        this.order(flag)
      }
    },

    async order(flag) 
    {
      this.noPopFlag = "Y"    //새로고침방지
      //console.log("memberFlag:",this.memberFlag)

     //예약번호생성
      let catCode = "RQ-" + this.salesRoute;
      let reqReserveCode = await this.genCode(catCode)
      this.reqReserveCode = reqReserveCode
      // console.log("resCode:", this.reqReserveCode)
      console.log("flag1:", flag+'21K23!98h40D')

      this.reqRtnDate = (this.reqRtnDate == "") ? null : this.reqRtnDate;

      //100원 미만 절사처리
      let reqPdtPriceFin = Math.floor(this.showPrice/100) * 100;


      if(reqReserveCode === undefined || this.showPrice === undefined || this.showPrice < 0 || this.productDetail.pdtCode === undefined) {
        var swal = require('sweetalert2');
        swal.fire({
          html: '예약진행 중 오류가 있습니다.<br>확인 후 재신청하여 주십시오',
          icon: 'warning'
        });
        return false;
      }
      else if(this.tot_aduCount == 0 && this.tot_chiCount == 0) {
        this.$swal.fire("","예약인원수를 확인해주세요","question");
        return false;
      }      
      else 
      {
        let disCnt = 0
        let disPrice = 0
        let rCopyFlag = "N"
        let reqStatus = "IQ"
        let completeFlag = "N"

        //연계상품은 환급,할인 미적용
        let memLevel = ""
        let dispFlag = ""
        let priceForMem = 0


        //this.userInfo = handlerId OR user.email

        //예약정보 일부생성
        let res_insReserveM = await this.$api("/api/proc_insReserve_m", 
          {param: [
            this.reqReserveCode,this.productId,reqStatus,this.selectedDate,this.tot_infCount,this.tot_aduCount,this.tot_chiCount,disCnt,this.tot_perCount,this.aduPrice,this.chiPrice,this.infPrice,disPrice,this.tot_margin,this.tot_vatPrice,this.reqRtnDate,this.tripProp,this.tot_promo,this.productDetail.stayCnt,reqPdtPriceFin,reqPdtPriceFin,rCopyFlag,
            this.productDetail.basicMargin,this.productDetail.marginMethod,this.productDetail.ctCd,completeFlag,this.pdtPromoInfo.promoCode,
            this.memberFlag, memLevel, dispFlag, priceForMem, priceForMem, priceForMem, priceForMem,
            '0','0','0','','0','',
            '0','0','0','','','',''
        ]});

        if(res_insReserveM.affectedRows < 0 || res_insReserveM.affectedRows === undefined)
        {
          this.$swal.fire('','<b class="text-danger">죄송합니다</b><br>처리 중 문제가 발생했습니다.('+ res_insReserveM.error.sqlMessage +')', 'warning')
          return false;
        }
        else
        {
          this.$router.push({path: "/reservation", query: {
            resCode: this.reqReserveCode, prodId: this.productId, assoFlag: "Y"
          }})
        }
      }
    },
  }
}
</script>

<style>
@import "../assets/css/style.css";

/*
#recent-item{
  display: none;
}
*/

#product{
  margin-top:4rem;
}


/* -------------------------------------------------
  # 왼쪽
--------------------------------------------------- */
#product .pr-left{
  padding-right:1rem
}
@media (max-width:991px){
  #product .pr-left{
    padding-right:0
  }
}


/* -----------------
  타이틀
----------------- */
#product .pr-left .section-tittle {
  margin: 2rem 0 1rem;
  text-align: left !important
}
#product .pr-left .section-tittle h2 {
  font-size: 1.8rem;
  display: block;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 5px;
  color: #111;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #product .pr-left .section-tittle h2 {
    font-size: 1.7rem
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  #product .pr-left .section-tittle h2 {
    font-size: 1.6rem
  }
}
@media (max-width: 575px) {
  #product .pr-left .section-tittle h2 {
    font-size: 1.3rem
  }
}


/* -----------------
  사진
----------------- */
#product .pr-left .carousel-inner img {
  height: 500px;
  object-fit: cover
}

@media (max-width:991px){
  #product .pr-left .carousel-inner img{
    height: 350px;
  }
}


/* -----------------
  상품번호
----------------- */
#product .pr-left .subheading{
  font-size: .8rem;
  color: #999;
  margin-bottom: 5px;
}


/* -----------------
  교통편,체크포인트
----------------- */
#product .pr-left .vech{
  margin-top:2.5rem;
  border-top:2px solid #000
}
#product .pr-left .vech .stand{
  background-color: #F8F8F8;
  color:#000;
  font-size:1.1rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 5px;
  width:170px;
  vertical-align:middle
}
#product .pr-left .vech .sub{
  padding:1rem 0 1rem 1.5rem
}

@media (max-width:991px){
  #product .pr-left .vech .stand{
    font-size:.95rem;
    letter-spacing: 0;
    width:85px;
  }
  #product .pr-left .vech .sub{
    padding:1rem 0 1rem .5rem;
    font-size:.9rem
  }
}



/* -----------------
  포함,불포함
----------------- */
#product .pr-left .in-non{
  margin-top:5rem;
  border-top:2px solid #000
}
#product .pr-left .in-non thead th{
  background-color: #F8F8F8;
  height:4rem;
  text-align: center;
  vertical-align:middle;
  font-size:1.1rem;
  font-weight: 600
}
#product .pr-left .in-non thead th:first-child,
#product .pr-left .in-non tbody td:first-child{
  border-right: 1px solid #ddd;
}
#product .pr-left .in-non tbody td{
  text-align: center;
  vertical-align:middle;
  padding:20px 0
}

@media (max-width:991px){
  #product .pr-left .in-non thead th{
    font-size:1rem;
    height:3.5rem;
  }
  #product .pr-left .in-non tbody td{
    font-size:.9rem;
    padding:15px 0
  }
}



/* -----------------
  카테고리
----------------- */
#product .pr-left .category {
  margin: 7rem 0;
}
#product .pr-left .category .clist{
  margin: 4rem 0;
  border-bottom: 3px solid #000;
  padding-bottom:1rem
}
#product .pr-left .category .nav-tabs {
  border:none
}
#product .pr-left .category ul li{
  display: inline-block; 
  margin-right: 3rem
}
#product .pr-left .category ul li:last-child{
  margin-right:0
}

#product .pr-left .category .nav-link{
  border:none;
  color: #999;
}

#product .pr-left .category .nav-item h4{
  position: relative; 
  font-size: 1.3rem;
  margin: 0;
  font-weight: 600;
  transition: all 500ms ease-out; 
}

#product .pr-left .category .nav-item h4::after{
  position: absolute; 
  display:block;
  content: '';
  border-top:none;
  border-right:none;
  border-left:none;
  border-bottom: 5px solid #000; 
  transition: all 250ms ease-out; 
  bottom:-29px;
  left: 50%; 
  width: 0;
}

#product .pr-left .category .nav-item:hover h4::after{
  transition: all 250ms ease-out; 
  left: 0%; 
  width: 100%;
}

#product .pr-left .category .nav-item:hover h4{
  color:#000
}


#product .pr-left .category .nav-tabs .nav-link.active {
  color:#000;
}


@media (max-width:1200px) {
  #product .pr-left .category ul li{
    margin-right: 1.5rem
  }
  #product .pr-left .category .nav-item h4{
    font-size:1.1rem
  }
}
@media (max-width:767px) {
  #product .pr-left .category ul li{
    margin-right: .8rem
  }
  #product .pr-left .category ul li .nav-link{
    padding:0 5px
  }
  #product .pr-left .category .nav-item h4{
    font-size:.95rem
  }
  #product .pr-left .category .nav-item h4::after{
    bottom:-21px;
  }
}
@media (max-width:453px) {
  #product .pr-left .category ul li{
    margin-right: .5rem
  }
  #product .pr-left .category .nav-item h4{
    font-size:.8rem
  }
}

/* ---- 카테고리제목 ---- */
#product .pr-left .category .tab-pane h4{
  font-weight: 600;
}

/* ---- 이용후기 ---- */
#product .pr-left .category .creview{
  position:relative
}
#product .pr-left .category .creview p {
  font-size: 0.8rem;
  margin-top: 20px
}
#product .pr-left .category .creview img{
  width:auto;
  height:220px !important;
  object-fit: cover;
}
#product .pr-left .category .creview .c-content{
  padding-top:10px
}
#product .pr-left .category .creview .reviewt{
  font-size:1.4rem;
  font-weight: bold;
}
#product .pr-left .category .creview .reviewn{
  font-size:.75rem;
  color:#aaa
}
#product .pr-left .category .creview .reviewn .name{
  border-right:1px solid #aaa;
  padding-right:15px
}
#product .pr-left .category .creview .reviewn .date{
  padding-left:15px
}
#product .pr-left .category .creview .reviewc{
  font-size:.9rem;
  padding:10px 0 30px
}
@media (min-width:992px){
  #product .pr-left .category .creview .c-content{
    padding-left:30px
  }
}




/* -------------------------------------------------
  # 오른쪽
--------------------------------------------------- */
#product .pr-right{
  display: block;
  margin-top:0rem;
  padding-bottom:0rem;
  border:1px solid #000;
  border-radius: 15px;
  background-color:rgba(255, 252, 252, 0.8);
  width: 22rem;
}

/* #product .pr-right .container{
  align-items: center;
  background-color:rgba(255, 252, 252, 0.8);
} */


/* -----------------
  달력
----------------- */
/* 글자 */
/* #product .pr-right .top{
  text-align:center;
  margin-top:0.5rem;
  font-size:1.5rem;
  color:#000;
  font-weight: 600;
} */

/* 윗부분 */
#product .pr-right .calendar{
  width:100%
}
#product .pr-right .calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color:rgba(2, 13, 39, 0.8);
}
#product .pr-right .calendar-header-date {
  font-size: 1.4rem;
  font-weight: bold;
  position: relative;
  top:50%;
  transform: translateY(30%);
  color:white;
}

/* 버튼 아이콘 */
#product .pr-right .calendarbtn {
  border: 0px;
  background-color: transparent;
  position: relative;
  top:50%;
  transform: translateY(30%);
  color:white;
}

/* 안에 내용 */
#product .pr-right .calendar-row {
  display: flex;
  cursor: pointer;
}
#product .pr-right .calendar-day-header {
  width: calc(100% / 7);
  text-align: center;
  padding: 1px;
  font-weight: 700;
  font-size: 1.1rem;
}
#product .pr-right .calendar-day {
  width: calc(100% / 7);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px; 
}
#product .pr-right .calendar-date {
  font-size: 1.2rem;
  font-weight: normal;
}

/* 오늘 날짜 */
#product .pr-right .calendar-day.today .calendar-date{
  border:2px solid #f00;
  text-align: center;
}
/* #product .pr-right .calendar-day.today .calendar-date{
  border:2px solid #f00;
  border-radius: 25%;
  width:30px;
  height:30px;
  text-align: center;
} */

#product .pr-right .calendar-day.today::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
}
#product .pr-right .calendar-day.today .calendar-date {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/* 가격들 */
#product .pr-right .calendar-price {
  margin-top: 0.1rem;
  font-size: 0.8rem;
}


/* -----------------
  금액
----------------- */
#product .pr-right .price{
  margin-top:.5rem;
}
#product .pr-right .price table{
  margin-left:auto; 
  margin-right:auto;
}
#product .pr-right .price td{
  font-size:1.1rem;
  font-weight:600;
  color:#000;
  margin:5px 0
}
#product .pr-right .price .bi-question-square{
  font-size:.9rem;
  font-weight: normal;
  color:darkblue;
}

/* 인원수조정 */
#product .pr-right .price .count{
  text-align: center;
  padding: 0 10px;
}
#product .pr-right .price .count .btn-group{
  border:1px solid #000;
  border-radius: 0;
}
#product .pr-right .price .count .number{
  border-right:1px solid #000;
  border-left:1px solid #000;
  width: 4rem;
  padding:8px 0
}
#product .pr-right .price .count .left,
#product .pr-right .price .count .right{
  font-size:1.3rem
}
#product .pr-right .price .count .btn-check:focus+.btn,
#product .pr-right .price .count .btn:focus{
  border-radius: 0;
}


/* -----------------
  총금액
----------------- */
#product .pr-right .total-P table{
  margin-left:auto; 
  margin-right:auto;
}
#product .pr-right .total{
  margin-top:1rem
}
#product .pr-right .total span{
  font-size:1.3rem;
  padding-left:3px
}
#product .pr-right .total .result{
  padding-left:10px;
  font-size:2.3rem;
  font-weight: 600;
  color:#000
}


/* ----------------------------------
  예약하기 버튼
---------------------------------- */
#product .pr-right .pr-go{
  margin-top:1rem;
  text-align: center;
}
#product .pr-right .pr-go p{
  color:#fff;
  font-size:1.3rem;
  font-weight: 600;
  letter-spacing: 5px;
  background-color: #676666;
  padding:15px 0;
  border-radius: 12px;
}
#product .pr-right .pr-go p:hover{
  color:gold;
  background-color: #000;
}

.checkPoint, .supPre, .supCxl, 
.inclusion, .notInclusion, 
.reviewContent {
  word-wrap:break-word;
  white-space:pre-wrap;
}


/* 오른쪽 화면 모바일에서 사라지게 */
@media (max-width:991px){
  #product .pr-right{
    display:none
  }
}


/* -------------------------------------------------
  # 모바일 예약하기
--------------------------------------------------- */
#product .mobile-cal{
  display: block;
}

#product .mobile-cal .openm{
  background: rgba(80, 80, 80);
  color: #fff;
  text-align: center;
  padding:15px 0;
  transition: all .4s ease;
  cursor: pointer;
  width:100%;
  position: fixed;
  left:0;
  bottom: 0;
}

#product .mobile-cal .modal2 {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y:scroll;
  margin: 0;
  padding: 0;
  transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
  top: 100%;
  position: fixed;
  left: 0;
  text-align: left;
}
#product .mobile-cal .modal2 .body {
  padding: 40px 10px 50px 10px;
  position: relative;
}

#product .mobile-cal .modal2 .closem{
  position:absolute;
  top:5px;
  right:3%;
}


/* -----------------
  모바일 달력
----------------- */
#product .modal2 .calendar{
  width:100%;
  margin:0
}
#product .modal2 .calendar-header-date{
  font-size:1.5rem
}
#product .modal2 .calendar-row{
  margin:3px 0
}
#product .modal2 .calendar-day{
  height:auto
}
#product .modal2 .calendar-date{
  font-size:1rem
}
#product .modal2 .calendar-day.today .calendar-date{
  padding:5px 0 5px 8px
}

/* -----------------
  모바일 가격
----------------- */
#product .modal2 .price table{
  width:100%
}
/* 인원수조정 */
#product .modal2 .price .count{
  text-align: center;
  padding: 0 10px;
}
#product .modal2 .price .count .btn-group{
  border:1px solid #000;
  border-radius: 0;
}
#product .modal2 .price .count .number{
  border-right:1px solid #000;
  border-left:1px solid #000;
  width: 3.5rem;
  font-weight:600;
  padding:11px 0
}
#product .modal2 .price .count .left,
#product .modal2 .price .count .right{
  font-size:1.3rem
}
#product .modal2 .price .count .btn-check:focus+.btn,
#product .modal2 .price .count .btn:focus{
  border-radius: 0;
}

/* 총금액 */
#product .modal2 .total-P table{
  margin-left:auto; 
  margin-right:auto;
}
#product .modal2 .total .result{
  padding-left:10px;
  font-size:2.3rem;
  font-weight: 600;
  color:#000
}

/* 예약하기 버튼 */
#product .modal2 .pr-go{
  margin-top:1rem;
  text-align: center;
}
#product .modal2 .pr-go p{
  color:#fff;
  font-size:1.3rem;
  font-weight: 600;
  letter-spacing: 5px;
  background-color: #676666;
  padding:15px 0;
  border-radius: 12px;
  margin-bottom:3rem
}
#product .modal2 .pr-go p:hover{
  color:gold;
  background-color: #000;
}



/* 열었을 때 */
#product .mobile-cal .open{
  top: 10%;
  z-index: 1000;
  box-shadow: 0 -5px 20px rgba(0,0,0,0.2);
}

/* pc 화면 안 보임 */
@media (min-width:992px){
  #product .mobile-cal{
    display: none;
  }
}



/* -------------------------------------------------
  # 캘린더에 필요한 것!
--------------------------------------------------- */
.calendar {
  display: inline-block;
  border: 0px solid #ccc;
  font-family: sans-serif;
  width: 90%
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0 20px ; 
  /*background-color: #eee;*/
  border-bottom: 2px solid #ccc;
}

.calendar-header-date {
  font-size: 2rem;
  font-weight: bold;
}

.calendar-body {
  display: flex;
  flex-direction: column;
}

.calendar-row {
  display: flex;
  margin:5px 0;
  cursor: pointer;
}

.calendar-header-row {
  font-weight: bold;
}

.calendar-day-header {
  width: calc(100% / 7);
  text-align: center;
  padding: 5px;
}

.calendar-day {
  width: calc(100% / 7);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px; 
  height:auto
  /* day border
  border-right: 0px solid #ccc;
  border-bottom: 0px solid #ccc;
  */
}

.calendar-day.today {
  background-color: transparent;
  /*position: relative;*/
}
.calendar-day.today .calendar-date{
  border:3px solid #f00;
  border-radius: 50%;
  width:40px;
  height:40px;
}

.calendar-day.today .calendar-date {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.calendar-day.today::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
}

.calendar-day:last-child {
  border-right: none;
}

.calendar-day.selected {
  background-color: #ccc;
}

.calendar-date {
  font-size: 1.3rem;
  font-weight: normal;
  color:#666;
}

.calendar-price {
  margin-top: 3px;
  font-size: 0.8rem;
  font-weight: bold;
  color:darkslategray
}

.calendarbtn {
  border: 0px;
  background-color: transparent;
}


.assoLogo {
  max-height: 60px;
  margin-top: 0rem;
}
</style>


<style scoped>
.pr-right {
  position: sticky;
  top: 0;
}
</style>
