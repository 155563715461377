<template>
  <main class="mt-3">
  <div class="pl-1 text-center">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center">
          <u>&nbsp;알림톡 일괄발송&nbsp;</u>
        </h2>

        <div class="float-end mb-1">
          <button type="button" class="btn btn-success" @click="goToInsert">템플릿등록</button>
        </div>        

          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr style="text-align: center">
                <th class="table-secondary col-2">
                  발송분류
                </th>
                <td class="col-4">
                  <div class="input-group">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="this.reqStatus">
                    <!-- <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="this.reqStatus" @change="changeProcCode(this.reqStatus)"> -->
                      <!-- <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option> -->
                      <option value="CX">취소</option>
                      <option value="ET" class="text-danger">여행후</option>
                      <option value="EC" class="text-danger">일반</option>
                    </select>
                  </div>
                </td>                
                <th class="table-secondary col-2">발신대상리스트</th>
                <td class="col-4 text-primary">
                  <b>
                    <input type="file" class="btn btn-outline-secondary btn-sm" ref="searchExcel" @change="handleFileUpload" :disabled="this.reqStatus == ''">
                  </b>
                </td>
              </tr>
              <tr style="text-align: center" v-if="this.reqStatus === 'ET' || this.reqStatus === 'EC'">
                <th class="table-secondary col-md-2">알림톡 템플릿 리스트</th>
                <td class="col-md-10" colspan="3">
                  <div class="input-group">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="pCode" @change="choicePattern(pCode)">
                      <option value="noVal">선택없음</option>
                      <option :value="pl.patternCode" :key=i v-for="(pl,i) in patternList">{{pl.pTitle}}</option>
                    </select>
                  </div>
                </td>
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary col-md-2" rowspan="3">전송내용</th>
                <td class="col-md-10 text-start" colspan="3" v-if="this.reqStatus === 'ET'">
                  최고의 1일, 엔데이트립!
                  <br>
                  OOO님, 여행은 즐거우셨나요^^
                  <br>
                  저희 엔데이트립 버스투어를 이용해 주셔서 감사드립니다!
                  <br>
                  <br>
                  투어코스 : OOOOO (미입력 시, 공란)
                  <br>
                  여행일자 : O월 O일 (미입력 시, 공란)
                </td>
                <td class="col-md-10 text-start" colspan="3" v-else-if="this.reqStatus === 'EC'">
                    OOO님,
                    <br>
                    저희 엔데이트립 버스투어를 이용해 주셔서 감사드립니다!
                </td>

              </tr>
              <tr style="text-align: center">
                <td class="col-md-10" colspan="3" v-if="this.reqStatus === 'ET' || this.reqStatus === 'EC'">
                  <textarea class="form-control" placeholder="700자 이내" ref="notice" id="floatingTextarea" style="height:500px" v-model="this.notice" maxlength="700"></textarea>
                </td>
                <td class="col-md-10" colspan="3" v-else>
                  <textarea class="form-control" placeholder="2,000자 이내" ref="notice" id="floatingTextarea" style="height:500px" v-model="this.notice" maxlength="2000"></textarea>
                </td>
              </tr>   
              <tr style="text-align: center">
                <td class="col-md-10 text-start" colspan="3" v-if="this.reqStatus === 'EC'">
                  투어코스 : OOOOO (미입력 시, 공란)
                  <br>
                  여행일자 : O월 O일 (미입력 시, 공란)
                </td>
              </tr>

              <tr style="text-align: center" v-if="this.reqStatus === 'ET' || this.reqStatus === 'EC'">
                <th class="table-secondary col-md-2">
                  전송예약
                  <span style="font-size:small;">(매일 10분간격)</span>
                </th>
                <td colspan="3" >
                  <div class="input-group mb-1">
                    <input type="type" class="form-control form-control-sm" ref="sendTitle" v-model="this.skdTitle" style="font-size:smaller; width: 60%;" placeholder="예약발송제목(50자이내)">
                    <input type="date" class="form-control form-control-sm" ref="sendSKD" v-model="this.sendSKD" style="font-size:small;width: 15%;">
                    <select id="startTime" v-model="sendTime" class="form-select text-center" style="width:15%;" ref="sendTime">
                      <option v-for="time in times" :key="time" :value="time">{{ time }}</option>
                    </select>
                    <button type="button" class="btn btn-primary" style="width:10%;" @click="sendMsgSKD">일정등록</button>
                  </div>
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary" colspan="4">
                  <button class="btn btn-dark btn-lg" @click="sendMsg">알림톡발송</button>
                  <!-- <button  type="button" class="btn btn-danger btn-lg" style="margin-right: 7px;" @click="sendMsgSKD()">알림톡예약발송</button> -->
                </th>
              </tr>              
            </tbody>
          </table>
      </div>
    </div>
  </div>
  </main>
</template>

<script>
import moment from 'moment';
import * as XLSX from 'xlsx'
import {genCode} from '../assets/js/genCode'

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      rProc: [],                    //예약상태
      reqStatus: "",                //선택예약상태
      cxlFlagList: [],              //취소사유리스트
      cxlName: "",                  //취소사유
      notice: "",                   //전송내용
      excelUpData: [],              //엑셀데이타

      patternList: [],              //템플릿리스트
      mmCode: "",                   //대량문자발송코드
      skdTitle: "",                 //예약발송제목
      sendSKD: "",                  //발송일정
      currentDate: "",              //today
      skdFlag: "N",                 //예약발송여부
      sendTime: "",                 //발송예약시간
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    console.log("USER:",this.user);
    this.getReqProcLevel();
    this.getPatternList();            //템플릿리스트    
  },
  mounted() {
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")

    this.generateTimeOptions();
  },
  methods: {

    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      //return this.$currencyFormat(value);
      if(isNaN(value)) 
      { 
        location.reload()
      }
      else
      {
        return this.$currencyFormat(value);
      }      
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },    
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
        
    // //상태변화체크
    // changeProcCode(rProcCode)
    // {
    //   alert(rProcCode)
    // },

    generateTimeOptions() {
      const times = [];
      const startTime = new Date();
      startTime.setHours(0);
      startTime.setMinutes(0);

      // 시간 옵션을 10분 간격으로 생성
      for (let i = 0; i < 144; i++) { // 24시간 * 6 (10분 간격)
        times.push(this.formatTime(startTime));
        startTime.setMinutes(startTime.getMinutes() + 10);
      }

      this.times = times;
    },
    formatTime(time) {
      const hours = time.getHours().toString().padStart(2, '0');
      const minutes = time.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },

    //템플릿리스트
    async getPatternList() {
      let patternList = await this.$api("/api/patterLsit_new", {param: [ "A", "Y"]});
      if(patternList.length > 0)
      {
        this.patternList = patternList
      }
      console.log("patternList:",this.patternList);
    },

    choicePattern(pCode){
      if(pCode === "noVal")
      {
        this.notice = ""
      }
      else
      {
        let choicedPattern = this.patternList.filter(item => {
          return item.patternCode === pCode
        })
        this.notice = choicedPattern[0].pContent
      }
    },

    async getReqProcLevel() {
      //예약진행상태
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      // console.log("rProc:",this.rProc);
    },

    //알림톡내용템플릿생성
    goToInsert() {
      this.$router.push({path: '/patternCreate'});
    },

    //mass message code
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) 
      {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_resCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","코드생성횟수 한계에 도달하였습니다.<br>담당자에게 문의하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },

    handleFileUpload(event) {
      const file = event.target.files[0];

      if(!file) return

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        this.processExcel(workbook, this.reqStatus);
      };

      reader.readAsBinaryString(file);
    },

    async processExcel(workbook, st) {
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      //const jsonData = XLSX.utils.sheet_to_json(worksheet);

      let startLine = 0
      // 날짜 형식 옵션을 사용하여 JSON 변환
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        raw: false, // 날짜 데이터를 숫자가 아닌 텍스트로 변환
        dateNF: 'yyyy-mm-dd HH:MM:SS', // 날짜 형식 지정
        range: startLine
      });
      //console.log("jsonData:",jsonData);

      if(jsonData.length > 2001)
      {
        this.$swal.fire("","일괄입력데이타의 최대값은 2000건입니다.", "warning")
        return false
      }
      else
      {
        this.$swal.fire("","데이타의 양에 따라 시간이 소요될 수 있습니다.<br>종료안내까지 대기해주세요.", "info")
      }

      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();

      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      let timestamp = "";
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;
      
      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      hour = (hour > 9) ? hour : "0" + hour;
      min = (min > 9) ? min : "0" + min;
      sec = (sec > 9) ? sec : "0" + sec;
      let todayDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
      let ymd = `${yyyy}${mm}${dd}`;
      
      this.currentDate = yyyy+"-"+mm+"-"+dd

      timestamp = today.getTime();
      
      let resCode = "BS-"+ymd+"-"+timestamp;          //일괄발송번호(BulkSendNo)

      let mCode = "MSG-"
      let mmCode = await this.genCode(mCode)
      this.mmCode = mmCode                       //일괄전송구분코드
      console.log("resCode:", resCode, "mmCode:", mmCode)

      if(st === undefined)
      {
        this.$swal.fire("", "발송분류를 선택해주세요", "warning")
        return false
      }
      else if(st === "ET" || st === "EC")        //여행 후 또는 일반내용
      {
        let res = ""
        let i=0

        while(i < jsonData.length) 
        {
          this.excelUpData.nStat = this.reqStatus
          this.excelUpData.resCode = resCode+"-"+i                  //알림톡 시스템용
          this.excelUpData.clientName = jsonData[i].clientName
          if(jsonData[i].tourCourse == undefined)
          {
            this.excelUpData.tourCourse = ""
          }
          else
          {
            this.excelUpData.tourCourse = jsonData[i].tourCourse
          }

          if(jsonData[i].tMonth == undefined || jsonData[i].tDay == undefined)
          {
            this.excelUpData.tripDate = ""   
          }
          else
          {
            this.excelUpData.tripDate = jsonData[i].tMonth+"월 "+jsonData[i].tDay+"일" 
          }

          if(jsonData[i].alimCall.includes('-'))
          {
            this.excelUpData.alimCall = jsonData[i].alimCall.replace(/-/g, '')
          }
          else
          {
            this.excelUpData.alimCall = jsonData[i].alimCall
          }
          this.excelUpData.insId = this.user.handlerId;
          this.excelUpData.insDate = todayDate;

          this.excelUpData.mmCode = this.mmCode;

          let bUseFlag = "Y"

          //발송기록
          let insBulkNoticeCall = await this.$api("/api/insBulkNoticeCall_etc", {param: [
            this.excelUpData.nStat, this.excelUpData.clientName, this.excelUpData.tourCourse, this.excelUpData.tripDate, this.excelUpData.alimCall, this.excelUpData.insId, this.excelUpData.mmCode, bUseFlag, this.excelUpData.resCode
          ]});

          if(insBulkNoticeCall.affectedRows > 0)
          {
            res = "OK"
          }
          else
          {
            res = "Error"
            break
          }
          i++
        }
        if(res === "error")
        {
          this.$swal.fire('', '처리 중 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', jsonData.length+'건이 발송가능합니다', 'info');
        }
      }      
      else
      {
        let res = ""
        let i=0

        while(i < jsonData.length) 
        {
          this.excelUpData.nStat = this.reqStatus
          this.excelUpData.clientName = jsonData[i].clientName
          //this.excelUpData.resCode = resCode + i
          this.excelUpData.resCode = jsonData[i].resCode
          //this.excelUpData.resDate = this.getDateFormat(jsonData[i].resDate)
          this.excelUpData.resDate = null
          this.excelUpData.dptDate = this.getDateFormat(jsonData[i].dptDate)
          this.excelUpData.cxlDate = this.getDateFormat(jsonData[i].cxlDate)
          //this.excelUpData.cxlRemark = this.notice
          if(jsonData[i].alimCall.includes('-'))
          {
            this.excelUpData.alimCall = jsonData[i].alimCall.replace(/-/g, '')
          }
          else
          {
            this.excelUpData.alimCall = jsonData[i].alimCall
          }
          this.excelUpData.pdtCode = jsonData[i].pdtCode

          this.excelUpData.insId = this.user.handlerId;
          this.excelUpData.insDate = todayDate;

          this.excelUpData.mmCode = this.mmCode;

          let bUseFlag = "Y"

          //발송기록
          let insBulkNoticeCall = await this.$api("/api/insBulkNoticeCall", {param: [
            this.excelUpData.nStat, this.excelUpData.clientName, this.excelUpData.resCode, this.excelUpData.resDate, this.excelUpData.dptDate, this.excelUpData.cxlDate, this.excelUpData.alimCall, this.excelUpData.pdtCode, this.excelUpData.insId, this.excelUpData.mmCode, bUseFlag
          ]});

          if(insBulkNoticeCall.affectedRows > 0)
          {
            res = "OK"
          }
          else
          {
            res = "Error"
            break
          }
          i++
        }
        if(res === "error")
        {
          this.$swal.fire('', '처리 중 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', jsonData.length+'건이 발송가능합니다', 'info');
        }
      }
    },

    async sendMsg(){
      if(this.mmCode == undefined || this.mmCode == "")
      {
        this.$swal.fire("", "발송에 필요한 정보가 부족합니다", "warning")
        return false;        
      }
      else if(this.skdFlag === "Y")
      {
        this.$swal.fire("", "발송예약된 메시지입니다.", "info")
        return false;
      }


      let bUseFlag = "Y"
      let infoForSendingMSG = await this.$api("/api/getInfoSendingMSG", {param: [ this.mmCode, bUseFlag]})
      //console.log("infoForSendingMSG:", infoForSendingMSG)
      
      for(let i=0; i<infoForSendingMSG.length; i++)
      {
        console.log("sending",[i],":", infoForSendingMSG[i])
        this.sendKakao(infoForSendingMSG[i], this.reqStatus)
      }
    },

    //예약발송
    async sendMsgSKD(){
      // alert("cDate:" + this.currentDate)
      if(!this.mmCode)
      {
        this.$swal.fire("","예약진행을 위한 필수정보가 부족합니다. 재등록을 진행해주세요.", "warning")
        //location.reload(true)
        return false
      }
      else if(!this.notice)
      {
        this.$refs.notice.focus()
        this.$swal.fire("","전송내용을 입력해주세요.", "warning")
        return false
      }
      else if(this.sendSKD <= this.currentDate)
      {
        this.$swal.fire("","예약일은 최소 내일부터 가능합니다.", "warning")
        return false
      }
      
      let sUseFlag = "Y"
      let sendFlag = "N"
      let insBulkNoticeCallSKD_etc = await this.$api("/api/insBulkNoticeCallSKD_etc", {param: [ 
        this.mmCode, this.skdTitle, sUseFlag, this.sendSKD, sendFlag, this.user.handlerId, this.notice, this.sendTime
      ]})

      if(insBulkNoticeCallSKD_etc.affectedRows < 0)
      {
        this.$swal.fire("","발송스케쥴 등록 중 오류가 발생했습니다. 재시도 해주세요", "warning")
        return false;
      }
      else
      {
        this.skdFlag = "Y"
        this.$swal.fire("","발송스케쥴이 등록되었습니다.", "success").then(() => {
          location.href="/bulkNoticeCallSKD_list"
        })
        //location.reload(true)
      }
      //console.log("sendTitle:", this.sendTitle, "sendSKD:", this.sendSKD)
    },

    // showData(params){
    //   console.log("params:", params)
    // },

    /** 카카오알림 발송 */
    async sendKakao(param, reqStatus)   //예약상태, 회원여부
    {
      if(reqStatus == "CX") 
      {
        //카카오 알림톡 발송(취소)
        let kakaoAlimData = 
        {
          clientName: param.clientName,
          resCode: param.resCode,
          resDate: this.getDateFormat(param.resDate),
          dptDate: this.getDateFormat(param.dptDate),
          cxlDate: this.getDateFormat(param.cxlDate),
          cxlRemark: this.notice,                          //전송문구
          alimCall: param.alimCall,
          clientCall: param.alimCall,                      //카톡발송연락처
          pdtCode: param.pdtCode,                          //비회원예약 결제처리용 상품코드            
          alimFlag: reqStatus,
        };
        this.$api("api/kakaoAlimSend2", {
          param: [
            { kakaoAlimData: kakaoAlimData},
          ]
        }).then((res) => {console.log(res)});
        this.$swal.fire('', '취소안내 알림톡이 발송되었습니다', 'success');
        //location.reload(true)
      }

      else if(reqStatus === "ET" || reqStatus === "EC")      //여행 후 또는 일반안내
      {
        //카카오 알림톡 발송(기타)
        let kakaoAlimData = 
        {
          clientName: param.clientName,
          resCode: param.resCode,                          //알림톡용
          tourCourse: param.tourCourse,
          tripDate: param.tripDate,
          msg: this.notice,                                //전송문구
          alimCall: param.alimCall,
          clientCall: param.alimCall,                      //카톡발송연락처
          alimFlag: reqStatus,
        };
        this.$api("api/kakaoAlimSend2", {
          param: [
            { kakaoAlimData: kakaoAlimData},
          ]
        }).then((res) => {console.log(res)});
        this.$swal.fire('', '알림톡이 발송되었습니다', 'success');
        //location.reload(true)
      }


/*
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.reqRsvCode, param, this.user.handlerId
      ]});
      if(insKSendHistory.affectedRows > 0) {
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm_tmp = today.getMonth()+1;
        let dd_tmp = today.getDate();
        
        let mm = "";
        if(mm_tmp < 10) {mm = "0" + mm_tmp}
        else mm = mm_tmp;
        let dd = "";
        if(dd_tmp < 10) {dd = "0" + dd_tmp}
        else dd = dd_tmp;

        let curDate = yyyy + "-" + mm + "-" + dd

        if(param == "RP") 
        {
          //결제요청 카카오 알림톡
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제요청)
            clientName: this.reserveInfo.reqName,
            resName: this.productInfo.pdtNameKor,
            resPaxName: this.reserveInfo.reqName,
            resPaxCnt: this.personCntK,
            dptDate: this.reserveInfo.reqDptDate,
            resPhone: this.reserveInfo.reqTelNo,
            resCode: this.reqRsvCode,
            resPayAmount: this.getCurrencyFormat(this.cstPrice),
            resManager: this.productInfo.manName,
            alimFlag: param                                         //알림유형(결제요청)
          };
          this.$api("api/kakaoAlimSend2", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '결제요청 알림톡이 발송되었습니다', 'success');
          location.reload(true)
        }
        else if(param == "nonRP") 
        {
          //비회원용 결제요청 카카오 알림톡
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제요청)
            clientName: this.reserveInfo.reqName,
            resName: this.productInfo.pdtNameKor,
            resPaxName: this.reserveInfo.reqName,
            resPaxCnt: this.personCntK,
            dptDate: this.reserveInfo.reqDptDate,
            resPhone: this.reserveInfo.reqTelNo,
            resCode: this.reqRsvCode,
            resPayAmount: this.getCurrencyFormat(this.cstPrice),
            resManager: this.productInfo.manName,
            alimFlag: param,                                        //알림유형(결제요청)
            pdtCode: this.productInfo.pdtCode                       //비회원예약 결제처리용 상품코드
          };
          this.$api("api/kakaoAlimSend2", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '결제요청 알림톡이 발송되었습니다', 'success');
          location.reload(true)
        }
        else if(param == "CO" || param == "nonCO") 
        {
          //카카오 알림톡 발송(확정)
          let kakaoAlimData = 
          {
            clientName: this.reserveInfo.reqName,
            resCode: this.reqRsvCode,
            resName: this.productInfo.pdtNameKor,
            resPrice: this.getCurrencyFormat(this.cstPrice),
            alimCall: this.reserveInfo.reqTelNo,
            alimFlag: param,
            pdtCode: this.productInfo.pdtCode                     //비회원예약 결제처리용 상품코드
          };
          this.$api("api/kakaoAlimSend", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '예약확정 알림톡이 발송되었습니다', 'success');
          location.reload(true)
        } 
        else if(param == "CX" || param == "nonCX") 
        {
          //카카오 알림톡 발송(취소)
          let kakaoAlimData = 
          {
            clientName: this.reserveInfo.reqName,
            resCode: this.reqRsvCode,
            resDate: this.getDateFormat(this.reserveInfo.insDate),
            dptDate: this.reserveInfo.reqDptDate,
            cxlDate: curDate,
            cxlRemark: this.cxlName,                                    //취소사유 
            alimCall: this.reserveInfo.reqTelNo,
            clientCall: this.reserveInfo.reqTelNo,                      //카톡발송연락처
            pdtCode: this.productInfo.pdtCode,                          //비회원예약 결제처리용 상품코드            
            alimFlag: param,
          };
          this.$api("api/kakaoAlimSend2", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '예약취소 알림톡이 발송되었습니다', 'success');
          location.reload(true)
        }        
      }
 */     
    },        
  }
}
</script>