<template>
  <main class="mt-3">
  <div class="p-1 text-center">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center"><u>&nbsp;멤 버 쉽 &nbsp; 환 불 율&nbsp;</u></h2>
          <div class="text-success text-start fs-6 mb-2">
            <b>
              [ 일괄수정&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('U','B');">&nbsp;UPDATE</i> ] &nbsp;
              <i class="bi bi-exclamation-triangle-fill text-primary"></i>일별설정률을 일괄수정합니다
              </b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto">
            <tbody>
              <tr>
                <th scope="row" class="table-secondary col-md-1">기간지정</th>
                <td class="col-md-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">개시</span>
                    <input type="date" class="form-control" ref="txt_batchPriceStart" id="bpStart" style="font-size: small" v-model="batchPriceStart" @click="setStart()">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">종료</span>
                    <input type="date" class="form-control" ref="txt_batchPriceEnd" id="bpEnd" style="font-size: small" v-model="batchPriceEnd" @click="setEnd()">
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-1">
                  <input class="form-check-input" type="checkbox" v-model="dOw_Flag" true-value="Y" false-value="N">&nbsp;요일
                </th>
                <td class="col-md-5">
                  <div class="input-group">
                    <span class="input-group-text text-white bg-danger" style="font-size: small">
                      <b>제외</b>&nbsp;<input class="form-check-input" type="checkbox" v-model="prUseFlagNot" true-value="Y" false-value="N">
                    </span>
                    &nbsp;&nbsp;
                    <span class="input-group-text text-danger" style="font-size: small">일&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[0]" true-value="0" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">월&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[1]" true-value="1" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">화&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[2]" true-value="2" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">수&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[3]" true-value="3" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">목&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[4]" true-value="4" false-value="N"></span>
                    <span class="input-group-text" style="font-size: small">금&nbsp;<input class="form-check-input" type="checkbox" v-model="dayOfWeek[5]" true-value="5" false-value="N"></span>
                    <span class="input-group-text text-primary" style="font-size: small">토<input class="form-check-input" type="checkbox" v-model="dayOfWeek[6]" true-value="6" false-value="N"></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">
                  <input class="form-check-input" type="checkbox" v-model="price_Flag" true-value="Y" false-value="N">&nbsp;
                  변경환불율
                </th>
                <td colspan="3">
                  <div class="input-group" v-if="this.sRoute === 'S'">
                    <span class="input-group-text">1</span>
                    <input type="number" class="form-control-sm text-end" min="0" ref="txt_pdtPrice1" v-model="batchPrice[0]">
                  </div>
                  <div class="input-group" v-else>
                    <!-- Premium Membership -->
                    <span class="input-group-text form-control-sm" style="font-size:smaller; background-color:goldenrod">1</span>
                    <input type="number" class="form-control-sm text-end" min="0" max="100" ref="txt_pdtPrice1" v-model="batchPrice[0]" style="font-size: smaller; width:100px">
                    <span class="input-group-text" style="font-size:smaller; background-color:goldenrod">2</span>
                    <input type="number" class="form-control-sm text-end" min="0" max="100" ref="txt_pdtPrice2" v-model="batchPrice[1]" style="font-size: smaller; width:100px">
                    <span class="input-group-text" style="font-size:smaller; background-color:goldenrod">3</span>
                    <input type="number" class="form-control-sm text-end" min="0" max="100" ref="txt_pdtPrice3" v-model="batchPrice[2]" style="font-size: smaller; width:100px">
                    <span class="input-group-text" style="font-size:smaller; background-color:goldenrod">4</span>
                    <input type="number" class="form-control-sm text-end" min="0" max="100" ref="txt_pdtPrice4" v-model="batchPrice[3]" style="font-size: smaller; width:100px">

                    &nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-grip-vertical text-danger" style="font-size:x-large; font-weight:bold; vertical-align:middle"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                    
                    <!-- Standard Membership -->
                    <span class="input-group-text form-control-sm" style="font-size:smaller">1</span>
                    <input type="number" class="form-control-sm text-end" min="0" max="100" ref="txt_pdtPrice_S1" v-model="batchPrice_S[0]" style="font-size: smaller; width:100px">
                    <span class="input-group-text" style="font-size:smaller">2</span>
                    <input type="number" class="form-control-sm text-end" min="0" max="100" ref="txt_pdtPrice_S2" v-model="batchPrice_S[1]" style="font-size: smaller; width:100px">
                    <span class="input-group-text" style="font-size:smaller">3</span>
                    <input type="number" class="form-control-sm text-end" min="0" max="100" ref="txt_pdtPrice_S3" v-model="batchPrice_S[2]" style="font-size: smaller; width:100px">
                    <span class="input-group-text" style="font-size:smaller">4</span>
                    <input type="number" class="form-control-sm text-end" min="0" max="100" ref="txt_pdtPrice_S4" v-model="batchPrice_S[3]" style="font-size: smaller; width:100px">                    
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2">
            <b>
              [ 일별설정 ]
              <div class="float-end mb-1">
                <button type="button" class="btn btn-sm btn-outline-warning" @click="updPriceSetflag('S')">선택일자 입력환불율적용</button>&nbsp;&nbsp;
                <button type="button" class="btn btn-sm btn-outline-primary" @click="updPriceSetflag('C')">선택일자 변경환불율적용</button>&nbsp;&nbsp;
                <button type="button" class="btn btn-sm btn-outline-success" @click="updPriceSetflag('A')">선택일자 환불개시</button>&nbsp;&nbsp; 
                <button type="button" class="btn btn-sm btn-outline-danger" @click="updPriceSetflag('D')">선택일자 환불중지</button>
              </div>              
            </b>
          </div>
        <table class="table table-bordered table-striped fs-6 w-100 h-auto">
          <thead class = "small">
            <tr>
              <th style="width:5%">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th>
              <th style="width:10%">적용일자</th>
              <th style="width:5%">요일</th>
              <th style="width:60%">프리미엄/스탠다드 멤버십 권종별 환불율 (1:성인 / 2:소인 / 3:유아 / 4:기타->비사용)</th>
              <th style="width:5%">제외</th>
              <th style="width:5%"></th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(prDate, i) in this.productPriceDetailCnt">
              <td>
                <input type="checkbox" v-model="prDate.checkFlag"  ref="chb_Seq" class="align-baseline mt-3" :id=i name="checkedBox">
              </td>
              <td class="text-success align-middle" v-if="getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)==='토'">
                <small class="text-primary">{{this.productPriceDetailCnt[i].prDate}}</small>
              </td>
              <td class="text-success align-middle" v-else-if="getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)==='일'">
                <small class="text-danger">{{this.productPriceDetailCnt[i].prDate}}</small>
              </td>
              <td clasas="text-success align-middle" v-else>
                <small>{{this.productPriceDetailCnt[i].prDate}}</small>
              </td>

              <td class="text-success align-middle" v-if="getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)==='토'">
                <small class="text-primary">{{getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)}}</small>
              </td>
              <td class="text-success align-middle" v-else-if="getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)==='일'">
                <small class="text-danger">{{getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)}}</small>
              </td>
              <td class="text-success align-middle" v-else>
                <small>{{getDayOfWeekFormat(this.productPriceDetailCnt[i].prDate)}}</small>
              </td>

              <td>
                <div class="input-group" v-if="this.sRoute === 'S'">
                  <span class="input-group-text">1</span>
                  <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice1" v-model="this.arr_pdtPrice1[i]">
                  <!-- <span class="input-group-text">원</span> -->
                </div>
                <div class="input-group" v-else>
                  <span class="input-group-text" style="font-size:smaller; background-color:goldenrod">1</span>
                  <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice1" v-model="this.arr_pdtPrice1[i]" style="font-size: smaller; width:70px">
                  <span class="input-group-text" style="font-size:smaller; background-color:goldenrod">2</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice2" v-model="this.arr_pdtPrice2[i]" style="font-size: smaller; width:70px">
                  <span class="input-group-text" style="font-size:smaller; background-color:goldenrod">3</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice3" v-model="this.arr_pdtPrice3[i]" style="font-size: smaller; width:70px">
                  <span class="input-group-text" style="font-size:smaller; background-color:goldenrod">4</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice4" v-model="this.arr_pdtPrice4[i]" style="font-size: smaller; width:70px">
                  
                  &nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-grip-vertical text-danger" style="font-size:large; font-weight:bold; vertical-align:middle"></i>&nbsp;&nbsp;&nbsp;&nbsp;

                  <span class="input-group-text" style="font-size:smaller">1</span>
                  <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice1" v-model="this.arr_pdtPrice1_S[i]" style="font-size: smaller; width:70px">
                  <span class="input-group-text" style="font-size:smaller">2</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice2" v-model="this.arr_pdtPrice2_S[i]" style="font-size: smaller; width:70px">
                  <span class="input-group-text" style="font-size:smaller">3</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice3" v-model="this.arr_pdtPrice3_S[i]" style="font-size: smaller; width:70px">
                  <span class="input-group-text" style="font-size:smaller">4</span>
                  <input type="number" class="form-control-sm text-sm text-end" ref="txt_pdtPrice4" v-model="this.arr_pdtPrice4_S[i]" style="font-size: smaller; width:70px">                  
                </div>
              </td>
              <td class="align-middle">
                <input class="form-check-input" type="checkbox" v-model="this.priceUseFlag[i]" true-value="1" false-value="0" id="pUseFlag">
              </td>
              <td class="align-middle">
                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="updatePdtPrice(this.productId, this.productPriceDetailCnt[i].prDate, this.priceUseFlag[i], this.arr_pdtPrice1[i], this.arr_pdtPrice2[i], this.arr_pdtPrice3[i], this.arr_pdtPrice4[i], this.arr_pdtPrice1_S[i], this.arr_pdtPrice2_S[i], this.arr_pdtPrice3_S[i], this.arr_pdtPrice4_S[i])">
                  <small><small>변경</small></small>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-3 row">
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
      </div> -->
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToBack()">돌아가기</button>
      </div>
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
      </div> -->
    </div>
  </div>
  
  </main>
</template>

<script>
export default {
  data() {
    return {
      dayOfWeek: ["N","N","N","N","N","N","N"],   //요일정보
      numberOfWeek: [],                           //요일숫자정보
      productInfo: {},                            //상품정보
      productPrice: {},                           //권종가격
      productPriceDetail: {},                     //기간별 적용가격
      productPriceDetailCnt: {},                  //기간별 적용가격 카운트
      dInterval: 0, //날짜수
      arr_nextDay: [],
      arr_dayOfWeek: [],
      arr_numberOfWeek: [],
      //arr_pdtPrice: [],
      arr_pdtPrice1: [],    //프리미엄성인
      arr_pdtPrice2: [],
      arr_pdtPrice3: [],
      arr_pdtPrice4: [],
      arr_pdtPrice1_S: [],    //스탠다드성인
      arr_pdtPrice2_S: [],
      arr_pdtPrice3_S: [],
      arr_pdtPrice4_S: [],      
      priceUseFlag: [],         //금액사용여부
      pdtPrice_d: [],           //특수가격(상품별)

      batchPriceStart: "",      //일괄변경시작
      batchPriceEnd: "",
      batchPrice: [0,0,0,0],            //프리미엄환불율
      batchPrice_S: [0,0,0,0],          //스탠다드환불율
      arr_batchPrice: [],               //PRE일괄변경환불율
      arr_batchPrice_S: [],             //STD일괄변경환불율

      dOw_Flag: "N",          //요일선택여부
      price_Flag: "N",        //환불율변동선택여부
      prUseFlagNot: "N",      //일괄제외선택

      dpDate: "",             //적용시작일
      rtDateFin: "",          //적용종료일
      firstDay: "",           //적용첫날
      allSelected: "N"        //전체체크여부
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.productId = this.$route.query.productId;
    this.sRoute = this.$route.query.sRoute;

    this.getProductInfo();
    this.getProductPrice();
    this.getProdctPriceDetail();
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    //상세정보와 무관한 내용 비표시처리
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: 
  {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDayOfWeekFormat(text) {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text) {
      return this.$numberOfWeekFormat(text);
    },
    allChoice() 
    {
      if(this.allSelected)
      {
        this.productPriceDetailCnt.forEach((prDate) => {
          prDate.checkFlag = true;
        })
      }
      else
      {
        this.productPriceDetailCnt.forEach((prDate) => {
          prDate.checkFlag = false;
        })
      }
    },
    //선택일자 상태변경
    async updPriceSetflag(flag)
    {
      let selectedDate = [];
      this.productPriceDetailCnt.forEach((prDate) => {
        if(prDate.checkFlag)
        {
          selectedDate.push(prDate.prDate)
        }
      })
      console.log("selectedCount:",selectedDate.length, ",selectedDate:",selectedDate)

      //체크박스의 아이디
      let checkBox = document.getElementsByName('checkedBox')
      //체크된 박스의 아이디들
      let selectedDatePrice = []
      let selectedDatePrice_S = []

      for (var i = 0; i < checkBox.length; i++) 
      {
        //let checkBoxes = checkBox[i];
        if (checkBox[i].checked)
        //if(checkBoxes.id.endsWith('i') && checkBoxes.checked)
        {
          //checkedIds.push(checkBox[i].id);
          selectedDatePrice.push([
            this.productPriceDetailCnt[checkBox[i].id].prDate, this.arr_pdtPrice1[checkBox[i].id], this.arr_pdtPrice2[checkBox[i].id], this.arr_pdtPrice3[checkBox[i].id], this.arr_pdtPrice4[checkBox[i].id], this.priceUseFlag[checkBox[i].id]
          ])

          selectedDatePrice_S.push([
            this.productPriceDetailCnt[checkBox[i].id].prDate, this.arr_pdtPrice1_S[checkBox[i].id], this.arr_pdtPrice2_S[checkBox[i].id], this.arr_pdtPrice3_S[checkBox[i].id], this.arr_pdtPrice4_S[checkBox[i].id], this.priceUseFlag[checkBox[i].id]
          ])          
        }
      }
      //console.log(checkedIds);
      //console.log("selectedDatePrice:",selectedDatePrice,"selectedDatePrice_S:",selectedDatePrice_S);

      //선택일자 환불중지처리
      if(flag === "D")
      {
        this.$swal.fire({
        html: '선택한 날짜들의 환불적용을 중지합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let updPriceSellFlag = await this.$api("/api/updPriceSellFlag_mem", {param: [ '1',this.user.handlerId,this.productId,selectedDate[i]]});
              if(updPriceSellFlag.affectedRows < 0 || updPriceSellFlag.affectedRows === undefined)
              {
                res = "error"
                break;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","처리 중 에러가 발생했습니다.","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","선택일자가 환불중지되었습니다.","success");
              location.reload(true)
            }
          } 
        });
      }
      //환불개시처리
      else if(flag === "A")
      {
        this.$swal.fire({
        html: '선택한 날짜들의 환불을 개시합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let updPriceSellFlag = await this.$api("/api/updPriceSellFlag_mem", {param: [ '0',this.user.handlerId,this.productId,selectedDate[i]]});
              if(updPriceSellFlag.affectedRows < 0 || updPriceSellFlag.affectedRows === undefined)
              {
                res = "error"
                break;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","처리 중 에러가 발생했습니다.","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","선택일자의 환불이 개시되었습니다.","success");
              location.reload(true)
            }
          } 
        });
      }
      //판매가격변경처리
      else if(flag === "C")
      {
        let arr_batchPrice = [this.batchPrice[0],this.batchPrice[1],this.batchPrice[2],this.batchPrice[3]];
        let arr_batchPrice_S = [this.batchPrice_S[0],this.batchPrice_S[1],this.batchPrice_S[2],this.batchPrice_S[3]];

        this.$swal.fire({
        html: '선택한 날짜들의 멤버십환불율을 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let j = 0;
              //권종별 금액업데이트
              while(j < arr_batchPrice.length)
              {
                let updateCheckedPrice = await this.$api("/api/updateCheckedPrice_mem", {param: [ 
                  arr_batchPrice[j],arr_batchPrice_S[j],this.user.handlerId,this.productId,selectedDate[i], j+1
                ]});
                if(updateCheckedPrice.affectedRows < 0 || updateCheckedPrice.affectedRows === undefined)
                {
                  res = "error"
                  break;
                }
                j++;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","환불율 업데이트 중 에러가 발생했습니다(코드: updateCheckedPrice_mem)","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","선택일자의 멤버십환불율이 변경되었습니다","success");
              location.reload(true)
            }
          } 
        });
      }
      //선택일자 변경내용 일괄적용
      else if(flag === "S")
      {
        let arr_batchPrice = [this.batchPrice[0],this.batchPrice[1],this.batchPrice[2],this.batchPrice[3]];
        let arr_batchPrice_S = [this.batchPrice_S[0],this.batchPrice_S[1],this.batchPrice_S[2],this.batchPrice_S[3]];

        this.$swal.fire({
        html: '선택한 날짜들에 입력된 환불율을 적용합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let i = 0;
            let res = "";
            while(i < selectedDate.length)
            {
              let j = 0;
              //권종별 금액업데이트
              while(j < arr_batchPrice.length)
              {
                let updateCheckedPrice = await this.$api("/api/updateCheckedPrice_mem", {param: [ 
                  arr_batchPrice[j],arr_batchPrice_S[j],this.user.handlerId,this.productId,selectedDate[i], j+1
                ]});
                if(updateCheckedPrice.affectedRows < 0 || updateCheckedPrice.affectedRows === undefined)
                {
                  res = "error"
                  break;
                }
                j++;
              }
              i++;
            }
            if(res == "error")
            {
              this.$swal.fire("","환불율 업데이트 중 에러가 발생했습니다(코드: updateCheckedPrice_mem)","error");
              return false;          
            }
            else
            {
              this.$swal.fire("","입력된 멤버십환불율로 변경되었습니다","success");
              location.reload(true)
            }
          } 
        });
      }
    },    
    async getProductInfo() {
      this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"

      let productInfo = {};
      if(this.pdtFlag === "SG") {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      else if(this.pdtFlag === "MX") {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else {
        return this.$swal.fire("","상품이 검색되지 않습니다<br>재확인 후 시도해 주세요.","error");
      }
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      console.log("productInfo:",this.productInfo);
    },
    async getProductPrice()
    {
      let productPrice = await this.$api("/api/productPrice", {param: [this.productId]});
      this.productPrice = productPrice;
      //console.log("productPrice:",productPrice)

      //세팅된 날짜의 시작~끝일
      let minMaxDate = await this.$api("/api/getMinMaxDate_mem", {param: [this.productId]});
      //console.log("minMaxDate:",minMaxDate)

      if(minMaxDate.length > 0)
      {
        this.firstDay = this.getDateFormat(minMaxDate[0].startDate);
        this.rtDateFin = this.getDateFormat(minMaxDate[0].endDate);
      }
      console.log("firstDay:", this.firstDay, "rtDateFin:", this.rtDateFin)
    },

    //기간별 적용가격정보
    async getProdctPriceDetail() {
      //일자+요일별 환불률
      //let productPriceDetailCnt = await this.$api("/api/productPriceDetailCnt", {param: [this.productId]});
      let productPriceDetailCnt = await this.$api("/api/productMembershipDetailCnt", {param: [this.productId]});
      //let productPriceDetail = await this.$api("/api/productPriceDetail", {param: [this.productId]});
      let productPriceDetail = await this.$api("/api/productMembershipDC", {param: [this.productId]});
      this.productPriceDetailCnt = productPriceDetailCnt;
      this.productPriceDetail = productPriceDetail;

      let arr_pdtPrice1 = [];       //Premium
      let arr_pdtPrice2 = [];
      let arr_pdtPrice3 = [];
      let arr_pdtPrice4 = [];
      let arr_pdtPrice1_S = [];     //Standard
      let arr_pdtPrice2_S = [];
      let arr_pdtPrice3_S = [];
      let arr_pdtPrice4_S = [];      

      let arr_priceUseFlag = [];

      this.arr_pdtPrice1 = arr_pdtPrice1
      this.arr_pdtPrice2 = arr_pdtPrice2
      this.arr_pdtPrice3 = arr_pdtPrice3
      this.arr_pdtPrice4 = arr_pdtPrice4
      this.arr_pdtPrice1_S = arr_pdtPrice1_S
      this.arr_pdtPrice2_S = arr_pdtPrice2_S
      this.arr_pdtPrice3_S = arr_pdtPrice3_S
      this.arr_pdtPrice4_S = arr_pdtPrice4_S

      this.priceUseFlag = arr_priceUseFlag;
      
      if(this.productPriceDetailCnt.length < 1)
      {
        this.$swal.fire("","설정된 환불율이 없습니다","info");
        history.back();
      }
      else {
        let p = 0;
        while(p < this.productPriceDetailCnt.length) 
        {
          //console.log("P:",p,"/productPriceDetailCnt:",productPriceDetailCnt[p].prDate);
          let spPrice = this.productPriceDetail.filter(e => {return e.prDate === this.productPriceDetailCnt[p].prDate})
          //console.log("spPrice:",spPrice,"/example:", spPrice[1].pdtPrice_S);

          if(this.sRoute === "S") {
              arr_pdtPrice1.push(spPrice[0].pdtPrice);
              arr_priceUseFlag.push(spPrice[0].useFlag)
          }
          else 
          {
            arr_pdtPrice1.push(spPrice[0].pdtPrice);
            arr_pdtPrice2.push(spPrice[1].pdtPrice);
            arr_pdtPrice3.push(spPrice[2].pdtPrice);
            arr_pdtPrice4.push(spPrice[3].pdtPrice);

            arr_pdtPrice1_S.push(spPrice[0].pdtPrice_S);
            arr_pdtPrice2_S.push(spPrice[1].pdtPrice_S);
            arr_pdtPrice3_S.push(spPrice[2].pdtPrice_S);
            arr_pdtPrice4_S.push(spPrice[3].pdtPrice_S);

            arr_priceUseFlag.push(spPrice[0].useFlag);
          }
          p++;
        }
      }
      //console.log("arr_pdtPrice1:",arr_pdtPrice1);
    },

    async updatePdtPrice(pCode, pDate, pUse, p1, p2, p3, p4, p1_S, p2_S, p3_S, p4_S) {
      //console.log("pCode:",pCode, "pDate:",pDate, "pUse:",pUse, p1, p2, p3, p4, p1_S, p2_S, p3_S, p4_S);
      
      let dubCheck = [];
      let dubCheckFlag = "";
      dubCheck = this.productPriceDetail.filter(e => {return e.prDate === pDate});
      dubCheckFlag = (dubCheck.length > 0) ? "Y" : "N"
      
      let arr_p = [p1, p2, p3, p4]              //프리엄환급율
      let arr_p_S = [p1_S, p2_S, p3_S, p4_S]    //스탠다드환급율
      let prUse = (pUse != undefined) ? pUse : "0";
      let pDow = this.getNumberOfWeekFormat(pDate);

      //console.log("dubCheckFlag:",dubCheckFlag,"/pDow:",pDow);

      //기존 특수가격의 존재에 따라 ins / upd를 진행
      if(dubCheckFlag === "N") {
        let timeStamp = "";
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = today.getMonth()+1;
        let dd = today.getDate();
        let ymd = `${yyyy}${mm}${dd}`
        timeStamp = today.getTime(); //가격코드에 들어갈 타임스탬프

        let prcCode = "P"+this.productId.charAt(0)+"-"+ymd+timeStamp; //기본가격과는 다른 가격을 위한 고유코드
        //alert("prcCode:" + prcCode);

        let a = 0
        while(a < 4) {
          let resIns_pdtPrice_d = await this.$api("/api/priceMemDetailInsert", {param: [this.productId, prcCode, a+1, arr_p[a], arr_p_S[a], pDow, pDate, prUse, this.user.handlerId]});
          //console.log("errCheck:", Object.keys(resIns_pdtPrice_d)[0]);
          if(Object.keys(resIns_pdtPrice_d)[0] === "error") {
            this.$swal.fire("","저장오류","error");
            return false;
          }
          a++;
        }
      }
      else {
        let b = 0
        while(b < 4) {
          let resIns_pdtPrice_d = await this.$api("/api/priceMemDetailUpdate", {param: [arr_p[b], arr_p_S[b], prUse,  this.user.handlerId, this.productId, pDate, b+1]});
            if(resIns_pdtPrice_d.affectedRows < 0 || resIns_pdtPrice_d.affectedRows === undefined){
              this.$swal.fire('', '상품기본정보 업데이트 오류', 'error');
              return false;
            }
          b++;
        }
      }
      location.reload();
    },
    setStart(){
      //this.batchPriceStart = "";
      document.getElementById("bpStart").setAttribute("min", this.firstDay);
      document.getElementById("bpStart").setAttribute("max", this.rtDateFin);
    },
    setEnd(){
      //this.batchPriceEnd = "";
      document.getElementById("bpEnd").setAttribute("min", this.firstDay);
      document.getElementById("bpEnd").setAttribute("max", this.rtDateFin);
    },
    goToBack() {
      //this.$router.push({path: '/reserveInfoList'});
      history.back();
    },
    //일괄가격설정
    dataBaseTransaction(p1, p2)
    {
      let useFlag = "";

      //환불율 일괄변경용 배열
      if(this.price_Flag === "Y")
      {
        if(this.sRoute === "S")
        {
          this.arr_batchPrice = [this.batchPrice[0]];
        }
        else
        {
          //프리미엄환불율
          this.arr_batchPrice = [this.batchPrice[0],this.batchPrice[1],this.batchPrice[2],this.batchPrice[3]]; 
          //스탠다드환불율
          this.arr_batchPrice_S = [this.batchPrice_S[0],this.batchPrice_S[1],this.batchPrice_S[2],this.batchPrice_S[3]];
        }
        console.log("arr_batchPrice:",this.arr_batchPrice,"/arr_batchPrice_S:",this.arr_batchPrice_S);
      }
      
      if(p1 === "U" && p2 ==="B") 
      {
        let diffDate = this.batchPriceStart.replace(/-/g, "") - this.batchPriceEnd.replace(/-/g,"")
        if(diffDate > 0 )
        {
          return this.$swal.fire('', '시작일은 종료일보다 이전이어야 합니다.', 'error')
        }

        if(!this.batchPriceStart || !this.batchPriceEnd ) {
          return this.$swal.fire('', '적용기간을 입력하세요', 'question')
        }

        //선택된 요일체크
        let arr_selDoW = [];
          this.dayOfWeek.forEach((array) => 
          {
            if(array != "N") {
              arr_selDoW.push(array)
            }
          });
        console.log("selDoW:", arr_selDoW,"/string:", arr_selDoW.toString());

        this.$swal.fire({
        icon: 'question',
        text: '정보를 변경하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            //요일일괄처리루틴(dOw_Flag: 요일선택여부 / price_Flag: 환불율변동선택여부)
            if(this.price_Flag === "N" && this.dOw_Flag === "Y")
            {
              //일괄제외or취소처리
              if(this.prUseFlagNot === "Y")
              {
                useFlag = "1";
                this.$swal.fire('', '선택요일은 환불율적용에서 제외되어 판매되지 않습니다.', 'info');
              }
              else if(this.prUseFlagNot === "N")
              { 
                console.log("prUseFlagNot:", this.prUseFlagNot)
                useFlag = "0";
                this.$swal.fire('', '선택요일의 환불제외가 취소됩니다.', 'info');
              }
              let i = 0
                while(i < arr_selDoW.length) 
                {
                  console.log(arr_selDoW[i]);
                  let resUpd_DoWStatus = await this.$api("/api/setNotUseDoWStatus_mem", {param: [useFlag, this.user.handlerId,this.productId, arr_selDoW[i], this.batchPriceStart, this.batchPriceEnd]});
                  if(resUpd_DoWStatus.affectedRows < 0 || resUpd_DoWStatus.affectedRows === undefined)
                  {
                    alert("업데이트 중, 오류가 발생했습니다.");
                    return false;
                  }
                  i++;
                }
                location.reload();              
            }
            else if(this.price_Flag === "Y" && this.dOw_Flag === "Y")
            {
              if(this.prUseFlagNot === "Y") 
              {
                return this.$swal.fire('', '요일제외가 선택되어 있습니다. <br> 제외체크 해지 후, 재시도해 주세요.', 'warning');
              }
              else
              {
                //결합상품의 기간+요일 상품가격 일괄변경
                if(this.sRoute === "L")
                {
                  let j = 0;
                  while(j < arr_selDoW.length)
                  {
                    let i = 0;
                    while(i < 4)
                    {
                      let updatePricesAndDoW = await this.$api("/api/updatePricesAndDoW", {param: [this.arr_batchPrice[i], this.user.handlerId, this.productId, this.batchPriceStart, this.batchPriceEnd, arr_selDoW[j], i+1]});
                      if(updatePricesAndDoW.affectedRows < 0 || updatePricesAndDoW.affectedRows === undefined)
                      {
                        alert("업데이트 중, 오류가 발생했습니다.");
                        return false;
                      }
                      i++;
                    }
                    j++;
                  }
                  this.$swal.fire('', '변경되었습니다.', 'success');
                  location.reload();
                }
                else
                {
                  // let i = 0;
                  // while(i < arr_selDoW.length)
                  // {
                  //   let updatePricesAndDoW = await this.$api("/api/updatePricesAndDoW", {param: [this.arr_batchPrice[0], this.user.handlerId, this.productId, this.batchPriceStart, this.batchPriceEnd, arr_selDoW[i], "1"]});
                  //   if(updatePricesAndDoW.affectedRows < 0 || updatePricesAndDoW.affectedRows === undefined)
                  //   {
                  //     alert("업데이트 중, 오류가 발생했습니다.");
                  //     return false;
                  //   }
                  //   i++;
                  // }
                  // this.$swal.fire('', '변경되었습니다.', 'success');
                  // location.reload();
                  this.$swal.fire('', '멤버십적용은 현지투어상품에만 적용됩니다 ', 'warning');
                  location.reload();
                }                
              }
            }
            else if(this.price_Flag === "Y" && this.dOw_Flag === "N")
            {
              this.$swal.fire('', '지정기간의 상품가격을 변경합니다.', 'info');
              if(this.sRoute === "L") 
              {
                let j=0
                while(j < 4)
                {
                  let resUpd_Prices = await this.$api("/api/updatePrices_mem", {param: [this.arr_batchPrice[j],this.arr_batchPrice_S[j], this.user.handlerId, this.productId, j+1, this.batchPriceStart, this.batchPriceEnd]});
                  console.log("resUpd_Prices:", resUpd_Prices);
                  if(resUpd_Prices.affectedRows < 0 || resUpd_Prices.affectedRows === undefined)
                  {
                    //this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                    alert("업데이트 중, 오류가 발생했습니다.");
                    return false;
                  }
                  j++;
                }
                this.$swal.fire('', '변경되었습니다.', 'success');
                  location.reload();
              }
              else
              {
                this.$swal.fire('', '멤버십적용은 현지투어상품에만 적용됩니다 ', 'warning');
                location.reload();
                // //기간 중 비용일괄변경(단일가 상품)
                // let resUpd_Prices = await this.$api("/api/updatePrices", {param: [this.arr_batchPrice[0], this.user.handlerId, this.productId, "1", this.batchPriceStart, this.batchPriceEnd]});
                //   if(resUpd_Prices.affectedRows < 0 || resUpd_Prices.affectedRows === undefined)
                //   {
                //     this.$swal.fire('', '업데이트 오류발생!<br>관리자에 문의해주세요', 'error');
                //     return false;
                //   }
                //   else
                //   {
                //     this.$swal.fire('', '변경되었습니다.', 'success');
                //     location.reload();
                //   }
              }
            }
          }
        })
      }
    }
  }
}
</script>