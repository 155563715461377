<template>
  <div>
    <section class="py-4">
      <div class="adver my-3">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <h2 class="d-flex justify-content-center" style="font-weight:600">광고 제휴 문의</h2>
              
            <hr style="margin:30px 0 50px;">

            <div class="container">
              <main class="d-flex justify-content-center">   

                  <div class="col-sm-12 col-md-8">
                    <form>
                      <div class="row gy-3">
                        <div class="col-md-6 px-2">
                          <label for="company" class="form-label">회사명 *</label>
                          <input type="text" class="form-control" id="company" placeholder="">
                        </div>

                        <div class="col-md-6 px-2">
                          <label for="name" class="form-label">담당자명 *</label>
                          <input type="text" class="form-control" id="name" placeholder="">
                        </div>

                        <div class="col-12 px-2">
                          <label for="address" class="form-label">홈페이지 URL</label>
                          <div class="input-group has-validation">
                            <input type="text" class="form-control" id="address" placeholder="">
                          </div>
                        </div>

                        <div class="col-md-6 px-2">
                          <label for="email" class="form-label">이메일 *</label>
                          <input type="text" class="form-control" id="email" placeholder="example@example.co.kr">
                        </div>

                        <div class="col-md-6 px-2">
                          <label for="number" class="form-label">전화번호 *</label>
                          <input type="text" class="form-control" id="number" placeholder="">
                        </div>

                        <div class="col-12 px-2">
                          <label for="ask" class="form-label">문의내용 *</label>
                          <div class="input-group has-validation">
                            <textarea type="text" class="form-control ask" id="ask" placeholder="문의내용을 적어주세요."></textarea>
                          </div>
                        </div>
            
                      </div>
            
                      <hr class="my-4">
            
                      <button class="w-100 btn btn-primary btn-lg">문의하기</button>
                    </form>
                  </div>

              </main>
            </div> 

          </div>
        </div>
      </div>
    </section>
  </div>
</template>



<style>
.adver{
  text-align:left;
}

.ask{
  height: 200px;
}


</style>
