<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="pl-1 text-center">
        <div class="float-end mb-1">
          <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="rProcCode" @change="changeProcCode()">
            <option :value="noVal" disabled>진행상태선택</option>
            <option value="A" >전체보기</option>
            <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}} ({{rProcCode.procCode}})</option>
            <!-- <option value="DC" >입금</option> -->
            <option value="RF" >환불 (RF)</option>
          </select>
        </div>

        <!-- To-do: 추후검색기능 보강용 코드 -->
        <!-- <div class="float-end mb-2">
          <input v-model="dataInput" @input="submitAutoComplete" type="text" class="form-control form-control-sm text-primary" placeholder="신청자 또는 상담번호" />
          <div class="autocomplete disabled">
            <div class="text-success" style="cursor: pointer" :key="i" v-for="(res,i) in result" @click="searchData(res)">
              <small>{{ res }}</small>
            </div>
          </div>          
        </div>
        <div class="float-start mb-1">
          <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="i" @change="changeCondition()">
            <option :value="noVal" disabled>현황조회</option>
            <option value="all" >전체행사</option>
            <option value="IQ" >문의</option>
            <option value="PR" >진행</option>
            <option value="RP" >결제요청</option>
            <option value="CO" >확정</option>
            <option value="CX" >취소</option>
            <option value="DC" >결제</option>
            <option value="RF" >환불</option>
          </select>
        </div> -->

        <table class="table table-bordered table-striped fs-6">
          <thead class = "small">
            <tr>
              <th>상태</th>
              <th>코드</th>
              <th>성명</th>
              <th>상품명</th>
              <th>요청일</th>
              <th>결제액</th>
              <th>결제</th>
              <th>수단</th>
              <th>결제일</th>
              <th>확인일</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(rList, i) in reqList">
              <!-- <td>{{ rList.prName }}</td> -->
              <td>{{ rList.rStatus }}</td>
              <td class="text-primary">
                <small><a @click="goToInfo(rList.reqPayCode, rList.reqPdtCode);" style="cursor:pointer;">{{ rList.reqPayCode }}</a></small>
              </td>
              <td>{{ rList.rName }}</td>
              <td>{{ rList.payName }}</td>
              <td>{{ getDateFormat(rList.reqPayDate) }}</td>
              <td>{{ getCurrencyFormat(rList.reqPayAmount) }}</td>
              <!-- <td v-if="rList.checkFlag === 'Y'" class="text-danger"><b>{{ rList.checkFlag }}</b></td>
              <td v-else>{{ rList.checkFlag }}</td> -->
              <td v-if="rList.paidId != undefined" class="text-danger"><b>Y</b></td>
              <td v-else>N</td>
              
              <td>{{ rList.fName }}</td>
              <td>{{ getDateFormat(rList.paidDate) }}</td>
              <td>{{ getDateFormat(rList.checkDate) }}</td>
              <!-- <td>{{ rList.respodent }}</td>
              <td>{{ rList.ansFlag }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      reqList: [],
      rProc: [],
      //ansFlag: "N"
    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
    // 데이타베이스 쿼링테스트
    //this.getProductMaList();
    this.getReqProcLevel();
    //this.getCheckAnserFlag();
    this.getPaymentList();

  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    //상세정보와 무관한 내용 비표시처리
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
    },
    
    //결제리스트
    async getPaymentList() 
    {
      let reqList = await this.$api("/api/paymentList", {});
      if(reqList.length > 0) {
        this.reqList = reqList;
      }
      //console.log("reqList:",this.reqList);
    },    
    // //견적리스트
    // async getRequestList() 
    // {
    //   let reqList = await this.$api("/api/paymentList", {});
    //   if(reqList.length > 0) {
    //     this.reqList = reqList;
    //   }
    //   console.log("reqList:",this.reqList);
    // },
    goToInfo(param, pCode)  
    {
      let p = "";
      p = param.substring(0, 2);

      if(p === "EV")
      {
        this.$router.push({path: '/eventInfo', query: {eCode: param}});
      }
      else
      {
        this.$router.push({path: '/reserveInfoDetail', query: {seqReq: param, pCode: pCode}});
        // this.$router.push({path: '/reserveInfoDetail', query: {seqReq: seqReq, pCode: pCode, cCode: cCode}});
      }
    },
    async changeProcCode() {
      if(this.rProcCode === 'A') {
        //this.getRequestList();
        this.getPaymentList();
      }
      else {
        let reqList_tmp = await this.$api("/api/paymentList", {})
        //console.log("reqList_tmp:",reqList_tmp);
        this.reqList = reqList_tmp.filter(e => {return e.rStatus === this.rProcCode});
      }
    }
  }
}
</script>