<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="float-end mb-1">
          <button type="button" class="btn btn-primary" @click="goToInsert">상품등록</button>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class = "small">
            <tr>
              <th>상품코드</th>
              <th>상품명</th>
              <th>여행지</th>
              <th>
                판매가
                <br>
                <font class="text-primary">할인</font>
              </th>
              <th>판매</th>
              <th>상품소개</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(product, i) in productMaList">
              <!-- <td>
                <img v-if="product.useFlag == '0'" :src="`/download/${product.pdtCode}/${product.imgName}`" style="height:50px;width:auto;" />
                <img v-else :src="`/download/noImage/noImage.jpg`" style="height:50px;width:auto;" />
              </td> -->
              <td>{{ product.pdtCode }}</td>
              <td>{{ product.pdtNameKor }}</td>

              <td v-if="product.salesRoute == 'B'" class="text-primary">B2B</td>
              <td v-else-if="product.salesRoute == 'C'" class="text-danger">B2C</td>
              <td v-else>{{product.salesRoute}}</td>
              
              <td>{{ getCurrencyFormat(product.unitPrice) }}</td>
              <td>{{ product.buyGrade }}</td>
              <td>
                {{ getCurrencyFormat(product.pdtPrice) }}
                <br>
                <font class="text-primary" v-if="product.promoMethod === 'P'">-{{ getCurrencyFormat(Math.floor(product.promoRate)) }}%</font>
                <font class="text-primary" v-else-if="product.promoMethod === 'A'">-{{ getCurrencyFormat(Math.floor(product.promoRate)) }}</font>
                <!-- <font class="text-primary">{{ getCurrencyFormat(Math.floor(product.pdtPromoPrice)) }}</font> -->
                </td>
              <td> 
                <!-- 판매기간과 설정이 모두 만족해야 판매중 -->
                {{ product.pdtSalesStatus }}
                <!-- {{ product.saleStatus }} -->
              </td>
              <td>
                <!-- me-1: rigt margin1 -->
                <button type="button" class="btn btn-info btn-xs me-1" @click="goToImgInsert(product.pdtCode);">사진등록</button>
                <button type="button" class="btn btn-secondary btn-xs me-1" @click="goToUpdate(product.pdtCode, product.salesRoute);">정보수정</button>
                <button type="button" class="btn btn-warning btn-xs" @click="changeStatus(product.saleStatus, product.pdtCode)">상태변경</button>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      productMaList: []
    };
  },
  created() {
    this.productId = this.$route.query.productId;
    // 데이타베이스 쿼링테스트
    this.getProductMaList();
    this.getProductPrice();
    console.log("AccessInfo:",this.$store.state.user);
    //this.getProductPromotionPrice();
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }    
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    async getProductMaList() {
      // axios공통모듈을 호출하여 app.js를 타고 sql.js를 타고 데이타호출
      this.productMaList = await this.$api("/api/productMaList", {});
      // this.productMaList = productMaList_tmp.filter(e => {return e.useFlag == "0"});
      console.log("productMaList:",this.productMaList);
    },
    goToInsert() {
      this.$router.push({path: '/create'});
    },
    goToDetail(pdtCode) {
      //console.log(pdtCode);
      this.$router.push({path: '/detail', query: {productId: pdtCode}});
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    // 숫자콤마표시(mixins로 이관 20210920)
    // getCurrencyFormat(value) {
    //   // console.log('value' + value)
    //   return (value+"").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // },
    goToImgInsert(pdtCode) {
      // 이미지 저장패스
      this.$router.push({path: '/image_insert', query: {productId: pdtCode}});
    },
    goToUpdate(pdtCode, salesRouet) {
      // 이미지 저장패스
      this.$router.push({path: '/update', query: {productId: pdtCode, salesRoute: salesRouet}});
    },
    getProductPrice() {
      let productPrice = this.$api("/api/productPrice", {param: [this.productId]});
      // console.log(typeof(productPrice));
      console.log("productPrice",productPrice);
    },
    // getProductPrmomoPrice() {
    //   let productPromoPrice = this.$api("/api/productPrice", {param: [this.productId]});
    //   console.log("productPromoPrice",productPromoPrice);
    // },
    changeStatus(saleStatus, pdtCode) {
      // 변경전 확인
      this.$swal.fire({
        title: '판매상태(판매<->중지)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let sStatus = (saleStatus == "Y") ? "N" : "Y"
          // console.log(sStatus, pdtCode);
          await this.$api("/api/changeStatus", {param: [sStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getProductMaList();
          this.$swal.fire('변경완료!', '', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  }
}
</script>