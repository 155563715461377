<template>
  <div id="infoc">
    <section>
      <div class="container my-3">
        <div class="row justify-content-center">
          <h3>나의 멤버십</h3>

          <!-- 기본 정보 pc-->
          <div class="row basic pc">
            <div class="container">
              <div class="title text-start">
                <p>
                  기본정보&nbsp;
                    <b v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" style="font-size: 1rem">(가입경로: 홈페이지)</b>
                    <b v-else style="font-size: 1rem">(가입경로: 간편인증)</b>
                </p>
              </div>
              
              <div class="text-start">
                <table class="table">
                  <tbody>
                    <tr>
                      <th class="ta-name col-2 text-center">아이디</th>
                      <td class="col-2">
                        <input type="email" class="form-control" placeholder="name@example.com" v-model="this.userInfo.uEmail" style="font-size:small;" disabled>
                      </td>
                      <th class="ta-name col-2 text-center">한국명</th>
                      <td calss="col-2">
                        <input type="text" class="form-control" placeholder="한글이름" v-model="this.userInfo.uNickName" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="uNickName" disabled>
                        <input type="text" class="form-control" placeholder="한글이름" v-model="this.userInfo.uNickName" disabled v-else>
                      </td>
                      <th class="ta-name col-2 text-center">연락처(휴대전화)</th>
                      <td class="col-2">
                        <input type="text" class="form-control" placeholder="휴대폰번호" v-model="this.userInfo.alimCallC" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="alimCallC" disabled>
                        <input type="text" class="form-control" placeholder="휴대폰번호" v-model="this.userInfo.alimCallC" disabled v-else>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- 기본 정보 모바일 -->
          <div class="row basic mobile">
            <div class="container">
              <div class="title text-start">
                <p class="mb-0">기본정보
                  <b v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" style="font-size: 1rem">(가입경로: 홈페이지)</b>
                  <b v-else style="font-size: 1rem">(가입경로: 간편인증)</b>                  
                </p>
              </div>
              
              <div class="text-start">
                <table class="table">
                  <tbody>
                    <tr class="text-center">
                      <th class="ta-name col-4 text-center">아이디</th>
                      <td class="col-8">
                        <input type="email" class="form-control" placeholder="name@example.com" v-model="this.userInfo.uEmail" disabled>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">한국명</th>
                      <td>
                        <input type="text" class="form-control" placeholder="한글이름" v-model="this.userInfo.uNickName" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="uNickName" disabled>
                        <input type="text" class="form-control" placeholder="한글이름" v-model="this.userInfo.uNickName" disabled v-else>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">
                        연락처
                        <div>(휴대전화)</div>
                      </th>
                      <td>
                        <input type="text" class="form-control" placeholder="휴대폰번호" v-model="this.userInfo.alimCallC" v-if="this.userInfo.joinFlag == 'E' || this.userInfo.joinFlag == 'H'" ref="alimCallC" disabled>
                        <input type="text" class="form-control" placeholder="휴대폰번호" v-model="this.userInfo.alimCallC" disabled v-else>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- 나의 멤버십 정보 pc -->
          <div class="row members pc">
            <div class="container">
              <div class="title text-start">
                <p>
                  나의 멤버십 정보 &nbsp;
                  <span class="member-date">({{ getDateFormat(userMemInfo.insDate) }} ~ <font style="color:#f00">{{ getDateFormat(userMemInfo.expDate) }}</font>)</span>
                  <span class="member-dday">* 24.03.04일부터 멤버십 혜택이 적용됩니다.</span>
                </p>
              </div>
              
              <div class="text-start">
                <table class="table">
                  <tbody>
                    <tr>
                      <th class="ta-name col-2 text-center">무제한</th>
                      <td class="col-8">
                        <p><strong>[엔데이트립 버스투어 전코스]</strong></p>
                        <p>오사카(교토) , 북해도(비에이) , 후쿠오카(아소) , 오키나와(북부) , 대만(카발란, 예스지, 잉거) , 북해도(샤코탄) , 북해도(오시호) , 북해도(호헤이쿄) , 북해도(아사히야마) , 나고야(빅3) , 도쿄(가마쿠라)</p>
                      </td>
                      <td class="col-2 text-center" rowspan="3">
                        <img src="../assets/img/new-test/ms/premium.png" class="img-fluid">
                        <p class="m-namep">Premium</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">할인 혜택</th>
                      <td>
                        <p><i class="bi bi-circle-fill"></i> 동반 여행자 엔데이트립 일본 버스투어 20% 할인</p>
                        <p><i class="bi bi-circle-fill"></i> 엔타비, 엔데어 브랜드 여행 상품 최대 50% 할인(상품에 따라 할인율 상이)</p>
                        <p><i class="bi bi-circle-fill"></i> 멤버십 가입 연장 시 10% 할인</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">포인트 혜택</th>
                      <td>
                        <p>엔타비, 엔데어 여행 상품 구매 시 포인트 더블 적립</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- 나의 멤버십 정보 모바일 -->
          <div class="row members mobile">
            <div class="container">
              <div class="title text-start">
                <p>
                  나의 멤버십 정보 &nbsp;
                  <span class="member-date">({{ getDateFormat(userMemInfo.insDate) }} ~ <font style="color:#f00">{{ getDateFormat(userMemInfo.expDate) }}</font>)</span>
                  <span class="member-dday">* 24.03.04일부터 멤버십 혜택이 적용됩니다.</span>
                </p>
              </div>
              
              <div class="text-start">
                <table class="table">
                  <tbody>
                    <tr>
                      <th class="text-center" colspan="3">
                        <img src="../assets/img/new-test/ms/premium.png" class="img-fluid">
                        <p class="m-namep">Premium</p>
                      </th>
                    </tr>
                    <tr>
                      <th class="ta-name col-3 text-center">무제한</th>
                      <td class="col-7">
                        <p><strong>[엔데이트립 버스투어 전코스]</strong></p>
                        <p>오사카(교토) , 북해도(비에이) , 후쿠오카(아소) , 오키나와(북부) , 대만(카발란, 예스지, 잉거) , 북해도(샤코탄) , 북해도(오시호) , 북해도(호헤이쿄) , 북해도(아사히야마) , 나고야(빅3) , 도쿄(가마쿠라)</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">할인 혜택</th>
                      <td>
                        <p><i class="bi bi-circle-fill"></i> 동반 여행자 엔데이트립 일본 버스투어 10% 할인</p>
                        <p><i class="bi bi-circle-fill"></i> 엔타비, 엔데어 브랜드 여행 상품 최대 50% 할인(상품에 따라 할인율 상이)</p>
                        <p><i class="bi bi-circle-fill"></i> 멤버십 가입 연장 시 10% 할인</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="ta-name text-center">포인트 혜택</th>
                      <td>
                        <p>엔타비, 엔데어 여행 상품 구매 시 포인트 더블 적립</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          

          <!-- 약관 -->
          <div class="row clause">
            <div class="container">
              <div class="title text-start">
                <p>엔데이트립 멤버십 약관</p>
              </div>
              
              <div class="mc-info">
                <p>- 멤버십 등급은 엔데이트립에서 정한 기준에 따라 2개 등급(스탠다드, 프리미엄)으로 구분됩니다.</p>
                <p>- 버스투어 이용 시 NO SHOW 및 취소 규정 적용을 위해 환급 형식(상품 금액 결제 후 탑승 종료 시 전액 환급)으로 혜택이 제공됩니다.</p>
                <p>- 환급에 해당되실 경우, 실제 환급은 이용하신 투어의 종료 후 일괄 환급 진행되며 결제하신 수단을 통해 환급니다.</p>
                <p>- 연간 멤버십 혜택 적용기간은 가입 일자 기준 익년 동월 말일까지로 일괄 적용됩니다.</p>
                <p>- 멤버십 가입 비용은 기존의 포인트로 결제하실 수 없습니다.</p>
                <p>- 멤버십 가입 시 엔타비글로벌(주), 엔데이트립 공식 홈페이지 회원가입이 포함되며 개인정보취급방침, 이용약관, 여행약관 등 공식 홈페이지에서 제공하는 약관에 동의하는 것으로 간주됩니다.</p>
                <p>- 멤버십 가입 시 가입자의 여권정보(여권사본)을 필수로 수집합니다.</p>
                <p>- 미성년자의 멤버십 가입의 경우 법정대리인(부모 등)의 동의(인감 날인 동의서, 인감증명서 등의 구비서류 제시)를 얻어야 합니다.</p>
                <p>- 멤버십 가입 전 코스 예약(구매) 고객의 경우 멤버십 가입 시 기존 코스 예약(구매) 금액은 환불 혹은 차액만 결제하시면 멤버십 가입이 가능합니다.</p>
                <p>- 멤버십 가입 완료 후 즉시 멤버십 혜택을 이용 하실 수 있습니다.</p>
                <p>- 멤버십 가입 완료 후 철회 및 취소, 환불은 일체 불가능합니다.(해외여행결격사유 발생에도 적용)</p>
                <p>- 엔타비글로벌(주), 엔데이트립 공식 홈페이지 회원 탈퇴 시 멤버십도 자동 해지되며 환불되지 않습니다.</p>
                <p>- 멤버십은 양도가 불가능하며 다음의 경우 1회 양도가 가능합니다.(가입자 당사자의 사망, 사망진단서 서류 제출 必)</p>
                <p>- 코스의 예약은 엔타비글로벌(주), 엔데이트립 공식 홈페이지 및 상담사를 통해 유선으로 예약이 가능합니다.</p>
                <p>- 버스투어의 예약은 최대 3건까지 동시 예약이 가능하며 3건이 넘어가는 예약은 제한됩니다.</p>
                <p>- 코스는 사전 예약이 필수입니다. 사전 예약 없이 현장에서 임의 탑승은 불가능합니다.</p>
                <p>- 멤버십 회원의 경우 투어 탑승 시 회원 본인 확인을 위해 여권 제시가 필수이며, 본인 확인이 불가능 한 경우 탑승을 거부할 수 있습니다.</p>
                <p>- 코스 이용 시 규정은 상품에 명시된 내용을 토대로 동일하게 적용됩니다.</p>
                <p>- 코스 예약 시 해당 일자의 잔여 좌석 유무에 따라 확정됩니다.</p>
                <p>- 코스 예약 후 취소는 1일 전까지 가능합니다.</p>
                <p>- 투어 당일 NO SHOW, 당일 취소의 경우 결제하신 상품 금액은 환불되지 않습니다.</p>
                <p>- 3회 이상 NO SHOW가 확인될 경우 멤버십 자격 박탈, 결제하신 상품금액과 멤버십 가입비는 환불되지 않으며 재가입이 불가능합니다.</p>
                <p>- 멤버십 특전은 엔타비글로벌(주), 엔데이트립 공식 홈페이지에서 제공하는 여행 상품 이용 시 적용됩니다.</p>
                <p>- 코스의 운행 종료는 사전에 고지 없이 종료 될 수 있습니다.</p>
                <p>- 등급별 이용 가능한 코스는 변동될 수 있으며 변동 시 적용 기간으로부터 30일 전 멤버십 가입자에게 사전고지 합니다.</p>
                <p>- 해외(일본 등) 현지 비용(차량, 가이드, 관광시설 및 현지 국가의 관할국에서 정한 비용) 인상으로 인한 단품의 요금 인상이 불가피할 경우 멤버십 가입비도 인상될 수 있으며 아래의 개정 시 통지 방법으로 고지됩니다.</p>
                <p>- 본 약관은 수시로 개정 가능하며, 개정하고자 할 경우 개정하고자 하는 날로부터 15일 이전에 약관이 개정된다는 사실과 개정된 내용 등을 다음의 방법 중 1가지 이상의 방법으로 회원에게 통지합니다.
                  (단, 회원에게 불리하게 약관이 변경되는 경우 최소 30일 이상의 유예기간을 두고 사전고지 합니다.)
                  <br>
                  a. e-mail 통보, b. SMS 통보, c. 공식 홈페이지 고시, d. 회원이 제공한 연락처 정보 등을 이용한 전화 안내
                </p>
                <p class="check text-end">
                  <input class="form-check-input" type="checkbox" id="fAgree" v-model="confirm_Privacy" ref="confirm_Privacy" true-value="Y" false-value="N">
                  <label class="form-check-label" for="fAgree">
                    &nbsp;동의
                  </label>
                </p>                
              </div>
            </div>
          </div>

          <!-- 버튼 -->
          <div class="mt-5 row justify-content-center">
            <button class="btn cxlp col-5 col-lg-3 mx-2"  @click="goToMypage()">마이페이지</button>
            <button class="btn gop col-5 col-lg-3 mx-2" @click="updateMembership('EP');">멤버십연장</button>
          </div>

          <div class="row justify-content-center" id="tossPaymentArea">
            <div id="payment-method" style="height:100%" v-show="showFlag"></div>
            <div>
              <button @click="requestPayment()" class="btn btn-warning" id="payment-button" style="width:100%" v-show="showFlag">
                <b class="fs-6">연장결제하기</b>
              </button>              
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import moment from 'moment';

export default {
  data() {
    return {
      showFlag: false,        //결제창 표시여부

      //토스페이결제용
      paymentWidget: null,
      paymentMethodWidget: null,
      //clientKey: "test_ck_YyZqmkKeP8gG2Klanln8bQRxB9lG",
      clientKey: "live_ck_jZ61JOxRQVEZXa1aoR0rW0X9bAqw",
      customerKey: "Ntabi-1936=@",

      jmsFlag: "",                //멤버십플래그
      userInfo: [],               //유저정보
      userMemInfo: [],            //유저멤버십정보
      confirm_Privacy: "",        //개인정보동의
      preCost: 0,                 //프리미엄결제액
      stdCost: 0,                 //스탠다드결제액
      curCost: 0                  //현재멤버십금액
    };
  },
  created(){
    console.log("user:",this.$store.state.user);
    
    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")
    //console.log("today:",this.today)

    //회원정보
    this.getUserInfo();
    //회원멤버십정보
    this.getMembershipInfo()
    //멤버십결제액
    this.getMembershipCost()

    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.userInfo.joinFlag == 'H')
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.phone.replace("+82 ", "0")
      }
      this.k_tel = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }
    console.log("k_name:",this.k_name, "k_tel:",this.k_tel)
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },  
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   this.$router.push({path: '/'});
    // }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
  },  
  methods: {
    // 숫자 3자리마다 콤마 찍기
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    goToMypage() {
      this.$router.push({path: '/myPage'});
    },

    //멤버십 결제액 추출
    async getMembershipCost(){
      let msInfo = await this.$api("/api/baseCategory", {param: ["mnCd"]})

      if(msInfo.length > 0)
      {
        let preCost_tmp = msInfo.filter((item) => {
            return item.baseCode === "P"
          })
          let stdCost_tmp = msInfo.filter((item) => {
            return item.baseCode === "S"
          })
          let curCost_tmp = msInfo.filter((item) => {
            return item.baseCode === this.userMemInfo.memLevel
          })

          this.preCost = preCost_tmp[0].spRemark
          this.stdCost = stdCost_tmp[0].spRemark
          this.curCost = curCost_tmp[0].spRemark
          //console.log(this.preCost,"/",this.stdCost,"/",this.curCost)
      }
    },

    joinMS(val){
      this.jmsFlag = val
      if (this.jmsFlag === "U") 
      {
        document.getElementById("pre").style.backgroundColor = "white";
        document.getElementById("pre").style.color = "#ffb400";
        document.getElementById("std").style.backgroundColor = "white";
        document.getElementById("std").style.color = "#aaa";

        let currentDate = new Date(this.userMemInfo.expDate);
        let oneYearLater_tmp = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), 1);
        // 해당 달의 말일로 설정
        let oneYearLater = this.getDateFormat(oneYearLater_tmp.setMonth(oneYearLater_tmp.getMonth() + 1, 0))
        console.log(oneYearLater)

      } 
      else if (this.jmsFlag === "EP") 
      {
        document.getElementById("std").style.backgroundColor = "white";
        document.getElementById("std").style.color = "#ffb400";
        document.getElementById("pre").style.backgroundColor = "white";
        document.getElementById("pre").style.color = "#aaa";
      }
      else if (this.jmsFlag === "ES") 
      {
        document.getElementById("std").style.backgroundColor = "white";
        document.getElementById("std").style.color = "#0065bd";
        document.getElementById("pre").style.backgroundColor = "white";
        document.getElementById("pre").style.color = "#aaa";
      }
    },

    async getMembershipInfo(){
      let userMemInfo = await this.$api("/api/checkMembership", {param: [this.user.email]});

      if(userMemInfo.length > 0)
      {
        this.userMemInfo = userMemInfo[0]
        console.log("userMemInfo:",this.userMemInfo)
      }
    },

    //멤버십 업그레이드(U) Or 연장(EP)
    async updateMembership(val){
      if(!this.confirm_Privacy || this.confirm_Privacy == 'N')
      {
        this.$refs.confirm_Privacy.focus()
        this.$swal.fire("","멤버십 약관에 동의해 주세요","warning")
        return false
      }

      await this.pgPay(val)
    },

    //about UserInfo
    async getUserInfo() 
    {
      let userInfo = await this.$api("/api/checkMemEmail", {param: [this.user.email]});
      if(userInfo.length > 0) 
      {
        this.userInfo = userInfo[0];
      }

      console.log("userInfo:", this.userInfo)
    },

    //토스페이먼츠PG-업그레이드 or 연장(EP: premium)
    async pgPay(param)          //EP
    {
      let today = new Date();
      let yyyy = today.getFullYear();
      let dd_tmp = today.getDate();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      let rNum = yyyy+""+dd_tmp+""+min+""+sec


      let cstPrice_new = 0
      let orderName = ""
      let orderId =  this.user.email.replace(/[@.]/g, "_")+"-" + rNum    //특수문자제한이 있어 @과.을 ,으로 치환
      //console.log("orderId:", orderId)
      if(param === "U")
      {
        cstPrice_new = this.preCost*1 - this.stdCost*1
        orderName = "Membership Upgrade"
      }
      else
      {
        cstPrice_new = this.curCost*1 - ((this.curCost*1) * 0.1)
        orderName = "Membership Renewal"
      }
      
      const element = document.getElementById("tossPaymentArea");
      if(element)
      {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline:'nearest' }); 
      }

      // ------  결제위젯 초기화 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
      this.paymentWidget = await loadPaymentWidget(this.clientKey, ANONYMOUS);
      // ------  결제 UI 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
      this.paymentMethodWidget = this.paymentWidget.renderPaymentMethods("#payment-method", { value: cstPrice_new }, { variantKey: "membershipOnly" });
      // ------  이용약관 UI 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
      //this.paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });
      this.showFlag = !this.showFlag

      this.orderId = orderId
      this.orderName = orderName
      this.cstPrice_new = cstPrice_new

      //멤버십가입페이지에서 처리할 변수저장
      localStorage.setItem('memLevel', param)
      localStorage.setItem('total_amount', cstPrice_new)
      localStorage.setItem('engName', this.userInfo.engName)
      localStorage.setItem('uPassport', this.userInfo.uPassport)
      localStorage.setItem('uPassportExp', this.userInfo.uPassportExp)
      localStorage.setItem('memExpDate', this.userMemInfo.expDate)
      // if(param === 'E')
      // {
      //   localStorage.setItem('memExpDate', this.userMemInfo.expDate)
      // }
    },

    async requestPayment() {
      try {
        if (this.paymentWidget) 
        {
          // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
          // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
          // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
          // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
          await this.paymentWidget.requestPayment({
            orderId: this.orderId,
            orderName: this.orderName,
            successUrl: 'https://ntabi.co.kr/success_membership',
            failUrl: 'https://ntabi.co.kr/fail_membership',
            customerEmail: this.userInfo.uEmail,
            customerName: this.userInfo.uNickName,
            amount: this.cstPrice_new
          });
        }
      } catch (error) {
        // 에러 처리하기
        console.error(error);
      }
    },

    goToHome() {
      this.$router.push({path: '/myPage'});
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

#infoc{
  margin:4rem 0 8rem;
}
@media (max-width:992px){
  #infoc{
    margin:4rem 0;
  }
}

#infoc h3{
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1rem;
  text-transform: uppercase;
  text-align: center;
}

/* 모바일 개행처리 */
@media (max-width:768px){
  line{ display: block;}
}


/*--------------------------------------------------------------
	# 업그레이드 / 연장
--------------------------------------------------------------*/
#infoc .lo-tap2{
  margin:3.5rem 0 2.5rem;
  text-align: center;
}
#infoc .lo-tap2 .member{
  border-left:1px solid #aaa
}
#infoc .lo-tap2 button{
  width:50%;
  padding:1.5rem 0 .8rem;
  border: transparent;
  font-family: 'Black Han Sans', sans-serif;
  font-size:2.3rem;
  color:#777;
  background-color: #fff;
  line-height:40px
}
@media (max-width:991px){
  #infoc .lo-tap2{
    margin:2rem 0;
  }
  #infoc .lo-tap2 button{
    font-size:1.8rem;
    line-height:30px
  }
}


/* -------------------------------------------------
  # 내용 공통부분
--------------------------------------------------- */
#infoc .pc{
  display: block;
}
#infoc .mobile{
  display: none;
}


#infoc .title{
  border-bottom: 2px solid #000;
}
#infoc .title p{
  font-size:1.4rem;
  color:#000;
}
#infoc .table{
  vertical-align: middle;
}
#infoc .table tr{
  font-size:.9rem;
  height:5rem;
}
#infoc .table .ta-name{
  background-color:#f8f8f8;
  color:#000;
  font-weight: 600;
  text-align: left;
  padding-left: 1rem;
}
#infoc .table .ta-name span{
  color:#f00;
  padding-right:3px
}

#infoc .table .form-control{
  border-radius: 0;
  padding:10px
}

@media (max-width:991px){
  #infoc .pc{
    display: none;
  }
  #infoc .mobile{
    display: block;
  }
  #infoc .table .ta-name{
    font-size:.8rem
  }
}



/* -------------------------------------------------
  # 기본 정보
--------------------------------------------------- */
#infoc .basic{
  margin-top:4rem
}
#infoc .basic .form-control{
  width:80%
}
#infoc .basic .out{
  text-align: right;
  font-size:.8rem
}
#infoc .basic .out a{
  color:#aaa
}
#infoc .basic .out:hover a{
  color:#333
}
@media (max-width:991px){
  #infoc .basic{
    margin-top:2.5rem
  }
  #infoc .basic .form-control{
    width:100%
  }
}



/* -------------------------------------------------
  # 나의 멤버십
--------------------------------------------------- */
#infoc .members{
  margin:6rem 0
}
#infoc .members .member-date{
  font-size:.9rem
}
#infoc .members .member-dday{
  font-size:.9rem;
  padding-left:10px
}
@media (max-width:992px){
  #infoc .members .member-dday{
    font-size:.75rem;
    padding-left:0;
    display: block;
    text-align: left;
  } 
}
#infoc .members table p{
  margin-bottom:0;
  padding-left:10px
}
#infoc .members table .bi-circle-fill::before{
  font-size: 6px;
}
#infoc .members table img{
  width:auto;
  height:7.5rem
}
#infoc .members table .m-namep{
  font-family: 'Black Han Sans', sans-serif;
  padding-top:15px;
  font-size:1.5rem;
  background: linear-gradient(to right, #fff400, #ffcd00, #ffb400);
  color: transparent;
  -webkit-background-clip: text;
}

@media (max-width:992px){
  #infoc .members{
    margin:4rem 0
  }
  #infoc .members .member-date{
    font-size:.8rem
  }
}




/* -------------------------------------------------
  # 약관
--------------------------------------------------- */
#infoc .clause .title{
  margin-bottom:.8rem
}
#infoc .clause .mc-info{
  padding-left:1rem
}
#infoc .clause .mc-info p{
  margin-bottom:0.3rem;
  font-size:.9rem;
  color:#777
}

@media (max-width:992px){
  #infoc .clause .mc-info{
    padding-left:.5rem
  }
  #infoc .clause .mc-info p{
    font-size:.85rem;
    margin-bottom:0
  }
}




/* -------------------------------------------------
  # 버튼
--------------------------------------------------- */
#infoc .cxlp,
#infoc .gop{
  --bs-btn-padding-y: 0;
  margin:5rem 0;
  border-radius: 0;
  font-size: 1.15rem;
  transition: all .5s;
}
#infoc .cxlp{
  background-color: #f3f3f3;
}
#infoc .cxlp:hover{
  border:1px solid #bbb
}
#infoc .gop{
  background: linear-gradient(to right, #fff400, #ffcd00, #ffb400);
  color:#000;
  padding:15px 5px;
}
#infoc .gop:hover{
  border:1px solid #ffb400
}
</style>
