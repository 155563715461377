<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="float-end mb-1">
          <button type="button" class="btn btn-success" @click="goToInsert">템플릿등록</button>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class="small text-center">
            <tr>
              <th>적용상품</th>
              <th>템플릿유형</th>
              <th>템플릿 제목</th>
              <th>사용상태</th>
              <th>상태변경</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(pattern, i) in patternList">
              <td class="text-center">{{ pattern.pdtCatName }}</td>

              <td v-if="pattern.pCat === 'A'" class="text-danger text-center">알림톡</td>
              <td v-else class="text-center">{{ pattern.pCategory }}</td>

              <td>
                <a @click="goToPatternDetail(pattern.patternCode);" style="cursor:pointer;"><font class="text-primary">{{ pattern.pTitle }}</font></a>
              </td>
              <!-- <td>{{ notice.nTarget }}</td> -->
              <td class="text-center">{{ pattern.pUseFlag }}</td>
              
              <td class="text-center">
                <div class="d-flex justify-content-center align-items-center">
                  <div>
                    <button type="button" class="btn btn-warning btn-sm me-2" @click="changeStatus(pattern.pUseFlag, pattern.patternCode)">변경</button>
                  </div>
                  <div>
                    <button
                      type="button"
                      :class="pattern.pStandard === 'Y' ? 'btn btn-dark btn-sm' : 'btn btn-outline-dark btn-sm'"
                      @click="setStandardPattern(pattern.pStandard, pattern.patternCode)"
                    >
                      기본형
                    </button>
                  </div>
                </div>
              </td>

              <!-- <td class="d-none">{{ notice.seqNotice }}</td>
              <td class="d-none">{{ notice.nUseFlag }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() { 
    return {
      //productMaList: [],
      patternList: []
      //companyInfo: []
    };
  },
  created() {
    this.productId = this.$route.query.productId;
    // 데이타베이스 쿼링테스트
    //this.getProductMaList();
    this.getPatternList();
  }, 
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },    
    async getPatternList() {
      this.patternList = await this.$api("/api/patternList", {});
      console.log("patternList:",this.patternList);
    },
    goToInsert() {
      this.$router.push({path: '/patternCreate'});
    },
    goToPatternDetail(patternCode) 
    {
      this.$router.push({path: '/patternDetail', query: {patternCode: patternCode}});
      //console.log("oId:", orderId);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    // goToImgInsert(pdtCode) {
    //   // 이미지 저장패스
    //   this.$router.push({path: '/image_insert', query: {productId: pdtCode}});
    // },
    // goToUpdate(pdtCode, salesRouet) {
    //   // 이미지 저장패스
    //   this.$router.push({path: '/update', query: {productId: pdtCode, salesRoute: salesRouet}});
    // },

    //기본패턴설정
    async setStandardPattern(param, ptCode){
      let pFlag = (param === "Y") ? "" : "Y" 
      let setStandPattern = await this.$api("/api/setStandPattern", { param: [ pFlag, ptCode ] })
      if(setStandPattern.affectedRows > 0)
      {
        this.$swal.fire("", "변경되었습니다", "info")
        location.reload(true)
      }

    },    
    changeStatus(pUseFlag, patternCode) {
      console.log(pUseFlag,"/",patternCode);
      //변경전 확인
      this.$swal.fire({
        title: '상태를 변경할까요?(Y<->N)',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let nStatus = (pUseFlag === "Y") ? "N" : "Y"
          await this.$api("/api/changePatternStatus", {param: [nStatus, patternCode]});
          // 상태변경 후, 갱신
          this.$swal.fire('', '변경완료!', 'success')
          this.getPatternList();
        }
      })
    }
  }
}
</script>