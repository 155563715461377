<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="alert alert-secondary" role="alert">
          <b class="fs-4">환율 / 기관-수수료</b>
        </div>
        <div class="float-start mb-1">
          <label class="text-primary">[ 통화 & 환율 ]</label>
        </div>
        <table class="table table-bordered table-striped fs-6 align-item-center text-center">
          <thead class="small">
            <tr>
              <!-- <th style="width:40px;">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th> -->
              <th style="width:70px;">코드</th>
              <th style="width:140px;">통화명</th>
              <th style="width:120px;">기본환율(1:원화)</th>
              <th style="width:80px;">사용</th>              
              <th style="width:200px;">비고사항</th>
              <th style="width:90px;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(m, i) in currencyInfo">
              <td class="form-control-sm text-primary">
                <b>{{ m.fCodeNo }}</b>
              </td>
              <td class="form-control-sm">
                <input type="text" v-model="m.fName" size="10">
              </td>
              <td class="form-control-sm">
                <input type="text" v-model="m.feeRate" size="6">
              </td>              
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_pUseFlag" v-model="m.fUseFlag">
                  <option value="Y" class="text-success">Y</option>
                  <option value="N" class="text-danger">N</option>
                </select>
              </td>
              <td style="width:150px;">
                <textarea class="form-control-sm" ref="fRemarks" id="floatingTextarea" style="width:100%; height:10px; font-size: smaller" v-model="m.fRemarks" maxlength="220" placeholder="200자까지"></textarea>
              </td>
              
              <td style="width:80px;">
                <button type="button" class="btn btn-outline-primary btn-sm" style="font-size: small;" @click="updateCurrency(m.fName,m.feeRate,m.fUseFlag,m.fRemarks,m.fCodeNo,m.fCategory)">변경</button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="float-start mt-2 mb-1">
          <label class="text-success">[ 금융기관 & 수수료 ]</label>
        </div>
        <table class="table table-bordered table-striped fs-6 align-item-center text-center">
          <thead class="small">
            <tr>
              <!-- <th style="width:40px;">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th> -->
              <th style="width:10%;">기관명</th>
              <th style="width:5%;">수수료(%)</th>
              <th style="width:5%;">사용</th>
              <th style="width:8%;">카테고리</th>
              <th style="width:5%;">내부용코드</th>
              <th style="width:20%;">비고사항</th>
              <th style="width:10%;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(f, i) in finInstInfo">
              <td class="form-control-sm">
                <input type="text" v-model="f.fName" size="18" class="text-success">
              </td>
              <td class="form-control-sm">
                <input type="text" v-model="f.feeRate" size="6">
              </td>              
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_fUseFlag" v-model="f.fUseFlag">
                  <option value="Y" class="text-success">Y</option>
                  <option value="N" class="text-danger">N</option>
                </select>
              </td>
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_fCategory2" v-model="f.fCategory2">
                  <option :value="f.baseCode" :key="i" v-for="(f,i) in this.finCategory">{{ f.codeNameKor }}</option>
                </select>
              </td>
              <td style="width:80px;">
                <input class="form-check-input my-auto" type="checkbox" v-model="f.inCdFlag" true-value="Y" false-value="N" id="chb_addItemFlag">
              </td>
              <td style="width:150px;">
                <textarea class="form-control-sm" ref="fRemarks" id="floatingTextarea" style="width:100%; height:10px; font-size: smaller" v-model="f.fRemarks" maxlength="220" placeholder="200자까지"></textarea>
              </td>
              
              <td style="width:80px;">
                <button type="button" class="btn btn-outline-primary btn-sm" style="font-size: small;" @click="updatefInst(f.fName,f.feeRate,f.fUseFlag,f.fCategory2,f.fRemarks,f.fCodeNo,f.inCdFlag)">변경</button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      currencyInfo: [],   //통화정보
      finInstInfo: [],    //금융기관정보
      finCategory: [],    //금융거래구분
    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;
    this.getFinanceList();
  }, 
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    //memberInfo: 회원정보리스트용 sql
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //통화, 금융기관정보
    async getFinanceList() 
    {
      //금융거래구분
      let finCategory = await this.$api("/api/baseCategory", {param: ['fnCd']});      
      //통화리스트
      let currencyInfo = await this.$api("/api/currencyInfo", {param: ['Currency']});
      //금융기관리스트
      let finInstInfo = await this.$api("/api/finInstInfo", {param: ['Inst']});

      if(currencyInfo.length > 0)
      {
        this.currencyInfo = currencyInfo
      }
      if(finInstInfo.length > 0)
      {
        this.finInstInfo = finInstInfo
      }
      if(finCategory.length > 0)
      {
        this.finCategory = finCategory
      }
      console.log("finInstInfo:", this.finInstInfo);
    },

    //통화정보수정
    updateCurrency(fName,fUseFlag,fRate,fRemarks,fCodeNo,fCategory)
    {
      //console.log(fName,fUseFlag,fRate,this.user.handlerId,fRemarks,fCodeNo,fCategory)
      //변경전 확인
      this.$swal.fire({
        title: '통화정보를 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let upd_currencyInfo = await this.$api("/api/upd_currencyInfo", {param: [
            fName,fUseFlag,fRate,this.user.handlerId,fRemarks,fCodeNo,fCategory
          ]});
          //console.log("upd_currencyInfo:",upd_currencyInfo)
          if(upd_currencyInfo.affectedRows > 0)
          {
            this.$swal.fire('', '변경완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:upd_currencyInfo)', 'warning')
            return false;
          }
        }
      })
    },
    //금융기관정보수정
    updatefInst(fName,feeRate,fUseFlag,fCategory2,fRemarks,fCodeNo,fInCd)
    {
      console.log(fName,feeRate,fUseFlag,fCategory2,fRemarks,fCodeNo,fInCd)
      //변경전 확인
      this.$swal.fire({
        title: '기관정보를 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let upd_fInstInfo = await this.$api("/api/upd_fInstInfo", {param: [
            fName,feeRate,fUseFlag,this.user.handlerId,fRemarks,fCategory2,fInCd,fCodeNo
          ]});
          //console.log("upd_fInstInfo:",upd_fInstInfo)
          if(upd_fInstInfo.affectedRows > 0)
          {
            this.$swal.fire('', '변경완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:upd_fInstInfo)', 'warning')
            return false;
          }
        }
      })
    }, 
  }
}
</script>