<template>
  <div class="cask">
    <section class="py-4">
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-lg-10" style="line-height:200%">
            <h2 class="d-flex justify-content-center" style="font-weight:600">1:1 문의답변</h2>

            <div class="row justify-content-center">
              <div class="col-12 col-md-8">
                <div class="box">
                  <div class="my-4">
                    <!-- 문의유형 -->
                    <div class="row">
                      <div class="col-12 row mb-1">
                        <div class="col-12 gx-3 form-group">
                          <!-- <label for="budget" class="tit col-form-label mx-3 my-1">문의유형 <font class="text-danger">*</font></label> -->
                          <div class="col-12">
                            <div class="input-group mb-3" >
                              <select class="form-select mx-3 form-select-sm text-success" aria-label=".form-select-sm" ref="sel_selCat" v-model="this.reqInfo.selCat" disabled>
                                <option :value="noVal" disabled>문의유형: {{ this.jDetail.cat }}</option>
                                <!-- <option :value="cl.baseCode" :key="i" v-for="(cl, i) in inquiryCatList">
                                  {{ cl.codeNameKor }}
                                </option> -->
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-12 gx-5 form-group">
                        <input type="text" maxlength="22" class="form-control form-select-sm" placeholder="제목(필수입력)" ref="txt_reqTitle" v-model="this.jDetail.reqTitle" readonly>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-6 gx-5 form-group">
                        <input type="text" maxlength="12" class="form-control form-select-sm" placeholder="성명(필수입력)" ref="txt_reqPersonName" v-model="this.jDetail.reqPersonName" readonly>
                      </div>
                      <div class="col-md-6 gx-5 form-group">
                        <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_Nat" v-model="this.reqInfo.selNat" disabled>
                          <option :value="noVal" disabled>여행지역: {{ this.jDetail.nation }}</option>
                          <!-- <option :value="nl.baseCode" :key="i" v-for="(nl, i) in nationList">
                            {{ nl.codeNameKor }}
                          </option> -->
                        </select>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-6 gx-5 form-group">
                        <input type="email" maxlength="40" class="form-control form-select-sm" ref="txt_cEmail" placeholder="이메일(필수입력)" v-model="this.jDetail.reqEmail" readonly>
                      </div>
                      <div class="col-md-6 gx-5 form-group">
                        <input type="text" maxlength="11" id="reqTel" placeholder="연락처(숫자만, 필수입력)" class="form-control form-select-sm" ref="txt_cTel" v-model="this.jDetail.reqTel" readonly>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 gx-5 form-group">
                        <textarea class="form-control form-select-sm" maxlength="248"  placeholder="문의내용(250자 이내, 필수입력)" ref="txt_reqSpRemarks" id="floatingTextarea" style="height:150px" v-model="this.jDetail.reqSpRemarks" readonly></textarea>
                      </div>
                    </div>       

                    <hr style="margin:3rem 0 2rem">

                    <!-- 문의답변 -->
                    <div class="col-12 gx-3 my-1">
                      <label class="col-form-label my-1 name">문의답변 <font style="color:gray; font-size:small">(답변일시: {{ getDateFormat(this.requestAnswer.insDate) }})</font></label>
                      <textarea class="form-control" maxlength="999" ref="txt_ansContent" id="floatingTextarea" style="height:200px" v-model="this.requestAnswer.ansContent" readonly></textarea>
                    </div>

                    <!-- 목록으로 -->
                    <div class="mt-5 row justify-content-center">
                      <button class="btn go col-5 col-lg-4 mx-2" @click="goToList()">마이페이지</button>
                    </div>
                    <!-- 문의버튼 -->
                    <!-- <div class="row justify-content-center mt-5 mx-3">
                      <button class="btn cxl col-5 col-sm-4 mx-2" @click="goToHome()">
                        취소
                      </button>
                      <button class="btn go col-5 col-sm-4 mx-2" @click="requestInsert();">
                        등록
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import moment from 'moment';
export default {
  data() {
    return {
      jDetail: {}, //상세페이지 정보
      requestAnswer: [], //답변내용

      // ruleAgree: "N",
      nationList: [], //국가리스트
      inquiryCatList: {}, //질의영역분류
      reqInfo: {}
    };
  },
  created(){
    this.askCode = this.$route.query.askCode;
    console.log("userInfo:",this.$store.state.user);
    console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }
    this.getRequestDetail();
    this.getReqAnswer(); //답변데이타

    this.getInquiryCatList();
    this.getNationList();
  },
  mounted() {
    window.scrollTo(0, 0);
    
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }
  },  
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },    
    async getRequestDetail() {
      let reqJoinDetail = await this.$api("/api/customerAskDetail ", {param: [this.askCode]});
      this.jDetail = reqJoinDetail[0];
      console.log("jDetail:",this.jDetail)
    },

    //문의답변
    async getReqAnswer() {
      let requestAnswer = await this.$api("/api/customerAskAnswer", {param: [this.askCode]});
      if(requestAnswer.length > 0) {
        this.requestAnswer = requestAnswer[0];
        this.answerFlag = "Y";
      }
      console.log("requestAnswer:",this.requestAnswer);
    },

    async getNationList() {
      let nationList = await this.$api("/api/searchBasecode", {param:['ntCd']});
      if (nationList.length > 0)
      {
        this.nationList = nationList;
      }
    },
    async getInquiryCatList() {
      let inquiryCatList = await this.$api("/api/searchBasecode", {param:['iqCd']});
      // let inquiryCatList = inquiryCatList_tmp.filter((item) => {return item.uFlag === 'Y'})
      if (inquiryCatList.length > 0)
      {
        this.inquiryCatList = inquiryCatList;
      }
    },
    // async requestInsert() {
    //   let date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
    //   //const time = new Date().toTimeString().split(" ")[0];
    //   let today = new Date()
    //   let timeStamp = today.getTime()
    //   //let currentDate = date + ' ' + time;
    //   let askCode = 'CA-'+date+timeStamp
    //   console.log("askCode:", askCode)

    //   // console.log("today:", date + ' ' + time);
    //   if(this.ruleAgree == "" || !this.ruleAgree || this.ruleAgree == 'N') { 
    //     this.$refs.chb_ruleAgree.focus();
    //     this.$swal.fire("","진행을 위해서 필수확인사항에 동의를 해주십시오.", "warning");
    //     return false;
    //   }
    //   if(this.reqInfo.selCat == "noVal" || this.reqInfo.selCat == undefined) 
    //   { 
    //     this.$refs.sel_selCat.focus();
    //     this.$swal.fire("","문의영역을 선택해주세요.", "warning");
    //     return false;
    //   }
    //   if(!this.reqInfo.reqTitle) { 
    //     this.$refs.txt_reqTitle.focus();
    //     return this.$swal.fire("","제목을 입력해주세요.", "warning");
    //   }
    //   if(!this.reqInfo.reqPersonName) {
    //     this.$refs.txt_reqPersonName.focus();
    //     this.$swal.fire("","성명을 입력해주세요.", "warning");
    //     return false;
    //   }
    //   // if(this.reqInfo.selNat == "noVal" || this.reqInfo.selNat == undefined) 
    //   // { 
    //   //   this.$refs.sel_Nat.focus();
    //   //   this.$swal.fire("","여행지역을 선택해주세요.", "warning");
    //   //   return false;
    //   // }
    //   if(!this.reqInfo.reqEmail) {
    //     this.$refs.txt_cEmail.focus();
    //     this.$swal.fire("","이메일을 입력해주세요", "warning");
    //     return false;
    //   }
    //   if(!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]+$/.test(this.reqInfo.reqEmail)) {
    //     this.$refs.txt_cEmail.focus();
    //     this.$swal.fire('','이메일 양식이 불명확합니다', 'question');
    //     return false;
    //   }
    //   if(!this.reqInfo.reqTel) {
    //     this.$refs.txt_cTel.focus();
    //     this.$swal.fire("","연락처를 입력해주세요", "warning");
    //     return false;
    //   }
    //   if(!/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(this.reqInfo.reqTel)) {
    //     this.$refs.txt_cTel.focus();
    //     this.$swal.fire('','연락처가 불명확합니다', 'question');
    //     return false;
    //   }
    //   if(!this.reqInfo.reqSpRemarks) {
    //     this.$refs.txt_reqSpRemarks.focus();
    //     this.$swal.fire("","문의내용이 없습니다", "warning");
    //     return false;
    //   }
    //   this.reqInfo.insDate = today;
    //   this.reqInfo.reqStatus = "IQ";

    //   //1:1 문의등록
    //   let resIns_Request = await this.$api("/api/ins_customerAsk", 
    //     {param: [this.reqInfo.selCat,this.reqInfo.reqTitle,this.reqInfo.reqPersonName,this.reqInfo.selNat,this.reqInfo.reqEmail,
    //     this.reqInfo.reqTel,this.reqInfo.reqSpRemarks,this.ruleAgree,this.reqInfo.reqStatus,this.user,askCode]});
    //   if(Object.keys(resIns_Request)[0] === 'error') {
    //     this.$swal.fire('','문의등록 중 오류가 발생했습니다.<br>연속오류 발생 시 연락처<br> (1660-4602)', 'error')
    //   }
    //   else {
    //     this.$swal.fire('','감사합니다<br>빠른 시간안에 확인연락을 드리겠습니다.', 'success');
    //     this.$router.push({path: '/'});
    //   }
    //   console.log("resIns_Request:",resIns_Request);
    // },
    goToList() {
      //this.$router.push({path: '/requestList_client'});
      this.$router.push({path: '/myPage'});
    }
  }
}
</script>


<style>
.cask .box {
  text-align:left
}
.cask .head{
  font-size:.8rem;
  margin-bottom:0;
  line-height:25px
}
.cask.cinfo{
  font-size:.8rem;
  margin-bottom:15px;
  line-height:20px
}
.cask .box hr{
  margin:.5rem 0
}
.cask .box .tit{
  font-size:.9rem;
  font-weight:bold
}
.cask .box .form-select{
  border-radius: 0.375rem !important
}
.cask .box .ask-btn{
  margin-top:3rem;
}

.cask .go {
  padding:15px 0;
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.cask .cxl{
  padding:15px 0;
  background-color: #eee;
  border-radius:0;
}
.cask .go:hover{
  background-color: #444;
}
.cask .cxl:hover{
  border:1px solid #777
}
</style>
