<template>
  <div id="lastautumnpage_hj">

    <div class="la-fst_section_hj">
      <img class="la-fst-bg-hj1" src="../assets/img/lastautumn/fstbg.png" alt="">
      <div class="la-fst_sc_imgs_hj">
        <img class="imgbgleaf" src="../assets/img/lastautumn/fstbgleaf.png" alt="">
        <img class="imgfstpic" src="../assets/img/lastautumn/fstpic.png" alt="">
        <img class="imgfsttit" src="../assets/img/lastautumn/fsttit.png" alt="">
      </div><!-- la-fst_sc_imgs_hj -->
      <img class="imgmoneygun" src="../assets/img/lastautumn/moneygun.png" alt="">
      <img class="imgmoney1" src="../assets/img/lastautumn/moneygun1.png" alt="">
      <img class="imgmoney2" src="../assets/img/lastautumn/moneygun2.png" alt="">
    </div><!-- la-fst_section_hj -->

    <div class="la-scd_section_hj">
      <div class="la-scd-onetg_hj">
        <img class="onetarget" src="../assets/img/lastautumn/onetarget.png" alt="">
        <div class="la-onetgbox_hj">
          <img class="saleimg" src="../assets/img/lastautumn/saleimg.png" alt="">
        </div><!-- la-onetgbox_hj -->
      </div><!-- la-scd-onetg_hj -->
      <div class="la-scd-twotg_hj">
        <img class="twotarget" src="../assets/img/lastautumn/twotarget.png" alt="">
        <div class="la-twotgbox_hj">
          <img class="saleimg2" src="../assets/img/lastautumn/saleimg2.png" alt="">
        </div><!-- twotgbox_hj -->
      </div><!-- la-scd-twotg_hj -->

    </div><!-- la-scd_section_hj -->

    <div class="la-thr_section_hj">
      <div class="la-thr_product_hj">
        <img src="../assets/img/lastautumn/thrtit.png" alt="">
      </div><!-- la-thr_product_hj -->

      <div class="la-thr_osaka_hj">
        <img class="osakatit" src="../assets/img/lastautumn/osakatit.png" alt="">
        <div class="la-osaka-pd_hj">
          <img src="../assets/img/lastautumn/kyoto.png" @click="goToDetail('L-2023220-1676865317720', 'L')" alt="">
          <img src="../assets/img/lastautumn/gobe.png" @click="goToDetail('L-2024228-1709084594375', 'L')" alt="">
        </div><!-- la-osaka-pd_hj -->
      </div><!-- la-thr_osaka_hj -->

      <div class="la-thr_sapporo_hj">
        <img class="sapporotit" src="../assets/img/lastautumn/sapporo.png" alt="">
        <div class="la-thr_sapporo-pd_hj">
          <img class="spr01" src="../assets/img/lastautumn/biei.png" @click="goToDetail('L-202346-1680743217094', 'L')" alt="">
          <img class="spr02" src="../assets/img/lastautumn/nobori.png" @click="goToDetail('L-2431993LGC', 'L')" alt="">
          <img class="spr03" src="../assets/img/lastautumn/osiho.png" @click="goToDetail('L-20231026-1698301178789', 'L')" alt="">
          <img class="spr04" src="../assets/img/lastautumn/nikapu.png" @click="goToDetail('L-2432173MCC', 'L')" alt="">
          <img class="spr05" src="../assets/img/lastautumn/hoheykyo.png" @click="goToDetail('L-20231019-1697704655687', 'L')" alt="">
        </div><!-- la-thr_sapporo-pd_hj -->
      </div><!-- la-thr_sapporo_hj -->

      <div class="la-thr_fukuoka_hj">
        <img class="fukuokatit" src="../assets/img/lastautumn/fukuoka.png" alt="">
        <div class="la-fukuoka-pd_hj">
          <img src="../assets/img/lastautumn/yuhuin.png" @click="goToDetail('L-2023220-1676881365712', 'L')" alt="">
          <img class="asoimg02" src="../assets/img/lastautumn/aso.png" @click="goToDetail('L-2023221-1676942590519', 'L')" alt="">
          <img src="../assets/img/lastautumn/yamaguchi.png" @click="goToDetail('L-2024312-1710224515956', 'L')" alt="">
        </div><!-- la-sapporo-pd_hj -->
      </div><!-- la-thr_fukuoka_hj -->
      
      <div class="la-thr_other_hj">
        <img class="othertit" src="../assets/img/lastautumn/othertit.png" alt="">
        <div class="la-other-pd_hj">
          <img src="../assets/img/lastautumn/nagoya.png" @click="goToDetail('L-2023221-1676943535719', 'L')" alt="">
          <img class="othimg02" src="../assets/img/lastautumn/tokyo.png" @click="goToDetail('L-2023329-1680070456245', 'L')" alt="">
          <img src="../assets/img/lastautumn/okinawa.png" @click="goToDetail('L-2023221-1676948010488', 'L')" alt="">
        </div><!-- la-other-pd_hj -->
      </div><!-- la-thr_other_hj -->

    </div><!-- la-thr_section_hj -->

  </div> <!-- lastautumnpage_hj -->
  </template>
  
<script>
export default {
  data() {},
  computed: {
    user() {
      return this.$store.state.user;
    }   
  },  
  methods: {
    goToDetail(productId, salesRoute) {
    this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  }
}
  </script>
  
  <style>
    /* main id */
    #lastautumnpage_hj{
      margin-top: -56px;
      position: relative;
      width: 100%;
      height: 100%;
    }
    /* ---------------la-fst_section_hj----------------------- */

    .la-fst_section_hj{
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    }
  .la-fst_section_hj .la-fst-bg-hj1 {
    width: 100%;
  }
  .la-fst_section_hj .imgmoneygun{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
  }
    .la-fst_sc_imgs_hj img{
    position: absolute;
    width: 100%;
  }

  
  .la-fst_sc_imgs_hj .imgfstpic{
    top: 80%;
    width: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  
  .la-fst_sc_imgs_hj .imgbgleaf{
    top: 15%;
    width: 50%;
    left: 25%;
  }

  .la-fst_sc_imgs_hj .imgfsttit{
    top: 50%;
    width: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .la-fst_section_hj .imgmoney1{
    position: absolute;
    top: 67%;
    width: 16%;
    left: 10%;
  }
  .la-fst_section_hj .imgmoney2{
    position: absolute;
    top: 26%;
    width: 13.5%;
    right: 13%;
  }
/* ---------------.la-scd_section_hj----------------------- */

.la-scd_section_hj {
  width: 100%;
  height: 100%;
  background-color: #ffb81c;
  position: relative;
}
.la-scd-onetg_hj{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.la-scd-onetg_hj .onetarget{
  margin-top: 100px;
}

.la-onetgbox_hj{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
}

.la-onetgbox_hj .saleimg {
}

.la-scd-twotg_hj{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.la-scd-twotg_hj .twotarget{
  margin-top: 100px;
}

.la-twotgbox_hj{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
  margin-bottom: 140px;
}

.la-onetgbox_hj .saleimg {
  width: 65%;
}
.la-twotgbox_hj .saleimg2 {
  width: 65%;
}

/* ----------------------.la-thr_section_hj-------------------------------- */

.la-thr_section_hj{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background: url(../assets/img/lastautumn/5998.jpg);
  background-size: cover;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.la-thr_section_hj .la-thr_product_hj{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-block: 150px ;
}

.la-thr_product_hj img {
  display: flex;
  width: 350px;

}
/* ----------------------osaka-------------------------------- */
.la-thr_osaka_hj{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.la-thr_osaka_hj .osakatit {
  margin-bottom: 60px;
}

.la-osaka-pd_hj {
  display: flex;
  width:40%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}

.la-osaka-pd_hj img{
  width: 20%;
  margin-right: 60px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(90%/2);
}

.la-osaka-pd_hj img:hover{
  transform:translateY(-8px);
}

.la-osaka-pd_hj img:last-child{
  margin-right: 0;
}
/* ----------------------sapporo-------------------------------- */

.la-thr_sapporo_hj{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.la-thr_sapporo_hj .sapporotit {
  margin-top: 150px;
  margin-bottom: 60px;
}

.la-thr_sapporo-pd_hj{
  display: flex;
  width: 70%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.la-thr_sapporo-pd_hj img{
  width: 20%;
  margin-right: 60px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(77%/3);
  margin-bottom: 60px;
}

.la-thr_sapporo-pd_hj img:hover{
  transform:translateY(-8px);
}
.la-thr_sapporo-pd_hj .spr03 {
  margin-right: 0;
}

.la-thr_sapporo-pd_hj .spr04{
  margin-bottom: 0;
}

.la-thr_sapporo-pd_hj .spr05 {
  margin-right: 0;
  margin-bottom: 0;
}



/* ----------------------fukuoka------------------------------- */
.la-thr_fukuoka_hj{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.la-thr_fukuoka_hj .fukuokatit {
  margin-top: 150px;
  margin-bottom: 60px;
}

.la-fukuoka-pd_hj {
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}

.la-fukuoka-pd_hj img{
  width: 20%;
  margin-right: 60px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(90%/2);
  margin-bottom: 60px;
}

.la-fukuoka-pd_hj img:hover{
  transform:translateY(-8px);
}
.la-fukuoka-pd_hj .asoimg02{
  margin-right: 60px;
}
.la-fukuoka-pd_hj img{
  margin-bottom: 0;
}
.la-fukuoka-pd_hj img:last-child{
  margin-right: 0;
}


/* ----------------------other------------------------------- */
.la-thr_other_hj{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;
}

.la-thr_other_hj .othertit {
  margin-top: 150px;
  margin-bottom: 60px;
}

.la-other-pd_hj {
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}

.la-other-pd_hj img{
  width: 20%;
  margin-right: 60px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(90%/2);
  margin-bottom: 60px;
}

.la-other-pd_hj img:hover{
  transform:translateY(-8px);
}

.la-other-pd_hj .othimg02{
  margin-right: 60px;
}

.la-other-pd_hj img {
  margin-bottom: 0;
}
.la-other-pd_hj img:last-child{
  margin-right: 0;
}


/*-------------- 🪐media🪐-------------------- */

@media (min-width: 340px) and (max-width: 767px) {

  .la-fst_section_hj {
    position: relative;
    width: 100%;
    padding-top: 15px;
  }
  .la-fst_section_hj .la-fst-bg-hj1 {
  position: relative;
  width: 100%;
}

.la-fst_sc_imgs_hj {
  width: 100%;
}
.la-fst_sc_imgs_hj .imgfsttit{
    top: 50%;
    width: 40%;
    left: 50%;
    transform: translate(-50%,-50%);

  }
  .la-fst_sc_imgs_hj .imgfstpic{
    top: 80%;
    width: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .la-fst_section_hj .imgmoneygun{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 27%;
  }

  .la-scd-onetg_hj {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

  .la-scd-onetg_hj .onetarget {
    margin-top: 50px;
    width: 40%;
    
}

.la-onetgbox_hj {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
}

.la-onetgbox_hj .saleimg {
  width: 95%;
}

.la-scd-twotg_hj .twotarget {
    margin-top: 50px;
    width: 40%;
    
}

.la-twotgbox_hj {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
    margin-bottom: 80px;
}

.la-twotgbox_hj .saleimg2 {
  width: 95%;
}

.la-thr_section_hj .la-thr_product_hj {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-block: 50px;
}

.la-thr_product_hj img {
    display: flex;
    width: 35%;
}

.la-thr_osaka_hj .osakatit {
    margin-bottom: 40px;
    width: 23%;
}


.la-osaka-pd_hj {
  width:98%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.la-osaka-pd_hj img {
    width: 45%;
    margin-right: 15px;
    cursor: pointer;
    transition: all 0.5s;
}

.la-thr_sapporo_hj .sapporotit {
    margin-top: 70px;
    margin-bottom: 40px;
    width: 23%;
}

.la-thr_sapporo-pd_hj{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.la-thr_sapporo-pd_hj img{
  width: 45%;
  margin-right: 15px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(88%/2);
  margin-bottom: 30px;
}

.la-thr_sapporo-pd_hj .spr02 {
  margin-right: 0;
}
.la-thr_sapporo-pd_hj .spr03 {
  margin-right: 15px;
}

.la-thr_sapporo-pd_hj .spr04 {
  margin-right: 0;
  margin-bottom: 30px;
}

.la-thr_sapporo-pd_hj .spr05 {
  margin-right: 46%;
  margin-bottom: 0;
}

.la-thr_fukuoka_hj .fukuokatit {
    margin-top: 70px;
    margin-bottom: 40px;
    width: 45%;
}

.la-fukuoka-pd_hj {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.la-fukuoka-pd_hj img{
  width: 45%;
  margin-right: 15px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(88%/2);
  margin-bottom: 30px;
}

.la-fukuoka-pd_hj .asoimg02 {
  margin-right: 0;
}

.la-fukuoka-pd_hj img:last-child {
        margin-right: 47%;
    }
.la-thr_other_hj .othertit {
    margin-top: 70px;
    margin-bottom: 40px;
    width: 28%;
}

.la-other-pd_hj {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.la-other-pd_hj img{
  width: 45%;
  margin-right: 15px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(88%/2);
  margin-bottom: 30px;
}
.la-other-pd_hj .othimg02{
  margin-right: 0;
}

.la-other-pd_hj img:last-child{
margin-bottom: 0;
margin-right: 47%;
}
}

/* ------------------------------------------ */

@media (min-width: 340px) and (max-width:991px) {

#lastautumnpage_hj{
margin-top: 0;
position: relative;
width: 100%;
height: 100%;
}
}

/* ------------------------------------------ */

@media (min-width: 768px) and (max-width: 1023px) {

.la-fst_section_hj {
  position: relative;
  width: 100%;
}
.la-fst_section_hj .la-fst-bg-hj1 {
  position: relative;
  width: 100%;
}

.la-fst_sc_imgs_hj {
  width: 100%;
}
.la-fst_sc_imgs_hj .imgfsttit{
    top: 50%;
    width: 40%;
    left: 50%;
    transform: translate(-50%,-50%);

  }
  .la-fst_sc_imgs_hj .imgfstpic{
    top: 80%;
    width: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

.la-scd-onetg_hj .onetarget {
  margin-top: 50px;
  width: 40%;
  
}

.la-onetgbox_hj {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
}

.la-onetgbox_hj .saleimg {
width: 80%;
}

.la-scd-twotg_hj .twotarget {
  margin-top: 50px;
  width: 40%;
  
}

.la-twotgbox_hj {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
  margin-bottom: 100px;
}

.la-twotgbox_hj .saleimg2 {
width: 80%;
}

.la-thr_section_hj .la-thr_product_hj {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-block: 100px;
}

.la-thr_product_hj img {
  display: flex;
  width: 35%;
}

.la-thr_osaka_hj .osakatit {
  margin-bottom: 60px;
  width: 23%;
}

.la-osaka-pd_hj {
  width:88%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.la-osaka-pd_hj img {
  width: 20%;
  margin-right: 60px;
  cursor: pointer;
  transition: all 0.5s;
  width: calc(90%/2);
}

.la-thr_sapporo_hj .sapporotit {
  margin-top: 100px;
  margin-bottom: 60px;
  width: 23%;
}

.la-thr_sapporo-pd_hj{
display: flex;
width: 90%;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
}

.la-thr_sapporo-pd_hj img{
width: 20%;
margin-right: 60px;
cursor: pointer;
transition: all 0.5s;
width: calc(88%/2);
margin-bottom: 60px;
}

.la-thr_sapporo-pd_hj .spr02 {
margin-right: 0;
}
.la-thr_sapporo-pd_hj .spr03 {
margin-right: 60px;
}

.la-thr_sapporo-pd_hj .spr04 {
margin-right: 0;
margin-bottom: 60px;
}

.la-thr_sapporo-pd_hj .spr05 {
  display: flex;
margin-bottom: 0;
margin-right: 51%;
justify-content: center;
}

.la-thr_fukuoka_hj .fukuokatit {
  margin-top: 100px;
  margin-bottom: 60px;
  width: 45%;
}

.la-fukuoka-pd_hj {
display: flex;
width: 100%;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
}

.la-fukuoka-pd_hj img{
width: 20%;
margin-right: 60px;
cursor: pointer;
transition: all 0.5s;
width: calc(80%/2);
margin-bottom: 60px;
}

.la-fukuoka-pd_hj .asoimg02 {
margin-right: 0;
}

.la-fukuoka-pd_hj img:last-child {
    margin-right: 46%;
}
.la-thr_other_hj .othertit {
  margin-top: 100px;
  margin-bottom: 60px;
  width: 28%;
}

.la-other-pd_hj {
display: flex;
width: 100%;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
}

.la-other-pd_hj img{
width: 20%;
margin-right: 60px;
cursor: pointer;
transition: all 0.5s;
width: calc(80%/2);
margin-bottom: 60px;
}
.la-other-pd_hj .othimg02{
margin-right: 0;
}

.la-other-pd_hj img:last-child{
margin-bottom: 0;
margin-right: 46%;
}
}

@media (min-width: 1024px) {

.la-scd-onetg_hj{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.la-scd-onetg_hj .onetarget{
  margin-top: 150px;
}

.la-onetgbox_hj {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
}

.la-onetgbox_hj .saleimg {
width: 60%;
}

.la-twotgbox_hj .saleimg2 {
width: 60%;
}


.la-scd-twotg_hj{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.la-scd-twotg_hj .twotarget{
  margin-top: 100px;
}

.la-twotgbox_hj{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
  margin-bottom: 140px;
}

}
</style>
