<template>
  <div class="quotationlist">
    <section class="py-1">
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <h2 class="d-flex justify-content-center mt-5" style="font-weight:600">견 적 문 의</h2>

            <hr style="margin:25px 0 0; color:#111">

            <!-- 문의내역 -->
            <div>
              <div class="container">
                
                <div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="col-1">상태</th>
                        <th class="col-3">견적번호</th>
                        <th class="col-1">구분</th>
                        <th class="col-2">신청일</th>
                        <th class="col-3">제목</th>
                        <th class="col-1">응대자</th>
                        <th class="col-1">답변</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- 테스트로 글자만 넣은 것입니다. -->
                      <tr>
                        <th>IQ</th>
                        <th>QT-20230310-1678412765743</th>
                        <th>개인</th>
                        <th>2023-03-10</th>
                        <th>견적문의테스트</th>
                        <th>테스트</th>
                        <th>테스트</th>
                      </tr>
                      <tr>
                        <th>IQ</th>
                        <th>QT-20230310-1678412765743</th>
                        <th>개인</th>
                        <th>2023-03-10</th>
                        <th>견적문의테스트</th>
                        <th>테스트</th>
                        <th>테스트</th>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- 이동버튼 -->
                <nav aria-label="Page navigation">
                  <ul class="pagination justify-content-center">
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>




<style>
.quotationlist .table{
  border-color:transparent; 
}
@media (max-width:767px){
  .quotationlist .table thead th{
    font-size:.95rem
  }
}
@media (max-width:415px){
  .quotationlist .table thead th{
    font-size:.75rem
  }
}
.quotationlist .table thead th{
  padding:20px 0
}

.quotationlist .table tbody th{
  font-size:.85rem;
  font-weight:300;
  max-width:50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding:15px 3px
}


/* 페이지 버튼 */
.quotationlist nav{
  margin-top:5rem
}
.quotationlist .pagination{
--bs-pagination-focus-box-shadow: none
}
.quotationlist .pagination .page-link{
  color:#111;
}
.quotationlist .page-link {
  color: #000; 
  background-color: #fff;
  border: 1px solid #ccc; 
}
.quotationlist .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  font-weight:bold;
  background-color: #333;
  border-color: #333;
}
.quotationlist .page-link:focus, .page-link:hover {
  color: #000;
  background-color: #fafafa; 
  border-color: #ccc;
}
</style>
