export async function genCertiNumber(api, char, gNo, val) {
  let characters = char;
  let length = gNo;
  let randomString = ''
  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  let uEmail = val
  let upd_tempPass = await api("/api/upd_tempPass", {
    param: [
      randomString, uEmail
    ]
  })

  if (upd_tempPass.affectedRows > 0) {
    return randomString;
  }
  else {
    return false;
  }
}
