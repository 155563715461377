<template>
  <div id="yamagucibustour_hj">

    <div class="yama-fst_hj">
      <div class="yama-fstsecbn_hj">
        <img src="../assets/img/yamaguci/fsttit.png" alt="">
        <img src="../assets/img/yamaguci/fstbanner.png" alt="">
        <img src="../assets/img/yamaguci/secbanner.png" alt="">
      </div><!-- yama-fstsecbn_hj -->
    </div> <!-- // yama-fst_hj -->
    <div class="yama-sec_hj">
      <div class="yama-sec-pointtxt_hj">야마구치 투어 POINT 3 <br> ★★★</div>
      <div class="yama-sec-pointimg_hj">
        <img src="../assets/img/yamaguci/pointimg.png" alt="">
        <img src="../assets/img/yamaguci/pickthree.png" alt="">
      </div>

    </div><!-- // yama-sec -->
    <div class="yama-third_hj">
      <div class="yama-sca_hj">
        <img src="../assets/img/yamaguci/sca.png" alt="">
      </div>
      <div class="yama-scrpic_hj">
        <img src="../assets/img/yamaguci/tripscpt.png" alt="">
      </div>

    </div> <!-- yama-third_hj -->
    <div class="yama-bottom-button_hj">
      <a class="yama-gogobtn_hj" href="javascript:void(0)" @click="goToDetail('L-2024312-1710224515956','L')">야마구치 GOGO</a>
    </div> <!-- // yama-bottom-button_hj -->

  </div> <!-- yamagucibustour_hj -->
  </template>
  
  <script>
  export default {
    methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  }
  }
  </script>
  
  <style>
  
  @font-face {
      font-family: 'WAGURITTF';
      src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/2403@1.0/WAGURITTF.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  /* main id */
  #yamagucibustour_hj{
    width: 100%;
    margin: 0 auto;
    margin-top: -56px;
  }
  
  /* yama-fst */

  .yama-fst_hj{
    width: 100%;
    height: 100%;
    background: url(../assets/img/yamaguci/fstsecbg.png) no-repeat;
    background-size: 100%;
    background-color: #E8EFF2;
  }
  .yama-fst_hj .yama-fstsecbn_hj {
    position: relative;
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* top: -165px; */
    justify-content: center;
    align-items: center;
    padding-top: 12%;
}
  .yama-fst_hj .yama-fstsecbn_hj img{
    width: 100%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:first-child{
    width: 80%;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img:nth-child(2){
    margin-top: 174px;
    margin-bottom: 50px;
  }

  /* yama-sec*/

 .yama-sec_hj {
  position: relative;
    width: 100%;
    background: url(../assets/img/yamaguci/thrfobg.png) no-repeat;
    background-size: 100%;
 }
 .yama-sec_hj .yama-sec-pointtxt_hj {
    font-family: 'WAGURITTF';
    text-align: center;
    font-size: 4vw;
    color: #fff;
    padding-top: 20px;
  }
  .yama-sec_hj .yama-sec-pointimg_hj {
    position: relative;
    margin: 0 auto;
    margin-top: 200px;
    width: 40%;
  }
  .yama-sec_hj .yama-sec-pointimg_hj img {
    width: 100%;
  }
  .yama-sec_hj .yama-sec-pointimg_hj img:nth-child(2) {
    margin-top: 265px;
  }

  /* yama-third */

  .yama-third_hj{  
    position: relative;
    width: 100%;
    height: 100%;
    background: url(../assets/img/yamaguci/fivbg.png) no-repeat;
    background-size: 110%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .yama-third_hj .yama-sca_hj{
    position: relative;
    width: 60%;
    padding-top: 200px;
    padding-bottom: 100px;

  }

  .yama-third_hj .yama-sca_hj img {
    width: 100%;
  }
  .yama-third_hj .yama-scrpic_hj {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #8FC9FF;
  }

  .yama-third_hj .yama-scrpic_hj img {
    width: 50%;
  }

  /* yama-bottom-button */

  .yama-bottom-button_hj {
    
    position: relative;
    width: 100%;
    height: 120px;
    background-color: #8FC9FF;
    padding-block: 30px ;
}
.yama-bottom-button_hj .yama-gogobtn_hj {
    display: block;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    width: 24%;
    height: 50px;
    padding-top: 10px;
    background-color: #FFC876;
    border-radius: 30px;
    font-family: 'WAGURITTF';
    font-size: 25px;
    box-shadow: 0px 8px 0px 0px #f0a73a;
    transition: all 0.5s;
    color: #fff;
  }

  .yama-bottom-button_hj .yama-gogobtn_hj:hover{
    box-shadow: 0px 0px 0px 0px #f0a73a;
    background: #fff;
    color: #FFC876;
    transform: translateY(4px);
  }


/* -----------------------media------------------------------- */

@media(max-width: 2560px) and (min-width: 1921px){

  .yama-fst_hj .yama-fstsecbn_hj {
    position: relative;
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* top: -165px; */
    justify-content: center;
    align-items: center;
    padding-top: 12%;
}
  .yama-fst_hj .yama-fstsecbn_hj img{
    width: 100%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:first-child{
    width: 80%;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img:nth-child(2){
    margin-top: 174px;
  
  }
}

@media (max-width: 1920px) and (min-width: 1721px){

  .yama-fst_hj .yama-fstsecbn_hj {
    position: relative;
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* top: -165px; */
    justify-content: center;
    align-items: center;
    padding-top: 12%;
}
  .yama-fst_hj .yama-fstsecbn_hj img{
    width: 100%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:first-child{
    width: 80%;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img:nth-child(2){
    margin-top: 174px;
  
  }
}
@media (max-width: 1720px) and (min-width: 1401px){
  .yama-fst_hj .yama-fstsecbn_hj {
    position: relative;
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* top: -165px; */
    justify-content: center;
    align-items: center;
    padding-top: 11%;
}
  .yama-fst_hj .yama-fstsecbn_hj img{
    width: 100%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:first-child{
    width: 80%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:nth-child(2){
    margin-top: 174px;
  
  }
}

@media (max-width: 1400px) and (min-width:1025px){

  .yama-fst_hj .yama-fstsecbn_hj {
    position: relative;
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* top: -165px; */
    justify-content: center;
    align-items: center;
    padding-top: 12%;
}
  .yama-fst_hj .yama-fstsecbn_hj img{
    width: 100%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:first-child{
    width: 80%;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img:nth-child(2){
    margin-top: 100px;
  
  }
  .yama-sec_hj .yama-sec-pointimg_hj {
    position: relative;
    margin: 0 auto;
    margin-top: 100px;
    width: 40%;
}

/* .yama-third_hj .yama-sca_hj {
    width: 60%;
    padding-top: 58px;
    padding-bottom: 100px;
}

.yama-third_hj .yama-scrpic_hj img {
    width: 55%;
} */

}

@media(max-width: 1024px) and (min-width: 767px){

  
  .yama-fst_hj .yama-fstsecbn_hj {
    position: relative;
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* top: -165px; */
    justify-content: center;
    align-items: center;
    padding-top: 15%;
}
  .yama-fst_hj .yama-fstsecbn_hj img{
    width: 100%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:first-child{
    width: 80%;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img:nth-child(2){
    margin-top: 100px;
  
  }
  .yama-sec_hj .yama-sec-pointtxt_hj {
    padding-top: 20px;
    font-size: 3.5vw;
}
  .yama-sec_hj .yama-sec-pointimg_hj {
    margin-top: 120px;
    width: 42%;
}
  .yama-sec_hj .yama-sec-pointimg_hj img {
    width: 90%;
}
.yama-sec_hj .yama-sec-pointimg_hj img:nth-child(2) {
    margin-top: 47%;
}
.yama-third_hj {
  width: 100%;
  background-size: 110%;
  height: 100%;
}
.yama-third_hj .yama-sca_hj {
    width: 60%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.yama-third_hj .yama-scrpic_hj img {
    width: 400px;
}
.yama-bottom-button_hj .yama-gogobtn_hj {
    display: block;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    width: 240px;
    height: 50px;
    padding-top: 10px;
    background-color: #FFC876;
    border-radius: 30px;
    font-family: 'WAGURITTF';
    font-size: 20px;
    box-shadow: 0px 8px 0px 0px #f0a73a;
    transition: all 0.5s;
    color: #fff;
}
}

@media(max-width: 766px) and (min-width: 375px){
  #yamagucibustour_hj{
    width: 100%;
    margin: 0 auto;
    margin-top: 0px;
  }
  .yama-fst_hj .yama-fstsecbn_hj {
    position: relative;
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* top: -165px; */
    justify-content: center;
    align-items: center;
    padding-top: 15%;
}
  .yama-fst_hj .yama-fstsecbn_hj img{
    width: 100%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:first-child{
    width: 80%;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img:nth-child(2){
    margin-top: 50px;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img {
    width: 98%;
  }
  .yama-sec_hj .yama-sec-pointtxt_hj {
    padding-top: 8px;
    font-size: 3.5vw;
}
  .yama-sec_hj .yama-sec-pointimg_hj {
    margin-top:54px;
    width: 42%;
}
  .yama-sec_hj .yama-sec-pointimg_hj img {
    width: 90%;
}
.yama-sec_hj .yama-sec-pointimg_hj img:nth-child(2) {
    margin-top: 47%;
}
.yama-third_hj {
  background-size: 130%;
  height: 100%;
}
.yama-third_hj .yama-sca_hj {
    display: flex;
    flex-direction: column;
    width: 74%;
    padding-top: 58px;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
}
.yama-third_hj .yama-sca_hj img {
  width: 77%;
}
.yama-third_hj .yama-scrpic_hj img {
    width: 190px;
}
.yama-bottom-button_hj .yama-gogobtn_hj {
    width: 150px;
    height: 30px;
    font-size: 12px;
    padding-top: 6px;
}
}

@media(max-width: 374px) and (min-width: 375px){
  .yama-fst_hj .yama-fstsecbn_hj {
    position: relative;
    margin: 0 auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* top: -165px; */
    justify-content: center;
    align-items: center;
    padding-top: 15%;
}
  .yama-fst_hj .yama-fstsecbn_hj img{
    width: 100%;
  }
  .yama-fst_hj .yama-fstsecbn_hj img:first-child{
    width: 80%;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img:nth-child(2){
    margin-top: 50px;
  
  }
  .yama-fst_hj .yama-fstsecbn_hj img {
    width: 98%;
  }
  .yama-sec_hj .yama-sec-pointtxt_hj {
    padding-top: 8px;
    font-size: 3.5vw;
}
  .yama-sec_hj .yama-sec-pointimg_hj {
    margin-top:54px;
    width: 42%;
}
  .yama-sec_hj .yama-sec-pointimg_hj img {
    width: 91%;
}
.yama-sec_hj .yama-sec-pointimg_hj img:nth-child(2) {
    margin-top: 47%;
}
.yama-third_hj {
  background-size: 130%;
  height: 100%;
}
.yama-third_hj .yama-sca_hj {
    display: flex;
    flex-direction: column;
    width: 74%;
    padding-top: 58px;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
}
.yama-third_hj .yama-sca_hj img {
  width: 77%;
}
.yama-third_hj .yama-scrpic_hj img {
    width: 190px;
}
.yama-bottom-button_hj .yama-gogobtn_hj {
    width: 150px;
    height: 30px;
    font-size: 12px;
    padding-top: 6px;
}
}

</style>
