import axios from 'axios';
import moment from 'moment';
// CORS Middleware
//axios.defaults.baseURL = 'http://211.110.44.112:5055';
//axios.defaults.baseURL = 'http://211.110.44.112';               //real server ip
//axios.defaults.baseURL = 'http://ntabi.co.kr';                    //real server domain
axios.defaults.baseURL = 'https://ntabi.co.kr';                 //secure server domain
//axios.defaults.baseURL = 'https://211.110.44.112';              //secure server ip
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// axios를 공통으로 사용할 수 있는 공통모듈용 메소드
export default {
  methods: {
    async $api(url, data) {
      return (await axios({
        // client에서 server를 호출하는 정보
        method: 'post',
        url,
        data
      }).catch(e => {
        console.log(e);
      })).data;
    },
    // 파일->string파일로 읽기처리
    $base64(file) {
      return new Promise(resolve => {
        var a = new FileReader();
        a.onload = e => resolve(e.target.result);
        a.readAsDataURL(file);
      });
    },
    /** 숫자콤마표시*/
    $currencyFormat(value) {
      // console.log('value' + value)
      return (value + "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    /** 숫자콤마제거*/
    $deCurrencyFormat(value) {
      // console.log('value' + value)
      return (value + "").toString().replace(/,/g, "");
    },
    /** 간편날짜전환 */
    $dateFormat(date) {
      // console.log('mixinsDate', date)
      return moment(date).format("YYYY-MM-DD");
    },
    /** 간편시간표시 */
    $timeFormat(date) {
      // console.log('mixinsDate', date)
      return moment(date).format("HH:mm:ss");
    },
    /** 문자열 개행처리 */
    $stringFormat(text) {
      //return (text+"").replace('\n', '<br />');
      return (text + "").replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    /** 요일표시 */
    $dayOfWeekFormat(text) {
      let week = ["일", "월", "화", "수", "목", "금", "토"];
      let dayOfWeek = week[new Date(text).getDay()];
      return dayOfWeek;
    },
    /** 요일숫자로 표시 */
    $numberOfWeekFormat(text) {
      let number = ["0", "1", "2", "3", "4", "5", "6"];
      let numberOfWeek = number[new Date(text).getDay()];
      return numberOfWeek;
    },
    /** 국문구성체크 */
    $isKoreanOnly(text) {
      const koreanRegex = /^[가-힣]+$/;
      let checkKorean = koreanRegex.test(text);

      return checkKorean
    },
    /**이메일체크 */
    $checkEmail(eMail) {
      const emailRegex = /^(?!.*\.{2})[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return emailRegex.test(eMail);
    },
    /** 모바일번호체크 */
    $checkMobile(mobile) {
      const koreanMobileRegex = /^01[0]\d{3,4}\d{4}$/;
      return koreanMobileRegex.test(mobile);
    },
    /** 날짜기반요일추출 */
    $getDayOfWeek(dateString) {
      const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
      const date = new Date(dateString);
      const dayIndex = date.getDay(); // 0(일요일)부터 6(토요일)까지의 인덱스를 반환

      return daysOfWeek[dayIndex];
    },

    /** 1원단위 절사처리 */
    $truncateToWon(value) {
      return Math.floor(value / 10) * 10
    },

    /** 한국시간으로 변환표시 */
    $korDateFormat(dateString) {
      // const date = new Date(dateString);

      // // 한국 시간대로 변환
      // const options = {
      //   year: 'numeric',
      //   month: '2-digit',
      //   day: '2-digit',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   timeZone: 'Asia/Seoul'
      // };

      // return date.toLocaleString('ko-KR', options);
      const date = new Date(dateString);

      // 한국 시간대로 변환 후 각 요소 추출
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // 원하는 형식으로 포맷팅
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    /** 숫자를 한글로 변환 */
    $numberToKorean(number) {
      const numStr = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
      const units = ['', '만', '억', '조', '경'];
      const smallUnits = ['', '십', '백', '천'];
      let result = '';
      let unitCount = 0;

      if (number === 0) return '영';

      while (number > 0) {
        let unitNumber = number % 10000;
        number = Math.floor(number / 10000);

        let unitStr = '';
        for (let i = 0; i < smallUnits.length; i++) {
          const currentNumber = unitNumber % 10;
          unitNumber = Math.floor(unitNumber / 10);

          if (currentNumber > 0) {
            unitStr = numStr[currentNumber] + smallUnits[i] + unitStr;
          }
        }
        if (unitStr) { // 현재 단위에 해당하는 문자열이 존재하는 경우에만 추가
          result = unitStr + units[unitCount] + result;
        }
        unitCount++; // 다음 더 큰 단위로 이동
      }

      return result;
    }


    /** 초단위 카운트다운 표시 */
    // $countdown(minutes) {
    //   let seconds = minutes * 60;
    //   let timeRemaining = ""

    //   const timer = setInterval(() => {
    //     const minutesRemaining = Math.floor(seconds / 60);
    //     const secondsRemaining = seconds % 60;
    //     timeRemaining = `${minutesRemaining.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`;

    //     seconds--;
    //     if (seconds < 0) {
    //       clearInterval(timer);
    //       timeRemaining = "타이머 종료";
    //     }
    //   }, 1000); // 1초마다 실행
    //   return timeRemaining
    // }

    /** 천아래 절사 후 ~만으로 표시 */
    // formatManCurrency(amount) 
    // {
    //   // 만 단위로 처리하기 위해 10,000으로 나누기
    //   const amountInMan = amount / 10000;

    //   // 만 원 미만일 경우 단순히 숫자와 "만원"을 연결
    //   if (amountInMan < 1) {
    //     return amount
    //   }

    //   // 소수점 첫째 자리까지만 표시
    //   const formattedAmount = amountInMan.toFixed(1);

    //   // 만 원 이상이면서 소수점 이하가 0인 경우
    //   if (formattedAmount.endsWith(".0")) {
    //     return formattedAmount.slice(0, -2)
    //   }

    //   // 만 원 이상이면서 소수점 이하가 0이 아닌 경우
    //   return formattedAmount
    // },

    /** 배열내 중복제거*/
    // $func_Distinct(value, index, self) {
    //   return self.indexOf(value) === index;
    // },
    /** 두배열간 중복제거 */
    // $removeDubofArrays(arr1, arr2)
    // {
    //   return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    // },    
  }
}
