<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-0">
      <div class="p-1">
        <h2 class="text-center"><u>&nbsp;엔 버 스 문 의 리 스 트&nbsp;</u></h2>

        <div class="float-start mb-1">
          <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode"
            v-model="rProcCode" @change="changeProcCode()">
            <option :value="noVal" disabled>진행상태선택</option>
            <option value="A" >전체보기</option>
            <option value="NC" >신규</option>            
            <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
            <option value="MEM" >멤버십</option>
          </select>
        </div>
        <div class="float-end mb-1">
          <input v-model="dataInput" @input="submitAutoComplete" type="text" class="form-control form-control-sm text-primary" placeholder="연락처 또는 문의번호" />
          <!-- <div class="autocomplete disabled"> -->
          <div class="autocomplete disabled">
            <div class="text-success" style="cursor: pointer" :key="i" v-for="(res,i) in result" @click="searchData(res)"><small>{{ res }}</small></div>
          </div>
        </div>

        <div class="form-check mt-2" style="padding-left: 15rem;">
          <input class="form-check-input" type="checkbox" id="cancelReservationCheckbox" v-model="noDispCxl" @change="cxlNoDisplay(noDispCxl)">
          <label class="form-check-label" for="cancelReservationCheckbox">취소문의非표시</label>
        </div>

        <table class="table table-bordered table-striped fs-6">
          <thead class="small">
            <tr class="text-center">
              <th>확인</th>
              <th>상태</th>
              <th>신청일</th>
              <th>사용일</th>
              <th>문의번호</th>
              <th>회원</th>
              <th>이용기간</th>
              <th>가이드</th>
              <th>버스</th>
              <th>성명</th>
              <th>연락처</th>
              <th>결제</th>
              <th>확인자</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(rList, i) in this.reqList" class="text-center">
              <td v-if="rList.checkFlag == 'N'" class="text-danger">N</td>
              <td v-else></td>

              <td>
                <span v-if="rList.inqStatus === 'RC'" style="color:crimson;">{{ rList.procName }}</span>
                <span v-else>{{ rList.procName }}</span>
              </td>

              <td>{{ getDateFormat(rList.insDate) }}</td>
              <td>{{ rList.rentTotDays }}</td>
              <td class="text-primary">
                <a @click="goToNbusInquiryDetail(rList.inqCode);" style="cursor:pointer;">{{ rList.inqCode }}</a>
              </td>
              <td>
                <span :class="{'blue-text': rList.memFlag === 'STD', 'gold-text': rList.memFlag === 'PRE'}">
                  {{ rList.memFlag }}
                </span>
              </td>

              <td>
                {{ getDateFormat(rList.rentStartDate) }} ({{ rList.rentTotDays }}일간)
              </td>

              <td>{{ rList.guideLv }}</td>
              <td>{{ rList.busLv }}</td>

              <td>{{ rList.inqName }}</td>
              <td>{{ rList.inqTel }}</td>

              <td v-if="rList.paidFlag === 'Y'" class="text-danger"> <b>{{ rList.paidFlag }}</b></td>
              <td v-else>N</td>

              <td>{{ rList.checkerName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      nbusInqList: [],            //엔버스문의리스트
      //rProcCode: "",              //조회상태값
      reqList: [],
      noDispCxl: false,             //취소예약비표시

      //검색 및 자동완성
      arrSearchData: [],
      rProc: [],
      result: null,

      //fullPayFlag: ""             //완불여부 (fp: 완불)
    };
  },
  created() {
    this.getNbusInqList();
    this.getReqProcLevel();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },

  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },

    async getNbusInqList(){
      let nbusInqList = await this.$api("/api/nbusInqList")
      if(nbusInqList.length > 0)
      {
        this.reqList = nbusInqList
        
        this.reqList.forEach(e => {
          //회원분류
          if(e.inqId != undefined && e.inqId != "")
          {
            if(e.memLevel == "S")
            {
              e.memFlag = "STD"
            }
            else if(e.memLevel == "P")
            {
              e.memFlag = "PRE"
            }
            else if(e.memLevel == undefined)
            {
              e.memFlag = "Y"
            }
          }
          else
          {
            e.memFlag = ""
          }

          //가이드분류
          if(e.guideFlag == "GG")
          {
            e.guideLv = "일반"
          }
          else if(e.guideFlag == "PG")
          {
            e.guideLv = "전문"
          }
          else if(e.guideFlag == "BG")
          {
            e.guideLv = "통역"
          }
          else
          {
            e.guideLv = ""
          }

          //버스분류
          if(e.busFlag == "luxB")
          {
            e.busLv = "10인승"
          }
          else if(e.busFlag == "micB")
          {
            e.busLv = "마이크로"
          }
          else if(e.busFlag == "midB")
          {
            e.busLv = "중형"
          }
          else if(e.busFlag == "lagB")
          {
            e.busLv = "대형"
          }
          else
          {
            e.busLv = ""
          }
        });
      }
      console.log("nbusInqList:", this.reqList)

      let i=0;
      let arrSearchData = [];
      if(nbusInqList.length > 0) {
        this.reqList = nbusInqList;
        while(i<nbusInqList.length) 
        {
          arrSearchData.push(nbusInqList[i].inqCode, nbusInqList[i].inqTel);
          i++;
        }
      }
      this.arrSearchData = arrSearchData;
    },

    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      //console.log("rProc:",this.rProc);
    },

    //상태소팅
    async changeProcCode() {
      //this.noDispCxl = false

      if(this.rProcCode === 'A') 
      {
        this.getNbusInqList();
      }
      else if(this.rProcCode === 'NC')    //신규정보
      {
        let reqList_tmp1 = await this.$api("/api/nbusInqList", {})
        //console.log("reqList_tmp1:",reqList_tmp1);
        this.reqList = reqList_tmp1.filter(e => {return e.checkFlag === 'N'});

        this.reqList.forEach(e => {
          //회원분류
          if(e.inqId != undefined && e.inqId != "")
          {
            if(e.memLevel == "S")
            {
              e.memFlag = "STD"
            }
            else if(e.memLevel == "P")
            {
              e.memFlag = "PRE"
            }
            else if(e.memLevel == undefined)
            {
              e.memFlag = "Y"
            }
          }
          else
          {
            e.memFlag = ""
          }
        });
      }
      else if(this.rProcCode === 'MEM')    //멤버십
      {
        let reqList_tmp1 = await this.$api("/api/nbusInqList", {})
        this.reqList = reqList_tmp1.filter(e => {return (e.memLevel === 'P' || e.memLevel === 'S')});
        this.reqList.forEach(e => {
          //회원분류
          if(e.inqId != undefined && e.inqId != "")
          {
            if(e.memLevel == "S")
            {
              e.memFlag = "STD"
            }
            else if(e.memLevel == "P")
            {
              e.memFlag = "PRE"
            }
            else if(e.memLevel == undefined)
            {
              e.memFlag = "Y"
            }
          }
          else
          {
            e.memFlag = ""
          }
        });
      }
      else if(this.rProcCode === 'nCxl')    //취소문의 비표시
      {
        let reqList_tmp = await this.$api("/api/nbusInqList", {})
        this.reqList = reqList_tmp.filter(e => {return e.inqStatus != "CX"});
 
        this.reqList.forEach(e => {
          //회원분류
          if(e.inqId != undefined && e.inqId != "")
          {
            if(e.memLevel == "S")
            {
              e.memFlag = "STD"
            }
            else if(e.memLevel == "P")
            {
              e.memFlag = "PRE"
            }
            else if(e.memLevel == undefined)
            {
              e.memFlag = "Y"
            }
          }
          else
          {
            e.memFlag = ""
          }
        });
      }      
      else 
      {
        let reqList_tmp = await this.$api("/api/nbusInqList", {})
        this.reqList = reqList_tmp.filter(e => {return e.inqStatus === this.rProcCode});
        this.reqList.forEach(e => {
          //회원분류
          if(e.inqId != undefined && e.inqId != "")
          {
            if(e.memLevel == "S")
            {
              e.memFlag = "STD"
            }
            else if(e.memLevel == "P")
            {
              e.memFlag = "PRE"
            }
            else if(e.memLevel == undefined)
            {
              e.memFlag = "Y"
            }
          }
          else
          {
            e.memFlag = ""
          }
        });
      }
    },

    //취소문의 비표시
    async cxlNoDisplay(){
      alert(this.noDispCxl)
      if(this.noDispCxl === true)
      {
        this.rProcCode = "nCxl"
        //this.getNbusInqList()
        this.changeProcCode()
        //let reqList_tmp = await this.$api("/api/reqResConsultList2", {})
      }
      else
      {
        this.getNbusInqList();
      }
    },

    //자동완성기능
    submitAutoComplete() {
      const autocomplete = document.querySelector(".autocomplete");
      let result_tmp = [];
      if (this.dataInput) {
        autocomplete.classList.remove("disabled");
        autocomplete.style.display = "block"
        result_tmp = this.arrSearchData.filter((e) => {
          return String(e).match(new RegExp("^" + this.dataInput.toUpperCase(), "i"));
        });
        //결과값 중복제거
        this.result = Array.from(new Set(result_tmp));        
      }
      else {
        autocomplete.classList.add("disabled");
        autocomplete.style.display = "none";
        this.getNbusInqList()
      }
    },
    
    searchData(res) {
      console.log("reqList1:", this.reqList);
      console.log("res:",res);
      let reqList_tmp = this.reqList;

      if(res.substr(0,2) === "NB") {
        this.reqList =  reqList_tmp.filter(e => {return e.inqCode === res});
      }
      else {
        this.reqList =  reqList_tmp.filter(e => {return e.inqTel === res});
      }

      //console.log("reqList_searchData:",this.reqList);
    },    
    goToNbusInquiryDetail(inqCode) {
      this.$router.push({path: '/nbusInquiryInfo_detail', query: {iCode: inqCode}});
      //console.log("oId:", orderId);
    },
    
  }
}
</script>

<style>

.blue-text {
  color: royalblue;
}

.gold-text {
  color: gold;
}

</style>