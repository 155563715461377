
<template>
  <main class="mt-3">
  <div class="container">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center"><u>&nbsp;행  사  생  성&nbsp;</u></h2>
        <div class="text-success text-start fs-6 mb-2">
            <b>[행사정보&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('I','E');">&nbsp;저장</i>&nbsp; ]</b>&nbsp;&nbsp;
            <button type="button" class="btn btn-sm btn-outline-primary align-right" @click="callWinCalc();">
              <i class="bi bi-calculator-fill">계산기이용</i>
            </button>
        </div>
        <table class="table table-bordered border-success align-middle w-100 h-auto">
          <tbody>
              <tr>
                <th scope="row" class="table-secondary col-md-2">행사코드</th>
                <td class="col-md-4 text-primary">{{this.eventCode}}</td>
                <th scope="row" class="table-secondary col-md-2">회원확인</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" class="form-control text-end" ref="txt_userPoint" placeholder="회원이메일" v-model = this.chkEmail>
                    <button type="button" class="btn btn-primary btn-sm me-1" @click="checkEmail();">
                      <font style="font-size:small" class="align-bottom">조회</font>
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>행사명(국문)</th>
                <td class="col-md-4"><input type="text" class="form-control" ref="txt_eventNameKor" v-model="this.eventNameKor"></td>
                <th scope="row" class="table-secondary col-md-1">행사명(영문)</th>
                <td class="col-md-4"><input type="text" class="form-control" ref="txt_eventNameKor" v-model="this.eventNameEng"></td>
              </tr>              
              <tr>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>예약자/연락처/이메일</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" class="form-control form-control-sm" style="font-size:x-small;" ref="txt_reqName" v-model="this.reqName">
                    <input type="text" class="form-control form-control-sm" style="font-size:x-small;" ref="txt_reqTelNo" v-model="this.reqTelNo">
                    <input type="text" class="form-control form-control-sm" style="font-size:x-small;" ref="txt_reqEmail" v-model="this.conEmail">
                  </div>                  
                </td>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>신청일</th>
                <td class="col-md-4">
                  <input type="date" class="form-control" ref="txt_reqDate" v-model="reqDate">
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>출발지/여행국가/지역</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <!-- 출발도시 -->
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_dptCtCode" v-model="dptCtCode">
                      <option :value="cityList[i].baseCode" :key=i v-for="(baseCode,i) in cityList">{{cityList[i].codeNameKor}}</option>
                    </select>
                    <!-- 여행국가 -->
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripNation" v-model="ntCd" @change="changeNationList(ntCd)">
                      <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
                    </select>
                    <!-- 여행지역 -->
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripArea" v-model="arCd">
                      <option :value="areaList[j].baseCode" :key=j v-for="(baseCode,j) in areaList">{{areaList[j].codeNameKor}}</option>
                    </select>
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-1"><i class="bi bi-check text-danger"></i>행사기간</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">개시</span>
                    <input type="date" class="form-control" ref="txt_evtStartDate" v-model="evtStartDate">
                    <span class="input-group-text" id="basic-addon1">종료</span>
                    <input type="date" class="form-control" ref="txt_evtCloseDate" v-model="evtCloseDate">
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">포함사항</th>
                <td class="col-md-4">
                  <textarea class="form-control text-primary" ref="txt_includeContent" id="floatingTextarea" placeholder="490자 이내" style="height:80px" v-model="includeContent" maxlength="499"></textarea>
                </td>
                <th scope="row" class="table-secondary col-md-1">불포함사항</th>
                <td class="col-md-4">
                  <textarea class="form-control text-danger" placeholder="490자 이내" ref="txt_notInCludeContent" id="floatingTextarea" style="height:80px" v-model="this.notIncludeContent" maxlength="499"></textarea>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1" rowspan="2">
                  <i class="bi bi-check text-danger"></i>금액 X 인원
                </th>
                <td class="col-md-4" rowspan="2">
                    <div class="text-success text-start">
                      <i class="bi bi-align-end text-primary"></i> {{this.evtStartDate}} ~ {{this.evtCloseDate}}
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">성인</span>
                      <input type="number" min=0 class="form-control text-end" ref="txt_adultPrice" v-model="adultPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 max="5" class="form-control-sm text-small text-end col-md-2" ref="txt_adultCnt" v-model="adultCnt">
                      <span class="input-group-text">=</span>
                      <input type="number" min=0 class="form-control text-end" ref="txt_totAdultPrice" :value="adultPrice * adultCnt" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">소인</span>
                      <input type="number" min=0 class="form-control text-end" ref="txt_childPrice" v-model="childPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" ref="txt_childCnt" v-model="childCnt">
                      <span class="input-group-text">=</span>
                      <input type="number" min=0 class="form-control text-end" ref="txt_totChildPrice" :value="childPrice * childCnt" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">유아</span>
                      <input type="number" min=0 class="form-control text-end" ref="txt_infPrice" v-model="infPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" ref="txt_infCnt" v-model="infCnt">
                      <span class="input-group-text">=</span>
                      <input type="number" min=0 class="form-control text-end" ref="txt_totInfPrice" :value="infPrice * infCnt" disabled>
                    </div>
                    <div class="input-group mb-1">
                      <span class="input-group-text">할인</span>
                      <input type="number" min=0 class="form-control text-end" ref="txt_discountPrice" v-model="discountPrice">
                      <span class="input-group-text">X</span>
                      <input type="number" min=0 class="form-control-sm text-small text-end col-md-2" ref="txt_discountCnt" v-model="discountCnt">
                      <span class="input-group-text">=</span>
                      <input type="number" min=0 class="form-control text-end" ref="txt_totDiscountPrice" :value="discountPrice * discountCnt" disabled>
                    </div>
                </td>

                <th scope="row" class="table-secondary col-md-2 align-middle">
                  추가사항 / 금액
                  <p class="mt-2">
                    <button type="button" class="btn btn-sm btn-outline-primary align-right" @click="addItem()">
                      추가
                    </button>
                    <!-- <button type="button" class="btn btn-sm btn-outline-primary align-right" @click="funcTest()">
                      TEST
                    </button>-->
                  </p>
                </th>
                <td class="col-md-4 text-start small">
                  <div class="col">
                    <div v-if="this.vCnt <= 20">
                      <div class="input-group mb-1" :id=via :key="via" v-for="via in this.vCnt">
                        <div class="align-self-center col-md-1" v-if="this.vCnt === via">
                          <i class="bi bi-dash-circle-fill text-danger" @click="removeItem(via)"></i>
                        </div>
                        <div class="col">
                          <div class="input-group mb-1">
                            <input type="text" class="form-control" placeholder="추가사항" ref="txt_vatPrice" v-model="addItemName[via]">
                            <input type="number" class="form-control" ref="txt_vatPrice" v-model="addItemPrice[via]">
                            <span class="input-group-text">원</span>&nbsp;&nbsp;
                            <input class="form-check-input my-auto" type="checkbox" v-model="addItemFlag[via]" true-value="Y" false-value="N" id="chb_addItemFlag">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-mb-2">
                  추가마진 / 금액(비율)
                  <br>
                  <small class="text-secondary">(총액기준)</small>
                </th>
                <td class="col-md-4 text-start small">
                  <div class="input-group mb-1">
                    <input type="number" class="form-control text-end" ref="txt_basicMargin" v-model="addMargin">
                    <select class="form-select form-select-md text-end" aria-label=".form-select-md" v-model="addMarginMethod">
                      <option value="A" id="promoMethod">원</option>
                      <option value="P" id="promoMethod">%</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-2">
                  추가할인
                  <p class="mt-2">
                    <button type="button" class="btn btn-sm btn-outline-primary align-right" @click="addDisItem()">
                      추가
                    </button>
                  </p>
                </th>
                <td class="col-md-4 text-start small">
                  <div class="col">
                    <div v-if="this.dCnt <= 20">
                      <div class="input-group mb-1" :name=dia :key="dia" v-for="dia in this.dCnt">
                        <div class="align-self-center col-md-1" v-if="this.dCnt === dia">
                          <i class="bi bi-dash-circle-fill text-danger" @click="removeDisItem(dia)"></i>
                        </div>
                        <div class="col">
                          <div class="input-group mb-1">
                            <input type="text" class="form-control" placeholder="추가할인" ref="txt_addDisName" v-model="addDisName[dia]">
                            <input type="number" class="form-control text-end" ref="txt_addDisPrice" v-model="addDisPrice[dia]">
                            <span class="input-group-text">원</span>&nbsp;&nbsp;
                            <input class="form-check-input my-auto" type="checkbox" v-model="addDisFlag[dia]" true-value="Y" false-value="N" id="chb_addDisFlag">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <th scope="row" class="table-secondary col-md-2">
                  <button type="button" class="btn btn-primary btn-sm me-1" @click="calcCstPrice();">
                    <font style="font-size:small" class="align-bottom">상담가격계산</font>
                  </button>
                </th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_cstPrice" v-model="this.cstPrice" readonly>
                    <span class="input-group-text">원</span>
                  </div>
                  <div class="text-start">
                    &nbsp;<small><small class="text-secondary">-수식: 인원가격+추가금액+추가마진-추가할인-포인트(사용)</small></small>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">부가세(0 ~ 10%)</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" max="10" min="0" class="form-control text-end" ref="txt_vatPrice" v-model="this.vatPrice">
                    <span class="input-group-text">%</span>
                  </div>
                </td>
                <!-- <th scope="row" class="table-secondary col-md-1">키워드</th>
                <td class="col-md-4"><input type="text" class="form-control" v-model="productInfo.pdtTopic"></td> -->
                <th scope="row" class="table-secondary col-md-1">
                  가용포인트&nbsp;
                  <button type="button" class="btn btn-primary btn-sm me-1 align-text-middle" @click="getAvailPoint();" v-if="this.memberFlag === 'Y'">
                    <font style="font-size:small" class="align-bottom"><small>조회</small></font>
                  </button>
                  <p class="text-secondary"><small>(이메일 기준)</small></p>
                </th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="number" class="form-control text-end" ref="txt_userPoint" v-model="this.availPoint" readonly>
                    <!-- <button type="button" class="btn btn-primary btn-sm me-1" @click="payByPoint();" v-if="this.memberFlag === 'Y'">
                      <font style="font-size:small" class="align-bottom">사용</font>
                    </button> -->
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" class="table-secondary col-md-1">기존업무코멘트</th>
                <td class="col-md-4">
                  <textarea class="form-control" ref="txt_oldComment" id="floatingTextarea" style="height:110px" 
                    v-model="this.oldComment" maxlength="1000" disabled></textarea>
                </td>
                <th scope="row" class="table-secondary col-md-1">업무코멘트</th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="250자 이내" ref="txt_newComment" id="floatingTextarea" style="height:110px" 
                    v-model="this.newComment" maxlength="250"></textarea>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-3 row">
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
      </div> -->
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToList()">행사리스트</button>
      </div>
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
      </div> -->
    </div>
  </div>
  </main>
</template>

<script>
//import moment from 'moment';
import {genCode} from '../assets/js/genCode'

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      eventCode: "", //이벤트코드
      eventNameKor: "",
      eventNameEng: "",
      cityList: [],
      nationList: [],
      areaList: [],

      reqDate: "", // 이벤트 생성일
      dptCtCode: "", //출발도시(국내)
      ntCd: "", // 여행국가
      arCd: "", // 여행지역

      evtStartDate: "", // 행사시작일
      evtCloseDate: "", // 행사종료일

      includeContent: "", //포함사항
      notIncludeContent: "", // 불포함사항

      //행사예약자
      reqName: "", // 예약자명
      reqTelNo: "", // 예약자연락처
      reqEmail: "", // 예약자이메일
      conEmail: "", // 연락용이메일

      adultPrice: 0, //성인가격
      childPrice: 0, //소아가격
      infPrice: 0, //유아가격
      discountPrice: 0, //할인가격
      adultCnt: 0, //성인인원
      childCnt: 0, //소아인원
      infCnt: 0, //유아인원
      discountCnt: 0, //할인인원

      //추가사항관련
      addItemUseFlag: "N", //추가사항사용여부
      addItemName: [],
      addItemPrice: [],
      vCnt: 0,
      addItemInfo: {} , //기타옵션
      addItemFlag: [], //기타옵션플래그
      obj_addItemSize: 0, //추가사항 오브젝트크기
      obj_addItem: {}, //추가사항 오브젝트

      //추가할인관련
      addDisUseFlag: "N", //추가할인사용여부      
      addDisName: [], //추가할인명
      addDisPrice: [], //추가할인금액
      dCnt: 0,
      addDisInfo: {} , //추가할인배열값
      addDisFlag: [], //추가할인 플래그
      obj_addDisSize: 0, //추가할인 오브젝트크기
      obj_addDisItem: {}, //추가할인 오브젝트

      vatPrice: 0, //부가세

      cstPrice: 0, //행사금액
      cstPrice_new: 0, //행사금액(최신)
      cstPrice_org: 0, //행사금액(이전)

      //추가마진
      addMargin: 0, //추가마진
      addMarginMethod: "P", //추가마진형태

      //포인트정보
      availPoint: 0, //사용가능포인트

      oldComment: "", //이전코멘트
      newComment: "", //최신코멘트

      evtTransFlag: "N", //교통편 여부

      pniUseFlag: "N", //Pni 저장여부

      personCnt: 0, //총인원(화면상)

      memberFlag: "N", //회원여부
      //memFlag: "" //데이타처리용 회원여부
      chkEmail: "" //회원확인용 이메일
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() 
  {
    console.log("USER:",this.user);
    //this.getEventInfo();
    this.getCityList();
    this.getNationList();
    this.getAreaList();
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    /** 윈도우즈 계산기호출*/
    callWinCalc() 
    { 
      window.open('Calculator: ');
    },
    /** 회원여부조회(이메일기준) */
    async checkEmail()
    {
      let checkMemEmail = await this.$api("/api/checkMemEmail", {param: [this.chkEmail]});
      console.log("checkMemEmail:",checkMemEmail)
      
      if(checkMemEmail.length > 0)
      {
        let k_name = ""
        let k_tel = ""

        this.memberFlag = "Y";
        this.reqEmail = this.chkEmail;
        this.conEmail = this.chkEmail;
        //인적정보

        k_name = (!checkMemEmail[0].uNickName) ? "" : checkMemEmail[0].uNickName;

        let k_telTmp = checkMemEmail[0].alimCallC;

        if(k_telTmp == undefined)
        {
          k_telTmp = ""
        }
        else if(k_telTmp.charAt(0) === '+')
        {
          k_telTmp = k_telTmp.replace("+82 ", "0")
        }
        k_tel = k_telTmp.replace(/-/g,"")

        this.reqName = k_name
        this.reqTelNo = k_tel
        this.$swal.fire("","회원확인이 되었습니다.","info");
      }
      else
      {
        this.memberFlag = "N";
        this.reqEmail = "";
        this.conEmail = "";
        this.chkEmail = "";
        this.$swal.fire("","회원으로 확인되지 않습니다","warning");
        return false;
      }
    },
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      if(cityList.length > 0) {
        this.cityList = cityList;
      }
      //console.log("nationList",nationList);
    },    
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    async getAreaList() {
      this.areaList_tmp = await this.$api("/api/areaList", {});
      //console.log("areaList_tmp:",this.areaList_tmp)
    },
    changeNationList(ntCd) {
      //this.nationCode = this.productInfo.baseCode;
      //alert("ntCd:" + ntCd)
      this.viewAreaList(ntCd);
    },
    viewAreaList(param1){
      //console.log("selectedNation:", param);
      //this.getAreaList();
      this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode == param1});
      //console.log("area:", this.areaList);
    },

    /** 배열추가항목처리 */
    addItem() {
      this.vCnt++;
      if(this.vCnt > 20) {
        return this.$swal.fire("","항목추가는 20까지입니다.","error");
      }
    },
    /** 배열 미선택항목 삭제 */
    removeItem(val) {
      this.vCnt--;
      // this.addItemFlag[val] = undefined;
      // this.addItemName[val] = undefined;
      // this.addItemPrice[val] = undefined;
      document.getElementById(val).remove();
      this.addItemFlag.splice(val);
      this.addItemName.splice(val);
      this.addItemPrice.splice(val);
      //console.log("addItemName:",this.addItemName,"addItemPrice:",this.addItemPrice);
    },

    //추가할인처리
    addDisItem() {
      this.dCnt++;
      if(this.dCnt > 20) {
        return this.$swal.fire("","항목추가는 20까지입니다.","error");
      }
    },
    /** 배열값 제거 querySelector */
    removeDisItem(dal) {
      this.dCnt--;
      // this.addDisName[dal] = undefined;
      // this.addDisPrice[dal] = undefined;
      document.querySelector("div[name=dal]")
      this.addDisFlag.splice(dal);
      this.addDisName.splice(dal);
      this.addDisPrice.splice(dal);
      console.log("addDisFlag:",this.addDisFlag,"addDisName:",this.addDisName);
    },
  /////////////상담가격계산(터미널이용료/유류할증료 별도표시)
    /** 여정기간 중 상품가격합산식 */
    calcCstPrice() 
    {
      //alert("Calc!");
      let aduPrice = 0;
      let chiPrice = 0;
      let infPrice = 0;
      let disPrice = 0;
      let pdtPrice = 0;

      //여행참가인원수
      let personCnt = (this.adultCnt*1 + this.childCnt*1 + this.infCnt*1 + this.discountCnt*1);
      this.personCnt = personCnt*1;
      console.log("personCnt:",personCnt);

      //1. 권종수 기준가격 계산
      aduPrice = (this.adultPrice*1) * (this.adultCnt*1);
      chiPrice = (this.childPrice*1) * (this.childCnt*1);
      infPrice = (this.infPrice*1) * (this.infCnt*1);
      disPrice = (this.discountPrice*1) * (this.discountCnt*1);
      pdtPrice = (aduPrice*1) + (chiPrice*1) + (infPrice*1) + (disPrice*1);
      console.log("pdtPrice1:",pdtPrice);

      //2. 추가사항적용
      if(this.addItemName.length-1 <= 0) // 신규추가사항이 없는 경우
      {
        this.addItemUseFlag = 'N';
      }      
      else // 추가사항이 있는 경우
      {
        this.addItemUseFlag = 'Y';
        let arr_addItemName = [];
        let arr_addItemPrice = [];
        let arr_addItemFlag = [];
        let j = 1;

        while(j < this.addItemName.length)
        {
          if(this.addItemName[j] != "")
          {
            arr_addItemName.push(this.addItemName[j]);
            arr_addItemPrice.push(this.addItemPrice[j]);
            arr_addItemFlag.push(this.addItemFlag[j]);
          }
          j++;
        }
        console.log("addItemName:",arr_addItemName,"addItemPrice:",arr_addItemPrice,"addItemFlag:",arr_addItemFlag);
        
        //추가사항 리스트
        let obj_addItem = new Object();
        obj_addItem.addItemName = arr_addItemName;
        obj_addItem.addItemPrice = arr_addItemPrice;
        obj_addItem.addItemFlag = arr_addItemFlag;
        console.log("obj_addItem:",obj_addItem);

        this.obj_addItem = obj_addItem;

        let obj_addItemSize = Object.keys(obj_addItem.addItemName).length;
        this.obj_addItemSize = obj_addItemSize;

        let k = 0;
        while(k<obj_addItemSize)
        {
          if(obj_addItem.addItemFlag[k] === "Y")
          {
            pdtPrice += (obj_addItem.addItemPrice[k]*1);
          }
          k++;
        }
        //pdtPrice = aduPrice*1 + chiPrice*1 + infPrice*1 + disPrice*1;
        console.log("pdtPrice2:",pdtPrice);
      }

      //3.추가마진 적용
      //console.log("addMargin:",this.addMargin);
      //let pdtPriceMargin = 0;
      if(this.addMarginMethod ==="A")
      {
        pdtPrice = (pdtPrice*1) + (this.addMargin*1);
        //pdtPriceMargin = this.addMargin*1
      }
      else
      {
        if(this.addMargin*1 > 0)
        {
          pdtPrice = pdtPrice*1 + (pdtPrice*1 * ((this.addMargin*1)/100));
        }
        else
        {
          pdtPrice = pdtPrice*1 + 0;
          //pdtPriceMargin = 0;
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
      }
      //pdtPrice = pdtPrice*1 + pdtPriceMargin*1;

      //4. 추가할인적용
      if(this.addDisName.length-1 <= 0) // 신규추가할인이 없는 경우
      {
        this.addDisUseFlag = 'N';

      }      
      else(this.addDisName.length > 0) // 추가할인이 있는 경우
      {
        this.addDisUseFlag = 'Y'; // 추가할인사용여부
        let arr_addDisName = [];
        let arr_addDisPrice = [];
        let arr_addDisFlag = [];
        let j = 1;

        while(j < this.addDisName.length)
        {
          arr_addDisName.push(this.addDisName[j]);
          arr_addDisPrice.push(this.addDisPrice[j]);
          arr_addDisFlag.push(this.addDisFlag[j]);
          j++;
        }
        //console.log("addItemName:",arr_addItemName,"addItemPrice:",arr_addItemPrice,"addItemFlag:",arr_addItemFlag);
        
        //추가할인 리스트
        let obj_addDisItem = new Object();
        obj_addDisItem.addDisName = arr_addDisName;
        obj_addDisItem.addDisPrice = arr_addDisPrice;
        obj_addDisItem.addDisFlag = arr_addDisFlag;

        //console.log("obj_addDisItem:",obj_addDisItem);
        this.obj_addDisItem = obj_addDisItem;

        let obj_addDisItemSize = Object.keys(obj_addDisItem.addDisName).length;
        this.obj_addDisItemSize = obj_addDisItemSize;
        // console.log("obj_addItemSize:",obj_addItemSize);
        // console.log("obj_addItem:",obj_addItem);

        let k = 0;
        while(k<obj_addDisItemSize)
        {
          if(obj_addDisItem.addDisFlag[k] === "Y")
          {
            pdtPrice -= (obj_addDisItem.addDisPrice[k]*1);
          }
          k++;
        }
        //pdtPrice = aduPrice*1 + chiPrice*1 + infPrice*1 + disPrice*1;
        console.log("pdtPrice:",pdtPrice);
      }

      //5.부가세 적용
      let vatR = 0;
      //console.log("vat:R",vatR);
      if(this.vatPrice > 0) 
      {
        vatR = (pdtPrice*1) * (this.vatPrice*1/100);
      }
      else
      {
        vatR = 0;
      }
      //this.vatPrice = vatR;
      pdtPrice = pdtPrice*1 + vatR*1;

      this.cstPrice = pdtPrice*1;
    },
    async getAvailPoint() 
    {
      let totalPoint = await this.$api("/api/userTotalPoint", {param: [this.reqEmail]});
      console.log("totalPoint:",totalPoint)

      let unavailPoint = await this.$api("/api/userUnavailablePoint", {param: [this.reqEmail]});
      console.log("unavailPoint:",unavailPoint)

      //let availPoint = 0;
      if(totalPoint.length > 0) 
      {
        //availPoint = (totalPoint[0].totPoint*1) - (unavailPoint[0].unavilpoint*1);
        //console.log("availPoint:",availPoint)
        
        //적립
        let sumA = totalPoint.filter((itme) => {return itme.useFlag === 'A'})
        sumA = (sumA.length > 0) ? sumA[0].total_A : 0;

        //사용
        let sumU = totalPoint.filter((itme) => {return itme.useFlag === 'U'})
        sumU = (sumU.length > 0) ? sumU[0].total_U : 0;

        //사용대기
        let sumS = totalPoint.filter((itme) => {return itme.useFlag === 'S'})
        sumS = (sumS.length > 0) ? sumS[0].total_S : 0;

        //적립대기
        let sumW = totalPoint.filter((itme) => {return itme.useFlag === 'W'})
        sumW = (sumW.length > 0) ? sumW[0].total_W : 0;
        console.log(sumA,"/", sumU,"/", sumS,"/", sumW)

        //조회시점 가용포인트
        this.availPoint = (sumA*1 + sumW*1) - (sumU*1 + sumS*1);
      }
      else
      {
        this.availPoint = 0;
      }
    },
    // 가용포인트 사용처리
    payByPoint()
    {
      if(!this.cstPrice || this.cstPrice == 0) 
      {
        this.$swal.fire("","결제할 금액이 없거나 0원입니다.","warning");
        return;
      }
      else
      {
        this.$swal.fire({
        icon: 'question',
        text: '포인트를 사용하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '사용',
        cancelButtonText: '나중에'
        }).then(async (result) => 
        {
          if (result.isConfirmed) 
          {
            //포인트 사용처리(포인트결제 한도없음)
            let availPoint = this.availPoint
            let amtAfterPayingPoint =  this.cstPrice*1 - availPoint*1

            if(amtAfterPayingPoint > 0)
            {
              this.paidPoint = availPoint
              this.availPoint = 0
              this.cstPrice = amtAfterPayingPoint;
              //this.paymentInFull = "Y" //완납여부
            }
            else
            {
              this.paidPoint = this.cstPrice
              this.availPoint = availPoint - this.cstPrice 
              this.cstPrice = 0
              this.paymentInFull = "Y" //완납여부
            }
          }
        })
      }
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },

    goToList() {
      this.$router.push({path: '/eventList'});
    },
    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },

    //행사번호생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_evCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","행사번호 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },

    dataBaseTransaction(p1,p2){
      if(p1==="I" && p2==="E") 
      { //행사 생성처리
        //console.log("memberFlag:",this.memberFlag);
        this.$swal.fire({
        icon: 'question',
        text: '행사상품을 생성합니까?',
        showCancelButton: true,
        confirmButtonText: '생성',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            this.cstPrice_org = this.cstPrice;
            this.cstPrice_new = this.cstPrice;

            //let timeStamp = "";
            let today = new Date();
            let yyyy = today.getFullYear();
            let mm_tmp = today.getMonth()+1;
            let dd_tmp = today.getDate();
            let hour = today.getHours();
            let min = today.getMinutes();
            let sec = today.getSeconds();
            
            let mm = "";
            if(mm_tmp < 10) {mm = "0" + mm_tmp}
            else mm = mm_tmp;

            let dd = "";
            if(dd_tmp < 10) {dd = "0" + dd_tmp}
            else dd = dd_tmp;
            
            // let ymd = `${yyyy}${mm}${dd}`

            // timeStamp = today.getTime(); // 행사코드에 들어갈 타임스탬프
            let catCode = "EV-"+this.arCd+"-"
            //this.eventCode = catCode+"-"+ymd+"-"+timeStamp; //행사코드
            let evCode = await this.genCode(catCode)
            this.eventCode = evCode //행사코드
            let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;

            let reqStatus = "IQ"; //진행상태

            let oldComment = ""; 
            let newComment = "";
            oldComment = this.oldComment;
            newComment = this.newComment;
            let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newComment;
            //console.log("insComment:",insComment);

            if(this.memberFlag === 'N') //비회원 상담요청처리
            {
              //this.memFlag = "N";
              let insResNonMember = await this.$api("/api/insResNonMember", {param: [
                this.eventCode,this.reqEmail,this.reqTelNo,this.reqName,this.eventCode
              ]});
              //console.log("insResNonMember:",insResNonMember);
              if(Object.keys(insResNonMember)[0] === 'error') 
              {
                this.$swal.fire('상담요청등록오류','죄송하지만 재요청을 해주세요<br>연속오류 발생 시 연락처<br> 1660-4602)', 'error')
                return false;
              }
            }
            let insEvent_m = await this.$api("/api/insEvent_m", {param: 
              [
                this.eventCode, this.eventNameKor, this.eventNameEng, this.reqName, this.reqTelNo, this.reqEmail, this.reqDate, this.dptCtCode, this.ntCd, this.arCd, this.evtStartDate, this.evtCloseDate, this.includeContent, this.notIncludeContent, this.adultCnt, this.childCnt, this.infCnt, this.discountCnt, this.adultPrice, this.childPrice, this.infPrice, this.discountPrice, this.addItemUseFlag, this.addMargin, this.addMarginMethod, this.addDisUseFlag, this.cstPrice_new, this.cstPrice_org, this.vatPrice, this.payPoint, insComment, this.pniComent, this.evtTransFlag, reqStatus, this.pniUseFlag, this.user.handlerId, this.memberFlag, this.conEmail
              ]
            });
            if(Object.keys(insEvent_m)[0] === 'error') 
            {
              this.$swal.fire('', '오류발생(insEvent_m)<br>시스템 관리자에게 문의바랍니다.', 'error')
              return false;
            }
            else
            {
              //추가사항 존재시 처리
              if(this.addItemUseFlag === "Y")
              {
                console.log("obj_addItem:",this.obj_addItem);

                let k = 0;
                let res_addItem = "";
                while(k<this.obj_addItemSize)
                {
                  let insAddEventItem = await this.$api("/api/insAddEventItem", {param: 
                    [
                      this.eventCode, k, this.obj_addItem.addItemName[k], this.obj_addItem.addItemPrice[k], this.obj_addItem.addItemFlag[k], this.user.handlerId
                    ]
                  });
                  if(Object.keys(insAddEventItem)[0] === 'error')
                  {
                    res_addItem = "error";
                    //console.log(res_addItem)
                    break;
                  }
                  k++;
                }
                if(res_addItem === "error")
                {
                  this.$swal.fire('', '오류발생(insAddEventIetm)<br>시스템 관리자에게 문의바랍니다.', 'error')
                  return false;
                }
              }

              //추가할인 존재시 처리
              if(this.addDisUseFlag === "Y")
              {
                console.log("obj_addDisItem:",this.obj_addDisItem);

                let m = 0;
                let res_addDis = "";
                while(m<this.obj_addDisItemSize)
                {
                  // console.log("obj_addDisItem.addDisFlag:",this.obj_addDisItem[m].addDisFlag);
                  // console.log("obj_addDisItem.addDisName:",this.obj_addDisItem[m].addDisName);
                  // console.log("obj_addDisItem.addDisPrice:",this.obj_addDisItem[m].addDisPrice);
                  let insAddDiscountItem = await this.$api("/api/insAddDiscountItem", {param: 
                    [
                      this.eventCode, m, this.obj_addDisItem.addDisName[m], this.obj_addDisItem.addDisPrice[m], this.obj_addDisItem.addDisFlag[m], this.user.handlerId
                    ]
                  });
                  if(Object.keys(insAddDiscountItem)[0] === 'error')
                  {
                    res_addDis = "error";
                    //console.log(res_addItem)
                    break;
                  }
                  m++;
                }
                if(res_addDis === "error")
                {
                  this.$swal.fire('', '오류발생(insAddEventIetm)<br>시스템 관리자에게 문의바랍니다.', 'error')
                  return false;
                }
              }

              //기본PNI 등록
              let i = 0;
              let res = "";
              while(i<this.personCnt)
              {
                let insDefaultPNI_evt = await this.$api("/api/insDefaultPNI_evt", {param: [this.eventCode,i,'D','Y']});
                //console.log("insDefaultPNI:",insDefaultPNI);
                if(Object.keys(insDefaultPNI_evt)[0] === 'error') 
                {
                  res = "error";
                  break;
                }
                else
                {
                  res = "ok";
                }
                i++;
              }
              if(res == "error")
              {
                this.$swal.fire('', '오류발생(Default PNI making)<br>시스템 관리자에게 문의바랍니다.', 'error')
                return false;
              }
              else
              {
                this.$swal.fire('', '행사생성(기본정보)이 완료되었습니다', 'success');
                this.goToList();
              }
            }
          }
        })
      }
    }
  }
}
</script>