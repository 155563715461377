<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span @click="$emit('close')" class="close">&times;</span>
      <p class="fs-4 bold">[ 입금내역분할 ]</p>
      
      <div class="text-dark text-start fs-6 mb-2">
        <b>[ 입금정보 <font class="text-secondary" style="font-size: small">({{ this.exDepositNo }})</font> ] </b>
      </div>
      <table class="table table-bordered border-secondary align-middle w-100 h-auto text-center" style="font-size: smaller;">
        <thead>
          <tr class="table-secondary border-secondary">
            <th class="col-1">입금일</th>
            <th class="col-2">입금자</th> 
            <th class="col-2">입금액 (원)</th>
            <th class="col-3">입금내역</th>
            <th class="col-1">입금은행</th>
            <th class="col-4">메모사항</th>
          </tr>
          <tr>
            <td>
              {{this.getDateFormat(this.exdepositInfo.depositDate) }}
            </td>
            <td>
              {{ this.exdepositInfo.depositor }}
            </td>
            <td>
              {{ this.getCurrencyFormat(this.exdepositInfo.depositAmt) }}
            </td>
            <td>
              {{ this.exdepositInfo.depositDetail }}
            </td>
            <td>
              {{ this.exdepositInfo.finName }}
            </td>
            <td>
              {{ this.exdepositInfo.depositMemo }}
            </td>
          </tr>
        </thead>
      </table>
      
      <div class="text-primary text-start fs-6 mb-2">
          <b>[ 행사검색 ]</b> &nbsp;&nbsp;<i style="font-size: small;">※분할가능잔액: <b class="text-danger">{{ this.getCurrencyFormat(this.disibleDepositAmt) }}</b>원</i>
      </div>
      <table class="table table-bordered border-success w-100 h-auto text-center" style="font-size: smaller">
        <tbody>
          <tr>
            <th class="table-primary col-md-1 align-middle">
              행사명 <font style="font-size: smaller; color: darkolivegreen">(자동완성형)</font>
              <p class="mt-2">
                <button type="button" class="btn btn-secondary btn-sm me-1" @click="simpleSplit()">단순분할</button> 
              </p>
              <p class="text-primary">
                일괄단순분할
                <input type="file" class="btn btn-primary btn-sm" ref="searchExcel" style="font-size:small" @change="handleFileUpload">
              </p>
            </th>
            <td class="col-md-7" v-if="this.viewSimpleSplit === 'N'">
              <input v-model="dataInput" @input="submitAutoComplete" type="text" class="form-control form-control-sm" placeholder="행사명(※ 정산완료된 행사는 검색제외)" />
              <div class="autocomplete disabled">
                <div class="text-start" style="cursor: pointer" :key="i" v-for="(res,i) in this.result" @click="searchDealData(res[1])">
                  <div class="mt-2">&nbsp;&nbsp;
                    <font style="color:darkblue">{{ res[0] }}</font> 
                    <font style="color:darkgray; font-size:smaller">({{ res[1] }})</font>
                  </div>
                </div>
              </div>
            </td>

            <td class="col-md-4 fs-6 text-success" v-if="this.viewSplit === 'Y' && this.viewSimpleSplit === 'N'">
              <div class="d-flex align-items-between">
                <div>
                  [ 금액분할 ] &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div class="form-check form-switch justify-content-end">
                  <input class="form-check-input align-bottom" type="checkbox" role="switch" id="total" v-model="this.totalCheck" true-value="Y" false-value="N">
                  <label class="form-check-label" for="total" style="font-size: small; color:blue">전액({{ this.totalCheck }})</label>
                </div>
              </div>  
              <div class="mt-1 input-group"> 
                <input type="text" v-model="this.splitDepositAmt" class="form-control text-end text-small w-auto">&nbsp;
                <button type="button" class="btn btn-outline-danger btn-sm" @click="depositSplit(this.dealCode,'GS');">분할입력</button>
              </div>
              
              <div class="mt-1">
                <input type="text" v-model="this.splitMemo" class="form-control resize-input" placeholder="메모사항(200자 이내)" style="font-size: smaller;" maxlength="200">&nbsp;
              </div>
            </td>

            <td class="col-md-4 fs-6 text-success" v-if="this.viewSimpleSplit === 'Y'">
              <div class="d-flex align-items-between">
                <div>
                  [ 단순분할 ] &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <!-- <div class="form-check form-switch justify-content-end">
                  <input class="form-check-input align-bottom" type="checkbox" role="switch" id="total" v-model="this.totalCheck" true-value="Y" false-value="N">
                  <label class="form-check-label" for="total" style="font-size: small; color:blue">전액({{ this.totalCheck }})</label>
                </div> -->
              </div>  
              <div class="mt-1 input-group"> 
                <input type="text" v-model="this.splitDepositAmt" class="form-control text-end text-small w-auto">&nbsp;
                <button type="button" class="btn btn-outline-primary btn-sm" @click="depositSplit('','SS');">단순분할</button>
              </div>
              
              <div class="mt-1">
                <input type="text" v-model="this.splitMemo" class="form-control resize-input" placeholder="메모사항(200자 이내)" style="font-size: smaller;" maxlength="200">&nbsp;
              </div>
            </td>            
          </tr>
        </tbody> 
      </table>

      <div class="text-danger text-start fs-6 mb-2">
        <b>[ 분할내역 ]</b>
      </div>
      <table class="table table-bordered border-secondary align-middle w-100 h-auto text-center" style="font-size: smaller;">
        <thead>
          <tr class="table-danger border-secondary">
            <th class="col-1">확정</th>
            <th class="col-1">분할액(원)</th>
            <th class="col-3">행사명</th>
            <th class="col-1">분할일자</th>
            <th class="col-1">처리자</th>
            <th class="col-4">메모사항</th>
            <th class="col-1" v-if="this.user.handlerGrade <= 5">-</th>
          </tr>
          <tr :key=i v-for="(es, i) in this.exdepositSplitHistory">
            <td class="text-danger" v-if="es.exFixFlag === 'Y'">Y</td>
            <td class="col-1" style="font-weight: bold; color:red" v-else-if="es.dealStatus === 'SE'">정산마감</td>
            <td v-else></td>
          
            <td class="text-end">
              {{ this.getCurrencyFormat(es.splitDepositAmt) }}
            </td>

            <td v-if="es.dealCode != '' && es.dealCode != null">
              {{ es.dealName }}
            </td>
            <td v-else-if="(es.dealCode == '' || es.dealCode ==  null) && es.simpleName != undefined">
              {{ es.simpleName }}
            </td>
            <td v-else>
            </td>

            <td>
              {{this.getDateFormat(es.splitDate) }}
            </td>
            <td>
              {{ es.spName }}
            </td>
            <td class="text-start">
              {{ es.splitMemo }}
            </td>
            <td v-if="this.user.handlerGrade <= 5">
              <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="changeFixFlag(es.exSubNo, 'Y')">
                <small><small>확정</small></small>
              </button>
              <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="changeFixFlag(es.exSubNo, 'N')">
                <small><small>취소</small></small>
              </button>
            </td>            
          </tr>
        </thead>
      </table>

    </div>
  </div>



</template>

<script>
import * as XLSX from 'xlsx'
import moment from 'moment'

export default {
  props: ['showModal', 'modalContent'],
  data() {
    return {
      exdepositInfo: [],          //입금내역정보
      exdepositSplitHistory: [],  //입금분할내역
      exDepositNo: "",            //내역코드
      dealName: "",               //행사제목
      dealData: [],               //행사정보

      result: null,               //행사명 검색결과

      viewSplit: "N",             //분할창 표시여부
      dataInput: "",              //자동완성변수

      dealCode: "",               //행사코드
      splitDepositAmt: 0,         //분할액
      splitMemo: "",              //분할메모

      totalCheck: "N",            //전액분할플래그
      viewSimpleSplit: "N",       //단순분할표시플래그

      disibleDepositAmt: 0,       //분할가능한데파짓잔액

      depCode: "",                //입금처코드
      //excelUpData: [],            //엑셀업로드데이타
      arr_excelUpData: [],        //축차저장된 엑셀업로드데이타
    }
  },
  created() {
    this.user = this.$store.state.user
    console.log("user:",this.user)

    this.exDepositNo = this.modalContent
    this.getExdepositInfo()
    this.getRequestConsultList();

    let today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")

  },
  watch: {
    //부모에서 넘어오는 props와 로컬변수 동기화
    modalContent(newVal)
    {
      this.exDepositNo = newVal
    },

    //금액분할영역 표시추적
    dataInput(newVal)
    {
      if(newVal === 'Y')
      {
        this.viewSplit = "Y"
      }
      else if (newVal == "")
      {
        this.viewSplit = "N"
        this.totalCheck = "N"
      }
    },

    //전액분할여부추적
    totalCheck(newVal)
    {
      if(newVal === 'Y')
      {
        this.splitDepositAmt = this.exdepositInfo.depositAmt
      }
      else
      {
        this.splitDepositAmt = 0
      }
    }
  },
  methods: {
    //mixins 공통함수
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value)
    {
      return this.$currencyFormat(value);
    },
    getDeCurrencyFormat(value)
    {
      return this.$deCurrencyFormat(value);
    },
    getDayOfWeekFormat(text)
    {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text)
    {
      return this.$numberOfWeekFormat(text);
    },

    //입금내역정보
    async getExdepositInfo(){
      //외부입금기록정보(분할내역)
      let exdepositInfo = await this.$api("/api/getExdepositInfo", {param:[ this.exDepositNo ]})

      if(exdepositInfo.length > 0)
      {
        this.exdepositInfo = exdepositInfo[0]
      }

      //console.log("exdepositInfo:", this.exdepositInfo)

      //입금분할내역
      let exdepositSplitHistory = await this.$api("/api/getExdepositSplitHistory", {param:[ this.exDepositNo ]})

      if(exdepositSplitHistory.length > 0)
      {
        this.exdepositSplitHistory = exdepositSplitHistory

        //분할가능잔액계산
        let i = 0
        let sumOfexDepositAmt = 0
        let disibleDepositAmt = 0   //분할가능한데파짓

        while(i < exdepositSplitHistory.length)
        {
          this.exdepositSplitHistory[i].splitDepositAmt = Math.trunc(this.exdepositSplitHistory[i].splitDepositAmt)
          sumOfexDepositAmt += this.exdepositSplitHistory[i].splitDepositAmt
          i++
        }

        disibleDepositAmt = this.exdepositInfo.depositAmt*1 - sumOfexDepositAmt*1

        this.disibleDepositAmt = disibleDepositAmt
      }
      else
      {
        this.disibleDepositAmt = this.exdepositInfo.depositAmt*1 - 0
      }
      console.log("exdepositSplitHistory:", this.exdepositSplitHistory)
    },

    //일괄분할처리
    handleFileUpload(event) {
      console.log(event)
      const file = event.target.files[0];

      if(!file) return

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        this.processExcel(workbook);
      };

      reader.readAsBinaryString(file);
    },

    async processExcel(workbook) {
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      //const jsonData = XLSX.utils.sheet_to_json(worksheet);

      let startLine = 0
      // if(this.depCode === "NSM")    //네이버스마트스토어
      // {
      //   startLine = 0
      // }
      // else if(this.depCode === "BKB")
      // {
      //   startLine = 6
      // }
      // else
      // {
      //   startLine = 5                   //부산은행
      // }      

      // 날짜 형식 옵션을 사용하여 JSON 변환
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        raw: false, // 날짜 데이터를 숫자가 아닌 텍스트로 변환
        dateNF: 'yyyy-mm-dd HH:MM:SS', // 날짜 형식 지정
        range: startLine
      });
      console.log("jsonData:",jsonData);

      if(jsonData.length > 500)
      {
        this.$swal.fire("","일괄입력데이타의 최대값은 500건입니다.", "warning")
        return false
      }
      else
      {
        this.$swal.fire("","데이타의 양에 따라 시간이 소요될 수 있습니다.<br>종료안내까지 대기하세요.", "info")
      }

      let i=0
      let simpleSplitAmt = 0
      let arr_excelUpData = []    //excelUpData를 배열로 변경

      while(i < jsonData.length) 
      {
        let excelUpData = {}      //엑셀업로드 데이타객체

        simpleSplitAmt +=(jsonData[i].정산예정금액*1)

        excelUpData.simpleName = jsonData[i].상품명
        excelUpData.splitMemo = jsonData[i].구매자명
        excelUpData.splitDepositAmt = jsonData[i].정산예정금액

        arr_excelUpData.push(excelUpData)        //add the excelUpData to array
        i++
      }
      this.arr_excelUpData = arr_excelUpData

      if(this.disibleDepositAmt != simpleSplitAmt)
      {
        this.$swal.fire("","업로드된 총액이 입금가능액과 불일치합니다. <br>금액을 체크해주세요","warning")
        return false
      }
      else
      {
        let res = ""

        let param = "SS"
        let dCode = ""
        let exUseFlag = "Y"
        let dFlag = "D"
        let dCurCd = "KRW"
        let dExRate = 1
        let dPayMethod = "Bank"
        let finCd = this.exdepositInfo.finCd
        let dPayCode = finCd
        let dFeeRate = 0
        let dUseFlag = "Y"
        let splitFlag = "Y"

        let j = 0
        while(j < arr_excelUpData.length)
        {
          let ins_simpleSplitInfo = []

          ins_simpleSplitInfo = await this.$api("/api/proc_createExdepositSplit", {param: [ param, this.arr_excelUpData[j].simpleName,
            this.exDepositNo, dCode, this.arr_excelUpData[j].splitDepositAmt, this.user.handlerId, exUseFlag, this.arr_excelUpData[j].splitMemo, finCd, this.user.handlerId,
            dFlag, dCurCd, dExRate, this.splitDepositAmt, dPayMethod, dPayCode, dFeeRate, this.arr_excelUpData[j].splitMemo, this.exdepositInfo.depositor, this.getDateFormat(this.exdepositInfo.depositDate), dUseFlag, 
            splitFlag
          ]})

          if(ins_simpleSplitInfo.affectedRows > 0)
          {
            res = "OK"
          }
          else
          {
            res = "Error"
            break
          }

          j++
        }

        if(res === "error")
        {
          this.$swal.fire('', '처리 중 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', '일괄단순분할이 완료되었습니다.', 'success');
          location.reload(true);
        }
      }
    },    

    //분할확정-취소
    async changeFixFlag(sub, param){        //param: Y or N
      //console.log(sub, param, ",", this.exDepositNo)
      let updStatusSpHistory = await this.$api("/api/updStatusSpHistory", {param:[
        param, this.user.handlerId, this.exDepositNo, sub
      ]})

      if(updStatusSpHistory.affectedRows < 0 || updStatusSpHistory.affectedRows == undefined)
      {
        this.$swal.fire('', '분할처리의 상태변경(updStatusSpHistory)에 문제가 발생했습니다.', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '분할처리의 상태가 변경되었습니다.', 'success');
        location.reload(true);
      }
    },

    simpleSplit(){
      this.viewSimpleSplit = (this.viewSimpleSplit === "Y") ? "N" : "Y"
      this.dataInput = ""
      //console.log("viewSimpleSplit:", this.viewSimpleSplit)
    },

    //행사명검색(정산완료된 행사는 제외)
    async getRequestConsultList() {
      let eUseFlag = "Y"
      let dealData = await this.$api("/api/getDealInfo", {param:[ eUseFlag ]})
      if(dealData.length > 0)
      {
        this.dealData = dealData
      }
      
      let i=0;
      let arrSearchData = [];
      if(dealData.length > 0) {
        this.dealData = dealData;
        while(i<dealData.length) 
        {
          arrSearchData.push([dealData[i].dealNameKor,dealData[i].dealCode]);
          i++;
        }
      }
      this.arrSearchData = arrSearchData;
    },
    //자동완성기능
    submitAutoComplete() {
      const autocomplete = document.querySelector(".autocomplete");
      let result_tmp = [];
      if (this.dataInput) {
        autocomplete.classList.remove("disabled");
        autocomplete.style.display = "block"
        result_tmp = this.arrSearchData.filter((e) => {
          return String(e).match(new RegExp(this.dataInput.toUpperCase(), "i"));
        });
        //결과값 중복제거
        this.result = Array.from(new Set(result_tmp));        
      }
      else {
        autocomplete.classList.add("disabled");
        autocomplete.style.display = "none";
        this.getRequestConsultList()
      }
    },

    //정산용행사검색
    searchDealData(dCode){
      //console.log("delaCode:",dCode)
      this.viewSplit = "Y"
      this.dealCode = dCode
    },

    //입금액 분할처리
    async depositSplit(dCode, param){
      //console.log(this.splitDepositAmt,", ", dCode)
      let exUseFlag = "Y"
      let dFlag = "D"
      let dCurCd = "KRW"
      let dExRate = 1
      let dPayMethod = "Bank"
      let finCd = this.exdepositInfo.finCd
      let dPayCode = finCd
      let dFeeRate = 0
      let dUseFlag = "Y"
      let splitFlag = "Y"
      let simpleName = ""

      let ins_splitInfo = []

      if(this.disibleDepositAmt < this.splitDepositAmt)
      {
        this.splitDepositAmt = 0
        this.$swal.fire("","입력금액은 분할가능한 잔액을 초과할 수 없습니다!", "warning")
        return false;
      }                
      else
      {
        if(param === "GS")
        {
          ins_splitInfo = await this.$api("/api/proc_createExdepositSplit", {param: [ param, simpleName,
            this.exDepositNo, dCode, this.splitDepositAmt, this.user.handlerId, exUseFlag, this.splitMemo, finCd, this.user.handlerId,
            dFlag, dCurCd, dExRate, this.splitDepositAmt, dPayMethod, dPayCode, dFeeRate, this.splitMemo, this.exdepositInfo.depositor, this.getDateFormat(this.exdepositInfo.depositDate), dUseFlag, 
            splitFlag
          ]})
        }
        //단순분할
        else if(param === "SS")
        {
          let splitMemo = (this.splitMemo == "") ? "단순분할" : this.splitMemo
          ins_splitInfo = await this.$api("/api/proc_createExdepositSplit", {param: [ param, simpleName,
            this.exDepositNo, dCode, this.splitDepositAmt, this.user.handlerId, exUseFlag, splitMemo, finCd, this.user.handlerId,
            dFlag, dCurCd, dExRate, this.splitDepositAmt, dPayMethod, dPayCode, dFeeRate, splitMemo, this.exdepositInfo.depositor, this.getDateFormat(this.exdepositInfo.depositDate), dUseFlag, 
            splitFlag
          ]})
        }
        //console.log("ins_splitInfo:",ins_splitInfo)
        if(Object.keys(ins_splitInfo)[0] === "error")
        {
          this.$swal.fire("","등록실패("+ ins_splitInfo.error.sqlMessage +")", "error")
        }
        else {
          this.$swal.fire('','금액분할처리완료', 'success');
          //this.getExdepositInfo()
          location.reload();
        }
      }
    },
  }
}
</script>

<style>
.table_sup td {
  padding: 0
}

/**테이블 안의 오브젝트 크기세팅 */
.resize-select {
    width: 100%;
    box-sizing: border-box;
    font-size: smaller;
  }

.resize-input {
  width: 100%;
  box-sizing: border-box;
  font-size: smaller;
}

/**스위치버튼 컬러처리 */
.custom-switch .form-check-input:checked {
    background-color: #007bff;
  }
  
  .custom-switch .form-check-input:checked+.form-check-label::before {
    background-color: #fff;
  }
</style>