<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-0">
      <div class="p-1">
        <h2 class="text-center"><u>&nbsp;지 출 품 의 리 스 트&nbsp;</u></h2>

        <div class="text-success text-start fs-6 mb-2 print-hide">
          <b>[ &nbsp; 지출품의서 검색조건 &nbsp; ]</b>
          <!-- <span style="padding-left: 2rem;">
            <input class="form-check-input" type="checkbox" id="cancelReservationCheckbox" v-model="noDispCxl" @change="cxlNoDisplay(noDispCxl)">
            &nbsp;<label class="form-check-label text-success" for="cancelReservationCheckbox">파기품의서표시</label>
          </span> -->
        </div>
        
        <!-- search area START -->
        <table class="table table-bordered border-success align-middle w-100 h-auto print-hide text-center">
          <tbody>
            <tr>
              <th class="table-secondary">
                발의기간
              </th>
              <td colspan="3">
                <div class="input-group">
                  <span class="input-group-text input-group-sm" style="font-size:smaller">시작</span>
                  <input type="date" class="form-control form-control-sm" ref="searchStart" v-model="this.searchStart" style="font-size:smaller">
                  <span class="input-group-text input-group-sm" style="font-size:smaller">종료</span>
                  <input type="date" class="form-control form-controm-sm" ref="searchEnd" v-model="this.searchEnd" style="font-size:smaller">
                </div>
              </td>

              <th class="table-secondary col-md-1">
                품의번호
              </th>
              <td>
                <input type="text" v-model="this.ser_reqCode" style="font-size:smaller" size="40">
              </td>

              <th class="table-secondary col-md-1">
                발의자
              </th>
              <td>
                <select class="form-select form-select-sm" v-model="this.chargePerson" style="font-size: smaller;" ref="dealChargeId">
                  <option :value="c.handlerId" :key="i" v-for="(c,i) in this.chargePList">{{ c.handlerNameKor }}</option>
                </select>
              </td>
            </tr>

            <tr>
              <th class="table-secondary">
                진행상태
              </th>
              <td colspan="3">
                <!-- <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="rProcCode" @change="changeProcCode(rProcCode)"> -->
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="rProcCode">  
                  <option :value="noVal" disabled>진행상태선택</option>
                  <option value="A" >전체보기</option>
                  <option value="lv1" >발의</option>            
                  <option value="lv5" >팀장결재</option>
                  <option value="lv9" >대표결재</option>
                  <option value="nay" >지출대기</option>
                  <option value="pay" >지출완료</option>
                  <option value="rj5" >팀장반려</option>
                  <option value="rj9" >대표반려</option>
                  <option value="lv0" >파기</option>
                </select>
              </td>

              <th class="table-secondary col-md-1">
                거래처
              </th>
              <td>
                <select class="form-select form-select-sm" v-model="this.partner" style="font-size: smaller;" ref="dealChargeId">
                  <option :value="p.pCode" :key="i" v-for="(p,i) in this.partnerList">{{ p.pName }}</option>
                </select>
              </td>
              <th class="table-secondary col-md-1">
                거래명/거래코드
              </th>
              <td>
                <input type="text" v-model="this.dealName" style="font-size:smaller">
              </td>
            </tr>
          </tbody> 
        </table>
        <div class="text-success text-end fs-6 mb-2 print-hide print-hide">
          <b><button type="button" class="btn btn-outline-secondary btn-sm" @click="searchDefault">검색조건초기화</button></b>
          &nbsp;&nbsp;
          <b><button type="button" class="btn btn-outline-danger btn-sm" @click="searchRequistionData">검색하기</button></b>
        </div>
        <!-- search area END -->

        <div style="display: flex; justify-content: space-between;">
          <span style="text-align: start;">
            TOTAL: [ 
              <b class="text-primary">₩{{ this.getCurrencyFormat(rPrice_ex_sum) }}</b> 
              <b class="text-danger"  v-if="rPrice_sum.JPY"> / {{rPrice_sum.JPY.rSymbol }}{{this.getCurrencyFormat(rPrice_sum.JPY.rPrice_sum)}}</b>
              <b class="text-secondary"  v-if="rPrice_sum.USD"> / US{{rPrice_sum.USD.rSymbol }}{{this.getCurrencyFormat(rPrice_sum.USD.rPrice_sum)}}</b>
              <b class="text-success" v-if="rPrice_sum.TWD"> / TW{{rPrice_sum.TWD.rSymbol }}{{this.getCurrencyFormat(rPrice_sum.TWD.rPrice_sum)}}</b>
            ]&nbsp;&nbsp;
            미지출건수: [ {{ this.notYetPaidCount }} ]
          </span>
          <span style="text-align: end;">
            <btn class="btn btn-secondary btn-sm" style="font-size: 0.7rem; vertical-align: top;" @click="makeNonChecked">선택초기화</btn>
            CheckSum: [ 
              <b class="text-primary" v-if="checkedSum_ex > 0">₩{{ this.getCurrencyFormat(checkedSum_ex) }}</b> 
              <b class="text-danger"  v-if="checkedSum_jp > 0"> / ¥{{this.getCurrencyFormat(checkedSum_jp)}}</b>
              <b class="text-secondary"  v-if="checkedSum_us > 0"> / US${{this.getCurrencyFormat(checkedSum_us)}}</b>
              <b class="text-success" v-if="checkedSum_tw > 0"> / TW${{this.getCurrencyFormat(checkedSum_tw)}}</b>
            ]
          </span>
        </div>

        <table class="table table-bordered table-striped fs-6 mt-2" id="reqTable">
          <thead class="small">
            <tr class="text-center">
              <th style="width:5%;">상태</th>
              <th style="width:8%;">발의</th>
              <th style="width:8%;">팀장</th>
              <th style="width:8%;">대표</th>
              <th style="width:8%;">지출완료</th>
              <th style="width:10%;">품의번호</th>
              <th style="width:7%;">거래처</th>
              <!-- <th style="width:7%;">원화 / <span style="color: darkgray;">원화환산</span></th> -->
              <th style="width:7%;">
                <btn class="btn btn-outline-primary btn-sm" @click="calcSum_rPrice_ex">원화/원화환산</btn>
              </th>
              <!-- <th style="width:8%;">외화</th> -->
              <th style="width:8%;">
                <btn class="btn btn-outline-danger btn-sm" style="padding-left: 2rem; padding-right: 2rem;" @click="calcSum_rPrice">외화</btn>
              </th>
              <th style="width:6%;">결제시한</th>
              <th style="width:18%;">거래명/거래코드</th>
              <th style="width:3%;">파기</th>
            </tr>
          </thead>
          <tbody class="small" style="font-size:small;" id="reqTbody">
            <tr :key="i" v-for="(rList, i) in this.reqList" class="text-center">
              <td style="vertical-align: middle; color: red;" v-if="rList.rStat === '팀장반려' || rList.rStat === '대표반려'">{{ rList.rStat }}</td>
              <td style="vertical-align: middle;" v-else>{{ rList.rStat }}</td>

              <td>
                {{ getDateFormat(rList.check1Date) }}
                <p class="mb-0" style="font-size:smaller;">({{ rList.checker1Name }})</p>
              </td>

              <td v-if="rList.reqStat === 'rj5' || rList.reqStat === 'rj9'">
                <div v-if="rList.reject5Date == undefined || rList.reject5Date == null">
                </div>
                <div v-else class="text-primary">
                  <a @click="showComment(rList.reqCode, 'rj5');" style="cursor:pointer;">
                    반려({{ getDateFormat(rList.reject5Date) }})
                  </a>
                  <p class="mb-0" style="font-size:smaller;">({{ rList.checker5Name }})</p>
                </div>
              </td>

              <td v-else-if="rList.reqStat === 'lv5' || rList.reqStat === 'lv9'">
                <div v-if="rList.check5Date == undefined || rList.check5Date == null">
                </div>
                <div v-else>
                  결재({{ getDateFormat(rList.check5Date) }})
                  <p class="mb-0" style="font-size:smaller;">({{ rList.checker5Name }})</p>
                </div>
              </td>
              <td v-else>
              </td>

              <td v-if="rList.reqStat === 'rj5' || rList.reqStat === 'rj9'">
                <div v-if="rList.reject9Date == undefined || rList.reject9Date == null">
                </div>
                <div v-else class="text-primary">
                  <a @click="showComment(rList.reqCode, 'rj9');" style="cursor:pointer;">
                    반려({{ getDateFormat(rList.reject9Date) }})
                  </a>
                  <p class="mb-0" style="font-size:smaller;">({{ rList.checker9Name }})</p>
                </div>
              </td>
              <td v-else-if="rList.reqStat === 'lv5' || rList.reqStat === 'lv9'">
                <div v-if="rList.check9Date == undefined || rList.check9Date == null">
                </div>
                <div v-else>
                  결재({{ getDateFormat(rList.check9Date) }})
                  <p class="mb-0" style="font-size:smaller;">({{ rList.checker9Name }})</p>
                </div>
              </td>
              <td v-else>
              </td>

              <td v-if="rList.payDate == null || rList.payDate == undefined"></td>
              <td v-else class="text-danger">
                Y ({{ getDateFormat(rList.payDate) }})
                <p class="mb-0" style="font-size:smaller;">({{ rList.payerName }})</p>
              </td>
              <td class="text-primary" style="vertical-align: middle;">
                <a @click="goToReqReqView(rList.reqCode);" style="cursor:pointer;">{{ rList.reqCode }}</a>
                <!-- {{ rList.reqCode }} -->
              </td>
              <td>
                {{ rList.pName }}
                <p class="mb-0" style="font-size: smaller;">({{ rList.ptCode }})</p>
              </td>

              <td style="vertical-align: middle; text-align: end;" v-if="rList.rcCode === 'KRW'">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <input class="form-check-input" type="checkbox"  v-model="rList.checked_ex">
                  <span>{{ rList.rSymbol }}{{ getCurrencyFormat(rList.rPrice) }}</span>
                </div>
              </td>
              <td style="vertical-align: middle; text-align: end; color: darkgray;" v-else>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <input class="form-check-input" type="checkbox" v-model="rList.checked_ex">
                  <span>₩{{ getCurrencyFormat(rList.rPrice_ex) }}</span>
                </div>
              </td>

              <td style="vertical-align: middle; text-align: end;" v-if="rList.rcCode != 'KRW'">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <input class="form-check-input" type="checkbox" v-model="rList.checked">
                  <span>{{ rList.rSymbol }}{{ getCurrencyFormat(rList.rPrice) }}</span>
                </div>
              </td>
              <td style="vertical-align: middle; text-align: end;" v-else>0</td>

              <td v-if="rList.ttlDate == null || rList.ttlDate == undefined"></td>
              <td style="vertical-align:middle;" v-else>
                {{ this.getDateFormat(rList.ttlDate) }}
              </td>              

              <td>
                {{ rList.dealNameKor }}
                <p class="mb-0" style="font-size: smaller; cursor: pointer; color:blue;" @click="goToBizInfoDetail(rList.dealCode);" >({{ rList.dealCode }})</p>
              </td>
              <td>
                <span v-if="user.handlerGrade > 5 && (rList.reqStat === 'lv5' || rList.reqStat === 'lv9')">
                </span>                
                <button type="button" class="btn btn-danger btn-sm me-1" v-else-if="rList.reqStat === 'lv0'" disabled>
                  <i class="bi bi-trash3"></i>
                </button>
                <!-- <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="destDoc(rList.reqCode);" v-else>
                  <i class="bi bi-trash3"></i>
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      reqList: [],

      result: null,
      arrSearchData: [],

      //fullPayFlag: ""             //완불여부 (fp: 완불)
      noDispCxl: false,             //취소예약비표시

      reqPriceList: [],             //지품금액 리스트
      reqList1: [],                 //진행상태처리용
      reqPriceList1: [],

      chargePList: [],              //발의자리스트
      partnerList: [],              //파트너리스트
      //검색조건
      searchStart: "",              //검색기준일
      searchEnd: "",
      ser_reqCode: "",              //품의코드
      chargePerson: "",             //발의자
      rProcCode: "",                //진행상태
      partner: "",                  //파트너
      dealName: "",                 //거래정보

      rPrice_sum: 0,                //품의액합산
      rPrice_ex_sum: 0,             //원화환산합산

      checkedSum_jp: 0,             // 엔화합산 결과를 저장할 변수
      checkedSum_us: 0,             // 미화합산 결과를 저장할 변수
      checkedSum_tw: 0,             // 타이완달라합산 결과를 저장할 변수
      //checkedSum: null,             // 합산 결과를 저장할 변수
      checkedSum_ex: 0,             // 환산합산 결과를 저장할 변수

      notYetPaidCount: 0,           // 미지출품의 카운트
      

      // expRptPriceList_sum: [],      //품의액 합산
      // expRptPriceList_sum1: [],     //진행상태처리용

    };
  },
  created() {
    this.getExpRptList()

    this.getRequesterList()             //발의자리스트
    this.getPartnerList()               //거래처리스트


    //console.log("AccessInfo:",this.$store.state.user); 
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },

  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value)
    {
      return this.$currencyFormat(value);
    },

    //검색조건 초기화
    searchDefault(){
      this.searchStart = ""
      this.searchEnd   = ""
      this.ser_reqCode = ""
      this.chargePerson= ""
      this.rProcCode   = ""
      this.partner     = ""
      this.dealName    = ""
    },

    //발의자리스트
    async getRequesterList(){
      let chargePList = await this.$api("/api/handlerInfo", {})
      if(chargePList.length > 0)
      {
        this.chargePList = chargePList;
      }
    },

    //거래처리스트
    async getPartnerList(){
      let partnerList = await this.$api("/api/partnerInfo", {})
      if(partnerList.length > 0)
      {
        this.partnerList = partnerList;
      }
    },    

    //선택초기화
    makeNonChecked(){
      this.reqList.forEach(item => {
        item.checked = false;
        item.checked_ex = false;
      });

      this.checkedSum_ex = 0
      this.checkedSum_jp = 0
      this.checkedSum_us = 0
      this.checkedSum_tw = 0
    },

    //체크외화 합산
    calcSum_rPrice(){
      // 체크된 항목의 `rPrice_ex`만 합산
      // let sum = this.reqList.reduce((acc, item) => {
      //   if(item.checked && item.rcCode != "KRW")
      //   {
      //     if(!acc[item.rcCode])
      //     {
      //       acc[item.rcCode].rPrice_sum += item.rPrice
      //     }
      //     return acc
      //   }
      // })

      let sum_jp = this.reqList
        .filter(item => (item.checked && item.rcCode === "JPY"))         //체크된 금액만 추출
        .reduce((acc, item) => acc + item.rPrice, 0)
      // 결과 저장
      this.checkedSum_jp = sum_jp;

      let sum_us = this.reqList
        .filter(item => (item.checked && item.rcCode === "USD"))         //체크된 금액만 추출
        .reduce((acc, item) => acc + item.rPrice, 0)
      // 결과 저장
      this.checkedSum_us = sum_us;      

      let sum_tw = this.reqList
        .filter(item => (item.checked && item.rcCode === "TWD"))         //체크된 금액만 추출
        .reduce((acc, item) => acc + item.rPrice, 0)
      // 결과 저장
      this.checkedSum_tw = sum_tw;

      // console.log("sum_jp:",sum_jp, "sum_us:",sum_us, "sum_tw:",sum_tw)
      // console.log('체크된 항목의 합산된 엔화값:', this.checkedSum_jp, '체크된 항목의 합산된 타이완값:', this.checkedSum_tw);
    },

    //체크환산 합산
    calcSum_rPrice_ex() {
      // 체크된 항목의 `rPrice_ex`만 합산
      let sum_ex = this.reqList
        .filter(item => item.checked_ex)         //체크된 금액만 추출
        .reduce((acc, item) => acc + item.rPrice_ex, 0)

      // 결과 저장
      this.checkedSum_ex = sum_ex;
      console.log('체크된 항목의 합산된 원화/원화환산 값:', this.checkedSum_ex);
    },

    async getExpRptList(){
      let id = this.user.handlerId
      let level = this.user.handlerGrade
      console.log("level:", level)
      
      let expRptList = []
      let expRptPriceList = []

      if(level <= 5)
      {
        expRptList = await this.$api("/api/expRptListForLeader", {});
        expRptPriceList = await this.$api("/api/exRptPriceListForLeader", {});
        //expRptPriceList_sum = await this.$api("/api/exRptPriceListForLeader_sum", {});          //합산액
      }
      else
      {
        expRptList = await this.$api("/api/expRptList", { param: [id] });
        expRptPriceList = await this.$api("/api/exRptPriceList", { param: [id] });
        //expRptPriceList_sum = await this.$api("/api/exRptPriceList_sum", { param: [id] });
      }

      // if(level < 1)
      // {
      //   //초기표시에는 파기품의 비표시
      //   expRptList = expRptList.filter(e => {
      //     return ((e.payDate == null || e.payDate == undefined) && e.reqStat != "lv0")
      //   })

      //   expRptPriceList = expRptPriceList.filter(e => {
      //     return ((e.payDate == null || e.payDate == undefined) && e.reqStat != "lv0")
      //   })
      // }
      // else
      // {
      //   //초기표시에는 파기품의 비표시
      //   expRptList = expRptList.filter(e => {
      //     return e.reqStat != "lv0"
      //   })

      //   expRptPriceList = expRptPriceList.filter(e => {
      //     return e.reqStat != "lv0"
      //   })
      // }

      // console.log("expRptList:", expRptList, "expRptPriceList:", expRptPriceList)
      //초기표시에는 파기품의 비표시
      expRptList = expRptList.filter(e => {
        return e.reqStat != "lv0"
      })

      expRptPriceList = expRptPriceList.filter(e => {
        return e.reqStat != "lv0"
      })

      if(expRptList.length > 0) {
        //this.reqList = expRptList
        this.reqList = expRptList.map(item => ({
          ...item,
          checked: false // 처음에 모두 체크 해제
        }));
      }

      if(expRptPriceList.length > 0) {
        this.reqPriceList = expRptPriceList
      }

      //추출한 품의금액리스트 객체를 품의리스트에 합병
      for(let i=0; i<expRptList.length; i++)
      {
        this.reqList[i] = {
          ...this.reqList[i],
          ...this.reqPriceList[i]
        }
      }

      //파기품의 표시유무체크
      if(this.noDispCxl === true)
      {
        this.reqList = this.reqList.filter(e => {
          return e.reqStat != "lv0"
        })
      }

      //품의액 합산
      let rPrice_sum_tmp = this.reqList.reduce((acc, item) => {       //acc:누적값
        //KRW는 제외
        if(item.rcCode != "KRW")
        {
          if(!acc[item.rcCode])
          {
            acc[item.rcCode] = { rPrice_sum: 0, rcCode: item.rcCode, rSymbol: item.rSymbol }
          }
          acc[item.rcCode].rPrice_sum += item.rPrice
        }
        return acc
      }, {})

      //배열형태로 변환
      //let rPrice_sum = Object.values(rPrice_sum_tmp)
      this.rPrice_sum = rPrice_sum_tmp

      //품의액 원화환산 합산
      let rPrice_ex_sum = this.reqList.reduce((acc, item) => acc + item.rPrice_ex, 0)
      this.rPrice_ex_sum = rPrice_ex_sum

      let notYetPaidCount = 0
      notYetPaidCount = Object.values(this.reqList).filter(item => (item.payDate == null || item.payDate == undefined) && item.reqStat != "lv0").length
      this.notYetPaidCount = (notYetPaidCount == undefined || notYetPaidCount < 0) ? "0" : notYetPaidCount
    },
    
    showComment(qCode, r){
      let res = this.reqList.filter(item => {
        return item.reqCode === qCode
      })

      let rejectComment = ""
      if(r === "rj5")
      {
        rejectComment = res[0].rejectLv5
      }
      else if(r === "rj9")
      {
        rejectComment = res[0].rejectLv9
      }
      this.$swal.fire("반려사유", rejectComment, "")
    },

    // goToReqReqView(rCode) {
    //   this.$router.push({path: '/expenseReportView', query: {rCode: rCode}});
    // },

    //새로운 탭으로
    goToReqReqView(rCode) {
      const url = this.$router.resolve({ path: '/expenseReportView', query: { rCode } }).href;
      window.open(url, '_blank'); // 새 탭에서 열기
    },


    //거래상세정보로 이동
    goToBizInfoDetail(dCode) {
      const url = this.$router.push({path: '/businessInfo_detail', query: {dCode: dCode}}).href;
      window.open(url, '_blank'); // 새 탭에서 열기
    },    

    //품의서 검색
    async searchRequistionData(){
      this.makeNonChecked()

      if(this.searchStart)
      {
        if(!this.searchEnd)
        {
          this.$swal.fire("","종료일이 누락되었습니다", "warning")
          return false
        }
      }
      if(this.searchEnd)
      {
        if(!this.searchStart)
        {
          this.$swal.fire("","시작일이 누락되었습니다", "warning")
          return false
        }
      }      
      await this.getExpRptList1();
      //검색조건
      let searchStart   = (!this.searchStart ) ? "" : this.searchStart.replace(/-/g, "")
      let searchEnd     = (!this.searchEnd   ) ? "" : this.searchEnd.replace(/-/g, "")   
      let ser_reqCode   = (!this.ser_reqCode ) ? "" : this.ser_reqCode              //품의코드
      let chargePerson  = (!this.chargePerson) ? "" : this.chargePerson             //발의자
      let rProcCode     = (!this.rProcCode   ) ? "" : this.rProcCode                //진행상태
      let partner       = (!this.partner     ) ? "" : this.partner                  //파트너
      let dealName      = (!this.dealName    ) ? "" : this.dealName                 //거래정보

      if(this.rProcCode === "nay")
      {
        this.reqList1 = this.reqList1.filter(e => {
          let check1Date = this.getDateFormat(e.check1Date).replace(/-/g, "")
          return (
            //발의일자검색(날짜가 없으면 넘어감)
            ((searchStart === "" && searchEnd === "") || (check1Date >= searchStart && check1Date <= searchEnd)) &&
            //품의코드(없으면 넘어감)
            (ser_reqCode == "" || e.reqCode === ser_reqCode) &&
            //발의자검색
            (chargePerson == "" || e.checker1Id === chargePerson) &&
            // 진행상태 검색 조건 확인(지출대기는 파기X+미지출)
            // ((e.payDate == null || e.payDate == undefined) && e.reqStat != 'lv0' && (e.checker9Id != null || e.checker9Id != undefined)) &&
            ((e.payDate == null || e.payDate == undefined) && e.reqStat != 'lv0') &&
            // 파트너 검색 조건 확인
            (partner === "" || e.ptCode === partner) &&
            // 거래정보 검색 조건 확인
            (dealName === "" || e.dealNameKor === dealName || e.dealCode === dealName)
          )
        })
      }
      else if(this.rProcCode === "pay")
      {
        this.reqList1 = this.reqList1.filter(e => {
          let check1Date = this.getDateFormat(e.check1Date).replace(/-/g, "")
          return (
            //발의일자검색(날짜가 없으면 넘어감)
            ((searchStart === "" && searchEnd === "") || (check1Date >= searchStart && check1Date <= searchEnd)) &&
            //품의코드(없으면 넘어감)
            (ser_reqCode == "" || e.reqCode === ser_reqCode) &&
            //발의자검색
            (chargePerson == "" || e.checker1Id === chargePerson) &&
            // 진행상태 검색 조건 확인
            ((e.payDate != null || e.payDate != undefined) && e.reqStat != 'lv0'  && (e.checker9Id != null || e.checker9Id != undefined)) &&
            // 파트너 검색 조건 확인
            (partner === "" || e.ptCode === partner) &&
            // 거래정보 검색 조건 확인
            (dealName === "" || e.dealNameKor === dealName || e.dealCode === dealName)
          )
        })
      }
      else if(this.rProcCode === "A")
      {
        this.reqList1 = this.reqList1.filter(e => {
          let check1Date = this.getDateFormat(e.check1Date).replace(/-/g, "")
          return (
            //발의일자검색(날짜가 없으면 넘어감)
            ((searchStart === "" && searchEnd === "") || (check1Date >= searchStart && check1Date <= searchEnd)) &&
            //품의코드(없으면 넘어감)
            (ser_reqCode == "" || e.reqCode === ser_reqCode) &&
            //발의자검색
            (chargePerson == "" || e.checker1Id === chargePerson) &&
            // 진행상태 검색 조건 확인(전체보기포함)
            (rProcCode === "" || rProcCode === "A" || e.reqStat === rProcCode) &&
            // 파트너 검색 조건 확인
            (partner === "" || e.ptCode === partner) &&
            //파기는 제외
            (e.reqStat != "lv0") &&
            // 거래정보 검색 조건 확인
            (dealName === "" || e.dealNameKor === dealName || e.dealCode === dealName)
          )
        })
      }      
      else
      {
        this.reqList1 = this.reqList1.filter(e => {
          let check1Date = this.getDateFormat(e.check1Date).replace(/-/g, "")
          return (
            //발의일자검색(날짜가 없으면 넘어감)
            ((searchStart === "" && searchEnd === "") || (check1Date >= searchStart && check1Date <= searchEnd)) &&
            //품의코드(없으면 넘어감)
            (ser_reqCode == "" || e.reqCode === ser_reqCode) &&
            //발의자검색
            (chargePerson == "" || e.checker1Id === chargePerson) &&
            // 진행상태 검색 조건 확인(전체보기포함)
            (rProcCode === "" || rProcCode === "A" || e.reqStat === rProcCode) &&
            // 파트너 검색 조건 확인
            (partner === "" || e.ptCode === partner) &&
            // 거래정보 검색 조건 확인
            (dealName === "" || e.dealNameKor === dealName || e.dealCode === dealName)
          )
        })
      }
      //파기품의 표시유무체크
      if(this.noDispCxl === true)
      {
        this.reqList1 = this.reqList1.filter(e => {
          return e.reqStat != "lv0"
        })
      }
      else
      {
        this.reqList = this.reqList1
        // this.expRptPriceList_sum = this.expRptPriceList_sum1
      }

      //품의액 합산
      let rPrice_sum_tmp = this.reqList.reduce((acc, item) => {       //acc:누적값
        //KRW는 제외
        if(item.rcCode != "KRW")
        {
          if(!acc[item.rcCode])
          {
            acc[item.rcCode] = { rPrice_sum: 0, rcCode: item.rcCode, rSymbol: item.rSymbol }
          }
          acc[item.rcCode].rPrice_sum += item.rPrice
        }
        return acc
      }, {})

      //배열형태로 변환
      //let rPrice_sum = Object.values(rPrice_sum_tmp)
      this.rPrice_sum = rPrice_sum_tmp

      //품의액 원화환산 합산
      let rPrice_ex_sum = this.reqList.reduce((acc, item) => acc + item.rPrice_ex, 0)
      this.rPrice_ex_sum = rPrice_ex_sum

      let notYetPaidCount = 0
      //const count = Object.values(data).filter(item => item.payDate === "").length;
      notYetPaidCount = Object.values(this.reqList).filter(item => (item.payDate == null || item.payDate == undefined) && item.reqStat != "lv0").length
      this.notYetPaidCount = (notYetPaidCount == undefined || notYetPaidCount < 0) ? "0" : notYetPaidCount
      // console.log("notYetPaidCount:", this.notYetPaidCount)
      // console.log("reqList:", this.reqList, "rPrice_sum:", this.rPrice_sum,  "rPrice_ex_sum:", this.rPrice_ex_sum)
    },

    //선택조건 처리용
    async getExpRptList1(){
      let id = this.user.handlerId
      let level = this.user.handlerGrade
      
      let expRptList1 = []
      let expRptPriceList1 = []
      //let expRptPriceList_sum1 = []

      if(level <= 5)
      {
        expRptList1 = await this.$api("/api/expRptListForLeader", {});
        expRptPriceList1 = await this.$api("/api/exRptPriceListForLeader", {});
        //expRptPriceList_sum1 = await this.$api("/api/exRptPriceListForLeader_sum", {});          //합산액
      }
      else
      {
        expRptList1 = await this.$api("/api/expRptList", { param: [id] });
        expRptPriceList1 = await this.$api("/api/exRptPriceList", { param: [id] });
        //expRptPriceList_sum1 = await this.$api("/api/exRptPriceList_sum", { param: [id] });
      }

      // //기본표시는 파기품의 비표시
      // expRptList1 = expRptList1.filter(e => {
      //   return e.reqStat != "lv0"
      // })

      // expRptPriceList1 = expRptPriceList1.filter(e => {
      //   return e.reqStat != "lv0"
      // })

      if(expRptList1.length > 0) {
        this.reqList1 = expRptList1
      }

      if(expRptPriceList1.length > 0) {
        this.reqPriceList1 = expRptPriceList1
      }

      //추출한 품의금액리스트 객체를 품의리스트에 합병
      for(let i=0; i<expRptList1.length; i++)
      {
        this.reqList1[i] = {
          ...this.reqList1[i],
          ...this.reqPriceList1[i]
        }
      }

      console.log("reqList1:::::::", this.reqList1)

      // //파기품의 표시유무체크
      // if(this.noDispCxl === true)
      // {
      //   this.reqList1 = this.reqList1.filter(e => {
      //     return e.reqStat != "lv0"
      //   })
      // }
    },
  }
}
</script>

<style>
  .table-secondary{
    font-size: small;
  }
</style>