<template>
  <main class="mt-3">
    <div class="cominfo container">

      <div class="video">
        <video muted autoplay loop>
          <source src="../assets/img/sakura.mp4" type="video/mp4">
        </video>
        <div class="video-text">
          <p>고객님은 엔타비의 미래입니다.</p>
        </div>
      </div>
    
      <div class="container mt-5">
        <div class="row">
          <div class="col-12">
            <p class="litit">배우고 느끼고 실천하는 엔타비는 다릅니다.</p>
          </div>
          <div class="row mt-5">
            <div class="col-lg-6">
              <img src="../assets/img/best/kimono.jpg" class="comim">
            </div>
            <div class="col-lg-6 text-start gx-lg-5 align-self-center mt-3">
              <p><font style="color:darkgreen; font-weight: 600" class="fs-5">엔타비</font>는 여행 서비스와 패키지를 고객에게 제공하는 종합여행사입니다.<br>
                국내외 여행지, 가이드 투어, 맞춤형 여행일정 등, 다양한 여행옵션을 제공합니다.<br>
                엔타비는 고객 한 분 한 분께 <span>편안하고 편리한 여행경험을 제공하는 것을 목표</span>로 하고 있습니다.
              </p>
            </div>
          </div>
          
          <div class="row my-4 mobile">
            <div class="col-lg-6">
              <img src="../assets/img/best/night.jpg" class="comim">
            </div>  
            <div class="col-lg-6 text-start gx-lg-5 align-self-center mt-3">
              <p><font style="color:dodgerblue; font-weight: 600" class="fs-5">엔데이트립</font>은 현지에서의 여행 서비스, 패키지, 체험 등을 전문적으로 기획 후 고객에게 제공하며 국내외 투어, 수려한 경치의 여행지, 의미있는 문화적 경험 등을 포함한 다양한 현지투어 옵션을 제공합니다.<br>
                엔데이트립은 <span>현지에서 개인화된 독특한 여행경험을 제공하는 것을 목표</span>로 하고 있습니다.
              </p>
            </div>
          </div>
          <div class="row my-2 cpc">
            <div class="col-lg-6 text-start gx-lg-5 align-self-center">
              <p><font style="color:dodgerblue; font-weight: 600;" class="fs-5">엔데이트립</font>은 현지에서의 여행 서비스, 패키지, 체험 등을 전문적으로 기획 후 고객에게 제공하며 국내외 투어, 수려한 경치의 여행지, 의미있는 문화적 경험 등을 포함한 다양한 현지투어 옵션을 제공합니다.<br>
                엔데이트립은 <span>현지에서 개인화된 독특한 여행경험을 제공하는 것을 목표</span>로 하고 있습니다.
              </p>
            </div>
            <div class="col-lg-6">
              <img src="../assets/img/best/night.jpg" class="comim">
            </div>
          </div>

          <div class="col-12 comci">
            <p style="font-size:1.7rem; font-weight:600">CI &amp; COLOR</p>
            <div class="row mt-5">
              <div class="col-6"><img src="../assets/img/Ntabi.png" class="img-flui logo"></div>
              <div class="col-6"><img src="../assets/img/Ndaytrip.png" class="img-fluid logo"></div>
            </div>
            <p class="text-between mt-5" style="word-wrap: break-word; white-space: normal;">
            Ntabi의 머릿자 “<b class="text-success"> N </b>”은 Nihon(日本)을 의미하며, 뒤에 잇는 “ <b class="text-primary">tabi</b> ”는 旅行(여행)의 의미를 지니고 있습니다. <br>
            하지만 엔타비를 아끼는 일본 협력사분들 사이에서는 “ 여행으로 맺어지는 좋은 인연 縁旅(えんたび) ” 으로 해석 되기도 합니다. <br>
            최근엔 何でも旅(난데모타비) 즉, “ 여행의 무엇이든 ” 의 의미로 불러지기도 합니다. <br>
            엔타비 컬러는 봄(자연)과 같은 따뜻함과 새로움의 이미지를 담고 있으며, <br>
            이것은 엔타비가 추구하는 “ 사람중심의 여행 ” 의 본질과도 같은 것입니다. 
            </p>
          </div>
          
        </div>
      </div>

    </div>
  </main> 
</template>

<style>
/* 비디오 */
.cominfo .video{ 
  width: 100%; 
  height: 400px; 
  overflow: hidden;
  margin: 0px auto; 
  position: relative; 
}
.cominfo .video video{
  width: 100%;
}
.cominfo .video-text{ 
  position: absolute; 
  top: 50%; 
  width: 100%; 
}
.cominfo .video-text p{ 
  margin-top: -24px; 
  text-align: center; 
  font-size: 2rem; 
  color: #ffffff; 
}
@media (max-width:500px){
  .cominfo .video{ 
    height: 200px; 
  }
  .cominfo .video-text p{
    font-size: 1.5rem; 
  }
}


/* 본문 */
.cominfo .litit{
  font-size:1.7rem;
  font-style: italic;
  color:#54965e
}
.cominfo .comim{
  width: 100%;
  height:17rem;
  object-fit: cover;
}

.cominfo .mobile{
  display: none;
}
@media (max-width:992px){
  .cominfo .mobile{
    display: block;
  }
  .cominfo .cpc{
    display: none;
  }
}
/* ci color */
.cominfo .comci{
  margin-top: 6rem;
}
.cominfo .comci .logo{
  height:6rem
}
@media (max-width:991px){
  .cominfo .comci .logo{
    height:4rem
  }
}
@media (max-width:450px){
  .cominfo .comci .logo{
    height:2.5rem
  }
}
.cominfo .comci p{
  line-height: 25px;
}
</style>
