<template>
  <div>
    <!-- <p>Naver Login Access Pages</p>
    <div id="naverArea"></div> -->
    <!-- <div><button type="button" class="btn btn-primary" @click="look()">LookToken</button></div> -->
  </div>
</template>

<script>
import {genCode} from '../assets/js/genCode'

export default {
  data() {
    return {
      nToken: "",
      naverAccount: {}
    }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      return this.$store.state.user;
    }
  },
  created() {
  },
  mounted() {
    //var naver_id_login = new window.naver_id_login("ZWHmhzE6YbpdaPkpRVxP", "http://localhost/naverCallBack");
    var naver_id_login = new window.naver_id_login("ZWHmhzE6YbpdaPkpRVxP", "https://ntabi.co.kr/naverCallBack");
    // 접근 토큰 값 출력
    this.nToken = naver_id_login.oauthParams.access_token;
    //console.log("nToken:",this.nToken);
    
    // 네이버 사용자 프로필 조회
    naver_id_login.get_naver_userprofile(naverSignInCallback);
    // 네이버 사용자 프로필 조회 이후 프로필 정보를 처리할 callback function
    function naverSignInCallback() {
      const email = naver_id_login.getProfileData('email');
      const name = naver_id_login.getProfileData('name');
      const birthday = naver_id_login.getProfileData('birthday');
      const mobile = naver_id_login.getProfileData('mobile');
      const nickname = naver_id_login.getProfileData('nickname');
      const gender = naver_id_login.getProfileData('gender');
      //const age = naver_id_login.getProfileData('age');

      console.log(name, gender);
      let nEmail = email;
      let nName = nickname;
      let nBirthday = birthday;
      let nMobile = mobile;
      let naverAccount = []
      naverAccount.push(nEmail,nName,nBirthday,nMobile);
    }
    this.lookUpProfile();
  },
  methods: {
    //회원프로필 조회 api연동
    async lookUpProfile() {
      await this.$api("/api/member", {
        // 라우터에서 sql호출시, 파라메타를 같이 넘긴다
        // 처음 파라메터: 기존여부체크용, 두번째 파라메터: 업데이트용
        param: [
          {token: this.nToken}
        ]
      })
      //.then((res) => {console.log("res:",res.response)})
      .then((res) => {this.naverAccount = res.response
        ,this.login()
        //this.acceptJoinUs()
      })
    },

    async login() {
      //회원계정여부 체크
      let res_checkEmail = await this.$api("/api/checkMemEmail", { param: [this.naverAccount.email] })

      //회원아이디 존재 => 간편로그인
      if(res_checkEmail.length > 0)
      {
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm_tmp = today.getMonth()+1;
        let dd_tmp = today.getDate();
        let hour = today.getHours();
        let min = today.getMinutes();
        let sec = today.getSeconds();
        
        let mm = "";
        if(mm_tmp < 10) {mm = "0" + mm_tmp}
        else mm = mm_tmp;

        let dd = "";
        if(dd_tmp < 10) {dd = "0" + dd_tmp}
        else dd = dd_tmp;
        
        let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
        //let ymd = `${yyyy}${mm}${dd}`

        let naverAccount1 = {
          email: this.naverAccount.email,
          birthday: this.naverAccount.birthday,
          name: this.naverAccount.name,
          mobile: this.naverAccount.mobile,
          joinFlag: "N",
          insDate: insDate
          //userNo: userNo
        };
        await this.$api("/api/login", {
        // 라우터에서 sql호출시, 파라메타를 같이 넘긴다
        // 처음 파라메터: 기존여부체크용, 두번째 파라메터: 업데이트용
        param: [
          {
            uEmail: naverAccount1.email,
            uBirthday: naverAccount1.birthday,
            uNickName: naverAccount1.name,
            alimCallC: naverAccount1.mobile,
            uPhone: naverAccount1.mobile,
            joinFlag: naverAccount1.joinFlag,
            insDate: naverAccount1.insDate
            //userNo: naverAccount1.userNo
          },
          { uNickName: naverAccount1.name },
        ]
        })
        .then(async (res) => {
          //console.log("result:",res);
          if(res.uEmail != "")
          {
            // login으로 넘어온 유저정보를 store쪽으로
            let res_membershipInfo = []
            res_membershipInfo = await this.membershipCheck(res.uEmail)

            let naverAccount2 = {}
            naverAccount2 = {
              email:    this.naverAccount.email,
              birthday: this.naverAccount.birthday,
              name:     this.naverAccount.name,
              mobile:   this.naverAccount.mobile,
              joinFlag: "N",
              insDate:  insDate,
              memLevel: res_membershipInfo.memLevel, 
              memNo:    res_membershipInfo.memNo, 
              memExpDate:res_membershipInfo.expDate
            }

            this.$store.commit("user", naverAccount2);

            localStorage.setItem('sessionExpiration', new Date().getTime() + 60 * 60 * 1000);
            //this.$router.go(-2);
            this.$router.push({ path: "/" });
          }
          else
          {
            this.$swal.fire("","로그인에 실패하였습니다","error");
            //this.$router.push({ path: "/" });
            location.href = "/"
          }
        })
      }
      else    //회원아이디 부존재 -> 간편가입절차시작
      {
        this.acceptJoinUs()
      }


    },

    //간편인증자의 멤버십가입자 정보획득
    async membershipCheck(uEmail){
      // console.log(uEmail)
      // return []

      let checkMembershipYN = ""
      checkMembershipYN = await this.$api("/api/checkMembershipYN", {param: [uEmail]})
      //console.log("checkMembershipYN:",checkMembershipYN)

      if(checkMembershipYN[0].mShipFlag == "Y")
      {
        let membershipInfo = []
        membershipInfo = await this.$api("/api/checkMembershipInfo", {param: [uEmail]});
        //console.log("membershipInfo:",membershipInfo)
        if(membershipInfo.length > 0)
        {
          return membershipInfo[0]
        }
      }
      else
      {
        return []
      }
    },
    
    //회원가입확인과정
    async acceptJoinUs(){
      // //임시회원번호생성
      let catCode   = "U-"
      let tmpUserNo = await this.genCode(catCode)
      let joinFlag = "N"
      let useFlag = "Y"
      let birthday = this.naverAccount.birthday.replace(/-/g, '')
      let phone = this.naverAccount.mobile.replace(/-/g, '')

      let ins_memberInfo_tmp = await this.$api("/api/ins_memberInfo_tmp", {param: [
        tmpUserNo, this.naverAccount.email, birthday, this.naverAccount.name, joinFlag, useFlag, phone, this.naverAccount.gender, this.naverAccount.birthyear
      ]});

      if(ins_memberInfo_tmp.affectedRows < 0)
      {
        this.$swal.fire("","간편회원가입 중 에러가 발생했습니다. 재시도를 부탁드립니다.", "warning")
        location.href = "/b2cLogin"
      }
      else
      {
        // this.$router.push({path: '/acceptJoinUs', query: {uNo: tmpUserNo}});
        this.$router.push({ name: 'AcceptJoinUs', params: { uNo: tmpUserNo }})    //파라메타노출방지(path가 아닌 Name에 맞춘다)
      }
    },

    //임시유저번호생성(간편로그인용)
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++)
      {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_resCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N")
      {
        this.$swal.fire("","임시번호 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      }
      else
      {
        return rtnCode
      }
    },    
  }
}
</script>