import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mixins from './mixins'
import store from './store'
// vue-sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue2TouchEvents from 'vue2-touch-events';


const app = createApp(App);
app.use(router);
app.mixin(mixins);
app.use(store);
app.use(VueSweetalert2);
app.use(Vue2TouchEvents);

// Vue 컴파일러에게 <font> 태그를 일반 HTML 엘리먼트로 처리하도록 설정
//app.config.compilerOptions.isCustomElement = tag => tag === 'font';

app.mount('#app');


// createApp(App).use(router).mount('#app')
// app에서 사용할 수 있도록 use시켜준다
// createApp(App).use(router).mixin(mixins).use(store).mount('#app')



// 카카오 api용키(for javascri pt)
window.Kakao.init("2d91bae1654af0bf8b3e7b6acbdaf16c");
//window.Kakao.init("08cdc469c3031b412bdda9337de06e2d");