<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="alert alert-success" role="alert">
          ※ 멤버십 결제가 완료되었습니다. 감사합니다 ※
        </div>
      </div>
    </main>    
  </div>
</template>

<script>
import { handlerInfo } from '../assets/js/commonFunctions.js';

export default {
  data() {
    return {
      handTel: [],            //매니저연락처
      handTelCnt: 0,          //수신처갯수
      //getVerifyData: 0,
      //payStyle: "",           //p1:1차결제, p2:2차결제 / fu or NULL : 전액결제

      //toAlimCall: ['01073440940', '01058234055', '01045447404', '01022083249']
    };
  },
  created() {
    this.user = this.$store.state.user;

    if (this.$store.state.user.handlerId != undefined) {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined) {
      this.userInfo = this.$store.state.user.email;
    }
    else {
      this.userInfo = "";
    }
    console.log("user:", this.user);
    this.paymentKey = this.$route.query.paymentKey;     //결제키
    this.orderId = this.$route.query.orderId;           //주문아이디
    this.amount = this.$route.query.amount;             //결제금액

    this.getHandlerInfo()                               //알림톡 발송대상 체크
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }

    this.total_amount = localStorage.getItem("total_amount")
    this.mLv = localStorage.getItem("memLevel")                 // ES, EP, U
    this.engName = localStorage.getItem("engName")
    this.uPassport = localStorage.getItem("uPassport")
    this.uPassportExp = localStorage.getItem("uPassportExp")
    this.memExpDate = localStorage.getItem("memExpDate")

    let toss_pg = {
      paymentKey: this.paymentKey,
      amount:     this.amount,
      orderId:    this.orderId
    }
    
    if(this.amount === this.total_amount)
    {
      // connect with api on backend
      this.$api("/api/tossPG/success",
      {
        param: toss_pg
      })
      .then((res) => {
        console.log(res);
        //window.location.href = res.checkoutPage;
        this.joinMembership()

      })
      .catch((err) => {
        console.error(err);
      });      
    }
    else
    {
      this.$swal.fire('', '멤버십금액과 결제금액이 불일치합니다. 문의: 1660-4602', 'error');
      this.$router.push({ path: "/" }); 
      return false;
    }
  },

  methods:
  {
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },    
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },

    async joinMembership(){

      let mName = ""
      let imageUrl = ""
      let mShipFlag = "Y"
      let mUseFlag = "Y"
      let today = new Date();
      let yyyy = today.getFullYear()
      let mm_tmp = today.getMonth()+1
      let dd_tmp = today.getDate();

      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;

      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;


      let payDate = yyyy +"-" + mm + "-" + dd
      //console.log(payDate)

      let joinDate_tmp = yyyy +""+ mm +""+ dd
      let joinDate
      let oneYearLater_tmp
      let oneYearLater

      if(this.mLv == "P")
      {
        mName = "프리미엄멤버십"
      }
      else if(this.mLv == "S")
      {
        mName = "스탠다드멤버십"
      }
      else if(this.mLv == "U")
      {        
        mName = "멤버십업그레이드"
      }
      else if(this.mLv == "ES" || this.mLv == "EP")
      {
        mName = "멥버십기한연장"
      }

      if(this.mLv == "P" || this.mLv == "S")  //멥버쉽가입(혜택기간반영)
      {
        if(joinDate_tmp < '20240304')
        {
          joinDate = '2024-03-03'
          oneYearLater = '2025-04-30'
        }
        else
        {
          joinDate = yyyy +""+ mm +""+ dd
          // 1년 후의 날짜 계산
          oneYearLater_tmp = new Date(today.getFullYear() + 1, today.getMonth(), 1);
          // 해당 달의 말일로 설정
          oneYearLater = this.getDateFormat(oneYearLater_tmp.setMonth(oneYearLater_tmp.getMonth() + 1, 0))
        }

        try
        {
          let res_joinMembership = await this.$api("/api/proc_joinMembership", {param: [
            mShipFlag, this.user.email,
            this.engName, this.uPassport, this.getDateFormat(this.uPassportExp), this.mLv, joinDate, oneYearLater, mUseFlag
          ]})

          // 에러가 포함되어 있다면 에러 메시지 표시
          if (res_joinMembership.error) {
            this.$swal.fire(
              "에러 발생",
              "멤버십 가입 진행 중 오류가 발생했습니다: " + res_joinMembership.error.sqlmessage,
              "error"
            );

            // 에러 로깅 (옵션)
            console.error("프로시저 호출 에러:", res_joinMembership.error);
            return false;
          }

          else
          {
            if(this.mLv === "S")
            {
              imageUrl = '/IMGs/Standard.jpg'
            }
            else if(this.mLv === "P")
            {
              imageUrl = '/IMGs/Premium.jpg'
            }

            this.$swal.fire({
              html: '</b>멤버십 가입을 환영합니다! <br> 재로그인 후 다양한 혜택을 누려보세요!',
              imageUrl: imageUrl,
              imageWidth: 320,
              imageHeight: 240,
              imageAlt: 'Join membership',
            })

            this.sendKakao("MJO", payDate, oneYearLater, this.mLv)
            //this.toB2CLogout()
          }
        }
        catch(error) 
        {
          console.error("프로시저 호출 에러:", error);
          return false;
        }
      }

      else if(this.mLv === "U")  //멤버십등업
      {
        let mUp = "P"

        try
        {
          let memberUpgrade = await this.$api("/api/proc_memberUpgrade", {param: [
            this.user.email,
            this.engName, this.uPassport, this.getDateFormat(this.uPassportExp), mUp
          ]})

          // 에러가 포함되어 있다면 에러 메시지 표시
          if (memberUpgrade.error) {
            this.$swal.fire(
              "에러 발생",
              "멤버십 가입 진행 중 오류가 발생했습니다: " + memberUpgrade.error.message,
              "error"
            );

            // 에러 로깅 (옵션)
            console.error("프로시저 호출 에러:", memberUpgrade.error);
            return false;
          }
          else
          {
            this.$swal.fire({
              html: '<b class="text-success">'+ mName + '</b>가 완료되었습니다.<br>재로그인 후 엔데이트립 일일버스투어를 무제한으로 누려보세요.',
              imageUrl: '/IMGs/Premium.jpg',
              imageWidth: 320,
              imageHeight: 240,
              imageAlt: 'Renewal membership',
            })
            this.sendKakao("MUP", payDate, this.getDateFormat(this.memExpDate), this.mLv)
            //this.toB2CLogout()
          }          
        }
        catch(error) 
        {
          console.error("프로시저 호출 에러:", error);
          return false;
        }
      }

      else if(this.mLv == "EP" || this.mLv == "ES")  //멤버십연장
      {
        let currentDate = new Date(this.memExpDate);
        oneYearLater_tmp = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), 1);
        // 해당 달의 말일로 설정
        oneYearLater = this.getDateFormat(oneYearLater_tmp.setMonth(oneYearLater_tmp.getMonth() + 1, 0))
        //console.log("oneYearLater:",oneYearLater)

        try
        {
          let memberRenewal = await this.$api("/api/proc_memberRenewal", {param: [
            this.user.email,
            this.engName, this.uPassport, this.getDateFormat(this.uPassportExp), oneYearLater
          ]})

          if (memberRenewal.error) {
            this.$swal.fire(
              "에러 발생",
              "멤버십 가입 진행 중 오류가 발생했습니다: " + memberRenewal.error.message,
              "error"
            );

            // 에러 로깅 (옵션)
            console.error("프로시저 호출 에러:", memberRenewal.error);
            return false;
          }
          else
          {
            if(this.mLv === "ES")
            {
              imageUrl = '/IMGs/Standard.jpg'
            }
            else if(this.mLv === "EP")
            {
              imageUrl = '/IMGs/Premium.jpg'
            }

            this.$swal.fire({
              html: '<b class="text-success">'+ mName + '</b>이 완료되었습니다.<br>재로그인 후 엔데이트립 일일버스투어를 무제한으로 누려보세요.',
              imageUrl: imageUrl,
              imageWidth: 320,
              imageHeight: 240,
              imageAlt: 'Renewal membership',
            })
            this.sendKakao("MEX", payDate, oneYearLater, this.mLv)
            //this.toB2CLogout()
          }
        }
        catch(error)
        {
          console.log("ProcError:", error)
        }
      }

      else
      {
        this.$swal.fire("","처리 중 문제가 발생했습니다.<br>문의처: 1660-4602","warning")
        return false;
      }
    },

    toB2CLogout() {
      window.Kakao.Auth.logout((response) => {
        this.$store.commit("user", {});       // 로그아웃->카카오유저 정보를 빈값으로 대체
        
        window.sessionStorage.clear();        // 로그아웃->세션스토리지를 모두 삭제
        window.localStorage.clear();
        console.log(response);
        
        //this.$router.push({ path: "/" });    // 로그아웃->메인페이지로
        location.href = "/"
      });
    },

    /** 직원정보추출 */
    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
    },

    /** 카카오알림 발송 */
    async sendKakao(param, payDate, expDate, mLv)  //param: P, S
    {
      //console.log(payDate, expDate)  //결제일, 멤버십기한

      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.user.email, param, this.user.alimCallC
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.user.alimCallC})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0) 
      {
        let membership = ""
        if(mLv === "P" || mLv == "U")
        {
          membership = "엔데이트립 프리미엄멤버십"
        }
        else if(mLv == "S") 
        {
          membership = "엔데이트립 스탠다드멤버십"
        }
        else if(mLv == "ES" || mLv == "EP") 
        {
          membership = "엔데이트립 멤버십연장"
        }        
        //console.log(membership)

        let promises = []

        //결제요청 카카오 알림톡(결제자)
        let i = 0
        while(i < this.handTelCnt)
        {
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제완료)
            clientName: this.user.nickname,
            membership: membership,
            memPayDate: payDate,
            memPayAmt: this.getCurrencyFormat(this.total_amount),
            memExpDate: expDate,
            resCode: "",
            alimFlag: param, //알림유형(결제요청)
            clientCall: this.handTel[i].aimCall,       //알림톡발송대상
          };
          let promise = this.$api("api/kakaoAlimSend6", {param: [{ kakaoAlimData: kakaoAlimData}]})
          promises.push(promise)
          i++
        }
        //반복문 이후 처리
        Promise.all(promises).then(() => {
          //console.log(results)
          this.$swal.fire('', '멤버십결제완료 알림톡이 발송되었습니다', 'success').then((res) => {
            console.log(res)
            this.toB2CLogout()
          })
        }).catch((error) => {
          console.log("Error calling api: ", error)
          this.toB2CLogout()
        })       
      }
    },
  }
}
</script>
