<template>
  <div class="pointlist">
    <main class="py-4">
      <div class="container my-4">
        <div class="row justify-content-center">
          <div class="col-12">
            <h2 class="d-flex justify-content-center" style="font-weight:600">포인트 정보</h2>

            <!-- 윗 포인트 안내 -->
            <div class="top">
              <div class="container py-4 justify-content-center">
                <div class="row col-12">
                  <div class="col-3 text-center align-self-center">
                    <img class="img-fluid" src="../assets/img/point.png" alt="">
                  </div>
                  <div class="col-9 text-start align-self-center incont">
                    <p class="mb-0">현재 사용가능한 포인트는</p>
                    <p class="mb-0 nowp"><b class="text-primary">{{ this.getCurrencyFormat(this.availPoint) }}</b> P 입니다.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 현황조회 -->
        <div class="float-start mb-3">
          <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="con" @change="changeCondition(con)">
            <option :value="noVal" disabled>현황조회</option>
            <option value="all" >전체</option>
            <option value="A" >적립</option>
            <option value="U" >사용</option>
            <!-- <option value="S" >사용대기</option> -->
          </select>
        </div>

        <!-- 포인트 조회 -->
        <table class="table">
          <thead>
            <tr>
              <th>상태</th>
              <th>포인트</th>
              <th>일자</th>
              <th>상품명</th>
              <th>비고사항</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(uPoint, i) in resUserPoint">
              <td>{{ uPoint.pStatus }}</td>
              <td v-if="uPoint.useFlag === 'U' || uPoint.useFlag === 'S'" class="text-danger">{{ this.getCurrencyFormat(uPoint.userPoint) }}</td>
              <td v-else-if="uPoint.useFlag === 'A' || uPoint.useFlag === 'W'" class="text-primary">{{ this.getCurrencyFormat(uPoint.userPoint) }}</td>
              <td>{{ this.getDateFormat(uPoint.pointAddDate) }}</td>

              <td class="text-primary" v-if="uPoint.reserNo == undefined">
                <a @click="goToReqReserveDetail(uPoint.reserNo,uPoint.pdtCode,uPoint.ntCd);" style="cursor:pointer;">
                  {{ uPoint.pdtNameKor }}
                </a>
              </td>              
              <td class="text-primary" v-else-if="uPoint.reserNo.substring(0,2) === 'EV'">
                <a @click="goToReqReserveDetail(uPoint.reserNo,uPoint.pdtCode,uPoint.e_ntCd);" style="cursor:pointer;">
                  {{ uPoint.pdtNameKor }}
                </a>
              </td>
              <td class="text-primary" v-else-if="uPoint.reserNo.substring(0, 2) != 'EV'">
                <a @click="goToReqReserveDetail(uPoint.reserNo,uPoint.pdtCode,uPoint.p_ntCd);" style="cursor:pointer;">
                  {{ uPoint.pdtNameKor }}
                </a>
              </td>              
              <td>{{ uPoint.pointRemarks }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import {getAvailPoint} from "../assets/js/commonFunctions.js" //call the namedFunction
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      rProc: [],
      resUserPoint: [],
      availPoint: 0 // 사용자 가용포인트
      //reqList: [],
    };
  },
  created() {
    this.uEmail = this.$route.query.id;
    // console.log("userInfo:",this.$store.state.user);
    // console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);
    // console.log("uEmail:", this.uEmail);

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }    
    this.getUserPoint();
    this.getAvailPoint();

  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    // 숫자 3자리마다 콤마 찍기
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },    
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async changeCondition(con) {
      //alert("con:"+con)
      this.getUserPoint(con);
    },
    async getUserPoint(con) 
    {
      if (con === "all" || con === "" || con === undefined) 
      {
        con = "%"; 
      }

      let resUserPoint = await this.$api("/api/getUserPoint", {param: [this.uEmail, con]});
      if(resUserPoint.length > 0) 
      {
        this.resUserPoint = resUserPoint;
      }
      console.log("resUserPoint:",this.resUserPoint);
    },
    async getAvailPoint() 
    {
      this.availPoint = await getAvailPoint(this.$api, this.uEmail)
    },
    goToReqReserveDetail(seqReq, pCode, ntCode1)
    {
      console.log(seqReq, pCode, ntCode1)
      if(seqReq.substr(0,2) === "RQ")
      {
        this.$router.push({path: '/reserveInfoDetail', query: {seqReq: seqReq, pCode: pCode, cCode: 'Y'}});
      }
      else
      {
        this.$router.push({path: '/eventInfo', query: {eCode: seqReq, ntCd: ntCode1}});
      }
    },
  }
}
</script>


<style>
.pointlist .top{
  margin:30px 0 50px
}
.pointlist .top img{
  width:7rem
}
.pointlist .top .incont{
  padding-left:20px
}
.pointlist .top p{
  font-size:1rem;
  font-weight: 600;
}
.pointlist .top .nowp{
  font-size:1.7rem
}

.pointlist .table{
  border-color:transparent; 
  border-top:1px solid #000
}
.pointlist .table thead th{
  font-size:.9rem;
  font-weight:600;
  max-width:50px;
  padding:15px 0
}
@media (max-width:370px){
  .pointlist .table thead th{
    font-size:.7rem
  }
}
.pointlist .table tbody td{
  padding:10px 0
}

.pointlist .guide{
  padding:20px 0 0 10px;
  text-align:left;
  font-size:.85rem;
  line-height:23px;
}
.pointlist .guide p{
  margin-bottom: .3rem;
}
.pointlist .guide p a{
  padding:0 5px;
  color:#000;
  font-weight: 600;
  text-decoration: underline;
}
</style>
