<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>비 용 등 록</u></h2>
      <p>
        <label><font class="text-warning" style="font-size: small;">(유류할증료는 예시금액으로 표시됨)</font></label>
      </p>
    <div class="mb-3 row">
        <!-- 수정불가 -->
        <label class="col-md-3 col-form-label">코드</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productInfo.etcFeeCode" readonly>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">분류<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.feeClass" value="T" ref="rbt_feeClass">
            <label class="form-check-label text-primary" for="flexRadioDefault1">
              터미널이용료
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.feeClass" value="O">
            <label class="form-check-label text-warning" for="flexRadioDefault2">
              유류할증료
            </label>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">명칭<font class="text-secondary" style="font-size: small;">(국문)</font> <font class="text-danger">*</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_etcFeeNameKor" v-model="productInfo.etcFeeNameKor">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">Name<font class="text-secondary" style="font-size: small;">(영문)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productInfo.etcFeeNameEng">
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">적용기간<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <date-picker class="form-control" v-model="productInfo.fromDate" ref="txt_fromDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <date-picker class="form-control" v-model="productInfo.toDate" ref="txt_toDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
          </div>
        </div>
      </div>

      <div>
        <div class="mb-3 row" v-if="productInfo.feeClass === 'T'">
          <label class="col-md-3 col-form-label">국가/수단/터미널<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ntCd" v-model="productInfo.ntCd">
                <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_trCd" v-model="productInfo.trCd" @change="changeTransList()">
                <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tmCd" v-model="productInfo.tmCd">
                <option :value="termList[p].baseCode" :key=p v-for="(baseCode,p) in termList">{{termList[p].codeNameKor}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-3 row" v-if="productInfo.feeClass === 'O'">
          <label class="col-md-3 col-form-label">노선/수단/출발/교통<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <div class="input-group mb-3">
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_liCd" v-model="productInfo.liCd">
                <option :value="lineList[i].baseCode" :key=i v-for="(baseCode,i) in lineList">{{lineList[i].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_trCd1" v-model="productInfo.trCd" @change="changeTrList()">
                <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ntCd1" v-model="productInfo.ntCd">
                <option :value="nationList[k].baseCode" :key=k v-for="(baseCode,k) in nationList">{{nationList[k].codeNameKor}}</option>
              </select>
              <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_mbCd" v-model="productInfo.mbCd">
                <option :value="mobilityList[j].transCode" :key=j v-for="(transCode,j) in mobilityList">{{mobilityList[j].codeNameKor}}</option>
              </select>
            </div>
          </div>
        </div>
        <div v-else></div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">결제통화<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_cuCd" v-model="productPrice.cuCd">
              <option :value="currencyList[i].baseCode" :key=i v-for="(baseCode,i) in currencyList">{{currencyList[i].codeNameKor}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">비용정보<font class="text-danger">*</font>
          <p v-if="productInfo.feeClass === 'T'"><font class="text-danger" style="font-size:small;">1:성인 / 2:소아 / 3:인펀트 / 4:기타할인</font></p>
          <p><font class="text-secondary" style="font-size:small;">(미입력시 0원)</font></p>
        </label>
        <div class="col-md-9" v-if="!productInfo.feeClass">
        </div>
        <div class="col-md-9" v-else-if="productInfo.feeClass === 'T'">
          <div class="input-group mb-3" :key="i" v-for="i in 4">
            <span class="input-group-text">{{i}}</span>
            <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice[i]" v-model="productPrice.pdtPrice[i]">
          </div>
        </div>
        <div class="col-md-9" v-else-if="productInfo.feeClass === 'O'">
          <div class="input-group mb-3">
            <span class="input-group-text">1</span>
            <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice1" v-model="productPrice.pdtPrice1">
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">특기사항</label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
            v-model="productInfo.pdtRemarks" maxlength="210"></textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList">취소</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>
    </div>
  </main>

</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      nationList: {},
      transList: {},
      termList: {},
      mobilityList_tmp: {},
      mobilityList: {},
      lineList: {}, //노선(국제-국내)
      currencyList: {},

      productInfo: {
        // TB_Etc_Fee
        feeClass: "",
        etcFeeCode: "", // 상품코드
        etcFeeNameKor: "", //코드명(한글)
        etcFeeNameEng: "",
        ntCd: "", //국가코드
        trCd: "", //운송수단코드
        tmCd: "", //터미널코드
        mbCd: "", //교통편코드
        fromDate: "", //적용시작
        toDate: "", //적용종료
        liCd: "", //노선코드
        useFlag: "1", // 초기값은 불적용(1)
        pdtRemarks: "",
        insDate: "",
        insId: "",
      },

      productPrice: {
        cuCd: "", //통화코드
        pdtPrice1: 0,
        pdtPrice: [0,0,0,0,0]
      }
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.getNationList();
    this.getTransList();
    this.getMobilityList();
    this.getLineList(); //노선리스트
    this.getCurrencyList(); //통화
  },
  mounted() {
    console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    // callTest(){
    //   let i=1;
    //   while(i<5) {
    //     console.log("priceInof",i,":",this.productPrice.pdtPrice[i])
    //     i=i+1;
    //   }
    // },
    async getCurrencyList() {
      let currencyList = await this.$api("/api/currencyList", {});
      this.currencyList = currencyList;
      //console.log("nationList",nationList);
    },
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      //console.log("nationList",nationList);
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      console.log("mobilityList_tmp",this.mobilityList_tmp);
    },
    changeTransList() {
      this.getTerminalList(this.productInfo.ntCd, this.productInfo.trCd);
      //console.log("trCd:",this.transCode);
    },
    async getTerminalList(param1, param2) {
      //console.log(param1, param2);
      //let param3 = (param2 === "SHP") ? "ptCd" : "apCd"
      let param3 = "";
      if(param2 === "SHP") {
        param3 = "ptCd"
      }
      else if (param2 === "FLT") {
        param3 = "apCd"
      }
      else {
        this.productInfo.trCd = "";
        this.productInfo.tmCd = "";
        return this.$swal.fire("","대상이 아닙니다.","info");
      }

      this.termList = await this.$api("/api/termList", {param: [param1, param3]});
      //console.log("mobilityList",this.mobilityList_tmp);
    },
    async getLineList() {
      let lineList = await this.$api("/api/lineList", {});
      this.lineList = lineList;
      //console.log("lineList",lineList);
    },
    changeTrList(){
      //alert("trCd:"+this.productInfo.trCd);
      this.mobilityList = this.mobilityList_tmp.filter(e => {return e.categoryCode === this.productInfo.trCd});
      //console.log("mobilityList:", this.mobilityList);
    },
    goToList() {
      this.$router.push({path: '/etcPrice'});
      //this.getMobility
    },
    productInsert() {
      // e.preventDefault();
      // 입력 validation check
      let timeStamp = "";
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime(); // 코드에 들어갈 타임스탬프
      let catCode = this.productInfo.feeClass;
      //this.productInfo.feeClass = catCode;
      this.productInfo.etcFeeCode = catCode+"-"+ymd+"-"+timeStamp;
      this.productInfo.insDate = `${yyyy}-${mm}-${dd}`;
      this.productInfo.insId = this.user.handlerId;
      
      if(!this.productInfo.feeClass) {
        return this.$swal.fire("","필수누락: 분류");
      }
      if(!this.productInfo.etcFeeNameKor) {
        this.$refs.txt_etcFeeNameKor.focus();
        return this.$swal.fire("","필수누락: 명칭(국문)");
      }
      if(!this.productInfo.fromDate) {
        this.$refs.txt_fromDate.focus();
        return this.$swal.fire("","필수누락: 적용개시일");
      }
      if(!this.productInfo.toDate) {
        this.$refs.txt_toDate.focus();
        return this.$swal.fire("","필수누락: 적용종료일");
      }
      if(!this.productPrice.cuCd) {
        this.$refs.sel_cuCd.focus();
        return this.$swal.fire("","필수누락: 통화");
      }

      this.productInfo.fromDate = moment(this.productInfo.fromDate).format("YYYY-MM-DD");
      this.productInfo.toDate = moment(this.productInfo.toDate).format("YYYY-MM-DD");

      // 등록전 확인
      this.$swal.fire({
        title: '등록 진행?',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // await this.$api("/api/produtInsert", {param: [this.productInfo]});
          let resIns_product_m = await this.$api("/api/etcFeeInsert", {param: [this.productInfo]});
          console.log("errCheck:", Object.keys(resIns_product_m)[0]);
          // 인서트오류
          if(Object.keys(resIns_product_m)[0] === 'error') {
            this.$swal.fire('기본정보 저장오류', '', 'alert')
          }
          else{
            //터미널 이용료 저장
            if(this.productInfo.feeClass === "T") {
              let i=1;
              while(i < 5) {
                let resIns_product_prc = await this.$api("/api/etcFeePrcInsert",
                // etcFeeCode, agegroup, ageFee, useFlag, insId, insDate
                {param: [this.productInfo.etcFeeCode, i, this.productPrice.pdtPrice[i], '1', this.productPrice.cuCd, this.productInfo.insId]});
                  //console.log(resIns_product_prc);
                  // 인서트오류처리
                  if(Object.keys(resIns_product_prc)[0] === 'error') {
                    alert("비용정보 저장오류");
                  }
                  i++;
              }
            }
            // 유류할증료 저장
            else if(this.productInfo.feeClass === "O") {
              //await this.$api("/api/productTmpImgInsert",{param: this.productInfo.pdtCode});
              let resIns_product_prc = await this.$api("/api/etcFeePrcInsert", 
                {param: [this.productInfo.etcFeeCode, 1, this.productPrice.pdtPrice1, '0', this.productPrice.cuCd, this.productInfo.insId]});
                  if(Object.keys(resIns_product_prc)[0] === 'error') {
                    alert("비용정보 저장오류");
                  }
            }
            // let resIns_product_prc = await this.$api("/api/productPrcInsert", {param: [this.productPrice]});
            // console.log(resIns_product_prc)
          }
          // this.$swal.fire('등록완료!', '', 'success')
          // 등록 후 라우터를 통해 상품관리리스트로
          this.$router.push({path: '/etcPrice'});
        }
      })
    }
  }
}
</script>