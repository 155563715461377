<template>
  <main class="mt-5">

    <div class="container inquiy text-center">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <h2 class="d-flex justify-content-center" style="font-weight:600">상 품 문 의</h2>

          <div class="row justify-content-center">
            <div class="col-12 col-md-8">
              <div class="box">
                <hr>
                <p class="head">
                  ※ 해당 여행상품에 대해 궁금하신 것이 있으신가요?<br>
                  담당 직원이 확인하여 곧 답변을 드리겠습니다.<br><br>
                  카카오톡 플러스 친구로 문의를 주시면,<br>
                  더욱 빠른 답변을 받아보실 수 있습니다.
                </p>
                <!-- <p class="qinfo"><input type="checkbox" v-model="ruleAgree" ref="chb_ruleAgree" class="align-bottom mt-4">
                &nbsp;개인정보 수집에 동의합니다.
                </p> -->

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <input class="form-check-input" type="checkbox" v-model="ruleAgree" ref="chb_ruleAgree">
                      <label class="form-check-label" for="defaultCheck3">
                        <span class="text-danger px-2">[필수]</span> 개인정보 수집 및 이용동의
                      </label>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      엔타비 글로벌 (이하 '엔타비')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br>
    
                      1. 개인정보 수집 및 이용목적<br>
                      엔타비는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br>
                      &nbsp; 1) 홈페이지 회원가입 및 관리<br>
                      &nbsp; 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.<br>
                      &nbsp; 2) 서비스 제공<br>
                      &nbsp;  물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.<br>
                      &nbsp; 3) 마케팅 및 광고에의 활용<br>
                      &nbsp; 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
                      <br>
                      2. 개인정보 수집 항목<br>
                      엔타비는 다음의 개인정보 항목을 처리하고 있습니다.<br>
                      &nbsp; 1) 홈페이지 회원가입 및 관리/마케팅 정보 제공의 목적 (회원탈퇴 시 까지)<br>
                      &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름<br>
                      &nbsp; 2) 상담 및 해외 및 국내 여행 예약의 목적 (신용카드정보, 은행계좌정보)<br>
                      &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 여권사본, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호<br>
                      &nbsp; 3) 여행자 보험가입의 목적 (신용카드정보, 은행계좌정보)<br>
                      &nbsp;  이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름<br>
                      &nbsp; 4) 대금결제의 목적 (이용목적이 달성되면 파기)<br>
                      &nbsp;  신용카드정보, 은행계좌정보<br>
                        <br>
                      3. 개인정보 수집 항목 보유 및 이용기간<br>
                      &nbsp; 1) 엔타비는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.<br>
                      &nbsp; 2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
                      &nbsp;  ① 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                      &nbsp;  ② 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                      &nbsp;  ③ 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                      &nbsp;  ④ 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                      &nbsp;  ⑤ 표시/광고에 관한 기록 : 6개월<br>
                    </div>
                  </div>
                </div>

                <hr>

                <!-- 상품코드 -->
                <div class="row my-3">
                  <div class="col-md-12 gx-3">
                    <label class="name col-form-label">상품코드</label>
                    <input type="text" maxlength="22" class="form-control" ref="txt_reqTitle" v-model="this.productId" disabled>
                  </div>
                </div> 

                <!-- 문의제목 -->
                <div class="row my-2">
                  <div class="col-md-12 gx-3">
                    <label class="name col-form-label">제목<font class="text-danger">*</font></label>
                    <input type="text" maxlength="22" class="form-control" ref="txt_reqTitle" v-model="this.reqInfo.reqTitle">
                  </div>
                </div>

                <!-- 성함, 인원수 -->
                <div class="row">
                  <div class="col-md-6 gx-3 my-2">
                    <label class="name col-form-label my-1">성명<font class="text-danger"> *</font></label>
                    <input type="text" maxlength="12" class="form-control" ref="txt_reqPersonName" v-model="this.reqName">
                    <!-- <input type="text" maxlength="12" class="form-control" ref="txt_reqPersonName" v-model="this.reqInfo.reqPersonName"> -->
                  </div>
                  <div class="col-md-6 gx-3 my-2">
                    <label class="name col-form-label my-1">인원수</label>
                    <input type="number" min="0" max="15" class="form-control" ref="txt_perCnt" v-model="this.reqInfo.perCnt">
                  </div>
                </div>

                <!-- 이메일, 연락처 -->
                <div class="row">
                  <div class="col-md-6 gx-3 my-2">
                    <label class="name col-form-label my-1">이메일<font class="text-danger">*</font></label>
                    <input type="email" maxlength="40" class="form-control" ref="txt_cEmail" v-model="this.reqInfo.reqEmail">
                  </div>
                  <div class="col-md-6 gx-3 my-2">
                    <label class="name col-form-label my-1">연락처(휴대폰)<font class="text-danger">*</font></label>
                    <input type="text" maxlength="11" id="reqTel" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.reqTel">
                    <!-- <input type="text" maxlength="11" id="reqTel" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.reqInfo.reqTel"> -->
                  </div>
                </div>

                <!-- 문의사항 -->
                <div class="row my-3">
                  <div class="col-md-12 gx-3">
                    <label class="name col-form-label">문의사항<font class="text-danger"> *</font></label>
                    <textarea class="form-control" maxlength="248"  placeholder="문의사항을 입력하세요" ref="txt_reqSpRemarks" id="floatingTextarea" 
            style="height:150px" v-model="this.reqInfo.reqSpRemarks"></textarea>
                  </div>
                </div> 

              </div>
            </div>
          </div>

          <!-- 버튼 -->
          <div class="mt-5 row justify-content-center mb-4">
            <button class="btn cxl col-5 col-lg-2 mx-2" @click="goBack()">취소</button>
            <button class="btn go col-5 col-lg-2 mx-2" @click="requestInsert();">문의</button>
          </div>

        </div>
      </div>
    </div>
  </main>

</template>

<script>
//import moment from 'moment';
export default {
  data() {
    return {
      inquiryCatList: {}, //질의영역분류
      reqInfo: [],
      reqName: "",
      reqTel: ""
    };
  },
  created(){
    this.user = this.$store.state.user;
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;
    //this.user = this.$store.state.user;

    //this.getInquiryCatList();
    
    // console.log("userInfo:",this.$store.state.user);
    // console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);

    if (this.$store.state.user.handlerId != undefined) {
      this.userInfo = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.userInfo = this.$store.state.user.email
    }
    else {
      this.userInfo = ""
    }
    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.user.joinFlag === "H")
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.alimCallC != undefined &&this.user.alimCallC.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.alimCallC.replace("+82 ", "0")
      }
      else
      {
        kakaoPhone = this.user.alimCallC
      }
      this.k_tel = (!this.user.alimCallC) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }    
    //console.log("k_name:",this.k_name,",k_tel:",this.k_tel)
    this.reqName = this.k_name
    this.reqTel = this.k_tel
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   //this.$router.push({path: '/'});
    //   location.replace("/");
    // }

    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/b2CLogin', query:{st: "Y"}});
    }    

    //뷰를 상단으로 이동
    window.scrollTo(0, 0);
  },  
  methods: {
    async requestInsert() {
      //alert(this.reqInfo.passFlag);
      //오늘날짜추출
      const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      const time = new Date().toTimeString().split(" ")[0];
      let today = date + ' ' + time;
      let timeStamp = new Date().getTime();
      let iqCode = "IQ-" + date.replace(/-/g, '') + "-" + timeStamp;
      //상품문의코드
      this.reqInfo.iqCode = iqCode;      

      // console.log("today:", date + ' ' + time);
      if(this.ruleAgree == "" || !this.ruleAgree) { 
        this.$refs.chb_ruleAgree.focus();
        return this.$swal.fire("","진행을 위해 개인정보 수집동의가 필요합니다.", "warning");
      }
      // if(this.reqInfo.selCat === "noVal" || this.reqInfo.selCat == undefined) { 
      //   this.$refs.sel_inquiry.focus();
      //   return this.$swal.fire("","문의영역을 선택해주세요.", "question");
      // }
      if(!this.reqInfo.reqTitle) { 
        this.$refs.txt_reqTitle.focus();
        return this.$swal.fire("","문의제목을 입력해주세요", "warning");
      }
      if(!this.reqName) {
        this.$refs.txt_reqPersonName.focus();
        return this.$swal.fire("","성명을 입력해주세요", "warning");
      }
      if(!this.reqInfo.reqEmail) {
        this.$refs.txt_cEmail.focus();
        return this.$swal.fire("","이메일을 입력해주세요", "warning");
      }
      if(!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]+$/.test(this.reqInfo.reqEmail)) {
        this.$refs.txt_cEmail.focus();
        return this.$swal.fire('','이메일 양식이 불명확합니다', 'question');
      }
      if(!this.reqTel) {
        this.$refs.txt_cTel.focus();
        return this.$swal.fire("","연락처를 입력해주세요", "warning");
      }
      if(!/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(this.reqTel)) {
        this.$refs.txt_cTel.focus();
        return this.$swal.fire('','연락처가 불명확합니다', 'question');
      }
      if(!this.reqInfo.reqSpRemarks) {
        this.$refs.txt_reqSpRemarks.focus();
        return this.$swal.fire("","문의내용을 입력해주세요", "warning");
      }
      this.reqInfo.insDate = today;
      this.reqInfo.reqStatus = "IQ";

      let resIns_Request = await this.$api("/api/inquiryInsert", {param: [
          "PD",this.reqInfo.reqTitle,this.reqName,this.reqInfo.perCnt,this.reqInfo.reqEmail,
        this.reqTel,"N",this.reqInfo.reqSpRemarks,this.ruleAgree,this.reqInfo.reqStatus,this.userInfo, this.productId,this.reqInfo.iqCode
      ]});
      if(Object.keys(resIns_Request)[0] === 'error') {
        this.$swal.fire('','문의등록에 오류가 발생했습니다.<br>(문의처: 1660-4602)','')
      }
      else 
      {
        this.sendKakao("IQ")
        this.$swal.fire('','빠른 시간안에 확인하고 회신을 드리겠습니다.', '');
        //this.$router.push({path: '/'});
        location.reload(true)
      }
      //console.log("resIns_Request:",resIns_Request);
    },
    /** 카카오알림 발송(문의접수 IQ) */
    //param: BO/BO_non
    async sendKakao(param)
    {
      //alert("param:"+param);
      //let resCode = this.eventCode
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.reqInfo.iqCode, param, this.userInfo
      ]});
      console.log("insKSendHistory:",insKSendHistory);
      if(insKSendHistory.affectedRows > 0) {
        if(param == "IQ") 
        {
          //문의등록안내
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(예약접수)
            clientName: this.reqName,               //수신자명
            clientCall: this.reqTel,       //수신자전번
            iqCode: this.reqInfo.iqCode,           //문의번호
            alimFlag: param                        //알림유형(문의등록)
            //alimFlag: "r"
          };
          this.$api("api/kakaoAlimSend3", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          //this.$swal.fire('', '예약하신 내용의 안내가 발송되었습니다. 감사합니다.', '');
          //this.$router.push({path: '/'});
          //history.back();
        }
        // else if(param == "nonRP") 
        // {
        //   //비회원용 결제요청 카카오 알림톡
        //   let kakaoAlimData = 
        //   {
        //     //카카오 알림톡 발송(결제요청)
        //     clientName: this.reserveInfo.reqName,
        //     resCode: this.reqRsvCode,
        //     resName: this.productInfo.pdtNameKor,
        //     resPrice: this.getCurrencyFormat(this.reserveInfo.cstPrice_new),
        //     alimCall: this.reserveInfo.reqTelNo,
        //     alimFlag: "nonRP"
        //     //alimFlag: "r"
        //   };
        //   this.$api("api/kakaoAlimSend", {
        //     param: [
        //       { kakaoAlimData: kakaoAlimData},
        //     ]
        //   }).then((res) => {console.log(res)});
        //   this.$swal.fire('', '비회원용 결제요청 알림톡이 발송되었습니다', 'success');
        //   //this.$router.push({path: '/'});
        //   //history.back();
        // }
      }
    },    
  goBack() {
    //this.$router.push({path: '/'});
    history.back();
    }
  }
}
</script>

<style>
.inquiy{
  margin-top:5rem
}
.inquiy .box {
  text-align:left
}
.inquiy .box .head{
  text-align: center;
  font-size:.9rem;
  font-weight:bold;
}
.inquiy .box .qinfo{
  text-align: center;
  margin:1rem 0 2rem;
}
.inquiy .box .name{
  font-size:.9rem;
  font-weight:bold
}
.inquiy .box .form-select{
  border-radius: 0.375rem !important
}

.inquiy .go {
  padding:15px 0;
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.inquiy .cxl{
  padding:15px 0;
  background-color: #eee;
  border-radius:0;
}
.inquiy .go:hover{
  background-color: #444;
}
.inquiy .cxl:hover{
  border:1px solid #777
}



/* 동의부분 */
.inquiy .accordion-button{
  display:block;
  text-align: center;
  padding:10px 0 15px
}

.inquiy .accordion-body{
  font-size:.8rem;
  line-height: 20px;
}
</style>
