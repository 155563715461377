<template>
  <div id="bsu_summer_ten">
    <section>
      <div class="bsu__container">
        <div class="bsu_summerten_bg">
          <img class="summerten_bg" src="../assets/img/summerten/bg.png" alt="">
          <img class="summerten_mobile" src="../assets/img/summerten/mobile.png" alt="">
          <img class="bsu_title" src="../assets/img/summerten/main_title.png" alt="">
        </div>
        <div class="best_title">
          <img class="tube" src="../assets/img/summerten/tube.png" alt="">
          <img class="title1" src="../assets/img/summerten/title1.png" alt="">
        </div>
        <div class="best_line">
          <div class="best_item">
            <img class="best_size" src="../assets/img/summerten/osaka.png"
              @click="goToDetail('L-2023220-1676865317720', 'L')" alt="">
            <p>
              <span class="name_mid">난바 도톤보리 출발 / 후시미이나리 / 청수사 / 금각사 / 아라시야마 / 도게츠교(or 후후노유온천) </span>

              <br> <span class="big">64%</span> &nbsp;&nbsp; <span class="gray_line">55,000원</span>&nbsp; <span
                class="mid">19,800원!</span>
              <br>
              <span class="last_name">

                <br>· 1명부터 출발 확정(매일 출발!)
                <br>· 오사카 교토 전문 한국인 가이드!
                <br> · 쾌적하고 시원한 전용버스!
                <br> · 불필요한 쇼핑NO, 강제 옵션NO!
                <br> · 돈키호테 등 다양한 현지 쿠폰 제공!</span>
            </p>
          </div>

          <div class="best_item">
            <img class="best_size" src="../assets/img/summerten/aso.png"
              @click="goToDetail('L-2023221-1676942590519', 'L')" alt="">
            <p>
              <span class="name_mid">
                하카타역 출발 / 쿠사센리 / 아소신사&몬젠마치 / 아소산 대관봉 / 쿠로가와 온천 입욕</span>

              <br> <span class="big">48%</span> &nbsp;&nbsp; <span class="gray_line">75,000원</span>&nbsp; <span
                class="mid">39,000원!</span>
              <br>
              <span class="last_name"><br>
                · 텐진 하차 선택 가능!
                <br>
                · 규슈 후쿠오카 전문 한국인 가이드!
                <br>· 쾌적하고 시원한 전용버스!
                <br>· 불필요한 쇼핑NO, 강제 옵션NO!
                <br>· 돈키호테 등 다양한 현지 쿠폰 제공!</span>
            </p>
          </div>

          <div class="best_item">
            <img class="best_size" src="../assets/img/summerten/biei.png"
              @click="goToDetail('L-202346-1680743217094', 'L')" alt="">
            <p>
              <span class="name_mid">오오도리역 출발 / 사계채의 언덕 / 탁신관 / 비에이역 / 청의 연못 / 흰수염폭포 / 팜토미타 </span>

              <br> <span class="big">50%</span> &nbsp;&nbsp; <span class="gray_line">89,000원</span>&nbsp; <span
                class="mid">44,500원!</span>
              <br>
              <span class="last_name">
                <br>· 북해도 버스투어 코스 연계 할인 상품!
                <br>· 1명부터 출발 확정(매일 출발!)
                <br>· 북해도 전문 한국인 가이드
                <br>· 비에이역 맛집 준페이 예약 가능!
                <br>· 라벤더 아이스크림 1인 1개 제공 특전!

                <br>· 쾌적하고 시원한 전용버스!
                <br>· 불필요한 쇼핑NO, 강제 옵션NO!
                <br>· 돈키호테 등 다양한 현지 쿠폰 제공!</span>
            </p>
          </div>

        </div>
        <div class="bsu_summerten_member_line">
          <a href="/membershipPage">
            <img src="../assets/img/summerten/memberbanner.png" alt="">
            <img src="../assets/img/summerten/memberaa.png" alt="">
          </a>

        </div>

        <div class="member_botten">
          <a href="/membershipPage">멤버십 자세히보기</a>
        </div>

        <div class="title2_line">
          <img class="tube2" src="../assets/img/summerten/tube2.png" alt="">
          <img class="title2" src="../assets/img/summerten/title2.png" alt="">
        </div>

        <div class="place_line">
          <div>
            <img class="best_size" src="../assets/img/summerten/l1.png"
              @click="goToDetail('L-2023221-1676943535719', 'L')" alt="">
            <p><span class="gray_line">99,000원</span> &nbsp;<span class="mid">88,000원</span> </p>
          </div>

          <div>
            <img class="best_size" src="../assets/img/summerten/l2.png"
              @click="goToDetail('L-2023329-1680070456245', 'L')" alt="">
            <p><span class="gray_line">99,000원</span> &nbsp;<span class="mid">88,000원</span> </p>
          </div>

          <div>
            <img class="best_size" src="../assets/img/summerten/l3.png"
              @click="goToDetail('L-2024228-1709084594375', 'L')" alt="">
            <p><span class="gray_line">99,000원</span> &nbsp;<span class="mid">88,000원</span> </p>
          </div>

          <div>
            <img class="best_size" src="../assets/img/summerten/l4.png" @click="goToDetail('L-2431993LGC', 'L')" alt="">
            <p><span class="gray_line">99,000원</span> &nbsp;<span class="mid">88,000원</span> </p>
          </div>

          <div>
            <img class="best_size" src="../assets/img/summerten/l5.png" @click="goToDetail('L-2432173MCC', 'L')" alt="">
            <p><span class="gray_line">99,000원</span> &nbsp;<span class="mid">88,000원</span> </p>
          </div>

          <div>
            <img class="best_size" src="../assets/img/summerten/l6.png"
              @click="goToDetail('L-2023221-1676948010488', 'L')" alt="">
            <p><span class="gray_line">69,000원</span> &nbsp;<span class="mid">59,000원</span> </p>
          </div>

          <div>
            <img class="best_size" src="../assets/img/summerten/l7.png"
              @click="goToDetail('L-2024312-1710224515956', 'L')" alt="">
            <p><span class="gray_line">99,000원</span> &nbsp;<span class="mid">88,000원</span> </p>
          </div>
        </div>
        <div class="last_ment">
          <img src="../assets/img/summerten/source4.png" alt="">
          <p class="last_ment_p">좋은 여행과 서비스 제공을 위해 항상 발전하겠습니다!
            <br>

            <br>예약 가능 일자 7월 한 달간!
            <br>
            투어 탑승 가능 일자 7월~12월! <br>
            해당 이벤트는 조기 마감될 수 있습니다.
          </p>
        </div>
      </div>

    </section>
  </div>
</template>

<script>

import defaultPath from '@/assets/img/taiwan/back.jpg';

export default {
  // DB에서 가져온 데이타
  data() {
    return {
      //imagePath: "/IMGs/testtaiwan/back.jpg"   //상단이미지 default
      imagePath: defaultPath,   //상단이미지 default
      animateImage: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }

  },
  methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({ path: '/detail', query: { productId: productId, salesRoute: salesRoute } });
    }
  },
  mounted() {

    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요', 
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'}); 
    // }
  }
}

</script>

<style>
#bsu_summer_ten {
  margin-top: -56px;
}

.summerten_mobile {
  display: none;
}

.summerten_bg {
  width: 100%;
}

.bsu_title {
  position: absolute;
  top: 630px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}

.best_title {
  width: 800px;
  height: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tube {
  position: relative;
  top: 65%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.title1 {
  position: relative;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.best_line {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.best_size {
  width: 400px;
  margin: 0 30px;
  cursor: pointer;
}

.best_item {
  width: 400px;
  margin: 0 50px;
}
.best_item p{
  margin:10px 30px;
}


/*---------------------------------------------text start---------------------*/
.name_mid {
  font-size: 16pt;
  font-weight: 600;
}

.gray_line {
  text-decoration: line-through;
  font-size: 12pt;
}

.big {
  color: rgb(216, 7, 42);
  font-size: 22pt;
  font-weight: bold;
}

.mid {
  color: #000;
  font-size: 18pt;
  font-weight: bold;
}

/*---------------------------------------------text end------------------*/
.bsu_summerten_member_line {
  width: 60%;
  margin: 100px auto;
}

.bsu_summerten_member_line img {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.member_botten {
  width: 300px;
  height: 100px;
  display: block;
  margin: 0 auto;


}

.member_botten a {
  padding-top: 35px;
  text-align: center;
  display: block;
  width: 300px;
  height: 100px;
  border-radius: 30px;
  background-color: rgb(37, 152, 226);
  color: #fff;
  font-weight: bold;
  font-size: 16pt;
}

.title2_line {
  width: 800px;
  height: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title2 {
  position: relative;
  top: 30%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.tube2 {
  position: relative;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}



/*--------------------------------------------7 place ---------------------------------*/


.place_line {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  margin: 0 auto;
  justify-content: space-evenly;
}

.place_line img {
  margin-top: 90px;
}

.place_line p {
  width: 60%;
  margin: 10px 0px 0 50px;

}

/*--------------------------------------------7 place ---------------------------------*/
.last_ment {
  width: 60%;
  margin: 100px auto;
}

.last_ment_p {
  margin-top: 30px;
  text-align: center;
  font-size: 20pt;
  color: #000;
  font-weight: bold
}

/*--------------------------------------------1920px - pc ---------------------------------*/

@media (max-width: 1920px) and (min-width: 701px) {
  .place_line {

    width: 80%;
    margin: 0 auto;
  }

  .place_line img {
    margin-top: 60px;
  }

  .summerten_bg {
    width: 100%;
  }

  .last_ment img {
    width: 100%;
  }
}

/*-------------------------------------------1920px pc end -------------------------------*/






/*-------------------------------------------mobile start -------------------------------*/

@media (max-width: 700px) {
  .summerten_mobile {
    display: block;
    width: 100%;
  }

  .bsu_summerten_bg {
    margin-top: 91px;
  }

  .summerten_bg {
    display: none;
  }

  .bsu_title {
    position: absolute;
    top: 280px;
    width: 90%;
  }

  .best_title {
    width: 100%;
    height: 210px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .best_line {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .best_size {
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
  }

  .best_line_pay {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .best_line_pay p {
    width: 80%;
    margin: 0 auto;
  }

  .last_ment img {
    width: 100%;
  }

  .title2_line {
    width: 100%;
    height: 180px;
  }

  .title2 {
    width: 80%;
    margin: 0;
  }

  .best_item {
    width: 90%;
    margin: 0 auto;
  }

  .best_item p {
    margin: 0 auto 100px;
    width: 90%;

  }

  .bsu_summerten_member_line {
    width: 95%;
    margin: 0 auto;
  }

  .member_botten {
    width: 80%;
    margin: 30px auto;
    height: 80px;
  }

  .member_botten a {
    width: 80%;
    margin: 0 auto;
    height: 80px;
    padding-top: 23px;
  }

  .place_line {
    width: 90%;
  }

  .place_line p {
    margin: 20px auto;
  }

  .last_ment {
    width: 90%;
  }

  .last_ment_p {
    font-size: 10pt;
  }

  .place_line img {
    margin-top: 9px;
  }
}
</style>
