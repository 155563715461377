<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>견 적 상 세</u></h2>
      <div class="mb-1 mt-1 row">
        <div class="mt-3">
          <button class="btn btn-warning" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
            <i class="bi bi-exclamation-triangle text-danger"></i>&nbsp;필수확인사항 (클릭)
          </button>
        </div>
        <div style="min-height: auto" class="mt-2">
          <div class="collapse collapse-horizontal" id="collapseWidthExample">
            <div class="card card-body bg-secondary shadow-sm p-3 mb-5 bg-body rounded text-success" style="width: auto">
              <small>예약(또는 구매)은 대금결제가 확인된 후, 진행됩니다.</small>
              <small>문의만으로 확정되는 것이 아니며, 당사의 안내 후, 확정됩니다.</small>
              <small>확정 후 취소되는 경우, 소정의 수수료가 발생할 수 있습니다.</small>
              <small>상기내용을 확인하였으며, 동의합니다. &nbsp;&nbsp;
                <span class="text-danger">
                  동의( <input type="checkbox" v-model="this.quotationInfo.ruleAgree" ref="chb_ruleAgree" true-value="Y" false-value="N" disabled> )
                </span>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">견적상태/번호</label>
        <div class="col-md-9">
          <div class="input-group">
            <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_inquiry" v-model="this.quotationInfo.reqStatus">
              <option :value="noVal" disabled>-- 진행상황 --</option>
              <option :value="catList.procCode" :key="i" v-for="(catList, i) in inquiryCatList">
                {{ catList.procName }}
              </option>
            </select>
            <input type="text" class="form-control w-75 text-success" v-model = "this.quotationInfo.qtCode" readonly>
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">유형/제목</label>
        <div class="col-md-9">
          <div class="input-group">
            <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_inquiry" v-model="this.quotationInfo.selCat">
              <option :value="noVal" disabled>-- 여행유형 --</option>
              <option :value="tc.procCode" :key="i" v-for="(tc, i) in tripCategroy">
                {{ tc.procName }}
              </option>
            </select>
            <input type="text" class="form-control w-75" ref="txt_reqTitle" v-model="this.quotationInfo.reqTitle">            
          </div>
        </div>
      </div>      

      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">요청자/인원수<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" maxlength="12" class="form-control" ref="txt_reqPersonName" v-model="this.quotationInfo.reqPersonName" placeholder="요청자 성명">
            <input type="number" maxlength="4" min = 1 class="form-control" ref="txt_perCnt" v-model="this.quotationInfo.perCnt" placeholder="인원수">
          </div>
        </div>
      </div>

      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">이메일<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <input type="email" maxlength="40" class="form-control" ref="txt_cEmail" v-model="this.quotationInfo.reqEmail">
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">연락처(휴대폰)<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <input type="text" maxlength="11" id="reqTel" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.quotationInfo.reqTel">
        </div>
      </div>

      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">예상일정<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1">시작</span>
            <input type="date" class="form-control" ref="txt_evtStartDate" v-model="this.quotationInfo.startDate">
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" ref="txt_evtCloseDate" v-model="this.quotationInfo.closeDate">
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">출발지/여행국가/여행지역<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <!-- 출발도시 -->
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_deptCity" v-model="this.quotationInfo.ctCd">
              <option :value="cityList[i].baseCode" :key=i v-for="(baseCode,i) in cityList">{{cityList[i].codeNameKor}}</option>
            </select> 
            <!-- 여행국가 -->
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripNation" v-model="this.quotationInfo.ntCd" @change="changeNationList(this.quotationInfo.ntCd)">
              <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
            </select>
            <!-- 여행지역 -->
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripArea" v-model="this.quotationInfo.arCd">
              <option :value="areaList[j].baseCode" :key=j v-for="(baseCode,j) in areaList">{{areaList[j].codeNameKor}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-2 row">
        <label class="col-md-3 col-form-label">교통편 선택</label>
        <div class="col-md-9">
          <div class="form-check-inline align-middle">
            <input class="form-check-input" type="checkbox" v-model="this.quotationInfo.transFlag" true-value="Y" false-value="N" @change="openTrans(this.quotationInfo.transFlag);">
            <label class="form-check-label " for="ckb_transFlag">&nbsp;보기</label>
          </div>
        </div>
      </div>
      <div v-if="this.quotationInfo.transFlag === 'Y'" class = "mb-3">
        <div class="row border p-sm-2 border-info">
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">출발편<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class="input-group mb-1">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="this.quotationInfo.trCd1" @change="changeDpTransList()">
                  <option :value="noVal">비이용</option>
                  <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
                </select>
                <!-- <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="this.quotationInfo.mbCd1">
                  <option :value="mobilityList_dp[m].transCode" :key=m v-for="(transCode,m) in mobilityList_dp">{{mobilityList_dp[m].codeNameKor}}</option>
                </select> -->
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="this.quotationInfo.mbCd1">
                  <option :value="noVal" disabled>선호교통편</option>
                  <option :value="mList.transCode" :key=m v-for="(mList,m) in mobilityList_dp">{{mList.codeNameKor}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">추가내용</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="quotationInfo.transRemarks_dp" maxlength="210"></textarea>
            </div>
          </div>
        </div>
        <br>
        <div class="row border p-sm-2 border-warning">
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">리턴편</label>
            <div class="col-md-9">
              <div class="input-group mb-1">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="quotationInfo.trCd2" @change="changeRtTransList">
                  <option :value="noVal">비이용</option>
                  <option :value="transList[q].baseCode" :key=q v-for="(baseCode,q) in transList">{{transList[q].codeNameKor}}</option>
                </select>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="quotationInfo.mbCd2">
                  <option :value="noVal" disabled>선호교통편</option>
                  <option :value="mobilityList_rt[r].transCode" :key=r v-for="(transCode,r) in mobilityList_rt">{{mobilityList_rt[r].codeNameKor}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">추가내용</label>
            <div class="col-md-9 mb-2">
              <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="quotationInfo.transRemarks_ar" maxlength="210"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">문의사항</label>
        <div class="col-md-9">
          <textarea class="form-control" maxlength="250"  placeholder="기타문의사항(250자)" ref="txt_reqSpRemarks" id="floatingTextarea" style="height:150px" v-model="this.quotationInfo.reqSpRemarks" readonly></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">담당자 비고사항
          <p><small>내부용(외부표시안됨)</small></p>
        </label>
        <div class="col-md-9">
            <div class="row">
            <textarea class="form-control" ref="txt_nContent" id="floatingTextarea" style="height:150px; font-size:small" v-model="this.quotationInfo.respoComment" disabled></textarea>
            </div>
            <div class="row">
            <textarea class="form-control" maxlength="500"  placeholder="500자 이내" ref="txt_respoComment_new" id="floatingTextarea" style="height:150px; font-size:small" v-model="this.respoComment_new"></textarea>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-success" @click="requestUpdate();">수정</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">취소</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>

      <hr class = "text-danger">

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">
          직전답변
          <p class="text-secondary">답변자 ({{this.requestAnswer.handlerNameKor}})</p>
        </label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" maxlength="999" ref="txt_ansContent" id="floatingTextarea" style="height:100px" v-model="this.requestAnswer.ansContent" readonly></textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">
          신규답변
          <p>
            <button type="button" class="btn btn-primary btn-sm me-1 align-text-middle" @click="sendAnswer();">
              <font style="font-size:small" class="align-bottom"><small>답변처리</small></font>
            </button>
          </p>
          <p class="text-danger">
            ※ 상기내용의 '수정'과 별개로 진행됩니다
          </p>
        </label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" maxlength="999"  placeholder="1,000자 이내" ref="txt_ansContent" id="floatingTextarea" style="height:150px" v-model="this.ansContent"></textarea>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      inquiryCatList: [], //질의영역분류
      tripCategroy: [], //여행자카테고리
      cityList: [],
      nationList: [],
      areaList: [],
      areaList_tmp: [],
      transList: [],
      mobilityList: [],
      mobilityList_dp: [], //출발편
      mobilityList_rt: [], //리턴편

      //견적요청정보
      quotationInfo: [],

      respoComment_new: "", //신규코멘트
      requestAnswer: [], //답변내용
      answerFlag: "N" //답변여부
    };
  },
  created(){
    this.qtCode = this.$route.query.qtCode;
    this.seqReq = this.$route.query.seqReq;
    //console.log("qtCode:",this.qtCode);

    this.getInquiryCatList();

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = "nonMember"
    }
    console.log("userInfo:",this.user,"uName:",this.$store.state.user.handlerNameKor);
    console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);

    this.getRequestDetail();

    this.getCityList();
    this.getNationList();
    this.getAreaList();
  },
  mounted() {
    if(document.referrer == "") 
    {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) 
    {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }    
  },  
  methods: {
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    //견적관련 프로세스 코드
    async getInquiryCatList() {
      let inquiryCatList = await this.$api("/api/inquiryCatList2", {param:["B"]});
      let tripCategroy = await this.$api("/api/inquiryCatList2", {param:["Q"]});

      if (inquiryCatList.length > 0) {
        this.inquiryCatList = inquiryCatList;
      }
      if (tripCategroy.length > 0) {
        this.tripCategroy = tripCategroy;
      }      
      console.log("inquiryCatList:",this.inquiryCatList);
    },
    //견적요청내용
    async getRequestDetail() 
    {
      let quotationInfo = await this.$api("/api/requestQuotationDetail", {param: [this.qtCode, this.seqReq]});
      this.quotationInfo = quotationInfo[0];
      this.quotationInfo.startDate = this.getDateFormat(this.quotationInfo.startDate);
      this.quotationInfo.closeDate = this.getDateFormat(this.quotationInfo.closeDate);
      console.log("quotationInfo:",this.quotationInfo);

      if(this.quotationInfo.transFlag === 'Y')
      {
        this.getTransList();
        this.getMobilityList1();
      }
      this.getReqAnswer(); //답변데이타      
    },
    //문의답변체크
    async getReqAnswer() {
      let requestAnswer = await this.$api("/api/requestAnswerForQuotation", {param: [this.qtCode, this.seqReq]});
      if(requestAnswer.length > 0)
      {
        this.requestAnswer = requestAnswer[0];
      }
      console.log("requestAnswer:",this.requestAnswer);
    },
    async getCityList() 
    {
      let cityList = await this.$api("/api/cityList", {});
      this.cityList = cityList;
      //console.log("cityList",cityList);
    },
    async getNationList() 
    {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    async getAreaList() 
    {
      //this.areaList_tmp = await this.$api("/api/areaList", {});
      this.areaList = await this.$api("/api/areaList", {});
      console.log("areaList_tmp",this.areaList_tmp);
    },
    async changeNationList(param) 
    {
      //this.nationCode = this.productInfo.baseCode;
      this.areaList = await this.$api("/api/areaList", {});      
      this.viewAreaList(param);
    },
    viewAreaList(param1)
    {
      this.areaList = this.areaList.filter(e => {return e.categoryCode === param1});
    },

    openTrans(val) {
      if(val === "Y")
      {
        this.getTransList();
        this.getMobilityList();
      }
    },
    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      console.log("transList",transList);
    },
    async getMobilityList() {
      //this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      this.mobilityList = await this.$api("/api/mobilityList", {});
      console.log("mobilityList:",this.mobilityList);
    },
    async getMobilityList1() {
      let mobilityList_tmp = await this.$api("/api/mobilityList", {});
      console.log("mobilityList_tmp:", mobilityList_tmp);
      this.mobilityList_dp = mobilityList_tmp.filter(e => {return e.transCode === this.quotationInfo.mbCd1});
      this.mobilityList_rt = mobilityList_tmp.filter(e => {return e.transCode === this.quotationInfo.mbCd2});
    },    
    //교통수단변경->교통편획득
    changeDpTransList() {
      this.quotationInfo.mbCd1 = "";
      this.viewDpMobilityList(this.quotationInfo.trCd1);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    changeRtTransList() {
      this.quotationInfo.mbCd2 = "";
      this.viewRtMobilityList(this.quotationInfo.trCd2);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    async viewDpMobilityList(param1){
      //alert("categoryCode:" + param1);
      let mobilityList_d = await this.$api("/api/mobilityList", {});
      this.mobilityList_dp = mobilityList_d.filter(e => {return e.categoryCode === param1});
      //console.log("mobility:", this.mobilityList_dp);
    },
    async viewRtMobilityList(param2){
      //alert("categoryCode:" + param2);
      let mobilityList_r = await this.$api("/api/mobilityList", {});
      this.mobilityList_rt = mobilityList_r.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList_rt);
    },
    async requestUpdate() 
    {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;

      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;

      if(!this.respoComment_new) 
      {
        this.respoComment_new = 
          this.quotationInfo.respoComment;
      }
      else
      {
        this.respoComment_new = 
          this.quotationInfo.respoComment + '\n' + "["+this.$store.state.user.handlerNameKor +"("+ insDate +")" +"] " +this.respoComment_new;
      }

      let resUpd_Request = await this.$api("/api/reqQuotationUpdate", {param: [
        this.quotationInfo.reqTitle,this.quotationInfo.reqPersonName,this.quotationInfo.perCnt,this.quotationInfo.reqEmail,this.quotationInfo.reqTel,this.quotationInfo.transFlag,this.quotationInfo.reqSpRemarks,this.quotationInfo.reqStatus,this.$store.state.user.handlerId,this.$store.state.user.handlerNameKor,this.respoComment_new,this.quotationInfo.startDate,this.quotationInfo.closeDate,this.quotationInfo.ctCd,this.quotationInfo.ntCd,this.quotationInfo.arCd,this.quotationInfo.trCd1,this.quotationInfo.trCd2,this.quotationInfo.mbCd1,this.quotationInfo.mbCd2,this.quotationInfo.transRemarks_dp,this.quotationInfo.transRemarks_ar,this.quotationInfo.selCat,this.qtCode,this.seqReq
      ]});
      if(resUpd_Request.affectedRows > 0)
      {
        this.$swal.fire('','수정완료', 'success');
        location.reload();
      }
      else 
      {
        this.$swal.fire('','수정(resUpd_Request) 중 오류가 발생했습니다.', 'error')
      }
      //console.log("resUpd_Request:",this.resUpd_Request);
    },
    async sendAnswer()
    {
      let ins_requestAnswer = await this.$api("/api/ins_requestAnswerForQuotation", {param: 
          [
            this.qtCode,this.seqReq,this.ansContent, this.$store.state.user.handlerId
          ]});
        //console.log("ins_requestAnswer:", ins_requestAnswer);
        if(Object.keys(ins_requestAnswer)[0] === 'error')
        {
          this.$swal.fire('','답글등록(ins_requestAnswer)중 오류가 발생했습니다.<br>관리자에게 문의해주세요', 'warning')
        }
        else
        {
          let upd_ansFlagForQuotation = await this.$api("/api/upd_reqQuotationFlag", {param: [ this.qtCode, this.seqReq ]});
          console.log(upd_ansFlagForQuotation);
          this.$swal.fire('','답글등록이 완료되었습니다.', 'success')
          location.reload();
          
          //todo: 카카오 알림톡 전송
        }
    },    
    goToList() {
      this.$router.push({path: '/quotationList'});
    }
  }
}
</script>