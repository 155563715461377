<template>
  <div id="sub">
    <!-- 카로셀 -->
    <section class="sub-main">
      <div id="carouselSubIndicators" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselSubIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselSubIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <!--<button type="button" data-bs-target="#carouselSubIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>-->
          <!-- <button type="button" data-bs-target="#carouselSubIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselSubIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button> -->
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../assets/img/ships/pukwanF.jpg" class="d-block w-100" alt="..." style="height:50rem">
            <div class="carousel-caption">
              <h5>유서깊은 국내 최초의 한일여객선 부관훼리</h5>
              <p>부관훼리를 타고 시모노세키까지 안전하고 시원하게!</p>
            </div>
          </div>
          <div class="carousel-item">
            <img src="../assets/img/ships/camelliaF.jpg" class="d-block w-100" alt="..." style="height:50rem">
            <div class="carousel-caption">
              <h5>부산과 후쿠오카를 연결하는 화객선 카멜리아</h5>
              <p>가까운 후쿠오카까지 최고의 바다여행을 즐겨보세요!</p>
            </div>
          </div>
          <!--<div class="carousel-item">
            <img src="../assets/img/ships/queenBeetleF.jpg" class="d-block w-100" alt="..." style="height:50rem">
            <div class="carousel-caption">
              <h5>바다 위의 점보여객기, 퀸비틀</h5>
              <p>부산과 후쿠오카를 빠르게 연결합니다</p>
            </div>
          </div>-->
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselSubIndicators" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselSubIndicators" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </section>

    <!-- 일본선박상품 -->
    <section id="pack" class="packages">
			<div class="container">
				<div class="packages-content">
					<div class="row gx-4">
            <!-- 제목 -->
            <div class="sub-title">
              <span class="st-main">일본선박</span>
              <span class="brdown">시원한 바다를 건너 이웃나라 일본으로 유유자적한 선박여행을 즐기세요.</span>
            </div>
            <!-- 카테고리 -->
            <div class="category">
              <ul>
                <li key="jpAll" @click="showJpSelArea('jpAll')"><a href="javascript: void(0)">전체</a></li>
                <li :key="dtCd" v-for="(etc,dtCd) in this.spToDt" @click="showJpSelArea(etc[0].dtCd)"><a href="javascript: void(0)">{{ etc[0].DT }}</a></li>
              </ul>              
            </div>
            <!-- 내용 -->
						<div class="col-lg-6 col-md-6 col-sm-12 my-5" :key="i" v-for="(nd, i) in this.ndaytirpList_jp">
              <span :id="nd.arCd"></span>
              <a class="text-decoration-none link-dark" href="javascript: void(0)"  @click='goToDetail(nd.pdtCode,nd.salesRoute)' style="cursor: pointer">
								<div class="package-item">
									<div class="pic">
										<div class="packages-hover">
											<div class="packages-hover-content"></div>
										</div>               
										<img v-if="nd.imgName != 'noImage'" :src="`/download/${nd.pdtCode}/${nd.imgName}`" class="card-img-top" alt="..."/>
										<img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                    <div class="badge">
                      <div v-if="nd.badgeFlag === 'Y'">
                        <div class="badge1">
                          <small class="badge1-text">{{ nd.badge }}</small>
                        </div>
                      </div>
                      <div v-if="nd.badge2Flag === 'Y'">
                        <div v-if="nd.badgeFlag != 'Y'" class="badge1">
                          <small class="badge1-text">{{ nd.badge2 }}</small>
                        </div>
                        <div v-else class="badge2" style="margin-left: 180px;">
                          <small class="badge2-text">{{ nd.badge2 }}</small>
                        </div>
                      </div>
                    </div>
									</div>

									<div class="package-item-txt">
										<h3>
											{{ nd.pdtNameKor }}
										</h3>
										<div class="packages-para">
											<p>
												<i class="bi bi-bus-front-fill"></i> {{ nd.pdtRemarks }}
											</p>
										</div>

                    <hr>

										<div class="price">
                      <div v-if="this.user.memLevel === 'P'">
                        <div v-if="nd.dispPrice === 'mem'">
                          <div class="my-1" v-if="nd.sMinPrice === nd.memP">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                          </div>
                          <div v-else class="my-1">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b><span style="font-size:small;">원~</span>
                            <small style="font-size: 0.8rem;" v-if="nd.memP*1 > 0"> (결제 후,</small>
                            <b class="mx-1" style="color:#ffce00;font-size: 0.9rem;" v-if="nd.memP*1 > 0">{{ getCurrencyFormat(nd.memP)}}</b>
                            <small style="font-size: 0.8rem;" v-if="nd.memP*1 > 0">원 환급)</small>
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="nd.promoStatus === 'Y'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                            <i class="bi bi-caret-right-fill" style="font-size:small;"></i>
                            <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b><span style="font-size: small;">원~</span>
                          </div>
                          <div v-else-if="nd.promoStatus === 'N'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b><span style="font-size:small;">원~</span>
                          </div>
                          <div v-else-if="nd.promoStatus === 'E'">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b><span style="font-size:small;">원~</span>
                          </div>
                        </div>
                      </div>
                      
                      <div v-else-if="this.user.memLevel === 'S'">
                        <div v-if="nd.dispPrice === 'mem'">
                          <div v-if="nd.dcForStd > 0" class="my-1">
                            <div>
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                              <b class="mx-1">{{ getCurrencyFormat(nd.sMinPrice-(nd.sMinPrice*(nd.dcForStd/100)))}} <span style="font-size:small;">원~</span></b>
                            </div>
                            <div>
                              <img src="../assets/img/pBus.png" alt="" class="mbus-icon">
                              <b class="mx-1" v-if="nd.sMinPrice*1 - (nd.sMinPrice*(nd.maxDC_P/100)) == 0 && nd.memLevel_P === 'Y'">
                                0원! <span style="font-size:small;">(연간무제한)</span>
                              </b>
                              <b class="mx-1" v-else>
                                {{ getCurrencyFormat(nd.sMinPrice*1 - (nd.sMinPrice*(nd.maxDC_P/100)))}} <small><span>원~</span></small>
                              </b>
                            </div>
                          </div>

                          <div v-else class="my-1">
                            <div v-if="nd.sMinPrice === nd.memS">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                              <b class="mx-1">0원! <span style="font-size:small;">(연간무제한)</span></b>
                            </div>
                            <div v-else class="my-1">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                              <b class="mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                              <small><span>원~</span></small>
                              <small style="color:white;font-size: 0.8rem;" v-if="nd.memS*1 > 0"> (결제 후, </small>
                              <b class="mx-1" style="color:slategrey;font-size: 0.9rem;" v-if="nd.memS*1 > 0">{{ getCurrencyFormat(nd.memS)}}</b>
                              <small style="color:white;font-size: 0.8rem;" v-if="nd.memS*1 > 0">원 환급) </small>
                            </div>
                            <div>
                              <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                              <b class="mx-1" v-if="nd.sMinPrice*1 - (nd.sMinPrice*(nd.maxDC_P/100)) == 0 && nd.memLevel_P == 'Y'">
                                0원! <span style="font-size:small;">(연간무제한)</span>
                              </b>
                              <b class="mx-1" v-else>
                                {{ getCurrencyFormat(nd.sMinPrice*1 - (nd.sMinPrice*(nd.maxDC_P/100)))}} <small><span>원~</span></small>
                              </b>
                            </div>
                          </div>
                        </div>

                        <div v-else>
                          <div v-if="nd.promoStatus === 'Y'">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                              <small><i class="bi bi-caret-right-fill"></i></small>
                            <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                            <small><span>원~</span></small>
                          </div>
                          <div v-else-if="nd.promoStatus === 'N'">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                            <small><span>원~</span></small>
                          </div>
                          <div v-else-if="nd.promoStatus === 'E'">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon">
                            <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                            <small><span>원~</span></small>
                          </div>
                        </div>
                      </div>                      

                      <!-- 비로그인과 일반회원 멤버십표시 -->
                      <div v-else>
                        <!-- 일반회원가격(default) -->
                        <div v-if="nd.promoStatus === 'Y'" class="my-1">
                          <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                          <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                            <small><i class="bi bi-caret-right-fill"></i></small>
                          <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                          <small><span>원~</span></small>
                        </div>

                        <div v-else-if="nd.promoStatus === 'E'" class="my-1">
                          <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                          <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                          <small><span>원~</span></small>
                        </div>
                        
                        <div v-else-if="nd.promoStatus === 'N'" class="my-1">
                          <div class="my-1" v-if="(nd.dcForMem_S > 0)">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                            <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                            <small><span>원~</span></small>
                          </div>
                          <div v-else class="my-1">
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color:darkgray; padding:3px 5px" class="memLabel" v-else>일반회원 </label>
                            <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                            <small><span>원~</span></small>
                          </div> 
                        </div>

                        <div v-if="((nd.memLevel_S == 'N' || nd.memLevel_S == null) && (nd.memLevel_P == 'N' || nd.memLevel_P == null)) || (nd.nrFlag == 'Y' || nd.vrFlag == 'Y')">
                          <div class="my-1" v-if="nd.promoStatus === 'E'">
                            <div>
                              <div class="my-1">
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1">{{ getCurrencyFormat(nd.sMinPrice*1 - nd.price_dcForMem_S)}}</b>
                                <small><span>원~</span></small>
                              </div>
                              <div class="my-1">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                <b class="mx-1">{{ getCurrencyFormat(nd.sMinPrice*1 - nd.price_dcForMem_P)}}</b>
                                <small><span>원~</span></small>
                              </div>
                            </div> 
                          </div>
                          <div class="my-1" v-else-if="nd.promoStatus === 'N'">
                            <div>
                              <div class="my-1">
                                <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                                <b class="mx-1">{{ getCurrencyFormat(nd.sMinPrice*1 - nd.price_dcForMem_S)}}</b>
                                <small><span>원~</span></small>
                              </div>
                              <div class="my-1">
                                <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                                <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                                <b class="mx-1">{{ getCurrencyFormat(nd.sMinPrice*1 - nd.price_dcForMem_P)}}</b>
                                <small><span>원~</span></small>
                              </div>
                            </div> 
                          </div>
                        </div>

                        <!-- 스탠다드멤버십 상품 -->
                        <div class="my-1" v-if="nd.memLevel_S === 'Y' && ((nd.nrFlag === 'N' || nd.nrFlag == null) && (nd.vrFlag === 'N' || nd.vrFlag == null))">
                          <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                          <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                          <b class="mx-1" v-if="nd.sMinPrice*1 - (nd.sMinPrice*(nd.maxDC_S/100)) == 0">
                            0원! <span style="font-size:small;">(연간무제한)</span>
                          </b>
                          <b class="mx-1" v-else>
                            {{ getCurrencyFormat(nd.sMinPrice*1 - (nd.sMinPrice*(nd.maxDC_S/100)))}} <small><span>원~</span></small>
                          </b>
                        </div>

                        <!-- 프리미엄멤버십 상품 -->
                        <div class="my-1" v-if="nd.memLevel_P === 'Y' && ((nd.nrFlag === 'N' || nd.nrFlag == null) && (nd.vrFlag === 'N' || nd.vrFlag == null))">
                          <div class="my-1" v-if="(nd.memLevel_s == 'N' || nd.memLevel_s == null) && (nd.dcForStd > 0)">
                            <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color: #0b7fd4; padding:3px 5px" class="memLabel" v-else>스탠다드 </label>
                            <b class="mx-1">{{ getCurrencyFormat(nd.sMinPrice*1 - (nd.sMinPrice*(nd.dcForStd/100)))}}</b> 
                            <small><span>원~</span></small>
                          </div>
                          <div class="my-1">
                            <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined">
                            <label style="background-color: #ffce00; padding:3px 5px" class="memLabel" v-else>프리미엄 </label>
                            <b class="mx-1" v-if="nd.sMinPrice*1 - (nd.sMinPrice*(nd.maxDC_P/100)) == 0">
                              0원! <span style="font-size:small;">(연간무제한)</span>
                            </b>
                            <b class="mx-1" v-else>
                              {{ getCurrencyFormat(nd.sMinPrice*1 - (nd.sMinPrice*(nd.maxDC_P/100)))}} <small><span>원~</span></small>
                            </b>
                          </div>
                        </div>
                      </div>
										</div>
									</div>
								</div>
							</a>
						</div>
            <!-- 더보기 -->
            <div class="row more-jp justify-content-center fingerPointer" @click="showJpMoreList()" v-if="this.ndaytirpList_jp_more.length > 6">
              <p class="col-6 col-lg-2" v-if="this.jp_more === 'N'"><a href="javascript:void(0)">더보기</a></p>
              <p class="col-6 col-lg-2" v-else><a href="javascript:void(0)">접기</a></p>
            </div>
            <div class="row more-jp justify-content-center fingerPointer" v-else>
            </div>            

					</div>
				</div>
			</div>
    </section>

    <!-- 배너 -->
    <div class="banner2 d-flex justify-content-center">
      <a href="/membershipPage">
        <img src="../assets/img/new-test/banner3.jpg" class="img-fluid pc">
        <img src="../assets/img/new-test/banner3-2.jpg" class="img-fluid pad">
        <img src="../assets/img/new-test/banner3-3.jpg" class="img-fluid mobile">
      </a>
    </div>


  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      transportList: [],            //선택가능한 교통편정보
      spToDt: {},                   //선박운항지
      dt: "",                       //상품카테고리(운항지기준)
      tr: "",                       //상품카테고리(선박기준)
      mbCode: "",                   //판매상품의 교통수단코드
      nt: "",                       //상품카테고리(국가기준)
      ar: "",                       //상품카테고리(지역기준)
      
      //productListForSales_org: [],  //로컬전체상품
      productListForSales: [],        //판매상품리스트
      ndaytirpList_jp: [],            //일본내 상품
      ndaytirpList_jp_org: [],
      ndaytirpList_jp_more: [],
      jp_more: "N",                   //일본내 더보기

      sellerCode: "NT",               //판매자코드


      natName: "",                      //표시페이지 국가명
      selectableNation: [],             //선택가능 국가

      // n1: "",                        //bannerName1
      // n2: "",                        //bannerName2
      nationList: {},                   //여행지역리스트
      pdtCatList: {},                   //상품카테고리
      
      productListForSales_recomend: {}, //추천상품리스트
      pPriceY: 0,
      pPriceN: 0,
      pPriceE: 0,

      /////멤버십 금액반영
      memLv: "",         //멤버십등급(S,P)
      memDC: [0,0,0,],    //멤버십환급율(성인,소인,유아)

      // maxDC_P: 0,        //프리미엄 최대환급율
      // maxDC_S: 0,        //스탠다드 최대환급율
      // dcForMem_P: 0,     //프리미엄 일반상품 할인율
      // dcForMem_S: 0,     //스탠다드 일반상품 할인율
      // dcForStd: 0,       //프리미엄전용을 스탠다드가 구매할 경우 할인율
      
      dispPrice: [],        //가격표시방법(mem / promo)

      promoPrice: 0,        //프로모션할인액
      price_memP: 0,        //프리미엄 환급액
      price_memS: 0,        //스탠다드 환급액
      price_dcForStd: 0,    //프리미엄전용을 스탠다드가 구매할 경우 할인액
      price_dcForMem_P: 0,  //일반상품을 프리미엄이 구매시 할인액
      price_dcForMem_S: 0,  //일반상품을 스탠다드가 구매시 할인액
    };
  },
  created() {
    // 페이지생성과 동시에 리스트정보를 get
		this.dt = (this.$route.query.dt == undefined) ? "%" : this.$route.query.dt;
    this.tr = (this.$route.query.tr == undefined) ? "%" : this.$route.query.tr;

    this.getProductList();
    this.getTransportInfo()
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   //this.$router.push({path: '/'});
    //   location.replace("/");
    // }

    //dom이 모두 로딩 된 후, 펑션호출
    window.onload = () => {
      this.$nextTick(() => {
        this.scrollToSection();
      });
    };

  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },  
  methods: {
    scrollToSection() {
      const element = document.getElementById(this.dt);
      if (element) 
      {
        const offset = 180; // 조정하고자 하는 픽셀값
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: 'smooth'
        });
      }
      // if (element) {
      //   element.scrollIntoView({ behavior: 'smooth', block: 'start', inline:'nearest' });
      // }
    },

    //선택가능한 선박
    async getTransportInfo() {
      //선박상품용 변수
      let ctCode = "SHP"
      let uFlag = "Y"
      let rtCode = "JP"

      let transportList = await this.$api("/api/transportList", {param: 
        [
          ctCode, uFlag, rtCode
        ]
      })

      let spToDt = {}

      if(transportList.length > 0)
      {
        //console.log("transportList:", transportList)
        this.transportList = transportList
        //운항지별로 정리
        transportList.forEach(item => {
          if(!spToDt[item.dtCode])
          {
            spToDt[item.dtCode] = [];
          }
          spToDt[item.dtCode].push({
            dtCd: item.dtCode,            //운항지코드
            trCd: item.transCode,         //선박코드
            DT: item.destName,            //운항지명
            TR: item.transName            //선박명
          })
        })
      }
      this.spToDt = spToDt
      //console.log("spToDt:", this.spToDt)

    },

    async getProductList() 
    {
      let dtCd = this.dt    //운항지
      let trCd = this.tr    //선박코드
      //console.log("dtCd:",dtCd,"/","trCd:",trCd)

      //let ntCd = this.nt   //국가
      //let arCd = this.ar   //지역

      let sRoute = "F";
      let sMinPrice = 0;
      let sMargin = 0;


      //console.log("dtCd:",dtCd,"trCd:",trCd,"arCd:",arCd);

      //전체선박상품추출
      let productListForSales_tmptmp = await this.$api("/api/productListForSales", {param: ['%', sRoute]});

      //연계상품배제
      let productListForSales_tmp = productListForSales_tmptmp.filter((item) => {
        return (item.assoFlag == null || item.assoFlag == "" || item.assoFlag == undefined)
      })
      
      let i = 0;
      while(i < productListForSales_tmp.length)
      {
        if(productListForSales_tmp[i].marginMethod === "P")
        {
          sMargin = productListForSales_tmp[i].minPrice * (productListForSales_tmp[i].basicMargin / 100);
        }
        else
        {
          sMargin = productListForSales_tmp[i].basicMargin;
        }
        sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
        productListForSales_tmp[i].sMinPrice = sMinPrice;
        i++;
      }

      this.productListForSales = productListForSales_tmp    //선박판매상품 전체
      //console.log("productListForSales: ", this.productListForSales)


      //상품가격계산 최저가기준 표시 + 마진적용
      let marginPrice = 0;
      let promoPrice = 0;

      //상품가격계산 최저가기준 표시 + 마진적용(성인기준)
      let maxDC_P = 0;        //프리미엄 최대환급율
      let maxDC_S = 0;        //스탠다드 최대환급율
      let dcForMem_P = 0      //이외상품 프리미엄 할인율
      let dcForMem_S = 0      //이외상품 스탠다드 할인율
      let dcForStd = 0        //프리이엄전용을 스탠다드가 구매시 할인율

      let price_memP = 0      //프리미엄 환급액
      let price_memS = 0      //스탠다드 환급액

      let price_dcForStd = 0  //프리이엄전용을 스탠다드가 구매시 할인액

      let price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
      let price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액

      for (let i = 0; i < this.productListForSales.length; i++) 
      {
        if(this.productListForSales[i].marginMethod == "P")
        {
          marginPrice = this.productListForSales[i].minPrice * (this.productListForSales[i].basicMargin / 100);
        }
        else
        {
          marginPrice = this.productListForSales[i].basicMargin;
        }
        //console.log("marginPrice:",marginPrice);

        ////프로모션 상태에 따른 프로모션가격
        if (this.productListForSales[i].promoStatus === "Y") 
        {
          if (this.productListForSales[i].promoMethod === "P") 
          {
            promoPrice = (this.productListForSales[i].minPrice+marginPrice)*(this.productListForSales[i].promoRate / 100);
          }
          else
          {
            promoPrice = this.productListForSales[i].promoRate;
          }
          this.promoPrice = promoPrice.toFixed(0)*1  //프로모션 할인액
        }
        //console.log("promoPrice:",promoPrice);

        if (this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y") 
        {
          //최대환급율
          maxDC_P = (this.productListForSales[i].maxDC_P == undefined) ? 0 : this.productListForSales[i].maxDC_P*1
          maxDC_S = (this.productListForSales[i].maxDC_S == undefined) ? 0 : this.productListForSales[i].maxDC_S*1
          //프리미엄전용을 스탠다드가 구매시 할인율
          dcForStd = (this.productListForSales[i].dcForStd == undefined) ? 0 : this.productListForSales[i].dcForStd*1

          //환급액
          price_memP = (this.productListForSales[i].minPrice+marginPrice)*(maxDC_P / 100);
          price_memS = (this.productListForSales[i].minPrice+marginPrice)*(maxDC_S / 100);
          //소숫점 절사
          this.price_memP = price_memP.toFixed(0)*1   //price_memP*1
          this.price_memS = price_memS.toFixed(0)*1   //price_memS*1
          
          //프리미엄전용을 스탠다드가 구매시 할인액 
          price_dcForStd = (this.productListForSales[i].minPrice+marginPrice)*(dcForStd / 100); 
          this.price_dcForStd = price_dcForStd.toFixed(0)*1
        }

        //선박상품(F), 엔데어||버라이어티의 멤버십등급에 따른 할인액1
        if(this.productListForSales[i].salesRoute == "F" || (this.productListForSales[i].salesRoute == 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
        {   
          dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
          dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

          //할인방식에 따라 분기처리
          if(this.productListForSales[i].dcRateForMem_P === "P")
          {
            price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
          }
          else
          {
            price_dcForMem_P = this.productListForSales[i].dcForMem_P*1
          }
          
          if(this.productListForSales[i].dcRateForMem_S === "P")
          {
            price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)
          }
          else
          {
            price_dcForMem_S = this.productListForSales[i].dcForMem_S*1
          }

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1

          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1          
        }

        //멤버십미지정 로컬투어
        else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
        {
          dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
          dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

          price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
          price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1

          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1          
        }

        //화면표시용 가격
        //구매자 멤버십
        if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
        {
          //this.productListForSales[i].pPrice = "멤버십가격"
          //멤버십 + 멤버십상품
          if(this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y")
          {
            //this.productListForSales[i].pPrice = "멤버용 상품가격" 
            
            //유효프로모션 존재
            if (this.productListForSales[i].promoStatus === "Y")
            {
              if(this.user.memLevel === 'P')
              {
                //멤버십환급과 프로모션할인을 비교하여 큰 금액으로 표시
                if(this.price_memP >= this.promoPrice && this.price_memP > 0)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memP = this.price_memP  //환급액
                  this.productListForSales[i].forStd = this.price_dcForStd  //스탠다드를 위한 할인율
                }
                else if(this.price_memP < this.promoPrice || this.price_memP == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 프로모션
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
              else if(this.user.memLevel === 'S' && this.price_memS > 0)
              {
                if(this.price_memS >= this.promoPrice)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memS = this.price_memS  //환급액
                }
                else if(this.price_memS < this.promoPrice || this.price_memS == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
            }

            //프로모션 없음
            else
            {
              if(this.user.memLevel === 'P')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memP = this.price_memP  //환급액
              }
              else if(this.user.memLevel === 'S')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memS = this.price_memS  //환급액
              }
            }
          }

          //멤버십+일반상품
          else
          {
            //일반상품지정(패키지, 로컬+엔데어 || 버라이어티)
            if(this.productListForSales[i].salesRoute == 'T' || this.productListForSales[i].salesRoute == 'F' || (this.productListForSales[i].salesRoute === 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
            {
              //this.productListForSales[i].pPrice = "멤버용 일반상품가격1" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].memDC = "Y"       //멤버십할인적용
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].memDC = "Y"     //멤버십할인적용
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"       //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_S = "promo"       //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                }
              }
            }
            
            //멤버십+일반상품유형2(로컬+멤버십미지정)
            else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
            {
              this.productListForSales[i].pPrice = "멤버용 일반상품가격2" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"  //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"  //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"          //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"   //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"           //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                }
              }
            }
          }
        }
        //구매자 비멤버십
        else
        {
          //프로모션이 있으면 할인처리
          if (this.productListForSales[i].promoStatus === "Y")
          {
            this.pPriceY = (this.productListForSales[i].minPrice + marginPrice) - promoPrice;
            //console.log(`pPrice${i}: ${this.pPriceY}`);
            this.productListForSales[i].pPrice = this.pPriceY;
          } 
          else if (this.productListForSales[i].promoStatus == "E") 
          {
            this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceE;
          } 
          else if (this.productListForSales[i].promoStatus == "N") 
          {
            this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceN;
          }
        }
      }
      //일본여행 선박상품
      //let ndaytirpList_jp = [];
      let ndaytirpList_jp = this.productListForSales.filter(item => {
        return item.ntCd == 'JP'
      })
      
      this.ndaytirpList_jp_org = ndaytirpList_jp.slice(0,6)
      this.ndaytirpList_jp_more = ndaytirpList_jp

      //전체선박상품
      // if((ntCd == "%" || ntCd == ""))
      // {
      //   this.ndaytirpList_jp = ndaytirpList_jp.slice(0,6)
      //   return; //이후 조건에 따라 값이 배열값이 덮어써지는 것을 막는다
      // }
      //선박상품 전체선택
      if((dtCd == "%" && trCd == "%"))
      {
        this.ndaytirpList_jp = ndaytirpList_jp.slice(0,6)
        return; //이후 조건에 따라 값이 배열값이 덮어써지는 것을 막는다
      }
      
      if(dtCd != "" && trCd == "")   //일본내 특정지역
      {
        ndaytirpList_jp = this.productListForSales.filter(item => {
          return (item.arCd === dtCd)
        })

        this.ndaytirpList_jp = ndaytirpList_jp.slice(0,6)
      }
      if(dtCd != "" && trCd != "")   //특정선박
      {
        ndaytirpList_jp = this.productListForSales.filter(item => {
          return (item.arCd == dtCd && item.mbCd1 == trCd)
        })
        this.ndaytirpList_jp = ndaytirpList_jp.slice(0,6)
        //this.ndaytirpList_etc = ndaytirpList_etc.slice(0,6)
      }


      // //일본선택
      // if((ntCd == "JP" && arCd == ""))    //일본전역
      // {
      //   this.ndaytirpList_jp = ndaytirpList_jp.slice(0,6)
      // }      
      // else if(ntCd == "JP" && arCd != "")   //일본내 특정
      // {
      //   ndaytirpList_jp = this.productListForSales.filter(item => {
      //     return (item.ntCd === 'JP' && item.arCd == arCd)
      //   })

      //   this.ndaytirpList_jp = ndaytirpList_jp.slice(0,6)
      // }

      // else if(trCd != "")   //특정선박코드
      // {
      //   ndaytirpList_jp = this.productListForSales.filter(item => {
      //     return (item.mbCd1 == this.tr)
      //   })
      //   this.ndaytirpList_jp = ndaytirpList_jp.slice(0,6)
      //   //this.ndaytirpList_etc = ndaytirpList_etc.slice(0,6)
      // }
    },

    //일본내 상품 더보기처리
    showJpMoreList() {
      this.jp_more = (this.jp_more === 'Y') ? "N" : "Y"
      if(this.jp_more === "N")
      {
        this.ndaytirpList_jp = this.ndaytirpList_jp_org
      }
      else
      {
        this.ndaytirpList_jp = this.ndaytirpList_jp_more
      }
    },

    showJpSelArea(param) {
      if(param === 'jpAll') 
      {
        document.querySelector('.more-jp').style.display = 'block'
        document.querySelector('.more-jp').classList.add('initial-state');
        this.ndaytirpList_jp = this.ndaytirpList_jp_more
      }
      else
      {
        document.querySelector('.more-jp').style.display = "none"
        this.ndaytirpList_jp = this.ndaytirpList_jp_more.filter(item => {
          return item.arCd === param
        })
      }
    },


    async getNationList() 
    {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      
      let natName = nationList.filter((item) => {
        return item.baseCode == this.nt
      })
      //this.natName = natName[0].codeNameKor
      this.natName = natName.codeNameKor
      //console.log("nationList:",this.nationList,",natName:",this.natName)
    },

    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },
		/** 일자처리 */
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },


    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

@media (min-width:991px){
  #sub{
    margin-top:-6rem
  }
}



/*--------------------------------------------------------------
	# 서브카로셀
--------------------------------------------------------------*/
#sub .carousel-indicators .active {
  width:50px
}
#sub .carousel-control-prev, #sub .carousel-control-next, 
#sub .carousel-control-prev:hover, #sub .carousel-control-prev:focus, 
#sub.carousel-control-next:hover, #sub .carousel-control-next:focus{
  opacity:0
}
#sub .carousel-caption h5{
  font-family: 'Black Han Sans', sans-serif;
  font-size:3.5rem
}
#sub .carousel-caption p{
  font-size:1.3rem
}

@media (max-width:580px){
  #sub .carousel-caption h5{
    font-size:2.1rem
  }
  #sub .carousel-caption p{
    font-size:.9rem
  }
}


/*--------------------------------------------------------------
	# 일본투어
--------------------------------------------------------------*/
.packages{
  margin:5rem 0;
}

/* 제목 */
#sub .sub-title .st-main{
  color:#000;
  font-size:2rem;
  font-weight: 600;
  padding-right:20px
}

@media (max-width:767px) {
  #sub .sub-title .st-main{
    font-size:1.7rem;
  }
  #sub .sub-title .brdown{ 
    display: block;
  }
}


/* 카테고리 */
#sub .category{
  margin-top:.8rem
}
#sub .category ul{
  padding-left:0
}
#sub .category ul li{
  list-style: none;
  display: inline-block;
  padding:10px 30px;
  border:1px solid darkgray;
  border-radius: 50px;
  margin:5px 15px;
  transition: all .5s
}
#sub .category ul li:hover{
  background-color:rgba(10, 109, 230, 0.988);
}
#sub .category ul li:hover a{
  color:#fff
}

@media (max-width:1024px){
  #sub .category ul li{
    padding:5px 15px;
    margin:5px;
  }
  #sub .category ul li a{
    font-size:.85rem
  }
}


/* 내용 */
#sub .packages .package-item{
  -webkit-transition: .5s; 
  -moz-transition:.5s; 
  -ms-transition:.5s; 
  -o-transition:.5s;
  transition: .5s;
  height:auto;
}
@media (max-width: 991px){
	#sub .packages .package-item{
		margin-bottom:3.5rem;
	}
  #sub .packages .sub-etc{
    margin-bottom:1.5rem
  }
}

#sub .package-item .pic{
  position:relative;
}
#sub .package-item .card-img-top {
  width: 100%;
  min-height:350px;
  max-height:350px;
  object-fit: cover;
}

/* 멤버십 아이콘 */
#sub .package-item .mbus-icon{
  width: 2.5rem !important;
  height: auto !important;
}

#sub .package-item .pic .packages-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  opacity:0;
  transition: opacity ease-in-out 0.5s;
}
#sub .package-item:hover .pic .packages-hover {
  opacity:.7;
}
#sub .package-item-txt{
  padding: 0 5px;
}
#sub .package-item h3{
  text-align:start;
  position: relative;
  font-size: 1rem;
  line-height:23px;
  color: #000;
  font-weight:600;
  padding-top: 15px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#sub .package-item-txt h3{
  height:60px;
}
#sub .packages-para{
  text-align:start;
  text-transform: capitalize;
	font-size: .75rem;
  color: #777;
}

#sub .packages-para-etc{
  text-align:center;
  text-transform: capitalize;
	font-size: .75rem;
  color: #777;
}

#sub .packages-para,.packages-para-etc p{
  margin-bottom:0;
  width: auto;
  height:20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#sub .packages-content .price {
	float:left;
  font-size: 1.2rem;
  font-weight:600;
  color:#111;
}

/* 뱃지 */
#sub .badge {
  /* width: 120px; */
  text-align: center;
}

#sub .badge .badge1{
  position: absolute;
  top:0;
  left:0;
  background-color:red;
  opacity: 0.7 !important;
  padding:15px 15px;
  width: 160px;
}
#sub .badge .badge1 .badge1-text{
  /* color:#fff; */
  color: white;
  font-size:1.2rem;
  padding:0
}

#sub .badge .badge2{
  position: absolute;
  top:0;
  left:0;
  /* left:100px; */
  background-color:white;
  opacity: 0.8 !important;
  padding:15px 15px;
  width: 160px;
  /* background:linear-gradient(#dacd4e, #97ca55); */
}
#sub .badge .badge2 .badge2-text{
  /* color:#fff; */
  color:#000;
  font-size:1.2rem;
  padding:0
}

/* 더보기 */
#sub .more-jp, .more-etc{
  margin:3rem 0 2rem;
  text-align: center;
}
#sub .more-jp p, .more-etc p{
  padding:15px 0;
  margin: 0 auto;
  border:1px solid #000;
  font-weight: 600;
  color: #000;
  transition: all .5s
}
#sub .more-jp p:hover, .more-etc p:hover{
  background-color:black;
}
#sub .more-jp p:hover a, .more-etc p:hover a{
  color:#fff
}

.fingerPointer {
  cursor: pointer;
}


/*--------------------------------------------------------------
	#배너
--------------------------------------------------------------*/
#sub .banner2{
  background-color: #49b8f8;
  width:100%;
  height: auto;
  margin:10rem 0;
}
#sub .banner2 .pc{
  display: block;
}
#sub .banner2 .pad{
  display: none;
}
#sub .banner2 .mobile{
  display: none;
}
@media (max-width:699px){
  #sub .banner2{
    margin:5rem 0;
  }
  #sub .banner2 .pc{
    display: none;
  }
  #sub .banner2 .pad{
    display: none;
  }
  #sub .banner2 .mobile{
    display: block;
  }
}
@media (min-width:700px) and (max-width:1800px){
  #sub .banner2 .pc{
    display: none;
  }
  #sub .banner2 .pad{
    display: block;
  }
  #sub .banner2 .mobile{
    display: none;
  }
}


/*--------------------------------------------------------------
	# 그 외 투어
--------------------------------------------------------------*/
#sub .sub-etc{
  margin-bottom:2rem
}
#sub .sub-etc img{
  border-radius: 15px;
}
#sub .sub-etc h3{
  text-align: center;
  -webkit-line-clamp: 1;
  height:auto
}
#sub .sub-etc .price{
  /* text-align: center; */
  text-align: start;
  padding-left: 1rem;
  float:none;
  font-size:1.3rem
}
#sub .sub-etc .pic .packages-hover{
  border-radius: 15px;
}

/* 뱃지표시 강행코드: 서버환경에서 뱃지미표시 현상처리용 */
.badge1 {
  opacity: 70% !important; /* 투명도 조정 */
  z-index: 9999 !important; /* 다른 요소 위에 표시 */
  display: block !important; /* 표시 */
  visibility: visible !important; /* 가시성 확보 */
}
.badge2 {
  opacity: 80% !important; /* 투명도 조정 */
  z-index: 9999 !important; /* 다른 요소 위에 표시 */
  display: block !important; /* 표시 */
  visibility: visible !important; /* 가시성 확보 */
}
</style>

