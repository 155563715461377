<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span @click="$emit('close')" class="close">&times;</span>
      <div class="fs-4 bold">[ 지출품의 목록 ]</div>
      <p class="text-end mb-0">
        <button class="btn btn-warning" @click="makeRequisition"><i class="bi bi-newspaper"></i> 지출품의서 생성</button>
      </p>
      
      <div :key="i" v-for="(e, i) in arr_expesneInfo">
        <div class="text-dark text-start fs-6 mb-2">
          <b>[ 세부 지출내역 <span class="text-secondary" style="font-size: small">({{ e.dealCode }}-{{ e.dSubNo }})</span> ] </b>
        </div>
        <table class="table table-bordered border-secondary align-middle w-100 h-auto text-center" style="font-size: smaller;">
          <thead>
            <tr class="table-secondary border-secondary">
              <th style="width:10%">품의일</th>
              <th style="width:10%">거래처</th>
              <th style="width:5%">환율</th> 
              <th style="width:10%">지출액</th>
              <th style="width:15%">적요(항목명)</th>
              <th style="width:15%">계좌정보</th>
              <th style="width:20%">비고사항</th>
            </tr>
            <tr>
              <td class="align-top">
                {{this.getDateFormat(this.today) }}
              </td>
              <td class="align-top">
                {{ e.paName }}
              </td>
              <td class="align-top">
                {{ e.dExRate }}
              </td>              

              <td v-if="e.curName === '한국원'" class="align-top">
                <span class="text-danger">{{ e.curSimbol }} {{ this.getCurrencyFormat(e.dPrice) }}</span>
              </td>
              <td v-else class="align-top">
                {{ e.curSimbol }} {{ this.getCurrencyFormat(e.dPrice) }}
                <br>
                <span class="text-danger">(₩ {{ this.getCurrencyFormat(e.dPrice * e.dExRate) }})</span>
              </td>              

              <td class="align-top">
                {{ e.exName }}
              </td>
              <td class="align-top">
                <span v-if="e.bankName == '은행정보 불일치'" class="text-warning">{{ e.bankName }}</span>
                <span v-else>{{ e.bankName }}</span>
                <br>
                {{ e.bankAccount }}
                <br>
                {{ e.accHolder }}
              </td>
              <td class="align-top text-start">
                {{ e.dDetailRemarks }}
              </td>
            </tr>
          </thead>
        </table>

        <div class="text-start fs-6 mb-5">
          <table>
            <tr>
              <td class="text-primary">
                <button class="btn btn-outline-primary btn-sm" style="font-size:small; margin-left:10px;" @click="callUpload(i)">
                  <i class="bi bi-upload"></i> 증빙자료
                </button>
                <input type="file" :ref="`fileInput-${i}`" style="display: none" @change="uploadReceipt(e.dealCode+'-'+e.dSubNo, i)" :id="`fileInput-${i}`">
              </td>
              <!-- 특정영역만 리로드를 하기 위해 key를 사용 -->
              <td style="font-size:small; text-align: start; padding-left: 10px;" v-if="e.recNames!= ''">
                <div :key="rKey">
                  <span :key="j" v-for="(r,j) in e.recNames" style="padding-left: 5px; padding-right: 5px;">
                    <a href="javascript:void(0)" @click="downloadUrl(e.dealCode+'-'+e.dSubNo, r)">{{ r }}</a>
                  <i class="bi bi-x-square-fill text-danger" style="font-size:smaller; cursor: pointer;" @click="del_recName(r, e.dealCode+'-'+e.dSubNo)"></i>
                </span>
                </div>
              </td>
              <td style="font-size:small; text-align: start; padding-left: 10px;" v-else>
                <span></span>
              </td>
            </tr>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
//import * as XLSX from 'xlsx'
import moment from 'moment'

// import axios from 'axios';
// import { saveAs } from 'file-saver';

export default {
  props: ['showModal', 'modalContent'],   //modalContent: 지출정보배열값(arr_expenseInfo)
  data() {
    return {
      arr_expesneInfo: [],        //지출품의정보
      res_receipts: [],           //지출증빙자료리스트
      rKey: 0,                    //for 특정영역 reload
    }
  },
  created() {
    this.user = this.$store.state.user
    console.log("user:",this.user)

    this.arr_expesneInfo = this.modalContent
    //this.getReceiptList("", "A")
    this.getReceiptList(this.rKey)
    console.log("this.arr_expesneInfo:", this.arr_expesneInfo)

    this.getBankName()

    let today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")
  },
  watch: {
    //부모에서 넘어오는 props와 로컬변수 동기화
    modalContent(newVal)
    {
      this.arr_expesneInfo = newVal
    }
  },
  methods: {
    //mixins 공통함수
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value)
    {
      return this.$currencyFormat(value);
    },
    getDeCurrencyFormat(value)
    {
      return this.$deCurrencyFormat(value);
    },

    async getBankName() {
      try {
        // Promise.all을 사용하여 모든 은행 이름 요청을 병렬로 처리
        const promises = this.arr_expesneInfo.map(info =>
          this.$api("/api/getBankName", { param: [info.bankCode] })
        );
        const results = await Promise.all(promises);

        // 모든 결과를 모아 처리
        results.forEach((result, index) => {
          if (result && result.length > 0) {
            this.arr_expesneInfo[index].bankName = result[0].fName;
          } else {
            this.arr_expesneInfo[index].bankName = "은행정보 불일치";
          }
        });
      } catch (error) {
        console.error("Failed to fetch bank names:", error);
        this.error = "은행 정보를 불러오는 중 문제가 발생했습니다."

        this.$swal.fire("", this.error, "error")
        return false
      }
    },

    //증빙파일다운로드
    downloadUrl(folder, fileName) {
      let encodedFolder = encodeURIComponent(folder);
      let encodedFilename = encodeURIComponent(fileName);

      //back end와 링크주소 일치해야 함
      //let url = `http://localhost:5000/filedownload/${encodedFolder}/${encodedFilename}`;
      let url = `https://ntabi.co.kr/filedownload/${encodedFolder}/${encodedFilename}`;
      //console.log("url:", url)

      window.location.href = url; 
    },

    //증빙서류 업로드펑션 호출(업로드 버튼특정을 위해 ref를 배열로 관리)
    callUpload(index){
      this.$refs[`fileInput-${index}`][0].click();
    },

    async uploadReceipt(recCode, index){
      let i = index
      let file = this.$refs[`fileInput-${index}`][0].files[0]
      
      if (file) 
      {
        //backend의 multer이용
        let formData = new FormData()

        formData.append('file', file)
        formData.append('recCode', recCode)
        // let config = {
        //   headers: {
        //     'x-memid': recCode
        //   }
        // }
        console.log("recCode:", recCode)

        try
        {
          //const url = `http://localhost:5000/Receipts/${encodeURIComponent(recCode)}`;
          const url = `https://ntabi.co.kr/Receipts/${encodeURIComponent(recCode)}`;
          let response = await this.$api(url, formData);

          if(response.status === 200)
          {
            let dUseFlag = "Y"
            let makeExpReceipt = await this.$api("/api/proc_createExpReceipt", {param: [
              recCode, this.arr_expesneInfo[i].dealCode, this.arr_expesneInfo[i].dSubNo, this.arr_expesneInfo[i].dFlag, this.user.handlerId, file.name, dUseFlag
            ]});
            if(makeExpReceipt.affectedRows >= 0)
            {
              this.$swal.fire("","증빙자료가 등록되었습니다", "info").then(() => {
                this.getReceiptList(this.rKey)
              })
            }
            else
            {
              this.$swal.fire("","증빙자료를 재등록해주세요.", "warning")
              return false;
            }
          }
          else
          {
            this.$swal.fire("","증빙자료 등록에 오류가 발생했습니다.", "error")
            return false;
          }
        }
        catch(error)
        {
          console.error("upload Error:", error)
        }

      }
      else
      {
        this.$swal.fire("","업로드할 증빙자료가 업습니다", "info")
        return false;
      }
    },

    async getReceiptList(key){         //sFlag: 셀렉트방식 (전체리스트A / 특정리스트P)
      let res_receipts = []
      let recNames = []
      let recSubNo = []
      let i = 0
      while(i < this.arr_expesneInfo.length)
      {
        res_receipts = await this.$api("/api/res_receipts", {param: [ this.arr_expesneInfo[i].dealCode + "-" +  this.arr_expesneInfo[i].dSubNo]});
        console.log("res_receipts:", res_receipts)
        
        if(res_receipts.length > 0)
        {
          recNames = res_receipts.map(e => e.recName)
          recSubNo = res_receipts.map(e => e.rSubNo)
          //console.log("recNames:", recNames, "recSubNo:",recSubNo)
          this.arr_expesneInfo[i].recNames = recNames
          this.arr_expesneInfo[i].recSubNo = recSubNo
        }
        else
        {
          this.arr_expesneInfo[i].recNames = ""
        }
        //console.log("arr_expesneInfo:", this.arr_expesneInfo[i])
        i++
      }
      this.rKey = key++
    },

    // async getReceiptList(recCode, sFlag){         //sFlag: 셀렉트방식 (전체리스트A / 특정리스트P)
    //   let res_receipts = []
    //   if(recCode != "" && sFlag === "P")          //특정내역의 증빙
    //   {
    //     res_receipts = await this.$api("/api/res_receipts", {param: [ recCode ]});
    //     if(res_receipts.length > 0)
    //     {
    //       this.res_receipts = res_receipts
    //       console.log("res_receipts:", this.res_receipts)
    //     }
    //   }
    //   else if(recCode == "" && sFlag === "A")
    //   {
    //     //let obj_receipts = {}

    //     let recNames = []
    //     let recSubNo = []
    //     let i = 0
    //     while(i < this.arr_expesneInfo.length)
    //     {
    //       res_receipts = await this.$api("/api/res_receipts", {param: [ this.arr_expesneInfo[i].recCode ]});
          
    //       if(res_receipts.length > 0){
    //         console.log("res_receipts:", res_receipts)
    //         recNames = res_receipts.map(e => e.recName)
    //         recSubNo = res_receipts.map(e => e.rSubNo)

    //         console.log("recNames:", recNames, "recSubNo:",recSubNo)
    //         this.arr_expesneInfo[i].recNames = recNames
    //         this.arr_expesneInfo[i].recSubNo = recSubNo
    //       }
    //       else
    //       {
    //         this.arr_expesneInfo[i].recNames = ""
    //       }
    //       //console.log("arr_expesneInfo:", this.arr_expesneInfo[i])
    //       i++
    //     }
    //   }
    //   console.log("arr_expesneInfo:>>>>", this.arr_expesneInfo)
    //   this.rKey++
    //   console.log("rKey:", this.rKey)
    // },
    
    async del_recName(recName, recCode){
      let folder = recCode
      let receipt = recName
      let flag = "receipt"
      let response = await this.$api("/api/deletePhoto", {param: folder, receipt, flag})    //call back-end

      if(response != undefined)
      {
        let uFlag = "N"
        let delReceipt_d = await this.$api("/api/delReceipt_d", {param: [ uFlag, this.user.handlerId, recName, recCode ]});
        if(delReceipt_d.affectedRows > 0)
        {
          this.$swal.fire("", "선택된 증빙자료를 삭제하였습니다", "info").then(() => {
            //this.getReceiptList(recCode, "A")
            this.getReceiptList(this.rKey)
          })
        }
        else
        {
          this.$swal.fire("", "선택된 증빙자료 삭제오류가 발생했습니다. 관리자에 문의하여 주십시오", "warning")
          return false
        }
      }
    },
    
    //품의서 생성
    makeRequisition(){
      console.log("arr_expesneInfoFinal:", this.arr_expesneInfo)
      let arr_valueFlag = this.arr_expesneInfo.filter(item => {
        return (!item.recNames || (typeof item.recNames === "string" && item.recNames.trim() === ""))
      })
      console.log("valueFlag:", arr_valueFlag)
      
      let undefinedRecNames = arr_valueFlag.map(item => ({
        arr_check: item.dealCode + "-" + item.dSubNo
      }))

      const arr_checkValues = undefinedRecNames.map(item => item.arr_check);
      console.log("arr_checkValues:", arr_checkValues);

      if(arr_valueFlag.length > 0)
      {
        this.$swal.fire("", "증빙자료가 첨부되지 않은 세부지출내역(" + arr_checkValues + ")이 있습니다.", "warning")
        return false
      }
      else
      {
        this.goToExpReport();
      }
    },

    //지출품의서로 이동
    goToExpReport(){
      const width = window.innerWidth * 0.75; // 현재 창의 너비의 65%
      const height = window.innerHeight * 1; // 현재 창의 높이의 100%
      const left = (window.innerWidth - width) / 2; // 가운데 정렬을 위한 왼쪽 위치 계산
      const top = (window.innerHeight - height) / 2; // 가운데 정렬을 위한 위쪽 위치 계산

      let dCode = 'DL-2310540AL'
      const url = `/expenseReport?dCode=${dCode}`;
      // 데이터를 로컬스토리지에 저장
      localStorage.setItem('expenseInfo', JSON.stringify(this.arr_expesneInfo))

      window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }
  }
}
</script>

<style>
.table_sup td {
  padding: 0
}

/**테이블 안의 오브젝트 크기세팅 */
.resize-select {
    width: 100%;
    box-sizing: border-box;
    font-size: smaller;
  }

.resize-input {
  width: 100%;
  box-sizing: border-box;
  font-size: smaller;
}

/**스위치버튼 컬러처리 */
.custom-switch .form-check-input:checked {
    background-color: #007bff;
  }
  
  .custom-switch .form-check-input:checked+.form-check-label::before {
    background-color: #fff;
  }
</style>