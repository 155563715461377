<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <!-- <div class="float-start mb-1">
          <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode"
            v-model="rProcCode" @change="changeProcCode()">
            <option :value="noVal" disabled>진행상태선택</option>
            <option value="A" >전체보기</option>
            <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
          </select>
        </div> -->
        <div class="float-end mb-1">
          <!-- <input type="text" class="form-control form-control-sm text-primary" placeholder="제목 또는 상담요청번호" v-model="searchKeyword" @click="searchInfo()"> -->
          <input v-model="dataInput" @input="submitAutoComplete" type="text" class="form-control form-control-sm text-primary" placeholder="신청자 또는 상담번호" />
          <!-- <div class="autocomplete disabled"> -->
          <div class="autocomplete disabled">
            <div class="text-success" style="cursor: pointer" :key="i" v-for="(res,i) in result" @click="searchData(res)">
              <small>{{ res }}</small>
            </div>
          </div>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class = "small">
            <tr>
              <th>상태</th>
              <th>신청일</th>
              <th>상담번호</th>
              <th>상품명</th>
              <th>신청자</th>
              <th>회원</th>
              <th>연락처</th>
              <!-- <th>이메일</th> -->
              <th>응대자</th>
              <th>상품참조</th>
              <th>cFlag</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(rList, i) in reqList">
              <td>{{ rList.procName }}</td>
              <td>{{ getDateFormat(rList.reqDate) }}</td>
              <td class="text-primary"><a @click="goToReqReserveDetail(rList.reqReserveCode, rList.reqPdtCode,rList.rCopyFlag);" style="cursor:pointer;">{{ rList.reqReserveCode }}</a></td>
              <!-- <td>{{ rList.pdtNameKor  }}</td> -->
              <td v-if="rList.reqPdtCode.substr(0,2) === 'MX'">{{ rList.mxName  }}</td>
              <td v-else>{{ rList.mName  }}</td>
              <td>{{ rList.reqName }}</td>
              <td class="text-danger" v-if="rList.resMFlag === 'N'">X</td>
              <td v-else></td>
              <td>{{ rList.reqTelNo }}</td>
              <!-- <td>{{ rList.reqTel }}</td> -->
              <td>{{ rList.respodent }}</td>
              <td>
                  <button type="button" class="btn btn-outline-success btn-sm me-1" @click="refProduct(rList.reqPdtCode);">
                    <i class="bi bi-search small"></i>
                  </button>
              </td>
              <td>{{rList.rCopyFlag}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      reqList: [],
      rProc: [],

      skillInput: null,
      result: null,
      arrSearchData: []
    };
  },
  created() {
    //this.bSeq = this.$route.query.bSeq;
    //this.getProductMaList();
    this.getReqProcLevel();
    this.getRequestConsultList();

    console.log("AccessInfo:",this.$store.state.user); 
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      //console.log("rProc:",this.rProc);
    },
    async getRequestConsultList() {
      let reqList = await this.$api("/api/reqResConsultList", {});
      //console.log("stat:",reqList[1].reqPdtCode.substr(0,2));
      // if(reqList.reqPdtCode.substr(0,2) === "MX") (
      //   this.reqList.pdtKorName = reqList.mxName
      // )
      // else {
      //   this.reqList.pdtKorName = reqList.mName
      // }
      console.log("reqList:",reqList);
      
      let i=0;
      let arrSearchData = [];
      if(reqList.length > 0) {
        this.reqList = reqList;
        while(i<reqList.length) {
        // if(reqList[i].reqPdtCode.substr(0,2) === "MX") {
        //   //arrSearchData.push(reqList[i].reqReserveCode, reqList[i].reqName, reqList[i].pdtCode);
        //   arrSearchData.push(reqList[i].reqReserveCode, reqList[i].reqName, reqList[i].mxName);
        // }
        // else {
        //   arrSearchData.push(reqList[i].reqReserveCode, reqList[i].reqName, reqList[i].mName);
        // }
          arrSearchData.push(reqList[i].reqReserveCode, reqList[i].reqName);
          i++;
        }
      }
      this.arrSearchData = arrSearchData;
      console.log("arrSearchData:",arrSearchData)
    },
    //자동완성기능
    submitAutoComplete() {
      const autocomplete = document.querySelector(".autocomplete");
      let result_tmp = [];
      if (this.dataInput) {
        autocomplete.classList.remove("disabled");
        autocomplete.style.display = "block"
        result_tmp = this.arrSearchData.filter((e) => {
          return String(e).match(new RegExp("^" + this.dataInput.toUpperCase(), "i"));
        });
        //결과값 중복제거
        this.result = Array.from(new Set(result_tmp));        
      }
      else {
        autocomplete.classList.add("disabled");
        autocomplete.style.display = "none";
        this.getRequestConsultList()
      }
    },
    async searchData(res) {
      console.log("reqList1:", this.reqList);
      console.log("res:",res);
      let reqList_tmp = this.reqList;
      if(res.substr(0,2) === "RQ") {
        this.reqList = await reqList_tmp.filter(e => {return e.reqReserveCode === res});
      }
      else {
        this.reqList = await reqList_tmp.filter(e => {return e.reqName === res});
      }

      //console.log("reqList_searchData:",this.reqList);
    },
    refProduct(pdtCode) {
      this.$router.push({path: '/detail', query: {productId: pdtCode}});
    },
    goToReqReserveDetail(seqReq, pCode, cCode) {
      this.$router.push({path: '/reserveInfoDetail', query: {seqReq: seqReq, pCode: pCode, cCode: cCode}});
      //console.log("oId:", orderId);
    },
    async changeProcCode() {
      if(this.rProcCode === 'A') {
        this.getRequestConsultList();
      }
      else {
        let reqList_tmp = await this.$api("/api/reqResConsultList", {})
        console.log("reqList_tmp:",reqList_tmp);
        this.reqList = reqList_tmp.filter(e => {return e.reqStatus === this.rProcCode});
      }
    }
  }
}
</script>