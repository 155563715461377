<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="float-end mb-1">
          <!-- <button type="button" class="btn btn-primary" @click="goToInsert">TEST</button> -->
          <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode"
            v-model="rProcCode" @change="changeProcCode()">
            <option :value="noVal" disabled>진행상태선택</option>
            <option value="A" >전체보기</option>
            <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
          </select>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class = "small">
            <tr>
              <th>상태</th>
              <th>견적번호</th>
              <th>구분</th>
              <th>신청일</th>
              <th>제목</th>
              <th>문의자</th>
              <th>이메일</th>
              <th>연락처</th>
              <th>응대자</th>
              <th>답변</th>
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(rList, i) in reqList">
              <td>{{ rList.status }}</td>
              <td class="text-primary">
                <small><a @click="goToRequestDetail(rList.qtCode, rList.seqReq);" style="cursor:pointer;">{{ rList.qtCode }}</a></small>
              </td>
              <td>{{ rList.pCategory }}</td>
              <td>{{ getDateFormat(rList.insDate) }}</td>
              <td>{{ rList.reqTitle }}</td>
              <td>{{ rList.reqPersonName }}</td>
              <td>{{ rList.reqEmail }}</td>
              <td>{{ rList.reqTel }}</td>
              <!-- <td>{{ rList.handlerNameKor }}</td> -->
              <td>{{ rList.respodent }}</td>
              <td>{{ rList.ansFlag }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      reqList: [],
      rProc: [],
      ansFlag: "N"
    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
    // 데이타베이스 쿼링테스트
    //this.getProductMaList();
    this.getReqProcLevel();
    //this.getCheckAnserFlag();
    this.getRequestList();

  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      //console.log("rProc:",this.rProc);
    },
    // async getCheckAnserFlag()
    // {
    //   let checkAnswerFlag = await this.$api("/api/checkAnswerForQuotation", {});
    //   console.log("checkAnswerFlag:",checkAnswerFlag);
    // },
    //견적리스트
    async getRequestList() 
    {
      let reqList = await this.$api("/api/reqQuotationList", {});
      if(reqList.length > 0) {
        this.reqList = reqList;
      }
      console.log("reqList:",this.reqList);
    },
    goToRequestDetail(qtCode, seqReq) {
      this.$router.push({path: '/quotationDetail', query: {qtCode: qtCode, seqReq: seqReq}});
      //console.log("oId:", orderId);
    },
    async changeProcCode() {
      if(this.rProcCode === 'A') {
        this.getRequestList();
      }
      else {
        let reqList_tmp = await this.$api("/api/reqQuotationList", {})
        console.log("reqList_tmp:",reqList_tmp);
        this.reqList = reqList_tmp.filter(e => {return e.reqStatus === this.rProcCode});
      }
    }
  }
}
</script>