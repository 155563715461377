<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="main p-2">
        <h2 class="text-center"><u>&nbsp;확정 수지계산서 리스트&nbsp;</u></h2>

        <div class="text-primary text-start fs-6 mb-2 print-hide">
          <b>[ &nbsp; 확정수지계산서 검색 &nbsp; ]</b>
        </div>
        <table class="table table-bordered border-success align-middle w-100 h-auto print-hide text-center">
          <tbody>
            <tr>
              <th class="table-secondary col-1">
                <select class="form-select form-select-sm" v-model="this.searchDuration">
                  <option value="G" selected>행사월</option>
                  <!-- <option value="E">정산월</option> -->
                </select>
              </th>
              <td class="col-2">
                <div class="input-group">
                  <span class="input-group-text input-group-sm" style="font-size:smaller">선택월</span>
                  <input type="month" class="form-control form-control-sm" ref="searchMonth" v-model="this.searchMonth" style="font-size:smaller">
                </div>
              </td>
              <th class="table-secondary col-1">
                담당자
              </th>
              <td class="col-2">
                <select class="form-select form-select-sm" v-model="this.chargePerson" style="font-size: smaller;" ref="dealChargeId">
                  <option :value="p.handlerId" :key="i" v-for="(p,i) in this.chargePList">{{ p.handlerNameKor }}</option>
                </select>
              </td>
            </tr>
          </tbody> 
        </table>
        <div style="display: flex; justify-content: end;">
          <!-- <div class="text-success fs-6 mb-2 print-hide">
            <b><button type="button" class="btn btn-success btn-sm" @click="makeProfitAndLoss(searchDuration, searchMonth, searchNtCd, searchArCd, chargePerson)">수지계산서생성</button></b>
            <span style="font-size:smaller; padding-left: 5px; color: red; vertical-align: bottom;">※ 담당자 기준으로 생성(국가/지역무관)</span>
          </div> -->
          <div class="text-success text-end fs-6 mb-2 print-hide">
            <b><button type="button" class="btn btn-outline-secondary btn-sm" @click="searchDefault">검색조건초기화</button></b>
            &nbsp;&nbsp;
            <b><button type="button" class="btn btn-outline-primary btn-sm" @click="searchSettleData">검색하기</button></b>
          </div>
        </div>

        <!-- 모달영역 -->
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <span @click="showModal = false" class="close print-hide">&times;</span>
            <Modal_BusinessInvoice :showModal="showModal" :modalContent="this.sel_dealCode" @close="closeModal"/>
          </div>
        </div>

        <!-- 정산정보 조회결과 -->
        <div class="mt-3 print-hide" style="height: auto;">
          <ag-grid-vue3 
            style="width: 100%; height: 800px;" 
            class="ag-theme-balham" 
            :columnDefs="columnDefs"
            :rowData="rowData"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
            @cell-clicked="onCellClicked"
            >
          </ag-grid-vue3>
        </div>

      </div>
    </main>
  </div>
</template>

<script>
import Modal_BusinessInvoice from "./Modal_BusinessInvoice.vue";
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

export default {
  components: {
    Modal_BusinessInvoice,
    'ag-grid-vue3': AgGridVue
  },  
  data() {
    return {
      //modal컨트롤
      showModal: false,
      modalContent: "",
      sel_dealCode: "",      //선택된 딜코드

      searchDuration: "G",   //검색기간기준
      searchMonth: "",       //검색월
      chargePerson: "",      //검색명 담당자명

      partnerInfo: [],       //검색용 거래처정보

      //담당자데이타
      chargePList: [],    //담당자리스트

      settleDataInfo: [], //검색된 정산데이타

      //agGrid
      columnDefs: [
        { headerCheckboxSelection: true, checkboxSelection: true, resizable: true, width: 50 },
        { 
          headerName: '',     field: '',   sortable: true, resizable: false, width: 70,
          cellRenderer: (params) => {
            if (params.data.plFix) {
              return '<button type="button" class="btn btn-danger btn-sm justify-content-center" style="font-size: smaller">파기</button>';
            }
            return params.value;
          }
        },
        { headerName: 'CodeNo',     field: 'plCode',   sortable: true, resizable: true, width: 122 },
        { headerName: '기준월',     field: 'plMonth',   sortable: true, resizable: true, width: 80 },
        { headerName: '부서',   field: 'tName',     sortable: true, resizable: true, width: 110 },
        { headerName: '담당자',   field: 'handlerNameKor',     sortable: true, resizable: true, width: 110 },
        { headerName: '판매가(KWD)',     field: 'sales_KRW_tot',      sortable: true, resizable: true, width: 110,
          cellStyle: () => {
            return { color: 'red'}
          }
        },

        { headerName: '지출금액(KWD)',     field: 'dPrice_curKRW_sum_n_tot',      sortable: true, resizable: true, width: 110,
          cellStyle: () => {
            return { color: 'blue'}
          }
        },
        { headerName: '지출금액(JPY)',     field: 'dPrice_curJPY_sum_n_tot',      sortable: true, resizable: true, width: 110,
          cellStyle: () => {
            return { color: 'red'}
          }
        },
        { headerName: '지출금액(USD)',     field: 'dPrice_curUSD_sum_n_tot',      sortable: true, resizable: true, width: 110,
          cellStyle: () => {
            return { color: 'darkgrey'}
          }
        },
        { headerName: '지출금액(TWD)',     field: 'dPrice_curTWD_sum_n_tot',      sortable: true, resizable: true, width: 110,
          cellStyle: () => {
            return { color: 'green'}
          }
        },

        { headerName: '알선수수료',  field: 'searchersFee_n_tot',     sortable: true, resizable: true, width: 110 },
        { headerName: '공급가액',    field: 'providePrice_n_tot',     sortable: true, resizable: true, width: 110 },
        { headerName: '부가가치세',  field: 'vatPrice_n_tot',     sortable: true, resizable: true, width: 110 },
        { headerName: '미수금',      field: 'accountRecievalbe_n_tot',     sortable: true, resizable: true, width: 110 },
        { headerName: '상품판매수익', field: 'profit_n_tot',     sortable: true, resizable: true, width: 110,
        cellStyle: () => {
            return { color: 'green'}
          }
        },
        { headerName: '비고',        field: 'plRemarks',     sortable: true, resizable: true, width: 300 }, 
      ],
      rowData: [],  //정산검색결과 초기값
      gridOptions: {
        rowSelection: "multiple"
        //onGridReady: 'onGridReady'
      },
    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;
    this.getHandlerList()
  }, 
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    this.dealChargeId = this.user.handlerId
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";

    this.searchSettleData("default")
  },
  methods: {
    //담당자리스트
    async getHandlerList(){
      //담당자정보
      let chargePList = await this.$api("/api/handlerInfo", {})
      if(chargePList.length > 0)
      {
        this.chargePList = chargePList;
      }
    },

    //확정수지계산서호출
    async makeProfitAndLoss(plCode){
      let fFlag = "Y"

      const width = window.innerWidth * 0.90; // 현재 창의 너비의 90%
      const height = window.innerHeight * 1; // 현재 창의 높이의 100%
      const left = (window.innerWidth - width) / 2; // 가운데 정렬을 위한 왼쪽 위치 계산
      const top = (window.innerHeight - height) / 2; // 가운데 정렬을 위한 위쪽 위치 계산

      const url = `/businessInvoice_profit_loss?sD=&sM=&sNt=&sAr=&sCp=&pCode=${plCode}&fFlag=${fFlag}`;
      window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    },

    openModal(sel_dealCode){
      this.showModal = true
      this.sel_dealCode = sel_dealCode
    },

    //검색조건 초기화
    searchDefault(){
      this.searchMonth = ""
      this.chargePerson = ""
    },

    closeModal(){
      this.showModal = false;
    },

    //그리드에서 선택된 로우정보취득
    onGridReady(params) { 
      this.gridOptions.api = params.api;    //할당을 해야 함(실서버용)
      const gridApi = params.api;

      //gridApi에 이벤트리스너 등록
      gridApi.addEventListener("selectionChanged:", this.onSelectionChanged)

      // 체크된 노드
      let selectedNodes = gridApi.getSelectedNodes();

      // 선택된 로우의 데이터를 배열로 저장
      let selectedData = selectedNodes.map(node => node.data);

      console.log("selectedData:", selectedData);
    },

    // //그리드에서 선택된 로우정보취득
    // onGridReady(params) {
    //   this.gridOptions.api = params.api;    //할당을 해야 함(실서버용)
    //   const gridApi = params.api;

    //   //gridApi에 이벤트리스너 등록
    //   gridApi.addEventListener("selectionChanged:", this.onSelectionChanged)

    //   // 체크된 노드
    //   let selectedNodes = gridApi.getSelectedNodes();

    //   // 선택된 로우의 데이터를 배열로 저장
    //   let selectedData = selectedNodes.map(node => node.data);

    //   console.log("selectedData:", selectedData);
    // },

    //선택된 그리드값 취득
    onSelectionChanged(){
      let selectedRows = this.gridOptions.api.getSelectedNodes();
      let selectedData = selectedRows.map(node => node.data);
      console.log("selectedData:", selectedData);
    },

    //클릭된 그리드의 plCode취득
    onCellClicked(event){
      let selectedRows = this.gridOptions.api.getSelectedNodes();
      let sel_rowData = selectedRows.map(node => node.data);
      //console.log("sel_rowData:",sel_rowData)
      
      let sel_plCode = sel_rowData[0].plCode
      //console.log("sel_dealCode:", sel_dealCode);

      //그리드 클릭
      if(event.event.target.innerText === "파기")
      {
        //확정수지계산서 취소
        this.cxl_plReport(sel_plCode)
        //this.goToBizInvoice(sel_dealCode);
      }
      else
      {
        //상세페이지로 이동
        this.goToBizInfoDetail(sel_plCode);
      }
    },

    async cxl_plReport(plCode){
      let plFix = "N"
      let plUseFlag = "N"
      let res_fixedProfitLoss = await this.$api("/api/upd_fixedProfitLoss", {param: [plFix, plUseFlag, plCode]} )
      if(res_fixedProfitLoss.affectedRows > 0)
      {
        this.$swal.fire("", `${plCode}는 파기되었습니다`, "info")
        location.reload(true)
      }
      else
      {
        this.$swal.fire("", `${plCode}의 파기에 오류가 발생했습니다. 재시도해주세요`, "info")
        return false
      }
    },

    //상세정보로 이동(새탭에서 열기)
    goToBizInfoDetail(dCode){
      this.makeProfitAndLoss(dCode)
      // const url = `/businessInfo_detail?dCode=${dCode}`;
      // window.open(url, '_blank');
    },

    // //상세정보로 이동
    // goToBizInvoice(dCode){
    //   //console.log("dCode:",dCode)
    //   this.$router.push({path:'/businessInvoice', query: {dCode: dCode}})
    // },

    //새로운 탭에서 열리도록
    // goToBizInvoice(dCode) {
    //   const url = `/businessInvoice?dCode=${dCode}`;
    //   window.open(url, '_blank');
    // },
    
    // goToBizInvoice(dCode) {
    //   const width = window.innerWidth * 0.65; // 현재 창의 너비의 65%
    //   const height = window.innerHeight * 1; // 현재 창의 높이의 100%
    //   const left = (window.innerWidth - width) / 2; // 가운데 정렬을 위한 왼쪽 위치 계산
    //   const top = (window.innerHeight - height) / 2; // 가운데 정렬을 위한 위쪽 위치 계산

    //   const url = `/businessInvoice?dCode=${dCode}`;
    //   window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    // },


    //memberInfo: 회원정보리스트용 sql
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },

    //정산데이타 검색(프로시저이용)
    async searchSettleData(param){
      let searchDuration = ""
      let searchMonth = ""

      //확정수지계산서추출
      if(param === "default")
      {
        //기본값인 경우 당해월 수지계산서 리스트 호출
        let today = new Date();
        let yy = today.getFullYear().toString();
        let mm = today.getMonth()+1;
        let ym = `${yy}-${mm}`;

        searchDuration = "G"
        searchMonth = ym
      }
      else
      {
        searchDuration = this.searchDuration
        searchMonth = this.searchMonth
      }

      let settleDataInfo_tmp = await this.$api("/api/proc_searchProfitLoss_fixed", {param: [
        searchDuration, searchMonth, this.chargePerson
      ]});
      
      if(settleDataInfo_tmp.length > 0)
      {
        if (settleDataInfo_tmp.length > 0) 
        {
          this.settleDataInfo = settleDataInfo_tmp[1];

          for(let i=0; i<this.settleDataInfo.length; i++)
          {
            let sales_KRW_tot = 0
            let dPrice_curKRW_sum_n_tot = 0
            let dPrice_curJPY_sum_n_tot = 0
            let dPrice_curUSD_sum_n_tot = 0
            let dPrice_curTWD_sum_n_tot = 0
            let searchersFee_n_tot = 0
            let providePrice_n_tot = 0
            let vatPrice_n_tot = 0
            let accountRecievalbe_n_tot = 0
            let profit_n_tot = 0

            sales_KRW_tot             = this.getCurrencyFormat(this.settleDataInfo[i].sales_KRW_tot)
            dPrice_curKRW_sum_n_tot   = this.getCurrencyFormat(this.settleDataInfo[i].dPrice_curKRW_sum_n_tot)
            dPrice_curJPY_sum_n_tot   = this.getCurrencyFormat(this.settleDataInfo[i].dPrice_curJPY_sum_n_tot)
            dPrice_curUSD_sum_n_tot   = this.getCurrencyFormat(this.settleDataInfo[i].dPrice_curUSD_sum_n_tot)
            dPrice_curTWD_sum_n_tot   = this.getCurrencyFormat(this.settleDataInfo[i].dPrice_curTWD_sum_n_tot)
            searchersFee_n_tot        = this.getCurrencyFormat(this.settleDataInfo[i].searchersFee_n_tot)
            providePrice_n_tot        = this.getCurrencyFormat(this.settleDataInfo[i].providePrice_n_tot)
            vatPrice_n_tot            = this.getCurrencyFormat(this.settleDataInfo[i].vatPrice_n_tot)
            accountRecievalbe_n_tot   = this.getCurrencyFormat(this.settleDataInfo[i].accountRecievalbe_n_tot)
            profit_n_tot              = this.getCurrencyFormat(this.settleDataInfo[i].profit_n_tot)

            // settleDataInfo 배열 객체에 수지계산 지출합산결과 추가
            this.settleDataInfo[i] = {
              ...this.settleDataInfo[i],
              sales_KRW_tot,          
              dPrice_curKRW_sum_n_tot,
              dPrice_curJPY_sum_n_tot,
              dPrice_curUSD_sum_n_tot,
              dPrice_curTWD_sum_n_tot,
              searchersFee_n_tot,
              providePrice_n_tot,    
              vatPrice_n_tot,    
              accountRecievalbe_n_tot,
              profit_n_tot
            };            
          }

          this.rowData = this.settleDataInfo;
        }
        console.log("rowData:::::::::::::::::::::::", this.rowData)
      }

      // if(this.dCode != "")
      // {
      //   this.rowData = this.settleDataInfo.filter(item => {
      //     return item.dealCode === this.dCode.trim()
      //   })
      // }
    },

  }
}
</script>

<style scoped>
.ag-header-cell-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ag-theme-balham ::ng-deep .ag-header-cell .ag-header-cell-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

/** 그리드 컬럼값 컨트롤 */
.red-font-bold {
  color:red;
  font-weight: bold;
}

/**Modal CSS */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;;
  /*z-index: 1;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 5px;
  border: 1px solid #888;
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  /*box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);*/
}

/*
.close {
  color: #aaa;
  float: center;
  font-size: 22px;
  font-weight: bold;
  text-align: end;
  cursor: pointer;
}
*/
</style>