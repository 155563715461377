export async function genCode(api, rCode) {
  //인증번호생성(4자리)
  const numbers = '0123456789';
  const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ';
  let code;
  let rtnCode;

  // 앞의 두 자리는 숫자로 선택
  code = numbers.charAt(Math.floor(Math.random() * numbers.length)) +
    numbers.charAt(Math.floor(Math.random() * numbers.length));

  // 뒤의 두 자리는 영어로 선택
  code += characters.charAt(Math.floor(Math.random() * characters.length)) +
    characters.charAt(Math.floor(Math.random() * characters.length));


  rtnCode = rCode + code;

  return rtnCode;
}