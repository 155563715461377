<template>
  <div>
    <section id="privacy">
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-lg-12" style="line-height:200%">
            <h3 class="d-flex justify-content-center" style="font-weight:600">개인정보 취급방침</h3>
              
            <hr style="margin:30px 0;">

            <p class="text-start" style="font-size:.85rem"> 
              <strong>개인정보 취급방침</strong>

              <br>

              엔타비 글로벌 (이하 '엔타비')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 
              있도록 다음과 같은 처리방침을 두고 있습니다.<br>

              엔타비는 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
              <br>
              본 방침은부터 2016년 6월 1일부터 시행됩니다.
              <br><br>

              <strong>1. 개인정보 수집 및 이용목적</strong>
              <br>
              엔타비는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 
              구할 예정입니다.
              <br>
              1) 홈페이지 회원가입 및 관리
              <br>
              &nbsp;회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 
              만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
              <br>
              2) 서비스 제공
              <br>
              &nbsp; 물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.
              <br>
              3) 마케팅 및 광고에의 활용
              <br>
              &nbsp;신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 
              서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
              <br><br>

              <strong>2. 개인정보 수집 항목</strong>
              <br>
              엔타비는 다음의 개인정보 항목을 처리하고 있습니다.
              <br>
              1) 홈페이지 회원가입 및 관리/마케팅 정보 제공의 목적 (회원탈퇴 시 까지)<br>
              &nbsp; 이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름<br>
              2) 상담 및 예약의 목적 (신용카드정보, 은행계좌정보)<br>
              &nbsp; 이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 여권사본, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호<br>
              3) 여행자 보험가입의 목적 (신용카드정보, 은행계좌정보)<br>
              &nbsp; 이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름<br>
              4) 대금결제의 목적 (이용목적이 달성되면 파기)<br>
              &nbsp; 신용카드정보, 은행계좌정보
              <br><br>

              <strong>3. 개인정보 수집 항목 보유 및 이용기간</strong>
              <br>
              1) 엔타비는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.
              <br>
              2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
              <br>
              &nbsp; ① 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 
              <br>
              &nbsp; ② 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 
              <br>
              &nbsp; ③ 대금결제 및 재화 등의 공급에 관한 기록 : 5년 
              <br>
              &nbsp; ④ 계약 또는 청약철회 등에 관한 기록 : 5년 
              <br>
              &nbsp; ⑤ 표시/광고에 관한 기록 : 6개월
              <br><br>

              <strong>4. 개인정보의 제3자 제공 및 공유</strong>
              <br>
              1) 엔타비는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
              <br>
              2) 엔타비는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
              <br>
              &nbsp; ① 항공사/선박업체/여행업체/은행·카드 CP사/비자발급대행사/인솔자(여행을 다녀온 시점에 즉시 파기)<br>
              &nbsp;&nbsp;&nbsp; > 콘텐츠 제공, 구매 및 요금 결제, 개인 식별, 불만처리 등 민원처리, 고지사항 전달의 목적<br>
              &nbsp;&nbsp;&nbsp; > 성명(국/영문), 여권 및 비자 사본, 전화번호, 주소, 회원 종류 위탁
              <br>
              &nbsp; ② 숙박업체<br>
              &nbsp;&nbsp;&nbsp; > 숙박 예약 및 개인 식별의 목적
              <br>
              &nbsp; ③ 토파스<br>
              &nbsp;&nbsp;&nbsp; > 항공권 카드 결제의 목적<br>
              &nbsp;&nbsp;&nbsp; > 한글명, 카드사명, 카드번호, 카드유효기간 위탁
              <br>
              &nbsp; ④ KB손해보험<br>
              &nbsp;&nbsp;&nbsp; > 여행자 보험가입의 목적<br>
              &nbsp;&nbsp;&nbsp; > 한글명, 영문명, 주민번호 위탁
              <br><br>

              <strong>5. 개인정보의 권리,의무 및 그 행사방법</strong>
              <br>
              정보주체의 권리,의무 및 그 행사방법 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
              <br>
              1) 정보주체는 엔타비에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
              <br>
              &nbsp; ㄱ. 개인정보 열람요구
              <br>
              &nbsp; ㄴ. 오류 등이 있을 경우 정정 요구
              <br>
              &nbsp; ㄷ. 삭제 요구
              <br>
              &nbsp; ㄹ. 처리정지 요구
              <br>
              2) 제1항에 따른 권리 행사는 엔타비에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 
              <br>
              엔타비는 이에 대해 지체 없이 조치하겠습니다.
              <br>
              3) 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 엔타비는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 
              않습니다.
              <br>
              4) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
              <br><br>

              <strong>6. 개인정보 자동수집 장치(쿠키 등)의 설치, 운영 및 그 거부에 관한 사항</strong>
              <br>
              1) 쿠키의 사용 목적
              <br>
              &nbsp; ① 회사는 이용자의 정보를 수시로 저장하고 '쿠키(cookie)'를 사용합니다. 쿠키란 회사의 웹사이트를 운영하는 데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.
              <br>
              &nbsp; ② 고객의 접속빈도나 방문시간 등을 분석하여 마케팅 및 서비스 개편 등의 척도로 활용합니다. 
              <br>
              &nbsp; ③ 선택한 상품들에 대한 정보들을 추적하여 맞춤 서비스를 제공하는 데 이용합니다.
              <br>
              &nbsp; ④ 회사가 진행하는 각종 이벤트에서 차별화된 정보를 제공하기 위한 자료로 이용됩니다.
              <br><br>
              2) 쿠키의 설치/운용 및 거부<br>
              &nbsp; ① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 조정함으로써 모든 쿠키를 허용/거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다.<br>
              &nbsp; ② 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.<br>
              &nbsp; &nbsp; ㄱ. [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.<br>
              &nbsp; &nbsp; ㄴ. [개인정보 탭]을 클릭합니다.<br>
              &nbsp; &nbsp; ㄷ. [개인정보취급 수준]을 설정하시면 됩니다.
              <br><br>


              <strong>7. 개인정보의 안전성 확보 조치</strong><br>
              1) 정기적인 자체 감사 실시<br>
              &nbsp; 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.<br>

              2) 개인정보 취급 직원의 최소화 및 교육<br>
              &nbsp; 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br>

              3) 내부관리계획의 수립 및 시행<br>
              &nbsp; 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br>

              4) 해킹 등에 대비한 기술적 대책<br>
              &nbsp; 엔타비는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br>

              5) 개인정보의 암호화<br>
              &nbsp; 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br>

              6) 접속기록의 보관 및 위변조 방지<br>
              &nbsp; 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br>

              7) 개인정보에 대한 접근 제한<br>
              &nbsp; 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br>

              8) 문서보안을 위한 잠금장치 사용<br>
              &nbsp; 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br>

              9) 비인가자에 대한 출입 통제<br>
              &nbsp; 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
              <br><br>


              <strong>8.개인정보에 관한 민원서비스</strong><br>
              엔타비는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br>
              <br>
              개인정보 보호책임자 성명 : 백상훈<br>
              전화번호 : 1660-4602<br>
              이메일 : paeksh@ntabi.co.kr<br>
              <br>
              기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br>
              ① 개인정보 침해신고센터 (www.1336.or.kr/국번 없이 118)<br>
              ② 정보보호 마크인증위원회 (www.eprivacy.or.kr/ 02-580-0533~4)<br>
              ③ 대 검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/ 02-3480-3600)<br>
              ④ 경찰청 사이버테러대응센터 (www.ctrc.go.kr/ 02-392-0330)
              <br><br>

              <strong>9. 개인정보 처리 방침 변경</strong><br>
              이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.<br>

            </p>
            </div>
          </div>
        </div>
    </section>
  </div>
</template>

<style>
#privacy{
  margin:4rem 0
}
</style>
