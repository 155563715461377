<template>
  <main class="mt-3">
    <div class="container cask">
      <div class="row justify-content-center">

        <h2 class="d-flex justify-content-center" style="font-weight:600">문의하기</h2>
        <div class="mt-3">
          <button class="btn btn-outline-secondary btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" 
            aria-controls="collapseWidthExample">
            필수확인사항 (클릭)
          </button>
        </div>
        <div style="min-height: auto" class="mt-2">
          <div class="collapse collapse-horizontal" id="collapseWidthExample">
            <div class="card card-body bg-secondary shadow-sm p-3 mb-5 bg-body rounded text-success" style="width: auto">
              <small>※ 여행에 관련하여 궁금하신 것이 있으신가요?</small>
              <small>담당직원이 확인하여 곧 답변을 드리겠습니다.</small>
              <small class="mt-2">조금만 기다려 주십시오.</small>
              <small class="mt-2">카카오톡 플러스친구로 문의를 주시면,</small>
              <small>더욱 빠른 답변을 받아보실 수 있습니다.</small>
              <small class="text-end">
                <input type="checkbox" v-model="ruleAgree" ref="chb_ruleAgree" class="align-bottom mt-4">
                &nbsp;개인정보 수집에 동의합니다.
              </small>
            </div>
          </div>
        </div>
        <!-- <hr style="margin:10px 0;"> -->
      </div>

      <div class="row justify-content-center">
          <div class="mb-3 row">
            <div class="box p-3">
              <p class="head mx-4 mt-3">확인사항</p>
              <p class="cinfo mx-4">문의에 대한 답변은 <strong>[나의 페이지 > 나의 문의]</strong> 에서 확인할 수 있습니다. </p>
              <hr>
            </div>
          </div>
        
        <div class="mt-4 row">
          <label class="col-md-3 col-form-label">문의유형<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_inquiry"
            v-model="this.reqInfo.selCat">
              <option :value="noVal" disabled>-- 문의유형 --</option>
              <option :value="catList.procCode" :key="i" v-for="(catList, i) in inquiryCatList">
                {{ catList.procName }}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3 mt-2 row">
          <label class="col-md-3 col-form-label">문의제목<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <input type="text" maxlength="22" class="form-control" ref="txt_reqTitle" v-model="this.reqInfo.reqTitle">
          </div>
        </div>
        <div class="mb-3 mt-2 row">
          <label class="col-md-3 col-form-label">성함<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <input type="text" maxlength="12" class="form-control" ref="txt_reqPersonName" v-model="this.reqInfo.reqPersonName">
          </div>
        </div>
        <div class="mb-3 mt-2 row">
          <label class="col-md-3 col-form-label">인원수<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <!-- <input type="number" maxlength="4" class="form-control" ref="txt_perCnt" v-model="this.reqInfo.perCnt" @oninput="cntCheck(this)"> -->
            <input type="number" maxlength="4" class="form-control" ref="txt_perCnt" v-model="this.reqInfo.perCnt">
          </div>
        </div>
        <div class="mb-3 mt-2 row" v-if="this.reqInfo.selCat === 'PA' || this.reqInfo.selCat === 'TR'">
          <label class="col-md-3 col-form-label">여권정보
            <p class="form-switch">
              <input class="form-check-input" type="checkbox" role="switch" ref="chb_passFlag"
                v-model="this.reqInfo.passFlag" true-value="N" false-value="Y">&nbsp;없음
            </p>
          </label>
          <div v-if="this.reqInfo.passFlag === 'N'"></div>
          <div class="col-md-9" v-else>
            <div class="input-group">
              <span class="input-group-text col-2 justify-content-center">만료일</span>
              <input type="date" class="form-control" ref="txt_salesStart" v-model="this.reqInfo.passExpire">
            </div>
          </div>
        </div>
        <div class="mb-3 mt-2 row">
          <label class="col-md-3 col-form-label">이메일<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <input type="email" maxlength="40" class="form-control" ref="txt_cEmail" v-model="this.reqInfo.reqEmail">
          </div>
        </div>
        <div class="mb-3 mt-2 row">
          <label class="col-md-3 col-form-label">연락처(휴대폰)<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <!-- <input type="tel" ref="txt_cTel" placeholder="00*000*0000" pattern="[0-9]{2,3}[0-9]{3,4}[0-9]{3,4}" maxlength="13" 
            v-model="this.reqInfo.reqTel"> -->
            <input type="text" maxlength="11" id="reqTel" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.reqInfo.reqTel">
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-md-3 col-form-label">문의사항<font class="text-danger">*</font></label>
          <div class="col-md-9">
            <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
            <textarea class="form-control" maxlength="248"  placeholder="여행이나 항공(승선)권 문의의 경우는 운송수단,인원정보,탑승예정일 필수(250자 이내)" ref="txt_reqSpRemarks" id="floatingTextarea" 
              style="height:150px" v-model="this.reqInfo.reqSpRemarks"></textarea>
          </div>
        </div>
        <div class="mb-3 row">
          <div class="col-auto d-grid p-1">
            <button type="button" class="btn btn-lg btn-primary" @click="requestInsert();">문의</button>
          </div>
          <div class="col-auto d-grid p-1">
            <button type="button" class="btn btn-lg btn-danger" @click="goToHome()">취소</button>
          </div>
          <!-- <div class="col-auto d-grid p-1">
            <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
          </div> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
//import moment from 'moment';
export default {
  data() {
    return {
      inquiryCatList: {}, //질의영역분류
      reqInfo: []
    };
  },
  created(){
    this.getInquiryCatList();
    
    console.log("userInfo:",this.$store.state.user);
    console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }
    //alert(this.user);
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }
  },  
  methods: {
    async getInquiryCatList() {
      let inquiryCatList_tmp = await this.$api("/api/inquiryCatList3", {});
      let inquiryCatList = inquiryCatList_tmp.filter((item) => {return item.uFlag === 'Y'})
      if (inquiryCatList.length > 0) 
      {
        this.inquiryCatList = inquiryCatList;
      }
      console.log("inquiryCatList:",this.inquiryCatList);
    },
    async requestInsert() {
      //alert(this.reqInfo.passFlag);
      //오늘날짜추출
      const date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      const time = new Date().toTimeString().split(" ")[0];
      let today = date + ' ' + time;

      // console.log("today:", date + ' ' + time);
      if(this.ruleAgree == "" || !this.ruleAgree) { 
        this.$refs.chb_ruleAgree.focus();
        return this.$swal.fire("","진행을 위해서 필수확인사항에 동의를 해주십시오.", "warning");
      }
      if(this.reqInfo.selCat === "noVal" || this.reqInfo.selCat == undefined) { 
        this.$refs.sel_inquiry.focus();
        return this.$swal.fire("","문의영역을 선택해주세요.", "question");
      }
      if(!this.reqInfo.reqTitle) { 
        this.$refs.txt_reqTitle.focus();
        return this.$swal.fire("","필수누락: 문의제목", "warning");
      }
      if(!this.reqInfo.reqPersonName) {
        this.$refs.txt_reqPersonName.focus();
        return this.$swal.fire("","필수누락: 문의자 성함", "warning");
      }
      if(!this.reqInfo.perCnt) {
        this.$refs.txt_perCnt.focus();
        return this.$swal.fire("","필수누락: 인원수", "warning");
      }
      else if(this.reqInfo.perCnt < 1) {
        this.$refs.txt_perCnt.select();
        return this.$swal.fire("","최소 1인 이상으로 입력해주세요.", "info");
      }      
      else if(this.reqInfo.perCnt > 15) {
        this.$refs.txt_perCnt.select();
        return this.$swal.fire("","15인 이상은 별도 문의를 해주세요<br>(TEL) 051-466-4602", "info");
      }
      if(!this.reqInfo.reqEmail) {
        this.$refs.txt_cEmail.focus();
        return this.$swal.fire("","필수누락: 이메일", "warning");
      }
      if(!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]+$/.test(this.reqInfo.reqEmail)) {
        this.$refs.txt_cEmail.focus();
        return this.$swal.fire('','이메일 양식이 불명확합니다', 'question');
      }
      if(!this.reqInfo.reqTel) {
        this.$refs.txt_cTel.focus();
        return this.$swal.fire("","필수누락: 연락처", "warning");
      }
      if(!/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(this.reqInfo.reqTel)) {
        this.$refs.txt_cTel.focus();
        return this.$swal.fire('','연락처가 불명확합니다', 'question');
      }
      if(!this.reqInfo.reqSpRemarks) {
        this.$refs.txt_reqSpRemarks.focus();
        return this.$swal.fire("","필수누락: 문의내용", "warning");
      }
      this.reqInfo.insDate = today;
      this.reqInfo.reqStatus = "IQ";

      let resIns_Request = await this.$api("/api/requestInsert", 
      //inqCat, reqTitle, reqPersonName, perCnt, reqEmail, reqTel, passFlag, passExpire, reqSpRemarks, agreeFlag, reqStatus, insId, insDate
        {param: [this.reqInfo.selCat,this.reqInfo.reqTitle,this.reqInfo.reqPersonName,this.reqInfo.perCnt,this.reqInfo.reqEmail,
        this.reqInfo.reqTel,this.reqInfo.passFlag,this.reqInfo.passExpire,this.reqInfo.reqSpRemarks,this.ruleAgree,
        this.reqInfo.reqStatus,this.user]});
      if(Object.keys(resIns_Request)[0] === 'error') {
        this.$swal.fire('문의등록오류','죄송하지만 재문의를 해주세요<br>연속오류 발생 시 연락처<br> (1660-4602)', 'error')
      }
      else {
        this.$swal.fire('문의등록완료','감사합니다<br>빠른 시간안에 확인연락을 드리겠습니다.', 'success');
        this.$router.push({path: '/'});
      }
      console.log("resIns_Request:",resIns_Request);
    },
  goToHome() {
    this.$router.push({path: '/'});
  }
  }
}
</script>

<style>
.cask .box {
  text-align:left
}
.cask .box .head{
  font-size:.9rem;
  font-weight:bold;
  margin-bottom:0
}
.cask .box .cinfo{
  font-size:.8rem;
  margin-bottom:15px;
  line-height:20px
}
.cask .box hr{
  margin:.5rem 0
}
.cask .box .tit{
  font-size:.9rem;
  font-weight:bold
}
.cask .box .form-select{
  border-radius: 0.375rem !important
}
.cask .box .ask-btn{
  margin-top:3rem;
}

.cask .go {
  padding:15px 0;
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.cask .cxl{
  padding:15px 0;
  background-color: #eee;
  border-radius:0;
}
.cask .go:hover{
  background-color: #444;
}
.cask .cxl:hover{
  border:1px solid #777
}
</style>