<template>
  <div id="biei">
    <div class="biei-main">
      <img class="dkdlrjektitle" src="../assets/img/biei/title.png" alt="">
      <img class="bg" src="../assets/img/biei/bg.png" alt=""> 
      <div class="white_op">
        <img class="line-blue" src="../assets/img/biei/ang.png" alt="">
        </div> 
    </div>
    <img class="dash" src="../assets/img/biei/line.png" alt="">
    <img class="blue" src="../assets/img/biei/back.png" alt="">
      <img class="q" src="../assets/img/biei/q.png" alt="">
      <img class="q2" src="../assets/img/biei/q2.png" alt="">
      <img class="map" src="../assets/img/biei/map.png" alt="">
      <img class="map2" src="../assets/img/biei/map2.png" alt="">
    <div class="bottom_bg">
      <img class="title3" src="../assets/img/biei/title3.png" alt="">
      <img class="choice" src="../assets/img/biei/choice.png" alt="">
        <img class="title2" src="../assets/img/biei/title2.png" alt="">
        <img class="miri" src="../assets/img/biei/miri.png" alt="">
        <img class="back2" src="../assets/img/biei/back2.png" alt="">
    </div>
    <img class="title4" src="../assets/img/biei/title4.png" alt="">
    <div class="last_bg">
      <img class="sangse" src="../assets/img/biei/sangse.jpg" alt="">
    </div>
    <div class="bottom_btn">
      <a class="going_btn" href="javascript:void(0)" @click="goToDetail('L-2023220-1676874910805','L')">예약하기</a>
    </div>
      
  </div>
  
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {

    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요',
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'});
    // }
    //this.isMobile = this.checkIfMobile();
  },
  methods: {
    goToDetail(productId, salesRoute) 
    {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
  }
}
</script>



<style>
@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@300;400;500;700&display=swap');
.dkdlrjektitle{
  display: block;
  position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.biei-main{
  width: 100%;
  background: url(../assets/img/biei/main_back2.jpg) center no-repeat;
  background-size: 100%;
}
.white_op{
  position: absolute;
  width: 100%;
  bottom: 12vh;
  height: 10vh;
}
.bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;

}

.blue{
  width: 100%;
}
.white_op img{
  width: 100%;
}

.bottom_bg{
margin: 0 auto;
width: 100%;
height: 100%;
}
.dash{
position: absolute;
top: 95vh;
left: 40%;
right: 50%;
transform: rotate(-40deg);
}
.map{
position: absolute;
top: 92vh;
right: 50%;
}
.map2{
position: absolute;
top: 90%;
left: 15vw;
right: 50%;
}
.q{
  position: absolute;
}
.q2{
  position: absolute;
}
.title3{
  display: block;
  margin: 0 auto;
}
.choice{
  margin:  0 auto;
  display: block;
  margin-bottom: 200px;
}
.title2{
margin: 0 auto;
display: block;
}
.miri{
  display: block;
  margin: 100px auto;
}
.back2{
  width: 100%;
}
.last_bg{
  background-color: rgb(0, 67, 157);
}
.sangse{
  display: block;
  margin: 0 auto;
}
.title4{
  position: absolute;
  
  left: 50%;
  right: -50%;
  transform: translate(-50%,-50%);
}
.bottom_btn{
  width: 100%;
  height: 100px;
  margin: 0 auto;
}
.going_btn{
  margin: 50px auto;
  display: block;
  width: 250px;
  height:70px;
  background-color: rgb(0, 67, 157);
  text-align: center;
  padding: 20px 50px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0px 9px 0px 0px rgb(0, 46, 107);
  transition: all 0.5s;
}
.going_btn:hover{
box-shadow: 0px 0px 0px 0px rgb(0, 46, 107);
transform: translateY(9px);
color: orange;
}
@media(max-width:2560px) and (min-width:1921px){
  .biei-main{
  width: 100%;
  height: 90vh;
  margin-top: 40px;
  }

  .white_op{
  position: absolute;
  width: 100%;
  bottom: 12vh;
  height: 10vh;
  }
  .map{
    right: 28%;
  }

  .map2{
    left: 25%;
  }
  .q2{
    top: 120%;
    left: 43%;
  }
  .q{
    top: 120%;
    left: 35%;
  }
  .title4{
    top: 470%;

  }
}
@media(max-width:1920px) and (min-width:768px){
  .biei-main{
  width: 100%;
  height: 90vh;
  margin-top: 40px;
  }
  .white_op{
  width: 100%;
  }
  .map{
    top:82vh;
    right: 17%;
  }

  .map2{
    left: 14%;
  }
  .q2{
    top: 120%;
    left: 43%;
  }
  .q{
    top: 120%;
    left: 35%;
  }
  .title4{
    top: 560%;

  }
}
@media(max-width:389px) and (min-width: 375px){
  .biei-main{
  width: 100%;
  height: 196px;
  margin-top: 35px;
  }
  .dkdlrjektitle{
    width: 150px;
    top:20%;
  }
  .bg{
    display: none;
  }
  .white_op{
    bottom: 58%;
  }
  
  .map{
    width: 100px;
    top:37vh;
    right: 17%;
  }

  .map2{
    width: 100px;
    left: 14%;
    top: 37%;
  }
  .q2{
    width: 130px;
    top: 50%;
    left: 33%;
  }
  .q{
    width: 150px;
    top: 48%;
    left: 19%;
  }
  .title4{
    width: 100%;
    top: 339%;
}
.sangse{
  width: 100%;
}
.miri{
  width: 100%;
}
.title2{
  width: 100%;
}
.choice{
  width: 100%;
}
.title3{
  width: 100%;
}
.dash{
  width: 100px;
  top: 38%;
}
}
@media(max-width:414px) and (min-width: 390px){
  .biei-main{
  width: 100%;
  height: 196px;
  margin-top: 35px;
  }
  .dkdlrjektitle{
    width: 150px;
    top:15%;
  }
  .bg{
    display: none;
  }
  .white_op{
    bottom: 66%;
  }
  
  .map{
    width: 120px;
    top:27vh;
    right: 15%;
  }

  .map2{
    width: 100px;
    left: 17%;
    top: 28%;
  }
  .q2{
    width: 130px;
    top: 39%;
    left: 33%;
  }
  .q{
    width: 150px;
    top: 39%;
    left: 19%;
  }
  .title4{
    width: 100%;
    top: 276%;
}
.sangse{
  width: 100%;
}
.miri{
  width: 100%;
}
.title2{
  width: 100%;
}
.choice{
  width: 100%;
}
.title3{
  width: 100%;
}
.dash{
  width: 100px;
  top: 28%;
}
}

@media(max-width:430px) and (min-width: 415px){
  .biei-main{
  width: 100%;
  height: 196px;
  margin-top: 35px;
  }
  .dkdlrjektitle{
    width: 150px;
    top:15%;
  }
  .bg{
    display: none;
  }
  .white_op{
    bottom: 68%;
  }
  
  .map{
    width: 120px;
    top:26vh;
    right: 18%;
  }

  .map2{
    width: 120px;
    left: 17%;
    top: 25%;
  }
  .q2{
    width: 100px;
    top: 37%;
    left: 42%;
  }
  .q{
    width: 150px;
    top: 35%;
    left: 23%;
  }
  .title4{
    width: 100%;
    top: 268%;
}
.sangse{
  width: 100%;
}
.miri{
  width: 100%;
}
.title2{
  width: 100%;
}
.choice{
  width: 100%;
}
.title3{
  width: 100%;
}
.dash{
  width: 100px;
  top: 28%;
}
}
</style>

