<template>
  <div id="Bsu_tenyear_anniversary">
    <div class="Bsu_container">
      <p class="bottom_p" >좋은 여행과 서비스 제공을 위해 항상 발전하겠습니다!<br> <br>
      <span class="bottom_color">
        예약 가능 일자 7월 한 달간! <br>
       투어 탑승 가능 일자 7월~12월! <br>
       해당 이벤트는 조기 마감될 수 있습니다.
        </span> 

      </p>
      <img class="tbg" src="../assets/img/TenyearAnniversary/topbg.png" alt="" style="width: 100%;">
      <img class="main_title" src="../assets/img/TenyearAnniversary/source.png"  alt="">
      <div class="main_p_lo">
        <p class="main_p">여행자님의 성원과 사랑에 힘 입어 <br> 
                          엔데이트립이 <span class="year_color">10주년</span>을 맞이하였습니다!<br>
                          감사합니다 고객님!
       </p>

       <img class="Best_seller" src="../assets/img/TenyearAnniversary/source2.png" alt="" >
       <div class="click_line">

        <a class="click_osaka" href="javascript:void(0)" @click="goToDetail('L-2023220-1676865317720','L')">
          <img  src="../assets/img/TenyearAnniversary/clicksource.png" alt="" >
        </a>

        <a class="click_aso" href="javascript:void(0)" @click="goToDetail('L-2023221-1676942590519','L')">
          <img  src="../assets/img/TenyearAnniversary/clicksource2.png" alt="" >
        </a>
       </div>
       <div class="click_line_pay">
        <img class="img_margin_left" src="../assets/img/TenyearAnniversary/pay1.png" alt="">
        <img src="../assets/img/TenyearAnniversary/pay2.png" alt="">
       </div>
       
       
      </div>
      <img class="manwon" src="../assets/img/TenyearAnniversary/source3.png" alt="">
      <img class="ribon" src="../assets/img/TenyearAnniversary/ribon1.png" alt="">
      <img class="ribon2" src="../assets/img/TenyearAnniversary/ribon2.png" alt="">

      <a href="javascript:void(0)" @click="goToDetail('L-2431993LGC','L')">
        <img class="img_doya" src="../assets/img/TenyearAnniversary/doya.png" alt="" style="z-index: 1000;">
      </a>

      <a href="javascript:void(0)" @click="goToDetail('L-2432173MCC','L')">
        <img class="img_nitka" src="../assets/img/TenyearAnniversary/nitka.png" alt="" style="z-index: 1000;">
      </a>

      <a href="javascript:void(0)" @click="goToDetail('L-2023329-1680070456245','L')">
        <img class="img_dokyo" src="../assets/img/TenyearAnniversary/dokyo.png" alt="" style="z-index: 1000;">
      </a>

      <a href="javascript:void(0)" @click="goToDetail('L-2023221-1676943535719','L')">
        <img class="img_nago" src="../assets/img/TenyearAnniversary/nago.png" alt="" style="z-index: 1000;">
      </a>

      <a href="javascript:void(0)" @click="goToDetail('L-2024312-1710224515956','L')">
        <img class="img_yama" src="../assets/img/TenyearAnniversary/yama.png" alt="" style="z-index: 1000;">
      </a>

      <a href="javascript:void(0)" @click="goToDetail('L-2024228-1709084594375','L')">
        <img class="img_gobe" src="../assets/img/TenyearAnniversary/gobe.png" alt="" style="z-index: 1000;">
      </a>

      <a href="javascript:void(0)" @click="goToDetail('L-2023221-1676948010488','L')">
        <img class="img_oki" src="../assets/img/TenyearAnniversary/oki.png" alt="" style="z-index: 1000;">
      </a>
      
      <div>
        <img class="bottom_title" src="../assets/img/TenyearAnniversary/source4.png" alt="">
        
      </div>
      
    </div>
    

  </div>
</template>

<script>

import defaultPath from '@/assets/img/taiwan/back.jpg';

export default {
  // DB에서 가져온 데이타
  data() {
    return {
      //imagePath: "/IMGs/testtaiwan/back.jpg"   //상단이미지 default
      imagePath: defaultPath,   //상단이미지 default
      animateImage: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
    
  },
  methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  },
  mounted() {

    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요', 
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'}); 
    // }
  }
}

</script>

<style>
#Bsu_tenyear_anniversary{
  margin-top: -56px;
}
.Bsu_container{
  width: 100%;
  margin: 0 auto;
  height: 4700px;
  background-color: rgb(24,20,18);
}
.main_title{
  width: 700px;
  position: absolute;
  top: 400px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
}
.main_p_lo{
  width:100%;
  position: absolute;
  top: 630px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
}
.main_p{
  color: #fff;
  font-size: 25px;
  text-align: center;
  font-weight: 500;
}
.year_color{
color: rgb(255, 208, 83);
font-weight: 600;
}
.Best_seller{
  display: block;
  position: absolute;
  top: 350px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
}
.click_line{
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  z-index: 1000;
  position: absolute;
  top: 800px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  box-sizing: border-box;
}
.click_line a{
  width: 40%;
  opacity: 0.5;
  transition: all 0.4s;
}
.click_line img{
  max-width: 100%;
  height: auto;
  
}
.click_line a:hover{
  opacity: 1;
}
.click_line_pay{
  display: flex;
  justify-content: center;
  width: 90%;
  z-index: 1000;
  position: absolute;
  top: 1180px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  box-sizing: border-box;
}
.click_line_pay img{
  width: 25%;
}
.click_osaka{
  margin-right: 30px;
}
.img_margin_left{
  margin-right:50px;
}
.manwon{
  position: absolute;
  top: 2000px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 800px;
}
.ribon{
  width: 60%;
  position: relative;
  top: 500px;
  left: 0;
  z-index: 99;
}
.ribon2{
  display: inline-block;
  width: 40%;
  position: relative;
  top: 1830px;
  right: 110px;
  z-index: 99;
  rotate: 17deg;
}
.img_doya{
  position: absolute;
  top: 2350px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 1100px;
}
.img_nitka{
  position: absolute;
  top: 2630px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 1100px;
}
.img_dokyo{
  position: absolute;
  top: 2910px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 1100px;
}
.img_nago{
  position: absolute;
  top: 3190px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 1100px;
}
.img_yama{
  position: absolute;
  top: 3470px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 1100px;
}
.img_gobe{
  position: absolute;
  top: 3750px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 1100px;
}
.img_oki{
  position: absolute;
  top: 4030px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 1100px;
}
.bottom_title{
  position: absolute;
  top: 4300px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 1100px;
}
.bottom_p{
  position: absolute;
  top: 4460px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 25px;
}
.bottom_color{
  color: rgb(255, 222, 165);
  font-weight: 600;
}

@media (max-width: 1920px) and (min-width: 701px){
  .click_line{
    top: 700px;
  }
  .ribon{
  width: 900px;
  top: 800px;
  left: 0;
  }
  .ribon2{
    width: 730px;
    top: 1650px;
    right: -200px;
  }
  .bottom_title{
  top: 3400px;
  width: 800px;
}
.bottom_p{
  top: 3530px;
  font-size: 20px;
}
.bottom_color{
  font-weight: 400;
}
.img_doya{
  top: 2030px;
  width: 800px;
}
.img_nitka{
  top: 2230px;
  width: 800px;
}
.img_dokyo{
  top: 2430px;
  width: 800px;
}
.img_nago{
  top: 2630px;
  width: 800px;
}
.img_yama{
  top: 2830px;
  width: 800px;
}
.img_gobe{
  top: 3030px;
  width: 800px;
}
.img_oki{
  top: 3230px;
  width: 800px;
}

.click_line_pay{
  width: 90%;
  top: 970px;
}

.manwon{
  position: absolute;
  top: 1750px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 600px;
}
.Bsu_container{
  height: 3700px;
}
}

/*---------------------------------------------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------폰 사이즈-------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------------------------------------------*/

@media (max-width: 700px){
  .main_title{
    width: 80%;
    top: 200px;
  }
  .main_p_lo{
    top: 300px;
  }
  .main_p{
    font-size: 15px;
  }
  .Best_seller{
    width: 80%;
    top: 130px;
  }
  .click_line{
    top: 250px;
    width: 100%;
  }
  .click_line a{
    opacity: 1;
  }
  .click_line_pay img{
    width: 45%;
  }
  .click_line_pay{
    top: 360px;
  }
  .img_margin_left{
    margin-right: 40px;
  }
  .manwon{
    width: 80%;
    top: 700px;
  }
  .ribon{
    display: none;
  }
  .img_doya{
    width: 92%;
    top: 800px;
  }
  .img_nitka{
    width: 92%;
    top: 885px;
  }
  .img_dokyo{
    width: 92%;
    top: 970px;
  }
  .img_nago{
    width: 92%;
    top: 1055px;
  }
  .img_yama{
    width: 92%;
    top: 1140px;
  }
  .img_gobe{
    width: 92%;
    top: 1225px;
  }
  .img_oki{
    width: 92%;
    top: 1310px;
  }
  .ribon2{
    display: none;
  }
  .bottom_title{
    width: 80%;
    top: 1400px;
  }
  .bottom_p{
    top:1472px;
    font-size: 12px;
  }
  .Bsu_container{
    height: 1600px;
  }
  .tbg{
    margin-top: 67px;
  }
}
</style>

