<template>
  <div class="nonmeber">
    <main>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-3 my-5">
            <h3 class="d-flex justify-content-center mb-5">비회원 로그인</h3>
            <form class="mb-3">
              <div class="form-floating mt-3">
                <input type="text" class="form-control" placeholder="예약번호" v-model="bId" ref="txt_bId">
                <label for="floatingInput">예약번호</label>
              </div>
              <div class="form-floating mt-2 input-group">
                <input type="text" class="form-control" id="floatingPassword" placeholder="인증번호" v-model="bPass" ref="txt_bPass">
                <label for="floatingPassword">인증번호</label>
                <button type="button" class="btn btn-success btn-sm me-1" @click="reqCertiNo(this.bId);">
                  <font style="font-size:small" class="align-bottom">번호요청</font>
                </button>
              </div>
            </form>
            <div v-if="this.showCertiTime == 'Y'">
              인증번호 유효시간 ( <font class="text-danger">{{ this.timeRemaining }}</font> )
            </div>
            <button class="w-100 go btn mt-3" type="button" ref="enterBtn" @click="loginCheck()">예 약 조 회</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import { generateRandomString } from '../assets/js/commonFunctions'

export default {
  data() {
    return {
      loginInfo: [],
      checkNonMember: [],
      bId: "",
      bPass: "",
      certiNo: "",
      timeRemaining: "", //인증번호유효시간
      showCertiTime: "N" //유효시간표시
    }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },
  created() {
    // 페이지생성과 동시에 리스트정보를 get
		this.nRN = this.$route.query.nRN;
    if(this.nRN !="")
    {
      this.bId = this.nRN
    }
    //console.log("nRN:", this.nRN, "bId:", this.bId);

    //this.getCountdown(1)
  },
  mounted() {
    document.addEventListener("keydown",this.handleEnterKeyPress);
  },
  beforeUnmount() {
    document.removeEventListener("keydown",this.handleEnterKeyPress);
  },  
  methods: {
    handleEnterKeyPress(event)
    {
      if(event.keyCode === 13)
      {
        this.$refs.enterBtn.click();
      }
    },
    //인증번호 유효시간표시
    getCountdown(minutes)
    {
      this.showCertiTime = "Y"
      let seconds = minutes * 60;

      const timer = setInterval(() => {
        const minutesRemaining = Math.floor(seconds / 60);
        const secondsRemaining = seconds % 60;
        this.timeRemaining = `${minutesRemaining.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`;

        seconds--;
        if (seconds < 0) {
          clearInterval(timer);
          this.timeRemaining = "00:00";
          //this.$api("/api/upd_certiNumber", {param: [this.bPass, this.bId]});
        }
      }, 1000); // 1초마다 실행
    },
    async reqCertiNo(rCode)
    {
      this.bPass = "";
      if(!rCode)
      {
        this.$swal.fire('','예약번호를 입력하세요', 'info')
        return false;
      }
      else
      {
        let rFlag = rCode.substring(0,2)
        if(rFlag == 'RQ' || rFlag == 'EV')
        {
          //console.log("여행예약")
          let nonMemResInfo = await this.$api("/api/nonMemResInfo", {param:[rCode]});
          if(nonMemResInfo.length > 0)
          {
            //this.nonMemResInfo = nonMemResInfo;
            let nmTel = nonMemResInfo[0].nonTelNo
            let nmRes = nonMemResInfo[0].nonResCode

            this.generateRandomString(rCode, nmTel, nmRes);
          }
        }
        // else if(rFlag === 'EV')
        // {
        //   console.log("행사예약")
        // }
        else
        {
          this.$refs.txt_bId.select();
          this.$swal.fire('','유효한 예약번호가 아닙니다.', 'info')
          return false;
        }
        //this.generateRandomString(rCode);
      }
    },
    async generateRandomString(rCode, nmTel, nmRes)
    {
      this.certiNo = await generateRandomString(this.$api, rCode);
      if(!this.certiNo)
      {
        this.bPass = "";
        this.$swal.fire('','인증번호를 다시 요청해주세요.', 'info')
        return false;        
      }
      else
      {
        this.sendKakao('CT', this.certiNo, nmTel, nmRes)
        this.getCountdown(5)
      }
    },
    /** 카카오알림 발송 */
    async sendKakao(param, cNo, nmTel, nmRes)
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: 
      [
        cNo, param, 'NonMember'
      ]});

      //- 인증번호: #{certiNo}

      if(insKSendHistory.affectedRows > 0) {
        if(param == "CT") 
        {
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(인증번호발송)
            certiNo: cNo,
            clientCall: nmTel,
            resCode: nmRes,
            alimFlag: "CT" //알림유형(인증번호발송)
          };
          this.$api("api/kakaoAlimSend4", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '예약시 등록한 연락처로 인증번호가 발송되었습니다', 'success');
          //location.reload(true)
        }
      }
    },    
    async loginCheck() 
    {
      if(!this.bId)
      {
        this.$refs.txt_bId.focus();
        this.$swal.fire('','예약번호를 입력하세요', 'info')
        return false;
      }

      if(!this.bPass)
      {
        this.$refs.txt_bPass.focus();
        this.$swal.fire('','요청하신 인증번호를 입력하세요', 'warning')
        return false;
      }

      if(this.timeRemaining === '00:00' || this.timeRemaining === '')
      {
        await this.$api("/api/upd_certiNumber_nullify", {param: [this.bId]});
        this.bPass = "";
        this.$swal.fire('','인증번호가 유효하지 않습니다. 재요청해주세요.', 'warning')
        return false;
      }      

      //let swal = require('sweetalert2');
      let loginInfo = await this.$api("/api/checkNonMember", {param: [this.bId, this.bPass]});
      this.loginInfo = loginInfo;

      if(loginInfo.length <= 0) {
        //this.$refs.txt_bId.select();
        this.bPass = "";
        this.$swal.fire('','예약이 없거나, 인증번호가 유효하지 않습니다.', 'question')
        return false;
      }
      else
      {
        let nonMemResInfo1 = await this.$api("/api/nonMemResInfo", {param:[this.bId]});
        let connector = "";
        let resFlag = "";
        resFlag = this.bId.substring(0, 2);
        connector = nonMemResInfo1[0].nonName;
        
        this.$swal.fire('',`안녕하세요? ${connector}님.`, 'success');

        let upd_certiNumber = await this.$api("/api/upd_certiNumber", {param: [this.bPass, this.bId]});
        if(upd_certiNumber.affectedRows > 0)
        {
          if(resFlag === "EV")
          {
            this.$router.push({path: '/eventInfo_client', query: { eCode: this.bId, ntCd: "nonM" }});
          }
          else
          {
            this.$router.push({path: '/reserveInfoDetail_non', query: {seqReq: this.bId, pCode: this.nonMemResInfo[0].nonPdtCode, cCode: 'Y'}});
          }
        }
        else
        {
          this.$swal.fire('','인증처리 중 에러가 발생했습니다(upd_certiNumber). 인증번호를 재요청해 주세요.', 'question')
          return false;
        }
        
        // // 로그인 & 세션 처리
        // this.$api("/api/accNonMember", {param: 
        //   [
        //     //bId: 접속자ID, bName: 접속자이름, bGrade: 작업레벨, admin: loginInfo.admin, alimCall: loginInfo.Call
        //     { bId: loginInfo.nonResCode, bName: loginInfo.nonName, bGrade: 'NM', alimCall: ''}
        //   ]
        // });
        // // location.reload();
        // this.$store.commit("user", loginInfo);
        // this.$router.push({path: '/'});
      }
    }
  }
}
</script>



<style>
.nonmeber{
  padding:3.5rem 0;
  background-color:#fff;
}
.nonmeber h3{
  font-size: 32px;
  font-weight:500;
}

.nonmeber .go {
  padding:15px 0;
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.nonmeber .go:hover{
  background-color: #444;
}
</style>
