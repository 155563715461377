<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>텍스트 템플릿 등록</u></h2>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">적용상품/분류/제목<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ctCd" v-model="this.pattern.pdtCat" @change="makeDefaultText" style="width:auto;">
              <option :value="pcl.baseCode" :key=i v-for="(pcl,i) in pdtCatList">{{pcl.codeNameKor}}</option>
              <option value="" class="text-danger">미지정</option>
            </select>
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_ctCd" v-model="this.pattern.pCat" @change="makeDefaultText" style="width:auto;">
              <option :value="rProcCode.baseCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.codeNameKor}}</option>
              <option value="A" class="text-danger">알림톡안내</option>
            </select>
            <input type="text" class="form-control w-75" ref="txt_pTitle" v-model="pattern.pTitle">
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">표시템플릿<font class="text-danger">*</font></label>
        <div class="col-md-9" v-if="this.pattern.pCat === 'A'">
          <textarea class="form-control" placeholder="※알림톡용은 700자 이내(중요)" ref="txt_nContent" id="floatingTextarea" style="height:250px" v-model="pattern.pContent" maxlength="700"></textarea>
        </div>
        <div class="col-md-9" v-else>
          <textarea class="form-control" placeholder="2,000자 이내" ref="txt_nContent" id="floatingTextarea" style="height:250px" v-model="pattern.pContent" maxlength="2000"></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="patternInsert();">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList">리스트</button>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
export default {
  data() {
    return {
      pattern: {
        pTitle: "",
        pContent: "",
        insDate: "",
        insId: "",
        pUseFlag: "N", 
        pCat: "",               //유의(P), 취소(C), 체크(L), 알림톡(A)
        patternCode: "",        //공지코드
      },
      rProc: [],                //요청처리단계

      pdtCatList: [],       //적용상품카테고리
      //fileList: "" //선택된 이미지파일
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() 
  {
    console.log("USER:",this.user);
    this.getReqProcLevel();
    this.getPdtCategory()               //적용상품카테고리
    
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) 
    {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";   
  },
  methods: {
    //적용상품리스트
    async getPdtCategory()
    {
      let pdtCatList = await this.$api("/api/baseCategory", {param:["pdtCd"]});
      if(pdtCatList.length > 0) 
      {
        this.pdtCatList = pdtCatList
      }
      console.log("pdtCatList:",this.pdtCatList);
    },

    async getReqProcLevel()
    {
      //alert("param:"+param)
      let rProc = await this.$api("/api/baseCategory", {param:["spCd"]});
      if(rProc.length > 0) 
      {
        this.rProc = rProc
      }
      //console.log("rProc:",rProc);
    },    

    //텍스트내용 초기화
    makeDefaultText(){
      this.pattern.pContent = ""
    },

    // //적용상품선택
    // selPdtCategory(pdtCode){
    //   alert(pdtCode)
    // },
    
    goToList() 
    {
      this.$router.push({path: '/patternList'});
    },

    async patternInsert() 
    {
      //오늘날짜추출
      let timeStamp = "";
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();

      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;

      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;

      let ymd = `${yyyy}${mm}${dd}`

      timeStamp = today.getTime(); // 행사코드에 들어갈 타임스탬프
      let catCode = "PT";
      let patternCode = catCode+"-"+ymd+"-"+timeStamp; //행사코드
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
      // console.log("instDate:",insDate);

      //console.log("today:", date + ' ' + time);
      console.log("patternCode:", patternCode);

      if(!this.pattern.pCat) {
        this.$refs.sel_ctCd.focus();
        this.$swal.fire("분류를 선택하세요");
        return false;
      }
      // if(!this.notice.nContent) {
      //   this.$refs.txt_nContent.focus();
      //   return this.$swal.fire("필수누락: 공지내용");
      // }
      // if(!this.productInfo.catCode) {
      //   this.$refs.sel_pdtCategoryt.focus();
      //   return this.$swal.fire("필수누락: 상품카테고리");
      // }
      this.pattern.patternCode = patternCode;
      this.pattern.insDate = insDate;
      this.pattern.insId = this.user.handlerId;
      //this.pattern.pUseFlag = "N";
      console.log("this.pattern:", this.pattern);

      let resIns_pattern = await this.$api("/api/patternInsert", {param: [this.pattern]});
      if(Object.keys(resIns_pattern)[0] === 'error') 
      {
        this.$swal.fire('','저장실패', 'error')
      }
      else 
      {
        this.$swal.fire('','저장완료', 'success');
        this.$router.push({path: '/patternList'});
      }
    },
  }
}
</script>