<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>교 통 편 등 록</u></h2>
      <div class="mb-3 row">
        <!-- 수정불가 -->
        <label class="col-md-3 col-form-label">상품코드</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="this.productId" readonly>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품구분</label>
        <div class="col-md-9">
          <div class="form-check-inline" :key=i v-for="(baseCode,i) in this.sRoute">
            <div v-if="this.sRoute[i].baseCode === this.salesRoute">
              <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value=this.sRoute[i].baseCode ref="rbt_salesRoute" checked disabled>
              <label class="form-check-label text-primary" for="flexRadioDefault1">
                {{ this.sRoute[i].codeNameKor }}
              </label>
            </div>
            <div v-else>
              <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value=this.sRoute[i].baseCode ref="rbt_salesRoute" disabled>
              <label class="form-check-label text-secondary" for="flexRadioDefault1">
                {{ this.sRoute[i].codeNameKor }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<font class="text-secondary" style="font-size: small;">(국문)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_pdtNameKor" v-model="productInfo.pdtNameKor" disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<font class="text-secondary" style="font-size: small;">(영문)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productInfo.pdtNameEng" disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">출발/여행국가/여행지역</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="text" class="form-control text-primary" v-model="this.productInfo.ctName" disabled>
            <input type="text" class="form-control text-danger" v-model="this.productInfo.ntName" disabled>
            <input type="text" class="form-control text-danger" v-model="this.productInfo.arName" disabled>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">교통편</label>
        <div class="col-md-9">
          <div class="form-check-inline align-middle">
            <input class="form-check-input" type="checkbox" v-model="this.transFlag" true-value="0" false-value="1">
            <label class="form-check-label " for="ckb_transFlag">&nbsp;포함</label>
          </div>
        </div>
      </div>
      <div v-if="this.transFlag === '0'">
        <div class="row border p-sm-2 border-info">
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">출발정보<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class="input-group mb-1">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.trCd1" @change="changeDpTransList()">
                  <option :value="noVal">비이용</option>
                  <option :value="transList[k].baseCode" :key=k v-for="(baseCode,k) in transList">{{transList[k].codeNameKor}}</option>
                </select>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.mbCd1"  @change="changeDpMobilityList">
                  <option :value="mobilityList_dp[m].transCode" :key=m v-for="(transCode,m) in mobilityList_dp">{{mobilityList_dp[m].codeNameKor}}</option>
                </select>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.dpdCd">
                  <option :value="noVal">비이용</option>
                  <option :value="termList_dpd[p].baseCode" :key=p v-for="(baseCode,p) in termList_dpd">{{termList_dpd[p].codeNameKor}}</option>
                </select>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.dpaCd" 
                  @change="getEtcFeeInfo('D',pdtTransInfo.trCd1,pdtTransInfo.mbCd1,pdtTransInfo.dpdCd)">
                  <option :value="noVal">비이용</option>
                  <option :value="termList_dpa[p].baseCode" :key=p v-for="(baseCode,p) in termList_dpa">{{termList_dpa[p].codeNameKor}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">교통편정보<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class= "input-group">
                <input type="text" class="form-control text-end" ref="txt_dFlt" maxlength="4" v-model="pdtTransInfo.dpTpCode">
                <input type="time" class="form-control" ref="txt_d_dpTime" v-model="pdtTransInfo.d_dpTime">
                <input type="time" class="form-control" ref="txt_d_arTime" v-model="pdtTransInfo.d_arTime">
              </div>
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">추가정보</label>
            <div class="col-md-9">
              <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="pdtTransInfo.transRemarks_dp" maxlength="210"></textarea>
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">유류할증료<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class="input-group mb-1">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_oilSurcharge_dp" v-model="pdtTransInfo.oilCd_dp">
                  <option :value="noVal">비이용</option>
                  <option :value="oilSurcharge_dp[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in oilSurcharge_dp">
                    {{oilSurcharge_dp[k].etcFeeNameKor}}&nbsp;({{getCurrencyFormat(oilSurcharge_dp[k].ageFee)}}<small>{{oilSurcharge_dp[k].codeNameKor}}</small>)
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">터미널이용료<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class="input-group mb-1">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.termCd_dp" 
                  @change="changeTermFeeNameList('D', pdtTransInfo.termCd_dp)">
                  <option :value="noVal">비이용</option>
                  <option :value="termFeeNameList_dp[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in termFeeNameList_dp">
                    {{termFeeNameList_dp[k].etcFeeNameKor}}&nbsp;({{termFeeNameList_dp[k].tmCd}})
                  </option>
                </select>
                <div class="input-group mt-2" :key=j v-for="(ageFee,j) in termFee_dp">
                  <span class="input-group-text">{{termFee_dp[j].agegroup}}</span>
                  <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="termFee_dp[j].ageFee" readonly>
                  <span class="input-group-text">{{termFee_dp[j].codeNameKor}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br>

        <div class="row border p-sm-2 border-warning">
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">리턴정보</label>
            <div class="col-md-9">
              <div class="input-group mb-1">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.trCd2" @change="changeRtTransList">
                  <option :value="noVal">비이용</option>
                  <option :value="transList[q].baseCode" :key=q v-for="(baseCode,q) in transList">{{transList[q].codeNameKor}}</option>
                </select>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.mbCd2"  @change="changeRtMobilityList">
                  <option :value="mobilityList_rt[r].transCode" :key=r v-for="(transCode,r) in mobilityList_rt">{{mobilityList_rt[r].codeNameKor}}</option>
                </select>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.rtdCd">
                  <option :value="noVal">비이용</option>
                  <option :value="termList_rtd[s].baseCode" :key=s v-for="(baseCode,s) in termList_rtd">{{termList_rtd[s].codeNameKor}}</option>
                </select>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.rtaCd"
                  @change="getEtcFeeInfo('R',pdtTransInfo.trCd2,pdtTransInfo.mbCd2,pdtTransInfo.rtdCd)">
                  <option :value="noVal">비이용</option>
                  <option :value="termList_rta[s].baseCode" :key=s v-for="(baseCode,s) in termList_rta">{{termList_rta[s].codeNameKor}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">교통편정보<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class= "input-group">
                <input type="text" class="form-control text-end" ref="txt_rFlt" maxlength="4" v-model="pdtTransInfo.rtTpCode">
                <input type="time" class="form-control" ref="txt_r_dpTime" v-model="pdtTransInfo.r_dpTime">
                <input type="time" class="form-control" ref="txt_r_arTime" v-model="pdtTransInfo.r_arTime">
              </div>
            </div>
          </div>          
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">추가정보</label>
            <div class="col-md-9 mb-2">
              <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="pdtTransInfo.transRemarks_ar" maxlength="210"></textarea>
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">유류할증료<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class="input-group mb-1">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_oilSurcharge_rt" v-model="pdtTransInfo.oilCd_rt">
                  <option :value="noVal">비이용</option>
                  <option :value="oilSurcharge_rt[j].etcFeeCode" :key=j v-for="(etcFeeCode,j) in oilSurcharge_rt">
                    {{oilSurcharge_rt[j].etcFeeNameKor}}&nbsp;({{getCurrencyFormat(oilSurcharge_rt[j].ageFee)}}<small>{{oilSurcharge_rt[j].codeNameKor}}</small>)
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-md-3 col-form-label">터미널이용료<font class="text-danger">*</font></label>
            <div class="col-md-9">
              <div class="input-group mb-1">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="pdtTransInfo.termCd_rt" 
                  @change="changeTermFeeNameList('R', pdtTransInfo.termCd_rt)">
                  <option :value="noVal">비이용</option>
                  <option :value="termFeeNameList_rt[k].etcFeeCode" :key=k v-for="(etcFeeCode,k) in termFeeNameList_rt">
                    {{termFeeNameList_rt[k].etcFeeNameKor}}&nbsp;({{termFeeNameList_rt[k].tmCd}})
                  </option>
                </select>
                <div class="input-group mt-2" :key=j v-for="(ageFee,j) in termFee_rt">
                  <span class="input-group-text">{{termFee_rt[j].agegroup}}</span>
                  <input type="number" class="form-control-sm text-end" ref="txt_deliChargeA" v-model="termFee_rt[j].ageFee" readonly>
                  <span class="input-group-text">{{termFee_rt[j].codeNameKor}}</span>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>

      <div class="mt-3 mb-3 row">
        <label class="col-md-3 col-form-label">여행/유효기간</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.tripStart" disabled>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" v-model="productInfo.tripEnd" disabled>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">특기사항</label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
            v-model="productInfo.pdtRemarks" maxlength="210" disabled></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">취소</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>
    </div>
  </main>

</template>

<script>
export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      pdtFlag: "", //상품유형(결합:MIX)
      productInfo: {}, //상품기본정보
      transFlag: "",

      nationList_via: {},
      areaList: {},
      areaList_tmp: {},
      transList: {},
      //tranList_via: {},
      mobilityList_tmp: {},
      mobilityList_dp: {}, //출발편
      mobilityList_rt: {}, //리턴편
      termList_dpd: {}, //출발시작터미널
      termList_dpa: {}, //출발도착터미널
      termList_rtd: {}, //리턴시작터미널
      termList_rta: {}, //리턴도착터미널
      optionFlag1: "1",
      optionFlag2: "1",
      optionFlag3: "1",
      // vCnt_dp: 0,
      // vCnt_ar: 0,
      sRoute: {}, //상품구분

      oilSurcharge_dp: {}, //유류할증
      oilSurcharge_rt: {},
      termFee_dp: {}, //터미널이용료
      termFee_rt : {},
      termFeeNameList_dp: {}, //터미널이용료 명칭
      termFeeNameList_rt : {},

      pdtTransInfo: {
        // TB_Product_M
        pdtCode: "", // 상품코드
        pdtTransCode: "", //교통편코드
        trCd1: "", //운송수단코드
        trCd2: "", //운송수단코드
        mbCd1: "", //교통편코드
        mbCd2: "", //교통편코드
        dpdCd: "", //출발시작터미널
        dpaCd: "", //출발도착터미널
        rtdCd: "", //리턴시작터미널
        rtaCd: "", //리턴도착터미널
        //transRemarks: "",
        transRemarks_dp: "", //출발편 추가정보
        transRemarks_ar: "", //리턴편 추가정보
        insDate: "",
        insId: "",
        useFlag: "0", //Y0, N1
        dpTpCode: "",
        rtTpCode: "",
        d_dpTime: "",
        d_arTime: "",
        r_dpTime: "",
        r_arTime: ""
      }
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.productId = this.$route.query.productId;
    //결합상품?
    if(this.productId.substring(0,3)==="MX-") {
      this.pdtFlag = "MIX";
    }
    //alert("pdtFlag:"+this.pdtFlag);
    this.salesRoute = this.$route.query.salesRoute;

    this.getProductInfo();
    //this.getNationList();
    this.getTransList();
    this.getMobilityList();
    this.getSalesRoute();
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    async getSalesRoute() {
      this.sRoute = await this.$api("/api/salesRoute", {});
      //console.log("sRoute",this.sRoute);
    },
    async getProductInfo() {
      let productInfo = {};
      if(this.pdtFlag === "MIX") {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(productInfo[0].tripStart);
      this.productInfo.tripEnd = this.getDateFormat(productInfo[0].tripEnd);
      //console.log("productInfo",this.productInfo);
    },
    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      //console.log("transList",transList);
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      //console.log("mobilityList_tmp",this.mobilityList_tmp);
    },

    //교통수단변경->교통편획득
    changeDpTransList() {
      this.pdtTransInfo.mbCd1 = "";
      this.pdtTransInfo.dpdCd = "";
      this.pdtTransInfo.dpaCd = "";
      this.viewDpMobilityList(this.pdtTransInfo.trCd1);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    changeRtTransList() {
      this.pdtTransInfo.mbCd2 = "";
      this.pdtTransInfo.rtdCd = "";
      this.pdtTransInfo.rtaCd = "";
      this.viewRtMobilityList(this.pdtTransInfo.trCd2);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    viewDpMobilityList(param1){
      //alert("categoryCode:" + param1);
      this.mobilityList_dp = this.mobilityList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("mobility:", this.mobilityList_dp);
    },
    viewRtMobilityList(param2){
      //alert("categoryCode:" + param2);
      this.mobilityList_rt = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList_rt);
    },
    changeDpMobilityList() {
      this.getDpTermList(this.productInfo.ntCd, this.pdtTransInfo.trCd1);
    },
    changeRtMobilityList() {
      this.getRtTermList(this.productInfo.ntCd, this.pdtTransInfo.trCd2);
    },
    async getDpTermList(param2, param3) {
      //console.log("prarms:",param2,"/",param3);
      //let param2 = "KR";
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
      //출발도착터미널
      this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_dp);
    },
    async getRtTermList(param2, param3) {
      //console.log("prarms:",param2,"/",param3, "/", this.productInfo.ntCd);
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_rtd = await this.$api("/api/termList", {param: [param2, param4]});
      this.termList_rta = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_rt);
    },

    async getEtcFeeInfo(p1,p2,p3,p4) {
      let drFlag = p1;
      let liCd = (this.productInfo.ntCd === "KR") ? "L" : "G"
      let trCd = p2;
      let mbCd = p3;
      let tmCd = p4
      //console.log(tmCd)
      //유류할증료
      this.getOilFeeInfo(drFlag, liCd, trCd, mbCd);
      //터미널이용료
      this.getTermFeeInfo(drFlag, tmCd);
    },
    async getOilFeeInfo(f,o1,o2,o3){
      //console.log(f+"//"+o1+"//"+o2+"//"+o3);
      if(f === "D") {
        //츨발편 유류할증료
        let oilSurcharge_dp = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,'KR']});
        this.oilSurcharge_dp = oilSurcharge_dp;
        //console.log("oilSurcharge_dp",this.oilSurcharge_dp);
      }
      else if(f === "R") {
        //리턴편 유류할증료
        let oilSurcharge_rt = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,this.productInfo.ntCd]});
        this.oilSurcharge_rt = oilSurcharge_rt;
        console.log("oilSurcharge_rt",this.oilSurcharge_rt);
      }
    },
    async getTermFeeInfo(f,t1){
      //console.log(f+"//"+t1);
      if(f === "D") {
        // let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        // this.termFee_dp = termFee_dp;
        let termFeeNameList_dp = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_dp = termFeeNameList_dp;
        //console.log("termFeeNameList_dp",this.termFeeNameList_dp);
      }
      else if(f === "R") {
        let termFeeNameList_rt = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_rt = termFeeNameList_rt;
        //console.log("termFeeNameList_rt",this .termFeeNameList_rt);
      }
    },
    async changeTermFeeNameList(f,t1){
      //console.log(f+"//"+t1);
      if(f === "D") {
        let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_dp = termFee_dp;
        console.log("termFee_dp",this.termFee_dp);
      }
      else if(f === "R") {
        let termFee_rt = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_rt = termFee_rt;
        console.log("termFee_rt",this .termFee_rt);
      }
    },

    goToList() {
      if(this.pdtFlag === "MIX") {
        this.$router.push({path: '/salesMix'});
      }
      else {
        this.$router.push({path: '/sales'});
      }
    },

    productInsert() {
      // e.preventDefault();
      // 입력 validation check
      let timeStamp = "";
      let today = new Date();   
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`      
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime(); // 상품코드에 들어갈 타임스탬프
      let catCode = "TR";
      this.pdtTransInfo.pdtCode = this.productId;
      this.pdtTransInfo.pdtTransCode = catCode+"-"+ymd+"-"+timeStamp;
      this.pdtTransInfo.insDate = `${yyyy}-${mm}-${dd}`;
      this.pdtTransInfo.insId = this.user.handlerId;

      // if(!this.pdtTransInfo.pdtNameKor) {
      //   this.$refs.txt_pdtNameKor.focus();
      //   return this.$swal.fire("필수누락: 제품명(국문)");
      // }
      // if(!this.pdtTransInfo.salesRoute) {
      //   this.$refs.rbt_salesRoute.focus();
      //   return this.$swal.fire("필수누락: 판매루트");
      // }      
      // if(!this.salesUnit) {
      //   this.$refs.sel_salesUnit.focus();
      //   return this.$swal.fire("필수누락: 판매단위");
      // }
      // if(!this.pdtTransInfo.unitPrice) {
      //   this.$refs.txt_unitPrice.focus();
      //   return this.$swal.fire("필수누락: 기본가격");
      // }
      // if(!this.seqDeli) {
      //   this.$refs.sel_seqDeli.focus();
      //   return this.$swal.fire("필수누락: 배송비");
      // }
      // if(!this.pdtTransInfo.vatPrice) {
      //   this.$refs.txt_vatPrice.focus();
      //   return this.$swal.fire("필수누락: 부가세");
      // }      
      // if(!this.pdtTransInfo.salesStart) {
      //   this.$refs.txt_fromDate.focus();
      //   return this.$swal.fire("필수누락: 판매개시일");
      // }
      // if(!this.pdtTransInfo.salesEnd) {
      //   this.$refs.txt_toDate.focus();
      //   return this.$swal.fire("필수누락: 판매종료일");
      // }
      // if(this.pdtTransInfo.salesStart > this.pdtTransInfo.salesEnd) {
      //   return this.$swal.fire("입력오류: 개시일&종료일");
      // }
      // if(!this.pdtTransInfo.catCode) {
      //   this.$refs.sel_pdtCategoryt.focus();
      //   return this.$swal.fire("필수누락: 상품카테고리");
      // }

      // 등록전 확인
      this.$swal.fire({
        title: '교통정보 등록진행?',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //let resIns_product_m = {};
          // if(this.pdtFlag === "MIX") {
          //   resIns_product_m = await this.$api("/api/pdtTransInsert", {param: [this.pdtTransInfo]});
          // }
          // else {
            let resIns_product_m = await this.$api("/api/pdtTransInsert", {param: [this.pdtTransInfo]});
          // }
          
          // 인서트오류
          if(Object.keys(resIns_product_m)[0] === 'error') {
            this.$swal.fire('교통편 저장오류', '', 'error')
          }
          else{
            let res_updTransInfo = {};
            //상품마스터에 교통정보코드를 입력
            if(this.pdtFlag === "MIX") {
              res_updTransInfo = await this.$api("/api/updTransInfo_mix", {param: [this.transFlag, this.pdtTransInfo.pdtTransCode, this.productId]});
            }
            else {
              res_updTransInfo = await this.$api("/api/updTransInfo", {param: [this.transFlag, this.pdtTransInfo.pdtTransCode, this.productId]});
            }
            
            //console.log("res_updTransInfo:",res_updTransInfo);
            if(res_updTransInfo.affectedRows < 0 || res_updTransInfo.affectedRows === undefined) {
              //alert("상품가격정보 수정오류\n결과확인 후 재실행");
              this.$swal.fire('','교통편 등록오류<br>현재정보확인 후 재실행 필요', 'error')
            }
            else {
              this.$swal.fire('','교통편 등록완료','success')
            }
          }
          // this.$swal.fire('등록완료!', '', 'success')
          // 등록 후 라우터를 통해 상품관리리스트로
          if(this.pdtFlag === "MIX") {
            this.$router.push({path: '/salesMix'});
          }
          else {
            this.$router.push({path: '/sales'});
          }
          
        }
      })
    }
  }
}
</script>