<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>상품이미지등록</u></h2>
      <div class="mb-3 row">
        <!-- 수정불가 -->
        <label class="col-md-3 col-form-label">상품코드</label>
        <div class="col-md-9 text-primary">
          <!-- <input type="text" class="form-control" readonly> -->
          {{ productId }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<font class="text-secondary" style="font-size: small;">(국문)</font></label>
        <div class="col-md-9 text-primary">
          <!-- <input type="text" class="form-control" readonly> -->
          {{ productInfo.pdtNameKor }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품Thumbnail</label>
        <div class="col-md-9">
          <!-- <input class="form-control" type="file" accept="image/png, image/jpg, image/jpeg"> -->
          <div class="form-control" type="file" accept="image/png, image/jpg, image/jpeg" :key="i" v-for="(m,i) in productImage.filter(c=>c.catImg=='T')">
            <div class="position-relative">
              <img :src="`/download/${productId}/${m.imgName}`" class="img-fluid" />
              <!-- <img :src="`/download/${productId}/${tImage}`" class="img-fluid" /> -->
              <div>
                <!-- <p class="position-absolute top-0 end-0" style="cursor:pointer;" @click="deleteImage(m.pdtCode, 'T', m.imgName)">
                  <i class="bi bi-file-excel text-warning"></i></p> -->
                <p class="position-absolute top-0 end-70" style="cursor:pointer;" @click="sealingImage(m.pdtCode, 'T', m.imgName)">
                  <i class="bi bi-eye-slash text-warning"></i></p>
              </div>
            </div>
          </div>
          <input type="file" class="form-control" accept="image/png,image/jpeg" @change="uploadFile($event.target.files, 'T', '0')">  
          <div class="alert alert-secondary" role="alert" style="font-size:small;">
            <ul>
              <li>Image사이즈: 640 * 450</li>
              <li>File 사이즈: 1M 이하</li>
              <li>File 확장자: png, jpeg, jpg</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품Images</label>
        <div class="col-md-9">
          <div class="form-control" type="file" accept="image/png, image/jpg, image/jpeg" :key="i" v-for="(m,i) in productImage.filter(c=>c.catImg=='P')">
            <div class="position-relative">
              <img :src="`/download/${productId}/${m.imgName}`" class="img-fluid" />
              <!-- <img :src="`/download/${productId}/${tImage}`" class="img-fluid" /> -->
              <div>
                <p class="position-absolute top-0 end-0" style="cursor:pointer;" @click="deleteImage(m.pdtCode, 'P', m.imgName)">
                  <i class="bi bi-file-excel text-warning"></i></p>
                <p class="position-absolute top-0 end-70" style="cursor:pointer;" @click="sealingImage(m.pdtCode, 'P', m.imgName)">
                  <i class="bi bi-eye-slash text-warning"></i></p>
              </div>
            </div>
          </div>
          <input type="file" class="form-control" accept="image/png,image/jpeg" @change="uploadFile($event.target.files, 'P', '0')">  
          <div class="alert alert-secondary" role="alert" style="font-size:small;">
            <ul>
              <li>등록가능: 3개까지</li>
              <li>Image사이즈: 640 * 450</li>
              <li>File 사이즈: 1M 이하</li>
              <li>File 확장자: png, jpeg, jpg</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품상세Image</label>
        <div class="col-md-9">
          <div class="form-control" type="file" accept="image/png, image/jpg, image/jpeg" :key="i" v-for="(m,i) in productImage.filter(c=>c.catImg=='D')">
            <div class="position-relative">
              <img :src="`/download/${productId}/${m.imgName}`" class="img-fluid" />
              <!-- <img :src="`/download/${productId}/${tImage}`" class="img-fluid" /> -->
              <div>
                <p class="position-absolute top-0 end-0" style="cursor:pointer;" @click="deleteImage(m.pdtCode, 'D', m.imgName)">
                  <i class="bi bi-file-excel text-warning"></i></p>
                <p class="position-absolute top-0 end-70" style="cursor:pointer;" @click="sealingImage(m.pdtCode, 'D', m.imgName)">
                  <i class="bi bi-eye-slash text-warning"></i></p>
              </div>
            </div>
          </div>
          <input type="file" class="form-control" accept="image/png,image/jpeg" @change="uploadFile($event.target.files, 'D', '0')">  
          <div class="alert alert-secondary" role="alert" style="font-size:small;">
            <ul>
              <li>Image사이즈: 가로 1000px 이상</li>
              <li>File 사이즈: 5M 이하</li>
              <li>File 확장자: png, jpeg, jpg</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary">저장</button>
        </div> -->
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">취소</button>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
export default {
  data() {
    return {
      productInfo: {}, //상품기본정보
      pdtFlag: "",
      productId: '', // 상품코드
      pdtNameKor: '', // 상품국문명
      //productDetail: {}, // 상세정보를 오브젝트형으로 변수처리
      productImage: [],
      productDetailImage: [],
      tImage: "",
      m: []
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    // path에서 사용된 productId
    this.productId = this.$route.query.productId;
    //결합상품?
    if(this.productId.substring(0,3)==="MX-") {
      this.pdtFlag = "MIX";
    }
    this.getProductInfo();

    // this.getProductDetail();
    this.getProductImage();
    this.getProductDetailImage(); // 제품상세이미지호출
    // this.getProductB2BPrice();
  },
  mounted() {
    console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    async getProductInfo() {
      let productInfo = {};
      if(this.pdtFlag === "MIX") {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      this.productInfo = productInfo[0]
    },
    // async getProductDetail() {
    //   // axios공통모듈을 호출하여 app.js를 타고 sql.js를 타고 데이타호출, 상품코드도 파라메타로 넘김
    //   let productDetail = await this.$api("/api/productDetail", {param: [this.productId]});
    //   // 상세정보가 있다면 첫번째값 하나만 가져온다
    //   if(productDetail.length > 0) {
    //     this.productDetail = productDetail[0]
    //   }
    //   console.log("pDetail:", this.productDetail);
    // },
    async getProductImage() {
      this.productImage = await this.$api("/api/imageList", {param: [this.productId]});
    },
    async getProductDetailImage() {
      this.productDetailImage = await this.$api("/api/productDetailImage", {param: [this.productId]});
    },
    deleteImage(pdtCode, catImg, imgName) {
      console.log(`id:${pdtCode}, catImg:${catImg}`);
      this.$swal.fire({
        title: '확인: 삭제?',
        showCancelButton: true,
        confirmButtonText: `삭제`,
        cancelButtonText: `취소`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$api("/api/imageDelete",{param:[pdtCode, catImg, imgName]});
          this.getProductImage();
          this.$swal.fire('삭제완료', '', 'success');
        } 
      });
    },
    sealingImage(pdtCode, catImg, imgName) {
      console.log(`id:${pdtCode}, catImg:${catImg}, imgName:${imgName}`);
      this.$swal.fire({
        title: '확인: 미사용?',
        showCancelButton: true,
        confirmButtonText: `처리`,
        cancelButtonText: `취소`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$api("/api/imageSealing",{param:['admin', pdtCode, catImg, imgName]});
          this.getProductImage();
          this.$swal.fire('미사용 처리', '', 'success');
        } 
      });
    },
    async uploadFile(files, catImg, useFlag) {
      let imgName = "";
      let data = null;
      if (files) {
        imgName = files[0].name;
        data = await this.$base64(files[0]);
      }
      // console.log("files:",files, "name:",imgName, "catImg:",catImg, "pdtCode:",this.productId);
      const { error } = await this.$api(`/upload/${this.productId}/${catImg}/${imgName}/${useFlag}`, { data });
      if (error) {
        return this.$swal("업로드 오류발생, 재시도 필요.");
      }

      this.$swal("업로드 완료");

      setTimeout(() => {
        this.getProductImage();
      }, 1000);
    },
    goToList() {
      if(this.pdtFlag === 'MIX') {
        this.$router.push({path: '/salesMix'});
      }
      else {
        this.$router.push({path: '/sales'});
      }
    }
  }
}
</script>
